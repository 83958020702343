export default {
    template: (
        /*html*/
        `
            <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2480_23714)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.2699 18.9015C3.1235 18.8928 2.97682 18.9131 2.83834 18.9612C2.69983 19.0094 2.5722 19.0844 2.46277 19.182C2.35337 19.2796 2.26432 19.3979 2.20074 19.53C2.13949 19.6573 2.10306 19.7951 2.09334 19.936L2.04709 35.163C2.06832 35.4525 2.20036 35.723 2.41614 35.9178C2.63654 36.1169 2.92653 36.221 3.2232 36.2076C3.23461 36.2071 3.24603 36.2068 3.25746 36.2068L28.7177 36.2842C28.7311 36.2842 28.7445 36.2846 28.7578 36.2854C28.9042 36.2941 29.0509 36.2739 29.1894 36.2257C29.3279 36.1776 29.4555 36.1026 29.565 36.0049C29.6744 35.9074 29.7634 35.7891 29.827 35.6569C29.8882 35.5296 29.9247 35.3919 29.9344 35.251L29.9806 20.0239C29.9594 19.7344 29.8274 19.464 29.6116 19.2691C29.3912 19.0701 29.1012 18.966 28.8045 18.9794C28.7931 18.9799 28.7817 18.9801 28.7703 18.9801L3.31001 18.9028C3.29663 18.9027 3.28325 18.9023 3.2699 18.9015ZM3.33275 17.4807C3.00659 17.4641 2.68017 17.5107 2.37151 17.6179C2.05603 17.7276 1.76533 17.8985 1.51609 18.1208C1.26684 18.3431 1.06403 18.6125 0.919237 18.9135C0.77443 19.2144 0.69054 19.5411 0.672354 19.8745C0.67169 19.8867 0.671339 19.8989 0.671302 19.9111L0.624924 35.1825C0.624881 35.1967 0.625261 35.2108 0.626062 35.225C0.664079 35.8952 0.964818 36.5234 1.46304 36.9733C1.95667 37.4191 2.60471 37.6542 3.26886 37.629L28.695 37.7062C29.0211 37.7228 29.3476 37.6763 29.6562 37.569C29.9717 37.4594 30.2624 37.2885 30.5116 37.0662C30.7609 36.8438 30.9637 36.5744 31.1085 36.2735C31.2533 35.9725 31.3372 35.6459 31.3554 35.3124C31.356 35.3002 31.3564 35.288 31.3564 35.2759L31.4028 20.0044C31.4028 19.9903 31.4025 19.9761 31.4017 19.962C31.3637 19.2918 31.0629 18.6636 30.5647 18.2137C30.0711 17.7679 29.423 17.5328 28.7589 17.558L3.33275 17.4807Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9208 27.4202C14.1539 27.7363 14.0866 28.1814 13.7706 28.4145L2.54491 36.6941C2.22887 36.9272 1.78371 36.8599 1.55061 36.5439C1.31751 36.2279 1.38475 35.7827 1.70079 35.5496L12.9265 27.27C13.2425 27.0369 13.6877 27.1042 13.9208 27.4202Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.61 18.397C1.84816 18.0847 2.29435 18.0247 2.6066 18.2628L16.0116 28.4874L29.4775 18.3445C29.7912 18.1082 30.237 18.1709 30.4733 18.4846C30.7095 18.7983 30.6468 19.2441 30.3331 19.4804L16.4367 29.9476C16.1821 30.1393 15.8311 30.1382 15.5777 29.945L1.74414 19.3936C1.4319 19.1554 1.37184 18.7092 1.61 18.397Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.107 27.4329C18.342 27.1183 18.7876 27.0538 19.1022 27.2888L30.2774 35.6364C30.592 35.8714 30.6566 36.317 30.4215 36.6316C30.1865 36.9462 29.741 37.0107 29.4263 36.7757L18.2512 28.4281C17.9365 28.1931 17.872 27.7475 18.107 27.4329Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.5822 4.04715C27.9312 4.04821 28.2278 4.30243 28.2823 4.64717L28.7835 7.81728L31.9505 8.3377C32.2949 8.39429 32.5473 8.69248 32.5463 9.0415C32.5452 9.39052 32.291 9.68717 31.9463 9.74167L28.7758 10.2429L28.2538 13.4065C28.197 13.7508 27.8988 14.003 27.5498 14.0018C27.2009 14.0007 26.9044 13.7465 26.8499 13.4018L26.3488 10.2319L23.1839 9.71322C22.8393 9.65676 22.5867 9.35849 22.5878 9.00935C22.5889 8.66021 22.8433 8.3635 23.1882 8.30915L26.358 7.80964L26.8784 4.64291C26.9349 4.2985 27.2331 4.04609 27.5822 4.04715Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.7898 12.8725C38.1402 12.8736 38.4376 13.1297 38.4906 13.4761L38.7022 14.8599L40.0847 15.0799C40.4307 15.1349 40.6851 15.4339 40.684 15.7843C40.683 16.1346 40.4268 16.432 40.0804 16.485L38.6966 16.6966L38.4766 18.0791C38.4215 18.4252 38.1226 18.6795 37.7722 18.6784C37.4218 18.6774 37.1245 18.4212 37.0715 18.0748L36.8599 16.691L35.4774 16.471C35.1313 16.4159 34.877 16.117 34.878 15.7666C34.8791 15.4162 35.1353 15.1189 35.4816 15.0659L36.8655 14.8543L37.0855 13.4718C37.1405 13.1258 37.4395 12.8714 37.7898 12.8725Z" fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_2480_23714">
                        <rect width="40" height="40" fill="white" transform="translate(0.729492 0.794678) rotate(0.174)"/>
                    </clipPath>
                </defs>
            </svg>
        `
    )
};