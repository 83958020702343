import ModeloIconDescription from "../../../components/atomos/ModeloIconDescription";

export default {
    name: "ModeloIconDescriptionUsage",
    components: { ModeloIconDescription },
    data() {
        return {
            modelName: "M",
            subTitle: ""
        };
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="6" class="text-center">
                <v-row>
                    <v-col>
                        <v-card class="pa-5">
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field label="Icone" v-model="modelName"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field label="Sub titulo" v-model="subTitle"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <modelo-icon-description 
                                        :model-name="modelName"
                                        :sub-title="subTitle"
                                    ></modelo-icon-description>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        `
    )
};