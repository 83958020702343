export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g id="Btn-Chat" transform="translate(-1755 -2098)">
                    <g id="Ico-Chat-Enabled" transform="translate(1755.8 2056)">
                        <g id="Grupo_1854" data-name="Grupo 1854" transform="translate(0.2 45)">
                            <path id="Caminho_482" data-name="Caminho 482" d="M13,7.2H4.2a.773.773,0,0,0-.7.8.789.789,0,0,0,.8.8H13a.8.8,0,0,0,0-1.6Z" fill="currentColor"/>
                            <path id="Caminho_483" data-name="Caminho 483" d="M10.2,10.1h-6a.789.789,0,0,0-.8.8.736.736,0,0,0,.8.8h6a.8.8,0,0,0,0-1.6Z" fill="currentColor"/>
                            <path id="Caminho_484" data-name="Caminho 484" d="M22.6.9A3.1,3.1,0,0,0,20.4,0H9.1A3.08,3.08,0,0,0,6,2.9H3.1A3.116,3.116,0,0,0,0,6v6.8a3.119,3.119,0,0,0,2.3,3v2.9a.752.752,0,0,0,.5.7c.1,0,.2.1.3.1a.764.764,0,0,0,.5-.2L7,15.9h7.5a3.13,3.13,0,0,0,2.9-2.1l2.5,2.5a.764.764,0,0,0,.5.2.367.367,0,0,0,.3-.1.752.752,0,0,0,.5-.7V12.8a2.851,2.851,0,0,0,1.4-.8,3.1,3.1,0,0,0,.9-2.2h0V3.1A3.6,3.6,0,0,0,22.6.9ZM16,12.8a1.58,1.58,0,0,1-1.6,1.6H6.7a.764.764,0,0,0-.5.2L3.9,16.9V15.1c0-.4-.3-.7-.8-.8a1.58,1.58,0,0,1-1.6-1.6V6A1.58,1.58,0,0,1,3.1,4.4H14.4A1.645,1.645,0,0,1,16,6Zm6-2.9h0a1.721,1.721,0,0,1-1.6,1.6h0a.684.684,0,0,0-.7.7V14l-2.1-2.1V6a3.116,3.116,0,0,0-3.1-3.1h-7A1.453,1.453,0,0,1,9,1.5H20.3a1.606,1.606,0,0,1,1.1.5A1.382,1.382,0,0,1,22,3.1Z" fill="currentColor"/>
                        </g>
                    </g>
                    <rect id="Retângulo_472" data-name="Retângulo 472" width="26" height="26" transform="translate(1755 2098)" fill="currentColor" opacity="0"/>
                </g>
            </svg>
        `
    )
};