import FileImport from "../../campaign/components/FileImport.js";

/**
 * Componente para importa��o de arquivos com dados para cadastro de campanhas
 *
 * @author: Lyndon Marques - lyndon.marques@pgmais.com.br
 *
 * @displayName FileImport
 * @requires {@link FileImport}
 * @component
 * @category Page / Criar Campanha
 * @vue
 */
export default {
    components: {
        FileImport
    },
    template:
        /*html*/
        `
            <div>                   
               <v-row class="justify-content-center mx-0">
                    <v-col cols="4">
                        <file-import></file-import>
                    </v-col>
                </v-row>                
            </div>
        `
};
