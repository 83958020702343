export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g id="Btn-Cancel-Hover" transform="translate(-1335 -2155)">
                    <g id="Ico-Cancelar-Hover" transform="translate(1339 2113)">
                        <g id="slash-circle" transform="translate(-1 45)">
                            <path id="Subtração_3" data-name="Subtração 3" d="M-1778.115-2011.23a9.868,9.868,0,0,1-6.59-2.517l14.234-13.635a9.9,9.9,0,0,1,2.24,6.267A9.9,9.9,0,0,1-1778.115-2011.23Zm-7.79-3.8h0a9.784,9.784,0,0,1-2.1-6.085,9.9,9.9,0,0,1,9.886-9.886,9.885,9.885,0,0,1,6.415,2.365l-14.2,13.606Z" transform="translate(1788 2031)" fill="currentColor"/>
                            <path id="Caminho_620" data-name="Caminho 620" d="M9.885,18.534A8.649,8.649,0,1,0,1.236,9.885,8.649,8.649,0,0,0,9.885,18.534Zm0,1.236A9.885,9.885,0,1,0,0,9.885,9.885,9.885,0,0,0,9.885,19.77Z" fill="currentColor" stroke="currentColor" stroke-width="0.5" fill-rule="evenodd"/>
                        </g>
                    </g>
                    <rect id="Retângulo_478" data-name="Retângulo 478" width="26" height="26" transform="translate(1335 2155)" fill="currentColor" opacity="0"/>
                </g>
            </svg>
        `
    )
};