/**
 * Classe que representa um valor utilizado para BaseProgressBar
 */
export class ProgressBarValueClass {
    name;
    total;
    color;

    /**
     * Cria um ProgressBarValue
     * @param {String} name - Nome do item
     * @param {Number} total - Valor total
     * @param {String} color - Cor de representacao
     */
    constructor({
        name,
        total,
        color
    }) {
        this.name = window.Vue.htmlEntities(name);
        this.total = total;
        this.color = color;
    }

    /**
     * Pega o valor de name
     * @return {String} name do objeto
     */
    get name() {
        return this.name;
    }

    /**
     * Define um novo name para o objeto
     * @param {String} name - name do objeto
     */
    set name(name) {
        this.name = name;
    }

    /**
     * Pega o valor de total
     * @return {Number} total do objeto
     */
    get total() {
        return this.total;
    }

    /**
     * Define um novo total para o objeto
     * @param {Number} total - total do objeto
     */
    set total(total) {
        this.total = total;
    }

    /**
     * Pega o valor de color
     * @return {String} color do objeto
     */
    get color() {
        return this.color;
    }

    /**
     * Define um novo color para o objeto
     * @param {String} color - color do objeto
     */
    set color(color) {
        this.color = color;
    }
}