import BaseInformation from "../../../components/atomos/BaseInformation";

export default {
    components: { BaseInformation },
    data() {
        return {
            description: "O nome do template ser&aacute; gravado em letras MAI&Uacute;SCULAS, al&eacute;m disso todo espa&ccedil;o ser&aacute; convertido em <i>underline</i> ( _ )",
            icon: "mdi-information-outline"
        };
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="6" class="text-center">
                <v-row>
                    <v-col>
                        <v-card class="pa-5">
                            <v-row>
                                <v-col cols="4">
                                    <v-textarea label="Descricao " 
                                                  v-model="description">
                                    </v-textarea>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field label="Icone " 
                                                  v-model="icon">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="6">
                                    <base-information :description="description" :icon="icon"></base-information>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        `
    )
};
