import BaseTextField from "../../../../components/atomos/BaseTextField";
import BaseButton from "../../../../components/atomos/BaseButton";
import FilterPageSlider from "../../../../components/atomos/FilterPageSlider";
import BaseAutocomplete from "../../../../components/atomos/BaseAutocomplete";
import BaseDatePicker from "../../../../components/atomos/BaseDatePicker";

/**
 * Componente de formulario de pesquisa de blacklist
 *
 * @requires {@link BaseTextField}
 * @requires {@link BaseButton}
 * @requires {@link FilterPageSlider}
 * @requires {@link BaseAutocomplete}
 * @requires {@link BaseDatePicker}
 *
 * @displayName FormFilterBlacklist
 * @category Page / Blacklist
 * @subcategory forms
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseButton,
        FilterPageSlider,
        BaseAutocomplete,
        BaseDatePicker
    },
    data() {
        return {
            rules: {
                telefone: [
                    v => !v || Number.isInteger(parseInt(v.match(/\d/g).join(""))) || window.Vue.htmlEntities("Informe um telefone v&aacute;lido"),
                    v => !v || (v.match(/\d/g).length === 11 || v.match(/\d/g).length === 11) || window.Vue.htmlEntities("Informe um telefone v&aacute;lido")
                ],
                data_inicio: [
                    v => !v || this.isDateValid(v) || "A data inicial deve ser menor que a data atual",
                    _ => {
                        const startDate = this.form_data.data_inicio;
                        const endDate = this.form_data.data_fim;
                        const dateDifference = this.dateStringDiff(startDate, endDate);
                        return dateDifference <= 31 || "A diferenca entre as datas nao pode ser maior do que 31 dias";
                    }
                ],
                data_fim: [
                    v => !v || this.isDateValid(v) || "A data final deve ser menor que a data atual",
                    _ => {
                        const startDate = this.form_data.data_inicio;
                        const endDate = this.form_data.data_fim;
                        const dateDifference = this.dateStringDiff(startDate, endDate);
                        return dateDifference <= 31 || "A diferenca entre as datas nao pode ser maior do que 31 dias";
                    }
                ]
            },
            form_data: {
                data_inicio: dayjs().format("YYYY-MM-DD"),
                data_fim: dayjs().format("YYYY-MM-DD"),
                telefone: null
            },
            clients_loading: false,
            client_list: [],
            pills_filter: [],
            today: dayjs(),
        };
    },
    computed: {
        showReset() {
            let has_filter = false;

            for (let key of Object.keys(this.form_data)) {
                if (this.form_data[key] && this.form_data[key].length > 0) {
                    has_filter = true;
                }
            }

            return has_filter;
        }
    },
    mounted() {
        this.setStartDate(this.today);
        this.setEndDate(this.today);
    },
    methods: {
        isDateValid(date) {
            const parts = date.split("/");
            if (parts.length === 3) {
                const day = parseInt(parts[0]);
                const month = parseInt(parts[1]);
                const year = parseInt(parts[2]);
                if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1900) {
                    return false;
                }
                const inputDate = dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD");
                const tomorrow = dayjs().add(1, "day");
                return inputDate.isValid() && inputDate.isBefore(tomorrow, "day");
            }
            return false;
        },
        dateStringDiff(startDate, endDate) {
            return Math.floor((new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24));
        },
        /**
         * Submit dor formulario, atualiza a lista de dados de acordo com o filtro
         * @vue
         */
        formSubmit() {
            if (this.$refs.form.validate()) {
                /**
                 * Emite o conteudo do formulario
                 * @property {Object} form_data Conteudo emitido
                 */
                this.$emit("formSubmit", this.form_data);
                this.makePillsFilter();
            }
        },
        /**
         * Define o dia inicial do filtro
         * @param {String} start_date
         * @vue
         */
        setStartDate(start_date) {
            let date = dayjs(start_date).format("YYYY-MM-DD");
            this.form_data.data_inicio = date;
            this.$emit("dataInicio", date);
        },
        /**
         * Define o dia final do filtro
         * @param {String} end_date
         * @vue
         */
        setEndDate(end_date) {
            let date = dayjs(end_date).format("YYYY-MM-DD");
            this.form_data.data_fim = date;
            this.$emit("dataFim", date);
        },
        /** 
         * Função usada para limpar os inputs do formulário de busca
         * @vue
        */
        limparFiltro() {

            // Inputs a serem limpos, precisa declarar o nome dos inputs nos components e inserir o nome no objeto "limpar_obj"
            let limpar_obj = ["telefone"];

            /*
             * this.$refs.form.inputs.forEach
             * Percorre o DOM pegando todos os inputs dentro do form
            */
            this.$refs.form.inputs.forEach((input) => {
                // Percorre o objeto "limpar_obj" para verificar se o input atual está dentro do objeto
                if (limpar_obj.includes(input.$refs.input.name)) {
                    // Se estiver, limpa o input
                    input.reset();
                }
            });

            //Reinicia os valores das datas nos campos Início e Fim
            let day = "";

            const now = dayjs(new Date());
            day = now.month(now.month()).date(1).format("YYYY-MM-DD"); // Insere o primeiro dia do mês
            this.form_data.data_inicio = day; //Valor data_inicio

            day = dayjs().day(dayjs().day()).format("YYYY-MM-DD"); // Insere a data atual
            this.form_data.data_fim = day; // Valor data_fim

            // Declara null os inputs do formulário
            this.form_data.telefone = null;

            this.formSubmit();
        },
        /**
         * Retorna a mascara de telefone para aplicar ao campo de acordo com a quantidade de caracteres inseridos
         * 
         * @returns {String} Mascara de telefone
         * @vue
         */
        maskFone() {
            if (this.form_data.telefone) {
                return this.form_data.telefone.length == 15 ? "(##) #####-####" : "(##) ####-#####";
            } else {
                return "(##) #####-####";
            }
        },
        /**
         * Monta a configuracao das pills do filtro
         * @vue
         */
        makePillsFilter() {
            let config = [];

            if (
                this.form_data.data_inicio !== null &&
                this.form_data.data_inicio.length > 0
            ) {
                config.push({
                    text: window.Vue.htmlEntities("Per&iacute;odo de:"),
                    subText: moment(this.form_data.data_inicio).format("DD/MM/YYYY HH:mm:ss"),
                    click: () => {
                        this.form_data.data_Inicio = "";
                        this.formSubmit();
                    }
                });
            }

            if (
                this.form_data.data_fim !== null &&
                this.form_data.data_fim.length > 0
            ) {
                config.push({
                    text: "Data Fim:",
                    subText: moment(this.form_data.data_fim).format("DD/MM/YYYY HH:mm:ss"),
                    click: () => {
                        this.form_data.data_fim = "";
                        this.formSubmit();
                    }
                });
            }

            if (
                this.form_data.telefone !== null &&
                this.form_data.telefone.length > 0
            ) {
                config.push({
                    text: "Telefone:",
                    subText: this.form_data.telefone,
                    click: () => {
                        this.form_data.telefone = "";
                        this.formSubmit();
                    }
                });
            }

            this.pills_filter = config;
        },
    },
    template:
        /*html*/
        `
        <filter-page-slider
            :pills-filter="pills_filter"
            :show-reset="showReset"
            @reset="limparFiltro();" 
            v-bind:data-v-step="2"
        >
            <v-form ref="form">
                <v-row class="mt-0" justify="center" justify-md="end">
                    <v-col cols="6" md="3">
                        <base-date-picker                            
                            tooltip-text="Escolha uma <b>data inicial</b> para filtrar."
                            v-model="form_data.data_inicio"
                            label="Selecione..."
                            title="Per&iacute;odo de"
                            use-icon-validation
                            clearable
                            :rules="rules.data_inicio"
                            :inicio="true"
                            @input="setStartDate"
                            :max-date="form_data.data_fim"
                        ></base-date-picker>
                    </v-col>
                   <v-col cols="6" md="3">
                        <base-date-picker 
                            tooltip-text="Escolha uma <b>data fim</b> para filtrar."
                            v-model="form_data.data_fim"                            
                            label="Selecione..."
                            title="Fim"
                            use-icon-validation
                            clearable
                            name="dataFim"
                            :rules="rules.data_fim"
                            :fim="true"
                            @input="setEndDate"
                            :min-date="form_data.data_inicio"
                        ></base-date-picker>
                    </v-col>                      
                    <v-col cols="6" md="3">
                        <base-text-field
                            tooltip-text="Voc&ecirc; pode pesquisar um n&uacute;mero de telefone  espec&iacute;fico."
                            v-model="form_data.telefone"
                            label="(__) _____-____"
                            :mask="maskFone()"
                            :rules="rules.telefone"
                            type="tel"
                            title="Telefone"
                            name="telefone"
                            use-icon-validation
                        ></base-text-field>
                    </v-col>

                    <v-col class="mt-5 d-flex justify-end" md="3">
                        <base-button 
                            block
                            icon='fa fa-filter'
                            label='APLICAR FILTROS'
                            variant="extra-dark-grey"
                            @click="formSubmit()"
                            :disabled="false"
                        ></base-button>
                    </v-col>                                    
                </v-row>
              
            </v-form>
        </filter-page-slider>
    `
};
