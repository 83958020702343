export default {
    template: (
        /*html*/
        `
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.36841 13.0834C6.36841 12.8073 6.59227 12.5834 6.86841 12.5834H15.3421C15.6182 12.5834 15.8421 12.8073 15.8421 13.0834C15.8421 13.3595 15.6182 13.5834 15.3421 13.5834H6.86841C6.59227 13.5834 6.36841 13.3595 6.36841 13.0834Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.36841 18.7676C6.36841 18.4915 6.59227 18.2676 6.86841 18.2676H12.0263C12.3024 18.2676 12.5263 18.4915 12.5263 18.7676C12.5263 19.0438 12.3024 19.2676 12.0263 19.2676H6.86841C6.59227 19.2676 6.36841 19.0438 6.36841 18.7676Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.36841 15.9255C6.36841 15.6494 6.59227 15.4255 6.86841 15.4255H18.1842C18.4603 15.4255 18.6842 15.6494 18.6842 15.9255C18.6842 16.2017 18.4603 16.4255 18.1842 16.4255H6.86841C6.59227 16.4255 6.36841 16.2017 6.36841 15.9255Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 3.41602C4.44772 3.41602 4 3.86373 4 4.41602V21.5834C4 22.1357 4.44772 22.5834 5 22.5834H21C21.5523 22.5834 22 22.1357 22 21.5834V4.41602C22 3.86373 21.5523 3.41602 21 3.41602H5ZM21 10.3714H5L5 21.5834L21 21.5834V10.3714ZM6.36841 13.0834C6.36841 12.8073 6.59227 12.5834 6.86841 12.5834H15.3421C15.6182 12.5834 15.8421 12.8073 15.8421 13.0834C15.8421 13.3595 15.6182 13.5834 15.3421 13.5834H6.86841C6.59227 13.5834 6.36841 13.3595 6.36841 13.0834ZM6.36841 18.7676C6.36841 18.4915 6.59227 18.2676 6.86841 18.2676H12.0263C12.3024 18.2676 12.5263 18.4915 12.5263 18.7676C12.5263 19.0438 12.3024 19.2676 12.0263 19.2676H6.86841C6.59227 19.2676 6.36841 19.0438 6.36841 18.7676ZM6.36841 15.9255C6.36841 15.6494 6.59227 15.4255 6.86841 15.4255H18.1842C18.4603 15.4255 18.6842 15.6494 18.6842 15.9255C18.6842 16.2017 18.4603 16.4255 18.1842 16.4255H6.86841C6.59227 16.4255 6.36841 16.2017 6.36841 15.9255ZM9.6343 8.10545C9.68817 8.14336 9.75256 8.16371 9.81857 8.16368L16.1815 8.16336C16.2474 8.16334 16.3116 8.14304 16.3654 8.10525C16.4192 8.06746 16.4598 8.01404 16.4818 7.95234C16.5037 7.89065 16.5058 7.82371 16.4879 7.76076C16.4699 7.6978 16.4328 7.64192 16.3815 7.60082L14.0028 5.69392C13.9462 5.64851 13.8756 5.62375 13.8028 5.62375C13.73 5.62375 13.6594 5.64851 13.6028 5.69392L12.012 6.96898L11.4116 6.49281C11.3552 6.44796 11.285 6.42353 11.2127 6.42353C11.1404 6.42353 11.0703 6.44796 11.0138 6.49281L9.61952 7.60018C9.56796 7.64114 9.53049 7.697 9.51229 7.76004C9.49408 7.82307 9.49605 7.89017 9.51791 7.95205C9.53976 8.01393 9.58044 8.06754 9.6343 8.10545Z" fill="currentColor"/>
            </svg>
        `
    )
};