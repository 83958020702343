import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "add-botao-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom xmlns="http://www.w3.org/2000/svg" width="14.815" height="14.815" viewBox="0 0 14.815 14.815">
                <path id="shape-plus" d="M2,2H8.667V8.667H2V2M13.482,2a3.333,3.333,0,1,1-3.333,3.333A3.32,3.32,0,0,1,13.482,2M5.333,10.889l3.333,5.926H2l3.333-5.926m9.26,2.222h2.222v1.482H14.593v2.222H13.112V14.593H10.889V13.112h2.222V10.889h1.482Z" transform="translate(-2 -2)"/>
            </svg-custom>
        `
    )
};