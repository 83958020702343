import BaseButton from "../atomos/BaseButton?v=2";
import BaseIconButton from "../atomos/BaseIconButton";

/**
 * Componente de base dialog para modais
 * 
 * @requires {@link BaseButton}
 * @requires {@link BaseIconButton}
 * 
 * @displayName BaseDialog
 * @component
 * @vue
 * @category Moleculas
 * @author Igor Mateus Rodrigues de Oliveira <igor.oliveira@pgmais.com.br>
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 * @vue/component 
 */
export default {
    name: "base-dialog",
    components: {
        BaseButton,
        BaseIconButton
    },
    props: {
        /**
         * Valor do atributo v-model do componente
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Propriedade do titulo da modal
         */
        title: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Propriedade do subTitulo da modal
         */
        subTitle: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Indica se o botao de voltar/cancelar deve ser apresentado dentro da modal
         */
        cancel: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Dados do botao de acao dentro da modal
         * <pre>
         * {
         *  primary: false,
         *  secondary: false,
         *  tertiary: false,
         *  dange: false,
         *  color: "primary-blue-500",
         *  icon: "mdi-check-bold",
         *  label: "Label do botao",
         *  loading: false,
         * }
         * </pre>
         */
        actionButton: {
            type: Object,
            required: false,
            default: null
        },
        /**
         * Propriedade verificar se a modal vai utilizar icone de sair
         */
        useIconClose: {
            type: Boolean,
            required: false,
        },
        /**
         * Tamanho maximo da largura da modal
         */
        maxWidth: {
            type: String,
            required: false,
            default: "500px"
        },
        /**
         * Propriedade para liberar mais uma coluna 
         */
        useSubAction: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Quantidade de colunas que o botao de acao deve ocupar
         */
        colsButtonAction: {
            type: [String, Number],
            required: false,
            default: null
        },
        /**
         * Quantidade de colunas que o botao cancelar/voltar deve ocupar
         */
        colsButtonCancel: {
            type: [String, Number],
            required: false,
            default: null
        },
        /**
         * justify content dos botoes
         */
        justifyButtons: {
            type: String,
            required: false,
            default: "space-between"
        },
        /**
         * Habilita o estado de loading do dialog
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica que o botao de acao deve ficar com o estado de desabilitado
         */
        disableActionButton: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Habilita o estado de tela cheia
         */
        fullscreen: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Classe personalizada
         */
        contentClass: {
            type: String,
            required: false,
            default: "pa-8"
        },
        /**
         * Permite exibir um link para redirect
         */
        clickLink: {
            type: Object,
            required: false,
            default() {
                return {
                    active: false,
                    description: ""
                };
            }
        },
        /**
         * Ordem em que o buttonAction deve aparecer
         */
        buttonActionOrder: {
            type: [String, Number],
            required: false,
            default: undefined
        },
        /**
         * Parametro para definir se a modal deve ser persistente
         */
        persistent: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            dialog: this.value
        };
    },
    computed: {
        configActionButton() {
            let primary = this.actionButton.primary ? this.actionButton.primary : false;
            let secondary = this.actionButton.secondary ? this.actionButton.secondary : false;
            let tertiary = this.actionButton.tertiary ? this.actionButton.tertiary : false;

            return {
                primary: !primary ? (secondary || tertiary ? false : (this.actionButton.color ? false : true)) : primary,
                secondary: secondary,
                tertiary: tertiary,
                danger: this.actionButton.danger ? this.actionButton.danger : false,
                icon: this.actionButton.icon ? this.actionButton.icon : null,
                label: this.actionButton.label,
                loading: this.actionButton.loading ? this.actionButton.loading : false,
                color: this.actionButton.color ?? null,
                id: this.actionButton?.id ?? null
            };
        }
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog() {
            /**
            *
            * Eh realizado um emmit de input sempre que o do valor do dialog eh alterado 
            * @event input
            * @vue
            * @property {Boolean} dialog  status da modal se ela esta ou nao ativa
            */
            this.$emit("input", this.dialog);

            if (!this.dialog) {
                this.closeModal();
            }
        }
    },
    methods: {
        /**
         * Funcao para fechar a modal
         * @vue
         */
        closeModal() {
            /**
            * Evento de closeDialog dos botoes
            * @event closeDialog
            * @vue
            */
            this.$emit("closeDialog");
            this.$emit("input", false);
        },
        /**
         * Funcao que fica vinculada ao botao  
         * @vue
         */
        buttonFunction: function () {
            /**
            * Evento de clickDialog do botao
            * @event clickDialog
            * @vue
            */
            this.$emit("clickDialog");
        },
        /**
         * Funcao chamada quando clicado fora da modal
         * @vue
         */
        clickOutside() {
            /**
            * Evento de clickOutside da modal
            * @event clickOutside
            * @vue
            */
            if (!this.persistent) { // se a modal nao for persistente
                this.$emit("clickOutside");
                this.closeModal();
            }
        },
        /**
          * Evento de clickLink do link da modal
          * @event redirectLink
          * @vue
          */
        redirectLink(link) {
            window.location.href = `${link}`;
        }
    },
    template:
        /*html*/
        `  
        <v-dialog
            v-model="dialog"
            content-class="bg-gray-0 "
            :max-width="maxWidth"
            :fullscreen="fullscreen"
            persistent="persistent"
            @click:outside="clickOutside"
        >
            <v-card
                v-if="loading"
                :class="contentClass"
                elevation="0"
                height="200"
            >
                <v-row class="fill-height" justify="center" align="center">
                    <v-col cols="auto">
                        <v-progress-circular indeterminate color="primary-blue-500" />
                    </v-col>
                </v-row>
            </v-card>
            <v-card v-else :class="contentClass" elevation="0">
                <v-btn
                    v-if="useIconClose"
                    size="32"
                    icon
                    color="gray-300"
                    absolute
                    right
                    top
                    @click="closeModal"
                >
                    <base-icon-button
                        size="32"
                        danger
                        :value="false"
                        icon="mdi-close-thick"
                    />
                </v-btn>
                <v-row v-if="title" no-gutters>
                    <v-col class="pr-3">
                        <span class="subtitle-3 text-gray-900" v-html="title" />
                    </v-col>
                </v-row>
                <v-row v-if="subTitle" no-gutters>
                    <v-col class="pr-3 pt-1">
                        <span class="text-gray-900 font-size-12" v-html="subTitle" />
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-6">
                    <v-col>
                        <slot />
                    </v-col>
                </v-row>
                <v-row
                    v-if="cancel || actionButton || useSubAction"
                    :justify="justifyButtons"
                    no-gutters
                    class="mt-6 align-items-center"
                >
                    <v-col v-if="cancel" :cols="colsButtonCancel">
                        <base-button
                            tertiary
                            left
                            icon="fa-undo"
                            label="Voltar"
                            @click="closeModal"
                        />
                    </v-col>
                    <v-col v-if="actionButton" :cols="colsButtonAction" :order="buttonActionOrder">
                        <base-button
                            :primary="configActionButton.primary"
                            :secondary="configActionButton.secondary"
                            :tertiary="configActionButton.tertiary"
                            :color="configActionButton.color"
                            :danger="configActionButton.danger"
                            :icon="configActionButton.icon"
                            :label="configActionButton.label"
                            :loading="configActionButton.loading"
                            :id="configActionButton.id"
                            :disabled="disableActionButton"
                            @click="buttonFunction"
                        />
                    </v-col>
                    <v-col v-if="useSubAction" :cols="colsButtonCancel === 'auto' && colsButtonAction === 'auto' ? 'auto' : 12">
                        <slot name="subAction" />
                    </v-col>
                </v-row>
                <v-row v-if="clickLink.active" class="mt-5">
                    <v-col>
                        <a
                            class="font-weight-bold btn btn-link btn-sm text-primary font-size-12"
                            @click="redirectLink(clickLink.link)"
                            v-html="clickLink.description"
                        />
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        `,
};