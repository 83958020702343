import ReportStepFive from "../../reports/components/ReportStepFive";

export default {
    components: {
        ReportStepFive
    },
    template: (
        /*html*/
        `
            <v-container>
                <v-row class="mt-10">
                    <v-col cols="12" md="3">
                    </v-col>
                    <v-col cols="12" md="9">
                        <report-step-five></report-step-five>
                    </v-col>
                </v-row>
            </v-container>
        `
    )
};