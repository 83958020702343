const DDD = { 11: true, 12: true, 13: true, 14: true, 15: true, 16: true, 17: true, 18: true, 19: true, 21: true, 22: true, 24: true, 27: true, 28: true, 31: true, 32: true, 33: true, 34: true, 35: true, 37: true, 38: true, 41: true, 42: true, 43: true, 44: true, 45: true, 46: true, 47: true, 48: true, 49: true, 51: true, 53: true, 54: true, 55: true, 61: true, 62: true, 63: true, 64: true, 65: true, 66: true, 67: true, 68: true, 69: true, 71: true, 73: true, 74: true, 75: true, 77: true, 79: true, 81: true, 82: true, 83: true, 84: true, 85: true, 86: true, 87: true, 88: true, 89: true, 91: true, 92: true, 93: true, 94: true, 95: true, 96: true, 97: true, 98: true, 99: true };

/**
 * Utilizado para retornar os dados de validacao com o status e o motivo caso status seja false
 * @vue
 */
class ValidationResult {
    status;
    reason;

    constructor(status, reason = null) {
        this.reason = reason;
        this.status = status;
    }
}

/**
 * Utilitario de campanha
 * @vue
 */
export class CampaignUtils {
    /**
     * Verifica se e um numero 0800 valido
     * @vue
     * @param {Integer} phone
     * @returns {ValidationResult}
     */
    is0800Valid(phone) {
        if (phone.length !== 11) {
            return new ValidationResult(false, "N&atilde;o tem a quantidade correta de caracteres");
        }

        if (phone.slice(0, 4) !== "0800") {
            return new ValidationResult(false, "N&atilde;o &eacute; um n&uacute;mero 0800 v&aacute;lido");
        }

        return new ValidationResult(true);
    }

    /**
     * Verifica se e um numero 0300 valido
     * @vue
     * @param {Integer} phone
     * @returns {ValidationResult}
     */
    is0300Valid(phone) {
        if (phone.length !== 8) {
            return new ValidationResult(false, "N&atilde;o tem a quantidade correta de caracteres");
        }

        if (phone.slice(0, 4) !== "0300") {
            return new ValidationResult(false, "N&atilde;o &eacute; um n&uacute;mero 0300 v&aacute;lido");
        }

        return new ValidationResult(true);
    }

    /**
     * Verifica se e um numero 400x valido
     * @vue
     * @param {Integer} phone
     * @returns {ValidationResult}
     */
    is4000Valid(phone) {
        if (phone.length !== 8) {
            return new ValidationResult(false, "N&atilde;o tem a quantidade correta de caracteres");
        }

        if (phone.slice(0, 3) !== "400") {
            return new ValidationResult(false, "N&atilde;o &eacute; um n&uacute;mero 400X v&aacute;lido");
        }

        return new ValidationResult(true);
    }

    /**
     * Verifica se e um numero valido
     * @vue
     * @param {Integer} phone
     * @param {Boolean} required
     * @param {Integer} type
     * @returns {ValidationResult}
     */
    isPhoneValid(phone, required = false, type = 3) {
        let regexCellPhone = /^\d{2}9?[1-9]\d{7}$/gm;
        let regexLandlinePhone = /^\d{2}[2-5]\d{7}$/gm;

        if ((!phone) && required) {
            return new ValidationResult(false, "O campo n&atilde;o pode estar vazio");
        }

        if (phone && (
            (phone.length !== 10 && type === 2) ||
            (phone.length !== 10 && phone.length !== 11 && (type === 3 || type === 1))
        )
        ) {
            return new ValidationResult(false, "Telefone possui quantidade de d&iacute;gitos inv&aacute;lida");
        }

        if (phone && !regexCellPhone.test(phone) && !regexLandlinePhone.test(phone)) {
            return new ValidationResult(false, "Telefone inv&aacute;lido");
        }

        return new ValidationResult(true);
    }

    /**
     * Verifica se tem um DDD valido
     * @vue
     * @param {Integer} ddd
     * @returns {ValidationResult}
     */
    isDDDValid(ddd) {
        let isValid = DDD[ddd] === true;
        if (!isValid) {
            return new ValidationResult(false, "DDD inv&aacute;lido");
        }
        return new ValidationResult(true);
    }

    /**
     * Verifica se o numero e o DDD e valido
     * @vue
     * @param {Integer} phone
     * @param {Boolean} required
     * @param {Integer} type
     * @returns {ValidationResult}
     */
    isTelefoneDDDValid(phone, required = false, type = 3 ) {
        let isPhoneValidResult = this.isPhoneValid(phone, required, type);
        if (!isPhoneValidResult.status) {
            return isPhoneValidResult;
        }

        let isDDDValidResult = this.isDDDValid(phone.slice(0, 2));
        if (!isDDDValidResult.status && phone.length > 2) {
            return isDDDValidResult;
        }
        return new ValidationResult(true);
    }
}
