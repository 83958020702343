import PageHeader from "../../components/moleculas/PageHeader";
import BaseSectionForm from "../../components/atomos/BaseSectionForm";
import BaseAutocomplete from "../../components/atomos/BaseAutocomplete";
import BaseButton from "../../components/atomos/BaseButton";
import SummaryHygiene from "./components/SummaryHygiene";
import PreviewTemplate from "../../components/organismos/PreviewTemplate";
import PgTour from "../../components/organismos/PgTour";
import TimeRules from "./components/TimeRules";
import DialogPreviewTemplate from "../../components/organismos/DialogPreviewTemplate";
import BaseDatePicker from "../../components/atomos/BaseDatePicker";
import BaseTimePicker from "../../components/atomos/BaseTimePicker";
import FileImport from "./components/FileImport";
import DialogFileConfiguration from "./components/dialogs/DialogFileConfiguration?v=2";
import ModalFormTesteTemplate from "../template-rcs/components/dialogs/ModalFormTesteTemplate";
import DialogTemplateManual from "./components/dialogs/DialogTemplateManual";
import DialogConfirmation from "./components/dialogs/DialogConfirmation";
import BaseTooltip from "../../components/atomos/BaseTooltip";
import BaseDialog from "../../components/moleculas/BaseDialog";
import DialogStaggerCampaign from "./components/dialogs/DialogStaggerCampaign";
import BaseInformation from "../../components/atomos/BaseInformation";
import DialogUnderstandingConfirmation from "./components/dialogs/DialogUnderstandingConfirmation";
import { CardRcsClass } from "../../classes/CardRcsClass";
import { BasePillClass } from "../../classes/BasePillClass";
import { DraggableItemClass } from "../../classes/DraggableItemClass";
import TemplateUtils from "../../utils/TemplateUtils";
import { BaseService } from "../../services/BaseService";
import * as types from "./store/mutations-types";

/**
 * Pagina de criacao de campanhas RCS
 * 
 * @requires {@link PageHeader}
 * @requires {@link BaseSectionForm}
 * @requires {@link BaseAutocomplete}
 * @requires {@link BaseButton}
 * @requires {@link SummaryHygiene}
 * @requires {@link PreviewTemplate}
 * @requires {@link PgTour}
 * @requires {@link TimeRules}
 * @requires {@link DialogPreviewTemplate}
 * @requires {@link BaseDatePicker}
 * @requires {@link BaseTimePicker}
 * @requires {@link FileImport}
 * @requires {@link DialogFileConfiguration}
 * @requires {@link ModalFormTesteTemplate}
 * @requires {@link DialogTemplateManual}
 * @requires {@link DialogConfirmation}
 * @requires {@link BaseTooltip}
 * @requires {@link BaseDialog}
 * @requires {@link DialogStaggerCampaign}
 * @requires {@link BaseInformation}
 * @requires {@link CardRcsClass}
 * @requires {@link BasePillClass}
 * @requires {@link DraggableItemClass}
 * @requires {@link TemplateUtils}
 * @requires {@link DialogUnderstandingConfirmation}
 * 
 * @displayName NewCampaignPage
 * @category Page / Campaign
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PageHeader,
        BaseSectionForm,
        BaseAutocomplete,
        BaseButton,
        SummaryHygiene,
        PreviewTemplate,
        PgTour,
        TimeRules,
        DialogPreviewTemplate,
        BaseDatePicker,
        BaseTimePicker,
        FileImport,
        DialogFileConfiguration,
        ModalFormTesteTemplate,
        DialogTemplateManual,
        DialogConfirmation,
        BaseTooltip,
        BaseDialog,
        DialogStaggerCampaign,
        BaseInformation,
        DialogUnderstandingConfirmation
    },
    data() {
        return {
            id_empresa: this.$cookie === undefined ? getCookie("id_empresa_selected") : this.$cookie.get("id_empresa_selected"),
            loading: true,
            time_rules_loading: true,
            time_rules_description: null,
            client_id: null,
            client_list: [],
            client_info: null,
            template_id: null,
            template_list: [],
            template_list_autocomplete: [],
            template_loading: false,
            layout_id: null,
            layout_list: [],
            layout_list_autocomplete: [],
            layout_loading: false,
            ds_template: null,
            show_dialog_preview: false,
            template_utils: new TemplateUtils(),
            test_phones: null,
            test_phones_rules: [
                v => {
                    let is_valid = true;

                    for (let i of v) {
                        if (!(/^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/.test(i))) {
                            is_valid = false;
                        }
                    }

                    return is_valid || Vue.htmlEntities("N&uacute;mero de telefone inv&aacute;lido");
                }
            ],
            shipping_type: "immediate",
            scheduled_date: dayjs().format("YYYY-MM-DD"),
            scheduled_time: dayjs().format("HH:mm"),
            show_dialog_configuration: false,
            current_file_config_id: {},
            modal_send_message: false,
            show_dialog_manual: false,
            show_dialog_confirmation: false,
            type_product: this.$cookie === undefined ? getCookie("type_product") : this.$cookie.get("type_product"),
            valid_phones: 0,
            loading_confirm: false,
            progress_send: 0,
            show_single_confirmation_dialog: false,
            show_finish_dialog: false,
            show_error_dialog: false,
            confirm_click_counter: 0,
            template_type: "",
            msg_error: "",
            campaign_id: null,
            base_service: new BaseService(),
            steps: [
                {
                    target: "[data-v-step='1']",
                    title: "Escolha da carteira",
                    content: "As carteiras (clientes) s&atilde;o vinculadas ao canal escolhido e cada carteira pode ter as pr&oacute;prias configura&ccedil;&otilde;es.",
                    date: "2023-01-30 00:00:00"
                },
                {
                    target: "[data-v-step='2']",
                    title: "Regras de agendamento",
                    content: "<p>Ao cadastrar uma carteira, h&aacute; a possibilidade de definir regras de hor&aacute;rio para agendamento e cria&ccedil;&atilde;o de campanhas. Tamb&eacute;m h&aacute; a possibilidade de criar hor&aacute;rios permitidos para envio de mensagem por DDD.</p><p>Estas informa&ccedil;&otilde;es ser&atilde;o exibidas aqui, para que voc&ecirc; monte a melhor estrat&eacute;gia de envio da sua campanha.</p>",
                    before: () => {
                        this.client_id = this.client_list[0].value;
                    }
                },
                {
                    target: "[data-v-step='3']",
                    title: "Escolha a template",
                    content: "A template &eacute; o modelo da mensagem que ser&aacute; enviada na campanha. Para visualizar as templates dispon&iacute;veis e fazer a escolha, utilize o <b>Manual de Templates</b>.",
                    before: () => {
                        this.template_id = this.template_list_autocomplete[0].value;
                    }
                },
                {
                    target: "[data-v-step='4']",
                    title: "Importa&ccedil;&atilde;o de arquivos",
                    content: "<p>Voc&ecirc; pode fazer upload de um ou mais arquivos do tipo .csv</p><p>Ap&oacute;s o upload ser&aacute; necess&aacute;rio configurar o arquivo, ou seja, definir quais colunas do seu arquivo ser&atilde;o consumidas para os dados de envio e para a template da mensagem.</p>",
                    before: () => {
                        this.listFiles = [{ "id": "882qmhu2k1kp961gvcrq1675095995405", "file_name": "arquivo_exemplo.txt", "size": 829, "type": "text/plain", "style": { "icon": "mdi-check-bold", "background": "gray-0", "border": "success-500" }, "status": "success", "total_registers": 5, "file_content": [{ "row_id": "0", "coluna0": "41997463830", "coluna1": "Campo Informado", "coluna2": "TALL", "coluna3": "Uma descri&ccedil;&atilde;o qualquer", "coluna4": "Nome", "coluna5": "Algum t&iacute;tulo aqui", "coluna6": "https://fisioanimal.com/blog/wp-content/uploads/2019/06/Filhote-Cachorro-900x628.jpg" }, { "row_id": "1", "coluna0": "41997834342", "coluna1": "Campo Informado", "coluna2": "TALL", "coluna3": "Uma descri&ccedil;&atilde;o qualquer", "coluna4": "Nome", "coluna5": "Algum t&iacute;tulo aqui", "coluna6": "https://fisioanimal.com/blog/wp-content/uploads/2019/06/Filhote-Cachorro-900x628.jpg" }, { "row_id": "2", "coluna0": "", "coluna1": "Campo Informado", "coluna2": "TALL", "coluna3": "Uma descri&ccedil;&atilde;o qualquer", "coluna4": "Nome", "coluna5": "Algum t&iacute;tulo aqui", "coluna6": "https://gmconline.com.br/wp-content/uploads/2020/06/e6234d30f882543f3fdaadc769d4affb.jpg" }, { "row_id": "3", "coluna0": "41997463830", "coluna1": "Campo Informado", "coluna2": "TALL", "coluna3": "Uma descri&ccedil;&atilde;o qualquer", "coluna4": "Nome", "coluna5": "Algum t&iacute;tulo aqui", "coluna6": "https://gmconline.com.br/wp-content/uploads/2020/06/e6234d30f882543f3fdaadc769d4affb.jpg" }, { "row_id": "4", "coluna0": "", "coluna1": "Campo Informado", "coluna2": "TALL", "coluna3": "Uma descri&ccedil;&atilde;o qualquer", "coluna4": "Nome", "coluna5": "Algum t&iacute;tulo aqui", "coluna6": "https://gmconline.com.br/wp-content/uploads/2020/06/e6234d30f882543f3fdaadc769d4affb.jpg" }], "file_configuration": { "phone": "coluna0", "client_data": "" } }];
                    }
                },
                {
                    target: "[data-v-step='5']",
                    title: "Valida&ccedil;&atilde;o dos n&uacute;meros",
                    content: "A valida&ccedil;&atilde;o dos n&uacute;meros de telefone s&oacute; ser&aacute; realizada ap&oacute;s a configura&ccedil;&atilde;o do arquivo, j&aacute; que &eacute; necess&aacute;rio voc&ecirc; apontar em qual coluna do seu arquivo est&atilde;o os n&uacute;meros de telefone."
                },
                {
                    target: "[data-v-step='6']",
                    title: "Resultados da higieniza&ccedil;&atilde;o",
                    content: "<p>A higieniza&ccedil;&atilde;o dos registros &eacute; um servi&ccedil;o da PG Mais que evita gastos desnecess&aacute;rios e problemas com acionamentos excessivos.</p><p>Este servi&ccedil;o remove da sua campanha os registros em que o n&uacute;mero de telefone informado:</p><ul><li>Esteja cadastrado na blacklist</li><li>Seja um formato inv&aacute;lido (n&uacute;mero de d&iacute;gitos incorreto, sem DDD, entre outros)</li><li>Esteja repetido em mais de uma linha do arquivo (salvo casos em que as configura&ccedil;&otilde;es da carteira permitam n&uacute;meros repetidos)</li></ul>"
                },
                {
                    target: "[data-v-step='7']",
                    title: "Exemplo de envio",
                    content: "Ap&oacute;s a configura&ccedil;&atilde;o do arquivo, ser&aacute; exibido um exemplo de como ser&aacute; enviada a mensagem, utilizando os dados do primeiro arquivo que foi adicionado e configurado."
                },
                {
                    target: "[data-v-step='8']",
                    title: "Pr&eacute;via da mensagem",
                    content: "Voc&ecirc; pode definir um ou mais telefones para enviar uma mensagem de teste. Estes envios ser&atilde;o contabilizados como envios avulsos."
                },
                {
                    target: "[data-v-step='9']",
                    title: "Agendamento",
                    content: "Voc&ecirc; pode escolher entre disparar imediatamente (ou no pr&oacute;ximo hor&aacute;rio dispon&iacute;vel para disparo, cadastrado na carteira) ou dividir em lotes e agendar os disparos para datas e hor&aacute;rios espec&iacute;ficos."
                },
                {
                    target: "[data-v-step='10']",
                    title: "Criar campanha",
                    content: "Ao clicar no bot&atilde;o <b>criar campanha</b>, voc&ecirc; ainda ter&aacute; uma janela de confirma&ccedil;&atilde;o dos dados que permite voltar caso note algo de errado."
                },
                {
                    target: "[data-v-step='11']",
                    title: "Limpar tudo",
                    content: "<p>Percebeu coisas erradas no preenchimento do formul&aacute;rio?</p><p>Calma, n&atilde;o clique ainda para limpar tudo!</p><ul><li>&Eacute; poss&iacute;vel excluir e adicionar arquivos novamente, sem jogar fora as demais configura&ccedil;&otilde;es.</li><li>&Eacute; poss&iacute;vel tamb&eacute;m alterar a template sem jogar fora os arquivos, e revisar se a configura&ccedil;&atilde;o permanece correta.</li><li>Tamb&eacute;m &eacute; poss&iacute;vel trocar de carteira, e, caso a template esteja dispon&iacute;vel para a nova carteira voc&ecirc; n&atilde;o perde a escolha nem a configura&ccedil;&atilde;o. O que pode ser alterado &eacute; a data e hor&aacute;rio do agendamento caso n&atilde;o seja permitido para a nova carteira.</li></ul><p>Mesmo assim ainda quer come&ccedil;ar do zero? Tudo bem, &eacute; s&oacute; clicar em limpar tudo.</p>"
                }
            ],
            show_stagger: false,
            lotes: [],
            total_messages: 0,
            blacklist_phones: 0,
            invalid_phones: 0,
            repeated_phones: 0,
            basic_send: 0,
            single_send: 0,
            show_missing_info_message: false
        };
    },
    computed: {
        listFiles: {
            get() {
                return this.$store.state.campaign.list_files;
            },
            set(new_value) {
                this.$store.commit("campaign/" + types.SET_LIST_FILES, new_value);
            }
        },
        allFilesOk() {
            let ok = true;

            for (let file of this.listFiles) {
                if (file.status !== "success") {
                    ok = false;
                }
            }

            if (this.listFiles.length === 0) {
                ok = false;
            }

            return ok;
        },
        templateContent() {
            let template = null;

            if (this.template_id) {
                template = {
                    type: "",
                    message: "",
                    list_suggestions: [],
                    image: "",
                    card: new CardRcsClass({}),
                    list_card: []
                };

                let ds_template = this.template_list.data.find(content => content.ID_TEMPLATE_APPS_TAES === this.template_id).DS_TEMPLATE;

                if (this.allFilesOk) {
                    for (let key in this.listFiles[0]?.file_configuration) {
                        if (["phone", "client_data"].indexOf(key) === -1) {
                            ds_template = ds_template.replace(
                                new RegExp("\\{" + this.escapeRegExp(key) + "\\}", "gm"),
                                this.listFiles[0].file_content[0][this.listFiles[0]?.file_configuration[key]]
                            );
                        }
                    }
                }

                try {
                    ds_template = JSON.parse(ds_template);
                } catch (error) {
                    ds_template = { text: ds_template };
                }

                if (ds_template.url !== undefined && ds_template.url !== "") {
                    template.type = "image";
                    template.image = ds_template.url[0] === "{" && ds_template.url[ds_template.url.length - 1] === "}" ? "variable" : ds_template.url;
                } else if (ds_template.text !== undefined && ds_template.text !== "") {
                    template.type = "message";
                    template.message = this.template_utils.formatMessageList(ds_template.text);
                    template.list_suggestions = [];

                    if (ds_template.suggestions !== undefined && Array.isArray(ds_template.suggestions)) {
                        for (let suggestion of ds_template.suggestions) {
                            let pill = new BasePillClass({ title: suggestion.label });

                            switch (suggestion.type) {
                            case "url":
                                pill.titleIcon = "fa-globe-americas";
                                pill.type = "link";
                                break;
                            case "dial_phone":
                                pill.titleIcon = "fa-phone-alt";
                                pill.type = "phone";
                                break;
                            case "show_location":
                                pill.titleIcon = "fa-map-marker-alt";
                                pill.type = "map";
                                break;
                            case "request_location":
                                pill.titleIcon = "fa-crosshairs";
                                pill.type = "localization";
                                break;
                            }

                            template.list_suggestions.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                        }
                    }
                } else if (ds_template.contents !== undefined && !Array.isArray(ds_template.contents)) {
                    template.type = "card";
                    let buttons = [];
                    let responses = [];

                    if (ds_template.contents.suggestions !== undefined && Array.isArray(ds_template.contents.suggestions)) {
                        for (let index in ds_template.contents.suggestions) {
                            let pill = new BasePillClass({ title: ds_template.contents.suggestions[index].label });

                            switch (ds_template.contents.suggestions[index].type) {
                            case "url":
                                pill.titleIcon = "fa-globe-americas";
                                pill.type = "link";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "dial_phone":
                                pill.titleIcon = "fa-phone-alt";
                                pill.type = "phone";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "show_location":
                                pill.titleIcon = "fa-map-marker-alt";
                                pill.type = "map";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "request_location":
                                pill.titleIcon = "fa-crosshairs";
                                pill.type = "localization";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "text":
                                responses.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            }
                        }
                    }

                    template.card = new CardRcsClass({
                        image: ds_template.contents.media !== undefined ? (ds_template.contents.media.file.url[0] === "{" && ds_template.contents.media.file.url[ds_template.contents.media.file.url.length - 1] === "}" ? "variable" : ds_template.contents.media.file.url) : null,
                        title: this.template_utils.formatMessageList(ds_template.contents.title),
                        description: this.template_utils.formatMessageList(ds_template.contents.description),
                        suggestionButtons: buttons,
                        suggestionResponses: responses,
                        orientation: ds_template.orientation,
                        alignment: ds_template.alignment,
                        image_height: ds_template.contents.media?.height ?? "TALL"
                    });
                } else if (ds_template.contents !== undefined && Array.isArray(ds_template.contents)) {
                    template.type = "carousel";
                    template.list_card = [];

                    for (let card of ds_template.contents) {
                        let buttons = [];
                        let responses = [];

                        if (card.suggestions !== undefined && Array.isArray(card.suggestions)) {
                            for (let suggestion of card.suggestions) {
                                let pill = new BasePillClass({ title: suggestion.label, subTitle: suggestion.value ?? null});
                                // pill.subTitle = suggestion.value ?? "";

                                switch (suggestion.type) {
                                case "url":
                                    pill.titleIcon = "fa-globe-americas";
                                    pill.type = "link";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "dial_phone":
                                    pill.titleIcon = "fa-phone-alt";
                                    pill.type = "phone";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "show_location":
                                    pill.titleIcon = "fa-map-marker-alt";
                                    pill.type = "map";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "request_location":
                                    pill.titleIcon = "fa-crosshairs";
                                    pill.type = "localization";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "text":
                                    responses.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                }
                            }
                        }

                        template.list_card.push(new CardRcsClass({
                            image: card.media !== undefined ? (card.media.file.url[0] === "{" && card.media.file.url[card.media.file.url.length - 1] === "}" ? "variable" : card.media.file.url) : null,
                            title: this.template_utils.formatMessageList(card.title),
                            media: card.media.file,
                            description: this.template_utils.formatMessageList(card.description),
                            suggestionButtons: buttons,
                            suggestionResponses: responses,
                            image_height: card.media?.height ?? "TALL"
                        }));
                    }
                }
            }

            return template;
        },
        templateSelected() {
            if (this.template_id) {
                return this.template_list.data.find(content => content.ID_TEMPLATE_APPS_TAES === this.template_id);
            }

            return {};
        },
        listPhones() {
            let phones = [];

            if (this.allFilesOk) {
                for (let file of this.listFiles) {
                    for (let row of file.file_content) {
                        phones.push(row[file.file_configuration.phone].replace(/(\r\n|\n|\r)/gm, ""));
                    }
                }
            }

            return phones;
        },
        dataTest() {
            let campos = {};

            if (this.allFilesOk) {
                for (let campo of Object.keys(this.listFiles[0].file_configuration)) {
                    if (campo !== "client_data" && campo !== "phone") {
                        campos[campo] = this.listFiles[0].file_content[0][this.listFiles[0].file_configuration[campo]];
                    }
                }
            }

            return {
                tipo: "template",
                id_template: this.template_id,
                campos: campos
            };
        },
        campaignName() {
            if (this.allFilesOk) {
                return this.listFiles[0].file_name.replace(/\.\w+$/, "");
            }

            return "campanha-" + dayjs().format("YYYYMMDDHHmm");
        },
        schedulingDescription() {
            let lots_description = "";
            switch (this.shipping_type) {
            case "immediate":
                return "Disparo imediato";
            case "scheduled":
                return dayjs(this.scheduled_date).format("DD/MM/YYYY") + " &agrave;s " + this.scheduled_time;
            case "lots":
                for (let lot_key in this.lotes) {
                    if (typeof this.lotes[lot_key] !== "function") {
                        const lot = this.lotes[lot_key];
                        const { quantity, startTime, endTime, startDateScheduling } = lot;

                        lots_description += `Lote ${Number(lot_key) + 1} -- ${quantity} registros - `;

                        if (startTime && endTime) {
                            lots_description += `${dayjs(startDateScheduling).format("DD/MM/YYYY")} das ${startTime} &agrave;s ${endTime}`;
                        } else if (startTime) {
                            lots_description += `${dayjs(startDateScheduling).format("DD/MM/YYYY")} &agrave;s ${startTime}`;
                        } else {
                            lots_description += "Envio imediato";
                        }

                        lots_description += "<br>";
                    }
                }
                return lots_description;
            }

            return "";
        },
        lockCampaignCreationConfig() {
            let config = {
                activated: false,
                start: null,
                type: null
            };

            if (this.client_info?.hr_bloqueio_agendamento && this.client_info.hr_bloqueio_agendamento?.ST_ATIVO_BADES === "S") {
                config.activated = true;
                config.start = dayjs("2023-01-01 " + this.client_info.hr_bloqueio_agendamento.HORA_INICIAL_BADES).format("HH:mm");
                config.type = "client";
            } else if (this.client_info?.company?.hr_bloqueio_agendamento && this.client_info.company.hr_bloqueio_agendamento?.ST_ATIVO_BADES === "S") {
                config.activated = true;
                config.start = dayjs("2023-01-01 " + this.client_info.company.hr_bloqueio_agendamento.HORA_INICIAL_BADES).format("HH:mm");
                config.type = "company";
            }

            return config;
        },
        dateSchedule() {
            let now = dayjs();
            let rule_start_time = null;
            let rule_end_time = null;
            let result = false;

            if (this.client_info) {
                do {
                    result = false;

                    // Se for domingo transforma em segunda
                    if (now.day() === 0) {
                        now.add(1, "day");
                    }

                    if (now.day() === 6) {
                        rule_start_time = dayjs(this.client_info.HR_SABADO_INI_EPES || "00:00", "HH:mm");
                        rule_end_time = dayjs(this.client_info.HR_SABADO_FIM_EPES || "23:59", "HH:mm");
                    } else {
                        rule_start_time = dayjs(this.client_info.HR_SEMANA_INI_EPES || "00:00", "HH:mm");
                        rule_end_time = dayjs(this.client_info.HR_SEMANA_FIM_EPES || "23:59", "HH:mm");
                    }

                    if (now.format("HH:mm") < rule_start_time.format("HH:mm")) {
                        now = now.hour(rule_start_time.hour());
                        now = now.minute(rule_start_time.minute());
                        result = true;
                    } else if (now.format("HH:mm") > rule_end_time.format("HH:mm")) {
                        now = now.add(1, "day").startOf("day");
                    } else {
                        result = true;
                    }
                } while (!result);
            }

            return now;
        },
        minDateSchedule() {
            return this.dateSchedule.format("YYYY-MM-DD");
        },
        minTimeSchedule() {
            if (dayjs(this.scheduled_date).isSame(dayjs(), "day")) {
                return this.dateSchedule.format("HH:mm");
            } else {
                if (dayjs(this.scheduled_date).day() === 6) {
                    return dayjs(this.client_info.HR_SABADO_INI_EPES || "00:00", "HH:mm").format("HH:mm");
                } else {
                    return dayjs(this.client_info.HR_SEMANA_INI_EPES || "00:00", "HH:mm").format("HH:mm");
                }
            }
        },
        maxTimeSchedule() {
            if (dayjs(this.scheduled_date).day() === 6) {
                return this.client_info.HR_SABADO_FIM_EPES || "23:59";
            } else {
                return this.client_info.HR_SEMANA_FIM_EPES || "23:59";
            }
        },
        disabledImmediateShipping() {
            return !(this.dateSchedule.isSame(dayjs(), "minute"));
        },
        currentLayout() {
            if (Array.isArray(this.layout_list) && this.layout_id) {
                return this.layout_list.find(layout => layout.ID_LAYOUT_LYES === this.layout_id) || {};
            }
            return {};
        }
    },
    watch: {
        async client_id() {
            this.clearTemplates();
            this.showMissingInfoMessage(false);

            if (this.client_id) {
                await this.getClientInfo();

                if (!this.shouldFetchTemplates()) {
                    this.showMissingInfoMessage(true);
                    return;
                }

                this.getTemplates();
            }
        },
        template_id() {
            this.$store.commit("campaign/" + types.CLEAR_ALL_CONFIG_FILES);
        },
        shipping_type() {
            this.scheduled_time = dayjs().format("HH:mm");
        },
        disabledImmediateShipping() {
            if (this.disabledImmediateShipping) {
                this.shipping_type = "scheduled";
            }
        },
        minDateSchedule() {
            if (dayjs().isBefore(this.dateSchedule, "minute")) {
                this.scheduled_date = this.dateSchedule.format("YYYY-MM-DD");
            } else {
                this.scheduled_date = dayjs().format("YYYY-MM-DD");
            }
        },
        minTimeSchedule() {
            if (dayjs().isBefore(this.dateSchedule, "minute")) {
                this.scheduled_time = this.dateSchedule.format("HH:mm");
            } else {
                this.scheduled_time = dayjs().format("HH:mm");
            }
        },
        listFiles() {
            this.openModalFileConfig();
        }
    },
    mounted() {
        this.getClients();
        this.getLayouts();
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetMyClients", "ActionDoGetClientOrCompanyInfo",
            "ActionDoGetTemplateList", "ActionDoGetLayoutList"]),
        ...window.Vuex.mapActions("campaign", ["ActionDoCreateCampaign"]),
        /**
         * Busca a lista de carteiras para criacao de campanhas
         * @vue
         */
        getClients() {
            this.ActionDoGetMyClients(true).then(response => {
                this.client_list = response;
                this.loading = false;
            });
        },
        /**
         * Busca as informacoes de uma carteira selecionada
         * @vue
         */
        async getClientInfo() {
            this.time_rules_loading = true;
            this.time_rules_description = null;
            this.client_info = null;
            try {
                this.client_info = await this.ActionDoGetClientOrCompanyInfo(this.client_id);
            } finally {
                this.time_rules_loading = false;
            }
        },
        /**
         * Limpa a lista de templates e o template selecionado se houver
         * @vue
         */
        clearTemplates() {
            this.template_list = [];
            this.template_list_autocomplete = [];
            this.template_id = null;
        },
        /**
         * Busca a lista de templates conforme a carteira selecionada
         * @vue
         */
        getTemplates() {
            this.template_loading = true;
            this.ActionDoGetTemplateList({
                cliente: [this.client_id],
                status: "A",
                order: [
                    {
                        column: 0,
                        dir: "ASC"
                    }
                ],
                columns: [
                    {
                        data: "CD_TEMPLATE_TAES",
                        name: "",
                        searchable: true,
                        orderable: true
                    }
                ],
            }).then(response => {
                this.template_list = response;
                let templates = [];

                for (let template of response.data) {
                    templates.push({
                        text: template.CD_TEMPLATE,
                        value: template.ID_TEMPLATE_APPS_TAES
                    });
                }

                this.template_list_autocomplete = templates;
                this.template_loading = false;
            });
        },
        /**
         * Busca a lista de layouts RCS da empresa pai se houver
         */
        getLayouts() {
            this.layout_loading = true;

            this.ActionDoGetLayoutList({
                "id_empresa": this.id_empresa
            }).then(response => {
                this.layout_list = response;

                let layouts = [{
                    text: Vue.htmlEntities("Delimitador padr&atilde;o"),
                    value: 1
                }];
                for (let layout of response) {
                    layouts.push({
                        text: layout.DS_LAYOUT_LYES,
                        value: layout.ID_LAYOUT_LYES
                    });
                }

                this.layout_list_autocomplete = layouts;
                this.layout_id = 1;
                this.layout_loading = false;
            });
        },
        /**
         * Exibe o dialog de preview de template
         * @param {String} content_preview JSON no formato string
         * @vue
         */
        showDialogPreview(content_preview) {
            this.ds_template = content_preview;
            this.show_dialog_preview = true;
        },
        /**
         * Abre o modal para configurar um arquivo
         * @param {Object} file Dados de um arquivo
         * @vue
         */
        openModalFileConfig(file_id) {
            if (file_id && file_id !== undefined) {
                this.current_file_config_id = file_id;
                this.show_dialog_configuration = true;
            } else {
                for (let file of this.listFiles) {
                    if (file.status === "configuration") {
                        this.current_file_config_id = file.id;
                        this.show_dialog_configuration = true;
                        break;
                    }
                }
            }
        },
        /**
         * Fecha o modal de configuracao de arquivo com e atualiza os dados
         * @param {Object} data Dados de um arquivo configurado
         * @vue
         */
        saveConfiguration() {
            this.show_dialog_configuration = false;
            this.current_file_config_id = null;
        },
        /**
         * Define o template atraves do modal de manual
         * @vue
         */
        setTemplate(template) {
            this.template_id = template.ID_TEMPLATE;
        },
        /**
         * Limpa todos os dados
         * @vue
         */
        clearData() {
            this.client_id = null;
            this.listFiles = [];
            this.shipping_type = "immediate";
            this.progress_send = 0;
            this.lotes = [];
            this.total_messages = 0;
            this.blacklist_phones = 0;
            this.valid_phones = 0;
            this.invalid_phones = 0;
            this.repeated_phones = 0;
            this.basic_send = 0;
            this.single_send = 0;
            this.layout_id = 1;
            this.showMissingInfoMessage(false);
        },
        /**
         * Abre o modal de confirmacao da criacao de campanha
         * @vue
         */
        confirm() {

            this.blacklist_phones = 0;
            this.valid_phones = 0;
            this.invalid_phones = 0;
            this.repeated_phones = 0;
            this.basic_send = 0;
            this.single_send = 0;
            this.progress_send = 0;

            if (this.shipping_type === "lots") {
                this.show_stagger = true;
            } else {
                this.show_dialog_confirmation = true;
            }
        },
        /**
         * Define as datas disponiveis no calendario
         * @vue
         */
        allowedDates(date) {
            return dayjs(date).day() !== 0;
        },
        /**
         * Cria lotes de envio conforme o tamanho definido
         * @param {Array} array
         */
        chunk(array, size) {
            return Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
                array.slice(i * size, i * size + size)
            );
        },
        createCampaign() {
            this.loading_confirm = true;

            if (this.single_send && this.template_type === "M") {
                this.show_single_confirmation_dialog = false;
                return this.finishingCampaign();
            }

            performance.mark("createCampaign_start");
            // Parte 1/3 da criacao de campanha
            this.ActionDoCreateCampaign({
                client_id: this.client_id,
                template_id: this.template_id,
                campaign_name: this.campaignName
            }).then(campaign_created => {
                performance.mark("campaignCreated");

                let fieldMappingsSet = new Set(["numero_telefone", "dado_cliente"]);
                const createConfiguration = (fileConfig, row) => {
                    let configuration = [
                        row[fileConfig.phone],
                        fileConfig.client_data ? row[fileConfig.client_data] : ""
                    ];

                    for (let config_key in fileConfig) {
                        if (config_key !== "phone" && config_key !== "client_data") {
                            if (!fieldMappingsSet.has(config_key)) {
                                fieldMappingsSet.add(config_key);
                            }
                            configuration.push(row[fileConfig[config_key]]);
                        }
                    }
                    return configuration;
                };

                let campaign_id = campaign_created.return.id;
                this.campaign_id = campaign_id;
                let messages = [];

                // Obtem os dados para do arquivo para envio
                for (let file of this.listFiles) {
                    const fileConfig = file.file_configuration; // Cache do objeto de configura��o
                    for (let row of file.file_content) {
                        messages.push(createConfiguration(fileConfig, row));
                    }
                }

                return this.sendRequests(campaign_id, messages, Array.from(fieldMappingsSet));
            }).catch(error => {
                this.show_dialog_confirmation = false;
                this.loading_confirm = false;
                this.show_finish_dialog = false;
                this.show_single_confirmation_dialog = false;
                this.msg_error = Vue.htmlEntities("Ocorreu um erro ao criar a campanha");
                throw error;
            });
        },
        goToDashboard() {
            window.location.href = this.base_service.baseDomainPhp() + "/Dashboard";
        },
        saveLots(lots) {
            this.lotes = lots;
            this.show_stagger = false;
            this.show_dialog_confirmation = true;
        },
        async sendRequests(campaign_id, messages, fieldMappings) {
            performance.mark("sendRequests_start");

            // Cria lotes com size_slot de mensagens para enviar de forma fracionada para o servidor
            const size_slot = 10000; // Quantidade de mensagens por slot
            const message_batches = this.chunk(messages, size_slot); // Fraciona array messages no tamanho de size_slot
            const totalMessages = messages.length; // Quantidade total de mensagens
            const concurrentRequests = Math.min(5, Math.ceil(totalMessages / size_slot)); // N�mero de requisi��es m�xima
            let sent = 0; // Contador de slots que foram enviadas com sucesso

            // Parte 2/3 da criacao de campanha
            const sendBatch = async (batch, index, fieldMappings) => {
                const payload = {
                    client_id: this.client_id,
                    campaign_id: campaign_id,
                    template_id: this.template_id,
                    type: "add_message",
                    messages: batch,
                    fieldMappings: fieldMappings
                }; // Cria o objeto payload com o lote correspondente ao �ndice atual

                const batchStartMark = `sendBatch_start_${index}`;
                const batchEndMark = `sendBatch_end_${index}`;
                performance.mark(batchStartMark);

                try {
                    const response = await this.ActionDoCreateCampaign(payload);

                    if (!response.status || response.status !== true) {
                        throw new Error(`Erro no lote ${index}: ${response.message || "Erro desconhecido"}`);
                    }

                    performance.mark(batchEndMark);
                    performance.measure(`Send Batch ${index}`, batchStartMark, batchEndMark);

                    if (response.return && response.return.resume) {
                        const { blacklist, imported, invalid, repeated, basic, single } = response.return.resume;
                        this.blacklist_phones += blacklist;
                        this.valid_phones += imported;
                        this.invalid_phones += invalid;
                        this.repeated_phones += repeated;
                        this.basic_send += basic;
                        this.single_send += single;
                    }
                    this.template_type = response.template_type;

                    sent += batch.length; // Agora incrementamos pelo tamanho do lote
                    this.progress_send = (sent * 100) / totalMessages; // Atualiza a porcentagem de progresso

                    this.show_dialog_confirmation = true;
                    this.loading_confirm = false;
                    this.show_finish_dialog = false;

                } catch (error) {
                    performance.mark(batchEndMark);
                    performance.measure(`Send Batch ${index} (Error)`, batchStartMark, batchEndMark);

                    this.show_single_confirmation_dialog = false;
                    this.show_dialog_confirmation = false;
                    this.loading_confirm = false;
                    this.show_finish_dialog = false;

                    console.log("error_sendRequests", error);
                    if (error.response?.status == 413) { // Request Entity Too Large (413)
                        this.msg_error = Vue.htmlEntities("A quantidade de registros inseridos excede o limite suportado.");
                        this.show_error_dialog = true;
                    } else { // Outros erros
                        this.msg_error = Vue.htmlEntities("Ocorreu um erro ao criar a campanha");
                    }
                    throw error;
                }
            };

            // Cria uma fila para lidar com as solicita��es concorrentes
            let requestQueue = []; // Fila de requests
            let batchIndex = 0;
            for (let batch of message_batches) {
                // Cria uma nova promessa para cada lote e adiciona � fila de solicita��es
                const requestPromise = sendBatch(batch, batchIndex, fieldMappings).finally(() => {
                    // Remove a solicita��o conclu�da da fila
                    requestQueue = requestQueue.filter(p => p !== requestPromise);
                });

                requestQueue.push(requestPromise);

                // Se atingirmos o n�mero m�ximo de solicita��es concorrentes, esperamos a primeira solicita��o ser conclu�da antes de prosseguir
                if (requestQueue.length >= concurrentRequests) {
                    await Promise.race(requestQueue);
                }

                batchIndex++;
            }

            // Espera todas as solicita��es restantes na fila serem conclu�das
            await Promise.all(requestQueue);

            performance.mark("sendRequests_end");
            performance.mark("createCampaign_end");

            if (this.single_send > 0 && this.template_type === "M") {
                this.show_single_confirmation_dialog = true;
            } else {
                this.loading_confirm = true;
                this.finishingCampaign();
            }
        },

        async finishingCampaign () {
            // Realiza a finaliza��o da cria��o de campanha
            const now = dayjs().format("YYYY-MM-DD HH:mm:ss");

            let scheduled_dt = null;
            let lots = [];

            switch (this.shipping_type) {
            case "immediate":
                lots.push({
                    quantidade: this.valid_phones,
                    quantidade_maxima: null,
                    dt_inicio: now,
                    dt_fim: now
                });
                break;
            case "scheduled":
                if (this.scheduled_date && this.scheduled_time) {
                    scheduled_dt = dayjs(this.scheduled_date + " " + this.scheduled_time).format("YYYY-MM-DD HH:mm:ss");
                } else {
                    scheduled_dt = now; // Utiliza o valor atual caso a data ou hora n�o estejam definidas
                }
                lots.push({
                    quantidade: this.valid_phones,
                    quantidade_maxima: null,
                    dt_inicio: scheduled_dt,
                    dt_fim: scheduled_dt
                });
                break;
            case "lots":
                for (const lot of Object.values(this.lotes)) {
                    const { startTime = "", endTime = "", startDateScheduling = "" } = lot;

                    const dt_inicio = startDateScheduling ? dayjs(startDateScheduling + " " + startTime).format("YYYY-MM-DD HH:mm:ss") : now;
                    const dt_fim = startTime && !endTime ? dt_inicio : endTime ? dayjs(startDateScheduling + " " + endTime).format("YYYY-MM-DD HH:mm:ss") : now;

                    lots.push({
                        quantidade: lot.quantity,
                        quantidade_maxima: null,
                        dt_inicio,
                        dt_fim
                    });
                }
                break;
            default:
                break;
            }

            // Parte 3/3 da criacao de campanha
            try {
                await this.ActionDoCreateCampaign({
                    client_id: this.client_id,
                    campaign_id: this.campaign_id,
                    type: "finish",
                    lotes: lots
                });

                performance.mark("createCampaign_ended");

                // Medi��es de desempenho
                performance.measure("Create Campaign - Part 1", "createCampaign_start", "campaignCreated");
                performance.measure("Send Requests", "sendRequests_start", "sendRequests_end");
                performance.measure("Create Campaign - Part 3", "createCampaign_end", "createCampaign_ended");
                performance.measure("Total Create Campaign Time", "createCampaign_start", "createCampaign_ended");

                // Log das medi��es
                const measures = performance.getEntriesByType("measure");
                measures.forEach(measure => console.log(`${measure.name}: ${(measure.duration / 1000).toFixed(2)} s`));

                this.show_dialog_confirmation = false;
                this.loading_confirm = false;
                this.show_single_confirmation_dialog = false;
                this.show_finish_dialog = true;
            } catch (error) {
                this.show_dialog_confirmation = false;
                this.loading_confirm = false;
                this.show_single_confirmation_dialog = false;
                this.show_finish_dialog = false;
                this.msg_error = Vue.htmlEntities("Ocorreu um erro ao criar a campanha");
                throw error;
            }
        },
        totalImported(total) {
            this.total_messages = total;
        },
        /**
         * Verifica se a carteira tem os v�nculos necess�rios para enviar RCS
         * @returns {Boolean}
         * @vue
         */
        shouldFetchTemplates() {
            const rotasApps = this.client_info.rotas_apps;
            return rotasApps && rotasApps.ID_CLIENTE_RCS_RAES && rotasApps.ID_FORNECEDOR_API_RCS_RAES;
        },
        /**
         * Se a carteira n�o tiver v�nculo necess�rio para enviar RCS, exibe mensagem
         * @vue
         */
        showMissingInfoMessage(show) {
            this.show_missing_info_message = show;
        },
        /**
         * Fun��o para escapar caracteres especiais para regex
         * @vue
         */
        escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escapa caracteres especiais
        }

    },
    template:
        /*html*/
        `
            <v-container>
                <v-card v-if="!loading" elevation="0" width="100%">
                    <v-card-text>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <page-header
                                    tutorial
                                    title="Cria&ccedil;&atilde;o de campanha"
                                    description="Aqui voc&ecirc; pode criar novas campanhas para RCS"
                                    @tutorial="$refs.tour.start()"
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="gap-12">
                            <v-col cols="12" md="6" lg="4">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <base-section-form title="Cliente">
                                            <v-row no-gutters class="mt-6">
                                                <v-col cols="12" :data-v-step="1">
                                                    <base-autocomplete
                                                        v-model="client_id"
                                                        :items="client_list"
                                                        label="Escolha..."
                                                        required
                                                        use-icon-validation
                                                        title="Escolha o cliente (carteira)"
                                                        tooltip-text="Escolha o cliente (carteira) vinculado &agrave; nova campanha"
                                                    />
                                                </v-col>
                                                <v-col cols="12" class="mt-4" v-if="show_missing_info_message">
                                                    <base-information
                                                        description="Ol&aacute;! Parece que sua conta precisa de uma pequena configura&ccedil;&atilde;o
                                                        adicional para come&ccedil;ar a usar nossos servi&ccedil;os RCS. Por gentileza, contate nosso
                                                        suporte t&eacute;cnico para que possamos ajustar isso para voc&ecirc;. Este ajuste &eacute;
                                                        feito apenas uma vez e garantir&aacute; que tudo funcione perfeitamente em seguida.
                                                        Agradecemos sua compreens&atilde;o e estamos ansiosos para ajud&aacute;-lo!"
                                                        icon="mdi-alert-circle"
                                                        border-color="border-danger"
                                                        icon-color="danger"
                                                    ></base-information>
                                                </v-col>
                                                <v-col v-if="client_id && !show_missing_info_message" cols="12" class="mt-4" :data-v-step="2">
                                                    <time-rules
                                                        :week-start="client_info?.HR_SEMANA_INI_EPES"
                                                        :week-end="client_info?.HR_SEMANA_FIM_EPES"
                                                        :saturday-start="client_info?.HR_SABADO_INI_EPES"
                                                        :saturday-end="client_info?.HR_SABADO_FIM_EPES"
                                                        :lock-activated="lockCampaignCreationConfig.activated"
                                                        :lock-start="lockCampaignCreationConfig.start"
                                                        :lock-type="lockCampaignCreationConfig.type"
                                                        :ddd-rules="client_info?.ddd_rules"
                                                        :loading="time_rules_loading"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </base-section-form>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="mt-8">
                                    <v-col cols="12">
                                        <base-section-form title="Template">
                                            <v-row no-gutters class="mt-6 gap-4">
                                                <v-col :data-v-step="3">
                                                    <base-autocomplete
                                                        v-model="template_id"
                                                        :items="template_list_autocomplete"
                                                        label="Escolha..."
                                                        required
                                                        use-icon-validation
                                                        :disable="!client_id || !client_info || show_missing_info_message"
                                                        :loading="template_loading"
                                                        title="Escolha a template da mensagem"
                                                        tooltip-text="Escolha a template que ser&aacute; enviada para os numeros da campanha"
                                                    />
                                                </v-col>
                                                <v-col cols="auto" class="d-flex align-end">
                                                    <base-button
                                                        label="Manual de Templates"
                                                        :loading="template_loading"
                                                        secondary
                                                        block
                                                        :disabled="!client_id || !client_info || show_missing_info_message"
                                                        @click="show_dialog_manual = true"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </base-section-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6" lg="4">
                                <v-row v-if="layout_list.length > 0" no-gutters>
                                    <v-col cols="12">
                                        <base-section-form title="Tipo de Layout">
                                            <v-row no-gutters class="mt-6">
                                                <v-col cols="12">
                                                    <base-autocomplete
                                                        v-model="layout_id"
                                                        :items="layout_list_autocomplete"
                                                        label="Escolha..."
                                                        required
                                                        use-icon-validation
                                                        :disable="!client_id || !client_info || show_missing_info_message"
                                                        :loading="layout_loading"
                                                        title="Escolha o layout da mensagem"
                                                        tooltip-text="Escolha o layout da campanha"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </base-section-form>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters :class="{ 'mt-8': layout_list.length > 0 }">
                                    <v-col cols="12">
                                        <base-section-form title="Importa&ccedil;&atilde;o de arquivos">
                                            <v-row no-gutters class="mt-6">
                                                <v-col cols="12" :data-v-step="4">
                                                    <file-import 
                                                        :disabled="!template_id || !layout_id"
                                                        :selected-layout="currentLayout"
                                                        @openModalConfigurations="openModalFileConfig"
                                                        @totalImported="totalImported" />
                                                </v-col>
                                            </v-row>
                                        </base-section-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6" lg="4">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <base-section-form title="Mensagem">
                                            <v-row no-gutters class="mt-6" :data-v-step="7">
                                                <v-col v-if="templateContent" cols="12">
                                                    <preview-template
                                                        :type="templateContent.type"
                                                        :message="templateContent.message"
                                                        :list-suggestions="templateContent.list_suggestions"
                                                        :image="templateContent.image"
                                                        :card="templateContent.card"
                                                        :list-card="templateContent.list_card"
                                                        :height="200"
                                                        @showDialogPreview="showDialogPreview"
                                                    />
                                                </v-col>
                                                <v-col v-else cols="12">
                                                    <v-card
                                                        elevation="0"
                                                        height="200"
                                                        width="100%"
                                                        class="bg-gray-30 border-gray-300 border-1 rounded-1 px-4 pt-4"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-6">
                                                <v-col cols="12" :data-v-step="8">
                                                    <base-button
                                                        label="Enviar uma pr&eacute;via"
                                                        secondary
                                                        :disabled="!allFilesOk"
                                                        @click="modal_send_message = true"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-6">
                                                <v-col cols="12" :data-v-step="9">
                                                    <v-radio-group
                                                        v-model="shipping_type"
                                                        row
                                                        class="mt-0"
                                                        :disabled="!allFilesOk"
                                                    >
                                                        <v-row no-gutters class="gap-4">
                                                            <v-col cols="12">
                                                                <base-tooltip text="Sua campanha ser&aacute; enviada imediatamente, ou, no pr&oacute;ximo hor&aacute;rio poss&iacute;vel dentro das regras definidas para a carteira.">
                                                                    <v-radio :disabled="disabledImmediateShipping" label="Disparo imediato" color="primary-blue-500" value="immediate" id="disparoImediato" />
                                                                </base-tooltip>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <base-tooltip text="Voc&ecirc; pode agendar a sua campanha para uma data espec&iacute;fica.">
                                                                    <v-radio label="Disparo agendado" color="primary-blue-500" value="scheduled" id="disparoAgendado" />
                                                                </base-tooltip>
                                                                <v-row v-if="shipping_type === 'scheduled'" no-gutters class="mt-2 gap-4">
                                                                    <v-col>
                                                                        <base-date-picker v-model="scheduled_date" :min-date="minDateSchedule" :allowed-dates="allowedDates" id="dataAgendado" />
                                                                    </v-col>
                                                                    <v-col cols="auto" class="d-flex align-center">
                                                                        <span class="body-2 text-gray-900">&agrave;s</span>
                                                                    </v-col>
                                                                    <v-col>
                                                                        <base-time-picker v-model="scheduled_time" :min-time="minTimeSchedule" :max-time="maxTimeSchedule" id="horaAgendado" />
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <base-tooltip text="Voc&ecirc; pode separar em lotes definir datas e estrat&eacute;gias espec&iacute;ficas para cada um deles.">
                                                                    <v-radio label="Criar lotes" color="primary-blue-500" value="lots" id="criarLotes" />
                                                                </base-tooltip>
                                                            </v-col>
                                                        </v-row>
                                                    </v-radio-group>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mt-4 gap-4">
                                                <v-col cols="6" :data-v-step="10">
                                                    <base-button
                                                        :label="shipping_type === 'lots' ? 'Configurar lotes' : 'Criar campanha'"
                                                        icon="$checkIcon"
                                                        primary
                                                        :disabled="!allFilesOk"
                                                        @click="confirm"
                                                        :id="shipping_type === 'lots' ? 'configurarLotes' : 'criarCampanha'"
                                                    />
                                                </v-col>
                                                <v-col cols="6" :data-v-step="11">
                                                    <base-button
                                                        label="Limpar tudo"
                                                        icon="$discardIcon"
                                                        tertiary
                                                        danger
                                                        :disabled="!client_id"
                                                        @click="clearData"
                                                        id="btnLimparTudo"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </base-section-form>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <dialog-template-manual
                        v-model="show_dialog_manual"
                        :list-template="template_list?.data ?? []"
                        :template-selected="template_id"
                        @setTemplate="setTemplate"
                    />
                    <dialog-preview-template v-model="show_dialog_preview" :ds-template="ds_template" />
                    <dialog-file-configuration
                        v-if="show_dialog_configuration"
                        v-model="show_dialog_configuration"
                        :ds-template="templateSelected.DS_TEMPLATE"
                        :ds-parametro="templateSelected.DS_PARAMETRO"
                        :client-id="client_id"
                        :file-id="current_file_config_id"
                        @saveConfig="saveConfiguration"
                    />
                    <modal-form-teste-template
                        v-model="modal_send_message"
                        :data-to-send="dataTest"
                        :control-modal="modal_send_message"
                        :client-id="client_id"
                    />
                    <dialog-confirmation
                        v-model="show_dialog_confirmation"
                        :type-product="type_product"
                        :client-name="client_info?.NM_EMPRESA_EPES ?? ''"
                        :campaign-name="campaignName"
                        :valid-phones="valid_phones"
                        :total-phones="total_messages"
                        :invalid-phones="invalid_phones"
                        :repeated-phones="repeated_phones"
                        :blacklist-phones="blacklist_phones"
                        :basic-send="basic_send"
                        :single-send="single_send"
                        :scheduling="schedulingDescription"
                        :template-content="templateContent"
                        :loading="loading_confirm"
                        :progress="progress_send"
                        @confirm="createCampaign"
                    />
                    <dialog-understanding-confirmation
                        v-model="show_single_confirmation_dialog"
                    >
                    </dialog-understanding-confirmation>
                    <base-dialog
                        v-model="show_finish_dialog"
                        title="Campanha criada com sucesso"
                        cancel
                        :action-button="{
                            primary: true,
                            icon: 'mdi-arrow-right',
                            label: 'Acompanhar',
                            id: 'btnAcompanharCampanha'
                        }"
                        use-icon-close
                        @closeDialog="clearData"
                        @clickDialog="goToDashboard"
                    >
                        <v-row no-gutters>
                            <v-col cols="12">
                                <span class="body-2 text-gray-900">A campanha com ID <b id="idCampanha">{{ campaign_id }}</b> e nome <b>{{ campaignName }}</b> foi criada com sucesso.</span>
                            </v-col>
                            <v-col cols="12" class="mt-4">
                                <span class="body-2 text-gray-900">O que deseja fazer agora?</span>
                            </v-col>
                        </v-row>
                    </base-dialog>
                    <base-dialog
                        v-model="show_error_dialog"
                        title="Erro na cria&ccedil;&atilde;o da campanha"
                        cancel
                        use-icon-close
                    >
                        <v-row no-gutters>
                            <v-col cols="12">
                                <span class="body-2 text-gray-900">{{ msg_error }}</span>
                            </v-col>
                        </v-row>
                    </base-dialog>
                    <dialog-stagger-campaign 
                        v-model="show_stagger" 
                        :time-rules="client_info?.hr_bloqueio_agendamento" 
                        :total-records="total_messages" 
                        :campaign-name="campaignName"
                        :client-name="client_info?.NM_EMPRESA_EPES ?? ''"
                        @saveConfig="saveLots"
                    />
                    <pg-tour ref="tour" :steps="steps" @closed="clearData" />
                </v-card>
                <v-card v-else width="100%" elevation="0">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-row no-gutters>
                                <v-col cols="12" class="mt-12">
                                    <v-skeleton-loader width="170" type="heading" />
                                    <v-skeleton-loader class="mt-2 mb-8" width="315" type="text" />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="gap-12">
                                <v-col cols="12" md="6" lg="4">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-skeleton-loader width="80" type="text" />
                                        </v-col>
                                        <v-col cols="12" class="mt-6">
                                            <v-skeleton-loader width="160" type="text" />
                                            <v-skeleton-loader height="34" type="image" />
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="mt-8">
                                        <v-col cols="12">
                                            <v-skeleton-loader width="80" type="text" />
                                        </v-col>
                                        <v-col cols="12" class="mt-6">
                                            <v-skeleton-loader width="160" type="text" />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row no-gutters class="gap-4">
                                                <v-col cols="9">
                                                    <v-skeleton-loader height="34" type="image" />
                                                </v-col>
                                                <v-col cols="3">
                                                    <v-skeleton-loader height="34" type="image" />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-skeleton-loader width="160" type="text" />
                                        </v-col>
                                        <v-col cols="12" class="mt-6">
                                            <v-skeleton-loader width="180" type="text" />
                                        </v-col>
                                        <v-col cols="12" class="mt-6">
                                            <v-skeleton-loader height="150" type="image" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6" lg="4">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-skeleton-loader width="160" type="text" />
                                        </v-col>
                                        <v-col cols="12" class="mt-6">
                                            <v-skeleton-loader height="495" type="image" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-container>
        `
};