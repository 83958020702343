import BaseButton from "../../../components/atomos/BaseButton?v=2";
import BaseSectionForm from "../../../components/atomos/BaseSectionForm?v=2";
import PreviewTemplate from "../../../components/organismos/PreviewTemplate";
import DialogPreviewTemplate from "../../../components/organismos/DialogPreviewTemplate";
import BaseInformation from "../../../components/atomos/BaseInformation";
import DialogConfigFallback from "./dialogs/DialogConfigFallback";
import DialogRemoveFallback from "./dialogs/DialogRemoveFallback";
import PreviewText from "../../../components/atomos/PreviewText";
import BaseNotify from "../../../components/atomos/BaseNotify";
import TemplateUtils from "../../../utils/TemplateUtils";
import SupportContactDialog from "../../../components/organismos/SupportContactDialog";
import * as types from "../store/mutations-types";

/**
 * Componente que representa o passo tres do cadastro de templates para RCS
 *
 * @displayName CadastroTemplatePassoTres
 * @requires {@link BaseButton}
 * @requires {@link BaseSectionForm}
 * @requires {@link PreviewTemplate}
 * @requires {@link DialogPreviewTemplate}
 * @requires {@link BaseInformation}
 * @requires {@link DialogConfigFallback}
 * @requires {@link DialogRemoveFallback}
 * @requires {@link PreviewText}
 * @requires {@link BaseNotify}
 * @requires {@link SupportContactDialog}
 * @requires {@link TemplateUtils}
 * @component
 * @category Page / Template RCS
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseButton,
        BaseSectionForm,
        PreviewTemplate,
        DialogPreviewTemplate,
        BaseInformation,
        DialogConfigFallback,
        DialogRemoveFallback,
        PreviewText,
        BaseNotify,
        SupportContactDialog
    },
    data() {
        return {
            conf_content: {},
            show_dialog_config: false,
            show_dialog_preview: false,
            show_dialog_remove: false,
            show_dialog_support: false,
            content_preview: null,
            view: {
                subtitle: "",
                description: "",
                information: false,
                button: { label: null, icon: null, primary: true, secondary: false, show_dialog: null }
            },
            view_loading: true,
            template_utils: new TemplateUtils(),
            show_notify: false,
            notify_title: null,
            notify_message: null,
            removed_fallback: null,
            notify_button_label: null,
            id_empresa: this.$cookie === undefined ? getCookie("id_empresa_selected") : this.$cookie.get("id_empresa_selected")
        };
    },
    computed: {
        ...window.Vuex.mapState("templatesrcs", {
            step_one_content: state => state.step_one_content,
            step_two_content: state => state.step_two_content,
            step_three_content: state => state.step_three_content
        }),
        ...window.Vuex.mapState("utils", {
            clients_loading: state => state.clients_loading,
            list_clients: state => state.list_clients
        }),
        ...window.Vuex.mapState("auth", {
            list_empresas: state => state.list_empresas
        })
    },
    created() {
        this.buildView();
    },
    mounted() {
        if (this.step_three_content.list_messages) {
            this.conf_content = _.cloneDeep(this.step_three_content);
        }
    },
    methods: {
        /**
         * Monta a exibi��o correspondente as permiss�es do usu�rio
         * @vue
         */
        buildView() {
            if (!this.clients_loading) {
                let carteira = null;
                let empresa = this.list_empresas.find(emp => {
                    return parseInt(emp.ID_EMPRESA_EPES) === parseInt(this.id_empresa);
                });

                if (this.step_one_content.clients[0] !== -1) {
                    for (let client of this.step_one_content.clients) {
                        carteira = this.list_clients.find(carteira => {
                            return parseInt(carteira.ID_EMPRESA_EPES) === parseInt(client);
                        });

                        if (carteira.CD_ROTA_API_AVULSO_EPES/*  && carteira.CD_ROTA_EPES */) {
                            break;
                        }
                    }
                }

                if ((carteira && carteira.CD_ROTA_API_AVULSO_EPES/*  && carteira.CD_ROTA_EPES */) || (empresa && empresa.CD_ROTA_API_AVULSO_EPES/*  && empresa.CD_ROTA_EPES */)) {
                    this.view = {
                        subtitle: this.step_three_content.list_messages ? "A mensagem alternativa ser&aacute; enviada por SMS para os contatos que n&atilde;o receberem o RCS por algum motivo" : "Voc&ecirc; pode cadastrar uma mensagem alternativa para ser enviada por SMS para os contatos que n&atilde;o receberem o RCS por algum motivo",
                        information: true,
                        description: "O envio RCS s&oacute; ser&aacute; cobrado se tiver sucesso na entrega. Caso seja cadastrada a mensagem alternativa, os envios de SMS ser&atilde;o cobrados &agrave; parte.",
                        button: { label: "Quero cadastrar", icon: "$smsProductSmallIcon", primary: true, secondary: false, show_dialog: "show_dialog_config", block: true }
                    };
                } else {
                    this.$store.commit("templatesrcs/" + types.SET_STEP_THREE, {});
                    this.view = {
                        subtitle: "Deseja cadastrar uma mensagem alternativa para ser enviada por SMS para os contatos que n&atilde;o receberem o RCS por algum motivo?",
                        information: false,
                        description: "Para utilizar esta funcionalidade &eacute; necess&aacute;rio ter o canal de SMS contratado para a carteira selecionada.",
                        button: { label: "Tenho interesse!", icon: null, primary: false, secondary: true, show_dialog: "show_dialog_support", block: false }
                    };
                }

                this.view_loading = false;
            } else {
                setInterval(() => {
                    this.buildView();
                }, 500);
            }
        },
        /**
         * Exibe o dialog de preview de template
         * @param {String} content_preview JSON no formato string
         * @vue
         */
        showDialogPreview(content_preview) {
            this.content_preview = content_preview;
            this.show_dialog_preview = true;
        },
        /**
         * Salva as informa��es da modal de configura��o de fallback
         * @param {Object} conf_content
         * @vue
         */
        onSave(conf_content) {
            if (Object.values(conf_content).length > 0 && Object.values(this.conf_content).length === 0) {
                this.showNotify({
                    notify_title: "Mensagem alternativa criada com sucesso!",
                    notify_message: "A mensagem alternativa de SMS foi criada e j&aacute; est&aacute; dispon&iacute;vel no seu template"
                });
            } else if (Object.values(conf_content).length === 0 && Object.values(this.conf_content).length > 0) {
                this.showNotify({
                    notify_title: "Mensagem alternativa removida",
                    notify_message: "A mensagem alternativa de SMS foi removida",
                    removed_fallback: _.cloneDeep(this.conf_content),
                    notify_button_label: "Restaurar mensagem"
                });
            } else if (Object.values(conf_content).length > 0 && Object.values(this.conf_content).length > 0) {
                this.showNotify({
                    notify_title: "Mensagem alternativa alterada com sucesso!",
                    notify_message: "A mensagem alternativa de SMS foi alterada"
                });
            }

            this.conf_content = conf_content;
            this.show_dialog_config = false;
            this.$store.commit("templatesrcs/" + types.SET_STEP_THREE, conf_content);
        },
        /**
         * Limpa as informa��es de fallback do passo 3
         * @vue
         */
        clear() {
            this.showNotify({
                notify_title: "Mensagem alternativa removida",
                notify_message: "A mensagem alternativa de SMS foi removida",
                removed_fallback: _.cloneDeep(this.conf_content),
                notify_button_label: "Restaurar mensagem"
            });

            this.conf_content = {};
            this.$store.commit("templatesrcs/" + types.SET_STEP_THREE, {});
        },
        /**
         * Evento click que abrir� o dialog informado
         * @param show_dialog
         * @vue
         */
        actionButton(show_dialog) {
            this[show_dialog] = true;
        },
        /**
         * Exibe uma notificacao ao usuario
         * @param {Object} data Dados da notificacao
         * @vue
         */
        showNotify(data) {
            this.notify_title = data.notify_title;
            this.notify_message = data.notify_message;
            this.removed_fallback = data.removed_fallback;
            this.notify_button_label = data.notify_button_label;
            this.show_notify = true;
        },
        /**
         * Faz a restauracao do fallback removido
         * @vue
         */
        restoreFallback() {
            this.conf_content = this.removed_fallback;
            this.$store.commit("templatesrcs/" + types.SET_STEP_THREE, this.conf_content);
            this.show_notify = false;
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters>
                <v-col v-if="!view_loading" cols="12" md="5" lg="4" :data-v-step="6">
                    <v-row no-gutters>
                        <v-col>
                            <base-section-form
                                title="Mensagem alternativa SMS"
                                :description="view.subtitle"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="!step_three_content.list_messages" class="pt-4" no-gutters justify="center">
                        <v-col :cols="view.button.block ? 12 : 'auto'">
                            <base-button
                                :primary="view.button.primary"
                                :secondary="view.button.secondary"
                                :icon="view.button.icon"
                                :label="view.button.label"
                                :width="view.button.block ? '100%' : 'auto'"
                                :block="false"
                                @click="actionButton(view.button.show_dialog)"
                            />
                        </v-col>
                    </v-row>   
                    <v-row v-if="step_three_content.list_messages">
                        <v-col cols="12">
                            <preview-text :list-message="template_utils.formatMessageList(step_three_content.list_messages)" />
                        </v-col>
                    </v-row>                                     
                    <v-row v-if="step_three_content.list_messages">
                        <v-col cols="12">
                            <base-button
                                secondary
                                icon="mdi-pencil"
                                label="Alterar"
                                width="100%"
                                :block="false"
                                @click="actionButton(view.button.show_dialog)"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="step_three_content.list_messages">
                        <v-col cols="12">
                            <base-button
                                tertiary
                                danger
                                icon="mdi-trash-can"
                                label="Excluir"
                                width="100%"
                                :block="false"
                                @click="actionButton('show_dialog_remove')"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="pt-4" no-gutters>
                        <v-col cols="12">
                            <base-information v-if="view.information" :description="view.description" icon="mdi-information-outline" />
                            <span v-else="view.information" class="body-2 text-gray-700" v-html="view.description" />
                        </v-col>
                    </v-row>
                    <v-row class="pt-4 bottom-0">
                        <v-col cols="12">
                            <v-img contain src="/images/cadastro-template/Ilustra_Fallback.svg" />
                        </v-col>
                    </v-row>
                    <base-notify
                        v-model="show_notify"
                        :title="notify_title"
                        :message="notify_message"
                        :button-label="notify_button_label"
                        button-icon="fa-undo"
                        type="success"
                        @click="restoreFallback"
                    />
                </v-col>
                <v-col v-else cols="12" md="5" lg="4">
                    <v-row no-gutters>
                        <v-skeleton-loader width="386" height="19" type="image"></v-skeleton-loader>
                    </v-row>
                    <v-row class="pt-2" no-gutters>
                        <v-skeleton-loader width="386" height="63" type="image"></v-skeleton-loader>
                    </v-row>
                    <v-row class="pt-2" no-gutters>
                        <v-skeleton-loader width="386" height="36" type="image"></v-skeleton-loader>
                    </v-row>
                    <v-row class="pt-2" no-gutters>
                        <v-skeleton-loader width="386" height="54" type="image"></v-skeleton-loader>
                    </v-row>
                    <v-row class="pt-2 bottom-0" cols="12" no-gutters>
                        <v-skeleton-loader width="386" height="200" type="image"></v-skeleton-loader>
                        <v-skeleton-loader width="386" height="200" type="image"></v-skeleton-loader>
                    </v-row>
                </v-col>
                <v-col
                    v-if="step_two_content && (step_two_content.list_messages.length || step_two_content.image || step_two_content.card.image)"
                    cols="12"
                    md="7"
                    lg="6"
                    offset-lg="1"
                    class="pl-md-10 mt-12 mt-md-0"
                    :data-v-step="5"
                >
                    <preview-template
                        :type="this.step_one_content.modelo.value === 'text' ? 'message' : this.step_one_content.modelo.value"
                        :message="step_two_content.list_messages"
                        :list-suggestions="step_two_content.list_chips_preview"
                        :image="step_two_content.image"
                        :card="step_two_content.card"
                        :list-card="step_two_content.list_card"
                        @showDialogPreview="showDialogPreview"
                    />
                </v-col>
                <dialog-preview-template v-model="show_dialog_preview" :ds-template="content_preview" />
                <dialog-config-fallback v-model="show_dialog_config" :config-data="conf_content" @save="onSave" />                
                <dialog-remove-fallback v-model="show_dialog_remove" @clearFallback="clear" />
                <support-contact-dialog v-model="show_dialog_support" />
            </v-row>
        `
    )
};