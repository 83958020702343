import ChatMessage from "../../../components/moleculas/ChatMessage";

export default {
    components: { ChatMessage },
    template: (
        /*html*/
        `
    <div class="container" id="vue-page">
        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col>
                        <chat-message :icon_left="true"> <div class="font-size-12 p-3"> Teste de mensagemtexttextotextotecctoxootasofahu <br> fiahufhasuihduihasuidhasudashduasghidhuashduashudhasuhdiushi</div> </chat-message>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
        `
    )
};