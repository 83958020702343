import DialogStaggerCampaign from "../../campaign/components/dialogs/DialogStaggerCampaign";

export default {
    components: {
        DialogStaggerCampaign
    },
    data() {
        return {
            show_dialog: false,
            total_records: 6,
            campaign_name: "Teste QA",
            client_name: "LA - SMS RADAR - QA PgMais",
            client_id: 17977,
            time_rules: {}
        };
    },
    created() {
        this.getTimeRulesClient();
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetClientOrCompanyInfo"]),
        save(data) {
            console.log(data);
        },
        getTimeRulesClient() {
            this.ActionDoGetClientOrCompanyInfo(this.client_id).then(response => {
                this.time_rules = response?.hr_bloqueio_agendamento ?? {};
            });
        }
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="12" md="8" lg="6">
                <v-card class="d-flex justify-center">
                    <v-btn @click="show_dialog = true" color="dark-primary white--text" class="my-10">Abrir modal</v-btn>
                </v-card>
            </v-col>
            <dialog-stagger-campaign v-model="show_dialog" :time-rules="time_rules" :total-records="total_records" :campaign-name="campaign_name" :client-name="client_name" @saveConfig="save"/>
        </v-row>
        `
    )
};