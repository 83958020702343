import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";
import FilterPageSlider from "../../../../components/atomos/FilterPageSlider?v=2";
import BaseAutocomplete from "../../../../components/atomos/BaseAutocomplete?v=2";
import BaseDatePicker from "../../../../components/atomos/BaseDatePicker";
import BaseSelect from "../../../../components/atomos/BaseSelect?v=2";

/**
 * Componente de formulario de pesquisa de sessoes RCS
 *
 * @requires {@link BaseTextField}
 * @requires {@link BaseButton}
 * @requires {@link FilterPageSlider}
 * @requires {@link BaseAutocomplete}
 * @requires {@link BaseDatePicker}
 * @requires {@link BaseSelect}
 *
 * @displayName FormFilterSessoesRcs
 * @category Page / Sessoes RCS
 * @subcategory forms
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseButton,
        FilterPageSlider,
        BaseAutocomplete,
        BaseDatePicker,
        BaseSelect
    },
    props: {
        /**
         * Valor da data inicial (Exibe o dia de hoje)
         */
        filterRadarDates: {
            type: Object,
            required: false,
            default: null
        },
        value: {
            type: Object,
            required: false,
            default() {
                return {
                    situacao: "A",
                    data_inicio: null,
                    data_fim: null,
                    id_empresa: null,
                    telefone: null,
                    campo_informado: null,
                    id_campanha: null,
                };
            }
        },
    },
    data() {
        return {
            rules: {
                telefone: [
                    (v) =>
                        !v ||
                        Number.isInteger(parseInt(v.match(/\d/g).join(""))) ||
                        window.Vue.htmlEntities(
                            "Informe um telefone v&aacute;lido"
                        ),
                    (v) =>
                        !v ||
                        v.match(/\d/g).length === 11 ||
                        window.Vue.htmlEntities(
                            "Informe um telefone v&aacute;lido"
                        )
                ],
                requiredIfEnabled: (value) => {
                    if (!this.disable_data_input && !value) {
                        return window.Vue.htmlEntities("Este campo &eacute; obrigat&oacute;rio");
                    }
                    return true;
                },
            },
            form_data: _.cloneDeep(this.value),
            clients_loading: false,
            client_list: [],
            pills_filter: [],
            situacao: [
                {
                    value: "T",
                    text: "Todas..."
                },
                {
                    value: "A",
                    text: "Aberta"
                },
                {
                    value: "E",
                    text: "Expirada"
                }
            ],
            initial_data_inicio: "",
            initial_data_fim: "",
            disable_data_input: Boolean(this.value.id_campanha)
        };
    },
    computed: {
        showReset() {
            let has_filter = false;

            for (let key of Object.keys(this.form_data)) {
                if (this.form_data[key] && this.form_data[key].length > 0) {
                    has_filter = true;
                }
            }

            return has_filter;
        }
    },
    watch: {
        /*
         * Adiciona o valor do campo Situa��o no objeto form_data.situacao
         */
        situacao(ev) {
            this.form_data.situacao = ev;
        },
        value() {
            this.form_data = _.cloneDeep(this.value);
        },
        form_data: {
            handler(form) {
                this.disable_data_input = Boolean(form.id_campanha);
            },
            deep: true
        }
    },
    created() {
        if (this.filterRadarDates) {
            if (this.filterRadarDates?.data_inicio) {
                this.pegaHoje(this.filterRadarDates?.data_inicio);
            }
            if (this.filterRadarDates?.data_fim) {
                this.pegaFim(this.filterRadarDates?.data_fim);
            }
            this.form_data.situacao = "T";
        }

    },
    mounted() {
        this.getClients();

        this.form_data.telefone = this.form_data.telefone ? this.form_data.telefone.replace(/\D/g, "") : "";
        this.$emit("formSubmit", this.form_data);
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetMyClients"]),
        /**
         * Submit dor formulario, atualiza a lista de dados de acordo com o filtro
         * @vue
         */
        formSubmit() {
            if (this.$refs.form.validate()) {
                this.form_data.telefone = this.form_data.telefone ? this.form_data.telefone.replace(/\D/g, "") : "";
                /**
                 * Emite o conteudo do formulario
                 * @property {Object} form_data Conteudo emitido
                 */
                this.$emit("formSubmit", this.form_data);
                this.$emit("input", this.form_data);
                this.makePillsFilter();
            }
        },
        /**
         * Busca na API e monta a lista de clientes (carteiras) que a empresa definida na sessao possui
         * @vue
         */
        getClients() {
            this.clients_loading = true;
            this.ActionDoGetMyClients(true).then((response) => {
                this.client_list = response;
                this.clients_loading = false;
            });
        },
        /**
         * Monta a configuracao das pills do filtro
         * @vue
         */
        makePillsFilter() {
            let config = [];

            if (
                this.form_data.id_campanha !== null &&
                this.form_data.id_campanha.length > 0
            ) {
                config.push({
                    closeIcon: "mdi-close",
                    text: "Identificador da Campanha:",
                    subText: this.form_data.id_campanha,
                    click: () => {
                        this.form_data.id_campanha = "";
                        this.formSubmit();
                    }
                });
            }

            if (
                this.form_data.id_empresa !== null &&
                this.form_data.id_empresa.length > 0
            ) {
                config.push({
                    closeIcon: "mdi-close",
                    text: "Cliente:",
                    subText: this.form_data.id_empresa.length + " selecionados",
                    click: () => {
                        this.form_data.id_empresa = "";
                        this.formSubmit();
                    }
                });
            }

            if (
                this.form_data.situacao !== null &&
                this.form_data.situacao.length > 0
            ) {
                //Inseri a descricao do status da campanha
                let status = this.situacao.find(
                    (item) => item.value === this.form_data.situacao
                );

                config.push({
                    closeIcon: "mdi-close",
                    text: window.Vue.htmlEntities("Situa&ccedil;&atilde;o:"),
                    subText: status.text,
                    click: () => {
                        this.form_data.situacao = "A";
                        this.formSubmit();
                    }
                });
            }

            if (
                this.form_data.data_inicio !== null &&
                this.form_data.data_inicio.length > 0
            ) {
                config.push({
                    closeIcon: "mdi-close",
                    text: window.Vue.htmlEntities("Data Inicio:"),
                    subText: window.Vue.formatDateUsaToBrasil(
                        this.form_data.data_inicio
                    ),
                    click: () => {
                        let now = dayjs(new Date());

                        let day = now
                            .month(now.month())
                            .date(1)
                            .format("YYYY-MM-DD");

                        this.form_data.data_inicio = "";
                        this.form_data.data_inicio = day;
                        this.formSubmit();
                    }
                });
            }

            if (
                this.form_data.data_fim !== null &&
                this.form_data.data_fim.length > 0
            ) {
                config.push({
                    closeIcon: "mdi-close",
                    text: "Data Fim:",
                    subText: window.Vue.formatDateUsaToBrasil(
                        this.form_data.data_fim
                    ),
                    click: () => {
                        let now = dayjs(new Date());

                        let day = now
                            .month(now.month())
                            .date(now.format("DD"))
                            .format("YYYY-MM-DD");

                        this.form_data.data_fim = "";
                        this.form_data.data_fim = day;
                        this.formSubmit();
                    }
                });
            }

            if (
                this.form_data.campo_informado !== null &&
                this.form_data.campo_informado.length > 0
            ) {
                config.push({
                    closeIcon: "mdi-close",
                    text: "Campo Informado:",
                    subText: this.form_data.campo_informado,
                    click: () => {
                        this.form_data.campo_informado = "";
                        this.formSubmit();
                    }
                });
            }

            if (
                this.form_data.telefone !== null &&
                this.form_data.telefone.length > 0
            ) {
                config.push({
                    closeIcon: "mdi-close",
                    text: "Telefone:",
                    subText: this.form_data.telefone,
                    click: () => {
                        this.form_data.telefone = "";
                        this.formSubmit();
                    }
                });
            }

            this.pills_filter = config;
        },
        pegaHoje(ev) {
            this.form_data.data_inicio = ev;
            this.initial_data_inicio = ev;
            this.$emit("data_inicio", ev);
        },
        pegaFim(ev) {
            this.form_data.data_fim = ev;
            this.initial_data_fim = ev;
            this.$emit("data_fim", ev);
        },
        /**
         * Fun��o usada para limpar os inputs do formul�rio de busca
         * @vue
         */
        limparFiltro() {
            //Inputs a serem limpos, precisa declarar o nome dos inputs nos components e inserir o nome no objeto "limpar_obj"
            let limpar_obj = ["id_empresa", "id_campanha", "campo_informado", "telefone", "situacao"];

            /*
             * this.$refs.form.inputs.forEach
             * Percorre o DOM pegando todos os inputs dentro do form
             */
            this.$refs.form.inputs.forEach((input) => {
                //Percorre o objeto "limpar_obj" para verificar se o input atual est� dentro do objeto
                if (limpar_obj.includes(input.$refs.input.name)) {
                    //Se estiver, limpa o input
                    input.reset();
                }
            });

            //Reinicia os valores das datas nos campos In�cio e Fim
            let day = "";

            const now = dayjs(new Date());
            day = now.month(now.month()).date(1).format("YYYY-MM-DD");
            this.form_data.data_inicio = day; //Valor data_inicio

            day = dayjs()
                .day(dayjs().day())
                .format("YYYY-MM-DD"); /*Inseri data atual*/
            this.form_data.data_fim = day; //Valor dataFim

            this.form_data.situacao = "A"; //Valor status da sessao
            /* Declara null os inputs do formul�rio */
            this.form_data.id_empresa = null;
            this.form_data.id_campanha = null;
            this.form_data.campo_informado = null;
            this.form_data.telefone = null;

            this.formSubmit();
        },
        /**
         * Retorna a mascara de telefone para aplicar ao campo de acordo com a quantidade de caracteres inseridos
         *
         * @returns {String} Mascara de telefone
         * @vue
         */
        maskFone() {
            if (this.form_data.telefone) {
                return this.form_data.telefone.length == 15
                    ? "(##) #####-####"
                    : "(##) ####-#####";
            } else {
                return "(##) #####-####";
            }
        }
    },
    template:
        /*html*/
        `
        <filter-page-slider
            :pills-filter="pills_filter"
            :show-reset="showReset"
            @reset="limparFiltro();" 
            v-bind:data-v-step="1"
        >
            <v-form ref="form">
                <v-row class="mt-0" justify="center" justify-md="end">                   
                    <v-col cols="6" md="3">
                        <base-autocomplete
                            tooltip-text="Voc&ecirc; pode filtrar as templates por cliente."
                            v-model="form_data.id_empresa"
                            label="Escolha um cliente"
                            title="Cliente"
                            :items="client_list"
                            :rules="[]"
                            :loading="clients_loading"
                            multiple
                            name="id_empresa"
                            use-icon-validation
                        ></base-autocomplete>
                    </v-col>
                  
                    <v-col cols="6" md="3">
                        <base-text-field
                            tooltip-text="Voc&ecirc; pode pesquisar os retornos pelo identificador da campanha"
                            v-model="form_data.id_campanha"
                            label="Digite..."
                            title="Identificador da Campanha"
                            name="id_campanha"
                            use-icon-validation
                            :rules="[]"
                        ></base-text-field>
                    </v-col>
                  
                    <v-col cols="6" md="3">                        
                        <base-select
                            tooltip-text="Voc&ecirc; pode selecionar uma ou mais <b>situa&ccedil;&otilde;es</b> (status) dos disparos"
                            v-model="form_data.situacao"
                            label="Status da sess&atilde;o"
                            title="Status da sess&atilde;o"
                            name="situacao"
                            :items="situacao"
                            use-icon-validation
                            v-bind:data-v-step="3"
                        ></base-select>                            
                    </v-col>

                    <v-col cols="6" md="3">
                        <base-text-field
                            tooltip-text="Voc&ecirc; pode pesquisar um n&uacute;mero de telefone  espec&iacute;fico."
                            v-model="form_data.telefone"
                            label="(__) _____-____"
                            :mask="maskFone()"
                            :rules="rules.telefone"
                            type="tel"
                            title="Telefone"
                            name="telefone"
                            use-icon-validation
                        ></base-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-0" justify="center" justify-md="end">
                    <v-col cols="12" md="6" v-bind:data-v-step="2">
                        <v-row>
                            <v-col cols="6" md="6">
                                <base-date-picker
                                    tooltip-text="Escolha uma <b>data inicial</b> para filtrar."
                                    v-model="form_data.data_inicio"
                                    label="Selecione..."
                                    title="Per&iacute;odo (retorno) de"
                                    use-icon-validation
                                    clearable
                                    :rules="[rules.requiredIfEnabled]"
                                    :inicio="true"
                                    @input="pegaHoje"
                                    :max-date="form_data.data_fim"
                                    :disabled="disable_data_input"
                                ></base-date-picker>
                            </v-col>
                            <v-col cols="6" md="6">
                                <base-date-picker
                                    tooltip-text="Escolha uma <b>data fim</b> para filtrar."
                                    v-model="form_data.data_fim"
                                    label="Selecione..."
                                    title="At&eacute;"
                                    use-icon-validation
                                    clearable
                                    name="dataFim"
                                    :rules="[rules.requiredIfEnabled]"
                                    :fim="true"
                                    @input="pegaFim"
                                    :min-date="form_data.data_inicio"
                                    :disabled="disable_data_input"
                                ></base-date-picker>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" md="3">
                        <base-text-field 
                            tooltip-text="Voc&ecirc; pode pesquisar os retornos por um campo que foi informado no arquivo de dados (exclusivo API)"
                            v-model="form_data.campo_informado"                            
                            label="Digite..."
                            title="Campo Informado"
                            name="campo_informado"
                            use-icon-validation
                            :rules="[]"
                        ></base-text-field>                       
                    </v-col>
                      
                    <v-col class="mt-5 d-flex justify-end" md="3">
                        <base-button 
                            block
                            icon='fa fa-filter'
                            label='Aplicar Filtros'
                            variant="extra-dark-grey"
                            @click="formSubmit()"
                            :disabled="false"
                        ></base-button>
                    </v-col>
                </v-row>
            </v-form>
        </filter-page-slider>
    `
};
