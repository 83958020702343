import BaseCalendar from "../../../components/moleculas/BaseCalendar";

export default {
    name: "base-calendar-usage",
    components: {
        BaseCalendar
    },
    data() {
        return {
            enableInput: false,
            range: true,
            legend: true,
            legend_bottom: false,
            content: ["2022-02-13", "2022-02-19"]
        };
    },
    watch: {
        content() {
            console.log(this.content);
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center" class="mt-16">
                <v-col cols="12" sm="6" md="4" lg="4" class="bg-white">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <v-checkbox label="Habilitar opcao de input" v-model="enableInput"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox label="Habilitar range" v-model="range"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox label="Legenda" v-model="legend"></v-checkbox>
                        </v-col>
                        <v-col cols="6">
                            <v-checkbox label="Legenda abaixo" v-model="legend_bottom"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <base-calendar v-model="content" :enable-input="enableInput" :range="range" :legend="legend" :legend-bottom="legend_bottom"></base-calendar>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
     
        `
    )
};