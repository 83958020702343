/**
 * Componente de card selecionavel
 * 
 * @displayName BaseCardExtraLarge
 * @component
 * @vue
 * @category Atomos
 * @author Igor Oliveira <igor.oliveira@pgmais.com.br>
 */
export default {
    props: {
        /**
         * Url da imagem do card
         */
        imageUrl: {
            type: String,
            required: true
        },
        /**
         * Texto a ser exibido como titulo
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Texto a ser exibido como descrio
         */
        description: {
            type: String,
            required: true
        },
        /**
         * Valor de v-model indica que o componente esta selecionado
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define o componente como desabilitado
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define o componente como em breve
         */
        soon: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            hover_card: false,
            selected: this.value
        };
    },
    computed: {
        cardColor() {
            let color = "white";

            if (this.selected) {
                color = "extra-light-primary";
            } else if (this.hover_card && !this.disabled && !this.soon) {
                color = "background";
            }

            return color;
        },
        border() {
            let border = "super-dark-grey border";

            if (this.disabled || this.soon) {
                border = "extra-light-grey " + (this.soon ? "border-dashed" : "border");
            } else if (this.selected) {
                border = "dark-primary border";
            }

            return border;
        },
        textColor() {
            let color = "super-dark-grey";

            if (this.disabled || this.soon) {
                color = "extra-light-grey";
            }

            return color;
        }
    },
    watch: {
        value() {
            this.selected = this.value;
        }
    },
    methods: {
        /**
         * Evento de click do card
         * @vue
         */
        click() {
            if (!this.disabled && !this.soon) {
                /**
                 * Evento de input, disparado sempre que o card for clicado
                 * 
                 * @property {Boolean} selected
                 * 
                 * @event input
                 * @vue
                 */
                this.$emit("input", !this.selected);

                /**
                 * Evento de click, disparado sempre que o card for clicado
                 * 
                 * @event click
                 * @vue
                 */
                this.$emit("click");
            }
        }
    },
    template: (
        /*html*/
        `
            <v-hover v-model="hover_card" :disabled="disabled || soon">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card elevation="0" :class="'pa-6 rounded-lg border-' + border + ' bg-'+ cardColor" :disabled="disabled || soon" @click="click">
                            <v-row no-gutters justify="center">
                                <v-col cols="auto">
                                    <v-img contain :src="imageUrl" height="180" max-width="209"></v-img>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col cols="12">
                                    <span :class="'font-weight-bold font-size-13 text-center text-' + textColor" v-html="title"></span>
                                </v-col>
                                <v-col cols="12" class="mt-1">
                                    <span :class="'font-size-13 text-center text-' + textColor" v-html="description"></span>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col v-if="soon" cols="12" class="text-center">
                        <span class="text-dark-grey font-size-10">Em breve!</span>
                    </v-col>
                </v-row>
            </v-hover>
        `
    )
};
