import ConfigMessageTemplate from "../../template-rcs/components/ConfigMessageTemplate?v=2";

export default {
    components: {
        ConfigMessageTemplate
    },
    data() {
        return {
            listMessage: [
                {
                    text: "Ola,",
                    type: "text"
                },
                {
                    text: "Nome",
                    type: "pill",
                    size: 50,
                    color: "dark-primary"
                },
                {
                    text: "esse",
                    type: "text"
                },
                {
                    text: "eh",
                    type: "text"
                },
                {
                    text: "um",
                    type: "text"
                },
                {
                    text: "texto",
                    type: "text"
                },
                {
                    text: "de",
                    type: "text"
                },
                {
                    text: "exemplo",
                    type: "text"
                },
                {
                    text: "utilizado",
                    type: "text"
                },
                {
                    text: "pela",
                    type: "text"
                },
                {
                    text: "empresa",
                    type: "text"
                },
                {
                    text: "Empresa",
                    type: "pill",
                    size: 100,
                    color: "dark-green"
                }
            ]
        };
    },
    watch: {
        listMessage(new_val) {
            console.log(new_val);
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center" class="bg-white">
                <v-col cols="3" class="mt-5">
                    <config-message-template v-model="listMessage"></config-message-template>
                </v-col>
            </v-row>
        `
    )
};