import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "add-campo-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom xmlns="http://www.w3.org/2000/svg" width="11.648" height="11.648" viewBox="0 0 11.648 11.648">
                <path id="Path_1299" data-name="Path 1299" d="M2.324,3.5H1.176v8.176a1.155,1.155,0,0,0,1.148,1.148H10.5V11.676H2.324Zm9.352-2.324h-7A1.161,1.161,0,0,0,3.5,2.324v7A1.167,1.167,0,0,0,4.676,10.5h7a1.161,1.161,0,0,0,1.148-1.176v-7A1.155,1.155,0,0,0,11.676,1.176Zm0,8.148h-7v-7h7Zm-4.1-1.148H8.75V6.426H10.5V5.25H8.75V3.5H7.574V5.25H5.824V6.426h1.75Z" transform="translate(-1.176 -1.176)"/>
            </svg-custom>
        `
    )
};