export default {
    template: (
        /*html*/
        `
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.36841 16.4173C6.36841 16.1412 6.59227 15.9173 6.86841 15.9173H15.3421C15.6182 15.9173 15.8421 16.1412 15.8421 16.4173C15.8421 16.6935 15.6182 16.9173 15.3421 16.9173H6.86841C6.59227 16.9173 6.36841 16.6935 6.36841 16.4173Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.36841 22.1016C6.36841 21.8254 6.59227 21.6016 6.86841 21.6016H12.0263C12.3024 21.6016 12.5263 21.8254 12.5263 22.1016C12.5263 22.3777 12.3024 22.6016 12.0263 22.6016H6.86841C6.59227 22.6016 6.36841 22.3777 6.36841 22.1016Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.36841 19.2595C6.36841 18.9833 6.59227 18.7595 6.86841 18.7595H18.1842C18.4603 18.7595 18.6842 18.9833 18.6842 19.2595C18.6842 19.5356 18.4603 19.7595 18.1842 19.7595H6.86841C6.59227 19.7595 6.36841 19.5356 6.36841 19.2595Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0.0830078C4.44772 0.0830078 4 0.530722 4 1.08301L4 24.9173C4 25.4696 4.44772 25.9173 5 25.9173H21C21.5523 25.9173 22 25.4696 22 24.9173V1.08301C22 0.530723 21.5523 0.0830078 21 0.0830078H5ZM21 13.7053H5L5 24.9173H21V13.7053ZM6.36841 16.4173C6.36841 16.1412 6.59227 15.9173 6.86841 15.9173H15.3421C15.6182 15.9173 15.8421 16.1412 15.8421 16.4173C15.8421 16.6935 15.6182 16.9173 15.3421 16.9173H6.86841C6.59227 16.9173 6.36841 16.6935 6.36841 16.4173ZM6.36841 22.1016C6.36841 21.8254 6.59227 21.6016 6.86841 21.6016H12.0263C12.3024 21.6016 12.5263 21.8254 12.5263 22.1016C12.5263 22.3777 12.3024 22.6016 12.0263 22.6016H6.86841C6.59227 22.6016 6.36841 22.3777 6.36841 22.1016ZM6.36841 19.2595C6.36841 18.9833 6.59227 18.7595 6.86841 18.7595H18.1842C18.4603 18.7595 18.6842 18.9833 18.6842 19.2595C18.6842 19.5356 18.4603 19.7595 18.1842 19.7595H6.86841C6.59227 19.7595 6.36841 19.5356 6.36841 19.2595ZM7.71108 8.79833C7.79573 8.85791 7.89691 8.88989 8.00065 8.88984L17.9996 8.88934C18.1031 8.88931 18.204 8.85741 18.2885 8.79802C18.373 8.73864 18.4369 8.65469 18.4714 8.55774C18.5058 8.46079 18.5092 8.35561 18.481 8.25668C18.4528 8.15775 18.3944 8.06994 18.3139 8.00534L14.5759 5.00879C14.4869 4.93743 14.3759 4.89852 14.2616 4.89852C14.1472 4.89852 14.0363 4.93743 13.9473 5.00879L11.4474 7.01246L10.504 6.26419C10.4153 6.19371 10.3051 6.15532 10.1915 6.15532C10.0779 6.15532 9.96765 6.19371 9.87892 6.26419L7.68785 8.00435C7.60683 8.06871 7.54795 8.15649 7.51934 8.25554C7.49074 8.3546 7.49382 8.46004 7.52817 8.55728C7.56252 8.65452 7.62644 8.73876 7.71108 8.79833Z" fill="currentColor"/>
        </svg>
        `
    )
};