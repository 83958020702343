import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente de botao basico com icone
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName IconButton
 * @component
 * @category Atomos
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Nome do icone a ser apresentado no botao
         */
        icon: {
            type: String,
            required: false,
            default: "mdi-close-circle"
        },
        /**
         * Texto do tooltip do campo
         */
        tooltip: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Indica o estado de ativo
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica o estado de danger
         */
        danger: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Aplica as cores para o modo dark
         */
        dark: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define a escala do botao
         */
        size: {
            type: [Number, String],
            required: false,
            default: 16
        },
        /**
         * Define uma cor customizada para o background
         */
        color: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Define uma cor customizada para o texto
         */
        textColor: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Define o botao como desabilitado
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define o background como arredondado
         */
        rounded: {
            type: Boolean,
            required: false,
            default: true
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            hover: false,
            active: this.value
        };
    },
    computed: {
        backgrounColor() {
            let color = "gray-300";

            if (this.color) {
                color = this.color;
            } else {
                if (this.disabled) {
                    color = "gray-50";
                } else if (this.active && !this.dark) {
                    color = "gray-900";
                } else if (this.hover) {
                    color = "primary-blue-500";

                    if (this.danger) {
                        color = "error-500";
                    }

                    if (this.dark) {
                        color = "gray-0";
                    }
                }
            }

            return color;
        },
        textSize() {
            if (this.small == true) {
                return 21;
            } else {
                return this.size - (this.size * 0.5);
            }
        }
    },
    watch: {
        value() {
            this.active = this.value;
        }
    },
    methods: {
        /**
         * Funcao que fica vinculada ao botao  
         */
        click() {
            /**
            * Evento de click do botao
            * @event click
            * @vue
            */
            this.$emit("click");
            this.$emit("input", !this.value);
        }
    },
    template: (
        /*html*/
        `
            <base-tooltip :text="tooltip">
                <v-hover v-model="hover" :disabled="disabled">
                    <v-btn elevation="0" :small="small" :icon="rounded" :width="size" :height="size" :min-width="size" :class="(color ? 'bg-' + color : 'bg-transparent') + ' pg-secondary'" :disabled="disabled" @click="click">
                        <v-icon  :size="textSize" :color="textColor ? textColor : backgrounColor">{{ icon }}</v-icon>
                    </v-btn>
                </v-hover>
            </base-tooltip>
        
        `
    )
};