export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g id="Btn-Escalonar" transform="translate(-1155 -2098)">
                    <g id="Ico-Escalonar-Enabled" transform="translate(1159 2057)">
                        <path id="Caminho_456" data-name="Caminho 456" d="M15.5,1.5H13.7V.8a.789.789,0,0,0-.8-.8.736.736,0,0,0-.8.8v.8H5.3V.8A.713.713,0,0,0,4.6,0a.789.789,0,0,0-.8.8v.8H2.1A1.97,1.97,0,0,0,0,3.6V17a2.03,2.03,0,0,0,2.1,2H15.5a2.006,2.006,0,0,0,2-2V3.6A2.094,2.094,0,0,0,15.5,1.5ZM5.3,17.5H2.1a.56.56,0,0,1-.6-.5V13.7H5.3v3.8Zm0-5.3H1.5V8.4H5.3Zm5.4,5.3H6.8V13.7h3.8v3.8Zm0-5.3H6.8V8.4h3.8v3.8ZM16,17a.472.472,0,0,1-.5.5H12.2V13.7H16Zm0-4.8H12.2V8.4H16Zm0-5.3H1.5V3.6A.576.576,0,0,1,2.1,3H3.9v.8a.789.789,0,0,0,.8.8.736.736,0,0,0,.8-.8V3h6.9v.8a.789.789,0,0,0,.8.8.736.736,0,0,0,.8-.8V3h1.8a1.268,1.268,0,0,1,.2.6Z" transform="translate(0 45)" fill="currentColor"/>
                    </g>
                    <rect id="Retângulo_462" data-name="Retângulo 462" width="26" height="26" transform="translate(1155 2098)" fill="currentColor" opacity="0"/>
                </g>
            </svg>
        `
    )
};