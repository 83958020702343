import DialogConfigTemplateMessage from "../../template-rcs/components/dialogs/DialogConfigTemplateMessage";

export default {
    components: {
        DialogConfigTemplateMessage
    },
    data() {
        return {
            show: false,
            data: {
                buttons_type: [
                    {
                        icon: "$simplesIcon",
                        title: "Simples",
                        type: "simples",
                        description: "Crie uma mensagem simples podendo ter campos personalizados.",
                        height: "auto",
                        hover: false,
                        selected: true,
                        horizontal: this.single_line,
                        disable: false
                    },
                    {
                        icon: "$respostasRapidasIcon",
                        title: "Respostas r&aacute;pidas",
                        type: "respostas_rapidas",
                        description: "Crie uma mensagem com respostas r&aacute;pidas e campos personalizados.",
                        height: "auto",
                        hover: false,
                        selected: false,
                        horizontal: this.single_line,
                        disable: false
                    },
                    {
                        icon: "$botoesIcon",
                        title: "Bot&otilde;es",
                        type: "botoes",
                        description: "Crie uma mensagem com bot&otilde;es de a&ccedil;&atilde;o e campos personalizados.",
                        height: "auto",
                        hover: false,
                        selected: false,
                        horizontal: this.single_line,
                        disable: false
                    },
                    {
                        icon: "$completoIcon",
                        title: "Completo",
                        type: "completo",
                        description: "Crie uma mensagem utilizando todos os recursos",
                        height: "auto",
                        hover: false,
                        selected: false,
                        horizontal: this.single_line,
                        disable: false
                    }
                ]
            }
        };
    },
    watch: {
        data() {
            console.log(this.data);
        }
    },
    methods: {
        save(data) {
            this.data = data;
        }
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="12" md="8" lg="6">
                <v-card class="d-flex justify-center">
                    <v-btn @click="show = true" color="dark-primary white--text" class="my-10">Abrir modal de configuração</v-btn>
                </v-card>
            </v-col>
            <dialog-config-template-message :config-data="data" @save="save" v-model="show"></dialog-config-template-message>
        </v-row>
        `
    )
};