import RepeatReport from "../../reports/components/RepeatReport";

export default {
    components: {
        RepeatReport
    },
    methods: {
        teste(data) {
            console.log(data);
        }
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="4">
                <repeat-report @change="teste">
                </repeat-report>    
            </v-col>
        </v-row>
        `
    )
};