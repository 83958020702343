import BaseTooltip from "../../../components/atomos/BaseTooltip";

/**
 * Componente para exibicao e edicao de conteudo da pre visualizacao
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName ContentEdit
 * @category Page / Template RCS
 * @author David dos Santos Andrade <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Indica que o componente esta selecionado
         */
        selected: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica que o botao de fechar deve ser exibido
         */
        showClose: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica que o componente deve ocupar todo o espaco
         */
        fillHeight: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Icone a ser exibido
         */
        icon: {
            type: String,
            required: false,
            default: "mdi-pencil"
        },
        /**
         * Texto a ser exibido
         */
        text: {
            type: String,
            required: false,
            default: " Clique para editar"
        },
        /**
         * Adiciona uma borda vermelha ao redor
         */
        danger: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            hover: false
        };
    },
    computed: {
        borderStyle() {
            let style = "bg-transparent fill-height rounded-0";

            if (this.danger) {
                style += " border-error-500 border-2";
            } else if (this.hover) {
                style += " border-primary-blue-500";
            } else {
                style += " border-transparent";
            }

            if (this.selected) {
                style += " border-2 pa-3";
            } else if (!this.selected && !this.danger) {
                style += " border-2";
            }

            return style;
        }
    },
    watch: {
        selected() {
            if (this.selected) {
                this.hover = false;
            }
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters :class="fillHeight ? 'fill-height' : ''">
                <v-col cols="12" :class="fillHeight ? 'fill-height' : ''">
                    <v-hover v-model="hover" :disabled="selected || $vuetify.breakpoint.width < $vuetify.breakpoint.thresholds.md">
                        <v-card
                            elevation="0"
                            :min-height="selected ? 150 : undefined"
                            :class="borderStyle"
                            :ripple="false"
                            @click="$emit('click')"
                        >
                            <v-row no-gutters :class="'fill-height ' + (hover ? 'blur-4' : '')" align="end">
                                <v-col cols="12" class="fill-height">
                                    <slot />
                                </v-col>
                            </v-row>
                            <base-tooltip text="Remover bloco">
                                <v-btn
                                    v-if="showClose && (hover || selected)"
                                    small
                                    icon
                                    color="gray-300"
                                    absolute
                                    right
                                    top
                                    class="mt-n3 mr-n3 z-index-6"
                                    :data-v-step="'closeContentBlockConfig'"
                                    @click="$emit('close')"
                                >
                                    <v-icon color="gray-300">
                                        mdi-close-circle
                                    </v-icon>
                                </v-btn>
                            </base-tooltip>
                            <v-fade-transition>
                                <v-overlay
                                    v-if="hover"
                                    absolute
                                    color="transparent"
                                    opacity="0.3"
                                >
                                    <v-row no-gutters justify="center">
                                        <v-col cols="auto">
                                            <v-icon small color="primary-blue-500">
                                                {{ icon }}
                                            </v-icon>
                                            <span v-if="text" class="text-primary-blue-500"> {{ text }}</span>
                                        </v-col>
                                    </v-row>
                                </v-overlay>
                            </v-fade-transition>
                        </v-card>
                    </v-hover>
                </v-col>
            </v-row>
        `
    )
};