/**
 * Ao realizar alreacoes nas cores, o arquivo '/vue-app/public/styles/custom_colors/new_colors.css' tambem deve ser alterado
 * Alterar tambem em src/utils/colors.js e src/utils/theme.js
 */
export default {
    primary: {
        blue_900: "#162EDD",
        blue_500: "#3559EB",
        blue_200: "#9AACF5",
        blue_100: "#C2CDF9",
        blue_50: "#E7EBFD"
    },
    secondary: {
        blue_500: "#3DB7F4",
        blue_300: "#77CDF7",
        blue_200: "#9EDBFA",
        blue_100: "#C5E9FC",
        pink_900: "#FD4198",
        pink_500: "#FE70BD"
    },
    tertiary: {
        blue_900: "#0D1853",
        blue_100: "#BDC4DA"
    },
    success: {
        900: "#1CDD26",
        500: "#40EB4F",
        200: "#A0F5A7",
        50: "#E8FDEA"
    },
    warning: {
        900: "#FF9900",
        500: "#FFBE00",
        200: "#FFDF80",
        50: "#FFF7E0"
    },
    error: {
        900: "#EE1100",
        500: "#F52D00",
        200: "#FA9680",
        50: "#FEE6E0"
    },
    gray: {
        0: "#FFFFFF",
        10: "#F5F7F8",
        30: "#EFF0F2",
        50: "#E1E1E4",
        100: "#CBD0D6",
        200: "#B5BCC4",
        300: "#9EA7B1",
        400: "#8A929B",
        500: "#7C818A",
        600: "#6B717C",
        700: "#555A64",
        800: "#3A4148",
        900: "#1D1F27"
    },
    brand: {
        sms_500: "#00B6F5",
        rcs_500: "#0064E4",
        email_500: "#20DFF1"
    },
    info: {
        500: "#0045F5"
    },
    green: {
        900: "#5D930A",
        500: "#9DD200"
    },
    purple: {
        900: "#5104DC",
        500: "#9340FF"
    },
    coral: {
        900: "#C93D45",
        500: "#EA6B72"
    },
    orange: {
        900: "#D25118",
        500: "#F47901"
    },
    cyan: {
        900: "#04A99B"
    }
};