export default {
    name: "components-usage",
    computed: {
        list_child_routes() {
            let matched = this.$route.matched;
            let routePath = matched[matched.length - 1].path;
            let childrens = this.getChildrenByPath(routePath);

            if (childrens) {
                return childrens.sort(this.compare);
            }
            return [];

        }
    },
    methods: {
        getChildrenByPath(path) {
            let children = this.$router.options.routes;

            if (path) {
                let chunks = path.split("/");
                chunks = chunks.filter((chunk) => chunk !== "");

                if (chunks.length) {
                    chunks.forEach((chunk, index) => {
                        let path = chunk;
                        if (index === 0) {
                            path = `/${path}`;
                        }

                        if (children) {
                            const found = children.find((child) => child.path === path);
                            if (found) {
                                children = found.children;
                            }
                        }
                    });
                }
            }

            return children;
        },
        returnToParent() {
            this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
        },
        compare(a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
        }
    },
    template: (
        /*html*/
        `
        <div class="fill-height">
            <v-card tile v-if="list_child_routes.length > 0">
                <v-list>
                    <v-subheader>Documenta&ccedil;&atilde;o</v-subheader>
                    <v-list-item-group>
                        <v-list-item v-for="(item, i) in list_child_routes" :key="i" :to="{ name: item.name }">
                            <v-list-item-icon>
                                <v-icon>fa-link</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name ? item.name : item.path"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
            <router-view></router-view>
            <v-btn style="bottom: 10px !important"
                color="dark-primary"
                fab
                dark
                absolute
                bottom
                right
                @click="returnToParent"
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </div>
        `
    )
};
