import DialogFileConfigurationUsage from "./DialogFileConfigurationUsage";
import SummaryHygieneUsage from "./SummaryHygieneUsage";
import FileImportUsage from "./FileImportUsage";
import FileImportConfigUsage from "./FileImportConfigUsage";
import DialogStaggerCampaign from "./DialogStaggerCampaignUsage";
import AddCampaignUsage from "./AddCampaignUsage";

export default [
    {
        path: "summary-hygiene-usage",
        name: "Summary Hygiene",
        component: SummaryHygieneUsage
    },
    {
        path: "dialog-file-configuration-usage",
        name: "Dialog File Configuration",
        component: DialogFileConfigurationUsage
    },
    {
        path: "file-import-usage",
        name: "FileImportUsage",
        component: FileImportUsage
    },
    {
        path: "file-import-config",
        name: "FileImportConfig",
        component: FileImportConfigUsage
    },
    {
        path: "dialog-stagger-campaign-usage",
        name: "Dialog Stagger Campaign",
        component: DialogStaggerCampaign
    },
    {
        path: "new-campaign-page-usage",
        name: "Pagina de cadastro de campanha",
        component: AddCampaignUsage
    },
];