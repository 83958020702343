import TutorialGetCoordinates from "../../template-rcs/components/dialogs/TutorialGetCoordinates";

export default {
    components: {
        TutorialGetCoordinates
    },
    data() {
        return {
            open: false
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6" class="text-center">
                    <v-card class="pa-5">
                        <v-row justify="center">
                            <v-col cols="auto">
                                <v-btn @click="open = true">Abrir tutorial</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <tutorial-get-coordinates v-model="open"></tutorial-get-coordinates>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        `
    )
};