export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g id="Btn-TemplateEmail" transform="translate(-1875 -2098)">
                    <g id="Ico-email-Enabled" transform="translate(1876.807 2038)">
                        <g id="Grupo_2079" data-name="Grupo 2079" transform="translate(-136.43 93.135)">
                            <path id="Caminho_547" data-name="Caminho 547" d="M158.218-25.307a1.909,1.909,0,0,0-2-1.825H139.664a1.939,1.939,0,0,0-2.041,1.867v10.528c0,.015,0,.03,0,.045a1.941,1.941,0,0,0,1.9,1.826c.032,0,.065,0,.1,0h16.554a1.936,1.936,0,0,0,2.041-1.867Zm-10.3,5.872-8.168-6.2h16.339Zm-8.8-4.792,6.03,4.574-6.03,4.467Zm7.274,5.518,1.071.813a.753.753,0,0,0,.453.152.751.751,0,0,0,.453-.152l1.072-.813,5.859,4.341H140.537Zm4.294-.944,6.028-4.573v9.04Z" fill="currentColor"/>
                        </g>
                    </g>
                    <rect id="Retângulo_474" data-name="Retângulo 474" width="26" height="26" transform="translate(1875 2098)" fill="currentColor" opacity="0"/>
                </g>
            </svg>      
        `
    )
};