import services from "../../../http/index";

/**
 * Recebe um id numérico de blacklist e deleta o registro
 * @param id {numeric}
 * @returns {Promise}.
 */
export const actionDoDelete = (context, params) => {
    return services.php.request({
        url: "/BlackList/delete",
        method: "post",
        data: params
    }).then((response) => {
        let resp = response.data;
        return resp;
    });
};

/**
 * Recebe um objeto com os ids numérico de blacklist e deleta os registros
 * @param ids {numeric}
 * @returns {Promise}.
 */
export const actionDoDeleteAll = (context, params) => {
    return services.php.request({
        url: "/BlackList/multiDelete",
        method: "post",
        data: params
    }).then((response) => {
        let resp = response.data;
        return resp;
    });
};

/**
 * Busca a lista de números em blacklist
 * @returns Object
 */
export const ActionDoGetBlacklist = (context, params) => {
    return services.api.request({
        url: "/blacklist/list",
        method: "post",
        data: params
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
};

