import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";

/**
 * Componente de formulario para adicionar suggestions de link no cadastro de templates rcs
 * 
 * @displayName FormSuggestionLink
 * @component
 * @category Page / Template RCS
 * @subcategory Forms
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseButton
    },
    props: {
        /**
         * Conteudo a ser preenchido no formulario
         */
        formContent: {
            type: Object,
            required: false,
            default: null
        },
        /**
         * Lista de itens do tipo draggable para valida��o das regras
         */
        listValidation: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            rules: {
                etiqueta: [
                    v => !!v || window.Vue.htmlEntities("Voc&ecirc; precisa dar um nome para o bot&atilde;o"),
                    v => !v || v.length <= 25 || window.Vue.htmlEntities("O bot&atilde;o n&atilde;o pode ter mais de 25 caracteres"),
                    v => {
                        let valid = (typeof this.listValidation.find(element => element.text === v && element.id !== (this.formContent ? this.formContent.id : null)) == "undefined");
                        return !!valid || window.Vue.htmlEntities("J&aacute; existe um bot&atilde;o com esse nome") ;
                    }
                ],
                link: [
                    v => !!v || window.Vue.htmlEntities("Voc&ecirc; precisa inserir um link para o bot&atilde;o"),
                    v => {
                        let valid = false;
                        if (v && v[0] == "{" && v[v.length - 1] == "}") {
                            return true;
                        } else {
                            try {
                                let url = new URL(v);
                                valid = ["http:", "https:"].indexOf(url.protocol) > -1;
                            } catch (e) {
                                valid = false;
                            }
                            if (valid) {
                                if (v.toLowerCase().indexOf("http://") === 0 || v.toLowerCase().indexOf("https://") === 0) {
                                    let regex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);

                                    if (v.match(regex)) {
                                        return true;
                                    } else {
                                        return window.Vue.htmlEntities("Link inv&aacutelido");
                                    }
                                } else {
                                    return window.Vue.htmlEntities("O link deve conter o prefixo HTTP:// ou HTTPS://");
                                }
                            } else {
                                return window.Vue.htmlEntities("Informe um link v&aacute;lido com o prefixo HTTP:// ou HTTPS://");
                            }
                        }
                    }
                ]
            },
            etiqueta: this.formContent && this.formContent.etiqueta !== undefined ? this.formContent.etiqueta : null,
            link: this.formContent && this.formContent.link !== undefined ? this.formContent.link : null
        };
    },
    computed: {
        isEmpty() {
            return !this.formContent || this.formContent.id === undefined || this.formContent.id == "";
        },
        showUnsetButtons() {
            return this.etiqueta && this.etiqueta != "" && this.link && this.link != "";
        },
        showCancelButton() {
            return this.formContent && this.formContent.id !== undefined && this.formContent.id != "" && !this.disable_button;
        },
        disable_button() {
            for (let rule of this.rules.etiqueta) {
                if (rule(this.etiqueta) !== true) {
                    return true;
                }
            }
            for (let rule of this.rules.link) {
                if (rule(this.link) !== true) {
                    return true;
                }
            }

            return false;
        }
    },
    watch: {
        formContent() {
            if (this.formContent) {
                if (this.formContent.etiqueta !== undefined) {
                    this.etiqueta = this.formContent.etiqueta;
                }
                if (this.formContent.link !== undefined) {
                    this.link = this.formContent.link;
                }
            }
        }
    },
    mounted() {
        if (this.formContent && this.formContent.etiqueta && this.formContent.link) {
            this.disable_button = false;
        }
    },
    methods: {
        /**
         * Metodo chamado ao clicar no botao de acao, o formulario sera validado e caso seja valido emite as informaoees
         * 
         * @vue
         */
        submitForm() {
            if (this.$refs.formLink.validate()) {
                let data = {
                    id: this.formContent && this.formContent.id ? this.formContent.id : null,
                    etiqueta: this.etiqueta,
                    link: this.link.trim()
                };

                /**
                 * Evento de submit do formulario, dispara os dados preenchido em etiqueta e link
                 * 
                 * @property {Object} data Dados do formulario {etiqueta: String, link: String}
                 * 
                 * @event formSubmit
                 * @vue
                 */
                this.$emit("formSubmit", data);
                this.$refs.formLink.reset();
            }
        },
        /**
         * Realiza a limpeza das informacoes no formulario
         * @vue
         */
        clearContent(delete_field = false) {
            this.etiqueta = null;
            this.link = null;
            this.$refs.formLink.reset();

            /**
             * Informa que a edicao foi cancelada
             * @event cancelEdit
             * @vue
             */
            this.$emit("cancelEdit");

            if (delete_field) {
                /**
                 * Indica que um campo personalizado deve ser removido
                 * @property {Object} formContent Dados do campo a ser removido {id, name_field, number_characters}
                 * @event deleteItem
                 * @vue
                 */
                this.$emit("deleteItem", this.formContent);
            }
        }
    },
    template: (
        /*html*/
        `
        <v-form ref="formLink">
            <v-row no-gutters>
                <v-col>
                    <span class="body-2 text-gray-700">Direciona o destinat&aacute;rio a uma p&aacute;gina de internet</span>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col>
                    <base-text-field
                        v-model="etiqueta"
                        required
                        title="Etiqueta do bot&atilde;o"
                        :label="'Digite um nome para o bot&atilde;o' | htmlEntities"
                        :rules="rules.etiqueta"
                        tooltip-text="Este &eacute; o nome que ser&aacute; apresentado no bot&atilde;o."
                        :counter="25"
                        @enterPressed="submitForm"
                        @focus="$emit('focus')"
                        @blur="$emit('blur')"
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col>
                    <base-text-field
                        v-model="link"
                        required
                        title="Link"
                        label="https://..."
                        type="url"
                        :rules="rules.link"
                        tooltip-text="Este &eacute; o link que ser&aacute; acessado ao clicar no bot&atilde;o"
                        @enterPressed="submitForm"
                        @focus="$emit('focus')"
                        @blur="$emit('blur')"
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col cols="12">
                    <base-button
                        primary
                        block
                        :label="isEmpty ? 'Adicionar bot&atilde;o' : 'Alterar bot&atilde;o'"
                        :icon="isEmpty ? 'mdi-plus-thick' : 'mdi-refresh'"
                        :disabled="disable_button"
                        @click="submitForm"
                    ></base-button>
                </v-col>
            </v-row>
            <v-row v-if="showUnsetButtons" no-gutters class="mt-4" :justify="showCancelButton ? 'space-around' : 'center'">
                <v-col cols="auto">
                    <base-button
                        tertiary
                        danger
                        :label="isEmpty ? 'Limpar' : 'Excluir bot&atilde;o'"
                        icon="mdi-trash-can"
                        width="auto"
                        @click="clearContent(showCancelButton)"
                    ></base-button>  
                </v-col>
                <v-col v-if="showCancelButton" cols="auto">
                    <base-button
                        tertiary
                        danger
                        :label="'Cancelar edi&ccedil;&atilde;o' | htmlEntities"
                        icon="mdi-close-thick"
                        width="auto"
                        @click="clearContent"
                    ></base-button>  
                </v-col>
            </v-row>
        </v-form>
        `
    )
};