import AreaAction from "../../../components/atomos/AreaAction";
import ProductButton from "../../../components/atomos/ProductButton";
import SelectEmpresaForm from "../../auth/components/SelectEmpresaForm";

export default {
    name: "areaActionUsage",
    components: { AreaAction,
        ProductButton,
        SelectEmpresaForm },
    data: function() {
        return {data_button_1: {product_icon: "$smsProductIcon",
            title: "SMS",
            description: "O RCS &eacute; um mensageiro instant&acirc;neo, moderno e articulado especificamente para tornar a experi&ecirc;ncia de di&aacute;logo mais rica e din&acirc;mica",
            disable_button: false,
            color_button: "dark-primary"},
        data_button_2: {product_icon: "$rcsProductIcon",
            title: "RCS",
            description: "O RCS &eacute; um mensageiro instant&acirc;neo, moderno e articulado especificamente para tornar a experi&ecirc;ncia de di&aacute;logo mais rica e din&acirc;mica",
            disable_button: false,
            color_button: "primary"},
        lista_empresas: [{
            text: "Teste1",
            value: 1},
        {text: "Teste2",
            value: 2},
        {text: "Teste3",
            value: 3},
        {text: "Teste4",
            value: 4}]
        };
    },
    methods: {
        buttonEntrar: function () {
            window.location.href = "/Dashboard";
        },
        buttonVoltar: function () {
            alert("Teste");
        }

    },
    template: (
        /*html*/
        `
            <v-row>
               <v-col cols="12" >
                 <area-action nome_usuario="Pedro"> 
                    <v-row>
                        <v-col cols="12" lg="6">
                            <product-button 
                            :product-icon="data_button_1.product_icon"
                            :title="data_button_1.title" 
                            :description="data_button_1.description" 
                            :disable-button="true" 
                            :color-button="data_button_1.color_button" 
                            :center-button="true" />
                        </v-col>  
                        <v-col class="p-0 m-0" sm="12" lg="3">
                            <select-empresa-form 
                                :lista_empresas="lista_empresas" 
                                @click-button-entrar="buttonEntrar"
                                @click-button-voltar="buttonVoltar"/> 
                        </v-col>  
                    </v-row>         
                   </area-action>
                </v-col>
                <v-col cols="12" >
                   <area-action nome_usuario="Pedro"> 
                     <v-row>
                        <v-col class="mb-3" sm="12" lg="6">
                            <product-button 
                                        :product-icon="data_button_1.product_icon"
                                        :title="data_button_1.title" 
                                        :description="data_button_1.description" 
                                        :disable-button="data_button_1.disable_button" 
                                        :color-button="data_button_1.color_button" 
                                        :center-button="true"
                                        @click="console.log('teste');"/>
                        </v-col>  
                        <v-col class="mb-3" sm="12" lg="6">
                            <product-button 
                            :product-icon="data_button_2.product_icon"
                            :title="data_button_2.title" 
                            :description="data_button_2.description" 
                            :disable-button="data_button_2.disable_button" 
                            :color-button="data_button_2.color_button" 
                            @click="console.log('teste');"/> 
                        </v-col>           
                    </v-row> 
                  </area-action>
                </v-col>
                
            </v-row>
        `
    )
};