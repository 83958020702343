import ActiveFilter from "./ActiveFilter";

/**
 * Componente para ser utilizado como slider dos formularios de filtro de pagina
 * 
 * @displayName FilterPageSlider
 * @category Atomos
 * @requires {@link ActiveFilter}
 * @author David Nunes dos Santos <david.santos@pgmais.com>
 * @component
 * @vue
 * @vue/component
 */
export default {
    name: "filter-page-slider",
    components: {
        ActiveFilter
    },
    props: {
        /**
         * Define o valor de v-model do componente
         */
        value: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Define a configuracao da lista de pills, a estrutura de cada item do array deve ser:
         * <pre>
         * {
         *  text: String,
         *  subText: String,
         *  click: Function,
         *  removable: Boolean - Indica se o conteudo � removivel|default true
         * }
         * </pre>
         */
        pillsFilter: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Indica que o botao de reset do formulario deve ser exibido
         */
        showReset: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            show_filter: this.value
        };
    },
    computed: {
        icon_filter() {
            return this.show_filter ? "fa-caret-up" : "fa-caret-down";
        }
    },
    watch: {
        value() {
            this.show_filter = this.value;
        },
        show_filter() {
            this.$emit("input", this.show_filter);
        }
    },
    methods: {
        /**
         * Funcao para limpar campos do formulario
         * @vue
         */
        limparFiltro() {
            this.$refs.form.reset();
            /**
             * Emite um evento indicando que o botao de reset foi clicado
             * 
             * @event reset
             * @vue
             */
            this.$emit("reset");
        },
    },
    template: (
        /*html*/
        `
        <v-row no-gutters>
            <v-col>
                <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                        <v-btn
                            id="filter_slider"
                            class="text-dark-grey cursor-pointer-hover"
                            text
                            @click="show_filter = !show_filter"
                        >
                            <v-icon left small>
                                fa-filter
                            </v-icon> Filtros <v-icon right small>
                                {{ icon_filter }}
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col v-if="!show_filter" class="px-6">
                        <v-row
                            no-gutters
                            class="flex-nowrap overflow-x-auto"
                            align="center"
                            justify="center"
                        >
                            <v-col
                                v-for="(item, i) in pillsFilter"
                                :key="i"
                                cols="auto"
                                class="px-2"
                            >
                                <active-filter
                                    :title="item.text"
                                    :value="item.subText"
                                    :readonly="(item.removable === undefined ? false : !item.removable)"
                                    @close="item.click"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="showReset" cols="auto">
                        <a class="text-dark-primary color-dark-primary font-size-14 text-decoration-none" @click="limparFiltro">
                            <span>Redefinir filtros</span>
                        </a>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" lg="10">
                        <v-slide-y-transition>
                            <v-form ref="form" :class="!show_filter ? 'd-none' : ''">
                                <slot />
                            </v-form>
                        </v-slide-y-transition>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    `
    )
};
