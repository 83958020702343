import PageHeader from "../../components/moleculas/PageHeader?v=2";
import ButtonFormStep from "../../components/atomos/ButtonFormStep?v=2";
import CadastroTemplatePassoUm from "./components/CadastroTemplatePassoUm?v=2";
import CadastroTemplatePassoDois from "./components/CadastroTemplatePassoDois?v=2";
import CadastroTemplatePassoTres from "./components/CadastroTemplatePassoTres?v=4";
import CadastroTemplatePassoQuatro from "./components/CadastroTemplatePassoQuatro?v=1";
import FooterAction from "../../components/moleculas/FooterAction?v=2";
import ModalCreatedTemplate from "./components/dialogs/ModalCreatedTemplate?v=2";
import PgTour from "../../components/organismos/PgTour?v=2";
import BaseNotify from "../../components/atomos/BaseNotify?v=2";
import BaseInformation from "../../components/atomos/BaseInformation?v=3";
import SupportContactDialog from "../../components/organismos/SupportContactDialog?v=2";
import TemplateUtils from "../../utils/TemplateUtils";
import { FormStepClass } from "../../classes/FormStepClass";
import { BaseService } from "../../services/BaseService.js";
import { DraggableItemClass } from "../../classes/DraggableItemClass?v=2";
import { BasePillClass } from "../../classes/BasePillClass?v=2";
import { CardRcsClass } from "../../classes/CardRcsClass";
import * as types from "./store/mutations-types";
import * as types_utils from "../../utils/store/mutations-types";

/**
 * Pagina que realiza a criacao de templates RCS
 * 
 * @requires {@link PageHeader}
 * @requires {@link ButtonFormStep}
 * @requires {@link CadastroTemplatePassoUm}
 * @requires {@link CadastroTemplatePassoDois}
 * @requires {@link CadastroTemplatePassoTres}
 * @requires {@link CadastroTemplatePassoQuatro}
 * @requires {@link FooterAction}
 * @requires {@link ModalCreatedTemplate}
 * @requires {@link PgTour}
 * @requires {@link BaseNotify}
 * @requires {@link BaseInformation}
 * @requires {@link SupportContactDialog}
 * @requires {@link FormStepClass}
 * @requires {@link BaseService}
 * @requires {@link DraggableItemClass}
 * @requires {@link BasePillClass}
 * @requires {@link CardRcsClass}
 * @displayName CadastroTemplatePage
 * @category Page / Template RCS
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PageHeader,
        ButtonFormStep,
        CadastroTemplatePassoUm,
        CadastroTemplatePassoDois,
        CadastroTemplatePassoTres,
        CadastroTemplatePassoQuatro,
        FooterAction,
        ModalCreatedTemplate,
        PgTour,
        BaseNotify,
        BaseInformation,
        SupportContactDialog
    },
    data() {
        return {
            loading_content: true,
            base_service: new BaseService(),
            steps: [
                new FormStepClass({ step: 1, title: "Dados do template", ready: false, active: true, disabled: false, line: false }),
                new FormStepClass({ step: 2, title: "Configure o modelo escolhido", ready: false, active: false, disabled: true, line: false }),
                new FormStepClass({ step: 3, title: "Mensagem alternativa SMS", ready: false, active: false, disabled: true, line: false }),
                new FormStepClass({ step: 4, title: "Revise e salve o template", ready: false, active: false, disabled: true, line: false })
            ],
            footer_config: {
                // { right_button: { text: steps.length == key ? "Salvar template" : this?.steps[key]?.title } }
                // { left_button: { text: (key > 1 ? "Voltar" : ""), sub_text: (key > 1 ? (steps.length == key ? this?.steps[key-2]?.title : this?.steps[key-1]?.title) : "") }
                1: { right_button: { text: "2. Configure o modelo escolhido", disabled: true }, left_button: { text: null, sub_text: null } },
                2: { right_button: { text: "3. Mensagem alternativa SMS", disabled: true }, left_button: { text: "Voltar", sub_text: "1. Dados do template" } },
                3: { right_button: { text: "4. Revise e salve o template", disabled: true }, left_button: { text: "Voltar", sub_text: "2. Configure o modelo escolhido" } },
                4: { right_button: { text: "Salvar template", disabled: false }, left_button: { text: "Voltar", sub_text: "3. Mensagem alternativa SMS" } }
            },
            template_name: null,
            modal_created: false,
            tooltips_text: {
                1: {
                    title: {
                        active: "Preencha as informa&ccedil;&otilde;es iniciais para cria&ccedil;&atilde;o do seu template",
                        default: "Navegue para o primeiro passo"
                    },
                    description: {
                        active: "Informa&ccedil;&otilde;es coletadas no primeiro passo",
                        default: "Informa&ccedil;&otilde;es coletadas no primeiro passo"
                    }
                },
                2: {
                    title: {
                        ready: "Navegue para o segundo passo",
                        disabled: "Esse passo n&atilde;o est&aacute; dispon&iacute;vel no momento",
                        active: "Escolha o modelo para seu template e configure como desejar",
                        default: "Navegue para o segundo passo"
                    },
                    description: {
                        active: "Informa&ccedil;&otilde;es coletadas no segundo passo",
                        default: "Informa&ccedil;&otilde;es coletadas no segundo passo"
                    }
                },
                3: {
                    title: {
                        ready: "Navegue para o terceiro passo",
                        disabled: "Esse passo n&atilde;o est&aacute; dispon&iacute;vel no momento",
                        active: "Configure uma mensagem alternativa de SMS",
                        default: "Navegue para o segundo passo"
                    },
                    description: {
                        active: "Informa se o fallback foi configurado",
                        default: "Informa se o fallback foi configurado"
                    }
                },
                4: {
                    title: {
                        ready: "Navegue para o &uacute;ltimo passo",
                        disabled: "Esse passo n&atilde;o est&aacute; dispon&iacute;vel no momento",
                        active: "Agora &eacute; s&oacute; confirmar se est&aacute; tudo certinho e salvar seu novo template."
                    }
                }
            },
            confirm_return: false,
            show_notify: false,
            show_dialog_contact_support: false,
            template_utils: new TemplateUtils(),
            id_empresa: this.$cookie === undefined ? getCookie("id_empresa_selected") : this.$cookie.get("id_empresa_selected")
        };
    },
    computed: {
        ...window.Vuex.mapState("templatesrcs", {
            step_one_content: state => state.step_one_content,
            step_two_content: state => state.step_two_content,
            step_three_content: state => state.step_three_content,
            id_template_edit: state => state.id_template_edit
        }),
        ...window.Vuex.mapState("utils", {
            list_clients_for_select: state => state.list_clients_for_select
        }),
        step_active() {
            for (let step of this.steps) {
                if (step.active) {
                    return step;
                }
            }

            return null;
        },
        steps_tour() {
            let tour = [];

            switch (this.step_active.step) {
            case 1:
                tour = [
                    {
                        target: "[data-v-step='1']",
                        title: "Passo a passo",
                        content: "<p>Utilize o passo a passo para configurar seu template, preencha os campos obrigat&oacute;rios para avan&ccedil;ar as etapas.</p>",
                        date: "2022-10-07 00:00:00"
                    },
                    {
                        target: "[data-v-step='2']",
                        title: "Dados do template",
                        content: "Preencha o nome do seu template, defina uma carteira e escolha entre os modelos dispon&iacute;veis."
                    },
                    {
                        target: "[data-v-step='footerActionRight']",
                        title: "Pr&oacute;ximo passo",
                        content: "<p>Utilize o <b>passo-a-passo</b> ou o <b>bot&atilde;o abaixo</b> para prosseguir.</p>",
                    }
                ];
                break;
            case 2:
                tour = [
                    {
                        target: "[data-v-step='4']",
                        title: "Escolha o modo de configura&ccedil;&atilde;o.",
                        content: "Escolha o modo de configura&ccedil;&atilde;o mais adequado para seu novo template. Voc&ecirc; poder&aacute; alternar entre os modos a qualquer momento sem perder o que j&aacute; foi criado.",
                        date: "2022-10-07 00:00:00"
                    }
                ];

                if (Object.keys(this.step_two_content).length > 0) {
                    tour.push({
                        target: "[data-v-step='5']",
                        title: "Pr&eacute; visualiza&ccedil;&atilde;o da sua mensagem",
                        content: "Aqui ser&aacute; exibida a pr&eacute; visualiza&ccedil;&atilde;o da sua mensagem, ou seja, uma simula&ccedil;&atilde;o de como a mensagem deve chegar no aparelho do destinat&aacute;rio."
                    });
                }
                break;
            case 3:
                tour = [
                    {
                        target: "[data-v-step='6']",
                        title: "Configurar mensagem SMS alternativa",
                        content: "<p>&Eacute; poss&iacute;vel cadastrar uma mensagem alternativa de SMS para os contatos que ainda n&atilde;o conseguem receber RCS.</p><p>Para isso, &eacute; necess&aacute;rio ter o canal SMS contratado.</p>"
                    }
                ];
                break;
            case 4:
                tour = [
                    {
                        target: "[data-v-step='7']",
                        title: "Resumo do seu template",
                        content: "<p>Neste momento voc&ecirc; poder&aacute; conferir toda a configura&ccedil;&atilde;o feita no seu template.</p><p>Voc&ecirc; pode voltar aos passos a qualquer momento para visualizar e editar a configura&ccedil;&atilde;o.</p>",
                        date: "2022-10-07 00:00:00"
                    },
                    {
                        target: "[data-v-step='buttonDeleteReview']",
                        title: "Comece tudo do zero",
                        content: "<p>Voc&ecirc; pode limpar tudo que configurou e come&ccedil;ar do zero.</p><p>Aten&ccedil;&atilde;o: Utilizando esse bot&atilde;o, voc&ecirc; ir&aacute; limpar tudo o que fez at&eacute; aqui.</p>"
                    },
                    {
                        target: "[data-v-step='8']",
                        title: "Fa&ccedil;a um disparo de teste",
                        content: "<p>Voc&ecirc; pode fazer um disparo do seu template para at&eacute; 3 n&uacute;meros.</p><p><b>Obs</b>: O campos personalizados ser&atilde;o apresentados mas os dados n&atilde;o ser&atilde;o substitu&iacute;dos.</p>"
                    },
                    {
                        target: "[data-v-step='footerActionRight']",
                        title: "Salve seu template",
                        content: "<p>Est&aacute; satisfeito com o template criado? Clique em &ldquo;salvar template&rdquo; e comece a utilizar em seus envios.</p>"
                    }
                ];
                break;
            }

            return tour;
        }
    },
    watch: {
        step_one_content() {
            if (this.checkStepOne()) {
                this.toggleStepReady(2, true);
                if (this.checkStepTwo()) {
                    this.toggleStepReady(3, true);
                    this.toggleStepReady(4, true);
                }
            } else {
                this.toggleStepReady(2, false);
                this.toggleStepReady(3, false);
                this.toggleStepReady(4, false);
            }
        },
        step_two_content() {
            if (this.checkStepTwo()) {
                this.toggleStepReady(3, true);
                this.toggleStepReady(4, true);
            } else {
                this.toggleStepReady(3, false);
                this.toggleStepReady(4, false);
            }
        },
        step_three_content() {
            this.checkStepThree();
        }
    },
    mounted() {
        this.checkEditTemplate();
        this.ActionDoGetMinhasEmpresas();

        //Verifica se na URL tem o parametro para editar o fallback
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);

        if (params.get("fallback")) { //Se tiver o parametro fallback
            this.goToStep(3);//Vai para o passo 3
        }
    },
    methods: {
        ...window.Vuex.mapActions("templatesrcs", ["ActionDoCreateTemplateRcs", "ActionDoGetTemplateRcsById", "ActionDoUpdateTemplateRcs"]),
        ...window.Vuex.mapActions("utils", ["ActionDoGetMyClients"]),
        ...window.Vuex.mapActions("auth", ["ActionDoGetMinhasEmpresas"]),
        /**
         * Verifica se eh edicao de template, e valida se o ID informado pertence a empresa da sessao ativa
         * @vue
         */
        checkEditTemplate() {
            let query_params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });

            if (query_params.id) {
                this.ActionDoGetTemplateRcsById(query_params.id).then(template => {
                    let modelo = {};
                    let button_type = {};
                    let list_fast_response = [];
                    let list_suggestions = [];
                    let list_messages = [];
                    let list_title = [];
                    let image = null;
                    let contents = null;
                    let card = new CardRcsClass({});

                    let ds_template = JSON.parse(template.DS_TEMPLATE_TAES);

                    // Monta os dados para configurar o modelo e o tipo de modelo
                    switch (template.TP_TEMPLATE_TAES) {
                    case "M":
                        modelo = {
                            label: "Texto",
                            icon: "$templateTextIcon",
                            icon_small: "$templateTextIcon",
                            value: "text"
                        };

                        if (ds_template.suggestions && ds_template.suggestions !== undefined && ds_template.suggestions.length > 0) {
                            let { have_buttons, have_fast_responses, fast_response, suggestions } = this.mountSuggestions(ds_template.suggestions);
                            list_fast_response = fast_response;
                            list_suggestions = suggestions;

                            if (have_buttons && have_fast_responses) {
                                button_type = {
                                    icon: "$completoIcon",
                                    icon_small: "$completoSmallIcon",
                                    title: "Completo",
                                    type: "completo"
                                };
                            } else if (have_buttons) {
                                button_type = {
                                    icon: "$botoesIcon",
                                    icon_small: "$botoesSmallIcon",
                                    title: "Bot&otilde;es",
                                    type: "botoes"
                                };
                            } else if (have_fast_responses) {
                                button_type = {
                                    icon: "$respostasRapidasIcon",
                                    icon_small: "$respostasRapidasSmallIcon",
                                    title: "Respostas r&aacute;pidas",
                                    type: "respostas_rapidas"
                                };
                            }
                        } else {
                            button_type = {
                                icon: "$simplesIcon",
                                icon_small: "$simplesSmallIcon",
                                title: "Simples",
                                type: "simples"
                            };
                        }

                        list_messages = this.mountMessageOrTitleList(ds_template.text ?? "");
                        break;
                    case "I":
                        modelo = {
                            label: "Imagem",
                            icon: "$templateImageIcon",
                            icon_small: "$templateImageIcon",
                            value: "image"
                        };

                        if (template.DS_PARAMETRO_TAES === "{}") {
                            button_type = {
                                icon: "$imageIcon",
                                icon_small: "$imageSmallIcon",
                                title: "&Uacute;nica",
                                type: "imagem_unica"
                            };
                        } else {
                            button_type = {
                                icon: "$imageVariableIcon",
                                icon_small: "$imageVariableSmallIcon",
                                title: "Personalizada",
                                type: "imagem_personalizada"
                            };
                        }

                        image = ds_template.url === "{url}" ? "variable" : ds_template.url;
                        break;
                    case "C":
                        modelo = {
                            label: "Card",
                            icon: "$templateCardIcon",
                            icon_small: "$templateCardIcon",
                            value: "card"
                        };

                        contents = ds_template.contents !== undefined ? ds_template.contents : ds_template.content !== undefined ? ds_template.content : null;
                        if (contents.suggestions && contents.suggestions !== undefined && contents.suggestions.length > 0) {
                            let { have_buttons, have_fast_responses, fast_response, suggestions } = this.mountSuggestions(contents.suggestions);
                            list_fast_response = fast_response;
                            list_suggestions = suggestions;

                            if (have_buttons && have_fast_responses) {
                                button_type = {
                                    icon: "$completoIcon",
                                    icon_small: "$completoSmallIcon",
                                    title: "Completo",
                                    type: "completo"
                                };
                            } else if (have_buttons) {
                                button_type = {
                                    icon: "$botoesIcon",
                                    icon_small: "$botoesSmallIcon",
                                    title: "Bot&otilde;es",
                                    type: "botoes"
                                };
                            } else if (have_fast_responses) {
                                button_type = {
                                    icon: "$respostasRapidasIcon",
                                    icon_small: "$respostasRapidasSmallIcon",
                                    title: "Respostas r&aacute;pidas",
                                    type: "respostas_rapidas"
                                };
                            }
                        } else {
                            button_type = {
                                icon: "$simplesIcon",
                                icon_small: "$simplesSmallIcon",
                                title: "Simples",
                                type: "simples"
                            };
                        }

                        list_messages = this.mountMessageOrTitleList(contents.description ?? "");
                        list_title = this.mountMessageOrTitleList(contents.title ?? "");

                        image = contents.media?.file?.url ?? "";
                        image = image === "{url}" ? "variable" : image;

                        card.image = image;
                        card.title = list_title ?? "";
                        card.description = list_messages ?? "";
                        card.suggestionButtons = list_suggestions;
                        card.suggestionResponses = list_fast_response;
                        card.orientation = ds_template.orientation ?? "";
                        card.alignment = ds_template.alignment ?? "";
                        card.image_height = contents.media?.height ?? "";
                        break;
                    }

                    // Comita as informacoes do passo um e o id em edicao
                    this.$store.commit("templatesrcs/" + types.SET_ID_TEMPLATE_EDIT, template.ID_TEMPLATE_APPS_TAES);
                    let step_one = {
                        template_code: template.CD_TEMPLATE_TAES,
                        template_name: template.NM_TEMPLATE_TAES,
                        modelo: modelo,
                        clients: parseInt(template.ID_EMPRESA_TAES) === parseInt(this.id_empresa) ? [-1] : [template.ID_EMPRESA_TAES],
                        client_description: parseInt(template.ID_EMPRESA_TAES) === parseInt(this.id_empresa) ? "Todos" : ""
                    };

                    this.$store.commit("templatesrcs/" + types.SET_STEP_ONE, step_one);

                    this.$store.commit("templatesrcs/" + types.SET_STEP_TWO, {
                        button_type: button_type,
                        image: image,
                        list_messages: list_messages,
                        list_fast_response: list_fast_response,
                        list_suggestions: list_suggestions,
                        list_chips_preview: [...list_fast_response, ...list_suggestions],
                        card: card
                    });

                    if (template.fallback && template.fallback.length) {
                        let ds_fallback = "";

                        try {
                            ds_fallback = JSON.parse(template.fallback[0].DS_TEMPLATE_TAES).text;
                        } catch (error) {
                            ds_fallback = template.fallback[0].DS_TEMPLATE_TAES;
                        }
                        this.$store.commit("templatesrcs/" + types.SET_STEP_THREE, { list_messages: this.mountMessageOrTitleList(ds_fallback) });
                    }

                    this.toggleStepReady(2, true);
                    this.toggleStepReady(3, true);
                    this.toggleStepReady(4, true);

                    this.getClients();
                    this.loading_content = false;
                }).catch(() => {
                    this.redirectPage();
                });
            } else {
                this.$store.commit("templatesrcs/" + types.SET_ID_TEMPLATE_EDIT, null);
                this.getClients();
                this.loading_content = false;
            }
        },
        /**
         * Monta a lista de suggestions de botões e respostas rapidas
         * @vue
         */
        mountSuggestions(suggestions) {
            let icons_buttons = {
                "url": {
                    icon: "fa-globe-americas",
                    type: "link"
                },
                "dial_phone": {
                    icon: "fa-phone-alt",
                    type: "phone"
                },
                "show_location": {
                    icon: "fa-map-marker-alt",
                    type: "map"
                },
                "request_location": {
                    icon: "fa-crosshairs",
                    type: "localization"
                }
            };

            let result = {
                have_buttons: false,
                have_fast_responses: false,
                fast_response: [],
                suggestions: []
            };

            for (let index in suggestions) {
                if (suggestions[index].type === "text") {
                    result.have_fast_responses = true;

                    result.fast_response.push(new DraggableItemClass({
                        text: suggestions[index].label,
                        type: "pill",
                        custom: true, // TODO: Como saber se eh custom?,
                        order: index,
                        pill: new BasePillClass({
                            title: suggestions[index].label,
                            prependIcon: "fa-grip-vertical",
                            draggable: true,
                            editable: true,
                            deletable: true,
                            order: index
                        })
                    }));
                } else if (["url", "dial_phone", "show_location", "request_location"].indexOf(suggestions[index].type) > -1) {
                    result.have_buttons = true;

                    result.suggestions.push(new DraggableItemClass({
                        text: suggestions[index].label,
                        type: "pill",
                        custom: true,
                        order: index,
                        pill: new BasePillClass({
                            title: suggestions[index].label,
                            subTitle: suggestions[index].type === "show_location" ? `${suggestions[index].value.latitude}, ${suggestions[index].value.longitude}` : suggestions[index].value,
                            prependIcon: "fa-grip-vertical",
                            titleIcon: icons_buttons[suggestions[index].type].icon,
                            draggable: true,
                            editable: true,
                            deletable: true,
                            subTitleNewLine: true,
                            type: icons_buttons[suggestions[index].type].type,
                            order: index
                        })
                    }));
                }
            }

            return result;
        },
        /**
         * Monta a lista de mensagens ou titulo
         * @vue
         */
        mountMessageOrTitleList(message_or_title) {
            let list_words = [];

            for (let message of message_or_title.split(" ")) {

                if (message[0] === "{" && message[message.length - 1] === "}") {
                    let text = message.replaceAll("{", "").replaceAll("}", "");
                    list_words.push(new DraggableItemClass({
                        text: text,
                        type: "pill",
                        custom: true, // TODO: Como saber se eh custom?
                        pill: new BasePillClass({
                            title: text,
                            subTitle: "", // TODO: Quantos caracteres colocar?
                            prependIcon: "fa-grip-vertical",
                            draggable: true,
                            editable: true,
                            deletable: true,
                            rounded: true,
                            numberCharacters: 1 // TODO: Quantos caracteres colocar?
                        })
                    }));
                } else {
                    list_words.push(new DraggableItemClass({
                        text: message,
                        type: "text",
                        pill: new BasePillClass({})
                    }));
                }
            }

            return list_words;
        },
        /**
         * Monta a descricao do passo um e verifica se os dados foram preenchidos corretamente
         * @returns {Boolean}
         * @vue
         */
        checkStepOne() {
            this.confirm_return = false;
            this.steps[0].description = [];

            if (Object.keys(this.step_one_content).length > 0 && this.step_one_content.template_code && this.step_one_content.template_name && this.step_one_content.modelo !== null && this.step_one_content.clients.length) {
                this.steps[0].description.push({ key: "Clientes:", value: this.step_one_content.client_description});
                this.steps[0].description.push({ key: "C&oacute;digo:", value: this.step_one_content.template_code});
                this.steps[0].description.push({ key: "Nome:", value: this.step_one_content.template_name});
                this.steps[0].description.push({ key: "Modelo:", value: this.step_one_content.modelo.label });
                this.confirm_return = true;
                return true;
            } else {
                return false;
            }
        },
        /**
         * Monta a descricao do passo dois e verifica se os dados foram preenchidos corretamente
         * @returns {Boolean}
         * @vue
         */
        checkStepTwo() {
            this.steps[1].description = [];

            if (Object.keys(this.step_two_content).length > 0 && (this.step_two_content.list_messages.length > 0 || this.step_two_content.card?.title?.length > 0 || this.step_two_content.image)) {
                this.steps[1].description.push({ key: this.getTranslateButtonType(this.step_two_content.button_type.type) });
                return true;
            } else {
                return false;
            }
        },
        /**
         * Monta a descricao do passo tres
         * @returns {Boolean}
         * @vue
         */
        checkStepThree() {
            let description = "N&atilde;o";

            if (this.step_three_content && Object.keys(this.step_three_content).length > 0) {
                description = "Sim";
            }
            this.steps[2].description = [{ key: description }];
        },
        /**
         * Funcao responsavel por mudar os valores do {@link ButtonFormStep}
         * @vue
         * @param {Number} step 
         * @param {Boolean} ready 
         */
        toggleStepReady(step, ready) {
            this.footer_config[step - 1].right_button.disabled = !ready;

            let new_steps = [];
            for (let item of this.steps) {
                item.ready = false;

                if (item.step === step) {
                    item.ready = ready;
                    item.disabled = !ready;
                }

                if (item.step === step - 1) {
                    item.line = ready;
                }

                new_steps.push(item);
            }

            this.steps = new_steps;
        },
        /**
         * Funcao responsavel por mudar os valores do {@link FooterAction}
         * @vue
         * @param {Number} step 
         */
        goToStep(step) {
            if (step === 5) {
                this.footer_config[--step].right_button.disabled = true; // Desabilita o botão enquanto salva
                this.saveTemplate();
            } else {
                for (let item of this.steps) {
                    if (item.step !== step) {
                        item.active = false;
                    } else {
                        item.active = true;
                    }
                }
            }
        },
        /**
         * Funcao que pega a traducao do type do componente {@link CardLargeChoice}
         * @vue
         * @param {String} button_text 
         * @returns {String}
         */
        getTranslateButtonType(button_text) {
            switch (button_text) {
            case "simples":
                return "Simples";
            case "respostas_rapidas":
                return "Respostas r&aacute;pidas";
            case "botoes":
                return "Bot&otilde;es";
            case "completo":
                return "Completo";
            case "imagem_unica":
                return "&Uacute;nica";
            case "imagem_personalizada":
                return "Parsonalizada";
            }
        },
        /**
         * Limpa todos os campos
         * @vue
         */
        clearAll() {
            this.$store.commit("templatesrcs/" + types.SET_STEP_ONE, {});
            this.$store.commit("templatesrcs/" + types.SET_STEP_TWO, {});
            this.$store.commit("templatesrcs/" + types.SET_STEP_THREE, {});

            for (let step of this.steps) {
                if (step.step === 1) {
                    step.ready = false;
                    step.active = true;
                    step.disabled = false;
                } else {
                    step.ready = false;
                    step.active = false;
                    step.disabled = true;
                }

                step.line = false;
            }
            this.footer_config[(Object.keys(this.footer_config).length)].right_button.disabled = false;
        },
        /**
         * Funcao para salvar o template
         * @vue
         */
        saveTemplate() {
            let data = {
                id_produto: 134, // 134 ID do tipo de produto 'RCS TEMPLATE'
                tipo: this.template_utils.getTypeTemplate(this.step_one_content.modelo.value),
                cd_template: this.step_one_content.template_code.trim().toUpperCase().replaceAll(" ", "_"),
                nm_template: this.step_one_content.template_name.trim(),
                ds_template: this.template_utils.getDsTemplate(this.step_one_content.modelo.value, this.step_two_content),
                ds_parametro: this.template_utils.getDsParametro(this.step_one_content.modelo.value, this.step_two_content),
                clients: this.step_one_content.clients[0] === -1 || this.step_one_content.clients.length === this.list_clients_for_select.length ? [parseInt(this.id_empresa)] : this.step_one_content.clients
            };
            if (this.step_three_content && Object.keys(this.step_three_content).length > 0) {
                data["fallback_content"] = {
                    cd_template: this.step_one_content.template_code.trim().toUpperCase().replaceAll(" ", "_") + "_FALLBACK",
                    nm_template: this.step_one_content.template_name.trim(),
                    ds_template: this.template_utils.getDsTemplate("fallback", this.step_three_content),
                    ds_parametro: this.template_utils.getDsParametro("text", this.step_three_content)
                };
            }

            if (this.id_template_edit) {
                this.ActionDoUpdateTemplateRcs({ id: this.id_template_edit, form_data: data }).then(response => {
                    if (response) {
                        this.show_notify = true;
                        this.template_name = this.step_one_content.template_name.trim();
                        this.modal_created = true;
                    }
                });
            } else {
                this.ActionDoCreateTemplateRcs(data).then((response) => {
                    if (response.status) {
                        this.show_notify = true;
                        this.template_name = this.step_one_content.template_name.trim();
                        this.modal_created = true;
                    }
                });
            }
        },
        /**
         * Funcao para redirecionar a pagina
         * @vue
         */
        redirectPage() {
            window.location.href = this.base_service.baseDomainPhp() + "/Template";
        },
        /**
         * Busca as configuracoes de tooltip
         * @param {Object} item_step {@link FormStepClass}
         * @param {String} type description ou title
         * @vue
         */
        getTooltipStep(item_step, type) {
            // Verifica se existem configuracoes de tooltip para o passo informado
            if (this.tooltips_text[item_step.step] !== undefined) {
                // Verifica se existem configuracoes de tooltip para o tipo informado
                if (this.tooltips_text[item_step.step][type] !== undefined) {
                    let status = item_step.active ? "active" : item_step.ready ? "ready" : item_step.disabled ? "disabled" : "default";
                    let config_tooltip = this.tooltips_text[item_step.step][type][status];

                    // Verifica se existem configuracoes de tooltip para o estado do passo informado
                    if (config_tooltip !== undefined && config_tooltip !== null) {
                        return config_tooltip;
                    }
                }
            }

            return null;
        },
        /**
         * Limpa os dados e fecha a modal de confirmacao da criacao do template
         * @vue
         */
        closeModalConfirm() {
            this.clearAll();
            this.modal_created = false;
        },
        /**
         * Busca os clientes que o usuario tem acesso
         * @vue
         */
        getClients() {
            this.$store.commit("utils/" + types_utils.SET_CLIENTS_LOADING, true);
            this.ActionDoGetMyClients();
        },
        /**
         * Redireciona o usuario para a criacao de template
         * @vue
         */
        newTemplate() {
            window.location.href = this.base_service.baseDomainPhp() + "/Template/criarTemplateRcs";
        }
    },
    template: (
        /*html*/
        `
        <v-container>
            <v-card v-if="!loading_content" elevation="0" class="mb-10">
                <v-row class="mb-15 pb-16 pb-sm-0" no-gutters>
                    <v-col cols="12">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <page-header
                                    tutorial
                                    :title="id_template_edit ? 'Editar template' : 'Cadastro de template'"
                                    :description="id_template_edit ? 'Aqui voc&ecirc; poder&aacute; realizar altera&ccedil;&otilde;es em seu template.' : 'Aqui voc&ecirc; pode criar novos modelos de template de mensagem.'"
                                    page-return-name="Templates de mensagem"
                                    page-return-link="/Template"
                                    page-return-tooltip="Volte para a listagem de templates"
                                    :confirm-return="confirm_return"
                                    @tutorial="$refs.tour.start()"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="step_active.step === 1 && !id_template_edit" no-gutters class="mt-n5 mb-5">
                            <v-col cols="12" @click='show_dialog_contact_support = true'>
                                <base-information description="Para cadastrar templates do tipo carrossel entre em <a class='text-dark-primary'>contato com o suporte</a>"></base-information>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="8" offset-sm="2" md="3" offset-md="0" v-bind:data-v-step="1">
                                <v-row no-gutters class="flex-nowrap flex-md-wrap overflow-x-auto">
                                    <v-col cols="auto" sm="4" md="12" v-for="step in steps" :key="step.step" :class="step.line ? 'mb-9' : 'mb-15'">
                                        <button-form-step
                                            :step="step.step"
                                            :title="step.title"
                                            :ready="step.ready"
                                            :active="step.active"
                                            :disabled="step.disabled"
                                            :line="step.line"
                                            :tooltip-title="getTooltipStep(step, 'title')"
                                            :tooltip-description="getTooltipStep(step, 'description')"
                                            @click="goToStep(step.step)"
                                        >
                                            <v-row v-if="step.description.length" no-gutters>
                                                <v-col cols="12">
                                                    <v-row v-for="description in step.description" no-gutters>
                                                        <v-col cols="auto">
                                                            <span class="caption text-gray-500">{{ description.key | htmlEntities }} <span v-if="description.value" class="bold">{{ description.value | htmlEntities}}</span></span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </button-form-step>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="8" offset-sm="2" md="9" offset-md="0" class="mb-1">
                                <cadastro-template-passo-um v-if="step_active.step === 1"></cadastro-template-passo-um>
                                <cadastro-template-passo-dois v-if="step_active.step === 2"></cadastro-template-passo-dois>
                                <cadastro-template-passo-tres v-if="step_active.step === 3"></cadastro-template-passo-tres>
                                <cadastro-template-passo-quatro v-if="step_active.step === 4" @clearAll="clearAll"></cadastro-template-passo-quatro>
                                <modal-created-template
                                    :controlModal="modal_created"
                                    :template-name="template_name"
                                    @click="redirectPage"
                                    @clickSubAction="newTemplate"
                                    @closeOnEsc="closeModalConfirm()"
                                    @close="closeModalConfirm()"
                                ></modal-created-template>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <footer-action
                            :btn-right-text="footer_config[step_active.step].right_button.text"
                            :btn-right-icon="step_active.step === 4 ? 'mdi-check-bold' : 'mdi-chevron-right'"
                            :btn-right-disabled="footer_config[step_active.step].right_button.disabled"
                            :sub-text-left="footer_config[step_active.step].left_button.sub_text"
                            :btn-left-text="footer_config[step_active.step].left_button.text"
                            btn-left-icon="fa-undo"
                            :btn-left-disabled="false"
                            :btn-right-tooltip="step_active.step === 4 ? 'Salve e comece a utilizar seu novo template em suas campanhas' : 'Avan&ccedil;ar para o pr&oacute;ximo passo'"
                            btn-left-tooltip="Voltar para o passo anterior"
                            @btnRightClick="goToStep(step_active.step + 1)"
                            @btnLeftClick="goToStep(step_active.step - 1)"
                        ></footer-action>
                    </v-col>
                </v-row>
                <pg-tour ref="tour" :steps="steps_tour"></pg-tour>
                <base-notify
                    v-model="show_notify"
                    :title="id_template_edit ? 'Seu template foi salvo com sucesso!' : 'Seu template foi criado com sucesso!'"
                    :message="id_template_edit ? 'Seu template j&aacute; est&aacute; pronto para ser utilizado em campanhas' : 'Agora seu template j&aacute; est&aacute; pronto para ser utilizado em campanhas'"
                    type="success"
                ></base-notify>
            </v-card>
            <v-card v-else elevation="0">
                <v-row class="px-8 mb-15 pb-16 pb-sm-0" no-gutters>
                    <v-col cols="12">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-skeleton-loader class="py-4" width="256" type="text"></v-skeleton-loader>
                                <v-skeleton-loader width="170" type="heading"></v-skeleton-loader>
                                <v-skeleton-loader class="mt-2 mb-8" width="315" type="text"></v-skeleton-loader>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="8" offset-sm="2" md="3" offset-md="0">
                                <v-row no-gutters class="flex-nowrap flex-md-wrap overflow-x-auto">
                                    <v-col cols="auto" sm="4" md="12" v-for="step in 4" class="mb-9">
                                        <v-skeleton-loader width="100%" type="list-item-avatar-two-line"></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="8" offset-sm="2" md="9" offset-md="0" class="mb-1">
                                <v-row no-gutters>
                                    <v-col cols="12" md="5" lg="4" class="pa-lg-8">
                                        <v-row no-gutters>
                                            <v-col class="p-0">
                                                <v-skeleton-loader width="150" type="heading"></v-skeleton-loader>
                                                <v-row no-gutters class="mt-8">
                                                    <v-col>
                                                        <v-skeleton-loader class="mb-1" width="280" type="text"></v-skeleton-loader>
                                                        <v-skeleton-loader width="306" height="34" type="image"></v-skeleton-loader>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-4">
                                                    <v-col>
                                                        <v-skeleton-loader width="306" height="70" type="image"></v-skeleton-loader>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-8" no-gutters>
                                            <v-col>
                                                <v-row no-gutters>
                                                    <v-col>
                                                        <v-skeleton-loader class="mb-1" width="110" type="text"></v-skeleton-loader>
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-4">
                                                    <v-col cols="6">
                                                        <v-skeleton-loader width="143" height="48" type="image"></v-skeleton-loader>
                                                    </v-col>
                                                    <v-col cols="6">
                                                        <v-skeleton-loader width="143" height="48" type="image"></v-skeleton-loader>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="8" offset="2" offset-sm="0" sm="12" md="5" offset-md="2" lg="6" offset-lg="1" class="mt-2 mt-lg-0" >
                                        <v-skeleton-loader width="100%" height="400" type="image"></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
            <support-contact-dialog v-model="show_dialog_contact_support"></support-contact-dialog>
        </v-container>
        `
    )
};
