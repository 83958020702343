import BaseDialog from "../moleculas/BaseDialog?v=3";
import BaseIconButton from "../atomos/BaseIconButton?v=3";
import BaseButton from "../atomos/BaseButton?v=2";
import BasePill from "../atomos/BasePill?v=2";
import BaseInformation from "../atomos/BaseInformation?v=3";

import * as types from "../../utils/store/mutations-types";

/**
 * Componente de dialog de novidades
 *
 * @requires {@link BaseDialog}
 * @requires {@link BaseIconButton}
 * @requires {@link BaseButton}
 * @requires {@link BasePill}
 * @requires {@link BaseInformation}
 *
 * @displayName DialogNews
 * @category Organismos
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseDialog,
        BaseIconButton,
        BaseButton,
        BasePill,
        BaseInformation
    },
    props: {
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            show: this.value,
            news_date: new Date("2022-11-07 00:00:00"),
            list_news: ["Abril de 2022", "Julho de 2022", "Agosto de 2022", "Outubro de 2022", "Novembro de 2022"],
            news_selected: 4
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            this.$store.commit("utils/" + types.SET_SHOWING_NEWS, this.show);
            this.$emit("input", this.show);

            if (!this.show) {
                this.finishNews();
            }
        }
    },
    async mounted() {
        let form_data = new FormData();
        form_data.append("nome_tela", "/Dashboard");

        await this.ActionDoGetTourInteraction(form_data).then((response) => {
            let have_news = false;

            for (let interaction of response) {
                if (
                    interaction.id_tipo == "4" &&
                    new Date(interaction.last_interaction) > this.news_date
                ) {
                    have_news = true;
                }
            }

            if (!have_news) {
                this.show = true;
            }
        });
    },
    methods: {
        ...window.Vuex.mapActions("utils", [
            "ActionDoSaveStatusTour",
            "ActionDoGetTourInteraction"
        ]),
        /**
         * Finaliza o novidades
         * @vue
         */
        finishNews() {
            let form_data = new FormData();
            form_data.append("nome_tela", "/Dashboard");
            form_data.append("evento", "sair");
            form_data.append("passo", "1");
            form_data.append("bloqueio", "0");
            form_data.append("tipo_tour", "4");

            this.ActionDoSaveStatusTour(form_data);
        },
        prevNews() {
            if (this.news_selected > 0) {
                this.news_selected = this.news_selected - 1;
            }
        },
        nextNews() {
            if (this.news_selected < this.list_news.length - 1) {
                this.news_selected = this.news_selected + 1;
            }
        }
    },
    template:
        /*html*/
        `
        <v-dialog
            v-model="show"
            scrollable
            :fullscreen="$vuetify.breakpoint.width < $vuetify.breakpoint.thresholds.md"
            :max-width="450"
            content-class="bg-gray-0"
        >
            <v-card class="pa-8" elevation="0">
                <v-card-title class="pa-0">
                    <v-btn size="32" icon color="gray-300" absolute right top @click="show = false">
                        <base-icon-button size="32" danger :value="false" icon="mdi-close-thick"></base-icon-button>
                    </v-btn>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <span class="subtitle-3 text-gray-900">Novidades</span>
                        </v-col>
                        <v-col cols="12" class="mt-3">
                            <v-row no-gutters align="center">
                                <v-col cols="auto" class="mr-2">
                                    <span class="caption text-gray-900">{{ list_news[news_selected] }}</span>
                                </v-col>
                                <v-col cols="auto">
                                    <base-icon-button size="40" icon="mdi-chevron-up" :value="false" :disabled="news_selected === 0" @click="prevNews"></base-icon-button>
                                </v-col>
                                <v-col cols="auto">
                                    <base-icon-button size="40" icon="mdi-chevron-down" :value="false" :disabled="news_selected === (list_news.length - 1)" @click="nextNews"></base-icon-button>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text class="pa-0 mt-4 body-2 text-gray-900">
                    <v-row v-if="news_selected === 0" no-gutters>
                        <v-col cols="12">
                            <p>H&aacute; algum tempo estamos trabalhando em diversas melhorias para proporcionar a melhor experi&ecirc;ncia poss&iacute;vel.</p>
                            <p>Mantemos um design pr&oacute;ximo do que voc&ecirc; j&aacute; conhece, por&eacute;m com mais modernidade e praticidade para suas atividades di&aacute;rias.</p>
                            <p>Agora voc&ecirc; pode gerar seus relat&oacute;rios em plano de fundo e fazer o download dos relat&oacute;rios gerados na nova p&aacute;gina: <a target='_blank' style='display: unset; font-size: 14px;' href='/Relatorios'>Meus relat&oacute;rios</a></p>
                            <p>
                                <strong>Quais telas est&atilde;o com novidades?</strong><br />
                                <a target='_blank' style='display: unset; font-size: 14px;' href='/Dashboard'>Dashboard - Tela inicial</a> <br />
                                <a target='_blank' style='display: unset; font-size: 14px;' href='/Campanha'>Gerenciar - Jobs &gt; Gerenciar</a><br />
                                <a target='_blank' style='display: unset; font-size: 14px;' href='/AvulsoApi'>Avulsos API - Jobs &gt; Avulso/API</a><br />
                                <a target='_blank' style='display: unset; font-size: 14px;' href='/BlackList'>Blacklist - Jobs &gt; Blacklist</a><br />
                                <a target='_blank' style='display: unset; font-size: 14px;' href='/EnvioRapido'>Envio r&aacute;pido - Jobs &gt; Envio R&aacute;pido</a><br />
                                <a target='_blank' style='display: unset; font-size: 14px;' href='/Relatorios'>Meus relat&oacute;rios - Relat&oacute;rios &gt; Meus relat&oacute;rios</a><br />
                                Detalhes da campanha - Escolha alguma campanha na Dashboard ou em Gerenciar e clique no bot&atilde;o detalhes <v-icon color="dark-primary">$openOutlineIcon</v-icon>
                            </p>
                            <p>
                                <strong>O que atualizamos?</strong><br />
                                <strong>Mais clean, com certeza.</strong> Criamos uma interface mais intuitiva e de f&aacute;cil entendimento, tanto para quem &eacute; novo usu&aacute;rio quanto para quem j&aacute; usa a um tempinho&hellip;
                            </p>
                            <p><strong>Mais informa&ccedil;&otilde;es, mas sem atrapalhar.</strong> Nossas tabelas e cards est&atilde;o com mais informa&ccedil;&otilde;es e completos, mas fique tranquilo(a), pois temos certeza que vai te ajudar muito.</p>
                            <p><strong>Transparente como deve ser.</strong> Voc&ecirc; n&atilde;o deve ficar com d&uacute;vidas, por isso a maioria dos campos que voc&ecirc; encontrar nas telas ter&atilde;o uma pequena dica para te auxiliar.</p>
                            <p><strong>Mais velocidade.</strong> Algumas partes do nosso sistema que mostravam uma certa instabilidade passaram por diversas melhorias. Com o passar do tempo voc&ecirc; ir&aacute; perceber.</p>
                            <p><strong>E a cereja do bolo.</strong> N&atilde;o paramos por a&iacute;, ainda estamos trabalhando em muita coisa que em breve vai chegar fresquinho pra voc&ecirc;!</p>
                            <p>
                                <strong>Ficou alguma d&uacute;vida?</strong> Disponibilizamos um passo a passo explicativo sobre as fun&ccedil;&otilde;es de cada tela, clique no bot&atilde;o <strong>Precisa de ajuda</strong>, e escolha a op&ccedil;&atilde;o <strong>Quero ver o tutorial</strong>:<br />
                                <img src='/images/precisa_de_ajuda_news.png'></img>
                            </p>
                            <p>
                                <strong>Alguma sugest&atilde;o ou cr&iacute;tica?</strong><br />
                                Utilize o bot&atilde;o de feedback na lateral da tela, ele se parece com isso:<br />
                                <img src='/images/feedback.png'></img>
                            </p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="news_selected === 1">
                        <v-col cols="auto">
                            <v-card elevation="0" class="bg-coral-900 py-1 px-2 rounded-2">
                                <span class="caption bold text-gray-0">NOVOS RECURSOS</span>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-1 text-gray-900">Temos novidades para os templates de RCS!</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <v-card elevation="0" class="bg-primary-blue-50 rounded-2">
                                        <v-img width="252" src="/images/cadastro-template/respostas_rapidas_e_botoes_de_acao.svg" class="container"></v-img>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-2 bold">Respostas r&aacute;pidas</span>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <span class="body-2">Voc&ecirc; pode criar repostas prontas para seu destinat&aacute;rio, assim ele consegue te retornar de forma r&aacute;pida e f&aacute;cil.</span>
                                </v-col>
                                <v-col cols="12" class="mt-4">
                                    <span class="body-2 bold">Bot&otilde;es</span>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <span class="body-2">Voc&ecirc; Crie bot&otilde;es de a&ccedil;&otilde;es para seu destinat&aacute;rio, ele pode te ligar, saber seu endere&ccedil;o, entrar no seu site... E por a&iacute; vai.</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <base-information description="Quer conhecer os novos recursos? Acesse o link abaixo" link-label="RCS > Novo template" link-href="/Template/criarTemplateRcs"></base-information>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="news_selected === 2">
                        <v-col cols="auto">
                            <v-card elevation="0" class="bg-coral-900 py-1 px-2 rounded-2">
                                <span class="caption bold text-gray-0">NOVOS RECURSOS</span>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-1 text-gray-900">Novo gerador de relat&oacute;rios de SMS</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <v-card elevation="0" class="bg-primary-blue-50 rounded-2">
                                        <v-img width="346" height="160" src="/images/cadastro-template/Relatorios-illustration.svg" class="container"></v-img>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-2 bold">Gerador de relat&oacute;rios novinho em folha.</span>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <span class="body-2">Crie relat&oacute;rios dos seus disparos de SMS com mais facilidade e com poucos cliques. Voc&ecirc; pode escolher entre gerar seu relat&oacute;rio com datas espec&iacute;ficas ou por um per&iacute;odo, bem legal n&eacute;?</span>
                                </v-col>
                                <v-col cols="12" class="mt-4">
                                    <span class="body-2 bold">Automatize seu recebimento.</span>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <span class="body-2">Outra novidade &eacute; que voc&ecirc; pode automatizar o recebimento do seu relat&oacute;rio sem precisar voltar ao painel e gerar novamente.</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <base-information description="Quer conhecer a nova tela? Acesse o link abaixo" :link-label="'Gerador de relat&oacute;rios' | htmlEntities" link-href="/Relatorios/novo"></base-information>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <v-divider class="pa-0 ma-0"></v-divider>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-1 text-gray-900">Temos novidades para os templates de RCS!</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <v-card elevation="0" class="bg-primary-blue-50 rounded-2 pt-3 px-6 d-flex justify-center" max-height="160">
                                        <v-img width="252" src="/images/cadastro-template/Img_Onboarding_news.svg" contain></v-img>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-2 bold">Uma imagem vale mais que mil palavras</span>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <span class="body-2">Agora voc&ecirc; pode criar templates de imagem para se comunicar com seus clientes, para campanhas de RCS.</span>
                                </v-col>
                                <v-col cols="12" class="mt-4">
                                    <span class="body-2 bold">&Uacute;nicas ou personalizadas</span>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <span class="body-2">Escolha entre enviar a mesma imagem para todos ou uma imagem diferente para cada contato, confira!</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <base-information description="Quer conhecer os novos recursos? Acesse o link abaixo e escolha o modelo <b>Imagem</b>." link-label="RCS > Novo template" link-href="/Template/criarTemplateRcs"></base-information>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="news_selected === 3">
                        <v-col cols="auto">
                            <v-card elevation="0" class="bg-coral-900 py-1 px-2 rounded-2">
                                <span class="caption bold text-gray-0">NOVOS RECURSOS</span>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-1 text-gray-900">Temos novidades para os templates de RCS!</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <v-card elevation="0" class="bg-primary-blue-50 rounded-2">
                                        <v-img width="346" height="160" src="/images/cadastro-template/News_Card_Ilustration.svg" class="container"></v-img>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-2">Agora voc&ecirc; pode criar templates do tipo <b>Card</b> para <b>RCS</b></span>
                                </v-col>
                                <v-col cols="12" class="mt-4">
                                    <span class="body-2 bold">Tudo junto e misturado</span>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <span class="body-2">Crie cart&otilde;es de mensagem podendo conter imagem, t&iacute;tulo, descri&ccedil;&atilde;o, respostas r&aacute;pidas e bot&otilde;es em um &uacute;nico envio.</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <base-information description="Quer conhecer os novos recursos? Acesse o link abaixo e escolha o modelo <b>Card</b>. " link-label="RCS > Novo template" link-href="/Template/criarTemplateRcs"></base-information>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="news_selected === 4">
                        <v-col cols="auto">
                            <v-card elevation="0" class="bg-coral-900 py-1 px-2 rounded-2">
                                <span class="caption bold text-gray-0">NOVOS RECURSOS</span>
                            </v-card>
                        </v-col>
                        <v-col cols="auto">
                            <v-card elevation="0" class="bg-green-500 py-1 px-2 rounded-2">
                                <span class="caption bold text-gray-0">ATUALIZA&Ccedil;&Atilde;O</span>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-1 text-gray-900">Envie mensagem alternativa de SMS!</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <v-card elevation="0" class="bg-primary-blue-50 rounded-2">
                                        <v-img width="100%" height="160" src="/images/cadastro-template/Ilustra_Novidades_Fallback.svg"></v-img>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-2">Agora &eacute; poss&iacute;vel configurar uma mensagem alternativa para aqueles clientes que ainda n&atilde;o conseguem receber RCS</span>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <span class="body-2">Este novo recurso &eacute; opcional e voc&ecirc; pode configurar durante a cria&ccedil;&atilde;o do seu template.</span>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <span class="body-2">Al&eacute;m do mais, &eacute; poss&iacute;vel editar templates j&aacute; existentes para adicionar uma mensagem alternativa.</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <base-information description="Quer criar um novo template com mensagem alternativa de SMS? Acesse: " link-label="Jobs > Templates de Mensagem > Novo template" link-href="/Template/criarTemplateRcs"></base-information>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <v-divider class="pa-0 ma-0"></v-divider>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-1 text-gray-900">Detalhes da campanha est&aacute; de cara nova!</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <v-card elevation="0" class="bg-primary-blue-50 rounded-2 pt-3 px-6 d-flex justify-center" max-height="160">
                                        <v-img width="252" src="/images/campaigns/Ilustra_Novidades_DetalhesCampanha.svg" contain></v-img>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <span class="body-2">Hoje temos novidades na tela de detalhes da campanha, fizemos algumas altera&ccedil;&otilde;es de interface para te ajudar a encontrar as informa&ccedil;&otilde;es de maneira mais f&aacute;cil!</span>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <span class="body-2">Tamb&eacute;m inclu&iacute;mos um novo card para ver a quantidade de respostas recebidas dos seus contatos e bot&otilde;es de a&ccedil;&atilde;o mais amig&aacute;veis. </span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12">
                                    <base-information description="Quer conhecer a nova tela de detalhes da campanha? Clique sobre o &iacute;cone <span class='icon-btn-detalhes' /> na listagem das suas campanhas na coluna A&ccedil;&otilde;es." />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pa-0 mt-6">
                    <v-row justify="center" no-gutters>
                        <v-col cols="12">
                            <base-button
                                primary
                                label="Obrigado"
                                icon="fa-check"
                                block
                                @click="show = false"
                            ></base-button>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        `
};
