export default {
    template: (
        /*html*/
        `
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_360_1226)">
                    <path d="M14.8749 0.0999837L0.390555 8.45623C-0.17507 8.78123 -0.103195 9.56873 0.459305 9.80623L3.78118 11.2L12.7593 3.28748C12.9312 3.13436 13.1749 3.36873 13.0281 3.54686L5.49993 12.7187V15.2344C5.49993 15.9719 6.39055 16.2625 6.82805 15.7281L8.81243 13.3125L12.7062 14.9437C13.1499 15.1312 13.6562 14.8531 13.7374 14.375L15.9874 0.874984C16.0937 0.243734 15.4156 -0.212516 14.8749 0.0999837Z" fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_360_1226">
                        <rect width="16" height="16" fill="currentColor"/>
                    </clipPath>
                </defs>
            </svg>
        `
    )
};