import BaseIconButton from "../../../components/atomos/BaseIconButton";

export default {
    components: {
        BaseIconButton
    },
    data() {
        return {
            icon: "mdi-close-thick",
            tooltip: "Some text",
            active: false,
            danger: false,
            dark: false,
            size: 16
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6" class="text-center">
                    <v-row>
                        <v-col>
                            <v-card :class="'pa-5 ' + (dark ? 'bg-gray-900 text-gray-0' : 'bg-gray-0 text-gray-900')">
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field label="Icone" v-model="icon"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Texto do tooltip" v-model="tooltip"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Tamanho" v-model="size"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-checkbox label="Ativo" v-model="active"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-checkbox label="Danger" v-model="danger"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-checkbox label="Dark" v-model="dark"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="d-flex justify-center">
                                        <base-icon-button
                                            v-model="active"
                                            :icon="icon"
                                            :tooltip="tooltip"
                                            :danger="danger"
                                            :dark="dark"
                                            :size="size"
                                        ></base-icon-button> 
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};