import BaseButton from "../atomos/BaseButton?v=2";
import ButtonHeader from "../atomos/ButtonHeader";

/**
 * Componente de Subheader
 * 
 * @displayName SubHeader
 * @component
 * @category Moleculas
 * @vue
 * @author Igor Mateus Rodrigues de Oliveira <igor.oliveira@pgmais.com.br>
 */
export default {
    name: "sub-header",
    components: {
        BaseButton,
        ButtonHeader
    },
    props: {
        /**
         * Indica se o componente esta sendo renderizado de uma tela antiga
         * @values true, false
         */
        vue_bootstrap: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Lista de bot�es de produtos a serem apresentados
         */
        product_buttons: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            feedback_button: {
                icon: "fa fa-bullhorn",
                label: "Feedback",
                variant: "light-primary",
                icon_left: false,
                disabled: false
            }
        };
    },
    methods: {
        /**
         * Emite um evento informando que um bot�o foi clicado
         * @category Moleculas
         * @vue
         */
        changeButtonChannel: function (value) {
            /**
             * Evento click do botao
             * @category Moleculas
             * @event clickChannel
             * @vue 
             */
            this.$emit("clickChannel", value);
        },
        /**
         * Emite um evento informando que o bot�o de feedback foi clicado
         * @category Moleculas
         * @vue
         */
        buttonFeedback() {
            /**
             * Evento click do botao de feedback
             * @category Moleculas
             * @event clickFeedback
             * @vue 
             */
            this.$emit("clickFeedback");
        }
    },
    computed: {
        class_row_primary() {
            let class_row = "row";

            if (this.vue_bootstrap) {
                class_row += " my-4";
            } else {
                class_row += " my-5";
            }

            return class_row;
        },
        class_col_primary() {
            let class_col = "col col-12";

            if (this.vue_bootstrap) {
                class_col += " px-5";
            } else {
                //class_col += " px-16";
            }

            return class_col;
        }
    },
    template: (
        /*html*/
        `
        <div class="row bg-super-dark-primary mb-3 no-gutters">
            <div :class="class_col_primary">
                <div :class="vue_bootstrap ? '' : 'container'">
                    <div :class="class_row_primary + ' no-gutters px-0 px-md-6'">
                        <div class="col-12 col-lg-6">
                            <div class="row no-gutters">
                                <div class="col col-auto d-flex align-items-center">
                                    <span class="text-white font-size-12">Canais:</span>
                                </div>
                                <div v-for="(product, index) in product_buttons" :key="index" class="col col-md-3 col-xl-2 px-1">
                                    <button-header 
                                        :icon='product.icon'
                                        :label='product.label'
                                        :variant="product.variant"      
                                        :left="product.icon_left"
                                        :disabled="product.disabled"
                                        :active="product.active"
                                        :loading="product.loading"
                                        :vue_bootstrap="vue_bootstrap"
                                        @click="changeButtonChannel"
                                    ></button-header>
                                </div>
                            </div>
                        </div>
                        <div class="col col-4 col-lg-6 d-none">
                            <div class="row justify-content-end no-gutters">
                                <div class="col col-3">
                                    <base-button
                                        block
                                        width="200px"
                                        :icon='feedback_button.icon'
                                        :label='feedback_button.label'
                                        :color="feedback_button.variant"      
                                        :left="feedback_button.icon_left"
                                        :disabled="feedback_button.disable"
                                        @click="buttonFeedback"
                                    ></base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `
    )
};