//const tipos_template_rcs = { "r": "Carrossel", "m": "Texto", "t": "Template", "i": "Imagem", "v": "Video", "a": "Audio", "f": "Arquivo", "c": "Card" };
import { DraggableItemClass } from "../classes/DraggableItemClass";

const tipos_template_rcs = {
    m: "Texto",
    r: "Carrossel",
    i: "Imagem",
    v: "Video",
    c: "Card"
};

export default class TemplateUtils {
    /**
     * Retorna os tipos de template de RCS no formato que pode ser utilizado por um {@link BaseAutocomplete}
     * @vue
     * @return {[Object]}
     */
    getTiposTemplateToAutocomplete() {
        let keys = Object.keys(tipos_template_rcs);
        let data = [];

        for (let key of keys) {
            data.push({
                value: key,
                text: tipos_template_rcs[key]
            });
        }

        return data;
    }

    /**
     * Retorna as chaves dos tipos de template de RCS
     *
     * @return  {[Object]}
     */
    getTiposTemplateKeys() {
        return Object.keys(tipos_template_rcs);
    }

    /**
     * Busca a tradu��o do tipo de template
     * @vue
     * @param  {String}  tipo  Tipo do template
     * @return {String}
     */
    getTraducaoTipoTemplateApps(tipo) {
        if (tipo != null) {
            if (tipo.length > 0) {
                return tipos_template_rcs[tipo.toLowerCase()];
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    /**
     * Busca o ��cone do tipo de template
     * @vue
     * @param  {String}  tipo  Tipo do template
     * @return {String}
     */
    getIconTipoTemplate(tipo) {
        if (tipo !== null && tipo.length) {
            switch (tipo.toLowerCase()) {
            case "m":
                return "$templateTextIcon";
            case "r":
                return "$templateCarouselIcon";
            case "i":
                return "$templateImageIcon";
            case "c":
                return "$templateCardIcon";
            default:
                return "";
            }
        } else {
            return "$templateTextIcon";
        }
    }

    /**
     * Fun��o para formatar a mensagem
     * @vue
     * @param   {Object} content
     * @returns {String}
     */
    formatMessageWithCustomField(content) {
        let message = "";
        if (typeof content === "string") {
            message += "{" + content.trim().replaceAll(" ", "_") + "} ";
        } else {
            for (let item of content) {
                // se o tipo do item for 'text', concatena ele na mensagem, junto com um espa�o vazio
                if (item.type === "text") {
                    message += item.text + " ";
                // se o tipo do item for 'break-line', concatena ele na mensagem, sem espa�o vazio ao final
                } else if (item.type === "break-line") {
                    message += item.text;
                } else {
                    message += "{" + this.removerAccentos(item.text.trim().replaceAll(" ", "_")) + "} ";
                }
            }
        }

        return message.trim();
    }
    /**
     * Fun��o para formatar a string limpando os caracteres especiais e caso possua vari�vel, adiciona as chaves
     * @param {String} str
     * @return {String}
     * @vue
     */
    formatStringWithVariable(str) {
        if (!str) {
            return "";
        }
        let pattern = /{([^}]+)}/g;
        return str.replace(pattern, (match, p1) => {
            let cleaned = p1.replace(/[^a-zA-Z0-9_]+/g, "");
            return "{" + cleaned.replace(/[{}]+/g, "") + "}";
        });
    }
    /**
     * Pega a estrutura do template
     * @param {String} templateFormat
     * @param {Object} templateContent
     * @vue
     */
    getDsTemplate(templateFormat, templateContent) {
        let template = {};
        let content = {};

        switch (templateFormat) {
        case "fallback":
            template = this.formatMessageWithCustomField(templateContent.list_messages);
            break;
        case "text":
            template = {
                text: this.formatMessageWithCustomField(templateContent.list_messages)
            };

            if (templateContent.list_chips_preview?.length > 0) {
                let suggestions = [];

                for (let item of templateContent.list_chips_preview) {

                    if (item.pill.type) {
                        let title = this.formatStringWithVariable(item.pill.title);
                        let subTitle = this.formatStringWithVariable(item.pill.subTitle);

                        let coordinates = null;
                        switch (item.pill.type) {
                        case "phone":
                            suggestions.push({ type: "dial_phone", label: title, value: subTitle.toString().replace(/[^0-9]+/g, "") });
                            break;
                        case "link":
                            suggestions.push({ type: "url", label: title, value: subTitle });
                            break;
                        case "map":
                            coordinates = subTitle.split(",");
                            if (coordinates.length == 1) {
                                let coordenates_obj = {
                                    latitude: "{" + (coordinates[0].trim().replace(/[{}]/g, "") + 0) + "}",
                                    longitude: "{" + (coordinates[0].trim().replace(/[{}]/g, "") + 1) + "}"
                                };
                                suggestions.push({ type: "show_location", label: title, value: coordenates_obj});

                            } else {
                                suggestions.push({ type: "show_location", label: title, value: { latitude: coordinates[0].trim(), longitude: coordinates[1].trim() } });
                            }
                            break;
                        case "localization":
                            suggestions.push({ type: "request_location", label: title });
                            break;
                        }
                    } else {
                        suggestions.push({ type: "text", label: this.formatStringWithVariable(item.pill.title) });
                    }
                }

                template["suggestions"] = suggestions;
            }
            break;
        case "image":
            template["url"] = templateContent.image === "variable" ? "{url}" : templateContent.image;
            break;
        case "card":
            template["orientation"] = templateContent.card.orientation; // VERTICAL - HORIZONTAL
            template["alignment"] = templateContent.card.alignment ?? "LEFT"; // RIGHT - LEFT

            if (templateContent.list_title) {
                content["title"] = this.formatStringWithVariable(this.formatMessageWithCustomField(templateContent.list_title));
            } else if (templateContent.card.title && templateContent.card.title.length) {
                content["title"] = this.formatStringWithVariable(this.formatMessageWithCustomField(templateContent.card.title));
            } else {
                content["title"] = " ";
            }

            if (templateContent.list_messages) {
                content["description"] = this.formatStringWithVariable(this.formatMessageWithCustomField(templateContent.list_messages));
            } else if (templateContent.card.description && templateContent.card.description.length) {
                content["description"] = this.formatStringWithVariable(this.formatMessageWithCustomField(templateContent.card.description));
            }

            if (templateContent.card.image && templateContent.card.image.length) {
                content["media"] = {
                    "height": templateContent.card.image_height ?? "TALL", // TALL - MEDIUM - SHORT
                    "file": {
                        "url": templateContent.card.image === "variable" ? "{url}" : templateContent.card.image
                    }
                };
            }

            if (templateContent.list_chips_preview.length > 0) {
                let suggestions = [];

                let new_list_suggestions = templateContent.list_chips_preview;
                new_list_suggestions.sort((a, b) => a.order !== null && b.order !== null ? a.order - b.order : 0);

                for (let item of new_list_suggestions) {
                    if (item.pill.type) {
                        let title = item.pill.title ? this.formatStringWithVariable(item.pill.title) : "";
                        let subTitle = item.pill.subTitle ? this.formatStringWithVariable(item.pill.subTitle) : "";

                        let coordinates = null;
                        switch (item.pill.type) {
                        case "phone":
                            suggestions.push({ type: "dial_phone", label: title, value: subTitle.toString().replace(/[^0-9]+/g, "") });
                            break;
                        case "link":
                            suggestions.push({ type: "url", label: title, value: subTitle });
                            break;
                        case "map":
                            coordinates = subTitle.split(",");
                            if (coordinates.length == 1) {
                                let coordenates_obj = {
                                    latitude: "{" + (coordinates[0].trim().replace(/[{}]/g, "") + 0) + "}",
                                    longitude: "{" + (coordinates[0].trim().replace(/[{}]/g, "") + 1) + "}"
                                };
                                suggestions.push({ type: "show_location", label: title, value: coordenates_obj});

                            } else {
                                suggestions.push({ type: "show_location", label: title, value: { latitude: coordinates[0].trim(), longitude: coordinates[1].trim() } });
                            }
                            break;
                        case "localization":
                            suggestions.push({ type: "request_location", label: title });
                            break;
                        }
                    } else {
                        suggestions.push({ type: "text", label: this.formatStringWithVariable(item.pill.title) }); //Ajustes para o tipo card
                    }

                    content["suggestions"] = suggestions;
                }
            }

            template["contents"] = content;
            break;

        case "carousel":
            template["contents"] = [];

            for (let card of templateContent.card) {
                content = {};
                let suggestions = [];

                card["list_chips_preview"] = [...card.suggestionButtons, ...card.suggestionResponses];
                template["orientation"] = card.orientation; // VERTICAL - HORIZONTAL
                template["alignment"] = card.alignment ?? "LEFT"; // RIGHT - LEFT

                if (card.description && card.description.length) {
                    content["description"] = this.formatStringWithVariable(this.formatMessageWithCustomField(card.description));
                }

                if (card.image && card.image.length) {
                    content["media"] = {
                        "height": card.image_height ?? "TALL", // TALL - MEDIUM - SHORT
                        "file": {
                            "url": card.image === "variable" ? "{url}" : card.image
                        }
                    };
                }
                content["suggestions"] = null;
                if (card.list_chips_preview.length > 0) {
                    let new_list_suggestions = card.list_chips_preview;
                    new_list_suggestions.sort((a, b) => a.order !== null && b.order !== null ? a.order - b.order : 0);

                    for (let item of new_list_suggestions) {
                        if (item.pill.type) {
                            let title = item.pill.title ? this.formatStringWithVariable(item.pill.title) : "";
                            let subTitle = item.pill.subTitle ? this.formatStringWithVariable(item.pill.subTitle) : "";

                            let coordinates = null;
                            switch (item.pill.type) {
                            case "phone":
                                suggestions.push({ type: "dial_phone", label: title, value: subTitle.toString().replace(/[^0-9]+/g, "") });
                                break;
                            case "link":
                                suggestions.push({ type: "url", label: title, value: subTitle });
                                break;
                            case "map":
                                coordinates = subTitle.split(",");
                                if (coordinates.length == 1) {
                                    let coordenates_obj = {
                                        latitude: "{" + (coordinates[0].trim().replace(/[{}]/g, "") + 0) + "}",
                                        longitude: "{" + (coordinates[0].trim().replace(/[{}]/g, "") + 1) + "}"
                                    };
                                    suggestions.push({ type: "show_location", label: title, value: coordenates_obj});

                                } else {
                                    suggestions.push({ type: "show_location", label: title, value: { latitude: coordinates[0].trim(), longitude: coordinates[1].trim() } });
                                }
                                break;
                            case "localization":
                                suggestions.push({ type: "request_location", label: title });
                                break;
                            }
                        } else {
                            suggestions.push({ type: "text", label: this.formatStringWithVariable(item.pill.title) }); //Ajustes para o tipo card
                        }
                    }
                    content["suggestions"] = suggestions;
                }
                template["contents"].push(content);
            }
            break;
        }
        return templateFormat === "fallback" ? template : JSON.stringify(template);
    }

    /**
     * Fun��o que pega os parametros do template
     * @vue
     * @param   {String} templateFormat
     * @param   {Object} templateContent
     * @returns {String}
     */
    getDsParametro(templateFormat, templateContent) {
        let list_message = templateContent.list_messages;
        let ds_parametro = {};
        switch (templateFormat) {
        case "text":
            for (let item of list_message) {
                if (item.type === "pill") {
                    ds_parametro[this.removerAccentos(this.formatStringWithVariable(item.text).trim().replaceAll(" ", "_").replace(/[{}]/g, ""))] = "str";
                }
            }

            if (templateContent.list_suggestions) {
                for (let suggestion_buttons of templateContent.list_suggestions) {
                    if (suggestion_buttons.pill.title && suggestion_buttons.pill.title[0] == "{" && suggestion_buttons.pill.title[suggestion_buttons.pill.title.length - 1] == "}") {
                        ds_parametro[this.formatStringWithVariable(suggestion_buttons.pill.title).trim().replaceAll(" ", "_").replace(/[{}]/g, "")] = "str";
                    }

                    if (suggestion_buttons.pill.subTitle && suggestion_buttons.pill.subTitle[0] == "{" && suggestion_buttons.pill.subTitle[suggestion_buttons.pill.subTitle.length - 1] == "}") {

                        if (suggestion_buttons.pill.type === "map") {
                            ds_parametro[this.formatStringWithVariable(suggestion_buttons.pill.subTitle).trim().replaceAll(" ", "_").replace(/[{}]/g, "") + "0"] = "str";
                            ds_parametro[this.formatStringWithVariable(suggestion_buttons.pill.subTitle).trim().replaceAll(" ", "_").replace(/[{}]/g, "") + "1"] = "str";
                        } else {
                            ds_parametro[this.formatStringWithVariable(suggestion_buttons.pill.subTitle).trim().replaceAll(" ", "_").replace(/[{}]/g, "")] = "str";
                        }

                    }
                }
            }

            if (templateContent.list_fast_response) {
                for (let suggestion_responses of templateContent.list_fast_response) {

                    if (suggestion_responses.text && suggestion_responses.text[0] == "{" && suggestion_responses.text[suggestion_responses.text.length - 1] == "}") {
                        ds_parametro[this.formatStringWithVariable(suggestion_responses.text).trim().replaceAll(" ", "_").replace(/[{}]/g, "")] = "str";
                    }
                }
            }
            break;
        case "image":
            if (templateContent.image === "variable") {
                ds_parametro["url"] = "url";
            }
            break;
        case "card":
            for (let description_word of templateContent.card.description) {
                if (description_word.type === "pill") {
                    ds_parametro[this.removerAccentos(this.formatStringWithVariable(description_word.text).trim().replaceAll(" ", "_").replace(/[{}]/g, ""))] = "str";
                }
            }

            for (let title_word of templateContent.card.title) {
                if (title_word.type === "pill") {
                    ds_parametro[this.removerAccentos(this.formatStringWithVariable(title_word.text).trim().replaceAll(" ", "_").replace(/[{}]/g, ""))] = "str";
                }
            }

            if (templateContent.card.suggestionButtons) {
                for (let suggestion_buttons of templateContent.card.suggestionButtons) {
                    if (suggestion_buttons.pill.title && suggestion_buttons.pill.title[0] == "{" && suggestion_buttons.pill.title[suggestion_buttons.pill.title.length - 1] == "}") {
                        ds_parametro[this.formatStringWithVariable(suggestion_buttons.pill.title).trim().replaceAll(" ", "_").replace(/[{}]/g, "")] = "str";
                    }

                    if (suggestion_buttons.pill.subTitle && suggestion_buttons.pill.subTitle[0] == "{" && suggestion_buttons.pill.subTitle[suggestion_buttons.pill.subTitle.length - 1] == "}") {

                        if (suggestion_buttons.pill.type === "map") {
                            ds_parametro[this.formatStringWithVariable(suggestion_buttons.pill.subTitle).trim().replaceAll(" ", "_").replace(/[{}]/g, "") + "0"] = "str";
                            ds_parametro[this.formatStringWithVariable(suggestion_buttons.pill.subTitle).trim().replaceAll(" ", "_").replace(/[{}]/g, "") + "1"] = "str";
                        } else {
                            ds_parametro[this.formatStringWithVariable(suggestion_buttons.pill.subTitle).trim().replaceAll(" ", "_").replace(/[{}]/g, "")] = "str";
                        }

                    }
                }
            }

            if (templateContent.card.suggestionResponses) {
                for (let suggestion_responses of templateContent.card.suggestionResponses) {

                    if (suggestion_responses.text && suggestion_responses.text[0] == "{" && suggestion_responses.text[suggestion_responses.text.length - 1] == "}") {
                        ds_parametro[this.formatStringWithVariable(suggestion_responses.text).trim().replaceAll(" ", "_").replace(/[{}]/g, "")] = "str";
                    }
                }
            }

            if (templateContent.card.image === "variable") {
                ds_parametro["url"] = "url";
            }
            break;
        }
        return JSON.stringify(ds_parametro);
    }

    /**
     * Fun��o que pega o tipo template
     * @vue
     * @param   {String} templateFormat
     * @returns {String}
     */
    getTypeTemplate(templateFormat) {
        const templateCdStructure = {
            text: "M",
            image: "I",
            card: "C",
            carousel: "R"
        };

        //return Object.keys(tipos_template_rcs).find(key => tipos_template_rcs[key] === templateFormat).toUpperCase();
        return templateCdStructure[templateFormat];
    }

    /**
     * Monta o array de bot�es a ser exibido, de acordo com o modelo do template
     * @vue
     * @param   {String} templateFormat
     * @returns {Array}
     */
    getMountButtonsConfig(templateFormat) {
        switch (templateFormat) {
        case "text":
        case "card":
            return [
                {
                    icon: "$simplesIcon",
                    icon_small: "$simplesSmallIcon",
                    title: "Simples",
                    type: "simples",
                    description: "Crie uma mensagem simples podendo ter campos personalizados.",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "$respostasRapidasIcon",
                    icon_small: "$respostasRapidasSmallIcon",
                    title: "Respostas r&aacute;pidas",
                    type: "respostas_rapidas",
                    description: "Crie uma mensagem com respostas r&aacute;pidas e campos personalizados.",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "$botoesIcon",
                    icon_small: "$botoesSmallIcon",
                    title: "Bot&otilde;es",
                    type: "botoes",
                    description: "Crie uma mensagem com bot&otilde;es de a&ccedil;&atilde;o e campos personalizados.",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "$completoIcon",
                    icon_small: "$completoSmallIcon",
                    title: "Completo",
                    type: "completo",
                    description: "Crie uma mensagem utilizando todos os recursos",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                }
            ];
        case "image":
            return [
                {
                    icon: "$imageIcon",
                    icon_small: "$imageSmallIcon",
                    title: "&Uacute;nica",
                    type: "imagem_unica",
                    description: "Envie a mesma imagem para todos os contatos",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "$imageVariableIcon",
                    icon_small: "$imageVariableSmallIcon",
                    title: "Personalizada",
                    type: "imagem_personalizada",
                    description: "Envie uma imagem diferente para cada contato",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                }
            ];
        }
    }

    /**
     * Formata lista de mensagens
     * @param {Array} message_list
     * @returns {Array}
     * @vue
     */
    formatMessageList(message_list) {
        let new_list_message = [];
        if (message_list) {
            if (Array.isArray(message_list)) {
                for (let message of message_list) {
                    if (message.text === undefined) {
                        new_list_message.push(new DraggableItemClass({
                            text: Array.isArray(message) ? message[0] : message,
                        }));
                    } else {
                        // Analisa se existe quebra de linha (\n) nos elementos da lista de mensagem
                        if (message.text.match(/\n/g)) {
                            // Se existir elementos com quebra de linha, realiza um split no caracter '\n' 
                            // salvando o mesmo na lista, e itera sobre a lista resultante
                            for (let word of message.text.split(/(\n)/g)) {
                                // Se o elemento da lista for '\n', adiciona ele na new_list_message como um 
                                // DraggableItemClass, com o tipo 'break-line'
                                if (word === "\n") {
                                    new_list_message.push(new DraggableItemClass({
                                        text: word,
                                        type: "break-line",
                                    }));
                                // Se o elemento n�o for '\n', e se n�o for uma string vazia, adiciona ele na 
                                // new_list_message como um DraggableItemClass, com o tipo 'text'
                                } else if (word != "") {
                                    new_list_message.push(new DraggableItemClass({
                                        text: word,
                                        type: "text",
                                    }));
                                }
                            }
                        } else {
                            new_list_message.push(message);
                        }
                    }
                }

            } else {
                let split_message_list = message_list.split(" ");

                for (let message of split_message_list) {
                    // Analisa se existe quebra de linha (\n) nos elementos da lista de mensagem
                    if (message.match(/\n/g)) {
                        // Se existir elementos com quebra de linha, realiza um split no caracter '\n' 
                        // salvando o mesmo na lista, e itera sobre a lista resultante
                        for (let word of message.split(/(\n)/g)) {
                            // Se o elemento da lista for '\n', adiciona ele na new_list_message como um 
                            // DraggableItemClass, com o tipo 'break-line'
                            if (word === "\n") {
                                new_list_message.push(new DraggableItemClass({
                                    text: word,
                                    type: "break-line",
                                }));
                            // Se o elemento n�o for '\n', e se n�o for uma string vazia, adiciona ele na 
                            // new_list_message como um DraggableItemClass, com o tipo 'text'
                            } else if (word != "") {
                                new_list_message.push(new DraggableItemClass({
                                    text: word,
                                    type: "text",
                                }));
                            }
                        }
                    // Se n�o existir '\n' nos elementos da lista de texto
                    } else {
                        // Se o elemento n�o for uma string vazia, adiciona ele na new_list_message como um 
                        // DraggableItemClass, com o tipo 'text'
                        if (message != "") {
                            new_list_message.push(new DraggableItemClass({
                                text: message,
                                type: "text",
                            }));
                        }
                    }
                }
            }
        }

        for (let item of new_list_message) {
            //let message = item.text.trim().replace(/\s|!|@|#|\$|%|&|\*|\(|\)|-|=|_|\+|,|\.|;|:|\?|<|>|\[|\]|\||`||\^|~|\\|\/|'|"/g, "");
            let message = item.text;

            if (message[0] === "{" && message[message.length - 1] === "}") {
                item.text = message.replaceAll("{", "").replaceAll("}", "");
                item.type = "pill";
            }
        }

        return new_list_message;
    }
    /**
     *
     * Retorna o texto sem os acentos
     * @param word
     * @returns {string}
     */
    removerAccentos(word) {
        return word
            .normalize("NFD")
            .replace(/[\u0300-\u036f|\u00b4|\u0060|\u005e|\u007e|\u00A8]/g, "");
    }

    /**
     * Recebe o texto de um template e substitui os valores de campo pelos valores informados
     * com os atributos name e placeholder = nome do campo
     * @param template {string}
     * @param valores {Object} - os indices devem ser os nomes dos campos
     * @param campos_negrito {boolean} - se true retorna um html com os campos dentro de tags <b></b>
     * @returns {string|html}
     */
    preencherCamposTemplate(template, valores, campos_negrito = false) {
        let resultado = template;

        const getRegExp = (key) => new RegExp(`\\[${key}\\]|\\{${key}\\}`, "gm");

        Object.keys(valores).forEach((key) => {
            const valor = (valores[key] && valores[key].toString().trim()) || "";
            const substituicao = campos_negrito ? `<b>${valor}</b>` : valor;
            resultado = resultado.replace(getRegExp(key), substituicao);
        });

        if (campos_negrito) {
            resultado = resultado.replace(/(\[\w+\]|\{\w+\})/gm, "<b>$1</b>");
        }
        return resultado;
    }

}