import BaseSectionForm from "../../../components/atomos/BaseSectionForm";
import GroupCardExtraLarge from "../../../components/moleculas/GroupCardExtraLarge";
import { BaseCardExtraLargeClass } from "../../../classes/BaseCardExtraLargeClass";

import * as types from "../store/mutations-types";

/**
 * Componente de passo dois do gerador de relatorios
 *
 * @requires {@link BaseSectionForm}
 * @requires {@link GroupCardExtraLarge}
 * @requires {@link BaseCardExtraLargeClass}
 *
 * @displayName ReportStepTwo
 * @category Page / Relatorios
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseSectionForm,
        GroupCardExtraLarge
    },
    data() {
        return {
            cards: []
        };
    },
    computed: {
        ...window.Vuex.mapState("reports", {
            generator_step_one: (state) => state.generator_step_one
        })
    },
    mounted() {
        this.cards.push(
            new BaseCardExtraLargeClass({
                id: "analytical",
                title: "Analitico",
                description: "Extrai os dados de forma detalhada, disparo a disparo.",
                imageUrl: "/images/reports/analytical_report.png"
            })
        );
        this.cards.push(
            new BaseCardExtraLargeClass({
                id: "synthetic",
                title: window.Vue.htmlEntities("Sint&eacute;tico"),
                description: "Extrai os dados de forma resumida por campanha ou envios avulsos",
                imageUrl: "/images/reports/synthetic_report.png"
            })
        );

        if (this.generator_step_one.report_type) {
            for (let card of this.cards) {
                if (card.id === this.generator_step_one.report_type) {
                    card.active = true;
                }
            }
        }
    },
    methods: {
        /**
         * Salva o tipo de relatorio selecionado no store
         * @param {Array|Object} card Array com os dados dos cards selecionados ou Objeto com os dados de um card selecionado quando for selecao unica
         */
        cardSelected(card) {
            this.$store.commit(
                `reports/${types.SET_GENERATOR_CONFIG_STEP_ONE}`,
                {
                    report_type: card.id
                }
            );
            this.$store.commit(
                `reports/${types.SET_GENERATOR_CONFIG_STEP_FIVE}`,
                {}
            );
        }
    },
    template:
        /*html*/
        `
            <v-row>
                <v-col cols="12">
                    <base-section-form title="Modalidade" description="Escolha a modalidade que deseja gerar o relat&oacute;rio">
                        <v-row no-gutters>
                            <v-col v-bind:data-v-step="7">
                                <group-card-extra-large :cards="cards" :singleSelect="true" @selected="cardSelected"></group-card-extra-large>
                            </v-col>
                        </v-row>
                    </base-section-form>
                </v-col>
            </v-row>
        `
};