import { DataTableHeaderClass } from "../../classes/DataTableHeaderClass";
import PageHeader from "../../components/moleculas/PageHeader?v=2";
import BaseDataTable from "../../components/organismos/BaseDataTable?v=2";
import FormFilterReports from "./components/forms/FormFilterReports?v=2";
import PgTour from "../../components/organismos/PgTour?v=2";
import BaseButton from "../../components/atomos/BaseButton?v=2";
import BaseNotify from "../../components/atomos/BaseNotify?v=2";
import DialogNotifyReportGeneration from "./components/dialogs/DialogNotifyReportGeneration";
import { BaseService } from "../../services/BaseService";
import DialogRepeatReport from "./components/dialogs/DialogRepeatReport?v=2";
import BaseDropdownButton from "../../components/atomos/BaseDropdownButton";

const base_service = new BaseService();

/**
 * Pagina que realiza a listagem de relatorios
 * 
 * @requires {@link DataTableHeaderClass}
 * @requires {@link PageHeader}
 * @requires {@link BaseDataTable}
 * @requires {@link FormFilterReports}
 * @requires {@link PgTour}
 * @requires {@link BaseButton}
 * @requires {@link DialogRepeatReport}
 * @requires {@link BaseDropdownButton}
 * @displayName ListReportsPage
 * @category Page / Relatorios
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    name: "list-reports-page",
    components: {
        PageHeader,
        BaseDataTable,
        FormFilterReports,
        PgTour,
        BaseButton,
        BaseNotify,
        DialogNotifyReportGeneration,
        DialogRepeatReport,
        BaseDropdownButton
    },
    filters: {
        format_date: function (date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss");
        },
        type_report_translate: function (modalidade) {
            switch (modalidade) {
            case "analytical":
                return window.Vue.htmlEntities("Anal&iacute;tico");
            case "synthetic":
                return window.Vue.htmlEntities("Sint&eacute;tico");
            case "radar":
                return window.Vue.htmlEntities("Radar");
            case "blacklist":
                return window.Vue.htmlEntities("Blacklist");
            case "interactive":
                return window.Vue.htmlEntities("Interativo");
            case "action-history":
                return window.Vue.htmlEntities("Hist&oacute;rico de a&ccedil;&otilde;es");
            case "messages":
                return window.Vue.htmlEntities("Mensagens");
            case "phone":
                return window.Vue.htmlEntities("Por telefone");
            case "api-shipping":
                return window.Vue.htmlEntities("Envios API");
            case "phones-company":
                return window.Vue.htmlEntities("Telefones - Operadoras");
            case "single-shipping":
                return window.Vue.htmlEntities("Envios Avulsos");
            case "blocked":
                return window.Vue.htmlEntities("Bloqueados");
            case "movement-statement":
                return window.Vue.htmlEntities("Extrato de movimenta&ccedil;&atilde;o");
            /*case "original_files_status":
                return window.Vue.htmlEntities("Arquivo Original + status");
            case "single_shipping_company":
                return window.Vue.htmlEntities("Envio Avulso Operador");
            /*case "custom-export":
                return window.Vue.htmlEntities("Relat&oacute;rio personalizado");*/
            /*case "file-status":
                return window.Vue.htmlEntities("Arquivo original + status");*/
            /*case "single-shipping-operator":
                return window.Vue.htmlEntities("Envios avulsos por operador");*/
            /*case "search":
                return window.Vue.htmlEntities("Por telefone");*/
            /*case "billing":
                return window.Vue.htmlEntities("Cobran&ccedil;a");*/
            /*case "synthetic-corporate":
                return window.Vue.htmlEntities("Corporativo sint&eacute;tico");*/
            /*case "credit":
                return window.Vue.htmlEntities("Cr&eacute;dito");*/
            /*case "movement-statistics":
                return window.Vue.htmlEntities("Estat&iacute;stica da movimenta&ccedil;&atilde;o");*/
            /*case "general-movement-statement":
                return window.Vue.htmlEntities("Extrato de movimenta&ccedil;&atilde;o");*/
            /*case "phone-validation":
                return window.Vue.htmlEntities("Valida telefone");*/
            /*case "joker":
                return window.Vue.htmlEntities("Coringa");*/
            default:
                return window.Vue.htmlEntities("N&atilde;o identificado");
            }
        }
    },
    data() {
        return {
            title: "",
            message: "",
            type: "notification",
            title_dialog_notify: null,
            description_dialog_notify: null,
            active: false,
            data_report: null,
            filters: {
                modalidade: null,
                data_solicitacao: 30,
                solicitante: null,
                status: null
            },
            table_loading: true,
            report_list: [],
            show: false,
            show_repeat_dialog: false,
            report_selected: {ID_REPORT_STATUS_RSES: null},
            dados_notificacao: null,
            table_headers: [
                new DataTableHeaderClass({ text: window.Vue.htmlEntities("Repetir relat&oacute;rio por per&iacute;odo"), value: "repeat_report", sortable: false, disabled: false, stepTutorial: 4}),
                new DataTableHeaderClass({ text: "Modalidade", value: "DS_MODALIDADE_RSES", sortable: true, disabled: true }),
                new DataTableHeaderClass({ text: window.Vue.htmlEntities("Data da solicita&ccedil;&atilde;o"), value: "DT_SOLICITACAO_USER_RSES", sortable: true, visibility: "lg" }),
                new DataTableHeaderClass({ text: "Quem solicitou", value: "NM_USUARIO_RSES", sortable: true, visibility: "lg" }),
                new DataTableHeaderClass({ text: "Download", value: "actions", sortable: false, disabled: true, width: "390px" })
            ],
            custom_slots: [
                "repeat_report",
                "DS_MODALIDADE_RSES",
                "DT_SOLICITACAO_USER_RSES",
                "NM_USUARIO_RSES",
                "actions"
            ],
            type_product: this.$cookie === undefined ? getCookie("type_product") : this.$cookie.get("type_product"),
        };
    },
    computed: {
        steps() {
            return [
                {
                    target: "[data-v-step='1']",
                    title: "Selecione a modalidade desejada",
                    content: this.type_product === "RCS" ? "As atuais modalidades dispon&iacute;veis para RCS s�o Anal&iacute;tico e Sint&eacute;tico. Escolha as modalidades que deseja exibir na tabela" : "As atuais modalidades s&atilde;o: Anal&iacute;tico, Sint&eacute;tico, Bloqueados, Avulsos/API, Blacklist, Telefone, Extrato de Movimenta&ccedil;&atilde;o. Escolha as modalidades que deseja exibir na tabela. ",
                    date: "2022-10-07 00:00:00"
                },
                {
                    target: "[data-v-step='2']",
                    title: "Busca por per&iacute;odo",
                    content: "Escolha um per&iacute;odo para pesquisar."
                },
                {
                    target: "[data-v-step='3']",
                    title: "Quem solicitou",
                    content: "Filtre pelo usu&aacute;rio que solicitou o relat&oacute;rio."
                },
                {
                    target: "[data-v-step='4']",
                    title: "Ganhe tempo automatizando relat&oacute;rios",
                    content: "Habilite a op&ccedil;&atilde;o \"Repetir relat&oacute;rio\" para receber uma vers&atilde;o atualizada do relat&oacute;rio sempre que precisar.",
                },
            ];
        }
    },
    mounted() {
        let search_params = new URLSearchParams(window.location.search);
        let download_report = search_params.get("download_report");

        if (download_report && Number.isInteger(parseInt(download_report))) {
            this.callDownlodReportEndpoint("csv", download_report);
        }

        this.getListReport();
    },
    methods: {
        ...window.Vuex.mapActions("reports", ["ActionDoGetListReport", "ActionDoDownloadReport", "ActionDoGenerateReport", "ActionDoRemoveRecurrence", "ActionDoSetRecurrence"]),
        /**
         * Busca a lista de relatorios de acordo com os filtros informados
         * @param {Function} callBack Call back para ser executado apos a requisicao ActionDoGetListReport
         * @vue
         */
        getListReport(callBack = ()=>{}) {
            this.table_loading = true;

            this.ActionDoGetListReport(this.filters).then(response => {
                this.report_list = [];

                for (let report of response) {
                    report.recurrence_enabled = report.ID_REPORT_RECURRENCE_RRES ? true : false;
                    report.loading = false;
                    this.report_list.push(report);
                }

                this.table_loading = false;
                callBack();
            });
        },
        /**
         * Faz o download do relatorio escolhido
         * @param {String} type Tipo de download TXT ou CSV
         * @param {Object} data Objeto com os dados do relatorio
         * @vue
         */
        downloadReport(type, data) {
            if (type && data) {
                let report = this.report_list.map((e) => {
                    return e.ID_REPORT_STATUS_RSES;
                }).indexOf(data.ID_REPORT_STATUS_RSES);

                data["loading"] = true;
                window.Vue.set(this.report_list, report, data);

                this.callDownlodReportEndpoint(type, data.ID_REPORT_STATUS_RSES).then(response => {
                    data["loading"] = false;
                    window.Vue.set(this.report_list, report, data);
                    this.title = "Eba, o arquivo terminou de baixar!";
                    this.message = `O arquivo ${response.name} foi baixado com sucesso.`;
                    this.type = "success";
                    this.active = true;
                }).catch(() => {
                    data["loading"] = false;
                    this.title = "Alguma coisa deu errado...";
                    this.message = window.Vue.htmlEntities("Ops, parece que n&atilde;o conseguimos baixar o arquivo. Tente novamente.");
                    this.type = "error";
                    this.active = true;
                    window.Vue.set(this.report_list, report, data);
                });
            }
        },
        /**
         * Chama o endpoint de download
         * @param {string} type Tipo de download (csv or txt)
         * @param {number} report_id ID do relatrio a ser baixado
         * @vue
         */
        callDownlodReportEndpoint(type, report_id) {
            return this.ActionDoDownloadReport({
                report_id: report_id,
                type: type
            }).then(response => {
                // Tenta obter o nome do arquivo do cabe�alho 'file-name'.
                let filename = response.headers["file-name"];

                // Se o nome do arquivo n�o estiver presente no cabe�alho 'file-name', tenta extrair do cabe�alho 'Content-Disposition'
                if (!filename) {
                    const contentDisposition = response.headers["content-disposition"];
                    if (contentDisposition) {
                        // Express�o regular para extrair o nome do arquivo do cabe�alho 'Content-Disposition'
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const matches = filenameRegex.exec(contentDisposition);
                        if (matches != null && matches[1]) {
                            // Remove aspas do nome do arquivo, se presente
                            filename = matches[1].replace(/['"]/g, "");
                        }
                    }
                    // Se n�o estiver presente, usa "download" como padr�o
                    filename = filename || "download";
                }

                // Converte de ISO-8859-1 para UTF-8
                const bytes = new Uint8Array(filename.length);
                for (let i = 0; i < filename.length; i++) {
                    bytes[i] = filename.charCodeAt(i);
                }
                const utf8Str = new TextDecoder("utf-8").decode(bytes);
                response.name = utf8Str;

                // Cria um blob com os dados do arquivo
                const blob = new Blob([response.data], { type: response.headers["content-type"] });

                // Cria um elemento de link para iniciar o download do blob
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = utf8Str;
                document.body.appendChild(link);
                link.click(); // Inicia o download

                document.body.removeChild(link); // Limpa a DOM, removendo o elemento de link

                return response;
            });
        },
        /**
         * Faz uma nova requisicao para pegar a lista de relatorios
         * @param {Object} new_filter Novos dados a serem filtrados
         * @vue
         */
        formSubmit(new_filter) {
            this.filters = new_filter;
            this.getListReport();
        },
        /**
         * Funcao para regerar o relatorio
         * @vue 
         */
        generateReport() {
            let filtros = JSON.parse(this.dados_notificacao.DS_FILTRO_RSES);
            filtros.id = this.dados_notificacao.ID_REPORT_STATUS_RSES;
            filtros.notification_type = this.dados_notificacao.notification_type == "no" ? null : this.dados_notificacao.notification_type;
            filtros.notification_to = this.dados_notificacao.notification_to;
            let report = this.report_list.map((e) => {
                return e.ID_REPORT_STATUS_RSES;
            }).indexOf(this.dados_notificacao.ID_REPORT_STATUS_RSES);
            this.ActionDoGenerateReport({
                type_report: this.dados_notificacao.DS_MODALIDADE_RSES,
                data: filtros
            });

            this.dados_notificacao["loading"] = false;
            window.Vue.set(this.report_list, report, data);
            this.title = window.Vue.htmlEntities("Estamos gerando o seu relat&oacute;rio&hellip;");
            this.message = window.Vue.htmlEntities("Recebemos todas as informa&ccedil;&otilde;es selecionadas e estamos trabalhando para gerar o seu relat&oacute;rio.");
            this.type = "success";
            this.active = true;

            setTimeout(() => {
                this.getListReport();
            }, 1500);
        },
        /**
         * Funcao para ativar a modal 
         * @param {Object} data Objeto com os dados do relatorio
         * @vue 
         */
        activeModal(data) {
            this.show = true;
            let report = this.report_list.map((e) => {
                return e.ID_REPORT_STATUS_RSES;
            }).indexOf(data.ID_REPORT_STATUS_RSES);
            data["loading"] = true;
            this.data_report = data;
            window.Vue.set(this.report_list, report, data);
        },
        /**
         * Redireciona para a pagina de criacao de campanha, dependendo do tipo de produto selecionado na sessao
         * @vue
         */
        redirectGenerateReportPage() {
            window.location = base_service.baseDomainPhp() + "/Relatorios/novo";
        },
        /**
         * Verifica se o relatorio possui campanhas especificas ou nao
         * @param {Object} item 
         * @returns Verdadeiro ou falso
         * @vue
         */
        haveSpecificCampaignsInFilter(item) {
            let filter = JSON.parse(item.DS_FILTRO_RSES);

            if (filter.todas_campanhas !== undefined) {
                if (filter.todas_campanhas) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },
        /**
         * Habilita ou desabilita a repeticao do relatorio
         * @param {Object} item 
         * @vue
         */
        changeRepeat(item) {
            if (item.ID_REPORT_RECURRENCE_RRES) {
                item.recurrence_enabled = false;
                item.loading = true;
                this.ActionDoRemoveRecurrence({ id_recurrent_report: item.ID_REPORT_RECURRENCE_RRES }).then(() => {
                    this.showNotifyRecurrence(false);
                    item.loading = false;
                });
            } else {
                item.recurrence_enabled = true;
                this.report_selected = item;
                this.show_repeat_dialog = true;
            }
        },
        /**
         * Desativa o switch de recorrencia
         * @vue
         */
        closeRecurrence() {
            this.report_selected.recurrence_enabled = false;
        },
        /**
         * Exibe o notify de ativacao ou desativacao da recorrencia do relatorio
         * @param {Boolean} enable Indica que a mensagem rh de habilitacao da recorrencia
         * @vue
         */
        showNotifyRecurrence(enable) {
            this.getListReport();
            if (enable) {
                this.title = window.Vue.htmlEntities("Repetir relat&oacute;rio foi habilitado com sucesso");
                this.message = window.Vue.htmlEntities("Voc&ecirc; habilitou a automa&ccedil;&atilde;o do relat&oacute;rio, caso deseje extrair relat&oacute;rios de campanhas espec&iacute;ficas, desabilite a automa&ccedil;&atilde;o.");
            } else {
                this.title = window.Vue.htmlEntities("Repetir relat&oacute;rio foi desabilitado com sucesso");
                this.message = window.Vue.htmlEntities("Voc&ecirc; desabilitou a automa&ccedil;&atilde;o do relat&oacute;rio. Esse relat&oacute;rio ser&aacute; gerado uma &uacute;nica vez.");
            }

            this.type = "info";
            this.active = true;
        },
        /**
         * Evento de submit do formulario
         * @vue
         */
        setRecurrence(recurrence_config) {
            this.ActionDoSetRecurrence({
                id_report_status: recurrence_config.id_report_status,
                recurrence_period: recurrence_config.recurrence_period,
                start_recurrence: recurrence_config.start_recurrence,
                finish_recurrence: recurrence_config.finish_recurrence
            }).then(() => {
                this.showNotifyRecurrence(true);
            });
        },
        /**
         * Monta a lista de op��es para download dosponiveis
         * @param {boolean} txt 
         * @param {boolean} pdf 
         * @returns 
         */
        getListOptionsDownload(txt, pdf) {
            let list = [];

            if (txt) {
                list.push(...[{ name: "Formato TXT", key: "txt" }, { name: "Formato CSV", key: "csv" }]);
            }
            if (pdf) {
                list.push({ name: "Formato PDF", key: "pdf" });
            }

            return list;
        }
    },
    template: (
        /*html*/
        ` 
        <v-container class="align-start">
            <v-card elevation="0">
                <v-card-text>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12">
                            <page-header
                                :tutorial="true"
                                title="Meus relat&oacute;rios"
                                description="Encontre os seus relat&oacute;rios gerados nos &uacute;ltimos 30 dias"
                                @tutorial="$refs.tour.start()"
                            >
                                <template #button>
                                    <base-button
                                        primary
                                        icon="mdi-plus"
                                        label="Novo relat&oacute;rio"
                                        @click="redirectGenerateReportPage"
                                    ></base-button>
                                </template>
                            </page-header>
                        </v-col>
                    </v-row>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12" class="filtro-content pb-10">
                            <form-filter-reports @formSubmit="formSubmit"></form-filter-reports>
                        </v-col>
                    </v-row>
                    <v-row class="px-0 px-md-6">
                        <v-col cols="12">
                            <base-data-table
                                :headers="table_headers"
                                :list-items="report_list"
                                item-key="ID_REPORT_STATUS_RSES"
                                :pagination="true"
                                :custom-columns="true"
                                :loading="table_loading"
                                :custom-slots="custom_slots"
                                :show-page-length="true"
                                sort-by="DT_SOLICITACAO_USER_RSES"
                            >
                                <template v-slot:repeat_report="props">
                                    <span v-if="['analytical', 'synthetic'].indexOf(props.item.DS_MODALIDADE_RSES) === -1">
                                        Esse relat&oacute;rio n&atilde;o &eacute; campat&iacute;vel com o sistema de repeti&ccedil;&atilde;o
                                    </span>
                                    <span v-else-if="haveSpecificCampaignsInFilter(props.item)">
                                        Esse relat&oacute;rio tem campanhas espec&iacute;ficas e n&atilde;o pode ser repetido
                                    </span>
                                    <v-switch
                                        v-else
                                        v-model="props.item.recurrence_enabled"
                                        inset
                                        :loading="props.item.loading"
                                        :disabled="props.item.loading"
                                        hide-details
                                        class="mt-0"
                                        color="dark-primary"
                                        @change="changeRepeat(props.item)"
                                    >
                                        <template #label>
                                            {{ props.item.ID_REPORT_RECURRENCE_RRES ? 'Repetir' : 'N&atilde;o repetir' }}
                                        </template>
                                    </v-switch>
                                </template>
                                <template v-slot:DS_MODALIDADE_RSES="props">
                                    {{ props.item.DS_MODALIDADE_RSES | type_report_translate }}
                                </template>
                                <template v-slot:DT_SOLICITACAO_USER_RSES="props">
                                    {{ props.item.DT_SOLICITACAO_USER_RSES | format_date }}
                                </template>
                                <template v-slot:NM_USUARIO_RSES="props">
                                    {{ props.item.NM_USUARIO_RSES | htmlEntities }}
                                </template>
                                <template v-slot:actions="props">
                                    <base-dropdown-button
                                        v-if="props.item.ST_REPORT_RSES === 'F' && (props.item.txt_exists || props.item.pdf_exists)"
                                        primary
                                        width="auto"
                                        icon="icon-btn-download"
                                        label="Baixar arquivo"
                                        :disabled="props.item.loading"
                                        :loading="props.item.loading"
                                        :block="false"
                                        small
                                        :list-options="getListOptionsDownload(props.item.txt_exists, props.item.pdf_exists)"
                                        @click="downloadReport($event, props.item)"
                                    >
                                        <template v-slot:loader>
                                            <span>Baixando o arquivo ...</span>
                                            <v-progress-linear
                                                indeterminate
                                                absolute
                                                bottom
                                                color="dark-primary"
                                                height="4"
                                            ></v-progress-linear>
                                        </template>
                                    </base-dropdown-button>
                                    <v-row v-else no-gutters>
                                        <v-col cols="auto">
                                            <v-icon left small color="medium-grey" v-if="props.item.ST_REPORT_RSES === 'P'">mdi-clock-outline</v-icon>
                                            <v-icon left small color="medium-grey" v-if="props.item.ST_REPORT_RSES === 'R'">mdi-cancel</v-icon>
                                        </v-col>
                                        <v-col>
                                            <span class="font-size-11 text-medium-grey" v-if="props.item.ST_REPORT_RSES === 'P'">Por favor, aguarde enquanto geramos o relat&oacute;rio em plano de fundo. Isso poder&aacute; levar algumas horas.</span>
                                            <v-row>
                                                <v-col v-if="props.item.ST_REPORT_RSES === 'E'">
                                                    <span class="font-size-11 text-medium-grey"> <v-icon left small color="medium-grey">mdi-alert-circle</v-icon> N&atilde;o foi poss&iacute;vel gerar o relat&oacute;rio. Deseja <a @click="activeModal(props.item)" class='text-dark-primary'>TENTAR NOVAMENTE</a>? </span>
                                                    <v-progress-linear
                                                        v-if="props.item.loading"
                                                        indeterminate
                                                        color="dark-primary"
                                                        height="4"
                                                    ></v-progress-linear>
                                                </v-col>
                                            </v-row>
                                            <span class="font-size-11 text-medium-grey" v-if="props.item.ST_REPORT_RSES === 'R'">O relat&oacute;rio foi gerado a mais de 30 dias e n&atilde;o est&aacute; mais dispon&iacute;vel para download</span>
                                            <span class="font-size-11 text-medium-grey" v-if="props.item.ST_REPORT_RSES === 'F' && !props.item.txt_exists && !props.item.pdf_exists">O arquivo do relat&oacute;rio n&atilde;o esta mais dispon&iacute;vel para download</span>
                                        </v-col>
                                    </v-row>
                                </template>
                            </base-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <base-notify
                    :active="active"
                    :title="title"
                    :message="message"
                    :type="type"
                    @close="active = false"
                ></base-notify>
                <dialog-notify-report-generation
                    :data-report="data_report"
                    v-model="dados_notificacao"
                    :dialog="show"
                    @actionDialog="generateReport"
                    @close="show = false"
                ></dialog-notify-report-generation>
                <dialog-repeat-report
                    v-model="show_repeat_dialog"
                    :report-id="report_selected.ID_REPORT_STATUS_RSES"
                    @confirm="setRecurrence"
                    @close="closeRecurrence"
                ></dialog-repeat-report>
                <pg-tour ref="tour" :steps="steps"></pg-tour>
            </v-card>
        </v-container>
        `
    )
};