export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g id="Btn-TemplateEmail-Hover" transform="translate(-1875 -2155)">
                    <g id="Ico-email-Hover" transform="translate(1876.807 2098)">
                        <g id="Grupo_2080" data-name="Grupo 2080" transform="translate(-2.509 56.287)">
                            <path id="Caminho_548" data-name="Caminho 548" d="M23.163,10.143,21.431,8.8V8.559A2.338,2.338,0,0,0,19.1,6.224h-1.37L14.438,3.855a.748.748,0,0,0-.875,0l-3.3,2.37H8.9A2.339,2.339,0,0,0,6.569,8.559v.234L4.862,10.1A2.792,2.792,0,0,0,3.7,12.364v10.1a1.939,1.939,0,0,0,1.9,1.825c.033,0,.066,0,.1,0H22.257A1.933,1.933,0,0,0,24.3,22.416V12.385A2.778,2.778,0,0,0,23.163,10.143ZM5.2,12.925,11.231,17.5,5.2,21.966Zm7.274,5.518,1.071.813a.751.751,0,0,0,.906,0l1.072-.813,5.859,4.341H6.616ZM16.77,17.5,22.8,12.925v9.041Zm-8.7-8.939A.837.837,0,0,1,8.9,7.724H19.1a.836.836,0,0,1,.835.835v4.618c0,.013.006.023.007.035L14,17.717l-5.938-4.5c0-.012.007-.022.007-.035Z" fill="currentColor"/>
                            <path id="Caminho_549" data-name="Caminho 549" d="M10.13,11h7.741a.75.75,0,1,0,0-1.5H10.13a.75.75,0,1,0,0,1.5Z" fill="currentColor"/>
                            <path id="Caminho_550" data-name="Caminho 550" d="M10.13,14.078h4.887a.75.75,0,0,0,0-1.5H10.13a.75.75,0,1,0,0,1.5Z" fill="currentColor"/>
                        </g>
                    </g>
                    <rect id="Retângulo_487" data-name="Retângulo 487" width="26" height="26" transform="translate(1875 2155)" fill="currentColor" opacity="0"/>
                </g>
            </svg>
        `
    )
};