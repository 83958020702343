import { BasePillClass } from "../../../classes/BasePillClass";
import { DraggableItemClass } from "../../../classes/DraggableItemClass?v=2";
import CadastroTemplatePassoQuatro from "../../template-rcs/components/CadastroTemplatePassoQuatro";
import * as types from "../../template-rcs/store/mutations-types";

export default {
    components: {
        CadastroTemplatePassoQuatro
    },
    created() {
        this.$store.commit("templatesrcs/" + types.SET_STEP_ONE, {
            "name": "dsdsf",
            "modelo": {
                "label": "Texto",
                "icon": "icon-editar-template",
                "disabled": false,
                "active": true,
                "value": "text"
            }
        });
        this.$store.commit("templatesrcs/" + types.SET_STEP_TWO, {
            "button_type": {
                "icon": "$completoIcon",
                "title": "Completo",
                "type": "completo",
                "description": "Crie uma mensagem utilizando todos os recursos",
                "height": "auto",
                "hover": false,
                "selected": true,
                "horizontal": undefined,
                "disable": false
            },
            "list_messages": [
                new DraggableItemClass({ id: 0.3291099775606281, text: "fasfdsd", type: "text", pill: new BasePillClass({ id: 0.26712158959001386, title: null, subTitle: null, titleIcon: null, prependIcon: null, draggable: false, editable: false, deletable: false, removable: false, rounded: false, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: null, subTitleNewLine: false, type: null, selected: false }), custom: false }),
                new DraggableItemClass({ id: 0.13785034155082942, text: "Nome", type: "pill", pill: new BasePillClass({ id: 0.16438698274440222, title: "Nome", subTitle: "10 caracteres", titleIcon: null, prependIcon: "fa-grip-vertical", draggable: true, editable: false, deletable: false, removable: false, rounded: true, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: 10, subTitleNewLine: false, type: null, selected: false }), custom: false }),
                new DraggableItemClass({ id: 0.3944474855595592, text: "dasd", type: "pill", pill: new BasePillClass({ id: 0.01951253383666085, title: "dasd", subTitle: "4 caracteres", titleIcon: null, prependIcon: "fa-grip-vertical", draggable: true, editable: true, deletable: true, removable: false, rounded: true, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: 4, subTitleNewLine: false, type: null, selected: false }), custom: true })
            ],
            "list_fast_response": [
                new DraggableItemClass({ id: 0.528073940510863, text: "Sim", type: "pill", pill: new BasePillClass({ id: 0.7727926462407086, title: "Sim", subTitle: null, titleIcon: null, prependIcon: "fa-grip-vertical", draggable: true, editable: false, deletable: false, removable: false, rounded: false, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: null, subTitleNewLine: false, type: null, selected: false }), custom: false }),
                new DraggableItemClass({ id: 0.9607774465805663, text: "dasd", type: "pill", pill: new BasePillClass({ id: 0.19421869731557506, title: "dasd", subTitle: null, titleIcon: null, prependIcon: "fa-grip-vertical", draggable: true, editable: true, deletable: true, removable: false, rounded: false, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: null, subTitleNewLine: false, type: null, selected: false }), custom: true })
            ],
            "list_suggestions": [
                new DraggableItemClass({ id: 0.6572774185552255, text: "dasdsd", type: "pill", pill: new BasePillClass({ id: 0.8773450166389587, title: "dasdsd", subTitle: "(44) 54545-4545", titleIcon: "fa-phone-alt", prependIcon: "fa-grip-vertical", draggable: true, editable: true, deletable: true, removable: false, rounded: false, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: null, subTitleNewLine: true, type: "phone", selected: false }), custom: true }),
                new DraggableItemClass({ id: 0.592163629036651, text: "dasds", type: "pill", pill: new BasePillClass({ id: 0.08460070075715054, title: "dasds", subTitle: "", titleIcon: "fa-crosshairs", prependIcon: "fa-grip-vertical", draggable: true, editable: true, deletable: true, removable: false, rounded: false, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: null, subTitleNewLine: true, type: "localization", selected: false }), custom: true })
            ],
            "list_chips_preview": [
                new DraggableItemClass({ id: 0.528073940510863, text: "Sim", type: "pill", pill: new BasePillClass({ id: 0.7727926462407086, title: "Sim", subTitle: null, titleIcon: null, prependIcon: "fa-grip-vertical", draggable: true, editable: false, deletable: false, removable: false, rounded: false, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: null, subTitleNewLine: false, type: null, selected: false }), custom: false }),
                new DraggableItemClass({ id: 0.9607774465805663, text: "dasd", type: "pill", pill: new BasePillClass({ id: 0.19421869731557506, title: "dasd", subTitle: null, titleIcon: null, prependIcon: "fa-grip-vertical", draggable: true, editable: true, deletable: true, removable: false, rounded: false, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: null, subTitleNewLine: false, type: null, selected: false }), custom: true }),
                new DraggableItemClass({ id: 0.6572774185552255, text: "dasdsd", type: "pill", pill: new BasePillClass({ id: 0.8773450166389587, title: "dasdsd", subTitle: "(44) 54545-4545", titleIcon: "fa-phone-alt", prependIcon: "fa-grip-vertical", draggable: true, editable: true, deletable: true, removable: false, rounded: false, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: null, subTitleNewLine: true, type: "phone", selected: false }), custom: true }),
                new DraggableItemClass({ id: 0.592163629036651, text: "dasds", type: "pill", pill: new BasePillClass({ id: 0.08460070075715054, title: "dasds", subTitle: "", titleIcon: "fa-crosshairs", prependIcon: "fa-grip-vertical", draggable: true, editable: true, deletable: true, removable: false, rounded: false, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: null, subTitleNewLine: true, type: "localization", selected: false }), custom: true })
            ]
        });
    },
    template: (
        /*html*/
        `
            <v-container>
                <v-row  justify="center">
                    <v-col class="mt-5" cols="12" md="8" lg="9">
                        <cadastro-template-passo-quatro></cadastro-template-passo-quatro>
                    </v-col>
                </v-row>
            </v-container>
        `
    )
};