import StyledDivBallon from "../../../../vue-app/src/components/atomos/StyledDivBallon.js";
import { SugestionPreview } from "../../../..//vue-app/src/components/moleculas/SugestionPreview";
import { TemplateService } from "../../../../js/services/TemplateService.js";

const template_service = new TemplateService();

/**
 * Componente de Text Preview
 * 
 * @displayName TextPreview
 * @component
 * @category Moleculas
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @vue
 */
export const TextPreview = {
    name: "text-preview",
    props: {
        //Title do carrossel
        text: {
            type: String,
            required: true
        },
        //tamanho da imagem
        sent_time: {
            type: String
        },
        //url da imagem do card
        variant: {
            type: String,
            default: "outgoing"
        },
        //Array com as sugestions
        suggestions: {
            type: Array,
            required: false,
            default: []
        },
        //force the direction of the chat balloon
        force_direction: {
            type: ["right", "left"]
        }
    },
    computed: {
        text_direction: function() {
            return this.variant.toLowerCase() === "outgoing"
                ? "Enviado"
                : "Recebido";
        },
        direction: function() {
            if (this.force_direction != null) {
                return this.force_direction.toLowerCase();
            } else {
                return this.variant.toLowerCase() === "outgoing"
                    ? "right"
                    : "left";
            }
        },
        text_formatted: function() {
            return template_service.preencherCamposTemplate(
                this.text,
                {},
                true
            );
        }
    },
    components: {
        "suggestion-preview": SugestionPreview,
        "styled-div-ballon": StyledDivBallon
    },
    template:
        /*html*/
        `
           <div class="px-0 w-100 d-flex" :class="{'justify-content-end': direction === 'right'}" >
                <div style="width: max-content; max-width: 100%">
                          <div class="row mx-0 justify-content-center card-child" >
                   <styled-div-ballon class="col-12" :direction="direction" :variant="variant.toLowerCase() === 'outgoing' ? 'white' : 'dark-primary'">
                      <div class="row div__ballon--text">
                            <div class="col ">
                                <span class="overflow-wrap" v-html="text_formatted"></span>
                            </div>
                      </div> 
                      <template v-if="sent_time">
                         <span>-</span>
                         <div class="w-100"></div> 
                         <span class="div__ballon--label">{{text_direction}} em {{sent_time}}</span>
                      </template>
                   </styled-div-ballon>
                </div>
                 <div class="row" >
                    <div class="col-12" :class="'text-'+direction">
                        <suggestion-preview class="m-1" v-for="({label, value, highlight, type}, index) in suggestions" :key="index"
                        :label="label"
                        :value="value"
                        :highlight="highlight"
                        :type="type"
                        :active="variant.toLowerCase() === 'incoming'"
                        ></suggestion-preview>
                    </div>
                 </div>
                </div>
              
            </div>
        `
};
