import FormLoginUsage from "./FormLoginUsage";
import SelectEmpresaUsage from "./SelectEmpresaUsage";
import FormRecoveryPasswordUsage from "./FormRecoveryPasswordUsage";
import FormResetPasswordUsage from "./FormResetPasswordUsage";
import ProductDescriptionUsage from "./ProductDescriptionUsage";
import SubHeaderUsage from "./SubHeaderUsage";
import BackgroundIconTextUsage from "./BackgroundIconTextUsage";
import BaseDialogUsage from "../moleculas/BaseDialogUsage";
import ChatMessageUsage from "../moleculas/ChatMessageUsage";
import AccordionDefaultUsage from "./AccordionDefaultUsage";
import FooterActionUsage from "./FooterActionUsage";
import DataSummaryUsage from "./DataSummaryUsage";
import SectionCardUsage from "./SectionCardUsage";
import CardShortChoiceUsage from "./CardShortChoiceUsage";
import CardLargeChoiceUsage from "./CardLargeChoiceUsage";
import FormAddCustomFieldUsage from "./FormAddCustomFieldUsage";
import GroupPillsUsage from "./GroupPillsUsage";
import BaseRadioButtonsUsage from "./BaseRadioButtonsUsage";
import TextFieldPhoneValidatorUsage from "./TextFieldPhoneValidatorUsage";
import FormRespostasRapidasUsage from "./FormRespostasRapidasUsage";
import BaseCalendarUsage from "./BaseCalendarUsage";
import ProgressCardUsage from "./ProgressCardUsage";
import ButtonActionUsage from "./ButtonActionUsage";
import GroupButtonCheckboxUsage from "./GroupButtonCheckboxUsage";
import GroupCardExtraLargeUsage from "./GroupCardExtraLargeUsage";
import PageHeaderUsage from "./PageHeaderUsage";
import TableThumbUsage from "./TableThumbUsage";
import StatusCampaignUsage from "./StatusCampaignUsage";
import PreviewCarouselUsage from "./PreviewCarouselUsage";

export default [
    {
        path: "form-login-usage",
        name: "Form login usage",
        component: FormLoginUsage
    },
    {
        path: "select-empresa-usage",
        name: "Select empresa usage",
        component: SelectEmpresaUsage
    },
    {
        path: "form-recovery-password-usage",
        name: "Form recovery password usage",
        component: FormRecoveryPasswordUsage
    },
    {
        path: "form-reset-password-usage",
        name: "Form reset password usage",
        component: FormResetPasswordUsage
    },
    {
        path: "product-description-usage",
        name: "Product description usage",
        component: ProductDescriptionUsage
    },
    {
        path: "background-icon-text-usage",
        name: "Background icon text usage",
        component: BackgroundIconTextUsage
    },
    {
        path: "sub-header-usage",
        name: "Sub header usage",
        component: SubHeaderUsage
    },
    {
        path: "base-dialog-usage",
        name: "Base dialog usage",
        component: BaseDialogUsage
    },
    {
        path: "chat-message-usage",
        name: "Chat message usage",
        component: ChatMessageUsage
    },
    { path: "accordion-default-usage",
        name: "Accordion default",
        component: AccordionDefaultUsage
    },
    {
        path: "footer-action-usage",
        name: "Footer Action",
        component: FooterActionUsage
    },
    {
        path: "data-summary-usage",
        name: "Data summary",
        component: DataSummaryUsage
    },
    {
        name: "Section card",
        path: "section-card-usage",
        component: SectionCardUsage
    },
    {
        name: "Card Short Choice",
        path: "card-short-choice-usage",
        component: CardShortChoiceUsage
    },
    {
        name: "Card Large Choice",
        path: "card-large-choice-usage",
        component: CardLargeChoiceUsage
    },
    {
        name: "Form Add Custom Field Usage",
        path: "form-add-custom-field-usage",
        component: FormAddCustomFieldUsage
    },
    {
        name: "Group Pills",
        path: "group-pills-usage",
        component: GroupPillsUsage
    },
    {
        name: "Base Radio Buttons",
        path: "base-radio-buttons-usage",
        component: BaseRadioButtonsUsage
    },
    {
        name: "Text Field Phone Validator",
        path: "text-field-phone-validator-usage",
        component: TextFieldPhoneValidatorUsage
    },
    {
        name: "Form Respostas Rapidas Usage",
        path: "form-respostas-rapidas-usage",
        component: FormRespostasRapidasUsage
    },
    {
        name: "Base Calendar",
        path: "base-calendar-usage",
        component: BaseCalendarUsage
    },
    {
        name: "Progress Card Usage",
        path: "progress-card-usage",
        component: ProgressCardUsage
    },
    {
        name: "Button Action",
        path: "button-action-usage",
        component: ButtonActionUsage
    },
    {
        name: "Group Button Checkbox Usage",
        path: "group-button-checkbox-usage",
        component: GroupButtonCheckboxUsage
    },
    {
        name: "Group card extra large",
        path: "group-card-extra-large-usage",
        component: GroupCardExtraLargeUsage
    },
    {
        name: "Page header usage",
        path: "page-header-usage",
        component: PageHeaderUsage
    },
    {
        name: "Table thumb",
        path: "table-thumb-usage",
        component: TableThumbUsage
    },
    {
        path: "status-campaign-usage",
        name: "Status campaign",
        component: StatusCampaignUsage
    },
    {
        path: "preview-carousel-usage",
        name: "Preview Carousel Usage",
        component: PreviewCarouselUsage
    }
];
