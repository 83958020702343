import BasePill from "./BasePill";
import BaseSelect from "./BaseSelect";

/**
 * Componente para pre visualizacao de card
 *
 * @requires {@link BasePill}
 * @requires {@link BaseSelect}
 * 
 * @displayName PreviewCard
 * @category Atomos
 * @author David dos Santos Andrade <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BasePill,
        BaseSelect
    },
    props: {
        /**
         * URL da imagem a ser exibida
         */
        image: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Titulo do card
         */
        title: {
            type: [String, Array],
            required: false,
            default: null
        },
        /**
         * Descricao do card
         */
        description: {
            type: [String, Array],
            required: true
        },
        /**
         * Informa se e necessario formatar o titulo e a descricao
         */
        needFormatMessage: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Lista de suggestions do tipo bototes
         */
        suggestionButtons: {
            type: Array,
            required: false,
            default: null
        },
        /**
         * Lista de suggestions de respostas rapidas
         */
        suggestionResponses: {
            type: Array,
            required: false,
            default: null
        },
        /**
         * Define a orientacao do card
         */
        orientation: {
            type: String,
            required: false,
            default: "VERTICAL",
            validator(value) {
                return ["VERTICAL", "HORIZONTAL"].indexOf(value) > -1;
            }
        },
        /**
         * Define o alinhamento do card
         */
        alignment: {
            type: String,
            required: false,
            default: "LEFT",
            validator(value) {
                return ["RIGHT", "LEFT"].indexOf(value) > -1;
            }
        },
        /**
         * Define o tamanho da imagem do card
         */
        imageHeight: {
            type: String,
            required: false,
            default: "TALL",
            validator(value) {
                return ["TALL", "MEDIUM", "SHORT"].indexOf(value) > -1 || (value[0] === "{" && value[value.length - 1] === "}");
            }
        },
        /**
         * Lista de opcoes que podem ser utilizadas em um select
         */
        selectOptions: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Campos variaveis do template
         */
        fieldsTemplate: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        },
        /**
         * Informa se e um card de criacao de campanha
         */
        inCreateCampaign: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define as regras a serem utilizadas para validacao dos dados inseridos
         */
        rules: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Lista com o nome dos parametros a serem preenchidos.
         */
        media: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Lista com o nome dos parametros a serem preenchidos.
         */
        typeTemplate: {
            type: String,
            required: false,
            default: "card"
        },
    },
    data() {
        return {
            error: false,
            fields_template: this.fieldsTemplate,
            image_select: null,
            image_height: null
        };
    },
    computed: {
        heightImage() {
            let height = "auto";

            if (this.orientation === "VERTICAL") {
                if (this.imageHeight === "TALL" || this.fields_template[this.imageHeight.replace(/[{}]/gi, "")] === "TALL") {
                    height = 140;
                } else if (this.imageHeight === "MEDIUM" || this.fields_template[this.imageHeight.replace(/[{}]/gi, "")] === "MEDIUM") {
                    height = 120;
                } else {
                    height = 92;
                }
            } else {
                height = "100%";
            }

            return height;
        },
        classImage() {
            let image_class = "";

            if (this.orientation === "VERTICAL") {
                image_class = "rounded-top-2 rounded-bottom-0";
            } else {
                if (this.alignment === "RIGHT") {
                    image_class = "rounded-right-2 rounded-left-0";
                } else {
                    image_class = "rounded-right-0 rounded-left-2";
                }
            }

            return image_class + " overflow-hidden";
        },
        listSuggestions() {
            let new_buttons = this.suggestionButtons ?? [];
            let new_responses = this.suggestionResponses ?? [];

            let new_list_suggestions = [
                ...new_buttons,
                ...new_responses
            ];

            new_list_suggestions.sort((a, b) => a.order !== null && b.order !== null ? a.order - b.order : 0);

            return new_list_suggestions;
        }
    },
    watch: {
        image() {
            this.error = false;
        },
        fieldsTemplate() {
            this.fields_template = this.fieldsTemplate;
        },
        fields_template() {
            this.fieldChanged();
        }
    },
    methods: {
        formatMessage(message) {
            // Se nao precisar formatar mensagem retorna o texto padrao
            if (!this.needFormatMessage) {
                return message;
            }

            let content = "";
            if (message) {
                for (let word of message) {
                    if (word.type === "text") {
                        content += word.text + " ";
                    } else if (word.type === "break-line") {
                        content += word.text;
                    } else {
                        content += /*html*/`<span class="bold">{${word.text}} </span>`;
                    }
                }
            }
            return content;
        },
        fieldChanged() {
            this.$emit("changeField", this.fields_template);
            this.setImageSelect();
        },
        setImageSelect() {
            if (this.typeTemplate === "card") {
                for (let option of this.selectOptions) {
                    if (option.value === this.fields_template["url"]) {
                        this.image_select = option.text;
                        break;
                    } else {
                        this.image_select = null;
                    }
                }
            }
            if (this.typeTemplate === "carousel") {

                for (let option of this.selectOptions) {
                    if (option.value === this.fields_template[this.media.url.slice(1, -1)]) {
                        this.image_select = option.text;
                        break;
                    } else {
                        this.image_select = null;
                    }
                }
            }
        }
    },
    template: (
        /*html*/
        `
            <v-card elevation="0" class="rounded-2 border-1 border-gray-300" :width="orientation === 'VERTICAL' ? 250 : 262">
                <v-row no-gutters>
                    <v-col :cols="orientation === 'VERTICAL' ? 12 : 'auto'" :order="orientation === 'HORIZONTAL' && alignment === 'RIGHT' ? 2 : 0">
                        <v-card elevation="0" :class="classImage" :height="heightImage + (inCreateCampaign ? 10 : 0) + (selectOptions && Object.keys(selectOptions).length > 0 ? 70 : 0)">
                            <v-row v-if="image === 'variable' && selectOptions && Object.keys(selectOptions).length > 0" no-gutters>
                                <v-col cols="12">
                                    <v-img
                                        :src="image_select"
                                        :class="classImage"
                                        :width="orientation === 'VERTICAL' ? 250 : 90"
                                        :height="heightImage"
                                    />
                                </v-col>
                                <v-col v-if="typeTemplate === 'card'" cols="12" class="px-2">
                                    <base-select
                                        v-model="fields_template['url']"
                                        :items="selectOptions"
                                        title="URL da imagem"
                                        label="Selecione uma op&ccedil;&atilde;o"
                                        tooltip-text="Selecione uma op&ccedil;&atilde;o para configurar seu arquivo"
                                        prepend-icon="$placeholderVariableImagesIcon"
                                        clearable
                                        @change="fieldChanged"
                                        :rules="rules"
                                    />
                                </v-col>
                                <v-col v-else-if="typeTemplate === 'carousel'" cols="12" class="px-2">
                                    <base-select
                                        v-model="fields_template[media.url.slice(1, -1)]"
                                        :items="selectOptions"
                                        title="URL da imagem"
                                        label="Selecione uma op&ccedil;&atilde;o"
                                        tooltip-text="Selecione uma op&ccedil;&atilde;o para configurar seu arquivo"
                                        prepend-icon="$placeholderVariableImagesIcon"
                                        clearable
                                        @change="fieldChanged"
                                        :rules="rules"
                                    />
                                </v-col>
                            </v-row>
                            <v-card
                                v-else-if="image === 'variable' && (!selectOptions || Object.keys(selectOptions).length === 0)"
                                elevation="0"
                                :class="classImage + ' bg-primary-blue-50 pa-4'"
                                :width="orientation === 'VERTICAL' ? 250 : 90"
                                :height="heightImage"
                            >
                                <v-row no-gutters class="fill-height" align="center">
                                    <v-col cols="12">
                                        <v-row no-gutters>
                                            <v-col cols="12" class="text-center">
                                                <v-icon size="62">
                                                    $placeholderVariableImagesIcon
                                                </v-icon>
                                            </v-col>
                                            <v-col cols="12" class="text-center mt-3">
                                                <span class="text-primary-blue-500 body-2">A URL da imagem ser&aacute; extra&iacute;da do arquivo de dados</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-img
                                v-else-if="!error"
                                :src="image"
                                :class="classImage"
                                :width="orientation === 'VERTICAL' ? 250 : 90"
                                :height="heightImage"
                                @error="error = true"
                            />
                            <v-card
                                v-else-if="error"
                                elevation="0"
                                :class="classImage + ' bg-gray-50 pa-4'"
                                :width="orientation === 'VERTICAL' ? 250 : 90"
                                :height="heightImage"
                            >
                                <v-row no-gutters align="center" class="fill-height">
                                    <v-col cols="12">
                                        <v-row no-gutters justify="center">
                                            <v-col cols="auto">
                                                <v-icon color="error-500" size="50">
                                                    $iconInvalid
                                                </v-icon>
                                            </v-col>
                                            <v-col cols="12" class="text-center">
                                                <span class="body-2 text-error-500">N&atilde;o foi poss&iacute;vel carregar a imagem</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-card>
                    </v-col>
                    <v-col v-if="imageHeight[0] === '{' && imageHeight[imageHeight.length - 1] === '}' && selectOptions && Object.keys(selectOptions).length > 0" class="px-2">
                        <base-select
                            v-model="fields_template[imageHeight.replace(/[{}]/gi, '')]"
                            :items="selectOptions"
                            label="Selecione uma op&ccedil;&atilde;o"
                            tooltip-text="Selecione uma op&ccedil;&atilde;o para configurar seu arquivo"
                            clearable
                            title="Tamanho da imagem"
                            prepend-icon="mdi-arrow-expand-vertical"
                            @change="fieldChanged"
                        />
                    </v-col>
                    <v-col :cols="orientation === 'VERTICAL' ? 12 : undefined">
                        <v-row no-gutters class="pa-4">
                            <v-col cols="12">
                                <span v-if="!needFormatMessage" class="body-2 bold text-gray-900" v-html="title" />
                                <v-row v-else no-gutters>
                                    <v-col
                                        v-for="(word, i) in title"
                                        :key="i"
                                        cols="auto"
                                        class="ml-1"
                                    >
                                        <span v-if="word.type === 'text'" class="body-2 bold text-gray-900" v-html="word.text" />
                                        <base-select
                                            v-else-if="selectOptions && Object.keys(selectOptions).length > 0"
                                            v-model="fields_template[word.text]"
                                            :items="selectOptions"
                                            label="Selecione uma op&ccedil;&atilde;o"
                                            tooltip-text="Selecione uma op&ccedil;&atilde;o para configurar seu arquivo"
                                            clearable
                                            @change="fieldChanged"
                                        />
                                        <span v-else class="body-2 bold text-gray-900">
                                            {{ "{" + word.text + "}" }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" class="mt-4">
                                <span v-if="!needFormatMessage" class="body-2 text-gray-900" v-html="description" />
                                <v-row v-else no-gutters>
                                    <v-col
                                        v-for="(message, i) in description"
                                        :key="i"
                                        :cols="message.type === 'break-line' ? 12 : 'auto'"
                                        class="ml-1"
                                    >
                                        <v-row v-if="message.type === 'break-line'" >
                                            <v-col cols="12">
                                                <span class="body-2 text-gray-900">{{ description[i-1].type === 'break-line' ? '&nbsp;' : '' }}</span>
                                            </v-col>
                                        </v-row>
                                        <span v-else-if="message.type === 'text'" class="body-2 text-gray-900" v-html="message.text" />
                                        <base-select
                                            v-else-if="selectOptions && Object.keys(selectOptions).length > 0"
                                            v-model="fields_template[message.text]"
                                            :items="selectOptions"
                                            label="Selecione uma op&ccedil;&atilde;o"
                                            tooltip-text="Selecione uma op&ccedil;&atilde;o para configurar seu arquivo"
                                            clearable
                                            @change="fieldChanged"
                                        />
                                        <span v-else class="body-2 text-gray-900 bold">
                                            {{ "{" + message.text + "}" }}
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-if="listSuggestions && listSuggestions.length" no-gutters class="pa-4">
                            <v-col
                                v-for="(item, index) in listSuggestions"
                                :key="'suggestion-' + index"
                                cols="12"
                                class="text-center"
                            >
                                <v-row no-gutters justify="center">
                                    <v-col cols="auto">
                                        <base-pill
                                            v-if="!selectOptions || Object.keys(selectOptions).length === 0"
                                            content-color="gray-900"
                                            background-color="transparent"
                                            border-color="transparent"
                                            :title="item.pill.title"
                                            :title-icon="item.pill.titleIcon"
                                            :prepend-icon="null"
                                        />
                                        <v-row v-else no-gutters class="bg-gray-30 mt-2 pa-2 rounded-2">
                                            <v-col cols="12">
                                                <base-pill
                                                    v-if="item.pill.title[0] !== '{' && item.pill.title[item.pill.title.length - 1] !== '}'"
                                                    content-color="gray-900"
                                                    background-color="transparent"
                                                    border-color="transparent"
                                                    :title="item.pill.title"
                                                    :title-icon="item.pill.titleIcon"
                                                    :prepend-icon="null"
                                                />
                                                <base-select
                                                    v-else
                                                    v-model="fields_template[item.pill.title.slice(1, -1)]"
                                                    :items="selectOptions"
                                                    :title="item.pill.title.slice(1, -1).replaceAll('_', ' ')"
                                                    label="Selecione uma op&ccedil;&atilde;o"
                                                    tooltip-text="Selecione uma op&ccedil;&atilde;o para configurar seu arquivo"
                                                    clearable
                                                    :prepend-icon="item.pill.titleIcon"
                                                    @change="fieldChanged"
                                                />
                                            </v-col>
                                            <v-col v-if="item.pill.subTitle && item.pill.subTitle[0] === '{' && item.pill.subTitle[item.pill.subTitle.length - 1] === '}'" cols="12">
                                                <base-select
                                                    v-model="fields_template[item.pill.subTitle.slice(1, -1)]"
                                                    :items="selectOptions"
                                                    :title="item.pill.subTitle.slice(1, -1).replaceAll('_', ' ')"
                                                    label="Selecione uma op&ccedil;&atilde;o"
                                                    tooltip-text="Selecione uma op&ccedil;&atilde;o para configurar seu arquivo"
                                                    clearable
                                                    :prepend-icon="item.pill.titleIcon"
                                                    @change="fieldChanged"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-if="index < listSuggestions.length - 1" no-gutters justify="center">
                                    <v-col cols="12">
                                        <v-divider class="ma-0 pa-0" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};