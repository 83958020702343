import CadastroTemplatePageUsage from "../../template-rcs/CadastroTemplatePage.js";

export default {
    components: {
        CadastroTemplatePageUsage
    },
    template:
        /*html*/
        `
            <div class="row justify-center mt-16">
                <cadastro-template-page-usage></cadastro-template-page-usage>
            </div>
     
        `
};
