import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "icone-texto-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom id="_002-font" data-name="002-font" xmlns="http://www.w3.org/2000/svg" width="18.263" height="15.98" viewBox="0 0 18.263 15.98">
                <path id="Path_1268" data-name="Path 1268" d="M6.849,29.788h4.566v2.283h1.141V28.647H0v3.424H1.141V29.788H5.707v13.7H3.424v1.141H9.132V43.486H6.849Z" transform="translate(0 -28.647)"/>
                <path id="Path_1269" data-name="Path 1269" d="M200.529,143.235v2.283h1.141v-1.141h3.424v9.132h-2.283v1.141h5.707v-1.141h-2.283v-9.132h3.424v1.141H210.8v-2.283Z" transform="translate(-192.539 -138.669)"/>
            </svg-custom>
        `
    )
};