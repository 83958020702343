import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "respostas-rapidas-small-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.75923 17.5921C5.66775 17.6645 5.55394 17.7045 5.43733 17.7048L5.43236 17.7048C5.35574 17.7031 5.28047 17.6844 5.21197 17.65C5.12541 17.6076 5.05248 17.5418 5.00125 17.4603C4.94972 17.3782 4.92225 17.2834 4.92187 17.1866L4.92187 15.9223C4.49587 15.8198 4.11022 15.5882 3.81897 15.2572C3.46414 14.857 3.26841 14.3406 3.26888 13.8057V12.4065C3.26534 11.8345 3.4886 11.2845 3.88992 10.8769C4.29139 10.4692 4.83818 10.2373 5.41036 10.2322L5.41216 10.2322H6.92116V11.2655H5.41348C5.11548 11.2696 4.83123 11.3916 4.62289 11.6047C4.41427 11.8182 4.29882 12.1057 4.30184 12.4041L4.30185 12.4062L4.30184 13.8082C4.29882 14.1066 4.41427 14.3941 4.62289 14.6076C4.83124 14.8207 5.11549 14.9427 5.41349 14.9468H5.43735C5.57424 14.947 5.70559 15.0014 5.80244 15.0982C5.89925 15.1951 5.95373 15.3265 5.95387 15.4635V16.1148L7.27558 15.0605C7.36693 14.988 7.48044 14.9484 7.59713 14.9482L11.7754 14.9482C12.0734 14.944 12.3576 14.8219 12.5659 14.6088C12.7744 14.3954 12.8898 14.1079 12.8868 13.8095L12.8868 13.8075L12.8868 13.5245H13.9202V13.8067C13.9238 14.3788 13.7006 14.9289 13.2992 15.3366C12.8977 15.7444 12.3509 15.9763 11.7786 15.9815L11.7768 15.9815L7.77782 15.9815L5.75923 17.5921Z"                    
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.946 14.4196C15.9976 14.3375 16.0251 14.2419 16.0254 14.1448V12.8802C16.4514 12.7778 16.8371 12.5463 17.1283 12.2152C17.483 11.8151 17.6787 11.2988 17.6784 10.7641V9.36489C17.6821 8.79294 17.4588 8.24286 17.0576 7.83525C16.6562 7.42751 16.1094 7.19563 15.5373 7.19049L9.17083 7.19048L9.16906 7.19049C8.59685 7.19553 8.05 7.42736 7.64851 7.83512C7.24719 8.24271 7.02383 8.7928 7.02747 9.36479V10.7635C7.02393 11.3355 7.24729 11.8855 7.6486 12.2931C8.05006 12.7008 8.59685 12.9327 9.16903 12.9378L13.1698 12.9378L15.1877 14.5479C15.4721 14.718 15.7336 14.61 15.7336 14.61C15.7336 14.61 15.8944 14.5016 15.946 14.4196ZM8.06077 10.7675C8.05775 11.0659 8.17317 11.3533 8.38173 11.5667C8.59 11.7797 8.87424 11.9017 9.17216 11.9058H13.3504C13.4671 11.906 13.5807 11.9456 13.6721 12.0182L14.9938 13.0724V12.4211C14.9939 12.2843 15.0484 12.1529 15.1451 12.0561C15.2419 11.9594 15.373 11.905 15.5099 11.9048H15.5338C15.8316 11.9006 16.1157 11.7787 16.3239 11.5656C16.5324 11.3522 16.6479 11.0648 16.6448 10.7665L16.6448 10.7645L16.6448 9.3624C16.6479 9.06406 16.5326 8.77668 16.3241 8.56329C16.1159 8.3502 15.8317 8.22827 15.5338 8.22415H9.17183C8.87379 8.22827 8.58947 8.35032 8.38123 8.5636C8.1727 8.77717 8.05743 9.06477 8.06076 9.36325L8.06077 9.36547V10.7675Z"                   
                />
                <path
                    d="M12.0095 6.44155C12.0581 6.31347 12.0849 6.18109 12.0844 6.04145L12.0844 4.63873C12.0875 4.34029 11.9722 4.05284 11.7636 3.8394C11.5553 3.62624 11.2711 3.50427 10.9731 3.50015H4.61115C4.31324 3.50435 4.02911 3.62635 3.82089 3.83949C3.61238 4.05294 3.49703 4.34036 3.50013 4.63873L3.50015 4.6408L3.50014 6.04289C3.49704 6.34126 3.61238 6.62868 3.82089 6.84212C4.02912 7.05527 4.31325 7.17728 4.61116 7.18148H4.63478C4.7716 7.18176 4.90319 7.23628 4.9999 7.3331C5.09665 7.42995 5.15096 7.56116 5.1511 7.69794L5.1511 8.34943L6.47238 7.29546C6.56361 7.22315 6.67701 7.18323 6.7934 7.18281L7.8255 7.18281V8.21614H6.97513L4.95654 9.82681C4.865 9.89941 4.75118 9.93934 4.63435 9.93948L4.6323 9.93948C4.55655 9.93879 4.48086 9.92167 4.4111 9.88756C4.32397 9.84515 4.25053 9.77919 4.19901 9.69721C4.14747 9.61521 4.11981 9.52032 4.11943 9.42326L4.11943 8.15755C3.69354 8.05506 3.30806 7.82348 3.0169 7.4925C2.66225 7.09212 2.46657 6.57568 2.46684 6.04081V4.64146C2.46321 4.06953 2.68637 3.51946 3.08762 3.11181C3.48901 2.70402 4.03578 2.47205 4.60796 2.46681L4.60979 2.4668L10.9763 2.46681C11.5485 2.47195 12.0953 2.70387 12.4968 3.11168C12.8981 3.51934 13.1215 4.0695 13.1178 4.64155V6.04035C13.1184 6.15554 13.0924 6.4656 12.9837 6.78436C12.8864 7.0311 12.7452 7.26046 12.6119 7.48874L11.755 6.92564L12.0095 6.44155Z"                    
                />   
            </svg-custom>

        `
    )
};

