import SmsProductIcon from "./icons/SmsProductIcon";
import RcsProductIcon from "./icons/RcsProductIcon";
import BotoesIcon from "./icons/BotoesIcon";
import BotoesSmallIcon from "./icons/BotoesSmallIcon";
import SimplesIcon from "./icons/SimplesIcon";
import SimplesSmallIcon from "./icons/SimplesSmallIcon";
import RespostasRapidasIcon from "./icons/RespostasRapidasIcon";
import RespostasRapidasSmallIcon from "./icons/RespostasRapidasSmallIcon";
import CompletoIcon from "./icons/CompletoIcon";
import CompletoSmallIcon from "./icons/CompletoSmallIcon";
import RespostasRapidasPlusIcon from "./icons/RespostasRapidasPlusIcon";
import AddBotaoIcon from "./icons/AddBotaoIcon";
import AddCampoIcon from "./icons/AddCampoIcon";
import BotoesSoloIcon from "./icons/BotoesSoloIcon";
import CaixaTextoIcon from "./icons/CaixaTextoIcon";
import CamposPersonalizadosIcon from "./icons/CamposPersonalizadosIcon";
import IconeTextoIcon from "./icons/IconeTextoIcon";
import AnexoIcon from "./icons/AnexoIcon";
import ChatBubbleIcon from "./icons/ChatBubbleIcon";
import ApiMessagesIcon from "./icons/ApiMessagesIcon";
import MultiChatBubbleIcon from "./icons/MultiChatBubbleIcon.js?v=2";
import CheckCircleIcon from "./icons/CheckCircleIcon";
import CloseCircleIcon from "./icons/CloseCircleIcon";
import ClockIcon from "./icons/ClockIcon";
import InfoIcon from "./icons/InfoIcon";
import BlockedIcon from "./icons/BlockedIcon";
import UserRefreshCircleIcon from "./icons/UserRefreshCircleIcon";
import FileAlertIcon from "./icons/FileAlertIcon";
import OpenOutlineIcon from "./icons/OpenOutlineIcon";
import OpenIcon from "./icons/OpenIcon";
import EditOutlineIcon from "./icons/EditOutlineIcon";
import EditIcon from "./icons/EditIcon";
import EscalonarOutlineIcon from "./icons/EscalonarOutlineIcon";
import EscalonarIcon from "./icons/EscalonarIcon";
import PauseOutlineIcon from "./icons/PauseOutlineIcon";
import PauseIcon from "./icons/PauseIcon";
import PlayOutlineIcon from "./icons/PlayOutlineIcon";
import PlayIcon from "./icons/PlayIcon";
import CancelOutlineIcon from "./icons/CancelOutlineIcon";
import CancelIcon from "./icons/CancelIcon";
import MoreInfoIcon from "./icons/MoreInfoIcon";
import MoreInfoOutlineIcon from "./icons/MoreInfoOutlineIcon";
import OpenTrashIcon from "./icons/OpenTrashIcon";
import TrashOutlineIcon from "./icons/TrashOutlineIcon";
import DisableIcon from "./icons/DisableIcon";
import DisableOutlineIcon from "./icons/DisableOutlineIcon";
import DownloadIcon from "./icons/DownloadIcon";
import DownloadOutlineIcon from "./icons/DownloadOutlineIcon";
import UserUnlockedIcon from "./icons/UserUnlockedIcon";
import UserLockedOutlineIcon from "./icons/UserLockedOutlineIcon";
import InternalPermissionIcon from "./icons/InternalPermissionIcon";
import InternalPermissionOutlineIcon from "./icons/InternalPermissionOutlineIcon";
import ChatIcon from "./icons/ChatIcon";
import ChatOutlineIcon from "./icons/ChatOutlineIcon";
import ChatFinishIcon from "./icons/ChatFinishIcon";
import ChatFinishOutlineIcon from "./icons/ChatFinishOutlineIcon";
import MailOutlineIcon from "./icons/MailOutlineIcon";
import MailOpenOutlineIcon from "./icons/MailOpenOutlineIcon";
import CheckIcon from "./icons/CheckIcon";
import DiscardIcon from "./icons/DiscardIcon";
import AndroidBackIcon from "./icons/AndroidBackIcon";
import AndroidHomeIcon from "./icons/AndroidHomeIcon";
import AndroidMenuIcon from "./icons/AndroidMenuIcon";
import ImageIcon from "./icons/ImageIcon";
import ImageSmallIcon from "./icons/ImageSmallIcon";
import ImageVariableIcon from "./icons/ImageVariableIcon";
import ImageVariableSmallIcon from "./icons/ImageVariableSmallIcon";
import PlaceholderVariableImagesIcon from "./icons/PlaceholderVariableImagesIcon";
import IconInvalid from "./icons/IconInvalid";
import CardVerticalSmallIcon from "./icons/CardVerticalSmallIcon";
import CardVerticalMediumIcon from "./icons/CardVerticalMediumIcon";
import CardVerticalLargeIcon from "./icons/CardVerticalLargeIcon";
import CardHorizontalRightIcon from "./icons/CardHorizontalRightIcon";
import CardHorizontalLeftIcon from "./icons/CardHorizontalLeftIcon";
import TemplateTextIcon from "./icons/TemplateTextIcon";
import TemplateImageIcon from "./icons/TemplateImageIcon";
import TemplateCarouselIcon from "./icons/TemplateCarouselIcon";
import TemplateCardIcon from "./icons/TemplateCardIcon";
import SmsProductSmallIcon from "./icons/SmsProductSmallIcon";
import ImportadosCardsIcon from "./icons/ImportadosCardsIcon";
import ProcessadosCardsIcon from "./icons/ProcessadosCardsIcon";
import HigienizadosCardsIcon from "./icons/HigienizadosCardsIcon";
import EnviadosCardsIcon from "./icons/EnviadosCardsIcon";
import RadarCardsIcon from "./icons/RadarCardsIcon";
import ExclamationCircleIcon from "./icons/ExclamationCircleIcon";
import ClockFillIcon from "./icons/ClockFillIcon";
import CheckCircleFillIcon from "./icons/CheckCircleFillIcon";
import TimesCircleFillIcon from "./icons/TimesCircleFillIcon";
import SendMessageIcon from "./icons/SendMessageIcon";
import SmsAlternativoIcon from "./icons/SmsAlternativoIcon";
import TemplateVideoIcon from "./icons/TemplateVideoIcon";
import TemplateAudioIcon from "./icons/TemplateAudioIcon";
import TemplateFileIcon from "./icons/TemplateFileIcon";
import TemplateIcon from "./icons/TemplateIcon";
import ExclamationTriangleIcon from "./icons/ExclamationTriangleIcon";

export default {
    smsProductIcon: { component: SmsProductIcon },
    rcsProductIcon: { component: RcsProductIcon },
    botoesIcon: { component: BotoesIcon },
    botoesSmallIcon: { component: BotoesSmallIcon },
    simplesIcon: { component: SimplesIcon },
    simplesSmallIcon: { component: SimplesSmallIcon },
    respostasRapidasIcon: { component: RespostasRapidasIcon },
    respostasRapidasSmallIcon: { component: RespostasRapidasSmallIcon },
    completoIcon: { component: CompletoIcon },
    completoSmallIcon: { component: CompletoSmallIcon },
    respostasRapidasPlusIcon: { component: RespostasRapidasPlusIcon },
    addBotaoIcon: { component: AddBotaoIcon },
    addCampoIcon: { component: AddCampoIcon },
    botoesSoloIcon: { component: BotoesSoloIcon },
    caixaTextoIcon: { component: CaixaTextoIcon },
    camposPersonalizadosIcon: { component: CamposPersonalizadosIcon },
    iconeTextoIcon: { component: IconeTextoIcon },
    anexoIcon: { component: AnexoIcon },
    chatBubbleIcon: { component: ChatBubbleIcon },
    apiMessagesIcon: { component: ApiMessagesIcon },
    multiChatBubbleIcon: { component: MultiChatBubbleIcon },
    checkCircleIcon: { component: CheckCircleIcon },
    closeCircleIcon: { component: CloseCircleIcon },
    clockIcon: { component: ClockIcon },
    infoIcon: { component: InfoIcon },
    blockedIcon: { component: BlockedIcon },
    userRefreshCircleIcon: { component: UserRefreshCircleIcon },
    fileAlertIcon: { component: FileAlertIcon },
    openIcon: { component: OpenIcon },
    openOutlineIcon: { component: OpenOutlineIcon },
    editIcon: { component: EditIcon },
    editOutlineIcon: { component: EditOutlineIcon },
    escalonarIcon: { component: EscalonarIcon },
    escalonarOutlineIcon: { component: EscalonarOutlineIcon },
    pauseIcon: { component: PauseIcon },
    pauseOutlineIcon: { component: PauseOutlineIcon },
    playIcon: { component: PlayIcon },
    playOutlineIcon: { component: PlayOutlineIcon },
    cancelIcon: { component: CancelIcon },
    cancelOutlineIcon: { component: CancelOutlineIcon },
    moreInfoIcon: { component: MoreInfoIcon },
    moreInfoOutlineIcon: { component: MoreInfoOutlineIcon },
    openTrashIcon: { component: OpenTrashIcon },
    trashOutlineIcon: { component: TrashOutlineIcon },
    disableIcon: { component: DisableIcon },
    disableOutlineIcon: { component: DisableOutlineIcon },
    downloadIcon: { component: DownloadIcon },
    downloadOutlineIcon: { component: DownloadOutlineIcon },
    userUnlockedIcon: { component: UserUnlockedIcon },
    userLockedOutlineIcon: { component: UserLockedOutlineIcon },
    internalPermissionIcon: { component: InternalPermissionIcon },
    internalPermissionOutlineIcon: { component: InternalPermissionOutlineIcon },
    chatIcon: { component: ChatIcon },
    chatOutlineIcon: { component: ChatOutlineIcon },
    chatFinishIcon: { component: ChatFinishIcon },
    chatFinishOutlineIcon: { component: ChatFinishOutlineIcon },
    mailOutlineIcon: { component: MailOutlineIcon },
    mailOpenOutlineIcon: { component: MailOpenOutlineIcon },
    checkIcon: { component: CheckIcon },
    discardIcon: { component: DiscardIcon },
    androidHomeIcon: { component: AndroidHomeIcon },
    androidBackIcon: { component: AndroidBackIcon },
    androidMenuIcon: { component: AndroidMenuIcon },
    imageIcon: { component: ImageIcon },
    imageSmallIcon: { component: ImageSmallIcon },
    imageVariableIcon: { component: ImageVariableIcon },
    imageVariableSmallIcon: { component: ImageVariableSmallIcon },
    placeholderVariableImagesIcon: { component: PlaceholderVariableImagesIcon },
    iconInvalid: { component: IconInvalid },
    cardVerticalSmallIcon: { component: CardVerticalSmallIcon },
    cardVerticalMediumIcon: { component: CardVerticalMediumIcon },
    cardVerticalLargeIcon: { component: CardVerticalLargeIcon },
    cardHorizontalRightIcon: { component: CardHorizontalRightIcon },
    cardHorizontalLeftIcon: { component: CardHorizontalLeftIcon },
    templateTextIcon: { component: TemplateTextIcon },
    templateImageIcon: { component: TemplateImageIcon },
    templateCarouselIcon: { component: TemplateCarouselIcon },
    templateCardIcon: { component: TemplateCardIcon },
    smsProductSmallIcon: { component: SmsProductSmallIcon },
    importadosCardsIcon: { component: ImportadosCardsIcon },
    processadosCardsIcon: { component: ProcessadosCardsIcon },
    higienizadosCardsIcon: { component: HigienizadosCardsIcon },
    enviadosCardsIcon: { component: EnviadosCardsIcon },
    radarCardsIcon: { component: RadarCardsIcon },
    exclamationCircleIcon: { component: ExclamationCircleIcon },
    clockFillIcon: { component: ClockFillIcon },
    checkCircleFillIcon: { component: CheckCircleFillIcon },
    timesCircleFillIcon: { component: TimesCircleFillIcon },
    sendMessageIcon: { component: SendMessageIcon },
    smsAlternativoIcon: { component: SmsAlternativoIcon },
    templateVideoIcon: { component: TemplateVideoIcon },
    templateAudioIcon: { component: TemplateAudioIcon },
    templateFileIcon: { component: TemplateFileIcon },
    templateIcon: { component: TemplateIcon },
    exclamationTriangleIcon: { component: ExclamationTriangleIcon}
};