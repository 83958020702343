import BaseNotify from "../../../components/atomos/BaseNotify";

export default {
    components: {
        BaseNotify
    },
    data() {
        return {
            title: window.Vue.htmlEntities("Bot&atilde;o criado"),
            message: window.Vue.htmlEntities("O bot&atilde;o [nome do bot&atilde;o] foi criado e adicionado &agrave; mensagem"),
            type: "success",
            autoclose: false,
            icon: null,
            color: null,
            types: ["notification", "success", "error", "warning", "info", "custom"],
            active: false
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6" class="text-center">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field label="Titulo" v-model="title"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Mensagem" v-model="message"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-select label="Tipo" :items="types" v-model="type"></v-select>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-checkbox label="Fechamento automatico" v-model="autoclose"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Icone (somente se tipo = custom)" v-model="icon"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Cor (somente se tipo = custom)" v-model="color"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn color="dark-primary white--text" @click="active = true">testar</v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <base-notify
                                            :active="active"
                                            :title="title"
                                            :message="message"
                                            :type="type"
                                            :autoclose="autoclose"
                                            :icon="icon"
                                            :color="color"
                                            @close="active = false"
                                        ></base-notify>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};