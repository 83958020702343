import TextFieldPhoneValidator from "@/components/moleculas/TextFieldPhoneValidator";

export default {
    name: "text-field-phone-validator-usage",
    components: { "text-field-phone-validator": TextFieldPhoneValidator },
    template: (
        /*html*/
        `
            <div class="row justify-center">
                <div class="col-12 mt-5">
                    <text-field-phone-validator>
                    </text-field-phone-validator>
                </div>
            </div>
     
        `
    )
};