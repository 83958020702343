import DialogTemplateManualUsage from "./DialogTemplateManualUsage";

export default [
    {
        path: "dialog-template-manual-usage",
        name: "Dialog template manual usage",
        component: DialogTemplateManualUsage
    }

];
