/**
 * Componente de card largo clicavel
 *
 * @displayName BaseCardLarge
 * @category Atomos
 * @component
 * @vue
 * @vue/component
 */
export default {
    props: {
        /**
         * Icone do botao
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * titulo do botao
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Descricao do botao
         */
        description: {
            type: String,
            required: true
        },
        /**
         * Valor de v-model para indicar que o card esta selecionado
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Verifica se o conteudo ficara na horizontal
         */
        isHorizontal: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Verifica se o conteudo ficara desabilitado
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Impede que o botao seja desselecionado no click
         */
        blockUnselect: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica que o componente deve ter o estado de em breve
         */
        soon: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Tamanho customizado do icone
         */
        size: {
            type: Number,
            required: false,
            default: 29
        }
    },
    data() {
        return {
            selected: this.value,
            hover: false
        };
    },
    computed: {
        cardBorder() {
            let color = "border-gray-800";
            let style = "border-1";

            if (this.disabled) {
                color = "border-gray-100";
            } else if (this.soon) {
                color = "border-gray-100";
                style = "border-dashed-1";
            } else if (this.hover || this.selected) {
                color = "border-primary-blue-500";
            }

            return `rounded-1 ${style} ${color}`;
        },
        cardColor() {
            let color = "gray-0";

            if (this.disabled) {
                color = "gray-10";
            } else if (this.selected && !this.soon) {
                color = "primary-blue-500";
            } else if (this.hover && !this.soon) {
                color = "gray-30";
            }

            return color;
        },
        contentColor() {
            let color = "gray-800";

            if (this.disabled || this.soon) {
                color = "gray-100";
            } else if (this.selected) {
                color = "gray-0";
            } else if (this.hover) {
                color = "primary-blue-500";
            }

            return color;
        }
    },
    watch: {
        value() {
            this.selected = this.value;
        }
    },
    methods: {
        /**
         * Click do botao
         * @vue
         */
        click() {
            if (
                !this.disabled &&
                !this.soon &&
                !(this.selected && this.blockUnselect)
            ) {
                /**
                 * Evento de click do botao
                 * @event click
                 * @vue
                 */
                this.$emit("click");
                this.$emit("input", !this.selected);
            }
        }
    },
    template:
        /*html*/
        `
        <v-hover v-model="hover" :disabled="disabled || soon">
            <v-row no-gutters class="fill-height">
                <v-col cols="12" class="fill-height">
                    <v-card
                        elevation="0" 
                        :class="cardBorder + ' bg-' + cardColor + ' fill-height d-flex align-center text-' + contentColor"
                        :disabled="disabled || soon"
                        :min-width="isHorizontal ? 213 : 137"
                        :min-height="isHorizontal ? 100 : 144"
                        @click="click" 
                    >
                        <v-row no-gutters align="center" :class="'fill-height pa-4' + (isHorizontal ? ' flex-no-wrap' : '')">
                            <v-col :cols="isHorizontal ? 'auto' : 12" class="text-center">
                                <v-icon :size="size" :class="'text-' + contentColor">{{ icon }}</v-icon>
                            </v-col>
                            <v-col :cols="isHorizontal ? undefined : 12" :class="isHorizontal ? 'pl-4' : 'pt-4'">
                                <v-row no-gutters>
                                    <v-col cols="12" class="text-start">
                                        <span class="body-2 bold" v-html="title"></span>
                                    </v-col>
                                    <v-col cols="12" class="text-start mt-2">
                                        <span class="caption" v-html="description"></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col v-if="soon && !disabled" cols="12" class="text-center">
                    <span class="caption text-gray-600">Em breve!</span>
                </v-col>
            </v-row>
        </v-hover>
        `
};
