import GroupButtonCheckbox from "../../../components/moleculas/GroupButtonCheckbox";
import ButtonCheckboxClass from "../../../classes/ButtonCheckboxClass";

export default {
    components: {
        GroupButtonCheckbox
    },
    data() {
        return {
            data: [
                new ButtonCheckboxClass({
                    label: "Mensagens Avulsas (147)",
                    icon: "$userRefreshCircleIcon",
                    checked: false,
                    backgroundColor: "off-white",
                    backgroundColorHover: "extra-light-grey",
                    backgroundColorChecked: "dark-primary",
                    contentColor: "black",
                    contentColorHover: "black",
                    contentColorChecked: "off-white"
                }),
                new ButtonCheckboxClass({
                    label: "Mensagens Avulsas (147)",
                    icon: "$userRefreshCircleIcon",
                    checked: false,
                    backgroundColor: "off-white",
                    backgroundColorHover: "extra-light-grey",
                    backgroundColorChecked: "dark-primary",
                    contentColor: "black",
                    contentColorHover: "black",
                    contentColorChecked: "off-white"
                })
            ],
            single: false
        };
    },
    methods: {
        click(buttons) {
            console.log(buttons);
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5 bg-background">
                                <v-row justify="center">
                                    <v-col cols="12">
                                        <v-checkbox 
                                            label="Single select"
                                            v-model="single"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <group-button-checkbox 
                                            :buttons="data"
                                            :single-select="single"
                                            @click="click"    
                                        >
                                        </group-button-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};