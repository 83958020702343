import BaseTextField from "../../../../components/atomos/BaseTextField";
import BaseButton from "../../../../components/atomos/BaseButton";
import BaseAutocomplete from "../../../../components/atomos/BaseAutocomplete";
import BaseDatePicker from "../../../../components/atomos/BaseDatePicker";
import FilterPageSlider from "../../../../components/atomos/FilterPageSlider";
import {CampanhaService} from "../../../../../../js/services/CampanhaService";

/**
 * Componente de formulario de pesquisa de avulsos
 *
 * @requires {@link BaseTextField}
 * @requires {@link BaseButton}
 * @requires {@link BaseAutocomplete}
 * @requires {@link BaseDatePicker}
 * @requires {@link FilterPageSlider}
 *
 * @displayName FormFilterAvulsoApi
 * @category Page / Avulsos
 * @subcategory forms
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseButton,
        BaseAutocomplete,
        BaseDatePicker,
        FilterPageSlider
    },
    props: {
        /**
         * Conteudo do formulario
         */
        value: {
            type: Object,
            required: false,
            default() {
                return {
                    id_empresa: null,
                    status: null,
                    campo_informado: null,
                    telefone: null,
                    data_inicio: dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                    data_fim: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                    codigo_agrupador: null
                };
            }
        }
    },
    data() {
        return {
            campanha_service: new CampanhaService(),
            form_data: this.value,
            client_loading: false,
            client_list: [],
            situation_loading: false,
            situation_list: [],
            pills_filter: [],
            rules: {
                phone: [
                    v => !v || Number.isInteger(parseInt(v.match(/\d/g).join(""))) || window.Vue.htmlEntities("1Informe um telefone v&aacute;lido"),
                    v => !v || (v.match(/\d/g).length === 11 || v.match(/\d/g).length === 11) || window.Vue.htmlEntities("2Informe um telefone v&aacute;lido")
                ]
            },
            today: dayjs(),
        };
    },
    computed: {
        showReset() {
            let has_filter = false;

            for (let key of Object.keys(this.form_data)) {
                if (this.form_data[key] && this.form_data[key].length > 0) {
                    has_filter = true;
                }
            }

            return has_filter;
        }
    },
    watch: {
        value() {
            this.form_data = this.value;
        }
    },
    mounted() {
        this.getClients();
        this.getSituations();

        this.setStartDate(this.today);
        this.setEndDate(this.today);
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetMyClients"]),
        ...window.Vuex.mapActions("avulsoapi", ["ActionDoGetStatusOptions"]),

        /**
         * Submit do formulario, atualiza a lista de dados conforme o filtro
         * @vue
         */
        formSubmit() {
            if (this.$refs.form.validate()) {
                /**
                 * Emite o conteudo do formulario
                 * @property {Object} form_data Conteudo emitido
                 */
                this.$emit("input", this.form_data);
                this.$emit("formSubmit", this.form_data);
                this.makePillsFilter();
            }
        },
        /**
         * Define dia inicial do filtro
         * @param {String} start_date
         * @vue
         */
        setStartDate(start_date) {
            let date = dayjs(start_date).format("YYYY-MM-DD");
            let date_with_hours = dayjs(start_date).startOf("day").format("YYYY-MM-DD HH:mm:ss");

            this.form_data.data_inicio = date;
            this.$emit("setFilterStartDate", date_with_hours);
        },
        /**
         * Define dia final do filtro
         * @param {String} end_date
         * @vue
         */
        setEndDate(end_date) {
            let date = dayjs(end_date).format("YYYY-MM-DD");
            let date_end_hours = dayjs(end_date).endOf("day").format("YYYY-MM-DD HH:mm:ss");

            this.form_data.data_fim = date;
            this.$emit("setFilterEndDate", date_end_hours);
        },
        /**
         * Limpa os filtros do formulário
         * @vue
         */
        resetFilter() {
            // Nome de variáveis que serão resetadas
            let resetInputs = ["client", "situation", "informed_field", "phone", "grouper_code"];

            // Percore inputs do form
            this.$refs.form.inputs.forEach((input) => {
                // Verifica se o nome do input está na variável para ser resetada
                if (resetInputs.includes(input.$refs.input.name)) {
                    input.reset();
                }
            });

            // Remove erros de validação
            this.$refs.form.resetErrorBag();

            // Define valor padrão para as datas
            this.setStartDate(this.today);
            this.setEndDate(this.today);

            // Carrega Cards/Table com os filtros resetados
            setTimeout(() => {
                this.formSubmit();
            }, 500);
        },
        /**
         * Retorna a mascara de telefone para aplicar ao campo de acordo com a quantidade de caracteres inseridos
         * @returns {String} Mask phone
         * @vue
         */
        maskPhone() {
            if (this.form_data.telefone) {
                return this.form_data.telefone.length == 15 ? "(##) #####-####" : "(##) ####-#####";
            } else {
                return "(##) #####-####";
            }
        },
        /**
         * Busca na API e monta a lista de clientes (carteiras) que a empresa definida na sessao possui
         * @vue
         */
        getClients() {
            this.client_loading = true;
            this.ActionDoGetMyClients(true).then(response => {
                this.client_list = response;
                this.client_loading = false;
            });
        },
        /**
         * Busca na API e monta a lista de situacoes (status) dos avulsos
         * @vue
         */
        getSituations() {
            this.situation_loading = true;
            this.situation_list = [
                {"value": "WAITING", "text": "Aguardando"},
                {"value": "CANCELED", "text": "Cancelado"},
                {"value": "NOT_SENT", "text": window.Vue.htmlEntities("N&atilde;o enviado")},
                {"value": "SENT", "text": "Enviado"},
                {"value": "NOT_DELIVERED", "text": window.Vue.htmlEntities("N&atilde;o entregue")},
                {"value": "DELIVERED", "text": "Entregue"}
            ];

            this.situation_loading = false;

        },
        /**
         * Monta a configuracao das pills do filtro
         * @vue
         */
        makePillsFilter() {
            let config = [];

            if (this.form_data.id_empresa !== null && this.form_data.id_empresa.length > 0) {
                config.push({
                    text: "Cliente:",
                    subText: this.form_data.id_empresa.length + " selecionado(s)",
                    click: () => {
                        this.form_data.id_empresa = [];
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.status !== null && this.form_data.status.length > 0) {
                config.push({
                    text: window.Vue.htmlEntities("Situa&ccedil;&atilde;o:"),
                    subText: this.form_data.status.length + " selecionado(s)",
                    click: () => {
                        this.form_data.status = [];
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.campo_informado !== null && this.form_data.campo_informado.length > 0) {
                config.push({
                    text: "Campo informado:",
                    subText: this.form_data.campo_informado,
                    click: () => {
                        this.form_data.campo_informado = "";
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.telefone !== null && this.form_data.telefone.length > 0) {
                config.push({
                    text: "Telefone:",
                    subText: this.form_data.telefone,
                    click: () => {
                        this.form_data.telefone = "";
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.data_inicio !== null && this.form_data.data_inicio.length > 0) {
                config.push({
                    removable: false,
                    text: window.Vue.htmlEntities("Data in&iacute;cio:"),
                    subText: moment(this.form_data.data_inicio).format("DD/MM/YYYY"),
                    click: () => {
                        //this.setStartDate(this.today);
                        //this.formSubmit();
                    }
                });
            }

            if (this.form_data.data_fim !== null && this.form_data.data_fim.length > 0) {
                config.push({
                    removable: false,
                    text: "Data fim",
                    subText: moment(this.form_data.data_fim).format("DD/MM/YYYY"),
                    click: () => {
                        //this.setEndDate(this.today);
                        //this.formSubmit();
                    }
                });
            }

            if (this.form_data.codigo_agrupador !== null && this.form_data.codigo_agrupador.length > 0) {
                config.push({
                    text: window.Vue.htmlEntities("C&oacute;digo agrupador"),
                    subText: this.form_data.codigo_agrupador,
                    click: () => {
                        this.form_data.codigo_agrupador = "";
                        this.formSubmit();
                    }
                });
            }

            this.pills_filter = config;
        }
    },
    template: (
        /*html*/
        `
        <filter-page-slider
            :pills-filter="pills_filter"
            :show-reset="showReset"
            :data-v-step="1"
            @reset="resetFilter();"
        >
            <v-form ref="form">
                <v-row class="mt-0" justify="center">
                    <v-col cols="6" lg="3">
                        <base-autocomplete
                            v-model="form_data.id_empresa"
                            tooltip-text="Voc&ecirc; pode selecionar um ou mais clientes(carteiras)."
                            label="Cliente"
                            title="Cliente"
                            name="client"
                            :items="client_list"
                            :rules="[]"
                            :loading="client_loading"
                            multiple
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="6" lg="3">
                        <base-autocomplete
                            v-model="form_data.status"
                            tooltip-text="Voc&ecirc; pode selecionar uma ou mais situa&ccedil;&otilde;es(status) dos disparos."
                            label="Situa&ccedil;&atilde;o"
                            title="Situa&ccedil;&atilde;o"
                            name="situation"
                            :items="situation_list"
                            :rules="[]"
                            :loading="situation_loading"
                            multiple
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="6" lg="3">
                        <base-text-field
                            v-model="form_data.campo_informado"
                            tooltip-text="Voc&ecirc; pode buscar por um campo espec&iacute;fico(se houver em seu arquivo de dados)"
                            label="Campo Informado"
                            title="Campo Informado"
                            name="informed_field"
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="6" lg="3">
                        <base-text-field
                            v-model="form_data.telefone"
                            tooltip-text="Voc&ecirc; pode pesquisar um n&uacute;mero de telefone  espec&iacute;fico."
                            label="(__) _____-____"
                            title="Telefone"
                            name="phone"
                            type="tel"
                            :mask="maskPhone()"
                            :rules="rules.phone"
                            use-icon-validation
                        />
                    </v-col>
                </v-row>
                <v-row class="mt-0" justify="center" justify-md="end">
                    <v-col cols="6" lg="3">
                        <base-date-picker
                            v-model="form_data.data_inicio"
                            tooltip-text="Escolha um per&iacute;odo para pesquisar"
                            label=""
                            title="Data in&iacute;cio"
                            name="start_date"
                            :max-date="form_data.data_fim"
                            close-on-click="true"
                            use-icon-validation
                            @input="setStartDate"
                        />
                    </v-col>
                    <v-col cols="6" lg="3">
                        <base-date-picker
                            v-model="form_data.data_fim"
                            tooltip-text="Escolha um per&iacute;odo para pesquisar"
                            label=""
                            title="Data fim"
                            name="end_date"
                            :min-date="form_data.data_inicio"
                            close-on-click="true"
                            use-icon-validation
                            @input="setEndDate"
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <base-text-field
                            v-model="form_data.codigo_agrupador"
                            tooltip-text="Voc&ecirc; pode buscar pelo c&oacute;digo agrupador"
                            label="Codigo agrupador"
                            title="C&oacute;digo agrupador"
                            name="grouper_code"
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="12" lg="3" class="pt-6">
                        <base-button
                            block
                            icon="fa fa-filter"
                            label="Aplicar Filtros"
                            variant="extra-dark-grey"
                            :disabled="false"
                            @click="formSubmit()"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </filter-page-slider>
        `
    )
};