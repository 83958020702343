import BaseCardShort from "../atomos/BaseCardShort";

/**
 * Componente base para uso de botoes como radio button
 *
 * @displayName BaseRadioButtons
 * @component
 * @requires {@link BaseCardShort}
 * @category Moleculas
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 */
export default {
    components: {
        BaseCardShort
    },
    props: {
        /**
         * Lista de botoes que irao compor o componente a estrutura deve ser
         */
        value: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            list_buttons: this.value
        };
    },
    methods: {
        /**
         * Metodo acionado no click de um botao
         * @param {Object} button Dados do botao que foi selecionado
         * @category Moleculas
         * @vue
         */
        clicked(button) {
            for (let item_btn of this.list_buttons) {
                if (item_btn.id !== button.id) {
                    item_btn.active = false;
                } else {
                    item_btn.active = true;
                }
            }

            /**
             * Evento que emite os dados do botao selecionado
             * 
             * @property {Array} list_buttons Dados de um botao
             * 
             * @category Moleculas
             * @event selected
             * @vue
             */
            this.$emit("input", this.list_buttons);
            this.$emit("change");
        }
    },
    template: (
        /*html*/
        `
        <v-row no-gutters class="flex-nowrap">
            <v-col v-for="(button, i) in list_buttons" :key="i" :cols="button.cols">
                <base-card-short
                    v-model="button.active"
                    :icon="button.icon"
                    :label="button.label"
                    :disabled="button.disabled"
                    :soon="button.soon"
                    :vertical="button.vertical"
                    :border-radius="i === 0 ? 'rounded-left-1' : (i === (list_buttons.length - 1) ? 'rounded-right-1' : 'rounded-0')"
                    :tooltip-text="button.tooltip"
                    border-color="gray-300"
                    @click="clicked(button)"
                ></base-card-short>
            </v-col>
        </v-row>
        `
    )
};