import CadastroTemplatePassoUm from "../../template-rcs/components/CadastroTemplatePassoUm";

export default {
    components: {
        CadastroTemplatePassoUm
    },
    data() {
        return {
            conteudo: null
        };
    },
    watch: {
        conteudo() {
            console.log(this.conteudo);
        }
    },
    template: (
        /*html*/
        `
            <v-container>
                <v-row  justify="center">
                    <v-col class="mt-5" cols="12" md="4" lg="3">
                    </v-col>
                    <v-col class="mt-5" cols="12" md="8" lg="9">
                        <cadastro-template-passo-um v-model="conteudo"></cadastro-template-passo-um>
                    </v-col>
                </v-row>
            </v-container>
        `
    )
};