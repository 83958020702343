import BaseTooltip from "./BaseTooltip";

/**
 * Componente de dropdown
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName BaseDropdown
 * @component
 * @category Atomos
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Valor selecionado, alterado por v-model
         */
        value: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Lista de opcoes no formado Object: { item1: { icon: String, label: String } }
         */
        options: {
            type: Object,
            required: true
        },
        /**
         * Desabilita o componente
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Texto do tooltip
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            input: this.value ?? Object.keys(this.options)[0],
            hovered: false,
            show_options: false
        };
    },
    computed: {
        buttonTextColor() {
            let color = "gray-500";

            if (this.disabled) {
                color = "gray-100";
            } else if (this.hovered || this.show_options) {
                color = "gray-900";
            }

            return color;
        },
        buttonIconColor() {
            return this.disabled ? "gray-100" : "gray-500";
        },
        buttonBackgroundColor() {
            return this.hovered && !this.show_options ? "secondary-blue-100" : "transparent";
        },
        buttonBorder() {
            return this.show_options ? "border-2 border-secondary-blue-500" : "";
        }
    },
    watch: {
        value() {
            this.input = this.value;
        },
        input() {
            this.$emit("input", this.input);
        }
    },
    template: (
        /*html*/
        `
            <v-menu offset-y v-model="show_options" content-class="my-1" :disabled="disabled">
                <template #activator="{ on, attrs }">
                    <v-hover v-model="hovered" :disabled="disabled">
                        <v-card v-bind="attrs" v-on="on" elevation="0" width="fit-content" :class="'pa-2 bg-' + buttonBackgroundColor + ' ' + buttonBorder">
                            <base-tooltip :text="tooltipText">
                                <v-row no-gutters>
                                    <v-col>
                                        <span :class="'body-2 pr-2 text-' + buttonTextColor" v-html="options[input].label" /><v-icon size="17" :color="buttonIconColor">mdi-menu-down</v-icon>
                                    </v-col>
                                </v-row>
                            </base-tooltip>
                        </v-card>
                    </v-hover>
                </template>
                <v-card class="pa-2 bg-gray-0 rounded-1">
                    <v-row no-gutters class="gap-2">
                        <v-col v-for="(item, index) in options" :key="index">
                            <v-hover v-slot="{ hover }">
                                <v-card elevation="0" :class="'rounded-1 ' + (input === index ? 'bg-primary-blue-500' : (hover ? 'bg-secondary-blue-100' : 'bg-gray-0'))" @click="input = index">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="pt-4 d-flex justify-center">
                                            <v-icon size="32" :color="input === index ? 'gray-0' : (hover ? 'primary-blue-500' : 'gray-800')">{{ item.icon }}</v-icon>
                                        </v-col>
                                        <v-col cols="12" class="pb-4 pt-2 text-center">
                                            <span :class="'body-2 text-' + (input === index ? 'gray-0' : (hover ? 'primary-blue-500' : 'gray-800'))" v-html="item.label" />
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-card>
            </v-menu>
        `
    )
};