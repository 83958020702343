import DialogRepeatReport from "../../reports/components/dialogs/DialogRepeatReport";

export default {
    components: {
        DialogRepeatReport
    },
    data() {
        return {
            show: false,
        };
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="12" md="8" lg="6">
                <v-card class="d-flex justify-center">
                    <v-btn @click="show = true" color="dark-primary white--text" class="my-10">Abrir modal</v-btn>
                </v-card>
            </v-col>
            <dialog-repeat-report v-model="show"></dialog-repeat-report>
        </v-row>
        `
    )
};