import PageHeader from "../../components/moleculas/PageHeader.js?v=2";
import BaseButton from "../../components/atomos/BaseButton.js?v=2";
import BaseDataTable from "../../components/organismos/BaseDataTable.js?v=2";
import StatusCampaign from "../../components/moleculas/StatusCampaign?v=2";
import BaseProgressBar from "../../components/atomos/BaseProgressBar?v=2";
import CanalIconDescription from "../../components/moleculas/CanalIconDescription.js?v=2";
import PgTour from "../../components/organismos/PgTour.js?v=2";
import FormFilterGerenciar from "./components/forms/FormFilterGerenciar.js";
import ButtonAction from "../../components/moleculas/ButtonAction?v=2";
import ExportGerenciar from "./components/ExportGerenciar.js";
import DialogCampaignDetails from "../../components/organismos/DialogCampaignDetails?v=2";
import { DataTableHeaderClass } from "../../classes/DataTableHeaderClass.js";
import { BaseService } from "../../services/BaseService.js";
import { ProgressBarValueClass } from "../../classes/ProgressBarValueClass";

/**
 * Pagina que realiza a listagem de campanhas
 * 
 * @requires {@link PageHeader}
 * @requires {@link BaseButton}
 * @requires {@link BaseDataTable}
 * @requires {@link StatusCampaign}
 * @requires {@link BaseProgressBar}
 * @requires {@link CanalIconDescription}
 * @requires {@link PgTour}
 * @requires {@link FormFilterGerenciar}
 * @requires {@link ButtonAction}
 * @requires {@link ExportGerenciar}
 * @requires {@link DialogCampaignDetails}
 * @requires {@link DataTableHeaderClass}
 * @requires {@link BaseService}
 * @requires {@link ProgressBarValueClass}
 * 
 * @displayName GerenciarPage
 * @category Page / Gerenciar
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PageHeader,
        BaseButton,
        BaseDataTable,
        PgTour,
        FormFilterGerenciar,
        CanalIconDescription,
        StatusCampaign,
        BaseProgressBar,
        ButtonAction,
        ExportGerenciar,
        DialogCampaignDetails
    },
    filters: {
        format_date: function (date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY HH:mm:ss");
            } else {
                return "-";
            }
        }
    },
    data() {
        return {
            base_service: new BaseService(),
            type_product: this.$cookie === undefined ? getCookie("type_product") : this.$cookie.get("type_product"),
            filter_data: {},
            custom_slots: [
                "cd_produto",
                "NM_EMPRESA_EPES",
                "status_campanha",
                "DT_INICIO_CAES",
                "DT_FIM_CAES",
                "DT_REINICIO_CAES",
                "bloqueados",
                "cancelados",
                "radar",
                "has_fallback",
                "total_enviado",
                "total_entregue",
                "actions"
            ],
            loading: true,
            table_loading: true,
            steps: [
                {
                    target: "[data-v-step='1']",
                    title: "Filtros",
                    content: "J&aacute; carregamos a tela com alguns filtros aplicados, que exibem os envios mais recentes. Voc&ecirc; pode refinar a busca de acordo com a sua necessidade.",
                    date: "2022-10-07 00:00:00"
                },
                {
                    target: "[data-v-step='2']",
                    title: "Bot&otilde;es",
                    content: "Voc&ecirc; pode baixar as informa&ccedil;&otilde;es da tabela em excel (.csv) ou texto (.txt)"
                },
                {
                    target: "[data-v-step='3']",
                    title: "Personalizar tabela",
                    content: "Voc&ecirc; pode escolher quais s&atilde;o as colunas que deseja visualizar na tabela."
                },
                {
                    target: "[data-v-step='4']",
                    title: "Mostrar linhas",
                    content: "E tamb&eacute;m pode escolher quantas linhas por p&aacute;gina deseja ver."
                },
                {
                    target: ".v-data-table-header",
                    title: "Tabela din&acirc;mica",
                    content: "Escolha como deseja ordenar sua tabela clicando nas setas dispon&iacute;veis ao lado de cada t&iacute;tulo."
                }
            ],
            content_table: [],
            show_dialog_details: false,
            campaign_id: null,
        };
    },
    computed: {
        server_side_config() {
            if (Object.keys(this.filter_data).length > 0) {
                return {
                    request_to: "API",
                    url: "/campaign/list-campaigns",
                    data: this.filter_data
                };
            } else {
                return null;
            }
        },
        data_export() {
            return this.filter_data;
        },
        tableHeaders() {
            let list = [
                new DataTableHeaderClass({
                    text: "Id",
                    value: "ID_CAMPANHA_CAES",
                    tooltipText: "O identificador &eacute; um c&oacute;digo gerado automaticamente e pode ser utilizado para integra&ccedil;&otilde;es via API"
                }),
                new DataTableHeaderClass({
                    text: "Canal",
                    value: "cd_produto",
                    visibility: "xl",
                    cellClass: "text-nowrap",
                    tooltipText: "Canal utilizado para disparo da mensagem",
                    sortable: false,
                    stepTutorial: 5
                }),
                new DataTableHeaderClass({
                    text: "Cliente",
                    value: "NM_EMPRESA_EPES",
                    visibility: "sm",
                    tooltipText: "Cliente (carteira) que foi selecionado no momento de cria&ccedil;&atilde;o da campanha",
                    sortable: false,
                }),
                new DataTableHeaderClass({
                    text: "Campanha",
                    value: "NM_CAMPANHA_CAES",
                    tooltipText: "O nome da campanha pode ser gerado pelo arquivo importado ou via API"
                }),
                new DataTableHeaderClass({
                    text: "Status",
                    value: "status_campanha",
                    visibility: "lg",
                    tooltipText: "Situa&ccedil;&atilde;o atual da campanha",
                    stepTutorial: 7,
                    sortable: false
                }),
                new DataTableHeaderClass({
                    text: "Inicio",
                    value: "DT_INICIO_CAES",
                    visibility: "sm",
                    tooltipText: "Data e hora em que foram iniciados os envios",
                    stepTutorial: 6
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("Rein&iacute;cio"),
                    value: "DT_REINICIO_CAES",
                    visibility: "xl",
                    tooltipText: "Data e hora em que os envios foram reiniciados"
                }),
                new DataTableHeaderClass({
                    text: "Fim",
                    value: "DT_FIM_CAES",
                    visibility: "sm",
                    tooltipText: "Data e hora em que foram finalizados os envios"
                }),
                new DataTableHeaderClass({
                    text: "Total",
                    value: "QT_TOTAL_SMS_CAES",
                    visibility: "xl",
                    tooltipText: "Total de registros importados"
                }),
                new DataTableHeaderClass({
                    text: "Bloqueados",
                    value: "bloqueados",
                    visibility: "xl",
                    tooltipText: "Registros bloqueados pela higieniza&ccedil;&atilde;o",
                    sortable: false
                }),
                new DataTableHeaderClass({
                    text: "Cancelados",
                    value: "cancelados",
                    visibility: "xl",
                    tooltipText: "Envios cancelados por regras de neg&oacute;cio ou manualmente",
                    sortable: false
                }),
                new DataTableHeaderClass({
                    text: this.type_product === "RCS" ? window.Vue.htmlEntities("Sess&otilde;es") : "Radar",
                    value: "radar",
                    visibility: "sm",
                    tooltipText: "Mensagens de retorno dos destinat&aacute;rios",
                    sortable: false
                })
            ];

            if (this.type_product === "RCS") {
                list.push(new DataTableHeaderClass({
                    text: "SMS Alternativo",
                    value: "has_fallback",
                    tooltipText: "Icone informativo mostrando se a campanha cont&eacute;m mensagens de SMS alternativo ou n&atilde;o.",
                    sortable: false
                }));
            }

            list.push(...[
                new DataTableHeaderClass({
                    text: "Envios",
                    value: "total_enviado",
                    tooltipText: "Envios realizados",
                    stepTutorial: 8,
                    sortable: false
                }),
                new DataTableHeaderClass({
                    text: "Entrega",
                    value: "total_entregue",
                    visibility: "sm",
                    tooltipText: "Informa&ccedil;&otilde;es de entrega aos destinat&aacute;rios",
                    stepTutorial: 9,
                    sortable: false
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("A&ccedil;&otilde;es"),
                    value: "actions",
                    width: "50px",
                    sortable: false,
                    className: "text-nowrap",
                    tooltipText: "A&ccedil;&otilde;es que podem ser tomadas por campanha",
                    stepTutorial: 10
                })
            ]);

            return list;
        }
    },
    mounted() {
        let query_params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (query_params?.details_campaign !== undefined && query_params?.details_campaign) {
            setTimeout(() => {
                this.campaignDetails(query_params?.details_campaign);
            }, 1000);
        }
    },
    methods: {
        filterSubmit(data) {
            this.table_loading = false;
            this.filter_data = {
                clients: data.id_empresa,
                campaign_id: data.id_campanha,
                campaign_name: data.nome_campanha,
                status: data.status_campanha != "T" ? data.status_campanha : null,
                start_date: data.dataInicio,
                end_date: data.dataFim
            };
            this.loading = true;
        },
        /**
         * Monta os dados para criar a barra de progresso de envios e entregas
         * @vue
         */
        getConfigProgressBar(item, type) {
            let config = {};

            let total = 0;
            let total_enviado = 0;
            let total_nao_enviado = 0;

            if (item.product.CD_PRODUTO_PDES === "SMS-C") {
                total = item.QT_TOTAL_SMS_CAES - (item.invalids_concatenated_count + item.blacklist_concatenated_count + item.repeated_concatenated_count) - item.canceleds_concatenated_count;
                total_enviado = item.delivered_concatenated_count + item.not_delivered_concatenated_count + item.sent_concatenated_count + item.not_sent_concatenated_count;
                total_nao_enviado = item.not_sent_concatenated_count;
            } else if (item.product.CD_PRODUTO_PDES === "RCS") {
                total = item.QT_TOTAL_SMS_CAES - (item.invalids_simple_count + item.blacklist_simple_count + item.repeated_simple_count) - item.canceleds_simple_count;
                total_enviado = item.delivered_rcs_count + item.not_delivered_rcs_count + item.delivered_fallback_count + item.not_delivered_fallback_count + item.sent_rcs_count + item.sent_fallback_count;
                total_nao_enviado = item.not_sent_rcs_count + item.not_sent_fallback_count;
            } else {
                total = item.QT_TOTAL_SMS_CAES - (item.invalids_simple_count + item.blacklist_simple_count + item.repeated_simple_count) - item.canceleds_simple_count;
                total_enviado = item.delivered_simple_count + item.not_delivered_simple_count + item.sent_simple_count;
                total_nao_enviado = item.not_sent_simple_count;
            }

            if (type === "envios") {
                config = {
                    total: total,
                    label: "",
                    list_values: [
                        new ProgressBarValueClass({
                            name: "Enviados",
                            total: total_enviado,
                            color: "primary-blue-500"
                        }),
                        new ProgressBarValueClass({
                            name: "N&atilde;o enviados",
                            total: total_nao_enviado,
                            color: "error-900"
                        }),
                        new ProgressBarValueClass({
                            name: "Aguardando Envio",
                            total: total - total_enviado - total_nao_enviado,
                            color: "gray-300"
                        })
                    ]
                };
            } else if (type === "entrega") {
                let total_entregue = 0;
                let total_nao_entregue = 0;
                let total_fallback = 0;

                switch (item.product.CD_PRODUTO_PDES) {
                case "RCS":
                    total_entregue = item.delivered_rcs_count;
                    total_nao_entregue = item.not_delivered_rcs_count + item.not_delivered_fallback_count;
                    total_fallback = item.delivered_fallback_count;
                    break;
                case "SMS-C":
                    total_entregue = item.delivered_concatenated_count + item.sent_concatenated_count;
                    total_nao_entregue = item.not_delivered_concatenated_count + item.not_sent_concatenated_count;
                    break;
                default:
                    total_entregue = item.delivered_simple_count;
                    total_nao_entregue = item.not_delivered_simple_count;
                    break;
                }

                let list_values = [
                    new ProgressBarValueClass({
                        name: "Entregues " + this.type_product,
                        total: total_entregue,
                        color: "success-900"
                    })
                ];

                if (this.type_product === "RCS" && item.has_fallback) {
                    list_values.push(new ProgressBarValueClass({
                        name: "Entregues SMS alternativo",
                        total: total_fallback,
                        color: "cyan-900"
                    }));
                }

                list_values.push(...[
                    new ProgressBarValueClass({
                        name: "N&atilde;o entregues",
                        total: total_nao_entregue,
                        color: "error-900"
                    }),
                    new ProgressBarValueClass({
                        name: "Aguardando retorno",
                        total: total_enviado - total_entregue - total_fallback - total_nao_entregue,
                        color: "gray-300"
                    })
                ]);

                config = {
                    total: total_enviado,
                    label: "",
                    list_values: list_values
                };
            }

            return config;
        },
        /**
         * Redireciona para a tela de detalhes da campanha
         * @vue
         */
        campaignDetails(campaign_id) {
            this.campaign_id = campaign_id;
            this.show_dialog_details = true; //Open the details dialog 
        },
        /**
         * Metodo que faz a tratativa do objeto loading recebendo o $emit refreshServerSideData do componente dataBaseTable
         */
        dataTable(ev) {
            if (ev.data[0]) {
                this.loading = false;
            } else {
                this.loading = true;
            }
            this.content_table = ev.data; // recebe o conteudo da tabela para enviar ao componente ExportGerenciar
        },
        /**
         * Redireciona o usuario para uma pagina de cadastro de campanha
         * @vue
         */
        actionButton() {
            document.location.href =
                this.base_service.baseDomainPhp() + "/CriarCampanha";
        },
        /**
         * Metodo usado para redirecionar para a tela de radar de campanha
         */
        postRadar(id_campanha, startDate, endDate) {
            if (this.type_product == "RCS") {
                let params = id_campanha
                    ? { id_campanha: id_campanha }
                    : {
                        data_inicio: dayjs(startDate).format("YYYY-MM-DD"),
                        data_fim: dayjs(endDate).format("YYYY-MM-DD")
                    };
                $.doGet("RadarApp", params);
            } else {
                $.doPost("list-radar.php", {
                    idcamp: id_campanha,
                    sh_dtini: dayjs(startDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
                    sh_dtfim: dayjs(endDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                });
            }
        },
        totalSanitized(item) {
            return item.product.CD_PRODUTO_PDES === "SMS-C"
                ? item.invalids_concatenated_count + item.blacklist_concatenated_count + item.repeated_concatenated_count
                : item.invalids_simple_count + item.blacklist_simple_count + item.repeated_simple_count;
        },
        totalCanceleds(item) {
            return item.product.CD_PRODUTO_PDES === "SMS-C" ? item.canceleds_concatenated_count : item.canceleds_simple_count;
        }
    },
    template:
        /*html*/
        ` 
        <v-card elevation="0">
            <v-card-text>
                <v-row class="px-0 px-md-6" no-gutters>
                    <v-col cols="12">
                        <page-header
                            :tutorial="true"
                            title="Gerenciar"
                            description="Esse &eacute; o hist&oacute;rico de suas campanhas."
                            @tutorial="$refs.tour.start()"
                        >
                            <template #button>
                                <base-button
                                    primary
                                    icon="mdi-plus"
                                    label="Nova campanha"
                                    @click="actionButton"
                                />
                            </template>
                        </page-header>
                    </v-col>
                </v-row>
                <v-row class="px-0 px-md-6" no-gutters>
                    <v-col cols="12" class="filtro-content pb-10">
                        <form-filter-gerenciar @formSubmit="filterSubmit"/>
                    </v-col>
                </v-row>
                <v-row class="px-0 px-md-6" no-gutters>
                    <export-gerenciar
                        :loading="loading"
                        :data-export="data_export"
                        :content-table="content_table"
                    />
                </v-row>
                <v-row class="px-0 px-md-6" no-gutters>
                    <v-col cols="12">
                        <base-data-table
                            :headers="tableHeaders"
                            :custom-slots="custom_slots"
                            :server-side-config="server_side_config"
                            item-key="id_campanha"
                            sort-by="DT_FIM_CAES"
                            show-page-length
                            :step-tutorial-custom-columns="3"
                            :step-tutorial-page-length="4"
                            :loading="table_loading"
                            @refreshServerSideData="dataTable"
                        >
                            <template #cd_produto="props">
                                <canal-icon-description :product-name="props.item.product.CD_PRODUTO_PDES" />
                            </template>
                            <template #NM_EMPRESA_EPES="props">
                                <span class="body-2 text-gray-900">{{ props.item.client.NM_EMPRESA_EPES }}</span>
                            </template>
                            <template #status_campanha="props">
                                <status-campaign :status="props.item.ST_CAMPANHA_CAES" />
                            </template>
                            <template #DT_INICIO_CAES="props">
                                <span>{{ props.item.DT_INICIO_CAES | format_date }}</span>
                            </template>
                            <template #DT_REINICIO_CAES="props">
                                <span>{{ props.item.DT_REINICIO_CAES | format_date }}</span>
                            </template>
                            <template #DT_FIM_CAES="props">
                                <span>{{ props.item.DT_FIM_CAES | format_date }}</span>
                            </template>
                            <template #bloqueados="props">
                                <span>{{ totalSanitized(props.item) }}</span>
                            </template>
                            <template #cancelados="props">
                                <span>{{ totalCanceleds(props.item) }}</span>
                            </template>
                            <template #radar="props">
                                <div v-if="type_product === 'RCS'">
                                    <span v-if="props.item.responses_rcs_count == 0">{{ props.item.responses_rcs_count }}</span>
                                    <a v-else href="javascript:void(0)" @click="postRadar(props.item.ID_CAMPANHA_CAES, props.item.DT_INICIO_CAES, props.item.DT_FIM_CAES)">{{ props.item.responses_rcs_count }}</a>
                                </div>
                                <div v-else>
                                    <span v-if="props.item.responses_sms_count == 0">{{ props.item.responses_sms_count }}</span>
                                    <a v-else href="javascript:void(0)" @click="postRadar(props.item.ID_CAMPANHA_CAES, props.item.DT_INICIO_CAES, props.item.DT_FIM_CAES)">{{ props.item.responses_sms_count }}</a>
                                </div>
                            </template>
                            <template #has_fallback="props">
                                <v-icon v-if="props.item.has_fallback" :size="20" color="gray-700">
                                    mdi-check-bold
                                </v-icon>
                                <span v-else class="body-2 text-gray-900">-</span>
                            </template>
                            <template #total_enviado="props">
                                <base-progress-bar
                                    :total="getConfigProgressBar(props.item, 'envios').total"
                                    :label="getConfigProgressBar(props.item, 'envios').label"
                                    :list-values="getConfigProgressBar(props.item, 'envios').list_values"
                                    show-tooltip-title
                                />
                            </template>
                            <template #total_entregue="props">
                                <base-progress-bar
                                    :total="getConfigProgressBar(props.item, 'entrega').total"
                                    :label="getConfigProgressBar(props.item, 'entrega').label"
                                    :list-values="getConfigProgressBar(props.item, 'entrega').list_values"
                                    show-tooltip-title
                                />
                            </template>
                            <template #actions="props">
                                <v-row class="my-2">
                                    <v-col cols="4">
                                        <button-action
                                            type="open"
                                            @click="campaignDetails(props.item.ID_CAMPANHA_CAES)"
                                        />
                                    </v-col>
                                </v-row>
                            </template>
                        </base-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <pg-tour ref="tour" :steps="steps" />
            <dialog-campaign-details
                v-model="show_dialog_details"
                :campaign-id="campaign_id"
            />
        </v-card>
        `
};
