/**
 * Componente basico de card de slider
 * 
 * @displayName BaseCardSlider
 * @component
 * @vue
 * @category Atomos
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    props: {
        /**
         * Imagem a ser exibida no card
         */
        image: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Titulo do texto a ser exibido no card
         */
        title: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Texto a ser exibido no card
         */
        text: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Indica que o conteudo do componente deve ser exibido na vertical
         */
        vertical: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    template: (
        /*html*/
        `
            <v-card elevation="7" class="bg-gray-30 pa-8 rounded-4 border-0" min-height="214" min-width="214">
                <v-row no-gutters>
                    <v-col :cols="vertical ? 12 : 6" :class="'d-flex justify-center ' + (vertical ? '' : 'pr-8')">
                        <v-img contain :src="image" width="150" height="150"></v-img>
                    </v-col>
                    <v-col :cols="vertical ? 12 : 6">
                        <v-row no-gutters>
                            <v-col cols="12" :class="'text-start ' + (vertical ? 'mt-8' : 'mt-4')">
                                <span class="subtitle-3 text-gray-900" v-html="title"></span>
                            </v-col>
                            <v-col cols="12" class="text-start mt-4">
                                <span class="body-2 text-gray-900" v-html="text"></span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};