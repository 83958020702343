import GroupPills from "../../../components/moleculas/GroupPills";
import { BasePillClass } from "../../../classes/BasePillClass";

export default {
    components: {
        GroupPills
    },
    data() {
        return {
            list_chips: [
                new BasePillClass({
                    prependIcon: "fa-grip-vertical",
                    title: "Quero Saber mais",
                    draggable: true
                }),
                new BasePillClass({
                    prependIcon: "fa-grip-vertical",
                    title: "Sim",
                    draggable: true
                }),
                new BasePillClass({
                    prependIcon: "fa-grip-vertical",
                    title: "Quero receber novidades",
                    draggable: true
                }),
                new BasePillClass({
                    prependIcon: "fa-grip-vertical",
                    title: "N&atilde;o",
                    draggable: true
                }),
            ],
            group_name: "grupo1",
            height: "100px"
        };
    },
    template: (
        /*html*/
        `
        <v-row class="mt-16" justify="center">
            <v-col cols="3">
                <v-row class="mt-10">
                    <v-col class="border">
                        <group-pills
                            :list-pills-default="list_chips"
                            :group-name="group_name"
                            :height="height"
                        ></group-pills>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>    
        `
    )
};