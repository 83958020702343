import DialogFileConfiguration from "../../campaign/components/dialogs/DialogFileConfiguration";

export default {
    components: {
        DialogFileConfiguration
    },
    data() {
        return {
            show_dialog: true,
            config_data: {
                file_name: "arquivo_carousel.txt",
                file_content: [
                    {
                        coluna0: "41997463830",
                        coluna1: "Campo Informado",
                        coluna2: "TALL",
                        coluna3: "Uma descrição qualquer",
                        coluna4: "Nome",
                        coluna5: "Algum título aqui",
                        coluna6: " https://fisioanimal.com/blog/wp-content/uploads/2019/06/Filhote-Cachorro-900x628.jpg",
                        row_id: 0
                    },
                    {
                        coluna0: "41997834342",
                        coluna1: "Campo Informado",
                        coluna2: "TALL",
                        coluna3: "Uma descrição qualquer",
                        coluna4: "Nome",
                        coluna5: "Algum título aqui",
                        coluna6: " https://fisioanimal.com/blog/wp-content/uploads/2019/06/Filhote-Cachorro-900x628.jpg",
                        row_id: 1
                    },
                    {
                        coluna0: "",
                        coluna1: "Campo Informado",
                        coluna2: "TALL",
                        coluna3: "Uma descrição qualquer",
                        coluna4: "Nome",
                        coluna5: "Algum título aqui",
                        coluna6: " https://gmconline.com.br/wp-content/uploads/2020/06/e6234d30f882543f3fdaadc769d4affb.jpg",
                        row_id: 2
                    },
                    {
                        coluna0: "41997463830",
                        coluna1: "Campo Informado",
                        coluna2: "TALL",
                        coluna3: "Uma descrição qualquer",
                        coluna4: "Nome",
                        coluna5: "Algum título aqui",
                        coluna6: " https://gmconline.com.br/wp-content/uploads/2020/06/e6234d30f882543f3fdaadc769d4affb.jpg",
                        row_id: 3
                    },
                    {
                        coluna0: "",
                        coluna1: "Campo Informado",
                        coluna2: "TALL",
                        coluna3: "Uma descrição qualquer",
                        coluna4: "Nome",
                        coluna5: "Algum título aqui",
                        coluna6: " https://gmconline.com.br/wp-content/uploads/2020/06/e6234d30f882543f3fdaadc769d4affb.jpg",
                        row_id: 4
                    }
                ],
                /* file_configuration: {
                    client_data: null,
                    nome: "coluna4",
                    phone: "coluna0",
                    time: "coluna5"
                } */
            },
            template: {
                "ID_TEMPLATE_APPS_TAES": 69,
                "ID_TEMPLATE_APPS_PAI_TAES": 0,
                "NR_ORDEM_TAES": 0,
                "ID_EMPRESA_TAES": 11051,
                "ID_PRODUTO_TAES": 134,
                "TP_TEMPLATE_TAES": "R",
                "CD_TEMPLATE_TAES": "teste_rcs_carousel",
                "DS_TEMPLATE_TAES": "{\"cardWidth\": \"MEDIUM\", \"contents\":[{\"media\":{\"file\":{\"url\":\"{url}\"},\"height\":\"{tamanho}\"},\"description\":\"{descricao}\",\"title\":\"Ola, {nome} . {titulo}\"},{\"media\":{\"file\":{\"url\":\"{url}\"},\"height\":\"{tamanho}\"},\"description\": \"{descricao}\",\"title\":\"{titulo}\"}]}",
                "DS_PARAMETRO_TAES": "{\"url\": \"str\", \"nome\": \"str\", \"titulo\": \"str\", \"tamanho\": \"str\", \"descricao\": \"str\"}",
                "ST_TEMPLATE_TAES": "A",
                "DS_HASH_TAES": "hash1",
                "ID_USUARIO_CADASTRO_TAES": null,
                "DT_CADASTRO_TAES": null,
                "ID_USUARIO_UPDATE_TAES": null,
                "DT_UPDATE_TAES": null,
                "fallback": []
            }
        };
    },
    methods: {
        save(data) {
            console.log(data);
        }
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="12" md="8" lg="6">
                <v-card class="d-flex justify-center">
                    <v-btn @click="show_dialog = true" color="dark-primary white--text" class="my-10">Abrir modal</v-btn>
                </v-card>
            </v-col>
            <dialog-file-configuration v-model="show_dialog" :template="template" :config-data="config_data" :client-id="11051" @saveConfig="save"/>
        </v-row>
        `
    )
};