import BaseTextField from "../../../components/atomos/BaseTextField";

export default {
    components: {BaseTextField },
    data: function () {
        return {
            email: "",
            email_rules: [
                v => !!v || "Digite um email",
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || window.Vue.htmlEntities("Digite um email v&aacute;lido")
            ],
        };
    },
    props: {
        /**
         * Mensagem de erro a ser exibida no formulario
         */
        error_message: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Indica se o botao de submit deve estar desabilitado ou nao
         */
        disable_button: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        /**
         * Faz a validacao dos campos do formulario e emite um evento submitLoginButton com os dados email e senha
         * @category Auth
         * @vue
         */
        formSubmit() {
            if (this.$refs.form_login.validate()) {
                let formData = { email: this.email, senha: this.senha };

                /**
                 * Evento de submit do formulario
                 * 
                 * @property {object} formData Dados do login {email, senha}
                 * 
                 * @event submitLoginButton
                 * @vue
                 */
                this.$emit("submitLoginButton", formData);
            }
        }
    },
    template: (
        /*html*/
        `
        <v-col cols="12">
            <v-row justify="center">
                <v-col cols="2">
                    <v-row  no-gutters>
                        <v-col>
                            <base-text-field 
                                v-model="email" 
                                :value="email"
                                :rules="email_rules"
                                label="Email*"
                                :required="true"
                                title="Email"
                                :use-icon-validation="true"
                                @enterPressed="formSubmit">
                            </base-text-field>
                        </v-col>
                    </v-row>
                </v-col> 
            </v-row>
        </v-col>
        `
    )
};