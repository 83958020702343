export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Editar-Enabled" transform="translate(4.983 -41)">
                    <rect id="Retângulo_342" data-name="Retângulo 342" width="28" height="28" transform="translate(-4.983 41)" fill="currentColor" opacity="0"/>
                    <path id="Caminho_466" data-name="Caminho 466" d="M16.8,2.2,15.3.7a2.605,2.605,0,0,0-3.5,0h0L1.6,10.9c-.1.1-.1.2-.2.3h0L0,16.5a.618.618,0,0,0,.2.7.764.764,0,0,0,.5.2H.9L6.2,16h.1c.1,0,.2-.1.3-.1L16.8,5.7A2.342,2.342,0,0,0,17.5,4,3.115,3.115,0,0,0,16.8,2.2ZM6,14.3,3.1,11.4l8-8L14,6.3ZM2.5,12.9,4.6,15l-2.8.7ZM15.7,4.6l-.6.6L12.2,2.3l.6-.6a.967.967,0,0,1,1.4,0l1.5,1.5a1.08,1.08,0,0,1,.3.7A1.08,1.08,0,0,1,15.7,4.6Z" transform="translate(0.039 45.975)" fill="currentColor"/>
                </g>
            </svg>
        `
    )
};