import { TemplateService } from "../../../../js/services/TemplateService.js";
/**
 *
 * @type {{dial_phone: string, request_location: string, text: string, url: string, show_location: string}}
 */
const type_icons = {
    text: "fa-align-left",
    url: "fa-globe-americas",
    show_location: "fa-crosshairs",
    request_location: "fa-map-marker-alt",
    dial_phone: "fa-phone"
};

let template_service = new TemplateService();

/**
 * Componente de Sugestion Preview
 * 
 * @displayName Sugestion Preview
 * @component
 * @category Moleculas
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @vue
 */
export const SugestionPreview = {
    name: "sugestion-preview",
    props: {
        //sugestion title
        label: {
            type: String,
            required: true
        },
        //valor da sugestion
        value: {
            type: String,
            required: true
        },
        //tipo da sugestion no bal�o
        type: {
            type: [
                "text",
                "url",
                "dial_phone",
                "show_location",
                "request_location"
            ],
            //'text'
            default: "text"
        },
        //Se o bal�o deve ou n�o ser edit�vel
        editable: {
            type: Boolean,
            //false
            default: false
        },
        //Se a pill deve estar ativa ou n�o
        active: {
            type: Boolean,
            //false
            default: false
        },
        //Se a pill deve ou n�o usar as variaveis em highlight
        //OBS: s� ser� considerada se editable === false
        highlight: {
            type: Boolean,
            //true
            default: true
        },
        //Se a pill deve ou n�o usar bordas
        bordered: {
            type: Boolean,
            //true
            default: true
        }
    },
    computed: {
        label_name: function() {
            let label_name = this.label
                .replace(new RegExp("\\[(.*?)]", "gm"), "$1")
                .replace(new RegExp("\\{(.*?)}", "gm"), "$1");
            return label_name;
        },
        is_variable: function() {
            return template_service.hasParams(this.label);
        },
        has_icon() {
            return true;
        },
        formatted_value() {
            if (this.has_value && this.type.toLowerCase() === "show_location") {
                return `LT: ${this.value.latitude}, LG: ${this.value.longitude}`;
            }
            return this.value;
        },
        has_value() {
            return (
                ["text", "request_location"].indexOf(
                    this.type.toLowerCase()
                ) === -1
            );
        },
        suggestion_icon() {
            return type_icons[this.type.toLowerCase()];
        },
        random_key() {
            return makeRandomId(10);
        }
    },
    template:
        /*html*/
        `            
                   <div class="d-inline-flex flex-column justify-content-center rounded  px-2 border-light-grey " 
                        style="max-width: 250px; height:52px"
                        :style="{'min-width': has_value ? 'null' : '96px','width': has_value ? '255px' : 'null'}"
                        :class="[{'border': bordered,
                                 'rounded': editable && is_variable,
                                 'bg-dark-primary text-white' : active,
                                 'bg-white' : !active},
                                 has_icon ? 'align-items-start' : 'align-items-center']">
                             <div :key="random_key" class="d-inline-flex text-truncate col-12 align-items-center">
                                 <i  class="fas mr-2" 
                                 v-if="has_icon && !(editable && is_variable)" 
                                 :class="[active ? 'text-white': 'text-dark-primary', suggestion_icon]"></i>
                                 <div class="text-truncate text-left">
                                     <span class="" :title="label_name" :class="highlight && is_variable ? 'font-weight-bold' : ''" v-html="label"></span>
                                     <div class="w-100"></div>
                                     <span v-if="has_value" :class="active ? 'text-white' : 'text-dark-grey'" style="font-size:10px; opacity: 0.5" v-html="formatted_value"></span>
                                </div>
                            </div>
                    </div>       
                </div>
            </div>  
        `
};
