//import {MockupingService} from "./MockupingService";

export class EscalonarInfo{
    dt_inicio;
    dt_fim;
    quantidade;
    quantidade_maxima;

    /**
     *
     * @param quantidade {number}
     * @param dt_inicio {string}
     * @param dt_fim {string}
     * @param quantidade_maxima {number}
     */
    constructor(quantidade = null, dt_inicio = null, dt_fim = null, quantidade_maxima = null) {
        this.dt_fim     = dt_fim;
        this.dt_inicio  = dt_inicio;
        this.quantidade = quantidade;
        this.quantidade_maxima = quantidade_maxima;
    }
}

export class CampanhaService {

    _base_url = '/Campanha';
    _base_url_criar = '/CriarCampanha';


    /**
     * Lista de campanhas criadas
     * @returns {Promise}
     */
    getCampanhasDashboard(){
        return $.post(this._base_url + '/getCampanhasDashboard');
    }


    /**
     * Busca a data da ultima campanha da empresa logada
     * @returns {Promise}
     */
    getLastDtInclusao() {
        return $.post(this._base_url + '/getLastDtInclusao');
    }
     /**
     * Busca todas as campanhas de um mes da empresa
     * @returns {Promise}
     */
    getSelectCampanha(dataInicio,dataFim,id = null,id_campanha=null) {
        return $.post(this._base_url + '/getCampanhas',{dataInicio,dataFim,id,id_campanha});
    }

    /**
     * Retorna count de todos os retornos
     * @returns {Promise}
     */
    getCountRetornos(dataInicio,dataFim,id_campanha=null,id_empresa = null,telefone = null,situacao = null,campo_informado = null,canais = null) {
        return $.post(this._base_url + '/getCountRetorno',{dataInicio,dataFim,id_campanha,id_empresa,telefone,situacao,campo_informado,canais});
    }
     /**
     * Retorna count de todos os envios
     * @returns {Promise}
     */
    getCountEnvios(dataInicio,dataFim, id_campanha = null,id_empresa = null,telefone = null,situacao = null,campo_informado = null,canais = null) {
        return $.post(this._base_url + '/getCountEnvios',{dataInicio,dataFim,id_campanha,id_empresa,telefone,situacao,campo_informado,canais});
    }


    /**
     * Retorna situa��es
     * @returns {Promise}
     */
    getStatusOptions() {
        return $.post(this._base_url + '/getStatus');
    }


    getStatusMongoOptions(){
        return $.post(this._base_url + '/getStatusMongo');
    }


    /**
     * Busca o relat�rio de envio no formato Excel
     * @returns {Promise}
     */
    exportarExcel(filtro) {
        return $.post(this._base_url + '/getReport', filtro);
    }


    /**
    * Busca o relat�rio de envio no formato Txt
    * @returns {Promise}
    */
    exportarTexto(filtro) {
        return $.post(this._base_url + '/getReport', filtro);
    }

     /**
     * Retorna os dados da campanha informada
     * @param id_campanha {int}
     * @returns {Promise}
     */
    getDadosCampanha(id_campanha) {
        return $.post(this._base_url + '/getInfoCampanha',{id_campanha});
    }
    validarNumeros(telefones, bloqueados) {
        return $.post(this._base_url_criar + '/validarNumeros', {telefones, bloqueados});
    }


    /**
     * Realiza o envio de RCS
     * @param envio_parametros
     * @returns {*}
     */
    enviarRCS(envio_parametros){
        // start mockuping
        // $.mockuping = MockupingService.mockuping({return: {id: 7340761}});
        // end
        return  $.post(this._base_url_criar + '/criarCampanhaRcs', envio_parametros);
    }

    /**
     * Realiza o cadastro das mensagens da campanha.
     * @param mensagens {any[]} - array de valores e parametros da mensagem
     * @param id_campanha {number|string} - id da campanha
     *
     * @returns {*}
     */
    addMessagesCampanhaRcs(mensagens, id_campanha){
        // start mockuping
        // $.post = MockupingService.mockuping({id: 970952});
        // end
        return  $.post(this._base_url_criar + '/addMessagesCampanhaRcs', {mensagens: mensagens, id_campanha});
    }

    /**
     * Executa a ultima etapa de cria��o da campanha que � adicionar os lotes
     * @param lotes {EscalonarInfo[]} - com as informa��es de escolnamento (mesmo campanhas com inicio imediato ou agendamento simples devem enviar um array)
     * @param id_campanha {number|string} - id da campanha
     * @returns {*}
     */
    finishCampanhaRcs(lotes, id_campanha){
        // start mockuping
        // $.post = MockupingService.mockuping({id: 970952});
        // end
        return  $.post(this._base_url_criar + '/finishCampanhaRcs', {lotes: lotes, id_campanha});
    }


    /**
     * Realiza o envio de Whatsapp
     * @param envio_parametros
     * @returns {*}
     */
    enviarWhatsapp(envio_parametros){
        // start mockuping
        //$.post = MockupingService.mockuping({id: 970952});
        // end
        return  $.post(this._base_url_criar + '/enviarWhatsapp', envio_parametros);
    }
    
    /**
    * Executa a a��o de pausar das campanhas
    * @returns {Promise}
    */
    campanhasPause(campanhas) {
        return $.post(this._base_url + '/campanhasPause', {campanhas});
    }

  
     /**
     * Retorna os dados da campanha informada
     * @param situacao {string}
     * @param extra_data {Number}
     * @returns {string}
     */
    getTraducaoSituacaoCampanha(situacao, extra_data = null) {
        let situacao_traducao = '';
        switch (situacao) {
            case 'T':
                situacao_traducao = 'Tempor&aacute;rio';
                break;
            case 'G':
                situacao_traducao = 'Processando '+ extra_data +'%';
                break;
            case 'C':
                situacao_traducao = 'Cancelada';
                break;
            case 'A':
                situacao_traducao = 'A Enviar';
                break;
            case 'E':
                situacao_traducao = 'Enviando';
                break;  
            case 'F':
                situacao_traducao = 'Finalizada';
                break;   
            case 'P':
                situacao_traducao = 'Pausa';
                break;                           
        }

        return situacao_traducao;
    }

    /**
     * Executa a a��o de reiniciar das campanhas
     * @returns {Promise}
     */
    campanhasReiniciar(campanhas) {
        return $.post(this._base_url + '/campanhasReiniciar', {campanhas});
    }

    /**
     * Retorna os lotes da campanha informada
     * @param id_campanha {int}
     * @returns {Promise}
     */
    getLote(id_campanha) {

        return $.post(this._base_url + '/getInfoLote',{id_campanha});
    }
    
    /**
    * Executa a a��o de pausar das campanhas
    * @returns {Promise}
    */
    campanhasPause(campanhas) {
        return $.post(this._base_url + '/campanhasPause', {campanhas});
    }

    /**
    * Executa a a��o de reiniciar das campanhas
    * @returns {Promise}
    */
    campanhasReiniciar(campanhas) {
        return $.post(this._base_url + '/campanhasReiniciar', {campanhas});
    }
}