export default {
    name: "exclamation-triangle-icon",
    template: (
        /*html*/
        `
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2600.000000 2600.000000" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,2600.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
            <path d="M13854 22178 c-291 -19 -576 -117 -829 -286 -116 -78 -323 -277 -407 -392 -30 -41 -102 -156 -160 -255 -378 -647 -8367 -14492 -8399 -14555 -69 -137 -113 -267 -151 -440 -19 -89 -22 -136 -23 -310 0 -176 3 -221 23 -319 109 -537 451 -986 927 -1216 195 -94 382 -145 610 -165 84 -8 2667 -10 8625 -7 7708 3 8513 5 8585 19 328 64 577 178 810 368 416 340 661 911 615 1436 -19 220 -70 412 -159 599 -54 112 -8454 14667 -8533 14785 -340 504 -911 779 -1534 738z m251 -1133 c137 -29 264 -113 342 -226 46 -67 8421 -14573 8459 -14652 84 -175 64 -389 -52 -560 -81 -118 -207 -204 -341 -232 -59 -13 -1225 -15 -8529 -15 -5994 0 -8478 3 -8520 11 -204 37 -395 224 -444 436 -15 66 -15 193 0 258 6 28 26 79 44 115 41 80 8383 14531 8438 14617 130 203 363 298 603 248z"/>
            <path d="M13740 18954 c-479 -75 -898 -343 -1154 -737 -135 -207 -216 -454 -216 -658 0 -71 632 -6920 651 -7049 98 -689 548 -1243 989 -1218 169 10 322 88 481 247 219 220 381 554 444 921 8 47 159 1640 335 3540 357 3835 341 3620 287 3820 -68 252 -192 458 -401 665 -138 137 -263 226 -431 309 -140 70 -265 113 -421 146 -133 29 -427 36 -564 14z"/>
            <path d="M13820 8095 c-225 -37 -408 -128 -574 -285 -537 -508 -404 -1393 259 -1725 414 -207 904 -128 1231 199 136 136 231 303 286 503 18 66 21 105 22 248 0 153 -3 180 -27 270 -107 398 -421 696 -822 781 -102 22 -275 26 -375 9z"/>
          </g>
        </svg>
        `
    )
};
