import DialogNotifyReportGeneration from "../../reports/components/dialogs/DialogNotifyReportGeneration";

export default {
    components: {
        DialogNotifyReportGeneration
    },
    data() {
        return {
            show: false,
            dados_notificacao: null
        };
    },
    watch: {
        dados_notificacao() {
            console.log(this.dados_notificacao);
        }
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="12" md="8" lg="6">
                <v-card class="d-flex justify-center">
                    <v-btn @click="show = true" color="dark-primary white--text" class="my-10">Abrir modal</v-btn>
                </v-card>
            </v-col>
            <dialog-notify-report-generation v-model="dados_notificacao" :dialog="show" @close="show = false"></dialog-notify-report-generation>
        </v-row>
        `
    )
};