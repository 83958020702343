import * as types from "./mutations-types";

export default {
    [types.SET_LIST_FILES](state, list_files) {
        state.list_files = list_files;
    },
    [types.CHANGE_LIST_FILES_ITEM](state, file) {
        let file_index = state.list_files.findIndex((x) => x.id === file.id);
        window.Vue.set(state.list_files, file_index, file);
    },
    [types.REMOVE_FILE_FROM_LIST](state, file_index) {
        state.list_files.splice(file_index, 1);
    },
    [types.CLEAR_ALL_CONFIG_FILES](state) {
        for (let file of state.list_files) {
            file.file_configuration = {};
            file.status = "configuration";
            file.style = {
                icon: "mdi-cog",
                icon_color: "green-500",
                background: "warning-200",
                border: "gray-300"
            };
        }
    },
    [types.SET_FILE_CONFIGURATION](state, data) {
        let file_index = state.list_files.findIndex((x) => x.id === data.file_id);
        let new_file = state.list_files[file_index];

        new_file.status = "success";
        new_file.style = {
            icon: "mdi-check-bold",
            background: "gray-0",
            border: "success-500"
        };
        new_file.file_configuration = data.file_configuration;

        window.Vue.set(state.list_files, file_index, new_file);
    }
};