import RespostasRapidas from "../../template-rcs/components/RespostasRapidas?v=2";

export default {
    components: {
        RespostasRapidas
    },
    methods: {
        formSubmit() {
            console.log("adsokao");
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="12" md="3">
                    <respostas-rapidas 
                        @formSubmit="formSubmit">
                    </respostas-rapidas>
                </v-col>
            </v-row>
        `
    )
};