import Vue from "vue";
window.Vue = Vue;
import App from "./App.js";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify/vuetify";

import "./plugins/vue-tour";
import "./plugins/vuedraggable";
import "./plugins/vue-cookie";
import "./plugins/v-mask";
import "./plugins/vue-recaptcha-v3";
import "./plugins/pg-custom-plugin";
import "./sass/breakpoints.scss";

if (process.env.NODE_ENV !== "production") {
    Vue.config.productionTip = false;
    Vue.config.devtools = true;
}

dayjs.extend(window.dayjs_plugin_customParseFormat);

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount("#app");