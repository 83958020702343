import BaseButton from "../../../components/atomos/BaseButton?v=2";
import BaseTextField from "../../../components/atomos/BaseTextField";

/**
 * Componente de formulario de login {@link Action}
 * 
 * @component
 * @category Page / Auth
 * @subcategory Forms
 * @requires {@link base-button.html|BaseButton}
 * @requires {@link base-text-field.html|BaseTextField}
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 */
export default {
    name: "form-recovery-password",
    components: { BaseButton, BaseTextField },
    data: function () {
        return {
            form_valid: false,
            email: "",
            email_rules: [
                v => !!v || "Digite um email",
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || window.Vue.htmlEntities("Digite um email v&aacute;lido")
            ],
            disable_button: false,
            disable_input: false,
        };
    },
    props: {
        /**
         * Mensagem de erro a ser exibida no formulario
         */
        error_message: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Mensagem de sucesso a ser exibida no formulario
         */
        success_message: {
            type: String,
            required: false,
            default: ""
        }
    },
    methods: {
        /**
         * Faz a validacao dos campos do formulario e emite um evento recoveryPassButton com o email
         * @vue
         */
        submitRecoveryPass() {
            if (this.$refs.form_lost_pass.validate()) {
                this.disable_button = true;
                this.disable_input = true;
                /**
                 * Evento de submit do formulario
                 * 
                 * @property {string} email Email do usuario
                 * 
                 * @event recoveryPassButton
                 * @vue
                 */
                this.$emit("recoveryPassButton", this.email);
            }
        },
        /**
         * Emite um evento informando que o link de voltar foi clicado
         * @vue
         */
        backLoginLink() {
            /**
             * Evento de click no link de "Voltar"
             * 
             * @event backLogin
             * @vue
             */
            this.$emit("backLogin");
        },
    },
    mounted() {
        let email = this.$route.query.email;

        if (typeof email !== "undefined" && email !== "") {
            this.email = email;
        }
    },
    template: (
        /*html*/
        `
            <v-form ref="form_lost_pass" lazy-validation v-model="form_valid">
                <v-row no-gutters>
                    <v-col>
                        <h5 class="font-weight-bold dark-primary--text">Esqueceu sua senha?</h5>
                        <p class="font-075rem">Informe seu e-mail para recupera-la.</p>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="error_message != ''">
                    <v-col>
                        <p>
                            <v-icon x-small color="danger">fa-exclamation-circle</v-icon>
                            <span class="danger--text" v-html="error_message"></span>
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="success_message != ''">
                    <v-col>
                        <p>
                            <v-icon x-small color="success">fa-check-circle</v-icon>
                            <span v-html="success_message"></span>
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <base-text-field 
                            v-model="email" 
                            :rules="email_rules" 
                            label="Email"
                            :required="true"
                            @enterPressed="submitRecoveryPass"
                            :disabled="disable_input"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters justify="end" class=" mb-3">
                    <v-col cols="auto">
                        <a class="font-075rem text-decoration-none dark-primary--text" @click="backLoginLink">Voltar</a>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <base-button 
                            primary
                            icon='fa-check'
                            label='Enviar'
                            :disabled="disable_button"
                            @click="submitRecoveryPass"
                        /> 
                    </v-col>
                </v-row>
            </v-form>
        `
    )
};