import BaseButtonCheckbox from "../atomos/BaseButtonCheckbox?v=2";
import ButtonCheckboxClass from "../../classes/ButtonCheckboxClass";

/**
 * Componente de Group Button Checkbox
 *
 * @displayName GroupButtonCheckbox
 * @component
 * @category Moleculas
 * @requires {@link BaseButtonCheckbox}
 * @requires {@link ButtonCheckboxClass}
 * @vue
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseButtonCheckbox
    },
    props: {
        /**
         * Lista de objetos do tipo {@link ButtonCheckboxClass}
         */
        value: {
            type: Array,
            required: true,
            validator: function (data) {
                let is_valid = true;

                for (const element of data) {
                    if (!(element instanceof ButtonCheckboxClass)) {
                        is_valid = false;
                        break;
                    }
                }

                return is_valid;
            }
        }
    },
    data() {
        return {
            list_buttons: this.value,
        };
    },
    watch: {
        value() {
            this.list_buttons = this.value;
        },
        list_buttons: {
            handler(list) {
                /**
                 * Evento de input, altera o valor de v-model sempre que a lista for atualizada
                 * 
                 * @property {Array} list
                 * 
                 * @event input
                 * @vue
                 */
                this.$emit("input", list);
            },
            deep: true
        }
    },
    template: (
        /*html*/
        `
        <v-row no-gutters>
            <v-col class="mr-6 mb-6" cols="auto" v-for="(item, key) in list_buttons">
                <base-button-checkbox
                    v-model="item.checked"
                    :label="item.label"
                    :icon="item.icon"
                    :background-color="item.background_color"
                    :background-color-hover="item.background_color_hover"
                    :background-color-checked="item.background_color_checked"
                    :content-color="item.content_color"
                    :content-color-hover="item.content_color_hover"
                    :content-color-checked="item.content_color_checked"
                    :tooltip-text="item.tooltipText"
                    :disabled="item.disabled"
                ></base-button-checkbox>
            </v-col>
        </v-row>
        `
    )
};