import CellPhonePreview from "../../../components/atomos/CellPhonePreview";
import ConfigContent from "./ConfigContent";
import ContentEdit from "./ContentEdit";
import ConfigMessageTemplate from "./ConfigMessageTemplate";
import PreviewText from "../../../components/atomos/PreviewText";
import ConfigRespostasRapidas from "./ConfigRespostasRapidas";
import PreviewSuggestions from "../../../components/atomos/PreviewSuggestions";
import PreviewImage from "../../../components/atomos/PreviewImage";
import InsertBlocks from "./InsertBlocks";
import ConfigBar from "../../../components/moleculas/ConfigBar";
import TemplateUtils from "../../../utils/TemplateUtils";

/**
 * Componente para configurar templates
 * 
 * @requires {@link CellPhonePreview}
 * @requires {@link ConfigContent}
 * @requires {@link ContentEdit}
 * @requires {@link ConfigMessageTemplate}
 * @requires {@link PreviewText}
 * @requires {@link ConfigRespostasRapidas}
 * @requires {@link PreviewSuggestions}
 * @requires {@link PreviewImage}
 * @requires {@link InsertBlocks}
 * @requires {@link ConfigBar}
 * 
 * @displayName ConfigPreview
 * @category Page / Template RCS
 * @author David dos Santos Andrade <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        CellPhonePreview,
        ConfigContent,
        ContentEdit,
        ConfigMessageTemplate,
        PreviewText,
        ConfigRespostasRapidas,
        PreviewSuggestions,
        PreviewImage,
        InsertBlocks,
        ConfigBar
    },
    props: {
        /**
         * Tipo de template
         */
        type: {
            type: String,
            required: true,
            validator: (data) => {
                return ["text", "image", "card"].indexOf(data) > -1;
            }
        },
        /**
         * Habilita respostas rápidas para o tipo Texto ou Card
         */
        fastResponses: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Habilita botões para o tipo Texto ou Card
         */
        buttons: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Habilita imagem para o tipo Card
         */
        image: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Habilita o imagem variável para o tipo Imagem ou Card com Imagem
         */
        imageVariable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Habilita o texto para o tipo Card
         */
        text: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Conteúdo configurado manipulado por v-model
         */
        value: {
            type: Object,
            required: false,
            default: null
        },
        /**
         * Define uma sessão como selecionada
         */
        sectionSelected: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Define o máximo de caracteres da mensagem
         */
        maxCharacters: {
            type: Number,
            required: false,
            default: 1000
        }
    },
    data() {
        return {
            config_content_selected: {
                text: false,
                suggestions: false,
                image: false
            },
            insert_blocks: {
                text: {
                    show: false,
                    positions: new DOMRect()
                },
                suggestions: {
                    show: false,
                    positions: new DOMRect()
                },
                image: {
                    show: false,
                    positions: new DOMRect()
                }
            },
            show_blocks: {
                text: true,
                suggestions: true,
                image: true
            },
            first_options_config_bar: { VERTICAL: { label: "Vertical", icon: "$cardVerticalLargeIcon" }, HORIZONTAL: { label: "Horizontal", icon: "$cardHorizontalLeftIcon" } },
            config_bar: { first_value: "VERTICAL", second_value: "TALL" },
            content: this.value,
            template_utils: new TemplateUtils()
        };
    },
    computed: {
        suggestionsConfig() {
            if (this.fastResponses && this.buttons) {
                return {
                    icon: "$completoIcon",
                    text: "Adicione resposta r&aacute;pidas e bot&otilde;es",
                    active_text: "As respostas e bot&otilde;es ir&atilde;o aparecer aqui"
                };
            } else if (this.fastResponses) {
                return {
                    icon: "$respostasRapidasIcon",
                    text: "Adicione respostas r&aacute;pidas",
                    active_text: "As respostas r&aacute;pidas ir&atilde;o aparecer aqui"
                };
            } else if (this.buttons) {
                return {
                    icon: "$botoesIcon",
                    text: "Adicione bot&otilde;es",
                    active_text: "Os bot&otilde;es ir&atilde;o aparecer aqui"
                };
            }

            return {
                icon: "",
                text: "",
                active_text: ""
            };
        },
        secondLabelConfigBar() {
            if (this.config_bar.first_value === "VERTICAL") {
                return "Imagem";
            } else {
                return "Alinhamento";
            }
        },
        secondTooltipConfigBar() {
            if (this.config_bar.first_value === "VERTICAL") {
                return "Altere a propor&ccedil;&atilde;o da imagem no card vertical";
            } else {
                return "Altere a posi&ccedil;&atilde;o da imagem &agrave; esquerda ou direita no card horizontal";
            }
        },
        secondOptionsConfigBar() {
            if (this.config_bar.first_value === "VERTICAL") {
                return { TALL: { label: "Grande", icon: "$cardVerticalLargeIcon" }, MEDIUM: { label: "M&eacute;dia", icon: "$cardVerticalMediumIcon" }, SHORT: { label: "Pequena", icon: "$cardVerticalSmallIcon" } };
            } else {
                return { LEFT: { label: "Esquerda", icon: "$cardHorizontalLeftIcon" }, RIGHT: { label: "Direita", icon: "$cardHorizontalRightIcon" } };
            }
        },
        isImageVisible() {
            return this.image && this.show_blocks.image;
        },
        isTextVisible() {
            return this.text && this.show_blocks.text;
        },
        isSuggestionsVisible() {
            return (this.fastResponses || this.buttons) && this.show_blocks.suggestions;
        },
        isCardVertical() {
            return this.config_bar.first_value === "VERTICAL";
        },
        isCardLeft() {
            return this.config_bar.second_value === "LEFT";
        },
        cardDimensionConfig() {
            let image_height;

            if (this.isCardVertical) {
                switch (this.config_bar.second_value) {
                case "TALL":
                    image_height = 180;
                    break;
                case "MEDIUM":
                    image_height = 140;
                    break;
                case "SHORT":
                    image_height = 100;
                    break;
                }
            } else {
                image_height = 272;
            }

            return {
                image: {
                    height: image_height,
                    width: this.isCardVertical ? undefined : (!this.isTextVisible && !this.isSuggestionsVisible ? 368 : 120)
                },
                text: {
                    height: (this.isCardVertical && !this.isImageVisible && !this.isSuggestionsVisible) || (!this.isCardVertical && !this.isSuggestionsVisible) ? 272 : 132,
                    width: this.isCardVertical ? undefined : 240
                },
                suggestions: {
                    height: !this.isCardVertical && !this.isTextVisible ? 272 : 132,
                    width: this.isCardVertical ? undefined : 240
                }
            };
        },
        totalMessageCharacters() {
            let total = 0;

            for (let message of this.content.list_messages) {
                if (message.type === "text") {
                    total += message.text.length;
                } else if (message.type === "pill") {
                    total += message.pill.numberCharacters;
                }

                total++;
            }

            if (total > 0) {
                total--;
            }

            return total;
        }
    },
    watch: {
        cardDimensionConfig() {
            setTimeout(() => {
                this.repositionInsertBlocks();
            }, 100);
        },
        secondOptionsConfigBar() {
            if (this.config_bar.first_value === "VERTICAL" && ["TALL", "MEDIUM", "SHORT"].indexOf(this.config_bar.second_value) === -1) {
                this.config_bar.second_value = "TALL";
            } else if (this.config_bar.first_value === "HORIZONTAL" && ["LEFT", "RIGHT"].indexOf(this.config_bar.second_value) === -1) {
                this.config_bar.second_value = "LEFT";
            }
        },
        value() {
            this.content = this.value;
            this.checkValue();
        },
        content() {
            this.$emit("input", this.content);
        },
        sectionSelected() {
            this.setSectionSelected();
        },
        config_bar: {
            handler() {
                this.content["config_bar"] = this.config_bar;
            },
            deep: true
        }
    },
    created() {
        this.checkValue();
        this.setSectionSelected();
    },
    methods: {
        /**
         * Define uma sessão como selecionada
         * @vue
         */
        setSectionSelected() {
            for (let index in this.config_content_selected) {
                if (index !== this.sectionSelected) {
                    this.config_content_selected[index] = false;
                } else {
                    this.config_content_selected[index] = true;
                }
            }
        },
        /**
         * Remove o status de SELECIONADO das outras sessões quando alguma for clicada
         * @param {String} type Tipo da sessão selecionada
         * @vue
         */
        clickConfigContent(type) {
            for (let index in this.config_content_selected) {
                if (index !== type) {
                    this.config_content_selected[index] = false;
                } else {
                    this.config_content_selected[index] = true;
                }
            }

            /**
             * Emite o tipo de sessão que foi clicada
             * @event clickSection
             * @vue
             */
            this.$emit("clickSection", type);
        },
        /**
         * Exibe um componente de {@link InsertBlock} para a sessão que foi removida
         * @param {String} section Nome da sessão que foi removida pelo usuário
         * @vue
         */
        showInsertBlock(section) {
            this.show_blocks[section] = false;

            setTimeout(() => {
                this.config_content_selected[section] = false;
                this.insert_blocks[section].show = true;
                this.repositionInsertBlocks();
            }, 100);
        },
        /**
         * Resposiciona os {@link InsertBlock} para se adaptar a alterações de largura e altura da tela
         * @vue
         */
        repositionInsertBlocks() {
            let positionImage = this.$refs.image !== undefined ? this.$refs.image.getBoundingClientRect() : new DOMRect();
            let positionText = this.$refs.text !== undefined ? this.$refs.text.getBoundingClientRect() : new DOMRect();
            let positionSuggestions = this.$refs.suggestions !== undefined ? this.$refs.suggestions.getBoundingClientRect() : new DOMRect();

            if (this.isCardVertical) {
                if (!this.show_blocks.text) {
                    positionSuggestions.width -= 60;
                }
                if (!this.show_blocks.suggestions) {
                    positionText.width += 60;
                }
            } else {
                if (!this.show_blocks.text && !this.show_blocks.suggestions) {
                    positionText.height -= 60;
                    positionSuggestions.height += 60;
                }
            }

            this.insert_blocks.image.positions = positionImage;
            this.insert_blocks.text.positions = positionText;
            this.insert_blocks.suggestions.positions = positionSuggestions;
        },
        /**
         * Restaura uma sessão que havia sido removida pelo usuário
         * @param {String} section Nome da sessão que foi restaurada
         * @vue
         */
        closeInsertBlock(section) {
            this.show_blocks[section] = true;

            setTimeout(() => {
                this.repositionInsertBlocks();
            }, 100);
        },
        /**
         * Verifica se o value foi preenchido, do contrário define os valores iniciais
         * @vue
         */
        checkValue() {
            let default_value = {
                list_messages: [],
                list_suggestions: [],
                list_title: [],
                image: null,
                config_bar: this.config_bar
            };

            if (this.content) {
                for (let index in default_value) {
                    if (this.content[index] === undefined) {
                        this.content[index] = default_value[index];
                    }
                }
            } else {
                this.content = default_value;
            }

            if (this.content.config_bar) {
                this.config_bar = this.content.config_bar;
            }
        },
        /**
         * Define um item como selecionado e remove o mesmo status de outros itens
         * @param {DraggableItemClass} item
         * @vue
         */
        clickMessage(item) {
            if (this.config_content_selected.text) {
                for (let draggable_item of this.content.list_messages) {
                    if (draggable_item.id === item.id) {
                        draggable_item.pill.selected = !item.pill.selected;
                    } else {
                        draggable_item.pill.selected = false;
                    }
                }

                this.content.list_messages = _.cloneDeep(this.content.list_messages);
                this.content.list_messages = this.template_utils.formatMessageList(this.content.list_messages);
            } else {
                for (let draggable_item of this.content.list_suggestions) {
                    if (draggable_item.id === item.id) {
                        draggable_item.pill.selected = !item.pill.selected;
                    } else {
                        draggable_item.pill.selected = false;
                    }
                }

                this.content.list_suggestions = _.cloneDeep(this.content.list_suggestions);
            }
        },
        /**
         * Dispara um evento para exibir uma notificação
         * @param {Object} data Dados da notificacao
         * @vue
         */
        showNotify(data) {
            this.$emit("showNotify", data);
        },
        /**
         * Movimenta um item do tipo pill para a esquerda ou direita através dos botões de navegação do teclado
         * @param {String} orientation Left ou Right
         * @vue
         */
        moveItem(orientation) {
            let object_items = {};
            let new_list = [];
            let new_list_title = [];
            let moved = false;

            if (this.config_content_selected.text) {
                let item_transition = null;
                let item_transition_key = null;
                object_items = Object.assign({}, this.content.list_messages);

                for (let item_key in object_items) {
                    item_key = parseInt(item_key);
                    if (orientation === "left") {
                        if (object_items[item_key].pill.selected) {
                            item_transition = object_items[item_key];
                            item_transition_key = item_key;
                        } else if (!moved && object_items[item_key + 1] !== undefined && object_items[item_key + 1].pill.selected) {
                            let item = object_items[item_key + 1];
                            object_items[item_key + 1] = object_items[item_key];
                            object_items[item_key] = item;
                            moved = true;
                        }
                    } else {
                        if (!moved && object_items[item_key].pill.selected && object_items[item_key + 1] !== undefined) {
                            let item = object_items[item_key];
                            object_items[item_key] = object_items[item_key + 1];
                            object_items[item_key + 1] = item;
                            moved = true;
                        }
                    }

                    new_list.push(object_items[item_key]);
                }

                if (item_transition && item_transition_key !== null && this.type === "card") {
                    new_list.splice(item_transition_key, 1);
                }

                if (!moved && this.type === "card") {
                    if (item_transition) {
                        new_list_title = _.cloneDeep(this.content.list_title);
                        new_list_title.push(item_transition);
                        item_transition = null;
                        moved = true;
                    } else {
                        let title_object = Object.assign({}, this.content.list_title);
                        // Remove espaços em branco extras
                        title_object = title_object.replace(/\s+/g, " ");

                        for (let title_key in title_object) {
                            title_key = parseInt(title_key);
                            if (orientation === "left") {
                                if (!moved && title_object[title_key + 1] !== undefined && title_object[title_key + 1].pill.selected) {
                                    let item = title_object[title_key + 1];
                                    title_object[title_key + 1] = title_object[title_key];
                                    title_object[title_key] = item;
                                    moved = true;
                                }
                            } else {
                                if (!moved && title_object[title_key].pill.selected && title_object[title_key + 1] !== undefined) {
                                    let item = title_object[title_key];
                                    title_object[title_key] = title_object[title_key + 1];
                                    title_object[title_key + 1] = item;
                                    moved = true;
                                }
                            }

                            new_list_title.push(title_object[title_key]);
                        }

                        if (orientation !== "left" && !moved && new_list_title[new_list_title.length - 1].pill.selected) {
                            new_list.unshift(new_list_title.pop());
                            moved = true;
                        }
                    }
                    this.content.list_title = new_list_title;
                }

                this.content.list_messages = new_list;
            } else {
                object_items = Object.assign({}, this.content.list_suggestions);

                for (let item_key in object_items) {
                    item_key = parseInt(item_key);
                    if (orientation === "left") {
                        if (!moved && object_items[item_key + 1] !== undefined && object_items[item_key + 1].pill.selected) {
                            let item = object_items[item_key + 1];
                            object_items[item_key + 1] = object_items[item_key];
                            object_items[item_key] = item;
                            moved = true;
                        }
                    } else {
                        if (!moved && object_items[item_key].pill.selected && object_items[item_key + 1] !== undefined) {
                            let item = object_items[item_key];
                            object_items[item_key] = object_items[item_key + 1];
                            object_items[item_key + 1] = item;
                            moved = true;
                        }
                    }

                    new_list.push(object_items[item_key]);
                }

                this.content.list_suggestions = new_list;
            }
        },
        /**
         * Registra a alteração na lista de suggestions
         * @vue
         */
        suggestionsChanged() {
            this.content = _.cloneDeep(this.content);
        }
    },
    template: (
        /*html*/
        `
            <v-card
                elevation="0"
                class="bg-gray-30 border-1 border-gray-300 rounded-1 overflow-auto pb-4"
                height="541"
                :ripple="false"
                @keyup.37="moveItem('left')"
                @keyup.39="moveItem('right')"
            >
                <v-row
                    v-if="type === 'card'"
                    no-gutters
                    justify="center"
                    class="mt-6"
                >
                    <v-col cols="auto">
                        <config-bar
                            v-model="config_bar"
                            first-label="Orienta&ccedil;&atilde;o:"
                            first-tooltip="Posicione a imagem do card na vertical ou horizontal"
                            :second-label="secondLabelConfigBar"
                            :second-tooltip="secondTooltipConfigBar"
                            :first-options="first_options_config_bar"
                            :second-options="secondOptionsConfigBar"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters justify="center" class="py-4">
                    <v-col cols="auto">
                        <span class="body-2 text-gray-500">Clique no bloco desejado para adicionar conte&uacute;do</span>
                    </v-col>
                </v-row>
                <v-row v-if="['text', 'image'].indexOf(type) > -1" no-gutters justify="center">
                    <v-col cols="auto">
                        <cell-phone-preview
                            :show-header="false"
                            show-footer
                            :show-preview-message="false"
                            height="457"
                            :width="300"
                            :content-fill="false"
                        >   
                            <v-row v-if="type === 'text'" no-gutters v-bind:data-v-step="'contentBlockConfigTemplate'">
                                <v-col cols="12">
                                    <config-content
                                        v-if="!content.list_messages.length"
                                        :value="config_content_selected.text"
                                        icon="$iconeTextoIcon"
                                        text="Escreva uma mensagem"
                                        text-on-active="A mensagem ir&aacute; aparecer aqui"
                                        :height="isSuggestionsVisible ? 150 : 300"
                                        @click="clickConfigContent('text')"
                                    />
                                    <content-edit
                                        v-else
                                        :danger="totalMessageCharacters > maxCharacters"
                                        :selected="config_content_selected.text"
                                        @click="clickConfigContent('text')"
                                    >
                                        <config-message-template
                                            v-if="config_content_selected.text"
                                            :list-message="template_utils.formatMessageList(content.list_messages)"
                                            :max-characters="maxCharacters"
                                            @clickItem="clickMessage"
                                            @showNotify="showNotify"
                                        />
                                        <preview-text v-else :list-message="template_utils.formatMessageList(content.list_messages)" />
                                    </content-edit>
                                </v-col>
                                <v-col
                                    v-if="fastResponses || buttons"
                                    ref="suggestions"
                                    cols="12"
                                    class="mt-2"
                                >
                                    <config-content
                                        v-if="isSuggestionsVisible && !content.list_suggestions.length"
                                        :value="config_content_selected.suggestions"
                                        show-close
                                        :icon="suggestionsConfig.icon"
                                        :text="suggestionsConfig.text"
                                        :text-on-active="suggestionsConfig.active_text"
                                        :height="150"
                                        @click="clickConfigContent('suggestions')"
                                        @close="showInsertBlock('suggestions')"
                                    />
                                    <content-edit
                                        v-else-if="isSuggestionsVisible"
                                        show-close
                                        :selected="config_content_selected.suggestions"
                                        @click="clickConfigContent('suggestions')"
                                        @close="showInsertBlock('suggestions')"
                                    >
                                        <config-respostas-rapidas
                                            v-if="config_content_selected.suggestions"
                                            v-model="content.list_suggestions"
                                            :suggestions-limit="10"
                                            @clickItem="clickMessage"
                                            @showNotify="showNotify"
                                            @change="suggestionsChanged"
                                        />
                                        <preview-suggestions v-else :list-chips="content.list_suggestions" />
                                    </content-edit>
                                    <insert-blocks
                                        v-model="insert_blocks.suggestions.show"
                                        bottom
                                        :icon="suggestionsConfig.icon"
                                        :ref-position="insert_blocks.suggestions.positions"
                                        @click="closeInsertBlock('suggestions')"
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-else-if="type === 'image'" no-gutters v-bind:data-v-step="'contentBlockConfigTemplate'">
                                <v-col cols="12">
                                    <config-content
                                        v-if="!content.image"
                                        :value="config_content_selected.image"
                                        :icon="imageVariable ? '$imageVariableIcon' : '$imageIcon'"
                                        text="Adicione uma imagem"
                                        text-on-active="A imagem ir&aacute; aparecer aqui"
                                        :height="300"
                                        @click="clickConfigContent('image')"
                                    />
                                    <preview-image v-else :image="content.image" :variable="imageVariable" />
                                </v-col>
                            </v-row>
                        </cell-phone-preview>
                    </v-col>
                </v-row>
                <v-row v-if="type === 'card'" no-gutters justify="center">
                    <v-col cols="auto" v-bind:data-v-step="'contentBlockConfigTemplate'">
                        <v-card elevation="0" :width="isCardVertical ? 300 : 'auto'" class="pa-4 bg-gray-0 border-2 border-gray-300 rounded-3">
                            <v-row no-gutters :class="(!isCardVertical ? ('flex-nowrap ' + (!isTextVisible && !isSuggestionsVisible ? '' : 'gap-2')) : 'gap-row-2')">
                                <v-col
                                    v-if="image"
                                    ref="image"
                                    :cols="isCardVertical ? 12 : 'auto'"
                                    :order="!isCardVertical && !isCardLeft ? '2' : ''"
                                >
                                    <config-content
                                        v-if="isImageVisible && !content.image"
                                        :value="config_content_selected.image"
                                        ${ /*:show-close="isTextVisible"*/"" }
                                        :icon="imageVariable ? '$imageVariableIcon' : '$imageIcon'"
                                        text="Adicione uma imagem"
                                        text-on-active="A imagem ir&aacute; aparecer aqui"
                                        :height="cardDimensionConfig.image.height"
                                        :width="cardDimensionConfig.image.width"
                                        @click="clickConfigContent('image')"
                                        @close="showInsertBlock('image')"
                                    />
                                    <content-edit
                                        v-else-if="isImageVisible"
                                        fill-height
                                        ${ /*:show-close="isTextVisible"*/"" }
                                        :selected="config_content_selected.image"
                                        @click="clickConfigContent('image')"
                                        @close="showInsertBlock('image')"
                                    >
                                        <preview-image
                                            is-card
                                            :is-vertical="isCardVertical"
                                            :is-left="isCardLeft"
                                            :image="content.image"
                                            :variable="imageVariable"
                                            :height="cardDimensionConfig.image.height"
                                            :width="cardDimensionConfig.image.width"
                                        />
                                    </content-edit>
                                    <insert-blocks
                                        v-model="insert_blocks.image.show"
                                        :top="isCardVertical"
                                        :left="!isCardVertical && isCardLeft"
                                        :right="!isCardVertical && !isCardLeft"
                                        :icon="imageVariable ? '$imageVariableIcon' : '$imageIcon'"
                                        :ref-position="insert_blocks.image.positions"
                                        @click="closeInsertBlock('image')"
                                    />
                                </v-col>
                                <v-col :cols="isCardVertical ? 12 : 'auto'" :class="!isCardVertical && !isTextVisible && !isSuggestionsVisible ? 'd-flex flex-nowrap' : ''">
                                    <v-row v-if="text" no-gutters :class="!isSuggestionsVisible && !isCardVertical ? 'fill-height' : ''">
                                        <v-col ref="text" cols="12">
                                            <config-content
                                                v-if="isTextVisible && !content.list_messages.length && !content.list_title.length"
                                                :value="config_content_selected.text"
                                                ${ /*:show-close="isImageVisible"*/"" }
                                                icon="$iconeTextoIcon"
                                                text="Escreva uma mensagem"
                                                text-on-active="A mensagem ir&aacute; aparecer aqui"
                                                :height="cardDimensionConfig.text.height"
                                                :width="cardDimensionConfig.text.width"
                                                @click="clickConfigContent('text')"
                                                @close="showInsertBlock('text')"
                                            />
                                            <content-edit
                                                v-else-if="isTextVisible"
                                                ${ /*:show-close="isImageVisible"*/"" }
                                                :danger="totalMessageCharacters > maxCharacters"
                                                :selected="config_content_selected.text"
                                                @click="clickConfigContent('text')"
                                                @close="showInsertBlock('text')"
                                            >
                                                <config-message-template
                                                    v-if="config_content_selected.text"
                                                    :list-message="template_utils.formatMessageList(content.list_messages)"
                                                    :list-title="content.list_title"
                                                    :width="cardDimensionConfig.text.width"
                                                    :max-characters="maxCharacters"
                                                    @changeTitle="content.list_title = $event"
                                                    @clickItem="clickMessage"
                                                    @showNotify="showNotify"
                                                />
                                                <preview-text
                                                    v-else
                                                    is-card
                                                    :width="cardDimensionConfig.text.width"
                                                    :list-message="template_utils.formatMessageList(content.list_messages)"
                                                    :list-title="content.list_title"
                                                />
                                            </content-edit>
                                            <insert-blocks
                                                v-model="insert_blocks.text.show"
                                                :right="(isCardVertical && isSuggestionsVisible) || (!isCardVertical && !isSuggestionsVisible && isCardLeft)"
                                                :top="!isCardVertical && isSuggestionsVisible"
                                                :bottom="isCardVertical && !isSuggestionsVisible"
                                                :left="!isCardVertical && !isSuggestionsVisible && !isCardLeft"
                                                icon="$iconeTextoIcon"
                                                :ref-position="insert_blocks.text.positions"
                                                @click="closeInsertBlock('text')"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="fastResponses || buttons" no-gutters :class="(isTextVisible ? 'mt-2' : '') + ' ' + (!isCardVertical && !isTextVisible ? 'fill-height' : '')">
                                        <v-col ref="suggestions" cols="12">
                                            <config-content
                                                v-if="isSuggestionsVisible && !content.list_suggestions.length"
                                                :value="config_content_selected.suggestions"
                                                show-close
                                                :icon="suggestionsConfig.icon"
                                                :text="suggestionsConfig.text"
                                                :text-on-active="suggestionsConfig.active_text"
                                                :height="cardDimensionConfig.suggestions.height"
                                                :width="cardDimensionConfig.suggestions.width"
                                                @click="clickConfigContent('suggestions')"
                                                @close="showInsertBlock('suggestions')"
                                            />
                                            <content-edit
                                                v-else-if="isSuggestionsVisible"
                                                show-close
                                                :selected="config_content_selected.suggestions"
                                                @click="clickConfigContent('suggestions')"
                                                @close="showInsertBlock('suggestions')"
                                            >
                                                <config-respostas-rapidas
                                                    v-if="config_content_selected.suggestions"
                                                    v-model="content.list_suggestions"
                                                    :width="cardDimensionConfig.suggestions.width"
                                                    :suggestions-limit="4"
                                                    @clickItem="clickMessage"
                                                    @showNotify="showNotify"
                                                    @change="suggestionsChanged"
                                                />
                                                <preview-suggestions
                                                    v-else
                                                    is-card
                                                    :list-chips="content.list_suggestions"
                                                    :width="cardDimensionConfig.suggestions.width"
                                                />
                                            </content-edit>
                                            <insert-blocks
                                                v-model="insert_blocks.suggestions.show"
                                                :bottom="isCardVertical || (!isCardVertical && isTextVisible)"
                                                :right="!isCardVertical && !isTextVisible && isCardLeft"
                                                :left="!isCardVertical && !isTextVisible && !isCardLeft"
                                                :icon="suggestionsConfig.icon"
                                                :ref-position="insert_blocks.suggestions.positions"
                                                @click="closeInsertBlock('suggestions')"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};