export default {
    name: "rcs-product-icon",
    template: (
        /*html*/
        `            
            <svg xmlns="http://www.w3.org/2000/svg" width="43.044" height="56.338" viewBox="0 0 43.044 56.338">
              <path id="RCS-logo-Vertical" d="M6.559,339.121V337.5H4.94a.983.983,0,1,1,0-1.967H6.559v-1.618a.983.983,0,0,1,1.966,0v1.618h1.618a.983.983,0,0,1,0,1.967H8.525v1.618a.983.983,0,0,1-1.966,0Zm19.667-.023a2.11,2.11,0,0,1,.339-1.151,7.989,7.989,0,0,0,2.122.35c1.05,0,1.49-.372,1.49-1.094,0-.609-.508-.892-1.174-1.129-1.162-.418-2.776-.688-2.776-2.449,0-1.422.926-2.381,2.844-2.381a6.8,6.8,0,0,1,2.268.339,1.733,1.733,0,0,1-.384,1.129,5.82,5.82,0,0,0-1.682-.248c-1.094,0-1.613.361-1.613,1.095,0,.711.982.9,1.963,1.253,1.016.372,2.031.914,2.031,2.359,0,1.569-.982,2.426-2.912,2.426a5.97,5.97,0,0,1-2.516-.5m-6.309-3.679c0-2.72,1.185-4.176,3.42-4.176a4.253,4.253,0,0,1,1.952.4,1.37,1.37,0,0,1-.429,1.072,4.461,4.461,0,0,0-1.366-.236c-1.2,0-2.065.621-2.065,2.957s.869,2.912,2.065,2.912a4.909,4.909,0,0,0,1.478-.237A1.5,1.5,0,0,1,25.4,339.2a4.8,4.8,0,0,1-2.065.395c-2.235,0-3.421-1.456-3.421-4.176Zm-2.156,3.961L15.9,336.232h-.914v3.149a1.964,1.964,0,0,1-.711.1,1.832,1.832,0,0,1-.734-.1v-8.023H16.51a2.337,2.337,0,0,1,2.517,2.438,2.285,2.285,0,0,1-1.557,2.291l1.964,3.3a2.876,2.876,0,0,1-.858.1,2.726,2.726,0,0,1-.813-.1Zm-2.776-4.345h1.32a1.241,1.241,0,1,0,0-2.483h-1.32Zm-11.214-7.6h0l-.017-.01-.056-.035-.018-.011-.017-.011-.024-.014-.037-.026-.019-.011a1.788,1.788,0,0,1-.734-1.45V294.151a9.4,9.4,0,0,1,9.334-9.385H35a9.394,9.394,0,0,1,9.383,9.383v17.709A9.394,9.394,0,0,1,35,321.24H12.889l-7.032,5.942a1.8,1.8,0,0,1-2.088.248Zm2.665-15.574V322l4.64-3.922a1.793,1.793,0,0,1,1.156-.424H35a5.8,5.8,0,0,0,5.8-5.8V294.113a5.8,5.8,0,0,0-5.8-5.762H12.232a5.8,5.8,0,0,0-5.8,5.8v17.708Zm7.06-2.886a1.792,1.792,0,1,1,0-3.584H33.735a1.792,1.792,0,1,1,0,3.584Zm0-8.351a1.793,1.793,0,1,1-.064-3.585h20.3a1.793,1.793,0,1,1,.064,3.585H13.5Z" transform="translate(-1.839 -284.266)" stroke="currentColor" fill="currentColor" stroke-miterlimit="10" stroke-width="1"/>
            </svg>
        `
    )
};
