export default {
    template: (
        /*html*/
        `
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9836 8.88846C11.9836 8.61232 12.2075 8.38846 12.4836 8.38846H19.8379C20.114 8.38846 20.3379 8.61232 20.3379 8.88846C20.3379 9.16461 20.114 9.38846 19.8379 9.38846H12.4836C12.2075 9.38846 11.9836 9.16461 11.9836 8.88846Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9836 14.5727C11.9836 14.2965 12.2075 14.0727 12.4836 14.0727H19.4799C19.756 14.0727 19.9799 14.2965 19.9799 14.5727C19.9799 14.8488 19.756 15.0727 19.4799 15.0727H12.4836C12.2075 15.0727 11.9836 14.8488 11.9836 14.5727Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9836 17.7084C11.9836 17.4323 12.2075 17.2084 12.4836 17.2084H17.2639C17.54 17.2084 17.7639 17.4323 17.7639 17.7084C17.7639 17.9846 17.54 18.2084 17.2639 18.2084H12.4836C12.2075 18.2084 11.9836 17.9846 11.9836 17.7084Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9836 11.7306C11.9836 11.4544 12.2075 11.2306 12.4836 11.2306H22.0441C22.3203 11.2306 22.5441 11.4544 22.5441 11.7306C22.5441 12.0067 22.3203 12.2306 22.0441 12.2306H12.4836C12.2075 12.2306 11.9836 12.0067 11.9836 11.7306Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.10059 4.40042L23.8996 4.40039C24.4519 4.40039 24.8996 4.84811 24.8996 5.40039V20.6005C24.8996 21.1528 24.4519 21.6005 23.8996 21.6005L2.10059 21.6005C1.5483 21.6005 1.10059 21.1528 1.10059 20.6005V5.40042C1.10059 4.84814 1.5483 4.40042 2.10059 4.40042ZM10.4596 20.6005V5.40042L23.8996 5.40039V20.6005L10.4596 20.6005ZM3.376 13.866C3.41448 13.8931 3.46047 13.9076 3.50762 13.9076L8.0526 13.9074C8.09965 13.9074 8.14553 13.8928 8.18394 13.8659C8.22234 13.8389 8.25138 13.8007 8.26704 13.7566C8.28271 13.7126 8.28424 13.6648 8.27142 13.6198C8.2586 13.5748 8.23207 13.5349 8.19546 13.5055L6.49636 12.1435C6.4559 12.111 6.40548 12.0934 6.3535 12.0934C6.30151 12.0934 6.25109 12.111 6.21063 12.1435L5.07433 13.0542L4.64552 12.7141C4.60519 12.6821 4.55509 12.6646 4.50345 12.6646C4.45181 12.6646 4.40171 12.6821 4.36139 12.7141L3.36544 13.5051C3.32862 13.5343 3.30185 13.5742 3.28885 13.6193C3.27585 13.6643 3.27725 13.7122 3.29286 13.7564C3.30848 13.8006 3.33753 13.8389 3.376 13.866Z" fill="currentColor"/>
        </svg>

        `
    )
};