import BaseCardLarge from "../../../components/atomos/BaseCardLarge.js";

export default ({
    components: {
        BaseCardLarge
    },
    data() {
        return {
            icon: "icon-imagem-template",
            title: "Title",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            value: false,
            isHorizontal: false,
            disabled: false,
            blockUnselect: false,
            soon: false
        };
    },
    methods: {
        teste() {
            console.log("teste");
        }
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="6">
                <v-row>
                    <v-col>
                        <v-card class="pa-5">
                            <v-row>
                                <v-col cols="4">
                                    <v-checkbox label="Ativo" v-model="value"></v-checkbox>
                                </v-col>
                                <v-col cols="4">
                                    <v-checkbox label="Desabilitado" v-model="disabled"></v-checkbox>
                                </v-col>
                                <v-col cols="4">
                                    <v-checkbox label="Em breve" v-model="soon"></v-checkbox>
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox label="Horizontal" v-model="isHorizontal"></v-checkbox>
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox label="Bloquear desselecaoo" v-model="blockUnselect"></v-checkbox>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field label="Icone" v-model="icon"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field label="Titulo" v-model="title"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field label="Descricao" v-model="description"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" md="6" lg="3">
                                    <base-card-large
                                        v-model="value"
                                        :icon="icon"
                                        :title="title"
                                        :description="description"
                                        :is-horizontal="isHorizontal"
                                        :disabled="disabled"
                                        :block-unselect="blockUnselect"
                                        :soon="soon"
                                    ></base-card-large> 
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        `)
});