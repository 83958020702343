import BaseDataTable from "../../../../components/organismos/BaseDataTable";
import BaseSelect from "../../../../components/atomos/BaseSelect";
import BaseDialog from "../../../../components/moleculas/BaseDialog";
import BaseSectionForm from "../../../../components/atomos/BaseSectionForm";
import PreviewTemplate from "../../../../components/organismos/PreviewTemplate";
import BaseButton from "../../../../components/atomos/BaseButton";
import SummaryHygiene from "../SummaryHygiene";
import { DataTableHeaderClass } from "../../../../classes/DataTableHeaderClass";
import { BasePillClass } from "../../../../classes/BasePillClass";
import { DraggableItemClass } from "../../../../classes/DraggableItemClass";
import { CardRcsClass } from "../../../../classes/CardRcsClass";

import * as types from "../../store/mutations-types";

/**
 * Componente para configurar o envio de campanhas
 * 
 * @requires {@link BaseDataTable}
 * @requires {@link BaseSelect}
 * @requires {@link BaseDialog}
 * @requires {@link BaseSectionForm}
 * @requires {@link PreviewTemplate}
 * @requires {@link BaseButton}
 * @requires {@link SummaryHygiene}
 * @requires {@link DataTableHeaderClass}
 * @requires {@link BasePillClass}
 * @requires {@link DraggableItemClass}
 * @requires {@link CardRcsClass}
 * 
 * @displayName DialogFileConfiguration
 * @category Page / Campaign
 * @subcategory dialogs
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseDataTable,
        BaseSelect,
        BaseDialog,
        BaseSectionForm,
        PreviewTemplate,
        BaseButton,
        SummaryHygiene
    },
    props: {
        /**
         * Controla a exibicao do modal atraves de v-model
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * ID do arquivo que esta sendo configurado
         */
        fileId: {
            type: String,
            required: true
        },
        /**
         * Conteudo do template a ser utilizado na configuracao
         */
        dsTemplate: {
            type: String,
            required: true
        },
        /**
         * Parametros do template a ser utilizado na configuracao
         */
        dsParametro: {
            type: String,
            required: true
        },
        /**
         * ID da carteira selecionada
         */
        clientId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            show: this.value,
            action_button: {
                primary: true,
                label: "Salvar configura&ccedil;&atilde;o",
                loading: false,
                icon: "mdi-check-bold",
                disabled: true,
                id: "salvarConfiguracao"
            },
            show_file_preview: false,
            selected_item: [],
            options_select: [],
            field_phone: null,
            field_phone_rules: [
                v => !!v || window.Vue.htmlEntities("Selecione uma op&ccedil;&atilde;o"),
                v => {
                    v = this.selected_item.length ? this.selected_item[0][v] : null;
                    return !v || window.Vue.validatePhoneDDD(v) || window.Vue.htmlEntities("Informe um telefone v&aacute;lido");
                }
                // TODO: Construir as regras de validação para os numeros de telefone
            ],
            image_rules: [
                v => !!v || window.Vue.htmlEntities("Selecione uma op&ccedil;&atilde;o"),
                v => {
                    v = this.selected_item.length ? this.selected_item[0][v] : null;
                    return !v || this.validateImage(v) || window.Vue.htmlEntities("Informe uma imagem v&aacute;lida");
                }
            ],
            field_client_data: "",
            fields_template: {},
            table_headers: [],
            filled_fields: 0,
            total_fields: 0,
            current_field: 0,
            only_empty_fields: false,
            in_create_campaign: true
        };
    },
    computed: {
        list_files: {
            get() {
                return this.$store.state.campaign.list_files;
            },
            set(new_value) {
                this.$store.commit("campaign/" + types.SET_LIST_FILES, new_value);
            }
        },
        fileSelected() {
            let file_index = this.list_files.findIndex((x) => x.id === this.fileId);
            return this.list_files[file_index];
        },
        listItemsTable() {
            if (this.fileSelected) {
                return this.fileSelected.file_content.slice(0, 10);
            }

            return [];
        },
        templateConfig() {
            let template = {
                type: "",
                message: "",
                list_suggestions: [],
                image: "",
                card: new CardRcsClass({}),
                list_card: []
            };

            if (this.dsTemplate) {
                let ds_template = this.dsTemplate ?? "";

                try {
                    ds_template = JSON.parse(ds_template);
                } catch (error) {
                    ds_template = { text: ds_template };
                }

                if (ds_template.url !== undefined && ds_template.url !== "") {
                    template.type = "image";
                    template.image = ds_template.url[0] === "{" && ds_template.url[ds_template.url.length - 1] === "}" ? "variable" : ds_template.url;
                } else if (ds_template.text !== undefined && ds_template.text !== "") {
                    template.type = "message";
                    template.message = ds_template.text;
                    template.list_suggestions = [];

                    if (ds_template.suggestions !== undefined && Array.isArray(ds_template.suggestions)) {
                        for (let suggestion of ds_template.suggestions) {
                            let pill = new BasePillClass({ title: suggestion.label, subTitle: suggestion.value ?? null });

                            switch (suggestion.type) {
                            case "url":
                                pill.titleIcon = "fa-globe-americas";
                                break;
                            case "dial_phone":
                                pill.titleIcon = "fa-phone-alt";
                                break;
                            case "show_location":
                                pill.titleIcon = "fa-map-marker-alt";
                                break;
                            case "request_location":
                                pill.titleIcon = "fa-crosshairs";
                                break;
                            }

                            template.list_suggestions.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                        }
                    }
                } else if (ds_template.contents !== undefined && !Array.isArray(ds_template.contents)) {
                    template.type = "card";
                    let buttons = [];
                    let responses = [];

                    if (ds_template.contents.suggestions !== undefined && Array.isArray(ds_template.contents.suggestions)) {
                        for (let index in ds_template.contents.suggestions) {
                            let pill = new BasePillClass({ title: ds_template.contents.suggestions[index].label, subTitle: ds_template.contents.suggestions[index].value ?? null });

                            switch (ds_template.contents.suggestions[index].type) {
                            case "url":
                                pill.titleIcon = "fa-globe-americas";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "dial_phone":
                                pill.titleIcon = "fa-phone-alt";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "show_location":
                                pill.titleIcon = "fa-map-marker-alt";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "request_location":
                                pill.titleIcon = "fa-crosshairs";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "text":
                                responses.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            }
                        }
                    }

                    template.card = new CardRcsClass({
                        image: ds_template.contents.media !== undefined ? (ds_template.contents.media.file.url[0] === "{" && ds_template.contents.media.file.url[ds_template.contents.media.file.url.length - 1] === "}" ? "variable" : ds_template.contents.media.file.url) : null,
                        title: ds_template.contents.title,
                        description: ds_template.contents.description,
                        suggestionButtons: buttons,
                        suggestionResponses: responses,
                        orientation: ds_template.orientation,
                        alignment: ds_template.alignment,
                        image_height: ds_template.contents.media?.height ?? "TALL"
                    });
                } else if (ds_template.contents !== undefined && Array.isArray(ds_template.contents)) {
                    template.type = "carousel";
                    template.list_card = [];

                    for (let card of ds_template.contents) {
                        let buttons = [];
                        let responses = [];

                        if (card.suggestions !== undefined && Array.isArray(card.suggestions)) {
                            for (let suggestion of card.suggestions) {
                                let pill = new BasePillClass({ title: suggestion.label, subTitle: suggestion.value });

                                switch (suggestion.type) {
                                case "url":
                                    pill.titleIcon = "fa-globe-americas";
                                    pill.type = "link";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "dial_phone":
                                    pill.titleIcon = "fa-phone-alt";
                                    pill.type = "phone";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "show_location":
                                    pill.titleIcon = "fa-map-marker-alt";
                                    pill.type = "map";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "request_location":
                                    pill.titleIcon = "fa-crosshairs";
                                    pill.type = "localization";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "text":
                                    responses.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                }
                            }
                        }

                        template.list_card.push(new CardRcsClass({
                            image: card.media !== undefined ? (card.media.file.url[0] === "{" && card.media.file.url[card.media.file.url.length - 1] === "}" ? "variable" : card.media.file.url) : null,
                            title: card.title,
                            media: card.media.file,
                            description: card.description,
                            suggestionButtons: buttons,
                            suggestionResponses: responses,
                            image_height: card.media?.height ?? "TALL"
                        }));
                    }
                }
            }

            return template;
        }
    },
    watch: {
        value() {
            this.$refs.form.reset();
            this.show = this.value;

            this.mountFieldsTemplate();
            this.mountTableHeader();
            this.updateOptions();
        },
        show() {
            this.$emit("input", this.show);
        },
        selected_item() {
            this.mountSelectOptions();
        },
        template() {
            this.mountFieldsTemplate();
        },
        field_phone() {
            this.mountTableHeader();
        },
        field_client_data() {
            this.mountTableHeader();
        }
    },
    mounted() {
        this.mountTableHeader();
        this.mountSelectOptions();
        this.mountFieldsTemplate();
    },
    methods: {
        /**
         * Monta os headers da tabela de exemplo
         * @vue
         */
        mountTableHeader() {
            let headers = [];

            for (let key of Object.keys(this.fileSelected.file_content[0])) {
                if (key !== "row_id") {
                    let name = key;
                    let text_class = "text-gray-300";

                    if (key === this.field_phone) {
                        name = "Telefone";
                    } else if (key === this.field_client_data) {
                        name = "Campo informado";
                    }

                    if (Object.values(this.fields_template).indexOf(key) > -1) {
                        let new_name = Object.keys(this.fields_template).find(item_name => this.fields_template[item_name] === key);
                        name = (new_name.charAt(0).toUpperCase() + new_name.slice(1)).replaceAll("_", " ");
                    }

                    if (name !== key) {
                        text_class = "text-gray-900";
                    }

                    headers.push(new DataTableHeaderClass({
                        text: name,
                        value: key,
                        sortable: false,
                        cellClass: text_class,
                        className: text_class
                    }));
                }
            }

            this.table_headers = headers;
            this.toggleSubmitButton();

            if (this.selected_item.length === 0) {
                this.selected_item = [this.fileSelected.file_content[0]];
            }
        },
        /**
         * Monta o array de opcoes dos selects
         * @vue
         */
        mountSelectOptions() {
            let items = [];

            for (let key in this.selected_item[0]) {
                if (key !== "row_id" && key !== "isSelectable") {
                    items.push({
                        text: this.selected_item[0][key],
                        value: key,
                        disabled: false
                    });
                }
            }

            this.options_select = items;
            this.updateOptions();
        },
        /**
         * Atualiza os dados de opcoes de select para desabilitar itens ja escolhidos
         * @vue
         */
        updateOptions() {
            for (let option of this.options_select) {
                if ([this.field_phone, this.field_client_data, ...Object.values(this.fields_template)].indexOf(option.value) > -1) {
                    option.disabled = true;
                } else {
                    option.disabled = false;
                }
            }

            let filled = 0;

            if (this.field_phone) {
                filled++;
            }
            if (this.field_client_data) {
                filled++;
            }

            this.filled_fields = Object.values(this.fields_template).filter(v => v).length + filled;
        },
        /**
         * Monta os dados iniciais a serem utilizados para configurar a template
         * @vue
         */
        mountFieldsTemplate() {
            let parametros = JSON.parse(this.dsParametro);
            this.total_fields = Object.keys(parametros).length + 2;

            this.field_phone = this.fileSelected.file_configuration?.phone ?? null,
            this.field_client_data = this.fileSelected.file_configuration?.client_data ?? "";

            for (let field_name in parametros) {
                this.fields_template[field_name] = this.fileSelected.file_configuration ? (this.fileSelected.file_configuration[field_name] ?? null) : null;
            }

            this.updateOptions();
        },
        /**
         * Configura novos valores para os campos
         * @param {Object} value 
         * @vue
         */
        configFields(value) {
            this.fields_template = value;

            this.filled_fields = Object.values(this.fields_template).filter(v => v).length;

            this.updateOptions();
            this.mountTableHeader();
        },
        /**
         * Ativa ou desativa o botao de acao do dialog
         * @vue
         */
        toggleSubmitButton() {
            let filled = Object.values(this.fields_template).every(field => Boolean(field)); // Verifica se todos os campos foram preenchidos
            if (this.fields_template.url) {
                const colunaKey = this.fields_template.url; // Pega o nome da coluna que contem a url
                const isValidUrl = colunaKey in this.selected_item[0] && this.selected_item[0][colunaKey].match(/^https?:\/\/[^\s]+(\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF))$/); // Verifica se a url é valida
                filled = isValidUrl ? filled : false; // Se a url for invalida, desabilita o botao
            }
            this.action_button.disabled = !(this.field_phone && this.field_phone.length && filled); // Ativa ou desativa o botao
        },
        /**
         * Emite os dados configurados
         * @vue
         */
        save() {
            this.$store.commit("campaign/" + types.SET_FILE_CONFIGURATION, {
                file_id: this.fileSelected.id,
                file_configuration: {
                    phone: this.field_phone,
                    client_data: this.field_client_data,
                    ...this.fields_template
                }
            });
            this.$emit("saveConfig");
        },
        /**
         * Avanca o foco para o proximo campo
         * @vue
         */
        nextField() {
            if (this.current_field >= (this.$refs.form.$el.length - 1)) {
                this.current_field = 0;
            }

            this.current_field += 1;
            while (this.$refs.form.$el[this.current_field].tagName !== "INPUT" || this.$refs.form.$el[this.current_field].type === "hidden" || (this.only_empty_fields && this.$refs.form.$el[this.current_field].previousSibling)) {
                this.current_field++;

                if (this.current_field >= (this.$refs.form.$el.length - 1)) {
                    this.current_field = 0;
                }
            }

            this.focusField();
        },
        /**
         * Volta o foco para o campo anterior
         * @vue
         */
        previousField() {
            this.current_field -= 1;

            if (this.current_field < 0) {
                this.current_field = this.$refs.form.$el.length - 1;
            }

            while (this.$refs.form.$el[this.current_field].tagName !== "INPUT" || this.$refs.form.$el[this.current_field].type === "hidden" || (this.only_empty_fields && this.$refs.form.$el[this.current_field].previousSibling)) {
                this.current_field--;

                if (this.current_field < 0) {
                    this.current_field = this.$refs.form.$el.length - 1;
                }
            }

            this.focusField();
        },
        /**
         * Habilita o foco de um campo
         * @vue
         */
        focusField() {
            this.$refs.form.$el[this.current_field].select();

            if (!this.$refs.form.$el[this.current_field].previousSibling) {
                this.$refs.form.$el[this.current_field].click();
            }
        },
        validateImage(url) {
            // expressão regular para verificar se a URL é uma imagem
            const regex = /\.(jpg|jpeg|png|gif|svg|JPG|JPEG|PNG|GIF|SVG)$/i;

            return regex.test(url);
        }
    },
    template: (
        /*html*/
        `
            <base-dialog
                v-model="show"
                title="Configura&ccedil;&atilde;o do arquivo"
                cancel
                :action-button="action_button"
                :disable-action-button="action_button.disabled"
                use-icon-close
                max-width="75%"
                use-sub-action
                cols-button-action="auto"
                cols-button-cancel="auto"
                button-action-order="1"
                @clickDialog="save"
            >
                <v-form ref="form">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-row no-gutters justify="center">
                                        <v-col cols="auto">
                                            <span class="body-2" v-html="fileSelected.file_name" /> | <a class="body-2" @click="show_file_preview = !show_file_preview">{{ show_file_preview ? "Ocultar pr&eacute;via do arquivo" : "Ver pr&eacute;via do arquivo" }}</a>
                                        </v-col>
                                    </v-row>
                                    <v-expand-transition>
                                        <v-row v-if="show_file_preview" no-gutters>
                                            <v-col cols="12">
                                                <base-data-table
                                                    v-model="selected_item"
                                                    :headers="table_headers"
                                                    :list-items="listItemsTable"
                                                    :pagination="false"
                                                    :show-total="false"
                                                    :custom-columns="false"
                                                    select-column
                                                    item-key="row_id"
                                                    single-select
                                                    dense
                                                    :selected-items="selected_item"
                                                    height="155"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-expand-transition>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4 gap-4">
                                <v-col cols="4">
                                    <base-section-form title="Dados para envio" description="Informe quais colunas do arquivo possuem os dados">
                                        <v-card elevation="0" class="mt-4 pa-4 border-1 border-gray-300 rounded-1">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <base-select
                                                        v-model="field_phone"
                                                        :items="options_select"
                                                        label="Selecione uma op&ccedil;&atilde;o"
                                                        required
                                                        title="Telefone"
                                                        tooltip-text="Informe em qual coluna do arquivo est&atilde;o os n&uacute;meros de telefone para envio."
                                                        clearable
                                                        :rules="field_phone_rules"
                                                        @change="updateOptions"
                                                        id="selectTelefone"
                                                    />
                                                </v-col>
                                                <v-col cols="12" class="mt-4">
                                                    <base-select
                                                        v-model="field_client_data"
                                                        :items="options_select"
                                                        label="Selecione uma op&ccedil;&atilde;o"
                                                        title="Campo informado"
                                                        tooltip-text="Caso seu arquivo possua a coluna <b>campo informado</b> inclua aqui."
                                                        clearable
                                                        @change="updateOptions"
                                                        id="selectCampoInformado"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </base-section-form>
                                </v-col>
                                <v-col cols="8">
                                    <base-section-form title="Vari&aacute;veis da mensagem" description="O modelo da mensagem escolhida cont&eacute;m vari&aacute;veis, informe quais as colunas do arquivo contemplam as vari&aacute;veis necess&aacute;rias">
                                        <v-card elevation="0" class="mt-4 pa-4 border-1 border-gray-300 rounded-1">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <preview-template
                                                        v-if="dsTemplate.length"
                                                        :type="templateConfig.type"
                                                        :message="templateConfig.message"
                                                        :list-suggestions="templateConfig.list_suggestions"
                                                        :image="templateConfig.image"
                                                        :card="templateConfig.card"
                                                        :list-card="templateConfig.list_card"
                                                        show-overflow
                                                        :select-options="options_select"
                                                        :fields-template="fields_template"
                                                        description=""
                                                        @changeField="configFields"
                                                        :in-create-campaign="in_create_campaign"
                                                        :rules="image_rules"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </base-section-form>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
                <template #subAction="">
                    <v-row no-gutters class="gap-4">
                        <v-col cols="auto">
                            <base-button
                                icon="mdi-arrow-left"
                                label="Vari&aacute;vel anterior"
                                secondary
                                left
                                small
                                :disabled="only_empty_fields && filled_fields === total_fields"
                                @click="previousField"
                            />
                        </v-col>
                        <v-col cols="auto" class="d-flex align-center">
                            <span class="body-2">Voc&ecirc; preencheu <b>{{ filled_fields }}</b> de <b>{{ total_fields }}</b> vari&aacute;veis</span>
                        </v-col>
                        <v-col cols="auto">
                            <base-button
                                icon="mdi-arrow-right"
                                label="Pr&oacute;xima vari&aacute;vel"
                                secondary
                                small
                                :disabled="only_empty_fields && filled_fields === total_fields"
                                @click="nextField"
                            />
                        </v-col>
                        <v-col cols="auto">
                            <v-checkbox
                                v-model="only_empty_fields"
                                color="primary-blue-500"
                                dense
                                :label="'Navegar apenas em vari&aacute;veis n&atilde;o preenchidas' | htmlEntities"
                                hide-details
                                class="mt-0 pt-0"
                            />
                        </v-col>
                    </v-row>
                </template>
            </base-dialog>
        `
    )
};