import { DataTableHeaderClass } from "../../classes/DataTableHeaderClass";
import BaseTextField from "../atomos/BaseTextField?v=2";
import BaseSelect from "../atomos/BaseSelect?v=2";
import BaseTooltip from "../atomos/BaseTooltip?v=2";

const headers_slots = "<template v-for=\"(h, i) in headers\" v-slot:[`header.${h.value}`]=\"{header}\">" +
    "   <v-row v-bind:data-v-step=\"h.stepTutorial\" :class=\"(h.sortable ? 'mb-n4 pr-3' : '') + ' mt-0 ml-n4 mr-0 pb-0 pt-0 pl-0'\">" +
    "       <v-col class=\"\">" +
    "           <base-tooltip :text=\"h.tooltipText\">" +
    "               <span class=\"body-2\" v-html=\"h.text\"></span>" +
    "           </base-tooltip>" +
    "       </v-col>" +
    "   </v-row>" +
    "</template>";

const section_slots = "<template v-for=\"(slot,i) in slots_default\" v-slot:[`item.${slot.value}`]=\"{item}\">" +
    "   <span class=\"body-2\">{{item[slot.value]}}</span>" +
    "</template>" +
    "<template v-for=\"(slot,i) in customSlots\" v-slot:[`item.${slot}`]=\"{item, index}\">" +
    "   <span class=\"hidden\">{{ item[slot] }}</span>" +
    "   <slot :name=\"slot\" v-bind:item=\"item\" v-bind:index=\"index\"></slot>" +
    "</template>";

/**
 * Componente de BaseDataTable do painel
 * 
 * @requires {@link BaseTextField}
 * @requires {@link BaseSelect}
 * @requires {@link DataTableHeaderClass}
 * @requires {@link BaseTooltip}
 *
 * @displayName BaseDataTable
 * @component
 * @category Organismos
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseSelect,
        BaseTooltip
    },
    props: {
        /**
         * Array de itens para serem utilizados como headers da tabela, os itens devem ser uma instancia de {@link DataTableHeaderClass}
         */
        headers: {
            type: Array,
            required: true,
            validator: function (data) {
                let is_valid = true;

                for (const element of data) {
                    if (!(element instanceof DataTableHeaderClass)) {
                        is_valid = false;
                        break;
                    }
                }

                return is_valid;
            }
        },
        /**
         * Lista de itens a serem exibidos
         */
        listItems: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Define se a tabela pode ser filtrada
         */
        filterable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define se a paginacao deve ser exibida
         */
        pagination: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Habilita a exibicao do total de dados da tabela
         */
        showTotal: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Quantidade de itens por pagina
         */
        pageLength: {
            type: Number,
            required: false,
            default: 10
        },
        /**
         * Indica que as linhas sao expandiveis
         * TODO: falta implementar
         */
        expandable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica que a exibicao de colunas pode ser personalizada
         */
        customColumns: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Adiciona checkbox as linhas da tabela
         */
        selectColumn: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica qual deve ser a chave para identificar cada linha da tabela, deve ser utilizado se selectColumn = true
         */
        itemKey: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Caso true, somente uma linha por vez podera ser selecionada
         */
        singleSelect: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Ativa o estado de loader da tabela
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Ativa o atributo dense do datatable
         */
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Coluna de ordenacao inicial
         */
        sortBy: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Define que a ordenacao inicial eh decrescente
         */
        sortDesc: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Define a lista de slots customizados
         */
        customSlots: {
            type: Array,
            required: false,
            default: function () {
                return [];
            }
        },
        /**
         * Indica que o select de total de items por pagina deve ser exibido
         */
        showPageLength: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Lista de itens selecionados inicialmente
         */
        selectedItems: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /** 
         * Indica que o componente deve utilizar o header alternativo
        */
        alternativeHeader: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica o nome a ser utilizado para se referenciar aos itens da tabela
         */
        itemName: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Lista de itens selecionados inicialmente
         */
        value: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Informacoees para realizar a busca dos dados de forma server-side, o conteudo suportado eh
         * <pre>{
         *     request_to: String - PHP ou API - Obrigatorio,
         *     url: String - Endpoint da URL para onde a requisicao sera realizada - Obrigatorio
         *     method: String - Tipo da requisicao (post, get, put) - Opcional, default = post
         *     data: Object - Conteudo da requisicao - Opcional
         *     headers: Object - Headers a serem adicionados a requisicao - Opcional
         * }</pre>
         */
        serverSideConfig: {
            type: Object,
            required: false,
            default: null,
            validator: function (data) {
                let is_valid = true;
                //let fields = ["request_to", "url", "method", "data", "headers"];
                let fields = ["request_to", "url"];

                for (let field of fields) {
                    if (data[field] === undefined) {
                        is_valid = false;
                        break;
                    }
                }

                return is_valid;
            }
        },
        /**
         * Define um numero para o passo a passo do tutorial para o select de personalizar colunas
         */
        stepTutorialCustomColumns: {
            type: [String, Number],
            required: false,
            default: null
        },
        /**
         * Define um numero para o passo a passo do tutorial para o select de quantidade de linhas
         */
        stepTutorialPageLength: {
            type: [String, Number],
            required: false,
            default: null
        },
        /**
         * Define um numero para o passo a passo do tutorial para o text field de busca
         */
        stepTutorialFilter: {
            type: [String, Number],
            required: false,
            default: null
        },
        /**
         * Regras para desabilitar o checkbox dos itens
         * @vue
         */
        ruleDisableCheckboxItem: {
            type: Function,
            required: false,
            default: () => {
                return false;
            }
        },
        /**
         * Define a altura da tabela
         */
        height: {
            type: [String, Number],
            required: false,
            default: null
        },
        /**
         * Deixa o header da tabela fixo
         */
        fixedHeader: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Texto do dos itens selecionados
         */
        labelSelectedItemName: {
            type: String,
            required: false,
            default: "selecionados"
        },
        /**
         * Op��es dinamicas
         */
        dynamicOptions: {
            type: Object,
            required: false,
            default: null
        },
        /**
         * Funcao para definir uma classe para linhas especificas
         */
        itemRowClass: {
            type: Function,
            required: false,
            default() {
                return () => {};
            }
        },
        /**
         * ID da tabela
         */
        id: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            options: {},
            search: null,
            page: 1,
            item_selected: this.selectedItems || this.value,
            columns_selected: this.getHeadersBreakpoint(),
            total_items: -1,
            loading_table: false,
            list_items: this.listItems,
            items_per_page: this.pageLength,
            table_height: this.height,
            all_selected: false,
            show_lines: [
                {"value": 10, "text": 10},
                {"value": 25, "text": 25},
                {"value": 50, "text": 50},
                {"value": 100, "text": 100}
            ]
        };
    },
    computed: {
        label_filter() {
            let names = [];

            for (let header of this.headers) {
                if (header.filterable) {
                    names.push(header.text);
                }
            }

            return names.length > 0 ? "Busque por " + names.join(" e/ou ") : "Busca";
        },
        tooltip_filter() {
            let names = [];

            for (let header of this.headers) {
                if (header.filterable) {
                    names.push(header.text);
                }
            }

            return names.length > 0 ? "Realize uma busca por " + names.join(" e/ou ") : "Realize uma busca";
        },
        slots_default() {
            return this.headers.filter((header) => {
                return !this.customSlots.includes(header.value);
            });
        },
        itemsTable() {
            return this.list_items.map(x => ({ ...x, isSelectable: !this.ruleDisableCheckboxItem(x)}));
        }
    },
    watch: {
        columns_selected() {
            this.showHideHeaders();
        },
        item_selected() {
            /**
             * Evento de input, emitido sempre que o valor relacionado aos itens selecionados for alterado
             * 
             * @property {Array} item_selected Lista de itens selecionados
             * 
             * @event input
             * @vue
             */
            this.$emit("input", this.item_selected);
        },
        selectedItems(new_value) {
            this.item_selected = new_value;
        },
        value(new_value) {
            this.item_selected = new_value;
        },
        options: {
            handler() {
                for (let header of this.headers) {
                    header = this.checkSortColumn(header).all;
                }

                if (this.serverSideConfig && this.dynamicOptions === null) {
                    this.getServerSideData();
                }
            },
            deep: true
        },
        listItems(new_value) {
            this.list_items = new_value;
        },
        items_per_page() {

            this.options.itemsPerPage = this.items_per_page;
            this.options.page = 1;
            this.page = 1;
        },
        serverSideConfig() {
            if (this.serverSideConfig) {
                this.page = 1;
                this.getServerSideData();
            }
        },
        dynamicOptions() {
            if (this.dynamicOptions !== null && this.serverSideConfig) {
                Object.entries(this.dynamicOptions).forEach((dynamicOption) => {
                    let [option, value] = dynamicOption;
                    this.options[option] = value;
                });

                this.getServerSideData();
            }
        }
    },
    mounted() {
        if (this.fixedHeader && !this.height) {
            this.table_height = window.innerHeight - 95;
        }
        for (let header of this.headers) {
            header = this.checkSortColumn(header).all;
        }
        this.showHideHeaders();
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetDataTableServerSide"]),
        /**
         * Verifica quais colunas da tabela deve ser exibidas ou escondidas
         * @vue
         */
        showHideHeaders() {
            if (this.customColumns) {
                for (let header of this.headers) {
                    header.align = header.align.replace("d-none", "");

                    if (!this.containsHeader(header, this.columns_selected)) {
                        header.align += " d-none";
                    }
                }
            }
        },
        /**
         * Verifica se um item do tipo {@link DataTableHeaderClass} esta contido em um array de itens do tipo {@link DataTableHeaderClass}
         * @param {DataTableHeaderClass} item_header Objeto header a ser procurado na lista
         * @param {Array} list_header Lista de itens do tipo {@link DataTableHeaderClass} onde sera realizada a busca
         * @returns {Boolean} True caso o objeto esteja presente no array, do contrario, false
         * @vue
         */
        containsHeader(item_header, list_header) {
            for (let header of list_header) {
                if (item_header.value === header.value) {
                    return true;
                }
            }

            return false;
        },
        /**
         * Verifica se a coluna referente ao header indicado deve ter a classe referente a ordenacao
         * @param {DataTableHeaderClass} header Objeto header a ser verificado se esta com status de ordenacao
         * @returns {DataTableHeaderClass} Objeto header verificado e atualizado
         * @vue
         */
        checkSortColumn(header) {
            header.cellClass = header.cellClass.replace("sorted-column", "");

            if (this.options.sortBy !== undefined) {
                for (let sort of this.options.sortBy) {
                    if (sort === header.value) {
                        header.cellClass += " sorted-column";
                    }
                }
            }

            return header;
        },
        /**
         * Verifica os breakpoints de cada header e define a sua exibi��o na tela
         * @return  {Array}  Array de headers
         * @vue
         */
        getHeadersBreakpoint() {
            let new_headers = [];

            for (let header of this.headers) {
                switch (header.visibility) {
                case "xs":
                    if (this.$vuetify.breakpoint.width >= this.$vuetify.breakpoint.thresholds.xs) {
                        new_headers.push(header);
                    }
                    break;
                case "sm":
                    if (this.$vuetify.breakpoint.width >= this.$vuetify.breakpoint.thresholds.sm) {
                        new_headers.push(header);
                    }
                    break;
                case "md":
                    if (this.$vuetify.breakpoint.width >= this.$vuetify.breakpoint.thresholds.md) {
                        new_headers.push(header);
                    }
                    break;
                case "lg":
                    if (this.$vuetify.breakpoint.width >= this.$vuetify.breakpoint.thresholds.lg) {
                        new_headers.push(header);
                    }
                    break;
                case "xl":
                    if (this.$vuetify.breakpoint.width >= this.$vuetify.breakpoint.thresholds.xl) {
                        new_headers.push(header);
                    }
                    break;
                case "none":
                    break;
                default:
                    new_headers.push(header);
                    break;
                }
            }

            return new_headers;
        },
        /**
         * Busca os dados para apresentar na tabela a partir dos dados informados em server-side-config
         * @vue
         */
        getServerSideData() {
            this.loading_table = true;

            let request_data = {
                ...this.serverSideConfig,
                options: this.options
            };

            this.ActionDoGetDataTableServerSide(request_data).then(response => {
                /**
                 * Emite um evento "refreshServerSideData" informando que o conteudo da tabela foi alterado via server side
                 * 
                 * @property {Object} response Dados de retorno do servidor
                 * 
                 * @event refreshServerSideData
                 * @vue
                 */
                this.$emit("refreshServerSideData", response);
                this.list_items = response.data;
                this.total_items = response.recordsTotal;
                this.loading_table = false;
            });
        },
        selectAllItems(status) {
            if (status) {
                if (this.search !== null && this.search.length > 0) {
                    this.item_selected = this.list_items.filter((item) => {
                        for (let header of this.headers) {
                            if (header.filterable) {
                                let new_value = item[header.value].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                                let new_search = this.search.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                                if (new_value.toUpperCase().includes(new_search.toUpperCase())) {
                                    return true;
                                }
                            }
                        }

                        return false;
                    });
                } else {
                    this.item_selected = this.list_items;
                }

                this.all_selected = true;
            } else {
                this.item_selected = [];
                this.all_selected = false;
            }
        }
    },
    template: (
        /*html*/
        `
            <v-data-table
                ref="table_data"
                :headers="headers"
                :items="itemsTable"
                :item-key="itemKey"
                :options.sync="options"
                :loading="loading || loading_table"
                :dense="dense"
                :page.sync="page"
                :items-per-page.sync="items_per_page"
                :disable-pagination="!pagination"
                :search="search"
                :show-select="selectColumn"
                :single-select="singleSelect"
                v-model="item_selected"
                class="table-stripped elevation-0"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                hide-default-footer
                :mobile-breakpoint="0"
                :server-items-length="total_items"
                :height="table_height"
                :fixed-header="fixedHeader"
                loading-text="Carregando... Por favor aguarde"
                checkbox-color="dark-primary"
                :item-class="itemRowClass"
                :id="id"
            >
                <template v-slot:top="{options, pagination}">
                    <v-card v-if="alternativeHeader" class="rounded-0" elevation="3">
                        <v-row class="px-5 py-2 ma-0" justify="space-between" align="center">
                            <v-col v-if="filterable" :cols="6">
                                <p class="font-italic super-dark-grey--text font-size-12 text-left ma-0">
                                    {{ item_selected.length > 0 ? item_selected.length + ' ' + itemName + ' ' + labelSelectedItemName + ' de ' : '' }} {{ pagination.itemsLength }} {{ itemName }} dispon&iacute;veis
                                </p>
                                <p v-if="item_selected.length > 0 && !all_selected" class="font-italic super-dark-grey--text font-size-12 text-left ma-0">
                                    <a class="text-dark-primary" @click="selectAllItems(true)">Selecionar todos os {{ pagination.itemsLength }}</a>
                                </p>
                                <p v-if="item_selected.length > 0 && all_selected" class="font-italic super-dark-grey--text font-size-12 text-left ma-0">
                                    <a class="text-dark-primary" @click="selectAllItems(false)">Limpar sele&ccedil;&atilde;o</a>
                                </p>
                            </v-col>
                            <v-col v-if="filterable" :cols="6" v-bind:data-v-step="stepTutorialFilter">
                                <base-text-field
                                    :label="label_filter"
                                    v-model="search"
                                    :tooltip-text="tooltip_filter"
                                ></base-text-field>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-row v-else class="mb-2 tableInputsSection" justify="space-between">
                        <v-col cols="12" v-if="customColumns" md="auto" v-bind:data-v-step="stepTutorialCustomColumns">
                            <base-select
                                multiple
                                return-object
                                v-model="columns_selected"
                                title="Personalizar colunas"
                                :items="headers"
                                label="Selecione as colunas"
                                tooltip-text="Voc&ecirc; pode personalizar quais colunas deseja visualizar na tabela."
                            >
                                <template v-slot:content_slot="props">
                                    <span v-if="props.index === 0">{{ columns_selected.length }} itens selecionados</span>
                                </template>
                            </base-select>
                        </v-col>
                        <v-col
                            v-if="filterable"
                            :cols="showPageLength && customColumns ? 6 : ''"
                            class="d-none d-md-block mt-0"
                            v-bind:data-v-step="stepTutorialFilter"
                        >
                            <base-text-field
                                title="&nbsp;"
                                :label="label_filter"
                                v-model="search"
                                :tooltip-text="tooltip_filter"

                            ></base-text-field>
                        </v-col>
                        <v-col v-if="showPageLength && pagination" md="2" v-bind:data-v-step="stepTutorialPageLength">
                            <base-select
                                v-model="items_per_page"
                                title="Mostrar linhas"
                                :items="show_lines"
                                label="Mostrar linhas"
                                tooltip-text="Voc&ecirc; pode personalizar quantas linhas deseja exibir na tabela"
                            ></base-select>
                        </v-col>
                        <v-col 
                            v-if="filterable"
                            :cols="12"
                            class="d-md-none"
                            v-bind:data-v-step="stepTutorialFilter"
                        >
                            <base-text-field :label="label_filter" v-model="search" :tooltip-text="tooltip_filter"></base-text-field>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:progress="">
                    <v-progress-linear
                        indeterminate
                        color="dark-primary"
                    ></v-progress-linear>
                </template>
                ${headers_slots}
                <template v-slot:footer="{props}" v-if="pagination || showTotal">
                    <v-divider :class="dense ? 'my-1' : ''"></v-divider>
                    <v-row justify="space-between" class="no-gutters px-5 py-3" align="center">
                        <v-col :cols="pagination ? 'auto' : 12" class="text-center text-md-left" v-if="showTotal">
                            <span class="font-size-12 text-dark-grey">
                                Mostrando do {{ props.pagination.pageStart + 1}}&ordm; ao {{ props.pagination.pageStop }}&ordm; do total de {{ props.pagination.itemsLength }} registros
                            </span>
                        </v-col>
                        <v-col :cols="showTotal ? 'auto' : 12" class="d-flex justify-end" v-if="pagination">
                            <v-pagination
                                v-model="page"
                                :length="props.pagination.pageCount"
                                :total-visible="7"
                                color="dark-primary"
                            ></v-pagination>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                    <v-simple-checkbox
                        :value="isSelected"
                        @input="select(!isSelected)"
                        :disabled="ruleDisableCheckboxItem(item)"
                    ></v-simple-checkbox>
                </template>
                ${section_slots}
            </v-data-table>
        `)
};