import services from "../../../http/index";

/**
 * Retorna os telefones informados que estão na blacklist
 * @returns Object
 */
export const ActionDoGetBlacklistNumbers = (context, params) => {
    return services.api.request({
        url: "/blacklist/check-numbers",
        method: "post",
        data: params
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

export const ActionDoCreateCampaign = (context, params) => {
    return services.api.request({
        url: "/campaign",
        method: "post",
        data: params
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};