import FormReportCompletion from "../../reports/components/forms/FormReportCompletion";

export default {
    components: {
        FormReportCompletion
    },
    methods: {
        teste(data) {
            console.log(data);
        }
    },
    template: (
        /*html*/
        `
            <v-container>
                <v-row class="mt-10" justify="center">
                    <v-col cols="12" md="6">
                        <form-report-completion @change="teste"></form-report-completion>
                    </v-col>
                </v-row>
            </v-container>
        `
    )
};