import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";
import TutorialGetCoordinates from "../dialogs/TutorialGetCoordinates";

/**
 * Componente de mini formulario para adicionar suggestion de localizacao
 * @requires {@link BaseTextField}
 * @requires {@link BaseButton}
 * @requires {@link TutorialGetCoordinates}
 * @component
 * @displayName FormSuggestionLocalizacao
 * @category Page / Template RCS
 * @subcategory Forms
 * @vue
 * @author Pedro Augusto <pedro.ribas@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseButton,
        TutorialGetCoordinates
    },
    props: {
        /**
         * Conteudo a ser preenchido no formulario
         */
        formContent: {
            type: Object,
            required: false,
            default: null
        },
        /**
         * Lista de itens do tipo draggable para valida��o das regras
         */
        listValidation: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            etiqueta_rules: [
                v => !!v || window.Vue.htmlEntities("Voc&ecirc; precisa dar um nome para o bot&atilde;o"),
                v => !v || v.length <= 25 || window.Vue.htmlEntities("O bot&atilde;o n&atilde;o pode ter mais de 25 caracteres"),
                v => {
                    let valid = (typeof this.listValidation.find(element => element.text === v && element.id !== (this.formContent ? this.formContent.id : null)) == "undefined");
                    return !!valid || window.Vue.htmlEntities("J&aacute; existe um bot&atilde;o com esse nome") ;
                }
            ],
            latitude_longitude_rules: [
                v => !!v || window.Vue.htmlEntities("Voc&ecirc; precisa inserir as coordenadas"),
                v => {
                    if (v && v[0] == "{" && v[v.length - 1] == "}") {
                        return true;
                    } else {
                        return !v || /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(v) || window.Vue.htmlEntities("Coordenadas inv&aacute;lidas!");
                    }
                }
            ],
            etiqueta: this.formContent && this.formContent.etiqueta !== undefined ? this.formContent.etiqueta : null,
            latitude_longitude: this.formContent && this.formContent.latitude_longitude !== undefined ? this.formContent.latitude_longitude : null,
            open_tutorial: false
        };
    },
    computed: {
        isEmpty() {
            return !this.formContent || this.formContent.id === undefined || this.formContent.id == "";
        },
        showUnsetButtons() {
            return this.etiqueta && this.etiqueta != "" && this.latitude_longitude && this.latitude_longitude != "";
        },
        showCancelButton() {
            return this.formContent && this.formContent.id !== undefined && this.formContent.id != "" && !this.disable_button;
        },
        disable_button() {
            for (let rule of this.etiqueta_rules) {
                if (rule(this.etiqueta) !== true) {
                    return true;
                }
            }
            for (let rule of this.latitude_longitude_rules) {
                if (rule(this.latitude_longitude) !== true) {
                    return true;
                }
            }

            return false;
        }
    },
    watch: {
        formContent() {
            if (this.formContent) {
                if (this.formContent.etiqueta !== undefined) {
                    this.etiqueta = this.formContent.etiqueta;
                }
                if (this.formContent.latitude_longitude !== undefined) {
                    this.latitude_longitude = this.formContent.latitude_longitude;
                }
            }
        }
    },
    mounted() {
        if (this.formContent && this.formContent.etiqueta && this.formContent.latitude_longitude) {
            this.disable_button = false;
        }
    },
    methods: {
        /**
         * Emite um evento informando que um botao foi clicado
         * @vue
         */
        submitForm() {
            if (this.$refs.FormLocalizacao.validate()) {
                let formData = {
                    id: this.formContent && this.formContent.id ? this.formContent.id : null,
                    etiqueta: this.etiqueta,
                    latitude_longitude: this.latitude_longitude
                };

                /**
                 * Evento formSubmit do botao
                 * @property {object} formData Dados do campo {etiqueta,latitude_longitude}
                 * @event formSubmit
                 * @vue 
                 */
                this.$emit("formSubmit", formData);
                this.$refs.FormLocalizacao.reset();
            }
        },
        /**
         * Realiza a limpeza das informacoes no formulario
         * @vue
         */
        clearContent(delete_field = false) {
            this.etiqueta = null;
            this.latitude_longitude = null;
            this.$refs.FormLocalizacao.reset();

            /**
             * Informa que a edicao foi cancelada
             * @event cancelEdit
             * @vue
             */
            this.$emit("cancelEdit");

            if (delete_field) {
                /**
                 * Indica que um campo personalizado deve ser removido
                 * @property {Object} formContent Dados do campo a ser removido {id, name_field, number_characters}
                 * @event deleteItem
                 * @vue
                 */
                this.$emit("deleteItem", this.formContent);
            }
        }

    },
    template: (
        /*html*/
        `
        <v-form ref="FormLocalizacao">
            <v-row no-gutters>
                <v-col>
                    <span class="body-2 text-gray-700">A localiza&ccedil;&atilde;o informada ser&aacute; apresentada no aplicativo de mapa do destinat&aacute;rio</span>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col>
                    <base-text-field 
                        v-model="etiqueta" 
                        required
                        title="Etiqueta do bot&atilde;o"
                        :label="'Digite um nome para o bot&atilde;o' | htmlEntities"
                        :rules="etiqueta_rules"
                        tooltip-text="Este &eacute; o nome que ser&aacute; apresentado no bot&atilde;o."
                        :counter="25"
                        @enterPressed="submitForm"
                        @focus="$emit('focus')"
                        @blur="$emit('blur')"
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col>
                    <base-text-field
                        v-model="latitude_longitude"
                        required
                        title="Latitude,Longitude"
                        label="Ex.: -15.798687530237899, -47.861348280983314"
                        :rules="latitude_longitude_rules"
                        tooltip-text="Informe as coordenadas Latitude e Longitude do endere&ccedil;o desejado."
                        @enterPressed="submitForm"
                        @focus="$emit('focus')"
                        @blur="$emit('blur')"
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-2">
                <v-col cols="12" class="text-center">
                    <a class="body-2 text-decoration-none text-primary-blue-500" @click="open_tutorial = true">Como descobrir as coordenadas?</a>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col cols="12">
                    <base-button
                        primary
                        block
                        :label="isEmpty ? 'Adicionar bot&atilde;o' : 'Alterar bot&atilde;o'"
                        :icon="isEmpty ? 'mdi-plus-thick' : 'mdi-refresh'"
                        :disabled="disable_button"
                        @click="submitForm"
                    ></base-button>
                </v-col>
            </v-row>
            <v-row v-if="showUnsetButtons" no-gutters class="mt-4" :justify="showCancelButton ? 'space-around' : 'center'">
                <v-col cols="auto">
                    <base-button
                        tertiary
                        danger
                        :label="isEmpty ? 'Limpar' : 'Excluir bot&atilde;o'"
                        icon="mdi-trash-can"
                        width="auto"
                        @click="clearContent(showCancelButton)"
                    ></base-button>  
                </v-col>
                <v-col v-if="showCancelButton" cols="auto">
                    <base-button
                        tertiary
                        danger
                        :label="'Cancelar edi&ccedil;&atilde;o' | htmlEntities"
                        icon="mdi-close-thick"
                        width="auto"
                        @click="clearContent"
                    ></base-button>  
                </v-col>
            </v-row>
            <tutorial-get-coordinates v-model="open_tutorial"></tutorial-get-coordinates>
        </v-form>
        `
    )
};