export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Excluir-Enabled" transform="translate(5.667 -41)">
                    <rect id="Retângulo_360" data-name="Retângulo 360" width="28" height="28" transform="translate(-5.667 41)" fill="currentColor" opacity="0"/>
                    <g id="Grupo_1866" data-name="Grupo 1866" transform="translate(0.333 45)">
                        <path id="Caminho_517" data-name="Caminho 517" d="M15.4,4.5c-.1-.2-.3-.2-.5-.2H.9a.764.764,0,0,0-.5.2c-.3.1-.3.3-.3.5L1,17.6a2.506,2.506,0,0,0,2.5,2.3h8.9a2.506,2.506,0,0,0,2.5-2.3L15.6,5A.764.764,0,0,0,15.4,4.5Zm-2.2,13a.987.987,0,0,1-1,.9H3.4a1.063,1.063,0,0,1-1-.9L1.6,5.7H14Z" transform="translate(0 0)" fill="currentColor"/>
                        <path id="Caminho_518" data-name="Caminho 518" d="M7.8,17.9a.789.789,0,0,0,.8-.8V6.9a.789.789,0,0,0-.8-.8.709.709,0,0,0-.7.8V17.1A.713.713,0,0,0,7.8,17.9Z" transform="translate(0 0)" fill="currentColor"/>
                        <path id="Caminho_519" data-name="Caminho 519" d="M4.7,17.9h0a.789.789,0,0,0,.8-.8L4.9,6.9a.773.773,0,0,0-.8-.7.713.713,0,0,0-.7.8l.5,10.2A.773.773,0,0,0,4.7,17.9Z" transform="translate(0 0)" fill="currentColor"/>
                        <path id="Caminho_520" data-name="Caminho 520" d="M10.9,17.9h0a.773.773,0,0,0,.8-.7L12.2,7a.752.752,0,0,0-1.5-.1l-.5,10.2A.713.713,0,0,0,10.9,17.9Z" transform="translate(0 0)" fill="currentColor"/>
                        <path id="Caminho_521" data-name="Caminho 521" d="M.7,3.4H14.6a.773.773,0,0,0,.8-.7.773.773,0,0,0-.7-.8H10.4V.8a.764.764,0,0,0-.2-.5A.55.55,0,0,0,9.7,0H6a.684.684,0,0,0-.7.7V1.8H.8c-.5.1-.8.4-.8.8A.773.773,0,0,0,.7,3.4ZM6.8,1.5H8.9v.4H6.8Z" transform="translate(0 0)" fill="currentColor"/>
                    </g>
                </g>
            </svg>
      
        `
    )
};