import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "caixa-texto-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom xmlns="http://www.w3.org/2000/svg" width="13.5" height="9" viewBox="0 0 13.5 9">
                <path id="Path_1296" data-name="Path 1296" d="M12.375-9H1.125A1.125,1.125,0,0,0,0-7.875v6.75A1.125,1.125,0,0,0,1.125,0h11.25A1.125,1.125,0,0,0,13.5-1.125v-6.75A1.125,1.125,0,0,0,12.375-9Zm.188,7.875a.188.188,0,0,1-.187.188H1.125a.188.188,0,0,1-.187-.187v-6.75a.188.188,0,0,1,.188-.187h11.25a.188.188,0,0,1,.188.188ZM3.984-4.172v-.656A.281.281,0,0,0,3.7-5.109H3.047a.281.281,0,0,0-.281.281v.656a.281.281,0,0,0,.281.281H3.7A.281.281,0,0,0,3.984-4.172Zm2.25,0v-.656a.281.281,0,0,0-.281-.281H5.3a.281.281,0,0,0-.281.281v.656a.281.281,0,0,0,.281.281h.656A.281.281,0,0,0,6.234-4.172Zm2.25,0v-.656A.281.281,0,0,0,8.2-5.109H7.547a.281.281,0,0,0-.281.281v.656a.281.281,0,0,0,.281.281H8.2A.281.281,0,0,0,8.484-4.172Zm2.25,0v-.656a.281.281,0,0,0-.281-.281H9.8a.281.281,0,0,0-.281.281v.656a.281.281,0,0,0,.281.281h.656A.281.281,0,0,0,10.734-4.172ZM2.859-2.25v-.656a.281.281,0,0,0-.281-.281H1.922a.281.281,0,0,0-.281.281v.656a.281.281,0,0,0,.281.281h.656A.281.281,0,0,0,2.859-2.25Zm9,0v-.656a.281.281,0,0,0-.281-.281h-.656a.281.281,0,0,0-.281.281v.656a.281.281,0,0,0,.281.281h.656A.281.281,0,0,0,11.859-2.25Zm-9-3.844V-6.75a.281.281,0,0,0-.281-.281H1.922a.281.281,0,0,0-.281.281v.656a.281.281,0,0,0,.281.281h.656A.281.281,0,0,0,2.859-6.094Zm2.25,0V-6.75a.281.281,0,0,0-.281-.281H4.172a.281.281,0,0,0-.281.281v.656a.281.281,0,0,0,.281.281h.656A.281.281,0,0,0,5.109-6.094Zm2.25,0V-6.75a.281.281,0,0,0-.281-.281H6.422a.281.281,0,0,0-.281.281v.656a.281.281,0,0,0,.281.281h.656A.281.281,0,0,0,7.359-6.094Zm2.25,0V-6.75a.281.281,0,0,0-.281-.281H8.672a.281.281,0,0,0-.281.281v.656a.281.281,0,0,0,.281.281h.656A.281.281,0,0,0,9.609-6.094Zm2.25,0V-6.75a.281.281,0,0,0-.281-.281h-.656a.281.281,0,0,0-.281.281v.656a.281.281,0,0,0,.281.281h.656A.281.281,0,0,0,11.859-6.094Zm-2.3,3.7v-.375a.281.281,0,0,0-.281-.281H4.219a.281.281,0,0,0-.281.281v.375a.281.281,0,0,0,.281.281H9.281A.281.281,0,0,0,9.563-2.391Z" transform="translate(0 9)"/>
            </svg-custom>
        `
    )
};