import BaseNotify from "../../../components/atomos/BaseNotify?v=2";
import DialogRepeatReport from "./dialogs/DialogRepeatReport?v=2";
import BaseTooltip from "../../../components/atomos/BaseTooltip?v=2";

/**
 * Componente de repeticao de relatorio
 * 
 * @required {@link BaseNotify}
 * @required {@link DialogRepeatReport}
 * @required {@link BaseTooltip}
 * 
 * @displayName RepeatReport
 * @component
 * @vue
 * @category Page / Relatorios
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br>
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseNotify,
        DialogRepeatReport,
        BaseTooltip
    },
    props: {
        /**
         * Valor para  
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            selected: this.value,
            show_notify: false,
            show_repeat_dialog: false
        };
    },
    computed: {
        ...window.Vuex.mapState("reports", {
            generator_step_one: state => state.generator_step_one
        }),
        configColors() {
            return {
                card: this.selected ? "super-dark-primary" : "extra-light-primary",
                switch: this.selected ? "primary" : "super-dark-primary",
                switch_label: this.selected ? "text-white" : "text-super-dark-primary",
                link: this.selected ? "text-light-primary" : "text-dark-primary",
                text: this.selected ? "text-white" : "text-super-dark-grey",
                info: this.selected ? "text-extra-light-primary" : "text-dark-primary"
            };
        },
        configNotify() {
            return {
                title: this.selected ? window.Vue.htmlEntities("Repetir relat&oacute;rio foi habilitado com sucesso") : window.Vue.htmlEntities("Repetir relat&oacute;rio foi desabilitado com sucesso"),
                message: this.selected ? window.Vue.htmlEntities("Voc&ecirc; habilitou a automa&ccedil;&atilde;o do relat&oacute;rio, caso deseje extrair relat&oacute;rios de campanhas espec&iacute;ficas, desabilite a automa&ccedil;&atilde;o.") : window.Vue.htmlEntities("Voc&ecirc; desabilitou a automa&ccedil;&atilde;o do relat&oacute;rio. Esse relat&oacute;rio ser&aacute; gerado uma &uacute;nica vez.")
            };
        }
    },
    watch: {
        value() {
            this.selected = this.value;
        },
        selected() {
            /**
             * Evento que emite a a��o de selecionado ou n�o selecionado
             * 
             * @property {Boolean} input A��o de selecionado 
             * @event input
             * @vue
             */
            this.$emit("input", this.selected);

            if (!this.selected && this.selected !== this.generator_step_one.recurrence_report) {
                this.$emit("change", {});
                this.show_notify = true;
            } else {
                this.show_repeat_dialog = true;
            }
        }
    },
    methods: {
        /**
         * Emite os dados de configuracao de recorrencia de relatorio
         * @param {Object} data 
         * @vue
         */
        setRecurrence(data) {
            /**
             * Emite os dados escolhidos pelo usuario
             * 
             * @property {Object} data
             * @event change
             * @vue
             */
            this.$emit("change", data);
            this.show_notify = true;
        }
    },
    template: (
        /*html*/
        `
        <v-row no-gutters>
            <v-col cols="12">
                <base-tooltip text="Ao ligar o repetir relat&oacute;rio, esse relat&oacute;rio ser&aacute; automatizado.">
                    <v-card
                        elevation="0"
                        :color="configColors.card"
                        class="rounded-lg"
                        @click="selected = !selected"
                    >
                        <v-row class="pt-2" no-gutters>
                            <v-col cols="auto" class="px-5">
                                <v-img src="/images/reports/HandRepeatTime.svg" height="125"></v-img>
                            </v-col>
                            <v-col class="pr-5">
                                <v-row justify="space-between" no-gutters>
                                    <v-col cols="auto">
                                        <v-switch 
                                            v-model="selected"
                                            :color="configColors.switch"
                                            class="mt-0"
                                            hide-details
                                            inset
                                            readonly
                                        >
                                            <template #label>
                                                <span :class="configColors.switch_label + ' font-size-12'">Repetir relat&oacute;rio</span>
                                            </template>
                                        </v-switch>
                                    </v-col>
                                    <v-col cols="auto">
                                        <base-tooltip content-class="text-black border border-light-grey bg-white" text="Receba o relat&oacute;rio com dados atualizados de todas as campanhas rodadas dos clientes selecionados a cada nova data do per&iacute;odo selecionado. Ex: se o per&iacute;odo selecionado for 7 dias, um novo relat&oacute;rio com dados atualizados ser&aacute; disponibilizado em Meus relat&oacute;rios">
                                            <span :class="configColors.link + ' font-size-12'">O que &eacute; isso?</span>
                                        </base-tooltip>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0" no-gutters>
                                    <v-col cols="12">
                                        <p :class="configColors.text + ' font-size-11'">Habilite essa op&ccedil;&atilde;o para repetir o relat&oacute;rio no per&iacute;odo selecionado.</p>
                                        <p :class="configColors.info + ' font-size-10'">Se &ldquo;Repetir relat&oacute;rio" estiver ligado, n&atilde;o ser&aacute; poss&iacute;vel selecionar campanhas espec&iacute;ficas.</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </base-tooltip>
                <base-notify
                    v-model="show_notify"
                    :title="configNotify.title"
                    :message="configNotify.message"
                    type="success"
                ></base-notify>
                <dialog-repeat-report
                    v-model="show_repeat_dialog"
                    @confirm="setRecurrence"
                    @close="selected = true"
                ></dialog-repeat-report>
            </v-col>
        </v-row>
        `
    )
};