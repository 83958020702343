import FormSuggestionGetLocation from "../../template-rcs/components/forms/FormSuggestionGetLocation";

export default {
    components: {
        FormSuggestionGetLocation
    },
    methods: {
        formSubmit(data) {
            alert(`Dados emitidos: \n Etiqueta: ${data.etiqueta}`);
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="12" md="3">
                    <form-suggestion-get-location @formSubmit="formSubmit"></form-suggestion-get-location>
                </v-col>
            </v-row>
        `
    )
};