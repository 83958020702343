import BoxFormManagerUsage from "./BoxFormManagerUsage";
import ResetPasswordUsage from "./ResetPasswordUsage";

export default [
    {
        path: "box-form-manager-usage",
        name: "Box form manager usage",
        component: BoxFormManagerUsage
    },
    {
        path: "reset-password-usage",
        name: "Reset password usage",
        component: ResetPasswordUsage
    }
];