import BaseBoxForm from "../../components/atomos/BaseBoxForm";
import FormResetPassword from "./components/FormResetPassword";
import TwoLogoTop from "../../components/moleculas/TwoLogoTop";
/**
 * Componente gerenciador do box de login para mudar a senha
 * 
 * @component
 * @category Page / Auth
 * @requires {@link base-box-form.html|BaseBoxForm}
 * @requires {@link form-reset-password.html|FormResetPassword}
 * @requires {@link two-logo-top.html|TwoLogoTop}
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 */
export default {
    components: {
        BaseBoxForm,
        FormResetPassword,
        TwoLogoTop
    },
    name: "reset-password",

    data: function () {
        return {
            form_recovery_pass: false,
            error_login: "",
            error_lost_pass: "",
            success_lost_pass: "",
            progress_val: 0,
            indeterminate: false,
            show: true,
            interval: 0,
            show_progress: false,
            disable_button: false,
            success_message: "",
            error_message: ""
        };
    },
    methods: {
        ...window.Vuex.mapActions("auth", ["resetPassword"]),
        /**
         * Funcao que sera vinculada ao componente FormResetPassword 
         * @param {object} data 
         * @vue
         */
        componentFunction(data) {
            let value = {
                id_cas: this.$route.query.id,
                token: this.$route.query.token,
                password: data.password_first_step,
                password_confirmation: data.password_second_step
            };
            this.initProgressBar();
            this.error_login = "";
            this.disable_button = true;
            this.resetPassword(value).then(() => {
                this.finishProgressBar();
                this.success_message = "Senha alterada com sucesso!";

                this.$router.push({name: "auth"});

            }).catch(() => {
                this.finishProgressBar();
                this.error_message = "Erro ao alterar a senha!";
                this.disable_button = false;
            });
        },
        /**
         * Faz a inicializacao da barra de progresso ao realizar a requisicaoo no formulario
         * @vue
         */
        initProgressBar() {
            this.disable_button = true;
            this.show_progress = true;
            this.indeterminate = true;
            this.progress_val = 0;

            setTimeout(() => {
                this.progress_val = 10;
                this.indeterminate = false;
                setTimeout(() => {
                    this.progress_val = 75;
                    this.indeterminate = false;
                }, 500);
            }, 1000);
        },
        /**
         * Finaliza a barra de progresso ao finalizar uma requisicao
         * @vue
         */
        finishProgressBar() {
            this.progress_val = 100;

            setTimeout(() => {
                this.disable_button = false;
                this.show_progress = false;
            }, 100);
        },
    },
    template: (
        /*html*/
        `
        <v-row class="fill-height">                
            <v-col cols="12 p-0" class="pl-0 pr-0">                    
                <v-img src="/images/capa-login-connect-mais2.svg" height="100vh" position="right center">
                    <v-row>
                        <two-logo-top :logo_connect_mais_branca="true" logo_pg_color="white"/>
                    </v-row>
                    <v-row justify="center" align="center" class="fill-height">
                        <v-col cols="7" md="5" lg="3">
                            <base-box-form>
                                <form-reset-password
                                    :error_message="error_message"
                                    :success_message="success_message"
                                    :disable_button="disable_button"
                                    @submitChangePassword="componentFunction" />
                                <v-progress-linear
                                    v-model="progress_val"
                                    :active="show_progress"
                                    :indeterminate="indeterminate"
                                    absolute
                                    bottom
                                    color="dark-primary"
                                    height="7"
                                ></v-progress-linear>
                            </base-box-form>
                        </v-col>
                    </v-row>
                </v-img>
            </v-col>
        </v-row>
        `
    )
};