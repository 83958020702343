import NewWindow from "../../../../components/organismos/NewWindow";

/**
 * Componente de tutorial sobre como obter as coordenadas
 *
 * @displayName TutorialGetCoordinates
 * @requires {@link NewWindow}
 * @component
 * @category Page / Template RCS
 * @subcategory dialogs
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 */
export default {
    components: {
        NewWindow
    },
    props: {
        /**
         * Indica se o tutorial deve ser exibido ou nao
         */
        open: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    model: {
        prop: "open",
        event: "close"
    },
    watch: {
        open() {
            if (!this.open) {
                this.$emit("close");
            }
        }
    },
    data() {
        return {
            host: window.location.origin
        };
    },
    template: (
        /*html*/
        `
            <new-window v-model="open">
                <v-app class="bg-gray-0">
                    <v-container>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <span class="subtitle-1 text-gray-900">Como descobrir as coordenadas?</span>
                            </v-col>
                            <v-col cols="12" class="mt-6">
                                <span class="subtitle-3 text-gray-900">Passo 1</span>
                            </v-col>
                            <v-col cols="12" class="mt-2">
                                <span class="body-2 text-gray-900">Entre no maps.google.com em seu computador</span>
                            </v-col>
                            <v-col cols="12" class="mt-2">
                                <v-img contain :src="host + '/images/tutorial-get-coordinates/Passo1.png'" width="520"></v-img>
                            </v-col>
                            <v-col cols="12" class="mt-6">
                                <span class="subtitle-3 text-gray-900">Passo 2</span>
                            </v-col>
                            <v-col cols="12" class="mt-2">
                                <span class="body-2 text-gray-900">Pesquise o endere&ccedil;o desejado</span>
                            </v-col>
                            <v-col cols="12" class="mt-2">
                                <v-img contain :src="host + '/images/tutorial-get-coordinates/Passo2.png'" width="520"></v-img>
                            </v-col>
                            <v-col cols="12" class="mt-6">
                                <span class="subtitle-3 text-gray-900">Passo 3</span>
                            </v-col>
                            <v-col cols="12" class="mt-2">
                                <span class="body-2 text-gray-900">Clique com o bot&atilde;o direito sobre o ponteiro vermelho e copie as coordenadas que ir&atilde;o aparecer no menu de contexto.</span>
                            </v-col>
                            <v-col cols="12" class="mt-2">
                                <v-img contain :src="host + '/images/tutorial-get-coordinates/Passo3.png'" width="384"></v-img>
                            </v-col>
                            <v-col cols="12" class="mt-6">
                                <span class="body-2 text-gray-900">Agora &eacute; s&oacute; colar as coordenadas em seu novo bot&atilde;o de localiza&ccedil;&atilde;o :)</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-app>
            </new-window>
        `
    )
};