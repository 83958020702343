import BaseCardSlider from "../../../components/atomos/BaseCardSlider";

export default {
    name: "base-card-slider-usage",
    components: {
        BaseCardSlider
    },
    data() {
        return {
            image: "/images/cadastro-template/Txt_Onboarding1.png",
            title: "O c&eacute;u &eacute; o limite",
            text: "Crie templates de texto com limite de at&eacute; 1000 caracteres.",
            vertical: false
        };
    },
    template: (
        /*html*/
        `
            <v-card class="bg-super-light-grey py-5 fill-height">
                <v-row justify="center" class="mb-5">
                    <v-col cols="12" sm="10" md="6">
                        <v-card class="px-5">
                            <v-row>
                                <v-col cols="12">
                                    <h6>
                                        <b>Dados para testar o componente</b>
                                    </h6>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-text-field label="URL ou caminho da image" v-model="image"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field label="Titulo" v-model="title"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-checkbox label="Conteudo na vertical" v-model="vertical"></v-checkbox>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea auto-grow rows="1" label="Texto" v-model="text"></v-textarea>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row justify="center" class="ma-0">
                    <v-col cols="12" sm="10" md="3">
                        <base-card-slider
                            :image="image"
                            :title="title"
                            :text="text"
                            :vertical="vertical"
                        ></base-card-slider>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};