import FormSuggestionLocalizacao from "../../template-rcs/components/forms/FormSuggestionLocalizacao";

export default {
    components: {
        FormSuggestionLocalizacao
    },
    methods: {
        formSubmit(data) {
            console.log(data);
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="12" md="2">
                    <form-suggestion-localizacao 
                            @formSubmit="formSubmit">
                    </form-suggestion-localizacao>
                </v-col>
            </v-row>
        `
    )
};