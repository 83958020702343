import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";

/**
 * Componente de formulario para adicionar suggestions de telefone no cadastro de templates rcs
 * 
 * @displayName FormSuggestionTelefone
 * @component
 * @category Page / Template RCS
 * @subcategory Forms
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseButton
    },
    props: {
        /**
         * Conteudo a ser preenchido no formulario
         */
        formContent: {
            type: Object,
            required: false,
            default: null
        },
        /**
         * Lista de itens do tipo draggable para validacao das regras
         */
        listValidation: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            rules: {
                etiqueta: [
                    v => !!v || window.Vue.htmlEntities("Voc&ecirc; precisa dar um nome para o bot&atilde;o"),
                    v => !v || v.length <= 25 || window.Vue.htmlEntities("O bot&atilde;o n&atilde;o pode ter mais de 25 caracteres"),
                    v => {
                        let valid = (typeof this.listValidation.find(element => element.text === v && element.id !== (this.formContent ? this.formContent.id : null)) == "undefined");
                        return !!valid || window.Vue.htmlEntities("J&aacute; existe um bot&atilde;o com esse nome") ;
                    }
                ],
                telefone: [
                    v => !!v || window.Vue.htmlEntities("Voc&ecirc; precisa inserir um n&uacute;mero de telefone"),
                    v => !v || Number.isInteger(parseInt(v.match(/\d/g).join(""))) || window.Vue.htmlEntities("Informe um telefone v&aacute;lido"),
                    v => !v || (v.length === 9 && v[0] !== "9")
                            || (/^\d{10,14}$/.test(v))
                            || (/^(\+\d{1,3}\s?)400[24]-?\d{4}$/.test(v)) && (v.length <= 14) && (v.length >= 9)
                            || (/^(\+\d{1,3}\s?)?\(\d{2}\)\s?\d{4,5}-?\d{4}$/.test(v))
                            || (/^(\d{5})$/.test(v))
                            || window.Vue.htmlEntities("Informe um telefone v&aacute;lido")
                ]
            },
            etiqueta: this.formContent && this.formContent.etiqueta !== undefined ? this.formContent.etiqueta : null,
            telefone: this.formContent && this.formContent.telefone !== undefined ? this.formContent.telefone : null,
        };
    },
    computed: {
        /**
         * Retorna a mascara de telefone para aplicar ao campo de acordo com a quantidade de caracteres inseridos
         * 
         * @returns {String} Mascara de telefone
         * @vue
         */
        maskPhone() {
            if (this.telefone != null) {
                const telefoneLimpo = this.telefone.replace(/[^0-9]/g, "");
                const regex0800 = /^0800/;
                const regexCelular = /^(\d{2})(\d{5})(\d{4})$/;
                const regexFixo = /^(\d{2})(\d{4})(\d{4})$/;
                const initNumber = /^9/;
                const init400X = /^(\d{1,3})400[24]/;
                const shortNumber = /^(\d{5})$/;
                if (regex0800.test(telefoneLimpo)) {
                    return telefoneLimpo;
                } else if ((telefoneLimpo.length <= 11) && (telefoneLimpo.length >= 8) && init400X.test(telefoneLimpo)) {
                    return telefoneLimpo.replace(/^(\d{1,3})(\d{4})(\d{4})$/, "+$1 $2-$3");
                } else if (regexCelular.test(telefoneLimpo)) {
                    return telefoneLimpo.replace(regexCelular, "($1) $2-$3");
                } else if ((telefoneLimpo.length === 10) || (regexFixo.test(telefoneLimpo))) {
                    return telefoneLimpo.replace(regexFixo, "($1) $2-$3");
                } else if (telefoneLimpo.length === 8 && (!(initNumber).test(telefoneLimpo))) {
                    return telefoneLimpo.replace(/^(\d{4})(\d{4})$/, "$1-$2");
                } else if (telefoneLimpo.length === 8) {
                    return telefoneLimpo.replace(/^(\d{4})(\d{4})$/, "$1-$2");
                } else if ((telefoneLimpo.length > 11) && (!(init400X).test(telefoneLimpo))) {
                    return telefoneLimpo.replace(/^(\d{1,3})(\d{2})(\d{5})(\d{4})$/, "+$1 ($2) $3-$4");
                } else if ((shortNumber.length === 5)) {
                    return shortNumber.replace(/^(\d{5})$/, "$1");
                } else {
                    return null;
                }
            } else {
                return null;
            }
        },
        isEmpty() {
            return !this.formContent || this.formContent.id === undefined || this.formContent.id == "";
        },
        showUnsetButtons() {
            return this.etiqueta && this.etiqueta != "" && this.telefone && this.telefone != "";
        },
        showCancelButton() {
            return this.formContent && this.formContent.id !== undefined && this.formContent.id != "" && !this.disable_button;
        },
        disable_button() {
            for (let rule of this.rules.etiqueta) {
                if (rule(this.etiqueta) !== true) {
                    return true;
                }
            }
            for (let rule of this.rules.telefone) {
                if (rule(this.telefone) !== true) {
                    return true;
                }
            }

            return false;
        }
    },
    watch: {
        formContent() {
            if (this.formContent) {
                if (this.formContent.etiqueta !== undefined) {
                    this.etiqueta = this.formContent.etiqueta;
                }
                if (this.formContent.telefone !== undefined) {
                    this.telefone = this.formContent.telefone;
                }
            }
        }
    },
    mounted() {
        if (this.formContent && this.formContent.etiqueta && this.formContent.telefone) {
            this.disable_button = false;
        }
    },
    methods: {
        /**
         * Metodo chamado ao clicar no botao de acao, o formulario sera validado e caso seja valido emite as informacoes
         * 
         * @vue
         */
        submitForm() {
            if (this.$refs.formTelefone.validate()) {
                let data = {
                    id: this.formContent && this.formContent.id ? this.formContent.id : null,
                    etiqueta: this.etiqueta,
                    telefone: this.telefone
                };

                /**
                 * Evento de submit do formulario, dispara os dados preenchido em etiqueta e telefone
                 * 
                 * @property {Object} data Dados do formulario {etiqueta: String, telefone: String}
                 * 
                 * @event formSubmit
                 * @vue
                 */
                this.$emit("formSubmit", data);
                this.$refs.formTelefone.reset();
            }
        },
        /**
         * Realiza a limpeza das informacoes no formulario
         * @vue
         */
        clearContent(delete_field = false) {
            this.etiqueta = null;
            this.telefone = null;
            this.$refs.formTelefone.reset();

            /**
             * Informa que a edicao foi cancelada
             * @event cancelEdit
             * @vue
             */
            this.$emit("cancelEdit");

            if (delete_field) {
                /**
                 * Indica que um campo personalizado deve ser removido
                 * @property {Object} formContent Dados do campo a ser removido {id, name_field, number_characters}
                 * @event deleteItem
                 * @vue
                 */
                this.$emit("deleteItem", this.formContent);
            }
        }
    },
    template: (
        /*html*/
        `
        <v-form ref="formTelefone">
            <v-row no-gutters>
                <v-col>
                    <span class="body-2 text-gray-700">O telefone informado ser&aacute; enviado ao aplicativo de liga&ccedil;&atilde;o do destinat&aacute;rio</span>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col>
                    <base-text-field
                        v-model="etiqueta"
                        required
                        title="Etiqueta do bot&atilde;o"
                        :label="'Digite um nome para o bot&atilde;o' | htmlEntities"
                        :rules="rules.etiqueta"
                        tooltip-text="Este &eacute; o nome que ser&aacute; apresentado no bot&atilde;o."
                        :counter="25"
                        @enterPressed="submitForm"
                        @focus="$emit('focus')"
                        @blur="$emit('blur')"
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col>
                    <base-text-field
                        v-model="telefone"
                        v-mask="maskPhone"
                        required
                        title="Telefone"
                        label="_____-____"
                        type="tel"
                        maxLength="20"
                        :rules="rules.telefone"
                        tooltip-text="Este &eacute; o telefone de contato que ser&aacute; acionado ao clicar no bot&atilde;o"
                        @enterPressed="submitForm"
                        @focus="$emit('focus')"
                        @blur="$emit('blur')"
                        :counter="20" 
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col cols="12">
                    <base-button
                        primary
                        block
                        :label="isEmpty ? 'Adicionar bot&atilde;o' : 'Alterar bot&atilde;o'"
                        :icon="isEmpty ? 'mdi-plus-thick' : 'mdi-refresh'"
                        :disabled="disable_button"
                        @click="submitForm"
                    ></base-button>
                </v-col>
            </v-row>
            <v-row v-if="showUnsetButtons" no-gutters class="mt-4" :justify="showCancelButton ? 'space-around' : 'center'">
                <v-col cols="auto">
                    <base-button
                        tertiary
                        danger
                        :label="isEmpty ? 'Limpar' : 'Excluir bot&atilde;o'"
                        icon="mdi-trash-can"
                        width="auto"
                        @click="clearContent(showCancelButton)"
                    ></base-button>  
                </v-col>
                <v-col v-if="showCancelButton" cols="auto">
                    <base-button
                        tertiary
                        danger
                        :label="'Cancelar edi&ccedil;&atilde;o' | htmlEntities"
                        icon="mdi-close-thick"
                        width="auto"
                        @click="clearContent"
                    ></base-button>  
                </v-col>
            </v-row>
        </v-form>
        `
    )
};