import FormSuggestionGetLocation from "./forms/FormSuggestionGetLocation?v=2";
import FormSuggestionLink from "./forms/FormSuggestionLink?v=2";
import FormSuggestionLocalizacao from "./forms/FormSuggestionLocalizacao?v=2";
import FormSuggestionTelefone from "./forms/FormSuggestionTelefone?v=2";
import GroupPills from "../../../components/moleculas/GroupPills?v=2";
import SectionCard from "../../../components/moleculas/SectionCard?v=2";
import BaseRadioButtons from "../../../components/moleculas/BaseRadioButtons?v=2";
import { BasePillClass } from "../../../classes/BasePillClass?v=2";
import { DraggableItemClass } from "../../../classes/DraggableItemClass?v=2";

/**
 * Componente para adicionar botoes de sugestao no template
 * @requires {@link FormSuggestionGetLocation}
 * @requires {@link FormSuggestionLink}
 * @requires {@link FormSuggestionLocalizacao}
 * @requires {@link FormSuggestionTelefone}
 * @requires {@link GroupPills}
 * @requires {@link SectionCard}
 * @requires {@link BaseRadioButtons}
 * @requires {@link BasePillClass}
 * @component
 * @displayName SuggestionButtons
 * @category Page / Template RCS
 * @vue
 * @author David Nunes dos Santos Andrade <david.santos@pgmais.com.br>
 */
export default {
    components: {
        FormSuggestionGetLocation,
        FormSuggestionLink,
        FormSuggestionLocalizacao,
        FormSuggestionTelefone,
        GroupPills,
        SectionCard,
        BaseRadioButtons
    },
    props: {
        value: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            list_buttons: _.cloneDeep(this.value),
            group_name: "groupButtons",
            list_card_short: [
                {
                    id: "link",
                    active: true,
                    icon: "fa-globe-americas",
                    label: "Link",
                    disabled: false,
                    soon: false,
                    vertical: false,
                    tooltip: "Crie um bot&atilde;o que direcione o destinat&aacute;rio a uma p&aacute;gina de internet"
                },
                {
                    id: "phone",
                    icon: "fa-phone-alt",
                    label: "Telefone",
                    active: false,
                    disabled: false,
                    soon: false,
                    vertical: false,
                    tooltip: "Adicione um bot&atilde;o de telefone para que o destinat&aacute;rio te ligue com apenas um clique"
                },
                {
                    id: "map",
                    icon: "fa-map-marker-alt",
                    label: window.Vue.htmlEntities("Localiza&ccedil;&atilde;o"),
                    active: false,
                    disabled: false,
                    soon: false,
                    vertical: false,
                    tooltip: "Insira coordenadas para que o destinat&aacute;rio te encontre pelo aplicativo de mapa"
                },
                {
                    id: "localization",
                    icon: "fa-crosshairs",
                    label: window.Vue.htmlEntities("Solicitar localiza&ccedil;&atilde;o"),
                    active: false,
                    disabled: false,
                    soon: false,
                    vertical: false,
                    cols: "auto",
                    tooltip: "Solicite que o destinat&aacute;rio lhe envie a pr&oacute;pria localiza&ccedil;&atilde;o"
                }
            ],
            data_form_link: null,
            data_form_phone: null,
            data_form_map: null,
            data_form_localization: null,
            focus_form: false
        };
    },
    computed: {
        cardSelected() {
            for (let card of this.list_card_short) {
                if (card.active) {
                    return card;
                }
            }

            return null;
        }
    },
    watch: {
        value() {
            this.list_buttons = _.cloneDeep(this.value);
        }
    },
    methods: {
        /**
         * Emite um evento informando que uma notificacao deve ser exibida
         * @param {Object} data 
         */
        showNotify(data) {
            this.$emit("showNotify", data);
        },
        /**
         * Funcao para remover do array list_buttons
         * @param {DraggableItemClass} item 
         * @vue
         */
        removeCustom(item) {
            this.showNotify({
                notify_title: "Bot&atilde;o exclu&iacute;do",
                notify_message: `O bot&atilde;o <b>${item.pill.title}</b> foi exclu&iacute;do.`,
                removed_pill: item,
                position_removed: null,
                notify_button_label: "Restaurar bot&atilde;o",
                restore_type: "delete"
            });

            this.list_buttons = this.list_buttons.filter((custom_item) => {
                return custom_item.id !== item.id;
            });

            /**
             * Indica que um item foi removido da lista
             * @param {DraggableItemClass} item Dados do item removido
             * @event removeItem
             * @vue
             */
            this.$emit("removeItem", item);
            this.$emit("input", this.list_buttons);
            this.data_form_link = null;
            this.data_form_phone = null;
            this.data_form_map = null;
            this.data_form_localization = null;
            this.focus_form = false;
        },
        /**
         * Metodo chamado ao clicar em alguma pill
         * @param {DraggableItemClass} item Dados de um item
         * @vue
         */
        itemClick(item) {
            this.showNotify({
                notify_title: "Bot&atilde;o adicionado",
                notify_message: `O bot&atilde;o <b>${item.pill.title}</b> foi adicionado &agrave; mensagem`,
                removed_pill: null,
                position_removed: null,
                notify_button_label: null,
                restore_type: null
            });

            /**
             * Evento que emite o item clicado
             * 
             * @property {DraggableItemClass} item Dados de um item
             * 
             * @event itemClick
             * @vue
             */
            this.$emit("itemClick", item);
        },
        /**
         * Faz a edicao de um suggestion ja adicionado
         * @param {DraggableItemClass} item Dados de um item a ser editado
         * @vue
         */
        editItem(item) {
            this.focus_form = true;
            this.data_form_link = null;
            this.data_form_phone = null;
            this.data_form_map = null;
            this.data_form_localization = null;

            for (let card of this.list_card_short) {
                if (card.id !== item.pill.type) {
                    card.active = false;
                } else {
                    card.active = true;
                }
            }

            for (let field of this.list_buttons) {
                if (field.id === item.id) {
                    field.pill.selected = true;

                    switch (item.pill.type) {
                    case "link":
                        this.data_form_link = {
                            id: item.id,
                            etiqueta: item.pill.title,
                            link: item.pill.subTitle
                        };
                        break;
                    case "phone":
                        this.data_form_phone = {
                            id: item.id,
                            etiqueta: item.pill.title,
                            telefone: item.pill.subTitle
                        };
                        break;
                    case "map":
                        this.data_form_map = {
                            id: item.id,
                            etiqueta: item.pill.title,
                            latitude_longitude: item.pill.subTitle
                        };
                        break;
                    case "localization":
                        this.data_form_localization = {
                            id: item.id,
                            etiqueta: item.pill.title
                        };
                        break;
                    }
                } else {
                    field.pill.selected = false;
                }
            }
        },
        /**
         * Adiciona um novo suggestion do tipo link
         * @param {Object} data Dados do formulario
         * @vue
         */
        createSuggestionLink(data) {
            if (data.id) {
                for (let field of this.list_buttons) {
                    if (field.id === data.id) {
                        field.pill.title = data.etiqueta;
                        field.pill.subTitle = data.link;
                        field.pill.selected = false;
                        field.text = data.etiqueta;

                        /**
                         * Informa que um item foi editado
                         * @property {DraggableItemClass} field Dados do item
                         * 
                         * @event editItem
                         * @vue
                         */
                        this.$emit("editItem", field);
                        break;
                    }
                }
            } else {
                let new_item = new DraggableItemClass({
                    text: data.etiqueta,
                    type: "pill",
                    custom: true,
                    pill: new BasePillClass({
                        type: "link",
                        deletable: true,
                        editable: true,
                        prependIcon: "fa-grip-vertical",
                        title: data.etiqueta,
                        titleIcon: "fa-globe-americas",
                        subTitle: data.link,
                        draggable: true,
                        subTitleNewLine: true
                    })
                });

                this.showNotify({
                    notify_title: "Bot&atilde;o criado",
                    notify_message: `O bot&atilde;o <b>${data.etiqueta}</b> foi criado e adicionado &agrave; mensagem`,
                    removed_pill: null,
                    position_removed: null,
                    notify_button_label: null,
                    restore_type: null
                });

                this.list_buttons.push(new_item);
                this.itemClick(new_item);
            }

            this.data_form_link = null;
            this.data_form_phone = null;
            this.data_form_map = null;
            this.data_form_localization = null;
            this.$emit("input", this.list_buttons);
        },
        /**
         * Adiciona um novo suggestion do tipo telefone
         * @param {Object} data Dados do formulario
         * @vue
         */
        createSuggestionPhone(data) {
            if (data.id) {
                for (let field of this.list_buttons) {
                    if (field.id === data.id) {
                        field.pill.title = data.etiqueta;
                        field.pill.subTitle = data.telefone;
                        field.pill.selected = false;
                        field.text = data.etiqueta;

                        /**
                         * Informa que um item foi editado
                         * @property {DraggableItemClass} field Dados do item
                         * 
                         * @event editItem
                         * @vue
                         */
                        this.$emit("editItem", field);
                        break;
                    }
                }
            } else {
                let new_item = new DraggableItemClass({
                    text: data.etiqueta,
                    type: "pill",
                    custom: true,
                    pill: new BasePillClass({
                        type: "phone",
                        deletable: true,
                        editable: true,
                        prependIcon: "fa-grip-vertical",
                        title: data.etiqueta,
                        titleIcon: "fa-phone-alt",
                        subTitle: data.telefone,
                        draggable: true,
                        subTitleNewLine: true
                    })
                });

                this.showNotify({
                    notify_title: "Bot&atilde;o criado",
                    notify_message: `O bot&atilde;o <b>${data.etiqueta}</b> foi criado e adicionado &agrave; mensagem`,
                    removed_pill: null,
                    position_removed: null,
                    notify_button_label: null,
                    restore_type: null
                });

                this.list_buttons.push(new_item);
                this.itemClick(new_item);
            }

            this.data_form_link = null;
            this.data_form_phone = null;
            this.data_form_map = null;
            this.data_form_localization = null;
            this.$emit("input", this.list_buttons);
        },
        /**
         * Adiciona um novo suggestion do tipo localizacao
         * @param {Object} data Dados do formulario
         * @vue
         */
        createSuggestionMap(data) {
            if (data.id) {
                for (let field of this.list_buttons) {
                    if (field.id === data.id) {
                        field.pill.title = data.etiqueta;
                        field.pill.subTitle = data.latitude_longitude;
                        field.pill.selected = false;
                        field.text = data.etiqueta;

                        /**
                         * Informa que um item foi editado
                         * @property {DraggableItemClass} field Dados do item
                         * 
                         * @event editItem
                         * @vue
                         */
                        this.$emit("editItem", field);
                        break;
                    }
                }
            } else {
                let new_item = new DraggableItemClass({
                    text: data.etiqueta,
                    type: "pill",
                    custom: true,
                    pill: new BasePillClass({
                        type: "map",
                        deletable: true,
                        editable: true,
                        prependIcon: "fa-grip-vertical",
                        title: data.etiqueta,
                        titleIcon: "fa-map-marker-alt",
                        subTitle: data.latitude_longitude,
                        draggable: true,
                        subTitleNewLine: true
                    })
                });

                this.showNotify({
                    notify_title: "Bot&atilde;o criado",
                    notify_message: `O bot&atilde;o <b>${data.etiqueta}</b> foi criado e adicionado &agrave; mensagem`,
                    removed_pill: null,
                    position_removed: null,
                    notify_button_label: null,
                    restore_type: null
                });

                this.list_buttons.push(new_item);
                this.itemClick(new_item);
            }

            this.data_form_link = null;
            this.data_form_phone = null;
            this.data_form_map = null;
            this.data_form_localization = null;
            this.$emit("input", this.list_buttons);
        },
        /**
         * Adiciona um novo suggestion do tipo solicitar localizacao
         * @param {Object} data Dados do formulario
         * @vue
         */
        createSuggestionLocalization(data) {
            if (data.id) {
                for (let field of this.list_buttons) {
                    if (field.id === data.id) {
                        field.pill.title = data.etiqueta;
                        field.pill.subTitle = "";
                        field.pill.selected = false;
                        field.text = data.etiqueta;

                        /**
                         * Informa que um item foi editado
                         * @property {DraggableItemClass} field Dados do item
                         * 
                         * @event editItem
                         * @vue
                         */
                        this.$emit("editItem", field);
                        break;
                    }
                }
            } else {
                let new_item = new DraggableItemClass({
                    text: data.etiqueta,
                    type: "pill",
                    custom: true,
                    pill: new BasePillClass({
                        type: "localization",
                        deletable: true,
                        editable: true,
                        prependIcon: "fa-grip-vertical",
                        title: data.etiqueta,
                        titleIcon: "fa-crosshairs",
                        subTitle: "",
                        draggable: true,
                        subTitleNewLine: true
                    })
                });

                this.showNotify({
                    notify_title: "Bot&atilde;o criado",
                    notify_message: `O bot&atilde;o <b>${data.etiqueta}</b> foi criado e adicionado &agrave; mensagem`,
                    removed_pill: null,
                    position_removed: null,
                    notify_button_label: null,
                    restore_type: null
                });

                this.list_buttons.push(new_item);
                this.itemClick(new_item);
            }

            this.data_form_link = null;
            this.data_form_phone = null;
            this.data_form_map = null;
            this.data_form_localization = null;
            this.$emit("input", this.list_buttons);
        },
        /**
         * Realiza o cancelamento da edicao de um item
         */
        cancelEdit() {
            for (let item of this.list_buttons) {
                if (this.data_form_link && this.data_form_link.id === item.id) {
                    item.pill.selected = false;
                }
                if (this.data_form_phone && this.data_form_phone.id === item.id) {
                    item.pill.selected = false;
                }
                if (this.data_form_map && this.data_form_map.id === item.id) {
                    item.pill.selected = false;
                }
                if (this.data_form_localization && this.data_form_localization.id === item.id) {
                    item.pill.selected = false;
                }
            }

            this.data_form_link = null;
            this.data_form_phone = null;
            this.data_form_map = null;
            this.data_form_localization = null;
        },
        /**
         * Utilizado para monitoar a alteracao do card selecionado
         */
        changeCardShort() {
            this.focus_form = false;
            this.data_form_link = null;
            this.data_form_phone = null;
            this.data_form_map = null;
            this.data_form_localization = null;

            for (let item of this.list_buttons) {
                item.pill.selected = false;
            }
        }
    },
    template: (
        /*html*/
        ` 
        <v-row>
            <v-col v-if="list_buttons.length > 0" cols="12" sm="6" md="12">
                <section-card title="Voc&ecirc; pode adicionar at&eacute; 10 bot&otilde;es de diferentes tipos na sua mensagem." icon="$addCampoIcon" icon-size="40">
                    <template #body>
                        <group-pills
                            description-custom="Bot&otilde;es que voc&ecirc; criou"
                            :list-pills-custom="list_buttons"
                            height="auto"
                            :group-name="group_name"
                            @delete="removeCustom"
                            @edit="editItem"
                            @click="itemClick"
                        ></group-pills>                                    
                    </template>
                </section-card>
            </v-col>
            <v-col cols="12" :sm="list_buttons.length > 0 ? 6 : 12" md="12" class="mt-6">
                <v-row no-gutters>
                    <v-col cols="12" class="overflow-auto">
                        <base-radio-buttons v-model="list_card_short" @change="changeCardShort"></base-radio-buttons>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-6">
                    <v-col v-if="cardSelected" cols="12">
                        <v-card :class="(focus_form ? 'border-2 border-primary-blue-500' : 'border-1 border-gray-300') + ' pa-4'" elevation="0">
                            <form-suggestion-link
                                v-if="cardSelected.id === 'link'"
                                :list-validation="list_buttons"
                                :form-content="data_form_link"
                                @formSubmit="createSuggestionLink"
                                @deleteItem="removeCustom"
                                @cancelEdit="cancelEdit"
                                @focus="focus_form = true"
                                @blur="focus_form = false"
                            ></form-suggestion-link>
                            <form-suggestion-telefone
                                v-else-if="cardSelected.id === 'phone'"
                                :list-validation="list_buttons"
                                :form-content="data_form_phone"
                                @formSubmit="createSuggestionPhone"
                                @deleteItem="removeCustom"
                                @cancelEdit="cancelEdit"
                                @focus="focus_form = true"
                                @blur="focus_form = false"
                            ></form-suggestion-telefone>
                            <form-suggestion-localizacao
                                v-else-if="cardSelected.id === 'map'"
                                :list-validation="list_buttons"
                                :form-content="data_form_map"
                                @formSubmit="createSuggestionMap"
                                @deleteItem="removeCustom"
                                @cancelEdit="cancelEdit"
                                @focus="focus_form = true"
                                @blur="focus_form = false"
                            ></form-suggestion-localizacao>
                            <form-suggestion-get-location
                                v-else-if="cardSelected.id === 'localization'"
                                :list-validation="list_buttons"
                                :form-content="data_form_localization"
                                @formSubmit="createSuggestionLocalization"
                                @deleteItem="removeCustom"
                                @cancelEdit="cancelEdit"
                                @focus="focus_form = true"
                                @blur="focus_form = false"
                            ></form-suggestion-get-location>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        `
    )
};