import CellPhonePreview from "../atomos/CellPhonePreview";
import PreviewText from "../atomos/PreviewText";
import PreviewSuggestions from "../atomos/PreviewSuggestions";
import PreviewImage from "../atomos/PreviewImage";
import PreviewCard from "../atomos/PreviewCard";
import PreviewCarousel from "../moleculas/PreviewCarousel";
import BaseTooltip from "../atomos/BaseTooltip";
import { CardRcsClass } from "../../classes/CardRcsClass";
import TemplateUtils from "../../utils/TemplateUtils";

/**
 * Componente para pre visualizacao de templates de RCS
 * 
 * @requires {@link CellPhonePreview}
 * @requires {@link PreviewText}
 * @requires {@link PreviewSuggestions}
 * @requires {@link PreviewImage}
 * @requires {@link PreviewCard}
 * @requires {@link PreviewCarousel}
 * @requires {@link BaseTooltip}
 * @requires {@link CardRcsClass}
 * @requires {@link TemplateUtils}
 *
 * @displayName PreviewTemplateRcs
 * @category Organismos
 * @author David dos Santos Andrade <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        CellPhonePreview,
        PreviewText,
        PreviewSuggestions,
        PreviewImage,
        PreviewCard,
        PreviewCarousel,
        BaseTooltip
    },
    props: {
        /**
         * Tipo de template a ser exibido
         */
        type: {
            type: String,
            required: false,
            default: "message",
            validator(data) {
                return ["message", "image", "card", "carousel"].indexOf(data) > -1;
            }
        },
        /**
         * Mensagem do template
         */
        message: {
            type: [String, Array],
            required: false,
            default: null
        },
        /**
         * Lista de suggestions (botoes ou respostas rapidas)
         */
        listSuggestions: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Imagem do template
         */
        image: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Card do template
         */
        card: {
            type: CardRcsClass,
            required: false,
            default: null
        },
        /**
         * Lista de cards do template para carrossel
         */
        listCard: {
            type: Array,
            required: false,
            default: null
        },
        /**
         * Habilita o overflow do conteudo
         */
        showOverflow: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Texto de descricao a ser apresentado acima do conteudo do template
         */
        description: {
            type: String,
            required: false,
            default: "Sua mensagem ser&aacute; apresentada desta maneira para o destinat&aacute;rio"
        },
        /**
         * Define a altura do componente
         */
        height: {
            type: Number,
            required: false,
            default: 495
        },
        /**
         * Define o componente como carregando
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Da um destaque por 1 segundo
         */
        highlight: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Lista de opcoes que podem ser utilizadas em um select
         */
        selectOptions: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Campos variaveis do template
         */
        fieldsTemplate: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        },
        /**
         * Informa se e um card de criacao de campanha
         */
        inCreateCampaign: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define as regras a serem utilizadas para validacao dos dados inseridos
         */
        rules: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
    },
    data() {
        return {
            slide_carousel: null,
            template_utils: new TemplateUtils(),
            background_color: "bg-gray-30"
        };
    },
    computed: {
        dsTemplate() {
            let list_messages = this.template_utils.formatMessageList(this.message);
            let list_chips_preview = null;
            let image = null;
            let orientation = null;
            let alignment = null;
            let list_title = null;
            let image_height = null;
            let card = null;

            if (this.type === "card") {
                list_chips_preview = [...this.card.suggestionButtons, ...this.card.suggestionResponses];
                image = this.card.image;
                orientation = this.card.orientation;
                alignment = this.card.alignment;
                list_title = this.template_utils.formatMessageList(this.card.title);
                list_messages = this.template_utils.formatMessageList(this.card.description);
                image_height = this.card.image_height;
                card = this.card;

            } else if (this.type === "carousel") {
                card = this.listCard;

            } else {
                list_chips_preview = this.listSuggestions;
                image = this.image;
                card = this.card;
            }

            return this.template_utils.getDsTemplate(this.type === "message" ? "text" : this.type, {
                list_messages,
                list_chips_preview,
                image,
                orientation,
                alignment,
                list_title,
                image_height,
                card
            });
        }
    },
    watch: {
        highlight() {
            this.setBackgroundColor();
        }
    },
    mounted() {
        this.setBackgroundColor();
    },
    methods: {
        /**
         * Define as classes do background e bordas do conteudo
         * @vue
         */
        setBackgroundColor() {
            this.background_color = "bg-gray-30 border-gray-300";

            if (this.highlight) {
                this.background_color = "bg-primary-blue-50 border-primary-blue-500";

                setTimeout(() => {
                    this.background_color = "bg-gray-30 border-gray-300";
                }, 1000);
            }
        },
        /**
         * Emite um evento informando que os campos foram alterados
         * @param {Object} value 
         */
        fieldChanged(value) {
            this.$emit("changeField", value);
        }
    },
    template: (
        /*html*/
        `
            <v-card
                elevation="0"
                :height="showOverflow ? undefined : height"
                :class="background_color + ' border-1 rounded-1 px-4 pt-4 ' + (showOverflow ? 'pb-5' : 'overflow-hidden')"
                width="100%"
            >
                <base-tooltip :disabled="loading" text="Expandir">
                    <v-btn
                        v-if="!showOverflow"
                        small
                        icon
                        color="gray-300"
                        absolute
                        right
                        top
                        class="mt-n3 mr-n3"
                        @click="$emit('showDialogPreview', dsTemplate)"
                    >
                        <v-icon color="gray-300">
                            mdi-eye
                        </v-icon>
                    </v-btn>
                </base-tooltip>
                <v-row no-gutters class="mb-4">
                    <v-col cols="12" class="text-center">
                        <span v-if="!loading" class="body-2 text-gray-600" v-html="description" />
                        <v-skeleton-loader v-else type="text" width="150" />
                    </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                    <v-col cols="auto">
                        <v-card
                            v-if="!loading"
                            elevation="0"
                            :height="showOverflow ? undefined : (height - 30)"
                            :class="background_color + ' border-0 pb-5 overflow-y-auto overflow-x-hidden'"
                        >
                            <v-card
                                v-if="['message', 'image'].indexOf(type) > -1"
                                height="444"
                                elevation="0"
                                class="bg-transparent"
                            >
                                <cell-phone-preview
                                    show-header
                                    :show-footer="false"
                                    :show-preview-message="false"
                                    height="100%"
                                    :width="300"
                                    :content-fill="false"
                                >
                                    <v-row v-if="type === 'message'" no-gutters>
                                        <v-col cols="12">
                                            <preview-text
                                                :list-message="template_utils.formatMessageList(message)"
                                                :select-options="selectOptions"
                                                :fields-template="fieldsTemplate"
                                                @changeField="fieldChanged"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <preview-suggestions
                                                :list-chips="listSuggestions"
                                                :select-options="selectOptions"
                                                :fields-template="fieldsTemplate"
                                                @changeField="fieldChanged"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row v-else-if="type === 'image'" no-gutters>
                                        <v-col cols="12">
                                            <preview-image
                                                :image="image"
                                                :variable="image === 'variable'"
                                                :select-options="selectOptions"
                                                :fields-template="fieldsTemplate"
                                                @changeField="fieldChanged"
                                            />
                                        </v-col>
                                    </v-row>
                                </cell-phone-preview>
                            </v-card>
                            <v-row v-else-if="type === 'card'">
                                <v-col cols="12">
                                    <preview-card
                                        :image="card.image"
                                        :title="template_utils.formatMessageList(card.title)"
                                        :description="template_utils.formatMessageList(card.description)"
                                        :suggestion-buttons="card.suggestionButtons"
                                        :suggestion-responses="card.suggestionResponses"
                                        :orientation="card.orientation"
                                        :alignment="card.alignment"
                                        :image-height="card.image_height"
                                        need-format-message
                                        :select-options="selectOptions"
                                        :fields-template="fieldsTemplate"
                                        @changeField="fieldChanged"
                                        :in-create-campaign="inCreateCampaign"
                                        :rules="rules"
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-else-if="type === 'carousel'">
                                <v-col cols="12">
                                    <preview-carousel
                                        :list-card="listCard"
                                        :select-options="selectOptions"
                                        :fields-template="fieldsTemplate"
                                        @changeField="fieldChanged"
                                    />
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card
                            v-else
                            elevation="0"
                            :height="showOverflow ? undefined : (height - 30)"
                            class="bg-gray-30 pb-5 overflow-y-auto overflow-x-hidden"
                        >
                            <v-skeleton-loader type="card-avatar, article" width=" 300" />
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};