import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "anexo-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom xmlns="http://www.w3.org/2000/svg" width="12.824" height="6.398" viewBox="0 0 12.824 6.398">
                <path id="Path_1298" data-name="Path 1298" d="M10.8,9.324H4.074a2.324,2.324,0,0,1,0-4.648h7.3a1.449,1.449,0,0,1,0,2.9H5.25a.574.574,0,0,1,0-1.148H10.8V5.551H5.25a1.449,1.449,0,0,0,0,2.9h6.125a2.324,2.324,0,0,0,0-4.648h-7.3a3.2,3.2,0,0,0,0,6.4H10.8Z" transform="translate(-0.875 -3.801)"/>
            </svg-custom>
        `
    )
};