import BaseInformation from "../../../../components/atomos/BaseInformation?v=3";
import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";
import BaseDataTable from "../../../../components/organismos/BaseDataTable?v=2";
import { DataTableHeaderClass } from "../../../../classes/DataTableHeaderClass";

/**
 * Componente de dialog para mensagem de acao concluida
 * <iframe src="http://localhost:8080/v2/documentacao/dashboard/dialog-done-usage" style="width: 100%; height: 315px;"></iframe>
 * 
 * @requires {@link BaseInformation}
 * @requires {@link BaseDialog}
 * @requires {@link BaseDataTable}
 * @requires {@link DataTableHeaderClass}
 * 
 * @displayName DialogDone
 * @category Page / Dashboard
 * @subcategory dialogs
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseInformation,
        BaseDialog,
        BaseDataTable
    },
    props: {
        /**
         * Valor do atributo v-model do componente, indica se o dialog deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Lista de dados a serem apresentados, a estrutura deve ser:
         * <pre>
         * [
         *  {
         *      id: Number
         *      name: String
         *  }
         * ]
         * </pre>
         */
        listData: {
            type: Array,
            required: false,
            default: null
        },
        /**
         * Tipo de exibicao (R: Reinicio, P: Pausa)
         */
        type: {
            type: String,
            required: true,
            validator(value) {
                return ["R", "P"].indexOf(value) !== -1;
            }
        }
    },
    data() {
        return {
            dialog: this.value,
            headers: [
                new DataTableHeaderClass({ text: "Identificador", value: "id" }),
                new DataTableHeaderClass({ text: "Campanha", value: "name" })
            ]
        };
    },
    computed: {
        title() {
            return this.type === "R" ? "Campanhas reiniciadas" : "Campanhas pausadas";
        },
        contentText() {
            let message = this.listData.length + " campanhas foram ";

            switch (this.type) {
            case "R":
                message += "reiniciadas com sucesso:";
                break;
            case "P":
                message += "pausadas com sucesso:";
                break;
            }

            return message;
        },
        informationText() {
            let message = "";

            switch (this.type) {
            case "R":
                message = window.Vue.htmlEntities("&Eacute; poss&iacute;vel pausar as campanhas novamente, desde que n&atilde;o tenham sido finalizadas ou canceladas.");
                break;
            case "P":
                message = window.Vue.htmlEntities("&Eacute; poss&iacute;vel reiniciar as campanhas a qualquer momento.");
                break;
            }

            return message;
        }
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog() {
            /**
             * Emite o status do dialog (false ou true)
             * @property {Boolean} dialog
             * 
             * @event input
             * @vue
             */
            this.$emit("input", this.dialog);

            if (!this.dialog) {
                /**
                 * Emite um evento chamado close informando que o model foi fechado
                 * 
                 * @event close
                 * @vue
                 */
                this.$emit("close");
            }
        }
    },
    template: (
        /*html*/
        `
            <base-dialog 
                v-model="dialog"
                :title="title"
                :action-button="{ label: 'Entendi', variant: 'dark-grey', loading: false }"
                use-icon-close
                max-width="520px"
                @clickDialog="dialog = false"
            >
                <v-card elevation="0">
                    <v-row>
                        <v-col cols="12" class="pb-0 mb-0">
                            <p class="font-size-12" v-html="contentText"></p>
                        </v-col>
                        <v-col cols="12" class="pt-0 mt-0">
                            <base-data-table
                                :headers="headers"
                                :list-items="listData"
                                :pagination="false"
                                :page-length="-1"
                                :custom-columns="false"
                                dense
                                sort-by="id"
                            ></base-data-table>
                        </v-col>
                        <v-col cols="12">
                            <base-information
                                :description="informationText"
                                icon="mdi-alert-circle"
                                border-color="border-primary"
                                icon-color="primary"
                            ></base-information>
                        </v-col>
                    </v-row>
                </v-card>
            </base-dialog>
        `
    )
};