/**
 * Componente basico de tooltip
 *
 * @displayName BaseTooltip
 * @component
 * @category Atomos
 * @component
 * @author David Santos <david.santos@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    props: {
        /**
         * Largura maxima
         */
        maxWidth: {
            type: [Number, String],
            required: false,
            default: 250
        },
        /**
         * Cor do bakcground
         */
        color: {
            type: String,
            required: false,
            default: "gray-700"
        },
        /**
         * Tempo de espera para exibir
         */
        openDelay: {
            type: [Number, String],
            required: false,
            default: 500
        },
        /**
         * Desabilita o tooltip
         */
        disabled: {
            type: [Boolean, String],
            required: false,
            default: false
        },
        /**
         * Texto a ser exibido
         */
        text: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Efeito de transi��o ao exibir ou esconder
         */
        transition: {
            type: String,
            required: false,
            default: "fade-transition"
        },
        /**
         * Classe personalizada
         */
        contentClass: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Forca a exibicao
         */
        show: {
            type: Boolean,
            required: false,
            default: undefined
        },
        /**
         * Exibe o tooltip acima
         */
        top: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe o tooltip abaixo
         */
        bottom: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe o tooltip a direita
         */
        left: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe o tooltip a esquerda
         */
        right: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            hover: false
        };
    },
    computed: {
        classTooltip() {
            let orientation_arrow = "bottom-arrow";

            if (this.bottom) {
                orientation_arrow = "top-arrow";
            } else if (this.left) {
                orientation_arrow = "right-arrow";
            } else if (this.right) {
                orientation_arrow = "left-arrow";
            }

            return orientation_arrow;
        }
    },
    template:
        /*html*/
        `
            <v-tooltip
                :top="top || (!bottom && !left && !right)"
                :bottom="bottom"
                :left="left"
                :right="right"
                :max-width="maxWidth"
                :color="color"
                :open-delay="openDelay"
                :disabled="!text || disabled"
                :transition="transition"
                :content-class="classTooltip + ' ' + contentClass + ' rounded-1'"
                :value="show || hover"
            >
                <template v-slot:activator="props">
                    <v-hover 
                    v-model="hover" 
                    :open-delay="openDelay" 
                    :disabled="show !== undefined"
                    >
                        <slot></slot>
                    </v-hover>
                </template>
                <v-row no-gutters justify="center" class="pa-2">
                    <v-col cols="auto" class="text-center">
                        <span class="caption text-gray-0" v-html="text"></span>
                    </v-col>
                </v-row>
            </v-tooltip>
        `
};
