import ButtonAction from "../../../components/moleculas/ButtonAction";

export default {
    components: {
        ButtonAction
    },
    data() {
        return {
            disabled: false,
            loading: false,
            types: [
                "open",
                "edit",
                "escalonar",
                "pause",
                "play",
                "cancel",
                "moreInfo",
                "delete",
                "disable",
                "download",
                "userPermission",
                "internalPermission",
                "chat",
                "chatFinish",
                "mail",
                "confirm",
                "discard"
            ]
        };
    },
    template: (
        /*html*/
        `
            <v-row class="mt-16" justify="center">
                <v-col cols="8">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col>
                                        <v-checkbox label="Desabilitado" v-model="disabled"></v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox label="Carregando" v-model="loading"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="mt-10">
                        <v-col v-for="item in types">
                            <button-action :type="item" :disabled="disabled" :loading="loading"></button-action>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
     
        `
    )
};