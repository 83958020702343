import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente basico de card pequeno clicavel
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName BaseCardShort
 * @component
 * @category Atomos
 * @component
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br>
 * @author Igor Mateus Rodrigues de Oliveira <igor.oliveira@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Icone que fica no lado direito da label no botao
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * label do botao
         */
        label: {
            type: String,
            required: true
        },
        /**
         * para desabilitar ou nao o botao
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * Define o estado do botao para Em Breve
         */
        soon: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Valor de v-model para indicar o estado de ativo do botao
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica que o componente deve ficar na vertical
         */
        vertical: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define o border radius do componente
         */
        borderRadius: {
            type: String,
            required: false,
            default: "rounded-1"
        },
        /**
         * Define a cor da borda
         */
        borderColor: {
            type: String,
            required: false,
            default: "gray-800"
        },
        /**
         * Texto a ser exibido no tooltip
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            hover: false
        };

    },
    computed: {
        buttonColor() {
            let color = "gray-0";

            if (this.disabled) {
                color = "gray-10";
            } else if (this.value && !this.soon) {
                color = "primary-blue-500";
            } else if (this.hover && !this.soon) {
                color = "gray-800";
            }

            return color;
        },
        contentColor() {
            let color = "gray-800";

            if (this.disabled || this.soon) {
                color = "gray-100";
            } else if (this.hover || this.value) {
                color = "gray-0";
            }

            return color;
        },
        colorBorder() {
            let color = this.borderColor;

            if (this.disabled || this.soon) {
                color = "gray-100";
            } else if (this.value) {
                color = "primary-blue-500";
            } else if (this.hover) {
                color = "gray-0";
            }

            return color;
        },
        borderStyle() {
            let border = "border-1";

            if (this.soon && !this.disabled) {
                border = "border-dashed-1";
            }

            return border;
        },
        spacing() {
            let classes = "px-4";

            if (this.vertical) {
                classes = "pa-4";
            }

            return classes;
        }
    },
    methods: {
        /**
         *  Funcao para emissao de valor pelo click de botao
         *  @vue
         */
        buttonFunction() {
            this.$emit("click");
            this.$emit("input", !this.value);
        }
    },
    template: (
        /*html*/
        `
            <v-hover v-model="hover" :disabled="disabled || soon">
                <base-tooltip :text="tooltipText">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-card
                                block
                                depressed
                                elevation="0"
                                :disabled="disabled || soon"
                                :class="borderRadius + ' bg-' + buttonColor + ' text-' + contentColor + ' ' + borderStyle + ' border-' + colorBorder"
                                @click="buttonFunction()"
                            >
                                <v-row no-gutters :justify="vertical ? 'center' : 'start'" :class="spacing" style="min-height: 48px" align="center">
                                    <v-col cols="auto">
                                        <v-row no-gutters :class="vertical ? '' : 'flex-nowrap'" align="center">
                                            <v-col :cols="vertical ? 12 : 'auto'" class="text-center">
                                                <v-icon small left :color="contentColor" class="mr-2">{{ icon }}</v-icon>
                                            </v-col>
                                            <v-col :cols="vertical ? 12 : 'auto'" class="text-center">
                                                <span class="body-2" v-html="label"></span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col v-if="soon && !disabled" cols="12" class="text-center">
                            <span class="caption text-gray-600">Em breve!</span>
                        </v-col>
                    </v-row>
                </base-tooltip>
            </v-hover>
        `
    )
};
