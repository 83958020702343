import BaseInformation from "../../../../components/atomos/BaseInformation?v=3";
import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";

/**
 * Componente de dialog para mensagem de acao concluida
 * <iframe src="http://localhost:8080/v2/documentacao/dashboard/dialog-done-usage" style="width: 100%; height: 315px;"></iframe>
 * 
 * @requires {@link BaseInformation}
 * @requires {@link BaseDialog}
 * 
 * @displayName DialogUnderstandingConfirmation
 * @category Page / CreateCampaign
 * @subcategory dialogs
 * @author Bruno Dias Ramos <bruno.dias@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseInformation,
        BaseDialog
    },
    props: {
        /**
         * Valor do atributo v-model do componente, indica se o dialog deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            dialog: this.value,
            icon_alert: "$exclamationTriangleIcon",
            action_button: {
                "label": "Entendi",
                "variant": "dark-primary",
                "icon": "fa-check",
                "id": "btnConfirmUnderstanding",
            },
        };
    },
    computed: {
        informationText() {
            let message = "Essa campanha possui envios com mais de 160 caracteres. Os que ultrapassarem esse limite deixar&atilde;o de ser da modalidade <b>Basic</b> e passar&atilde;o as ser cobrados como <b>Single</b>.";

            return message;
        }
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog() {
            /**
             * Emite o status do dialog (false ou true)
             * @property {Boolean} dialog
             * 
             * @event input
             * @vue
             */
            this.$emit("input", this.dialog);

            if (!this.dialog) {
                /**
                 * Emite um evento chamado close informando que o model foi fechado
                 * 
                 * @event close
                 * @vue
                 */
                this.$emit("close");
            }
        }
    },
    template: (
        /*html*/
        `
            <base-dialog 
                v-model="dialog"
                :action-button="action_button"
                use-icon-close
                max-width="600px"
                @clickDialog="dialog = false"
            >
                <v-card elevation="0">
                    <v-row>
                        <v-row>
                            <v-col cols="12">
                                <base-information
                                    :description="informationText"
                                    border-color="border-primary"
                                    icon="mdi-alert-circle"
                                    icon-color="primary"
                                ></base-information>
                            </v-col>
                        </v-row>
                    </v-row>
                </v-card>
            </base-dialog>
        `
    )
};