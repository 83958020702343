import BaseDialog from "../../../components/moleculas/BaseDialog";

export default ({
    name: "BaseDialogUsage",
    components: { BaseDialog },
    data() {
        return {
            icon_button: {"label": "Desativar", "variant": "danger", "icon": "icon-btn-desativar"}
        };
    },
    template: (
        /*html*/
        `
        <base-dialog
        :active=true :action-button="icon_button">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
        when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </base-dialog>
        `
    )
});