import { ItemAccordionClass } from "../../../classes/ItemAccordionClass";
import AccordionDefault from "../../../components/moleculas/AccordionDefault";

export default {
    name: "accordion-default-usage",
    components: {
        AccordionDefault
    },
    data() {
        return {
            items: [
                new ItemAccordionClass({
                    title: "Escreva sua mensagem",
                    icon: "mdi-format-text",
                    icon_bg_color: "dark-primary",
                    icon_text_color: "white",
                    active: true,
                    disabled: false,
                    complete: false,
                    slot_name: "item_1"
                }),
                new ItemAccordionClass({
                    title: "Respostas r&aacute;pidas e bot&otilde;es (Opcional)",
                    icon: "mdi-chat-processing",
                    icon_bg_color: "dark-primary",
                    icon_text_color: "white",
                    active: false,
                    disabled: false,
                    complete: false,
                    slot_name: "item_2"
                })
            ],
            conteudo: [
                { value: null },
                { value: null }
            ]
        };
    },
    watch: {
        conteudo: {
            handler() {
                for (let index in this.conteudo) {
                    this.items[index].complete = this.conteudo[index].value ? true : false;
                }
            },
            deep: true
        }
    },
    methods: {
        itemClicked({item, index}) {
            console.log(item);
            console.log(index);
        }
    },
    template: (
        /*html*/
        `
            <div class="row justify-center mt-16">
                <div class="col-6 bg-super-light-grey">
                    <accordion-default :items="items" @click_item="itemClicked">
                        <template v-for="(item, i) in items" #[item.slot_name]=''>
                            <div>
                                <p>Aqui ficam os dados para o accordion de {{ item.title }}</p>
                                <v-text-field v-model="conteudo[i].value" solo></v-text-field>
                            </div>
                        </template>
                    </accordion-default>
                </div>
            </div>
     
        `
    )
};