import PageHeader from "../../components/moleculas/PageHeader";
import BaseButton from "../../components/atomos/BaseButton";
import BaseDataTable from "../../components/organismos/BaseDataTable";
import StatusCampaign from "../../components/moleculas/StatusCampaign";
import BaseProgressBar from "../../components/atomos/BaseProgressBar";
import CanalIconDescription from "../../components/moleculas/CanalIconDescription";
import PgTour from "../../components/organismos/PgTour";
import FormFilterBlacklist from "./components/form/FormFilterBlacklist";
import ButtonAction from "../../components/moleculas/ButtonAction";
import ExportBlacklist from "./components/ExportBlacklist";
import DialogDelete from "./components/dialogs/DialogDelete";
import DialogDeleteMulti from "./components/dialogs/DialogDeleteMulti";
import { DataTableHeaderClass } from "../../classes/DataTableHeaderClass";
import { BaseService } from "../../services/BaseService";

/**
 * @requires {@link PageHeader}
 * @requires {@link BaseButton}
 * @requires {@link BaseDataTable}
 * @requires {@link PgTour}
 * @requires {@link FormFilterBlacklist}
 * @requires {@link CanalIconDescription}
 * @requires {@link StatusCampaign}
 * @requires {@link BaseProgressBar}
 * @requires {@link ButtonAction}
 * @requires {@link ExportBlacklist}
 * @requires {@link DialogDelete}
 *
 * Pagina que realiza a listagem da blacklist
 * @displayName BlacklistPage
 * @category Page / Blacklist
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PageHeader,
        BaseButton,
        BaseDataTable,
        PgTour,
        FormFilterBlacklist,
        CanalIconDescription,
        StatusCampaign,
        BaseProgressBar,
        ButtonAction,
        ExportBlacklist,
        DialogDelete,
        DialogDeleteMulti
    },
    filters: {
        format_date: function (date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY HH:mm:ss");
            } else {
                return "-";
            }
        },
        filter_empty: function (value) {
            return value ? value : "-";
        }
    },
    data() {
        return {
            base_service: new BaseService(),
            list_blacklist: [],
            filter_data: {},
            selecteds: [],
            selected: [],
            custom_slots: [
                "DT_INCLUSAO_BLES",
                "ID_BLACKLIST_BLES",
                "NM_EMPRESA_EPES",
                "NM_USUARIO_USES",
                "NR_CELULAR_BLES",
                "actions"
            ],
            loading: true,
            // table_loading: true,
            steps: [
                {
                    target: "[data-v-step='1']",
                    title: "Cadastrar n&uacute;mero",
                    content: "Voc&ecirc; pode incluir telefones manualmente ou por upload de arquivo txt.",
                    date: "2022-10-07 00:00:00"
                },
                {
                    target: "[data-v-step='2']",
                    title: "Filtros",
                    content: "A listagem j&aacute; vem filtrada com um per&iacute;odo de aproximadamente um m&ecirc;s.<br>Voc&ecirc; pode refinar sua busca por um telefone espec&iacute;fico e alterar o per&iacute;odo que deseja consultar."
                },
                {
                    target: "[data-v-step='3']",
                    title: "Excluir da lista",
                    content: "Voc&ecirc; pode selecionar v&aacute;rios registros na tabela para excluir da lista."
                },
                {
                    target: "[data-v-step='4']",
                    title: "Personalizar tabela",
                    content: "Voc&ecirc; pode escolher quais s&atilde;o as colunas que deseja visualizar na tabela."
                },
                {
                    target: "[data-v-step='5']",
                    title: "Bot&otilde;es",
                    content: "Voc&ecirc; pode baixar as informa&ccedil;&otilde;es da tabela em excel (.csv) ou texto (.txt)"
                },
                {
                    target: "[data-v-step='6']",
                    title: "Quantidade de linhas",
                    content: "Voc&ecirc; pode escolher a quantidade de linhas que deseja exibir na tabela."
                },
                {
                    target: "[data-v-step='7']",
                    title: "Ordenar",
                    content: "&Eacute; poss&iacute;vel ordenar os registros por coluna de forma crescente ou decrescente, basta clicar na seta <i class='fas fa-caret-down'></i> quando dispon&iacute;vel."
                },
                {
                    target: "[data-v-step='8']",
                    title: "Excluir",
                    content: "Voc&ecirc; pode tamb&eacute;m excluir telefones da lista individualmente."
                }
            ],
            dialog: false,
            dialog_multi: false,
            table_headers: [
                new DataTableHeaderClass({
                    text: "ID",
                    value: "ID_BLACKLIST_BLES",
                    sortable: true,
                    tooltipText: "Canal utilizado para disparo da mensagem",
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("Usu&aacute;rio que cadastrou"),
                    value: "NM_USUARIO_USES",
                    sortable: false,
                    visibility: "md",
                    tooltipText:
                        "O identificador &eacute; um c&oacute;digo gerado automaticamente e pode ser utilizado para integra&ccedil;&otilde;es via API"
                }),
                new DataTableHeaderClass({
                    text: "Telefone",
                    value: "NR_CELULAR_BLES",
                    sortable: true,
                    visibility: "md",
                    tooltipText: "Cliente (carteira) que foi selecionado no momento de cria&ccedil;&atilde;o da campanha"
                }),
                new DataTableHeaderClass({
                    text: "Cliente",
                    value: "NM_EMPRESA_EPES",
                    sortable: false,
                    visibility: "md",
                    tooltipText: "O nome da campanha pode ser gerado pelo arquivo importado ou via API"
                }),
                new DataTableHeaderClass({
                    text: "Data Cadastro",
                    value: "DT_INCLUSAO_BLES",
                    sortable: true,
                    visibility: "md",
                    tooltipText: "Situa&ccedil;&atilde;o atual da campanha",
                    stepTutorial: 7
                }),
                new DataTableHeaderClass({
                    text: "Excluir",
                    value: "actions",
                    width: "50px",
                    sortable: false,
                    className: "text-nowrap",
                    tooltipText: "A&ccedil;&otilde;es que podem ser tomadas por campanha",
                    stepTutorial: 8
                })
            ],
            content_table: []
        };
    },
    computed: {
        data_export() {
            return this.filter_data;
        },
        server_side_config() {
            return {
                request_to: "API",
                url: "/blacklist/list",
                data: this.filter_data
            };
        }
    },
    mounted() {
        //console.log(new Date(Date.now()));
    },
    methods: {
        filterSubmit(data) {
            this.filter_data = {
                telefone: data.telefone ? parseInt(data.telefone.replace(/[^0-9]/g, "")) : "",
                data_inicio: data.data_inicio,
                data_fim: data.data_fim
            };
            this.loading = true;
        },
        dataInicio(data) {
            this.filter_data["data_inicio"] = data;
        },
        dataFim(data) {
            this.filter_data["data_fim"] = data;
        },
        /**
         * Metodo que faz a trativa do id do item selecionado
         */
        selecionados(ev) {
            let ids = [];

            ev.forEach(function (item) {
                ids.push(item.ID_BLACKLIST_BLES);
            });

            this.selecteds = ids;
        },
        /**
         * Metodo que faz a trativa dos objetos que serão enviados para o componente dialog-delete
         */
        openDialog(ev) {
            this.dialog = true;
            this.selected = ev;
        },
        /**
         * Metodo que faz a trativa dos objetos que serão enviados para o componente dialog-delete/dialog-delete-multi
         */
        closeDialog() {
            this.dialog = false;
            this.dialog_multi = false;
        },
        /**
         * Metodo que faz a a atualização da tabela apos a remoção de item da blacklist
         */
        deleted() {
            this.dialog = false; //fecha o dialog
            this.dialog_multi = false; //fecha o dialog_multi
            this.filterSubmit(this.filter_data);
            this.selecionados([]); //limpa os itens selecionados
        },
        /**
         * Metodo para abrir o dialog de exclusão de mais de um item
         */
        multiDeleteDialog(ev) {
            if (ev) {
                this.dialog_multi = true;
            }
        },
        /**
         * Metodo que faz a tratativa do objeto loading recebendo o $emit refreshServerSideData do componente dataBaseTable
         */
        dataTable(ev) {
            this.loading = false;
            this.content_table = ev.data; // recebe o conteudo da tabela para enviar ao componente ExportBlacklist
        },
        /**
         * Redireciona o usuario para uma pagina de cadastro de blacklist
         * @vue
         */
        actionButton() {
            document.location.href = this.base_service.baseDomainPhp() + "/frm-blacklist.php";
        },
    },
    template:
        /*html*/
        ` 
        <v-container>
            <v-card elevation="0">
                <v-card-text>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12">
                            <page-header
                                :tutorial="true"
                                title="Blacklist"
                                description="Aqui voc&ecirc; pode verificar e excluir os telefones que est&atilde;o em blacklist."
                                old-page="/list-blacklist.php"
                                @tutorial="$refs.tour.start()"
                            >
                                <template v-slot:button>
                                    <v-row no-gutters v-bind:data-v-step="1">
                                        <v-col>
                                            <base-button
                                                icon="mdi-plus"
                                                label="Cadastrar N&uacute;mero"
                                                :block="true"
                                                :small="true"
                                                variant="dark-primary"
                                                @click="actionButton()"
                                            ></base-button>
                                        </v-col>
                                    </v-row>
                                </template>
                            </page-header>
                        </v-col>
                    </v-row>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12" class="filtro-content pb-10">
                            <form-filter-blacklist 
                                @dataInicio="dataInicio" 
                                @dataFim="dataFim" 
                                @formSubmit="filterSubmit"
                            ></form-filter-blacklist>
                        </v-col>
                    </v-row>
                    <v-row class="px-0 px-md-6" no-gutters >
                        <export-blacklist
                        :loading="loading"
                        :selecteds="selecteds"
                        :dataExport="filter_data"
                        :content-table="content_table"
                        @dialog="multiDeleteDialog"
                        ></export-blacklist>
                    </v-row>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12">
                            <base-data-table
                                :headers="table_headers"
                                :list-items="content_table"
                                :custom-slots="custom_slots"
                                :server-side-config="server_side_config"
                                item-key="ID_BLACKLIST_BLES"
                                sort-by="ID_BLACKLIST_BLES"
                                show-page-length
                                :step-tutorial-custom-columns="4"
                                :step-tutorial-page-length="6"
                                select-column="true"
                                @input="selecionados"
                                :loading="loading"
                                @refreshServerSideData="dataTable"
                            >
                                <template v-slot:ID_BLACKLIST_BLES="props">
                                    <span>{{ props.item.ID_BLACKLIST_BLES }}</span>
                                </template>
                                <template v-slot:NM_USUARIO_USES="props">
                                    <span>{{ props.item.NM_USUARIO_USES !== null ? props.item.NM_USUARIO_USES : "-" }}</span>
                                </template>
                                <template v-slot:NR_CELULAR_BLES="props">
                                    <span>{{ maskToPhone(props.item.NR_CELULAR_BLES) }}</span>
                                </template>
                                <template v-slot:NM_EMPRESA_EPES="props">
                                    <span>{{ props.item.NM_EMPRESA_EPES !== null ? props.item.NM_EMPRESA_EPES : "Todas"  }}</span>
                                </template>
                                <template v-slot:DT_INCLUSAO_BLES="props">
                                    <span>{{ props.item.DT_INCLUSAO_BLES | format_date }}</span>
                                </template>
                                <template v-slot:actions="props">
                                    <a @click="openDialog(props.item)">
                                        <span class="icon-btn-delete fa-lg  rounded text-dark-danger text-of-white-hover bg-dark-danger-hover py-1 px-2 fa-2x rounded  text-primary-dark-hover"></span>
                                    </a>
                                </template>
                            </base-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <dialog-delete
                    :value="dialog"
                    :item="selected"
                    @close="closeDialog"
                    @deleted="deleted"
                ></dialog-delete>
                <dialog-delete-multi
                    :value="dialog_multi"
                    :itens="selecteds"
                    @close="closeDialog"
                    @deleted="deleted"
                ></dialog-delete-multi>
                <pg-tour ref="tour" :steps="steps"></pg-tour>            
            </v-card>
        </v-container>
        `
};
