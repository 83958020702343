import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
    routes,
    mode: "history"
});

router.beforeEach((to, from, next) => {
    if (to.name === "root" ||
        (
            to.name !== "auth"
            && to.name !== "reset-password"
            && !store.state.auth.user
            && (typeof to.matched[1] !== "undefined" && to.matched[1].name !== "documentacao")
        )
    ) {
        next({ name: "auth" });
    } else {
        next();
    }
});

export default router;
