import styled from "../../plugins/vue-styled-components.es";

const RowForm = styled("div")`
    height: 70% !important;
`;
const RowDescription = styled("div")`
    height: 30% !important;
`;

import TwoLogoTop from "../../components/moleculas/TwoLogoTop";
import CarouselNews from "../../components/organismos/CarouselNews";
import ProductDescription from "../../components/moleculas/ProductDescription";
import BoxFormManager from "./components/BoxFormManager";

import * as types from "./store/mutations-types";

/**
 * P�gina de autentica��o
 * 
 * @component
 * @category Page / Auth
 * @requires {@link two-logo-top.html|TwoLogoTop}
 * @requires {@link carousel-news.html|CarouselNews}
 * @requires {@link product-description.html|ProductDescription}
 * @requires {@link box-form-manager.html|BoxFormManager}
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 */
export default {
    name: "login-page",
    components: {
        TwoLogoTop,
        CarouselNews,
        ProductDescription,
        BoxFormManager,
        RowForm,
        RowDescription
    },
    data() {
        return {
            items: [
                {
                    src: "/images/slider-login/painel_sms.png",
                    title: "O painel +SMS agora virou o +Connect",
                    description: "Estamos passando por mudan&ccedil;as nos mensageiros! Com o lan&ccedil;amento de novos produtos, surgiu o +Connect, um portal que vai incluir todos os seus produtos em um s&oacute; lugar! Alterne entre nossas solu&ccedil;&otilde;es e encontre o melhor para a sua campanha. ",
                    active: true
                },
                {
                    src: "/images/slider-login/rcs.png",
                    title: "RCS, sua mais nova ferramenta de mensageria",
                    description: "Agora a plataforma conta com uma nova ferramenta de mensagens por telefonia. O RCS (Rich Communications Service) &eacute; um protocolo de comunica&ccedil;&atilde;o exclusivo para Android, que funciona semelhante ao SMS por&eacute;m com recursos mais avan&ccedil;ados como, envio de m&iacute;dias, cards, carrossel e muito mais. Al&eacute;m disso &eacute; poss&iacute;vel enviar uma &uacute;nica mensagem com at&eacute; 1000 caracteres.",
                    active: false
                },
                {
                    src: "/images/slider-login/dashboard.png",
                    title: "Ficou diferente, mas bem familiar...",
                    description: "O novo painel +Connect traz uma estrutura toda remodelada mas sem perder a sua ess&ecirc;ncia. Uma nova marca, novas cores e diversas outras mudan&ccedil;as desenvolvidas para melhorar sua experi&ecirc;ncia.<br>Disponibilizamos um passo a passo para guiar seu acesso em cada uma das telas que foram remodelas.",
                    active: false
                }
            ],
            products: [
                {
                    icon_product: "$smsProductIcon",
                    background_color: "primary-blue-500",
                    icon_color: "white",
                    description: "O <strong>SMS</strong> &eacute; o canal de comunica&ccedil;&atilde;o de melhor custo-benef&iacute;cio dispon&iacute;vel na plataforma de envio de mensagens da PGMais.",
                    description_color: "white"
                },
                {
                    icon_product: "$rcsProductIcon",
                    background_color: "primary-blue-500",
                    icon_color: "white",
                    description: "O <strong>RCS</strong> &eacute; um mensageiro instant&acirc;neo, moderno e articulado especificamente para tornar a experi&ecirc;ncia de di&aacute;logo mais rica e din&acirc;mica",
                    description_color: "white",
                }
            ]
        };
    },
    mounted() {
        this.$store.commit("auth/" + types.SET_EMPRESA, null);
        this.$store.commit("auth/" + types.SET_LIST_EMPRESAS, null);
        this.$store.commit("auth/" + types.SET_TYPE_PRODUCT, null);
        this.$store.commit("auth/" + types.SET_USER, null);
    },
    methods: {
        /**
         * Redireciona o usu�rio para a pr�xima p�gina caso o login tenha sido realizado com sucesso
         * @vue
         */
        loginOk: function () {
            this.$router.push({name: "selectProduct"});
        }
    },
    beforeCreate() {
        localStorage.removeItem("vuex");
    },
    template: (
        /*html*/
        `
        <v-row class="fill-height off-white" no-gutters>
            <v-col cols="12 p-0">
                <v-row class="fill-height" no-gutters>
                    <v-col cols="12" lg="6">
                        <v-row class="fill-height mt-14 mt-md-0" align="center">
                            <v-col>
                                <carousel-news :items="items"></carousel-news>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="d-md-none">
                        <v-row justify="center">
                            <a href="#div_login">
                                <v-col col="12" class="text-center text-dark-primary p-0">
                                    Acesse o portal
                                </v-col>
                                <v-col col="12" class="text-center">
                                    <i class="fas fa-arrow-down text-dark-primary"></i>
                                </v-col>
                            </a>
                        </v-row>
                    </v-col>
                    <v-col cols="12" lg="6" class="p-0" id="div_login">
                        <row-form no-gutters class="row no-gutters">
                            <v-col cols="12">
                                <box-form-manager @loginIsOk="loginOk"></box-form-manager>
                            </v-col>
                        </row-form>
                        <row-description no-gutters class="row no-gutters">
                            <v-col cols="12" md="6" v-for="product in products" :key="product.title">
                                <product-description
                                    :icon_product="product.icon_product" 
                                    :background_color="product.background_color"
                                    :icon_color="product.icon_color"
                                    :description="product.description"
                                    :description_color="product.description_color"
                                >
                                </product-description>
                            </v-col>
                        </row-description>
                    </v-col>
                </v-row>
            </v-col>
            <two-logo-top></two-logo-top>
        </v-row>
        `
    )
};