import FormFilterUsage from "./FormFilterUsage";
import DialogRedirectUsage from "./DialogRedirectUsage";
import DialogDoneUsage from "./DialogDoneUsage";
import DialogActionsUsage from "./DialogActionsUsage";

export default [
    {
        path: "form-filter-usage",
        name: "Form Filter",
        component: FormFilterUsage
    },
    {
        path: "dialog-redirect-usage",
        name: "Dialog Redirect",
        component: DialogRedirectUsage
    },
    {
        path: "dialog-done-usage",
        name: "Dialog Done",
        component: DialogDoneUsage
    },
    {
        path: "dialog-actions-usage",
        name: "Dialog Actions",
        component: DialogActionsUsage
    }
];