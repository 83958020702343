import BaseVisualCard from "../../../components/atomos/BaseVisualCard";

export default {
    components: {
        BaseVisualCard
    },
    data() {
        return {
            icon: "mdi-whatsapp",
            title: "Whatsapp",
            number: 10,
            percent: 100
        };
    },
    template: /*html*/ `
        <v-container class="pt-5 fill-height">
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="icon" label="Icone do componente" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="title" label="Titulo" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="number" type="number" label="Valor" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="percent" type="number" label="Porcentagem" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="2">
                    <base-visual-card
                        :icon="icon"
                        :title="title"
                        :number="number"
                        :percent="percent"
                    />
                </v-col>
            </v-row>
        </v-container>
    `
};