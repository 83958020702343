import FormFilterReports from "../../reports/components/forms/FormFilterReports";

export default {
    components: {
        FormFilterReports
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="10">
                <form-filter-reports> 
                </form-filter-reports>     
            </v-col>
        </v-row>
        `
    )
};