import BaseSelect from "./BaseSelect";

/**
 * Componente para pre visualização de texto
 * 
 * @requires {@link BaseSelect}
 * 
 * @displayName PreviewText
 * @category Atomos
 * @author David dos Santos Andrade <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseSelect
    },
    props: {
        /**
         * Lista de elementos da mensagens a ser exibida.
         * Os itens do array deve ser do tipo {@link DraggableItemClass}
         */
        listMessage: {
            type: Array,
            required: true
        },
        /**
         * Lista de elementos do título a ser exibido.
         * Os itens do array deve ser do tipo {@link DraggableItemClass}
         */
        listTitle: {
            type: Array,
            required: false,
            default: null
        },
        /**
         * Indica que será utilizado para configurar um card
         */
        isCard: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Largura do card
         */
        width: {
            type: [Number, String],
            required: false,
            default: undefined
        },
        /**
         * Lista de opções que podem ser utilizadas em um select
         */
        selectOptions: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Campos variáveis do template
         */
        fieldsTemplate: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            fields_template: this.fieldsTemplate
        };
    },
    watch: {
        fieldsTemplate() {
            this.fields_template = this.fieldsTemplate;
        },
        fields_template() {
            this.fieldChanged();
        }
    },
    methods: {
        fieldChanged() {
            this.$emit("changeField", this.fields_template);
        }
    },
    template: (
        /*html*/
        `
            <v-card elevation="0" class="bg-transparent" :width="width">
                <v-row no-gutters>
                    <v-col v-if="listTitle && listTitle.length !== ''" cols="12">
                        <v-card elevation="0" class="pt-4 px-4">
                            <v-row no-gutters>
                                <v-col cols="auto" v-for="(title, i) in listTitle" :key="i" class="ml-1">
                                    <span v-if="title.type === 'text'" class="body-2 bold text-gray-900" v-html="title.text" />
                                    <span v-else class="body-2 text-gray-900 bold">
                                        {{ "{" + title.text + "}" }}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card elevation="0" :class="(isCard ? '' : 'bg-gray-30 border-0 rounded-3') + ' pa-4'">
                            <v-row no-gutters>
                                <v-col :cols="message.type === 'break-line' ? 12 : 'auto'" v-for="(message, i) in listMessage" :key="i" class="ml-1">
                                    <v-row v-if="message.type === 'break-line'" >
                                        <v-col cols="12">
                                            <span class="body-2 text-gray-900">{{ listMessage[i-1].type === 'break-line' ? '&nbsp;' : '' }}</span>
                                        </v-col>
                                    </v-row>
                                    <span v-else-if="message.type === 'text'" class="body-2 text-gray-900" v-html="message.text" />
                                    <base-select
                                        v-else-if="selectOptions && Object.keys(selectOptions).length > 0"
                                        v-model="fields_template[message.text]"
                                        :items="selectOptions"
                                        label="Selecione uma op&ccedil;&atilde;o"
                                        tooltip-text="Selecione uma op&ccedil;&atilde;o para configurar seu arquivo"
                                        clearable
                                        @change="fieldChanged"
                                    />
                                    <span v-else class="body-2 text-gray-900 bold">
                                        {{ "{" + message.text + "}" }}
                                    </span>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};