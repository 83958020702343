export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Mais-Enabled" transform="translate(5 -41)">
                    <rect id="Retângulo_336" data-name="Retângulo 336" width="28" height="28" transform="translate(-5 41)" fill="currentColor" opacity="0"/>
                    <path id="three-dots-vertical" d="M19.254,21.121a2.315,2.315,0,1,1-2.315-2.315A2.315,2.315,0,0,1,19.254,21.121Zm0-7.716a2.315,2.315,0,1,1-2.315-2.315A2.315,2.315,0,0,1,19.254,13.405Zm0-7.716A2.315,2.315,0,1,1,16.94,3.375,2.315,2.315,0,0,1,19.254,5.69Z" transform="translate(-7.625 41.625)" fill="currentColor"/>
                </g>
            </svg>
        `
    )
};