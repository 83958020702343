import BaseDialog from "../moleculas/BaseDialog?v=3";
import BaseTextField from "../atomos/BaseTextField?v=2";

export default {
    components: {
        BaseDialog,
        BaseTextField
    },
    props: {
        /**
        * Valor para exibi��o ou n�o da modal
        */
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            show_dialog: this.value,
            stepSecond: false,
            successRequest: true,
            loadingRequest: false,
            icon_button: {
                "label": "Desativar", "variant": "danger", "icon": "icon-btn-desativar"
            },
            name: null,
            client: null,
            contactReason: null
        };
    },
    computed: {
        configButton() {
            return {
                primary: true,
                label: this.stepSecond ? "Tudo bem" : "Entrar em contato",
                icon: "mdi-check-bold",
                loading: this.loadingRequest
            };
        }
    },
    watch: {
        show_dialog() {
            /**
             * Evento de input, disparado sempre que o status de exibi��o/n�o exibi��o do dialog for alterado
             * 
             * @property {string} content Status da modal
             * 
             * @event input
             * @vue
             */
            this.$emit("input", this.show_dialog);
        },
        value() {
            this.show_dialog = this.value;
        }
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoSendSupportContactMail"]),
        formSubmit() {
            if (this.$refs.form_support.validate()) {
                // Exibe loading durante a request
                this.loadingRequest = true;

                // Campos do formul�rio
                let formData = {
                    name: this.name,
                    client: this.client,
                    contactReason: this.contactReason
                };

                this.ActionDoSendSupportContactMail(formData).then(response => {
                    this.successRequest = response;
                    this.loadingRequest = false;
                    this.stepSecond = true;

                    this.name = null;
                    this.client = null;
                    this.contactReason = null;
                });
            }
        },
        closeDialog() {
            this.show_dialog = !this.show_dialog;
            this.stepSecond = false;
        },
        clickDialog(data) {
            if (this.stepSecond) {
                this.closeDialog();
            } else {
                this.formSubmit(data);
            }
        }
    },
    template: (
        /*html*/
        `
        <base-dialog
            v-model="show_dialog"
            max-width="476"
            :action-button="configButton"
            use-icon-close
            :title="stepSecond ? 'Mensagem enviada com sucesso!' : undefined"
            @clickDialog="clickDialog"
            @closeDialog="closeDialog"
        >
            <v-row no-gutters>
                <v-col v-if="!stepSecond" cols="12">
                    <v-row justify="start" no-gutters>
                        <v-col cols="12">
                            <v-row class="justify-center" no-gutters>
                                <v-col cols="auto">
                                    <v-img
                                        src="/images/consultation-outline.svg"
                                        position="center"
                                        height="90px"
                                        width="100px"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="mt-4" no-gutters>
                                <v-col cols="12">
                                    <span class="subtitle-3 text-primary-blue-500">Est&#225; com d&uacute;vidas ou precisa de ajuda?</span>
                                </v-col>
                                <v-col cols="12" class="mt-4">
                                    <span class="body-2 text-gray-900">O time de suporte est&#225; preparado para te atender</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="center" no-gutters class="mt-4">
                        <v-col cols="auto">
                            <v-card elevation="0" class="border-1 border-gray-800 rounded-1 px-4 py-2">
                                <v-row justify="center" no-gutters>
                                    <v-col cols="auto">
                                        <v-icon size="16" color="gray-800">
                                            mdi-at
                                        </v-icon>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="auto">
                                        <span class="caption text-gray-800">suporte.pg@pgmais.com.br</span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-form ref="form_support" class="mt-4">
                        <v-row no-gutters class="gap-row-4">
                            <v-col cols="12" class="pt-0">
                                <base-text-field
                                    v-model="name"
                                    label="Nome"
                                    :required="true"
                                    title="Nome para contato"
                                    :use-icon-validation="true"
                                    :rules="[v => !!v || 'Informe o nome']"
                                    @enterPressed="formSubmit"
                                />
                            </v-col>
                            <v-col cols="12" class="pt-0">
                                <base-text-field
                                    v-model="client"
                                    label="Informe sua empresa"
                                    :required="true"
                                    title="Conta"
                                    :use-icon-validation="true"
                                    :rules="[v => !!v || 'Informe o cliente']"
                                    @enterPressed="formSubmit"
                                />
                            </v-col>
                            <v-col cols="12" class="pt-0">
                                <base-text-field
                                    v-model="contactReason"
                                    label="Escreva para n&oacute;s"
                                    :required="true"
                                    title="Em que podemos ajudar?"
                                    :use-icon-validation="true"
                                    :rules="[v => !!v || 'Informe o motivo']"
                                    @enterPressed="formSubmit"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-col>
                <v-col v-else>
                    <span class="body-2 text-gray-900">Obrigado por nos contatar, em breve nosso suporte retornar&aacute; o contato!</span>
                </v-col>
            </v-row>
        </base-dialog>
        `
    )
};