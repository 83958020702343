export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="34.073" height="28.99" viewBox="0 0 34.073 28.99">
                <g id="Btn-Multichat" transform="translate(-60.5 -60.45)">
                    <g id="Btn-Multichatbubble" transform="translate(-73 37)">
                        <path d="M147.652,50.09a10.675,10.675,0,0,1,8.355-14.34,11.081,11.081,0,0,0-1-1.978A10.683,10.683,0,0,0,137.028,45.32l-1.481,4.072,4.268-1.145a10.675,10.675,0,0,0,7.838,1.843Z" transform="translate(-1.249 -4.891)" fill="#e1e1e4" stroke="currentColor" stroke-width="1"/>
                        <path d="M159.45,53.346a10.468,10.468,0,1,1,19.87,4.579l1.4,5.144-4.639-1.27a10.468,10.468,0,0,1-16.631-8.453Z" transform="translate(-13.86 -12.001)" fill="none" stroke="currentColor" stroke-width="1"/>
                        <line x2="10" transform="translate(151.225 37.075)" fill="#fff" stroke="currentColor" stroke-linecap="round" stroke-width="1"/>
                        <line x2="10" transform="translate(151.225 41.331)" fill="#fff" stroke="currentColor" stroke-linecap="round" stroke-width="1"/>
                        <line x2="10" transform="translate(151.225 45.587)" fill="#fff" stroke="currentColor" stroke-linecap="round" stroke-width="1"/>
                    </g>
                </g>
            </svg>      
        `
    )
};