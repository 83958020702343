import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "campos-personalizados-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom xmlns="http://www.w3.org/2000/svg" width="18.671" height="20.557" viewBox="0 0 18.671 20.557">
                <g id="surface1" transform="translate(15.261 -7.75)">
                    <path id="Path_1277" data-name="Path 1277" d="M12.2,7.826V1.886h.038a.944.944,0,0,0,0-1.886H6.654a.944.944,0,0,0,0,1.886h.04v5.94l-3.483,5.6L.762,17.359a1.841,1.841,0,0,0-.22,1.889,1.845,1.845,0,0,0,1.72.81H16.628a1.84,1.84,0,0,0,1.72-.81,1.844,1.844,0,0,0-.22-1.889ZM6.654,1.336a.394.394,0,0,1,0-.786h5.581a.394.394,0,0,1,0,.786Zm4.99.55v.578h-4.4V1.886ZM7.2,8.055a.273.273,0,0,0,.043-.144v-4.9h4.4V7.908a.27.27,0,0,0,.042.145l3.17,5.089a6.246,6.246,0,0,1-5.285.184,7.545,7.545,0,0,0-5.478-.277ZM17.867,18.975a1.324,1.324,0,0,1-1.239.528H2.263a1.322,1.322,0,0,1-1.239-.528,1.331,1.331,0,0,1,.207-1.33l2.4-3.85c.489-.195,3.3-1.2,5.683.024a5.493,5.493,0,0,0,2.532.592,7.817,7.817,0,0,0,3.3-.792l2.51,4.033a1.3,1.3,0,0,1,.211,1.322Zm0,0" transform="translate(-15.37 8)" stroke="currentColor" stroke-width="0.5"/>
                </g>
            </svg-custom>
        `
    )
};