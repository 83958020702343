/**
 * Componente base para utilizacao em formularios
 * 
 * @displayName BaseBoxForm
 * @component
 * @category Atomos
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 */
export default {
    name: "base-box-form",
    template: (
        /*html*/
        `
            <v-row>
                <v-col class="p-0">
                    <div class="white rounded-sm pa-11">
                        <!-- @slot Slot que recebe o formul�rio -->
                        <slot></slot>
                    </div>
                </v-col>
            </v-row>
        `
    )
};