import TableThumb from "../../../components/moleculas/TableThumb";

export default {
    components: {
        TableThumb
    },
    data() {
        return {
            templates: [
                {
                    template: `{"url": "{url}"}`,
                    type: "I"
                },
                {
                    template: `RCHLO: {nome}, separamos uma condicao especial pra vc quitar sua divida na Riachuelo. Esta IMPERDIVEL! Acesse https://www.midway.com.br/campanha-quitacao`,
                    type: "M"
                },
                {
                    template: `{"text":"Hey {nome} ! Isso eh um teste de template de RCS modelo texto completo, que cont�m bot�es e sugest�es de respostas.","suggestions":[{"type":"text","label":"Quero receber novidades"},{"type":"text","label":"Sim"},{"type":"url","label":"Site da PGMais","value":"https://pgmais.com.br/"},{"type":"dial_phone","label":"Ligar para a PGMais","value":"55555541992292703"},{"type":"show_location","label":"Escrit�rio da PGMais","value":{"latitude":"-25.416071798508998","longitude":"-49.261896828850894"}},{"type":"request_location","label":"Compartilhar minha localiza��o"}]}`,
                    type: "M"
                },
                {
                    template: `{"cardWidth":"SHORT","contents":[{"title":"Carrosel imagem 1","description":"Este teste foi enviado pelo time de QA, nele foi utilizado o tamanho TALL para as imagens do carrosel! E enviado 4 op�oes de suggestions.","media":{"height":"TALL","file":{"url":"https://vitolavecchia.altervista.org/wp-content/uploads/2019/03/Tipologie-di-testing-software-Il-Test-automatizzato.png"}},"suggestions":[{"type":"text","label":"Op��o 1"},{"type": "request_location","label": "Compartilhar localiza��o"}]},{"title":"Carrosel imagem 2","description":"Este teste foi enviado pelo time de QA, nele foi utilizado o tamanho MEDIUM para as imagens do carrosel! E enviado 4 op�oes de suggestions.","media":{"height":"TALL","file":{"url":"https://vitolavecchia.altervista.org/wp-content/uploads/2019/03/Tipologie-di-testing-software-Il-Test-automatizzato.png"}},"suggestions":[{"type":"text","label":"Op��o 1"},{"type": "request_location","label": "Compartilhar localiza��o"}]}]}`,
                    type: "R"
                },
                {
                    template: `{"url": "https://static.wixstatic.com/media/7f45ba_0871ca61590149588b50b587bdb641c4~mv2.png"}`,
                    type: "I"
                },
                {
                    template: `{"orientation":"VERTICAL","alignment":"RIGHT","contents":{"description":"IMPERDIVEL! Hora de quitar sua divida com o Inter por apenas {qtde}x de {valor} hoje no Mega Sald�o","media":{"height":"SHORT","file":{"url":"https://acerto.blob.core.windows.net/emails-sistema/Design%20/Campanhas/Inter_mega_sald%C3%A3o_sms_2.png"}},"suggestions":[{"type":"url","label":"Quero essa oferta","value":"https://bancointer.meuacerto.com.br/?utm_source=crm&utm_medium=rcs_pgmais&utm_campaign=banco-inter_mega-saldao&utm_term=camp-cv-qbr_teste-c&utm_content=null_null_20052022"}]}}`,
                    type: "C"
                }
            ]
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center" class="mt-16">
                <v-col cols="12" sm="6" md="4" lg="1" class="bg-white">
                    <v-row>
                        <v-col cols="12" v-for="template in templates">
                            <table-thumb :ds-template="template.template" :type-template="template.type" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
     
        `
    )
};