import BaseInformation from "../../../../components/atomos/BaseInformation?v=3";
import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";

/**
 * Componente de dialog para mensagem de erro ao realizar acao
 * <iframe src="http://localhost:8080/v2/documentacao/dashboard/dialog-fail-usage" style="width: 100%; height: 315px;"></iframe>
 * 
 * @requires {@link BaseInformation}
 * @requires {@link BaseDialog}
 * 
 * @displayName DialogFail
 * @category Page / Dashboard
 * @subcategory dialogs
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseInformation,
        BaseDialog
    },
    props: {
        /**
         * Valor do atributo v-model do componente, indica se o dialog deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Mensagem a ser exibida no texto de alerta
         */
        alertText: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            dialog: this.value
        };
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog() {
            /**
             * Emite o status do dialog (false ou true)
             * @property {Boolean} dialog
             * 
             * @event input
             * @vue
             */
            this.$emit("input", this.dialog);
        }
    },
    template: (
        /*html*/
        `
            <base-dialog 
                v-model="dialog"
                title="Erro!"
                use-icon-close
                max-width="520px"
                cancel
            >
                <v-card elevation="0">
                    <v-row>
                        <v-col cols="12">
                            <base-information
                                :description="alertText"
                                icon="mdi-alert-circle"
                                border-color="border-danger"
                                icon-color="danger"
                            ></base-information>
                        </v-col>
                        <v-col cols="12">
                            <span>Tente realizar a a&ccedil;&atilde;o mais tarde</span>
                        </v-col>
                    </v-row>
                </v-card>
            </base-dialog>
        `
    )
};