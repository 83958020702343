export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g id="Btn-PermissoesInternas" transform="translate(-1695 -2098)">
                    <g id="Ico-PermInt-Enabled" transform="translate(1696.895 2038)">
                        <path id="Caminho_504" data-name="Caminho 504" d="M19,8a5.222,5.222,0,0,0-2.1-.4,5.853,5.853,0,0,0-1.3.2V2.6A2.521,2.521,0,0,0,13,0H2.6A2.647,2.647,0,0,0,0,2.6V17.1a2.647,2.647,0,0,0,2.6,2.6H6.2c0,.1-.1.3-.1.4v2.1a1.538,1.538,0,0,0,1.5,1.5H9.5A1.538,1.538,0,0,0,11,22.2V22h.1a1.538,1.538,0,0,0,1.5-1.5v-.2h.3a2.1,2.1,0,0,0,1.1-.4L15.9,18a4.1,4.1,0,0,0,1.1.1,5.1,5.1,0,0,0,4.8-3.2A5.325,5.325,0,0,0,19,8ZM1.5,17.1V2.6A1.11,1.11,0,0,1,2.6,1.5H13a1.11,1.11,0,0,1,1.1,1.1V8.4A6.111,6.111,0,0,0,12,10.8a5.789,5.789,0,0,0-.3,3.2L7.3,18.4c-.1,0-.2-.1-.3-.1H2.6A1.278,1.278,0,0,1,1.5,17.1Zm18.9-2.8A3.793,3.793,0,0,1,17,16.6a4.013,4.013,0,0,1-1.5-.3L13,18.8H11.2v1.7H9.5v1.7H7.6V20.1l5.8-5.8a3.9,3.9,0,0,1,0-3A3.793,3.793,0,0,1,16.8,9a4.013,4.013,0,0,1,1.5.3A3.858,3.858,0,0,1,20.4,14.3Z" transform="translate(0.105 61)" fill="currentColor"/>
                        <g id="Grupo_1971" data-name="Grupo 1971" transform="translate(0.105 61)">
                            <path id="Caminho_500" data-name="Caminho 500" d="M3.7,5.8h8.2a.789.789,0,0,0,.8-.8.736.736,0,0,0-.8-.8H3.7A.849.849,0,0,0,3,5,.773.773,0,0,0,3.7,5.8Z" fill="currentColor"/>
                            <path id="Caminho_501" data-name="Caminho 501" d="M3.7,9h7.2a.789.789,0,0,0,.8-.8.736.736,0,0,0-.8-.8H3.7a.961.961,0,0,0-.7.9A.684.684,0,0,0,3.7,9Z" fill="currentColor"/>
                            <path id="Caminho_502" data-name="Caminho 502" d="M3.7,12.3H9.9a.789.789,0,0,0,.8-.8.736.736,0,0,0-.8-.8H3.7a.8.8,0,0,0,0,1.6Z" fill="currentColor"/>
                            <path id="Caminho_503" data-name="Caminho 503" d="M8.6,14.8a.789.789,0,0,0-.8-.8H3.7a.773.773,0,0,0-.7.8.789.789,0,0,0,.8.8H7.9A.969.969,0,0,0,8.6,14.8Z" fill="currentColor"/>
                            <path id="Caminho_505" data-name="Caminho 505" d="M16.9,11.7A1.1,1.1,0,1,0,18,12.8,1.11,1.11,0,0,0,16.9,11.7Z" fill="currentColor"/>
                        </g>
                    </g>
                    <rect id="Retângulo_471" data-name="Retângulo 471" width="26" height="26" transform="translate(1695 2098)" fill="currentColor" opacity="0"/>
                </g>
            </svg>
        `
    )
};