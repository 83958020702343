import FilterPageSlider from "../../../components/atomos/FilterPageSlider";
import BaseAutocomplete from "../../../components/atomos/BaseAutocomplete";
import BaseTextField from "../../../components/atomos/BaseTextField";
import BaseDatePicker from "../../../components/atomos/BaseDatePicker";
import BaseButton from "../../../components/atomos/BaseButton";

/**
 * Componente para documenta��o do componente FilterPageSlider
 * 
 * @requires {@link BaseAutocomplete}
 * @requires {@link BaseTextField}
 * @requires {@link BaseDatePicker}
 * @requires {@link BaseButton}
 * 
 * @displayName FilterPageSliderUsage
 * @category Atomos
 * @author Bruno Dias Ramos <bruno.dias@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */

export default {
    name: "FilterPageSliderUsage",
    components: {
        FilterPageSlider,
        BaseAutocomplete,
        BaseTextField,
        BaseDatePicker,
        BaseButton
    },

    data() {
        return {
            status: "",
            situation_loading: false,
            situation_list: [
                {"value": "WAITING", "text": "Aguardando"},
                {"value": "CANCELED", "text": "Cancelado"},
                {"value": "NOT_SENT", "text": window.Vue.htmlEntities("N&atilde;o enviado")},
                {"value": "SENT", "text": "Enviado"},
                {"value": "NOT_DELIVERED", "text": window.Vue.htmlEntities("N&atilde;o entregue")},
                {"value": "DELIVERED", "text": "Entregue"}
            ],
            campo_informado: "",
            data_inicio: "",
            data_fim: ""
        };
    },

    template: (
        /*html*/
        `
        <filter-page-slider>
            <v-form ref="form">
                <v-row class="mt-0" justify="center">
                    <v-col cols="6" lg="3">
                        <base-autocomplete
                            v-model="status"
                            tooltip-text="Voc&ecirc; pode selecionar uma ou mais situa&ccedil;&otilde;es(status) dos disparos."
                            label="Situa&ccedil;&atilde;o"
                            title="Situa&ccedil;&atilde;o"
                            name="situation"
                            :items="situation_list"
                            :rules="[]"
                            :loading="situation_loading"
                            multiple
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="6" lg="3">
                        <base-text-field
                            v-model="campo_informado"
                            tooltip-text="Voc&ecirc; pode buscar por um campo espec&iacute;fico(se houver em seu arquivo de dados)"
                            label="Campo Informado"
                            title="Campo Informado"
                            name="informed_field"
                            use-icon-validation
                            />
                    </v-col>
                    <v-col cols="6" lg="3">
                        <base-date-picker
                            v-model="data_inicio"
                            tooltip-text="Escolha um per&iacute;odo para pesquisar"
                            label=""
                            title="Data in&iacute;cio"
                            name="start_date"
                            :max-date="data_fim"
                            :close-on-click="true"
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="6" lg="3">
                        <base-date-picker
                            v-model="data_fim"
                            tooltip-text="Escolha um per&iacute;odo para pesquisar"
                            label=""
                            title="Data fim"
                            name="end_date"
                            :min-date="data_inicio"
                            :close-on-click="true"
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="12" lg="3" class="pt-6">
                        <base-button
                            block
                            icon="fa fa-filter"
                            label="Aplicar Filtros"
                            variant="extra-dark-grey"
                            :disabled="false"
                            @click="formSubmit()"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </filter-page-slider>
        `
    )
};