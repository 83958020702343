import AddMessageTemplate from "../../template-rcs/components/AddMessageTemplate";

export default {
    components: {
        AddMessageTemplate
    },
    data() {
        return {
            teste: null
        };
    },
    watch: {
        teste(new_value) {
            console.log(new_value);
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center" class="bg-background">
                <v-col cols="12" md="3">
                    <add-message-template v-model="teste"></add-message-template>
                </v-col>
            </v-row>
        `
    )
};