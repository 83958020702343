import BaseCardLarge from "../atomos/BaseCardLarge";

/**
 * Componente para escolha de do tipo de mensagem
 *
 * @displayName CardLargeChoice
 * @requires {@link BaseCardLarge}
 * @category Moleculas
 * @component
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseCardLarge
    },
    props: {
        /**
         * Array com os dados dos botoes que vao estar no componente
         */
        value: {
            type: Array,
            required: true
        },
        /**
         * Indica a quantidade de colunas que cada botão deve ocupar
         * @values auto, [1-12], full
         */
        columns: {
            type: [String, Number],
            required: false,
            default: "auto"
        },
        /**
         * Coloca os botoes na horizontal
         */
        horizontal: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Deixa tudo em uma linha somente
         */
        inline: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Tamanho customizado do icone
         */
        size: {
            type: Number,
            required: false,
            default: 29
        }
    },
    data() {
        return {
            buttons: _.cloneDeep(this.value)
        };
    },
    computed: {
        content_row() {
            return this.separarArray(this.value);
        }
    },
    watch: {
        value() {
            this.buttons = _.cloneDeep(this.value);
        }
    },
    methods: {
        separarArray(list) {
            let new_list = [];
            let row = 0;
            let max =
                12 /
                (this.columns !== "full" && this.columns !== "auto"
                    ? this.columns
                    : 12);

            for (let index = 0; index < list.length; index++) {
                if (new_list[row] === undefined) {
                    new_list[row] = [];
                }

                new_list[row].push(list[index]);

                if ((index + 1) % max === 0) {
                    row += 1;
                }
            }

            return new_list;
        },
        /**
         * Funcao que para vincular no botao
         * @param {String} type_selected
         */
        clickButton(type_selected) {
            let selected = null;

            for (let button of this.buttons) {
                if (type_selected != button.type) {
                    button.selected = false;
                } else {
                    button.selected = true;
                    selected = button;
                }
            }

            /**
             * Evento de click do botao
             * @property {Array} buttons Lista de botoes atualizada
             * @event click
             * @vue
             */
            this.$emit("input", this.buttons);
            this.$emit("click", selected);
        }
    },
    template:
        /*html*/
        `
        <v-row no-gutters :class="inline ? '' : 'fill-height'">
            <v-col :class="inline ? 'd-flex flex-nowrap gap-4 overflow-x-auto' : ''">
                <v-row v-for="(row, i1) in content_row" :key="i1" no-gutters :class="'gap-4 flex-nowrap ' + (inline ? 'fill-height' : 'mb-4') ">
                    <v-col v-for="(item, i2) in row" :key="i2" class="flex-1-1-auto" :cols="columns">
                        <base-card-large
                            v-model="item.selected"
                            :id="'cardLargeChoice' + item.type"
                            :icon='item.icon' 
                            :title='item.title'
                            :description="item.description"
                            :is-horizontal="item.horizontal || horizontal"
                            :disabled="item.disable"
                            :block-unselect="true"
                            :size="size"
                            @click="clickButton(item.type)"
                        ></base-card-large>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    `
};
