import FormFilter from "../../dashboard/components/forms/FormFilter";

export default {
    components: {
        FormFilter
    },
    data() {
        return {
            content: {
                campaigns_id: [],
                clients: [],
                status: []
            }
        };
    },
    methods: {
        submit(teste) {
            console.log(teste);
        }
    },
    watch: {
        content() {
            console.log(this.content);
        }
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="10">
                <form-filter v-model="content" @input="submit"> 
                </form-filter>     
            </v-col>
        </v-row>
        `
    )
};