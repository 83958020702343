import CardLargeChoice from "../moleculas/CardLargeChoice";
import BaseSectionForm from "../atomos/BaseSectionForm?v=2";
/**
 * Componente para configuracao de template
 * @displayName Cards Configuracao
 * @requires CardLargeChoice
 * @requires BaseSectionForm
 * @category Organismos
 * @component
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 * @vue
 */
export default ({
    name: "cards-configuracao",
    components: {CardLargeChoice,
        BaseSectionForm},
    props: {
        /**
       * Titulo do componente 
       */
        title: {
            type: String,
            required: true
        },
        /**
        * Array com os dados dos  botoes
        **/
        list_buttons: {
            type: Array,
            required: true
        }
    },
    methods: {
        /**
       * Funcao para vincular no componente CardLargeChoice
       * @param {String} value 
       */
        selectOption(value) {

            /**
              * Evento de selectOption do botao
              * @property {string} value 
              * @category Organismos
              * @event selectOption
              * @vue
              */
            this.$emit("selectOption", value);
        }
    },
    template:
    /*html*/
    ` 
    <base-section-form :title="title">
      <v-row class="mt-5"  no-gutters>
        <v-col cols="12">   
          <card-large-choice @click="selectOption" :list-buttons="list_buttons"></card-large-choice>
        </v-col>
      </v-row>
    </base-section-form>
    `,
});
