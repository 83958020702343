import BaseSelect from "./BaseSelect";

/**
 * Componente para pre visualizacao de imagens
 * 
 * @requires {@link BaseSelect}
 * 
 * @displayName PreviewImage
 * @category Atomos
 * @author David dos Satnos Andrade <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseSelect
    },
    props: {
        /**
         * URL da imagem a ser exibida
         */
        image: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Indica que o conteudo a ser preenchido eh variavel de um arquivo de importacao
         */
        variable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Altura da imagem
         */
        height: {
            type: [Number, String],
            required: false,
            default: undefined
        },
        /**
         * Largura da imagem
         */
        width: {
            type: [Number, String],
            required: false,
            default: undefined
        },
        /**
         * Indica que sera usado para configurar um card
         */
        isCard: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica que sera usado para configurar um card na vertical
         */
        isVertical: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica que sera usado para configurar um card para a esquerda
         */
        isLeft: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Lista de opcoes que podem ser utilizadas em um select
         */
        selectOptions: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Campos variaveis do template
         */
        fieldsTemplate: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            error: false,
            fields_template: this.fieldsTemplate,
            image_select: null
        };
    },
    watch: {
        image() {
            this.error = false;
        },
        fieldsTemplate() {
            this.fields_template = this.fieldsTemplate;
        },
        fields_template() {
            this.fieldChanged();
        },
        selectOptions() {
            this.setImageSelect();
        }
    },
    methods: {
        fieldChanged() {
            this.$emit("changeField", this.fields_template);
            this.setImageSelect();
        },
        setImageSelect() {
            for (let option of this.selectOptions) {
                if (option.value === this.fields_template["url"]) {
                    this.image_select = option.text;
                    break;
                } else {
                    this.image_select = null;
                }
            }
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters :class="isCard && !isVertical ? 'fill-height' : ''">
                <v-col cols="12" :class="isCard && !isVertical ? 'fill-height' : ''">
                    <v-card elevation="0" :class="isCard ? (!isVertical ? (isLeft ? 'rounded-left-3 fill-height rounded-right-0' : 'rounded-right-3 fill-height rounded-left-0') : 'rounded-top-3 rounded-botom-0') : 'rounded-3'">
                        <v-img
                            v-if="!variable && !error"
                            :src="image"
                            :min-height="height"
                            :height="isVertical ? height : '100%'"
                            :width="width"
                            @error="error = true"
                        />
                        <v-card
                            v-else-if="!variable && error"
                            elevation="0"
                            :min-height="height"
                            :height="height !== undefined ? (isVertical ? height : '100%') : 200"
                            :width="width"
                            class="rounded-1 bg-gray-50 overflow-hidden"
                        >
                            <v-row no-gutters align="center" class="fill-height">
                                <v-col cols="12">
                                    <v-row no-gutters justify="center">
                                        <v-col cols="auto">
                                            <v-icon color="error-500" size="50">
                                                $iconInvalid
                                            </v-icon>
                                        </v-col>
                                        <v-col cols="12" class="text-center">
                                            <span class="body-2 text-error-500">N&atilde;o foi poss&iacute;vel carregar a imagem</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-row v-else-if="selectOptions && Object.keys(selectOptions).length > 0" no-gutters>
                            <v-col cols="12" class="mt-2">
                                <v-img
                                    :src="image_select"
                                    :min-height="height"
                                    :height="isVertical ? height : '100%'"
                                    :width="width"
                                />
                            </v-col>
                            <v-col cols="12">
                                <base-select
                                    v-model="fields_template['url']"
                                    :items="selectOptions"
                                    title="URL da imagem"
                                    label="Selecione uma op&ccedil;&atilde;o"
                                    tooltip-text="Selecione uma op&ccedil;&atilde;o para configurar seu arquivo"
                                    prepend-icon="$placeholderVariableImagesIcon"
                                    clearable
                                    @change="fieldChanged"
                                />
                            </v-col>
                        </v-row>
                        <v-card
                            v-else
                            elevation="0"
                            class="bg-primary-blue-50 pa-4 overflow-hidden"
                            :min-height="height"
                            :height="height !== undefined ? (isVertical ? height : '100%') : 200"
                            :width="width"
                        >
                            <v-row no-gutters class="fill-height" align="center">
                                <v-col cols="12">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="text-center">
                                            <v-icon size="62">
                                                $placeholderVariableImagesIcon
                                            </v-icon>
                                        </v-col>
                                        <v-col cols="12" class="text-center mt-3">
                                            <span class="text-primary-blue-500 body-2">A URL da imagem ser&aacute; extra&iacute;da do arquivo de dados</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-card>
                </v-col>
            </v-row>
        `
    )
};