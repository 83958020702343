import { DataTableHeaderClass } from "../../../classes/DataTableHeaderClass";
import BaseInformation from "../../../components/atomos/BaseInformation";
import BaseTooltip from "../../../components/atomos/BaseTooltip";
import BaseDialog from "../../../components/moleculas/BaseDialog";
import BaseDataTable from "../../../components/organismos/BaseDataTable";

/**
 * Componente para exibir as regras de horario da empresa
 * 
 * @requires {@link BaseInformation}
 * @requires {@link BaseTooltip}
 * @requires {@link BaseDialog}
 * @requires {@link BaseDataTable}
 * 
 * @displayName TimeRules
 * @component
 * @vue
 * @category Atomos
 * @author David dos Santos Andrade <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseInformation,
        BaseTooltip,
        BaseDialog,
        BaseDataTable
    },
    filters: {
        formatHour(hour) {
            if (hour) {
                return dayjs("2023-01-01 " + hour).format("HH:mm");
            } else {
                return "";
            }
        }
    },
    props: {
        /**
         * Horario de inicio para dias uteis
         */
        weekStart: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Horario de finalizacao para dias uteis
         */
        weekEnd: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Horario de inicio para sabados
         */
        saturdayStart: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Horario de finalizacao para sabados
         */
        saturdayEnd: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Bloqueio de criacao de campanha
         */
        lockActivated: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Inicio do bloqueio de criacao
         */
        lockStart: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Tipo de bloqueio, por carteira ou empresa pai
         */
        lockType: {
            type: String,
            required: false,
            default: null,
            validator(v) {
                return ["company", "client"].indexOf(v) > -1;
            }
        },
        /**
         * Regras de DDD
         */
        dddRules: {
            type: Array,
            required: false,
            default: null
        },
        /**
         * Controla o status de carregando
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe horario limite para criacao de campanhas
         */
        showCampaign: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Background color
         */
        backgroundColor: {
            type: String,
            required: false,
            default: "gray-30"
        },
    },
    data() {
        return {
            show_dialog_ddd: false,
            table_headers: [
                new DataTableHeaderClass({ text: "Estado", value: "estado", filterable: true }),
                new DataTableHeaderClass({ text: "DDD's", value: "ddds", sortable: false }),
                new DataTableHeaderClass({ text: window.Vue.htmlEntities("Liberar hor&aacute;rio de"), value: "hora_inicio", sortable: false }),
                new DataTableHeaderClass({ text: window.Vue.htmlEntities("At&eacute;"), value: "hora_fim", sortable: false }),
                new DataTableHeaderClass({ text: "Dias da semana", value: "dia", sortable: false }),
                new DataTableHeaderClass({ text: "Bloqueio o dia todo", value: "dia_todo", sortable: false })
            ],
            custom_slots: [
                "hora_inicio",
                "hora_fim",
                "dia_todo"
            ]
        };
    },
    computed: {
        hasWeekRules() {
            return this.weekStart || this.weekEnd;
        },
        hasSaturdayRules() {
            return this.saturdayStart || this.saturdayEnd;
        },
        hasLockRules() {
            return this.lockActivated && this.lockStart;
        },
        weekTooltipText() {
            return this.hasWeekRules
                ? "Para alterar os hor&aacute;rios desta carteira, v&aacute; at&eacute; a aba <b>Cadastros > Clientes</b> e clique em <b>Alterar registro</b>"
                : "Para definir um hor&aacute;rio para esta carteira, v&aacute; at&eacute; a aba <b>Cadastros > Clientes</b> e clique em <b>Alterar registro</b>";
        },
        saturdayTooltipText() {
            let text = "Para definir um limite geral v&aacute; at&eacute; a aba <b>Cadastros > Configura&ccedil;&otilde;es</b> ou, para definir um limite para a carteira, v&aacute; at&eacute; a aba <b>Cadastros > Clientes</b> e clique em <b>Alterar registro</b>";

            if (this.hasLockRules && this.lockType === "client") {
                text = "Para alterar o limite desta carteira, v&aacute; at&eacute; a aba <b>Cadastros > Clientes</b> e clique em <b>Alterar registro</b>";
            } else if (this.hasLockRules && this.lockType === "company") {
                text = "Para alterar o limite geral, v&aacute; at&eacute; a aba <b>Cadastros>Configura&ccedil;&otilde;es</b> Voc&ecirc; tamb&eacute;m pode inserir um hor&aacute;rio limite espec&iacute;fico para a carteira, v&aacute; at&eacute; a aba <b>Cadastros > Clientes</b> e clique em <b>Alterar registro</b>";
            }

            return text;
        },
        borderColor() {
            if (this.hasLockRules && dayjs(this.lockStart, "HH:mm").isBefore(dayjs(), "minute")) {
                return "error-500";
            } else {
                return undefined;
            }
        }
    },
    template:
        /*html*/
        `
        <v-row no-gutters>
            <v-col v-if="!loading" cols="12">
                <base-information :border-color="borderColor" :icon-color="borderColor" :background-color="backgroundColor">
                    <template #customDescription>
                        <v-row no-gutters class="pa-4">
                            <v-col cols="12">
                                <base-tooltip :text="weekTooltipText">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <span class="body-2 text-gray-900 bold">Hor&aacute;rios permitidos para agendamento</span>
                                        </v-col>
                                        <v-col v-if="hasWeekRules" cols="12" class="mt-2">
                                            <span class="body-2 text-gray-900">{{ weekStart || '00:00' }} &agrave;s {{ weekEnd || '23:59' }} | Segunda a sexta</span>
                                        </v-col>
                                        <v-col v-if="hasSaturdayRules" cols="12" class="mt-1">
                                            <span class="body-2 text-gray-900">{{ saturdayStart || '00:00' }} &agrave;s {{ saturdayEnd || '23:59' }} | S&aacute;bados</span>
                                        </v-col>
                                        <v-col v-if="!hasWeekRules && !hasSaturdayRules" cols="12" class="mt-2">
                                            <span class="body-2 text-gray-900">N&atilde;o h&aacute; bloqueios de hor&aacute;rio para agendamento</span>
                                        </v-col>
                                    </v-row>
                                </base-tooltip>
                                <base-tooltip v-if="showCampaign" :text="saturdayTooltipText">
                                    <v-row no-gutters class="mt-4">
                                        <v-col cols="12">
                                            <span class="body-2 text-gray-900 bold">Cria&ccedil;&atilde;o de campanhas {{ lockType === "client" ? "(configura&ccedil;&atilde;o da carteira)" : (lockType === "company" ? "(configura&ccedil;&atilde;o Geral)" : "") }}</span>
                                        </v-col>
                                        <v-col cols="12" class="mt-2">
                                            <span v-if="hasLockRules" class="body-2 text-gray-900">Hor&aacute;rio limite para cria&ccedil;&atilde;o de campanhas: {{ lockStart }}</span>
                                            <span v-else class="body-2 text-gray-900">N&atilde;o h&aacute; hor&aacute;rio limite para cria&ccedil;&atilde;o de campanhas</span>
                                        </v-col>
                                    </v-row>
                                </base-tooltip>
                                <base-tooltip v-if="dddRules?.length" text="Esta carteira possui regras de hor&aacute;rio de disparo por <b>DDD</b>, clique para visualizar">
                                    <v-row no-gutters class="mt-4">
                                        <v-col cols="12">
                                            <a class="body-2 text-gray-900 bold" @click="show_dialog_ddd = true">Ver regras de DDD</a>
                                        </v-col>
                                    </v-row>
                                </base-tooltip>
                            </v-col>
                        </v-row>
                    </template>
                </base-information>
            </v-col>
            <v-col v-else cols="12">
                <v-skeleton-loader type="image" height="150" />
            </v-col>
            <base-dialog
                v-model="show_dialog_ddd"
                title="Regras de DDD"
                cancel
                use-icon-close
                max-width="900px"

            >
                <base-data-table
                    :headers="table_headers"
                    :list-items="dddRules ?? []"
                    filterable
                    :custom-columns="false"
                    dense
                    sort-by="estado"
                    :sort-desc="false"
                    :custom-slots="custom_slots"
                >
                    <template #hora_inicio="props">
                        <span v-if="!props.item.dia_todo" class="body-2 text-gray-900">{{ props.item.hora_inicio | formatHour }}</span>
                    </template>
                    <template #hora_fim="props">
                        <span v-if="!props.item.dia_todo" class="body-2 text-gray-900">{{ props.item.hora_fim | formatHour }}</span>
                    </template>
                    <template #dia_todo="props">
                        <v-checkbox
                            v-model="props.item.dia_todo"
                            readonly
                            class="ma-0 pa-0"
                            hide-details
                            dense
                            color="primary-blue-500"
                        />
                    </template>
                </base-data-table>
            </base-dialog>
        </v-row>
        `
};