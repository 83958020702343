import RouterPage from "../RouterPage";
import BlacklistPage from "./BlacklistPage";

export default [
    {
        path: "blacklist",
        name: "blacklist",
        component: RouterPage,
        children: [
            {
                path: "list",
                name: "blacklistPage",
                component: BlacklistPage
            }
        ]
    }
];
