import services from "../../../http/index";

/**
 * Faz a requisição para a API solicitando a lista de relatórios de acordo com os parametros informados
 * 
 * @param {Object} params Parametros para filtrar os dados
 * <pre>{
 *     modalidade: String - Modalidade do relatorio (analytical, synthetic, radar, blacklist, interactive, action-history, messages, phone, api-shipping, phones-company, single-shipping, blocked, movement-statement, custom-export, file-status, single-shipping-operator, search, billing, synthetic-corporate, credit, movement-statistics, general-movement-statement, phone-validation, joker)
 *     data_solicitacao: Number - Periodo de solicitacao (7, 15, 30)
 *     solicitante: String - Nome do solicitante
 *     status: String - Status do relatorio (P = Em progresso, F = Finalizado, E = Erro, R = Removido)
 * }</pre>
 * @returns {Object} Objeto com a lista de relatorios
 */
export const ActionDoGetListReport = (context, params) => {
    return services.api.request({
        url: "/report/list",
        method: "post",
        data: params
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
};

/**
 * 
 * @param {Object} params Parametros a serem enviados para a solicitacao de download
 * <pre>{
 *     report_id: Number - ID do relatorio a ser baixado
 *     type: String - Tipo do download TXT ou CSV
 * }</pre>
 * @returns {Object} Objeto com as informacoes do relatorio
 */
export const ActionDoDownloadReport = (context, params) => {
    return services.api.request({
        url: "/report/download",
        method: "post",
        data: params,
        responseType: "blob"
    }).then(response => {
        return response;
    }).catch(error => {
        throw error;
    });
};

/**
 * 
 * @param {Object} params Parametros a serem enviados para a solicitacao de download
 * <pre>{
 *     type_report: String - Tipo do report
 *     data: Object - Conteudo da requisicao
 * }</pre>
 * @returns {Object} Objeto com as informacoes do relatorio
 */
export const ActionDoGenerateReport = (context, params) => {
    return services.api.request({
        url: "/report/" + params.type_report,
        method: "post",
        data: params.data
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
};

/**
 * Cria uma nova recorrencia de relatorio
 * 
 * @param {Object} params Conteudo da requisicao
 * @returns {Promise}
 */
export const ActionDoSetRecurrence = (context, params) => {
    return services.api.request({
        url: "/report/recurrent",
        method: "post",
        data: params
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
};

/**
 * Remove uma recorrencia de relatorio
 * 
 * @param {Object} params Conteudo da requisicao
 * @returns {Promise}
 */
export const ActionDoRemoveRecurrence = (context, params) => {
    return services.api.request({
        url: "/report/delete-recurrent",
        method: "post",
        data: params
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
};

/**
 * Busca a lista de clientes
 * 
 * @param {Object} params  Conteudo da requisicao
 * @returns {Promise}
 */
export const ActionDoGetListClients = () => {
    return services.api.request({
        url: "empresas/list-clients",
        method: "post"
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
};

/**
 * Busca a lista de campanhas
 * 
 * @param {Object} params  Conteudo da requisicao
 * @returns {Promise}
 */
export const ActionDoGetListCampaigns = (context, params) => {
    return services.api.request({
        url: "campaign/list",
        method: "post",
        data: params
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
};