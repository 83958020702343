import BasePill from "../../../components/atomos/BasePill";

/**
 * Componente para configurar respostas rapidas no cadastro de template
 * 
 * @requires {@link BasePill}
 * 
 * @component
 * @displayName ConfigRespostasRapidas
 * @category Page / Template RCS
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BasePill
    },
    model: {
        prop: "listChips",
        event: "change"
    },
    props: {
        /**
         * Lista de chips a serem exibidos
         */
        listChips: {
            type: Array,
            required: true
        },
        /**
         * Define o nome do grupo draggable
         */
        groupName: {
            type: String,
            required: false,
            default: "groupRepostasRapidas"
        },
        /**
         * Define a largura do componente
         */
        width: {
            type: [Number, String],
            required: false,
            default: undefined
        },
        /**
         * Limite de suggestions que podem compor a template
         */
        suggestionsLimit: {
            type: Number,
            required: false,
            default: 10
        }
    },
    data() {
        return {
            list: _.cloneDeep(this.listChips)
        };
    },
    watch: {
        listChips(new_value) {
            this.list = _.cloneDeep(new_value);
        }
    },
    methods: {
        /**
         * Emite um evento informando que uma notificacao deve ser exibida
         * @param {Object} data 
         */
        showNotify(data) {
            this.$emit("showNotify", data);
        },
        /**
         * Informa que a lista de items foi alterada
         * @vue
         */
        changeList() {
            /**
             * Evento que emite a lista de items sempre que for alterada, este evento pode ser capturado pelo v-model
             * 
             * @property {Array} list Lista de items
             * 
             * @event change
             * @vue
            */
            this.$emit("change", this.list);
        },
        /**
         * Remove um item do tipo pill do array
         * @param {Number} position Posicao do item no array
         * @vue
         */
        removeItem(position) {
            this.showNotify({
                notify_title: this.list[position].pill.type ? "Bot&atilde;o removido" : "Resposta r&aacute;pida removida",
                notify_message: this.list[position].pill.type ? `O bot&atilde;o <b>${this.list[position].text}</b> foi removido da mensagem` : `A resposta r&aacute;pida <b>${this.list[position].text}</b> foi removida da mensagem`,
                removed_pill: this.list[position],
                position_removed: position,
                notify_button_label: "Reinserir " + this.list[position].pill.type ? "bot&atilde;o" : "resposta",
                restore_type: "remove"
            });

            this.list.splice(position, 1);
            this.changeList();
        },
        log(text) {
            console.log(text);
        }
    },
    template: (
        /*html*/
        `
            <v-card elevation="0" class="bg-transparent fill-height" :width="width">
                <v-row no-gutters align="end" class="fill-height">
                    <v-col cols="12">
                        <draggable
                            :list="list"
                            tag="v-row"
                            class="no-gutters"
                            :group="{ name: groupName, put: true, pull: false }"
                            @sort="changeList"
                        >
                            <v-col
                                cols="auto"
                                v-for="(item, i) in list"
                                :key="i"
                                class="pr-1 pb-1"
                            >
                                <base-pill
                                    :title="item.pill.title"
                                    :title-icon="item.pill.titleIcon"
                                    :prepend-icon="item.pill.selected ? 'mdi-arrow-all' : item.pill.prependIcon"
                                    :selected="item.pill.selected"
                                    selected-text="Reposicionando"
                                    :draggable="true"
                                    :editable="false"
                                    :deletable="false"
                                    :removable="true"
                                    @remove="removeItem(i)"
                                    @click="$emit('clickItem', item)"
                                ></base-pill>
                            </v-col>
                        </draggable>
                    </v-col>
                    <v-col v-if="list.length > 1" cols="12" class="bg-gray-10 mt-4">
                        <v-row no-gutters justify="center" class="mt-2">
                            <v-col cols="auto" class="pr-1">
                                <v-card elevation="0" class="bg-gray-50 rounded-1 text-center align-center align-items-center align-content-center align-self-center align-center" width="20" height="20">
                                    <v-icon small class="mb-1" color="gray-500">mdi-arrow-left</v-icon>
                                </v-card>
                            </v-col>
                            <v-col cols="auto" class="pl-1">
                                <v-card elevation="0" class="bg-gray-50 rounded-1 text-center align-center align-items-center align-content-center align-self-center align-center" width="20" height="20">
                                    <v-icon small class="mb-1" color="gray-500">mdi-arrow-right</v-icon>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="mt-2 text-center">
                                <span class="caption text-gray-500">Arraste ou utilize o teclado para reposicionar</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="text-end">
                        <v-divider :class="'mb-1 ' + (list.length > 1 ? 'mt-0' : 'mt-4')"></v-divider>
                        <span :class="'caption ' + (list.length > suggestionsLimit ? 'text-error-900' : 'text-gray-500')">{{ list.length }}/{{ suggestionsLimit }} itens</span>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};