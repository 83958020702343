import BaseDatePicker from "../../../components/atomos/BaseDatePicker";

export default {
    components: { BaseDatePicker },
    data() {
        return {
            rules: [
                (value) => !!value || "Campo obrigat�rio",
                (value) =>
                    (value && value > this.dataInicio) ||
                    "Data inicio maior que data fim"
            ],
            dataInicio: "",
            dataFim: ""
        };
    },
    watch: {
        rules(ev) {
            console.log(ev);
        }
    },
    methods: {
        pegaHoje(ev) {
            this.dataInicio = ev;
            this.$emit("dataInicio", ev);
        },
        pegaFim(ev) {
            this.dataFim = ev;
            this.$emit("dataFim", ev);
        }
    },
    template:
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="3">
                    <base-date-picker                            
                            tooltip-text="Escolha uma <b>data inicial</b> para filtrar."
                            v-model="dataInicio"
                            label="Selecione..."
                            title="In&iacute;cio"
                            use-icon-validation
                            clearable
                            :rules="[]"
                            :inicio="true"
                            @input="pegaHoje"
							:max-date="dataFim"
                        ></base-date-picker>
                </v-col>
				<v-col cols="3">
                    <base-date-picker 
                            tooltip-text="Escolha uma <b>data fim</b> para filtrar."
                            v-model="dataFim"                            
                            label="Selecione..."
                            title="Fim"
                            use-icon-validation
                            clearable
                            name="dataFim"
                            :rules="rules"
                            :fim="true"
							:popover-content="'teste'"
                            @input="pegaFim"
                        ></base-date-picker>
                </v-col>
            </v-row>
        `
};
