import CardLargeChoice from "../../../components/moleculas/CardLargeChoice";
import CarouselNews from "../../../components/organismos/CarouselNews?v=2";
import BaseSectionForm from "../../../components/atomos/BaseSectionForm?v=2";
import RepeatReport from "./RepeatReport?v=2";
import BaseCalendar from "../../../components/moleculas/BaseCalendar?v=2";
import BaseTooltip from "../../../components/atomos/BaseTooltip?v=2";
import { CardSliderClass } from "../../../classes/CardSliderClass?v=2";
import BaseTextField from "../../../components/atomos/BaseTextField";
import BaseDialog from "../../../components/moleculas/BaseDialog";
import * as types from "../store/mutations-types";
/**
 * Componente de passo dois do gerador de relatorios
 *
 * @requires {@link CardLargeChoice}
 * @requires {@link CarouselNews}
 * @requires {@link BaseSectionForm}
 * @requires {@link RepeatReport}
 * @requires {@link BaseCalendar}
 * @requires {@link BaseTooltip}
 * @requires {@link CardSliderClass}
 * @requires {@link BaseTextField}
 * @requires {@link BaseDialog}
 * 
 * @displayName ReportStepTwo
 * @category Page / Relatorios
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        CardLargeChoice,
        CarouselNews,
        BaseSectionForm,
        RepeatReport,
        BaseCalendar,
        BaseTooltip,
        BaseTextField,
        BaseDialog
    },
    data() {
        return {
            buttons_type_choice: [
                {
                    icon: "mdi-calendar-today",
                    title: "Ontem",
                    type: "yesterday",
                    description: "Inclui as atividades do dia anterior",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "mdi-calendar-week",
                    title: "7 dias",
                    type: "seven-days",
                    description: "Inclui as atividades de 7 dias anteriores",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "mdi-calendar-month",
                    title: "M&ecirc;s passado",
                    type: "thirty-days",
                    description: "Inclui as atividades do m&ecirc;s anterior",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "mdi-calendar-blank",
                    title: "Data espec&iacute;fica",
                    type: "specific-date",
                    description:
                        "Escolha o per&iacute;odo desejado em at&eacute; 60 dias atr&aacute;s",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                }
            ],
            button_type_selected: null,
            period_selected: null,
            dt_inicial: null,
            dt_final: null,
            repeat_report: false,
            repeat_config: null,
            min_date: "",
            data: dayjs().format("YYYY-MM-DD"),
            date_hint: dayjs().subtract(61, "days").format("YYYY-MM-DD"),
            errorDtInicial: false,
            errorDtFinal: false,
            dialog: false,
            dt_thirty: null,
            action_button: {
                "label": "Confirmar per&iacute;odo",
                "variant": "dark-primary",
                "icon": "mdi-check"
            }
        };
    },
    computed: {
        ...window.Vuex.mapState("reports", {
            step_one_content: state => state.generator_step_one,
            generator_step_two: (state) => state.generator_step_two
        }),
        isMobile() {
            return (
                this.$vuetify.breakpoint.width <
                this.$vuetify.breakpoint.thresholds.md
            );
        },
        pickerDate() {
            let result = new Date(new Date().setDate(new Date().getDate() - 1))
                .toISOString()
                .substring(0, 7);
            switch (this.button_type_selected) {
            case "yesterday":
                result = this.period_selected[0].substring(0, 7);
                break;
            case "seven-days":
                result = this.period_selected[1].substring(0, 7);
                break;
            case "thirty-days":
                result = this.period_selected[1].substring(0, 7);
                break;
            }
            return result;
        },
        cardVertical() {
            return (
                this.$vuetify.breakpoint.width <
                    this.$vuetify.breakpoint.thresholds.sm ||
                (this.$vuetify.breakpoint.width >=
                    this.$vuetify.breakpoint.thresholds.md &&
                    this.$vuetify.breakpoint.width <
                        this.$vuetify.breakpoint.thresholds.lg)
            );
        },
        cardsCarousel() {
            return [
                new CardSliderClass({
                    image: "/images/reports/manRunningTime.svg",
                    text: "<p class=\"ma-0\"><b>Ganhe tempo automatizando relat&oacute;rios</b></p>Habilite a op&ccedil;&atilde;o \"Repetir relat&oacute;rio\" para receber uma vers&atilde;o atualizada do relat&oacute;rio sempre que precisar.",
                    vertical: this.cardVertical
                }),
                new CardSliderClass({
                    image: "/images/reports/ger_rel_onboarding.svg",
                    text: "<p class=\"ma-0\"><b>Gere relat&oacute;rios em alguns cliques</b></p>Escolha o per&iacute;odo, clientes, campanhas e dados adicionais que melhor atendem a sua demanda.",
                    vertical: this.cardVertical
                }),
                new CardSliderClass({
                    image: "/images/reports/girlCloudDownload.svg",
                    text: "<p class=\"ma-0\"><b>O melhor caminho para receber seus arquivos</b></p>Seja <b>baixando</b> diretamente pelo painel ou recebendo por <b>email</b>, escolha sua conveni&ecirc;ncia .",
                    vertical: this.cardVertical
                })
            ];
        },
        hintDtInicial() {
            if (!this.errorDtInicial) {
                let date;

                if (this.step_one_content.report_type === "analytical") {
                    date = dayjs().subtract(60, "days").format("DD/MM/YYYY");
                } else {
                    date = dayjs().subtract(1, "year").subtract(1, "day").format("DD/MM/YYYY");
                }
                return "Data inicial deve ser maior ou igual a " + date;
            } else {
                return null;
            }

        },
        hintDtFinal() {
            if (!this.errorDtFinal) {
                if (this.step_one_content.report_type == "analytical") {
                    return "Data final deve ser menor ou igual a " + dayjs().format("DD/MM/YYYY");
                } else {
                    return "Data final deve ser menor ou igual a " + dayjs().subtract(1, "day").format("DD/MM/YYYY");
                }
            } else {
                return null;
            }
        },
    },
    watch: {
        isMobile() {
            for (let button of this.buttons_type_choice) {
                button.horizontal = this.isMobile ? true : false;
            }
        },
        period_selected() {
            if (this.period_selected && this.period_selected.length === 2) {
                this.dt_inicial = dayjs(this.period_selected[0]).format("DD/MM/YYYY");
                this.dt_final = dayjs(this.period_selected[1]).format("DD/MM/YYYY");

                setTimeout(() => {
                    this.dt_inicial = dayjs(this.period_selected[0]).format("DD/MM/YYYY");
                    this.dt_final = dayjs(this.period_selected[1]).format("DD/MM/YYYY");
                }, 500);
            }
        }
    },
    mounted() {
        this.fillValidateRules();
        for (let button of this.buttons_type_choice) {
            button.horizontal = this.isMobile ? true : false;
        }
        if (this.generator_step_two.type_period) {
            this.button_type_selected = this.generator_step_two.type_period;
            this.period_selected = this.generator_step_two.period_selected;
            this.repeat_report = this.generator_step_two.recurrence_report;
            this.repeat_config = this.generator_step_two.recurrence_config;
            for (let button of this.buttons_type_choice) {
                if (button.type === this.generator_step_two.type_period) {
                    button.selected = true;
                }
            }

            if (this.generator_step_two.type_period === "specific-date") {
                if (this.dt_inicial == null && this.dt_final == null) {
                    this.dt_inicial = window.Vue.formatDateUsaToBrasil(this.generator_step_two.period_selected[0]);
                    this.dt_final = window.Vue.formatDateUsaToBrasil(this.generator_step_two.period_selected[1]);
                }
            }
        }
        let buttonsCopy = [...this.buttons_type_choice];
        if (this.step_one_content.report_type === "analytical") {
            buttonsCopy[3].description = "Escolha o per&iacute;odo desejado em at&eacute; 60 dias atr&aacute;s";
            this.min_date = new Date(new Date().setDate(new Date().getDate() - 60))
                .toISOString()
                .substring(0, 10);
        } else {
            buttonsCopy[3].description = "Escolha o per&iacute;odo desejado em at&eacute; 1(um) ano atr&aacute;s";
            this.min_date = new Date(new Date().setDate(new Date().getDate() - 366))
                .toISOString()
                .substring(0, 10);
        }
        this.buttons_type_choice = buttonsCopy;
    },
    updated() {
        this.setStateData();
    },
    methods: {
        validatDiffDays() {
            if (this.dt_inicial && this.dt_final && this.dt_inicial.length == 10 && this.dt_final.length == 10) {
                this.dt_thirty = new Date(Vue.formatDateBrasilToUsa(this.dt_final)) - new Date(Vue.formatDateBrasilToUsa(this.dt_inicial));
                const diffInDays = this.dt_thirty / (1000 * 60 * 60 * 24);
                if (diffInDays >= 30) {
                    this.dialog = true;
                } else {
                    if (this.step_one_content.report_type == "analytical") {
                        if (window.Vue.dateIsBefore(window.Vue.formatDateBrasilToUsa(this.dt_final), dayjs().format("YYYY-MM-DD"))) {
                            if (window.Vue.formatDateBrasilToUsa(this.dt_inicial) < dayjs().subtract(61, "days").format("YYYY-MM-DD")) {
                                if (this.dt_final && window.Vue.dateIsAfter(window.Vue.formatDateBrasilToUsa(this.dt_inicial), window.Vue.formatDateBrasilToUsa(this.dt_final))) {
                                    this.dialog = false;
                                }
                            } else {
                                this.dialog = false;
                            }
                        } else {
                            this.dialog = false;
                        }
                    } else {
                        if (window.Vue.dateIsBefore(window.Vue.formatDateBrasilToUsa(this.dt_final), dayjs().format("YYYY-MM-DD"))) {
                            if (window.Vue.formatDateBrasilToUsa(this.dt_inicial) < dayjs().subtract(366, "days").format("YYYY-MM-DD")) {
                                if (this.dt_final && window.Vue.dateIsAfter(window.Vue.formatDateBrasilToUsa(this.dt_inicial), window.Vue.formatDateBrasilToUsa(this.dt_final))) {
                                    this.dialog = false;
                                }
                            } else {
                                this.dialog = false;
                            }
                        } else {
                            this.dialog = false;
                        }
                    }
                }
            }
        },
        fillValidateRules() {
            this.start_date_rules = [
                dt_inicial => !!dt_inicial || "Campo obrigat&oacute;rio",
                dt_inicial => {
                    if (dt_inicial && dt_inicial.length == 10) {
                        if (window.Vue.dateIsBefore(window.Vue.formatDateBrasilToUsa(dt_inicial), dayjs().add(1, "day").format("YYYY-MM-DD"))) {
                            if (window.Vue.formatDateBrasilToUsa(dt_inicial) < dayjs().subtract(this.step_one_content.report_type == "analytical" ? 60 : 366, this.step_one_content.report_type == "analytical" ? "days" : "days").format("YYYY-MM-DD")) {
                                this.dialog = false;
                                this.errorDtInicial = true;
                                let period = this.step_one_content.report_type == "analytical" ? "60 dias" : "1 ano";
                                return `Data informada maior que ${period}`;
                            } else {
                                if (this.dt_final && window.Vue.dateIsAfter(window.Vue.formatDateBrasilToUsa(dt_inicial), window.Vue.formatDateBrasilToUsa(this.dt_final))) {
                                    this.dialog = false;
                                    this.errorDtInicial = true;
                                    return "Data informada maior que a data final";
                                } else {
                                    this.errorDtInicial = false;
                                    return true;
                                }
                            }
                        } else if (window.Vue.isValidDateBr(dt_inicial) == false) {
                            this.errorDtInicial = true;
                            return "Data invalida";
                        } else {
                            this.dialog = false;
                            this.errorDtInicial = true;
                            return "Data informada maior que a data atual";
                        }
                    } else {
                        return false;
                    }
                },
            ];

            this.end_date_rules = [
                dt_final => !!dt_final || "Campo obrigat&oacute;rio",
                dt_final => {
                    if (dt_final && dt_final.length == 10) {
                        if (this.dt_inicial && window.Vue.dateIsBefore(window.Vue.formatDateBrasilToUsa(this.dt_final),
                            this.step_one_content.report_type === "analytical" ? dayjs().add(1, "day").format("YYYY-MM-DDD") : dayjs().format("YYYY-MM-DDD"))) {
                            if (window.Vue.formatDateBrasilToUsa(this.dt_final) < dayjs().subtract(this.step_one_content.report_type == "analytical" ? 60 : 366, this.step_one_content.report_type == "analytical" ? "days" : "days").format("YYYY-MM-DD")) {
                                this.dialog = false;
                                this.errorDtFinal = true;
                                let period = this.step_one_content.report_type == "analytical" ? "60 dias" : "1 ano";
                                return `Data informada maior que ${period}`;
                            } else {
                                if (this.dt_inicial && window.Vue.dateIsBefore(window.Vue.formatDateBrasilToUsa(this.dt_final), window.Vue.formatDateBrasilToUsa(this.dt_inicial))) {
                                    this.dialog = false;
                                    this.errorDtFinal = true;
                                    return "Data informada menor que a data inicial";
                                } else {
                                    this.errorDtFinal = false;
                                    return true;
                                }
                            }
                        } else if (window.Vue.isValidDateBr(this.dt_final) == false) {
                            this.errorDtFinal = true;
                            return "Data invalida";
                        } else if (window.Vue.formatDateBrasilToUsa(this.dt_final, this.data)) {
                            this.dialog = false;
                            this.errorDtFinal = true;
                            return "Data invalida para relatorio sintetico";
                        } else {
                            this.dialog = false;
                            this.errorDtFinal = true;
                            return "Data informada maior que a data atual";
                        }
                    } else {
                        return false;
                    }
                },

            ];
        },
        /**
         * Acao de click dos botoes de tipo de selecao
         * @param {Object} type Identificador do botao de tipo selecionado
         * @vue
         */
        clickButton(type) {
            // Caso botao de selecao de data seja precionado, apaga informacoes selecionadas nos proximos passos.
            // (em caso de retorno ao passo dois)
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_THREE}`, {
                clients: [],
            });
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_FOUR}`, {
                campaigns: [],
                only_single_api_messages: false,
            });
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_FIVE}`, {
                agroup: false,
                aditional_data: [],
            });

            this.period_selected = null;
            this.dt_inicial = null;
            this.dt_final = null;
            if (type.type === this.button_type_selected) {
                this.button_type_selected = null;
            } else {
                switch (type.type) {
                case "yesterday":
                    this.period_selected = [
                        new Date(
                            new Date().setDate(new Date().getDate() - 1)
                        )
                            .toISOString()
                            .substring(0, 10)
                    ];
                    break;
                case "seven-days":
                    this.period_selected = [
                        new Date(
                            new Date().setDate(new Date().getDate() - 7)
                        )
                            .toISOString()
                            .substring(0, 10),
                        new Date(
                            new Date().setDate(new Date().getDate() - 1)
                        )
                            .toISOString()
                            .substring(0, 10)
                    ];
                    break;
                case "thirty-days":
                    this.period_selected = [
                        new Date(
                            new Date().setMonth(new Date().getMonth() - 1)
                        )
                            .toISOString()
                            .substring(0, 8) + "01",
                        new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            0
                        )
                            .toISOString()
                            .substring(0, 10)
                    ];
                }
                this.button_type_selected = type.type;
            }
        },
        /**
         * Acao de confirmacao da configuracao de repeticao do relatorio
         * @param {Object} config
         * @vue
         */
        setConfigRepeat(config) {
            this.repeat_config = config;
            this.setStateData();
        },
        /**
         * Salva os dados selecionados no state
         * @vue
         */
        setStateData() {
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_TWO}`, {
                type_period: this.button_type_selected,
                period_selected: this.period_selected,
                recurrence_report: this.repeat_report,
                recurrence_config: this.repeat_config,
                date_invalid: this.errorDtInicial || this.errorDtFinal
            });
        },
        closeDialog() {
            this.dialog = false;
        },
        setInitialDate() {

            let dateCopy = [...this.date_hint];
            if (this.step_one_content.report_type !== "analytical") {
                dateCopy = (dayjs().add(-1, "day")).subtract(366, "days").format("YYYY-MM-DD");
            }
            this.date_hint = dateCopy;
            if (this.dt_inicial !== null && this.dt_final !== null && this.dt_inicial !== "" && this.dt_final !== "") {
                if (window.Vue.dateIsAfter(window.Vue.formatDateBrasilToUsa(this.dt_inicial), this.date_hint)) {
                    this.period_selected = [window.Vue.formatDateBrasilToUsa(this.dt_inicial), window.Vue.formatDateBrasilToUsa(this.dt_final)];
                }
            }
            this.validatDiffDays();
        },
        setEndDate() {

            let dateCopy = [...this.date_hint];
            if (this.step_one_content.report_type !== "analytical") {
                dateCopy = (dayjs().add(-1, "day")).subtract(366, "days").format("YYYY-MM-DD");
            }
            this.date_hint = dateCopy;
            if (this.dt_inicial !== null && this.dt_final !== null && this.dt_inicial !== "" && this.dt_final !== "") {
                if (window.Vue.dateIsAfter(window.Vue.formatDateBrasilToUsa(this.dt_inicial), this.date_hint) && window.Vue.dateIsAfter(window.Vue.formatDateBrasilToUsa(this.dt_final), window.Vue.formatDateBrasilToUsa(this.dt_inicial))) {
                    this.period_selected = [window.Vue.formatDateBrasilToUsa(this.dt_inicial), window.Vue.formatDateBrasilToUsa(this.dt_final)];
                }
            }
            this.validatDiffDays();
        }
    },
    template:
        /*html*/
        `
            <v-row no-gutters>
                <v-col cols="12" md="6" lg="5">
                    <base-section-form title="Per&iacute;odo" description="Escolha o per&iacute;odo que deseja gerar o relat&oacute;rio">
                        <v-row>
                            <v-col :data-v-step="2">
                                <card-large-choice
                                    v-model="buttons_type_choice"
                                    :columns="isMobile ? 'full' : '6'"
                                    :inline="isMobile"
                                    :horizontal="isMobile"
                                    @click="clickButton"
                                />
                            </v-col>
                        </v-row>
                    </base-section-form>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    lg=""
                    class="pl-md-5"
                >
                    <carousel-news v-if="!button_type_selected" :list-card-slider="cardsCarousel" />
                    <v-row v-else no-gutters>
                        <v-col cols="5">
                            <v-form>
                                <v-row no-gutters align="center">
                                    <v-col v-if="button_type_selected === 'specific-date'" cols="11" class="mr-4 mt-3">
                                        <base-text-field
                                            v-model="dt_inicial"
                                            :rules="start_date_rules"
                                            title="Data Inicial"
                                            :required="true"
                                            mask="##/##/####"
                                            label="__/__/__"
                                            :hint="hintDtInicial"
                                            @blur="setInitialDate"
                                        />
                                    </v-col>
                                    <v-col v-if="button_type_selected === 'specific-date'" cols="11" class="mr-4 mt-6 ">
                                        <base-text-field
                                            v-model="dt_final"
                                            :rules="end_date_rules"
                                            :required="true"
                                            mask="##/##/####"
                                            label="__/__/__"
                                            title="Data final"
                                            :hint="hintDtFinal"
                                            @blur="setEndDate"
                                        />
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-col>
                        <v-col
                            v-if="button_type_selected === 'specific-date'"
                            cols="7"
                            :data-v-step="3"
                            class="justify-content-center"
                        >
                            <base-calendar
                                v-model="period_selected"
                                custom-range
                                :range="button_type_selected !== 'yesterday'"
                                :readonly="button_type_selected !== 'specific-date'"
                                legend
                                :legend-bottom="true"
                                tooltip-selected-date="Voc&ecirc; escolheu esta data para gerar o relat&oacute;rio."
                                :min-date="button_type_selected !== 'specific-date' ? null : min_date"
                                :month-and-year="pickerDate"
                                :max-range="step_one_content.report_type === 'analytical' ? 60 : 366 "
                                :report-type="step_one_content.report_type"
                            />
                        </v-col>
                        <v-col
                            v-else
                            cols="10"
                            :data-v-step="3"
                            class="d-flex justify-content-end"
                        >
                            <base-calendar
                                v-model="period_selected"
                                :range="button_type_selected !== 'yesterday'"
                                :readonly="button_type_selected !== 'specific-date'"
                                legend
                                :legend-bottom="false"
                                tooltip-selected-date="Voc&ecirc; escolheu esta data para gerar o relat&oacute;rio."
                                :min-date="button_type_selected !== 'specific-date' ? null : min_date"
                                :month-and-year="pickerDate"
                                :report-type="step_one_content.report_type"
                            />
                        </v-col>
                        <v-col cols="12" :data-v-step="4" class="mt-5">
                            <base-tooltip text="Ao ligar o repetir relat&oacute;rio, esse relat&oacute;rio ser&aacute; automatizado.">
                                <repeat-report v-model="repeat_report" @change="setConfigRepeat" />
                            </base-tooltip>
                        </v-col>
                        <base-dialog
                            v-model="dialog"
                            :action-button="action_button"
                            :use-icon-close="true"
                            :cancel="false"
                            use-sub-action
                            title="Per&iacute;odo selecionado maior que 30 dias"
                            @clickDialog="closeDialog"
                            @closeDialog="closeDialog"
                            @clickOutside="closeDialog"
                        >
                            <v-card elevation="0">
                                <v-row justify="center">
                                    <v-col cols="14" class="mt-0 pt-0 text-superdark-grey text-left font-size-13">
                                        <p>
                                            Voc&ecirc; selecionou mais que 30 dias e isso poder&aacute; afetar o tempo
                                            de gera&ccedil;&atilde;o do relat&oacute;rio em plano de fundo. Voc&ecirc; tem certeza que deseja continuar?
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <template #subAction>
                                <v-row justify="end">
                                    <v-col cols="mt-6">
                                        <a class="body-2 text-primary-blue-500 text-decoration-none" href="#" @click="closeDialog">Selecionar um novo per&iacute;odo</a>
                                    </v-col>
                                </v-row>
                            </template>
                        </base-dialog>
                    </v-row>
                </v-col>
            </v-row>
        `
};
