import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";
import BaseTextField from "../../../../components/atomos/BaseTextField";
import SectionCard from "../../../../components/moleculas/SectionCard";
import PreviewImage from "../../../../components/atomos/PreviewImage";

/**
 * Componente de modal para simular upload de umagem customizada
 * 
 * @requires {@link BaseDialog}
 * @requires {@link BaseTextField}
 * @requires {@link SectionCard}
 * @requires {@link PreviewImage}
 * 
 * @displayName DialogCustomImageSimulator
 * @component
 * @category Page / Template RCS
 * @subcategory dialogs
 * @vue
 * @author David Santos <david.santos@pgmais.com.br>
 * @vue/component 
 */
export default {
    components: {
        BaseDialog,
        BaseTextField,
        SectionCard,
        PreviewImage
    },
    props: {
        /**
        * Propriedade para controlar status da modal se ela esta ou nao ativa
        */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * URL da imagem para o caso de edicao
         */
        imageUrl: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            image: this.imageUrl,
            image_rules: [
                v => !!v || window.Vue.htmlEntities("Voc&ecirc; precisa inserir o link da imagem"),
                v => {
                    let valid = false;

                    try {
                        let url = new URL(v);
                        valid = ["http:", "https:"].indexOf(url.protocol) > -1;
                    } catch (e) {
                        valid = false;
                    }

                    if (valid) {
                        if (v.toLowerCase().indexOf("http://") === 0 || v.toLowerCase().indexOf("https://") === 0) {
                            let regex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);

                            if (v.match(regex)) {
                                return true;
                            } else {
                                return window.Vue.htmlEntities("URL inv&aacute;lida, verifique se a URL &eacute; <b>https</b> e se a extens&atilde;o da imagem &eacute; <b>JPG</b> ou <b>PNG</b>");
                            }
                        } else {
                            return window.Vue.htmlEntities("URL inv&aacute;lida, verifique se a URL &eacute; <b>https</b> e se a extens&atilde;o da imagem &eacute; <b>JPG</b> ou <b>PNG</b>");
                        }
                    } else {
                        return window.Vue.htmlEntities("URL inv&aacute;lida, verifique se a URL &eacute; <b>https</b> e se a extens&atilde;o da imagem &eacute; <b>JPG</b> ou <b>PNG</b>");
                    }
                }
            ],
            dialog: this.value,
            button_action: {
                label: "Alterar imagem",
                icon: "mdi-refresh",
                loading: false
            }
        };
    },
    computed: {
        disable_button() {
            return !this.image || this.image.length === 0 || this.image === this.imageUrl;
        }
    },
    watch: {
        imageUrl() {
            this.image = this.imageUrl;
        },
        value() {
            this.dialog = this.value;
        },
        dialog() {
            this.$emit("input", this.dialog);
        }
    },
    methods: {
        /**
         * Informa que a imagem deve ser alterada
         * @vue
         */
        updateImage() {
            if (this.$refs.formCustomImageSimulator.validate()) {
                this.$emit("updateImage", this.image);
                this.$refs.formCustomImageSimulator.reset();
                this.dialog = false;
            }
        }
    },
    template: (
        /*html*/
        `
        <base-dialog
            v-model="dialog"
            use-icon-close
            cancel
            title="Simulador de imagem personalizada"
            max-width="410px"
            cols-button-cancel="auto"
            :cols-button-action="undefined"
            :action-button="button_action"
            :disable-action-button="disable_button"
            @clickDialog="updateImage"
        >
            <v-form ref="formCustomImageSimulator">
                <section-card title="Adicione uma URL <b>https</b> de imagem com extens&atilde;o <b>JPG</b> ou <b>PNG</b>" icon="mdi-link-plus" icon-size="40">
                    <template #header>
                        <v-row no-gutters>
                            <v-col cols="12">
                                <base-text-field
                                    v-model="image"
                                    label="https://"
                                    :required="true"
                                    :rules="image_rules"
                                    tooltip-text="Digite uma URL <b>Https</b> de imagem com extens&atilde;o <b>JPG</b> ou <b>PNG</b>"
                                    @enterPressed="updateImage"
                                ></base-text-field>
                            </v-col>
                            <v-col cols="12" class="mt-4">
                                <preview-image :image="image"></preview-image>
                            </v-col>
                        </v-row>
                    </template>
                </section-card>
            </v-form>
        </base-dialog>
        `
    )
};