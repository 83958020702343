import BaseInformation from "../../../../components/atomos/BaseInformation?v=3";
import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";
import { BaseService } from "../../../../services/BaseService";

const base_service = new BaseService();

/**
 * Componente de dialog para redirecionamento de tela
 * <iframe src="http://localhost:8080/v2/documentacao/dashboard/dialog-redirect-usage" style="width: 100%; height: 315px;"></iframe>
 * 
 * @requires {@link BaseInformation}
 * @requires {@link BaseDialog}
 * @requires {@link BaseService}
 * 
 * @displayName DialogRedirect
 * @category Page / Dashboard
 * @subcategory dialogs
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    name: "dialog-redirect",
    components: {
        BaseInformation,
        BaseDialog
    },
    props: {
        /**
         * Valor do atributo v-model do componente, indica se o dialog deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Nome da campanha (Utilizado somente se type = E)
         */
        campaignName: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Tipo de exibicao (C: Cancelamento, E: Escalonamento)
         */
        type: {
            type: String,
            required: true,
            validator(value) {
                return ["C", "E"].indexOf(value) !== -1;
            }
        },
        /**
         * IDs das campanhas a serem canceladas ou escalonadas
         */
        campaignsIds: {
            type: Array,
            required: true
        },
        /**
         * Usado para a pagina legada identificar de onde a requisicao foi enviada
         */
        link: {
            type: String,
            required: false,
            default: "Dashboard"
        }
    },
    data() {
        return {
            dialog: this.value
        };
    },
    computed: {
        title() {
            return this.type === "C" ? "Cancelar" : "Escalonar";
        },
        contentText() {
            let message = "";

            switch (this.type) {
            case "C":
                message = "Tem certeza que deseja cancelar a(s) campanhas selecionadas?";
                break;
            case "E":
                message = `Tem certeza que deseja escalonar a campanha <b>${this.campaignName}</b>?`;
                break;
            }

            return message;
        },
        informationText() {
            let message = window.Vue.htmlEntities("Ao clicar em continuar voc&ecirc; ser&aacute; redirecionado &agrave; tela de ");

            switch (this.type) {
            case "C":
                message += "cancelar";
                break;
            case "E":
                message += "escalonar";
                break;
            }

            return message;
        },
        stringIds() {
            return this.campaignsIds.join(",");
        },
        formAction() {
            let url = base_service.baseDomainPhp();

            switch (this.type) {
            case "C":
                url += "/alt-campanha-cancel.php";
                break;
            case "E":
                url += "/upCampEscalonar.php";
                break;
            }

            return url;
        }
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog() {
            /**
             * Emite o status do dialog (false ou true)
             * @property {Boolean} dialog
             * 
             * @event input
             * @vue
             */
            this.$emit("input", this.dialog);
        }
    },
    methods: {
        /**
         * Faz o redirecionamento da pagina de acordo com o tipo informado
         * @vue
         */
        redirect() {
            this.$refs.form.$el.submit();
        }
    },
    template: (
        /*html*/
        `
            <base-dialog 
                v-model="dialog"
                :title="title"
                :action-button="{ label: 'Continuar', variant: 'dark-primary', loading: false }"
                cancel
                use-icon-close
                max-width="520px"
                @clickDialog="redirect"
            >
                <v-card elevation="0">
                    <v-form ref="form" :action="formAction" method="POST" name="redirect">
                        <input type="hidden" name="conteudo" :value="stringIds" />
                        <input type="hidden" name="link" :value="link" />
                        <v-row justify="center">
                            <v-col cols="auto">
                                <p class="font-size-12" v-html="contentText"></p>
                                <base-information
                                    :description="informationText"
                                    icon="mdi-alert-circle"
                                    border-color="border-primary"
                                    icon-color="primary"
                                ></base-information>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card>
            </base-dialog>
        `
    )
};