export default {
    name: "blocked-icon",
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26.5" height="26.5" viewBox="0 0 26.5 26.5">
                <g id="Group_2605" data-name="Group 2605" transform="translate(0.75 0.75)">
                    <g id="Group_1790" data-name="Group 1790" transform="translate(0)">
                        <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="12.5" cy="12.5" rx="12.5" ry="12.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                    <g id="Group_1791" data-name="Group 1791" transform="translate(3.75 3.75)">
                        <line id="Line_63" data-name="Line 63" x2="16.894" y2="16.894" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    </g>
                </g>
            </svg>
        `
    )
};