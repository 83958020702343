import BaseSpanLimited from "./BaseSpanLimited?v=2";
import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente basico de pill para uso no painel
 * 
 * @requires {@link BaseSpanLimited}
 * @requires {@link BaseTooltip}
 * 
 * @displayName BasePill
 * @component
 * @vue
 * @category Atomos
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseSpanLimited,
        BaseTooltip
    },
    props: {
        /**
         * Texto a ser exibido no componente
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Sub titulo a ser exibido no componente
         */
        subTitle: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Icone a ser exibido junto com o texto
         */
        titleIcon: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Icone a ser exibido no inicio do componente
         */
        prependIcon: {
            type: String,
            required: false,
            default: "fa-grip-vertical"
        },
        /**
         * Indica que o componente eh arrastavel
         */
        draggable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe um icone de edicao
         */
        editable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe um icone de excluir
         */
        deletable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe um icone de remover
         */
        removable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define as bordas como pill
         */
        rounded: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Cor customizada do conteudo
         */
        contentColor: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Cor customizada do background
         */
        backgroundColor: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Cor customizada da borda
         */
        borderColor: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Texto do tooltip
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Adiciona o subtitle em uma nova linha abaixo do title
         */
        subTitleNewLine: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica que o pill esta selecionado
         */
        selected: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Texto a ser exibido quando selecionado
         */
        selectedText: {
            type: String,
            required: false,
            default: "Editando..."
        },
        /**
         * Deixa o componente com uma altura um pouco menor
         */
        small: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            hover: false,
            is_dragging: false
        };
    },
    computed: {
        color() {
            let color = "gray-0";

            if (this.backgroundColor) {
                color = this.backgroundColor;
            } else if (this.selected) {
                color = "primary-blue-500";
            } else if (this.hover) {
                color = "gray-30";

                if (this.draggable) {
                    color = "primary-blue-500";
                }
            }

            return color;
        },
        colorContent() {
            let color = "primary-blue-500";

            if (this.contentColor) {
                color = this.contentColor;
            } else if ((this.hover && this.draggable) || this.selected) {
                color = "gray-0";
            }

            return color;
        },
        border() {
            let border = "border-1 border-primary-blue-500";

            if (this.borderColor) {
                border = `border-1 border-${this.borderColor}`;
            } else if (this.selected || this.backgroundColor) {
                border = "border-0";
            } else if (this.hover && this.draggable) {
                border = "border-dashed-1 border-gray-0";
            }

            if (this.rounded) {
                border += " rounded-4";
            } else {
                border += " rounded-1";
            }

            return border;
        }
    },
    methods: {
        dragging(status) {
            if (this.draggable) {
                this.is_dragging = status;
            }
        }
    },
    template: (
        /*html*/
        `
            <base-tooltip :text="tooltipText">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-hover v-model="hover">
                            <v-chip
                                :pill="rounded"
                                :draggable="draggable"
                                :class="'bg-' + color + ' ' + border"
                                :outlined="!hover"
                                :large="subTitleNewLine"
                                :small="small"
                            >
                                <v-card elevation="0" :class="((editable && deletable && removable) ? 'mr-4' : (editable || deletable || removable ? 'mr-2' : '')) + ' bg-transparent'" max-width="100%">
                                    <v-row no-gutters class="flex-nowrap">
                                        <v-col :cols="(editable && deletable && removable) ? 9 : ((editable && deletable) || (deletable && removable) || (editable && removable) ? 10 : (editable || deletable || removable ? 11 : 12))" @click="$emit('click')">
                                            <v-row no-gutters :class="(editable || deletable || removable ? 'gap-2' : '') + ' flex-nowrap'" align="center">
                                                <v-col v-if="prependIcon" cols="auto">
                                                    <v-icon :color="colorContent">{{ draggable && hover ? 'mdi-arrow-all' : prependIcon }}</v-icon>
                                                </v-col>
                                                <v-col v-if="titleIcon && !is_dragging" cols="auto" :class="!editable && !deletable && !removable ? 'pr-2' : ''">
                                                    <v-icon :color="colorContent">{{ titleIcon }}</v-icon>
                                                </v-col>
                                                <v-col v-if="!is_dragging" :cols="12">
                                                    <v-row no-gutters>
                                                        <v-col :cols="subTitleNewLine ? 12 : 'auto'">
                                                            <base-span-limited :classes="'body-2 text-' + colorContent" :text="title" :limit="30"></base-span-limited>
                                                        </v-col>
                                                        <v-col v-if="subTitle" :cols="subTitleNewLine ? 12 : 'auto'" :class="subTitleNewLine ? '' : 'pl-2'">
                                                            <base-span-limited :classes="'caption text-' + colorContent" :text="subTitle" :limit="30"></base-span-limited>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col v-if="(editable || deletable || removable) && !is_dragging" cols="auto" class="d-flex align-center">
                                            <v-row v-if="selected" no-gutters class="flex-nowrap gap-4" @click="$emit('click')">
                                                <v-col cols="auto">
                                                    <span class="caption italic text-gray-0">{{ selectedText }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row v-else no-gutters class="flex-nowrap gap-2">
                                                <v-col v-if="editable" cols="auto">
                                                    <v-icon size="20" color="gray-300" @click="$emit('edit')">mdi-pencil</v-icon>
                                                </v-col>
                                                <v-col v-if="deletable" cols="auto">
                                                    <v-icon size="20" color="gray-300" @click="$emit('delete')">mdi-trash-can</v-icon>
                                                </v-col>
                                                <v-col v-if="removable" cols="auto">
                                                    <v-icon size="20" color="gray-300" @click="$emit('remove')">mdi-close-thick</v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-chip>
                        </v-hover>
                    </v-col>
                </v-row>
            </base-tooltip>
        `
    )
};