import DialogTemplateManual from "../../campaign/components/dialogs/DialogTemplateManual";
import BaseButton from "../../../components/atomos/BaseButton";

export default {
    name: "dialog-template-manual-usage",
    components: {
        DialogTemplateManual,
        BaseButton
    },
    props: {
    },
    data() {
        return {
            value: false
        };
    },
    watch: {
    },
    methods: {
    },
    template: (
        /*html*/
        `
        <v-container>
            <base-button @click="value = true">
            Abrir Modal</base-button>
            <div class="row justify-center">
                <div class="col-6">
                   <dialog-template-manual v-model=value>
                   </dialog-template-manual>
                </div>
            </div>
        </v-container>
        
        `
    )
};