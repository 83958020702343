import BaseButton from "../atomos/BaseButton?v=2";

/**
 * Componente de Footer Action
 *
 * @displayName FooterAction
 * @component
 * @category Moleculas
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseButton
    },
    props: {
        /**
         * Label do botao do lado direito
         */
        btnRightText: {
            type: String,
            required: false,
            default: "Continuar"
        },
        /**
         * Icone do botao do lado direito
         */
        btnRightIcon: {
            type: String,
            required: false,
            default: "fa-arrow-right"
        },
        /**
         * Indica se o botao do lado direito deve ficar desabilitado
         */
        btnRightDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Sub texto a ser exibido abaixo do botao do lado esquerdo
         */
        subTextLeft: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Icone do botao do lado esquerdo
         */
        btnLeftIcon: {
            type: String,
            required: false,
            default: "fa-undo"
        },
        /**
         * Label do botao do lado esquerdo
         */
        btnLeftText: {
            type: String,
            required: false,
            default: "Voltar"
        },
        /**
         * Indica se o botao do lado esquerdo deve estar desabilitado
         */
        btnLeftDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Texto do tooltip do botao esquerdo
         */
        btnLeftTooltip: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Texto do tooltip do botao direito
         */
        btnRightTooltip: {
            type: String,
            required: false,
            default: null
        }
    },
    methods: {
        /**
         * Metodo acionado quando o botao do lado esquerdo for clicado
         */
        btnLeftClick() {
            /**
             * Evento btnLeftClick disparado quando a funcao btnLeftClick for chamada
             * 
             * @event btnLeftClick
             * @vue
             */
            this.$emit("btnLeftClick");
        },
        /**
         * Metodo acionado quando o botao do lado direito for clicado
         */
        btnRightClick() {
            /**
             * Evento btnRightClick disparado quando a funcao btnRightClick for chamada
             * 
             * @event btnRightClick
             * @vue
             */
            this.$emit("btnRightClick");
        }
    },
    template: (
        /*html*/
        `
        <v-footer :fixed="true" color="white" class="shadow-top-3">
            <v-container>
                <v-row class="py-4 px-8 flex-wrap flex-sm-nowrap" align="start" justify-sm="space-between">
                    <v-col cols="12" sm="auto" class="order-1 order-sm-0">
                        <v-row no-gutters v-if="btnLeftText" align="center">
                            <v-col cols="auto">
                                <base-button
                                    tertiary
                                    left
                                    width="auto"
                                    :icon='btnLeftIcon'
                                    :label='btnLeftText'
                                    :disabled="btnLeftDisabled"
                                    :block="false"
                                    :tooltip-text="!btnLeftDisabled ? btnLeftTooltip : null"
                                    @click="btnLeftClick"
                                ></base-button>
                            </v-col>
                            <v-col class="pl-4 d-flex justify-content-start">
                                <span class="body-2 text-gray-500" v-html="subTextLeft"></span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="d-flex justify-content-center justify-content-sm-end order-0 order-sm-1" cols="12" sm="auto" v-bind:data-v-step="'footerActionRight'">
                        <base-button
                            primary
                            :icon='btnRightIcon'
                            :label='btnRightText'
                            :disabled="btnRightDisabled"
                            :block="false"
                            :tooltip-text="!btnRightDisabled ? btnRightTooltip : null"
                            @click="btnRightClick"
                        ></base-button>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
        `
    )
};