export default {
    template: (
        /*html*/
        `
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_466_3201)">
                    <path d="M6.00098 17C5.62967 17 5.27358 16.8525 5.01103 16.5899C4.74848 16.3274 4.60098 15.9713 4.60098 15.6V4.4C4.60098 4.0287 4.74848 3.6726 5.01103 3.41005C5.27358 3.1475 5.62967 3 6.00098 3H14.411C14.7823 3 15.1384 3.1475 15.4009 3.41005C15.6635 3.6726 15.811 4.0287 15.811 4.4V15.6C15.811 15.9713 15.6635 16.3274 15.4009 16.5899C15.1384 16.8525 14.7823 17 14.411 17H6.00098ZM6.00098 15.6H14.411V4.4H6.00098V15.6ZM18.61 15.6C18.2387 15.6 17.8826 15.4525 17.62 15.1899C17.3575 14.9274 17.21 14.5713 17.21 14.2V5.8C17.21 5.4287 17.3575 5.0726 17.62 4.81005C17.8826 4.5475 18.2387 4.4 18.61 4.4H21.41V5.8H18.61V14.2H21.41V15.6H18.61ZM-0.999023 15.6V14.2H1.80098V5.8H-0.999023V4.4H1.80098C2.17228 4.4 2.52838 4.5475 2.79093 4.81005C3.05348 5.0726 3.20098 5.4287 3.20098 5.8V14.2C3.20098 14.5713 3.05348 14.9274 2.79093 15.1899C2.52838 15.4525 2.17228 15.6 1.80098 15.6H-0.999023Z" fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_466_3201">
                        <rect width="20" height="20" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        `
    )
};