export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g id="Btn-FinalizarChat" transform="translate(-1816 -2098)">
                    <g id="Ico-FinalizarChat-Enabled" transform="translate(1751.931 2085.895)">
                        <g id="Grupo_2230" data-name="Grupo 2230">
                            <path id="Caminho_558" data-name="Caminho 558" d="M85.672,33.457a.742.742,0,0,1-.53-.22L68.289,16.385a.75.75,0,0,1,1.061-1.061L86.2,32.177a.75.75,0,0,1-.53,1.28Z" fill="currentColor"/>
                        </g>
                        <path id="Caminho_559" data-name="Caminho 559" d="M75.516,19.37h7.4a1.6,1.6,0,0,1,1.6,1.6v6.811a1.573,1.573,0,0,1-.089.5l1.117,1.117a3.075,3.075,0,0,0,.472-1.627v-6.8a3.108,3.108,0,0,0-3.094-3.094h-8.9Z" fill="currentColor"/>
                        <path id="Caminho_560" data-name="Caminho 560" d="M79.145,29.362h-4a.752.752,0,0,0-.531.219l-2.287,2.287V30.112a.763.763,0,0,0-.763-.75,1.592,1.592,0,0,1-1.584-1.6V20.954a1.572,1.572,0,0,1,.132-.625l-1.1-1.1a3.105,3.105,0,0,0-.533,1.735v6.8a3.1,3.1,0,0,0,2.347,3.006v2.91a.75.75,0,0,0,.463.693.738.738,0,0,0,.287.057.75.75,0,0,0,.53-.219l3.348-3.348h5.189Z" fill="currentColor"/>
                    </g>
                    <rect id="Retângulo_473" data-name="Retângulo 473" width="26" height="26" transform="translate(1816 2098)" fill="currentColor" opacity="0"/>
                </g>
            </svg>
        `
    )
};