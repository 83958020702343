import AditionalReportData from "../../reports/components/AditionalReportData";
import ButtonCheckboxClass from "../../../classes/ButtonCheckboxClass";

export default {
    components: {
        AditionalReportData
    },
    data() {
        return {
            selectAll: true,
            options: [
                {
                    id: "mensagens",
                    name: "Selecionar todas as mensagens",
                    checked: false,
                    indeterminate: false,
                    buttons: [
                        new ButtonCheckboxClass({ label: "Mensagens Avulsas ()", icon: "$chatBubbleIcon", checked: false, id: "avulsas", tooltipText: "Clique para incluir dados de envios avulsos" }),
                        new ButtonCheckboxClass({ label: "Mensagens API ()", icon: "$apiMessagesIcon", checked: false, id: "api", tooltipText: "Clique para incluir dados de envios realizados por API" }),
                        new ButtonCheckboxClass({ label: "Retornos (0)", icon: "$multiChatBubbleIcon", checked: false, id: "radar", tooltipText: "Clique para incluir as mensagens de retorno (radar)" })
                    ]
                },
                {
                    id: "envios",
                    name: "Selecionar todas as situa&ccedil;&otilde;es de envio",
                    checked: false,
                    indeterminate: false,
                    buttons: [
                        new ButtonCheckboxClass({ label: "Entregues", icon: "$checkCircleIcon", checked: false, id: "entregues", tooltipText: "Clique para incluir o status de Entregues" }),
                        new ButtonCheckboxClass({ label: "N&atilde;o Entregues", icon: "$closeCircleIcon", checked: false, id: "nao_entregues", tooltipText: "Clique para incluir o status de N&atilde;o Entregues" }),
                        new ButtonCheckboxClass({ label: "Aguardando", icon: "$clockIcon", checked: false, id: "aguardando", tooltipText: "Clique para incluir o status de Aguardando retorno da operadora" })
                    ]
                },
                {
                    id: "bloqueios",
                    name: "Selecionar todos os bloqueios",
                    checked: false,
                    indeterminate: false,
                    buttons: [
                        new ButtonCheckboxClass({ label: "Inv&aacute;lidos", icon: "$infoIcon", checked: false, id: "invalidos", tooltipText: "Clique para incluir os telefones inv&aacute;lidos" }),
                        new ButtonCheckboxClass({ label: "Cancelados", icon: "$blockedIcon", checked: false, id: "cancelados", tooltipText: "Clique para incluir os envios cancelados" }),
                        new ButtonCheckboxClass({ label: "Repetidos", icon: "$userRefreshCircleIcon", checked: false, id: "repetidos", tooltipText: "Clique para incluir os telefones repetidos" }),
                        new ButtonCheckboxClass({ label: "Blacklist", icon: "$fileAlertIcon", checked: false, id: "blacklist", tooltipText: "Clique para incluir os telefones na blacklist" })
                    ]
                }
            ]
        };
    },
    methods: {
        click(buttons) {
            console.log(buttons);
        }
    },
    template: (
        /*html*/
        `
            <v-container>
                <v-row justify="center">
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="12">
                                <v-row>
                                    <v-col>
                                        <v-checkbox label="Habilitar selecionar todos" v-model="selectAll"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <aditional-report-data :select-all="selectAll" :options="options" @click="click"></aditional-report-data>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        `
    )
};