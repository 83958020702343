/**
 * Classe utilizada nos ProgressCards da tela Avulsos
 */
export class ProgressStatusCountClass {
    DELIVERED;
    NOT_DELIVERED;
    SENT;
    NOT_SENT;
    CANCELED;
    WAITING;
    HIGIENIZADOS;
    REJECTED;
    TOTAL_RADAR;
    TOTAL_FALLBACK;

    /**
     * @property {number} DELIVERED Quantidade de Entregues;
     * @property {number} NOT_DELIVERED Quantidade de N�o Entregues;
     * @property {number} SENT Quantidade de Enviados;
     * @property {number} NOT_SENT Quantidade de N�o Enviados;
     * @property {number} CANCELED Quantidade de Cancelados;
     * @property {number} WAITING Quantidade de Aguardando;
     * @property {number} HIGIENIZADOS Quantidade de Higienizados pela API;
     * @property {number} REJECTED Quantidade de Entregas Rejeitadas;
     * @property {number} TOTAL_RADAR Quantidade de envios com retorno;
     * @property {number} TOTAL_FALLBACK Quantidade de envios de fallback;
     */
    constructor({
        DELIVERED = 0,
        NOT_DELIVERED = 0,
        SENT = 0,
        NOT_SENT = 0,
        CANCELED = 0,
        WAITING = 0,
        HIGIENIZADOS = 0,
        REJECTED = 0,
        TOTAL_RADAR = 0,
        TOTAL_FALLBACK = 0
    }) {
        this.DELIVERED = DELIVERED;
        this.NOT_DELIVERED = NOT_DELIVERED;
        this.SENT = SENT;
        this.NOT_SENT = NOT_SENT;
        this.CANCELED = CANCELED;
        this.WAITING = WAITING;
        this.HIGIENIZADOS = HIGIENIZADOS;
        this.REJECTED = REJECTED;
        this.TOTAL_RADAR = TOTAL_RADAR;
        this.TOTAL_FALLBACK = TOTAL_FALLBACK;
    }
}