export default {
    template: (
        /*html*/
        `
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M37.755 21.0522C36.5068 21.0522 35.495 22.0641 35.495 23.3122C35.495 24.5604 36.5068 25.5722 37.755 25.5722C39.0032 25.5722 40.015 24.5604 40.015 23.3122C40.015 22.0641 39.0032 21.0522 37.755 21.0522ZM33.495 23.3122C33.495 20.9595 35.4023 19.0522 37.755 19.0522C40.1077 19.0522 42.015 20.9595 42.015 23.3122C42.015 25.665 40.1077 27.5722 37.755 27.5722C35.4023 27.5722 33.495 25.665 33.495 23.3122Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.05407 9.5C6.95472 9.5 5.5 11.425 5.5 13.4708V46.5292C5.5 48.575 6.95472 50.5 9.05407 50.5H50.9459C53.0453 50.5 54.5 48.575 54.5 46.5292V13.4708C54.5 11.425 53.0453 9.5 50.9459 9.5H9.05407ZM9.05407 11.5C8.33227 11.5 7.5 12.2352 7.5 13.4708V43.9272L23.8874 28.0246C24.5256 27.407 25.3512 27.0982 26.1768 27.0982C27.0024 27.0982 27.828 27.407 28.4662 28.0246L33.5983 32.3561L36.1715 29.783C36.8143 29.1401 37.6569 28.8187 38.4994 28.8187C39.3419 28.8187 40.1844 29.1401 40.8273 29.783L52.5 40.6248V13.4708C52.5 12.2352 51.6677 11.5 50.9459 11.5H9.05407ZM33.495 23.3122C33.495 20.9595 35.4023 19.0522 37.755 19.0522C40.1077 19.0522 42.015 20.9595 42.015 23.3122C42.015 25.665 40.1077 27.5722 37.755 27.5722C35.4023 27.5722 33.495 25.665 33.495 23.3122ZM37.755 21.0522C36.5068 21.0522 35.495 22.0641 35.495 23.3122C35.495 24.5604 36.5068 25.5722 37.755 25.5722C39.0032 25.5722 40.015 24.5604 40.015 23.3122C40.015 22.0641 39.0032 21.0522 37.755 21.0522ZM52.5 46.5292C52.5 47.7648 51.6677 48.5 50.9459 48.5L23.3931 48.4999C22.6207 48.4954 22.3076 47.9411 22.2087 47.7023C22.1093 47.4624 21.9378 46.8451 22.4888 46.2941L37.5857 31.1972C37.8298 30.9531 38.1542 30.8187 38.4994 30.8187C38.8445 30.8187 39.169 30.9531 39.4131 31.1972L52.5 43.3599V46.5292ZM25.2782 29.4618C25.5205 29.2273 25.8396 29.0982 26.1768 29.0982C26.5139 29.0982 26.8331 29.2273 27.0753 29.4618L27.1242 29.5091L32.1791 33.7754L21.0746 44.8799C20.0278 45.9266 19.8836 47.3484 20.3745 48.4999L9.05407 48.5C8.36787 48.5 7.58182 47.8355 7.50596 46.7083L25.2782 29.4618Z" fill="currentColor"/>
            </svg>
        `
    )
};