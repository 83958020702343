import RouterPage from "../RouterPage";
import ListReportsPage from "./ListReportsPage";
import InfoNewReportGenerator from "./components/InfoNewReportGenerator?v=2";
import AgroupReportData from "./components/AgroupReportData";
import ReportGeneratorPage from "./ReportGeneratorPage";

export default [
    {
        path: "reports",
        name: "reports",
        component: RouterPage,
        children: [
            {
                path: "list-report",
                name: "ListReport",
                component: ListReportsPage
            },
            {
                path: "info-new-report-generator",
                name: "InfoNewReportGenerator",
                component: InfoNewReportGenerator
            },
            {
                path: "agroup-report-data",
                name: "AgroupReportData",
                component: AgroupReportData
            },
            {
                path: "report-generator",
                name: "ReportGenerator",
                component: ReportGeneratorPage
            }
        ]
    }
];