import ReportStepThree from "../../reports/components/ReportStepThree";

export default {
    components: {
        ReportStepThree
    },
    template: (
        /*html*/
        `
            <v-container>
                <v-row class="mt-10">
                    <v-col cols="12" md="3">
                    </v-col>
                    <v-col cols="12" md="9">
                        <report-step-three></report-step-three>
                    </v-col>
                </v-row>
            </v-container>
        `
    )
};