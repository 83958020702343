import { BasePillClass } from "./BasePillClass?v=2";

/**
 * Classe utilizada para representar itens do componente draggable
 */
export class DraggableItemClass {
    id;
    text;
    type;
    pill;
    custom;
    order;

    /**
     * @param {String} text - Texto a ser exibido
     * @param {String} type - Tipo do item deve ser preenchido con "text" ou "pill"
     * @param {BasePillClass} pill - Item da classe BasePillClass
     * @param {Boolean} custom
     * @param {Number} order
     */
    constructor({
        text,
        type = "text",
        pill = new BasePillClass({}),
        custom = false,
        order = null
    }) {
        this.id = Math.random();
        this.text = text;
        this.type = type;
        this.pill = pill;
        this.custom = custom;
        this.order = order;
    }

}