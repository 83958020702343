import ModalCreatedTemplate from "../../template-rcs/components/dialogs/ModalCreatedTemplate";
import BaseButton from "../../../components/atomos/BaseButton";

export default ({
    components: {
        ModalCreatedTemplate,
        BaseButton
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        testFunction() {
            console.log("teste");
        },
        toggleModal() {
            this.dialog = !this.dialog;
        },
    },
    template: (
        /*html*/
        `
        <v-row>
            <v-col cols="2">
                <base-button
                    color="dark-primary"
                    label="Click"
                    icon="fa-paper-plane"
                    @click="toggleModal">
                </base-button>
                <modal-created-template
                    v-model="dialog"
                    :controlModal="dialog"
                    @clickModal="testFunction"
                    @clickSubAction="testFunction"
                    >
                </modal-created-template>
            </v-col>
        </v-row>
        
        `
    )
});