import GroupCardExtraLarge from "../../../components/moleculas/GroupCardExtraLarge";
import { BaseCardExtraLargeClass } from "../../../classes/BaseCardExtraLargeClass";

export default {
    components: {
        GroupCardExtraLarge
    },
    data() {
        return {
            cards: [
                new BaseCardExtraLargeClass({ title: "Teste", description: "description", imageUrl: "/images/reports/analytical_report.png", active: false }),
                new BaseCardExtraLargeClass({ title: "Teste", description: "description", imageUrl: "/images/reports/analytical_report.png", active: false }),
                new BaseCardExtraLargeClass({ title: "Teste", description: "description", imageUrl: "/images/reports/analytical_report.png", active: false })
            ],
            singleSelect: true
        };
    },
    methods: {
        console(item) {
            console.log(item.id);
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6">
                    <v-row>
                        <v-col>
                            <group-card-extra-large :cards="cards" @value="console"></group-card-extra-large>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
     
        `
    )
};