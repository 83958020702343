import BaseDialog from "../../../../components/moleculas/BaseDialog";

/**
 * Componente de confirmacao para limpar a configuracao de fallback
 *
 * @requires {@link BaseDialog}
 *
 * @displayName DialogRemoveFallback
 * @category Page / Template RCS
 * @subcategory dialogs
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseDialog
    },
    props: {
        /**
         * Indica se o modal deve ou nao ser exibido
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            show: this.value
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            this.$emit("input", this.show);
        }
    },
    methods: {
        /**
         * Metodo chamado ao clicar no botao de limpar fallback
         * @vue
         */
        clickClear() {
            this.show = false;
            this.$emit("clearFallback");
        }
    },
    template:
        /*html*/
        `
            <base-dialog
                v-model="show"
                max-width="410px"
                title="Tem certeza que deseja excluir?"
                :action-button="{ label: 'Sim, quero excluir a mensagem', icon: 'mdi-trash-can', primary: true, danger: true }"
                use-sub-action
                @clickDialog="clickClear"
            >
                <v-row no-gutters>
                    <v-col class="text-start">
                        <span class="body-2 text-gray-900">Ao excluir, voc&ecirc; perder&aacute; a mensagem alternativa cadastrada.</span>
                    </v-col>
                </v-row>
                <template #subAction>
                    <v-row no-gutters class="mt-4">
                        <v-col cols="12" class="text-center">
                            <a class="text-primary-blue-500 text-decoration-none body-2" @click="show = false">Mudei de id&eacute;ia, n&atilde;o quero excluir</a>
                        </v-col>
                    </v-row>
                </template>
            </base-dialog>
        `
};