import ProgressCard from "../../../components/moleculas/ProgressCard";
import CardContentClass from "../../../classes/CardContentClass";

export default ({
    components: {
        ProgressCard
    },
    data: function () {
        return {
            listContent: [
                new CardContentClass({
                    name: "teste1",
                    divisor: 5083,
                    dividendo: 0,
                    tooltip: "Teste",
                    disable_tooltip: false
                }),
                new CardContentClass({
                    name: "teste2",
                    divisor: 5083,
                    dividendo: 6,
                    tooltip: "Teste",
                    disable_tooltip: false
                }),
                new CardContentClass({
                    name: "teste3",
                    divisor: 5083,
                    dividendo: 5077,
                    tooltip: "Teste",
                    disable_tooltip: false
                }),
            ],
            icon: "icon-processados",
            title: "Processados 5.083",
            contentColor: "white",
            backgroundColor: "dark-primary",
            loading: false,
            disabledTolltip: false,
            tooltip: "teste"
        };
    },
    template:
    /*html*/
    `
    <v-row >
        <v-col cols="12" class="text-center">
            <v-row justify="center">
                <v-col cols="6">
                    <v-card class="pa-5">
                        <v-row>
                            <v-col cols="12">
                                <v-row>
                                    <v-col>
                                        <v-text-field label="Icone " v-model="icon"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field label="Titulo" v-model="title"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field label="Cor do texto" v-model="contentColor"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field label="Cor do background" v-model="backgroundColor"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12">
                                <v-row>
                                    <v-col>
                                        <v-text-field label="Tooltip" v-model="tooltip"></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox label="Desabilitar tooltip" v-model="disabledTolltip"></v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox label="Loading" v-model="loading"></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="4">
                    <progress-card
                        :icon="icon"
                        :title="title"
                        :content-color="contentColor"
                        :background-color="backgroundColor"
                        :loading="loading"
                        :list-content="listContent"
                        :disabled-tooltip="disabledTolltip"
                        :tooltip-text="tooltip"
                    > 
                    </progress-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
      `
});

