export default {
    template: (
        /*html*/
        `
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_466_3199)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2251 2.22901H2.46112C2.13507 2.22901 1.82237 2.35846 1.59173 2.58891C1.36109 2.81937 1.23138 3.13196 1.23112 3.45801V15.75C1.23138 16.0761 1.36109 16.3886 1.59173 16.6191C1.82237 16.8496 2.13507 16.979 2.46112 16.979H17.2251C17.3866 16.9791 17.5466 16.9475 17.6959 16.8858C17.8452 16.8241 17.9808 16.7336 18.0952 16.6195C18.2095 16.5053 18.3002 16.3698 18.3621 16.2206C18.424 16.0715 18.456 15.9115 18.4561 15.75V3.45901C18.4559 3.13296 18.3261 2.82037 18.0955 2.58991C17.8649 2.35946 17.5522 2.23001 17.2261 2.23001L17.2251 2.22901ZM2.46112 1.00001C2.13765 0.999218 1.81719 1.06223 1.5181 1.18544C1.21902 1.30865 0.947163 1.48964 0.718108 1.71805C0.489053 1.94645 0.307294 2.21779 0.183233 2.51653C0.0591728 2.81526 -0.00475228 3.13553 -0.00488281 3.45901V15.75C-0.00475152 16.0731 0.0590083 16.3929 0.182756 16.6913C0.306504 16.9897 0.487817 17.2609 0.716342 17.4892C0.944867 17.7175 1.21613 17.8986 1.51464 18.0221C1.81315 18.1456 2.13307 18.2091 2.45612 18.209H17.2251C17.5482 18.2091 17.8681 18.1456 18.1666 18.0221C18.4651 17.8986 18.7364 17.7175 18.9649 17.4892C19.1934 17.2609 19.3747 16.9897 19.4985 16.6913C19.6222 16.3929 19.686 16.0731 19.6861 15.75V3.45901C19.686 3.13596 19.6222 2.81609 19.4985 2.51768C19.3747 2.21927 19.1934 1.94816 18.9649 1.71982C18.7364 1.49148 18.4651 1.31039 18.1666 1.18688C17.8681 1.06338 17.5482 0.999876 17.2251 1.00001H2.46112Z" fill="currentColor"/>                    <path d="M13.0981 9.17001C13.1896 9.07905 13.3074 9.01911 13.4348 8.99865C13.5621 8.9782 13.6927 8.99826 13.8081 9.05601L18.4561 11.45V16.982H1.23012V14.523L4.48612 11.629C4.58679 11.529 4.71888 11.4669 4.86008 11.453C5.00129 11.4392 5.14294 11.4745 5.26112 11.553L8.53412 13.733L13.0991 9.17201L13.0981 9.17001Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.53612 8.37601C5.90074 8.37561 6.25706 8.26713 6.56004 8.06427C6.86302 7.8614 7.09906 7.57327 7.23832 7.23629C7.37759 6.89931 7.41382 6.52861 7.34244 6.17104C7.27106 5.81347 7.09528 5.48508 6.83732 5.2274C6.57935 4.96971 6.25077 4.79428 5.89313 4.72329C5.53548 4.6523 5.16482 4.68894 4.82799 4.82857C4.49116 4.96819 4.20328 5.20454 4.00075 5.50774C3.79822 5.81094 3.69012 6.16738 3.69012 6.53201C3.69025 6.7743 3.7381 7.01419 3.83094 7.23798C3.92378 7.46178 4.0598 7.6651 4.23121 7.83633C4.40263 8.00756 4.60609 8.14335 4.82999 8.23595C5.05389 8.32855 5.29383 8.37614 5.53612 8.37601Z" fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_466_3199">
                        <rect width="20" height="20" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        `
    )
};