export default {
    template: (
        /*html*/
        `
        <svg id="Ico-Desativar-Disabled" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
            <rect id="Retângulo_364" data-name="Retângulo 364" width="28" height="28" transform="translate(0)" fill="currentColor" opacity="0"/>
            <g id="Grupo_1868" data-name="Grupo 1868" transform="translate(4 4)">
                <path id="Subtração_2" data-name="Subtração 2" d="M9.8,19.6A9.8,9.8,0,1,1,16.688,2.875,9.708,9.708,0,0,1,19.6,9.8,9.811,9.811,0,0,1,9.8,19.6ZM4.582,9a.778.778,0,0,0,0,1.556H14.836a.739.739,0,0,0,.768-.778A.774.774,0,0,0,14.836,9Z" fill="currentColor"/>
            </g>
        </svg>      
        `
    )
};