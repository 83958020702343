import services from "../../../http/index";

/**
 * Faz a requisicao para a API Laravel para realizar o envio de teste 
 * @param {Object} params Parametros para o envio de teste 
 * <pre>{
 *     telefones: Array telefones para o envio 
 *     id_carteira: Number id da empresa 
 *     id_template: Number id do template
 *     tipo: String Tipo do template
 *     campos: Object Campo parametros da API que realiza o envio
 *     session_id: ID da sess�o para vincular (opcional)
 *     Mensagem:Mensagem da template
 * }</pre>
 * @returns response.data
 */
export const ActionDoSendTemplateRcs = (context, params) => {
    return services.api.request({
        url: "/send-message/send-rcs",
        method: "post",
        data: params
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

/**
 * Faz a requisicao para a API Laravel para realizar o envio de teste 
 * @param {Object} form_data Parametros para o envio de teste 
 * <pre>{
 *     id_empresa: Number Id da empresa que o template vai ficar vinculado
 *     id_produto: Number Id do produto vinculado ao template 
 *     tp_template: String  Tipo do template
 *     cd_template: String  Codigo do template
 *     ds_template: String  Estrutura do template 
 *     ds_parametro: String Parametros do template 
 *     st_template: String Status do template
 *     ds_hash: String Hash do template
 * }</pre>
 * @returns {Boolean} response.status status da insercao do template
 */
export const ActionDoCreateTemplateRcs = (context, form_data) => {
    return services.api.request({
        url: "/template-rcs",
        method: "post",
        data: form_data
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

export const ActionDoUpdateTemplateRcs = (context, params) => {
    return services.api.request({
        url: "/template-rcs/" + params.id,
        method: "put",
        data: params.form_data
    }).then(response => {
        return response.data.status;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

/**
 * Faz a requisicao para a API Laravel para poegar as carteiras permitidas do usuario logado
 *
 * @returns {Array} response.data Array com as carteiras permitidas
 */
export const ActionDoGetCarteirasPermitidas = () => {
    return services.api.request({
        url: "/empresas/minhas-carteiras",
        method: "get"
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

export const ActionDoGetCarteirasPermitidasAtivas = () => {
    return services.api.request({
        url: "/empresas/minhas-carteiras?rota=true",
        method: "get"
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

export const ActionDoGetTemplatesRcs = () => {
    return services.api.request({
        url: "/template-rcs",
        method: "get"
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

export const ActionDoGetTemplateRcsById = (context, id) => {
    return services.api.request({
        url: "/template-rcs/" + id,
        method: "get"
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};