import ReportStepSix from "../../reports/components/ReportStepSix";

export default {
    components: {
        ReportStepSix
    },
    template: (
        /*html*/
        `
            <v-container>
                <v-row class="mt-10">
                    <v-col cols="12" md="9">
                        <report-step-six></report-step-six>
                    </v-col>
                </v-row>
            </v-container>
        `
    )
};