export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g id="Btn-Chat-Hover" transform="translate(-1755 -2155)">
                    <g id="Ico-Chat-Hover" transform="translate(1755.8 2056)">
                        <g id="Grupo_1758_1_" transform="translate(0.2 102)">
                            <path id="Caminho_481" data-name="Caminho 481" d="M603.6,238.9a3.1,3.1,0,0,0-2.2-.9H590.1a3.15,3.15,0,0,0-3.1,2.9h-2.9A3.116,3.116,0,0,0,581,244v6.8a3.119,3.119,0,0,0,2.3,3v2.9a.752.752,0,0,0,.5.7c.1,0,.2.1.3.1a.764.764,0,0,0,.5-.2l3.3-3.3h7.5a3.13,3.13,0,0,0,2.9-2.1l2.5,2.5a.764.764,0,0,0,.5.2.367.367,0,0,0,.3-.1.752.752,0,0,0,.5-.7v-2.9a2.851,2.851,0,0,0,1.4-.8,3.1,3.1,0,0,0,.9-2.2h0v-6.8A3.008,3.008,0,0,0,603.6,238.9Zm-12.4,10.7h-6a.789.789,0,0,1-.8-.8.736.736,0,0,1,.8-.8h6a.8.8,0,0,1,0,1.6Zm2.8-2.9h-8.8a.789.789,0,0,1-.8-.8.736.736,0,0,1,.8-.8H594a.8.8,0,0,1,0,1.6Zm9,1.2h0a1.721,1.721,0,0,1-1.6,1.6h0a.684.684,0,0,0-.7.7V252l-2.1-2.1V244a3.116,3.116,0,0,0-3.1-3.1h-7a1.546,1.546,0,0,1,1.6-1.4h11.3a1.721,1.721,0,0,1,1.6,1.6Z" transform="translate(-581 -238)" fill="currentColor"/>
                        </g>
                    </g>
                    <rect id="Retângulo_485" data-name="Retângulo 485" width="26" height="26" transform="translate(1755 2155)" fill="currentColor" opacity="0"/>
                </g>
            </svg>
        `
    )
};