import services from "../../../http/index";

/**
 * Busca a lista de campanhas atuais para o autocomplete do filtro da dashboard
 * @returns Object
 */
export const ActionDoGetAvulsos = (context, params) => {
    return services.api.request({
        url: "/avulso-api/list",
        method: "post",
        data: params
    }).then(listTable => {
        return listTable.data;
    }).catch(error => {
        throw error;
    });
};

/**
 * Busca os dados de Envios dos registros avulsos
 * @returns {Promise<ProgressStatusCountClass>}
 */
export const ActionDoGetProgressAvulsos = (context, params) => {
    return services.api.request({
        url: "/avulso-api/get-dados-envio",
        method: "post",
        data: params
    }).then(statusCount => {
        return statusCount.data;
    }).catch(error => {
        throw error;
    });
};

/**
 * @param {Object} params Parametros a serem enviados para a solicitacao de download
 * @returns {Object} Objeto com as informacoes do relatorio
 */
export const ActionDoDownloadAvulsos = (context, params) => {
    return services.php.request({
        url: "/AvulsoApi/getReport",
        method: "post",
        data: params,
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw error;
    });
};

/**
 * @returns {Object} Objeto formatado para select as situa��es(status) dispon�veis
 * @constructor
 */
export const ActionDoGetStatusOptions = () => {
    return services.php.request({
        url: "/Campanha/getStatus",
        method: "post",
    }).then(response => {
        let situation_list = [];

        for (let status of response.data) {
            situation_list.push({
                text: status.label,
                value: status.value
            });
        }

        return situation_list;
    }).catch(error => {
        throw error;
    });
};