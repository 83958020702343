import services from "../../../http/index";
import * as types from "./mutations-types";

import { BaseService } from "../../../services/BaseService";

const base_service = new BaseService();

/**
 * Faz a requisicao para a API Laravel para pegar o token de autenticacao
 * @returns Promise
 */
export const ActionDoTokenSanctum = () => {
    return services.api.request({
        baseURL: base_service.baseDomain(""),
        url: "/sanctum/csrf-cookie",
        method: "get"
    }).then(response => {
        if (response.status === 204) {
            return true;
        } else {
            return false;
        }
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

/**
 * Realiza uma requisicao para o backend em PHP para realizar o login
 * @param {object} context Store do axios
 * @param {FormData} form_data Dados do login {email, senha}
 * @returns Promise
 */
export const ActionDoLogin = ( context, form_data) => {
    return services.php.request({
        url: "/Login/validaLogin",
        method: "post",
        data: form_data
    }).then((response) => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

/**
 * Realiza uma requisicao para a API laravel para realizar o login
 * @param {object} context Store do axios
 * @param {object} json_data Dados do login {email, senha}
 * @returns Promise
 */
export const ActionDoLoginLaravel = ({ commit }, json_data) => {
    return services.api.request({
        url: "/auth",
        method: "post",
        data: json_data
    }).then(response => {
        let data = response.data;

        commit(types.SET_USER, (data.auth && !data.error) ? data.user_esms : null);

        return data;
    }).catch(error => {
        console.error(error);
        throw error;
    });
};

/**
 * Chama a API Laravel para realizar o envio de email de recuperacao de senha
 * @param {object} context Store do axios
 * @param {string} email Email do usu�rio
 * @returns Promise
 */
export const ActionDoRecoveryPassword = (context, email) => {
    return services.api.request({
        url: "/auth/recovery-password",
        method: "post",
        data: {
            email: email
        }
    }).then(response => {
        return response;
    }).catch(error => {
        if (error.response.data.message !== "") {
            return error.response;
        } else {
            console.error(error.response);
            throw error;
        }
    });
};

/**
 * Busca na API Laravel a lista de empresas e produtos que o usu�rio possui acesso
 * @returns Object
 */
export const ActionDoGetMinhasEmpresas = ({ commit }) => {
    commit(types.SET_LIST_EMPRESAS, null);

    return services.api.request({
        url: "/empresas/minhas-empresas",
        method: "get"
    }).then(response => {
        let resp = response.data;

        commit(types.SET_LIST_EMPRESAS, resp);

        return resp;
    });
};

/**
 * 
 * @param {object} context Store axios
 * @param {object} json_data Dados da requisi��o {id_empresa: Number, type_produto: String}
 * @returns void
 */
export const ActionDoUpdateSession = ({ dispatch }, json_data) => {
    return services.api.request({
        url: "/auth/update_session",
        method: "post",
        data: json_data
    }).then(response => {
        if (response.data === true) {
            return dispatch("ActionDoUpdateProductPhp", json_data.type_produto).then(resp_prod => {
                return dispatch("ActionDoUpdateEmpresaPhp", json_data.id_empresa).then(resp_emp => {
                    return { resp_prod, resp_emp };
                });
            });
        }

        return response.data;

    }).catch(error => {
        console.error(error);
        throw error;
    });
};

/**
 * Realiza uma requisicao para a API laravel para alterar a senha
 * @param {object} context Store do axios
 * @param {object} params Dados do login {password}
 * @returns Promise
 */
export const resetPassword = (context, params) => {
    return services.api.request({
        url: "auth/set-password",
        method: "post",
        data: params
    }).catch(error => {
        console.error(error);
        throw error;
    });
};

/**
 * Realiza a requisi��o para o back em PHP para salvar o produto selecionado
 * @param {object} context Store do axios
 * @param {string} type_product Tipo do produto
 * @returns Promise
 */
export const ActionDoUpdateProductPhp = (context, type_product) => {
    let form_data = new FormData();
    form_data.append("type_product", type_product);

    return services.php.request({
        url: "/Login/updateTypeProductSelected",
        method: "post",
        data: form_data
    }).then((response) => {
        return response.data.type_product;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

/**
 * Realiza uma requisi��o para o back em PHP para salvar a empresa selecionada
 * @param {object} context Store axios
 * @param {number} id_empresa ID da empresa
 * @returns Promise
 */
export const ActionDoUpdateEmpresaPhp = (context, id_empresa) => {
    let form_data = new FormData();
    form_data.append("idcbemp", id_empresa);

    return services.php.request({
        url: "/Login/validaEmpresa",
        method: "post",
        data: form_data
    }).then((response) => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};