import * as types from "./mutations-types";

export default {
    [types.SET_LIST_CLIENTS_TOTAL](state, list_clients_total) {
        state.list_clients_total = list_clients_total;
    },
    [types.SET_FILTER_CLIENTS_START_DATE](state, filter_clients_start_date) {
        state.filter_clients_start_date = filter_clients_start_date;
    },
    [types.SET_FILTER_CLIENTS_END_DATE](state, filter_clients_end_date) {
        state.filter_clients_end_date = filter_clients_end_date;
    },
    [types.SET_ALL_CAMPAIGNS](state, all_campaigns) {
        state.all_campaigns = all_campaigns;
    },
    [types.SET_GENERATOR_CONFIG_STEP_ONE](state, value) {
        state.generator_step_one = value;
    },
    [types.SET_GENERATOR_CONFIG_STEP_TWO](state, value) {
        state.generator_step_two = value;
    },
    [types.SET_GENERATOR_CONFIG_STEP_THREE](state, value) {
        state.generator_step_three = value;
    },
    [types.SET_GENERATOR_CONFIG_STEP_FOUR](state, value) {
        state.generator_step_four = value;
    },
    [types.SET_GENERATOR_CONFIG_STEP_FIVE](state, value) {
        state.generator_step_five = value;
    },
    [types.SET_GENERATOR_CONFIG_STEP_SIX](state, value) {
        state.generator_step_six = value;
    }
};