import state from "./state";
import mutations from "./mutations";
import * as actions from "./actions";

export default {
    state,
    mutations,
    actions,
    namespaced: true
};
