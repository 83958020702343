import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseSelect from "../../../../components/atomos/BaseSelect?v=2";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";
import FilterPageSlider from "../../../../components/atomos/FilterPageSlider?v=2";

/**
 * Componente de formulario de pesquisa de relatorios
 * 
 * @requires {@link BaseTextField}
 * @requires {@link BaseSelect}
 * @requires {@link BaseButton}
 * @requires {@link FilterPageSlider}
 * 
 * @displayName FormFilterReports
 * @category Page / Relatorios
 * @subcategory forms
 * @author Igor Oliveira <igor.oliveira@pgmais.com>
 * @component
 * @vue
 * @vue/component
 */
export default {
    name: "form-filter-reports",
    components: {
        BaseTextField,
        BaseSelect,
        BaseButton,
        FilterPageSlider
    },
    data() {
        return {
            items_data_solicitacao: [
                {value: 7, text: "7 dias"},
                {value: 15, text: "15 dias"},
                {value: 30, text: "30 dias"}
            ],
            modalidade: null,
            data_solicitacao: 30,
            solicitante: null,
            data_solicitacao_rules: [
                v => !!v || "Selecione um intervalo",
            ],
            type_product: this.$cookie === undefined ? getCookie("type_product") : this.$cookie.get("type_product"),
        };
    },
    computed: {
        items_modalidade() {
            let items = [
                { value: null, text: "Todas" },
                { value: "analytical", text: window.Vue.htmlEntities("Anal&iacute;tico") },
                { value: "synthetic", text: window.Vue.htmlEntities("Sint&eacute;tico") },
            ];

            if (this.type_product !== "RCS") {
                items.push({ value: "radar", text: window.Vue.htmlEntities("Radar") });
                items.push({ value: "blacklist", text: window.Vue.htmlEntities("Blacklist") });
                items.push({ value: "interactive", text: window.Vue.htmlEntities("Interativo") });
                items.push({ value: "action-history", text: window.Vue.htmlEntities("Hist&oacute;rico de a&ccedil;&otilde;es") });
                items.push({ value: "messages", text: window.Vue.htmlEntities("Mensagens") });
                items.push({ value: "phone", text: window.Vue.htmlEntities("Por telefone") });
                items.push({ value: "api-shipping", text: window.Vue.htmlEntities("Envios API") });
                items.push({ value: "phones-company", text: window.Vue.htmlEntities("Telefones - Operadoras") });
                items.push({ value: "single-shipping", text: window.Vue.htmlEntities("Envios Avulsos") });
                items.push({ value: "movement-statement", text: window.Vue.htmlEntities("Extrato de movimenta&ccedil;&atilde;o") });
                items.push({ value: "blocked", text: window.Vue.htmlEntities("Bloqueados") });
            }
            return items;

            //{ value: "custom-export", text: window.Vue.htmlEntities("Relat&oacute;rio personalizado") },
            //{ value: "file-status", text: window.Vue.htmlEntities("Arquivo original + status") },
            //{ value: "single-shipping-operator", text: window.Vue.htmlEntities("Envios avulsos por operador") },
            //{ value: "search", text: window.Vue.htmlEntities("Por telefone") },
            //{ value: "billing", text: window.Vue.htmlEntities("Cobran&ccedil;a") },
            //{ value: "synthetic-corporate", text: window.Vue.htmlEntities("Corporativo sint&eacute;tico") },
            //{ value: "credit", text: window.Vue.htmlEntities("Cr&eacute;dito") },
            //{ value: "movement-statistics", text: window.Vue.htmlEntities("Estat&iacute;stica da movimenta&ccedil;&atilde;o") },
            //{ value: "general-movement-statement", text: window.Vue.htmlEntities("Extrato de movimenta&ccedil;&atilde;o") },
            //{ value: "phone-validation", text: window.Vue.htmlEntities("Valida telefone") },
            //{ value: "joker", text: window.Vue.htmlEntities("Coringa") }
        }
    },
    methods: {
        /**
         * Funcao para emicao dos valores do formulario
         * @vue
         */
        formSubmit() {
            if (this.$refs.form.validate()) {
                let data_emit = { modalidade: this.modalidade, data_solicitacao: this.data_solicitacao, solicitante: this.solicitante };
                /**
                 * Emite o conteudo do formulario
                 * @property {Object} data_emit Conteudo emitido
                 * <pre>{
                 *     modalidade: String - Modalidade [analytical, synthetic, radar, blacklist, interactive, action-history, messages, phone, api-shipping, phones-company, single-shipping, blocked, movement-statement, custom-export, file-status, single-shipping-operator, search, billing, synthetic-corporate, credit, movement-statistics, general-movement-statement, phone-validation, joker],
                 *     data_solicitacao: String - Modalidade [7 dias, 15 dias, 30 dias],
                 *     solicitante: String - Solicitante
                 * }</pre>
                */
                this.$emit("formSubmit", data_emit);
            }
        },
        /**
         * Funcao para limpar campos do formulario
         * @vue
         */
        limparFiltro() {
            this.$refs.form.reset();
        },
    },
    template: (
        /*html*/
        `
        <filter-page-slider @reset="limparFiltro">
            <v-form ref="form">
                <v-row class="mt-0" justify="center" justify-md="end"> 
                    <v-col cols="12" md="4" lg="3" v-bind:data-v-step="1">
                        <base-select
                            tooltip-text="O tipo de relat&oacute;rio solicitado"
                            v-model="modalidade"
                            label="Selecione a modalidade desejada"
                            title="Modalidade"
                            :items="items_modalidade"
                        ></base-select>
                    </v-col>
                    <v-col cols="12" md="4" lg="3" v-bind:data-v-step="2">
                        <base-select
                            tooltip-text="O per&iacute;odo que o relat&oacute;rio foi solicitado"
                            v-model="data_solicitacao"
                            label="Selecione a data de solicitao desejada"
                            title="Data de solicita&ccedil;&atilde;o"
                            :items="items_data_solicitacao"
                            :required="true"
                            :rules="data_solicitacao_rules"
                        ></base-select>
                    </v-col>
                    <v-col cols="12" md="4" lg="3" v-bind:data-v-step="3">
                        <base-text-field 
                            tooltip-text="O usu&aacute;rio solicitante"
                            v-model="solicitante"
                            label="Nome do solicitante"
                            color-tooltip="black"
                            title="Solicitante"
                        ></base-text-field>
                    </v-col>
                    <v-col class="mt-4 d-flex justify-end" cols="auto" lg="3">
                        <base-button 
                            primary
                            width="200px"
                            icon='fa fa-filter'
                            label='Aplicar filtros'
                            :disabled="false"
                            :block="false"
                            @click="formSubmit()"
                        ></base-button>
                    </v-col>
                </v-row>
            </v-form>
        </filter-page-slider>
    `
    )
};
