import FormLogin from "../../auth/components/FormLogin";

export default {
    components: { FormLogin },
    methods: {
        login(dados) {
            console.log(dados);
        },
        link() {
            console.log("Link clicado");
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="2">
                    <form-login @submitLoginButton="login" @lostPasswordLink="link"></form-login>
                </v-col>
            </v-row>
        `
    )
};