export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g id="Btn-PermissoesInternas-Hover" transform="translate(-1695 -2155)">
                    <g id="Ico-PermInt-Hover" transform="translate(1696.895 2039)">
                        <g id="Grupo_1863" data-name="Grupo 1863" transform="translate(0.105 118)">
                            <path id="Caminho_512" data-name="Caminho 512" d="M19.1,43.1a4.735,4.735,0,0,0-3.1-.2V37a2.647,2.647,0,0,0-2.6-2.6H3A2.647,2.647,0,0,0,.4,37V51.5A2.647,2.647,0,0,0,3,54.1H7.4a.6.6,0,0,0-.1.4v2.1a.789.789,0,0,0,.8.8H10a.789.789,0,0,0,.8-.8v-1h.8a.789.789,0,0,0,.8-.8v-.9h1a.764.764,0,0,0,.5-.2l2.2-2.2a4.424,4.424,0,0,0,3-.1A4.521,4.521,0,0,0,21.6,49,4.7,4.7,0,0,0,19.1,43.1Zm-6,2.3a4.735,4.735,0,0,0-.2,3.1L8.8,52.6H3a1.11,1.11,0,0,1-1.1-1.1V37A1.11,1.11,0,0,1,3,35.9H13.4A1.11,1.11,0,0,1,14.5,37v6.7A4.744,4.744,0,0,0,13.1,45.4Zm4.2,2.9a1.1,1.1,0,1,1,1.1-1.1A1.11,1.11,0,0,1,17.3,48.3Z" transform="translate(-0.4 -34.4)" fill="currentColor"/>
                            <path id="Caminho_513" data-name="Caminho 513" d="M4.1,40.1h8.2a.789.789,0,0,0,.8-.8.736.736,0,0,0-.8-.8H4.1a.8.8,0,1,0,0,1.6Z" transform="translate(-0.4 -34.4)" fill="currentColor"/>
                            <path id="Caminho_514" data-name="Caminho 514" d="M4.1,43.3h7.2a.789.789,0,0,0,.8-.8.736.736,0,0,0-.8-.8H4.1a.8.8,0,1,0,0,1.6Z" transform="translate(-0.4 -34.4)" fill="currentColor"/>
                            <path id="Caminho_515" data-name="Caminho 515" d="M4.1,46.6h6.2a.789.789,0,0,0,.8-.8.736.736,0,0,0-.8-.8H4.1a.8.8,0,1,0,0,1.6Z" transform="translate(-0.4 -34.4)" fill="currentColor"/>
                            <path id="Caminho_516" data-name="Caminho 516" d="M9,49.1a.789.789,0,0,0-.8-.8H4.1a.789.789,0,0,0-.8.8.736.736,0,0,0,.8.8H8.2A.86.86,0,0,0,9,49.1Z" transform="translate(-0.4 -34.4)" fill="currentColor"/>
                        </g>
                    </g>
                    <rect id="Retângulo_488" data-name="Retângulo 488" width="26" height="26" transform="translate(1695 2155)" fill="currentColor" opacity="0"/>
                </g>
            </svg>
        `
    )
};