import styled from "../plugins/vue-styled-components.es";
const StyledDiv = styled("svg")`
    width: 70px;

    .crack polyline {
        fill: none;
        stroke: #00B9F9;
        stroke-width: 10px;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 1649.099;
        stroke-dashoffset: 1649.099;
        -webkit-animation: drawStroke 1500ms ease-out 500ms forwards;
        animation: drawStroke 1500ms ease-out 500ms forwards;
    }

    @keyframes drawStroke {
        0% {
            stroke-dashoffset: 1649.099;
        }
        100% {
            stroke-dashoffset: 0;
        }
    }
`;

const DivLogo = styled("div")`
    position: absolute !important;
`;

export default {
    name: "not-found",
    components: {
        "styled-div": StyledDiv,
        "div-logo": DivLogo
    },
    template: (
        /*html*/
        `
        <v-row class="fill-height super-dark-primary">
            <v-col cols="12">
                <v-row class="fill-height" align="center" justify="center">
                    <v-col cols="auto" class="white--text">
                        <h3 class="text-end">Erro 404</h3>
                        <h3>P&aacute;gina n&atilde;o encontrada</h3>
                    </v-col>
                    <v-col cols="auto" class="white--text">
                        <styled-div>
                            <svg class='crack' viewBox='0 0 200 600'>
                                <polyline points='118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514'></polyline>
                            </svg>
                        </styled-div>
                    </v-col>
                    <v-col cols="auto" class="white--text">
                        <h3>Desculpe-nos por isso</h3>
                        <router-link class="primary--text text-decoration-none" :to="{ name: 'auth' }">
                            <h3>Voltar para o login?</h3>
                        </router-link>
                    </v-col>
                </v-row>
            </v-col>
            <div-logo class="col col-12">
                <v-row class="mt-3 justify-center justify-md-space-between">
                    <v-col cols="auto" class="ml-10">
                        <v-img src="/images/logo-connect-mais.png" contain max-width="55" class="float-md-left"></v-img>
                    </v-col>
                    <v-col cols="auto" class="mr-10 d-none d-md-block">
                        <v-img src="/images/logos/logo-pgmais-branca.svg" contain max-width="122" class="d-none d-lg-block float-right"></v-img>
                        <v-img src="/images/logos/logo-pgmais-cinza.svg" max-width="122" class="d-none d-md-block d-lg-none float-right"></v-img>
                    </v-col>
                </v-row>
            </div-logo>
        </v-row>
        `
    )
};