import BaseSelect from "../../../../components/atomos/BaseSelect?v=2";
import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";
import BaseDatePicker from "../../../../components/atomos/BaseDatePicker";

/**
 * Componente para configurar a repeti��o de gera��o de relat�rio
 * 
 * @requires {@link BaseSelect}
 * @requires {@link BaseDialog}
 * @requires {@link BaseDatePicker}
 * 
 * @displayName DialogRepeatReport
 * @category Page / Relatorios
 * @subcategory dialogs
 * @author Igor M R Oliveira <igor.oliveira@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    name: "dialog-repeat-report",
    components: {
        BaseSelect,
        BaseDialog,
        BaseDatePicker
    },
    props: {
        /**
         * Indica que o dialog deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * ID do relatorio escolhido
         */
        reportId: {
            type: [Number, String],
            required: false,
            default: null
        }
    },
    data() {
        return {
            items_modalidade: [
                { text: "Diariamente", value: "D" },
                { text: "Semanalmente", value: "S" },
                { text: "Mensalmente", value: "M" }
            ],
            recurrence: "D",
            start_date: (new Date()).toISOString().substr(0, 10),
            end_date: null,
            value_radio: "never",
            show_dialog: this.dialog,
            min_date: (new Date()).toISOString().substr(0, 10)
        };
    },
    watch: {
        show_dialog() {
            /**
             * Evento de input, disparado sempre que o status de exibi??o/n?o exibi??o do dialog for alterado
             * 
             * @property {string} content Status da modal
             * 
             * @event input
             * @vue
             */
            this.$emit("input", this.show_dialog);

            if (!this.show_dialog) {
                this.recurrence = "D";
                this.start_date = (new Date()).toISOString().substr(0, 10);
                this.end_date = null;
                this.value_radio = "never";
                this.min_date = (new Date()).toISOString().substr(0, 10);
            }
        },
        value() {
            this.show_dialog = this.value;
        }
    },
    methods: {
        ...window.Vuex.mapActions("reports", ["ActionDoSetRecurrence"]),
        /**
         * Evento de fechamento da modal
         * @vue
         */
        closeModal() {
            this.show_dialog = false;
        },
        /**
         * Evento de submit do formulario
         * @vue
         */
        setRecurrence() {
            if (this.$refs.form_repeat.validate()) {
                this.$emit("confirm", {
                    id_report_status: this.reportId,
                    recurrence_period: this.recurrence,
                    start_recurrence: this.start_date,
                    finish_recurrence: this.value_radio === "never" ? null : this.end_date
                });
                this.closeModal();
            }
        }
    },
    template: (
        /*html*/
        `
        <base-dialog 
            v-model="show_dialog"
            max-width="700"
            title="Repetir relat&oacute;rio"
            cancel
            use-icon-close
            :action-button="{
                icon: 'mdi-check',
                label: 'Confirmar',
                variant: 'dark-primary',
                loading: false
            }"
            @clickDialog="setRecurrence"
            @closeDialog="$emit('close')"
        >
            <v-form ref="form_repeat">
                <v-row>
                    <v-col>
                        <v-row>
                            <v-col cols="auto">
                                <span class="text-dark text-size-12">Selecione prefer&ecirc;ncias da repeti&ccedil;&atilde;o do relat&oacute;rio</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <base-select
                                    tooltip-text="O tipo de relat&oacute;rio solicitado"
                                    v-model="recurrence"
                                    label="Periodo de recorrencia"
                                    title="Quando repetir"
                                    :items="items_modalidade">
                                </base-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <base-date-picker
                                    label="Data de inicio"
                                    title= "Repeti&ccedil;&atilde;o come&ccedil;a em:"
                                    v-model="start_date"
                                    :rules="[]"
                                    :min-date="min_date">
                                </base-date-picker>
                            </v-col>
                            <v-col cols="6">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <span class="text-medium-grey font-size-12">Termina em:</span>  
                                    </v-col>
                                    <v-col cols="12">
                                        <v-radio-group v-model="value_radio" row class="mt-0">
                                            <v-row no-gutters>
                                                <v-col cols="auto">
                                                    <v-radio label="Nunca" color="dark-primary" value="never"></v-radio>
                                                </v-col>
                                                <v-col>
                                                    <v-radio label="Em uma data especifica:" color="dark-primary" value="specific-date"></v-radio>
                                                    <base-date-picker
                                                        label="Data final de recorrencia"
                                                        v-if="value_radio == 'specific-date'"
                                                        v-model="end_date"
                                                        :rules="[]"
                                                        :min-date="start_date"
                                                    ></base-date-picker>
                                                </v-col>
                                            </v-row>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
        </base-dialog>
        `
    )
};