import SummaryHygiene from "../../campaign/components/SummaryHygiene";

export default {
    components: {
        SummaryHygiene
    },
    data() {
        return {
            phones: ["41991234567", "41991234567", "41991234568", "11910686845", "11910775544", "41991234", "41995283155"],
            client: 17977, // QA PGMais
        };
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-row>
                    <v-col>
                        <v-card class="pa-5 mt-16">
                            <v-row justify="center">
                                <v-col cols="6">
                                    <summary-hygiene :phones="phones" :client="client"/>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>    
        `
    )
};