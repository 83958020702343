import BaseProgressBar from "../../../components/atomos/BaseProgressBar";
import { ProgressBarValueClass } from "../../../classes/ProgressBarValueClass";

export default {
    components: {
        BaseProgressBar
    },
    data() {
        return {
            total: 1000,
            show_tooltip_title: true,
            label: ""
        };
    },
    computed: {
        listValues() {
            return [
                new ProgressBarValueClass({name: "Entregues", total: parseInt(this.total / 4), color: "success-900"}),
                new ProgressBarValueClass({name: "SMS Alternativo", total: parseInt(this.total / 4), color: "dark-cyan"}),
                new ProgressBarValueClass({name: "N&atilde;o entregues", total: parseInt(this.total / 4), color: "error-900"}),
                new ProgressBarValueClass({name: "Aguardando", total: parseInt(this.total / 4), color: "gray-300"}),
            ];
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="12" sm="8" md="6">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field label="Label" v-model="label"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-checkbox label="Mostrar tooltip no hover do titulo" v-model="show_tooltip_title"/>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col class="d-inline" cols="6" sm="6" md="6" lg="3">
                                        <base-progress-bar
                                            :total="total"
                                            :label="label"
                                            :list-values="listValues"
                                            :show-tooltip-title="show_tooltip_title"
                                        ></base-progress-bar>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};