import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseAutocomplete from "../../../../components/atomos/BaseAutocomplete?v=2";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";
import FilterPageSlider from "../../../../components/atomos/FilterPageSlider?v=2";

/**
 * Componente de formulario de filtro de dados da dashboard
 * 
 * @requires {@link BaseTextField}
 * @requires {@link BaseAutocomplete}
 * @requires {@link BaseButton}
 * @requires {@link FilterPageSlider}
 * 
 * @displayName FormFilter
 * @category Page / Dashboard
 * @subcategory forms
 * @author David Nunes dos Santos <david.santos@pgmais.com>
 * @component
 * @vue
 * @vue/component
 */
export default {
    name: "form-filter",
    components: {
        BaseTextField,
        BaseAutocomplete,
        BaseButton,
        FilterPageSlider
    },
    props: {
        /**
         * Define o valor de v-model do componente
         * <pre>{
         *  campaigns_id: Array - Lista de IDs de campanhas,
         *  clients: Array - Lista de carteiras,
         *  status: Array - Lista de status
         * }</pre>
         */
        value: {
            type: Object,
            required: false,
            default() {
                return {
                    campaigns_id: [],
                    clients: [],
                    status: []
                };
            }
        }
    },
    data() {
        return {
            data: this.value,
            show_form: false,
            campaign_id: null,
            campaign_id_list: [],
            status_items: [
                { text: "A Enviar", value: "A" },
                { text: "Enviando", value: "E" },
                { text: "Pausado", value: "P" },
                { text: "Cancelado", value: "C" },
                { text: "Finalizada", value: "F" },
                { text: "Processando", value: "G" },
                { text: "Renitencia", value: "R" }
            ],
            clients_loading: false,
            clients: [],
            campaigns_loading: false,
            campaigns: [],
            pills_filter: [],
            disable_button: true
        };
    },
    computed: {
        form_status() {
            return this.data.status;
        },
        form_clients() {
            return this.data.clients;
        },
        showReset() {
            return this.data.campaigns_id.length > 0 || this.data.clients.length > 0 || this.data.status.length > 0;
        }
    },
    watch: {
        campaign_id() {
            if (this.campaign_id && this.campaign_id.length > 0) {
                this.data.campaigns_id = [this.campaign_id];
            } else {
                this.data.campaigns_id = this.campaign_id_list;
            }
            this.disable_button = false;
        },
        campaign_id_list() {
            if (!this.campaign_id || this.campaign_id.length === 0) {
                this.data.campaigns_id = this.campaign_id_list;
            }
            this.disable_button = false;
        },
        value() {
            this.data = this.value;
        },
        form_status() {
            this.disable_button = false;
        },
        form_clients() {
            this.disable_button = false;
        }
    },
    mounted() {
        this.getClients();
        this.getCampaigns();
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetMyClients"]),
        ...window.Vuex.mapActions("dashboard", ["ActionDoGetCampaigns"]),
        /**
         * Funcao de submit do formulario de filtro
         * @vue
         */
        formSubmit() {
            if (this.$refs.formFilter.validate()) {
                /**
                 * Emite o conteudo do formulario
                 * @property {Object} data Conteudo emitido
                 * <pre>{
                 *  campaigns_id: Array - Lista de IDs de campanhas,
                 *  clients: Array - Lista de carteiras,
                 *  status: Array - Lista de status
                 * }</pre>
                 * 
                 * @event submit
                 * @vue
                 */
                this.$emit("input", this.data);
                this.$emit("submit", this.data);

                this.makePillsFilter();
                this.disable_button = true;
            }
        },
        /**
         * Busca na API e monta a lista de clientes (carteiras) que a empresa definida na sessao possui
         * @vue
         */
        getClients() {
            this.clients_loading = true;
            this.ActionDoGetMyClients(true).then((response) => {
                this.clients = response;
                this.clients_loading = false;
            });
        },
        /**
         * Busca na API e monta a lista de campanhas para colocar no select do filtro
         * @vue
         */
        getCampaigns() {
            this.campaigns_loading = true;
            this.ActionDoGetCampaigns().then((response) => {
                for (let index in response) {
                    if (response[index].ID_CAMPANHA_CAES && response[index].NM_CAMPANHA_CAES) {
                        this.campaigns.push({
                            text: response[index].NM_CAMPANHA_CAES,
                            value: response[index].ID_CAMPANHA_CAES
                        });
                    }
                }
                this.campaigns_loading = false;
            });
        },
        /**
         * Reseta o formulario
         * @vue
         */
        resetForm() {
            this.$refs.formFilter.reset();

            setTimeout(() => {
                this.formSubmit();
            }, 500);
        },
        /**
         * Monta a configuracao das pills do filtro
         * @vue
         */
        makePillsFilter() {
            let config = [];

            if (this.data.status !== null && this.data.status.length > 0) {
                config.push({
                    text: "Status:",
                    subText: this.data.status.length + " selecionados",
                    click: () => {
                        this.data.status = [];
                        this.formSubmit();
                    }
                });
            }

            if (this.data.campaigns_id !== null && this.data.campaigns_id.length > 0) {
                config.push({
                    text: "Campanhas:",
                    subText: this.data.campaigns_id.length + " selecionadas",
                    click: () => {
                        this.data.campaigns_id = [];
                        this.formSubmit();
                    }
                });
            }

            if (this.data.clients !== null && this.data.clients.length > 0) {
                config.push({
                    text: "Clientes:",
                    subText: this.data.clients.length + " selecionados",
                    click: () => {
                        this.data.clients = [];
                        this.formSubmit();
                    }
                });
            }

            this.pills_filter = config;
        }
    },
    template: (
        /*html*/
        `
        <filter-page-slider 
            v-model="show_form" 
            @reset="resetForm" 
            v-bind:data-v-step="1" 
            :pills-filter="pills_filter"
            :show-reset="showReset"
        >
            <v-form ref="formFilter" class="pb-10">
                <v-row class="mt-0" justify="center"> 
                    <v-col cols="6" lg="5">
                        <base-text-field
                            label="Identificador"
                            type="number"
                            tooltip-text="Voc&ecirc; pode filtrar pelo n&uacute;mero identificador (id) da campanha."
                            v-model="campaign_id"
                            title="Identificador"
                        ></base-text-field>
                    </v-col>
                    <v-col cols="6" lg="5">
                        <base-autocomplete
                            label="Cliente"
                            tooltip-text="Voc&ecirc; pode filtrar por cliente (carteira) para visualizar as campanhas"
                            v-model="data.clients"
                            title="Cliente"
                            :items="clients"
                            :rules="[]"
                            :loading="clients_loading"
                            multiple
                            use-icon-validation
                        ></base-autocomplete>
                    </v-col>
                    <v-col cols="6" lg="5">
                        <base-autocomplete
                            label="Campanha"
                            tooltip-text="Voc&ecirc; pode filtrar pelo nome da campanha."
                            v-model="campaign_id_list"
                            title="Campanha"
                            :items="campaigns"
                            :rules="[]"
                            :loading="campaigns_loading"
                            multiple
                            use-icon-validation
                        ></base-autocomplete>
                    </v-col>
                    <v-col cols="6" md="3" lg="2">
                        <base-autocomplete
                            label="Situa&ccedil;&atilde;o"
                            tooltip-text="Voc&ecirc; pode filtrar por situa&ccedil;&atilde;o (status) dos envios"
                            v-model="data.status"
                            title="Situa&ccedil;&atilde;o"
                            :items="status_items"
                            :rules="[]"
                            :return-object="false"
                            multiple
                            use-icon-validation
                        ></base-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3" class="mt-4">
                        <base-button
                            primary
                            icon='fa fa-filter'
                            label='Aplicar filtros'
                            :disabled="disable_button"
                            @click="formSubmit"
                        ></base-button>
                    </v-col>
                </v-row>
            </v-form>
        </filter-page-slider>
    `
    )
};
