import * as types from "./mutations-types";

export default {
    [types.SET_SHOWING_NEWS](state, value) {
        state.showing_news = value;
    },
    [types.SET_LIST_CLIENTS](state, value) {
        state.list_clients = value;
    },
    [types.SET_LIST_CLIENTS_FOR_SELECT](state, value) {
        state.list_clients_for_select = value;
    },
    [types.SET_CLIENTS_LOADING](state, value) {
        state.clients_loading = value;
    }
};