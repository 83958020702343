import BaseButton from "../../../components/atomos/BaseButton?v=2";
import BaseTextField from "../../../components/atomos/BaseTextField";

/**
 * Componente de formulario de login
 * 
 * @component
 * @category Page / Auth
 * @subcategory Forms
 * @requires {@link base-button.html|BaseButton}
 * @requires {@link base-text-field.html|BaseTextField}
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 */
export default {
    name: "form-login",
    components: { BaseButton, BaseTextField },
    data: function () {
        return {
            email: "",
            email_rules: [
                v => !!v || "Digite um email",
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || window.Vue.htmlEntities("Digite um email v&aacute;lido")
            ],
            senha: "",
            senha_rules: [
                v => !!v || "Digite uma senha"
            ],
            email_label: "",
            senha_label: ""
        };
    },
    props: {
        /**
         * Mensagem de erro a ser exibida no formulario
         */
        error_message: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Indica se o botao de submit deve estar desabilitado ou nao
         */
        disable_button: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        /**
         * Faz a validacao dos campos do formulario e emite um evento submitLoginButton com os dados email e senha
         * @vue
         */
        formSubmit() {
            if (this.$refs.form_login.validate()) {
                this.$recaptchaLoaded();
                this.$recaptcha("login").then((token) => {
                    let formData = {
                        email: this.email,
                        senha: this.senha,
                        recaptcha: token
                    };

                    /**
                     * Evento de submit do formulario
                     * 
                     * @property {object} formData Dados do login {email, senha}
                     * 
                     * @event submitLoginButton
                     * @vue
                     */
                    this.$emit("submitLoginButton", formData);
                });
            }
        },
        /**
         * Emite um evento informando que o link foi clicado
         * @vue
         */
        lostPassword() {
            /**
             * Evento de click no link de "Esqueci minha senha"
             * 
             * @event lostPasswordLink
             * @vue
             */
            this.$emit("lostPasswordLink");
        },
        fillLabels() {
            //console.log("Filled");
            this.email_label = "Email*";
            this.senha_label = "Senha*";
        }
    },
    template: (
        /*html*/
        `
            <v-form ref="form_login">
                <v-row no-gutters>
                    <v-col>
                        <h5 class="font-weight-bold dark-primary--text">Bem vindo ao +Connect</h5>
                        <p class="font-075rem" v-click-outside="fillLabels">Informe seu e-mail e senha para entrar.</p>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="error_message != ''">
                    <v-col>
                        <p>
                            <v-icon x-small color="danger">fa-exclamation-circle</v-icon>
                            <span class="danger--text" v-html="error_message"></span>
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mb-6">
                    <v-col>
                        <base-text-field v-model="email" :value="email" :rules="email_rules" :label="email_label" :required="true" title="E-mail:" @enterPressed="formSubmit"></base-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <base-text-field v-model="senha" :rules="senha_rules" :label="senha_label" :required="true" type="password" @enterPressed="formSubmit" autocomplete="off" title="Senha:"></base-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters justify="end" class="mb-3">
                    <v-col cols="auto">
                        <a class="font-075rem text-decoration-none dark-primary--text" @click="lostPassword">Esqueci minha senha</a>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <base-button
                            primary
                            icon='fa-check'
                            label='Entrar'
                            :disabled="disable_button"
                            @click="formSubmit"
                        ></base-button>
                    </v-col>
                </v-row>
            </v-form>
        `
    )
};