import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente basico de select do painel 
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName BaseSelect
 * @component
 * @vue
 * @category Atomos
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Array de items para listagem no select do componente
         */
        items: {
            type: Array,
            required: true
        },
        /**
         * Texto a ser exibido como placeholder
         */
        label: {
            type: String,
            required: true
        },
        /**
         * Define o status de loading
         */
        loading: {
            type: [Boolean, String],
            required: false,
            default: false
        },
        /**
         * Define a obrigatoriedade de preenchimento do campo
         * @values true, false
         */
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define as regras a serem utilizadas para validacao dos dados inseridos
         */
        rules: {
            type: Array,
            required: false,
            default: function () {
                return [];
            }
        },
        /**
         * Value do select
         */
        value: {
            type: [String, Number, Array],
            required: false,
            default: null
        },
        /**
         * verificar se usa o icone de validacao 
         */
        useIconValidation: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Title do select
         */
        title: {
            type: String,
            required: false,
            default: null
        },
        /**
         * hint do select
         */
        hint: {
            type: String,
            required: false,
            default: null
        },
        /**
         * popoverContent do select
         */
        popoverContent: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Define se o select pode ser multiselect ou nao
         */
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define que o select vai retornar um objeto
         */
        returnObject: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Texto do tooltip do campo
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Cor do tooltip
         */
        colorTooltip: {
            type: String,
            required: false,
            default: "gray-700"
        },
        /**
         * Icone no inicio do componente
         */
        prependIcon: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Habilita o icone de limpar o conteudo
         */
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        id: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            content: this.value,
            icon: null,
            show: false,
            label_hover: false
        };
    },
    computed: {
        show_tooltip() {
            return this.label_hover && this.tooltipText;
        }
    },
    watch: {
        content() {
            /**
             * Evento de input, disparado sempre que o conteudo do campo for alterado
             * 
             * @property {string} content Conteudo do campo
             * 
             * @event input
             * @vue
            */
            this.$emit("input", this.content);
            this.$emit("change", this.content);

            if (this.rules?.length === 0 && this.content?.length === 0) {
                this.setIcon(true);
            } else {
                this.setIcon();
            }
        },
        value() {
            this.content = this.value;
        }
    },
    methods: {
        /**
         * Emite um evento informando que a tecla enter foi pressionada no campo
         * @vue
         */
        enterPressed() {
            /**
             * Evento que informa que a tecla enter foi pressioanda enquanto o campo estava em foco
             * 
             * @property {string} content Conteudo do campo
             * 
             * @event enterPressed
             * @vue
             */
            this.$emit("enterPressed", this.content);
        },
        /**
         * Funcao para setar o icone de validacao do input
         */
        setIcon(clear) {
            if (clear) {
                this.icon = "";
            } else if (this.$refs.error.validate()) {
                this.icon = " text-success fa-check";
            } else {
                this.icon = " text-danger fa-exclamation";
            }

            if (this.content == "T") { // Se o status for "T de Todos", remove o icone
                this.icon = " text-success fa-check";
            }
        },
        /**
         * Funcao para modificar o estado do popover
         * @vue
         */
        togglePopover() {
            this.show = !this.show;
        },
        /**
         * Funcao para desabilitar popover
         * @vue
         */
        closePopover() {
            this.show = false;
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters>
                <v-col cols="12">
                    <base-tooltip :color="colorTooltip" :show="show_tooltip" :text="tooltipText">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-hover v-model="label_hover" :open-delay="500">
                                    <v-row v-if="title" no-gutters>
                                        <v-col>
                                            <span class="body-2 text-gray-700">{{ title | htmlEntities }} <i v-if="useIconValidation" :class="'fas ' + icon" /></span>
                                        </v-col>
                                    </v-row>
                                </v-hover>
                                <v-row no-gutters>
                                    <v-col class="pt-1">
                                        <v-select
                                            ref="error"
                                            v-model="content"
                                            :items="items"
                                            :loading="loading"
                                            :disabled="loading ? true : false"
                                            class="mb-n3"
                                            :rules="rules"
                                            :label="label | htmlEntities"
                                            :required="required"
                                            solo
                                            color="dark-primary"
                                            item-color="dark-primary"
                                            hide-details="auto"
                                            :multiple="multiple"
                                            :return-object="returnObject"
                                            :clearable="clearable"
                                            :prepend-icon="prependIcon"
                                            @keydown.enter.prevent="enterPressed"
                                            :id="id"
                                        >
                                            <template v-slot:item="{ item }">
                                                <div :id="id ? id + '-select-item-' + item.value : 'select-item-' + item.value">
                                                    {{ item.text }}
                                                </div>
                                            </template>
                                            <template #message="{ message }">
                                                <v-row no-gutters justify="start" class="flex-nowrap">
                                                    <v-col cols="auto">
                                                        <v-icon x-small left color="error-500">
                                                            mdi-alert
                                                        </v-icon>
                                                    </v-col>
                                                    <v-col class="text-error-500 text-start caption">
                                                        {{ message }}
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template v-if="$scopedSlots.content_slot" #selection="{ item, index }">
                                                <slot name="content_slot" :item="item" :index="index" />
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row v-if="hint && !popoverContent" no-gutters class="text-center">
                                    <v-col>
                                        <span v-html="hint" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </base-tooltip>
                    <v-row v-if="popoverContent && hint" no-gutters class="text-end">
                        <v-col>
                            <base-tooltip :color="colorTooltip" :show="show" :text="popoverContent">
                                <a
                                    v-click-outside="closePopover"
                                    class="body-2 text-primary-blue-500"
                                    href="#"
                                    @click="togglePopover"
                                    v-html="hint"
                                />
                            </base-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};