/**
 * Classe que representa o conteudo de um progress bar 
 * @property {string} name
 * @property {number} divisor
 * @property {number} dividendo
 * @property {string} tooltip
 * @property {Boolean} disable_tooltip
 */
export default class CardContentClass {
    name
    item_click
    divisor
    dividendo
    tooltip
    disable_tooltip
    hide_percent

    /**
     * Cria um CardContentClass
     * @param {String} name Nome do componente
     * @param {String} item_click Habilita o click do texto
     * @param {Number} divisor divisor
     * @param {Number} dividendo dividendo
     * @param {String} tooltip texto tooltip
     * @param {Boolean} disable_tooltip desabilitando tooltip
     * @param {Boolean} hide_percent esconde a porcentagem
     */
    constructor({
        name = null,
        item_click = null,
        divisor = null,
        dividendo = null,
        tooltip = null,
        disable_tooltip = null,
        hide_percent = false
    }) {
        this.name = name;
        this.item_click = item_click;
        this.divisor = divisor;
        this.dividendo = dividendo;
        this.tooltip = window.Vue.htmlEntities(tooltip);
        this.disable_tooltip = disable_tooltip;
        this.hide_percent = hide_percent;
    }

    /**
     * Pega o valor de name
     * @return {String} name do objeto
     */
    get name() {
        return this.name;
    }
    /**
     * Define um novo name para o objeto
     * @param {String} name - name do objeto
     */
    set name(name) {
        this.name = name;
    }

    /**
     * Pega o valor de name
     * @return {String} name do objeto
     */
    get item_click() {
        return this.item_click;
    }
    /**
     * Define um novo item_click para o objeto
     * @param {String} item_click - item_click do objeto
     */
    set item_click(item_click) {
        this.item_click = item_click;
    }
    /**
     * Pega o valor divisor
     * @return {Number} divisor
     */
    get divisor() {
        return this.divisor;
    }
    /**
     * Define um novo divisor
     * @param {Number} divisor
     */
    set divisor(divisor) {
        this.divisor = divisor;
    }
    /**
     * Pega o valor de dividendo
     * @return {Number} dividendo
     */
    get dividendo() {
        return this.dividendo;
    }
    /**
     * Define um novo dividendo
     * @param {Number} dividendo
     */
    set dividendo(dividendo) {
        this.dividendo = dividendo;
    }
    /**
     * Pega o texto tooltip
     * @return {String} tooltip
     */
    get tooltip() {
        return this.tooltip;
    }
    /**
     * Define um novo tooltip
     * @param {String} tooltip
     */
    set tooltip(tooltip) {
        this.tooltip = tooltip;
    }
    /**
     * Pega o texto tooltip
     * @return {Boolean} tooltip
     */
    get disable_tooltip() {
        return this.disable_tooltip;
    }
    /**
     * Define um novo disable_tooltip
     * @param {Boolean} disable_tooltip
     */
    set disable_tooltip(disable_tooltip) {
        this.disable_tooltip = disable_tooltip;
    }
}