import ModalFormTesteTemplate from "../../template-rcs/components/dialogs/ModalFormTesteTemplate";
import BaseButton from "../../../components/atomos/BaseButton";

export default ({
    components: {
        ModalFormTesteTemplate,
        BaseButton
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        testFunction() {
            console.log("teste");
        },
        toggleModal() {
            this.dialog = !this.dialog;
        },
    },
    template: (
        /*html*/
        `
        <v-row>
            <v-col cols="2">
                <base-button
                    color="dark-primary"
                    label="Enviar mensagem"
                    icon="fa-paper-plane"
                    @click="toggleModal">
                </base-button>
                <modal-form-teste-template
                    v-model="dialog"
                    :controlModal="dialog"
                    @clickModal="testFunction">
                </modal-form-teste-template>
            </v-col>
        </v-row>
        
        `
    )
});