import SupportContactDialog from "../../../components/organismos/SupportContactDialog";
import BaseButton from "../../../components/atomos/BaseButton";

export default {
    components: {
        SupportContactDialog,
        BaseButton
    },
    data() {
        return {
            show_dialog: false
        };
    },
    methods: {
        testFunction() {
            console.log("teste");
        }
    },
    template: (
        /*html*/
        `
        <v-container>
            <v-row justify="center">
                <v-col cols="3">
                    <base-button
                        color="dark-primary"
                        label="Abrir modal"  
                        @click="show_dialog = true">
                    </base-button>
                </v-col>
            </v-row>
            <support-contact-dialog v-model="show_dialog">
            </support-contact-dialog>
        </v-container>
        `
    )
};