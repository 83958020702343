export default {
    list_clients_total: [],
    filter_clients_start_date: null,
    filter_clients_end_date: null,
    all_campaigns: false,
    generator_step_one: {},
    generator_step_two: {},
    generator_step_three: {},
    generator_step_four: {},
    generator_step_five: {},
    generator_step_six: {}
};