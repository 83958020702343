/**
 * Componente de status de campanha, utilizado em tabelas da Dashboard, Avulso API e outros lugares
 *
 * @displayName StatusCampaign
 * @component
 * @category Moleculas
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    props: {
        /**
         * Codigo do status da campanha
         */
        status: {
            type: String,
            required: true,
            validator: function (data) {
                return ["A", "E", "P", "F", "G", "R", "C"].indexOf(data) > -1;
            }
        },
        /**
         * Porcentagem de progresso, usado somente se status = G
         */
        percentProgress: {
            type: [String, Number],
            required: false,
            default: 0
        }
    },
    computed: {
        style() {
            let config = window.Vue.getStatusCampaignConfig(this.status);

            if (this.status === "G") {
                config.label += " (" + (this.percentProgress || 0) + "%)";
            }

            return config;
        }
    },
    template:
        /*html*/
        `
            <v-row no-gutters class="d-flex flex-row justify-center align-center">
                <v-col cols="12">
                    <span :class="'d-inline-flex rounded-8 bg-' + style.color + ' '+ style.textColor + '--text body-2 py-1 px-2'">
                        <v-icon
                            v-if="style.icon"
                            :color="style.textColor"
                            left
                            size="12"
                            class="ma-0 d-inline-flex"
                        >{{ style.icon }}</v-icon>
                        <span class="body-2 d-inline-flex align-center pl-2"> {{ style.label }} </span>
                    </span>
                </v-col>
            </v-row>
        `
};
