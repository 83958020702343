import FooterAction from "../../../components/moleculas/FooterAction";

export default {
    name: "footer-action-usage",
    components: {
        FooterAction
    },
    data() {
        return {
            btn_right_text: "Continuar",
            btn_right_icon: "fa-arrow-right",
            btn_right_disabled: false,
            sub_text_left: "1. Alguma descricao",
            btn_left_text: "Voltar",
            btn_left_icon: "fa-undo",
            btn_left_disabled: false
        };
    },
    template: (
        /*html*/
        `
            <v-row class="mt-16" justify="center">
                <v-col cols="8">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field label="Label do botao esquerdo" v-model="btn_left_text"></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field label="Icone do botao esquerdo" v-model="btn_left_icon"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-checkbox label="Botao esquerdo desabilitado" v-model="btn_left_disabled" ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="1" class="text-center">
                                        <v-divider vertical></v-divider>
                                    </v-col>
                                    <v-col>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-text-field label="Label do botao direito" v-model="btn_right_text"></v-text-field>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field label="Icone do botao direito" v-model="btn_right_icon"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-checkbox label="Botao direito desabilitado" v-model="btn_right_disabled" ></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field label="Sub text lado esquerdo" v-model="sub_text_left"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="mt-10">
                        <v-col>
                            <footer-action
                                :btn-right-text="btn_right_text"
                                :btn-right-icon="btn_right_icon"
                                :btn-right-disabled="btn_right_disabled"
                                :sub-text-left="sub_text_left"
                                :btn-left-text="btn_left_text"
                                :btn-left-icon="btn_left_icon"
                                :btn-left-disabled="btn_left_disabled"
                            ></footer-action>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
     
        `
    )
};