import BaseDialog from "../../../../components/moleculas/BaseDialog";
import BaseDataTable from "../../../../components/organismos/BaseDataTable";
import PreviewTemplate from "../../../../components/organismos/PreviewTemplate";
import DialogPreviewTemplate from "../../../../components/organismos/DialogPreviewTemplate";
import TemplateUtils from "../../../../utils/TemplateUtils";
import { CardRcsClass } from "../../../../classes/CardRcsClass";
import { BasePillClass } from "../../../../classes/BasePillClass";
import { DraggableItemClass } from "../../../../classes/DraggableItemClass";
import { DataTableHeaderClass } from "../../../../classes/DataTableHeaderClass";

/**
 * Componente para exibicao de templates
 * 
 * @requires {@link BaseDialog}
 * @requires {@link BaseDataTable}
 * @requires {@link PreviewTemplate}
 * @requires {@link DialogPreviewTemplate}
 * @requires {@link TemplateUtils}
 * @requires {@link CardRcsClass}
 * @requires {@link BasePillClass}
 * @requires {@link DraggableItemClass}
 * @requires {@link DataTableHeaderClass}
 * 
 * @displayName DialogTemplateManual
 * @component
 * @category Dialog
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseDialog,
        BaseDataTable,
        PreviewTemplate,
        DialogPreviewTemplate
    },
    props: {
        /**
         * Controla a exibicao do dialog
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Lista de templates a serem exibidos
         */
        listTemplate: {
            type: Array,
            required: true
        },
        /**
         * ID do template previamente selecionado
         */
        templateSelected: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            action_button: {
                label: "Utilizar template selecionado",
                icon: "mdi-check-bold"
            },
            table_headers: [
                new DataTableHeaderClass({
                    text: "Nome",
                    value: "CD_TEMPLATE",
                    cellClass: "break-word",
                    tooltipText: "C&oacute;digo da template",
                    sortable: false,
                    filterable: true
                })
            ],
            template: [],
            template_utils: new TemplateUtils(),
            show_dialog_preview: false,
            ds_template: null
        };
    },
    computed: {
        configTemplate() {
            let template = {
                type: "",
                message: "",
                list_suggestions: [],
                image: "",
                card: new CardRcsClass({}),
                list_card: []
            };

            if (this.template && this.template.length) {
                let ds_template = this.template[0].DS_TEMPLATE;

                try {
                    ds_template = JSON.parse(ds_template);
                } catch (error) {
                    ds_template = { text: ds_template };
                }

                if (ds_template.url !== undefined && ds_template.url !== "") {
                    template.type = "image";
                    template.image = ds_template.url[0] === "{" && ds_template.url[ds_template.url.length - 1] === "}" ? "variable" : ds_template.url;
                } else if (ds_template.text !== undefined && ds_template.text !== "") {
                    template.type = "message";
                    template.message = this.template_utils.formatMessageList(ds_template.text);
                    template.list_suggestions = [];

                    if (ds_template.suggestions !== undefined && Array.isArray(ds_template.suggestions)) {
                        for (let suggestion of ds_template.suggestions) {
                            let pill = new BasePillClass({ title: suggestion.label });

                            switch (suggestion.type) {
                            case "url":
                                pill.titleIcon = "fa-globe-americas";
                                pill.type = "link";
                                break;
                            case "dial_phone":
                                pill.titleIcon = "fa-phone-alt";
                                pill.type = "phone";
                                break;
                            case "show_location":
                                pill.titleIcon = "fa-map-marker-alt";
                                pill.type = "map";
                                break;
                            case "request_location":
                                pill.titleIcon = "fa-crosshairs";
                                pill.type = "localization";
                                break;
                            }

                            template.list_suggestions.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                        }
                    }
                } else if (ds_template.contents !== undefined && !Array.isArray(ds_template.contents)) {
                    template.type = "card";
                    let buttons = [];
                    let responses = [];

                    if (ds_template.contents.suggestions !== undefined && Array.isArray(ds_template.contents.suggestions)) {
                        for (let index in ds_template.contents.suggestions) {
                            let pill = new BasePillClass({ title: ds_template.contents.suggestions[index].label });

                            switch (ds_template.contents.suggestions[index].type) {
                            case "url":
                                pill.titleIcon = "fa-globe-americas";
                                pill.type = "link";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "dial_phone":
                                pill.titleIcon = "fa-phone-alt";
                                pill.type = "phone";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "show_location":
                                pill.titleIcon = "fa-map-marker-alt";
                                pill.type = "map";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "request_location":
                                pill.titleIcon = "fa-crosshairs";
                                pill.type = "localization";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "text":
                                responses.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            }
                        }
                    }

                    template.card = new CardRcsClass({
                        image: ds_template.contents.media !== undefined ? (ds_template.contents.media.file.url[0] === "{" && ds_template.contents.media.file.url[ds_template.contents.media.file.url.length - 1] === "}" ? "variable" : ds_template.contents.media.file.url) : null,
                        title: this.template_utils.formatMessageList(ds_template.contents.title),
                        description: this.template_utils.formatMessageList(ds_template.contents.description),
                        suggestionButtons: buttons,
                        suggestionResponses: responses,
                        orientation: ds_template.orientation,
                        alignment: ds_template.alignment,
                        image_height: ds_template.contents.media?.height ?? "TALL"
                    });
                } else if (ds_template.contents !== undefined && Array.isArray(ds_template.contents)) {
                    template.type = "carousel";
                    template.list_card = [];

                    for (let card of ds_template.contents) {
                        let buttons = [];
                        let responses = [];

                        if (card.suggestions !== undefined && Array.isArray(card.suggestions)) {
                            for (let suggestion of card.suggestions) {
                                let pill = new BasePillClass({ title: suggestion.label });

                                switch (suggestion.type) {
                                case "url":
                                    pill.titleIcon = "fa-globe-americas";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "dial_phone":
                                    pill.titleIcon = "fa-phone-alt";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "show_location":
                                    pill.titleIcon = "fa-map-marker-alt";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "request_location":
                                    pill.titleIcon = "fa-crosshairs";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "text":
                                    responses.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                }
                            }
                        }

                        template.list_card.push(new CardRcsClass({
                            image: card.media !== undefined ? (card.media.file.url[0] === "{" && card.media.file.url[card.media.file.url.length - 1] === "}" ? "variable" : card.media.file.url) : null,
                            title: this.template_utils.formatMessageList(card.title),
                            description: this.template_utils.formatMessageList(card.description),
                            suggestionButtons: buttons,
                            suggestionResponses: responses
                        }));
                    }
                }
            }
            return template;
        }
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            this.$emit("input", this.show);
        },
        templateSelected() {
            this.selectTemplate();
        }
    },
    mounted() {
        this.selectTemplate();
    },
    methods: {
        /**
         * Emite o template selecionado
         * @vue
         */
        setTemplate() {
            this.show = false;
            this.$emit("setTemplate", this.template[0]);
        },
        /**
         * Marca um template como selecionado
         * @vue
         */
        selectTemplate() {
            if (this.templateSelected) {
                let template = this.listTemplate.find((item) => item.ID_TEMPLATE === this.templateSelected);

                this.template = [
                    template
                ];
            }
        },
        /**
         * Exibe o dialog de preview de template
         * @param {String} content_preview JSON no formato string
         * @vue
         */
        showDialogPreview(content_preview) {
            this.ds_template = content_preview;
            this.show_dialog_preview = true;
        },
    },
    template:
        /*html*/
        `  
        <base-dialog
            v-model="show"
            :action-button="action_button"
            title="Manual de templates"
            max-width="auto"
            use-icon-close
            cols-button-action="auto"
            justify-buttons="center"
            :disable-action-button="template?.length === 0"
            @clickDialog="setTemplate"
        >
            <v-row class="pa-4">
                <v-col cols="7">
                    <base-data-table
                        v-model="template"
                        :selected-items="template"
                        :headers="table_headers"
                        :list-items="listTemplate"
                        filterable
                        :custom-columns="false"
                        select-column
                        item-key="ID_TEMPLATE"
                        single-select
                        dense
                        sort-by="CD_TEMPLATE"
                        :sort-desc="false"
                    />
                </v-col>
                <v-col cols="5">
                    <v-card class="bg-background" elevation="0">
                        <preview-template
                            v-if="template?.length"
                            :type="configTemplate.type"
                            :message="configTemplate.message"
                            :list-suggestions="configTemplate.list_suggestions"
                            :image="configTemplate.image"
                            :card="configTemplate.card"
                            :list-card="configTemplate.list_card"
                            @showDialogPreview="showDialogPreview"
                        />
                    </v-card>
                </v-col>
            </v-row>
            <dialog-preview-template v-model="show_dialog_preview" :ds-template="ds_template" />
        </base-dialog>
        `,
};