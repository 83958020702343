import BaseButtonCheckbox from "../../../components/atomos/BaseButtonCheckbox";
import colors from "../../../plugins/vuetify/colors";

export default {
    components: {
        BaseButtonCheckbox
    },
    data() {
        return {
            id: "teste1",
            label: "Mensagens Avulsas (147)",
            icon: "$userRefreshCircleIcon",
            checked: false,
            disabled: false,
            background_color: "off-white",
            background_color_hover: "extra-light-grey",
            background_color_checked: "dark-primary",
            content_color: "black",
            content_color_hover: "black",
            content_color_checked: "off-white",
            colors: []
        };
    },
    mounted() {
        Object.entries(colors).forEach(([parent, childrens]) => {
            this.colors.push({
                header: parent.charAt(0).toUpperCase() + parent.slice(1)
            });

            Object.entries(childrens).forEach(([key]) => {
                let name = key.replaceAll("_", " ");

                this.colors.push({
                    text: name.charAt(0).toUpperCase() + name.slice(1),
                    value: key.replaceAll("_", "-")
                });
            });

            this.colors.push({
                divider: true
            });
        });
    },
    methods: {
        closeClick() {
            alert("O botao de remover foi clicado!");
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5 bg-background">
                                <v-row justify="center">
                                    <v-col cols="2">
                                        <v-checkbox label="Selecionado" v-model="checked"></v-checkbox>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-checkbox label="Desabilitado" v-model="disabled"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field label="Label" v-model="label"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Icone" v-model="icon"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-autocomplete label="Cor de fundo" v-model="background_color" :items="colors"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-autocomplete label="Cor de fundo com hover" v-model="background_color_hover" :items="colors"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-autocomplete label="Cor de fundo selecionado" v-model="background_color_checked" :items="colors"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-autocomplete label="Cor do conteudo" v-model="content_color" :items="colors"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-autocomplete label="Cor do conteudo com hover" v-model="content_color_hover" :items="colors"></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-autocomplete label="Cor do conteudo selecionado" v-model="content_color_checked" :items="colors"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="2">
                                        <base-button-checkbox
                                            v-model="checked"
                                            :id="id"
                                            :label="label"
                                            :icon="icon"
                                            :background-color="background_color"
                                            :background-color-hover="background_color_hover"
                                            :background-color-checked="background_color_checked"
                                            :content-color="content_color"
                                            :content-color-hover="content_color_hover"
                                            :content-color-checked="content_color_checked"
                                            :disabled="disabled"
                                        ></base-button-checkbox>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};