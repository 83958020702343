import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";

/**
 * Componente de modal com formulario, para envios de teste de template
 * @displayName ModalFormMensagemTeste
 * @requires {@link BaseDialog}
 * @component
 * @category Page / Template RCS
 * @subcategory dialogs
 * @vue
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br>
 * @vue/component 
 */
export default {
    components: {
        BaseDialog,
    },
    props: {
        /**
        * Nome do template
        */
        templateName: {
            type: String,
            required: false,
            default: null
        },
        /**
        * Propriedade para o controlar status da modal se ela esta ou nao ativa
        */
        controlModal: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            dialog: this.controlModal,
            edit_mode: false
        };
    },
    watch: {
        controlModal() {
            if (!this.controlModal) {
                document.addEventListener("keyup", this.escEvent);
            } else {
                document.removeEventListener("keyup", this.escEvent);
            }

            this.dialog = this.controlModal;
        },
        dialog() {

            /**
            *
            * E realizado um emmit de input sempre que o do valor do dialog eh alterado 
            * @event input
            * @vue
            * @property {Boolean} dialog  status da modal se ela esta ou nao ativa
            */
            this.$emit("input", this.dialog);
        },
    },
    mounted() {
        //Verifica se na URL tem o parametro id
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);

        if (params.get("id")) {
            this.edit_mode = true;
        }

    },
    methods: {
        /**
        * Funcao que o fecha a modal    
        * @vue
        */
        closeModal() {

            if (!this.edit_mode) {
                this.dialog = false;
                this.$emit("close");
            }
        },
        /**
         * Funcao que fica vinculada ao bot�o de  criar novo template
         * @vue
         */
        clickSubAction() {
            /**
            * Evento de clickSubAction do componente
            * @event clickSubAction
            * @vue
            */
            this.$emit("clickSubAction");
        },
        /**
         * Funcao par vincular eventos no esq
         * @vue
         * @param {Object} e 
         */
        escEvent(e) {
            if (!this.dialog) {
                if (!e) {
                    e = window.event;
                }
                let keyCode = e.keyCode || e.which;
                if (keyCode == "27") {
                    /**
                    * Evento de closeOnEsc do componente
                    * @event closeOnEsc
                    * @vue
                    */
                    this.$emit("closeOnEsc");
                    document.removeEventListener("keyup", this.escEvent);
                }
            }
        },
    },
    template: (
        /*html*/
        `
        <base-dialog
            v-model="dialog"
            :action-button="{ label: 'Ver meus templates', loading: false, icon: 'mdi-chevron-right' }"
            title="Template salvo com sucesso!"
            @clickDialog="$emit('click')"
            @closeDialog="closeModal"
            max-width="410px"
            :use-sub-action="true"
            :persistent="edit_mode"
        >
            <v-row no-gutters>
                <v-col cols="12">
                    <span class="body-2 text-gray-900"> O seu template com nome <span class="bold">< {{ templateName }} ></span> foi salvo com sucesso.<br><br>O que deseja fazer agora?</span>
                </v-col>
            </v-row>
            <template #subAction>
                <v-row no-gutters class="mt-4" justify="center">
                    <v-col cols="auto">
                        <a class="text-primary-blue-500 text-decoration-none body-2" @click="clickSubAction">Criar um novo template</a>
                    </v-col>
                </v-row>
            </template>
        </base-dialog>
        `
    )
};