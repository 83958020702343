export default {
    template: (
        /*html*/
        `
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8125 6C11.8125 9.21087 9.20993 11.8125 6 11.8125C2.79007 11.8125 0.1875 9.21087 0.1875 6C0.1875 2.79101 2.79007 0.1875 6 0.1875C9.20993 0.1875 11.8125 2.79101 11.8125 6ZM6 7.17188C5.40457 7.17188 4.92188 7.65457 4.92188 8.25C4.92188 8.84543 5.40457 9.32812 6 9.32812C6.59543 9.32812 7.07812 8.84543 7.07812 8.25C7.07812 7.65457 6.59543 7.17188 6 7.17188ZM4.97641 3.29658L5.15027 6.48408C5.15841 6.63323 5.28173 6.75 5.4311 6.75H6.5689C6.71827 6.75 6.84159 6.63323 6.84973 6.48408L7.02359 3.29658C7.03238 3.13547 6.9041 3 6.74276 3H5.25722C5.09587 3 4.96762 3.13547 4.97641 3.29658Z" fill="currentColor"/>
        </svg>
        `
    )
};
