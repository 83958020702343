import styled from "../../plugins/vue-styled-components.es";

const DivLogo = styled("div")`
    position: absolute !important;
    z-index: 1;
`;

/**
 * Componente que adiciona as logos do +Connect e PGMais ao topo da p�gina
 *
 * @component
 * @category Moleculas
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 */
export default {
    components: {
        "div-logo": DivLogo
    },
    props: {
        /**
         * Indica qual deve ser a cor da logo PG Mais
         * @values dinamic, grey, white
         */
        logo_pg_color: {
            type: String,
            required: false,
            default: "dinamic"
        },
        /**
         * Indica se a logo do +connect deve ser branca ou azul
         * @values true, false
         */
        logo_connect_mais_branca: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        logo_grey_class() {
            switch (this.logo_pg_color) {
            case "dinamic":
                return "d-none d-md-block d-lg-none float-right";
            case "grey":
                return "float-right";
            case "white":
                return "d-none";
            }
        },
        logo_white_class() {
            switch (this.logo_pg_color) {
            case "dinamic":
                return "d-none d-lg-block float-right";
            case "grey":
                return "d-none";
            case "white":
                return "float-right";
            }
        }
    },
    template:
        /*html*/
        `
            <div-logo class="col">
                <v-row class="mt-3 justify-center justify-md-space-between">
                    <v-col cols="auto" class="ml-md-10 mt-4">
                        <v-img src="/images/logos/logo_pgmais_azul.svg" contain max-width="170" class="float-md-left"></v-img>
                    </v-col>
                    <v-col cols="auto" class="mr-10 d-none d-md-block">
                        <v-icon class="rounded-0" color="white" size="60" contain max-width="170" :class="logo_white_class">$rcsProductIcon</v-icon>
                        <v-icon class="rounded-0" color="primary-blue-500" size="60" contain max-width="170" :class="logo_grey_class">$rcsProductIcon</v-icon>
                    </v-col>
                </v-row>
            </div-logo>
        `
};
