export default {
    name: "app",
    template: (
        /*html*/
        `
            <v-app id="app" class="font-default">
                <div class="fill-height">
                    <router-view></router-view>
                </div>
            </v-app>
        `
    )
};
