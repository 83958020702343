import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente de icone com background
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName BaseBackgroundIcon
 * @component
 * @category Atomos
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Nome do icone a ser utilizado
         */
        iconName: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Título a ser exibido ao lado do ícone
         */
        title: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Subtitulo a ser exibido ao lado do ícone, em baixo do titulo
         */
        subTitle: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Cor a ser utilizada como background do icone
         */
        backgroundColor: {
            type: String,
            required: false,
            default: "primary-blue-500"
        },
        /**
         * Cor do icone
         */
        iconColor: {
            type: String,
            required: false,
            default: "gray-0"
        },
        /**
         * Define se o componente deve assumir o formato de circulo ou não
         */
        circle: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Define o tamanho do icone
         */
        size: {
            type: [Number, String],
            required: false,
            default: 50
        },
        /**
         * Texto a ser exibido no tooltip
         */
        tooltip: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        icon_size() {
            return this.size / 2;
        }
    },
    template: (
        /*html*/
        `
            <base-tooltip :text="tooltip">
                <v-row class="spacer flex-nowrap" no-gutters>
                    <v-col cols="auto" v-if="iconName">
                        <v-avatar :class="circle ? '' : 'rounded'" :tile="!circle" :size="size" :color="backgroundColor">
                            <v-icon :color="iconColor" :size="icon_size" :class="circle ? '' : 'rounded-0'">{{ iconName }}</v-icon>
                        </v-avatar>
                    </v-col>
                    <v-col v-if="title || subTitle" :class="!subTitle ? 'd-flex align-center' : ''">
                        <v-row no-gutters class="ml-3">
                            <v-col cols="12">
                                <span class="body-2 text-gray-900" v-html="title"></span>
                            </v-col>
                            <v-col v-if="subTitle" cols="12">
                                <span class="caption text-gray-500" v-html="subTitle"></span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </base-tooltip>
        `
    )
};