//import {MockupingService} from "./MockupingService";

export class BlackListServices {

    _base_url = '/BlackList';

    /**
     * Recebe um id num�rico de blacklist e deleta o registro
     * @param id {numeric}
     * @returns {Promise}.
     */
    delete(id) {
        return $.post(this._base_url + '/delete', {id});
    }

    /**
     *  Recebe um array de ids num�rico de blacklist e deleta os registros
     * @param ids {Numeric[]}
     * @returns {Promise}
     */
    multiDelete(ids) {
        return $.post(this._base_url + '/multiDelete', {ids});
    }

    /**
     * Busca a �ltima data de inclus�o na blacklist da empresa logada
     * @returns {Promise}
     */
    getLastDtInclusao() {
        return $.post(this._base_url + '/getLastDtInclusao');
    }
    /**
     * Relat�rio de blacklist
     * @returns {Promise}
     */
    reportSynthetic(params) {
        return $.post(this._base_url + '/getReport',params);
    }

    /**
     * Busca todos os n�meros bloqueados de uma empresa, incluindo os que est�o na lista de campo informado
     * @returns {Promise}
     */
    meusNumerosBloqueados(id_empresa) {
        // $.mockuping = MockupingService.mockuping([]);
        // return $.mockuping(this._base_url + '/meusNumerosBloqueados', {id_empresa});
        return $.post(this._base_url + '/meusNumerosBloqueados', {id_empresa});
    }

    /**
     * Busca todos os n�meros bloqueados que est�o na lista do procon
     * @returns {Promise}
     */
    numerosBloqueadosProcon() {
        // $.mockuping = MockupingService.mockuping([]);
        // return $.mockuping(this._base_url + '/numerosBloqueadosProcon');
        return $.post(this._base_url + '/numerosBloqueadosProcon');
    }

}