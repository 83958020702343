import BaseSectionForm from "../../../components/atomos/BaseSectionForm?v=2";
import BaseDataTable from "../../../components/organismos/BaseDataTable?v=2";
import { DataTableHeaderClass } from "../../../classes/DataTableHeaderClass";

import * as types from "../store/mutations-types";

/**
 * Componente de passo quatro do gerador de relatorios
 * 
 * @requires {@link BaseSectionForm}
 * @requires {@link BaseDataTable}
 * @requires {@link DataTableHeaderClass}
 * 
 * @displayName ReportStepFour
 * @category Page / Relatorios
 * @author Igor Oliveira <igor.oliveira@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseSectionForm,
        BaseDataTable
    },
    filters: {
        format_date: function (date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY HH:mm");
            } else {
                return "-";
            }
        }
    },
    data() {
        return {
            headers_table: [
                new DataTableHeaderClass({ text: "Campanha", value: "NM_CAMPANHA_CAES", filterable: true }),
                new DataTableHeaderClass({ text: "Inicio", value: "DT_INICIO", filterable: false, sortable: false }),
                new DataTableHeaderClass({ text: "Fim", value: "DT_FIM", filterable: false, sortable: false }),
                new DataTableHeaderClass({ text: "Envios", value: "QT_TOTAL_SMS_CAES", filterable: false, sortable: false })
            ],
            items_table: [],
            table_loading: false,
            campaigns: [],
            custom_slot: ["DT_INICIO", "DT_FIM"],
            only_single_api_messages: false,
        };
    },
    computed: {
        ...window.Vuex.mapState("reports", {
            clients: state => state.generator_step_three.clients,
            datas: state => state.generator_step_two.period_selected,
            generator_step_four: state => state.generator_step_four
        })
    },
    watch: {
        clients() {
            this.getListCampaign();
        },
        datas() {
            this.getListCampaign();
        },
        campaigns() {
            if (this.items_table.length === this.campaigns.length) {
                this.$store.commit(`reports/${types.SET_ALL_CAMPAIGNS}`, true);
            } else {
                this.$store.commit(`reports/${types.SET_ALL_CAMPAIGNS}`, false);
            }

            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_FOUR}`, {
                campaigns: this.campaigns,
                only_single_api_messages: this.only_single_api_messages
            });
        },
        only_single_api_messages() {
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_FOUR}`, {
                campaigns: this.campaigns,
                only_single_api_messages: this.only_single_api_messages
            });
        }
    },
    mounted() {
        this.getListCampaign();
    },
    methods: {
        ...window.Vuex.mapActions("reports", ["ActionDoGetListCampaigns"]),
        /**
         * Busca a lista de campanhas de acordo com os filtros informados
         * @vue
         */
        getListCampaign() {
            if (this.datas != undefined && this.datas.length > 0) {
                this.table_loading = true;
                let list_clients = [];

                for (let client of this.clients) {
                    list_clients.push(client.ID_EMPRESA_EPES);
                }

                this.ActionDoGetListCampaigns({
                    clientes: list_clients,
                    data_inicio: this.datas[0],
                    data_fim: this.datas.length == 2 ? this.datas[1] : this.datas[0],
                }).then(response => {

                    for (let campanhas of response) {
                        if (campanhas["QT_TOTAL_SMS_CAES"] > 0) {
                            this.items_table.push(campanhas);
                        }
                    }

                    if (this.generator_step_four.campaigns !== undefined && this.generator_step_four.campaigns.length > 0) {
                        this.campaigns = this.generator_step_four.campaigns;
                    }

                    this.table_loading = false;
                    this.only_single_api_messages = this.generator_step_four.only_single_api_messages;
                });
            } else {
                this.only_single_api_messages = this.generator_step_four.only_single_api_messages;
            }
        }
    },
    template: (
        /*html*/
        `
        <v-row>
            <v-col cols="12" md="6" lg="5">
                <v-row no-gutters>
                    <v-col cols="6" md="12">
                        <base-section-form title="Campanhas">
                            <v-row>
                                <v-col>
                                    <p class="font-size-12">Selecione as campanhas com dados dispon&iacute;veis para gerar o relat&oacute;rio</p>
                                    <p class="font-italic font-size-12">Clique na caixa de sele&ccedil;&atilde;o para selecionar as campanhas que deseja gerar o relat&oacute;rio, utilize a barra de pesquisa se necess&aacute;rio.</p>
                                </v-col>
                            </v-row>
                        </base-section-form>
                    </v-col>
                    <v-col cols="6" md="12">
                        <v-img src="/images/reports/girlChecklist.svg" width="195"></v-img>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6" lg="" v-bind:data-v-step="10">
                <v-row no-gutters>
                    <v-col>
                        <base-data-table
                            v-model="campaigns"
                            :headers="headers_table"
                            :list-items="items_table"
                            :custom-columns="false"
                            :loading="table_loading"
                            item-key="ID_CAMPANHA_CAES"
                            :custom-slots="custom_slot"
                            :page-length="4"
                            filterable
                            select-column
                            alternative-header
                            item-name="campanhas"
                            label-selected-item-name="selecionadas"
                            :step-tutorial-filter="11"
                        >
                            <template v-slot:DT_INICIO="props">
                                {{ props.item.DT_INICIO | format_date }}
                            </template>
                            <template v-slot:DT_FIM="props">
                                {{ props.item.DT_FIM | format_date }}
                            </template>
                        </base-data-table>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-checkbox v-model="only_single_api_messages" label="Apenas mensagens Avulsas/API" color="primary-blue-500"></v-checkbox>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        `
    )
};