import PreviewCard from "../atomos/PreviewCard";
import { CardRcsClass } from "../../classes/CardRcsClass";
import TemplateUtils from "../../utils/TemplateUtils";

/**
 * Componente para pre visualizacao de Carousel
 * 
 * @requires {@link PreviewCard}
 * @requires {@link CardRcsClass}
 * @requires {@link TemplateUtils}
 * 
 * @displayName PreviewCarousel
 * @category Organismos
 * @author David dos Santos Andrade <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PreviewCard
    },
    props: {
        /**
         * Lista de cards do template para carrossel
         */
        listCard: {
            type: Array,
            required: false,
            default: null,
            validator(data) {
                let is_valid = true;

                for (const element of data) {
                    if (!(element instanceof CardRcsClass)) {
                        is_valid = false;
                        break;
                    }
                }
                return is_valid;
            }
        },
        /**
         * Lista de opcoes que podem ser utilizadas em um select
         */
        selectOptions: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Campos variaveis do template
         */
        fieldsTemplate: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            card_selected: 0,
            fields_template: this.fieldsTemplate,
            template_utils: new TemplateUtils()
        };
    },
    watch: {
        fieldsTemplate() {
            this.fields_template = this.fieldsTemplate;
        },
        fields_template() {
            this.fieldChanged();
        },
        listCard() {
            this.card_selected = 0;
        }
    },
    methods: {
        /**
         * Funcao para avancar um card no carousel
         * @vue
         */
        nextCard() {
            if (this.card_selected < this.listCard.length - 1) {
                this.card_selected++;
            }
        },
        /**
         * Funcao para voltar um card no carousel
         * @vue
         */
        prevCard() {
            if (this.card_selected > 0) {
                this.card_selected--;
            }
        },
        fieldChanged(value) {
            this.$emit("changeField", value);
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters justify="center">
                <v-col cols="12">
                    <v-slide-group
                        v-model="card_selected"
                        show-arrows
                        mandatory
                        center-active
                        @click:prev="prevCard"
                        @click:next="nextCard"
                    >
                        <v-slide-item
                            v-for="(item, i) of listCard"
                            :key="i"
                            v-slot="{ active, toggle }"
                            class="gap-4"
                        >
                            <v-card elevation="0" :class="'bg-transparent mx-4 ' + (!active ? 'opacity-3' : '')" @click="toggle">
                                <preview-card
                                    :image="item.image"
                                    :title="template_utils.formatMessageList(item.title)"
                                    :description="template_utils.formatMessageList(item.description)"
                                    :suggestion-buttons="item.suggestionButtons"
                                    :suggestion-responses="item.suggestionResponses"
                                    :orientation="item.orientation"
                                    :alignment="item.alignment"                                    
                                    :image-height="item.image_height"
                                    :select-options="selectOptions"
                                    :fields-template="fields_template"
                                    :media="item.media"
                                    need-format-message
                                    :type-template="'carousel'"
                                    @changeField="fieldChanged"
                                />
                            </v-card>
                        </v-slide-item>
                        <template #prev>
                            <v-btn
                                size="48"
                                class="bg-gray-0 border-1 border-gray-50 elevation-4"
                                icon
                                :disabled="card_selected === 0"
                            >
                                <v-icon size="16">
                                    mdi-arrow-left
                                </v-icon>
                            </v-btn>
                        </template>
                        <template #next>
                            <v-btn
                                size="48"
                                class="bg-gray-0 border-1 border-gray-50 elevation-4"
                                icon
                                :disabled="card_selected === listCard.length - 1"
                            >
                                <v-icon size="16">
                                    mdi-arrow-right
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-slide-group>
                </v-col>
            </v-row>
        `
    )
};