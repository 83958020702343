import CardLargeChoice from "../../../components/moleculas/CardLargeChoice";
import DialogConfigTemplateMessage from "../components/dialogs/DialogConfigTemplateMessage?v=2";
import BaseCardLarge from "../../../components/atomos/BaseCardLarge";
import BaseButton from "../../../components/atomos/BaseButton?v=2";
import BaseSectionForm from "../../../components/atomos/BaseSectionForm?v=2";
import CarouselNews from "../../../components/organismos/CarouselNews?v=2";
import PreviewTemplate from "../../../components/organismos/PreviewTemplate";
import TemplateUtils from "../../../utils/TemplateUtils";
import DialogPreviewTemplate from "../../../components/organismos/DialogPreviewTemplate";
import { CardSliderClass } from "../../../classes/CardSliderClass?v=2";
import { CardRcsClass } from "../../../classes/CardRcsClass";
import * as types from "../store/mutations-types";
/**
 * Componente que representa o passo dois do cadastro de templates para RCS
 * 
 * @displayName CadastroTemplatePassoDois
 * @requires {@link CardLargeChoice}
 * @requires {@link DialogConfigTemplateMessage}
 * @requires {@link BaseCardLarge}
 * @requires {@link BaseButton}
 * @requires {@link BaseSectionForm}
 * @requires {@link CarouselNews}
 * @requires {@link PreviewTemplate}
 * @requires {@link DialogPreviewTemplate}
 * @requires {@link CardSliderClass}
 * @requires {@link CardRcsClass}
 * @component
 * @category Page / Template RCS
 * @author Igor Mateus Rodrigues de Oliveira <igor.oliveira@pgmais.com.br>
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 * @author David Santos <david.santos@pgmais.com.br>
 * @vue 
 * @vue/component
 */
export default {
    components: {
        CardLargeChoice,
        DialogConfigTemplateMessage,
        BaseCardLarge,
        BaseButton,
        BaseSectionForm,
        CarouselNews,
        PreviewTemplate,
        DialogPreviewTemplate
    },
    data() {
        return {
            content_dialog: null,
            show: false,
            part_two: false,
            buttons: [],
            template_utils: new TemplateUtils(),
            show_dialog_preview: false,
            ds_template: null
        };
    },
    computed: {
        ...window.Vuex.mapState("templatesrcs", {
            step_one_content: state => state.step_one_content,
            step_two_content: state => state.step_two_content
        }),
        selectedButton() {
            let button = null;

            for (let item of this.buttons) {
                if (item.selected) {
                    button = item;
                    break;
                }
            }

            return button;
        },
        isUnderMd() {
            return this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md;
        },
        cardVertical() {
            return this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.sm || (this.$vuetify.breakpoint.width >= this.$vuetify.breakpoint.thresholds.md && this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lg);
        },
        listCardSlider() {
            let cards = [];

            switch (this.modeloSelected) {
            case "text":
                cards = [
                    new CardSliderClass({
                        image: "/images/cadastro-template/txt_placeholder1.svg",
                        title: "Oi Fulano, essa mensagem foi feita pra voc&ecirc;!",
                        text: "Crie campos personalizados para serem substitu&iacute;dos por informa&ccedil;&otilde;es do seu arquivo de dados",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/txt_placeholder2.svg",
                        title: "A resposta que voc&ecirc; precisa!",
                        text: "Voc&ecirc; pode criar repostas prontas para seu destinat&aacute;rio, assim ele consegue te retornar de forma r&aacute;pida e f&aacute;cil.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/txt_placeholder3.svg",
                        title: "Liga pra mim... &#9836;&#9836;",
                        text: "Crie bot&otilde;es de a&ccedil;&otilde;es para seu destinat&aacute;rio, ele pode te ligar, saber seu endere&ccedil;o, entrar no seu site... E por a&iacute; vai.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/txt_placeholder4.svg",
                        title: "Tudo junto e misturado!",
                        text: "Quer ir al&eacute;m? Voc&ecirc; pode enviar uma mensagem mais completa com bot&otilde;es e respostas r&aacute;pidas ao mesmo tempo.",
                        vertical: this.cardVertical
                    })
                ];
                break;
            case "image":
                cards = [
                    new CardSliderClass({
                        image: "/images/cadastro-template/img_placeholder1.svg",
                        title: "Uma imagem para todos...",
                        text: "A mesma imagem ser&aacute; enviada para todos os destinat&aacute;rios das suas campanhas.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/img_placeholder2.svg",
                        title: "Cada um recebe a sua imagem...",
                        text: "Quer enviar uma imagem espec&iacute;fica para cada destinat&aacute;rio? A imagem personalizada &eacute; o que voc&ecirc; precisa! ;)",
                        vertical: this.cardVertical
                    })
                ];
                break;
            case "card":
                cards = [
                    new CardSliderClass({
                        image: "/images/cadastro-template/card_placeholder1.svg",
                        title: "Menos &eacute; mais n&eacute;?",
                        text: "Direto ao ponto, com a modalidade simples crie seu card apenas com imagem e texto.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/card_placeholder2.svg",
                        title: "A resposta que voc&ecirc; precisa!",
                        text: "Voc&ecirc; pode criar repostas prontas para seu destinat&aacute;rio, assim ele consegue te retornar de forma r&aacute;pida e f&aacute;cil.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/card_placeholder3.svg",
                        title: "Liga pra mim... &#9836;&#9836;",
                        text: "Crie bot&otilde;es de a&ccedil;&otilde;es para seu destinat&aacute;rio, ele pode te ligar, saber seu endere&ccedil;o, entrar no seu site... E por a&iacute; vai.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/card_placeholder4.svg",
                        title: "Tudo junto e misturado!",
                        text: "Quer ir al&eacute;m? Voc&ecirc; pode enviar uma mensagem mais completa com bot&otilde;es e respostas r&aacute;pidas ao mesmo tempo.",
                        vertical: this.cardVertical
                    })
                ];
                break;
            }
            return cards;
        },
        modeloSelected() {
            return this.step_one_content.modelo.value;
        }
    },
    watch: {
        modeloSelected() {
            this.buttons = this.template_utils.getMountButtonsConfig(this.step_one_content.modelo.value);
        }
    },
    mounted() {
        var caminho = window.location.pathname;
        var regex = /^\/Template\/criarTemplateRcs$/;
        if (!regex.test(caminho)) {
            this.stepTwoEdit();
        }
        this.buttons = this.template_utils.getMountButtonsConfig(this.step_one_content.modelo.value);
        if (this.step_two_content.button_type && (
            (this.modeloSelected === "text" && this.step_two_content.list_messages.length > 0) ||
            (this.modeloSelected === "image" && this.step_two_content.image) ||
            (this.modeloSelected === "card" && (this.step_two_content.card.image || (this.step_two_content.card.description || this.step_two_content.card.title)))
        )) {
            // Modelo imagem alterado para outro modelo
            const modelImageToAnotherModel = (this.modeloSelected !== "image" && (this.step_two_content.button_type.type === "imagem_unica" || this.step_two_content.button_type.type === "imagem_personalizada"));
            // Outro modelo alterado para o modelo imagem
            const anotherModelForImageModel = (this.modeloSelected === "image" && this.step_two_content.button_type.type !== "imagem_unica" && this.step_two_content.button_type.type !== "imagem_personalizada");

            // Coloca o primeiro bot�o dispon�vel do modelo no passo 2
            if (modelImageToAnotherModel || anotherModelForImageModel) {
                const step_two = { ...this.step_two_content, button_type: { ...this.buttons[0], selected: true } };
                this.$store.commit(`templatesrcs/${types.SET_STEP_TWO}`, step_two);
            }

            this.content_dialog = {
                button_type: this.step_two_content.button_type,
                image: this.step_two_content.image,
                list_messages: this.step_two_content.list_messages,
                list_fast_response: this.step_two_content.list_fast_response,
                list_suggestions: this.step_two_content.list_suggestions,
                list_chips_preview: this.step_two_content.list_chips_preview,
                card: this.step_two_content.card,
                list_card: this.step_two_content.list_card
            };

            for (let button of this.buttons) {
                if (button.type === this.step_two_content.button_type.type) {
                    button.selected = true;
                } else {
                    button.selected = false;
                }
            }

            this.buttons = _.cloneDeep(this.buttons);
            this.part_two = true;
        }
    },
    methods: {
        /**
         * Edita o step_two_content caso seja a tela de edi��o de template
         */
        stepTwoEdit() {
            let step_two = _.cloneDeep(this.step_two_content); //Faz uma c�pia do step_two_content

            if (typeof step_two.list_suggestions != "undefined") {
                if (step_two.list_suggestions.length > 0) {
                    let template_id = window.location.search.substring(1).match(/id=(\d+)/); //Verifica se na URL tem o parametro id para identificar se � edi��o

                    if (template_id[1] ?? undefined !== null) { //Verifica se o parametro id existe e se o valor � diferente de null
                        step_two.list_suggestions.map((item, index) => {
                            if (item.pill.type === "map") {
                                let edit_value = item.pill.subTitle; //Pega o valor do bot�o
                                let is_variable = edit_value.match(/\{.+\}/g); //Verifica se o valor do bot�o tem uma vari�vel

                                if (is_variable) {
                                    let edit_value_array = edit_value.split(","); //Quebra o valor do bot�o em um array
                                    step_two.list_suggestions[index].pill.subTitle = edit_value_array[0].slice(0, edit_value_array[0].lastIndexOf("}") - 1) + "}"; //Pega o valor do array e remove o ultimo caracter
                                    this.$store.commit("templatesrcs/" + types.SET_STEP_TWO, step_two); //Seta o novo valor no step_two_content
                                }
                            }
                        });
                    }
                }
            }
        },
        /**
         * Monta o array de botoes a ser exibido, de acordo com o modelo escolhido no passo anterior
         */
        mountButtons() {
            switch (this.modeloSelected) {
            case "text":
                this.buttons = [
                    {
                        icon: "$simplesIcon",
                        icon_small: "$simplesSmallIcon",
                        title: "Simples",
                        type: "simples",
                        description: "Crie uma mensagem simples podendo ter campos personalizados.",
                        height: "auto",
                        hover: false,
                        selected: false,
                        horizontal: false,
                        disable: false
                    },
                    {
                        icon: "$respostasRapidasIcon",
                        icon_small: "$respostasRapidasSmallIcon",
                        title: "Respostas r&aacute;pidas",
                        type: "respostas_rapidas",
                        description: "Crie uma mensagem com respostas r&aacute;pidas e campos personalizados.",
                        height: "auto",
                        hover: false,
                        selected: false,
                        horizontal: false,
                        disable: false
                    },
                    {
                        icon: "$botoesIcon",
                        icon_small: "$botoesSmallIcon",
                        title: "Bot&otilde;es",
                        type: "botoes",
                        description: "Crie uma mensagem com bot&otilde;es de a&ccedil;&atilde;o e campos personalizados.",
                        height: "auto",
                        hover: false,
                        selected: false,
                        horizontal: false,
                        disable: false
                    },
                    {
                        icon: "$completoIcon",
                        icon_small: "$completoSmallIcon",
                        title: "Completo",
                        type: "completo",
                        description: "Crie uma mensagem utilizando todos os recursos",
                        height: "auto",
                        hover: false,
                        selected: false,
                        horizontal: false,
                        disable: false
                    }
                ];
                break;
            case "image":
                this.buttons = [
                    {
                        icon: "$imageIcon",
                        icon_small: "$imageSmallIcon",
                        title: "&Uacute;nica",
                        type: "imagem_unica",
                        description: "Envie a mesma imagem para todos os contatos",
                        height: "auto",
                        hover: false,
                        selected: false,
                        horizontal: false,
                        disable: false
                    },
                    {
                        icon: "$imageVariableIcon",
                        icon_small: "$imageVariableSmallIcon",
                        title: "Personalizada",
                        type: "imagem_personalizada",
                        description: "Envie uma imagem diferente para cada contato",
                        height: "auto",
                        hover: false,
                        selected: false,
                        horizontal: false,
                        disable: false
                    }
                ];
                break;
            }
        },
        /**
         * Desmarca todos os botoes
         * @vue
         */
        unselectButtons() {
            if (this.content_dialog.list_messages.length === 0 && !this.content_dialog.image) {
                for (let button of this.buttons) {
                    button.selected = false;
                }

                this.buttons = _.cloneDeep(this.buttons);
            }
        },
        /**
         * Funcao que realiza o selecao da opcao e ativa a modal
         * @vue
         */
        selectOption(button_selected) {
            this.content_dialog = {
                button_type: _.cloneDeep(button_selected),
                list_messages: [],
                list_fast_response: [],
                list_suggestions: [],
                list_chips_preview: [],
                card: new CardRcsClass({}),
                list_card: []
            };

            this.show = true;
        },
        /**
         * Exibe o dialog de preview de template
         * @param {String} content_preview JSON no formato string
         */
        showDialogPreview(content_preview) {
            this.ds_template = content_preview;
            this.show_dialog_preview = true;
        },
        /**
         * Metodo para salvar as alteracoes realizadas no dialog de configuracao
         * @vue
         */
        onSave(value) {
            this.content_dialog = value;

            for (let button of this.buttons) {
                if (button.type === value.button_type.type) {
                    button.selected = true;
                } else {
                    button.selected = false;
                }
            }

            this.buttons = _.cloneDeep(this.buttons);

            this.$store.commit("templatesrcs/" + types.SET_STEP_TWO, this.content_dialog);

            this.show = false;
            this.part_two = true;
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters>
                <v-col cols="12" md="5" lg="4">
                    <v-row no-gutters>
                        <v-col>
                            <base-section-form
                                title="Configure o modelo escolhido"
                                :description="part_two ? 'Esta &eacute; a op&ccedil;&atilde;o que voc&ecirc; escolheu' : 'Escolha a op&ccedil;&atilde;o que deseja configurar, voc&ecirc; poder&aacute; alternar entre eles na tela de configura&ccedil;&otilde;es.'"
                            />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-4" :data-v-step="4">
                        <v-col cols="12">
                            <v-row
                                v-if="part_two"
                                no-gutters
                                justify="center"
                                justify-md="start"
                            >
                                <v-col cols="6">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <base-card-large
                                                v-model="selectedButton.selected"
                                                :icon="selectedButton.icon"
                                                :title="selectedButton.title"
                                                :description="selectedButton.description"
                                                :is-horizontal="selectedButton.horizontal"
                                                :disabled="selectedButton.disable"
                                                :size="40"
                                                :block-unselect="true"
                                            />
                                        </v-col>
                                        <v-col cols="12" class="pt-4">
                                            <base-button
                                                secondary
                                                block
                                                label="Alterar"
                                                icon="mdi-pencil"
                                                tooltip-text="Alterne entre os modos e edite o conte&uacute;do"
                                                @click="show = true"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <card-large-choice
                                v-else
                                v-model="buttons"
                                :columns="6"
                                :inline="false"
                                :horizontal="false"
                                :size="40"
                                @click="selectOption"
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    v-if="content_dialog && (content_dialog.list_messages.length || content_dialog.image || content_dialog.card.image)"
                    cols="12"
                    md="7"
                    lg="6"
                    offset-lg="1"
                    class="pl-md-10 mt-12 mt-md-0"
                    :data-v-step="5"
                >
                    <preview-template
                        :type="modeloSelected === 'text' ? 'message' : modeloSelected"
                        :message="content_dialog.list_messages"
                        :list-suggestions="content_dialog.list_chips_preview"
                        :image="content_dialog.image"
                        :card="content_dialog.card"
                        :list-card="content_dialog.list_card"
                        @showDialogPreview="showDialogPreview"
                    />
                </v-col>
                <v-col
                    v-else
                    cols="12"
                    md="5"
                    offset-md="2"
                    :data-v-step="5"
                    class="d-flex justify-center mt-15 mt-md-0"
                >
                    <carousel-news :list-card-slider="listCardSlider" />
                </v-col>
                <dialog-config-template-message
                    v-model="show"
                    :config-data="content_dialog"
                    :list-buttons="buttons"
                    @save="onSave"
                    @close="unselectButtons"
                />
                <dialog-preview-template
                    v-model="show_dialog_preview"
                    :ds-template="ds_template"
                />
            </v-row>
        `
    )
};