import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";
import PreviewImage from "../../../../components/atomos/PreviewImage";
import SectionCard from "../../../../components/moleculas/SectionCard";

/**
 * Componente de formulario para adicionar imagens no template
 * 
 * @requires {@link BaseTextField}
 * @requires {@link BaseButton}
 * @requires {@link PreviewImage}
 * @requires {@link SectionCard}
 * 
 * @component
 * @displayName FormAddImage
 * @category Page / Template RCS
 * @subcategory Forms
 * @vue
 * @author David Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseButton,
        PreviewImage,
        SectionCard
    },
    props: {
        /**
         * Link a ser preenchido no formulario
         */
        value: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            image: this.value !== "variable" ? this.value : null,
            image_rules: [
                v => !!v || window.Vue.htmlEntities("Voc&ecirc; precisa inserir o link da imagem"),
                v => {
                    let valid = false;

                    try {
                        let url = new URL(v);
                        valid = ["https:"].indexOf(url.protocol) > -1;

                        if (["png", "jpg", "gif"].indexOf(v.split(".").pop().toLowerCase()) === -1) {
                            valid = false;
                        }
                    } catch (e) {
                        valid = false;
                    }

                    if (valid) {
                        if (v.toLowerCase().indexOf("https://") === 0) {
                            let regex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);

                            if (v.match(regex)) {
                                return true;
                            } else {
                                return window.Vue.htmlEntities("URL inv&aacute;lida, verifique se a URL &eacute; <b>https</b> e se a extens&atilde;o da imagem &eacute; <b>JPG</b> ou <b>PNG</b>");
                            }
                        } else {
                            return window.Vue.htmlEntities("URL inv&aacute;lida, verifique se a URL &eacute; <b>https</b> e se a extens&atilde;o da imagem &eacute; <b>JPG</b> ou <b>PNG</b>");
                        }
                    } else {
                        return window.Vue.htmlEntities("URL inv&aacute;lida, verifique se a URL &eacute; <b>https</b> e se a extens&atilde;o da imagem &eacute; <b>JPG</b> ou <b>PNG</b>");
                    }
                }
            ],
            disable_button: this.value === "variable" || this.value === null ? true : false,
        };
    },
    computed: {
        isEmpty() {
            return !this.value;
        },
        showUnsetButtons() {
            return this.image && this.image != "";
        },
        showCancelButton() {
            return this.value && this.value != "" && this.image !== this.value && !this.disable_button;
        },
        showSubmitButton() {
            return this.isEmpty || this.image !== this.value;
        }
    },
    watch: {
        image() {
            this.disable_button = !this.$refs.formImageAdd.validate();
        },
        value() {
            if (this.value && this.value !== "variable") {
                this.image = this.value;
            }
        }
    },
    methods: {
        /**
         * Acao do formulario
         * @vue
         */
        formSubmit() {
            if (this.$refs.formImageAdd.validate()) {
                /**
                 * Evento formSubmit do botao
                 * @property {String} image URL da imagem
                 * @event input
                 * @vue 
                 */
                this.$emit("input", this.image);

                this.showNotify({
                    notify_title: "Imagem adicionada",
                    notify_message: "A imagem foi adicionada &agrave; sua mensagem.",
                    removed_pill: null,
                    position_removed: null,
                    notify_button_label: null,
                    restore_type: null
                });
            }
        },
        /**
         * Deleta a imagem
         * @vue
         */
        deleteImage() {
            this.clearContent();

            if (!this.isEmpty) {
                this.showNotify({
                    notify_title: "Imagem exclu&iacute;da",
                    notify_message: "A imagem foi exclu&iacute;da da mensagem",
                    removed_pill: this.value,
                    position_removed: null,
                    notify_button_label: "Restaurar imagem",
                    restore_type: "deleteImage"
                });

                /**
                 * Evento formSubmit do botao
                 * @property {String} image URL da imagem
                 * @event input
                 * @vue 
                 */
                this.$emit("input", null);
            }
        },
        /**
         * Cancela a edicao
         * @vue
         */
        cancelEdit() {
            this.image = this.value;
        },
        /**
         * Limpa os dados do formulario
         * @vue
         */
        clearContent() {
            this.$refs.formImageAdd.reset();
        },
        /**
         * Emite um evento informando que uma notificacao deve ser exibida
         * @param {Object} data 
         * @vue
         */
        showNotify(data) {
            this.$emit("showNotify", data);
        }
    },
    template: (
        /*html*/
        `
        <section-card title="Adicione uma URL <b>https</b> de imagem com extens&atilde;o <b>JPG</b> ou <b>PNG</b>" icon="mdi-link-plus" icon-size="40">
            <template #header>
                <v-form ref="formImageAdd">
                    <v-row no-gutters>
                        <v-col>
                            <base-text-field
                                v-model="image"
                                label="https://"
                                :required="true"
                                :rules="image_rules"
                                tooltip-text="Digite uma URL <b>Https</b> de imagem com extens&atilde;o <b>JPG</b> ou <b>PNG</b>"
                                @enterPressed="formSubmit"
                                @focus="$emit('focus')"
                                @blur="$emit('blur')"
                            ></base-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-2">
                        <v-col cols="12">
                            <preview-image v-if="image" :image="image"></preview-image>
                            <v-row v-else no-gutters align="center" class="fill-height">
                                <v-col cols="12">
                                    <v-row no-gutters justify="center">
                                        <v-col cols="auto">
                                            <v-img src="/images/cadastro-template/no_data_placeholder.svg" width="114" height="80"></v-img>
                                        </v-col>
                                        <v-col cols="12" class="text-center mt-3">
                                            <span class="body-2 text-gray-500">Voc&ecirc; ainda n&atilde;o adicionou uma imagem</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-if="showSubmitButton" no-gutters class="mt-4">
                        <v-col cols="12">
                            <base-button
                                primary
                                block
                                :label="isEmpty ? 'Adicionar imagem' : 'Alterar imagem'"
                                :icon="isEmpty ? 'mdi-plus-thick' : 'mdi-refresh'"
                                :disabled="disable_button"
                                @click="formSubmit"
                            ></base-button>  
                        </v-col>
                    </v-row>
                    <v-row v-if="showUnsetButtons" no-gutters class="mt-6" :justify="showCancelButton ? 'space-around' : 'center'">
                        <v-col cols="auto">
                            <base-button
                                tertiary
                                danger
                                :label="isEmpty ? 'Limpar' : 'Excluir imagem'"
                                icon="mdi-trash-can"
                                width="auto"
                                @click="deleteImage"
                            ></base-button>  
                        </v-col>
                        <v-col v-if="showCancelButton" cols="auto">
                            <base-button
                                tertiary
                                danger
                                :label="'Cancelar edi&ccedil;&atilde;o' | htmlEntities"
                                icon="mdi-close-thick"
                                width="auto"
                                @click="cancelEdit"
                            ></base-button>  
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </section-card>
        `
    )
};