import { CardSliderClass } from "../../../classes/CardSliderClass";
import CarouselNews from "../../../components/organismos/CarouselNews";

export default {
    components: {
        CarouselNews
    },
    data () {
        return {
            items: [
                {
                    src: "https://i.redd.it/mkekxub8jg221.png",
                    title: "Novidade 1",
                    description: "Estamos passando por mudan�as nos mensageiros! Com o lan�amento de novos produtos, surgiu o +Connect, um portal que vai incluir todos os seus produtos em um s� lugar! Alterne entre nossas solu��es e encontre o melhor para a sua campanha.",
                    active: true
                },
                {
                    src: "https://i.pinimg.com/170x/2e/a7/8f/2ea78f48b81425b4eb9eff2fdc3a9cdf.jpg",
                    title: "Novidade 2",
                    description: "Estamos passando por mudan�as nos mensageiros! Com o lan�amento de novos produtos, surgiu o +Connect, um portal que vai incluir todos os seus produtos em um s� lugar! Alterne entre nossas solu��es e encontre o melhor para a sua campanha.",
                    active: false
                },
                {
                    src: "https://i.pinimg.com/originals/5e/29/d4/5e29d4c601ec2a2ecc92168b02a98c87.jpg",
                    title: "Novidade 3",
                    description: "Estamos passando por mudan�as nos mensageiros! Com o lan�amento de novos produtos, surgiu o +Connect, um portal que vai incluir todos os seus produtos em um s� lugar! Alterne entre nossas solu��es e encontre o melhor para a sua campanha.",
                    active: false
                },
                {
                    src: "https://i.pinimg.com/originals/56/2c/38/562c384821e83cb78c24b2dd9c254294.jpg",
                    title: "Novidade 4",
                    description: "Estamos passando por mudan�as nos mensageiros! Com o lan�amento de novos produtos, surgiu o +Connect, um portal que vai incluir todos os seus produtos em um s� lugar! Alterne entre nossas solu��es e encontre o melhor para a sua campanha.",
                    active: false
                },
            ],
            list_card_slider: [
                new CardSliderClass({
                    image: "/images/cadastro-template/Txt_Onboarding1.svg",
                    title: "O c&eacute;u &eacute; o limite",
                    text: "Crie templates de texto com limite de at&eacute; 1000 caracteres."
                }),
                new CardSliderClass({
                    image: "/images/cadastro-template/Txt_Onboarding2.svg",
                    title: "Adicione campos personalizados",
                    text: "Campos personalizados s&atilde;o utilizados para apresentar dados reais de diferentes contatos."
                }),
                new CardSliderClass({
                    image: "/images/cadastro-template/Txt_Onboarding3.svg",
                    title: "Respostas r&aacute;pidas e bot&otilde;es de a&ccedil;&atilde;o",
                    text: "Ajude os clientes a tomar decis&otilde;es sugerindo respostas r&aacute;pidas ou bot&otilde;es de a&ccedil;&atilde;o."
                }),
                new CardSliderClass({
                    image: null,
                    title: "Respostas r&aacute;pidas e bot&otilde;es de a&ccedil;&atilde;o",
                    text: "Ajude os clientes a tomar decis&otilde;es sugerindo respostas r&aacute;pidas ou bot&otilde;es de a&ccedil;&atilde;o."
                }),
                new CardSliderClass({
                    image: "/images/cadastro-template/Txt_Onboarding2.svg",
                    title: null,
                    text: null
                }),
                new CardSliderClass({
                    image: "/images/cadastro-template/Txt_Onboarding3.svg",
                    title: null,
                    text: "Campos personalizados s&atilde;o utilizados para apresentar dados reais de diferentes contatos."
                }),
                new CardSliderClass({
                    image: "/images/cadastro-template/Txt_Onboarding1.svg",
                    title: "O c&eacute;u &eacute; o limite",
                    text: null
                })
            ]
        };
    },
    template: (
        /*html*/
        `
            <v-row >
                <v-col cols="12" lg="6">
                    <carousel-news :items="items" ></carousel-news>
                </v-col>
                <v-col cols="12" lg="6">
                    <carousel-news :list-card-slider="list_card_slider" ></carousel-news>
                </v-col>
            </v-row>
        `
    )
};