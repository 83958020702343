import AreaAction from "../../../components/atomos/AreaAction";
import BaseButton from "../../../components/atomos/BaseButton?v=2";

/**
 * Componente erro ao realizar login
 * 
 * @component
 * @category Page / Auth
 * @requires {@link area-action.html|AreaAction}
 * @requires {@link base-button.html|BaseButton}
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 */
export default {
    name: "error-login-box",
    components: {
        AreaAction,
        BaseButton
    },
    methods: {
        /**
         * A��o do bot�o, retorna para a p�gina de login
         * @vue
         */
        bakcLogin() {
            this.$router.push({name: "auth"});
        }
    },
    template: (
        /*html*/
        `
            <area-action title="">
                <v-row class="px-15">
                    <v-col cols="12">
                        <v-row justify="center" no-gutters>
                            <v-col cols="auto">
                                <v-icon left x-large color="danger">fa-exclamation-triangle</v-icon>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="auto">
                                <h3>Algo deu errado!</h3>
                            </v-col>
                        </v-row>
                        <v-row justify="center" no-gutters>
                            <v-col cols="auto" class="">
                                Houve um erro ao realizar o login, tente novamente.
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="auto">
                                <base-button
                                    primary
                                    label="Voltar para o login"
                                    icon="mdi-arrow-left"
                                    @click="bakcLogin"
                                ></base-button>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </area-action>
        `
    )
};