import AreaAction from "../../../components/atomos/AreaAction";
import ProductButton from "../../../components/atomos/ProductButton";
import SelectEmpresaForm from "./SelectEmpresaForm";

/**
 * Componente de formulario de login
 * 
 * @component
 * @category Page / Auth
 * @requires {@link area-action.html|AreaAction}
 * @requires {@link product-button.html|ProductButton}
 * @requires {@link select-empresa-form.html|SelectEmpresaForm}
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 */
export default {
    name: "select-empresa-box",
    components: {
        AreaAction,
        ProductButton,
        SelectEmpresaForm
    },
    props: {
        /**
         * Produto a ser exibido junto ao formul�rio
         */
        product_selected: {
            type: Object,
            required: true,
            validator: function (data) {
                if (typeof data.id !== "undefined"
                    && typeof data.product_icon !== "undefined"
                    && typeof data.title !== "undefined"
                    && typeof data.description !== "undefined"
                    && typeof data.color_button !== "undefined"
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        /**
         * Valor da barra de progresso
         */
        progress_val: {
            type: Number,
            required: false,
            default: 0
        },
        /**
         * Define se a barra de progresso deve ter o status indeterminado
         */
        indeterminate: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica se a barra de progresso deve ser exibida ou n�o
         */
        show_progress: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            select_loading: false,
        };
    },
    computed: {
        ...window.Vuex.mapState("auth", {
            user: state => state.user,
            list_empresas: state => state.list_empresas,
            type_product: state => state.type_product
        }),
        title_area() {
            return "";
        },
        select_empresa() {
            this.select_loading = "dark-primary";
            let empresas = this.list_empresas.filter(empresa => {
                return empresa.NM_PRODUTO_PAINEL_PPES.toLowerCase() === this.type_product.toLowerCase() && empresa.TP_PERFIL_EPES === "C";
            }).map(empresa => {
                return {
                    text: empresa.NM_EMPRESA_EPES,
                    value: empresa
                };
            });

            this.select_loading = false;
            return empresas;
        }
    },
    methods: {
        /**
         * Indica que o bot�o de retorno para a sele��o de produtos foi clicado
         * @vue
         */
        backSelectProduct() {
            /**
             * Evento de retorno para o select product
             * 
             * @event selectProduct
             * @vue
             */
            this.$emit("selectProduct");
        },
        /**
         * Indica que o bot�o de entrar foi clicado e emite o ID da empresa selecionada
         * @param {number} empresa_selected 
         * @vue
         */
        entrarAction(empresa_selected) {
            /**
             * Evento de a��o de entrar
             * 
             * @property {number} empresa_selected ID da empresa
             * 
             * @event entrar
             * @vue
             */
            this.$emit("entrar", empresa_selected);
        }
    },
    template: (
        /*html*/
        `
            <area-action :title="title_area">
                <v-row class="px-lg-15" justify="center">
                    <v-col cols="auto" md="6" class="py-10 py-md-0">
                        <product-button
                            :id="product_selected.id"
                            :product-icon="product_selected.product_icon"
                            :icon-color="product_selected.icon_color"
                            :title="product_selected.title" 
                            :description="product_selected.description" 
                            :disable-button="true" 
                            :color-button="product_selected.color_button" 
                            :center-button="true"
                        ></product-button>
                        <v-col cols="12" class="mt-10">
                            <div class="text-center">
                                <a class="dark-primary--text" @click="backSelectProduct()"> Voltar </a>
                            </div>
                        </v-col>
                    </v-col>
                    <v-col cols="12" md="6" class="px-10 py-10 py-md-0">
                        <select-empresa-form
                            :lista_empresas="select_empresa"
                            :select_loading="select_loading"
                            :disable_form="show_progress"
                            @click-button-voltar="backSelectProduct"
                            @click-button-entrar="entrarAction"
                        ></select-empresa-form>
                    </v-col>
                </v-row>
                <v-progress-linear
                    v-model="progress_val"
                    :active="show_progress"
                    :indeterminate="indeterminate"
                    absolute
                    bottom
                    color="dark-primary"
                    height="7"
                ></v-progress-linear>
            </area-action>
        `
    )
};