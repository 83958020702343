export class RadarService {

    _base_url = '/Radar';



    /**
     *  Recebe um array de ids num�rico de chats e os finaliza
     * @param ids {Numeric[]}
     * @returns {Promise}
     */
     endChat(ids) {
        return $.post(this._base_url + '/endChatTwoway', {ids});
    }
    /**
     *  Relat�rio sint�tico 
     * @param params {array}
     * @returns {Promise}
     */
     reportSynthetic(params) {
        return $.post(this._base_url + '/reportSynthetic', params);
    }
    /**
     *  Relat�rio sint�tico para rcs
     * @param params {array}
     * @returns {Promise}
     */
     reportSyntheticApp(params) {
        return $.post('RadarApp/reportSyntheticApp', params);
    }

}