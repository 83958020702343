import styled from "../../plugins/vue-styled-components.es";

const DivArrow = styled("div")`
    position: absolute;
    right: 15px;
    bottom: 11px;
`;

/**
 * Componente de botao de selecao do produto
 * 
 * @displayName ProductButton
 * @component
 * @vue
 * @category Atomos
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 * @vue/component
 */
export default {
    name: "product-button",
    components: {
        "div-arrow": DivArrow
    },
    props: {
        /**
         * Identificador do botao, eh emitido quando o botao for clicado
         */
        id: {
            type: String,
            required: true
        },
        /**
         * Icone do botao, podendo ser um caminho para uma imagem ou um nome icone fonte
         */
        productIcon: {
            type: String,
            required: true
        },
        /**
         * Titulo do botao
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Descricao que fica no botao 
         */
        description: {
            type: String,
            required: true
        },
        /** 
         * Se o botao vai ou n�o estar habilitado 
         */
        disableButton: {
            type: Boolean,
            default: false
        },
        /**
         * Cor do botao 
         */
        colorButton: {
            type: String,
            required: true
        },
        /**
         * Centralizar ou nao o botao
         */
        centerButton: {
            type: Boolean,
            default: false
        },
        /**
         * Define a cor do texto da descri��o
         */
        descriptionColor: {
            type: String,
            required: false,
            default: "black"
        },
        /**
         * Indica se a mensagem de bloqueio deve ser exibida ou n�o
         */
        showMessageBlock: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define a cor do icone
         */
        iconColor: {
            type: String,
            required: false,
            default: "black"
        }
    },
    data() {
        return {
            hover: false
        };
    },
    computed: {
        border_button: function() {
            return " border-" + this.colorButton;
        },
        text: function() {
            return this.colorButton + "--text";
        },
        colorDescription: function () {
            return this.descriptionColor + "--text";
        }
    },
    methods: {
        /**
         * Funcao vinculada ao bot�o
         * @param {*} instance 
         * @category Atomos
         * @vue
         */
        buttonFunction: function () {
            /**
             * Evento click do botao
             * @category Atomos
             * @event click
             * @vue 
            */
            if (!this.disableButton) {
                this.$emit("click", this.id);
            }
        }
    },
    template: (
        /*html*/
        `
        <v-card class="rounded-3 bg-gray-0" height="300" width="310">
            <v-hover v-model="hover" :disabled="disableButton" :class="!disableButton ? 'cursor-pointer-hover' : ''">
                <v-row no-gutters>
                    <v-col :color="colorButton" cols="12" @click="buttonFunction">
                        <v-row no-gutters :class="'py-10 rounded-top-3 bg-' + colorButton" justify="center" :justify-xl="centerButton ? 'center' : 'start'">
                            <v-col cols="auto">
                                <v-icon size="90" :color="iconColor">{{ productIcon }}</v-icon>
                            </v-col>
                        </v-row>
                        <v-row v-if="!showMessageBlock" no-gutters>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col class="pa-5">
                                        <span :class="'body-2 ' + colorDescription" v-html="description"></span>
                                    </v-col>
                                </v-row>
                                <v-row v-if="!disableButton" no-gutters justify="end">
                                    <v-col cols="auto" class="pr-5 pb-5">
                                        <v-btn icon :class="'bg-' + (hover ? colorButton : 'transparent')" absolute right bottom>
                                            <v-icon size="30" :color="hover ? 'gray-0' : 'primary-blue-500'">mdi-arrow-right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-else no-gutters>
                            <v-col cols="12" class="pa-5">
                                <span class="body-2">Ops, parece que voc&ecirc; ainda n&atilde;o tem esse produto. Entre em contato com o atendimento para saber mais e contratar.</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-hover>
        </v-card>
        `
    )
};
