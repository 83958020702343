import styled from "../../plugins/vue-styled-components.es";

const StyledDiv = styled("div")` 
    /*border: 1px solid #dee2e6 !important;*/
    /*border-color: #343a40 !important;*/
    min-height: 450px !important;
`;
/**
 * Componente de area de acoes
 * 
 * @displayName AreaAction
 * @component
 * @category Atomos
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 * @vue
 */
export default {
    name: "area-action",
    props: {
        /**
         * Titulo a ser exibido
         */
        title: {
            type: String,
            required: true
        }
    },
    components: {
        "styled-div": StyledDiv
    },
    template: (
        /*html*/
        `
           <div class="container"> 
                <styled-div class="row ">  
                    <v-col cols="12" class="d-flex align-center mt-15 ">
                        <content class="d-flex align-center">
                            <h6 v-html="title"></h6>
                        </content>
                    </v-col> 
                    <v-col cols="12">
                        <slot></slot>
                    </v-col> 
                </styled-div> 
           </div>
        `
    )
};