import CellPhonePreview from "../../../components/atomos/CellPhonePreview";

export default {
    components: { CellPhonePreview },
    data() {
        return {
            show_header: true,
            show_footer: true
        };
    },
    template:
    /*html*/
    `
    <div class="container" id="vue-page">
        <v-row>
            <v-col cols="6">
                <v-checkbox label="Exibir header" v-model="show_header" ></v-checkbox>
            </v-col>
            <v-col cols="6">
                <v-checkbox label="Exibir footer" v-model="show_footer" ></v-checkbox>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-row>
                    <v-col>
                        <cell-phone-preview 
                        :show-header="show_header"
                        :show-footer="show_footer">
                        </cell-phone-preview>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
        `
};
