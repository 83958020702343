import BaseTextField from "../../../../components/atomos/BaseTextField";
import BaseSelect from "../../../../components/atomos/BaseSelect";
import BaseDialog from "../../../../components/moleculas/BaseDialog";
import BaseBackgroundIcon from "../../../../components/atomos/BaseBackgroundIcon";
import BaseTooltip from "../../../../components/atomos/BaseTooltip";
import BaseDatePicker from "../../../../components/atomos/BaseDatePicker";
import ButtonAction from "../../../../components/moleculas/ButtonAction";
import TimeRules from "../../../../views/campaign/components/TimeRules";

/**
 * Componente para configurar o envio de campanhas
 *
 * @requires {@link BaseTextField}
 * @requires {@link BaseSelect}
 * @requires {@link BaseDialog}
 * @requires {@link BaseBackgroundIcon}
 * @requires {@link BaseTooltip}
 * @requires {@link BaseDatePicker}
 * @requires {@link ButtonAction}
 * @requires {@link TimesRules}
 *
 * @displayName DialogStaggerCampaign
 * @category Page / Campaign
 * @subcategory dialogs
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseSelect,
        BaseDialog,
        BaseBackgroundIcon,
        BaseTooltip,
        BaseDatePicker,
        ButtonAction,
        TimeRules
    },
    props: {
        /**
         * Controla a exibicao do modal atraves de v-model
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Regras de horario para criacao de campanhas
         */
        timeRules: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        },
        /**
         * Numero total de telefones validos para envio
         */
        totalRecords: {
            type: Number,
            required: true
        },
        /**
         * Nome da campanha
         */
        campaignName: {
            type: String,
            required: true
        },
        /**
         * Nome da carteira
         */
        clientName: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            show: this.value,
            time_rules_active: {},
            action_button: {
                primary: true,
                label: "Criar campanha",
                loading: false,
                icon: "mdi-check-bold",
                disabled: false,
                id: "criarCampanhaLote"
            },
            lot_types: [
                { value: 1, text: "Agora" },
                { value: 2, text: "Escolher uma data" },
            ],
            lot_start_types: [
                { value: 1, text: window.Vue.htmlEntities("Iniciar &agrave;s") },
                { value: 2, text: "Iniciar entre" },
            ],
            quantity_lots: 1,
            lots: [],
            max_quantity: 0,
            remaining_quantity: false,
            surplus_quantity: false,
            informed_quantity: 0,
            quantity_rules: [
                v => !!v || "Informe uma quantidade",
                v => !v || parseInt(v) > 0 || "Precisa ser maior que 0"
            ],
            today: dayjs().format("YYYY-MM-DD"),
            max_date: dayjs().add(3, "month").format("YYYY-MM-DD"),
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            if (this.show && !this.lots.length) {
                this.setAttributesFirstExecution();
            }
            this.$emit("input", this.show);
        },
        informed_quantity() {
            this.action_button.disabled = this.informed_quantity != this.totalRecords ? true : false;
        },
        timeRules() {
            if (this.timeRules?.ST_ATIVO_BADES == "S") {
                this.time_rules_active = this.timeRules;
                if (this.timeRules?.HORA_FINAL_BADES == "00:00:00") {
                    this.time_rules_active.HORA_FINAL_BADES = "23:59:59";
                }
            } else {
                this.time_rules_active = {};
            }
        }
    },
    methods: {
        /**
         * Emite os dados configurados
         * @vue
         */
        save() {
            // Remove os dados que sao utilizados apenas no formulario
            const lotsConfig = _.cloneDeep(this.lots);
            lotsConfig.forEach(function(lot) {
                delete lot.type;
                delete lot.startType;
                delete lot.showPickerTimeStart;
                delete lot.showPickerTimeEnd;
                delete lot.uniqueId;
            });

            this.$emit("saveConfig", lotsConfig);
            this.lots = [];
        },
        /**
         * Redistribui a quantidade de registros entre a quantidade de lotes
         * @vue
         */
        redistribute() {
            let quantity_row = Math.floor(this.totalRecords / this.quantity_lots);
            let extra_quantity = this.totalRecords % this.quantity_lots;
            let loops = (this.lots.length < this.quantity_lots) ? this.quantity_lots : this.lots.length;

            for (let i = 0 ; i < loops ; i++) {
                if (i < this.quantity_lots) {
                    let quantity = quantity_row + (extra_quantity > 0 ? 1 : 0);
                    extra_quantity--;

                    if (this.lots[i] !== undefined) {
                        this.lots[i].quantity = quantity;
                    } else {
                        this.addRowLot(quantity);
                    }
                } else {
                    this.lots.pop();
                }
            }
            this.checkInformedQuantity();
        },
        /**
         * Acao de remover lote
         * @param {integer} index
         * @vue
         */
        removeLot(index) {
            this.lots.splice(index, 1);
            this.quantity_lots--;
            this.redistribute();
        },
        /**
         * Verifica se a quantidade de lotes informada e valida
         * @param {integer} quantity_lots
         * @vue
         */
        changeQuantityLots(quantity_lots) {
            let quantity = quantity_lots <= this.max_quantity ? quantity_lots : this.max_quantity;
            if (quantity > 0) {
                this.quantity_lots = quantity;
                if (quantity !== this.lots.length) {
                    this.redistribute();
                }
            } else {
                this.quantity_lots = this.lots.length;
            }
        },
        /**
         * Adiciona uma nova linha para o lote
         * @param {integer} quantity
         * @vue
         */
        addRowLot(quantity) {
            this.lots.push({
                quantity: quantity,
                type: 1,
                startType: 1,
                showPickerTimeStart: false,
                showPickerTimeEnd: false,
                uniqueId: this.generateUUID()
            });
        },
        /**
         * Verifica se a quantidade de registros informada e valida
         * @param {object} lot_change
         * @vue
         */
        changeQuantity(lot_change) {
            if ((parseInt(lot_change.quantity) || 0) < 1) {
                lot_change.quantity = 1;
            }

            this.checkInformedQuantity();

            if (this.informed_quantity !== this.totalRecords) {
                this.remaining_quantity = this.totalRecords > this.informed_quantity;
                this.surplus_quantity = this.totalRecords < this.informed_quantity;
            }
        },
        /**
         * Soma todos os registros informados e salva em informed_quantity
         * @vue
         */
        checkInformedQuantity() {
            let sum_quantity = this.lots.reduce(function(accumulator, lot) {
                return accumulator + (parseInt(lot.quantity) || 0);
            }, 0);
            this.informed_quantity = sum_quantity;
        },
        /**
         * Ao alterar o tipo define a data e hora inicial se forem indefinidos
         * @param {object} lot
         * @vue
         */
        changeType(lot) {
            if (lot.type == 2) {
                if (lot.startDateScheduling == undefined) {
                    lot.startDateScheduling = this.today;
                }

                if (lot.startTime == undefined) {
                    if (Object.values(this.time_rules_active).length) {
                        let min_time = dayjs(lot.startDateScheduling + this.time_rules_active.HORA_INICIAL_BADES);
                        let now_time = dayjs();

                        if (now_time.isBefore(min_time)) {
                            lot.startTime = this.time_rules_active.HORA_INICIAL_BADES.substr(0, 5);
                            return;
                        }
                    }
                    lot.startTime = dayjs().add(1, "minute").format("HH:mm");
                }
            }
        },
        /**
         * Verifica se a hora informada e valida e se typeStartEnd == fim verifica se e maior que a hora inicial
         * @param {object} lot
         * @param {string} typeStartEnd
         * @returns {string}
         * @vue
         */
        checkTime(lot, typeStartEnd = "startTime") {
            let start_time = dayjs(lot.startDateScheduling + lot.startTime);
            let between_time = lot?.startType == 2; // Se for entre horas
            let end_time, value;

            if (between_time) {
                end_time = dayjs(lot.startDateScheduling + lot.endTime);
            }

            // Se tiver regras de validacao de horario para agendamento
            if (Object.values(this.time_rules_active).length) {
                let min_time = dayjs(lot.startDateScheduling + this.time_rules_active.HORA_INICIAL_BADES);
                let max_time = dayjs(lot.startDateScheduling + this.time_rules_active.HORA_FINAL_BADES);

                if (typeStartEnd === "startTime") {
                    // Atualiza se o informado for menor que o horario minimo
                    if (start_time.isBefore(min_time)) {
                        value = lot.startTime = this.time_rules_active.HORA_INICIAL_BADES.substr(0, 5);
                    }

                    // Se for entre horas
                    if (between_time) {
                        // Verifica se hora inicial e maior que hora final
                        if (start_time.isAfter(end_time)) {
                            value = lot.endTime = this.time_rules_active.HORA_INICIAL_BADES.substr(0, 5);
                        }
                    }

                } else if (typeStartEnd === "endTime") {
                    // Atualiza se o informado for maior que o horario maximo e joga para o dia seguinte
                    if (end_time.isAfter(max_time)) {
                        lot.startDateScheduling = dayjs().add(1, "day").format("YYYY-MM-DD");
                        value = lot.endTime = this.time_rules_active.HORA_FINAL_BADES.substr(0, 5);
                    } else if (end_time.isBefore(start_time)) { // Verifica se a hora final e menor que a hora inicial
                        value = lot.endTime = this.time_rules_active.HORA_FINAL_BADES.substr(0, 5);
                    }
                }
            } else { // Se nao houver regras de validacao
                if (typeStartEnd === "startTime") { // For o primeiro campo de hora
                    if (start_time.isBefore(dayjs())) { // Verifica se a hora informada e antes de agora
                        value = lot.startTime = dayjs().add(1, "minute").format("HH:mm");
                    }

                    if (between_time && start_time.isAfter(end_time)) { // Se for entre horas verifica se hora informada e depois da hora final
                        lot.endTime = "23:59";
                    }
                } else if (typeStartEnd === "endTime") { // Se for o ultimo campo de hora
                    if (end_time.isBefore(start_time)) { // Verifica se a hora informada e antes de hora inicial
                        value = lot.endTime = "23:59";
                    }
                }
            }

            this.lots = _.cloneDeep(this.lots); // Necessario para atualizar a exibicao do novo valor no base-text-field
            if (value) {
                return value;
            }

            return lot[typeStartEnd];
        },
        /**
         * Se o startType informado for 2 (entre as horas) definira a data final se for indefinido
         * @param {object} lot
         * @vue
         */
        checkStartType(lot) {
            if (lot.startType == 2) {
                if (lot.endTime == undefined) {
                    // Se tiver regras de validacao de horario para agendamento
                    if (Object.values(this.time_rules_active).length) {
                        let min_time = dayjs(lot.startDateScheduling + this.time_rules_active.HORA_FINAL_BADES);
                        let now_time = dayjs();

                        // Verifica se a hora atual ja passou do horario maximo
                        if (now_time.isAfter(min_time)) {
                            lot.startDateScheduling = dayjs().add(1, "day").format("YYYY-MM-DD");
                            lot.endTime = this.time_rules_active.HORA_FINAL_BADES.substr(0, 5);
                        }
                    } else {
                        lot.endTime = "23:59";
                    }
                }
            }
        },
        /**
         * Se for a primeira vez abrindo a modal considera o total de registros validos informados
         * @vue
         */
        setAttributesFirstExecution() {
            this.lots.push({
                quantity: this.totalRecords,
                type: 1,
                startType: 1,
                showPickerTimeStart: false,
                showPickerTimeEnd: false,
                uniqueId: this.generateUUID()
            });
            this.max_quantity = this.totalRecords < 15 ? this.totalRecords : 15;
            this.informed_quantity = this.totalRecords;
            this.quantity_lots = 1;
        },
        generateUUID() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
                const r = Math.random() * 16 | 0,
                    v = c === "x" ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        saveTimePicker(prefix, uniqueId, lot) {
            const menusStart = this.$refs.menuStart;
            const menusEnd = this.$refs.menuEnd;
            const combinedId = prefix + "_" + uniqueId;

            // Buscando nos menus de in�cio
            for (let i = 0; i < menusStart.length; i++) {
                if (menusStart[i].$el.getAttribute("data-unique-id") === combinedId) {
                    menusStart[i].save(this.checkTime(lot, "startTime"));
                    return;
                }
            }

            // Buscando nos menus de fim
            for (let i = 0; i < menusEnd.length; i++) {
                if (menusEnd[i].$el.getAttribute("data-unique-id") === combinedId) {
                    menusEnd[i].save(this.checkTime(lot, "endTime"));
                    return;
                }
            }
        }
    },
    template: (
        /*html*/
        `
            <base-dialog
                v-model="show"
                title="Criar lotes"
                cancel
                :action-button="action_button"
                :disable-action-button="action_button.disabled"
                use-icon-close
                max-width="75%"
                use-sub-action
                cols-button-action="auto"
                cols-button-cancel="auto"
                button-action-order="1"
                @clickDialog="save"
            >               
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-row no-gutters class="mt-4 gap-6 align-items-center">
                            <v-col cols="auto">
                                <base-background-icon title="RCS" icon-name="icon-rcs" background-color="rcs" size="30" />
                            </v-col>
                            <v-col cols="auto">
                                <span class="body-2">Carteira: <strong>{{ clientName }}</strong></span>
                            </v-col>
                            <v-col cols="auto">
                                <span class="body-2">Campanha: <strong>{{ campaignName }}</strong></span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="gap-4 mt-4">
                            <v-col cols="4">
                                <base-tooltip text="Este &eacute; o total de registros importados, ser&aacute; feita a higieniza&ccedil;&atilde;o antes do envio">
                                    <v-row class="d-flex min-h-130 p-2 border-1 rounded border-success-500 align-items-center" no-gutters>
                                        <v-col cols="2" class="pl-4">
                                            <v-icon size="20" color="success-500">
                                                mdi-check-bold
                                            </v-icon>
                                        </v-col>
                                        <v-col cols="10" class="pl-2">
                                            <span class="body-2">Telefones cadastrados para envio: <strong>{{ totalRecords }}</strong></span>
                                        </v-col>
                                    </v-row>
                                </base-tooltip>
                            </v-col>
                            <v-col cols="8">
                                <v-row class="d-flex min-h-130 p-2 border-1 rounded border-gray-300 align-items-center" no-gutters>
                                    <v-col cols="12">
                                        <time-rules
                                            :week-start="time_rules_active?.HORA_INICIAL_BADES"
                                            :week-end="time_rules_active?.HORA_FINAL_BADES"
                                            :saturday-start="time_rules_active?.HORA_INICIAL_BADES"
                                            :saturday-end="time_rules_active?.HORA_FINAL_BADES"
                                            :show-campaign="false"
                                            background-color="white"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12">
                                <span class="body-2">Agende seus disparos divindo em lotes.</span>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12">
                                <v-row class="d-flex border-1 rounded" no-gutters>
                                    <v-col cols="12">
                                        <v-row no-gutters class="gx-5">
                                            <v-col cols="2" md="2" xs="2" lg="1" class="pl-3">
                                                <base-tooltip text="Escolha a quantidade de lotes que deseja agendar">        
                                                    <base-text-field
                                                        v-model="quantity_lots"
                                                        label="Quantidade lotes"
                                                        @blur="changeQuantityLots"
                                                        id="quantidadeLotes"
                                                    />
                                                </base-tooltip>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="bg-background">
                                            <base-tooltip text="N&uacute;mero do lote">
                                                <v-col cols="1" align="center">
                                                    <span class="body-2"><strong>Lote</strong></span>
                                                </v-col>
                                            </base-tooltip>
                                            <base-tooltip text="Quantidade de telefones v&aacute;lidos">
                                                <v-col cols="1">
                                                    <span class="body-2"><strong>Registros</strong></span>
                                                </v-col>
                                            </base-tooltip>
                                            <base-tooltip text="Agendamento">
                                                <v-col cols="2">
                                                    <span class="body-2"><strong>A partir de</strong></span>
                                                </v-col>
                                            </base-tooltip>
                                            <v-col cols="1" offset="7" align="center">
                                                <span class="body-2"><strong>Remover</strong></span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="(lot, index) in lots" class="align-items-center bg-background" no-gutters>
                                            <v-col cols="1" align="center">
                                                <span>{{ index + 1 }}&ordm;</span>
                                            </v-col> 
                                            <v-col cols="1">
                                                <base-text-field
                                                    v-model="lot.quantity"
                                                    label=""
                                                    type="number"
                                                    :rules="quantity_rules"
                                                    @blur="changeQuantity(lot)"
                                                />
                                            </v-col>
                                            <v-col cols="2">
                                                <base-select
                                                    v-model="lot.type"
                                                    :items="lot_types"
                                                    label=""
                                                    @change="changeType(lot)"
                                                />
                                            </v-col>
                                            
                                            <template v-if="lot.type === 2"><!-- 2: Escolher uma data by lot_types -->
                                                <v-col cols="2"> 
                                                    <base-date-picker
                                                        v-model="lot.startDateScheduling"
                                                        label="00/00/0000"
                                                        :minDate="today"
                                                        :maxDate="max_date"
                                                        required
                                                        close-on-click
                                                        use-icon-validation
                                                        @input="checkTime(lot, 'startTime')"
                                                    />
                                                </v-col>
                                                 <v-col cols="2" v-if="lot.type === 2"> <!-- 2: Escolher uma data by lot_types -->
                                                    <base-select
                                                        v-model="lot.startType"
                                                        :items="lot_start_types"
                                                        label=""
                                                        @change="checkStartType(lot)"
                                                    />
                                                </v-col>
                                            </template>
                                            <template v-else>
                                                <v-col cols="1" offset="7" align="center">                                                    
                                                    <button-action :disabled="lots.length === 1" type="delete" @click="removeLot(index)"></button-action>
                                                </v-col>
                                            </template>                                            
                                            <template v-if="lot.type === 2 && lot.startType === 1"> <!-- 1: Iniciar �s by lot_start_types -->
                                                <v-col cols="3">                                                    
                                                    <v-menu
                                                        ref="menuStart"
                                                        v-model="lot.showPickerTimeStart"
                                                        :data-unique-id="'start_' + lot.uniqueId"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        :return-value.sync="lot.startTime"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290px"
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <base-text-field
                                                                v-model="lot.startTime"
                                                                :value="lot.startTime"
                                                                label="00:00"
                                                                readonly
                                                                :bindPersonal="attrs"
                                                                :onPersonal="on"
                                                            />
                                                        </template>
                                                        <v-time-picker
                                                            v-if="lot.showPickerTimeStart"
                                                            v-model="lot.startTime"
                                                            format="24hr"
                                                            header-color="primary"
                                                            scrollable
                                                            full-width
                                                            @click:minute="saveTimePicker('start', lot.uniqueId, lot)"
                                                        />
                                                    </v-menu>
                                                </v-col>
                                            </template>
                                            <template v-else-if="lot.startType === 2"> <!-- 2: Enviar entre by lot_start_types -->
                                                <v-col cols="1">
                                                    <v-menu
                                                        ref="menuStart"
                                                        v-model="lot.showPickerTimeStart"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        :return-value.sync="lot.startTime"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290px"
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <base-text-field
                                                                v-model="lot.startTime"
                                                                :value="lot.startTime"
                                                                label="00:00"
                                                                readonly
                                                                :bindPersonal="attrs"
                                                                :onPersonal="on"
                                                            />
                                                        </template>
                                                        <v-time-picker
                                                            v-if="lot.showPickerTimeStart"
                                                            v-model="lot.startTime"
                                                            :data-unique-id="'start_' + lot.uniqueId"
                                                            format="24hr"
                                                            header-color="primary"
                                                            scrollable
                                                            full-width
                                                            @click:minute="saveTimePicker('start', lot.uniqueId, lot)"
                                                        />
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="1" align="center">
                                                    <span class="body-2">e</span>
                                                </v-col>
                                                <v-col cols="1">
                                                    <v-menu
                                                        ref="menuEnd"
                                                        v-model="lot.showPickerTimeEnd"
                                                        :data-unique-id="'end_' + lot.uniqueId"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        :return-value.sync="lot.endTime"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290px"
                                                        min-width="290px"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <base-text-field
                                                                v-model="lot.endTime"
                                                                label="00:00"
                                                                readonly
                                                                :bindPersonal="attrs"
                                                                :onPersonal="on"
                                                            />
                                                        </template>
                                                        <v-time-picker
                                                            v-if="lot.showPickerTimeEnd"
                                                            v-model="lot.endTime"
                                                            format="24hr"
                                                            header-color="primary"
                                                            scrollable
                                                            full-width
                                                            @click:minute="saveTimePicker('end', lot.uniqueId, lot)"
                                                        />
                                                    </v-menu>
                                                </v-col>
                                            </template>
                                            
                                            <v-col cols="1" v-if="lot.type === 2"> <!-- 2: Escolher uma data by lot_types -->
                                                <button-action :disabled="lots.length === 1" type="delete" @click="removeLot(index)"></button-action>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="totalRecords != informed_quantity" no-gutters class="mt-2 red">
                                            <base-tooltip text="Resolva a pend&ecirc;ncia para continuar. Voc&ecirc; pode escolher redistribuir tudo na op&ccedil;&atilde;o abaixo ou distribuir manualmente.">
                                                <v-col cols="12" class="white--text pt-2 pb-2 pl-2 body-2">
                                                    <span>Registros informados: <strong>{{ informed_quantity }}</strong> de <strong>{{ totalRecords }}</strong> - </span>                                                    
                                                    <span v-if="remaining_quantity">Restam <strong>{{ totalRecords - informed_quantity }}</strong> registros.</span>
                                                    <span v-if="surplus_quantity"><strong>{{ informed_quantity - totalRecords }}</strong> registros excedentes.</span>                                                    
                                                </v-col>
                                            </base-tooltip>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <template #subAction="">
                    <base-tooltip text="Redistribui os registros igualmente entre os lotes">
                        <v-row no-gutters>
                            <v-col cols="12" class="text-center">
                                <a class="text-primary-blue-500 text-decoration-none body-2" @click="redistribute">Redistribuir tudo</a>
                            </v-col>
                        </v-row>
                    </base-tooltip>
                </template>
            </base-dialog>
        `
    )
};