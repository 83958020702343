import BaseSectionForm from "../../../components/atomos/BaseSectionForm?v=2";
import BaseDataTable from "../../../components/organismos/BaseDataTable?v=2";
import { DataTableHeaderClass } from "../../../classes/DataTableHeaderClass";

import * as types from "../store/mutations-types";

/**
 * Componente de passo tres do gerador de relatorios
 * 
 * @requires {@link BaseSectionForm}
 * @requires {@link BaseDataTable}
 * @requires {@link DataTableHeaderClass}
 * 
 * @displayName ReportStepThree
 * @category Page / Relatorios
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseSectionForm,
        BaseDataTable
    },
    data() {
        return {
            headers_table: [
                new DataTableHeaderClass({ text: "Clientes", value: "NM_EMPRESA_EPES", filterable: true })
            ],
            items_table: [],
            table_loading: false,
            clients_selecteds: []
        };
    },
    computed: {
        ...window.Vuex.mapState("reports", {
            step_two_content: state => state.generator_step_two,
            generator_step_three: state => state.generator_step_three,
            list_clients_total: state => state.list_clients_total,
            filter_clients_start_date: state => state.filter_clients_start_date,
            filter_clients_end_date: state => state.filter_clients_end_date
        })
    },
    watch: {
        step_two_content() {
            this.checkReportPeriod();
        },
        clients_selecteds() {
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_THREE}`, {
                clients: this.clients_selecteds
            });
        }
    },
    mounted() {
        this.checkReportPeriod();
    },
    methods: {
        ...window.Vuex.mapActions("reports", ["ActionDoGetListClients"]),
        /**
         * Busca a lista de carteiras
         * @vue
         */
        getListClients() {
            this.table_loading = true;

            this.ActionDoGetListClients().then(response => {
                this.$store.commit(`reports/${types.SET_LIST_CLIENTS_TOTAL}`, response);
                this.items_table = response;
                this.table_loading = false;
            });
        },
        /**
         * Verifica se o periodo de geracao do relatorio foi definido e busca os dados
         * @vue
         */
        checkReportPeriod() {
            let start_date = null;
            let end_date = null;

            // Verifica se a data foi selecionada no passo um
            if (this.step_two_content.period_selected !== undefined) {
                if (this.step_two_content.period_selected.length === 2) {
                    start_date = this.step_two_content.period_selected[0];
                    end_date = this.step_two_content.period_selected[1];
                } else if (this.step_two_content.period_selected.length === 1) {
                    start_date = this.step_two_content.period_selected[0];
                    end_date = this.step_two_content.period_selected[0];
                }

                // Verifica se as datas selecionadas nao foram alteradas
                if (this.filter_clients_start_date === start_date && this.filter_clients_end_date === end_date) {
                    // Verifica se a lista de clientes existe
                    if (this.list_clients_total.length > 0) {
                        this.items_table = this.list_clients_total;
                        this.clients_selecteds = this.generator_step_three.clients !== undefined ? this.generator_step_three.clients : [];
                    } else {
                        this.getListClients();
                    }
                } else {
                    this.getListClients();
                }
            }

            this.$store.commit(`reports/${types.SET_FILTER_CLIENTS_START_DATE}`, start_date);
            this.$store.commit(`reports/${types.SET_FILTER_CLIENTS_END_DATE}`, end_date);
        }
    },
    template: (
        /*html*/
        `
            <v-row>
                <v-col cols="12" md="6" lg="5">
                    <v-row no-gutters>
                        <v-col cols="6" md="12">
                            <base-section-form title="Clientes">
                                <v-row>
                                    <v-col>
                                        <p class="font-size-12">Selecione os clientes com campanhas dispon&iacute;veis para gerar o relat&oacute;rio</p>
                                        <p class="font-italic font-size-12">Clique nos clientes que deseja gerar relat&oacute;rio, utilize a barra de pesquisa se necess&aacute;rio.</p>
                                    </v-col>
                                </v-row>
                            </base-section-form>
                        </v-col>
                        <v-col cols="6" md="12">
                            <v-img src="/images/reports/girlCheckbox.svg" width="195"></v-img>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6" lg="" v-bind:data-v-step="8">
                    <base-data-table
                        v-model="clients_selecteds"
                        :headers="headers_table"
                        :list-items="items_table"
                        :custom-columns="false"
                        :loading="table_loading"
                        item-key="ID_EMPRESA_EPES"
                        :page-length="4"
                        filterable
                        select-column
                        alternative-header
                        item-name="clientes"
                        label-selected-item-name="selecionados"
                        :step-tutorial-filter="9"
                    ></base-data-table>
                </v-col>
            </v-row>
        `
    )
};