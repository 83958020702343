export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Abrir-Hover" transform="translate(4.791 -98)">
                    <rect id="Retângulo_340" data-name="Retângulo 340" width="28" height="28" transform="translate(-4.791 98)" fill="currentColor" opacity="0"/>
                    <g id="Abrir-icon-2_1_" transform="translate(0.01 102)">
                        <g id="Caminho_361-2_1_" transform="translate(0 1.6)">
                            <path id="Caminho_461" data-name="Caminho 461" d="M-189.5-72.7H-203a2.006,2.006,0,0,1-2-2V-88.1a2.094,2.094,0,0,1,2-2.1h4.3a.789.789,0,0,1,.8.8.736.736,0,0,1-.8.8H-203c-.3,0-.5.2-.5.6v13.4a.472.472,0,0,0,.5.5h13.4a.472.472,0,0,0,.5-.5v-4.3a.789.789,0,0,1,.8-.8.736.736,0,0,1,.8.8v4.3A2.06,2.06,0,0,1-189.5-72.7Z" transform="translate(205 90.2)" fill="currentColor"/>
                        </g>
                        <path id="Caminho_462" data-name="Caminho 462" d="M-187.9-91.8h-5.2a1.324,1.324,0,0,0-1.3,1.3,1.324,1.324,0,0,0,1.3,1.3h0a1.163,1.163,0,0,1,.8,2l-3.9,3.9a1.268,1.268,0,0,0,0,1.8,1.489,1.489,0,0,0,.9.4,1.222,1.222,0,0,0,.9-.4l3.9-3.9a1.186,1.186,0,0,1,2,.8h0a1.324,1.324,0,0,0,1.3,1.3,1.324,1.324,0,0,0,1.3-1.3v-5.2A2.149,2.149,0,0,0-187.9-91.8Z" transform="translate(205 91.8)" fill="currentColor"/>
                    </g>
                </g>
            </svg>
          
        `
    )
};