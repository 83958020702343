import DialogListSessions from "../../sessoes_rcs/components/DialogListSessions";

export default {
    components: {
        DialogListSessions
    },
    data() {
        return {
            campaigns: [8618052, 8623871, 8617983, 8634961],
            show_dialog_session: true
        };
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="12" md="8" lg="6">
                <v-card class="d-flex justify-center">
                    <v-btn @click="show_dialog_session = true" color="dark-primary white--text" class="my-10">Abrir modal de sessoes</v-btn>
                </v-card>
            </v-col>
            <dialog-list-sessions v-model="show_dialog_session" :campaigns="campaigns"/>            
        </v-row>
        `
    )
};