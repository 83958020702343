import FormRespostasRapidas from "../../template-rcs/components/forms/FormRespostasRapidas";

export default {
    components: {FormRespostasRapidas},
    methods: {
        submit(dados) {
            console.log(dados);
        }
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="2">
                <form-respostas-rapidas 
                    @formSubmit="submit">
                </form-respostas-rapidas>
            </v-col>
        </v-row>
        `
    )
};