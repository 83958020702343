import ActiveFilter from "../../../components/atomos/ActiveFilter";

export default {
    components: {
        ActiveFilter
    },
    data() {
        return {
            title: "Teste",
            value: "teste",
            readonly: false
        };
    },
    methods: {
        close() {
            console.log("Close click");
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6" class="text-center">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="4">
                                        <v-checkbox label="Somente leitura" v-model="readonly"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Titulo" v-model="title"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Valor" v-model="value"></v-text-field>
                                    </v-col>
                                    
                                </v-row>
                                <v-row>
                                    <v-col class="d-flex justify-center">
                                        <active-filter :title="title" :value="value" :readonly="readonly" @close="close"/> 
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};