/**
 * Classe que representa um item BaseCardExtraLargeClass
 */
export class BaseCardExtraLargeClass {
    title
    description
    imageUrl
    active
    disabled
    soon
    id

    /**
     * Cria um BaseCardExtraLargeClass
     * @param {String} title
     * @param {String} description
     * @param {String} imageUrl
     * @param {Boolean} active
     * @param {Boolean} disabled
     * @param {Boolean} soon
     * @param {String} id
     */
    constructor({
        id = null,
        title,
        description,
        imageUrl,
        active = false,
        disabled = false,
        soon = false
    }) {
        this.id = id ? id : Math.random();
        this.title = title;
        this.description = description;
        this.imageUrl = imageUrl;
        this.active = active;
        this.disabled = disabled;
        this.soon = soon;
    }
}