import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "api-messages-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom id="_198809578316345278574481" data-name="198809578316345278574481" xmlns="http://www.w3.org/2000/svg" width="35.099" height="35.099" viewBox="0 0 35.099 35.099">
                <g id="Group_2587" data-name="Group 2587">
                    <path id="Path_590" data-name="Path 590" d="M25.819,30.718h-.383a.8.8,0,0,1,0-1.6h.383V26.251a.8.8,0,0,1,.617-.776,9.573,9.573,0,0,0,2.908-1.185.8.8,0,0,1,.987.112l2.017,2.017,2.074-2.074-2.017-2.017a.8.8,0,0,1-.112-.987,9.344,9.344,0,0,0,1.2-2.9.8.8,0,0,1,.779-.622h2.854V14.9H34.266a.8.8,0,0,1-.779-.622,9.344,9.344,0,0,0-1.2-2.9.8.8,0,0,1,.112-.987L34.42,8.37,32.346,6.3l-2.015,2.02a.8.8,0,0,1-.987.112,9.556,9.556,0,0,0-2.912-1.184.8.8,0,0,1-.614-.777V3.6H22.9V6.467a.8.8,0,0,1-.617.777,9.555,9.555,0,0,0-2.908,1.184.8.8,0,0,1-.987-.112L16.37,6.3,14.3,8.373l2.017,2.017a.8.8,0,0,1,.112.987,9.344,9.344,0,0,0-1.2,2.9.8.8,0,0,1-.779.622H11.6v.383a.8.8,0,0,1-1.6,0V14.9a1.6,1.6,0,0,1,1.6-1.6h2.238a10.953,10.953,0,0,1,.919-2.225L13.171,9.5a1.6,1.6,0,0,1,0-2.255l2.074-2.074a1.59,1.59,0,0,1,2.254,0l1.583,1.582a11.166,11.166,0,0,1,2.222-.9V3.6A1.6,1.6,0,0,1,22.9,2h2.92a1.6,1.6,0,0,1,1.6,1.6V5.851a11.168,11.168,0,0,1,2.223.9l1.582-1.582a1.6,1.6,0,0,1,2.257,0l2.07,2.074a1.6,1.6,0,0,1,0,2.255l-1.582,1.582a10.957,10.957,0,0,1,.925,2.222h2.234a1.6,1.6,0,0,1,1.6,1.6v2.92a1.6,1.6,0,0,1-1.6,1.6H34.889a10.976,10.976,0,0,1-.919,2.225l1.577,1.58a1.6,1.6,0,0,1,0,2.256l-2.074,2.074a1.6,1.6,0,0,1-2.255,0l-1.583-1.583a11.075,11.075,0,0,1-2.223.9v2.253a1.6,1.6,0,0,1-1.592,1.6Z" transform="translate(-3.618 -2)"/>
                    <path id="Path_591" data-name="Path 591" d="M26.521,24.353a.809.809,0,0,1-.81-.8.788.788,0,0,1,.787-.8h.024a4.236,4.236,0,1,0-4.236-4.236.808.808,0,0,1-.8.81.788.788,0,0,1-.8-.786,5.831,5.831,0,1,1,5.831,5.807Z" transform="translate(-5.781 -4.162)"/>
                    <path id="Path_592" data-name="Path 592" d="M13.168,40.741h-1.6a1.6,1.6,0,0,1-1.6-1.6V37.962a8.027,8.027,0,0,1-1.29-.525l-.83.83a1.6,1.6,0,0,1-2.256,0L4.473,37.139a1.6,1.6,0,0,1,0-2.256l.828-.828a7.807,7.807,0,0,1-.536-1.292H3.6a1.6,1.6,0,0,1-1.6-1.6v-1.6a1.6,1.6,0,0,1,1.6-1.6H4.765A7.806,7.806,0,0,1,5.3,26.686l-.829-.828a1.6,1.6,0,0,1,0-2.256L5.6,22.473a1.6,1.6,0,0,1,2.256,0l.83.83a7.957,7.957,0,0,1,1.29-.524V21.6a1.6,1.6,0,0,1,1.6-1.6h1.6a1.6,1.6,0,0,1,1.6,1.6v1.183a7.95,7.95,0,0,1,1.29.525l.83-.83a1.6,1.6,0,0,1,2.256,0L20.268,23.6a1.6,1.6,0,0,1,0,2.256l-.828.828a7.8,7.8,0,0,1,.536,1.292h1.169a1.6,1.6,0,0,1,1.6,1.6v1.6a1.6,1.6,0,0,1-1.6,1.6H19.976a7.8,7.8,0,0,1-.537,1.292l.829.828a1.6,1.6,0,0,1,0,2.256l-1.129,1.129a1.6,1.6,0,0,1-2.256,0l-.83-.83a8.034,8.034,0,0,1-1.29.524v1.183a1.6,1.6,0,0,1-1.6,1.6Zm-4.615-5.1a.8.8,0,0,1,.424.123,6.407,6.407,0,0,0,1.977.8.8.8,0,0,1,.618.778v1.794h1.6V37.351a.8.8,0,0,1,.618-.778,6.407,6.407,0,0,0,1.977-.8.8.8,0,0,1,.988.111l1.26,1.26,1.128-1.128-1.26-1.26a.8.8,0,0,1-.11-.989,6.352,6.352,0,0,0,.823-1.974.8.8,0,0,1,.774-.621h1.779v-1.6H19.366a.8.8,0,0,1-.778-.621,6.353,6.353,0,0,0-.819-1.974.8.8,0,0,1,.11-.989l1.26-1.26L18.011,23.6l-1.26,1.26a.8.8,0,0,1-.989.11,6.381,6.381,0,0,0-1.976-.8.8.8,0,0,1-.618-.778V21.6h-1.6v1.794a.8.8,0,0,1-.618.778,6.381,6.381,0,0,0-1.976.8.8.8,0,0,1-.989-.11L6.73,23.6,5.6,24.73l1.26,1.26a.8.8,0,0,1,.11.989,6.354,6.354,0,0,0-.819,1.974.8.8,0,0,1-.778.621H3.6v1.6H5.374a.8.8,0,0,1,.778.621,6.353,6.353,0,0,0,.819,1.974.8.8,0,0,1-.11.989L5.6,36.011,6.73,37.139l1.26-1.26a.8.8,0,0,1,.564-.234Z" transform="translate(-2 -5.641)"/>
                    <path id="Path_593" data-name="Path 593" d="M13.989,35.977a3.993,3.993,0,1,1,2.819-1.17,3.989,3.989,0,0,1-2.819,1.17Zm0-6.382a2.393,2.393,0,1,0,1.692.7,2.393,2.393,0,0,0-1.692-.7Z" transform="translate(-3.618 -7.259)"/>
                </g>
            </svg-custom>
        `
    )
};