export default {
    template: (
        /*html*/
        `
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M4 2.5C4 1.94772 4.44772 1.5 5 1.5H21C21.5523 1.5 22 1.94772 22 2.5V23.5C22 24.0523 21.5523 24.5 21 24.5H5C4.44772 24.5 4 24.0523 4 23.5V2.5ZM5 12.288H21V23.5H5L5 12.288ZM8.67275 8.45194C8.74201 8.50069 8.8248 8.52685 8.90967 8.52681L17.0906 8.5264C17.1753 8.52638 17.2579 8.50028 17.327 8.45169C17.3962 8.4031 17.4484 8.33441 17.4766 8.25509C17.5048 8.17577 17.5076 8.08971 17.4845 8.00877C17.4614 7.92783 17.4137 7.85598 17.3478 7.80313L14.2894 5.35141C14.2166 5.29303 14.1258 5.26118 14.0322 5.26118C13.9387 5.26118 13.8479 5.29303 13.7751 5.35141L11.7297 6.99077L10.9579 6.37855C10.8853 6.32089 10.7951 6.28947 10.7022 6.28947C10.6092 6.28947 10.519 6.32089 10.4464 6.37855L8.65375 7.80232C8.58746 7.85497 8.53928 7.92679 8.51588 8.00784C8.49247 8.08889 8.495 8.17516 8.5231 8.25472C8.5512 8.33428 8.6035 8.4032 8.67275 8.45194Z" fill="currentColor"/>
            <path d="M6.86841 14.5C6.59227 14.5 6.36841 14.7239 6.36841 15C6.36841 15.2761 6.59227 15.5 6.86841 15.5H15.3421C15.6182 15.5 15.8421 15.2761 15.8421 15C15.8421 14.7239 15.6182 14.5 15.3421 14.5H6.86841Z" fill="currentColor"/>
            <path d="M6.86841 17.3421C6.59227 17.3421 6.36841 17.566 6.36841 17.8421C6.36841 18.1182 6.59227 18.3421 6.86841 18.3421H18.1842C18.4603 18.3421 18.6842 18.1182 18.6842 17.8421C18.6842 17.566 18.4603 17.3421 18.1842 17.3421H6.86841Z" fill="currentColor"/>
            <path d="M6.86841 20.1842C6.59227 20.1842 6.36841 20.4081 6.36841 20.6842C6.36841 20.9604 6.59227 21.1842 6.86841 21.1842H12.0263C12.3024 21.1842 12.5263 20.9604 12.5263 20.6842C12.5263 20.4081 12.3024 20.1842 12.0263 20.1842H6.86841Z" fill="currentColor"/>
        </svg>
        `
    )
};