export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="currentColor">
                <g id="Btn-Pause-Hover" transform="translate(-1215 -2155)">
                    <g id="Ico-Pausar-Hover" transform="translate(1219 2113.018)">
                        <path id="pause-fill" d="M12.045,7.875a3.045,3.045,0,0,1,3.045,3.045V23.1A3.045,3.045,0,0,1,9,23.1V10.92a3.045,3.045,0,0,1,3.045-3.045Zm10.149,0a3.045,3.045,0,0,1,3.045,3.045V23.1a3.045,3.045,0,0,1-6.089,0V10.92A3.045,3.045,0,0,1,22.193,7.875Z" transform="translate(-8 38.107)" fill="currentColor"/>
                    </g>
                    <rect id="Retângulo_476" data-name="Retângulo 476" width="26" height="26" transform="translate(1215 2155)" fill="currentColor" opacity="0"/>
                </g>
            </svg>
        `
    )
};