import SectionCard from "../../../components/moleculas/SectionCard";

export default {
    components: {
        SectionCard
    },
    data() {
        return {
            title: "Voce pode adicionar ate 10 botoes de diferentes tipos na sua mensagem",
            icon: "fa-plus",
            cols: "3"
        };
    },
    template: (
        /*html*/
        `
            <v-row class="mt-16" justify="center">
                <v-col cols="12">
                    <v-row justify="center">
                        <v-col cols="3">
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field label="Titulo" v-model="title"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Icone" v-model="icon"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Colunas para exibicao" v-model="cols"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col :cols="cols">
                            <v-card class="bg-of-white mt-10">
                                <section-card
                                    :title="title"
                                    :icon="icon"
                                >
                                    <template #header v-html="teste">
                                        <v-btn block color="dark-green">Alguma coisa aqui no slot Header</v-btn>
                                    </template>
                                    <template #body>
                                        Outra coisa aqui no slot body
                                    </template>
                                </section-card>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};