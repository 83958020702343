import BaseCardShort from "../atomos/BaseCardShort";

/** 
 * Componente para selecao de card short
 * @component
 * @displayName CardShortChoice
 * @vue
 * @category Moleculas
 * @author Igor Mateus Rodrigues de Oliveira <igor.oliveira@pgmais.com.br>
 * @vue/component
 */
export default ({
    components: {
        BaseCardShort
    },
    props: {
        /**
         * Propiedade com array para renderizacao dos botoes
         */
        value: {
            type: Array,
            required: false,
            default: null
        }
    },
    data() {
        return {
            list_items: this.value
        };
    },
    watch: {
        value() {
            this.list_items = this.value;
        }
    },
    methods: {
        /**
         * Funcao para validar qual botao foi clicado e nao permitir que dois sejam clicados ao mesmo tempo
         * @vue
         */
        buttonFunction(valueEmited) {
            for (let item of this.list_items) {
                if (item.value !== valueEmited) {
                    item.active = false;
                }
            }

            /**
             * Evento de input, altera o valor de v-model
             * @property {Array} new_list Lista de botoes alterada
             * @event input
             * @vue
             */
            this.$emit("input", this.list_items);
        }
    },
    template:
        /*html*/
        `
            <v-row no-gutters>
                <v-col cols="6" v-for="(btn_array, key) in list_items" :key="key" class="pr-2 pb-2">
                    <base-card-short
                        v-model="btn_array.active"
                        :icon="btn_array.icon"
                        :label="btn_array.label" 
                        :disabled="btn_array.disabled"
                        :soon="btn_array.soon ? btn_array.soon : false"
                        :tooltip-text="btn_array.tooltip"
                        @click="buttonFunction(btn_array.value)"
                    ></base-card-short>
                </v-col>
            </v-row>
        `,
});
