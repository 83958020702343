import SelectEmpresaForm from "../../auth/components/SelectEmpresaForm";

export default {
    name: "SelectEmpresaUsage",
    components: { "select-empresa-form": SelectEmpresaForm },
    data() {
        return {
            lista_empresas: [
                {
                    text: "Teste1",
                    value: 1},
                {text: "Teste2",
                    value: 2},
                {text: "Teste3",
                    value: 3},
                {text: "Teste4",
                    value: 4}]
        };
    },
    methods: {
        buttonEntrar: function () {
            window.location.href = "/Dashboard";
        },
        buttonVoltar: function () {
            alert("Teste");
        }

    },

    template: (
        /*html*/
        `
           <v-container>
                <v-row>
                    <v-col cols="3">
                        <select-empresa-form
                                    :lista_empresas="lista_empresas" 
                                    url_destino="Dashboard"
                                    @click-button-entrar="buttonEntrar"
                                    @click-button-voltar="buttonVoltar"
                                    /> 
                    </v-col>
                </v-row>
           </v-container>
        `
    )
};