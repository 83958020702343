import FormAddImage from "../../template-rcs/components/forms/FormAddImage";

export default {
    components: {
        FormAddImage
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="12" md="3">
                    <form-add-image></form-add-image>
                </v-col>
            </v-row>
        `
    )
};