import PageHeader from "../../components/moleculas/PageHeader";
import PreviewImage from "../../components/atomos/PreviewImage";
import PreviewCard from "../../components/atomos/PreviewCard";
import PreviewCarousel from "../../components/moleculas/PreviewCarousel";
import PreviewSuggestions from "../../components/atomos/PreviewSuggestions";
import BaseButton from "../../components/atomos/BaseButton";
import BaseTextField from "../../components/atomos/BaseTextField";
import BaseDialog from "../../components/moleculas/BaseDialog";
import BaseChat from "../../components/atomos/BaseChat";
import { DraggableItemClass } from "../../classes/DraggableItemClass";
import { BasePillClass } from "../../classes/BasePillClass";
import TemplateUtils from "../../utils/TemplateUtils";
import BasePill from "../../components/atomos/BasePill";
import { CardRcsClass } from "../../classes/CardRcsClass";
/**
 * @requires {@link PageHeader}
 * @requires {@link PreviewImage}
 * @requires {@link PreviewCard}
 * @requires {@link PreviewCarousel}
 * @requires {@link PreviewSuggestions}
 * @requires {@link BaseButton}
 * @requires {@link BaseTextField}
 * @requires {@link BaseDialog}
 * @requires {@link BaseChat}
 * @requires {@link DraggableItemClass}
 * @requires {@link BasePillClass}
 * @requires {@link TemplateUtils}
 * @requires {@link BasePill}
 * @requires {@link CardRcsClass}

 *
 * Pagina de Chat RCS
 * @displayName ChatPage
 * @category Page / Sessoes RCS
 * @author David dos Santos Andrade <david.santos@pgmais.com.br>
 * @author Igor M R Oliveira <igor.oliveira@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PageHeader,
        PreviewImage,
        PreviewCard,
        PreviewCarousel,
        PreviewSuggestions,
        BaseButton,
        BaseTextField,
        BaseDialog,
        BaseChat,
        BasePill
    },
    data() {
        return {
            chat_data: null,
            template_utils: new TemplateUtils(),
            message_text: null,
            loading: false,
            header_height: 100,
            group_day_messages: {},
            messages_scrolled: false,
            new_messages: 0,
            loading_messages: true,
            session_id: null
        };
    },
    computed: {
        bodyHeight() {
            return this.$vuetify.breakpoint.height - this.header_height;
        }
    },
    mounted() {
        this.loading_messages = true;
        this.getChatData();
        setInterval(() => {
            this.getChatData();
        }, 10000);
        window.addEventListener("load", () => {
            this.header_height = this.$refs.header?.clientHeight ?? 100;
            this.scrollMessagesBottom();
        });
    },
    methods: {
        ...window.Vuex.mapActions("sessoes_rcs", ["ActionDoGetSessionMessages"]),
        ...window.Vuex.mapActions("templatesrcs", ["ActionDoSendTemplateRcs"]),

        /**
         * Executa o action pra buscar informações referentes a sessão
         * @vue
         */
        getChatData() {
            let query_params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });

            if (query_params.id) {
                this.session_id = query_params.id;

                this.ActionDoGetSessionMessages({ session_id: query_params.id }).then(response => {
                    let card_messages = this.$el.querySelector("#messagesCard");
                    if (this.chat_data?.messages.length !== response.messages.length) {
                        this.new_messages = response.messages.length - (this.chat_data?.messages.length ?? 0);

                        if ((card_messages.scrollHeight - card_messages.scrollTop) < 700) {
                            setTimeout(() => {
                                card_messages.scrollTop = card_messages.scrollHeight;
                            }, 500);
                        }
                    }

                    this.chat_data = response;
                    this.group_day_messages = {};
                    for (let message of this.chat_data.messages) {
                        let date = dayjs(message.date).format("YYYY-MM-DD");

                        if (!this.group_day_messages[date]) {
                            this.group_day_messages[date] = [];
                        }
                        this.group_day_messages[date].push(message);
                    }

                    this.loading_messages = false;
                });
            } else {
                window.location.href = "/Dashboard";
            }
        },

        /**
         * Mascara para telefone usando o metodo nativo do Vue maskToPhone
         * @vue
         */
        maskPhone(phone) {
            return window.Vue.maskToPhone(phone);
        },

        /**
         * Metodo responsavel por definir a ultima resposta da sessão
         * @vue
         */
        lastReponse(date) {
            let referer_date = dayjs(date);
            let now = dayjs();
            let diff = now.diff(referer_date, "minute");

            let days = Math.floor(diff / (24 * 60));
            let hours = Math.floor((diff % (24 * 60)) / 60);
            let minutes = diff % 60;

            let time_response = !days && !hours && !minutes ? "agora mesmo " : (days ? days + "d " : "") + (hours ? hours + "h " : "") + (minutes ? minutes + "m " : "") + " atr&aacute;s";

            return window.Vue.htmlEntities("&Uacute;ltima resposta " + time_response);
        },

        /**
         * Metodo responsavel por formatar a data da messagem
         * @vue
         */
        formatDateMessage(date, with_time = true) {
            date = dayjs(date);
            let today = dayjs().format("YYYY-MM-DD");
            let yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");

            if (date.format("YYYY-MM-DD") === today) {
                return !with_time ? "HOJE" : "hoje " + date.format("HH:mm");
            } else if (date.format("YYYY-MM-DD") === yesterday) {
                return !with_time ? "ONTEM" : "ontem " + date.format("HH:mm");
            } else {
                return !with_time ? date.format("DD/MM/YYYY") : date.format("DD/MM/YYYY HH:mm");
            }
        },

        /**
         * Metodo responsavel por montar o Card com os dados da sessão
         * @vue
         */
        mountCard(template, params) {
            let ds_template;
            if (params) {
                ds_template = JSON.parse(this.template_utils.preencherCamposTemplate(template, JSON.parse(params)));
            } else {
                ds_template = JSON.parse(template);
            }
            let contents = ds_template.contents !== undefined ? ds_template.contents : ds_template.content !== undefined ? ds_template.content : ds_template;
            let list_fast_response = null;
            let list_suggestions = null;

            if (contents.suggestions && contents.suggestions !== undefined && contents.suggestions.length > 0) {
                let { fast_response, suggestions } = this.mountSuggestions(contents.suggestions);
                list_fast_response = fast_response;
                list_suggestions = suggestions;
            }

            let list_messages = this.mountMessageOrTitleList(contents.description ?? "");
            let list_title = this.mountMessageOrTitleList(contents.title ?? "");
            let image = contents.media?.file?.url ?? ds_template.url;
            image = image === "{url}" ? "variable" : image;
            let card = {};

            card.image = image;
            card.title = list_title ?? "";
            card.description = list_messages ?? "";
            card.suggestionButtons = list_suggestions;
            card.suggestionResponses = list_fast_response;
            card.orientation = ds_template.orientation ?? "";
            card.alignment = ds_template.alignment ?? "";
            card.image_height = contents.media?.height ?? "";

            return card;
        },

        /**
         * Metodo responsavel por montar o Carousel usando a função CardRcsClass
         * @vue
         */
        mountCarousel(template, params) {
            let list_card = [];
            for (let card of JSON.parse(template).contents) {
                let result = this.mountCard(JSON.stringify(card), params);
                list_card.push(new CardRcsClass({
                    image: result.image,
                    title: result.title,
                    description: result.description,
                    suggestionButtons: result.suggestionButtons,
                    suggestionResponses: result.suggestionResponses
                }));
            }
            return list_card;
        },
        /**
         * Monta a lista de suggestions de botões e respostas rapidas
         * @vue
         */
        mountSuggestions(suggestions) {
            let icons_buttons = {
                "url": {
                    icon: "fa-globe-americas",
                    type: "link"
                },
                "dial_phone": {
                    icon: "fa-phone-alt",
                    type: "phone"
                },
                "show_location": {
                    icon: "fa-map-marker-alt",
                    type: "map"
                },
                "request_location": {
                    icon: "fa-crosshairs",
                    type: "localization"
                }
            };

            let result = {
                have_buttons: false,
                have_fast_responses: false,
                fast_response: [],
                suggestions: []
            };

            for (let index in suggestions) {
                if (suggestions[index].type === "text") {
                    result.have_fast_responses = true;

                    result.fast_response.push(new DraggableItemClass({
                        text: suggestions[index].label,
                        type: "pill",
                        custom: true,
                        order: index,
                        pill: new BasePillClass({
                            title: suggestions[index].label,
                            prependIcon: "fa-grip-vertical",
                            draggable: true,
                            editable: true,
                            deletable: true,
                            order: index
                        })
                    }));
                } else if (["url", "dial_phone", "show_location", "request_location"].indexOf(suggestions[index].type) > -1) {
                    result.have_buttons = true;

                    result.suggestions.push(new DraggableItemClass({
                        text: suggestions[index].label,
                        type: "pill",
                        custom: true,
                        order: index,
                        pill: new BasePillClass({
                            title: suggestions[index].label,
                            subTitle: suggestions[index].type === "show_location" ? `${suggestions[index].value.latitude}, ${suggestions[index].value.longitude}` : suggestions[index].value,
                            prependIcon: "fa-grip-vertical",
                            titleIcon: icons_buttons[suggestions[index].type].icon,
                            draggable: true,
                            editable: true,
                            deletable: true,
                            subTitleNewLine: true,
                            type: icons_buttons[suggestions[index].type].type,
                            order: index
                        })
                    }));
                }
            }

            return result;
        },
        /**
         * Chama a função pra montagem dos suggestions
         */
        callToSuggestionBuilder(template) {
            let suggestions = this.mountSuggestions(JSON.parse(template).suggestions);
            return [...suggestions.suggestions, ...suggestions.fast_response];
        },
        /**
         * Monta a lista de mensagens ou titulo
         * @vue
         */
        mountMessageOrTitleList(message_or_title) {
            let list_words = [];

            for (let message of message_or_title.split(" ")) {

                if (message[0] === "{" && message[message.length - 1] === "}") {
                    let text = message.replaceAll("{", "").replaceAll("}", "");
                    list_words.push(new DraggableItemClass({
                        text: text,
                        type: "pill",
                        custom: true,
                        pill: new BasePillClass({
                            title: text,
                            subTitle: "",
                            prependIcon: "fa-grip-vertical",
                            draggable: true,
                            editable: true,
                            deletable: true,
                            rounded: true,
                            numberCharacters: 1
                        })
                    }));
                } else {
                    list_words.push(new DraggableItemClass({
                        text: message,
                        type: "text",
                        pill: new BasePillClass({})
                    }));
                }
            }

            return list_words;
        },
        /**
         * Monta a resposta
         * @vue
         */
        mountResponse(message) {
            message = message.replace(/^"|"$/g, "");
            try {
                let content = JSON.parse(message);
                return "<b>Suggestion: </b> O usu&aacute;rio clicou no bot&atilde;o " + content.text;
            } catch (e) {
                return message;
            }
        },

        /**
         * Metodo responsavel por fazer envio de mensagem em sessão
         * @vue
         */
        sendMessage() {
            if (this.chat_data?.phone && this.chat_data?.customer_id && this.message_text) {
                this.loading = true;
                this.ActionDoSendTemplateRcs({
                    telefones: [this.chat_data?.phone],
                    id_carteira: this.chat_data?.customer_id,
                    tipo: "text",
                    mensagem: this.message_text,
                    session_id: this.session_id
                }).then(response => {
                    if (response.status) {
                        this.loading = false;
                        this.message_text = null;
                        this.getChatData();
                    }
                });
            }
        },

        /**
         * Metodo executado ao rolar na sessão 
         * @vue
         */
        scrolled({ target: { scrollTop, scrollHeight }}) {
            if ((scrollHeight - scrollTop) > 700) {
                this.messages_scrolled = true;
            } else {
                this.messages_scrolled = false;
                this.new_messages = 0;
            }
        },
        /**
         * Metodo executado ao rolar na sessão para exibir botão de scroll até o fim
         * @vue
         */
        scrollMessagesBottom() {
            let card_messages = this.$el.querySelector("#messagesCard");
            card_messages.scrollTop = card_messages.scrollHeight;
        }
    },
    template:
        /*html*/
        ` 
        <v-card elevation="0" width="100%" class="bg-gray-10">
            <div ref="header">
                <v-card elevation="0" width="100%" class="bg-gray-0">
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12">
                            <v-container>
                                <page-header
                                    title="Chat (RCS)"
                                    description="Aqui voc&ecirc; pode visualizar os retornos recebidos e interagir com o destinat&aacute;rio."
                                    page-return-name="Sess&otilde;es RCS"
                                    page-return-link="/RadarApp"
                                />
                            </v-container>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <v-card elevation="0" width="100%" class="bg-transparent" :height="bodyHeight">
                <v-container class="fill-height mx-auto">
                    <v-card elevation="0" class="bg-gray-0 mt-5 px-8 py-4 rounded-0" width="100%" :height="bodyHeight - 36">
                        <v-card-text>
                            <v-row no-gutters class="gap-2" align="center">
                                <v-col cols="6" md="auto" class="d-flex justify-start">
                                    <v-skeleton-loader v-if="loading_messages" width="120" type="heading" />
                                    <span v-else class="subtitle-3 text-gray-900">{{ maskPhone(chat_data?.phone) }}</span>
                                </v-col>
                                <v-col cols="6" md="" class="d-flex justify-end justify-md-start">
                                    <v-skeleton-loader v-if="loading_messages" width="250" type="text" />
                                    <span v-else class="body-2 font-italic text-gray-900">{{ lastReponse(chat_data?.last_response) }}</span>
                                </v-col>
                                <v-col cols="12" md="auto" class="d-flex justify-center">
                                    <v-skeleton-loader v-if="loading_messages" width="250" type="text" />
                                    <span v-else class="body-2 font-italic text-gray-900">Sess&atilde;o {{ chat_data?.closed ? "encerrada" : "aberta at&eacute; " + chat_data?.end_session}} ({{ chat_data?.messages?.length }} mensagens)</span>
                                </v-col>
                                <v-col cols="auto" class="d-none">
                                    <v-btn v-if="!loading_messages" icon small>
                                        <v-icon color="gray-900">$downloadOutlineIcon</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2" align="center">
                                <v-col cols="12">
                                    <v-card ref="messagesCard" id="messagesCard" elevation="0" width="100%" class="rounded-2 bg-gray-30 px-8 py-4 overflow-y-auto" :height="bodyHeight - 170" @scroll="scrolled">
                                        <v-speed-dial
                                            v-if="messages_scrolled"
                                            :top="false"
                                            :bottom="true"
                                            height="100%"
                                            style="position: absolute; bottom: 30%; left: calc(50% - 16px)"
                                        >
                                            <template v-slot:activator>
                                                <v-badge
                                                    :value="new_messages"
                                                    dot
                                                    color="green"
                                                    overlap
                                                    style="position: fixed;"
                                                >
                                                    <v-btn color="primary-blue-500" fab x-small dark @click="scrollMessagesBottom">
                                                        <v-icon>mdi-arrow-down</v-icon>
                                                    </v-btn>
                                                </v-badge>
                                            </template>
                                        </v-speed-dial>
                                        <v-row v-if="loading_messages" class="fill-height" justify="center" align="center">
                                            <v-col cols="auto">
                                                <v-row justify="center">
                                                    <v-col cols="auto">
                                                        <v-progress-circular indeterminate color="primary-blue-500" />
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="auto">
                                                        <span class="text-gray-800 subtitle-2">Carregando mensagens...</span>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else v-for="(messages, date) in group_day_messages" :key="date">
                                            <v-col cols="12">
                                                <v-row justify="center">
                                                    <v-col cols="auto">
                                                        <base-pill
                                                            :title="formatDateMessage(date, false)"
                                                            rounded
                                                            content-color="gray-900"
                                                            :prepend-icon="null"
                                                            background-color="tertiary-blue-100"
                                                            border-color="tertiary-blue-100"
                                                            selected
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-row v-for="(message, index) in messages" :key="index" :justify="message.type === 'S' ? 'end' : 'start'">
                                                    <v-col cols="12" sm="7" md="6" lg="4" xl="3">
                                                        <v-row no-gutters :justify="message.type === 'S' ? 'end' : 'start'">
                                                            <v-col cols="auto">
                                                                <preview-card
                                                                    v-if="message.template_type === 'C'"
                                                                    :image="mountCard(message.template, message.params).image"
                                                                    :title="template_utils.formatMessageList(mountCard(message.template, message.params).title)"
                                                                    :description="template_utils.formatMessageList(mountCard(message.template, message.params).description)"
                                                                    :suggestion-buttons="mountCard(message.template, message.params).suggestionButtons"
                                                                    :suggestion-responses="mountCard(message.template, message.params).suggestionResponses"
                                                                    :orientation="mountCard(message.template, message.params).orientation"
                                                                    :alignment="mountCard(message.template, message.params).alignment"
                                                                    :image-height="mountCard(message.template, message.params).image_height"
                                                                    need-format-message
                                                                />
                                                                <preview-image
                                                                    v-if="message.template_type === 'I'"
                                                                    :image="mountCard(message.template, message.params).image"
                                                                    :variable="false"

                                                                />
                                                                <preview-carousel v-if="message.template_type == 'R'" 
                                                                    :list-card="mountCarousel(message.template, message.params)"
                                                                    />
                                                                    
                                                                <base-chat
                                                                    v-else-if="!message.template_type || message.template_type === 'M'"
                                                                    :message-text="message.type === 'S' ? ((message.template === null || message.template === '{}') ? message.message : JSON.parse(template_utils.preencherCamposTemplate(message.template, JSON.parse(message.params))).text) : mountResponse(message.message)"
                                                                    :type="message.type"
                                                                />
                                                                <preview-suggestions v-if="message.template != null && message.template_type == 'M'" :list-chips="callToSuggestionBuilder(message.template)" />
                                                                <v-row no-gutters justify="end" class="mt-2">
                                                                    <v-col class="d-flex justify-end" cols="12">
                                                                        <span class="body-2 font-italic text-gray-900">
                                                                            {{ formatDateMessage(message.date) }} 
                                                                            <v-icon v-if="message.delivered && !message.read" right small>mdi-check</v-icon>
                                                                            <v-icon v-else-if="message.read" right small>mdi-check-all</v-icon>
                                                                        </span>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-2">
                                <v-col>
                                    <base-text-field
                                        v-model="message_text"
                                        label="Escreva sua mensagem..."
                                        required
                                        counter
                                        :disabled="chat_data?.closed"
                                        :max-length="1000"
                                    />
                                </v-col>
                                <v-col cols="auto" class="pt-1 ml-2 d-none d-sm-block">
                                    <base-button
                                        icon="$sendMessageIcon"
                                        label="Enviar mensagem"
                                        primary
                                        :loading="loading"
                                        :disabled="chat_data?.closed || !message_text"
                                        @click="sendMessage()"
                                    />
                                </v-col>
                                <v-col cols="auto" class="pt-1 ml-2 d-sm-none">
                                    <base-button
                                        icon="$sendMessageIcon"
                                        only-icon
                                        primary
                                        :loading="loading"
                                        :disabled="chat_data?.closed || !message_text"
                                        @click="sendMessage()"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-card>
        </v-card>
        `
};
