import DataSummary from "../../../components/moleculas/DataSummary";
import ModalFormTesteTemplate from "../../template-rcs/components/dialogs/ModalFormTesteTemplate?v=2";
import BaseButton from "../../../components/atomos/BaseButton?v=2";
import BaseDialog from "../../../components/moleculas/BaseDialog?v=3";
import BaseSectionForm from "../../../components/atomos/BaseSectionForm?v=2";
import PreviewTemplate from "../../../components/organismos/PreviewTemplate";
import DialogPreviewTemplate from "../../../components/organismos/DialogPreviewTemplate";
import PreviewText from "../../../components/atomos/PreviewText";
import TemplateUtils from "../../../utils/TemplateUtils";

/**
 * Componente que representa o passo quatro do cadastro de templates para RCS
 * 
 * @displayName CadastroTemplatePassoQuatro
 * @requires {@link DataSummary}
 * @requires {@link ModalFormTesteTemplate}
 * @requires {@link BaseButton}
 * @requires {@link BaseDialog}
 * @requires {@link BaseSectionForm}
 * @requires {@link PreviewTemplate}
 * @requires {@link DialogPreviewTemplate}
 * @requires {@link PreviewText}
 * @requires {@link TemplateUtils}
 * @component
 * @category Page / Template RCS
 * @author Igor Mateus Rodrigues de Oliveira <igor.oliveira@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    name: "cadastro-template-passo-quatro",
    components: {
        DataSummary,
        ModalFormTesteTemplate,
        BaseButton,
        BaseDialog,
        BaseSectionForm,
        PreviewTemplate,
        DialogPreviewTemplate,
        PreviewText
    },
    data() {
        return {
            modal_clean_all: false,
            modal_send_message: false,
            show_dialog_preview: false,
            ds_template: null,
            template_utils: new TemplateUtils()
        };
    },
    computed: {
        ...window.Vuex.mapState("templatesrcs", {
            step_one_content: state => state.step_one_content,
            step_two_content: state => state.step_two_content,
            step_three_content: state => state.step_three_content,
        }),
        summary() {
            let config = {
                template_code: this.step_one_content.template_code,
                template_name: this.step_one_content.template_name,
                list_items: [
                    {
                        icon: this.step_one_content.modelo.icon_small,
                        background: "primary-blue-500",
                        circle: false,
                        title: "Modelo",
                        description: this.step_one_content.modelo.label,
                        tooltip: "Este foi o modelo de template escolhido no primeiro passo."
                    }
                ]
            };

            if (["simples", "completo"].indexOf(this.step_two_content.button_type.type) > -1) {
                config.list_items.push({
                    icon: this.step_two_content.button_type.icon_small,
                    background: "primary-blue-500",
                    circle: false,
                    title: "Op&ccedil;&atilde;o",
                    description: this.step_two_content.button_type.title,
                    tooltip: "Este foi a op&ccedil;&atilde;o escolhida para configurar o template no segundo passo"
                });
            }

            if (this.step_two_content.list_fast_response.length > 0) {
                let fast_responses = {
                    icon: "$respostasRapidasSmallIcon",
                    background: "primary-blue-500",
                    circle: false,
                    title: "Respostas r&aacute;pidas",
                    description: "",
                    tooltip: "Respostas r&aacute;pidas que voc&ecirc; configurou para o template"
                };

                for (let fast_response of this.step_two_content.list_fast_response) {
                    fast_responses.description += fast_response.text + " <br>";
                }

                config.list_items.push(fast_responses);
            }

            if (this.step_two_content.list_suggestions.length > 0) {
                let suggestions = {
                    icon: "$botoesSmallIcon",
                    background: "primary-blue-500",
                    circle: false,
                    title: "Bot&otilde;es",
                    description: "",
                    tooltip: "Bot&otilde;es de a&ccedil;&atilde;o que voc&ecirc; configurou para o template"
                };

                for (let suggestion of this.step_two_content.list_suggestions) {
                    suggestions.description += suggestion.text + " (" + this.translateSuggestionType(suggestion.pill.type) + ") <br>";
                }

                config.list_items.push(suggestions);
            }

            if (["imagem_unica", "imagem_personalizada"].indexOf(this.step_two_content.button_type.type) > -1) {
                config.list_items.push({
                    icon: this.step_two_content.button_type.icon_small,
                    background: "primary-blue-500",
                    circle: false,
                    title: "Op&ccedil;&atilde;o",
                    description: this.step_two_content.button_type.title,
                    tooltip: "Este foi a op&ccedil;&atilde;o escolhida para configurar o template no segundo passo"
                });
            }

            if (this.step_three_content && Object.keys(this.step_three_content).length > 0) {
                config.list_items.push({
                    icon: "$smsProductSmallIcon",
                    background: "primary-blue-500",
                    circle: false,
                    title: "Mensagem alternativa SMS",
                    description: this.formatAlternateMessage(this.step_three_content.list_messages),
                    tooltip: "Mensagem alternativa configurada"
                });
            }

            return config;
        },
        dataTest() {
            let return_data = {
                tipo: this.step_one_content.modelo.value
            };

            let message = "";
            let suggestions = null;
            let title = "";
            let list_suggestions = [];

            switch (this.step_one_content.modelo.value) {
            case "text":
                for (let item of this.step_two_content.list_messages) {
                    message += item.text + " ";
                }

                if (this.step_two_content.list_chips_preview.length > 0) {
                    suggestions = [];

                    for (let item of this.step_two_content.list_chips_preview) {
                        if (item.pill.type) {
                            let coordinates = null;

                            switch (item.pill.type) {
                            case "phone":
                                suggestions.push({ type: "dial_phone", label: item.pill.title, value: item.pill.subTitle.toString().replace(/[^0-9]+/g, "") });
                                break;
                            case "link":
                                suggestions.push({ type: "url", label: item.pill.title, value: item.pill.subTitle });
                                break;
                            case "map":
                                coordinates = item.pill.subTitle.split(",");
                                if (coordinates.length == 1) {
                                    let coordenates_obj = {
                                        latitude: "{" + (coordinates[0].trim().replace(/[{}]/g, "") + 0) + "}",
                                        longitude: "{" + (coordinates[0].trim().replace(/[{}]/g, "") + 1) + "}"
                                    };
                                    suggestions.push({ type: "show_location", label: item.pill.title, value: coordenates_obj});

                                } else {
                                    suggestions.push({ type: "show_location", label: item.pill.title, value: { latitude: coordinates[0].trim(), longitude: coordinates[1].trim() } });
                                }
                                break;
                            case "localization":
                                suggestions.push({ type: "request_location", label: item.pill.title });
                                break;
                            }
                        } else {
                            suggestions.push({ type: "text", label: item.pill.title });
                        }
                    }
                }

                return_data["mensagem"] = message;
                return_data["suggestions"] = suggestions;
                break;
            case "image":
                return_data["url"] = this.step_two_content.image === "variable" ? (window.location.origin + "/images/cadastro-template/demonstra1.png") : this.step_two_content.image;
                break;
            case "card":
                for (let item of this.step_two_content.card.description) {
                    message += item.text + " ";
                }

                for (let item_title of this.step_two_content.card.title) {
                    title += item_title.text + " ";
                }

                if (this.step_two_content.card.suggestionButtons || this.step_two_content.card.suggestionResponses) {
                    suggestions = [];
                    list_suggestions = [
                        ...this.step_two_content.card.suggestionButtons ?? [],
                        ...this.step_two_content.card.suggestionResponses ?? []
                    ];

                    list_suggestions.sort((a, b) => a.order !== null && b.order !== null ? a.order - b.order : 0);

                    for (let item of list_suggestions) {
                        if (item.pill.type) {
                            let coordinates = null;

                            switch (item.pill.type) {
                            case "phone":
                                suggestions.push({ type: "dial_phone", label: item.pill.title, value: item.pill.subTitle.toString().replace(/[^0-9]+/g, "") });
                                break;
                            case "link":
                                suggestions.push({ type: "url", label: item.pill.title, value: item.pill.subTitle });
                                break;
                            case "map":
                                coordinates = item.pill.subTitle.split(",");

                                if (coordinates.length == 1) {
                                    let coordenates_obj = {
                                        latitude: "{" + (coordinates[0].trim().replace(/[{}]/g, "") + 0) + "}",
                                        longitude: "{" + (coordinates[0].trim().replace(/[{}]/g, "") + 1) + "}"
                                    };
                                    suggestions.push({ type: "show_location", label: item.pill.title, value: coordenates_obj});

                                } else {
                                    suggestions.push({ type: "show_location", label: item.pill.title, value: { latitude: coordinates[0].trim(), longitude: coordinates[1].trim() } });
                                }
                                break;
                            case "localization":
                                suggestions.push({ type: "request_location", label: item.pill.title });
                                break;
                            }
                        } else {
                            suggestions.push({ type: "text", label: item.pill.title });
                        }
                    }
                }

                return_data["parametros"] = {
                    orientation: this.step_two_content.card.orientation ?? "VERTICAL",
                    alignment: this.step_two_content.card.alignment ?? "LEFT",
                    content: {}
                };

                if (title && title.trim() !== "") {
                    return_data["parametros"]["content"]["title"] = title;
                }
                if (message && message.trim() !== "") {
                    return_data["parametros"]["content"]["description"] = message;
                }
                if (this.step_two_content.card.image && this.step_two_content.card.image !== "") {
                    return_data["parametros"]["content"]["media"] = {
                        height: this.step_two_content.card.image_height ?? "TALL",
                        file: {
                            url: this.step_two_content.card.image
                        }
                    };
                }
                if (suggestions && suggestions.length) {
                    return_data["parametros"]["content"]["suggestions"] = suggestions;
                }
                break;
            }

            if (Object.keys(this.step_three_content).length && this.step_three_content.list_messages.length) {
                let message_fallback = "";
                for (let item of this.step_three_content.list_messages) {
                    message_fallback += item.text + " ";
                }
                return_data["fallback"] = message_fallback;
            }

            return return_data;
        }
    },
    methods: {
        translateSuggestionType(type) {
            switch (type) {
            case "link":
                return "link";
            case "phone":
                return "telefone";
            case "map":
                return "coordenadas";
            case "localization":
                return "solicitar localiza&ccedil;&atilde;o";
            }

            return "";
        },
        /**
         * Função que realiza a limpeza do cadastro de template
         * @vue
         */
        clearAll() {
            /**
             * É realizado um emit do evento clearAll quando o usuário confirmar a limpeza de todos os campos
             * @event clearAll
             * @vue
             */
            this.$emit("clearAll");
        },
        /**
         * Exibe o dialog de preview de template
         * @param {String} content_preview JSON no formato string
         * @vue
         */
        showDialogPreview(content_preview) {
            this.ds_template = content_preview;
            this.show_dialog_preview = true;
        },
        /**
         * Formata a lista de mensagem para exibição
         * @param {Array} message_list
         * @returns {String}
         * @vue
         */
        formatAlternateMessage(message_list) {
            let parse_format_list_message = this.template_utils.formatMessageList(message_list);
            let message = "";
            for (let word of parse_format_list_message) {
                if (word.type === "text") {
                    message += word.text + " ";
                } else if (word.type === "break-line") {
                    message += word.text;
                } else {
                    message += /*html*/`<span class="bold">{${word.text}} </span>`;
                }
            }
            return message;
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters>
                <v-col cols="12" md="5" lg="4">
                    <base-section-form title="Revise e salve o template">
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12">
                                <v-row no-gutters :data-v-step="7">
                                    <v-col cols="12">
                                        <data-summary
                                            :steps="summary.list_items"
                                            :disabled-delete="false"
                                            columns="6 col-6 col-sm-auto col-md-12"
                                            tooltip-clear-button="Voc&ecirc; pode limpar tudo e recome&ccedil;ar do zero"
                                            @click="modal_clean_all = true"
                                        >
                                            <template #header>                                                
                                                <span class="body-2 text-gray-900">C&oacute;digo: </span><span class="caption text-gray-500">{{ summary.template_code.trim().toUpperCase().replaceAll(" ", "_") }}</span><br>
                                                <span class="body-2 text-gray-900">Nome: </span><span class="caption text-gray-500">{{ summary.template_name.trim() }}</span>
                                            </template>
                                        </data-summary>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" :data-v-step="8">
                                    <v-col cols="auto">
                                        <base-button
                                            secondary
                                            label="Teste seu template"
                                            icon="mdi-test-tube"
                                            width="auto"
                                            :block="false"
                                            tooltip-text="Envie uma mensagem de teste para at&eacute; tr&ecirc;s n&uacute;meros."
                                            @click="modal_send_message = true"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </base-section-form>
                </v-col>
                <v-col
                    cols="12"
                    md="7"
                    lg="6"
                    offset-lg="1"
                    class="pl-md-10 mt-12 mt-md-0"
                >
                    <preview-template
                        :type="step_one_content.modelo.value === 'text' ? 'message' : step_one_content.modelo.value"
                        :message="step_two_content.list_messages"
                        :list-suggestions="step_two_content.list_chips_preview"
                        :image="step_two_content.image"
                        :card="step_two_content.card"
                        :list-card="step_two_content.list_card"
                        @showDialogPreview="showDialogPreview"
                    />
                </v-col>
                <base-dialog
                    v-model="modal_clean_all"
                    max-width="410px"
                    title="Tem certeza que deseja limpar tudo?"
                    :action-button="{ label: 'Sim, quero limpar tudo', icon: 'mdi-delete', danger: true }"
                    use-sub-action
                    @clickDialog="clearAll"
                >
                    <v-row no-gutters>
                        <v-col class="text-start">
                            <span class="body-2 text-gray-900">Voc&ecirc; ainda pode retornar aos passos anteriores e alterar o que precisar.</span>
                        </v-col>
                    </v-row>
                    <template #subAction>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" class="text-center">
                                <a class="text-primary-blue-500 text-decoration-none body-2" @click="modal_clean_all = false">Mudei de id&eacute;ia, n&atilde;o limpe nada</a>
                            </v-col>
                        </v-row>
                    </template>
                </base-dialog>
                <modal-form-teste-template
                    v-model="modal_send_message"
                    :control-modal="modal_send_message"
                    :data-to-send="dataTest"
                />
                <dialog-preview-template
                    v-model="show_dialog_preview"
                    :ds-template="ds_template"
                />
            </v-row>
        `
    )
};