import PreviewImage from "../../../components/atomos/PreviewImage";

export default {
    components: {
        PreviewImage
    },
    data() {
        return {
            image: "https://s4.static.brasilescola.uol.com.br/img/2019/09/panda.jpg",
            variable: false
        };
    },
    template: (
        /*html*/
        `
            <v-container>
                <v-row justify="center" class="mt-10">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-card>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field label="URL da imagem" v-model="image"></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-checkbox label="Imagem variavel" v-model="variable"></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mt-10">
                            <v-col cols="12" md="3">
                                <preview-image :image="image" :variable="variable"></preview-image>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        `
    )
};