import PreviewTemplate from "./PreviewTemplate";
import BaseDialog from "../moleculas/BaseDialog";
import CanalIconDescription from "../moleculas/CanalIconDescription";
import { DraggableItemClass } from "../../classes/DraggableItemClass";
import { BasePillClass } from "../../classes/BasePillClass";
import { CardRcsClass } from "../../classes/CardRcsClass";

/**
 * Componente de dialog de previsualizacao de templates
 *
 * @requires {@link PreviewTemplate}
 * @requires {@link BaseDialog}
 * @requires {@link CanalIconDescription}
 * @requires {@link DraggableItemClass}
 * @requires {@link BasePillClass}
 * @requires {@link CardRcsClass}
 *
 * @displayName DialogPreviewTemplate
 * @category Organismos
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PreviewTemplate,
        BaseDialog,
        CanalIconDescription
    },
    props: {
        /**
         * Controle a exibicao do modal atraves do v-model
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Dados do template, JSON em formato de string
         */
        dsTemplate: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Dados do template de fallback, JSON em formato de string
         */
        dsTemplateFallback: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Adiciona um destaque a um dos templates
         */
        highlight: {
            type: String,
            required: false,
            default: null,
            validator(data) {
                if (data) {
                    return ["main", "fallback"].indexOf(data) > -1;
                } else {
                    return true;
                }
            }
        }
    },
    data() {
        return {
            show: this.value,
            type: "message",
            message: null,
            message_fallback: null,
            list_suggestions: [],
            image: null,
            card: null,
            list_card: [],
            loading: false,
        };
    },
    computed: {
        showMain() {
            let show = false;

            if (this.dsTemplateFallback) {
                if (this.$vuetify.breakpoint.width >= this.$vuetify.breakpoint.thresholds.sm) {
                    show = true;
                } else if (this.highlight === "main") {
                    show = true;
                }
            } else {
                show = true;
            }

            return show;
        },
        showFallback() {
            let show = false;

            if (this.dsTemplateFallback) {
                if (this.$vuetify.breakpoint.width >= this.$vuetify.breakpoint.thresholds.sm) {
                    show = true;
                } else if (this.highlight === "fallback") {
                    show = true;
                }
            }

            return show;
        }
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            this.$emit("input", this.show);
        },
        dsTemplate() {
            this.mountPreviewData();
        },
        dsTemplateFallback() {
            this.mountPreviewFallback();
        }
    },
    mounted() {
        this.mountPreviewData();
        this.mountPreviewFallback();
    },
    methods: {
        /**
         * Monta os dados para o preview de acordo com a string informada
         * @vue
         */
        mountPreviewData() {
            this.loading = true;

            if (this.dsTemplate && this.dsTemplate !== "") {
                let ds_template = "";

                try {
                    ds_template = JSON.parse(this.dsTemplate);
                } catch (error) {
                    ds_template = { text: this.dsTemplate };
                }

                if (ds_template.url !== undefined && ds_template.url !== "") {
                    this.type = "image";
                    this.image = ds_template.url[0] === "{" && ds_template.url[ds_template.url.length - 1] === "}" ? "variable" : ds_template.url;

                } else if (ds_template.text !== undefined && ds_template.text !== "") {
                    this.type = "message";
                    this.message = ds_template.text;
                    this.list_suggestions = [];

                    if (ds_template.suggestions !== undefined && Array.isArray(ds_template.suggestions)) {
                        for (let suggestion of ds_template.suggestions) {
                            let pill = new BasePillClass({ title: suggestion.label });

                            switch (suggestion.type) {
                            case "url":
                                pill.titleIcon = "fa-globe-americas";
                                break;
                            case "dial_phone":
                                pill.titleIcon = "fa-phone-alt";
                                break;
                            case "show_location":
                                pill.titleIcon = "fa-map-marker-alt";
                                break;
                            case "request_location":
                                pill.titleIcon = "fa-crosshairs";
                                break;
                            }

                            this.list_suggestions.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                        }
                    }
                } else if (ds_template.contents !== undefined && Array.isArray(ds_template.contents)) {
                    this.type = "carousel";
                    this.list_card = [];

                    for (let card of ds_template.contents) {
                        let buttons = [];
                        let responses = [];

                        if (card.suggestions !== undefined && Array.isArray(card.suggestions)) {
                            for (let suggestion of card.suggestions) {
                                let pill = new BasePillClass({ title: suggestion.label });

                                switch (suggestion.type) {
                                case "url":
                                    pill.titleIcon = "fa-globe-americas";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "dial_phone":
                                    pill.titleIcon = "fa-phone-alt";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "show_location":
                                    pill.titleIcon = "fa-map-marker-alt";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "request_location":
                                    pill.titleIcon = "fa-crosshairs";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "text":
                                    responses.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                }
                            }
                        }
                        this.list_card.push(new CardRcsClass({
                            image: card.media !== undefined ? (card.media.file.url[0] === "{" && card.media.file.url[card.media.file.url.length - 1] === "}" ? "variable" : card.media.file.url) : null,
                            title: card.title,
                            description: card.description,
                            suggestionButtons: buttons,
                            suggestionResponses: responses
                        }));
                    }
                // Tratativa para capturar diferentes formatos de armazenamento de informa��es das mensagens enviadas, 
                // permitindo preencher corretamente o preview das mensagens
                } else {
                    for (let content in ds_template) {
                        if (content === "contents" || content === "content") {
                            if (ds_template[content] && ds_template[content] !== undefined && !Array.isArray(ds_template[content])) {
                                this.type = "card";
                                let buttons = [];
                                let responses = [];

                                if (ds_template[content].suggestions !== undefined && Array.isArray(ds_template[content].suggestions)) {
                                    for (let index in ds_template[content].suggestions) {
                                        let pill = new BasePillClass({ title: ds_template[content].suggestions[index].label });

                                        switch (ds_template[content].suggestions[index].type) {
                                        case "url":
                                            pill.titleIcon = "fa-globe-americas";
                                            buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                            break;
                                        case "dial_phone":
                                            pill.titleIcon = "fa-phone-alt";
                                            buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                            break;
                                        case "show_location":
                                            pill.titleIcon = "fa-map-marker-alt";
                                            buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                            break;
                                        case "request_location":
                                            pill.titleIcon = "fa-crosshairs";
                                            buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                            break;
                                        case "text":
                                            responses.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                            break;
                                        }
                                    }
                                }
                                this.card = new CardRcsClass({
                                    image: ds_template[content].media !== undefined ? (ds_template[content].media.file.url[0] === "{" && ds_template[content].media.file.url[ds_template[content].media.file.url.length - 1] === "}" ? "variable" : ds_template[content].media.file.url) : null,
                                    title: ds_template[content].title,
                                    description: ds_template[content].description,
                                    suggestionButtons: buttons,
                                    suggestionResponses: responses,
                                    orientation: ds_template.orientation,
                                    alignment: ds_template.alignment,
                                    image_height: ds_template[content].media?.height ?? "TALL"
                                });
                            }
                        }
                    }
                }
            }
            this.loading = false;
        },
        /**
         * Monta os dados para o preview de fallback de acordo com a string informada
         * @vue
         */
        mountPreviewFallback() {
            this.loading = true;

            if (this.dsTemplateFallback && this.dsTemplateFallback !== "") {
                let ds_template = "";

                try {
                    ds_template = JSON.parse(this.dsTemplateFallback);
                } catch (error) {
                    ds_template = { text: this.dsTemplateFallback };
                }

                this.message_fallback = ds_template.text;
            }

            this.loading = false;
        }
    },
    template:
        /*html*/
        `
            <base-dialog
                v-model="show"
                title="Preview"
                use-icon-close
                :max-width="dsTemplateFallback ? '1128px' : '545px'"
                :loading="loading"
            >
                <v-row no-gutters>
                    <v-col v-if="dsTemplateFallback" cols="12">
                        <v-row no-gutters :class="dsTemplateFallback ? 'gap-6' : ''">
                            <v-col v-if="showMain" cols="12" :sm="dsTemplateFallback ? 6 : 12">
                                <v-row v-if="dsTemplateFallback" no-gutters class="mb-4">
                                    <v-col cols="12">
                                        <canal-icon-description product-name="rcs" />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="showFallback" cols="12" sm="6">
                                <v-row no-gutters class="mb-4">
                                    <v-col cols="12">
                                        <canal-icon-description product-name="sms-fallback" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row no-gutters :class="dsTemplateFallback ? 'gap-6' : ''">
                            <v-col
                                v-if="showMain"
                                cols="12"
                                :sm="dsTemplateFallback ? 6 : 12"
                                class="d-flex"
                            >
                                <preview-template
                                    :type="type"
                                    :message="message"
                                    :list-suggestions="list_suggestions"
                                    :image="image"
                                    :card="card"
                                    :list-card="list_card"
                                    show-overflow
                                    :highlight="show && highlight === 'main'"
                                />
                            </v-col>
                            <v-col
                                v-if="showFallback"
                                cols="12"
                                sm="6"
                                class="d-flex"
                            >
                                <preview-template
                                    type="message"
                                    :message="message_fallback"
                                    show-overflow
                                    :highlight="show && highlight === 'fallback'"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </base-dialog>
        `
};
