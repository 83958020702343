import RouterPage from "../RouterPage";
import AvulsoApiPage from "./AvulsoApiPage";

export default [
    {
        path: "avulsoapi",
        name: "avulsoapi",
        component: RouterPage,
        children: [
            {
                path: "list",
                name: "list-avulso-api",
                component: AvulsoApiPage
            }
        ]
    }
];