import styled from "../../plugins/vue-styled-components.es";
import { ProdutoUtils } from "../../utils/ProdutoUtils";

const StyledDivLoader = styled("div")`
    top: 30%;
`;

/**
 * Componente do botao de selecao de canal
 * 
 * @displayName CanalButton
 * @category Atomos
 * @component
 * @vue
 */
export default {
    name: "canal-button",
    components: {
        "styled-div-loader": StyledDivLoader
    },
    props: {
        /**
         * Define qual � o tipo do botao de acordo com os tipos de canais
         * @values rcs, whatsapp, sms-concatenado, sms, sms-flash, sms-smart, sms-interativo
         */
        type: {
            type: String,
            required: true,
            validator: function (value) {
                return [
                    "rcs",
                    "whatsapp",
                    "sms-concatenado",
                    "sms",
                    "sms-flash",
                    "sms-smart",
                    "sms-interativo"
                ].indexOf(value) !== -1;
            }
        },
        /**
         * Define se o botao deve ter o estado desabilitado
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define o botao para o status de ativo
         */
        active: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define se o botao esta com status de loading
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica se o botao pode ser desativado apos ser ativado
         */
        only_active: {
            type: Boolean,
            default: false
        },
        /**
         * Define o padding interno do botao
         */
        padding: {
            type: String,
            default: "py-3"
        },
        /**
         * Informa se o nome apresentado no botao deve ser o nome completo
         */
        full_name: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            produto_service: new ProdutoUtils()
        };
    },
    methods: {
        /**
         * Funcao vinculada ao botao
         * @public
         */
        buttonClick: function () {
            this.active = this.only_active || !this.active;
            /**
             * Evento de click do botao
             * @property {string} type 
             * @category Atomos
             * @event click
             * @vue
            */
            this.$emit("click", this.type);
        }
    },
    computed: {
        button_theme: function () {
            return this.produto_service.getTema(this.type);
        },
        class_button: function () {
            if (this.active && !this.disabled && !this.loading) {
                return "text-white border-" + this.type + " bg-" + this.type + " " + this.button_theme.icon_name + "-hover " + this.padding;
            } else if (!this.disabled && this.loading) {
                return this.button_theme.icon_name + " bg-background text-light-grey border border-light-grey " + this.padding;
            } else {
                return this.button_theme.icon_name + " " + this.padding;
            }
        }

    },
    template: (
        /*html*/
        `
            <v-btn
                block
                depressed
                height="40px !important"
                class="text-left btn-no-focus"
                :class="class_button"
                :disabled="disabled"
                @click="buttonClick">
                <v-icon left>{{button_theme.icon_name}}</v-icon>
                <span id="button-content"> 
                   {{ full_name ? button_theme.full_name : button_theme.name }}
                </span>
                <styled-div-loader v-if="loading" class="row justify-content-center position-absolute w-100 text-extradark-grey">
                    <div class="col-2 pl-1">
                        <div >
                            <v-icon left>fa fa-1x fa-spinner fa-spin</v-icon>
                        </div>
                    </div>
                </styled-div-loader>
            </v-btn>
        `
    )
};
