import styled from "../../plugins/vue-styled-components.es";

const LoaderStyled = styled("div")`
    .v-btn__loader {
        color: var(--medium-dark-grey)
    }
`;

const BadgeBootstrapTop = styled("div")`
    .badge {
        position: absolute;
        top: -50%;
        transform: translateY(50%) !important;
        border: 2px solid white !important;
        padding: 1px !important;
        font-size: 100% !important;
        left: 92% !important;
    }
`;

/**
 * Componente de bot�o de troca de produto
 * 
 * @displayName ButtonHeader
 * @component
 * @category Atomos
 * @vue
 */
export default {
    name: "button-header",
    components: {
        LoaderStyled,
        BadgeBootstrapTop
    },
    props: {
        /**
         * Define o icone do bot�o
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * Define a label do bot�o
         */
        label: {
            type: String,
            required: true
        },
        /**
         * Define a cor do bot�o
         */
        variant: {
            type: String,
            default: "primary"
        },
        /**
         * Indica se o bot�o deve ser desabilitado
         * @values true, false
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * Indica se o icone deve ficar � esquerda
         * @values true, false
         */
        left: {
            type: Boolean,
            default: false
        },
        /**
         * Indica que o bot�o deve cobrir todo o espa�o da div
         * @values true, false
         */
        block: {
            type: Boolean,
            default: true
        },
        /**
         * Indica se o bot�o esta ativo
         * @values true, false
         */
        active: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica se o bot�o deve estar com o loading ativado
         * @values true, false
         */
        loading: {
            type: Boolean,
            required: false,
            default: true
        },
        vue_bootstrap: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        /**
         * Emite um evento informando que o bot�o foi clicado
         * @category Atomos
         * @vue
         */
        buttonFunction: function () {
            /**
             * Evento de click do bot�o
             * 
             * @property {object} variant cor do bot�o clicado
             * 
             * @category Atomos
             * @event click
             * @vue
             */
            this.$emit("click", this.variant);
        }
    },
    computed: {
        class_button() {
            let cs_btn = "text-white";

            if (this.active && !this.loading) {
                cs_btn += (!this.vue_bootstrap ? " border" : "") + " border-2 border-white bg-" + this.variant;
            } else if (this.loading) {
                cs_btn += " bg-extra-light-grey";
            } else {
                cs_btn += " bg-" + this.variant;
            }

            return cs_btn;
        }
    },
    template: (
        /*html*/
        `
        <div>
            <div v-if="!vue_bootstrap">
                <loader-styled class="mr-3">
                    <v-badge avatar bordered overlap :value="active && !loading" class="w-100">
                        <template v-slot:badge >
                            <v-avatar :class="'bg-white text-' + variant">
                                <v-icon small>fa-check</v-icon>
                            </v-avatar>
                        </template>
                        <v-btn depressed :class="class_button" block :disabled="disabled" @click="buttonFunction()" :loading="loading">
                            <v-icon v-if='left' left>{{icon}}</v-icon> {{label.toUpperCase()}}
                            <v-icon v-if="!left" right>{{icon}}</v-icon>
                        </v-btn>
                    </v-badge>
                </loader-styled>
            </div>
            <div v-if="vue_bootstrap">
                <b-overlay class="text-medium-dark-grey mr-2" :show="loading" rounded="sm" variant="extra-light-grey" opacity="1" spinner-small>
                    <b-button rounded="sm" block :class="class_button" size="sm" :disabled="disabled" @click="buttonFunction()">
                        <span v-if='left' :class="icon"></span> {{label.toUpperCase()}} <span v-if='!left' :class="icon"></span>
                        <badge-bootstrap-top v-if="active && !loading">
                            <b-badge :class="'rounded-circle bg-white text-' + variant">
                                <i class="fas fa-check"></i>
                            </b-badge>
                        </badge-bootstrap-top>
                    </b-button>
                </b-overlay>
            </div>
        </div>
        
        `
    )
};