/**
 * Componente base para utilizacao em formularios
 * 
 * @displayName BaseSectionForm
 * @component
 * @category Atomos
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    name: "base-section-form",
    props: {
        /**
         * Titulo do componente 
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Breve descricao
         */
        description: {
            type: String,
            required: false,
            default: null
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters>
                <v-col class="p-0">
                    <p class="subtitle-3 text-gray-900 ma-0 pa-0" v-html="title"></p>
                    <p v-if="description" class="body-2 text-gray-700 ma-0 pa-0 pt-4" v-html="description"></p>
                    <!-- @slot Slot que recebe o formulario -->
                    <slot></slot>
                </v-col>
            </v-row>
        `
    )
};