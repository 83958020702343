/**
 * @property title {string}
 * @property icon {string}
 * @property icon_bg_color {string}
 * @property icon_text_color {string}
 * @property active {boolean}
 * @property disabled {boolean}
 * @property slot_name {string}
 */
export class ItemAccordionClass {
    title;
    icon;
    icon_bg_color;
    icon_text_color;
    active;
    disabled;
    complete;
    slot_name;
    tooltip

    constructor({title, icon, icon_bg_color = "dark-primary", icon_text_color = "white", active = false, disabled = false, complete = false, slot_name, tooltip = null}) {
        this.title = title;
        this.icon = icon;
        this.icon_bg_color = icon_bg_color;
        this.icon_text_color = icon_text_color;
        this.active = active;
        this.disabled = disabled;
        this.complete = complete;
        this.slot_name = slot_name;
        this.tooltip = tooltip;
    }
}