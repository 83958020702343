import BaseTextField from "./BaseTextField.js?v=2";

/**
 * Componente basico de date picker
 * @displayName BaseDatePicker
 * @requires {@link BaseTextField}
 * @component
 * @vue
 * @category Atomos
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTextField
    },
    props: {
        /**
         * Valor da data inicial (Exibe o dia de hoje)
         */
        inicio: {
            type: Boolean,
            required: false,
            default: null
        },
        /**
         * Valor da data final (Exibe 30 dias a frente)
         */
        fim: {
            type: Boolean,
            required: false,
            default: null
        },
        /**
         * Valor do input 
         */
        value: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Label do input
         */
        label: {
            type: String,
            required: false,
            default: "DD/MM/YYYY"
        },
        /**
         * Define se o campo e obrigatorio
         */
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Regras do input
         */
        rules: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Verifica se e utilizado icone na validacao
         */
        useIconValidation: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Conteudo do poppover
         */
        popoverContent: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Titulo
         */
        title: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Texto do hint
         */
        hint: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Texto do tooltip
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Cor do tooltip
         */
        colorTooltip: {
            type: String,
            required: false,
            default: "gray-700"
        },
        /**
         * Classe css do tooltip
         */
        contentClassTooltip: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Desabilita o datepicker
         */
        disablePicker: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Desabilita o input
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Data minima no formato YYYY-MM-DD
         */
        minDate: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Data maxima no formato YYYY-MM-DD
         */
        maxDate: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Adiciona o icone de limpar o campo
         */
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Adiciona name ao input
         */
        name: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Indica se fechara o datepicker ao ser selecionado
         */
        closeOnClick: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Array de datas habilitadas
         */
        allowedDates: {
            type: [Array, Function],
            required: false,
            default: undefined
        },
        /**
         * ID Data Picker
         */
        id: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            date: "",
            date_input: this.disabled ? null : window.Vue.formatDateUsaToBrasil((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            menu: false,
            new_rules: this.rules
        };
    },
    watch: {
        date(day) {
            // Se o componente estiver desabilitado, interrompe o processamento.
            if (this.disabled) {
                return;
            }

            if (this.inicio || this.fim) {
                const now = dayjs(new Date());
                if (day == null) {
                    if (this.inicio) {
                        day = now.month(now.month()).date(1).format("YYYY-MM-DD");
                        this.$emit("input", day);
                    }
                    if (this.fim) {
                        day = now.month(now.month()).date(now.format("DD")).format("YYYY-MM-DD");
                        this.$emit("input", day);
                    }
                } else {
                    this.date_input = window.Vue.formatDateUsaToBrasil(this.date);
                }

            } else {
                this.date_input = window.Vue.formatDateUsaToBrasil(this.date);
            }
        },
        date_input() {
            /**
             * Evento que informa qual data esta selecionada
             * 
             * @property {string} date_input Conteudo do campo
             * 
             * @event input
             * @vue
             */
            this.$emit("input", window.Vue.formatDateBrasilToUsa(this.date_input));
        },
        value() {
            if ((this.value && this.value.length === 10) || this.value === null) {
                this.date = this.value;
            }
        },
        maxDate(val) {
            if (!val || val.length === 0 ) {
                this.new_rules = this.rules;
            } else {
                this.addValidateMaxDate();
            }
        },
        minDate(val) {
            if (!val || val.length === 0 ) {
                this.new_rules = this.rules;
            } else {
                this.addValidateMinDate();
            }
        },
        /**
         * Se o componente estiver desabilitado, o valor do input � limpo
         * @vue
         */
        disabled() {
            if (this.disabled) {
                this.date_input = null;
            }
        }
    },
    mounted() {
        this.date = this.value;
        if (this.maxDate) {
            this.addValidateMaxDate();
        }
        if (this.minDate) {
            this.addValidateMinDate();
        }
    },
    methods: {
        /**
         * Funcao para atualizar o date
         * 
         * @param {String} value 
         */
        functionBlur(value) {
            this.date = window.Vue.formatDateBrasilToUsa(value);
        },
        /**
         * Adiciona validacao de data maxima no input
         */
        addValidateMaxDate() {
            if (this.maxDate.length === 10) {
                this.new_rules.push((value) => {
                    let date = new Date(window.Vue.formatDateBrasilToUsa(value));
                    let max_date = new Date(this.maxDate);
                    if (date > max_date) {
                        return window.Vue.htmlEntities("A data deve ser menor ou igual &agrave; " + window.Vue.formatDateUsaToBrasil(this.maxDate));
                    }

                    return true;
                });
            }
        },
        /**
         * Adiciona validacao de data minima no input
         */
        addValidateMinDate() {
            if (this.minDate.length) {
                this.new_rules.push((value) => {
                    if (!value) {
                        return false;
                    }

                    let date = new Date(window.Vue.formatDateBrasilToUsa(value));
                    let dateWithouTimezone = new Date(date.toISOString().slice(0, -1));
                    let min_date = new Date(this.minDate);

                    if (dateWithouTimezone < min_date) {
                        return window.Vue.htmlEntities("A data deve ser maior ou igual &agrave; " + window.Vue.formatDateUsaToBrasil(this.minDate));
                    }

                    return true;
                });
            }
        }
    },
    template: (
        /*html*/
        `
        <div>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="closeOnClick"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template #activator="{ on, attrs }">
                    <base-text-field
                        v-model="date_input"
                        :label="label"
                        :rules="new_rules"
                        :required="required"
                        :use-icon-validation="useIconValidation"
                        :title="title"
                        :hint="hint"
                        :content-class-tooltip="contentClassTooltip"
                        :color-tooltip="colorTooltip"
                        :popover-content="popoverContent"
                        :tooltip-text="tooltipText"
                        :bind-personal="attrs"
                        :readonly="!disablePicker"
                        :disabled="disabled"
                        :on-personal="on"
                        :clearable="clearable"
                        :name="name"
                        mask="##/##/####"
                        @blur="functionBlur"
                        :id="id"
                    />
                </template>
                <v-date-picker
                    v-if="!disablePicker && !disabled"
                    :allowed-dates="allowedDates"
                    v-model="date"
                    no-title
                    scrollable
                    :min="minDate"
                    :max="maxDate"
                />
            </v-menu>
        </div>
        `

    )
};