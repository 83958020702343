/**
 * Componente base para apresentar informacoes no painel
 * Este componente possui um slot default
 * @displayName BaseInformation
 * @component
 * @category Atomos
 * @author David dos Santos Andrade <david.santos@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    props: {
        /**
         * Descricao que fica no componente
         */
        description: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Icone do componente
         */
        icon: {
            type: String,
            required: false,
            default: "mdi-information-outline"
        },
        /**
         * Cor da borda
         */
        borderColor: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Cor do icone
         */
        iconColor: {
            type: String,
            required: false,
            default: "info-500"
        },
        /**
         * Cor do background do componente
         */
        backgroundColor: {
            type: String,
            required: false,
            default: "gray-30"
        },
        /**
         * Cor do texto
         */
        textColor: {
            type: String,
            required: false,
            default: "gray-900"
        },
        /**
         * Label do link
         */
        linkLabel: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Link de redirecionamento
         */
        linkHref: {
            type: String,
            required: false,
            default: null
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters :class="'bg-' + backgroundColor + ' rounded' + (borderColor ? ' border border-' + borderColor : '')">
                <v-col cols="12">
                    <v-row no-gutters class="py-2 px-3" align="center">
                        <v-col cols="auto">
                            <v-icon size="20" :color="iconColor">
                                {{ icon }}
                            </v-icon>
                        </v-col>
                        <v-col v-if="description" class="text-start pl-3">
                            <span :class="'body-2 text-' + textColor" v-html="description" />
                        </v-col>
                        <v-col v-if="$slots.customDescription" class="text-start pl-3">
                            <slot name="customDescription" />
                        </v-col>
                    </v-row>
                    <v-row v-if="linkLabel && linkHref" no-gutters justify="center">
                        <v-col cols="auto" class="pb-2">
                            <a class="body-2 text-primary-blue-500 text-decoration-none" :href="linkHref">{{ linkLabel }}</a>
                        </v-col>
                    </v-row>
                    <v-row v-if="$slots.default" no-gutters>
                        <v-col cols="12">
                            <slot />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};