import ProductDescription from "../../../components/moleculas/ProductDescription";

export default {
    components: {ProductDescription},
    template: (
        /*html*/
        `
            <v-row>
                <v-col cols="6" class="pr-0">
                        <product-description title="Canal SMS" 
                        icon_product="$rcsProductIcon" 
                        color_background="sms"
                        icon_color="rcs"
                        description="O SMS &eacute; o canal de comunica&ccedil;&atilde;o de melhor custo-benef&iacute;cio dispon&iacute;vel na plataforma de envio de mensagens da PGMais."/>
                </v-col>

                <v-col cols="3" class="pl-0 pr-0">
                        <product-description title="Canal SMS" 
                        icon_product="$rcsProductIcon" 
                        color_background="sms"
                        icon_color="rcs"
                        description="O SMS &eacute; o canal de comunica&ccedil;&atilde;o de melhor custo-benef&iacute;cio dispon&iacute;vel na plataforma de envio de mensagens da PGMais."/>
                </v-col>
                
                <v-col cols="3" class="pl-0 pr-0">
                    <product-description title="Canal SMS" 
                    icon_product="$rcsProductIcon" 
                    color_background="sms"
                    icon_color="rcs"
                    description="O SMS &eacute; o canal de comunica&ccedil;&atilde;o de melhor custo-benef&iacute;cio dispon&iacute;vel na plataforma de envio de mensagens da PGMais."/>
                </v-col>
            </v-row>
    
        `
    )
};