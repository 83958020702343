import RouterPage from "../RouterPage";
import SessoesRcsPage from "./SessoesRcsPage";
import ChatPage from "./ChatPage";

export default [
    {
        path: "RadarApp",
        name: "radarApp",
        component: RouterPage,
        children: [
            {
                path: "list",
                name: "sessoesRcsPage",
                component: SessoesRcsPage
            },
            {
                path: "chat",
                name: "chatRcsPage",
                component: ChatPage
            }
        ]
    }
];