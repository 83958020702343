import services from "../../../http/index";

export const ActionDoGetSessionMessages = (context, params) => {
    return services.api.request({
        url: "/radar-app/chat",
        method: "post",
        data: params
    }).then(response => {
        return response.data;
    });
};

export const ActionDoGetListSessions = (context, params) => {
    return services.api.request({
        url: "/radar-app/list",
        method: "post",
        data: params
    }).then(response => {
        return response.data;
    });
};