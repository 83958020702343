import { BlackListServices } from "../../../../../js/services/BlackListServices";
import BaseButton from "../../../components/atomos/BaseButton";

const blacklistService = new BlackListServices();

export default {
    components: {
        BaseButton
    },
    props: {
        /**
         * Objeto que indica se deve ou n�o habilitar os bot�es de exportar
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Dados do filtro a serem submetidos
         */
        dataExport: {
            type: Object,
            required: true,
            default: function () {
                return {};
            }
        },
        /**
         * Dados da tabela
         */
        contentTable: {
            type: Object,
            required: false,
            default: function () {
                return {};
            }
        },
        /**
         * ID's selecionados
         */
        selecteds: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            disabled_text: true,
            disabled_excel: true,
            loading_text: true,
            loading_excel: true,
            disabled_excluir: true
        };
    },
    watch: {
        loading: function (ev) {
            this.exportButtonLoading(ev);
        },
        contentTable: function (ev) {
            if (ev.length == 0) {
                this.exportButtonLoading(false);
            } else {
                this.exportButtonLoading(true);
            }
            //Sempre que o dados da tabela forem atualizados, desabilita o loading dos bot�es de exportar
            this.loading_text = false;
            this.loading_excel = false;
        },
        dataExport: function () {
            this.exportButtonLoading(true);
        },
        selecteds: function (ev) {
            if (ev.length > 0) {
                this.disabled_excluir = false;
            } else {
                this.disabled_excluir = true;
            }
        }
    },
    methods: {
        /**
         * Ativa ou inativa o loading e desabled, dentro do bot�o de exportar
         * @param {boolean} is_loading
         */
        exportButtonLoading(is_loading = false) {

            this.loading_text = is_loading;
            this.loading_excel = is_loading;

            if (this.contentTable.length == 0) { // Se n�o houver dados ap�s o filtro, ativa o disable
                this.disabled_text = true;
                this.disabled_excel = true;

            } else { // Se houver dados ap�s o filtro, inativa o disable
                this.disabled_text = false;
                this.disabled_excel = false;
            }
        },
        /**
         * Baixa um array buffer no tipo indicado.
         * OBS: O navegador pode acabar bloqueando multiplos downloads ento  importando inform-lo
         * @param arrayBuffer - Arquivo
         * @param full_name {string} -  nome + extenso = file.csv
         * @param type {string} - tipo arquivo
         */
        downloadFileBlackList(arrayBuffer, full_name, type = "text/csv") {
            var blob = new Blob([arrayBuffer], { type });
            var a = document.createElement("a");
            var url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = full_name;
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        },
        /**
         * Funcao que gera o relatorio em formato text/excel
         */
        async exportarTextExcel(file_type) {

            let filtros = {
                dataInicio: this.dataExport.dataInicio,
                dataFim: this.dataExport.dataFim,
                telefone: this.dataExport.telefone ? this.dataExport.telefone : "",
            };
            if (file_type == "text") {
                this.loading_text = true;
                this.disabled_text = true;
            } else {
                this.loading_excel = true;
                this.disabled_excel = true;
            }
            await blacklistService
                .reportSynthetic(filtros)
                .done((data) => {
                    if (file_type == "text") {
                        this.downloadFileBlackList(data, "BlacklistRelatorio.txt");
                    }
                    if (file_type == "excel") {
                        this.downloadFileBlackList(data, "BlacklistRelatorio.csv");
                    }

                    this.exportButtonLoading(false);
                })
                .fail(() => {
                    this.exportButtonLoading(false);
                });
        },
        excluirSelecionados() {
            this.$emit("dialog", true);
        }
    },
    template:
        /*html*/
        `
            <div>
                <v-row justify="end" class="mt-3">
                    <v-col cols="12" md="4" lg="2" justify="start" v-bind:data-v-step="3">                        
                        <button
                            type="button"
                            data-toggle="tooltip"
                            :disabled="disabled_excluir"
                            title="Selecione um ou mais telefones para excluir."
                            class="btn btn-danger btn-sm btn-block mt-5"
                            @click="excluirSelecionados()"
                        >
                            <span class="button-icon excluir">Excluir Selecionados</span>
                        </button>
                    </v-col>
                    
                    <v-col cols="1" lg="2" class="mt-md-3"></v-col>
                    
                    <v-col cols="12" sm="12" md="7" lg="8" class="mt-md-3">
                        <v-row justify="end">
                            <v-col cols="12" lg="1" class="col-md-2 text-md-right text-sm-left text-center mt-sm-0 mt-md-2">     
                                <label> Download:</label>   
                            </v-col>
                            <v-col cols="12" md="6" class="pt-0"> 
                                <v-row justify="end" v-bind:data-v-step="5">
                                    <v-col cols="12" md="6" lg="6">
                                        <base-button
                                            @click="exportarTextExcel('text')"
                                            :disabled="disabled_text"
                                            :loading="loading_text"
                                            icon="icon-btn-download"
                                            label="Texto"
                                            :block="false"
                                            width="100%"
                                            :small="true"
                                        >                                    
                                            <template v-slot:loader>
                                                <span>Carregando...</span>
                                                
                                                <v-progress-linear
                                                    indeterminate
                                                    absolute
                                                    bottom
                                                    color="dark-primary"
                                                    height="4"
                                                />
                                            </template>
                                        </base-button> 
                                    </v-col>    
                                    <v-col cols="12" md="6" lg="6">
                                        <base-button
                                            @click="exportarTextExcel('excel')"
                                            :disabled="disabled_excel"
                                            :loading="loading_excel"
                                            icon="icon-btn-download"
                                            label="Excel"
                                            :block="false"
                                            width="100%"
                                            :small="true"
                                        >                                    
                                            <template v-slot:loader>
                                                <span>Carregando...</span>
                                                
                                                <v-progress-linear
                                                    indeterminate
                                                    absolute
                                                    bottom
                                                    color="dark-primary"
                                                    height="4"
                                                />
                                            </template>
                                        </base-button>
                                    </v-col>
                                </v-row>
                            </v-col>   
                        </v-row>         
                    </v-col>
                </v-row>             
            </div>
    `
};
