import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "chat-bubble-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  width="22.341" height="22.332" viewBox="0 0 22.341 22.332">
                <path id="Chat_Bubble" data-name="Chat Bubble" d="M.437,22.019A.44.44,0,0,1,0,21.455L1.45,16.523a11.009,11.009,0,1,1,9.556,5.5A10.886,10.886,0,0,1,5.113,20.31L.586,22.019a.449.449,0,0,1-.15,0Zm4.73-2.642a.44.44,0,0,1,.247.07,10,10,0,0,0,5.593,1.691,10.12,10.12,0,1,0-8.684-4.906.44.44,0,0,1,.044.352L1.106,20.856l3.911-1.48A.44.44,0,0,1,5.166,19.376Z" transform="translate(0.187 0.157)" stroke="currentColor" stroke-width="0.3" />
            </svg-custom>
        `
    )
};