import { RadarService } from "../../../../../js/services/RadarService";
import BaseButton from "../../../components/atomos/BaseButton?v=2";

const radarService = new RadarService();

/**
 * Componente responsavel por fazer a exportacoes dos dados de Sessoes RCS nos formatos texto/CSV
 *
 * @requires {@link BaseButton}
 *
 * @displayName ExportSessoesRcs
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseButton
    },
    props: {
        /**
         * Objeto que indica se deve ou n�o habilitar os bot�es de exportar
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Dados do filtro a serem submetidos
         */
        dataExport: {
            type: Object,
            required: true,
            default: function () {
                return {};
            }
        },
        /**
         * Dados da tabela
         */
        contentTable: {
            type: Object,
            required: false,
            default: function () {
                return {};
            }
        },
    },
    data() {
        return {
            disabled_text: true,
            disabled_excel: true,
            loading_text: true,
            loading_excel: true
        };
    },
    watch: {
        loading: function (ev) {
            this.exportButtonLoading(ev);
        },
        contentTable: function (ev) {
            if (ev.length == 0) {
                this.exportButtonLoading(false);
            } else {
                this.exportButtonLoading(true);
            }

            //Sempre que o dados da tabela forem atualizados, inativa o bot�o de exportar
            this.loading_text = false;
            this.loading_excel = false;
        },
        dataExport: function () {
            this.exportButtonLoading(true);
        }
    },
    methods: {

        /**
         * Ativa ou inativa o loading e desabled, dentro do bot�o de exportar
         * @param {boolean} is_loading
         */
        exportButtonLoading(is_loading = false) {

            this.loading_text = is_loading;
            this.loading_excel = is_loading;

            if (this.contentTable.length == 0) { // Se n�o houver dados ap�s o filtro, ativa o disable
                this.disabled_text = true;
                this.disabled_excel = true;
            } else { // Se houver dados ap�s o filtro, inativa o disable
                this.disabled_text = false;
                this.disabled_excel = false;
            }
        },

        /**
         * Funcao que gera o relatorio em formato texto
         */
        exportarTexto() {

            this.loading_text = true;
            this.disabled_text = true;

            // Servi�o respons�vel por gerar o relatorio em .text
            radarService
                .reportSyntheticApp(this.dataExport)
                .done((data) => {
                    downloadFile(data, "RelatorioSessoesRCS.txt", "text/plain");
                    this.loading_text = false;
                    this.disabled_text = false;
                })
                .fail(() => {
                    this.loading_text = false;
                    this.disabled_text = false;
                });
        },
        /**
         * Funcao que gera o relatorio em formato excel
         */
        exportarExcel() {

            this.loading_excel = true;
            this.disabled_excel = true;

            // Servi�o respons�vel por gerar o relatorio em .csv
            radarService
                .reportSyntheticApp(this.dataExport)
                .done((data) => {
                    downloadFile(data, "RelatorioSessoesRCS.csv");
                    this.loading_excel = false;
                    this.disabled_excel = false;
                })
                .fail(() => {
                    this.loading_excel = false;
                    this.disabled_excel = false;
                });
        }
    },
    template:
        /*html*/
        `
            <div>
                <v-row justify="end" class="mt-3">
                    <v-col cols="12" lg="1" md="2" class="text-md-right text-sm-left text-center mt-sm-0 mt-md-2">     
                        <label> Download:</label>   
                    </v-col>
                    <v-col cols="12" md="4" class="pt-0"
                        data-intro-group-novidades="true"
                        data-intro-group-tutorial="true"
                       > 
                        <v-row justify="end" v-bind:data-v-step="4">
                            <v-col cols="12" md="6" class="mb-4">
                                <base-button
                                    @click="exportarTexto()"
                                    :disabled="disabled_text"
                                    :loading="loading_text"
                                    icon="icon-btn-download"
                                    label="Texto"
                                    :block="false"
                                    width="100%"
                                    :small="true"
                                >                                    
                                    <template v-slot:loader>
                                        <span>Carregando...</span>
                                        
                                        <v-progress-linear
                                            indeterminate
                                            absolute
                                            bottom
                                            color="dark-primary"
                                            height="4"
                                        />
                                    </template>
                                </base-button> 
                            </v-col>    
                            <v-col cols="12" md="6" class="mb-4">
                                <base-button
                                    @click="exportarExcel()"
                                    :disabled="disabled_excel"
                                    :loading="loading_excel"
                                    icon="icon-btn-download"
                                    label="Excel"
                                    :block="false"
                                    width="100%"
                                    :small="true"
                                >                                    
                                    <template v-slot:loader>
                                        <span>Carregando...</span>
                                        
                                        <v-progress-linear
                                            indeterminate
                                            absolute
                                            bottom
                                            color="dark-primary"
                                            height="4"
                                        />
                                    </template>
                                </base-button>
                            </v-col>
                        </v-row>
                    </v-col>   
                </v-row>             
            </div>
    `
};
