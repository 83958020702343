import { BasePillClass } from "../../../classes/BasePillClass";
import { DraggableItemClass } from "../../../classes/DraggableItemClass";
import FieldsTemplate from "../../template-rcs/components/FieldsTemplate?v=2";

export default {
    components: {
        FieldsTemplate
    },
    data() {
        return {
            list_custom: [
                new DraggableItemClass({ id: 0.13785034155082942, text: "Nome", type: "pill", pill: new BasePillClass({ id: 0.16438698274440222, title: "Nome", subTitle: "10 caracteres", titleIcon: null, prependIcon: "fa-grip-vertical", draggable: true, editable: false, deletable: false, removable: false, rounded: true, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: 10, subTitleNewLine: false, type: null, selected: false }), custom: false }),
                new DraggableItemClass({ id: 0.3944474855595592, text: "dasd", type: "pill", pill: new BasePillClass({ id: 0.01951253383666085, title: "dasd", subTitle: "4 caracteres", titleIcon: null, prependIcon: "fa-grip-vertical", draggable: true, editable: false, deletable: false, removable: false, rounded: true, contentColor: null, backgroundColor: null, tooltipText: null, numberCharacters: 4, subTitleNewLine: false, type: null, selected: false }), custom: true })
            ]
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="3">
                    <fields-template v-model="list_custom" />
                </v-col>
            </v-row>
        `
    )
};