import DialogActions from "../../dashboard/components/dialogs/DialogActions";

export default {
    components: {
        DialogActions
    },
    data() {
        return {
            show: false,
            type: "R",
            listData: [
                {
                    id: "123456",
                    canal: "SMS",
                    campanha: "Camapanha teste",
                    cliente: "Cliente de teste",
                    status: "p"
                },
                {
                    id: "123457",
                    canal: "SMS",
                    campanha: "Camapanha teste",
                    cliente: "Cliente de teste",
                    status: "p"
                },
            ]
        };

    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="12" md="8" lg="6">
                    <v-card class="pa-5">
                        <v-row justify="center">
                            <v-col>
                                <v-select label="Tipo de modal" v-model="type" :items="[{ text: 'Reiniciadas', value: 'R' }, { text: 'Pausadas', value: 'P' }]"></v-select>
                            </v-col>
                        </v-row>
                        <v-row justify="center" no-gutters>
                            <v-col cols="auto">
                                <v-btn @click="show = true" color="dark-primary white--text" class="my-10">Abrir modal</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <dialog-actions v-model="show" :type="type" :list-data="listData"></dialog-actions>
            </v-row>
        `
    )
};