export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Baixar-Hover" transform="translate(3.929 -98)">
                    <rect id="Retângulo_352" data-name="Retângulo 352" width="28" height="28" transform="translate(-3.929 98)" fill="currentColor" opacity="0"/>
                    <g id="Grupo_1857" data-name="Grupo 1857" transform="translate(0 102)">
                        <path id="Caminho_490" data-name="Caminho 490" d="M18.8,44.9a.789.789,0,0,0-.8.8v3a2.476,2.476,0,0,1-2.5,2.5H4a2.476,2.476,0,0,1-2.5-2.5v-3a.789.789,0,0,0-.8-.8c-.5,0-.7.3-.7.8v3a4.012,4.012,0,0,0,4,4H15.5a4.012,4.012,0,0,0,4-4v-3A.713.713,0,0,0,18.8,44.9Z" transform="translate(0 -32.7)" fill="currentColor"/>
                        <path id="Caminho_491" data-name="Caminho 491" d="M8.7,46.4a1.5,1.5,0,0,0,1.1.4,1.5,1.5,0,0,0,1.1-.4l3.8-3.8a1.273,1.273,0,0,0-1.8-1.8h0A1.115,1.115,0,0,1,11,40V34a1.324,1.324,0,0,0-1.3-1.3A1.24,1.24,0,0,0,8.5,34v6a1.115,1.115,0,0,1-1.9.8h0a1.273,1.273,0,0,0-1.8,1.8Z" transform="translate(0 -32.7)" fill="currentColor"/>
                    </g>
                </g>
            </svg>      
        `
    )
};