import RouterPage from "../RouterPage";
import NewCampaignPage from "./NewCampaignPage";

export default [
    {
        path: "campaign",
        name: "campaign",
        component: RouterPage,
        children: [
            {
                path: "new",
                name: "New Campaign Page",
                component: NewCampaignPage
            }
        ]
    }
];
