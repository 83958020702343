import DialogListSessionsUsage from "./DialogListSessionsUsage";
import DialogClickDetailsUsage from "./DialogClickDetailsUsage";

export default [
    {
        path: "dialog-list-sessions-usage",
        name: "Dialog List Sessions",
        component: DialogListSessionsUsage
    },
    {
        path: "dialog-click-details-usage",
        name: "Dialog Click Details",
        component: DialogClickDetailsUsage
    }
];