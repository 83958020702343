import BaseDialog from "../../../components/moleculas/BaseDialog?v=3";
import BaseButton from "../../../components/atomos/BaseButton?v=2";
import BaseDataTable from "../../../components/organismos/BaseDataTable";
import BaseVisualCard from "../../../components/atomos/BaseVisualCard";
import { DataTableHeaderClass } from "../../../classes/DataTableHeaderClass";
import ButtonAction from "../../../components/moleculas/ButtonAction";
import { BaseService } from "../../../services/BaseService";

/**
 * Descri��o componente
 *
 * @requires {@link BaseDialog}
 * @requires {@link BaseButton}
 * @requires {@link BaseDataTable}
 * @requires {@link BaseVisualCard}
 * @requires {@link ButtonAction}
 * @requires {@link BaseService}
 * @displayName DialogListSessions
 * @category Page / Sessoes
 * @subcategory dialogs
 * @component
 * @vue
 * @vue/component
 */
export default {
    name: "dialog-list-session",
    components: {
        BaseDialog,
        BaseButton,
        BaseDataTable,
        BaseVisualCard,
        ButtonAction,
        BaseService
    },
    props: {
        /**
         * Objeto que indica se o modal deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: true,
            default: false
        },
        /**
         * Objeto contendo os IDs das campanhas
         */
        campaigns: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            dialog: this.value,
            opened_sessions: 0,
            closed_sessions: 0,
            /**
             * Configura os headers das colunas presentes na tabela
             * @returns {DataTableHeaderClass} Objeto header
             * @vue
            */
            table_headers: [
                new DataTableHeaderClass({
                    text: "Campanha",
                    value: "NM_CAMPANHA",
                    cellClass: "text-nowrap",
                    tooltipText: "Nome da campanha."
                }),
                new DataTableHeaderClass({
                    text: "Telefone",
                    value: "NR_DESTINO",
                    cellClass: "text-nowrap",
                    tooltipText: "N&uacute;mero de telefone do destinat&aacute;rio."
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("Status da sess&atilde;o"),
                    value: "STATUS",
                    cellClass: "text-nowrap",
                    tooltipText: "Situa&ccedil;&atilde;o (status) atual da mensagem."
                }),
                new DataTableHeaderClass({
                    text: "Chat",
                    value: "chat",
                    sortable: false
                }),
            ],
            custom_slots: [
                "NM_CAMPANHA",
                "NR_DESTINO",
                "STATUS",
                "chat"
            ],
            content_table: [],
            table_loading: true,
            count_total: 0,
            base_service: new BaseService()
        };
    },
    computed: {
        //verificar isso
        listItemsTable() {
            if (this.fileSelected) {
                return this.fileSelected.file_content.slice(0, 10);
            }
            return [];
        },
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog() {
            /**
             * Emite o status do dialog (false ou true)
             * @property {Boolean} dialog
             *
             * @event input
             * @vue
             */
            this.$emit("input", this.dialog);
            if (!this.dialog) {
                /**
                 * Emite um evento chamado close informando que o modal foi fechado
                 * 
                 * @event close
                 * @vue
                 */
                this.$emit("close");
            }
        },
        campaigns() {
            this.getListSessions();
        }
    },
    mounted() {
        this.getListSessions();
    },
    methods: {
        ...window.Vuex.mapActions("sessoes_rcs", ["ActionDoGetListSessions"]),
        /**
         * 
         * @vue
         */
        getListSessions() {
            if (this.campaigns.length) {
                this.ActionDoGetListSessions({ "campaigns_ids": this.campaigns }).then(listSessions => {
                    this.table_loading = false;
                    this.content_table = listSessions.data;

                    this.calculaSessoesAbertasFechadasETotal();
                });
            }
        },
        calculaSessoesAbertasFechadasETotal() {
            this.opened_sessions = 0;
            this.closed_sessions = 0;
            for (let session of this.content_table) {
                if (session.SITUACAO === "A") {
                    this.opened_sessions++;
                } else {
                    this.closed_sessions++;
                }
            }
        },
        /**
         * Mascara para telefone usando o metodo nativo do Vue maskToPhone
         * @vue
         */
        maskPhone(phone) {
            return window.Vue.maskToPhone(phone);
        },
        /**
         * Formata o Status da sessao (A = aberta / E = expirada)
         * @vue
         */
        exibirStatus(item) {
            if (item.SITUACAO === "A") {
                let date = null;
                if (!item.DT_FIM_SAES) {
                    date = dayjs(item.DT_INICIO_SAES).add(24, "hours");
                } else {
                    date = dayjs(item.DT_FIM_SAES);
                }

                return "Aberta at&eacute; " + date.format("DD/MM") + " &agrave;s " + date.format("HH") + "h";
            } else {
                return "Expirada";
            }
        },
        redirectForView(id) {
            window.location.href = this.base_service.baseDomainPhp() + "/RadarApp/chat?id=" + id;
        },
        /**
         * Redireciona para a tela de radar
         * @vue
         */
        redirectSession() {
            window.location.href = this.base_service.baseDomainPhp() + "/RadarApp?campaigns_ids=" + this.campaigns.toString();
        },
    },
    template: (
        /*html */
        `
        <base-dialog
            v-model="dialog"
            :title="'Sess&otilde;es ' + content_table.length"
            max-width="874px"
            sub-title="Quantidade total de sess&otilde;es abertas e expiradas"
            use-icon-close
        >
            <v-card elevation="0">
                <v-row justify="center" no-gutters>
                    <v-col cols="12" sm="6" class="pr-2">
                        <base-visual-card
                            icon="$chatIcon"
                            title="SESS&Otilde;ES ABERTAS"
                            :number="opened_sessions"
                            hide-percent
                        />
                    </v-col>
                    <v-col cols="12" sm="6" class="pl-2">
                        <base-visual-card
                            icon="$chatIcon"
                            title="SESS&Otilde;ES EXPIRADAS"
                            :number="closed_sessions"
                            hide-percent
                        />
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                    <v-col cols="12">
                        <base-data-table
                            :headers="table_headers"
                            :list-items="content_table"
                            :custom-slots="custom_slots"
                            :custom-columns="false"
                            :show-page-length="false"
                            :pagination="false"
                            :show-total="false"
                            item-key="ID"
                            :loading="table_loading"
                        >
                        
                                 custom_slots #valorDefinidoEmcustom_slots
                                <template #NM_CAMPANHA="props">
                                    {{ props.item.NM_CAMPANHA_CAES }}
                                </template> 
                                <template #NR_DESTINO="props">
                                    {{ maskPhone(props.item.NR_DESTINO_SAES) }}
                                </template>      
                                <template #STATUS="props">
                                    <span v-html="exibirStatus(props.item)" />
                                </template>   
                                <template #chat="props">
                                    <button-action
                                        type="chat"
                                        :size="60"
                                        :disabled="props.item.SITUACAO !== 'A'"
                                        @click="redirectForView(props.item.ID_SESSAO_APPS_MAES)"
                                    />
                                </template>                              
                            
                        </base-data-table>
                    </v-col>
                </v-row>
                <v-row no-gutters justify="end">
                    <v-col cols="auto">
                        <span class="body-2 text-primary-blue-500 cursor-pointer-hover" @click="redirectSession">Ver todas as sess&otilde;es das campanhas</span>
                    </v-col>
                </v-row>
            </v-card>
        </base-dialog>            
        `
    )
};