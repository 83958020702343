/**
 * Classe de um objeto de itens do passo-a-passo ({@link ButtonFormStep})
 */
export class FormStepClass {
    id;
    step;
    step_display
    title;
    ready;
    active;
    disabled;
    line;
    description;
    hidden;

    /**
     * Construtor da classe
     *
     * @param {String} id Identificador do botao
     * @param {Integer} step Numero do passo
     * @param {Integer} step_display Numero do passo a ser exibido
     * @param {String} title Titulo do passo
     * @param {Boolean} ready Indica que o passo esta pronto
     * @param {Boolean} active Indica que o passo esta ativo
     * @param {Boolean} disabled Indica que o passo esta desabilitado
     * @param {Boolean} line Indica que a linha deve ser adicionada ao passo
     * @param {Array} description Descricao abaixo do passo, onde cada posicao do array eh considerada uma linha
     * @param {Boolean} hidden Define o item como oculto
     */
    constructor({id = null, step, step_display, title, ready = false, active = false, disabled = false, line = false, description = [], hidden = false}) {
        this.id = id ?? Math.random();
        this.step = step;
        this.step_display = step_display;
        this.title = title;
        this.ready = ready;
        this.active = active;
        this.disabled = disabled;
        this.line = line;
        this.description = description;
        this.hidden = hidden;
    }

    /**
     * Retorna o valor de id
     *
     * @return id
     */
    get id() {
        return this.id;
    }

    /**
     * Define um novo valor para id
     *
     * @param id
     */
    set id(id) {
        this.id = id;
    }

    /**
     * Retorna o valor de step
     *
     * @return step
     */
    get step() {
        return this.step;
    }

    /**
     * Define um novo valor para step
     *
     * @param step
     */
    set step(step) {
        this.step = step;
    }

    /**
     * Retorna o valor de step_display
     *
     * @return step_display
     */
    get step_display() {
        return this.step_display;
    }

    /**
     * Define um novo valor para step_display
     *
     * @param step_display
     */
    set step_display(step_display) {
        this.step_display = step_display;
    }

    /**
     * Retorna o valor de title
     *
     * @return title
     */
    get title() {
        return this.title;
    }

    /**
     * Define um novo valor para title
     *
     * @param title
     */
    set title(title) {
        this.title = title;
    }

    /**
     * Retorna o valor de ready
     *
     * @return ready
     */
    get ready() {
        return this.ready;
    }

    /**
     * Define um novo valor para ready
     *
     * @param ready
     */
    set ready(ready) {
        this.ready = ready;
    }

    /**
     * Retorna o valor de active
     *
     * @return active
     */
    get active() {
        return this.active;
    }

    /**
     * Define um novo valor para active
     *
     * @param active
     */
    set active(active) {
        this.active = active;
    }

    /**
     * Retorna o valor de disabled
     *
     * @return disabled
     */
    get disabled() {
        return this.disabled;
    }

    /**
     * Define um novo valor para disabled
     *
     * @param disabled
     */
    set disabled(disabled) {
        this.disabled = disabled;
    }

    /**
     * Retorna o valor de line
     *
     * @return line
     */
    get line() {
        return this.line;
    }

    /**
     * Define um novo valor para line
     *
     * @param line
     */
    set line(line) {
        this.line = line;
    }

    /**
     * Retorna o valor de description
     *
     * @return description
     */
    get description() {
        return this.description;
    }

    /**
     * Define um novo valor para description
     *
     * @param description
     */
    set description(description) {
        this.description = description;
    }

    /**
     * Retorna o valor de hidden
     *
     * @return hidden
     */
    get hidden() {
        return this.hidden;
    }

    /**
     * Define um novo valor para hidden
     *
     * @param hidden
     */
    set hidden(hidden) {
        this.hidden = hidden;
    }
}