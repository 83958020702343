import icons from "../../plugins/vuetify/icons";
import colors from "../../plugins/vuetify/colors";

export default {
    name: "icons",
    data() {
        return {
            icones: icons,
            colors: [],
            color: "dark-primary",
            bg_color: "background"
        };
    },
    mounted() {
        Object.entries(colors).forEach(([parent, childrens]) => {
            this.colors.push({
                header: parent.charAt(0).toUpperCase() + parent.slice(1)
            });

            Object.entries(childrens).forEach(([key]) => {
                let name = key.replaceAll("_", " ");

                this.colors.push({
                    text: name.charAt(0).toUpperCase() + name.slice(1),
                    value: key.replaceAll("_", "-")
                });
            });

            this.colors.push({
                divider: true
            });
        });
    },
    methods: {
        returnToParent() {
            this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name });
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="12" md="8" xl="6">
                    <v-card class="pa-5">
                        <v-row>
                            <v-col>
                                <v-autocomplete label="Cor" v-model="color" :items="colors"></v-autocomplete>
                            </v-col>
                            <v-col>
                                <v-autocomplete label="Background" v-model="bg_color" :items="colors"></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row :class="'bg-' + bg_color">
                            <v-col v-for="(item, i) in icones" cols="12" sm="6" lg="4" class="my-2">
                                <v-icon x-large left :color="color">{{ '$' + i }}</v-icon> <span :class="'text-' + color">{{ '$' + i }}</span>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-btn style="bottom: 10px !important"
                    color="dark-primary"
                    fab
                    dark
                    absolute
                    center
                    right
                    @click="returnToParent"
                >
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
            </v-row>
        `
    )
};
