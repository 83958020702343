export default {
    name: "file-alert-icon",
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="29" viewBox="0 0 22 29">
                <g id="Icone_Blacklist" transform="translate(-7 0.5)">
                    <g id="Page-1" transform="translate(-168.74 -24.21)">
                        <g id="icon-78-document-error">
                            <path id="Path_606" data-name="Path 606" d="M209.24,68.19l-3-5.07V46.19l-6-7h-11a2,2,0,0,0-2,2h0v23a2,2,0,0,0,2,2h8.19l-1.18,2Zm-6-2h-1v-1h1Zm0-2h-1v-3h1Zm-3-23.5,4.7,5.5h-3.7a1,1,0,0,1-1-1Zm-11,24.5a1,1,0,0,1-1-1v-23a1,1,0,0,1,1-1h10v5a2,2,0,0,0,2,2h4V61.42l-2.5-4.23-4.73,8Z" transform="translate(-11.5 -15.48)" fill="currentColor"/>
                        </g>
                    </g>
                </g>
            </svg>
        `
    )
};