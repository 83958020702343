import styled from "../../plugins/vue-styled-components.es";

const styledDivBallonProps = { direction: ["right", "left"], variant: String};

const theme = {
    "primary": { text_color: "white", bg_color: "primary-blue" },
    "dark-primary": { text_color: "white", bg_color: "dark-primary" },
    "white": { text_color: "dark", bg_color: "white" }
};

const StyledDivBallon = styled( "div", styledDivBallonProps)` 
    background-color: var(--${props=>(props.variant && theme[props.variant]) ? theme[props.variant].bg_color : theme["white"].bg_color});
    width: 100%;
    border: 1px solid var(--light-grey);
    padding: 20px;
    color: var(--${props=>(props.variant && theme[props.variant]) ? theme[props.variant].text_color : theme["white"].text_color});
    position: relative;
    border-radius: ${props => props.direction && props.direction.toLowerCase() === "right" ? "15px 0px 15px 15px" : "0px 15px 15px 15px"};
    :before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-top: 10px solid var(--light-grey);
        border-bottom: 10px solid transparent;
        ${props => {
        let is_right = (props.direction && props.direction.toLowerCase() === "right");
        return `border-left: 10px solid ${(is_right) ? "var(--light-grey)" : "transparent"}; 
                         border-right: 10px solid ${(is_right) ? "transparent" : "var(--light-grey)"};`;
    }}       
        ${props => props.direction ? props.direction.toLowerCase() : "left"}: -21px;
        top: -1px;
    }
    :after {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-top: 10px solid var(--${props=>(props.variant && theme[props.variant]) ? theme[props.variant].bg_color : theme["white"].bg_color});
        border-bottom: 10px solid transparent;
        ${props => {
        let is_right = (props.direction && props.direction.toLowerCase() === "right");
        let color = (props.variant && theme[props.variant]) ? theme[props.variant].bg_color : theme["white"].bg_color;
        return `border-left: 10px solid ${(is_right) ? "var(--" + color + ")" : "transparent"}; 
                    border-right: 10px solid ${(is_right) ? "transparent" : "var(--" + color + ")"} ;`;
    }}   
        ${props => props.direction ? props.direction.toLowerCase() : "left"}: -19px;
        top: 0px;
    }
    
      
    .div__ballon--label{
        opacity: 0.5;
    }

    .carousel-control-prev {
        left: -29px !important;
    }
    .carousel-control-next {
        right: -29px !important;
    }
    

`;

export default StyledDivBallon;
