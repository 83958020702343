import PageHeader from "./../../../components/moleculas/PageHeader";

export default ({
    components: { PageHeader },

    data() {
        return {
            title: "Lorem Ipsum",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ultrices dui sed odio ultrices, ",
            tutorial: true
        };
    },
    template: (
    /*html*/
        `
      <v-container>
         <v-row>
            <v-col cols="4">
                <v-text-field label="Titulo do page header" v-model="title"></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-text-field label="Descricao do page header" v-model="description"></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-checkbox label="Habilita o link de tutorial" v-model="tutorial"></v-checkbox>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <page-header 
                    :title='title'
                    :description='description'
                    :tutorial="tutorial"
                ></page-header>
            </v-col>
        </v-row>
      </v-container>
    `
    )
});
