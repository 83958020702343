import CardLargeChoice from "../../../components/moleculas/CardLargeChoice";

export default ({
    components: {
        CardLargeChoice
    },
    data() {
        return {
            array_teste: [
                {
                    icon: "$simplesIcon",
                    title: "Simples",
                    type: "simples",
                    description: "Crie uma mensagem simples podendo ter campos personalizados.",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "$respostasRapidasIcon",
                    title: "Respostas r&aacute;pidas",
                    type: "respostas_rapidas",
                    description: "Crie uma mensagem com respostas r&aacute;pidas e campos personalizados.",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "$botoesIcon",
                    title: "Bot&otilde;es",
                    type: "botoes",
                    description: "Crie uma mensagem com bot&otilde;es de a&ccedil;&atilde;o e campos personalizados.",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "$completoIcon",
                    title: "Completo",
                    type: "completo",
                    description: "Crie uma mensagem utilizando todos os recursos",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                }
            ],
            columns: 6
        };
    },
    methods: {
        teste(v) {
            console.log(v);
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="6">
                                        <v-checkbox label="Cards na horizontal" v-model="single_line"></v-checkbox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Quantidade de colunas que um card deve ocupar" v-model="columns"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row justify="center" no-gutters>
                                    <v-col cols="12" md="6" >
                                        <card-large-choice v-model="array_teste" :columns="columns" @click="teste"></card-large-choice>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `)
});