import { CardSliderClass } from "../../classes/CardSliderClass?v=2";
import BaseCardSlider from "../atomos/BaseCardSlider?v=3";
import styled from "../../plugins/vue-styled-components.es";

/**
 * Coluna personalizada para atribuir propriedades de forma din�mica
 */
const ColStyled = styled("div", {
    fixed_height: Boolean,
})`
    height: ${props => (props.fixed_height ? "300px" : "auto")};
`;

/**
 * Componente de carousel personaloizado para apresentar novidades do painel
 * 
 * @displayName CarouselNews
 * @requires {@link CardSliderClass}
 * @requires {@link BaseCardSlider}
 * @component
 * @vue
 * @category Organismos
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 * @vue/component
 */
export default {
    name: "carousel-news",
    components: {
        ColStyled,
        BaseCardSlider
    },
    props: {
        /**
         * Dados para montagem do carousel
         */
        items: {
            type: Array,
            required: false,
            default: null,
            validator: function (data) {
                let is_valid = [];
                for (let type in data) {
                    if (typeof data[type].src !== "undefined" &&
                        typeof data[type].title !== "undefined" &&
                        typeof data[type].description !== "undefined" &&
                        typeof data[type].active !== "undefined"
                    ) {
                        is_valid.push(true);
                    } else {
                        is_valid.push(false);
                    }
                }
                return is_valid.indexOf(false) === -1;
            }
        },
        /**
         * Lista de cards a ser exibido no carousel
         */
        listCardSlider: {
            type: Array,
            required: false,
            default: null,
            validator: function (data) {
                for (let card of data) {
                    if (!(card instanceof CardSliderClass)) {
                        return false;
                    }
                }

                return true;
            }
        }
    },
    data () {
        return {
            /**
             * Posicao onde o slide esta 
             */
            slide_positon: 0
        };
    },
    computed: {
        array_items() {
            return this.items || this.listCardSlider;
        },
        adjusted_items: function() {
            let new_array = this.array_items;
            for (let i = 0; i < new_array.length; i++) {
                new_array[i]["active"] = false ;
            }
            new_array[0]["active"] = true;
            return new_array;
        }
    },
    watch: {
        slide_positon(new_value, old_value) {
            this.array_items[old_value].active = false;
            this.array_items[new_value].active = true;
        }
    },
    methods: {
        /**
         * Funcao que realiza a mudanca de slide
         * 
         * @param {number} new_value
         * 
         * @vue
         */
        changePosition(new_value) {
            this.slide_positon = new_value;
        },
        swipeSlide(direction) {
            switch (direction) {
            case "left":
                this.slide_positon = (this.slide_positon === 0 ? (this.array_items.length - 1) : this.slide_positon - 1);
                break;
            case "right":
                this.slide_positon = (this.slide_positon === (this.array_items.length - 1) ? 0 : this.slide_positon + 1);
                break;
            }
        }
    },
    template: (
        /*html*/
        `     
            <v-row class="justify-center my-lg-0" :class="!listCardSlider ? 'my-10' : 'my-0'" no-gutters>
                <v-col
                    cols="12"
                    :sm="!listCardSlider ? 6 : 12"
                    :lg="!listCardSlider ? 8 : 12"
                    :class="items ? 'mb-lg-10' : ''"
                >
                    <v-row justify="center">
                        <v-col cols="12" class="d-flex justify-center">
                            <v-carousel
                                :class="listCardSlider ? 'elevation-7 rounded-4' : 'elevation-24 rounded-xl'"
                                height="auto"
                                v-model="slide_positon"
                                hide-delimiter-background
                                hide-delimiters
                                cycle
                                :show-arrows="false"
                            >
                                <v-carousel-item v-if="items" v-for="(item,i) in items" :key="i">
                                    <v-img :src="item.src"></v-img>
                                </v-carousel-item>
                                <v-carousel-item v-if="listCardSlider" v-for="(card, i) in listCardSlider" :key="i" class="bg-transparent">
                                    <base-card-slider
                                        :image="card.image"
                                        :title="card.title"
                                        :text="card.text"
                                        :vertical="card.vertical"
                                    ></base-card-slider>
                                </v-carousel-item>
                            </v-carousel>     
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" v-touch="{ left: () => swipeSlide('left'), right: () => swipeSlide('right') }">
                    <v-row v-if="items" no-gutters>
                        <v-col cols="12">
                            <v-card elevation="0" height="217" class="text-center px-0 px-md-16 px-lg-0 bg-transparent">
                                <h5 class="mt-5 mt-md-0 mt-lg-15" v-if="items" v-html="items[slide_positon].title"></h5>
                                <v-row v-if="items" class="justify-lg-center  d-flex  align-center mt-2 mb-lg-15">
                                    <v-col lg="8" class="text-center">
                                        <span v-html="items[slide_positon].description"></span>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-12 gap-4" justify="center">
                        <v-col cols="auto" v-for="(item, index) in array_items">
                            <v-card
                                elevation="0"
                                width="34px"
                                height="3px"
                                @click="changePosition(index)"
                                :class="item.active || index === slide_positon ? 'bg-secondary-blue-300' : 'bg-tertiary-blue-900'"
                            ></v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row> 
        `
    )
};
