import FormSuggestionLinkUsage from "./FormSuggestionLinkUsage";
import FormSuggestionTelefoneUsage from "./FormSuggestionTelefoneUsage";
import RespostasRapidasUsage from "./RespostasRapidasUsage";
import FormSuggestionLocalizacaoUsage from "./FormSuggestionLocalizacaoUsage";
import TutorialEnableRcsUsage from "./TutorialEnableRcsUsage";
import FormSuggestionGetLocationUsage from "./FormSuggestionGetLocationUsage";
import ConfigMessageTemplateUsage from "./ConfigMessageTemplateUsage";
import ModalMensagemTesteUsage from "./ModalMensagemTesteUsage";
import AddMessageTemplateUsage from "./AddMessageTemplateUsage";
import ModalFormTesteTemplateUsage from "./ModalFormTesteTemplateUsage";
import CadastroTemplatePassoUmUsage from "./CadastroTemplatePassoUmUsage";
import CadastroTemplatePassoTresUsage from "./CadastroTemplatePassoTresUsage";
import CadastroTemplatePassoQuatroUsage from "./CadastroTemplatePassoQuatroUsage";
import DialogConfigTemplateUsage from "./DialogConfigTemplateUsage";
import CadastroTemplatePassoDoisUsage from "./CadastroTemplatePassoDoisUsage";
import ModalCreatedTemplateUsage from "./ModalCreatedTemplateUsage";
import ConfigContentUsage from "./ConfigContentUsage";
import TutorialGetCoordinatesUsage from "./TutorialGetCoordinatesUsage";
import FormAddImageUsage from "./FormAddImageUsage";
import FormAddCustomImageUsage from "./FormAddCustomImageUsage";
import ConfigPreviewUsage from "./ConfigPreviewUsage";
import DialogRemoveFallbackUsage from "./DialogRemoveFallbackUsage";
import DialogConfigFallbackUsage from "./DialogConfigFallbackUsage";
import CadastroTemplatePageUsage from "./CadastroTemplatePageUsage";

export default [
    {
        path: "form-suggestion-link-usage",
        name: "Form Suggestion Link",
        component: FormSuggestionLinkUsage
    },
    {
        path: "form-suggestion-telefone-usage",
        name: "Form Suggestion Telefone",
        component: FormSuggestionTelefoneUsage
    },
    {
        path: "respostas-rapidas-usage",
        name: "Respostas Rapidas Usage",
        component: RespostasRapidasUsage
    },
    {
        path: "form-suggestion-localizacao-usage",
        name: "FormSuggestion Localizacao Usage",
        component: FormSuggestionLocalizacaoUsage
    }, {

        path: "tutorial-enable-rcs-usage",
        name: "Tutorial Enable RCS",
        component: TutorialEnableRcsUsage
    },
    {

        path: "modal-mensagem-teste-usage",
        name: "Modal Mensagem Teste Usage",
        component: ModalMensagemTesteUsage
    },
    {
        path: "form-suggestion-get-location-usage",
        name: "Form Suggestion Get Location",
        component: FormSuggestionGetLocationUsage
    },
    {
        path: "config-message-template-usage",
        name: "Config Message Template",
        component: ConfigMessageTemplateUsage
    },
    {
        path: "add-message-template-usage",
        name: "Add Message Template",
        component: AddMessageTemplateUsage
    },
    {
        path: "modal-form-teste-template-usage",
        name: "Modal Form Teste Template",
        component: ModalFormTesteTemplateUsage
    },
    {
        path: "cadastro-template-passo-um-usage",
        name: "Cadastro de template passo um",
        component: CadastroTemplatePassoUmUsage
    },
    {
        path: "cadastro-template-passo-dois-usage",
        name: "Cadastro de template passo dois",
        component: CadastroTemplatePassoDoisUsage
    },
    {
        path: "cadastro-template-passo-tres-usage",
        name: "Cadastro de template passo tres",
        component: CadastroTemplatePassoTresUsage
    },
    {
        path: "cadastro-template-passo-tres-quatro",
        name: "Cadastro de template passo quatro",
        component: CadastroTemplatePassoQuatroUsage
    },
    {
        path: "dialog-config-template-usage",
        name: "Dialog de Configuração de Template",
        component: DialogConfigTemplateUsage
    },
    {
        path: "modal-created-template-usage",
        name: "Modal Created Template Usage",
        component: ModalCreatedTemplateUsage
    },
    {
        path: "config-content-usage",
        name: "Config Content",
        component: ConfigContentUsage
    },
    {
        path: "tutorial-get-coordinates-usage",
        name: "Tutorial get coordinates",
        component: TutorialGetCoordinatesUsage
    },
    {
        path: "form-add-image-usage",
        name: "Form Add Image",
        component: FormAddImageUsage
    },
    {
        path: "form-add-custom-image-usage",
        name: "Form Add Custom Image",
        component: FormAddCustomImageUsage
    },
    {
        path: "config-preview-usage",
        name: "Config Preview",
        component: ConfigPreviewUsage
    },
    {
        path: "dialog-remove-fallback-usage",
        name: "Dialog remove fallback",
        component: DialogRemoveFallbackUsage
    },
    {
        path: "dialog-config-fallback-usage",
        name: "Dialog config fallback",
        component: DialogConfigFallbackUsage
    },
    {
        path: "cadastro-template-page-usage",
        name: "Cadastro de template",
        component: CadastroTemplatePageUsage
    }
];