import SubHeader from "../../../components/moleculas/SubHeader";

export default {
    name: "SubHeaderUsage",
    components: { SubHeader},
    template: (
        /*html*/
        `
            <div class="row justify-center">
                <div class="col-12">
                    <sub-header class="mt-15" title="Canal SMS"></sub-header>
                </div>
            </div>
     
        `
    )
};