import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";
import BaseInformation from "../../../../components/atomos/BaseInformation?v=3";
import { BaseService } from "../../../../services/BaseService";

const base_service = new BaseService();

/**
 * Componente para configurar o envio de notificacao do relatorio finalizado
 * 
 * @requires {@link BaseTextField}
 * @requires {@link BaseDialog}
 * @requires {@link BaseInformation}
 * @requires {@link BaseService}
 * 
 * @displayName DialogNotifyReportGeneration
 * @category Page / Relatorios
 * @subcategory dialogs
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    name: "dialog-notify-report-generation",
    components: {
        BaseTextField,
        BaseDialog,
        BaseInformation
    },
    props: {
        /**
         * Indica que o dialog deve ser exibido ou nao
         */
        dialog: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * dados do relatorio
         */
        dataReport: {
            type: Object,
            required: false,
            default: null
        },
        /**
         * Titulo dentro da modal
         */
        title: {
            type: String,
            required: false,
            default: "O relat&oacute;rio est&aacute; sendo gerado em plano de fundo!"
        },
        /**
         * Descricao dentro da modal
         */
        description: {
            type: String,
            required: false,
            default: "O relat&oacute;rio ficar&aacute; dispon&iacute;vel por 30 dias na p&aacute;gina <a href='" + base_service.baseDomainPhp() + "/Relatorios' target='_blank'>Meus relat&oacute;rios</a>"
        }
    },
    data() {
        return {
            email: null,
            telefone: null,
            error: null,
            action_button: {
                "label": "Entendi",
                "variant": "dark-primary",
                "icon": "fa-check"
            },
            radio_channel: "email",
            channels_notify: {
                "email": "Email",
                "sms": "SMS",
                "no": "Empty"
            },
            email_required: true,
            sms_required: false,
            rules: {
                email: [
                    v => !!v || window.Vue.htmlEntities("Informe um email v&aacute;lido"),
                    v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || window.Vue.htmlEntities("Informe um email v&aacute;lido")
                ],
                sms: [
                    v => !!v || window.Vue.htmlEntities("Informe um telefone v&aacute;lido"),
                    v => !v || Number.isInteger(parseInt(v.match(/\d/g).join(""))) || window.Vue.htmlEntities("Informe um telefone v&aacute;lido"),
                    v => !v || (v.match(/\d/g).length === 10 || v.match(/\d/g).length === 11) || window.Vue.htmlEntities("Informe um telefone v&aacute;lido"),
                    v => !v || window.Vue.validatePhoneDDD(v) || window.Vue.htmlEntities("Informe um telefone v&aacute;lido")
                ]
            }
        };
    },
    computed: {
        emailRules() {
            return this.radio_channel === "email" ? this.rules.email : [];
        },
        smsRules() {
            return this.radio_channel === "sms" ? this.rules.sms : [];
        }
    },
    watch: {
        radio_channel() {
            this.checkChoice();
        }
    },
    mounted() {
        this.fillInputMail();
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetUserInfo"]),
        /**
         * Fecha a modal
         * @vue
         */
        closeDialog() {
            /**
             * Emite um evento "close" informando que a modal deve ser encerrada
             * 
             * @event close
             * @vue
             */
            this.$emit("close", true);
        },
        checkChoice() {
            this.email_required = false;
            this.sms_required = false;

            switch (this.radio_channel) {
            case "email":
                this.email_required = true;
                break;
            case "sms":
                this.sms_required = true;
                break;
            }
        },
        /**
         * Retorna a mascara de telefone para aplicar ao campo de acordo com a quantidade de caracteres inseridos
         * 
         * @returns {String} Mascara de telefone
         * @vue
         */
        maskFone() {
            if (this.telefone) {
                return this.telefone.length == 15 ? "(##) #####-####" : "(##) ####-#####";
            } else {
                return "(##) #####-####";
            }
        },
        /**
         * Acao da modal
         * @vue
         */
        actionDialog() {
            this.error = null;

            if (this.$refs.form_notify.validate()) {
                let send_to = null;

                if (this.radio_channel === "email") {
                    send_to = this.email;
                } else if (this.radio_channel === "sms") {
                    send_to = this.telefone.replace(/\D+/g, "");
                }
                let notification = {
                    notification_type: this.radio_channel,
                    notification_to: send_to,
                    ...this.dataReport
                };

                /**
                 * Emite um evento "input" que altera o valor de v-model
                 * 
                 * @property {Object} notification Dados preenchidos:
                 * <pre>{
                 *     notification_type: String - Canal selecionado para envio (sms ou email)
                 *     notification_to: String - Destino da notificacao, um numero de telefone ou um email
                 * }</pre>
                 * 
                 * @event input
                 * @vue
                 */
                this.$emit("input", notification);
                /**
                 * Evento de "actionDialog" do botao
                 * @event actionDialog
                 * @vue
                 */
                this.$emit("actionDialog");

                this.closeDialog();
            }
        },
        /**
         * Funcao para realizar o preenchimento do input de email do formulario
         * @vue
         */
        fillInputMail() {
            this.ActionDoGetUserInfo().then((response) => {
                this.email = response.DS_LOGIN_USES;
            }).catch((error) => {
                console.log(error);
            });
        }
    },
    template: (
        /*html*/
        `
            <base-dialog
                v-model="dialog"
                :action-button="action_button"
                :use-icon-close="true"
                :cancel="false"
                @clickDialog="actionDialog"
                @closeDialog="closeDialog"
                @clickOutside="closeDialog"
            >
                <v-card elevation="0">
                    <v-row justify="center">
                        <v-col cols="auto">
                            <v-img src="/images/reports/report-gif.gif" contain max-width="80"></v-img>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="auto">
                            <span class="dark-primary--text font-size-12 bold" v-html="title"></span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <base-information
                                :description="description"
                                border-color="border-dark-primary"
                                icon-color="dark-primary"
                            ></base-information>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            Escolha uma op&ccedil;&atilde;o para receber a notifica&ccedil;&atilde;o quando seu relat&oacute;rio estiver pronto.
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-form ref="form_notify">
                                <v-radio-group v-model="radio_channel">
                                    <div v-for="(channel, key) in channels_notify" :key="key" class="mb-3">
                                        <v-radio
                                            :label="channel"
                                            :value="key"
                                            color="dark-primary"
                                            class="mb-0"
                                        >
                                            <template #label>
                                                <span v-if="key === 'email'" class="font-size-12">Receber notifica&ccedil;&atilde;o por email</span>
                                                <span v-else-if="key === 'sms'" class="font-size-12">Receber notifica&ccedil;&atilde;o por mensagem de texto</span>
                                                <span v-else class="font-size-12">N&atilde;o quero ser notificado</span>
                                            </template>
                                        </v-radio>
                                        <base-text-field
                                            v-if="radio_channel === 'email' && key === 'email'"
                                            label="Email"
                                            type="email"
                                            :rules="emailRules"
                                            v-model="email"
                                            :required="email_required"
                                            @enterPressed="actionDialog"
                                        ></base-text-field>
                                        <base-text-field
                                            v-if="radio_channel === 'sms' && key === 'sms'"
                                            label="Telefone"
                                            v-mask="maskFone()"
                                            type="tel"
                                            :rules="smsRules"
                                            v-model="telefone"
                                            :required="sms_required"
                                            @enterPressed="actionDialog"
                                        ></base-text-field>
                                    </div>
                                </v-radio-group>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-card>
            </base-dialog>
        `
    )
};