import BaseTextField from "./BaseTextField";

/**
 * Componente basico de time picker
 * 
 * @requires {@link BaseTextField}
 * 
 * @displayName BaseTimePicker
 * @component
 * @vue
 * @category Atomos
 * @author David dos Santos Andrade <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTextField
    },
    props: {
        /**
         * Label do input
         */
        label: {
            type: String,
            required: false,
            default: "DD/MM/YYYY"
        },
        /**
         * Indica se fechara o timepicker ao ser selecionado
         */
        closeOnClick: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Regras do input
         */
        rules: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Horario minimo no formato HH:mm
         */
        minTime: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Horario maximo no formato HH:mm
         */
        maxTime: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Valor do input 
         */
        value: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Define se o campo e obrigatorio
         */
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Verifica se e utilizado icone na validacao
         */
        useIconValidation: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Titulo
         */
        title: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Texto do hint
         */
        hint: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Classe css do tooltip
         */
        contentClassTooltip: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Cor do tooltip
         */
        colorTooltip: {
            type: String,
            required: false,
            default: "gray-700"
        },
        /**
         * Conteudo do poppover
         */
        popoverContent: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Texto do tooltip
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Desabilita o datepicker
         */
        disablePicker: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Adiciona o icone de limpar o campo
         */
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Adiciona name ao input
         */
        name: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * ID Time Picker
         */
        id: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            time: "",
            menu: false,
            time_input: dayjs().format("HH:mm"),
            new_rules: this.rules
        };
    },
    watch: {
        time_input() {
            /**
             * Evento que informa qual horario esta selecionado
             * 
             * @property {string} time_input Conteudo do campo
             * 
             * @event input
             * @vue
             */
            this.$emit("input", this.time_input);
        },
        maxTime(val) {
            if (!val || val.length === 0 ) {
                this.new_rules = this.rules;
            } else {
                this.addValidateMaxTime();
            }
        },
        minTime(val) {
            if (!val || val.length === 0 ) {
                this.new_rules = this.rules;
            } else {
                this.addValidateMinTime();
            }
        },
        value() {
            if ((this.value && this.value.length === 5) || this.value === null) {
                this.time = this.value;
            }
        },
        time() {
            this.time_input = this.time;
        }
    },
    mounted() {
        this.time = this.value;
        if (this.maxTime) {
            this.addValidateMaxTime();
        }
        if (this.minTime) {
            this.addValidateMinTime();
        }
    },
    methods: {
        /**
         * Adiciona validacao de tempo maximo no input
         */
        addValidateMaxTime() {
            if (this.maxTime.length === 5) {
                this.new_rules.push((value) => {
                    if (dayjs("2023-01-01 " + value).isAfter(dayjs("2023-01-01 " + this.maxTime))) {
                        return window.Vue.htmlEntities("O hor&aacute;rio deve ser menor ou igual &agrave; " + this.maxTime);
                    }

                    return true;
                });
            }
        },
        /**
         * Adiciona validacao de tempo minimo no input
         */
        addValidateMinTime() {
            if (this.minTime.length) {
                this.new_rules.push((value) => {
                    if (!value) {
                        return false;
                    }

                    if (dayjs("2023-01-01 " + value).isBefore(dayjs("2023-01-01 " + this.minTime))) {
                        return window.Vue.htmlEntities("O hor&aacute;rio deve ser maior ou igual &agrave; " + this.minTime);
                    }

                    return true;
                });
            }
        },
        /**
         * Funcao para atualizar o date
         * 
         * @param {String} value 
         */
        functionBlur(value) {
            this.date = window.Vue.formatDateBrasilToUsa(value);
        }
    },
    template:
        /*html*/
        `
        <div>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="closeOnClick"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
                <template #activator="{ on, attrs }">
                    <base-text-field
                        v-model="time_input"
                        :label="label"
                        :rules="new_rules"
                        :required="required"
                        :use-icon-validation="useIconValidation"
                        :title="title"
                        :hint="hint"
                        :content-class-tooltip="contentClassTooltip"
                        :color-tooltip="colorTooltip"
                        :popover-content="popoverContent"
                        :tooltip-text="tooltipText"
                        :bind-personal="attrs"
                        :readonly="!disablePicker"
                        :on-personal="on"
                        :clearable="clearable"
                        :name="name"
                        mask="##:##"
                        @blur="functionBlur"
                        :id="id"
                    />
                </template>
                <v-time-picker
                    v-if="!disablePicker"
                    v-model="time"
                    full-width
                    scrollable
                    header-color="primary-blue-500"
                    :min="minTime"
                    :max="maxTime"
                    format="24hr"
                />
            </v-menu>
        </div>
        `
};