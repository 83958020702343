import BaseRadioButtons from "../../../components/moleculas/BaseRadioButtons";

export default {
    components: {
        BaseRadioButtons
    },
    data() {
        return {
            list_button: [
                {
                    id: 1,
                    active: true,
                    icon: "fa-globe-americas",
                    label: "Link",
                    disabled: false,
                    soon: false,
                    vertical: false
                },
                {
                    id: 2,
                    icon: "fa-phone-alt",
                    label: "Telefone",
                    active: false,
                    disabled: false,
                    soon: false,
                    vertical: false
                },
                {
                    id: 3,
                    icon: "fa-map-marker-alt",
                    label: "Localizacao",
                    active: false,
                    disabled: false,
                    soon: false,
                    vertical: false
                },
                {
                    id: 4,
                    icon: "fa-crosshairs",
                    label: "Solicitar localizacao",
                    active: false,
                    disabled: false,
                    soon: false,
                    vertical: false,
                    cols: "auto"
                }
            ]
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center" class="mt-16">
                <v-col cols="auto">
                    <base-radio-buttons v-model="list_button"></base-radio-buttons>
                </v-col>
            </v-row>
        `
    )
};