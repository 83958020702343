export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Desativar-Enabled" transform="translate(3.667 -41)">
                    <rect id="Retângulo_363" data-name="Retângulo 363" width="28" height="28" transform="translate(-3.667 41)" fill="currentColor" opacity="0"/>
                    <g id="Grupo_2120" data-name="Grupo 2120" transform="translate(0.333 45)">
                        <path id="Caminho_529" data-name="Caminho 529" d="M9.8,34a9.8,9.8,0,1,0,9.8,9.8A9.816,9.816,0,0,0,9.8,34Zm0,18a8.3,8.3,0,1,1,8.3-8.3A8.344,8.344,0,0,1,9.8,52Z" transform="translate(0 -34)" fill="currentColor"/>
                        <path id="Caminho_530" data-name="Caminho 530" d="M6.6,39.6a.778.778,0,0,0-1.1,1.1l7.25,7.25a.743.743,0,0,0,1.093-.007.776.776,0,0,0,.007-1.093Z" transform="translate(-28.086 -14.331) rotate(-45)" fill="currentColor"/>
                    </g>
                </g>
            </svg>
        `
    )
};