import BaseImagem from "../../../../vue-app/src/components/atomos/BaseImagem";
import { SugestionPreview } from "../../../../vue-app/src/components/moleculas/SugestionPreview";

/**
 * Componente de Card RCS
 * 
 * @displayName CardRcs
 * @component
 * @category Moleculas
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @vue
 */
const CardRcs = {
    name: "card-rcs",
    props: {
        /**
         * titulo do card
         */
        title: {
            type: String,
            required: false
        },
        /**
         * descricao do card
         */
        description: {
            type: String,
            required: false
        },
        /**
         * tamanho da imagem
         * @values small, normal, large
         */
        height: {
            type: ["MEDIUM", "TALL", "SHORT"],
            required: true
        },
        /**
         * url da imagem do card
         */
        image: {
            type: String,
            required: true
        },
        /**
         * Numero do card
         */
        number: {
            type: Number,
            required: false
        },
        /**
         * Array com as sugestions
         */
        suggestions: {
            type: Array,
            required: false,
            default: []
        }
    },
    computed: {
        is_source_empty: function() {
            return isEmpty(this.src);
        }
    },
    data: function() {
        return {
            image_error: false,
            hovered_image: false
        };
    },
    components: {
        "base-imagem": BaseImagem,
        "suggestion-preview": SugestionPreview
    },
    template:
        /*html*/
        `
           <v-container class="px-0 " style="width: 16rem;">
                <v-row class="mx-0 justify-content-center card-child" >
                   <v-col cols="12" class="border border-light-grey rounded pb-3 bg-white">
                        <v-row class="border-bottom border-light-grey bg-extradark-grey">
                            <v-col cols="12" class=" p-0 position-relative">
                                <base-imagem :open-modal="false" :src="image"/>                       
                                <div v-if="number" :class="{'d-none' : number == null}" class="numero bg-white rounded ml-2 mt-2 px-3 py-2 border" style="position: absolute; top:-10px; left: 0; z-index: 1000;">{{number}}</div>
                            </v-col>
                        </v-row>
                        <v-row class="row border-bottom border-light-grey pt-2 pb-2 bg-dark-grey align-items-center">
                            <v-col cols="12" v-b-tooltip.hover :title="image" class="col-12 py-1 text-left text-truncate">
                                <a class="h6 font-size-12 text-white  text-truncate text-decoration-none"   :href="image" target="_blank">{{image}}</a>
                            </v-col>
                        </v-row>
                        <v-row class="py-2 border-bottom text-white border-light-grey mb-3 bg-dark-grey align-items-center">
                            <v-col cols="1"><i class="fas fa-image fa-lg"></i></v-col>
                            <v-col cols="10" class="font-size-12 text-left">Tamanho: <b>{{height}}</b></v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" v-if="title" class="overflow-wrap text-left  border-bottom">
                                <p class="h6">{{title}}</p>
                            </v-col>
                        </v-row>
                        <v-row class="my-2">
                            <v-col cols="12" v-if="description" class="overflow-wrap text-left"><span>{{description}}</span></v-col>
                        </v-row>                    
                        <v-row v-if="suggestions.length > 0">
                                <v-col cols="12" class="px-0" v-for="({label, value, type}, index) in suggestions" :key="index" :class="{'border-top': index > 0 }">
                                    <suggestion-preview  :label="label" :value ="value" :type="type" :bordered="false"></suggestion-preview>
                                </v-col>
                        </v-row>
                   </v-col>
                </v-row>
            </div>  
        `
};

export default CardRcs;
