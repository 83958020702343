export default {
    name: "components-usage",
    computed: {
        list_child_routes() {
            if (this.$route.name === "documentacao") {
                let matched = this.$route.matched;
                let routePath = matched[matched.length - 1].path;
                return this.getChildrenByPath(routePath);
            } else {
                return [];
            }
        }
    },
    methods: {
        getChildrenByPath(path) {
            let children = this.$router.options.routes;

            if (path) {
                let chunks = path.split("/");
                chunks = chunks.filter((chunk) => chunk !== "");

                if (chunks.length) {
                    chunks.forEach((chunk, index) => {
                        let path = chunk;
                        if (index === 0) {
                            path = `/${path}`;
                        }

                        if (children) {
                            const found = children.find((child) => child.path === path);
                            if (found) {
                                children = found.children;
                            }
                        }
                    });
                }
            }

            return children;
        },
        getCustomIcon(name) {
            switch (name) {
            case "Atomos":
                return "mdi-atom";
            case "Moleculas":
                return "mdi-molecule";
            case "Organismos":
                return "mdi-virus";
            default:
                return "fa-link";
            }
        }
    },
    template: (
        /*html*/
        `
        <div class="fill-height">
            <v-card tile v-if="list_child_routes.length > 0">
                <v-list>
                    <v-subheader>Documenta&ccedil;&atilde;o</v-subheader>
                    <v-list-item-group>
                        <v-list-item v-for="(item, i) in list_child_routes" :key="i" :to="{ name: item.name }">
                            <v-list-item-icon>
                                <v-icon>{{ getCustomIcon(item.name) }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                                <v-list-item-subtitle>{{ item.children !== undefined ? (item.children.length + ' item' + (item.children.length > 1 ? 's' : '')) : 'Clique para ver o conteudo' }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
            <router-view></router-view>
        </div>
        `
    )
};
