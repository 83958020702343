import PageHeader from "../../components/moleculas/PageHeader?v=2";
import ProgressCard from "../../components/moleculas/ProgressCard?v=2";
import PgTour from "../../components/organismos/PgTour?v=2";
import DialogRedirect from "./components/dialogs/DialogRedirect";
import DialogDone from "./components/dialogs/DialogDone";
import DialogActions from "./components/dialogs/DialogActions?v=2";
import DialogFail from "./components/dialogs/DialogFail";
import BaseDataTable from "../../components/organismos/BaseDataTable?v=2";
import BaseButton from "../../components/atomos/BaseButton?v=2";
import FormFilter from "./components/forms/FormFilter?v=2";
import BaseProgressBar from "../../components/atomos/BaseProgressBar?v=2";
import StatusCampaign from "../../components/moleculas/StatusCampaign?v=2";
import CanalIconDescription from "../../components/moleculas/CanalIconDescription?v=2";
import { DataTableHeaderClass } from "../../classes/DataTableHeaderClass";
import { ProgressBarValueClass } from "../../classes/ProgressBarValueClass";
import CardContentClass from "../../classes/CardContentClass";
import { BaseService } from "../../services/BaseService";
import ButtonAction from "../../components/moleculas/ButtonAction?v=2";
import DialogCampaignDetails from "../../components/organismos/DialogCampaignDetails?v=2";
import BaseDialog from "../../components/moleculas/BaseDialog";
import DialogClickDetails from "../sessoes_rcs/components/DialogClickDetails";
import DialogListSessions from "../sessoes_rcs/components/DialogListSessions";

const base_service = new BaseService();

/**
 * Pagina da Dashboard
 * 
 * @required {@link PageHeader}
 * @required {@link ProgressCard}
 * @required {@link PgTour}
 * @required {@link DialogRedirect}
 * @required {@link DialogDone}
 * @required {@link DialogActions}
 * @required {@link DialogFail}
 * @required {@link BaseDataTable}
 * @required {@link BaseButton}
 * @required {@link FormFilter}
 * @required {@link BaseProgressBar}
 * @required {@link StatusCampaign}
 * @required {@link CanalIconDescription}
 * @required {@link DataTableHeaderClass}
 * @required {@link ProgressBarValueClass}
 * @required {@link CardContentClass}
 * @required {@link BaseService}
 * @required {@link ButtonAction}
 * @required {@link DialogCampaignDetails}
 * @required {@link BaseDialog}
 * @required {@link DialogClickDetails}
 * @required {@link DialogListSessions}
 * 
 * @displayName DashboardPage
 * @category Page / Dashboard
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PageHeader,
        ProgressCard,
        PgTour,
        DialogRedirect,
        DialogDone,
        DialogActions,
        DialogFail,
        BaseDataTable,
        BaseButton,
        FormFilter,
        BaseProgressBar,
        StatusCampaign,
        CanalIconDescription,
        ButtonAction,
        DialogCampaignDetails,
        BaseDialog,
        DialogClickDetails,
        DialogListSessions
    },
    filters: {
        format_date: function (date) {
            if (date && date != "0000-00-00 00:00") {
                let formatDate = moment(date).format("DD/MM HH:mm");
                return formatDate == "Invalid date" ? "-" : formatDate;
            } else {
                return "-";
            }
        },
        filter_amount: function (value) {
            if (value) {
                return value;
            } else {
                return value || "-";
            }
        }
    },
    data() {
        return {
            user_info: null,
            type_product: this.$cookie === undefined ? getCookie("type_product") : this.$cookie.get("type_product"),
            form_data: {
                campaigns_id: [],
                clients: [],
                status: []
            },
            custom_slots: ["NM_PRODURO_DPES", "CD_STATUS_DPES", "DT_INICIO", "DT_REINICIO", "DT_FIM", "QT_RADAR_DPES", "QT_CANCELADOS_DPES", "enviados", "entregues", "actions", "fallback"],
            campaigns_list: [],
            campaigns_selecteds: [],
            ruleDisableCheckboxItem: (item) => {
                if (!this.canCancelCampaign(item) && !this.canPauseCampaign(item) && !this.canRestartCampaign(item) && !this.canEscalonarCampaign(item)) {
                    return true;
                } else {
                    return false;
                }
            },
            table_loading: false,
            list_status_count: [],
            cards_loading: false,
            show_dialog_redirect: false,
            dialog_redirect_data: {
                type: "E",
                campaign_name: "",
                campaigns_ids: []
            },
            show_dialog_actions: false,
            dialog_actions_data: {
                type: "P",
                list_data: [],
            },
            show_dialog_done: false,
            dialog_done_data: {
                type: "P",
                list_data: [],
            },
            show_dialog_fail: false,
            dialog_fail_data: "",
            disable_bulk_pause_button: true,
            disable_bulk_restart_button: true,
            disable_bulk_cancel_button: true,
            disable_bulk_escalonar_button: true,
            steps_tour: [
                {
                    target: "[data-v-step='1']",
                    title: "Filtros",
                    content: "Voc&ecirc; pode refinar as informa&ccedil;&otilde;es exibidas na tela de acordo com sua necessidade.",
                    before: () => {
                        document.querySelector("#filter_slider").click();
                    },
                    date: "2022-10-07 00:00:00"
                },
                {
                    target: "[data-v-step='2']",
                    title: "Informa&ccedil;&otilde;es de envio",
                    content: "Acompanhe as informa&ccedil;&otilde;es dispon&iacute;veis da sua campanha em tempo real.",
                },
                {
                    target: "[data-v-step='3']",
                    title: "A&ccedil;&otilde;es em multiplas campanhas",
                    content: "<p>Pause, cancele, reinicie suas campanhas a qualquer momento. Voc&ecirc; tamb&eacute;m pode dividir em lotes se precisar.</p><p>Selecione uma ou mais campanhas para as a&ccedil;&otilde;es e atente-se com as regras de cada a&ccedil;&atilde;o passando o mouse sobre o bot&atilde;o.</p>",
                },
                {
                    target: ".tableInputsSection",
                    title: "Colunas personalizadas e busca r&aacute;pida",
                    content: "<p>Personalize a maneira de visualizar a listagem das suas campanhas.</p><p>Voc&ecirc; tamb&eacute;m pode fazer uma busca r&aacute;pida.</p>",
                },
                {
                    target: "thead.v-data-table-header",
                    title: "Tabela din&acirc;mica",
                    content: "<p>Escolha como deseja ordenar sua tabela clicando nas setas dispon&iacute;veis ao lado de cada t&iacute;tulo.</p><p>Voc&ecirc; tamb&eacute;m pode selecionar multiplas campanhas atrav&eacute;s do checkbox dispon&iacute;vel na primeira coluna.</p>",
                }
            ],
            show_dialog_details: false,
            campaign_id: null,
            dialog_action_config: {
                show: false,
                title: "",
                description: "",
                link_text: "",
                information: "",
                form_action: "",
                show_lotes: false,
                action: () => {},
                button: {
                    label: "",
                    variant: "",
                    loading: false,
                    icon: ""
                }
            },
            selected_lotes: [],
            campaign_details: null,
            campaignId: null,
            headers_table: [
                new DataTableHeaderClass({text: "Lotes", value: "lote", sortable: false})
            ],
            load_content: false,
            show_click_details: false,
            clicks: {
                link: 0,
                phone: 0,
                map: 0,
                requestLocation: 0
            },
            show_list_sessions: false,
            list_campaigns_ids: []
        };
    },
    computed: {
        pageDescription() {
            let user_name = this.user_info && this.user_info.NM_USUARIO_USES !== undefined ? this.user_info.NM_USUARIO_USES : "";
            return `Ol&aacute; <b>${user_name}</b>, essas s&atilde;o suas campanhas do dia. Veja todas as campanhas em Gerenciar na aba Jobs.`;
        },
        cardImportedData() {
            let total_importados = parseInt(this.list_status_count.processados) + parseInt(this.list_status_count.bloqueados);

            return {
                title: "Importados",
                total: total_importados,
                tooltip_text: "Total de registros nos arquivos importados para cria&ccedil;&atilde;o das campanhas.",
                disable_tooltip: false,
                list_content: [
                    new CardContentClass({
                        name: "Higienizados",
                        divisor: total_importados,
                        dividendo: this.list_status_count.bloqueados,
                        tooltip: "Na higieniza&ccedil;&atilde;o, s&atilde;o removidos os registros inv&aacute;lidos, repetidos e bloqueados pela blacklist.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Processados",
                        divisor: total_importados,
                        dividendo: this.list_status_count.processados,
                        tooltip: "Apenas os registros v&aacute;lidos e permitidos s&atilde;o processados para envio.",
                        disable_tooltip: false
                    })
                ]
            };
        },
        cardSanitizedData() {
            return {
                title: "Higienizados",
                total: this.list_status_count.bloqueados,
                tooltip_text: "Total de registros que foram higienizados (descartados) para a campanha.",
                disable_tooltip: false,
                list_content: [
                    new CardContentClass({
                        name: "Inv&aacute;lidos",
                        divisor: this.list_status_count.bloqueados,
                        dividendo: this.list_status_count.invalidos,
                        tooltip: "Registros que foram descartados por n&atilde;o estarem de acordo com os requisitos de um n&uacute;mero de telefone v&aacute;lido.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Blacklist",
                        divisor: this.list_status_count.bloqueados,
                        dividendo: this.list_status_count.blacklist,
                        tooltip: "Registros bloqueados pela blacklist.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Repetidos",
                        divisor: this.list_status_count.bloqueados,
                        dividendo: this.list_status_count.repetidos,
                        tooltip: "N&uacute;meros de telefone que est&atilde;o repetidos no arquivo que foi processado.",
                        disable_tooltip: false
                    })
                ]
            };
        },
        cardProcessedData() {
            return {
                title: "Processados",
                total: this.list_status_count.processados,
                tooltip_text: "Total de registros que foram processados para envio",
                disable_tooltip: false,
                list_content: [
                    new CardContentClass({
                        name: "Enviados",
                        divisor: this.list_status_count.processados,
                        dividendo: this.list_status_count.enviados,
                        tooltip: "Voc&ecirc; tem acesso ao andamento dos envios em tempo real.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "N&atilde;o enviados",
                        divisor: this.list_status_count.processados,
                        dividendo: this.list_status_count.nao_enviados,
                        tooltip: "Voc&ecirc; tem acesso ao andamento dos envios em tempo real.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Aguardando envio",
                        divisor: this.list_status_count.processados,
                        dividendo: this.list_status_count.aguardando,
                        tooltip: "Os registros ir&atilde;o aguardar o envio at&eacute; a data e hora agendada, ou de acordo com regras de hor&aacute;rios que podem ser cadastradas por cliente (carteira).",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Cancelados",
                        divisor: this.list_status_count.processados,
                        dividendo: this.list_status_count.cancelados,
                        tooltip: "Alguns registros podem ser cancelados por regras de neg&oacute;cio ou manualmente.",
                        disable_tooltip: false
                    })
                ]
            };
        },
        cardSendData() {
            let total = this.list_status_count.enviados;
            let list_content = [
                new CardContentClass({
                    name: "Entregues",
                    divisor: total,
                    dividendo: this.list_status_count.entregues,
                    tooltip: "Mensagens com entrega confirmada ao destinat&aacute;rio",
                    disable_tooltip: false
                }),
                new CardContentClass({
                    name: "N&atilde;o Entregues",
                    divisor: total,
                    dividendo: this.list_status_count.nao_entregues + this.list_status_count.total_fallback,
                    tooltip: "Mensagens que n&atilde;o foram entregues ao destinat&aacute;rio. Isso pode acontecer se o aparelho estiver desligado, fora da &aacute;rea de servi&ccedil;o ou se o n&uacute;mero estiver inativo.",
                    disable_tooltip: false
                }),
                new CardContentClass({
                    name: "Aguardando retorno",
                    divisor: total,
                    dividendo: this.list_status_count.sem_confirmacao,
                    tooltip: "Mensagens enviadas e sem retorno do status de entregue ou n&atilde;o entregue. Essa informa&ccedil;&atilde;o pode levar at&eacute; 24h para retornar e nesse per&iacute;odo o status fica como aguardando retorno.",
                    disable_tooltip: false
                })
            ];

            return {
                title: "Enviados " + this.type_product,
                total: total,
                tooltip_text: "Total de registros que foram enviados &agrave;s operadoras / fornecedores",
                disable_tooltip: false,
                list_content: list_content
            };
        },
        cardDeliveredData() {
            let list_content = [
                new CardContentClass({
                    name: "Visualizados",
                    divisor: this.list_status_count.entregues,
                    dividendo: this.list_status_count.visualizados,
                    tooltip: "Quantidade total de visualiza&ccedil;&otilde;es nas mensagens entregues aos destinat&aacute;rios",
                    disable_tooltip: false
                }),
                new CardContentClass({
                    name: "Clicados",
                    item_click: "clicks",
                    divisor: this.list_status_count.entregues,
                    dividendo: this.list_status_count.clicados,
                    tooltip: "Quantidade Total de clicados. Veja em quais bot&otilde;es o destinat&aacute;rio clicou na mensagem recebida, acesse para ver mais detalhes",
                    disable_tooltip: false,
                    hide_percent: false
                }),
                new CardContentClass({
                    name: "Sess&otilde;es",
                    item_click: "sessions",
                    divisor: this.list_status_count.entregues,
                    dividendo: this.list_status_count.sessoes_rcs,
                    tooltip: "Quantidade total de sess&otilde;es abertas e expiradas, acesse para ver mais detalhes",
                    disable_tooltip: false,
                    hide_percent: false
                })
            ];

            return {
                title: "Entregues " + this.type_product,
                total: this.list_status_count.entregues,
                tooltip_text: "Total de registros que foram entregues",
                disable_tooltip: false,
                list_content: list_content
            };
        },
        cardFallbackData() {
            let list_content = [
                new CardContentClass({
                    name: "Entregues",
                    divisor: this.list_status_count.total_fallback,
                    dividendo: this.list_status_count.entregue_fallback,
                    tooltip: "Mensagens com entrega confirmada ao destinat&aacute;rio",
                    disable_tooltip: false
                }),
                new CardContentClass({
                    name: "N&atilde;o entregues",
                    divisor: this.list_status_count.total_fallback,
                    dividendo: this.list_status_count.nao_entregue_fallback,
                    tooltip: "Mensagens que n&atilde;o foram entregues ao destinat&aacute;rio. Isso pode acontecer se o aparelho estiver desligado, fora da &aacute;rea de servi&ccedil;o ou se o n&uacute;mero estiver inativo.",
                    disable_tooltip: false
                }),
                new CardContentClass({
                    name: "Aguardando retorno",
                    divisor: this.list_status_count.total_fallback,
                    dividendo: this.list_status_count.total_fallback - this.list_status_count.entregue_fallback - this.list_status_count.nao_entregue_fallback,
                    tooltip: "Mensagens enviadas e sem retorno do status de entregue ou n&atilde;o entregue. Essa informa&ccedil;&atilde;o pode levar at&eacute; 24h para retornar e nesse per&iacute;odo o status fica como aguardando retorno.",
                    disable_tooltip: false
                })
            ];

            return {
                title: "SMS Alternativo",
                total: this.list_status_count.total_fallback,
                tooltip_text: "",
                disable_tooltip: true,
                list_content: list_content
            };
        },
        cardResponseData() {
            let total_entregues = (this.list_status_count.entregues_fallback || 0) + (this.list_status_count.entregues || 0);

            return {
                title: this.type_product === "RCS" ? "Sess&otilde;es RCS" : "Radar",
                total: this.list_status_count.retornos,
                tooltip_text: "Total de respostas recebidas sobre as mensagens que foram entregues",
                disable_tooltip: false,
                link_text: this.list_status_count.retornos > 0 ? "Ver retornos" : null,
                link_callback: () => {
                    if (this.type_product === "RCS") {
                        $.doGet("RadarApp", {
                            data_inicio: dayjs().format("YYYY-MM-DD"),
                            data_fim: dayjs().format("YYYY-MM-DD")
                        });
                    } else {
                        $.doPost("list-radar.php", {
                            sh_dtini: dayjs().format("DD/MM/YYYY"),
                            sh_dtfim: dayjs().format("DD/MM/YYYY")
                        });
                    }
                },
                list_content: [
                    new CardContentClass({
                        name: "Retornos sobre entregues",
                        divisor: total_entregues,
                        dividendo: this.list_status_count.retornos,
                        tooltip: "Mensagens que foram recebidas pelos destinat&aacute;rios.<br><br>Clique sobre a quantidade de retornos e veja os detalhes das mensagens e os status atual da sess&atilde;o.",
                        disable_tooltip: false
                    })
                ]
            };
        },
        table_headers() {
            let headers = [
                new DataTableHeaderClass({ text: "Id", value: "ID_CAMPANHA_DPES", tooltipText: "O identificador &eacute; um c&oacute;digo gerado automaticamente e pode ser utilizado para integra&ccedil;&otilde;es via API" }),
                new DataTableHeaderClass({ text: "Canal", value: "NM_PRODURO_DPES", tooltipText: "Canal utilizado para disparo da mensagem.", sortable: false, visibility: "xl" }),
                new DataTableHeaderClass({ text: "Cliente", value: "NM_CARTEIRA_DPES", tooltipText: "Este &eacute; o cliente (carteira) para quem foi feita a campanha", disabled: true, filterable: true, visibility: "xl" }),
                new DataTableHeaderClass({ text: "Campanha", value: "NM_CAMPANHA_DPES", tooltipText: "O nome da campanha pode ser gerado pelo arquivo importado ou via API", disabled: true, className: "text-nowrap", cellClass: "break-word", filterable: true }),
                new DataTableHeaderClass({ text: "Status", value: "CD_STATUS_DPES", tooltipText: "Situa&ccedil;&atilde;o (status) atual da mensagem", sortable: false, className: "text-nowrap", width: "150px", cellClass: "text-nowrap", visibility: "md" }),
                new DataTableHeaderClass({ text: window.Vue.htmlEntities("In&iacute;cio"), value: "DT_INICIO", tooltipText: "Data e hora agendada para in&iacute;cio dos envios", cellClass: "text-nowrap", visibility: "sm" }),
                new DataTableHeaderClass({ text: window.Vue.htmlEntities("Rein&iacute;cio"), value: "DT_REINICIO", tooltipText: "Caso a campanha tenha sido escalonada ser&aacute; exibida a data e hora agendada para reiniciar", cellClass: "text-nowrap", visibility: "none" }),
                new DataTableHeaderClass({ text: "Fim", value: "DT_FIM", tooltipText: "Data e hora programada para finalizar os envios", cellClass: "text-nowrap", visibility: "sm" }),
                new DataTableHeaderClass({ text: "Total", value: "QT_TOTAL_DPES", tooltipText: "Total de registros importados", visibility: "xl" }),
                new DataTableHeaderClass({ text: "Higienizados", value: "QT_BLOQUEADOS_DPES", tooltipText: "Registros bloqueados pela higieniza&ccedil;&atilde;o", visibility: "xl" }),
            ];

            //Se o produto for RCS o nome da coluna sra "Sessoes" caso contrario "Radar"
            if (this.type_product === "RCS") {
                headers.push(...[
                    new DataTableHeaderClass({ text: window.Vue.htmlEntities("Sess&otilde;es"), value: "QT_RADAR_DPES", align: "center", tooltipText: "Mensagens de retorno dos destinat&aacute;rios", visibility: "lg" }),
                    new DataTableHeaderClass({
                        text: "SMS alternativo",
                        value: "fallback",
                        visibility: "lg",
                        tooltipText: "Icone informativo mostrando se a campanha cont&eacute;m mensagens de SMS alternativo ou n&atilde;o."
                    })
                ]);
            } else {
                headers.push(new DataTableHeaderClass({ text: "Radar", value: "QT_RADAR_DPES", align: "center", tooltipText: "Mensagens de retorno dos destinat&aacute;rios", visibility: "lg" }));
            }

            return headers.concat([
                new DataTableHeaderClass({ text: "Cancelados", value: "QT_CANCELADOS_DPES", tooltipText: "Envios cancelados por regras de neg&oacute;cio ou manualmente", sortable: false, visibility: "xl" }),
                new DataTableHeaderClass({ text: "Envios", value: "enviados", tooltipText: "Quantidade de mensagens enviadas", sortable: false }),
                new DataTableHeaderClass({ text: "Entrega", value: "entregues", tooltipText: "Informa&ccedil;&otilde;es de entrega aos destinat&aacute;rios", sortable: false, width: "200px", visibility: "sm" }),
                new DataTableHeaderClass({ text: window.Vue.htmlEntities("A&ccedil;&otilde;es"), value: "actions", tooltipText: "Detalhes da campanha", sortable: false, width: "130px" })
            ]);
        }
    },
    watch: {
        campaigns_selecteds() {
            this.enableBulkActionsButtons();
        }
    },
    mounted() {
        this.getUserInfo();
        this.getDataDashboard();

        setInterval(() => {
            this.getDataDashboard();
        }, 180000);

        let query_params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (query_params?.details_campaign !== undefined && query_params?.details_campaign) {
            setTimeout(() => {
                this.campaignDetails(query_params?.details_campaign);
            }, 1000);
        }
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetUserInfo", "ActionDoGetCampaignDetails"]),
        ...window.Vuex.mapActions("dashboard", ["ActionDoGetCampaignsData", "ActionDoCancelCampaigns"]),
        /**
         * Busca as informacoes do usuario logado na API Laravel
         * @vue
         */
        getUserInfo() {
            this.ActionDoGetUserInfo().then(response => {
                this.user_info = response;
            });
        },
        /**
         * Funcao centralizadora para buscar dados de acordo com o filtro do formulario
         * @vue
         */
        getDataDashboard() {
            this.campaigns_selecteds = [];
            this.getListCampaign();
            this.enableBulkActionsButtons();
        },
        /**
         * Busca a lista de campanhas a serem exibidas na tabela, de acordo com o filtro do formulario
         * @vue
         */
        getListCampaign() {
            this.table_loading = true;
            this.cards_loading = true;
            this.ActionDoGetCampaignsData(this.form_data).then(response => {
                this.campaigns_list = response.dashboard_data;
                this.list_status_count = response.totals;
                this.cards_loading = false;
                this.table_loading = false;
            });
        },
        /**
         * Monta os dados para criar a barra de progresso de envios e entregas
         * @vue
         */
        getConfigProgressBar(item, type) {
            let config = {};
            let list_values = {};

            if (type === "envios") {
                if (item.CD_STATUS_DPES.toUpperCase() === "G") {
                    config = {
                        total: 1,
                        label: "-",
                        list_values: [
                            new ProgressBarValueClass({ name: null, total: 1, color: "grey" })
                        ]
                    };
                } else {
                    let total = item.QT_TOTAL_DPES - item.QT_BLOQUEADOS_DPES - item.QT_CANCELADOS_DPES;

                    config = {
                        total: total,
                        label: "",
                        list_values: [
                            new ProgressBarValueClass({
                                name: "Enviados",
                                total: item.QT_ENVIADOS_DPES,
                                color: "primary-blue-500"
                            }),
                            new ProgressBarValueClass({
                                name: "N&atilde;o enviados",
                                total: item.QT_NAO_ENVIADO_DPES ?? 0,
                                color: "error-900"
                            }),
                            new ProgressBarValueClass({
                                name: "Aguardando Envio",
                                total: total - item.QT_ENVIADOS_DPES - (item.QT_NAO_ENVIADO_DPES ?? 0),
                                color: "gray-300"
                            })
                        ]
                    };
                }
            } else if (type === "entrega") {
                switch (item.CD_STATUS_DPES.toUpperCase()) {
                case "G":
                    config = {
                        total: 1,
                        label: "-",
                        list_values: [
                            new ProgressBarValueClass({ name: null, total: 1, color: "gray-300" })
                        ]
                    };
                    break;
                case "A":
                    config = {
                        total: item.QT_ENVIADOS_DPES || 1,
                        label: "-",
                        list_values: [
                            new ProgressBarValueClass({ name: null, total: (item.QT_ENVIADOS_DPES || 1), color: "gray-300" })
                        ]
                    };
                    break;
                default:
                    list_values = [
                        new ProgressBarValueClass({
                            name: "Entregues " + this.type_product,
                            total: item.NM_PRODURO_DPES === "SMS-C" ? item.QT_ENTREGUE_CONCAT_DPES : item.QT_ENTREGUES_DPES,
                            color: "success-900"
                        })
                    ];

                    if (this.type_product === "RCS") {
                        list_values.push(new ProgressBarValueClass({
                            name: "Entregues SMS alternativo",
                            total: item.QT_ENTREGUE_FALLBACK_DPES,
                            color: "cyan-900"
                        }));
                    }

                    list_values.push(...[
                        new ProgressBarValueClass({
                            name: "N&atilde;o entregues",
                            total: item.QT_NAO_ENTREGUE_DPES + item.QT_NAO_ENTREGUE_FALLBACK_DPES,
                            color: "error-900"
                        }),
                        new ProgressBarValueClass({
                            name: "Aguardando retorno",
                            total: item.QT_SEM_CONFIRMACAO_DPES + (item.QT_FALLBACK_DPES - item.QT_NAO_ENTREGUE_FALLBACK_DPES - item.QT_ENTREGUE_FALLBACK_DPES),
                            color: "gray-300"
                        })
                    ]);

                    config = {
                        total: item.QT_ENVIADOS_DPES,
                        label: "",
                        list_values: list_values
                    };
                    break;
                }
            }

            return config;
        },
        /**
         * Redireciona para a pagina de criacao de campanha, dependendo do tipo de produto selecionado na sessao
         * @vue
         */
        redirectCreateCampaign() {
            if (this.type_product === "RCS") {
                window.location = base_service.baseDomainPhp() + "/CriarCampanha";
            } else {
                window.location = base_service.baseDomainPhp() + "/frm-import-0.php";
            }
        },
        /**
         * Redireciona para a tela de radar
         * @vue
         */
        redirectRadar(item) {
            if (item.NM_PRODURO_DPES == "RCS") {
                let params = item.ID_CAMPANHA_DPES
                    ? { id_campanha: item.ID_CAMPANHA_DPES }
                    : {
                        data_inicio: dayjs(item.DT_INICIO).format("YYYY-MM-DD"),
                        data_fim: dayjs(item.DT_FIM).format("YYYY-MM-DD")
                    };
                $.doGet("RadarApp", params);
            } else {
                window.location = base_service.baseDomainPhp() + `/list-radar.php?idcamp=${item.ID_CAMPANHA_DPES}&idemp=${item.ID_CARTEIRA_DPES}&usa_data:'S'`;
            }
        },
        /**
         * Redireciona para a tela de detalhes da campanha
         * @vue
         */
        campaignDetails(campaign_id) {
            this.campaign_id = campaign_id;
            this.show_dialog_details = true; //Open the ditails dialog 
        },
        /**
         * Exibe o modal de redirecionamento para a tela de escalonamento
         * @vue
         */
        campaignEscalonar(campaign_id, campaign_name) {
            this.dialog_redirect_data = {
                type: "E",
                campaign_name: campaign_name,
                campaigns_ids: [campaign_id]
            };

            this.show_dialog_redirect = true;
        },
        /**
         * Exibe o modal de redirecionamento para a tela de cancelamento de campanhas
         * @vue
         */
        campaignCancelar(campaigns) {
            let ids = [];

            for (let campaign of campaigns) {
                if (this.canCancelCampaign(campaign)) {
                    ids.push(campaign.ID_CAMPANHA_DPES);
                }
            }

            this.dialog_redirect_data = {
                type: "C",
                campaign_name: null,
                campaigns_ids: ids
            };

            this.show_dialog_redirect = true;
        },
        /**
         * Exibe um modal referente a acao de pausa de campanhas
         * @vue
         */
        campaignsPause(campaigns) {
            let list = [];

            for (let campaign of campaigns) {
                if (this.canPauseCampaign(campaign)) {
                    list.push({
                        id: campaign.ID_CAMPANHA_DPES,
                        canal: campaign.NM_PRODURO_DPES,
                        cliente: campaign.NM_CARTEIRA_DPES,
                        campanha: campaign.NM_CAMPANHA_DPES,
                        status: campaign.CD_STATUS_DPES,
                    });
                }
            }

            this.dialog_actions_data = {
                type: "P",
                list_data: list,
            };

            this.show_dialog_actions = true;
        },
        /**
         * Exibe um modal referente a acao de reinicio de campanhas
         * @vue
         */
        campaignsRestart(campaigns) {
            let list = [];

            for (let campaign of campaigns) {
                if (this.canRestartCampaign(campaign)) {
                    list.push({
                        id: campaign.ID_CAMPANHA_DPES,
                        canal: campaign.NM_PRODURO_DPES,
                        cliente: campaign.NM_CARTEIRA_DPES,
                        campanha: campaign.NM_CAMPANHA_DPES,
                        status: campaign.CD_STATUS_DPES,
                    });
                }
            }

            this.dialog_actions_data = {
                type: "R",
                list_data: list,
            };

            this.show_dialog_actions = true;
        },
        /**
         * Abre um dialog de erro ao realizar acoes
         * @param {String} message Mensagem a ser exibida como alerta
         * @vue
         */
        actionFailed(message) {
            this.dialog_fail_data = message;
            this.show_dialog_fail = true;
        },
        /**
         * Abre um dialog de finalizacao de acao
         * @param {Object} data Dados para preencher o dialog
         * @vue
         */
        actionDone(data) {
            let list = [];

            for (let campaign of data.campaigns) {
                list.push({
                    id: campaign.id,
                    name: campaign.campanha
                });
            }

            this.dialog_done_data = {
                type: data.type,
                list_data: list
            };

            this.show_dialog_done = true;
        },
        /**
         * Verifica se a campanha pode ser pausada
         * @vue
         */
        canPauseCampaign(campaign_data) {
            return ["A", "E"].indexOf(campaign_data.CD_STATUS_DPES) > -1;
        },
        /**
         * Verifica se a campanha pode ser reiniciada
         * @vue
         */
        canRestartCampaign(campaign_data) {
            return campaign_data.CD_STATUS_DPES === "P";
        },
        /**
         * Verifica se a campanha pode ser cancelada
         * @vue
         */
        canCancelCampaign(campaign_data) {

            //Se a campanha estiver ativa, enviando ou pousada e for do tipo RCS, WHATS ou WTS entao pode ser cancelada
            let campaign = (["A", "E", "P"].indexOf(campaign_data.CD_STATUS_DPES) > -1 && ["RCS", "WHATS", "WTS"].indexOf(campaign_data.NM_PRODURO_DPES) > -1);

            return campaign;
        },
        /**
         * Verifica se a campanha pode ser escalonada
         * @vue
         */
        canEscalonarCampaign(campaign_data) {
            return (["A", "P"].indexOf(campaign_data.CD_STATUS_DPES) > -1 && ["RCS", "WHATS", "WTS"].indexOf(campaign_data.NM_PRODURO_DPES) === -1);
        },
        /**
         * Habilita ou desabilita os botoes de acoes para multiplas campanhas
         * @vue
         */
        enableBulkActionsButtons() {
            this.disable_bulk_pause_button = true;
            this.disable_bulk_restart_button = true;
            this.disable_bulk_cancel_button = true;
            this.disable_bulk_escalonar_button = true;

            if (this.campaigns_selecteds.length === 1 && this.canEscalonarCampaign(this.campaigns_selecteds[0])) {
                this.disable_bulk_escalonar_button = false;
            }

            for (let campaign of this.campaigns_selecteds) {
                if (this.canPauseCampaign(campaign)) {
                    this.disable_bulk_pause_button = false;
                }

                if (this.canRestartCampaign(campaign)) {
                    this.disable_bulk_restart_button = false;
                }

                if (this.canCancelCampaign(campaign)) {
                    this.disable_bulk_cancel_button = false;
                }

                if (!this.disable_bulk_pause_button && !this.disable_bulk_restart_button && !this.disable_bulk_cancel_button) {
                    break;
                }
            }
        },
        /**
         * Busca os dados de uma campanha
         * @vue
         */
        getCampaignDetails() {
            if (this.campaignId) {
                this.ActionDoGetCampaignDetails(this.campaignId).then(response => {
                    this.campaign_details = response;
                }).catch(() => {
                    this.campaign_details = null;
                });
            }
        },
        /**
         * Monta a formatacao do periodo de envio do lote
         * @param {Object} lote Dados do lote
         * @returns String
         * @vue
         */
        getPeriodLot(lote) {
            let period = "";
            if (dayjs(lote.DT_INICIO_LTES).isBefore(dayjs(this.campaign_details.DT_CAMPANHA_CAES).add(1, "minute")) && dayjs(lote.DT_INICIO_LTES).isAfter(dayjs(this.campaign_details.DT_CAMPANHA_CAES).subtract(1, "minute"))) {
                period = "envio imediato";
            } else if (lote.DT_FIM_LTES === undefined || lote.DT_FIM_LTES === null || lote.DT_FIM_LTES === "" || lote.DT_FIM_LTES === lote.DT_INICIO_LTES) {
                period = dayjs(lote.DT_INICIO_LTES).format("DD/MM [&agrave;s] HH:mm");
            } else {
                period = "entre " + dayjs(lote.DT_INICIO_LTES).format("DD/MM [&agrave;s] HH:mm") + " at&eacute; " + dayjs(lote.DT_FIM_LTES).format("DD/MM [&agrave;s] HH:mm");
            }

            return window.Vue.htmlEntities(period);
        },
        /**
         * Abre o modal para realiza o cancelamento da campanha
         * @vue
         */
        cancel(item) {
            this.campaignId = item[0].ID_CAMPANHA_DPES;

            this.load_content = true;

            // Monta os dados do dialog
            this.dialog_action_config.title = "Tem certeza que deseja cancelar a campanha?";
            this.dialog_action_config.description = "Escolha os lotes que deseja cancelar";
            this.dialog_action_config.information = "";
            this.dialog_action_config.link_text = "Mudei de id&eacute;ia, n&atilde;o quero cancelar";
            this.dialog_action_config.form_action = "";
            this.dialog_action_config.show_lotes = true;
            this.dialog_action_config.form_action = "";
            this.dialog_action_config.button = {
                primary: false,
                label: "Sim, quero cancelar minha campanha",
                loading: false,
                icon: "$cancelOutlineIcon",
                color: "error-500",
            };

            this.dialog_action_config.show = true; // Abre o modal

            // Busca os dados da campanha
            if (this.campaignId) {
                this.ActionDoGetCampaignDetails(this.campaignId).then(response => {
                    this.campaign_details = response;
                    this.load_content = false;

                    if (this.campaign_details.lotes.length === 1) {
                        this.selected_lotes = this.campaign_details.lotes;
                    }

                    // � ativada quando o usu�rio clica no bot�o de a��o
                    this.dialog_action_config.action = () => {
                        this.dialog_action_config.button.loading = true;
                        let lotes_ids = [];

                        for (let lote of this.selected_lotes) {
                            lotes_ids.push(lote.ID_LOTE_LTES);
                        }

                        // Realiza o cancelamento da campanha
                        this.ActionDoCancelCampaigns({ campaign_id: [this.campaign_details.ID_CAMPANHA_CAES], lotes: lotes_ids }).then(() => {
                            this.dialog_action_config.button.loading = false;
                            this.dialog_action_config.show = false;
                            this.selected_lotes = [];

                            this.notify_title = "Sua campanha foi cancelada";
                            this.notify_message = "Campanha cancelada com sucesso";
                            this.show_notify = true;

                            this.campaign_details = null;
                            this.getDataDashboard(); // Atualiza os dados do dashboard
                        }).catch(error => {
                            console.log(error);
                            this.dialog_action_config.button.loading = false;
                            this.dialog_action_config.show = false;
                        });
                    };

                }).catch(() => {
                    this.campaign_details = null;
                });
            }

        },
        cardClicked(type) {
            if (type === "clicks") {
                this.clicks.link = this.list_status_count.clicks_link;
                this.clicks.phone = this.list_status_count.clicks_phone;
                this.clicks.map = this.list_status_count.clicks_map;
                this.clicks.requestLocation = this.list_status_count.clicks_request_location;
                this.show_click_details = true;
            } else if (type === "sessions") {
                this.openListSessions();
            }
        },
        openListSessions() {
            this.list_campaigns_ids = [];
            for (let campaign of this.campaigns_list) {
                this.list_campaigns_ids.push(campaign.ID_CAMPANHA_DPES);
            }
            this.show_list_sessions = true;
        }
    },
    template: (
        /*html*/
        ` 
        <v-card elevation="0">
            <v-card-text>
                <v-row class="px-0 px-md-6" no-gutters>
                    <v-col cols="12">
                        <page-header
                            :tutorial="true"
                            title="Suas campanhas de hoje"
                            :description="pageDescription"
                            old-page="/dashboard.php"
                            @tutorial="$refs.tour.start()"
                        >
                            <template #button>
                                <base-button
                                    primary
                                    icon="mdi-plus"
                                    label="Nova campanha"
                                    @click="redirectCreateCampaign"
                                />
                            </template>
                        </page-header>
                    </v-col>
                </v-row>
                <v-row class="px-0 px-md-6" no-gutters>
                    <v-col cols="12" class="filtro-content" :data-v-step="1">
                        <form-filter v-model="form_data" @submit="getDataDashboard()" />
                    </v-col>
                </v-row>
                <v-row class="px-0 px-md-6 my-4 gap-row-6" :data-v-step="2">
                    <v-col cols="12" :sm="type_product === 'SMS' ? 6 : 12" :md="type_product === 'SMS' ? 6 : 4" class="d-flex">
                        <progress-card
                            :list-content="cardImportedData.list_content"
                            :title="cardImportedData.title"
                            :total="cardImportedData.total"
                            :loading="cards_loading"
                            :tooltip-text="cardImportedData.tooltip_text"
                            :disabled-tooltip="cardImportedData.disable_tooltip"
                            icon="$importadosCardsIcon"
                            background-color="super-dark-grey"
                        />
                    </v-col>
                    <v-col v-if="type_product === 'RCS'" cols="12" md="4" class="d-flex">
                        <progress-card
                            :list-content="cardSanitizedData.list_content"
                            :title="cardSanitizedData.title"
                            :total="cardSanitizedData.total"
                            :loading="cards_loading"
                            :tooltip-text="cardSanitizedData.tooltip_text"
                            :disabled-tooltip="cardSanitizedData.disable_tooltip"
                            icon="$importadosCardsIcon"
                            background-color="super-dark-grey"
                        />
                    </v-col>
                    <v-col cols="12" :md="type_product === 'SMS' ? 6 : 4" class="d-flex">
                        <progress-card
                            :list-content="cardProcessedData.list_content"
                            :title="cardProcessedData.title"
                            :total="cardProcessedData.total"
                            :loading="cards_loading"
                            :tooltip-text="cardProcessedData.tooltip_text"
                            :disabled-tooltip="cardProcessedData.disable_tooltip"
                            icon="$processadosCardsIcon"
                            background-color="super-dark-grey"
                        />
                    </v-col>
                    <v-col cols="12" :sm="type_product === 'RCS' ? 12 : 8" :md="type_product === 'RCS' ? 4 : 8" class="d-flex">
                        <progress-card
                            :list-content="cardSendData.list_content"
                            :title="cardSendData.title"
                            :total="cardSendData.total"
                            :loading="cards_loading"
                            :tooltip-text="cardSendData.tooltip_text"
                            :disabled-tooltip="cardSendData.disable_tooltip"
                            icon="$enviadosCardsIcon"
                            background-color="dark-primary"
                        />
                    </v-col>
                    <v-col v-if="type_product === 'RCS'" cols="12" md="4" class="d-flex">
                        <progress-card
                            :list-content="cardDeliveredData.list_content"
                            :title="cardDeliveredData.title"
                            :total="cardDeliveredData.total"
                            :loading="cards_loading"
                            :tooltip-text="cardDeliveredData.tooltip_text"
                            :disabled-tooltip="cardDeliveredData.disable_tooltip"
                            icon="$enviadosCardsIcon"
                            background-color="dark-primary"
                            @click="cardClicked"
                        />
                    </v-col>
                    <v-col v-if="type_product === 'RCS'" cols="12" md="4" class="d-flex">
                        <progress-card
                            :list-content="cardFallbackData.list_content"
                            :title="cardFallbackData.title"
                            :total="cardFallbackData.total"
                            :loading="cards_loading"
                            :tooltip-text="cardFallbackData.tooltip_text"
                            :disabled-tooltip="cardFallbackData.disable_tooltip"
                            icon="$smsAlternativoIcon"
                            background-color="dark-primary"
                        />
                    </v-col>
                    <v-col v-else cols="12" sm="4" class="d-flex">
                        <progress-card
                            :list-content="cardResponseData.list_content"
                            :title="cardResponseData.title"
                            :total="cardResponseData.total"
                            :loading="cards_loading"
                            :tooltip-text="cardResponseData.tooltip_text"
                            :disabled-tooltip="cardResponseData.disable_tooltip"
                            :link-text="cardResponseData.link_text"
                            :link-callback="cardResponseData.link_callback"
                            icon="$radarCardsIcon"
                            background-color="dark-primary"
                        />
                    </v-col>
                </v-row>
                <v-row class="px-0 px-md-6" justify="space-between" :data-v-step="3">
                    <v-col md="7">
                        <v-row class="gap-row-6">
                            <v-col cols="12" lg="3" xl="2">
                                <span class="text-black font-size-12">A&ccedil;&otilde;es em m&uacute;ltiplas campanhas:</span>
                            </v-col>
                            <v-col cols="12" md="4" lg="">
                                <base-button
                                    icon="$pauseIcon"
                                    label="Pausar"
                                    color="dark-grey"
                                    tooltip-text="Selecione uma ou mais campanhas com o status a enviar ou enviando para pausar."
                                    :disabled="disable_bulk_pause_button"
                                    @click="campaignsPause(campaigns_selecteds)"
                                />
                            </v-col>
                            <v-col cols="12" md="4" lg="">
                                <base-button
                                    icon="$playIcon"
                                    label="Reiniciar"
                                    color="primary-green"
                                    tooltip-text="Selecione uma ou mais campanhas com o status pausado para reiniciar."
                                    :disabled="disable_bulk_restart_button"
                                    @click="campaignsRestart(campaigns_selecteds)"
                                />
                            </v-col>
                            <v-col cols="12" md="4" lg="">
                                <base-button
                                    icon="$cancelOutlineIcon"
                                    label="Cancelar"
                                    color="danger"
                                    tooltip-text="Selecione uma ou mais campanhas com o status a enviar, enviando ou pausado para cancelar."
                                    :disabled="disable_bulk_cancel_button"
                                    @click="campaignCancelar(campaigns_selecteds)"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="auto" md="" class="text-end">
                        <v-divider vertical />
                    </v-col>
                    <v-col md="4" lg="4" xl="3">
                        <v-row class="gap-row-6">
                            <v-col lg="auto" class="d-flex align-center">
                                <span class="text-black font-size-12">A&ccedil;&otilde;es individuais:</span>
                            </v-col>
                            <v-col cols="12" lg="">
                                <base-button
                                    icon="$escalonarIcon"
                                    label="Escalonar"
                                    color="dark-primary"
                                    tooltip-text="Selecione apenas uma campanha com o status a enviar ou pausado para escalonar."
                                    :disabled="disable_bulk_escalonar_button"
                                    @click="campaignEscalonar(campaigns_selecteds[0].ID_CAMPANHA_DPES, campaigns_selecteds[0].NM_CAMPANHA_DPES)"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="px-0 px-md-6 mt-5" no-gutters>
                    <v-col cols="12">
                        <base-data-table
                            v-model="campaigns_selecteds"
                            :headers="table_headers"
                            :list-items="campaigns_list"
                            filterable
                            :page-length="-1"
                            select-column
                            item-key="ID_CAMPANHA_DPES"
                            :loading="table_loading"
                            sort-by="DT_INICIO"
                            :sort-desc="false"
                            :custom-slots="custom_slots"
                            :show-page-length="false"
                            :step-tutorial-custom-columns="9"
                            :step-tutorial-filter="10"
                            :rule-disable-checkbox-item="ruleDisableCheckboxItem"
                            fixed-header
                            id="tableDashboard"
                        >
                            <template #NM_PRODURO_DPES="props">
                                <canal-icon-description :product-name="props.item.NM_PRODURO_DPES" />
                            </template>
                            <template #CD_STATUS_DPES="props">
                                <status-campaign :status="props.item.CD_STATUS_DPES" :percent-progress="props.item.QT_PROCESSADOS_DPES" />
                            </template>
                            <template #DT_INICIO="props">
                                {{ props.item.DT_INICIO | format_date }}
                            </template>
                            <template #DT_REINICIO="props">
                                {{ props.item.DT_REINICIO | format_date }}
                            </template>
                            <template #DT_FIM="props">
                                {{ props.item.DT_FIM | format_date }}
                            </template>
                            <template #QT_RADAR_DPES="props">
                                <span v-if="(type_product === 'RCS' ? props.item.sessoes_rcs : props.item.QT_RADAR_DPES) <= 0"> {{ type_product === 'RCS' ? props.item.sessoes_rcs : props.item.QT_RADAR_DPES }} </span>
                                <a v-else href="javascript:void(0)" @click="redirectRadar(props.item)">{{ type_product === 'RCS' ? props.item.sessoes_rcs : props.item.QT_RADAR_DPES }}</a>
                            </template>

                            <template #fallback="props">
                                <div class="d-flex align-center">
                                    <v-icon
                                        v-if="props.item.ST_FALLBACK_DPES === 'S'"
                                        size="20"
                                        color="gray-700"
                                    >
                                        mdi-check-bold
                                    </v-icon>
                                    <span v-else class="body-2 text-gray-900">-</span>
                                </div>
                            </template>

                            <template #QT_CANCELADOS_DPES="props">
                                {{ props.item.QT_CANCELADOS_DPES | filter_amount }}
                            </template>
                            <template #enviados="props">
                                <base-progress-bar
                                    class="mb-4"
                                    :total="getConfigProgressBar(props.item, 'envios').total"
                                    :label="getConfigProgressBar(props.item, 'envios').label"
                                    :list-values="getConfigProgressBar(props.item, 'envios').list_values"
                                    show-tooltip-title
                                />
                            </template>
                            <template #entregues="props">
                                <base-progress-bar
                                    class="mb-4"
                                    :total="getConfigProgressBar(props.item, 'entrega').total"
                                    :label="getConfigProgressBar(props.item, 'entrega').label"
                                    :list-values="getConfigProgressBar(props.item, 'entrega').list_values"
                                    show-tooltip-title
                                />
                            </template>
                            <template #actions="props">
                                <v-row
                                    no-gutters
                                    p-0
                                    m-0
                                    class="my-2"
                                >
                                    <v-col cols="4">
                                        <button-action
                                            type="open"
                                            @click="campaignDetails(props.item.ID_CAMPANHA_DPES)"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <button-action
                                            type="escalonar"
                                            :disabled="!canEscalonarCampaign(props.item)"
                                            :small="true"
                                            @click="campaignEscalonar(props.item.ID_CAMPANHA_DPES, props.item.NM_CAMPANHA_DPES)"
                                        />
                                    </v-col>
                                    <v-col cols="4">
                                        <button-action
                                            v-if="canRestartCampaign(props.item)"
                                            type="play"
                                            :small="true"
                                            @click="campaignsRestart([props.item])"
                                        />
                                        <button-action
                                            v-else
                                            type="pause"
                                            :disabled="!canPauseCampaign(props.item)"
                                            :small="true"
                                            @click="campaignsPause([props.item])"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <button-action
                                            type="cancel"
                                            :disabled="!canCancelCampaign(props.item)"
                                            :small="true"
                                            @click="cancel([props.item])"
                                        />
                                    </v-col>
                                </v-row>
                            </template>
                        </base-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <pg-tour ref="tour" :steps="steps_tour" />
            <dialog-redirect
                v-model="show_dialog_redirect"
                :type="dialog_redirect_data.type"
                :campaign-name="dialog_redirect_data.campaign_name"
                :campaigns-ids="dialog_redirect_data.campaigns_ids"
            />
            <dialog-actions
                v-model="show_dialog_actions"
                :type="dialog_actions_data.type"
                :list-data="dialog_actions_data.list_data"
                @success="actionDone"
                @error="actionFailed"
            />
            <dialog-fail
                v-model="show_dialog_fail"
                :alert-text="dialog_fail_data"
            />
            <dialog-done
                v-model="show_dialog_done"
                :type="dialog_done_data.type"
                :list-data="dialog_done_data.list_data"
                @close="getDataDashboard"
            />
            <dialog-campaign-details
                v-model="show_dialog_details"
                :campaign-id="campaign_id"
            />
            <base-dialog
                v-model="dialog_action_config.show"
                :title="dialog_action_config.title"
                :action-button="dialog_action_config.button"
                use-icon-close
                max-width="410"
                use-sub-action
                :disable-action-button="dialog_action_config.show_lotes && selected_lotes.length === 0"
                @clickDialog="dialog_action_config.action"
            >
                <v-form
                    ref="form"
                    :action="dialog_action_config.form_action"
                    method="POST"
                    name="redirect"
                >
                    <input type="hidden" name="conteudo" :value="[campaign_details?.ID_CAMPANHA_CAES]">
                    <input type="hidden" name="link" :value="window?.location?.href">
                    <input type="hidden" name="return_details" :value="campaign_details?.ID_CAMPANHA_CAES">
                    <input type="hidden" name="return_link" :value="window?.location?.pathname">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <span class="body-2 text-gray-900" v-html="dialog_action_config.description" />
                        </v-col>
                        <v-col v-if="dialog_action_config.information" cols="12" class="mt-4">
                            <base-information :description="dialog_action_config.information" />
                        </v-col>
                        <v-col v-if="!load_content" cols="12">
                            <v-row no-gutters>
                                <v-col v-if="dialog_action_config.show_lotes" cols="12" class="mt-4">
                                    <base-data-table
                                        v-model="selected_lotes"
                                        :selected-items="selected_lotes"
                                        :headers="headers_table"
                                        :list-items="campaign_details?.lotes"
                                        :pagination="false"
                                        :show-total="false"
                                        :page-length="-1"
                                        :custom-columns="false"
                                        select-column
                                        item-key="ID_LOTE_LTES"
                                        dense
                                        :custom-slots="['lote']"
                                        :height="campaign_details?.lotes.length > 4 ? 160 : 'auto'"
                                        fixed-header
                                    >
                                        <template #lote="props">
                                            <span class="body-2 text-gray-900">Lote {{ props.index + 1 }} - {{ props.item.QT_SMS_LTES }} registros - {{ getPeriodLot(props.item) }}</span>
                                        </template>
                                    </base-data-table>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col v-else cols="12">
                            <v-row no-gutters>
                                <v-col class="mt-4">
                                    <v-row justify="center" class="d-flex flex-flow-column fill-height" no-gutters>
                                        <v-col cols="12">
                                            <v-skeleton-loader type="text" height="16" />
                                            <v-skeleton-loader type="text" height="16" />
                                            <v-skeleton-loader type="text" height="16" />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
                <template #subAction>
                    <v-row no-gutters class="mt-4">
                        <v-col cols="12" class="text-center">
                            <a class="text-primary-blue-500 text-decoration-none body-2" @click="dialog_action_config.show = false" v-html="dialog_action_config.link_text" />
                        </v-col>
                    </v-row>
                </template>
            </base-dialog>
            <dialog-click-details
                v-model="show_click_details"
                :link="clicks.link"
                :phone="clicks.phone"
                :map="clicks.map"
                :requestLocation="clicks.requestLocation"
            />
            <dialog-list-sessions
                v-model="show_list_sessions"
                :campaigns="list_campaigns_ids"
            />
        </v-card>
        `
    )
};