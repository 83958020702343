import styled from "../../plugins/vue-styled-components.es";

const RowActions = styled("div")`
    height: 89% !important;
`;
const RowImage = styled("div")`
    height: 10% !important;
`;

import TwoLogoTop from "../../components/moleculas/TwoLogoTop";
import SelectProductBox from "./components/SelectProductBox";
import SelectEmpresaBox from "./components/SelectEmpresaBox";
import ErrorLoginBox from "./components/ErrorLoginBox";
import { BaseService } from "../../services/BaseService";

import * as types from "./store/mutations-types";

const base_service = new BaseService();

/**
 * P�gina de transi��o do login (sele��o de produto e empresa)
 * 
 * @component
 * @category Page / Auth
 * @requires {@link two-logo-top.html|TwoLogoTop}
 * @requires {@link select-product-box.html|SelectProductBox}
 * @requires {@link select-empresa-box.html|SelectEmpresaBox}
 * @requires {@link error-login-box.html|ErrorLoginBox}
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 */
export default {
    name: "login-page",
    components: {
        TwoLogoTop,
        SelectProductBox,
        SelectEmpresaBox,
        ErrorLoginBox,
        "row-actions": RowActions,
        "row-image": RowImage
    },
    data() {
        return {
            product_selected: null,
            progress_val: 0,
            indeterminate: false,
            show_progress: false,
            disable_button: false,
            error: false,
            form_empresa: false
        };
    },
    computed: {
        ...window.Vuex.mapState("auth", {
            type_product: state => state.type_product,
            list_empresas: state => state.list_empresas
        })
    },
    methods: {
        ...window.Vuex.mapActions("auth", ["ActionDoUpdateSession"]),
        /**
         * Seta o produto selecionado no state, nos cookies a faz uma requisi��o para as APIs para salvar o produto selecionado
         * @vue
         */
        setProduct(product) {
            this.form_empresa = false;
            this.product_selected = product;

            if (product) {
                this.$store.commit("auth/" + types.SET_TYPE_PRODUCT, product.id.toUpperCase());
                this.$cookie.set("type_product", product.id.toUpperCase());

                if (Object.values(this.checkEmpresasUser()).length === 1) {
                    this.setEmpresa(this.list_empresas[0]);
                } else {
                    this.form_empresa = true;
                }
            } else {
                this.$store.commit("auth/" + types.SET_TYPE_PRODUCT, null);
                this.$cookie.set("type_product", null);
                this.setEmpresa(null);
            }
        },
        /**
         * Seta a empresa selecionada no state a faz uma requisi��o para as APIs para salvar a empresa selecionada
         * @vue
         */
        setEmpresa(empresa) {
            this.initProgressBar();
            this.$store.commit("auth/" + types.SET_EMPRESA, empresa);

            if (empresa) {
                this.ActionDoUpdateSession({
                    id_empresa: empresa.ID_EMPRESA_EPES,
                    type_produto: this.type_product
                }).then(response => {
                    if (response.codigo_erro) {
                        this.finishProgressBar();
                        this.error = true;
                        this.$cookie.set("id_empresa_selected", null);
                    } else {
                        this.$cookie.set("id_empresa_selected", empresa.ID_EMPRESA_EPES);
                        window.location.href = base_service.baseDomainPhp() + "/Dashboard";
                    }
                });
            } else {
                this.finishProgressBar();
            }
        },
        /**
         * Verifica a quantidade de empresas que o usu�rio possui acesso
         * @vue
         */
        checkEmpresasUser() {
            let empresas = {};

            this.list_empresas.forEach(empresa => {
                empresas[empresa.ID_EMPRESA_EPES] = empresa;
            });

            return empresas;
        },
        /**
         * Faz a inicializacao da barra de progresso ao realizar a requisicaoo no formulario
         * @vue
         */
        initProgressBar() {
            this.disable_button = true;
            this.show_progress = true;
            this.indeterminate = true;
            this.progress_val = 0;

            setTimeout(() => {
                this.progress_val = 10;
                this.indeterminate = false;
                setTimeout(() => {
                    this.progress_val = 75;
                    this.indeterminate = false;
                }, 500);
            }, 1000);
        },
        /**
         * Finaliza a barra de progresso ao finalizar uma requisicao
         * @vue
         */
        finishProgressBar() {
            this.progress_val = 100;

            setTimeout(() => {
                this.disable_button = false;
                this.show_progress = false;
            }, 100);
        },
    },
    template: (
        /*html*/
        `
        <v-row class="off-white fill-height" no-gutters>
            <two-logo-top logo_pg_color="grey"></two-logo-top>
            <v-col cols="12" class="p-0">
                <row-actions class="row align-center justify-center no-gutters">
                    <v-col cols="12" lg="11" xl="8" class="pl-md-10 pr-md-10 px-md-5">
                        <select-product-box v-if="!form_empresa && !error"
                            :progress_val="progress_val"
                            :indeterminate="indeterminate"
                            :show_progress="show_progress"
                            @productSelected="setProduct"
                        ></select-product-box>
                        <select-empresa-box v-if="form_empresa && !error"
                            :product_selected="product_selected"
                            :progress_val="progress_val"
                            :indeterminate="indeterminate"
                            :show_progress="show_progress"
                            @selectProduct="setProduct(null)"
                            @entrar="setEmpresa"
                        ></select-empresa-box>
                        <error-login-box v-if="error"></error-login-box>
                    </v-col>
                </row-actions>
                <row-image class="row no-gutters">
                    <v-col cols="12" class="p-0 m-0 d-flex align-center justify-start" style="background-color: #3559EB; height: 110px;">
                        <v-icon class="rounded-0 ml-10" color="white" size="60" contain max-width="170">$rcsProductIcon</v-icon>
                    </v-col>
                </row-image>
            </v-col>
        </v-row>
        `
    )
};