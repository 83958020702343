import * as types from "./mutations-types";

export default {
    [types.SET_STEP_ONE](state, value) {
        state.step_one_content = value;
    },
    [types.SET_STEP_TWO](state, value) {
        state.step_two_content = value;
    },
    [types.SET_STEP_THREE](state, value) {
        state.step_three_content = value;
    },
    [types.SET_ID_TEMPLATE_EDIT](state, value) {
        state.id_template_edit = value;
    },
    [types.SET_ID_TEMPLATE_CREATED](state, value) {
        state.id_template_created = value;
    },
};