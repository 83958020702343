import FormAddCustomField from "../../template-rcs/components/forms/FormAddCustomField";

export default {
    components: {
        FormAddCustomField
    },
    methods: {
        formData: function (value) {
            console.log(value);
        }
    },
    template: (
        /*html*/
        `
           <v-container>
                <v-row class="mt-10" justify="center">
                    <v-col cols="12" md="5" lg="3" >
                        <form-add-custom-field  @formSubmit="formData">
                        </form-add-custom-field>
                    </v-col>
                </v-row>
           </v-container>
        `
    )
};
