import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";
import BaseInformation from "../../../../components/atomos/BaseInformation?v=3";

/**
 * Componente de dialog para desativar um template
 * <iframe src="http://localhost:8080/v2/documentacao/template/dialog-disable" style="width: 100%; height: 315px;"></iframe>
 * 
 * @requires {@link BaseDialog}
 * @requires {@link BaseInformation}
 * 
 * @displayName DialogDisable
 * @category Page / Template
 * @subcategory dialogs
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseDialog,
        BaseInformation
    },
    props: {
        /**
         * Valor do atributo v-model do componente, indica se o dialog deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Dados do item a ser exibido
         */
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dialog: this.value,
            action_button: {
                label: "Sim, quero desativar",
                variant: "dark-danger",
                loading: false,
                icon: "mdi-block-helper",
                primary: false,
                danger: true
            },
        };
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog() {
            /**
             * Emite o status do dialog (false ou true)
             * @property {Boolean} dialog
             * 
             * @event input
             * @vue
             */
            this.$emit("input", this.dialog);

            if (!this.dialog) {
                /**
                 * Emite um evento chamado close informando que o modal foi fechado
                 * 
                 * @event close
                 * @vue
                 */
                this.$emit("close");
            }
        }
    },
    methods: {
        ...window.Vuex.mapActions("templates", ["ActionDoDisableTemplate", "ActionDoDisableTemplateApps"]),
        disableTemplate() {
            this.action_button.loading = true;

            if (this.item.CD_PRODUTO_PDES == "RCS" || this.item.CD_PRODUTO_PDES == "WTS") {
                this.ActionDoDisableTemplateApps(this.item.ID_TEMPLATE).then(() => {
                    this.dialog = false;
                    this.action_button.loading = false;
                    this.$emit("disabled", { result: true, type: "disable"});
                }).catch(() => {
                    this.dialog = false;
                    this.action_button.loading = false;
                    this.$emit("disabled", { result: false, type: "disable"});
                });
            } else {
                this.ActionDoDisableTemplate({ id_template: this.item.ID_TEMPLATE }).then(() => {
                    this.dialog = false;
                    this.action_button.loading = false;
                    this.$emit("disabled", { result: true, type: "disable"});
                }).catch(() => {
                    this.dialog = false;
                    this.action_button.loading = false;
                    this.$emit("disabled", { result: false, type: "disable"});
                });
            }
        }
    },
    template: (
        /*html*/
        `
            <base-dialog
                v-model="dialog"
                max-width="410px"
                title="Tem certeza que deseja desativar o template?"
                :action-button="action_button"
                use-sub-action
                use-icon-close
                @clickDialog="disableTemplate"
            >
                <v-row no-gutters>
                    <v-col cols="12" class="text-start">
                        <span class="body-2 text-gray-900">Voc&ecirc; esta desativando o template <b>{{ item.CD_TEMPLATE }}</b>.</span>
                    </v-col>
                    <v-col cols="12" class="text-start mt-4">
                        <span class="body-2 text-gray-900">Ative novamente a qualquer momento</span>
                    </v-col>
                </v-row>
                <template #subAction>
                    <v-row no-gutters class="mt-4">
                        <v-col cols="12" class="text-center">
                            <a class="text-primary-blue-500 text-decoration-none body-2" @click="dialog = false">Mudei de id&eacute;ia, n&atilde;o quero desativar</a>
                        </v-col>
                    </v-row>
                </template>
            </base-dialog>
        `
    )
};