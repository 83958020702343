import BaseIconButton from "./BaseIconButton?v=3";
import BaseButton from "./BaseButton?v=2";

/**
 * Componente BaseNotify
 * 
 * @requires {@link BaseIconButton}
 * @requires {@link BaseButton}
 * 
 * @displayName BaseNotify
 * @component
 * @category Atomos
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseIconButton,
        BaseButton
    },
    props: {
        /**
         * Ativa ou desativa a notificacao
         */
        active: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Titulo da notificacao
         */
        title: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Mensagem de notificacao
         */
        message: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Define o tipo da notificacao
         * @values notification, success, error, warning, info, custom
         */
        type: {
            type: String,
            required: true,
            validator: (data) => {
                return ["notification", "success", "error", "warning", "info", "custom"].indexOf(data) > -1;
            }
        },
        /**
         * Ativa ou desativa o fechamento autom�tico da notificacao
         */
        autoclose: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Nome do �cone a ser exibido na notificacao (somente se type = custom)
         */
        icon: {
            type: String,
            required: false,
            default: "mdi-bell-circle"
        },
        /**
         * Cor da notificacao, aplicada somente no icone e na barra lateral (somente se type = custom)
         */
        color: {
            type: String,
            required: false,
            default: "medium-grey"
        },
        /**
         * Define o valor de v-model
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Label do botao
         */
        buttonLabel: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Icone do botao
         */
        buttonIcon: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show_notify: this.active || this.value
        };
    },
    computed: {
        notify_theme() {
            return this.getThemeNotify(this.type);
        }
    },
    watch: {
        active(new_value) {
            this.show_notify = new_value;
        },
        value() {
            this.show_notify = this.value;
        },
        show_notify(new_value) {
            if (!new_value) {
                /**
                 * Evento de close da notifica��o
                 * @category Atomos
                 * @event close
                 * @vue
                 */
                this.$emit("close");
                this.$emit("input", this.show_notify);
            }
        }
    },
    methods: {
        /**
         * Metodo que monta o tema a ser utilizado na notifica��o
         * 
         * @param {string} type Tipo da notifica��o
         * @category Atomos
         * @vue
         */
        getThemeNotify(type) {
            switch (type) {
            case "notification":
                return { color: "medium-grey", icon: "mdi-bell-circle" };
            case "success":
                return { color: "success-500", icon: "mdi-check-circle" };
            case "error":
                return { color: "error-500", icon: "mdi-close-circle" };
            case "warning":
                return { color: "warning-500", icon: "mdi-alert-circle" };
            case "info":
                return { color: "primary", icon: "mdi-information" };
            case "custom":
            default:
                return { color: this.color, icon: this.icon };
            }
        }
    },
    template: (
        /*html*/
        `
            <v-snackbar
                v-model="show_notify"
                top
                right
                color="gray-0"
                max-width="390px"
                :timeout="autoclose ? 15000 : -1"
                elevation="3"
                rounded="2"
                transition="slide-y-transition"
                content-class="pa-0"
            >
                <v-row no-gutters class="py-4 pl-4">
                    <v-col cols="12">
                        <v-row no-gutters>
                            <v-col cols="auto">
                                <v-card elevation="0" width="3" height="100%" :class="'bg-' + notify_theme.color"></v-card>
                            </v-col>
                            <v-col cols="auto" class="px-6 d-flex align-center">
                                <v-icon size="32" :color="notify_theme.color">{{ notify_theme.icon }}</v-icon>
                            </v-col>
                            <v-col>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <span class="body-2 text-gray-900" v-html="title"></span>
                                    </v-col>
                                    <v-col cols="12" class="mt-2">
                                        <span class="caption text-gray-500" v-html="message"></span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-if="buttonLabel" no-gutters justify="center" class="mt-6">
                            <v-col cols="auto">
                                <base-button secondary :label="buttonLabel" :icon="buttonIcon" @click="$emit('click')"></base-button>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <template v-slot:action="{ attrs }">
                    <v-row no-gutters class="fill-height align-start">
                        <v-col cols="auto">
                            <base-icon-button v-bind="attrs" icon="mdi-close-thick" :value="false" danger size="32" @click="show_notify = false"></base-icon-button>
                        </v-col>
                    </v-row>
                </template>
            </v-snackbar>
        `
    )
};
