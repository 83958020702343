import BaseButton from "../../../components/atomos/BaseButton?v=2";

export default {
    components: {
        BaseButton
    },
    props: {
        /**
         * Objeto que indica se deve ou n�o habilitar os bot�es de exportar
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Dados do filtro a serem submetidos
         */
        dataExport: {
            type: Object,
            required: true,
            default: function () {
                return {};
            }
        },
        /**
         * Dados da tabela
         */
        contentTable: {
            type: Object,
            required: false,
            default: function () {
                return {};
            }
        },
    },
    data() {
        return {
            disabled_text: true,
            disabled_excel: true,
            loading_text: true,
            loading_excel: true
        };
    },
    watch: {
        loading: function (ev) {
            this.exportButtonLoading(ev);
        },
        contentTable: function (ev) {
            if (ev.length == 0) {
                this.exportButtonLoading(false);
            } else {
                this.exportButtonLoading(true);
            }

            //Sempre que o dados da tabela forem atualizados, inativa o bot�o de exportar
            this.loading_text = false;
            this.loading_excel = false;
        },
        dataExport: function () {
            this.exportButtonLoading(true);
        }
    },
    methods: {
        ...window.Vuex.mapActions("gerenciar", ["ActionDoGetCampanhasByFilter"]),
        /**
         * Ativa ou inativa o loading e desabled, dentro do bot�o de exportar
         * @param {boolean} is_loading
         */
        exportButtonLoading(is_loading = false) {
            this.loading_text = is_loading;
            this.loading_excel = is_loading;

            if (this.contentTable.length == 0) { // Se n�o houver dados ap�s o filtro, ativa o disable
                this.disabled_text = true;
                this.disabled_excel = true;
            } else { // Se houver dados ap�s o filtro, inativa o disable
                this.disabled_text = false;
                this.disabled_excel = false;
            }
        },
        /**
         * Funcao que gera o relatorio em formato texto
         */
        exportarTexto() {
            this.loading_text = true;
            this.disabled_text = true;

            this.ActionDoGetCampanhasByFilter({download: "txt", ...this.dataExport}).then(response => {
                response.name = decodeURIComponent(encodeURI(response.name));
                response.name = decodeURIComponent(escape(response.name));
                if (response.data.type !== "text/plain" && response.data.type !== undefined) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", response.name + ".zip");
                    document.body.appendChild(link);
                    link.click();
                } else {
                    let blob = new Blob([response.data]);
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = response.name;
                    link.click();
                }

                this.loading_text = false;
                this.disabled_text = false;
            }).catch(() => {
                this.loading_text = false;
                this.disabled_text = false;
            });
        },
        /**
         * Funcao que gera o relatorio em formato excel
         */
        exportarExcel() {
            this.loading_excel = true;
            this.disabled_excel = true;

            this.ActionDoGetCampanhasByFilter({ download: "csv", ...this.dataExport }).then(response => {
                response.name = decodeURIComponent(encodeURI(response.name));
                response.name = decodeURIComponent(escape(response.name));
                if (response.data.type !== "text/plain" && response.data.type !== undefined) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", response.name + ".zip");
                    document.body.appendChild(link);
                    link.click();
                } else {
                    let blob = new Blob([response.data]);
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = response.name + ".csv";
                    link.click();
                }

                this.loading_excel = false;
                this.disabled_excel = false;
            }).catch(() => {
                this.loading_excel = false;
                this.disabled_excel = false;
            });
        }
    },
    template:
        /*html*/
        `
            <v-row class="justify-content-end mt-3">
                <v-col cols="12" class="col-md-1 text-md-right text-sm-left text-center mt-2">
                    <label> Download:</label>
                </v-col>
                <v-col
                    cols="12"
                    class="col-md-3 pt-0"
                    data-intro-group-novidades="true"
                    data-step-novidades="2"
                    data-intro-group-tutorial="true"
                    data-step-tutorial="2"
                >
                    <v-row class="justify-content-end" :data-v-step="2">
                        <v-col cols="12" class="col-md-6 mb-4">
                            <base-button
                                :disabled="disabled_text"
                                :loading="loading_text"
                                icon="$downloadIcon"
                                label="Texto"
                                @click="exportarTexto()"
                            >
                                <template #loader>
                                    <span>Carregando...</span>

                                    <v-progress-linear
                                        indeterminate
                                        absolute
                                        bottom
                                        color="dark-primary"
                                        height="4"
                                    />
                                </template>
                            </base-button>
                        </v-col>
                        <v-col cols="12" class="col-md-6 mb-4">
                            <base-button
                                :disabled="disabled_excel"
                                :loading="loading_excel"
                                icon="$downloadIcon"
                                label="Excel"
                                @click="exportarExcel()"
                            >
                                <template #loader>
                                    <span>Carregando...</span>

                                    <v-progress-linear
                                        indeterminate
                                        absolute
                                        bottom
                                        color="dark-primary"
                                        height="4"
                                    />
                                </template>
                            </base-button>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
};
