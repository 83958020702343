import BaseSectionForm from "../../../components/atomos/BaseSectionForm?v=2";
import DataSummary from "../../../components/moleculas/DataSummary";
import FormReportCompletion from "./forms/FormReportCompletion?v=2";

import * as types from "../store/mutations-types";

/**
 * Componente de passo seis do gerador de relatorios
 * 
 * @requires {@link BaseSectionForm}
 * @requires {@link DataSummary}
 * @requires {@link FormReportCompletion}
 * 
 * @displayName ReportStepSix
 * @category Page / Relatorios
 * @author Igor Oliveira <igor.oliveira@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseSectionForm,
        DataSummary,
        FormReportCompletion
    },
    data() {
        return {
            email: null
        };
    },
    computed: {
        ...window.Vuex.mapState("reports", {
            step_one_content: state => state.generator_step_one,
            step_two_content: state => state.generator_step_two,
            step_three_content: state => state.generator_step_three,
            step_four_content: state => state.generator_step_four,
            step_five_content: state => state.generator_step_five,
            generator_step_six: state => state.generator_step_six
        }),
        steps() {
            let period = "";
            let type_report = "";

            switch (this.step_one_content.type_period) {
            case "yesterday":
                period = "Ontem " + window.Vue.formatDateUsaToBrasil( this.step_one_content.period_selected[0]);
                break;
            case "seven-days":
                period = "7 dias";
                break;
            case "thirty-days":
                period = "30 dias";
                break;
            case "specific-date":
                period = "De " + window.Vue.formatDateUsaToBrasil( this.step_one_content.period_selected[0]) + window.Vue.htmlEntities(" at&eacute; ") + window.Vue.formatDateUsaToBrasil(this.step_one_content.period_selected[1]);
            }

            switch (this.step_two_content.report_type) {
            case "synthetic":
                type_report = "Sintetico";
                break;
            case "analytical":
                type_report = "Analitico";

            }

            return {
                "periodo": {
                    title: window.Vue.htmlEntities("Per&iacute;odo"),
                    description: period
                },
                "campanha": {
                    title: "Campanhas",
                    description: this.step_four_content.campaigns !== undefined ? this.step_four_content.campaigns.length + " campanhas" : this.step_one_content.recurrence_report ? "Todas" : ""
                },
                "relatorios": {
                    title: window.Vue.htmlEntities("Repetir relat&oacute;rio?"),
                    description: this.step_one_content.recurrence_report ? "Sim" : window.Vue.htmlEntities("N&atilde;o")
                },
                "agrupado": {
                    title: "Agrupar por dia",
                    description: this.step_five_content.agroup ? "Sim" : window.Vue.htmlEntities("N&atilde;o")
                },
                "modalidade": {
                    title: "Modalidade",
                    description: type_report
                },
                "dados": {
                    title: "Dados adicionais",
                    description: this.step_five_content.aditional_data !== undefined ? this.step_five_content.aditional_data.join(", ") : ""
                },
                "cliente": {
                    title: "Clientes",
                    description: this.step_three_content.clients !== undefined ? this.step_three_content.clients.length + " clientes" : ""
                }
            };
        },
    },
    mounted() {
        if (this.generator_step_six.email_attach !== undefined && this.generator_step_six.email_attach !== "") {
            this.email = this.generator_step_six.email_attach;
        }
    },
    methods: {
        /**
         * Salva a configuracao escolhida pelo usuario no store
         * @param {Object} dados Dados da configuracao de recebimento de anexo
         * @vue
         */
        submit_form(dados) {
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_SIX}`, dados);
        },
        /**
         * Limpa todos os dados salvos no store referente a geracao do relatorio
         * @vue
         */
        cleanOptions() {
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_SIX}`, {});
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_FIVE}`, {});
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_FOUR}`, {});
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_THREE}`, {});
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_TWO}`, {});
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_ONE}`, {});

            /**
             * Informa que o botao de limpar tudo foi clicado e os dados foram resetados
             * @event clearData
             * @vue
             */
            this.$emit("clearData");
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters>
                <v-col cols="12" md="">
                    <base-section-form title="Revise e gere o relat&oacute;rio" description="Revise as op&ccedil;&otilde;es selecionadas e escolha por onde deseja receber seu relat&oacute;rio">
                        <v-row no-gutters>
                            <v-col v-bind:data-v-step="14">
                                <data-summary
                                    class="mt-3"
                                    :disabled-delete="false"
                                    :columns="6"
                                    :steps="steps"
                                    tooltip-text="Resumo do relat&oacute;rio"
                                    @click="cleanOptions()"
                                ></data-summary>
                            </v-col>
                        </v-row>
                    </base-section-form>
                </v-col>
                <v-col cols="auto" class="px-5">
                    <v-divider vertical></v-divider> 
                </v-col>
                <v-col cols="12" md="">
                    <form-report-completion :email-attach="email" @change="submit_form" v-bind:data-v-step="16"></form-report-completion>
                </v-col>
            </v-row>
        `
    )
};