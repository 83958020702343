export const SET_LIST_CLIENTS_TOTAL = "SET_LIST_CLIENTS_TOTAL";
export const SET_FILTER_CLIENTS_START_DATE = "SET_FILTER_CLIENTS_START_DATE";
export const SET_FILTER_CLIENTS_END_DATE = "SET_FILTER_CLIENTS_END_DATE";
export const SET_ALL_CAMPAIGNS = "SET_ALL_CAMPAIGNS";
export const SET_GENERATOR_CONFIG_STEP_ONE = "SET_GENERATOR_CONFIG_STEP_ONE";
export const SET_GENERATOR_CONFIG_STEP_TWO = "SET_GENERATOR_CONFIG_STEP_TWO";
export const SET_GENERATOR_CONFIG_STEP_THREE = "SET_GENERATOR_CONFIG_STEP_THREE";
export const SET_GENERATOR_CONFIG_STEP_FOUR = "SET_GENERATOR_CONFIG_STEP_FOUR";
export const SET_GENERATOR_CONFIG_STEP_FIVE = "SET_GENERATOR_CONFIG_STEP_FIVE";
export const SET_GENERATOR_CONFIG_STEP_SIX = "SET_GENERATOR_CONFIG_STEP_SIX";

