import BaseTooltip from "../atomos/BaseTooltip?v=2";

/**
 * Componente de uso basico para um calendario
 * 
 * @requires {@link BaseTooltip}
 *
 * @displayName BaseCalendar
 * @component
 * @category Moleculas
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Valor informado em v-model
         */
        value: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Indica que o canelario permite selecionar um range de datas
         */
        range: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Habilita a legenda do calendario
         */
        legend: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Coloca a legenda abaixo do calendario
         */
        legendBottom: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica que o calendario deve estar no modo readonly
         */
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Texto do tooltip de data selecionada
         */
        tooltipSelectedDate: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Data minima para geracao do relatorio
         */
        minDate: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Mes e ano de exibicao do calendario
         */
        monthAndYear: {
            type: String,
            required: false,
            default: undefined
        },
        /**
         * Maximo de dias permitidos no range
         */
        maxRange: {
            type: Number,
            required: false,
            default: 30
        },
        /**
         * Indica que o calendario deve ser alterado para o modo custom range
         */
        customRange: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Propriedade para passar o tipo de relatorio selecionado
         */
        reportType: {
            type: String,
            required: false,
            default: undefined
        }
    },
    data() {
        return {
            week_days: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", window.Vue.htmlEntities("S&aacute;b"), "Dom"],
            months: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            full_months: ["Janeiro", "Fevereiro", window.Vue.htmlEntities("Mar&ccedil;o"), "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            dates: [],
            pickerDate: null
        };
    },
    computed: {
        dateSelected() {
            let content = window.Vue.htmlEntities("&nbsp");

            if (this.range && this.dates.length == 2) {
                let date_start = new Date(this.dates[0].split("-"));

                content = date_start.getDate().toString().padStart(2, "0") + " de ";
                content += this.months[date_start.getMonth()] + " ~ ";

                let date_end = new Date(this.dates[1].split("-"));

                content += date_end.getDate().toString().padStart(2, "0") + " de ";
                content += this.months[date_end.getMonth()];
            } else if (!this.range && this.dates.length === 10) {
                let date = new Date(this.dates.split("-"));

                content = this.week_days[date.getDay()] + ", ";
                content += date.getDate().toString().padStart(2, "0") + " de ";
                content += this.months[date.getMonth()];
            }

            return content;
        },
        maximumDate() {
            let content = null;
            let date;
            if (this.reportType === "analytical") {
                date = dayjs().format("YYYY-MM-DD");
            } else {
                date = dayjs().subtract(1, "day").format("YYYY-MM-DD");
            }

            if (this.range && this.dates[0] !== undefined && this.dates[0] !== null) {
                let new_date = dayjs(date).add(this.maxRange, "days");

                if (dayjs(new_date).isAfter(date)) {
                    new_date = date;
                }

                content = dayjs(new_date).format("YYYY-MM-DD");
            } else {
                content = date;
            }

            return content;
        },
        minimumDate() {
            let content = null;

            if (this.customRange) {
                content = this.minDate;
            } else {
                let first_date = this.dates.length === 2 || this.dates.length === 1 ? this.dates[0] : this.dates;

                if (first_date !== undefined && first_date !== null && first_date.length > 0) {
                    let new_date = new Date(first_date.split("-"));

                    content = new_date.getFullYear() + "-" + ((new_date.getMonth() + 1).toString().padStart(2, "0")) + "-" + new_date.getDate().toString().padStart(2, "0");
                } else if (this.minDate) {
                    content = this.minDate;
                }
            }

            return content;
        },
    },
    watch: {
        range() {
            this.dates = [];
        },
        value() {
            this.fillDate();
        },
        monthAndYear() {
            this.pickerDate = this.monthAndYear;
        }
    },
    mounted() {
        this.pickerDate = this.monthAndYear;
        this.fillDate();
    },
    methods: {
        /**
         * Preenche os dados necessarios de acordo com o valor de value
         */
        fillDate() {
            if (this.value) {
                if (this.range && this.value.length === 2) {
                    this.dates = _.cloneDeep(this.value).sort();
                } else if (!this.range && this.value.length === 1) {
                    this.dates = this.value[0];
                } else {
                    this.dates = [];
                }
            } else {
                this.dates = [];
            }
        },
        /**
         * Retorna uma string com o formato de Mes e Ano a serem exibidos no calendario
         * 
         * @returns {String} Mes e ano no formato: Janeiro, 2022
         * @vue
         */
        monthSelected() {
            let content = null;

            if (this.pickerDate) {
                let year = this.pickerDate.split("-")[0];
                let month = this.pickerDate.split("-")[1];

                content = this.full_months[parseInt(month) - 1] + ", " + year;
            }

            return content;
        },
        emitValue() {
            if (this.range && this.dates.length == 2) {
                let new_order = _.cloneDeep(this.dates);

                /**
                 * Evento de input, altera o valor da variavel informada em v-model no uso do componente
                 * 
                 * @property {Array|String} dates Datas selecionadas, ["yyyy-mm-dd", "yyyy-mm-dd"] para range, e ["yyyy-mm-dd"] para selecao unica
                 * 
                 * @event input
                 * @vue
                 */
                this.$emit("input", new_order.sort());
            } else if (!this.range && this.dates.length === 10) {
                /**
                 * Evento de input, altera o valor da variavel informada em v-model no uso do componente
                 * 
                 * @property {Array|String} dates
                 * 
                 * @event input
                 */
                this.$emit("input", [this.dates]);
            }
        }
    },
    template:
        /*html*/
        `
            <v-card elevation="0" class="bg-transparent">
                <v-row no-gutters>
                    <v-col :cols="legend && !legendBottom ? 8 : 12">
                        <v-row no-gutters class="bg-primary-blue-500 pa-4 rounded-t-lg">
                            <v-col v-if="customRange">
                                <span class="body-2 text-gray-0">Selecione o per&iacute;odo desejado</span>
                            </v-col>
                            <v-col v-else>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <span class="caption text-gray-0">Data selecionada</span>
                                    </v-col>
                                    <v-col cols="12">
                                        <base-tooltip :text="tooltipSelectedDate">
                                            <span class="body-2 text-gray-0">{{ dateSelected }}</span>
                                        </base-tooltip>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="ma-0 pa-0">
                            <v-col class="ma-0 pa-0" v-if="dates && pickerDate && monthSelected">
                                <v-date-picker
                                    v-model="dates"
                                    :class="'rounded-b-lg rounded-t-0 ' + (customRange ? 'custom-range' : '')"
                                    :range="range"
                                    full-width
                                    flat
                                    no-title
                                    :max="maximumDate"
                                    :min="minimumDate"
                                    :picker-date.sync="pickerDate"
                                    :header-date-format="monthSelected"
                                    :readonly="readonly"
                                    @change="emitValue"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="legend" :class="(legendBottom ? 'pt-0' : '') + ' pl-4'">
                        <v-row :class="legendBottom ? 'mt-0 flex-nowrap' : ''" align="center">
                            <v-col :cols="legendBottom ? 'auto' : 12">
                                <span class="body-2">Legenda</span>
                            </v-col>
                            <v-col :cols="legendBottom ? 'auto' : 12" class="py-2 d-flex flex-nowrap">
                                <v-avatar size="21" color="dark-primary" /> <span class="body-2 pl-2">Data selecionada</span>
                            </v-col>
                            <v-col :cols="legendBottom ? 'auto' : 12" class="d-flex flex-nowrap">
                            <v-avatar size="21" :class="{'border-dashed border-dark-primary': reportType !== 'analytical', 'border border-dark-primary': reportType === 'analytical'}" /><span class="body-2 pl-2">Data atual (Hoje)</span>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        `
};

