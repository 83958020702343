/**
 * Componente de status usado na listagem de envios Avulso/API
 *
 * @displayName StatusAvulsoApi
 * @component
 * @category Moleculas
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @author Bruno Dias Ramos <bruno.dias@pgmais.com.br>
 * @vue/component
 */
export default {
    props: {
        /**
         * Status do envio Avulso/Api
         */
        status: {
            type: String,
            required: true,
            validator: function (data) {
                return ["Enviado", "Aguardando", "N&atilde;o entregue", "Entregue", "N&atilde;o enviado", "Rejeitado", "Cancelado"].indexOf(data) > -1;
            }
        },
    },
    computed: {
        style() {
            let config = window.Vue.getStatusAvulsoApiConfig(this.status);
            return config;
        }
    },
    template:
        /*html*/
        `
            <v-row no-gutters class="d-flex flex-row justify-center align-center">
                <v-col cols="12">
                    <span :class="'d-inline-flex rounded-8 bg-' + style.color + ' '+ style.textColor + '--text body-2 py-1 px-2 align-center'">
                        <v-icon
                            v-if="style.icon"
                            :color="style.textColor"
                            left
                            size="12"
                            class="ma-0 d-inline-flex align-center"
                        >{{ style.icon }}</v-icon>
                        <span class="body-2 d-inline-flex align-center pl-2" v-html="style.label"> </span>
                    </span>
                </v-col>
            </v-row>
        `
};

