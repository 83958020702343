import CarouselNewsUsage from "./CarouselNewsUsage";
import CardsConfiguracaoUsage from "./CardsConfiguracaoUsage";
import FieldsTemplateUsage from "./FieldsTemplateUsage?v=2";
import BaseDataTableUsage from "./BaseDataTableUsage";
import SupportContactDialogUsage from "./SupportContactDialogUsage";
import ValidDatePickerUsage from "./ValidDatePickerUsage";
import PreviewTemplateRcsUsage from "./PreviewTemplateRcsUsage";
import DialogPreviewTemplateRcsUsage from "./DialogPreviewTemplateRcsUsage";
import DialogCampaignDetailsUsage from "./DialogCampaignDetailsUsage";

export default [
    {
        path: "carousel-news-usage",
        name: "Carousel news usage",
        component: CarouselNewsUsage
    },
    {
        path: "cards-configuracao-usage",
        name: "Cards Configuracao Usage",
        component: CardsConfiguracaoUsage
    },
    {
        path: "fields-template-usage",
        name: "Fields Template Usage",
        component: FieldsTemplateUsage
    },
    {
        path: "base-data-table-usage",
        name: "Base data table",
        component: BaseDataTableUsage
    },
    {
        path: "support-contact-dialog-usage",
        name: "Support Contact Dialog",
        component: SupportContactDialogUsage
    },
    {
        path: "valid-date-picker-usage",
        name: "Valid Date Picker",
        component: ValidDatePickerUsage
    },
    {
        path: "preview-template-rcs-usage",
        name: "Preview Template RCS",
        component: PreviewTemplateRcsUsage
    },
    {
        path: "dialog-preview-template-rcs-usage",
        name: "Dialog Preview Template RCS",
        component: DialogPreviewTemplateRcsUsage
    },
    {
        path: "dialog-campaign-details-usage",
        name: "Dialog Campaing Details",
        component: DialogCampaignDetailsUsage
    }
];