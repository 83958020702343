import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente de text field basico para uso nos formularios do painel
 * 
 * @displayName BaseTextField
 * @component
 * @category Atomos
 * @requires {@link BaseTooltip}
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br> 
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Texto a ser exibido como placeholder
         */
        label: {
            type: String,
            required: true
        },
        /**
         * Define a obrigatoriedade de preenchimento do campo
         * @values true, false
         */
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define as regras a serem utilizadas para validacao dos dados inseridos
         */
        rules: {
            type: Array,
            required: false,
            default: function () {
                return [];
            }
        },
        /**
         * Define qual vai ser o tipo do text field
         * @values text, password, email, url, ...
         */
        type: {
            type: String,
            required: false,
            default: "text"
        },
        /**
         * Value do text-field
         */
        value: {
            type: [String, Number],
            required: false,
            default: null
        },
        /**
         * verificar se usa o icone de validacao 
         */
        useIconValidation: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Title do text-field
         */
        title: {
            type: String,
            required: false,
            default: null
        },
        /**
         * hint do text-field
         */
        hint: {
            type: String,
            required: false,
            default: null
        },
        /**
         * popover content do text-field
         */
        popoverContent: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Define se o autocomplete do campo esta ligado ou desligado
         */
        autocomplete: {
            type: String,
            required: false,
            default: "on"
        },
        /**
         * Texto do tooltip do campo
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Cor do tooltip
         */
        colorTooltip: {
            type: String,
            required: false,
            default: "gray-700"
        },
        /**
         * Classe para o tooltip
         */
        contentClassTooltip: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Prop para para eventos de outros components
         */
        bindPersonal: {
            type: [Boolean, Object],
            required: false,
            default: false
        },
        /**
         * Prop para para eventos de outros components
         */
        onPersonal: {
            type: [Boolean, Object],
            required: false,
            default: false
        },
        /**
         * Mascara para o input
         */
        mask: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Desabilita as interacoes do input
         */
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        /** 
         * Desabilita o componente
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Adiciona o icone de limpar o campo
         */
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Habilita o contador de caracteres
         */
        counter: {
            type: [Boolean, Number],
            required: false,
            default: false
        },
        /**
         * Define o maximo de caracteres para o contador
         */
        maxLength: {
            type: Number,
            required: false,
            default: null
        },
        /**
         * Adiciona name ao input
         */
        name: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * ID do input
         */
        id: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            content: this.value,
            icon: null,
            show: false,
            label_hover: false
        };
    },
    computed: {
        show_tooltip() {
            return this.label_hover && this.tooltipText !== null && this.tooltipText !== "";
        }
    },
    watch: {
        content() {
            /**
             * Evento de input, disparado sempre que o conteudo do campo for alterado
             * 
             * @property {string} content Conteudo do campo
             * 
             * @event input
             * @vue
             */

            if (this.rules.length == 0 && this.content == null ) {
                this.setIcon(true);
            } else {
                if (this.$refs.error.$refs.input.name == "dataFim") { //Se o nome do campo for DataFim, usa a fun��o de Hack para validar
                    this.alterarDataNoDOM(0, this.content, false);
                }

                if (this.content != null) {
                    if (this.content.length == 0) {
                        this.setIcon(true);
                    } else {
                        this.setIcon();
                    }
                } else {
                    this.setIcon(true);
                }
            }
            this.$emit("input", this.content);

        },
        value() {
            this.content = this.value;
        }
    },
    mounted() {
        this.content = this.value;
        if (this.$refs.error.$refs.input.name == "dataFim") {
            this.alterarDataNoDOM(1, this.content, true);
        }
    },
    methods: {
        /**
         * Emite um evento informando que a tecla enter foi pressionada no campo
         */
        enterPressed() {
            /**
             * Evento que informa que a tecla enter foi pressioanda enquanto o campo estava em foco
             * 
             * @property {string} content Conteudo do campo
             * 
             * @event enterPressed
             * @vue
             */
            this.$emit("enterPressed", this.content);
        },
        /**
         * Funcao para setar o icone de validacao do input
         */
        setIcon(clear) {

            if (clear) {
                this.icon = "";
            } else if (this.$refs.error.validate()) {
                this.icon = " text-success fa-check";
            } else {
                this.icon = " text-danger fa-exclamation";
            }
        },
        /**
         * Funcao para modificar o estado do popover
         */
        togglePopover() {
            this.show = !this.show;
        },
        /**
         * Funcao para desabilitar popover
         */
        closePopover() {
            this.show = false;
        },
        /**
         * Funcao de blur do textfield
         */
        functionBlur() {
            /**
             * Evento que informa que a tecla enter foi pressioanda enquanto o campo estava em foco
             * 
             * @property {string} content Conteudo do campo
             * 
             * @event blur
             * @vue
             */
            this.$emit("blur", this.content);
        },
        /**
         * Hack para modificar a data no Model caso seja a data de hoje
         * fazendo assim, aparecer o icone "text-success fa-check" no input de datepicker selecionado
         * 
         * Usado para o campo dataFim no form de Campanha
         * 
         * @var {Int} dias quantidade de dias a adicionar na data atual
         * @var {String} content conteudo do campo
         * @var {Boolean} mounted criado no mounted ou no watch
         */
        alterarDataNoDOM(dias, content, mounted) {
            if (this.$refs.error.$refs.input.name == "dataFim") {
                let day = "";
                if (dias > 0) {
                    day = dayjs().day(dayjs().day() + dias).format("DD/MM/YYYY"); /*Inseri data atual*/
                } else {
                    day = dayjs().day(dayjs().day()).format("DD/MM/YYYY"); /*Inseri data atual*/
                }

                if (mounted == true) {
                    if (day != content) {
                        this.content = day;
                    }
                }

                if (mounted == false) {
                    if (day != content) {
                        this.content = this.value;
                    }
                }
            }
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters>
                <v-col cols="12">
                    <base-tooltip :color="colorTooltip" :show="show_tooltip" :disabled="!tooltipText" :text="tooltipText">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-hover v-model="label_hover" :open-delay="500">
                                    <v-row v-if="title" no-gutters>
                                        <v-col>
                                            <span class="body-2 text-gray-700" v-html="title"></span> <i v-if="useIconValidation" class="fas" :class="icon"></i>
                                        </v-col>
                                    </v-row>
                                </v-hover>
                                <v-row no-gutters>
                                    <v-col class="pt-1">
                                        <v-text-field
                                            v-model="content"
                                            v-mask="mask"
                                            v-bind="bindPersonal"
                                            v-on="onPersonal"
                                            solo
                                            class="mb-n3"
                                            ref="error"
                                            hide-details="auto"
                                            :autocomplete="autocomplete"
                                            :rules="rules"
                                            :label="label | htmlEntities"
                                            :required="required"
                                            :type="type"
                                            :readonly="readonly"
                                            :disabled="disabled"
                                            :clearable="clearable"
                                            :counter="counter"
                                            :maxlength="maxLength"
                                            :name="name"
                                            @keydown.enter.prevent="enterPressed"
                                            @update:error="setIcon"
                                            @blur="functionBlur"
                                            @focus="$emit('focus')"
                                            :id="id"
                                        >
                                            <template v-slot:message="{ message }">
                                                <v-row no-gutters justify="start" class="flex-nowrap">
                                                    <v-col cols="auto">
                                                        <v-icon x-small left color="error-500">mdi-alert</v-icon>
                                                    </v-col>
                                                    <v-col class="text-error-500 text-start">
                                                        <span class="text-error-500 text-start caption" v-html="message"></span>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-text-field> 
                                    </v-col>
                                </v-row >
                                <v-row v-if="hint && !popoverContent" no-gutters class="text-center">
                                    <v-col>
                                        <span class="caption" v-html="hint"></span>                
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </base-tooltip>
                    <v-row v-if="popoverContent && hint" no-gutters class="text-end">
                        <v-col>
                            <base-tooltip :content-class="contentClassTooltip" :show="show" :text="popoverContent">
                                <a class="body-2 text-primary-blue-500"  href="#" v-click-outside="closePopover" @click="togglePopover" v-html="hint"></a>
                            </base-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};