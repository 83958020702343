import PageHeader from "../../components/moleculas/PageHeader.js";
import PgTour from "../../components/organismos/PgTour.js";
import BaseButton from "../../components/atomos/BaseButton.js";
import FormFilterAvulsoApi from "./components/forms/FormFilterAvulsoApi.js";
import ProgressCard from "../../components/moleculas/ProgressCard";
import BaseDataTable from "../../components/organismos/BaseDataTable";
import CardContentClass from "../../classes/CardContentClass";
import ExportAvulso from "./components/ExportAvulso";
import CanalIconDescription from "../../components/moleculas/CanalIconDescription";
import BaseSpanLimited from "../../components/atomos/BaseSpanLimited";
import { DataTableHeaderClass } from "../../classes/DataTableHeaderClass";
import { ProgressStatusCountClass } from "../../classes/ProgressStatusCountClass";
import { TemplateService } from "../../../../js/services/TemplateService";
import TableThumb from "../../components/moleculas/TableThumb.js";
import TemplateUtils from "../../utils/TemplateUtils.js";
import ModeloIconDescription from "../../components/atomos/ModeloIconDescription.js";
import StatusAvulsoApi from "../../components/moleculas/StatusAvulsoApi";

const template_service = new TemplateService();

/**
 * @requires {@link PageHeader}
 * @requires {@link PgTour}
 * @requires {@link BaseButton}
 * @requires {@link FormFilterAvulsoApi}
 * @requires {@link ProgressCard}
 * @requires {@link BaseDataTable}
 * @requires {@link ExportAvulso}
 * @requires {@link CanalIconDescription}
 * @requires {@link ModeloIconDescription}
 * @required {@link StatusAvulsoApi}
 *
 * Pagina que realiza a listagem dos avulsos
 * @displayName AvulsoApiPage
 * @category Page / Avulsos/API
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PageHeader,
        PgTour,
        BaseButton,
        FormFilterAvulsoApi,
        ProgressCard,
        BaseDataTable,
        ExportAvulso,
        CanalIconDescription,
        BaseSpanLimited,
        TableThumb,
        ModeloIconDescription,
        StatusAvulsoApi
    },
    filters: {
        format_date: function (date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY HH:mm:ss");
            } else {
                return "-";
            }
        },
        format_empty: function (value) {
            if (value != null) {
                if (value.length > 0) {
                    return value;
                } else {
                    return "-";
                }
            } else {
                return "-";
            }
        }
    },
    data() {
        return {
            type_product: this.$cookie === undefined ? getCookie("type_product") : this.$cookie.get("type_product"),
            template_utils: new TemplateUtils(),
            content_table: [],
            filter_data: {
                id_empresa: null,
                status: null,
                campo_informado: null,
                telefone: null,
                data_inicio: dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                data_fim: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                codigo_agrupador: null
            },
            dt_ini: dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            dt_fim: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            table_loading: false,
            cards_loading: false,
            steps: [
                {
                    target: "[data-v-step='1']",
                    title: "Filtros",
                    content: "J&aacute; carregamos a tela com alguns filtros aplicados, que exibem os envios mais recentes. Voc&ecirc; pode refinar a busca de acordo com a sua necessidade.",
                    date: "2022-10-07 00:00:00"
                },
                {
                    target: "[data-v-step='2']",
                    title: "Solicitados",
                    content:
                        "Quando os envios s&atilde;o solicitados, seja por Painel ou API, o sistema faz uma higieniza&ccedil;&atilde;o dos registros removendo os inv&aacute;lidos, repetidos e bloqueados pela blacklist, de acordo com a configura&ccedil;&atilde;o do cliente (carteira).<br><br>Apenas os registros que est&atilde;o aptos para envio s&atilde;o <b>carregados</b>."
                },
                {
                    target: "[data-v-step='3']",
                    title: "Carregados",
                    content:
                        "Os registros carregados passam por um filtro no momento do envio, nesse filtro existem os bloqueios por regra de neg&oacute;cio previamente cadastrados. Os registros que forem identificados por esse filtro t&ecirc;m seu envio cancelado.<br><br>Apenas os registros que est&atilde;o aptos para envio ser&atilde;o <b>enviados</b>."
                },
                {
                    target: "[data-v-step='4']",
                    title: "Enviados",
                    content:
                        "As mensagens s&atilde;o enviadas para a operadora e cabe a ela retornar a informa&ccedil;&atilde;o se a mensagem foi entregue ou n&atilde;o entregue ao destinat&aacute;rio.<br><br>Essa confirma&ccedil;&atilde;o pode levar at&eacute; 72h para ser atualizada."
                },
                {
                    target: "[data-v-step='5']",
                    title: "Download",
                    content:
                        "Voc&ecirc; pode baixar as informa&ccedil;&otilde;es da tabela em excel (.csv) ou texto (.txt)"
                },
                {
                    target: "[data-v-step='7']",
                    title: "Canal",
                    content:
                        "Canal utilizado para envio da mensagem pode ser SMS ou RCS, e fica listado aqui."
                },
                {
                    target: "[data-v-step='8']",
                    title: "Radar",
                    content:
                        "Caso a mensagem receba retornos do destinat&aacute;rio, a quantidade de mensagens trocadas aparecer&aacute; aqui como um link para a tela Jobs > Radar."
                },
                {
                    target: "[data-v-step='10']",
                    title: "Mostrar colunas",
                    content:
                        "Voc&ecirc; pode escolher quais s&atilde;o as colunas que deseja visualizar na tabela."
                },
                {
                    target: "[data-v-step='11']",
                    title: "Mostrar linhas",
                    content:
                        "Voc&ecirc; pode escolher quantas linhas por p&aacute;gina deseja ver."
                },
                {
                    target: "[data-v-step='12']",
                    title: "Ordenar",
                    content:
                        "Voc&ecirc; ordenar os registros por coluna de forma crescente ou decrescente, basta clicar nas setas <i class='text-primary fas fa-sort'></i> quando dispon&iacute;vel."
                }
            ],
            custom_slots: [
                "CD_PRODUTO",
                "NM_EMPRESA",
                "NR_DESTINO",
                "STATUS",
                "DT_ENVIO",
                "CD_AGRUPADOR",
                "RADAR",
                "OPERADORA",
                "TP_TEMPLATE",
                "DS_TEMPLATE",
                "ID_FALLBACK"
            ],
            progress_status_count: new ProgressStatusCountClass({})
        };
    },
    computed: {
        server_side_config() {
            if (Object.keys(this.filter_data).length > 0) {
                return {
                    request_to: "API",
                    url: "/avulso-api/list",
                    data: this.filter_data
                };
            } else {
                return null;
            }
        },
        filterData() {
            return {
                id_empresa: this.filter_data.id_empresa,
                status: this.filter_data.status,
                campo_informado: this.filter_data.campo_informado,
                telefone: this.filter_data.telefone ? this.filter_data.telefone.match(/\d+/g).join("") : null,
                data_inicio: dayjs(this.filter_data.data_inicio).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                data_fim: dayjs(this.filter_data.data_fim).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                codigo_agrupador: this.filter_data.codigo_agrupador
            };
        },
        /**
         * Apresenta a descri��o da p�gina
         * @returns {string}
         * @vue
         */
        pageDescription() {
            return `Aqui voc&ecirc; pode pesquisar todos os envios avulsos realizados via API ou pelo Envio r&aacute;pido.`;
        },
        /**
         * Monta os valores nos card respec�ficos
         * @returns {string}
         * @vue
         */
        cardImportedData() {
            return {
                title: "Importados",
                total: this.calcSolicitados(this.progress_status_count),
                tooltip_text: "Total de registros nos arquivos importados para envios avulsos.",
                disable_tooltip: false,
                list_content: [
                    new CardContentClass({
                        name: "Higienizados",
                        divisor: this.calcSolicitados(this.progress_status_count),
                        dividendo: this.progress_status_count.HIGIENIZADOS,
                        tooltip: "Na higieniza&ccedil;&atilde;o, s&atilde;o removidos os registros inv&aacute;lidos, repetidos e bloqueados pela blacklist.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Processados",
                        divisor: this.calcSolicitados(this.progress_status_count),
                        dividendo: this.calcCarregados(this.progress_status_count),
                        tooltip: "Apenas os registros v&aacute;lidos e permitidos s&atilde;o processados para envio.",
                        disable_tooltip: false
                    })
                ]
            };
        },
        cardProcessedData() {
            return {
                title: "Carregados",
                total: this.calcCarregados(this.progress_status_count),
                tooltip_text: "Total de registros que foram processados para envio",
                disable_tooltip: false,
                list_content: [
                    new CardContentClass({
                        name: "Cancelados",
                        divisor: this.calcCarregados(this.progress_status_count),
                        dividendo: this.progress_status_count.CANCELED,
                        tooltip: "Alguns registros podem ser cancelados por regras de neg&oacute;cio ou manualmente.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Aguardando envio",
                        divisor: this.calcCarregados(this.progress_status_count),
                        dividendo: this.calcAguardandoEnvio(this.progress_status_count),
                        tooltip:
                            "Os registros ir&atilde;o aguardar o envio at&eacute; a data e hora agendada, ou de acordo com regras de hor&aacute;rios que podem ser cadastradas por cliente (carteira).",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Enviados",
                        divisor: this.calcCarregados(this.progress_status_count),
                        dividendo: this.calcEnviados(this.progress_status_count),
                        tooltip: "Voc&ecirc; tem acesso ao andamento dos envios em tempo real.",
                        disable_tooltip: false
                    })
                ]
            };
        },
        cardSendData() {
            let list_content = [
                new CardContentClass({
                    name: "Entregues",
                    divisor: this.calcEnviados(this.progress_status_count),
                    dividendo: this.progress_status_count.DELIVERED,
                    tooltip: "Mensagens com entrega confirmada ao destinat&aacute;rio.",
                    disable_tooltip: false
                })
            ];
            if (this.type_product === "RCS") {
                list_content.push(new CardContentClass({
                    name: "Entregues SMS alternativo",
                    divisor: this.calcEnviados(this.progress_status_count),
                    dividendo: this.progress_status_count.TOTAL_FALLBACK,
                    tooltip: "Mensagens alternativas de SMS com entrega confirmada ao destinat&aacute;rio",
                    disable_tooltip: false
                }));
            }
            list_content.push(...[
                new CardContentClass({
                    name: "N&atilde;o Entregues",
                    divisor: this.calcEnviados(this.progress_status_count),
                    dividendo: this.progress_status_count.NOT_DELIVERED + this.progress_status_count.REJECTED,
                    tooltip:
                        "Mensagens que n&atilde;o foram entregues ao destinat&aacute;rio. Isso pode acontecer se o aparelho estiver desligado ou fora de &aacute;rea de servi&ccedil;o ou se o n&uacute;mero estiver inativo.",
                    disable_tooltip: false
                }),
                new CardContentClass({
                    name: "Aguardando retorno",
                    divisor: this.calcEnviados(this.progress_status_count),
                    dividendo: this.calcAguardandoRetorno(this.progress_status_count),
                    tooltip:
                        "Mensagens enviadas e sem retorno do status de entregue ou n&atilde;o entregue. Essa informa&ccedil;&atilde;o pode levar at&eacute; 24hs para retornar e nesse per&iacute;odo o status fica como aguardando retorno.",
                    disable_tooltip: false
                }),
            ]);
            return {
                title: "Enviados",
                total: this.calcEnviados(this.progress_status_count),
                tooltip_text: "Total de registros que foram enviados &agrave;s operadoras / fornecedores",
                disable_tooltip: false,
                list_content: list_content
            };
        },
        cardResponseData() {
            return {
                title: this.type_product === "RCS" ? "Sess&otilde;es RCS" : "Radar",
                total: this.progress_status_count.TOTAL_RADAR,
                tooltip_text: "Total de respostas recebidas sobre as mensagens que foram entregues",
                disable_tooltip: false,
                link_text: this.progress_status_count.TOTAL_RADAR > 0 ? "Ver retornos" : null,
                link_callback: () => {
                    if (this.type_product === "RCS") {
                        $.doGet("RadarApp", {
                            data_inicio: this.dt_ini,
                            data_fim: this.dt_fim
                        });
                    } else {
                        $.doPost("list-radar.php", {
                            sh_dtini: dayjs().format("DD/MM/YYYY"),
                            sh_dtfim: dayjs().format("DD/MM/YYYY")
                        });
                    }
                },
                list_content: [
                    new CardContentClass({
                        name: "Retornos sobre entregues",
                        divisor: this.progress_status_count.DELIVERED,
                        dividendo: this.progress_status_count.TOTAL_RADAR,
                        tooltip:
                            "Mensagens que foram recebidas pelos destinat&aacute;rios.<br><br>Clique sobre a quantidade de retornos e veja os detalhes das mensagens e os status atual da sess&atilde;o.",
                        disable_tooltip: false
                    })
                ]
            };
        },
        /**
         * Configura os headers das colunas presentes na tabela
         * @returns {DataTableHeaderClass} Objeto header
         * @vue
         */
        table_headers () {
            let headers = [
                new DataTableHeaderClass({
                    text: "Canal",
                    value: "CD_PRODUTO",
                    cellClass: "text-nowrap",
                    sortable: false,
                    tooltipText: "Canal utilizado para disparo da mensagem",
                    stepTutorial: 7
                }),
                new DataTableHeaderClass({
                    text: "Cliente",
                    value: "NM_EMPRESA",
                    cellClass: "text-nowrap",
                    sortable: false,
                    visibility: "sm",
                    tooltipText: "Cliente (carteira) vinculado ao envio."
                }),
                new DataTableHeaderClass({
                    text: "Telefone",
                    value: "NR_DESTINO",
                    cellClass: "text-nowrap",
                    sortable: false,
                    tooltipText: "N&uacute;mero de telefone do destinat&aacute;rio."
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("Status"),
                    cellClass: "text-nowrap",
                    value: "STATUS",
                    align: "center",
                    sortable: false,
                    tooltipText: "Situa&ccedil;&atilde;o (status) atual da mensagem."
                }),
                new DataTableHeaderClass({
                    text: "Envio",
                    value: "DT_ENVIO",
                    cellClass: "text-nowrap",
                    visibility: "md",
                    tooltipText: "Data e hora do envio da mensagem.",
                    stepTutorial: 12
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("C&oacute;d. agrupador"),
                    value: "CD_AGRUPADOR",
                    align: "center",
                    cellClass: "text-nowrap",
                    sortable: false,
                    visibility: "lg",
                    tooltipText: "C&oacute;digo agrupador."
                }),
            ];
            //Se o produto for RCS o nome da coluna sra "Sessoes" caso contrario "Radar"
            if (this.type_product === "RCS") {
                headers.push(new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("Sess&otilde;es"),
                    value: "RADAR",
                    align: "center",
                    tooltipText: "Mensagens de retorno dos destinat&aacute;rios",
                    visibility: "lg"
                }));
            } else if (this.type_product === "SMS") {
                headers.push(new DataTableHeaderClass({
                    text: "Radar",
                    value: "RADAR",
                    align: "center",
                    tooltipText: "Mensagens de retorno dos destinat&aacute;rios",
                    visibility: "lg"
                }));
            }
            if (this.type_product === "RCS" || this.type_product === "SMS") {
                headers.push(new DataTableHeaderClass({
                    text: "Operadora",
                    value: "OPERADORA",
                    align: "center",
                    cellClass: "text-nowrap",
                    sortable: false,
                    visibility: "xl",
                    tooltipText: "Operadora de telefonia da mensagem enviada."
                }));
            }
            // Se o produto for RCS , mostra qual tipo de template foi selecionado (Carousel, Card, Text, Imagem, ...)
            if (this.type_product === "RCS") {
                headers.push(new DataTableHeaderClass({
                    text: "Modelo",
                    value: "TP_TEMPLATE",
                    visibility: "sm",
                    tooltipText: "Tipo de template (Texto, m&iacute;dia ou carrousel)"
                }));
            }
            headers.push(
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("Conte&uacute;do"),
                    value: "DS_TEMPLATE",
                    sortable: false,
                    width: "130px",
                    visibility: "sm",
                    tooltipText: "Texto resumido do conte&uacute;do da template"
                })
            );
            //Se o produto selecionado for RCS, adiciona a coluna SMS Alternativo na tabela.
            if (this.type_product === "RCS") {
                return headers.concat(new DataTableHeaderClass({
                    text: "SMS alternativo",
                    value: "ID_FALLBACK",
                    visibility: "sm",
                    tooltipText: "Icone informativo mostrando se foi realizado envio de SMS alternativo ou n&atilde;o."
                }));
            } else {
                return headers;
            }
        },
    },
    mounted() {
        let search_params = new URLSearchParams(window.location.search);
        let cd_agrupador = search_params.get("cd_agrupador");

        if (cd_agrupador && cd_agrupador !== undefined) {
            this.filter_data.codigo_agrupador = cd_agrupador;
        }

        this.getListAvulsos();

        setInterval(() => {
            this.getListAvulsos();
        }, 180000);
    },
    methods: {
        ...window.Vuex.mapActions("avulsoapi", ["ActionDoGetProgressAvulsos"]),
        /**
         * Submit dos filtros
         * @param {Object} data
         * @vue
         */
        filterSubmit(data) {
            this.dt_ini = data.data_inicio;
            this.dt_fim = data.data_fim;
            this.filter_data = {
                id_empresa: data.id_empresa,
                status: data.status,
                campo_informado: data.campo_informado,
                telefone: data.telefone,
                data_inicio: data.data_inicio,
                data_fim: data.data_fim,
                codigo_agrupador: data.codigo_agrupador
            };

            this.getListAvulsos();
        },
        /**
         * Define dia inicial do filtro
         * @param {String} start_date
         * @vue
         */
        setFilterStartDate(start_date) {
            this.filter_data.data_inicio = start_date;
        },
        /**
         * Define dia final do filtro
         * @param {String} end_date
         * @vue
         */
        setFilterEndDate(end_date) {
            this.filter_data.data_fim = end_date;
        },
        /**
         * Busca a lista de avulsos a serem exibidos na tela, conforme o filtro do form
         * @vue
         */
        getListAvulsos() {
            this.table_loading = true;
            this.cards_loading = true;

            /**
             * Obt�m dados para o ProgressCard
             * @param filter_data {Array} Filtros do formul�rio
             * @return {ProgressStatusCountClass} statusCount
             * @vue
             */
            this.ActionDoGetProgressAvulsos({
                id_empresa: this.filter_data.id_empresa,
                status: this.filter_data.status,
                campo_informado: this.filter_data.campo_informado,
                telefone: this.filter_data.telefone ? this.filter_data.telefone.match(/\d+/g).join("") : null,
                data_inicio: dayjs(this.filter_data.data_inicio).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                data_fim: dayjs(this.filter_data.data_fim).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                codigo_agrupador: this.filter_data.codigo_agrupador
            }).then(
                (statusCount) => {
                    this.progress_status_count = statusCount;
                    this.table_loading = false;
                    this.cards_loading = false;

                }
            );
        },
        showDetails(item) {
            this.template_selected = item;
            this.show_dialog_details = true;
        },
        /**
         * Calcula os Carregados com base em todos os par�metros exceto o HIGIENIZADOS
         * @param SENT {number}
         * @param DELIVERED {number}
         * @param NOT_DELIVERED {number}
         * @param NOT_SENT {number}
         * @param WAITING {number}
         * @param CANCELED {number}
         * @param REJECTED {number}
         * @returns {number}
         * @vue
         */
        calcCarregados({
            SENT,
            DELIVERED,
            NOT_DELIVERED,
            NOT_SENT,
            WAITING,
            CANCELED,
            REJECTED
        }) {
            return (
                SENT +
                DELIVERED +
                NOT_DELIVERED +
                NOT_SENT +
                WAITING +
                CANCELED +
                REJECTED
            );
        },
        /**
         * Calcula os Solicitados somando os Carregados com os HIGIENIZADOS
         * @param {ProgressStatusCountClass}
         * @returns {number}
         * @vue
         */
        calcSolicitados({
            SENT,
            DELIVERED,
            NOT_DELIVERED,
            NOT_SENT,
            WAITING,
            CANCELED,
            HIGIENIZADOS,
            REJECTED
        }) {
            return (
                this.calcCarregados({
                    SENT,
                    DELIVERED,
                    NOT_DELIVERED,
                    NOT_SENT,
                    WAITING,
                    CANCELED,
                    REJECTED
                }) + HIGIENIZADOS
            );
        },
        /**
         * Calcula os que est�o aguardando envio subtraindo os Cancelados e os Enviados dos que foram Carregados
         * @param SENT {number}
         * @param DELIVERED {number}
         * @param NOT_DELIVERED {number}
         * @param NOT_SENT {number}
         * @param WAITING {number}
         * @param CANCELED {number}
         * @param REJECTED {number}
         * @returns {number}
         * @vue
         */
        calcAguardandoEnvio({
            SENT,
            DELIVERED,
            NOT_DELIVERED,
            NOT_SENT,
            WAITING,
            CANCELED,
            REJECTED
        }) {
            return (
                this.calcCarregados({
                    SENT,
                    DELIVERED,
                    NOT_DELIVERED,
                    NOT_SENT,
                    WAITING,
                    CANCELED,
                    REJECTED
                }) -
                CANCELED -
                this.calcEnviados({
                    SENT,
                    DELIVERED,
                    NOT_DELIVERED,
                    NOT_SENT,
                    WAITING,
                    CANCELED,
                    REJECTED
                })
            );
        },
        /**
         * Calcula o total de Enviados com base nos parametros de enviados Entregues e N�o entregues
         * @param SENT {number}
         * @param DELIVERED {number}
         * @param NOT_DELIVERED {number}
         * @param REJECTED {number}
         * @returns {number}
         * @vue
         */
        calcEnviados({ SENT, DELIVERED, NOT_DELIVERED, REJECTED }) {
            return SENT + DELIVERED + NOT_DELIVERED + REJECTED;
        },
        /**
         * Calcula os que est�o aguardando retorno substraindo a quantidade Entregues, de n�o Entregues e os Rejeitados do total enviado
         * @param {ProgressStatusCountClass}
         * @returns {number}
         * @vue
         */
        calcAguardandoRetorno({
            SENT,
            DELIVERED,
            NOT_DELIVERED,
            NOT_SENT,
            WAITING,
            CANCELED,
            HIGIENIZADOS,
            REJECTED
        }) {
            return (
                this.calcEnviados({
                    SENT,
                    DELIVERED,
                    NOT_DELIVERED,
                    NOT_SENT,
                    WAITING,
                    CANCELED,
                    HIGIENIZADOS,
                    REJECTED
                }) -
                DELIVERED -
                NOT_DELIVERED -
                REJECTED
            );
        },
        /**
         * Metodo que faz a tratativa do objeto loading recebendo o $emit refreshServerSideData do componente dataBaseTable
         */
        dataTable(ev) {
            if (ev.data[0]) {
                this.table_loading = false;
            } else {
                this.table_loading = true;
            }
            this.content_table = ev.data; // recebe o conteudo da tabela para enviar ao componente ExportAvulso
        },
        /**
         * Prepara os textos que ser�o exibidos pelo componente tableThumb
         * @param {item}
         * @returns {string}
         * @vue
         */
        prepareDsTemplate(item) {
            let text = "";
            if (item.TP_MENSAGEM == "M") {
                if (item.DS_MENSAGEM != "") {
                    text = item.DS_MENSAGEM;
                } else {
                    text = item.DS_TEMPLATE;
                }
            } else {
                if (item.DS_TEMPLATE != "") {
                    if (item.TP_TEMPLATE == "M") {
                        /** Quando for um tipo de template de msg, usa a fun��o preencherCamposTemplate() 
                          * para concatenar os paramentos com a string do template
                          */
                        let conteudo_mensagem =
                            template_service.preencherCamposTemplate(
                                item.DS_TEMPLATE,
                                JSON.parse(item.DS_PARAMETROS)
                            );

                        text = conteudo_mensagem;
                    } else {
                        if (item.TP_TEMPLATE == "C" || item.TP_TEMPLATE == "R") {
                            text = item.DS_TEMPLATE;
                        } else {
                            text = item.DS_PARAMETROS;
                        }
                    }
                } else {
                    text = item.DS_TEMPLATE;
                }
            }
            if (item.CD_PRODUTO == "SMS" || item.CD_PRODUTO == null) {
                text = item.MENSAGEM;
            }
            return text;
        },
        /**
         * Direciona para pagina Radar ou Sess�es
         * @param {item}
         * @vue
         */
        postRadar(item) {
            if (item.CD_PRODUTO == "RCS") {
                $.doGet("RadarApp", {
                    data_inicio: this.dt_ini,
                    data_fim: this.dt_fim,
                    telefone: item.NR_DESTINO
                });
            } else {
                $.doPost("list-radar.php", {
                    sh_dtini: dayjs(item.DT_ENVIO).format("DD/MM/YYYY"),
                });
            }
        },
        /**
         * Retorna tipo de mensagem a ser apresentado na coluna Modelo
         * @param {item}
         * @returns {string}
         * @vue
         */
        messageModel(item) {
            return item.TP_TEMPLATE != null ? item.TP_TEMPLATE : item.TP_MENSAGEM;
        },
        /**
         * Pega a quantidade de imagens/paginas presentes em um RCS do tipo Caroseul
         * @param {item}
         * @returns {number}
         * @vue
         */
        countCarouselPage(item) {
            let params = "";
            // Verifica se o envio � do tipo carousel
            if (item.TP_MENSAGEM == "R" || item.TP_TEMPLATE == "R") {
                // Se exitir valor em DS_TEMPLATE, utiliza essa coluna para buscar os dados do carousel (envio utilizando um template criado)
                if (item.DS_TEMPLATE) {
                    params = JSON.parse(item.DS_TEMPLATE);
                // Se n�o, pega as informa��es na coluna DS_PARAMETROS (envios do tipo carousel criado na hora (envio r�pido))
                } else {
                    params = JSON.parse(item.DS_PARAMETROS);
                }
                // Retorna o numero de p�ginas do carousel.
                return params.contents.length;
            }
        },
        /**
         * Retorna os dados de fallback para mostrar no preview
         * @vue
         * @param {Object} item;
         * @param {String} type;
         */
        getFallbackPreview(item, type) {
            let fallback_preview = "";

            if (item.ID_FALLBACK) {
                switch (type) {
                case "DS_TEMPLATE":
                    fallback_preview = item.DS_MENSAGEM;
                    break;
                default:
                    fallback_preview = item.MENSAGEM;
                    break;
                }
            } else {
                fallback_preview = null;
            }

            return fallback_preview;
        },
    },
    template:
    /*html*/
    `
    <v-container>
        <v-card elevation="0" width="100%">
            <v-card-text>
                <v-row class="px-0 px-md-6" no-gutters>
                    <v-col cols="12">
                        <page-header
                            :tutorial="true"
                            title="Avulsos / API"
                            :description="pageDescription"
                            old-page="/AvulsoApiV1"
                            name-old-page="Avulsos/API"
                            @tutorial="$refs.tour.start()"
                        >
                            <template #button>
                                <v-row no-gutters>
                                    <v-col>
                                        <base-button
                                            primary
                                            icon="mdi-plus"
                                            label="Envio R&aacute;pido"
                                            block
                                            small
                                            tooltip-text="Voc&ecirc; pode realizar envios avulsos aqui pelo painel."
                                            @click="window.location.href = '/EnvioRapido'"
                                        />
                                    </v-col>
                                </v-row>
                            </template>
                        </page-header>
                    </v-col>
                </v-row>
                <v-row class="px-0 px-md-6" no-gutters>
                    <v-col cols="12" class="filtro-content pb-5">
                        <form-filter-avulso-api
                            v-model="filter_data"
                            @formSubmit="filterSubmit"
                            @setFilterStartDate="setFilterStartDate"
                            @setFilterEndDate="setFilterEndDate"
                        />
                    </v-col>
                </v-row>
                <v-row class="px-0 px-md-6 my-4 gap-row-6" :data-v-step="2">
                    <v-col cols="12" sm="6" class="d-flex">
                        <progress-card
                            :list-content="cardImportedData.list_content"
                            :title="cardImportedData.title"
                            :total="cardImportedData.total"
                            :loading="cards_loading"
                            :tooltip-text="cardImportedData.tooltip_text"
                            :disabled-tooltip="cardImportedData.disable_tooltip"
                            icon="$importadosCardsIcon"
                            background-color="super-dark-grey"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex">
                        <progress-card
                            :list-content="cardProcessedData.list_content"
                            :title="cardProcessedData.title"
                            :total="cardProcessedData.total"
                            :loading="cards_loading"
                            :tooltip-text="cardProcessedData.tooltip_text"
                            :disabled-tooltip="cardProcessedData.disable_tooltip"
                            icon="$processadosCardsIcon"
                            background-color="super-dark-grey"
                        />
                    </v-col>
                    <v-col cols="12" sm="8" class="d-flex">
                        <progress-card
                            :list-content="cardSendData.list_content"
                            :title="cardSendData.title"
                            :total="cardSendData.total"
                            :loading="cards_loading"
                            :tooltip-text="cardSendData.tooltip_text"
                            :disabled-tooltip="cardSendData.disable_tooltip"
                            icon="$enviadosCardsIcon"
                            background-color="dark-primary"
                        />
                    </v-col>
                    <v-col cols="12" sm="4" class="d-flex">
                        <progress-card
                            :list-content="cardResponseData.list_content"
                            :title="cardResponseData.title"
                            :total="cardResponseData.total"
                            :loading="cards_loading"
                            :tooltip-text="cardResponseData.tooltip_text"
                            :disabled-tooltip="cardResponseData.disable_tooltip"
                            :link-text="cardResponseData.link_text"
                            :link-callback="cardResponseData.link_callback"
                            icon="$radarCardsIcon"
                            background-color="dark-primary"
                        />
                </v-col>
                </v-row>
                <v-row class="px-0 px-md-6" no-gutters>
                    <export-avulso
                        :loading="table_loading"
                        :filter-data="filterData"
                        :content-table="content_table"
                    />
                </v-row>
                <v-row class="px-0 px-md-6">
                    <v-col cols="12">
                        <base-data-table
                            :headers="table_headers"
                            :custom-slots="custom_slots"
                            :server-side-config="server_side_config"
                            item-key="ID"
                            sort-by="DT_ENVIO"
                            :sort-desc="true"
                            :show-page-length="true"
                            :loading="table_loading"
                            :step-tutorial-custom-columns="10"
                            :step-tutorial-page-length="11"
                            @refreshServerSideData="dataTable"
                        >
                            <template #CD_PRODUTO="props">
                                <canal-icon-description
                                    :product-name="props.item.CD_PRODUTO"
                                />
                            </template>
                            <template #NM_EMPRESA="props">
                                {{ props.item.NM_EMPRESA | format_empty }}
                            </template>
                            <template #STATUS="props">
                                <status-avulso-api
                                    :status="props.item.STATUS | translateStatus"
                                />
                            </template>
                            <template #NR_DESTINO="props">
                                {{ props.item.NR_DESTINO | maskPhone }}
                            </template>
                            <template #DT_ENVIO="props">
                                {{ props.item.DT_ENVIO | format_date }}
                            </template>
                            <template #CD_AGRUPADOR="props">
                                <div>
                                    <span v-if="['NULL', null, ''].indexOf(props.item.CD_AGRUPADOR) > -1">-</span>
                                    <span v-else>{{ props.item.CD_AGRUPADOR }}</span>
                                </div>
                            </template>
                            <template #RADAR="props">
                                <div>
                                    <span v-if="props.item.RADAR == 0">{{ props.item.RADAR }}</span>
                                    <a v-else href="javascript:void(0)" @click="postRadar(props.item)">{{ props.item.RADAR }}</a>
                                </div>
                            </template>
                            <template #OPERADORA="props">
                                {{ props.item.OPERADORA ?? "-" }}
                            </template>
                            <template #TP_TEMPLATE="props">
                                <modelo-icon-description 
                                    class="body-2 flex-nowrap" 
                                    :model-name="messageModel(props.item)"
                                    :sub-title="countCarouselPage(props.item)"
                                ></modelo-icon-description>
                            </template>
                            <template #DS_TEMPLATE="props">
                                <v-row no-gutters class="py-1">
                                    <v-col cols="12">
                                        <v-card elevation="0" class="rounded-0" max-width="130px">
                                            <table-thumb
                                                :ds-template="prepareDsTemplate(props.item)"
                                                :ds-template-fallback="getFallbackPreview(props.item)"
                                                highlight="main"
                                                :type-template="props.item.TP_TEMPLATE != null ? props.item.TP_TEMPLATE : props.item.TP_MENSAGEM"
                                            />
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </template>
                            <template #ID_FALLBACK="props">
                                <v-row v-if="props.item.ID_FALLBACK" no-gutters class="py-1">
                                    <v-col cols="12">
                                        <v-card elevation="0" class="rounded-0" max-width="130px">
                                            <table-thumb
                                                :ds-template="prepareDsTemplate(props.item)"
                                                :ds-template-fallback="getFallbackPreview(props.item)"
                                                highlight="fallback"
                                                :type-template="props.item.TP_TEMPLATE != null ? props.item.TP_TEMPLATE : props.item.TP_MENSAGEM"
                                            />
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <div v-else class="body-2 text-gray-900 align-center">-</div>
                            </template>
                        </base-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <pg-tour ref="tour" :steps="steps" />
        </v-card>
    </v-container>
    `
};
