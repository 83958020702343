/**
 * Componente de simbolismo de um aparelho celular
 * @component
 * @displayName CellPhonePreview
 * @vue
 * @category Atomos
 * @author Igor Mateus Rodrigues de Oliveira <igor.oliveira@pgmais.com.br>
 * @vue/component
 */
export default {
    props: {
        /**
         * Propriedade para mostrar header
         */
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Propriedade para mostrar footer
         */
        showFooter: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica que a mensagem informando sobre o preview deve ser exibida
         */
        showPreviewMessage: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define a altura do preview
         */
        height: {
            type: [Number, String],
            required: false,
            default: 379
        },
        /**
         * Define a largura do preview
         */
        width: {
            type: Number,
            required: false,
            default: 296
        },
        /**
         * Indica que o conteudo do slot deve ter fill-height
         */
        contentFill: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        borderStyle() {
            if (this.showFooter && this.showHeader) {
                return "rounded-6";
            } else if (this.showFooter) {
                return "rounded-bottom-6";
            } else if (this.showHeader) {
                return "rounded-top-6";
            }
            return "";
        },
        contentSize() {
            /* let size = this.height;

            if (this.showPreviewMessage) {
                size -= 22;
            }

            if (this.showFooter && this.showHeader) {
                return size - 201;
            } else if (this.showFooter) {
                return size - 132;
            } else if (this.showHeader) {
                return size - 143;
            } else {
                return "";
            } */

            return "";
        }
    },
    template:
        /*html*/
        ` 
        <v-card 
            :class="'bg-gray-0 border-2 border-gray-300 ' + borderStyle" 
            elevation="0" 
            :width="width"
            :height="height"
            :ripple="false"
        >
            <v-row class="fill-height" no-gutters>
                <v-col cols="12" class="d-flex flex-flow-column fill-height">
                    <v-row no-gutters class="flex-0-1-auto">
                        <v-col v-if="showHeader" cols="12">
                            <v-card height="48px" elevation="0" class="ma-0 pa-0 border-bottom-1 border-gray-300 rounded-0 bg-transparent"></v-card>     
                        </v-col>
                    </v-row>
                    <v-row v-if="showPreviewMessage" no-gutters class="bg-background">
                        <v-col class="text-center">
                            <span class="font-size-13">Esta &eacute; a pr&eacute; visualiza&ccedil;&atilde;o do seu template</span>       
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="px-8 flex-1-1-auto d-flex flex-flow-column fill-height">
                        <v-col cols="12" class="flex-1-1-auto">
                            <v-card elevation="0" :height="contentSize" class="overflow-y-auto fill-height" max-height="324" ref="cardSlot" :ripple="false">
                                <v-row class="fill-height" no-gutters align="end">
                                    <v-col :class="(contentFill ? 'fill-height' : '')">
                                        <slot></slot>
                                    </v-col>
                                </v-row>
                            </v-card>        
                        </v-col>
                        <v-col class="py-4 flex-0-1-auto">
                            <v-card
                                elevation="0"
                                class="rounded-8 border-1 border-gray-300"
                            >
                                <v-row no-gutters class="fill-height" justify="end" align="center">
                                    <v-col cols="auto" class="pr-2 py-2">
                                        <v-icon size="24" color="gray-300">mdi-send</v-icon>
                                    </v-col>
                                </v-row>
                            </v-card>   
                        </v-col>
                    </v-row>
                    <v-row v-if="showFooter" no-gutters class="px-8 border-top-1 border-gray-300 rounded-0">
                        <v-col cols="12" class="pt-4 pb-6">
                            <v-row no-gutters justify="space-between">
                                <v-col cols="auto">
                                    <v-icon size="24">$androidBackIcon</v-icon>
                                </v-col>
                                <v-col cols="auto">
                                    <v-icon size="24">$androidHomeIcon</v-icon>
                                </v-col>
                                <v-col cols="auto">
                                    <v-icon size="24">$androidMenuIcon</v-icon>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>    
                </v-col>
            </v-row>
        </v-card>
    `
};
