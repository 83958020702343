import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";
import BaseInformation from "../../../../components/atomos/BaseInformation?v=3";
import TutorialEnableRcs from "./TutorialEnableRcs";

/**
 * Componente de modal de mensagem teste
 * @displayName ModalMensagemTeste
 * @requires {@link BaseDialog}
 * @requires {@link BaseInformation}
 * @requires {@link TutorialEnableRcs}
 * @component
 * @category Page / Template RCS
 * @subcategory dialogs
 * @vue
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br>
 * @vue/component 
 */
export default {
    name: "modal-mensagem-teste",
    components: {
        BaseDialog,
        BaseInformation,
        TutorialEnableRcs
    },
    props: {
        /**
         * Telefones para apresentar na modal
         */
        phonesSent: {
            type: [String, Number],
            required: false,
            default: null
        },
        /**
         * Propriedade para  controlar status da modal se ela esta ou nao ativa
         */
        controlModal: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            dialog: this.controlModal,
            open_modal_tutorial_rcs: false,
            icon_button: {
                "label": "Entendi",
                "variant": "dark-primary",
                "icon": "fa-check",
                "use_max_cols": false
            }
        };

    },
    watch: {
        controlModal() {
            this.dialog = this.controlModal;
        },
        dialog() {
            /**
            *
            * Eh realizado um emmit de input sempre que o do valor do dialog eh alterado 
            * @event input
            * @vue
            * @property {Boolean} dialog  status da modal se ela esta ou nao ativa
            */
            this.$emit("input", this.dialog);
        }
    },
    methods: {
        /**
         * Funcao para ativar tutorial de como habilitar o rcs
         * @vue
         */
        activeTutorialRcs() {
            this.open_modal_tutorial_rcs = true;
        },
        /**
         * Funcao que fica vinculada ao BaseDialog  
         * @vue
         */
        baseDialogFunction() {
            /**
            * Evento de clickModal do BaseDialog
            * @event clickModal
            * @vue
            */
            this.$emit("clickModal");
        },
        /**
         * Funcao que fica vinculada ao BaseDialog  
         * @vue
         */
        closeModal() {
            /**
            * Evento de closeModal do BaseDialog
            * @event closeModal
            * @vue
            */
            this.$emit("closeModal");
        }
    },
    template: (
        /*html*/
        `
            <base-dialog 
                v-model="dialog"
                :actionButton="icon_button"
                title="Mensagem enviada com sucesso!"
                max-width="410px"
                :useIconClose="true"
                @clickDialog="baseDialogFunction"
                @closeDialog="closeModal"
            >
                <v-row no-gutters>
                    <v-col cols="12">
                        <span class="body-2 text-gray-900">Sua mensagem foi enviada para o(s) n&uacute;mero(s)</span>
                    </v-col>
                    <v-col cols="12">
                        <span class="body-2 bold">{{ phonesSent }}</span>
                    </v-col>
                    <v-col cols="12" class="mt-6">
                        <base-information description="Para que a mensagem chegue at&eacute; o aparelho, o RCS deve estar habilitado e a internet conectada.">
                            <v-row no-gutters justify="center">
                                <v-col cols="auto" class="pb-2">
                                    <a class="body-2 text-primary-blue-500 text-decoration-none" href="#" @click="activeTutorialRcs">Como habilitar o RCS?</a>
                                </v-col>
                            </v-row>
                        </base-information>
                    </v-col>
                </v-row>
                <tutorial-enable-rcs v-model="open_modal_tutorial_rcs"></tutorial-enable-rcs>
            </base-dialog>
        `
    )
};