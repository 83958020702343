import BaseNotify from "../../../components/atomos/BaseNotify?v=2";

/**
 * Componente para agrupamento de informa��es para gera��o de relat�rio
 * <iframe src="http://localhost:8080/v2/documentacao/reports/agroup-report-data-usage" style="width: 100%; height: 426px;"></iframe>
 * 
 * @requires {@link BaseNotify}
 * 
 * @displayName AgroupReportData
 * @category Page / Relatorios
 * @author Thais Possamai <thais.possamai@pgmais.com.br>
 * @author Igor Oliveira <igor.oliveira@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseNotify
    },
    props: {
        /**
         * Value utilizado para manipulao de estados de hover e selected do card
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            selected: this.value,
            show_notify: false,
            notify_title: "",
            notify_message: ""
        };
    },
    computed: {
        cardColor() {
            return this.selected ? "super-dark-primary" : "extra-light-primary";
        },
    },
    watch: {
        value() {
            this.selected = this.value;
        },
        selected() {
            if (this.selected) {
                this.notify_title = "Agrupar dados foi habilitado com sucesso!";
                this.notify_message = window.Vue.htmlEntities("Os dados extra&iacute;dos ser&atilde;o exibidos agrupados por dia.");
                this.show_notify = true;
            }
        }
    },
    methods: {
        /**
         * Funcao vinculada ao card para mudan�a de estado de selecionado
         * @vue
         */
        clickCard() {
            this.selected = !this.selected;

            /**
             * Evento que emite a a��o de selecionado ou n�o selecionado
             * 
             * @property {Boolean} input A��o de selecionado 
             * @event input
             * @vue
             */
            this.$emit("input", this.selected);
        }
    },
    template: (
        /*html*/` 
            <v-row no-gutters>
                <v-col cols="12">
                    <v-card :class="'mr-12 rounded-0 border border-dark-primary bg-'+ cardColor" elevation="0" @click="clickCard">
                        <v-row no-gutters class="px-10 pt-10">
                            <v-col cols="12">
                                <span :class="(selected ? 'text-white': 'text-black') + ' font-size-15'">Agrupar por dia</span>
                            </v-col>
                            <v-col cols="12">
                                <span :class="(selected ? 'text-white': 'text-black') + ' font-size-11'">Habilite essa opcao caso deseje extrair os dados agrupados por dia.</span>
                            </v-col>
                            <v-col cols="12" class="mt-5">
                                <v-checkbox v-model="selected" dense @click="clickCard">
                                    <template v-slot:label>
                                        <span :class="(selected ? 'text-white': 'text-painel-label') + ' font-size-12'">Quero agrupar por dia.</span>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row no-gutters justify="end" class="mr-n12">
                            <v-col cols="auto" class="pl-10">
                                <v-img 
                                    src="/images/reports/GirlAgroupReportData.svg"
                                    max-width="335"
                                ></v-img>
                            </v-col>
                        </v-row>   
                    </v-card>
                </v-col>
                <base-notify
                    v-model="show_notify"
                    :title="notify_title"
                    :message="notify_message"
                    type="notification"
                ></base-notify>
            </v-row>

        `
    )
};