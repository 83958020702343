import BaseDatePicker from "../../../components/atomos/BaseDatePicker";

export default {
    components: {
        BaseDatePicker
    },
    data() {
        return {
            value: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            label: "Teste",
            required: false,
            rules: [],
            useIconValidation: false,
            popoverContent: "Teste",
            title: "Teste",
            hint: "Testesss",
            tooltipText: "Teste",
            colorTooltip: "",
            contentClassTooltip: "",
            disablePicker: false,
            minDate: "2020/01/01",
            maxDate: "2023/01/01"
        };
    },
    template: (
        /*html*/
        `
            <v-card class="bg-super-light-grey py-5 fill-height">
                <v-row justify="center" class="mb-5">
                    <v-col cols="12" sm="10" md="6">
                        <v-card class="px-5">
                            <v-row>
                                <v-col cols="12">
                                    <h6>
                                        <b>Dados para testar o componente</b>
                                    </h6>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field label="Label" v-model="label"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field label="Ti�tulo" v-model="title"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field label="Texto do tooltip" v-model="tooltipText"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field label="Cor do tooltip" v-model="colorTooltip"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field label="Classe css do tooltip" v-model="contentClassTooltip"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field label="Data minima (formato yyyy-mm-dd)" v-model="minDate"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field label="Data maxima (formato yyyy-mm-dd)" v-model="maxDate"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field label="Data (formato yyyy-mm-dd)" v-model="value"></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-checkbox label="Desabilitar Picker" v-model="disablePicker"></v-checkbox>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field label="DAta" v-model="value"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col> 
                </v-row>
                <v-row justify="center" class="ma-0">
                    <v-col cols="12" sm="10" md="2">
                        <base-date-picker
                            :label= "label"
                            :required= "required"
                            :rules= "rules"
                            :use-icon-validation= "useIconValidation"
                            :popover-content= "popoverContent"
                            :title= "title"
                            :hint= "hint"
                            :tooltip-text= "tooltipText"
                            :color-tooltip= "colorTooltip"
                            :content-class-tooltip= "contentClassTooltip"
                            :disable-picker= "disablePicker"
                            :min-date= "minDate"
                            :max-date= "maxDate"
                            v-model="value"
                        >
                        </base-date-picker>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};