import BaseDropdown from "../atomos/BaseDropdown";

/**
 * Componente de barra de configuracao
 * 
 * @requires {@link BaseDropdown}
 *
 * @displayName ConfigBar
 * @component
 * @category Moleculas
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseDropdown
    },
    props: {
        /**
         * Conteudo selecionado, formato: { first_value: String, second_value: String }
         */
        value: {
            type: Object,
            required: false,
            default: null
        },
        /**
         * Label do primerio select
         */
        firstLabel: {
            type: String,
            required: true
        },
        /**
         * Tooltip do primerio select
         */
        firstTooltip: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Opcoes do primeri select
         */
        firstOptions: {
            type: Object,
            required: true
        },
        /**
         * Label do segundo select
         */
        secondLabel: {
            type: String,
            required: true
        },
        /**
         * Tooltip do segundo select
         */
        secondTooltip: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Opcoes do segundo select
         */
        secondOptions: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            content: this.value ?? { first_value: Object.keys(this.firstOptions)[0], second_value: Object.keys(this.secondOptions)[0] }
        };
    },
    watch: {
        value() {
            this.content = this.value;
        },
        content() {
            this.$emit("input", this.content);
        }
    },
    template: (
        /*html*/
        `
            <v-card elevation="0" class="bg-gray-0 rounded-1 px-4" height="35" min-width="350" max-width="400" v-bind:data-v-step="'configBarTemplate'">
                <v-row no-gutters align="center">
                    <v-col cols="6">
                        <v-row no-gutters align="center" class="gap-1 flex-nowrap" justify="center">
                            <v-col cols="auto">
                                <span class="caption text-gray-500" v-html="firstLabel" />
                            </v-col>
                            <v-col cols="auto">
                                <base-dropdown :options="firstOptions" v-model="content.first_value" :tooltip-text="firstTooltip" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <v-row no-gutters align="center" class="gap-1 flex-nowrap" justify="center">
                            <v-col cols="auto">
                                <span class="caption text-gray-500" v-html="secondLabel" />
                            </v-col>
                            <v-col cols="auto">
                                <base-dropdown :options="secondOptions" v-model="content.second_value" :tooltip-text="secondTooltip" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};