import StatusCampaign from "../../../components/moleculas/StatusCampaign";

export default ({
    name: "StatusCampaignUsage",
    components: { StatusCampaign },
    data() {
        return {
            status: "A",
            list_status: [
                { value: "A", text: "A Enviar" },
                { value: "E", text: "Enviando" },
                { value: "P", text: "Pausa" },
                { value: "F", text: "Finalizada" },
                { value: "G", text: "Processando" },
                { value: "R", text: "Renitencia" },
                { value: "C", text: "Cancelada" }
            ],
            percent_progress: 50
        };
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="12" sm="8" md="6">
                <v-row>
                    <v-col>
                        <v-card class="pa-5">
                            <v-row>
                                <v-col cols="12">
                                    <v-select label="Status" v-model="status" :items="list_status" color="dark-primary" item-color="dark-primary"></v-select>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="6" sm="6" md="5" lg="4">
                                    <status-campaign :status="status" :percent-progress="percent_progress"></status-campaign>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        `
    )
});