import DialogNotifyReportGenerationUsage from "./DialogNotifyReportGenerationUsage";
import FormFilterReportsUsage from "./FormFilterReportsUsage";
import InfoNewReportGeneratorUsage from "./InfoNewReportGeneratorUsage";
import AgroupReportDataUsage from "./AgroupReportDataUsage";
import RepeatReportUsage from "./RepeatReportUsage";
import DialogRepeatReportUsage from "./DialogRepeatReportUsage";
import AditionalReportDataUsage from "./AditionalReportDataUsage";
import FormReportCompletionUsage from "./FormReportCompletionUsage";
import ReportStepOneUsage from "./ReportStepOneUsage";
import ReportStepTwoUsage from "./ReportStepTwoUsage";
import ReportStepThreeUsage from "./ReportStepThreeUsage";
import ReportStepFourUsage from "./ReportStepFourUsage";
import ReportStepFiveUsage from "./ReportStepFiveUsage";
import ReportStepSixUsage from "./ReportStepSixUsage";

export default [
    {
        path: "dialog-notify-report-generation-usage",
        name: "Dialog Notify Report",
        component: DialogNotifyReportGenerationUsage
    },
    {
        path: "form-filter-reports-usage",
        name: "Form Filter Reports",
        component: FormFilterReportsUsage
    },
    {
        path: "info-new-report-generator-usage",
        name: "Info New Report Generator",
        component: InfoNewReportGeneratorUsage
    },
    {
        path: "agroup-report-data-usage",
        name: "Agroup Report Data",
        component: AgroupReportDataUsage
    },
    {
        path: "repeat-report-usage",
        name: "Repeat Report Usage",
        component: RepeatReportUsage
    },
    {
        path: "dialog-repeat-report-usage",
        name: "Dialog Repeat Report",
        component: DialogRepeatReportUsage
    },
    {
        path: "aditional-report-data-usage",
        name: "Aditional Report Data",
        component: AditionalReportDataUsage
    },
    {
        path: "form-report-completion-usage",
        name: "Form Report Completion",
        component: FormReportCompletionUsage
    },
    {
        path: "report-step-one-usage",
        name: "Report Step One",
        component: ReportStepOneUsage
    },
    {
        path: "report-step-two-usage",
        name: "Report Step Two",
        component: ReportStepTwoUsage
    },
    {
        path: "report-step-three-usage",
        name: "Report Step Three",
        component: ReportStepThreeUsage
    },
    {
        path: "report-step-four-usage",
        name: "Report Step Four",
        component: ReportStepFourUsage
    },
    {
        path: "report-step-five-usage",
        name: "Report Step Five",
        component: ReportStepFiveUsage
    },
    {
        path: "report-step-six-usage",
        name: "Report Step Six",
        component: ReportStepSixUsage
    }
];