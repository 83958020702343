import DataSummary from "../../../components/moleculas/DataSummary";

export default ({
    data: function () {
        return {
            steps: {
                "canal": {icon: "icon-rcs", background: "rcs", circle: false, title: "Canal", description: "Teste RCS"},
                "modelo": {icon: "icon-texto", background: "primary", circle: false, title: "Modelo", description: "Teste texto"}
            },
            nome: "Template_Imagem_Novo",
            disabled_delete: true,
            disabled_delete2: false,
            steps2: {
                "periodo": {title: "Per�odo", description: "Ontem (21/09/2021)"},
                "campanha": {title: "Campanhas", description: "SMS01_PG"},
                "relatorios": {title: "Repetir rel�torio?", description: "Nao"},
                "dados": {title: "Dados adicionais", description: "Mensagens Situacoes de Envio"},
                "modalidade": {title: "Modalidade", description: "Analitico"},
                "agrpado": {title: "Agrupar por dia", description: "Sim"},
                "cliente": {title: "Clientes", description: "PGMais Belinnati"}
            },
            nome2: "Template_Imagem_Novo_2"
        };
    },
    components: {
        DataSummary
    },
    methods: {
        funcao_teste: function() {
            console.log("teste");
        }
    },
    template:
    /*html*/
    `
      <v-container>
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="3">
                            <v-text-field
                                label="Titulo"
                                v-model="nome"
                                hide-details="auto">
                            </v-text-field> 
                        </v-col>
                        <v-col cols="3">
                            <v-checkbox
                                v-model="disabled_delete"
                                label="Booleano para definir exibicaoo de botao de limpar tudo">
                            </v-checkbox>
                        </v-col>
                    </v-row>             
                    <v-row>
                        <v-col cols="3">
                            <data-summary 
                                    :disabled-delete="disabled_delete"
                                    :steps="steps"
                                    :columns="6"
                                    @click="funcao_teste">
                                    <template v-slot:header><span><b>Nome:</b>{{nome}}<i class="text-success fas fa-check"></i> </span></template>
                            </data-summary >   
                        </v-col>
                        <v-col cols="3">
                            <data-summary 
                                :disabled-delete="disabled_delete"
                                :steps="steps2"
                                :columns="6"
                                @click="funcao_teste">
                                <template v-slot:header><span><b>Nome:</b>{{nome}}<i class="text-success fas fa-check"></i> </span></template>
                            </data-summary >
                        </v-col>    
                    </v-row>            
                </v-col>
            </v-row>
      </v-container>
      `
});

