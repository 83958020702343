import PageHeader from "../../components/moleculas/PageHeader.js?v=2";
import BaseDataTable from "../../components/organismos/BaseDataTable.js?v=2";
import CanalIconDescription from "../../components/moleculas/CanalIconDescription.js?v=2";
import PgTour from "../../components/organismos/PgTour.js?v=2";
import FormFilterSessoesRcs from "./components/forms/FormFilterSessoesRcs.js?v=2";
import { DataTableHeaderClass } from "../../classes/DataTableHeaderClass.js";
import { BaseService } from "../../services/BaseService.js";
import ButtonAction from "../../components/moleculas/ButtonAction?v=2";
import ExportSessoesRcs from "./components/ExportSessoesRcs.js";
import TemplateUtils from "../../utils/TemplateUtils.js";
import TableThumb from "../../components/moleculas/TableThumb.js";
import BaseSpanLimited from "../../components/atomos/BaseSpanLimited";
import ModeloIconDescription from "../../components/atomos/ModeloIconDescription.js";
import { TemplateService } from "../../../../js/services/TemplateService";

const template_service = new TemplateService();

/**
 *
 * @requires {@link PageHeader}
 * @requires {@link BaseDataTable}
 * @requires {@link PgTour}
 * @requires {@link FormFilterSessoesRcs}
 * @requires {@link CanalIconDescription}
 * @requires {@link ButtonAction}
 * @requires {@link ExportSessoesRcs}
 * @requires {@link TableThumb}
 * @requires {@link BaseSpanLimited}
 * @requires {@link DataTableHeaderClass}
 * @requires {@link BaseService}
 * @requires {@link ModeloIconDescription}
 * @requires {@link TemplateUtils}
 *
 * Pagina que realiza a listagem de Sessoes RCS
 * @displayName SessoesRcsPage
 * @category Page / Sessoes RCS
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PageHeader,
        BaseDataTable,
        PgTour,
        FormFilterSessoesRcs,
        CanalIconDescription,
        ButtonAction,
        ExportSessoesRcs,
        TableThumb,
        BaseSpanLimited,
        ModeloIconDescription
    },
    filters: {
        format_date: function (date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY HH:mm");
            } else {
                return "-";
            }
        },
        format_radar: function (value) {
            if (value != 0) {
                return value;
            } else {
                return "-";
            }
        },
        format_situation: function (value) {
            if (value == "A") {
                return "Aberto";
            } else {
                return "Expirado";
            }
        },
        format_empty: function (value) {
            if (value != null) {
                if (value.length > 0) {
                    return value;
                } else {
                    return "-";
                }
            } else {
                return "-";
            }
        },
        format_models: function (value) {
            if (value.length <= 0) {
                return value;
            } else {
                return value.map(function (item) {
                    if (item == "M") {
                        return "Texto";
                    } else if (item == "I") {
                        return "Imagem";
                    } else if (item == "C") {
                        return "Card";
                    } else if (item == "R") {
                        return "Carrossel";
                    } else if (item == "V") {
                        return "Video";
                    } else {
                        return "-";
                    }
                });
            }
        },
        format_return_message: function (item) {
            item = typeof item !== "string" ? JSON.stringify(item) : item;

            try {
                item = JSON.parse(item);
            } catch (e) {
                return item;
            }

            if (typeof item === "object" && item !== null) {
                return item.text;
            } else {
                return item;
            }
        }
    },
    data() {
        return {
            base_service: new BaseService(),
            template_utils: new TemplateUtils(),
            show_notify: false,
            type_product:
                this.$cookie === undefined
                    ? getCookie("type_product")
                    : this.$cookie.get("type_product"),
            filter_data: {
                data_inicio: null,
                data_fim: null,
                situacao: "A",
                id_empresa: null,
                telefone: null,
                campo_informado: null,
                id_campanha: null
            },
            custom_slots: [
                "CD_PRODUTO_PDES",
                "NR_DESTINO_SAES",
                "DT_ENVIO_MOES",
                "DT_INSERT_RAES",
                "SITUACAO",
                "CD_AGRUPADOR_AQES",
                "DS_DADO_CLIENTE_AQES",
                "total_envios",
                "TP_TEMPLATE_TAHES",
                "DS_TEMPLATE_TAHES",
                "DS_MENSAGEM_RAES",
                "ID_SESSAO_APPS_MAES"
            ],
            loading: true,
            steps: [
                {
                    target: "[data-v-step='1']",
                    title: "Como posso come&ccedil;ar?",
                    content:
                        "Seu primeiro passo &eacute; preencher os filtros de acordo com a pesquisa que deseja fazer.<br> N&atilde;o &eacute; obrigat&oacute;rio preencher todos os filtros."
                },
                {
                    target: "[data-v-step='2']",
                    title: "Per&iacute;odo",
                    content:
                        "Para um carregamento mais r&aacute;pido da tela, j&aacute; aplicamos filtros para a data de hoje. Mas voc&ecirc; pode pesquisar outros per&iacute;odos."
                },
                {
                    target: "[data-v-step='3']",
                    title: "Status da sess&atilde;o",
                    content:
                        "Por padr&atilde;o exibimos apenas as sess&otilde;es que est&atilde;o abertas mas voc&ecirc; pode escolher exibir todas as sess&otilde;es ou apenas as expiradas."
                },
                {
                    target: "[data-v-step='4']",
                    title: "Relat&oacute;rios",
                    content:
                        "&Eacute; poss&iacute;vel baixar relat&oacute;rios em excel (.csv) ou texto (.txt), baseado nos filtros aplicados e nas informa&ccedil;&otilde;es da tabela."
                },
                {
                    target: "[data-v-step='5']",
                    title: "Personalizar tabela",
                    content:
                        "Voc&ecirc; pode escolher quais s&atilde;o as colunas que deseja visualizar na tabela."
                },
                {
                    target: "[data-v-step='6']",
                    title: "Mostrar linhas",
                    content:
                        "E tamb&eacute;m pode escolher quantas linhas por p&aacute;gina deseja ver."
                }
            ],
            table_headers: [
                new DataTableHeaderClass({
                    text: "Canal",
                    value: "CD_PRODUTO_PDES",
                    cellClass: "text-nowrap",
                    tooltipText: "Canal utilizado para disparo da mensagem",
                    sortable: false,
                    stepTutorial: 5
                }),
                new DataTableHeaderClass({
                    text: "Telefone",
                    value: "NR_DESTINO_SAES",
                    visibility: "md",
                    tooltipText: window.Vue.htmlEntities(
                        "N&uacute;mero de telefone de quem retornou a mensagem."
                    ),
                    sortable: false,
                }),
                new DataTableHeaderClass({
                    text: "Campo Informado",
                    value: "DS_DADO_CLIENTE_AQES",
                    visibility: "md",
                    tooltipText: window.Vue.htmlEntities(
                        "Campo que foi informado no arquivo de dados"
                    ),
                    sortable: false,
                }),
                new DataTableHeaderClass({
                    text: "Data de envio",
                    value: "DT_ENVIO_MOES",
                    visibility: "md",
                    tooltipText: "Data e hora de envio da mensagem",
                    stepTutorial: 7,
                    sortable: true,
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("Status da sess&atilde;o"),
                    value: "SITUACAO",
                    visibility: "md",
                    tooltipText: window.Vue.htmlEntities(
                        "A sess&atilde;o pode estar aberta ou fechada."
                    ),
                    stepTutorial: 6,
                    sortable: false,
                }),

                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("&Uacute;ltimo retorno"),
                    value: "DT_INSERT_RAES",
                    visibility: "md",
                    tooltipText: window.Vue.htmlEntities(
                        "Data e hora do recebimento do &uacute;ltimo retorno."
                    ),
                    sortable: false,
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities(
                        "Mensagem &uacute;ltimo retorno"
                    ),
                    value: "DS_MENSAGEM_RAES",
                    visibility: "md",
                    tooltipText: window.Vue.htmlEntities(
                        "Conteuacute;do do &uacute;ltimo retorno recebido."
                    ),
                    sortable: false,
                }),
                new DataTableHeaderClass({
                    text: "Quantidade",
                    value: "total_envios",
                    visibility: "md",
                    tooltipText:
                        "Total de mensagens enviadas e recebidas nessa conversa.",
                    sortable: false,
                }),
                new DataTableHeaderClass({
                    text: "Modelo",
                    value: "TP_TEMPLATE_TAHES",
                    visibility: "md",
                    tooltipText: window.Vue.htmlEntities(
                        "Conte&uacute;do do &uacute;ltimo retorno recebido."
                    ),
                    sortable: false,
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("Conte&uacute;do"),
                    value: "DS_TEMPLATE_TAHES",
                    visibility: "md",
                    tooltipText: "Conte&uacute;do.",
                    sortable: false,
                }),
                new DataTableHeaderClass({
                    text: "Conversas",
                    value: "ID_SESSAO_APPS_MAES",
                    visibility: "md",
                    tooltipText: "Visualizar a conversa.",
                    sortable: false,
                })
            ],
            content_table: [],
            filter_radar_dates: {}
        };
    },
    computed: {
        server_side_config() {
            return {
                request_to: "API",
                url: "/radar-app/list",
                data: this.filter_data
            };
        },
        data_export() {
            return this.filter_data;
        }
    },
    created() {
        let url_params = new URLSearchParams(window.location.search);

        let data_inicio = url_params.get("data_inicio");
        if (data_inicio) {
            this.data_inicio(data_inicio);
            this.filter_radar_dates["data_inicio"] = data_inicio;
        }

        let data_fim = url_params.get("data_fim");
        if (data_fim) {
            this.data_fim(data_fim);
            this.filter_radar_dates["data_fim"] = data_fim;
        }

        let telefone = url_params.get("telefone");
        if (telefone) {
            this.filter_data["telefone"] = telefone;
        }

        let id_campanha = url_params.get("id_campanha");
        if (id_campanha) {
            this.filter_data["id_campanha"] = id_campanha;
        }

        let campaigns_ids = url_params.get("campaigns_ids");
        if (campaigns_ids) {
            this.filter_data["campaigns_ids"] = campaigns_ids.split(",");
        }

        this.filter_data = _.cloneDeep(this.filter_data);
    },
    methods: {
        filterSubmit(data) {
            this.filter_data = {
                id_empresa: data.id_empresa,
                telefone: data.telefone ? data.telefone.replace(/\D/g, "") : "", // remove caracteres n�o numericos
                campo_informado: data.campo_informado,
                situacao: data.situacao,
                data_inicio: data.data_inicio,
                data_fim: data.data_fim,
                id_campanha: data.id_campanha,
                campaigns_ids: data.campaigns_ids
            };
            this.loading = true;
        },
        data_inicio(data) {
            this.filter_data["data_inicio"] = data;
        },
        data_fim(data) {
            this.filter_data["data_fim"] = data;
        },
        /**
         * Metodo que faz a tratativa do objeto loading recebendo o $emit refreshServerSideData do componente dataBaseTable
         */
        dataTable(ev) {
            if (ev.data[0]) {
                this.loading = false;
            } else {
                this.loading = true;
            }
            this.content_table = ev.data; // recebe o conteudo da tabela para enviar ao componente ExportGerenciar
        },
        total_radar(total_envios, total_resposta) {
            let count = parseInt(total_envios) + parseInt(total_resposta);
            return count;
        },
        redirectForView(id) {
            window.location.href = this.base_service.baseDomainPhp() + "/RadarApp/chat?id=" + id;
        },
        /**
         * Prepara os textos que ser�o exibidos pelo componente tableTumb
         * @param {item}
         * @returns {string}
         * @vue
         */
        prepareDsTemplate(item) {
            if (item.TP_MENSAGEM_MAES == "M") {
                if (item.DS_MENSAGEM_MAES != "") {
                    return item.DS_MENSAGEM_MAES;
                }
                return item.DS_TEMPLATE_TAHES;
            } else {
                if (item.DS_TEMPLATE_TAHES != "") {
                    if (item.TP_TEMPLATE_TAHES == "M") {
                        /** Quando for um tipo de template de msg, usa a fun��o preencherCamposTemplate() 
                          * para concatenar os paramentos com a string do template
                          */
                        let conteudo_mensagem =
                            template_service.preencherCamposTemplate(
                                item.DS_TEMPLATE_TAHES,
                                JSON.parse(item.DS_PARAMETROS_MAES)
                            );

                        return conteudo_mensagem;
                    } else if (item.TP_TEMPLATE_TAHES == "C" || item.TP_TEMPLATE_TAHES == "R") {
                        let conteudo_mensagem = template_service.preencherCamposTemplate(
                            item.DS_TEMPLATE_TAHES,
                            JSON.parse(item.DS_PARAMETROS_MAES)
                        );
                        return conteudo_mensagem;
                    }
                    return item.DS_PARAMETROS_MAES;
                }
                return item.DS_TEMPLATE_TAHES;
            }
        },
        /**
         * Retorna tipo de mensagem a ser apresentado na coluna Modelo
         * @param {item}
         * @returns {string}
         * @vue
         */
        messageModel(item) {
            return item.TP_TEMPLATE_TAHES != null ? item.TP_TEMPLATE_TAHES : item.TP_MENSAGEM_MAES;
        },
        /**
         * Pega a quantidade de imagens/paginas presentes em um RCS do tipo Caroseul
         * @param {item}
         * @returns {number}
         * @vue
         */
        countCarouselPage(item) {
            let params = "";
            // Verifica se o envio � do tipo carousel
            if (item.TP_MENSAGEM == "R" || item.TP_TEMPLATE == "R") {
                // Se exitir valor em DS_TEMPLATE, utiliza essa coluna para buscar os dados do carousel (envio utilizando um template criado)
                if (item.DS_TEMPLATE) {
                    params = JSON.parse(item.DS_TEMPLATE);
                // Se n�o, pega as informa��es na coluna DS_PARAMETROS (envios do tipo carousel criado na hora (envio r�pido))
                } else {
                    params = JSON.parse(item.DS_PARAMETROS);
                }
                // Retorna o numero de p�ginas do carousel.
                return params.contents.length;
            }
        }
    },
    template:
        /*html*/
        ` 
        <v-container>
            <v-card elevation="0">
                <v-card-text>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12">
                            <page-header
                                :tutorial="true"
                                title="Sess&otilde;es (RCS)"
                                description="Aqui voc&ecirc; pode verificar as sess&otilde;es e as mensagens de retorno dos destinat&aacute;rios."
                                @tutorial="$refs.tour.start()"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12" class="filtro-content pb-10">
                            <form-filter-sessoes-rcs
                                v-model="filter_data"
                                :filter-radar-dates="filter_radar_dates"
                                @data_inicio="data_inicio"
                                @data_fim="data_fim"
                                @formSubmit="filterSubmit"
                            />
                        </v-col>
                    </v-row>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <export-sessoes-rcs
                            :loading="loading"
                            :data-export="data_export"
                            :content-table="content_table"
                        />
                    </v-row>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12">
                            <base-data-table
                                :headers="table_headers"
                                :custom-slots="custom_slots"
                                :server-side-config="server_side_config"
                                item-key="ID_SESSAO_APPS_MAES"
                                sort-by="DT_ENVIO_MOES"
                                :sort-desc="true"
                                show-page-length
                                :step-tutorial-custom-columns="5"
                                :step-tutorial-page-length="6"
                                @refreshServerSideData="dataTable"
                            >
                                <template #CD_PRODUTO_PDES="props">
                                    <canal-icon-description :product-name="props.item.CD_PRODUTO_PDES && props.item.CD_PRODUTO_PDES.length > 0 ? props.item.CD_PRODUTO_PDES : (props.item.TP_MENSAGEM_MAES === 'C' ? 'sms-c' : 'sms')" />
                                </template>

                                <template #NR_DESTINO_SAES="props">
                                    <span>{{ maskToPhone(props.item.NR_DESTINO_SAES) }}</span>
                                </template>

                                <template #DS_DADO_CLIENTE_AQES="props">
                                    <span>{{ props.item.DS_DADO_CLIENTE_AQES | format_empty }}</span>
                                </template>

                                <template #DT_ENVIO_MOES="props">
                                    <span>{{ props.item.DT_ENVIO_MOES | format_date }}</span>
                                </template>

                                <template #SITUACAO="props">
                                    <span>{{ props.item.SITUACAO | format_situation }}</span>
                                </template>

                                <template #DT_INSERT_RAES="props">
                                    <span>{{ props.item.DT_INSERT_RAES | format_date }}</span>
                                </template>

                                <template #DS_MENSAGEM_RAES="props">
                                    <base-span-limited :text="props.item.DS_MENSAGEM_RAES | format_return_message" />
                                </template>

                                <template #total_envios="props">
                                    <span>{{ total_radar(props.item.total_envios, props.item.total_resposta) | format_radar }}</span>
                                </template>
                                <template #TP_TEMPLATE_TAHES="props">
                                    <modelo-icon-description 
                                        class="body-2 flex-nowrap" 
                                        :model-name="messageModel(props.item)"
                                        :sub-title="countCarouselPage(props.item)"
                                    ></modelo-icon-description>
                                </template>

                                <template #DS_TEMPLATE_TAHES="props">
                                    <v-row no-gutters class="py-1">
                                        <v-col cols="12">
                                            <v-card elevation="0" class="rounded-0" max-width="130px">
                                                <table-thumb :ds-template="prepareDsTemplate(props.item)" :type-template="props.item.TP_TEMPLATE_TAHES != null ? props.item.TP_TEMPLATE_TAHES : props.item.TP_MENSAGEM_MAES" />
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </template>

                                <template #ID_SESSAO_APPS_MAES="props">
                                    <button-action
                                        type="chat"
                                        size="60"
                                        @click="redirectForView(props.item.ID_SESSAO_APPS_MAES)"
                                    />
                                </template>
                            </base-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <pg-tour ref="tour" :steps="steps" />
            </v-card>
        </v-container>
        `
};
