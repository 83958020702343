import BaseTooltip from "./BaseTooltip";

/**
 * Componente basico de um card somente visualizacao
 *
 * @displayName BaseVisualCard
 * @component
 * @category Atomos
 * @author David Santos <david.santos@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Icone a ser exibido no componente
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * Titulo descrevendo a informacao
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Numero com a quantidade de itens
         */
        number: {
            type: Number,
            required: false,
            default: null
        },
        /**
         * Porcentagem referente ao numero de itens
         */
        percent: {
            type: Number,
            required: false,
            default: null
        },
        hidePercent: {
            type: Boolean,
            required: false,
            default: false
        },
        tooltipText: {
            type: String,
            required: false,
            default: null
        }
    },
    template:
    /*html*/`
        <v-card width="100%" elevation="0" class="pa-4 bg-gray-0 border-1 border-gray-50 rounded-3">
            <v-row no-gutters class="gap-3" align="center">
                <v-col cols="auto">
                    <v-icon color="gray-800" size="32">{{ icon }}</v-icon>
                </v-col>
                <v-col>
                    <v-row no-gutters class="mb-1">
                        <v-col>
                            <base-tooltip :disabled="!tooltipText" :text="tooltipText">
                                <span class="text-gray-800 body-2" v-html="title" />
                            </base-tooltip>
                        </v-col>
                    </v-row>
                    <v-row align="center" no-gutters class="gap-1">
                        <v-col cols="auto">
                            <span class="subtitle-2 text-gray-800">{{ number }}</span>
                        </v-col>
                        <v-col v-if="!hidePercent">
                            <base-tooltip text="Porcentagem com base no total de bot&otilde;es clicados.">
                                <span class="body-2 text-gray-800">({{ percent }}%)</span>
                            </base-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>    
    `
};