import BaseButton from "../../../components/atomos/BaseButton";

export default {
    name: "BaseButtonUsage",
    components: { BaseButton },
    data() {
        return {
            icon: "mdi-test-tube",
            label: "Test",
            contentColor: "gray-0",
            color: "primary-blue-500",
            disabled: false,
            left: false,
            block: false,
            xLarge: false,
            width: "auto",
            small: false,
            loading: false,
            tooltipText: "Content here",
            onlyIcon: false,
            active: false,
            danger: false,
            primary: false,
            secondary: false,
            tertiary: false
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6" class="text-center">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="3">
                                        <v-checkbox label="Botao primario" v-model="primary"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Botao secundario" v-model="secondary"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Botao terciario" v-model="tertiary"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Efeito danger" v-model="danger"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Ativo" v-model="active"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Desabilitado" v-model="disabled"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Carregando" v-model="loading"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Icone a direita" v-model="left"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Somente icone" v-model="onlyIcon"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Bloco" v-model="block"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Extra largo" v-model="xLarge"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Pequeno" v-model="small"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Icone" v-model="icon"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Texto" v-model="label"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Texto do tooltip" v-model="tooltipText"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Largura" v-model="width"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Cor do conteudo" v-model="contentColor"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Cor do botao" v-model="color"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="d-flex justify-center">
                                        <base-button
                                            v-model="active"
                                            :icon="icon"
                                            :label="label"
                                            :content-color="contentColor"
                                            :color="color"
                                            :disabled="disabled"
                                            :left="left"
                                            :block="block"
                                            :x-large="xLarge"
                                            :width="width"
                                            :small="small"
                                            :loading="loading"
                                            :tooltip-text="tooltipText"
                                            :only-icon="onlyIcon"
                                            :danger="danger"
                                            :primary="primary"
                                            :secondary="secondary"
                                            :tertiary="tertiary"
                                        ></base-button> 
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};