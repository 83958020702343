import BaseBackgroundIcon from "../atomos/BaseBackgroundIcon?v=2";

/**
 * Component section card
 *
 * @displayName SectionCard
 * @component
 * @category Moleculas
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseBackgroundIcon
    },
    props: {
        /**
         * Section title
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Section icon
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * Icon color
         */
        iconColor: {
            type: String,
            required: false,
            default: "gray-700"
        },
        /**
         * Icon background color
         */
        iconBgColor: {
            type: String,
            required: false,
            default: "white"
        },
        /**
         * Tamanho do icone
         */
        iconSize: {
            type: String,
            required: false,
            default: "30"
        },
        /**
         * Define se o conteudo do body vai ser destacado
         */
        focusBody: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        borderBody() {
            let border = "border-1 border-gray-300";

            if (this.focusBody) {
                border = "border-2 border-primary-blue-500";
            }

            return border;
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters>
                <v-col cols="12">
                    <v-row align="center" no-gutters>
                        <v-col cols="auto">
                            <base-background-icon
                                :icon-name="icon"
                                :background-color="iconBgColor"
                                :icon-color="iconColor"
                                :circle="false"
                                :size="iconSize"
                            ></base-background-icon>
                        </v-col>
                        <v-col class="line-height-16 pl-6">
                            <span class="body-2 text-gray-700" v-html="title"></span>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-6" v-if="this.$slots.header">
                        <v-col>
                            <slot name="header"></slot>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-6" v-if="this.$slots.body">
                        <v-col>
                            <v-card :class="borderBody + ' pa-4'" elevation="0">
                                <slot name="body"></slot>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};