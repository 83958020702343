/**
 * Classe que representa um item BasePill
 */
export class BasePillClass {
    id
    title
    subTitle
    titleIcon
    prependIcon
    draggable
    editable
    deletable
    removable
    rounded
    contentColor
    backgroundColor
    tooltipText
    numberCharacters
    subTitleNewLine
    type
    selected
    order

    /**
     * Cria um PillClass
     * @param {String} id
     * @param {String} title
     * @param {String} subTitle
     * @param {String} titleIcon
     * @param {String} prependIcon
     * @param {Boolean} draggable
     * @param {Boolean} editable
     * @param {Boolean} deletable
     * @param {Boolean} removable
     * @param {Boolean} rounded
     * @param {String} contentColor
     * @param {String} backgroundColor
     * @param {String} tooltipText
     * @param {Number} numberCharacters
     * @param {Boolean} subTitleNewLine
     * @param {String} type
     * @param {Boolean} selected
     * @param {Number} order
     */
    constructor({
        id = null,
        title = null,
        subTitle = null,
        titleIcon = null,
        prependIcon = null,
        draggable = false,
        editable = false,
        deletable = false,
        removable = false,
        rounded = false,
        contentColor = null,
        backgroundColor = null,
        tooltipText = null,
        numberCharacters = null,
        subTitleNewLine = false,
        type = null,
        selected = false,
        order = null
    }) {
        this.id = id ? id : Math.random();
        this.title = title;
        this.subTitle = subTitle;
        this.titleIcon = titleIcon;
        this.prependIcon = prependIcon;
        this.draggable = draggable;
        this.editable = editable;
        this.deletable = deletable;
        this.removable = removable;
        this.rounded = rounded;
        this.contentColor = contentColor;
        this.backgroundColor = backgroundColor;
        this.tooltipText = tooltipText;
        this.numberCharacters = numberCharacters;
        this.subTitleNewLine = subTitleNewLine;
        this.type = type;
        this.selected = selected;
        this.order = order;
    }
}