import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente span com limitador e tooltip
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName BaseSpanLimited
 * @component
 * @category Atomos
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Texto a ser exibido
         */
        text: {
            type: String,
            required: true
        },
        /**
         * Limite de caracteres para o campo
         */
        limit: {
            type: Number,
            required: false,
            default: 30
        },
        /**
         * Classes a serem adicionadas no span
         */
        classes: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        excedLimit() {
            return this.text.length > this.limit;
        },
        textShowed() {
            return this.excedLimit ? this.text.slice(0, this.limit) + "..." : this.text;
        }
    },
    template: (
        /*html*/
        `
            <base-tooltip :disabled="!excedLimit" :text="text">
                <span v-html="textShowed" :class="classes"></span>
            </base-tooltip>
        `
    )
};