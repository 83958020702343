export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Descartar-Enabled" transform="translate(1.67 -41)">
                    <rect id="Retângulo_348" data-name="Retângulo 348" width="28" height="28" transform="translate(-1.67 41)" fill="currentColor" opacity="0"/>
                    <g id="icons_Q2" data-name="icons Q2" transform="translate(2.33 45)">
                        <path id="Caminho_644" data-name="Caminho 644" d="M21.9,19.9,29.4,12.466a1.487,1.487,0,0,0,.142-1.912,1.345,1.345,0,0,0-2.124-.142l-7.505,7.505-7.505-7.505a1.345,1.345,0,0,0-2.124.142,1.487,1.487,0,0,0,.142,1.912L17.931,19.9l-7.505,7.435a1.487,1.487,0,0,0-.142,1.912,1.345,1.345,0,0,0,2.124.142l7.505-7.505,7.505,7.505a1.345,1.345,0,0,0,2.124-.142,1.487,1.487,0,0,0-.142-1.912Z" transform="translate(-10.001 -9.959)" fill="currentColor" stroke="currentColor" stroke-width="1.5"/>
                    </g>
                </g>
            </svg>
        `
    )
};