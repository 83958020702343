/**
 * Componente de descricao de produto
 *
 * @displayName ProductDescription
 * @component
 * @category Moleculas
 * @vue
 * @author Igor Mateus Rodrigues de Oliveira <igor.oliveira@pgmais.com.br>
 */
export default {
    name: "product-description",
    props: {
        /**
         * Nome do icone do produto
         * */
        icon_product: {
            type: String,
            required: true
        },
        /**
         * Cor do background
         * */
        background_color: {
            type: String,
            required: true
        },
        /**
         * Cor do Icone
         * */
        icon_color: {
            type: String,
        },
        /**
         * Descricao do produto
         * */
        description: {
            type: String,
            default: true
        },
        /**
         * Cor da descri��o
         * */
        description_color: {
            type: String,
        },
    },

    template:
    /*html*/
    `
        <v-row class="fill-height" align="center" justify="center" :class="background_color">
            <v-col cols="8" class="p-4 text-center">
                <v-avatar justify="center" size="100">
                    <v-icon class="rounded-0" :color="icon_color" size="60">{{icon_product}}</v-icon>
                </v-avatar>               
                <span class="d-block font-078rem" :style="{color: description_color}" v-html="description"></span> 
            </v-col> 
        </v-row>
    `
};