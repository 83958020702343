import BaseBoxForm from "../../../components/atomos/BaseBoxForm";

export default {
    name: "BaseBoxFormUsage",
    components: { BaseBoxForm },
    template: (
        /*html*/
        `
            <v-row>
                <v-col>
                    <base-box-form>
                        Teste
                    </base-box-form>
                </v-col>
            </v-row>
        `
    )
};