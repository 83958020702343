import ConfigContent from "../../template-rcs/components/ConfigContent";

export default {
    components: {
        ConfigContent
    },
    data() {
        return {
            icon: "$iconeTextoIcon",
            text: "Some instruction here...",
            showClose: false,
            ativo: false
        };
    },
    template: (
        /*html*/
        `
        <v-row justify="center">
            <v-col cols="6">
                <v-row>
                    <v-col>
                        <v-card class="pa-5">
                            <v-row>
                                <v-col cols="4">
                                    <v-checkbox label="Ativo" v-model="ativo"></v-checkbox>
                                </v-col>
                                <v-col cols="4">
                                    <v-checkbox label="Removivel" v-model="showClose"></v-checkbox>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field label="Icone" v-model="icon"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field label="Texto" v-model="text"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" md="6">
                                    <config-content
                                        v-model="ativo"
                                        :icon="icon"
                                        :text="text"
                                        :show-close="showClose"
                                    ></config-content> 
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        `
    )
};