import DialogCampaignDetails from "../../../components/organismos/DialogCampaignDetails";
import BaseButton from "../../../components/atomos/BaseButton";

export default {
    components: {
        DialogCampaignDetails,
        BaseButton
    },
    data() {
        return {
            show_dialog: true,
        };
    },
    template: (
        /*html*/
        `
        <v-container>
            <v-row justify="center">
                <v-col cols="3">
                    <base-button
                        primary
                        label="Show campaign details"
                        @click="show_dialog = true"
                    />
                </v-col>
            </v-row>
            <dialog-campaign-details
                v-model="show_dialog"
                :campaign-id="8262514"
            />
        </v-container>
        `
    )
};