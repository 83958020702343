export default {
    template: (
        /*html*/
        `
            <svg width="63" height="62" viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.1665 60C47.735 60 61.1665 46.5685 61.1665 30C61.1665 13.4315 47.735 0 31.1665 0C14.598 0 1.1665 13.4315 1.1665 30C1.1665 46.5685 14.598 60 31.1665 60Z" fill="#80AEE8"/>
                <path d="M30.576 61.5361H30.012C16.155 61.8811 11.4988 58.0781 11.4988 58.0781C9.91559 57.0873 8.62342 55.6948 7.75322 54.0422C6.88303 52.3897 6.46626 50.5361 6.54522 48.6701C6.43523 46.7126 7.08377 44.7881 8.35674 43.2971C9.23938 42.0354 10.4743 41.0618 11.907 40.4978C13.3398 39.9338 14.9071 39.8045 16.4129 40.1261C16.873 37.1262 18.2272 34.3347 20.2981 32.1161C21.7256 30.4145 23.7213 29.2883 25.9158 28.9461C27.1793 28.6368 28.4764 28.485 29.7771 28.4941C29.9521 28.4941 30.1238 28.4941 30.2938 28.4941C30.4638 28.4941 30.6358 28.4941 30.8108 28.4941C32.1116 28.4848 33.4088 28.6366 34.6722 28.9461C36.8665 29.2881 38.8619 30.4144 40.2889 32.1161C42.3598 34.3348 43.7142 37.1262 44.1751 40.1261C45.6798 39.805 47.2457 39.934 48.6775 40.497C50.1094 41.06 51.3438 42.0321 52.2269 43.2921C53.5045 44.7858 54.1548 46.7158 54.0418 48.6781C54.1193 50.5429 53.7017 52.3949 52.8313 54.046C51.961 55.697 50.6695 57.0882 49.0872 58.0781C49.0872 58.0781 44.827 61.5581 32.326 61.5591C31.7593 61.5584 31.176 61.5507 30.576 61.5361Z" fill="white"/>
                <path d="M39.9163 34.63L38.6763 35.461C38.4907 35.2022 38.2861 34.9575 38.0643 34.729C37.7944 34.4441 37.5065 34.1769 37.2023 33.929L37.1503 33.886C35.8676 32.8492 34.3414 32.157 32.7163 31.875L32.6673 31.868C32.1004 31.7713 31.5264 31.7218 30.9513 31.72H30.8813C30.5046 31.72 30.145 31.7323 29.8023 31.757C29.2991 31.7902 28.7983 31.8527 28.3023 31.944C28.1653 31.968 28.0303 31.996 27.9023 32.026C27.6813 32.073 27.4673 32.126 27.2603 32.186C26.7983 32.3155 26.3459 32.4768 25.9063 32.669C25.5553 32.8223 25.2142 32.9977 24.8853 33.194L24.7433 33.28C24.4759 33.4478 24.2179 33.6301 23.9703 33.826C23.7609 33.9925 23.5606 34.1701 23.3703 34.358C23.3313 34.395 23.2943 34.433 23.2593 34.47C22.9799 34.747 22.7369 35.0585 22.5363 35.397L21.2173 34.697C21.4897 34.22 21.8261 33.7826 22.2173 33.397C22.3873 33.223 22.5793 33.039 22.7993 32.852C23.1037 32.5902 23.4251 32.3486 23.7613 32.129C23.9053 32.034 24.0543 31.941 24.2123 31.849C24.3703 31.757 24.5543 31.655 24.7373 31.563C25.5186 31.1675 26.3426 30.8628 27.1933 30.655C27.2563 30.639 27.3213 30.623 27.3933 30.609C27.5583 30.57 27.7283 30.533 27.9003 30.5C28.3739 30.409 28.8519 30.3422 29.3323 30.3C29.5883 30.276 29.8553 30.258 30.1273 30.247L30.3503 30.238C30.5253 30.232 30.7043 30.229 30.8893 30.229H31.0823C31.3023 30.229 31.5163 30.242 31.7253 30.256C31.8693 30.266 32.0113 30.278 32.1503 30.292L32.2293 30.299C34.2069 30.508 36.0931 31.2397 37.6943 32.419C37.8883 32.563 38.0713 32.705 38.2393 32.847C38.5411 33.0976 38.8289 33.3647 39.1013 33.647C39.399 33.9522 39.6715 34.2809 39.9163 34.63Z" fill="#015CD1"/>
                <path d="M28.6742 18.2131L21.6382 25.6351C22.8956 25.4313 24.179 25.4492 25.4302 25.6881C26.0489 25.76 26.6313 26.0181 27.1002 26.4281C27.6088 27.0836 27.8656 27.8996 27.8242 28.7281C27.8589 29.3188 27.8815 29.9095 27.8922 30.5001C27.8922 30.7131 27.8982 30.9251 27.8982 31.1381H27.9842C28.7032 31.1141 29.4232 31.0851 30.1402 31.0591C31.1532 30.9675 32.1745 31.0428 33.1632 31.2821C33.9118 31.4927 34.5668 31.951 35.0212 32.5821C35.7841 32.9244 36.4997 33.3637 37.1502 33.8891L37.2022 33.9321L38.2302 32.8481L41.0082 29.9101L28.6742 18.2131ZM28.3582 22.3781C28.6806 22.0377 29.0967 21.8004 29.5539 21.6965C30.0112 21.5926 30.4889 21.6266 30.9268 21.7943C31.3647 21.962 31.743 22.2559 32.0138 22.6386C32.2847 23.0214 32.4359 23.4759 32.4483 23.9446C32.4608 24.4133 32.334 24.8752 32.0838 25.2718C31.8337 25.6684 31.4715 25.9819 31.0432 26.1727C30.6148 26.3634 30.1395 26.4228 29.6774 26.3433C29.2153 26.2638 28.7872 26.049 28.4472 25.7261C27.9918 25.2937 27.7267 24.6983 27.71 24.0706C27.6933 23.4428 27.9265 22.8341 28.3582 22.3781Z" fill="#FFE494"/>
                <path d="M29.267 17.588L28.537 16.895L18.668 27.303L23.756 32.128L24.877 33.192C25.2059 32.9957 25.5469 32.8204 25.898 32.667L24.732 31.562L20.143 27.211L21.638 25.636L28.674 18.214L41.01 29.914L38.229 32.849L37.201 33.933C37.5052 34.1809 37.7931 34.4481 38.063 34.733L39.091 33.649L42.459 30.097L29.267 17.588Z" fill="white"/>
                <path d="M35.0199 32.579C34.2832 32.2518 33.5091 32.0167 32.7149 31.879L32.6659 31.872C32.099 31.7752 31.525 31.7257 30.9499 31.724H30.8799C30.5032 31.724 30.1435 31.7363 29.8009 31.761C29.2977 31.7941 28.7968 31.8566 28.3009 31.948C28.1639 31.972 28.0289 32 27.9009 32.03C27.9009 31.73 27.9069 31.438 27.9009 31.142H27.9869C28.7059 31.118 29.4259 31.089 30.1429 31.063C31.156 30.9713 32.1772 31.0466 33.1659 31.286C33.912 31.4955 34.5654 31.9512 35.0199 32.579Z" fill="#0BD9CE"/>
                <path d="M27.897 31.136C27.897 31.432 27.897 31.728 27.897 32.024C27.676 32.071 27.461 32.124 27.255 32.184C26.793 32.3134 26.3406 32.4748 25.901 32.667L24.735 31.562L20.146 27.211L21.641 25.636C22.8984 25.4321 24.1818 25.45 25.433 25.689C26.0517 25.7609 26.6341 26.0189 27.103 26.429C27.6115 27.0845 27.8684 27.9004 27.827 28.729C27.8617 29.3197 27.8843 29.9103 27.895 30.501C27.896 30.71 27.897 30.923 27.897 31.136Z" fill="#08B5AC"/>
                <path d="M31.709 22.285C32.0497 22.6073 32.2871 23.0234 32.3913 23.4807C32.4954 23.938 32.4617 24.4159 32.2942 24.8539C32.1267 25.292 31.8331 25.6706 31.4505 25.9418C31.0678 26.2129 30.6134 26.3646 30.1446 26.3774C29.6758 26.3903 29.2137 26.2638 28.8167 26.0141C28.4198 25.7643 28.1058 25.4024 27.9146 24.9742C27.7233 24.5459 27.6634 24.0706 27.7423 23.6083C27.8212 23.146 28.0355 22.7175 28.358 22.377C28.5719 22.1508 28.8283 21.9689 29.1125 21.8418C29.3967 21.7147 29.7032 21.6449 30.0144 21.6364C30.3256 21.6278 30.6355 21.6807 30.9262 21.792C31.217 21.9033 31.483 22.0708 31.709 22.285Z" fill="#FF5331"/>
                <path d="M39.7171 16.969L37.8771 24.776C37.6491 24.407 37.4251 24.04 37.1971 23.676C37.0047 23.3212 36.7637 22.9951 36.4811 22.707C36.0897 22.3817 35.6247 22.157 35.1266 22.0525C34.6285 21.9479 34.1124 21.9666 33.6231 22.107C32.6485 22.3974 31.7283 22.8461 30.8991 23.435C30.2911 23.818 29.6831 24.205 29.0761 24.588L29.0021 24.634C28.6021 23.941 28.1701 23.259 27.7251 22.588C27.344 21.852 26.7118 21.2769 25.9431 20.967C25.3319 20.848 24.6989 20.9178 24.1281 21.167C22.9279 21.5902 21.811 22.2204 20.8281 23.029L23.1741 13.074L39.7171 16.969ZM29.6081 17.915C29.7162 17.4586 29.6865 16.9805 29.5228 16.541C29.3591 16.1016 29.0687 15.7205 28.6885 15.4461C28.3082 15.1716 27.8551 15.0161 27.3864 14.9992C26.9178 14.9823 26.4546 15.1047 26.0556 15.351C25.6565 15.5974 25.3394 15.9565 25.1445 16.383C24.9496 16.8095 24.8855 17.2843 24.9604 17.7472C25.0352 18.2102 25.2457 18.6405 25.5652 18.9838C25.8847 19.3272 26.2988 19.568 26.7551 19.676C27.058 19.7478 27.3721 19.7592 27.6794 19.7095C27.9867 19.6598 28.2812 19.5501 28.5461 19.3865C28.8109 19.223 29.041 19.0088 29.2231 18.7563C29.4051 18.5038 29.5356 18.2179 29.6071 17.915H29.6081Z" fill="#BEEEFF"/>
                <path d="M37.8771 24.776L39.7171 16.969L23.1701 13.069L20.8241 23.024L20.3241 25.138L30.4241 27.519L36.8671 29.037L37.8771 24.776ZM41.0611 16.4L37.7721 30.36L19.0991 25.96L22.3881 12L41.0611 16.4Z" fill="white"/>
                <path d="M37.8773 24.7771L36.8773 29.0381L30.4343 27.5201L30.4774 27.3371C30.0184 26.4191 29.5313 25.5131 29.0063 24.6291L29.0804 24.5831C29.6874 24.2001 30.2963 23.8131 30.9043 23.4301C31.7334 22.8412 32.6537 22.3925 33.6283 22.1021C34.1175 21.9617 34.6337 21.943 35.1318 22.0476C35.6299 22.1522 36.0949 22.3768 36.4863 22.7021C36.7689 22.9902 37.0099 23.3164 37.2023 23.6711C37.4263 24.0411 37.6503 24.4081 37.8773 24.7771Z" fill="#0BD9CE"/>
                <path d="M30.4731 27.3361L30.4301 27.5191L20.3301 25.1381L20.8301 23.0241C21.8129 22.2155 22.9298 21.5853 24.1301 21.1621C24.7008 20.9128 25.3338 20.8431 25.9451 20.9621C26.7137 21.2721 27.3459 21.8472 27.7271 22.5831C28.1721 23.2541 28.6001 23.9361 29.0041 24.6291C29.5261 25.5121 30.0141 26.4181 30.4731 27.3361Z" fill="#08B5AC"/>
                <path d="M27.8421 15.0619C28.2986 15.1691 28.7131 15.4092 29.0332 15.752C29.3532 16.0948 29.5644 16.5247 29.6401 16.9875C29.7158 17.4504 29.6525 17.9252 29.4583 18.3521C29.2641 18.7789 28.9477 19.1386 28.5491 19.3856C28.1504 19.6326 27.6875 19.7559 27.2188 19.7398C26.7501 19.7237 26.2967 19.569 25.916 19.2952C25.5352 19.0214 25.2442 18.6409 25.0798 18.2017C24.9153 17.7625 24.8848 17.2845 24.992 16.8279C25.063 16.5247 25.193 16.2385 25.3747 15.9856C25.5564 15.7327 25.7862 15.5181 26.0509 15.354C26.3156 15.19 26.6101 15.0798 26.9174 15.0297C27.2247 14.9795 27.5389 14.9905 27.8421 15.0619Z" fill="#FFBE00"/>
                <path d="M33.2333 44.5399H32.2983V47.882C32.2983 48.1031 32.2105 48.3153 32.0541 48.4717C31.8977 48.6281 31.6855 48.7159 31.4643 48.7159H30.7733C30.5521 48.7159 30.34 48.6281 30.1836 48.4717C30.0272 48.3153 29.9393 48.1031 29.9393 47.882V44.5399H29.0093C28.9261 44.5399 28.8444 44.5175 28.7729 44.475C28.7014 44.4325 28.6426 44.3715 28.6028 44.2985C28.563 44.2254 28.5436 44.1429 28.5467 44.0598C28.5498 43.9766 28.5752 43.8959 28.6203 43.826L30.7293 40.546C30.7712 40.4807 30.8289 40.4271 30.8969 40.3899C30.965 40.3527 31.0413 40.3333 31.1188 40.3333C31.1964 40.3333 31.2727 40.3527 31.3407 40.3899C31.4088 40.4271 31.4664 40.4807 31.5083 40.546L33.6183 43.826C33.6633 43.8956 33.6887 43.976 33.6919 44.0588C33.6952 44.1416 33.6761 44.2238 33.6368 44.2967C33.5975 44.3697 33.5393 44.4307 33.4683 44.4735C33.3973 44.5163 33.3162 44.5392 33.2333 44.5399Z" fill="#015CD1"/>
                <path d="M39.8894 51.519H22.4434V47.601C22.4434 47.4552 22.5013 47.3153 22.6045 47.2121C22.7076 47.109 22.8475 47.051 22.9934 47.051H23.5054C23.6512 47.051 23.7911 47.109 23.8943 47.2121C23.9974 47.3153 24.0554 47.4552 24.0554 47.601V49.907H38.2784V47.601C38.2784 47.4552 38.3363 47.3153 38.4395 47.2121C38.5426 47.109 38.6825 47.051 38.8284 47.051H39.3404C39.4862 47.051 39.6261 47.109 39.7293 47.2121C39.8324 47.3153 39.8904 47.4552 39.8904 47.601L39.8894 51.519Z" fill="#015CD1"/>
            </svg>        
        `
    )
};