/**
 * Base Service para fazer a autentica??o se necess?ria e
 * informar o dominio da api
 */
export class BaseService {

    default_base_domain = "laravel_api_v1"
    env

    /**
     * Tem a lista de host
     * @type {{laravel_api_v1: string, host: {qa: string, localhost: string, production: string, homolog: string}}}
     * @private
     */
    _base_domains = {
        host: {
            qa: "qaapiconnect.pgmais.com.br",
            localhost: "localhost:8000",
            production: "apiconnect.pgmais.com.br",
            homolog: "homologapiconnect.pgmais.com.br",
        },
        laravel_api_v1: "/api/v1"
    }

    _base_domains_php = {
        qa: "qaconnect.pgmais.com.br",
        localhost: "localhost:8081",
        production: "connect.pgmais.com.br",
        homolog: "homologconnect.pgmais.com.br",
    }

    /**
     * Autentica a api do laravel caso n?o tenha sido autenticada ainda
     */
    constructor() {
        this.env = window.location.host.replace(/connect.pgmais\..*/g, "").replace(/connectpgmais\..*/g, "").replace("api", "").replace(/:|\d+|\.*/g, "") || "production";

        axios.defaults.withCredentials = true;
    }

    /**
     * Retorna o dominio da api que deve ser utilizada
     * @param type {"default"|"laravel_api_v1"|"php_api"}
     */
    baseDomain(type = "default") {
        type = type.toLowerCase();
        let base_method = "";
        if (type == "default") {
            base_method = this._base_domains[this.default_base_domain];
        } else {
            base_method = typeof this._base_domains[this.type] !== "undefined" ? this._base_domains[this.type] : "";
        }
        return this.protocolo + "://" + this._base_domains.host[(this.env)] + base_method;
    }

    /**
     * Retorna o dominio do backend em PHP que deve ser utilizado
     * @returns string
     */
    baseDomainPhp() {
        return this.protocolo + "://" + this._base_domains_php[(this.env)];
    }

    /**
     * Retorna o tipo de protocolo utilizado (http ou https)
     */
    get protocolo() {
        return window.location.href.match(/http(s?)/gm)[0];
    }
}
