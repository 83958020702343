import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "respostas-rapidas-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom xmlns="http://www.w3.org/2000/svg" width="58" height="52" viewBox="0 0 58 52">
                <g id="resp-rapidas" transform="translate(-2600 -1772)">
                    <rect id="Ret�ngulo_829" data-name="Ret�ngulo 829" width="58" height="52" transform="translate(2600 1772)" opacity="0"/>
                    <g id="Icon-respostas-rapidas" transform="translate(2539.814 1344.715)">
                    <path id="Uni�o_21" data-name="Uni�o 21" d="M-3565.28-244.762a.955.955,0,0,1-.537-.855V-249.9a5.8,5.8,0,0,1-3.459-1.9,5.955,5.955,0,0,1-1.5-3.957v-4.2a5.883,5.883,0,0,1,5.83-5.921h3.927v1.9h-3.927a3.982,3.982,0,0,0-3.931,4.022v4.2a3.982,3.982,0,0,0,3.931,4.022h.075a.951.951,0,0,1,.95.95v3.2l4.938-3.939a.956.956,0,0,1,.592-.207h12.539a3.982,3.982,0,0,0,3.93-4.022v-.249h1.9v.249a5.883,5.883,0,0,1-5.83,5.922h-12.207l-6.22,4.963a.96.96,0,0,1-.593.207A.952.952,0,0,1-3565.28-244.762Zm30.037-9.236-6.22-4.963h-12.207a5.883,5.883,0,0,1-5.83-5.921v-4.2a5.882,5.882,0,0,1,5.83-5.921h19.094a5.882,5.882,0,0,1,5.829,5.921v4.2a5.959,5.959,0,0,1-1.5,3.956,5.8,5.8,0,0,1-3.459,1.9v4.286a.955.955,0,0,1-.538.856.944.944,0,0,1-.412.095A.954.954,0,0,1-3535.242-254Zm-22.357-15.08v4.2a3.981,3.981,0,0,0,3.93,4.021h12.539a.955.955,0,0,1,.592.207l4.938,3.939v-3.2a.95.95,0,0,1,.949-.949h.075a3.981,3.981,0,0,0,3.929-4.021v-4.2a3.98,3.98,0,0,0-3.929-4.021h-19.094A3.98,3.98,0,0,0-3557.6-269.078Zm-10.086,1.027a.956.956,0,0,1-.538-.856v-4.287a5.8,5.8,0,0,1-3.458-1.9,5.963,5.963,0,0,1-1.5-3.958v-4.2a5.883,5.883,0,0,1,5.829-5.922h19.094a5.883,5.883,0,0,1,5.83,5.922v4.2a5.964,5.964,0,0,1-.978,3.286l-.139.209-1.587-1.043.138-.209a4.066,4.066,0,0,0,.666-2.243v-4.2a3.981,3.981,0,0,0-3.93-4.022h-19.094a3.981,3.981,0,0,0-3.929,4.022v4.2a3.981,3.981,0,0,0,3.929,4.022h.075a.951.951,0,0,1,.949.95v3.2l4.938-3.939a.957.957,0,0,1,.591-.207h2.494v1.9h-2.161l-6.22,4.963a.956.956,0,0,1-.593.207A.943.943,0,0,1-3567.686-268.052Z" transform="translate(3640.364 720.452)" />
                    </g>
                </g>
            </svg-custom>
        `
    )
};