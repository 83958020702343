import PreviewTemplate from "../../../components/organismos/PreviewTemplate";
import { DraggableItemClass } from "../../../classes/DraggableItemClass";
import { BasePillClass } from "../../../classes/BasePillClass";
import { CardRcsClass } from "../../../classes/CardRcsClass";

export default {
    components: {
        PreviewTemplate
    },
    data() {
        return {
            have_suggestions: false,
            type: "card",
            list_suggestions: [
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste", titleIcon: "mdi-earth" }) }),
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste 2", titleIcon: "mdi-map-marker" }) }),
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste 3", titleIcon: "mdi-phone" }) }),
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste 4", titleIcon: "mdi-map" }) })
            ],
            list_responses: [
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste 5"})}),
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste 6" }) })
            ],
            showOverflow: false,
            description: "Sua mensagem ser&aacute; apresentada desta maneira para o destinat&aacute;rio"
        };
    },
    computed: {
        message() {
            return this.type !== "message" ? null : [
                { "id": 0.19508571797241858, "text": "dasdsd", "type": "text", "pill": { "id": 0.9059766143196242, "title": null, "subTitle": null, "titleIcon": null, "prependIcon": null, "draggable": false, "editable": false, "deletable": false, "removable": false, "rounded": false, "contentColor": null, "backgroundColor": null, "tooltipText": null, "numberCharacters": null, "subTitleNewLine": false, "type": null, "selected": false }, "custom": false }];
        },
        image() {
            return this.type !== "image" ? null : "https://s4.static.brasilescola.uol.com.br/img/2019/09/panda.jpg";
        },
        listSuggestions() {
            return !this.have_suggestions ? [] : [
                ...this.list_responses,
                ...this.list_suggestions
            ];
        },
        card() {
            return this.type !== "card" ? null : new CardRcsClass({
                image: "https://s4.static.brasilescola.uol.com.br/img/2019/09/panda.jpg",
                title: "Um panda",
                description: "Como podemos ver, essa eh uma imagem de um panda muito fofo",
                suggestionButtons: [],
                suggestionResponses: this.have_suggestions ? this.list_responses : [],
                orientation: "VERTICAL",
                alignment: "LEFT",
                image_height: "TALL"
            });
        },
        listCard() {
            return this.type !== "carousel" ? [] : [
                new CardRcsClass({
                    image: "https://s4.static.brasilescola.uol.com.br/img/2019/09/panda.jpg",
                    title: "Tchau!",
                    description: "Aqui temos um panda dando tchauzinho",
                    suggestionButtons: this.have_suggestions ? this.list_suggestions : [],
                    suggestionResponses: []
                }),
                new CardRcsClass({
                    image: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0f/Grosser_Panda.JPG/1200px-Grosser_Panda.JPG",
                    title: "Brabo",
                    description: "Esse aqui como podemos observar, tem cara de mau",
                    suggestionButtons: [],
                    suggestionResponses: this.have_suggestions ? this.list_responses : []
                }),
                new CardRcsClass({
                    image: "https://st.depositphotos.com/1010683/1389/i/950/depositphotos_13895290-stock-photo-giant-panda-bear-eating-bamboo.jpg",
                    title: "Que fome!",
                    description: "Esse pandinha esta comendo um alimento muito saldavel",
                    suggestionButtons: this.have_suggestions ? this.list_suggestions : [],
                    suggestionResponses: this.have_suggestions ? this.list_responses : []
                }),
                new CardRcsClass({
                    image: "https://img.olhardigital.com.br/wp-content/uploads/2021/07/Panda-gigante-dormindo-em-um-ramo-de-arvore-1000x450.jpg",
                    title: "Que sono!",
                    description: "Os pandas tambme gostamd e dormir bastante, por isso essa imagem esta aqui",
                    suggestionButtons: this.have_suggestions ? this.list_suggestions : [],
                    suggestionResponses: this.have_suggestions ? this.list_responses : []
                })
            ];
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center" class="bg-background">
                <v-col cols="12" md="10">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="6">
                                        <v-radio-group v-model="type">
                                            <v-radio label="Mensagem" value="message"></v-radio>
                                            <v-radio label="Imagem" value="image"></v-radio>
                                            <v-radio label="Card" value="card"></v-radio>
                                            <v-radio label="Carrossel" value="carousel"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-checkbox label="Suggestions" v-model="have_suggestions"></v-checkbox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-checkbox label="Ajustar a altura" v-model="showOverflow"></v-checkbox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Descricao" v-model="description" />
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="5">
                            <v-card class="pa-5 bg-background" elevation="0">
                                <preview-template
                                    :type="type"
                                    :message="message"
                                    :list-suggestions="listSuggestions"
                                    :image="image"
                                    :card="card"
                                    :list-card="listCard"
                                    :show-overflow="showOverflow"
                                    :description="description"
                                ></preview-template>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};