import Vue from "vue";

const Utils = {};

const DDDs = { 11: true, 12: true, 13: true, 14: true, 15: true, 16: true, 17: true, 18: true, 19: true, 21: true, 22: true, 24: true, 27: true, 28: true, 31: true, 32: true, 33: true, 34: true, 35: true, 37: true, 38: true, 41: true, 42: true, 43: true, 44: true, 45: true, 46: true, 47: true, 48: true, 49: true, 51: true, 53: true, 54: true, 55: true, 61: true, 62: true, 63: true, 64: true, 65: true, 66: true, 67: true, 68: true, 69: true, 71: true, 73: true, 74: true, 75: true, 77: true, 79: true, 81: true, 82: true, 83: true, 84: true, 85: true, 86: true, 87: true, 88: true, 89: true, 91: true, 92: true, 93: true, 94: true, 95: true, 96: true, 97: true, 98: true, 99: true };

Utils.install = function (Vue) {
    Vue.prototype.$DDDs = DDDs;

    /**
     * Transforma uma string HTML em string normal
     * @param {string} str String HTML
     */
    Vue.htmlEntities = function (str) {
        let txt = document.createElement("textarea");
        txt.innerHTML = str;
        return txt.value;
    };

    /**
     * Verifica se um telefone tem formato e DDD validos
     * 
     * @param {string} phone 
     * @param {boolean} required 
     * @param {number} type 1 - Celular, 2 - Telefone Fixo, 3 - Ambos
     * @returns {boolean}
     */
    Vue.validatePhoneDDD = function (phone, required = false, type = 3) {
        let phone_numbers = (phone.match(/\d/gm) || []).join("");
        let is_phone_valid = window.Vue.validatePhone(phone_numbers, required, type);

        if (!is_phone_valid) {
            return false;
        }

        let is_ddd_valid = window.Vue.validateDDD(phone_numbers.slice(0, 2));

        if (!is_ddd_valid && phone_numbers.length > 2) {
            return false;
        }

        return true;
    };

    /**
     * Verifica se um telefone tem formato v�lido
     * 
     * @param {string} phone 
     * @param {boolean} required 
     * @param {number} type 1 - Celular, 2 - Telefone Fixo, 3 - Ambos
     * @returns {boolean}
     */
    Vue.validatePhone = function (phone, required = false, type = 3) {
        let phone_numbers = (phone.match(/\d/gm) || []).join("");
        let regex_mobile = /^\d{2}9?[1-9]\d{7}$/gm;
        let regex_fixo = /^\d{2}[2-5]\d{7}$/gm;

        if ((!phone_numbers) && required) {
            return false;
        }

        if (
            phone_numbers
            && (
                (phone_numbers.length !== 10 && type === 2)
                || (phone_numbers.length !== 10 && phone_numbers.length !== 11 && (type === 3 || type === 1))
            )
        ) {
            return false;
        }

        if (phone_numbers && !regex_mobile.test(phone_numbers) && !regex_fixo.test(phone_numbers)) {
            return false;
        }

        return true;
    };

    /**
     * Verifica se o DDD � v�lido
     * 
     * @param {number|string} ddd 
     * @returns {boolean}
     */
    Vue.validateDDD = function (ddd) {
        let is_valid = DDDs[ddd] === true;

        if (!is_valid) {
            return false;
        }

        return true;
    };

    /**
     * Formata uma data do formato DD/MM/YYYY para YYYY-MM-DD
     *
     * @param {string} date
     * @return {string}
     */
    Vue.formatDateBrasilToUsa = function (date) {
        if (!date) {
            return null;
        }
        date = date.split(" ")[0];
        const [day, month, year] = date.split("/");
        return `${year}-${month}-${day}`;
    };
    /**
     * Formata uma data do formato YYYY-MM-DD para DD/MM/YYYY
     *
     * @param {string} date
     * @return {string}
     */
    Vue.formatDateUsaToBrasil = function (date) {
        if (!date) {
            return null;
        }
        date = date.split(" ")[0];
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
    };
    /**
     * Verifica se uma data � anterior a outra
     *
     * @param {string} date            Data a ser verificada, no formado YYYY-MM-DD
     * @param {string} reference_date  Data de referencia, no formado YYYY-MM-DD
     * @return {boolean}
     */
    Vue.dateIsBefore = function (date, reference_date) {
        return dayjs(date).isBefore(dayjs(reference_date));
    };

    /**
     * Verifica se uma data � posterior a outra
     *
     * @param {string} date            Data a ser verificada, no formado YYYY-MM-DD
     * @param {string} reference_date  Data de referencia, no formado YYYY-MM-DD
     * @return {boolean}
     */
    Vue.dateIsAfter = function (date, reference_date) {
        return dayjs(date).isAfter(dayjs(reference_date));
    };

    /**
     * Pega a configura��o de tema de um produto
     *
     * @param {string} product Nome do produto
     * @return {object}
     */
    Vue.getProductTheme = function (product) {
        let config = {};

        switch (product.toLowerCase()) {
        case "rcs":
            config = {
                icon: "icon-rcs",
                background_color: "rcs",
                font_color: "white",
                full_name: "RCS",
                name: "RCS"
            };
            break;
        case "whatsapp":
            config = {
                icon: "icon-whatsapp",
                background_color: "whatsapp",
                font_color: "white",
                full_name: "Whatsapp",
                name: "Whatsapp"
            };
            break;
        case "sms-flash":
        case "flash":
            config = {
                icon: "icon-flash",
                background_color: "sms",
                font_color: "white",
                full_name: "SMS Flash",
                name: "Flash"
            };
            break;
        case "sms-concatenado":
        case "sms-c":
            config = {
                icon: "icon-sms-concatenado",
                background_color: "sms",
                font_color: "white",
                full_name: "SMS Concatenado",
                name: "Concatenado"
            };
            break;
        case "sms":
        case "hig":
            config = {
                icon: "icon-sms",
                background_color: "sms",
                font_color: "white",
                full_name: "SMS Simples",
                name: "SMS"
            };
            break;
        case "sms-smart":
        case "smg":
            config = {
                icon: "icon-smg",
                background_color: "sms",
                font_color: "white",
                full_name: "SMG SmartMessage",
                name: "SMG"
            };
            break;
        case "sms-interativo":
            config = {
                icon: "icon-sms-interativo",
                background_color: "sms",
                font_color: "white",
                full_name: "SMS Interativo",
                name: "Interativo"
            };
            break;
        case "sms-fallback":
            config = {
                icon: "$smsProductSmallIcon",
                background_color: "sms",
                font_color: "white",
                full_name: "SMS Alternativo",
                name: "SMS Alternativo"
            };
            break;
        }

        return config;
    };

    /**
     * Traduz o status do envio
     *
     * @param {string} status Nome do status
     * @return {string}
     */
    Vue.traduzirStatus = function (status) {
        switch (status.toUpperCase()) {
        case "DELIVERED":
            return "Entregue";
        case "WAITING":
            return "Aguardando";
        case "NOT_DELIVERED":
            return window.Vue.htmlEntities("N&atilde;o entregue");
        case "SENT":
            return "Enviado";
        case "CANCELED":
            return "Cancelado";
        case "REJECTED":
            return "Rejeitado";
        case "NOT_SENT":
            return window.Vue.htmlEntities("N&atilde;o enviado");
        }

        return status;
    };

    /**
     * Aplica a mascara ao telefone
     *
     * @param {string} phone N�mero de telefone
     * @return {string}
     */
    Vue.maskToPhone = function (phone) {
        let phone_masked = "";

        if (phone) {
            let phone_only_numbers = phone.match(/\d/gm).join("");
            let regex = new RegExp("(\\d{2})(\\d{" + Math.ceil((phone_only_numbers.length - 2) / 2) + "})(\\d{" + Math.trunc((phone_only_numbers.length - 2) / 2) + "})", "gm");
            phone_masked = phone_only_numbers.replace(regex, "($1) $2-$3");
        }

        return phone_masked;
    };

    /**
     * Pega a configura��o de status de campanha
     *
     * @param {string} product Nome do produto
     * @return {object}
     */
    Vue.getStatusCampaignConfig = function (cd_status) {
        let config = {};

        switch (cd_status.toUpperCase()) {
        case "A":
            config = {
                color: "gray-500",
                label: "A Enviar",
                icon: "mdi-clock",
                textColor: "gray-0",
                tooltipText: "Esta campanha est&aacute; aguardando a data e/ou hora agendada para iniciar os envios."
            };
            break;
        case "E":
            config = {
                color: "primary-blue-500",
                label: "Enviando",
                icon: "mdi-sync",
                textColor: "gray-0",
                tooltipText: "Esta campanha est&aacute; enviando as mensagens. Caso uma campanha permane&ccedil;a com este status por muito tempo, verifique se h&aacute; regras de DDD aplicadas para a carteira."
            };
            break;
        case "P":
            config = {
                color: "gray-50",
                label: "Pausa",
                icon: "mdi-pause",
                textColor: "gray-600",
                tooltipText: "A campanha foi pausada, portanto n&atilde;o est&aacute; realizando os envios."
            };
            break;
        case "F":
            config = {
                color: "green-900",
                label: "Finalizada",
                icon: "mdi-check-bold",
                textColor: "gray-0",
                tooltipText: "A campanha j&aacute; finalizou todos os envios."
            };
            break;
        case "G":
            config = {
                color: "white border-dashed-1 border-gray-600",
                label: "Processando",
                icon: "",
                textColor: "gray-600",
                tooltipText: "Esta campanha acabou de ser criada e o sistema ainda est&aacute; processando as informa&ccedil;&otilde;es."
            };
            break;
        case "R":
            config = {
                color: "white border-dashed-1 border-gray-600",
                label: "Renitencia",
                icon: "",
                textColor: "gray-600",
                tooltipText: "Esta campanha acabou de ser criada e o sistema ainda est&aacute; processando as informa&ccedil;&otilde;es de renit&ecirc;ncia"
            };
            break;
        case "C":
            config = {
                color: "error-500",
                label: "Cancelada",
                icon: "mdi-cancel",
                textColor: "gray-0",
                tooltipText: "A campanha foi cancelada, os envios restantes n&atilde;o ser&atilde;o realizados."
            };
            break;
        }

        return config;
    };
    /**
    * Pega a configura��o de status de envio AvulsoApi
    *
    * @param {string} product Nome do produto
    * @return {object}
    */
    Vue.getStatusAvulsoApiConfig = function (cd_status) {
        let config = {};

        switch (cd_status) {
        case "Aguardando":
            config = {
                color: "gray-200",
                label: "Aguardando",
                icon: "$clockFillIcon",
                textColor: "gray-0",
                tooltipText: "Mensagem aguardando para ser enviada."
            };
            break;
        case "Enviado":
            config = {
                color: "success-500",
                label: "Enviado",
                icon: "$checkCircleFillIcon",
                textColor: "gray-0",
                tooltipText: "Mensagem foi enviada."
            };
            break;
        case window.Vue.htmlEntities("N&atilde;o enviado"):
            config = {
                color: "orange-500",
                label: "N&atilde;o enviado",
                icon: "$exclamationCircleIcon",
                textColor: "gray-0",
                tooltipText: "Mensagem N&atilde;o foi enviada."
            };
            break;
        case "Entregue":
            config = {
                color: "success-900",
                label: "Entregue",
                icon: "$checkCircleFillIcon",
                textColor: "gray-0",
                tooltipText: "Mensagem foi entregue."
            };
            break;
        case window.Vue.htmlEntities("N&atilde;o entregue"):
            config = {
                color: "coral-900",
                label: "N&atilde;o entregue",
                icon: "$exclamationCircleIcon",
                textColor: "gray-0",
                tooltipText: "Mensagem N&atilde;o foi entregue."
            };
            break;
        case "Rejeitado":
            config = {
                color: "error-200",
                label: "Rejeitado",
                icon: " $timesCircleFillIcon",
                textColor: "gray-0",
                tooltipText: "O envio foi rejeitado"
            };
            break;
        case "Cancelado":
            config = {
                color: "error-500",
                label: "Cancelado",
                icon: " $timesCircleFillIcon",
                textColor: "gray-0",
                tooltipText: "O envio foi cancelada."
            };
            break;
        }

        return config;
    };

    /**
     * Pega a configura��o de tema de um modelo de template
     *
     * @param {string} product codigo do modelo
     * @return {object}
     */
    Vue.getModelTheme = function (product) {
        let config = {};

        switch (product.toLowerCase()) {
        case "r":
            config = {
                icon: "$templateCarouselIcon",
                background_color: false,
                font_color: "black",
                full_name: "CAROUSEL",
                name: "Carousel"
            };
            break;
        case "m":
            config = {
                icon: "$templateTextIcon",
                background_color: false,
                font_color: "black",
                full_name: "TEXTO",
                name: "Texto"
            };
            break;
        case "t":
            config = {
                icon: "$templateIcon",
                background_color: false,
                font_color: "black",
                full_name: "TEMPLATE",
                name: "Template"
            };
            break;
        case "i":
            config = {
                icon: "$templateImageIcon",
                background_color: false,
                font_color: "black",
                full_name: "IMAGEM",
                name: "Imagem"
            };
            break;
        case "v":
            config = {
                icon: "$templateVideoIcon",
                background_color: false,
                font_color: "black",
                full_name: "VIDEO",
                name: "Video"
            };
            break;
        case "a":
            config = {
                icon: "$templateAudioIcon",
                background_color: false,
                font_color: "black",
                full_name: "AUDIO",
                name: "Audio"
            };
            break;
        case "f":
            config = {
                icon: "$templateFileIcon",
                background_color: false,
                font_color: "black",
                full_name: "FILE",
                name: "file"
            };
            break;
        case "c":
            config = {
                icon: "$templateCardIcon",
                background_color: false,
                font_color: "black",
                full_name: "CARD",
                name: "Card"
            };
            break;
        }

        return config;
    };

    Vue.directive("date-usa-br", {
        bind(el, binding) {
            el.innerHTML = window.Vue.formatDateUsaToBrasil(binding.value);
        },
        update(el, binding) {
            el.innerHTML = window.Vue.formatDateUsaToBrasil(binding.value);
        }
    });

    /**
     * Verifica se a data no formato BR eh valida
     *
     * @param {Date} date Data no formado DD/MM/YYY
     * @return {boolean}
     */
    Vue.isValidDateBr = function (date) {
        let list_of_days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        let date_array = date.split("/");

        if (date_array.length === 3) {
            if (date_array[2].length === 4) {
                if (date_array[1].length === 2 && (parseInt(date_array[1]) > 0 && parseInt(date_array[1]) <= 12)) {
                    if (date_array[0].length === 2 && (parseInt(date_array[0]) > 0 && parseInt(date_array[0]) <= list_of_days[parseInt(date_array[1]) - 1])) {
                        return true;
                    }
                }
            }
        }

        return false;
    };

    /**
     * Verifica se a data no formato USA eh valida
     *
     * @param {Date} date Data no formado DD/MM/YYY
     * @return {boolean}
     */
    Vue.isValidDateUsa = function (date) {
        let list_of_days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        let date_array = date.split("-");

        if (date_array.length === 3) {
            if (date_array[0].length === 4) {
                if (date_array[1].length === 2 && (parseInt(date_array[1]) > 0 && parseInt(date_array[1]) <= 12)) {
                    if (date_array[2].length === 2 && (parseInt(date_array[2]) > 0 && parseInt(date_array[2]) <= list_of_days[parseInt(date_array[1]) - 1])) {
                        return true;
                    }
                }
            }
        }

        return false;
    };
};

Vue.filter("htmlEntities", function (value) {
    return window.Vue.htmlEntities(value);
});

Vue.filter("maskPhone", function (value) {
    return window.Vue.maskToPhone(value);
});

Vue.filter("translateStatus", function (value) {
    return window.Vue.traduzirStatus(value);
});

Vue.use(Utils);