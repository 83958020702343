import BaseChat from "../../../components/atomos/BaseChat";
export default {
    name: "BaseChatUsage",
    components: { BaseChat },
    data() {
        return {
            texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque luctus nunc vel ipsum scelerisque, a placerat mi laoreet. Mauris convallis volutpat sollicitudin. Vivamus vulputate scelerisque cursus. Mauris quis dui maximus, eleifend erat a, venenatis justo."
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center" class="mt-8">
                <v-col cols="6" class="text-center">
                    <base-chat type="S" :messageText="texto"/>
                </v-col>
            </v-row>
        `
    )
};