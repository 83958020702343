import FormAddCustomField from "./forms/FormAddCustomField?v=2";
import GroupPills from "../../../components/moleculas/GroupPills?v=2";
import SectionCard from "../../../components/moleculas/SectionCard?v=2";
import { BasePillClass } from "../../../classes/BasePillClass?v=2";
import { DraggableItemClass } from "../../../classes/DraggableItemClass?v=2";

/**
 * Componente para dicionar campos personalizados
 * @requires {@link FormAddCustomField}
 * @requires {@link GroupPills}
 * @requires {@link SectionCard}
 * @requires {@link DraggableItemClass}
 * @requires {@link BasePillClass}
 * @component
 * @displayName FieldsTemplate
 * @category Page / Template RCS
 * @vue
 * @author Pedro Augusto <pedro.ribas@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        FormAddCustomField,
        GroupPills,
        SectionCard
    },
    props: {
        /**
         * Lista de pills customizadas
         */
        value: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Indica se a sessao de campos padroes deve ser exibida ou nao
         */
        showDefaultFields: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Indica se o formulario de adicao de campos personalizados deve ser exibido ou nao
         */
        showForm: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Descricao para os campos customizados
         */
        descriptionCustomFields: {
            type: String,
            required: false,
            default: "Campos que voc&ecirc; criou"
        }
    },
    data() {
        return {
            list_fields_default: this.mountListToDraggable([
                new BasePillClass({
                    rounded: true,
                    prependIcon: "fa-grip-vertical",
                    title: "Nome",
                    subTitle: "10 caracteres",
                    draggable: true,
                    numberCharacters: 10
                }),
                new BasePillClass({
                    rounded: true,
                    prependIcon: "fa-grip-vertical",
                    title: "CPF",
                    subTitle: "11 caracteres",
                    draggable: true,
                    numberCharacters: 11
                }),
                new BasePillClass({
                    rounded: true,
                    prependIcon: "fa-grip-vertical",
                    title: window.Vue.htmlEntities("Linha digit&aacute;vel"),
                    subTitle: "48 caracteres",
                    draggable: true,
                    numberCharacters: 48
                })
            ]),
            list_fields_custom: _.cloneDeep(this.value),
            group_name: "default",
            data_form: null,
            focus_form: false
        };
    },
    computed: {
        list_data_validation() {
            return this.list_fields_default.concat(this.list_fields_custom);
        }
    },
    watch: {
        value() {
            this.list_fields_custom = _.cloneDeep(this.value);
        }
    },
    methods: {
        /**
         * Emite um evento informando que uma notificacao deve ser exibida
         * @param {Object} data 
         */
        showNotify(data) {
            this.$emit("showNotify", data);
        },
        /**
         * Funcao para remover do array list_fields_custom
         * @param {DraggableItemClass} item 
         * @vue
         */
        removeCustom(item) {
            this.showNotify({
                notify_title: "Campo personalizado exclu&iacute;do",
                notify_message: `O campo personalizado <b>${item.pill.title}</b> foi exclu&iacute;do.`,
                removed_pill: item,
                position_removed: null,
                notify_button_label: "Restaurar campo",
                restore_type: "delete"
            });

            this.list_fields_custom = this.list_fields_custom.filter((custom_item) => {
                return custom_item.id !== item.id;
            });

            /**
             * Indica que um item foi removido da lista
             * @param {DraggableItemClass} item Dados do item removido
             * @event removeItem
             * @vue
             */
            this.$emit("removeItem", item);
            this.$emit("input", this.list_fields_custom);
            this.data_form = null;
            this.focus_form = false;
        },
        /**
         * Metodo chamado ao clicar em alguma pill
         * @param {DraggableItemClass} item Dados de um item
         * @vue
         */
        itemClick(item) {
            this.showNotify({
                notify_title: "Campo personalizado adicionado",
                notify_message: `O campo personalizado <b>${item.pill.title}</b> foi adicionado &agrave; mensagem`,
                removed_pill: null,
                position_removed: null,
                notify_button_label: null,
                restore_type: null
            });

            /**
             * Evento que emite o item clicado
             * 
             * @property {DraggableItemClass} item Dados de um item
             * 
             * @event itemClick
             * @vue
             */
            this.$emit("itemClick", item);
        },
        /**
         * Funcao que adiciona conteudo no array de list_fields_custom
         * @param {Object} value 
         * @vue
         */
        formData(value) {
            if (value.id) {
                for (let field of this.list_fields_custom) {
                    if (field.id === value.id) {
                        field.pill.title = value.name_field;
                        field.pill.subTitle = value.number_characters + " caracteres";
                        field.pill.numberCharacters = value.number_characters;
                        field.pill.selected = false;
                        field.text = value.name_field;

                        /**
                         * Informa que um item foi editado
                         * @property {DraggableItemClass} field Dados do item
                         * 
                         * @event editItem
                         * @vue
                         */
                        this.$emit("editItem", field);
                        break;
                    }
                }
            } else {
                let new_item = new DraggableItemClass({
                    text: value.name_field,
                    type: "pill",
                    custom: true,
                    pill: new BasePillClass({
                        rounded: true,
                        deletable: true,
                        editable: true,
                        prependIcon: "fa-grip-vertical",
                        title: value.name_field,
                        subTitle: value.number_characters + " caracteres",
                        draggable: true,
                        numberCharacters: value.number_characters
                    })
                });

                this.showNotify({
                    notify_title: "Campo personalizado criado",
                    notify_message: `O campo personalizado <b>${value.name_field}</b> foi criado e adicionado &agrave; mensagem`,
                    removed_pill: null,
                    position_removed: null,
                    notify_button_label: null,
                    restore_type: null
                });

                this.list_fields_custom.push(new_item);
                this.itemClick(new_item);
            }

            this.data_form = null;
            this.$emit("input", this.list_fields_custom);
        },
        /**
         * Edita um item
         * @param {DraggableItemClass} item 
         */
        editItem(item) {
            this.focus_form = true;

            for (let field of this.list_fields_custom) {
                if (field.id === item.id) {
                    field.pill.selected = true;

                    this.data_form = {
                        id: item.id,
                        name_field: item.pill.title,
                        number_characters: item.pill.numberCharacters
                    };
                } else {
                    field.pill.selected = false;
                }
            }
        },
        /**
         * Monta a lista com a estrutura padrao para ser usado em um componente draggable
         * @param {BasePillClass} array_list Lista de classes pills
         * @vue
         */
        mountListToDraggable(array_list) {
            let list = [];

            for (let item of array_list) {
                list.push(
                    new DraggableItemClass({
                        text: item.title,
                        type: "pill",
                        pill: item
                    })
                );
            }

            return list;
        },
        /**
         * Realiza o cancelamento da edicao de um item
         */
        cancelEdit() {
            if (this.data_form) {
                for (let item of this.list_fields_custom) {
                    if (item.id === this.data_form.id) {
                        item.pill.selected = false;
                        break;
                    }
                }
            }

            this.data_form = null;
        }
    },
    template: (
        /*html*/
        `       
            <v-row>
                <v-col cols="12" sm="6" md="12">
                    <section-card 
                        title="Voc&ecirc; pode adicionar alguns campos personalizados na mensagem para serem substitu&iacute;dos pelos dados de cada cliente em uma campanha"
                        icon="$anexoIcon"
                    >
                        <template #body>
                            <group-pills
                                description-default="J&aacute; criamos alguns campos para voc&ecirc;"
                                :description-custom="descriptionCustomFields"
                                :list-pills-default="showDefaultFields ? list_fields_default : null"
                                :list-pills-custom="list_fields_custom"
                                :group-name="group_name"
                                @delete="removeCustom"
                                @edit="editItem"
                                @click="itemClick"
                            ></group-pills>
                        </template>
                    </section-card>          
                </v-col>
                <v-col v-if="showForm" cols="12" sm="6" md="12" class="mt-6">
                    <section-card 
                        title="Voc&ecirc; pode criar o seu pr&oacute;prio campo personalizado com informa&ccedil;&otilde;es exclusivas da sua campanha"
                        icon="$addCampoIcon"
                        :focus-body="focus_form"
                    >
                        <template #body>
                            <form-add-custom-field
                                :list-data-validation ="list_data_validation"
                                :data-form="data_form"
                                @formSubmit="formData"
                                @deleteItem="removeCustom"
                                @cancelEdit="cancelEdit"
                                @focus="focus_form = true"
                                @blur="focus_form = false"
                            ></form-add-custom-field>
                        </template>
                    </section-card>   
                </v-col>
            </v-row>

        `
    )
};