import RouterPage from "../RouterPage";
import GerenciarPage from "./GerenciarPage";

export default [
    {
        path: "gerenciar",
        name: "gerenciar",
        component: RouterPage,
        children: [
            {
                path: "list",
                name: "gerenciarPage",
                component: GerenciarPage
            }
        ]
    }
];