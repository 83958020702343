import CardShortChoice from "../../../components/moleculas/CardShortChoice";

export default ({
    components: { CardShortChoice },
    data() {
        return {
            buttons_array: [{label: "Texto", icon: "icon-editar-template", disabled: false, active: false, value: "texto"},
                {label: "Imagem", icon: "icon-imagem", disabled: false, active: false, value: "imagem"},
                {label: "Card", icon: "icon-card", disabled: false, active: false, value: "card"},
                {label: "Carrosel", icon: "icon-carousel", disabled: false, active: false, value: "carousel"}]
        };
    },
    template: (
    /*html*/
        `
                <div class="container" id="vue-page">
                    <v-row>
                        <card-short-choice v-model="buttons_array"></card-short-choice>
                    </v-row>
                </div>
                `
    )
});