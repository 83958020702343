import BaseTooltip from "../../../components/atomos/BaseTooltip";
import BaseInformation from "../../../components/atomos/BaseInformation";
import { CampaignUtils } from "../../../utils/CampaignUtils";

/**
 * Componente responsavel por exibir a higienizacao dos telefones
 *
 * @requires {@link BaseTooltip}
 * @requires {@link BaseInformation}
 * @requires {@link CampaignUtils}
 *
 * @displayName SummaryHygiene
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip,
        BaseInformation
    },
    props: {
        /**
         * Array de telefones
         */
        phones: {
            type: Array,
            required: true
        },
        /**
         * Carteira ID
         */
        client: {
            type: Number,
            required: true
        },
        /**
         * Utilizado para distinguir validacao se o tipo de produto for "whatsapp"
         */
        typeProduct: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Nao exibe a mensagem de erro caso nenhum numero valido seja encontrado
         */
        hideError: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Lista inicial de blacklist dos telefones
         */
        defaultBlackList: {
            type: Array,
            required: false,
            default: function() {
                return [];
            }
        }
    },
    data() {
        return {
            valid_phones: this.phones,
            blacklist: this.defaultBlackList,
            repeated: 0,
            invalid: 0,
            campaign_utils: new CampaignUtils(),
            loading: true,
            blacklist_loading: true
        };
    },
    watch: {
        phones() {
            this.loading = true;
            this.valid_phones = this.phones;
            this.checkPhones();
        },
        valid_phones() {
            this.$emit("validPhones", this.valid_phones);
        },
        blacklist(value) {
            this.$emit("blacklistChange", value);
        }
    },
    mounted() {
        this.checkPhones();
    },
    methods: {
        ...window.Vuex.mapActions("campaign", ["ActionDoGetBlacklistNumbers"]),
        /**
         * Faz a validacao dos numeros informados na seguinte ordem:
         * 1 - Remove os invalidos (numeros em formatos incorretos)
         * 2 - Remove os repetidos
         * 3 - Remove numeros em blacklist
         * @vue
         */
        checkPhones() {
            this.loading = true;
            this.filterRepeat();
            this.filterInvalid();
            this.filterBlacklist();
            this.loading = false;
        },
        /**
         * Retorna os telefones informados que est�o na blacklist
         * @vue
         */
        getBlacklistNumbers() {
            // Verifica se j� foi feita a request de blacklist para n�o refazer
            if (this.defaultBlackList.length) {
                return new Promise(resolve => {
                    resolve(this.defaultBlackList);
                });
            } else {
                // Envia para validacao no backend somente os numeros validos, desconsiderando os invalidos e os repetidos
                return this.ActionDoGetBlacklistNumbers({
                    "phones": this.valid_phones,
                    "client": this.client
                }).then(blacklistNumbers => {
                    this.blacklist = Object.keys(blacklistNumbers);
                }).catch(error => {
                    console.log("error", error);
                });
            }
        },
        /**
         * Filtra os numeros que estao presentes na blacklist
         * @vue
         */
        filterBlacklist() {
            if (this.valid_phones.length) {
                this.blacklist_loading = true;
                this.getBlacklistNumbers().then(() => {
                    let filteredPhones = this.valid_phones.filter((phone) =>
                        !this.blacklist.includes(phone)
                    );
                    this.valid_phones = filteredPhones;
                    this.blacklist_loading = false;
                });
            }
        },
        /**
         * Filtra os numeros repetidos
         * @vue
         */
        filterRepeat() {
            let phones_object = {};

            for (let phone of this.valid_phones) {
                phones_object[phone] = true;
            }

            let old_total = this.valid_phones.length;
            this.valid_phones = Object.keys(phones_object);
            this.repeated = old_total - this.valid_phones.length;
        },
        /**
         * Filtra os numeros invalidos
         * @vue
         */
        filterInvalid() {
            let filteredPhones = this.valid_phones.filter((phone) =>
                this.validatePhone(phone)
            );

            this.invalid = this.valid_phones.length - filteredPhones.length;
            this.valid_phones = filteredPhones;
        },
        /**
         * Funcao que valida se o numero e valido
         * @param {Integer} phone
         * @returns {Boolean}
         */
        validatePhone(phone) {
            if (phone) {
                let numbers_phone = phone.match(/\d+/gm)?.toString();

                if (numbers_phone !== null) {
                    let validate = false;

                    if (this.typeProduct === "whatsapp") {
                        validate = this.campaign_utils.is0800Valid(numbers_phone).status
                            || this.campaign_utils.is0300Valid(numbers_phone).status
                            || this.campaign_utils.is4000Valid(numbers_phone).status
                            || this.campaign_utils.isTelefoneDDDValid(numbers_phone, true).status;
                    } else {
                        validate = this.campaign_utils.isTelefoneDDDValid(numbers_phone, true, 1).status;
                    }
                    return validate;
                }
            }
            return false;
        },
        formatNumber(ev) {
            let formatted = ev.toLocaleString(); // Formata o numero
            formatted = formatted.replace(/,(\d{3})$/, ".$1"); // Troca a virgula por ponto
            return formatted;
        }
    },
    template:
    /*html*/
        `
        <v-row class="d-flex px-0" no-gutters>
            <v-col v-if="!loading && (valid_phones.length || hideError)" cols="12">
                <v-row class="d-flex" no-gutters>
                    <v-col cols="12" :data-v-step="5">
                        <base-tooltip text="Ap&oacute;s a higieniza&ccedil;&atilde;o, este &eacute; o total de registros que est&atilde;o eleg&iacute;veis para envio.">
                            <v-row class="d-flex min-h-60 p-2 border-1 rounded border-success-500 align-items-center" no-gutters>
                                <v-col cols="2" class="pl-4">
                                    <v-icon size="20" color="success-500">
                                        mdi-check-bold
                                    </v-icon>
                                </v-col>
                                <v-col cols="10" class="pl-2">
                                    <span class="body-2">Telefones v&aacute;lidos: <strong>{{ loading ? "..." : formatNumber(valid_phones.length) }}</strong></span>
                                </v-col>
                            </v-row>
                        </base-tooltip>
                    </v-col>
                </v-row>
                <v-row class="d-flex mt-2" no-gutters>
                    <v-col cols="12" :data-v-step="6">
                        <v-row class="d-flex justify-content-center p-2 border-1 rounded border-gray-300" no-gutters>
                            <base-tooltip text="Detalhamento da higieniza&ccedil;&atilde;o.">
                                <v-row no-gutters>
                                    <v-col cols="2">
                                        <v-icon size="20" color="gray-300">
                                            {{ "$higienizadosCardsIcon" }}
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="10" class="px-0">
                                        <span class="body-2">Telefones higienizados:</span>
                                    </v-col>
                                </v-row>
                            </base-tooltip>
                            <v-row class="d-flex mt-2 text-center">
                                <v-col
                                    cols="6"
                                    md="12"
                                    lg="6"
                                    class="pb-2"
                                >
                                    <base-tooltip text="Registros que foram descartados por blacklist.">
                                        <v-row class="pr-2 pl-2 align-items-center">
                                            <v-col cols="12" class="p-1 border-1 rounded border-gray-300 text-nowrap">
                                                <span class="body-2">Blacklist: <v-progress-circular
                                                v-if="blacklist_loading"
                                                :size="20"
                                                indeterminate
                                                color="primary-blue-500"
                                            /><strong v-else>{{ loading ? "..." : formatNumber(blacklist.length) }}</strong></span>
                                            </v-col>
                                        </v-row>
                                    </base-tooltip>
                                </v-col>
                                <v-col
                                    cols="6"
                                    md="12"
                                    lg="6"
                                    class="pb-2"
                                >
                                    <base-tooltip text="Registros com o n&uacute;mero de telefone fora do padr&atilde;o ou vazio.">
                                        <v-row class="pr-2 pl-2 align-items-center">
                                            <v-col cols="12" class="p-1 border-1 rounded border-gray-300 text-nowrap">
                                                <span class="body-2">Inv&aacute;lidos: <strong>{{ loading ? "..." : formatNumber(invalid) }}</strong></span>
                                            </v-col>
                                        </v-row>
                                    </base-tooltip>
                                </v-col>
                                <v-col
                                    cols="6"
                                    md="12"
                                    lg="6"
                                    class="pb-2"
                                >
                                    <base-tooltip text="Registros com o n&uacute;mero de telefone repetido.">
                                        <v-row class="pr-2 pl-2 align-items-center">
                                            <v-col cols="12" class="p-1 border-1 rounded border-gray-300 text-nowrap">
                                                <span class="body-2">Repetidos: <strong>{{ loading ? "..." : formatNumber(repeated) }}</strong></span>
                                            </v-col>
                                        </v-row>
                                    </base-tooltip>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <span class="body-2">Voc&ecirc; est&aacute; economizando <strong>{{ loading ? "..." : formatNumber(blacklist.length+invalid+repeated) }}</strong> envios</span>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-else-if="!loading" cols="12">
                <base-information description="N&atilde;o h&aacute; telefones v&aacute;lidos na coluna informada. Por favor selecione uma coluna do arquivo que contenha no m&iacute;nimo 1 (um) n&uacute;mero de telefone v&aacute;lido." />
            </v-col>
            <v-col v-else cols="12">
                <v-row no-gutters class="py-4">
                    <v-col cols="12">
                        <v-skeleton-loader type="card" height="50" />
                    </v-col>
                    <v-col cols="12" class="mt-2">
                        <v-skeleton-loader type="card" height="120" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        `
};