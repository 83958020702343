import PageHeader from "../../components/moleculas/PageHeader.js?v=2";
import PgTour from "../../components/organismos/PgTour.js?v=2";
import BaseButton from "../../components/atomos/BaseButton.js?v=2";
import BaseDropdownButton from "../../components/atomos/BaseDropdownButton.js?v=3";
import FormFilterTemplate from "./components/forms/FormFilterTemplate.js?v=3";
import BaseDataTable from "../../components/organismos/BaseDataTable.js?v=2";
import CanalIconDescription from "../../components/moleculas/CanalIconDescription.js?v=2";
import ButtonAction from "../../components/moleculas/ButtonAction.js?v=2";
import DialogDetails from "./components/dialogs/DialogDetails.js?v=2";
import DialogDisable from "./components/dialogs/DialogDisable.js";
import DialogEnable from "./components/dialogs/DialogEnable.js";
import DialogTemplateCopy from "../template-rcs/components/dialogs/DialogTemplateCopy";
import BaseNotify from "../../components/atomos/BaseNotify.js?v=2";
import TableThumb from "../../components/moleculas/TableThumb.js";
import BaseTooltip from "../../components/atomos/BaseTooltip";
import { DataTableHeaderClass } from "../../classes/DataTableHeaderClass.js";
import { BaseService } from "../../services/BaseService.js";
import TemplateUtils from "../../utils/TemplateUtils.js";
import ModeloIconDescription from "../../components/atomos/ModeloIconDescription.js";
import * as types from "../template-rcs/store/mutations-types";

const template_structure = {
    "CD_TEMPLATE": "",
    "NM_EMPRESA": "",
    "ID_EMPRESA": "",
    "ID_EMPRESA_PAI": "",
    "CD_PRODUTO_PDES": "",
    "DS_TEMPLATE": "",
    "TP_TEMPLATE": "",
    "ID_TEMPLATE": "",
    "NM_USUARIO": "",
    "DT_TEMPLATE": "",
    "ALT_NM_USUARIO": "",
    "DT_ULTIMA_ALTERACAO": "",
    "NM_PRODUTO_PAINEL": "",
    "NM_TEMPLATE": ""
};

/**
 * Pagina que realiza a listagem de relatorios
 * 
 * @requires {@link PageHeader}
 * @requires {@link PgTour}
 * @requires {@link BaseButton}
 * @requires {@link BaseDropdownButton}
 * @requires {@link FormFilterTemplate}
 * @requires {@link BaseDataTable}
 * @requires {@link CanalIconDescription}
 * @requires {@link ButtonAction}
 * @requires {@link DialogDetails}
 * @requires {@link DialogDisable}
 * @requires {@link DialogEnable}
 * @requires {@link DialogTemplateCopy}
 * @requires {@link BaseNotify}
 * @requires {@link TableThumb}
 * @requires {@link BaseTooltip}
 * @requires {@link DataTableHeaderClass}
 * @requires {@link BaseService}
 * @requires {@link TemplateUtils}
 * @requires {@link ModeloIconDescription}
 *
 * @displayName ListTemplatePage
 * @category Page / Template
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PageHeader,
        PgTour,
        BaseButton,
        BaseDropdownButton,
        FormFilterTemplate,
        BaseDataTable,
        CanalIconDescription,
        ButtonAction,
        DialogDetails,
        DialogDisable,
        DialogEnable,
        DialogTemplateCopy,
        BaseNotify,
        TableThumb,
        BaseTooltip,
        ModeloIconDescription
    },
    filters: {
        format_date: function (date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY HH:mm:ss");
            } else {
                return "-";
            }
        },
        filter_empty: function (value) {
            return value ? value : "-";
        }
    },
    data() {
        return {
            base_service: new BaseService(),
            template_structure: template_structure,
            template_utils: new TemplateUtils(),
            type_product: this.$cookie === undefined ? getCookie("type_product") : this.$cookie.get("type_product"),
            id_empresa: this.$cookie === undefined ? getCookie("id_empresa_selected") : this.$cookie.get("id_empresa_selected"),
            steps: [
                {
                    target: "[data-v-step='1']",
                    title: "Crie um novo template",
                    content: "A qualquer momento voc&ecirc; pode criar um novo template clicando neste bot&atilde;o.",
                    date: "2022-10-07 00:00:00"
                },
                {
                    target: "[data-v-step='2']",
                    title: "Filtro",
                    content: "Voc&ecirc; pode escolher qualquer uma das op&ccedil;&otilde;es de filtros dispon&iacute;veis para encontrar sua template de forma mais r&aacute;pida e f&aacute;cil."
                },
                {
                    target: "[data-v-step='3']",
                    title: "Personalizar tabela",
                    content: "Voc&ecirc; pode escolher quais s&atilde;o as colunas que deseja visualizar na tabela."
                },
                {
                    target: "[data-v-step='4']",
                    title: "Coluna de SMS alternativo",
                    content: "Voc&ecirc; pode ver quais s&atilde;o os templates que cont&eacute;m SMS alternativo cadastrado."
                },
                {
                    target: "[data-v-step='5']",
                    title: "Coluna de conte&uacute;do",
                    content: "Aqui voc&ecirc; pode ver como o template est&aacute; configurado. Clique sobre a miniatura para expandir a visualiza&ccedil;&atilde;o."
                }
            ],
            custom_slots: ["CD_PRODUTO_PDES", "NM_EMPRESA", "DS_TEMPLATE", "TP_TEMPLATE", "NM_USUARIO", "DT_TEMPLATE", "ALT_NM_USUARIO", "DT_ULTIMA_ALTERACAO", "fallback", "actions"],
            list_template: [],
            table_loading: true,
            table_options: null,
            filter_data: {},
            show_dialog_details: false,
            show_dialog_disable: false,
            show_dialog_enable: false,
            show_dialog_copy: false,
            template_selected: template_structure,
            show_notify: false,
            notify_title: "",
            notify_message: "",
            notify_type: "notification"
        };
    },
    computed: {
        ...window.Vuex.mapState("templatesrcs", {
            id_template_created: state => state.id_template_created
        }),
        table_headers() {
            let headers = [
                new DataTableHeaderClass({ text: "Canal", value: "CD_PRODUTO_PDES", cellClass: "text-nowrap", tooltipText: "Canal referente a template" }),
                new DataTableHeaderClass({ text: "Cliente", value: "NM_EMPRESA", visibility: "lg", tooltipText: "Cliente vinculado a template" }),
                new DataTableHeaderClass({ text: "C&oacute;digo", value: "CD_TEMPLATE", cellClass: "break-word", tooltipText: "C&oacute;digo do template" }),
                new DataTableHeaderClass({ text: "Nome", value: "NM_TEMPLATE", visibility: "lg", cellClass: "break-word", tooltipText: "Nome do template" }),
                new DataTableHeaderClass({ text: "Criado por", value: "NM_USUARIO", visibility: "none", className: "text-nowrap", tooltipText: "Usu&aacute;rio que criou a template" }),
                new DataTableHeaderClass({ text: window.Vue.htmlEntities("Data de cria&ccedil;&atilde;o"), value: "DT_TEMPLATE", className: "text-nowrap", tooltipText: "Data que foi criado a template" }),
                new DataTableHeaderClass({ text: "Atualizado por", value: "ALT_NM_USUARIO", visibility: "lg", className: "text-wrap", cellClass: "break-word", tooltipText: "&Uacute;ltimo usu&aacute;rio que alterou a template" }),
                new DataTableHeaderClass({ text: window.Vue.htmlEntities("&Uacute;ltima altera&ccedil;&atilde;o"), value: "DT_ULTIMA_ALTERACAO", width: "112px", visibility: "lg", className: "text-nowrap", tooltipText: "&Uacute;ltima data de altera&ccedil;&atilde;o da template" })
            ];

            if (this.type_product === "RCS") {
                headers.push(new DataTableHeaderClass({ text: "Modelo", value: "TP_TEMPLATE", visibility: "sm", tooltipText: "Tipo de template (Texto, m&iacute;dia ou carrousel)" }));
            }

            headers.push(new DataTableHeaderClass({ text: window.Vue.htmlEntities("Conte&uacute;do"), value: "DS_TEMPLATE", sortable: false, width: "130px", tooltipText: "Texto resumido do conte&uacute;do da template", stepTutorial: 5 }));

            if (this.type_product === "RCS") {
                headers.push(new DataTableHeaderClass({ text: "SMS alternativo", value: "fallback", visibility: "lg", tooltipText: "Resumo da template de SMS Alternativo", stepTutorial: 4 }));
            }

            return headers.concat([
                new DataTableHeaderClass({ text: window.Vue.htmlEntities("A&ccedil;&otilde;es"), value: "actions", visibility: "sm", sortable: false, className: "text-nowrap", tooltipText: "Bot�es de a��o" })
            ]);
        },
        server_side_config() {
            if (Object.keys(this.filter_data).length > 0) {
                return {
                    request_to: "API",
                    url: "/template-rcs/list",
                    data: this.filter_data
                };
            } else {
                return null;
            }
        }
    },
    watch: {
        /**
         * Verifica se foi copiado um template para exibir tooltip pro usuario
         *  e atualizar a tabela
         * @param {Integer} idTemplate
        */
        id_template_created(idTemplate) {

            if (idTemplate !== null) {
                this.showNotify({ result: true, type: "copy"});

                this.table_options = {
                    sortBy: ["DT_TEMPLATE"],
                    sortDesc: [true],
                    page: 1
                };
            }
        }
    },
    methods: {
        /**
         * Redireciona o usuario para uma pagina de cadastro de template de acordo com o tipo de produto ativo ou link selecionado
         * @param {String} type Tipo de redirecionamento
         * @vue
         */
        actionButton(type) {
            switch (type) {
            case "simples":
                document.location.href = this.base_service.baseDomainPhp() + "/frm-template.php";
                break;
            case "concatenado":
                document.location.href = this.base_service.baseDomainPhp() + "/frm-template-concatenado.php";
                break;
            case "rcs":
                document.location.href = this.base_service.baseDomainPhp() + "/Template/criarTemplateRcs";
                break;
            }
        },
        filterSubmit(data) {
            this.table_loading = false;
            this.filter_data = {
                tipo: data.tipo,
                cliente: data.cliente,
                codigo: data.codigo,
                nome: data.nome,
                resumo: data.resumo,
                dt_criacao: data.data_criacao,
                dt_alteracao: data.data_alteracao,
                has_fallback: data.fallback,
                status: data.status
            };
        },
        getResumoTemplate(type, resumo) {
            let data = resumo;
            //let template_type = this.template_utils.getTraducaoTipoTemplateApps(type);

            if (this.type_product === "SMS") {
                try {
                    data = JSON.parse(resumo).text;

                    if (data.length > 50) {
                        data = data.slice(0, 50) + "...";
                    }
                } catch (error) {
                    data = resumo;
                }
            } else {
                let content = JSON.parse(resumo);

                switch (type) {
                case "M":
                case "E":
                case "":
                case "texto":
                case null:
                    data = content.text;

                    if (data.length > 50) {
                        data = data.slice(0, 50) + "...";
                    }
                    break;
                case "C":
                    data = {
                        text: content.contents.description.length > 50 ? content.contents.description.slice(0, 50) + "..." : content.contents.description,
                        image: content.contents.media.file.url
                    };
                    break;
                }
            }

            return data;
        },
        showDetails(item) {
            this.template_selected = item;
            this.show_dialog_details = true;
        },
        showDisable(item) {
            this.template_selected = item;
            this.show_dialog_disable = true;
        },
        showEnable(item) {
            this.template_selected = item;
            this.show_dialog_enable = true;
        },
        showNotify(returned) {

            let response = {
                disable: {
                    success: "Template inativado com sucesso!",
                    error: "N&atilde;o foi poss&iacute;vel desativar os itens solicitados"
                },
                copy: {
                    success: "Template criado com sucesso!",
                    error: "N&atilde;o foi poss&iacute;vel criar o template"
                },
                active: {
                    success: "Template ativado com sucesso!",
                    error: "N&atilde;o foi poss&iacute;vel ativar o template"
                }
            };

            if (returned.result) {
                this.notify_title = "Sucesso";
                this.notify_message = response[returned.type].success;
                this.notify_type = "success";
                this.filter_data = {
                    tipo: "",
                    cliente: "",
                    codigo: "",
                    nome: "",
                    resumo: "",
                    dt_criacao: "",
                    dt_alteracao: "",
                    status: this.filter_data.status
                };
            } else {
                this.notify_title = "Problema com a requisi&ccedil;&atilde;o";
                this.notify_message = response[returned.type].error;
                this.notify_type = "error";
            }
            this.show_notify = true;

        },
        redirectEdit(item) {
            if (item.CD_PRODUTO_PDES !== "WTS" && item.CD_PRODUTO_PDES !== "RCS") {
                let form = document.createElement("form");
                let input = document.createElement("input");
                input.name = "id";
                input.value = item.ID_TEMPLATE;
                form.appendChild(input);
                form.action = this.base_service.baseDomainPhp() + "/alt-template.php";
                form.method = "POST";

                document.body.appendChild(form);
                form.submit();
            } else if (item.CD_PRODUTO_PDES === "RCS") {
                window.location.href = this.base_service.baseDomainPhp() + "/Template/editRcs?id=" + item.ID_TEMPLATE;
            }
        },
        /**
         * Exibe modal para copiar template
         * @params {Object} item
         */
        showCopyTemplate(item) {
            this.template_selected = item;
            this.show_dialog_copy = true;
        },
        /**
         * Verifica se o id do template � correspondente ao template criado atraves da copia para exibir destaque na listagem
         * @vue
         * @param {Object} response
         */
        setRowClassItem(item) {
            if (this.id_template_created && item.ID_TEMPLATE === this.id_template_created) {
                setTimeout(() => {
                    this.table_options = null;
                    this.$store.commit("templatesrcs/" + types.SET_ID_TEMPLATE_CREATED, null);
                }, 5000);

                return "bg-secondary-blue-100";
            } else {
                return undefined;
            }
        },
        /**
         * Retorna os dados de fallback para montar o template
         * @vue
         * @param {Object} item;
         * @param {String} type;
         */
        getFallbackTemplate(item, type) {

            let fallback_template = "";

            if (item.fallback?.length) {
                switch (type) {
                case "DS_TEMPLATE":
                    fallback_template = item.fallback[0].DS_TEMPLATE_TAES;
                    break;
                case "TP_TEMPLATE":
                    fallback_template = item.fallback[0].TP_TEMPLATE_TAES;
                    break;
                default:
                    fallback_template = item.fallback[0];
                    break;
                }
            }

            return fallback_template;
        },
        /**
         * Verifica se o objeto enviado tem fallback valido
         * @vue
         * @param {Object} item;
         */
        verifyFallback(item) {
            if (typeof item.fallback[0] != "undefined") {
                return true;
            } else {
                return false;
            }
        },
        /**
         * Redireciona para tela de editar template no passo 3 para criar o fallback
         * @vue
         * @param {Object} item;
         */
        editFalback(item) {
            window.location.href = this.base_service.baseDomainPhp() + "/Template/editRcs?id=" + item.ID_TEMPLATE + "&fallback=true";
        },
        /**
         * Retorna tipo de mensagem a ser apresentado na coluna Modelo
         * @param {item}
         * @returns {string}
         * @vue
         */
        messageModel(item) {
            return item.TP_TEMPLATE != null ? item.TP_TEMPLATE : item.TP_MENSAGEM;
        },
        /**
         * Pega a quantidade de imagens/paginas presentes em um RCS do tipo Caroseul
         * @param {item}
         * @returns {number}
         * @vue
         */
        countCarouselPage(item) {
            let params = "";
            // Verifica se o envio � do tipo carousel
            if (item.TP_MENSAGEM === "R" || item.TP_TEMPLATE === "R") {
                // Se exitir valor em DS_TEMPLATE, utiliza essa coluna para buscar os dados do carousel (envio utilizando um template criado)
                if (item.DS_TEMPLATE) {
                    params = JSON.parse(item.DS_TEMPLATE);
                // Se n�o, pega as informa��es na coluna DS_PARAMETROS (envios do tipo carousel criado na hora (envio r�pido))
                } else {
                    params = JSON.parse(item.DS_PARAMETROS);
                }
                if (params.contents) {
                    // Retorna o numero de p�ginas do carousel.
                    return params.contents.length;
                }
            }
        }
    },
    template: (
        /*html*/
        ` 
        <v-container>
            <v-card elevation="0">
                <v-card-text>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12">
                            <page-header
                                :tutorial="true"
                                title="Templates de Mensagens"
                                description="Aqui voc&ecirc; encontra a listagem de templates para SMS convencional e SMS concatenado."
                                :old-page="type_product !== 'RCS' ? '/list-template.php' : undefined"
                                :name-old-page="type_product !== 'RCS' ? 'Template de mensagem' : undefined"
                                :old-page-two="type_product !== 'RCS' ? '/list-template-concatenado.php' : undefined"
                                :name-old-page-two="type_product !== 'RCS' ? 'Template concatenado' : undefined"
                                @tutorial="$refs.tour.start()"
                            >
                                <template #button>
                                    <v-row no-gutters>
                                        <v-col :data-v-step="1">
                                            <base-button
                                                v-if="type_product === 'RCS'"
                                                primary
                                                icon="mdi-plus"
                                                label="Novo template"
                                                :block="true"
                                                @click="actionButton('rcs')"
                                            />
                                            <base-dropdown-button
                                                v-else
                                                primary
                                                icon="mdi-plus"
                                                label="Novo template"
                                                :block="true"
                                                :list-options="[{ name: 'Simples', key: 'simples' }, { name: 'Concatenado', key: 'concatenado' }]"
                                                @click="actionButton"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                            </page-header>
                        </v-col>
                    </v-row>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12" class="filtro-content pb-10">
                            <form-filter-template @formSubmit="filterSubmit" />
                        </v-col>
                    </v-row>
                    <v-row class="px-0 px-md-6">
                        <v-col id="divList" cols="12">
                            <base-data-table
                                :headers="table_headers"
                                :list-items="list_template"
                                :loading="table_loading"
                                :custom-slots="custom_slots"
                                :server-side-config="server_side_config"
                                item-key="ID_TEMPLATE"
                                sort-by="DT_ULTIMA_ALTERACAO"
                                show-page-length
                                :step-tutorial-custom-columns="3"
                                :dynamic-options="table_options"
                                :item-row-class="setRowClassItem"
                            >
                                <template #CD_PRODUTO_PDES="props">
                                    <canal-icon-description :product-name="props.item.CD_PRODUTO_PDES && props.item.CD_PRODUTO_PDES.length > 0 ? props.item.CD_PRODUTO_PDES : (props.item.TP_TEMPLATE === 'C' ? 'sms-c' : 'sms')" />
                                </template>
                                <template #NM_EMPRESA="props">
                                    <span v-if="props.item.ID_EMPRESA == id_empresa || (props.item.ID_EMPRESA_PAI == id_empresa && (!props.item.ID_EMPRESA || props.item.ID_EMPRESA == ''))" class="body-2">Todos</span>
                                    <span v-else class="body-2">{{ props.item.NM_EMPRESA }}</span>
                                </template>
                                <template #TP_TEMPLATE="props">
                                    <modelo-icon-description 
                                        class="body-2 flex-nowrap" 
                                        :model-name="messageModel(props.item)"
                                        :sub-title="countCarouselPage(props.item)"
                                    ></modelo-icon-description>
                                </template>
                                <template #NM_USUARIO="props">
                                    <span class="body-2">{{ props.item.NM_USUARIO | filter_empty }}</span>
                                </template>
                                <template #DT_TEMPLATE="props">
                                    <span class="body-2">{{ props.item.DT_TEMPLATE | format_date }}</span>
                                </template>
                                <template #ALT_NM_USUARIO="props">
                                    <span class="body-2">{{ Vue.htmlEntities(props.item.ALT_NM_USUARIO) || "-" }}</span>
                                </template>
                                <template #DT_ULTIMA_ALTERACAO="props">
                                    <span v-if="props.item.DT_ULTIMA_ALTERACAO" class="body-2">{{ props.item.DT_ULTIMA_ALTERACAO | format_date }}</span>
                                    <span v-else class="body-2">-</span>
                                </template>
                                <template #DS_TEMPLATE="props">
                                    <v-row no-gutters class="py-1">
                                        <v-col cols="12">
                                            <v-card elevation="0" class="rounded-0" max-width="130px">
                                                <table-thumb
                                                    :ds-template="props.item.DS_TEMPLATE"
                                                    :ds-template-fallback="getFallbackTemplate(props.item, 'DS_TEMPLATE')"
                                                    highlight="main"
                                                    :type-template="props.item.TP_TEMPLATE"
                                                    @click="showDetails(props.item)"
                                                />
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template #fallback="props">
                                    <v-row no-gutters class="py-1">
                                        <v-col v-if="verifyFallback(props.item)" cols="12">
                                            <v-card elevation="0" class="rounded-0" max-width="130px">
                                                <table-thumb
                                                    :ds-template="props.item.DS_TEMPLATE"
                                                    :ds-template-fallback="getFallbackTemplate(props.item, 'DS_TEMPLATE')"
                                                    highlight="fallback"
                                                    :type-template="props.item.TP_TEMPLATE"
                                                    @click="showDetails(getFallbackTemplate(props.item, 'TEMPLATE'))"
                                                />
                                            </v-card>
                                        </v-col>
                                        <v-col v-else cols="12">
                                            <base-tooltip text="Cadastre um SMS alternativo para este template">
                                                <a class="text-primary-blue-500 body-2 text-decoration-none" @click="editFalback(props.item)">Cadastrar</a>
                                            </base-tooltip>
                                        </v-col>
                                    </v-row>
                                </template>
                                <template #actions="props">
                                    <v-row no-gutters class="py-2 flex-nowrap">
                                        <v-col cols="auto" class="px-1">
                                            <button-action
                                                type="edit"
                                                :disabled="props.item.CD_PRODUTO_PDES === 'WTS' || (props.item.CD_PRODUTO_PDES === 'RCS' && ['M', 'I', 'C'].indexOf(props.item.TP_TEMPLATE) === -1)"
                                                @click="redirectEdit(props.item)"
                                            />
                                        </v-col>
                                        <v-col cols="auto" class="px-1" v-if="type_product != 'SMS'">
                                            <button-action
                                                type="copy"
                                                :disabled="props.item.CD_PRODUTO_PDES === 'WTS' || (props.item.CD_PRODUTO_PDES === 'RCS' && ['M', 'I', 'C'].indexOf(props.item.TP_TEMPLATE) === -1)"
                                                @click="showCopyTemplate(props.item)"
                                            />
                                        </v-col>
                                        <v-col v-show="props.item.ST_TEMPLATE_TAES == 'A' || props.item.ST_TEMPLATE_TEES == 'A'" cols="auto" class="px-1">
                                            <button-action
                                                type="disable"
                                                @click="showDisable(props.item)"
                                            />
                                        </v-col>
                                        <v-col v-show="props.item.ST_TEMPLATE_TAES == 'I' || props.item.ST_TEMPLATE_TEES == 'I'" cols="auto" class="px-1">
                                            <button-action
                                                type="enable"
                                                @click="showEnable(props.item)"
                                            />
                                        </v-col>
                                    </v-row>
                                </template>
                            </base-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <pg-tour ref="tour" :steps="steps" />
                <base-notify
                    v-model="show_notify"
                    :title="notify_title"
                    :message="notify_message"
                    :type="notify_type"
                />
                <dialog-details
                    v-model="show_dialog_details"
                    :item="template_selected"
                    @disable="showDisable"
                    @edit="redirectEdit"
                />
                <dialog-disable v-model="show_dialog_disable" :item="template_selected" @disabled="showNotify" />
                <dialog-enable v-model="show_dialog_enable" :item="template_selected" @disabled="showNotify" />
                <dialog-template-copy v-if="type_product != 'SMS'" v-model="show_dialog_copy" :item="template_selected" />
            </v-card>
        </v-container>
        `
    )
};