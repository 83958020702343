/**
 * Este componente deve ser utilizado para abrir outros componentes em uma nova janela do navegador
 * Este componente possui um slot default
 *
 * @displayName NewWindow
 * @component
 * @category Organismos
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 */
export default {
    name: "new-window",
    props: {
        /**
         * Indica se o componente deve ser exibido ou nao
         */
        open: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    model: {
        prop: "open",
        event: "close"
    },
    data() {
        return {
            window_ref: null
        };
    },
    watch: {
        open(newValue) {
            if (newValue) {
                this.openWindow();
            } else {
                this.closeWindow();
            }
        }
    },
    methods: {
        /**
         * Carrega os dados do componente dentro de uma nova janela
         * @vue
         */
        openWindow() {
            this.window_ref = window.open("", "", "width=600,height=400,left=200,top=200");
            this.window_ref.document.body.appendChild(this.$el);
            this.copyStyles(window.document, this.window_ref.document);
            this.window_ref.addEventListener("beforeunload", this.closeWindow);
        },
        /**
         * Fecha a janela criada anteriormente e limpa os dados
         * @vue
         */
        closeWindow() {
            if (this.window_ref) {
                this.window_ref.close();
                this.window_ref = null;
                this.$emit("close");
            }
        },
        /**
         * Copia os arquivos de style para dentro da nova janela para que o componente seja renderizado de forma correta
         * @vue
         */
        copyStyles(source_doc, target_doc) {
            const styleSheets = Array.from(source_doc.styleSheets).filter(
                (styleSheet) => !styleSheet.href || styleSheet.href.startsWith(window.location.origin)
            );

            for (let styleSheet of styleSheets) {
                if (styleSheet.cssRules) {
                    const newStyleEl = source_doc.createElement("style");

                    Array.from(styleSheet.cssRules).forEach(cssRule => {
                        newStyleEl.appendChild(source_doc.createTextNode(cssRule.cssText));
                    });

                    target_doc.head.appendChild(newStyleEl);
                } else if (styleSheet.href) {
                    const newLinkEl = source_doc.createElement("link");

                    newLinkEl.rel = "stylesheet";
                    newLinkEl.href = styleSheet.href;
                    target_doc.head.appendChild(newLinkEl);
                }
            }
        }
    },
    mounted() {
        if (this.open) {
            this.openWindow();
        }
    },
    beforeDestroy() {
        if (this.window_ref) {
            this.closeWindow();
        }
    },
    template: (
        /*html*/
        `
            <div v-if="open">
                <slot></slot>
            </div>
        `
    )
};