import BaseBoxForm from "../../../components/atomos/BaseBoxForm";
import FormLogin from "./FormLogin";
import FormRecoveryPassword from "./FormRecoveryPassword";

/**
 * Componente gerenciador do box de login e recupera��o de senha
 * 
 * @component
 * @category Page / Auth
 * @requires {@link base-box-form.html|BaseBoxForm}
 * @requires {@link form-login.html|FormLogin}
 * @requires {@link form-recovery-password.html|FormRecoveryPassword}
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 */
export default {
    name: "box-form-manager",
    components: {
        BaseBoxForm,
        FormLogin,
        FormRecoveryPassword
    },
    data: function () {
        return {
            form_recovery_pass: false,
            error_login: "",
            error_lost_pass: "",
            success_lost_pass: "",
            progress_val: 0,
            indeterminate: false,
            show_progress: false,
            disable_button: false
        };
    },
    methods: {
        ...window.Vuex.mapActions("auth", ["ActionDoLogin", "ActionDoLoginLaravel", "ActionDoRecoveryPassword", "ActionDoTokenSanctum"]),
        /**
         * Define o formulario de recuperacao de senha como visivel
         * @vue
         */
        recoveryPassLink() {
            this.form_recovery_pass = true;
        },
        /**
         * Define o formulario de login como visivel
         * @vue
         */
        loginLink() {
            this.form_recovery_pass = false;
            this.error_lost_pass = "";
            this.success_lost_pass = "";
        },
        /**
         * Monta a resposta de erro para o formulario de login
         * @vue
         */
        makeResponseLoginMessage(cod_erro) {
            switch (cod_erro) {
            case 1:
                this.error_login = "Informe o seu e-mail e senha e clique em 'Entrar'";
                break;
            case 2:
                this.error_login = "Usu&aacute;rio e/ou senha inv&aacute;lidos.";
                break;
            case 3:
                this.error_login = "Usu&aacute;rio e/ou senha inv&aacute;lidos.";
                break;
            case 4:
                this.error_login = "Usu&aacute;rio sem permiss&atilde;o para acesso ao painel!";
                break;
            case 5:
                this.error_login = "Senha expirada, por favor solicite uma redefini&ccedil;&atilde;o de senha clicando em 'Esqueceu sua senha?'.";
                break;
            case 6:
                this.error_login = "IP inv&aacute;lido! Contate seu administrador para mais detalhes.";
                break;
            case 8:
                this.error_login = "Periodo de teste finalizado! Entre em contado com nosso suporte!";
                break;
            case 9:
                this.error_login = "Painel em manuten&ccedil;&atilde;o.";
                break;
            case 10:
                this.error_login = "Token de defini&ccedil;&atilde;o de senha expirado!";
                break;
            case 11:
                this.error_login = "Selecione uma empresa!";
                break;
            case 12:
                this.error_login = "Este &eacute; um ambiente de homologa&ccedil;&atilde;o, somente usu&aacute;rios beta s&atilde;o permitidos! Deseja se tornar beta? Entre em contato com a equipe de melhoria dos pain&eacute;is.";
                break;
            default:
                this.error_login = "";
                break;
            }
        },
        /**
         * Faz a inicializacao da barra de progresso ao realizar a requisicaoo no formulario
         * @vue
         */
        initProgressBar() {
            this.disable_button = true;
            this.show_progress = true;
            this.indeterminate = true;
            this.progress_val = 0;

            setTimeout(() => {
                this.progress_val = 10;
                this.indeterminate = false;
                setTimeout(() => {
                    this.progress_val = 75;
                    this.indeterminate = false;
                }, 500);
            }, 1000);
        },
        /**
         * Finaliza a barra de progresso ao finalizar uma requisicao
         * @vue
         */
        finishProgressBar() {
            this.progress_val = 100;

            setTimeout(() => {
                this.disable_button = false;
                this.show_progress = false;
            }, 100);
        },
        /**
         * Logica do login, faz a requisicao para as APIs necessarias para validar as credenciais
         * @vue
         */
        loginAction(json_data) {
            this.initProgressBar();
            this.error_login = "";

            let form_data = new FormData();
            form_data.append("username", json_data.email);
            form_data.append("password", json_data.senha);

            try {
                this.ActionDoTokenSanctum().then(() => {
                    this.ActionDoLogin(form_data).then(response => {
                        if (typeof response.codigo_erro !== "undefined") {
                            this.makeResponseLoginMessage(response.codigo_erro);
                            this.finishProgressBar();
                        } else {
                            this.ActionDoLoginLaravel({
                                user_name: json_data.email,
                                password: json_data.senha,
                                token_recaptcha: json_data.recaptcha
                            }).then(response => {
                                if (response.auth && !response.error) {
                                    this.finishProgressBar();
                                    /**
                                     * Evendo que indica que o login foi realizado com sucesso
                                     * 
                                     * @event loginIsOk
                                     * @vue
                                     */
                                    this.$emit("loginIsOk");
                                } else {
                                    console.error(response.error);
                                }
                            });
                        }
                    });
                });
            } catch (error) {
                console.error(error);
            }
        },
        /**
         * Logica de recuperacao de senha, faz a requisicao para a api laravel enviar o email de recuperacao de senha
         * @vue
         */
        recoveryPassAction(email) {
            this.initProgressBar();
            this.error_lost_pass = "";
            this.success_lost_pass = "";

            try {
                this.ActionDoRecoveryPassword(email).then(response => {
                    if (response.status === 200 && response.data.errors == "") {
                        this.success_lost_pass += "<span class=\"success--text\">Um link para alterar sua senha foi enviado para o e-mail <strong>" + email + "</strong>.</span>";
                        this.success_lost_pass += "<p>O e-mail deve chegar em poucos minutos. Se o e-mail n&atilde;o chegar, verifique na caixa de Spam.</p>";
                        this.success_lost_pass += "<p>Voc&ecirc; pode clicar no link que ir&aacute; receber e seguir para a p&aacute;gina onde poder&aacute; configurar sua nova senha.</p>";
                    } else {
                        this.error_lost_pass = response.data.message;
                    }

                    this.finishProgressBar();
                });
            } catch (error) {
                console.error(error);
            }
        }
    },
    mounted() {
        let email = this.$route.query.email;

        if (typeof email !== "undefined" && email !== "") {
            this.form_recovery_pass = email;
        }
    },
    template: (
        /*html*/
        `
            <div class="fill-height">
                <v-img src="/images/capa-login-connect-mais3.svg" style="height: 100%" position="right top">
                    <v-row justify="center" align="center" class="fill-height">
                        <v-col cols="10" md="5" lg="7" xl="5">
                            <base-box-form>
                                <form-login v-if="!form_recovery_pass"
                                    ref="form_login"
                                    class="my-5"
                                    @submitLoginButton="loginAction"
                                    @lostPasswordLink="recoveryPassLink"
                                    :disable_button="disable_button"
                                    :error_message="error_login"
                                ></form-login>
                                <form-recovery-password v-if="form_recovery_pass"
                                    class="my-5"
                                    @recoveryPassButton="recoveryPassAction"
                                    @backLogin="loginLink"
                                    :error_message="error_lost_pass"
                                    :success_message="success_lost_pass"
                                ></form-recovery-password>

                                <v-progress-linear
                                    v-model="progress_val"
                                    :active="show_progress"
                                    :indeterminate="indeterminate"
                                    absolute
                                    bottom
                                    color="dark-primary"
                                    height="7"
                                ></v-progress-linear>
                            </base-box-form>
                        </v-col>
                    </v-row>
                </v-img>
            </div>
        `
    )
};