import InfoNewReportGenerator from "../../reports/components/InfoNewReportGenerator";

export default {
    components: {
        InfoNewReportGenerator
    },
    data() {
        return {
            imageUrl: "/images/reports/LadyWithIpad.svg"
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center" class="pa-15">
                <v-col cols="6" sm="12" md="6" lg="5" xl="4">
                    <info-new-report-generator :imageUrl="imageUrl"></info-new-report-generator>
                </v-col>
            </v-row>
        `
    )
};