import BaseDialog from "../../../../components/moleculas/BaseDialog";
import BaseInformation from "../../../../components/atomos/BaseInformation";

/**
 * Componente de dialog para desativar um template
 * <iframe src="http://localhost:8080/v2/documentacao/template/dialog-disable" style="width: 100%; height: 315px;"></iframe>
 *
 * @requires {@link BaseDialog}
 * @requires {@link BaseInformation}
 *
 * @displayName DialogDelete
 * @category Page / Blacklist
 * @subcategory dialogs
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseDialog,
        BaseInformation
    },
    props: {
        /**
         * Valor do atributo v-model do componente, indica se o dialog deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Dados do itens a serem exibidos
         */
        itens: {
            type: Object,
            required: false,
            default: function _default() {
                return {};
            }
        }
    },
    data() {
        return {
            title: "",
            dialog: this.value,
            action_button: {
                label: "Excluir",
                variant: "dark-danger",
                loading: false
            },
            deleteds: []
        };
    },
    watch: {
        itens(ev) {
            this.action_button.loading = false;

            ev.filter((item, index) => {
                if (this.deleteds.includes(item)) {
                    ev.splice(index, 1);
                }
            });

            this.title = `Tem certeza que deseja excluir <b>${ev.length}</b> registros(s) da Blacklist?`;
        },
        value(ev) {
            this.dialog = ev;
        },
        dialog() {
            if (!this.dialog) {
                /**
                 * Emite um evento chamado close informando que o modal foi fechado
                 *
                 * @event close
                 * @vue
                 */
                this.$emit("close");
            }
        }
    },
    methods: {
        ...window.Vuex.mapActions("blacklist", ["actionDoDeleteAll"]),
        async deleteBlacklist() {
            this.action_button.loading = true;

            this.itens.map((item) => {
                this.deleteds.push(item);
            });

            await this.actionDoDeleteAll({
                ids: this.itens
            })
                .then((data) => {
                    this.dialog = false;
                    this.action_button.loading = false;

                    if (data.status_code == 200) {
                        this.$emit("deleted", true);
                    }
                })
                .catch(() => {
                    this.dialog = false;
                    this.action_button.loading = false;
                });
        }
    },
    template:
        /*html*/
        `
            <base-dialog 
                v-model="dialog"
                title="Excluir"
                use-icon-close
                cancel
                max-width="520px"
                :action-button="action_button"
                @clickDialog="deleteBlacklist"
            >
                <v-card elevation="0">
                    <v-row justify="center">
                        <v-col>
                            <p class="font-size-12" v-html="title"></p>
                            <base-information
                                description="Voc&ecirc; n&atilde;o poder&aacute; desfazer essa a&ccedil;&atilde;o."
                                icon="mdi-alert-circle"
                                border-color="border-warning"
                                icon-color="warning"
                            ></base-information>
                        </v-col>
                    </v-row>
                </v-card>
            </base-dialog>
        `
};
