import {TemplateService} from "../../../../js/services/TemplateService";

/**
 * Componente de Base Image
 * 
 * @displayName BaseImagem
 * @component
 * @category Atomos
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @vue
 */
const BaseImagem = {
    name: "base-imagem",
    // vuetify: new Vuetify(),
    props: {
        //url da imagem
        src: {
            type: String,
            required: true
        },
        openModal: {
            type: Boolean,
            required: false,
            default: false
        },

        editable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * teste
         * @values 2
         */
        size: {
            type: String,
            default: "normal"
        }
    },
    computed: {
        is_source_empty: function() {
            return isEmpty(this.src);
        },
        is_source_variable: function() {
            return this.template_service.hasParams(this.src) && !this.editable;
        }
    },
    data: function() {
        return {
            image_error: false,
            template_service: new TemplateService()
        };
    },
    methods: {
        /**
         * @public
         */
        error: function() {
            this.image_error = true;
            this.$emit("error");
        },
        load: function() {
            this.image_error = false;
            this.$emit("error");
        }
    },
    template:
        /*html*/
        `
            <div :class="{'pointer-event-off': !openModal}" class="p-0 height-auto">
                <v-row class="justify-content-center pointer">
                    <v-col cols="12" class="min-h-225" :class="[image_error ? 'bg-background' : 'bg-extradark-grey']" data-id="image-div" >
                        <v-row class="justify-content-center h-100 m-0" >
                            <v-col  
                                cols="12"
                                data-id="image-background"
                                :style="{backgroundImage: 'url('+src+')'}" style="background-size: cover; background-position-x: center;"
                                v-if="!image_error && !is_source_empty && !is_source_variable" 
                                class="h-100">
                            </v-col>  
                           
                            <div 
                                v-if="image_error && !is_source_empty && !is_source_variable" 
                                class="col-12 text-center d-flex flex-column justify-content-center align-items-center"
                            >
                                <span class="far fa-4x fa-frown text-danger"></span>
                                <div class="mt-2 w-100"></div>
                                <b>Imagem n&atilde;o encontrada</b> 
                                <p>URL inv&aacute;lida ou extens&atilde;o n&atilde;o permitida</p> 
                            </div>                       
                        </v-row>
                    </v-col>
                </v-row>
                <img @error="error($event)" v-if="!is_source_variable" 
                @load="load($event)" 
                class="d-none" :src="src"/>                
            </div>  
        `
};

export default BaseImagem;

// Vue.component('base-imagem',BaseImagem);
