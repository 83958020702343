import BoxFormManager from "../../auth/components/BoxFormManager";

export default {
    components: { BoxFormManager },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6">
                    <box-form-manager></box-form-manager>
                </v-col>
            </v-row>
        `
    )
};