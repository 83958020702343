import BaseSpanLimited from "./BaseSpanLimited";

/**
 * Componente para ser utilizado em filtros
 * 
 * @requires {@link BaseSpanLimited}
 * 
 * @displayName ActiveFilter
 * @component
 * @category Atomos
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseSpanLimited
    },
    props: {
        /**
         * Titulo ou Nome do filtro
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Valor do filtro
         */
        value: {
            type: String,
            required: true
        },
        /**
         * Somente leitura (nao pode ser removido)
         */
        readonly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            hover: false
        };
    },
    methods: {
        /**
         * Acao de click do componente
         * @vue
         */
        click() {
            if (!this.readonly) {
                /**
                 * Evento de close disparado ao clicar no componente
                 * @event close
                 * @vue
                 */
                this.$emit("close");
            }
        }
    },
    template: (
        /*html*/
        `
            <v-hover v-model="hover">
                <v-card elevation="0" :class="(hover ? 'bg-gray-100' : 'bg-gray-50') + ' px-2 py-1' + (readonly ? ' cursor-default' : '')" @click="click">
                    <v-row
                        no-gutters
                        class="fill-height gap-1 flex-nowrap"
                        justify="space-between"
                        align="center"
                    >
                        <v-col cols="auto">
                            <span class="text-gray-800 caption bold" v-html="title" />
                        </v-col>
                        <v-col cols="auto">
                            <base-span-limited :text="value" :limit="21" classes="text-gray-800 caption" />
                        </v-col>
                        <v-col v-if="!readonly" cols="auto">
                            <v-icon x-small>
                                mdi-close-thick
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-card>
            </v-hover>
        `
    )
};