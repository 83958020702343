export default {
    template: (
        /*html*/
        `
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 0.75C3.0998 0.75 0.75 3.0998 0.75 6C0.75 8.9002 3.0998 11.25 6 11.25C8.9002 11.25 11.25 8.9002 11.25 6C11.25 3.0998 8.9002 0.75 6 0.75ZM7.20877 8.16139L5.34163 6.80444C5.27601 6.75575 5.2379 6.67954 5.2379 6.59909V3.03629C5.2379 2.89657 5.35222 2.78226 5.49194 2.78226H6.50806C6.64778 2.78226 6.7621 2.89657 6.7621 3.03629V5.95131L8.10635 6.92933C8.22067 7.0119 8.24395 7.17067 8.16139 7.28498L7.56442 8.10635C7.48185 8.21855 7.32308 8.24395 7.20877 8.16139Z" fill="currentColor"/>
        </svg>

        `
    )

};