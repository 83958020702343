import { BasePillClass } from "../../../classes/BasePillClass";
import { DraggableItemClass } from "../../../classes/DraggableItemClass";
import PreviewCard from "../../../components/atomos/PreviewCard";

export default {
    components: {
        PreviewCard
    },
    data() {
        return {
            image: "https://s4.static.brasilescola.uol.com.br/img/2019/09/panda.jpg",
            title: "Title",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin elementum enim sapien, ut imperdiet velit tincidunt non.",
            suggestionButtons: [
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste", titleIcon: "mdi-earth" })}),
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste 2", titleIcon: "mdi-map-marker" })}),
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste 3", titleIcon: "mdi-phone" })}),
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste 4", titleIcon: "mdi-map" })}),
            ],
            suggestionResponses: [
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste 5"})}),
                new DraggableItemClass({ text: null, type: "pill", pill: new BasePillClass({ title: "teste 6" }) })
            ],
            orientation: "VERTICAL",
            alignment: "LEFT",
            imageHeight: "TALL",
        };
    },
    template: (
        /*html*/
        `
            <v-container>
                <v-row justify="center" class="mt-10">
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <v-card>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field label="URL da imagem" v-model="image"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="Titulo" v-model="title"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-text-field label="Descricao" v-model="description"></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select label="Orientacao" v-model="orientation" :items="[{ text: 'Vertical', value: 'VERTICAL' }, { text: 'Horizontal', value: 'HORIZONTAL' }]"></v-select>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select label="Alinhamento" v-model="alignment" :items="[{ text: 'Esquerda', value: 'LEFT' }, { text: 'Direita', value: 'RIGHT' }]"></v-select>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select label="Tamanho da imagem" v-model="imageHeight" :items="[{ text: 'Grande', value: 'TALL' }, { text: 'Medio', value: 'MEDIUM' }, { text: 'Pequeno', value: 'SHORT' }]"></v-select>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mt-10">
                            <v-col cols="12" md="3">
                                <preview-card
                                    :image="image"
                                    :title="title"
                                    :description="description"
                                    :suggestion-buttons="suggestionButtons"
                                    :suggestion-responses="suggestionResponses"
                                    :orientation="orientation"
                                    :alignment="alignment"
                                    :image-height="imageHeight"
                                ></preview-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        `
    )
};