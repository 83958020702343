import BaseButton from "../../../components/atomos/BaseButton?v=2";
import BaseAutocomplete from "../../../components/atomos/BaseAutocomplete";

/**
 * Componente de botao de selecao do produto
 * 
 * @displayName SelectEmpresaForm
 * @component
 * @vue
 * @category Page / Auth
 * @requires {@link BaseButton}
 * @requires {@link BaseAutocomplete}
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 */
export default {
    name: "select-empresa-form",
    components: {
        BaseButton,
        BaseAutocomplete
    },
    props: {
        /**
         * Array de empresas para listagem no select do componente
         */
        lista_empresas: {
            type: Array,
            required: true
        },
        /**
         * Indica se o select tem o status de loading
         */
        select_loading: {
            type: Boolean | String,
            required: false,
            default: false
        },
        /**
         * Indica se o formul�rio deve estar desabilitado
         */
        disable_form: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            empresa_selected: "",
            empresa_rules: [
                v => !!v || "Selecione uma empresa"
            ]
        };
    },
    methods: {
        /**
         * Funcao vinculada ao boto de entrar
         * @vue
         */
        buttonFunctionEntrar: function () {
            if (this.$refs.form_empresa.validate()) {
                /**
                 * Evento click do botao
                 * @event click-button-entrar
                 * @vue 
                 */
                this.$emit("click-button-entrar", this.empresa_selected);
            }
        },
        /**
         * Funcao vinculada ao botao de voltar
         * @param {*} instance 
         * @vue 
         */
        buttonFunctionVoltar: function (instance) {
            /**
             * Evento click do botao
             * @event click-button-voltar
             * @vue 
             */
            this.$emit("click-button-voltar", instance);
        }
    },
    template: (
        /*html*/
        `
            <v-form ref="form_empresa">
                <v-row>   
                    <v-col cols="12 pt-0">
                        <h6 class="mb-0"> Encontramos mais de uma empresa vinculada a este canal, escolha uma para come&#231;ar:</h6>                      
                    </v-col>
                    <v-col cols="12" class="mt-2">
                        <base-autocomplete 
                            :items="lista_empresas"
                            label="Selecione uma empresa"
                            :loading="select_loading" 
                            :disable="disable_form"
                            :rules="empresa_rules"
                            no-data="Nenhuma empresa encontrada"
                            v-model="empresa_selected"
                            @enterPressed="buttonFunctionEntrar"
                        ></base-autocomplete>
                    </v-col>
                    <v-col cols="12" class="mt-5">
                        <base-button
                            primary
                            label="Entrar"
                            icon="fa-check"
                            :disabled="disable_form"
                            @click="buttonFunctionEntrar()"
                        ></base-button>
                    </v-col>
                    <v-col cols="12" class="mt-10">
                        <v-img src="/images/IMG-Login-Canal.svg" class="mx-auto" ></v-img>                        
                    </v-col>         
                </v-row>
            </v-form>
        `
    )
};