import CardsConfiguracao from "../../../components/organismos/CardsConfiguracao";

export default {
    components: { CardsConfiguracao },
    data() {
        return {
            title: "Configure o modelo escolhido",
            list_buttons: [
                {
                    icon: "$simplesIcon",
                    title: "Simples",
                    type: "simples",
                    description: "Crie uma mensagem simples podendo ter campos personalizados.",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "$respostasRapidasIcon",
                    title: "Respostas r&aacute;pidas",
                    type: "respostas_rapidas",
                    description: "Crie uma mensagem com respostas r&aacute;pidas e campos personalizados.",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "$botoesIcon",
                    title: "Bot&otilde;es",
                    type: "botoes",
                    description: "Crie uma mensagem com bot&otilde;es de a&ccedil;&atilde;o e campos personalizados.",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false
                },
                {
                    icon: "$completoIcon",
                    title: "Completo",
                    type: "comple",
                    description: "Crie uma mensagem utilizando todos os recursos.",
                    height: "auto",
                    hover: false,
                    selected: false,
                    horizontal: false,
                    disable: false}
            ]

        };
    },
    methods: {
        selectOption(value) {
            console.log(value);
        }
    },
    template: (
        /*html*/
        `
        <v-container>
            <v-row justify="center">
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12" md="3">
                            <cards-configuracao 
                            :title="title"
                            :list_buttons="list_buttons"
                            @selectOption="selectOption">
                            </cards-configuracao>     
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>    
        `
    )
};