import { BaseService } from "../services/BaseService";

const base_service = new BaseService();

export const php = axios.create({
    baseURL: base_service.baseDomainPhp(),
    headers: {
        "Content-Type": "application/json"
    }
});

export const api = axios.create({
    baseURL: base_service.baseDomain(),
    withCredentials: true,
    headers: {
        "Accept": "application/json, text/plain, */*",
        "X-Requested-With": "XMLHttpRequest"
    }
});

export default {
    api,
    php
};