import BaseButton from "../../../components/atomos/BaseButton?v=2";

/**
 * Componente para notificacao de novo gerador de relatorios
 * <iframe src="http://localhost:8080/v2/documentacao/reports/info-new-report-generator-usage" style="width: 100%; height: 426px;"></iframe>
 * 
 * @displayName InfoNewReportGenerator
 * @category Page / Relatorios
 * @author Igor Oliveira <igor.oliveira@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    name: "info-new-report-generator",
    components: {
        BaseButton
    },
    props: {
        /**
         * Propriedade de link para imagem
         */
        imageUrl: {
            default: "./vue-app/public/images/reports/LadyWithIpad.svg",
            required: true,
            type: String
        }
    },
    methods: {
        /**
         * Funcao vinculada ao botao
         * @vue
         */
        reportGenerator() {
            /**
            * Evento de click do botao
            * @property {string} type 
            * @event click
            * @vue
            */
            this.$emit("click", this.type);
        }
    },
    template: (
        /*html*/
        `
        <v-card class="rounded-lg border border-dark-primary bg-extra-light-primary pa-5" elevation="0">
            <v-row no-gutters>
                <v-col cols="4"> 
                    <v-img 
                        contain 
                        class="justify-center ml-3"
                        :src="imageUrl"
                        height="175"
                        width="146"
                    ></v-img>
                </v-col>
                <v-col cols="8">
                    <v-card-text>
                        <p class="font-size-15">
                            Voc&ecirc; j&aacute; conhece o novo gerador de relat&oacute;rios?
                        </p>
                        <p class="font-size-12 text-dark-grey mb-3">
                            Confira a nova ferramenta, extraia esse mesmo relat&oacute;rio com facilidade e rapidez.
                        </p>
                        <p class="font-size-12 text-dark-grey">
                            Al&eacute;m disso voc&ecirc; pode automatizar seus relat&oacute;rios e escolher receber por e-mail. Experimente!
                        </p>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row no-gutters justify="center" class="mt-5">
                <v-col cols="auto" class="d-flex justify-content-center"> 
                    <base-button
                        primary
                        :block="false"
                        width="auto"
                        icon="mdi-arrow-right"
                        label="Ver Gerador de Relatorios"
                        @click="reportGenerator"
                    ></base-button>
                </v-col>
            </v-row>
        </v-card>  
        `
    )
};