import BaseButton from "../../../../components/atomos/BaseButton?v=2";
import AccordionDefault from "../../../../components/moleculas/AccordionDefault?v=2";
import CardLargeChoice from "../../../../components/moleculas/CardLargeChoice";
import PageHeader from "../../../../components/moleculas/PageHeader?v=2";
import AddMessageTemplate from "../AddMessageTemplate?v=2";
import FieldsTemplate from "../FieldsTemplate?v=2";
import RespostasRapidas from "../RespostasRapidas?v=2";
import SuggestionButtons from "../SuggestionButtons";
import BaseTooltip from "../../../../components/atomos/BaseTooltip?v=2";
import PgTour from "../../../../components/organismos/PgTour?v=2";
import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";
import BaseNotify from "../../../../components/atomos/BaseNotify?v=2";
import FormAddImage from "../forms/FormAddImage";
import FormAddCustomImage from "../forms/FormAddCustomImage";
import ConfigPreview from "../ConfigPreview";
import { ItemAccordionClass } from "../../../../classes/ItemAccordionClass?v=2";
import { DraggableItemClass } from "../../../../classes/DraggableItemClass?v=2";
import { BasePillClass } from "../../../../classes/BasePillClass";
import { CardRcsClass } from "../../../../classes/CardRcsClass";

/**
 * Componente para realizar a configuracao de um template de mensagem simples
 *
 * @requires {@link BaseButton}
 * @requires {@link AccordionDefault}
 * @requires {@link CardLargeChoice}
 * @requires {@link PageHeader}
 * @requires {@link AddMessageTemplate}
 * @requires {@link FieldsTemplate}
 * @requires {@link RespostasRapidas}
 * @requires {@link SuggestionButtons}
 * @requires {@link BaseTooltip}
 * @requires {@link PgTour}
 * @requires {@link BaseDialog}
 * @requires {@link BaseNotify}
 * @requires {@link FormAddImage}
 * @requires {@link FormAddCustomImage}
 * @requires {@link ConfigPreview}
 * @requires {@link ItemAccordionClass}
 * @requires {@link DraggableItemClass}
 *
 * @displayName DialogConfigTemplateMessage
 * @category Page / Template RCS
 * @subcategory dialogs
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseButton,
        AccordionDefault,
        CardLargeChoice,
        PageHeader,
        AddMessageTemplate,
        FieldsTemplate,
        RespostasRapidas,
        SuggestionButtons,
        BaseTooltip,
        PgTour,
        BaseDialog,
        BaseNotify,
        FormAddImage,
        FormAddCustomImage,
        ConfigPreview
    },
    props: {
        /**
         * Conteudo a ser exibido
         */
        configData: {
            type: Object,
            required: false,
            default: null
        },
        /**
         * Lista de botoes de opcoes
         */
        listButtons: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Conteudo passado no v-model do componente
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            show: this.value,
            buttons_type: _.cloneDeep(this.listButtons),
            accordion_active_item: null,
            // Conteudo dos accordions
            title_input: null,
            message_input: null,
            show_title: false,
            fields_custom: [],
            list_chip: [],
            list_buttons: [],
            // Controle do conteudo dentro do preview
            list_fast_response: [],
            list_suggestions: [],
            image_variable: false,
            section_selected: null,
            // Outras infos
            show_confirm_close: false,
            show_notify: false,
            notify_title: null,
            notify_message: null,
            removed_pill: null,
            position_removed: null,
            notify_button_label: null,
            restore_type: null,
            config_preview_content: {
                list_title: [],
                list_messages: [],
                list_suggestions: [],
                image: null
            },
            type_selected_changed: false,
            isFallback: true,
        };
    },
    computed: {
        ...window.Vuex.mapState("templatesrcs", {
            step_one_content: (state) => state.step_one_content
        }),
        stepsTour() {
            let steps = [
                {
                    target: "[data-v-step='9']",
                    title: "Alterne entre modalidades",
                    content: "<p>Cada modalidade possui suas particularidades e funcionalidades, voc&ecirc; pode alternar entre elas a qualquer momento.</p><p>Pode ficar tranquilo, tudo o que voc&ecirc; j&aacute; criou vai ficar guardado at&eacute; que voc&ecirc; finalize a configura&ccedil;&atilde;o.</p>",
                    date: "2022-10-07 00:00:00"
                },
                {
                    target: "[data-v-step='10']",
                    title: "Pr&eacute; visualiza&ccedil;&atilde;o",
                    content: "<p>Aqui voc&ecirc; pode visualizar uma simula&ccedil;&atilde;o de como a mensagem est&aacute; ficando.</p><p>Clique sobre os blocos para adicionar ou editar informa&ccedil;&otilde;es.</p>"
                }
            ];

            if (this.step_one_content?.modelo?.value === "card") {
                steps.push({
                    target: "[data-v-step='configBarTemplate']",
                    title: "Barra de configura&ccedil;&otilde;es",
                    content: "<p>Escolha a posi&ccedil;&atilde;o, alinhamento e propor&ccedil;&atilde;o da imagem do seu card. Personalize como quiser!</p>",
                });
            }

            steps.push(...[
                {
                    target: "[data-v-step='contentBlockConfigTemplate']",
                    title: "Blocos de conte&uacute;do",
                    content: "<p>Clique no bloco desejado e utilize as abas de recursos para preencher o conte&uacute;do.</p>",
                },
                {
                    target: "[data-v-step='13']",
                    title: "Abas de recursos",
                    content: "<p>Os recursos dispon&iacute;veis para configura&ccedil;&atilde;o dentro do modelo escolhido est&atilde;o apresentados aqui. </p><p>Ao clicar em um bloco, ser&atilde;o exibidos os recursos dispon&iacute;veis para o bloco selecionado.</p>",
                }
            ]);

            if (this.step_one_content?.modelo?.value !== "image" && this.typeSelected.type !== "simples") {
                steps.push({
                    target: "[data-v-step='closeContentBlockConfig']",
                    title: "Remover blocos",
                    content: "<p>Voc&ecirc; pode remover os blocos que n&atilde;o deseja configurar... Mas se caso mudar de ideia, voc&ecirc; poder&aacute; adicion&aacute;-lo novamente.</p>",
                });
            }

            return steps;
        },
        listAccordion() {
            let list = [];

            switch (this.step_one_content?.modelo?.value) {
            case "text":
                list = [];
                if (!this.section_selected || this.section_selected === "text") {
                    list.push(...[
                        new ItemAccordionClass({
                            title: "Escreva sua mensagem",
                            icon: "$iconeTextoIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: false,
                            disabled: false,
                            complete: false,
                            slot_name: "messageInput",
                            tooltip: "Escreva uma mensagem ou altere o texto j&aacute; criado"
                        }),
                        new ItemAccordionClass({
                            title: "Adicione campos personalizados",
                            icon: "$camposPersonalizadosIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: false,
                            disabled: false,
                            complete: false,
                            slot_name: "custom_fields",
                            tooltip: "Enrique&ccedil;a a mensagem adicionando campos personalizados"
                        })
                    ]);
                }

                if ((this.typeSelected.type === "respostas_rapidas" || this.typeSelected.type === "completo") && (!this.section_selected || this.section_selected === "suggestions")) {
                    list.push(
                        new ItemAccordionClass({
                            title: "Respostas r&aacute;pidas",
                            icon: "$respostasRapidasIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: false,
                            disabled: false,
                            complete: false,
                            slot_name: "respostas_rapidas",
                            tooltip: "Crie respostas r&aacute;pidas e estimule a intera&ccedil;&atilde;o dos seus contatos"
                        })
                    );
                }

                if ((this.typeSelected.type === "botoes" || this.typeSelected.type === "completo") && (!this.section_selected || this.section_selected === "suggestions")) {
                    list.push(
                        new ItemAccordionClass({
                            title: "Bot&otilde;es",
                            icon: "$botoesIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: false,
                            disabled: false,
                            complete: false,
                            slot_name: "botoes",
                            tooltip: "Adicione bot&otilde;es para mais interatividade em seu template"
                        })
                    );
                }
                break;
            case "image":
                this.clearImage();
                if (this.typeSelected.type === "imagem_unica") {
                    list = [
                        new ItemAccordionClass({
                            title: "Imagem &uacute;nica",
                            icon: "$imageIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: true,
                            disabled: false,
                            complete: false,
                            slot_name: "single_image",
                            tooltip: "Envie a mesma imagem para todos os contatos"
                        })
                    ];
                } else {
                    list = [
                        new ItemAccordionClass({
                            title: "Imagem personalizada",
                            icon: "$imageVariableIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: true,
                            disabled: false,
                            complete: false,
                            slot_name: "custom_image",
                            tooltip: "Envie uma imagem diferente para cada contato"
                        })
                    ];
                }

                this.setSectionActive("image");
                break;
            case "card":
                list = [];

                if (!this.section_selected || this.section_selected === "image") {
                    list.push(...[
                        new ItemAccordionClass({
                            title: "Imagem &uacute;nica",
                            icon: "$imageIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: this.image_variable ? false : true,
                            disabled: false,
                            complete: false,
                            slot_name: "single_image",
                            tooltip: "Envie a mesma imagem para todos os contatos"
                        }),
                        new ItemAccordionClass({
                            title: "Imagem personalizada",
                            icon: "$imageVariableIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: this.image_variable ? true : false,
                            disabled: false,
                            complete: false,
                            slot_name: "custom_image",
                            tooltip: "Envie uma imagem diferente para cada contato"
                        })
                    ]);
                }

                if (!this.section_selected || this.section_selected === "text") {
                    list.push(...[
                        new ItemAccordionClass({
                            title: "Escreva sua mensagem",
                            icon: "$iconeTextoIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: false,
                            disabled: false,
                            complete: false,
                            slot_name: "messageInput",
                            tooltip: "Escreva uma mensagem ou altere o texto j&aacute; criado"
                        }),
                        new ItemAccordionClass({
                            title: "Adicione campos personalizados",
                            icon: "$camposPersonalizadosIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: false,
                            disabled: false,
                            complete: false,
                            slot_name: "custom_fields",
                            tooltip: "Enrique&ccedil;a a mensagem adicionando campos personalizados"
                        })
                    ]);
                }

                if ((this.typeSelected.type === "respostas_rapidas" || this.typeSelected.type === "completo") && (!this.section_selected || this.section_selected === "suggestions")) {
                    list.push(
                        new ItemAccordionClass({
                            title: "Respostas r&aacute;pidas",
                            icon: "$respostasRapidasIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: false,
                            disabled: false,
                            complete: false,
                            slot_name: "respostas_rapidas",
                            tooltip: "Crie respostas r&aacute;pidas e estimule a intera&ccedil;&atilde;o dos seus contatos"
                        })
                    );
                }

                if ((this.typeSelected.type === "botoes" || this.typeSelected.type === "completo") && (!this.section_selected || this.section_selected === "suggestions")) {
                    list.push(
                        new ItemAccordionClass({
                            title: "Bot&otilde;es",
                            icon: "$botoesIcon",
                            icon_bg_color: "primary-blue-500",
                            icon_text_color: "gray-0",
                            active: false,
                            disabled: false,
                            complete: false,
                            slot_name: "botoes",
                            tooltip: "Adicione bot&otilde;es para mais interatividade em seu template"
                        })
                    );
                }
                break;
            }

            return list;
        },
        typeSelected() {
            if (this.buttons_type) {
                for (let button_type of this.buttons_type) {
                    if (button_type.selected) {
                        return button_type;
                    }
                }
            }

            return { type: null };
        },
        totalCaracateresMessages() {
            let total = 0;

            for (let message of this.config_preview_content.list_messages) {
                if (message.type === "text") {
                    total += message.text.length;
                } else if (message.type === "pill") {
                    total += message.pill.numberCharacters;
                }

                total++;
            }

            if (total > 0) {
                total--;
            }

            if (this.config_preview_content.list_title) {
                for (let title of this.config_preview_content.list_title) {
                    if (title.type === "text") {
                        total += title.text.length;
                    } else if (title.type === "pill") {
                        total += title.pill.numberCharacters;
                    }

                    total++;
                }

                if (total > 0) {
                    total--;
                }
            }

            return total;
        },
        isUnderLg() {
            if (this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lg) {
                return true;
            }

            return false;
        },
        disableButton() {
            let limit_suggestions = this.step_one_content?.modelo?.value === "card" ? 4 : 10;

            let invalid_message = this.totalCaracateresMessages > 1000;
            let invalid_suggestions = this.config_preview_content.list_suggestions.length > limit_suggestions;

            let empty_message = this.totalCaracateresMessages < 1;
            let empty_image = !this.image_variable && (!this.config_preview_content.image || this.config_preview_content.image == null);

            let empty_message_image = (empty_image || empty_message);

            let type_card = this.step_one_content?.modelo?.value === "card";

            switch (this.typeSelected.type) {
            case "simples":
                if (type_card) {
                    return invalid_message || empty_message_image;
                }
                return invalid_message || empty_message;
            case "respostas_rapidas":
                if (type_card) {
                    return (invalid_message || invalid_suggestions) || empty_message_image;
                }
                return invalid_message || empty_message || invalid_suggestions;
            case "botoes":
                if (type_card) {
                    return (invalid_message || invalid_suggestions) || empty_message_image;
                }
                return invalid_message || empty_message || invalid_suggestions;
            case "completo":
                if (type_card) {
                    return (invalid_message || invalid_suggestions) || empty_message_image;
                }
                return invalid_message || empty_message || invalid_suggestions ;
            case "imagem_unica":
                return !this.config_preview_content.image;
            case "imagem_personalizada":
                return !this.image_variable;
            default:
                // Default button disable
                return true;
            }
        },
        contentListSuggestions() {
            return this.config_preview_content.list_suggestions;
        },
        imageUrl() {
            return this.config_preview_content.image;
        },
        /**
         * Pega o id da url
         * @returns {boolean} true se a url tem o parametro 'id' (modo de edicao) e false se nao tem o parametro 'id' (modo de criacao)
         */
        getIdFromUrl() {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has("id")) {
                return true;
            } else {
                return false;
            }
        }
    },
    watch: {
        listButtons() {
            this.buttons_type = _.cloneDeep(this.listButtons);
        },
        value() {
            this.show = this.value;
            this.show_title = this.step_one_content?.modelo?.value === "card";
        },
        configData() {
            this.mountDataOnOpen();
        },
        show() {
            this.title_input = null;
            this.message_input = null;
            this.fields_custom = [];
            this.list_chip = [];
            this.list_buttons = [];
            this.config_preview_content.list_messages = [];
            this.list_fast_response = [];
            this.list_suggestions = [];
            this.config_preview_content.list_suggestions = [];
            this.section_selected = null;
            this.config_preview_content.image = null;
            this.image_variable = false;

            if (!this.show) {
                document.addEventListener("keyup", this.onEsc);
            } else {
                this.mountDataOnOpen();
            }

            this.$emit("input", this.show);
        },
        section_selected() {
            for (let message of this.config_preview_content.list_messages) {
                message.pill.selected = false;
            }
        },
        typeSelected() {
            this.type_selected_changed = true;

            if (this.typeSelected.type === "respostas_rapidas") {
                this.config_preview_content.list_suggestions = _.cloneDeep(this.list_fast_response);
            } else if (this.typeSelected.type === "botoes") {
                this.config_preview_content.list_suggestions = _.cloneDeep(this.list_suggestions);
            } else if (this.typeSelected.type === "completo") {
                this.config_preview_content.list_suggestions = [
                    ..._.cloneDeep(this.list_fast_response),
                    ..._.cloneDeep(this.list_suggestions)
                ];
            }

            this.accordion_active_item = null;
        },
        image_variable() {
            if (this.image_variable) {
                this.config_preview_content.image = "variable";
            }
        },
        contentListSuggestions() {
            let fast_responses = this.contentListSuggestions.filter((item) => item.type === "pill" && item.pill.type === null);
            let buttons = this.contentListSuggestions.filter((item) => item.type === "pill" && item.pill.type !== null);

            if ((fast_responses.length || !this.type_selected_changed) && this.typeSelected.type !== "botoes") {
                this.list_fast_response = fast_responses;
            }
            if ((buttons.length || !this.type_selected_changed) && this.typeSelected.type !== "respostas_rapidas") {
                this.list_suggestions = buttons;
            }

            this.type_selected_changed = false;
        },
        imageUrl() {
            if (this.imageUrl && this.imageUrl !== "variable" && this.imageUrl.length) {
                this.image_variable = false;
            }
        }
    },
    mounted() {
        this.show_title = this.step_one_content?.modelo?.value === "card";
    },
    methods: {
        /**
         * Metodo chamado ao tentar fechar o dialog
         * @vue
         */
        clickClose() {
            if (this.totalCaracateresMessages > 0 || this.config_preview_content.list_suggestions.length > 0 || this.config_preview_content.image || this.image_variable) {
                this.show_confirm_close = true;
            } else {
                this.closeDialog();
            }
        },
        /**
         * Metodo chamado ao alterar a mensagem de template
         * @vue
         * @param {Object} messages_input
         */
        changeMessageTemplate(messages_input) {
            let keys_content_preview = {
                "message_input": "list_messages",
                "title_input": "list_title"
            };

            Object.entries(keys_content_preview).forEach((key_content_preview) => {
                let [key_content, key_preview] = key_content_preview;
                this[key_content] = messages_input[key_content];
                this.mountPreviewMessage(key_preview, this[key_content]);
            });
        },
        /**
         * Monta o conteudo da previsualizacao da mensagem
         * @vue
         */
        mountPreviewMessage(key_preview, content_preview) {
            if (content_preview && content_preview.length > 0) { // Se tiver conteudo
                let list_pills = {}; // Cria uma lista de pills vazia

                for (let draggable_position in this.config_preview_content[key_preview]) {
                    if (this.config_preview_content[key_preview][draggable_position].type === "pill") { // Se for um pill
                        list_pills[draggable_position] = this.config_preview_content[key_preview][draggable_position]; // Adiciona o pill na lista de pills
                    }
                }

                this.config_preview_content[key_preview] = []; // Limpa a lista de mensagens
                let words = content_preview.split(/({[^}]+})/);

                let list_text = [];
                for (let i = 0; i < words.length; i++) {
                    let word = words[i];
                    if (word.match(/{[^}]+}/)) {
                        list_text.push(word);
                    } else if (word.trim() !== "") {
                        // Quebra a frase em uma lista de plavras, removendo espacos em branco que possam surgir
                        let list_words = word.split(" ").filter(element => element);
                        let lastWord = list_words[list_words.length - 1];
                        if (lastWord && /[,.?!]$/.test(lastWord)) {
                            if (word[0] === " ") {
                                list_text.push(" ");
                                word = word.substr(1);
                            }
                        }
                        // Adiciona as palavras no array list_text com um espaco ao final de cada uma.
                        for (let element of list_words) {
                            list_text.push(element);
                        }
                    }
                }
                while (Object.values(list_text).length > 0) {
                    let position_text = Object.keys(list_text)[0]; // Pega a primeira posicao da lista de textos

                    if (list_text[position_text] && list_text[position_text].length > 0) { // Se existir um texto na posicao

                        if (list_text[position_text].startsWith("{") && list_text[position_text].endsWith("}")) { // Verifica se o valor de list_text[position_text] esta entre chaves {}

                            const pillText = list_text[position_text].slice(1, -1); // Remove as chaves e cria o objeto do tipo "pill"
                            let pill = new BasePillClass({ title: pillText }); // Cria o objeto do tipo "pill"

                            this.config_preview_content[key_preview].push(
                                new DraggableItemClass({
                                    text: pillText,
                                    type: "pill",
                                    custom: true,
                                    pill: pill,
                                    prependIcon: "fa-grip-vertical",
                                    draggable: true,
                                    editable: true,
                                    deletable: true,
                                    rounded: true,
                                })
                            );
                        } else {
                            // Cria o objeto do tipo "text"
                            let text = list_text[position_text];

                            this.config_preview_content[key_preview].push(
                                new DraggableItemClass({
                                    text: text,
                                    type: "text",
                                    custom: false
                                })
                            );
                        }

                        // Remove objetos duplicados em this.config_preview_content[key_preview]
                        const uniqueMessages = [];
                        this.config_preview_content[key_preview].forEach((message) => {
                            if (!uniqueMessages.some(m => m.text === message.text && m.type === "pill" && m.custom === message.custom)) { // Verifica se o objeto ja existe na lista
                                uniqueMessages.push(message); // Adiciona o objeto na lista
                            }
                        });
                        this.config_preview_content[key_preview] = []; // Limpa a lista de mensagens
                        this.config_preview_content[key_preview] = uniqueMessages; // Atualiza a lista de mensagens
                        delete list_text[position_text]; // Remove o texto da lista de textos
                    }
                }

            } else {
                this.config_preview_content[key_preview] = [];
            }
        },
        /**
         * Metodo que realiza o fechamento da modal de configuracao
         * @vue
         */
        closeDialog() {
            this.$emit("close");
            this.show = false;
        },
        /**
         * Funcao para vincular eventos no esq
         * @param {Object} e Evento
         * @vue
         */
        onEsc(e) {
            if (!e) {
                e = window.event;
            }

            let keyCode = e.keyCode || e.which;

            if (keyCode == "27") {
                if (!this.show) {
                    this.clickClose();
                    document.removeEventListener("keyup", this.onEsc);
                }
            }
        },
        /**
         * Metodo chamado ao clicar em algum campo customizado
         * @param {DraggableItemClass} item Dados de um item
         * @vue
         */
        clickCustomField(item) {
            this.config_preview_content.list_messages.push(item);
        },
        /**
         * Edita o conteudo de um item ja existente
         * @param {DraggableItemClass} edited_item Dados de um item editado
         */
        editCustomField(edited_item) {
            let new_list = [];

            for (let item of this.config_preview_content.list_messages) {
                if (item.id === edited_item.id) {
                    new_list.push(edited_item);
                } else {
                    new_list.push(item);
                }
            }

            this.config_preview_content.list_messages = new_list;
        },
        /**
         * Remove um item da lista de campos personalizados
         * @param {DraggableItemClass} removed_item Dados do item a ser removido
         */
        removeCustomField(removed_item) {
            this.config_preview_content.list_messages = this.config_preview_content.list_messages.filter(item => {
                return item.id !== removed_item.id;
            });
        },
        /**
         * Metodo chamado ao clicar em alguma resposta rapida
         * @param {DraggableItemClass} item Dados de um item
         * @vue
         */
        clickFastResponse(item) {
            this.config_preview_content.list_suggestions.push(item);
        },
        /**
         * Edita o conteudo de um item ja existente
         * @param {DraggableItemClass} edited_item Dados de um item editado
         */
        editFastResponse(edited_item) {
            let new_list = [];

            for (let item of this.config_preview_content.list_suggestions) {
                if (item.id === edited_item.id) {
                    new_list.push(edited_item);
                } else {
                    new_list.push(item);
                }
            }

            this.config_preview_content.list_suggestions = new_list;
        },
        /**
         * Remove um item da lista de respostas
         * @param {DraggableItemClass} removed_item Dados do item a ser removido
         */
        removeFastResponse(removed_item) {
            this.config_preview_content.list_suggestions = this.config_preview_content.list_suggestions.filter((item) => {
                return item.id !== removed_item.id;
            });
        },
        /**
         * Metodo chamado ao clicar em algum suggestion
         * @param {DraggableItemClass} item Dados do item
         * @vue
         */
        clickSuggestionButton(item) {
            this.config_preview_content.list_suggestions.push(item);
        },
        /**
         * Edita o conteudo de um item ja existente
         * @param {DraggableItemClass} edited_item Dados de um item editado
         */
        editSuggestionButton(edited_item) {
            let new_list = [];

            for (let item of this.config_preview_content.list_suggestions) {
                if (item.id === edited_item.id) {
                    new_list.push(edited_item);
                } else {
                    new_list.push(item);
                }
            }

            this.config_preview_content.list_suggestions = new_list;
        },
        /**
         * Remove um item da lista de respostas
         * @param {DraggableItemClass} removed_item Dados do item a ser removido
         */
        removeSuggestionButton(removed_item) {
            this.config_preview_content.list_suggestions = this.config_preview_content.list_suggestions.filter((item) => {
                return item.id !== removed_item.id;
            });
        },
        /**
         * Metodo chamado ao clicar no botao de salvar do componente
         * @vue
         */
        saveConfiguration() {
            /**
             * Emite um evento "saveConfig" informando que o botao de salvar do componente foi acionado
             *
             * @event saveConfig
             * @vue
             */
            this.$emit("saveConfig");

            let button_selected = [];
            let card = null;

            for (let button of this.buttons_type) {
                if (button.selected) {
                    button_selected = button;
                    break;
                }
            }

            for (let suggestion_index in this.config_preview_content.list_suggestions) {
                this.config_preview_content.list_suggestions[suggestion_index].order = suggestion_index;
            }

            switch (this.typeSelected.type) {
            case "respostas_rapidas":
                this.config_preview_content.list_suggestions = this.config_preview_content.list_suggestions.filter((suggestion) => suggestion.pill.type === null);

                this.list_suggestions = [];
                this.list_fast_response = this.config_preview_content.list_suggestions;
                break;
            case "botoes":
                this.config_preview_content.list_suggestions = this.config_preview_content.list_suggestions.filter((suggestion) => suggestion.pill.type !== null);

                this.list_fast_response = [];
                this.list_suggestions = this.config_preview_content.list_suggestions;
                break;
            case "simples":
                this.config_preview_content.list_suggestions = [];

                this.list_fast_response = [];
                this.list_suggestions = [];
                this.list_chips_preview = [];
                break;
            case "imagem_unica":
                this.config_preview_content.list_suggestions = [];

                this.list_fast_response = [];
                this.list_suggestions = [];
                this.list_chips_preview = [];
                break;
            case "imagem_personalizada":
                this.config_preview_content.list_suggestions = [];

                this.list_fast_response = [];
                this.list_suggestions = [];
                this.list_chips_preview = [];
                break;
            case "completo":
                this.list_fast_response = this.config_preview_content.list_suggestions.filter((suggestion) => suggestion.pill.type === null);
                this.list_suggestions = this.config_preview_content.list_suggestions.filter((suggestion) => suggestion.pill.type !== null);
                break;
            }

            if (this.step_one_content?.modelo?.value === "card") {
                let orientation = this.config_preview_content.config_bar.first_value;
                card = new CardRcsClass({
                    image: this.config_preview_content.image,
                    title: this.config_preview_content.list_title,
                    description: this.config_preview_content.list_messages,
                    suggestionButtons: this.list_suggestions,
                    suggestionResponses: this.list_fast_response,
                    orientation: orientation,
                    alignment: (orientation === "HORIZONTAL" ? this.config_preview_content.config_bar.second_value : null),
                    image_height: (orientation === "VERTICAL" ? this.config_preview_content.config_bar.second_value : null)
                });
            }

            /**
             * Emite os dados para alterar o valor de v-model
             * @event input
             * @vue
             */
            this.$emit("save", {
                button_type: button_selected,
                list_messages: this.config_preview_content.list_messages,
                list_fast_response: this.list_fast_response,
                list_suggestions: this.list_suggestions,
                list_chips_preview: this.config_preview_content.list_suggestions,
                image: this.config_preview_content.image,
                card: card
            });
        },
        /**
         * Limpa os dados da imagem
         * @vue
         */
        clearImage() {
            this.config_preview_content.image = null;
            this.image_variable = false;
        },
        /**
         * Monta os dados dentro do componente assim que o valor de value for alterado
         * @vue
         */
        mountDataOnOpen() {
            for (let button of this.buttons_type) {
                if (button.type === this.configData.button_type.type) {
                    button.selected = true;
                } else {
                    button.selected = false;
                }
            }

            this.config_preview_content.list_title = this.configData.card?.title ?? [];
            this.config_preview_content.list_messages = this.configData.list_messages ?? [];
            this.list_fast_response = this.configData.list_fast_response ?? [];
            this.list_suggestions = this.configData.list_suggestions ?? [];
            this.config_preview_content.list_suggestions = this.configData.list_chips_preview ?? [];
            this.config_preview_content.image = this.configData.image;

            this.image_variable = this.configData.image === "variable";

            if (this.step_one_content?.modelo?.value === "card") {
                this.config_preview_content.config_bar = {
                    first_value: this.configData.card.orientation,
                    second_value: this.configData.card.orientation === "VERTICAL" ? this.configData.card.image_height : this.configData.card.alignment
                };
            }

            if (this.configData.list_messages) {
                let schedule_view_content = { "title_input": "list_title", "message_input": "list_messages" };
                Object.entries(schedule_view_content).forEach((view_content) => {
                    let [key_input_view, key_config_data] = view_content;

                    if (this.config_preview_content[key_config_data].length < 1) {
                        return;
                    }

                    let new_message = "";
                    let isPreviousPill = false;
                    for (let message of this.config_preview_content[key_config_data]) {
                        if (message.type === "text") {
                            if (isPreviousPill) {
                                if (message.text && !/^[?,!]$/.test(message.text.trim())) { // Verificando se message.text nao eh uma virgula, interrogacao ou exclamacao
                                    new_message += " ";
                                }
                            }
                            new_message += message.text + " ";
                            isPreviousPill = false;

                        } else if (message.type === "break-line") {
                            if (isPreviousPill) {
                                new_message += " ";
                            }
                            new_message += message.text;
                            isPreviousPill = false;

                        } else if (message.type === "pill") {
                            if (message.text) {
                                new_message += `{${message.text}}`;
                                this.fields_custom.push(message);
                                isPreviousPill = true;
                            }
                        }
                    }

                    this[key_input_view] = new_message;
                });
            }

            if (this.list_fast_response) {
                for (let fast_response of this.list_fast_response) {
                    if (fast_response.custom) {
                        this.list_chip.push(fast_response);
                    }
                }
            }

            if (this.list_suggestions) {
                for (let suggestion of this.list_suggestions) {
                    if (suggestion.custom) {
                        this.list_buttons.push(suggestion);
                    }
                }
            }
        },
        /**
         * Exibe uma notificacao ao usuario
         * @param {Object} data Dados da notificacao
         * @vue
         */
        showNotify(data) {
            this.notify_title = data.notify_title;
            this.notify_message = data.notify_message;
            this.removed_pill = data.removed_pill;
            this.position_removed = data.position_removed;
            this.notify_button_label = data.notify_button_label;
            this.restore_type = data.restore_type;
            this.show_notify = true;
        },
        /**
         * Faz a restauracao de um item removido
         * @vue
         */
        restoreItem() {
            let array_start = [];
            let array_end = [];

            if (this.restore_type === "deleteImage") {
                this.config_preview_content.image = this.removed_pill;
                this.show_notify = false;
            } else {
                if (this.removed_pill.pill.numberCharacters !== null) {
                    switch (this.restore_type) {
                    case "remove":
                        array_start = this.config_preview_content.list_messages.slice(0, this.position_removed);
                        array_end = this.config_preview_content.list_messages.slice(this.position_removed);

                        this.config_preview_content.list_messages = array_start;
                        this.config_preview_content.list_messages.push(this.removed_pill);
                        this.config_preview_content.list_messages.push(...array_end);

                        this.show_notify = false;
                        break;
                    default:
                        this.fields_custom.push(this.removed_pill);
                        this.clickCustomField(this.removed_pill);

                        this.show_notify = false;
                        break;
                    }
                } else {
                    if (this.removed_pill.pill.type !== null) {
                        if (this.restore_type === "remove") {
                            array_start = this.config_preview_content.list_suggestions.slice(0, this.position_removed);
                            array_end = this.config_preview_content.list_suggestions.slice(this.position_removed);

                            this.config_preview_content.list_suggestions = array_start;
                            this.config_preview_content.list_suggestions.push(this.removed_pill);
                            this.config_preview_content.list_suggestions.push(...array_end);

                            this.show_notify = false;
                        } else {
                            this.list_buttons.push(this.removed_pill);
                            this.clickFastResponse(this.removed_pill);
                        }
                    } else {
                        if (this.restore_type === "remove") {
                            array_start = this.config_preview_content.list_suggestions.slice(0, this.position_removed);
                            array_end = this.config_preview_content.list_suggestions.slice(this.position_removed);

                            this.config_preview_content.list_suggestions = array_start;
                            this.config_preview_content.list_suggestions.push(this.removed_pill);
                            this.config_preview_content.list_suggestions.push(...array_end);

                            this.show_notify = false;
                        } else {
                            this.list_chip.push(this.removed_pill);
                            this.clickFastResponse(this.removed_pill);

                            this.show_notify = false;
                        }
                    }
                }
            }
        },
        /**
         * Define qual sessao (texto, respostas rapidas, botoes) esta ativa no momento
         * @param {String} type Tipo de secao ativa
         * @vue
         */
        setSectionActive(type, item_active = 0) {
            this.section_selected = type;

            if (type) {
                this.accordion_active_item = item_active;
            } else {
                this.accordion_active_item = null;
            }
        },
        /**
         * Metodo executado quando um item do accordion for clicado
         * @param {Object} accordion Dados do accordion selecionado
         * @vue
         */
        accordionClicked(accordion) {
            if (accordion.item !== undefined) {
                switch (accordion.item.slot_name) {
                case "messageInput":
                    this.setSectionActive("text", 0);
                    break;
                case "custom_fields":
                    this.setSectionActive("text", 1);
                    break;
                case "respostas_rapidas":
                    this.setSectionActive("suggestions", 0);
                    break;
                case "botoes":
                    this.setSectionActive("suggestions", this.typeSelected.type === "completo" ? 1 : 0);
                    break;
                case "single_image":
                    if (this.config_preview_content.image === "variable" && this.image_variable) {
                        if (!this.getIdFromUrl) {
                            this.clearImage();
                        }
                    }
                    this.setSectionActive("image", 0);
                    break;
                case "custom_image":
                    if (!this.config_preview_content.image !== "variable" && this.image_variable === false) {
                        if (!this.getIdFromUrl) {
                            this.clearImage();
                        }
                    }
                    this.setSectionActive("image", 1);
                    break;
                }
            } else {
                this.section_selected = null;
            }
        }
    },
    template:
        /*html*/
        `
            <v-dialog
                v-model="show"
                fullscreen
                scrollable
                persistent
            >
                <v-card elevation="0" tabindex="0" class="cursor-default" :ripple="false">
                    <v-card-title>
                        <v-toolbar elevation="0">
                            <v-container class="align-start">
                                <v-row justify="space-between" no-gutters>
                                    <v-col cols="auto">
                                        <a class="body-2 text-primary-blue-500 text-decoration-none" @click="clickClose">
                                            <v-icon small left color="primary-blue-500">mdi-close-thick</v-icon> Fechar
                                        </a>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-toolbar>
                    </v-card-title>
                    <v-card-text>
                        <v-container class="align-start">
                            <v-row class="px-0" no-gutters>
                                <v-col cols="12">
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <page-header
                                                title="Configure o modelo escolhido"
                                                description="Aqui voc&ecirc; pode criar sua mensagem de acordo com o modelo escolhido."
                                                tutorial
                                                @tutorial="$refs.tour.start()"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="step_one_content">
                                        <v-col cols="12" lg="3" :data-v-step="9">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <v-card elevation="0" class="bg-gray-0 rounded-top-1 rounded-bottom-0 border-1 border-gray-200 pa-4">
                                                        <span class="body-2 text-gray-800">Modelos de {{ step_one_content?.modelo?.label.toLowerCase() }}</span>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <v-card elevation="0" :height="isUnderLg ? 'auto' : '467'" class="bg-gray-30 rounded-bottom-1 rounded-top-0 border-bottom-1 border-left-1 border-right-1 border-top-0 border-gray-200 overflow-y-auto pa-6">
                                                        <base-tooltip text="Voc&ecirc; pode alternar entre os modos a qualquer momento sem perder o que voc&ecirc; j&aacute; criou.">
                                                            <card-large-choice
                                                                v-model="buttons_type"
                                                                :columns="12"
                                                                :inline="isUnderLg"
                                                                horizontal
                                                                @input="setSectionActive(null)"
                                                            />
                                                        </base-tooltip>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="6"
                                            lg="5"
                                            class="mt-2 mt-lg-0"
                                            :data-v-step="10"
                                        >
                                            <config-preview
                                                v-model="config_preview_content"
                                                :type="step_one_content?.modelo?.value"
                                                :fast-responses="['respostas_rapidas', 'completo'].indexOf(typeSelected.type) > -1"
                                                :buttons="['botoes', 'completo'].indexOf(typeSelected.type) > -1"
                                                image
                                                :image-variable="(typeSelected.type === 'imagem_personalizada') || (this.step_one_content?.modelo?.value === 'card' && image_variable)"
                                                text
                                                :section-selected="section_selected"
                                                @clickSection="setSectionActive"
                                                @showNotify="showNotify"
                                            />
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            md="6"
                                            lg="4"
                                            class="mt-2 mt-lg-0"
                                            :data-v-step="13"
                                        >
                                            <accordion-default 
                                                :items="listAccordion" 
                                                :active-item="accordion_active_item" 
                                                @click_item="accordionClicked"
                                            >
                                                <template #messageInput="{}">
                                                    <add-message-template 
                                                        v-model="message_input" 
                                                        :title="title_input" 
                                                        :show-title="show_title" 
                                                        :is-fallback="false"
                                                        @changeMessage="changeMessageTemplate" 
                                                    />
                                                </template>
                                                <template #custom_fields="{}">
                                                    <fields-template
                                                        v-model="fields_custom"
                                                        @itemClick="clickCustomField"
                                                        @editItem="editCustomField"
                                                        @removeItem="removeCustomField"
                                                        @showNotify="showNotify"
                                                    />
                                                </template>
                                                <template #respostas_rapidas="{}">
                                                    <respostas-rapidas
                                                        v-model="list_chip"
                                                        @itemClick="clickFastResponse"
                                                        @editItem="editFastResponse"
                                                        @removeItem="removeFastResponse"
                                                        @showNotify="showNotify"
                                                    />
                                                </template>
                                                <template #botoes="{}">
                                                    <suggestion-buttons
                                                        v-model="list_buttons"
                                                        @itemClick="clickSuggestionButton"
                                                        @editItem="editSuggestionButton"
                                                        @removeItem="removeSuggestionButton"
                                                        @showNotify="showNotify"
                                                    />
                                                </template>
                                                <template #single_image="{}">
                                                    <form-add-image
                                                        v-model="config_preview_content.image"
                                                        @showNotify="showNotify"
                                                    />
                                                </template>
                                                <template #custom_image="{}">
                                                    <form-add-custom-image
                                                        v-model="image_variable"
                                                        @showNotify="showNotify"
                                                    />
                                                </template>
                                            </accordion-default>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="bg-gray-0 pa-4 shadow-top-1">
                        <v-row justify="center" no-gutters>
                            <v-col cols="auto">
                                <base-button
                                    primary
                                    label="Salvar configura&ccedil;&atilde;o"
                                    icon="fa-check"
                                    block
                                    :disabled="disableButton"
                                    @click="saveConfiguration"
                                />
                            </v-col>
                        </v-row>
                    </v-card-actions>
                    <base-dialog
                        v-model="show_confirm_close"
                        max-width="410px"
                        title="Tem certeza que deseja fechar?"
                        :action-button="{ label: 'Sim, quero fechar', icon: 'mdi-check-bold' }"
                        use-sub-action
                        @clickDialog="closeDialog"
                    >
                        <v-row no-gutters>
                            <v-col class="text-start">
                                <span class="body-2 text-gray-900">Voc&ecirc; perder&aacute; todas as altera&ccedil;&otilde;es realizadas.</span>
                            </v-col>
                        </v-row>
                        <template #subAction>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12" class="text-center">
                                    <a class="text-primary-blue-500 text-decoration-none body-2" @click="show_confirm_close = false">Mudei de id&eacute;ia, quero continuar aqui</a>
                                </v-col>
                            </v-row>
                        </template>
                    </base-dialog>
                    <pg-tour ref="tour" :steps="stepsTour" />
                    <base-notify
                        v-model="show_notify"
                        :title="notify_title"
                        :message="notify_message"
                        :button-label="notify_button_label"
                        button-icon="fa-undo"
                        type="success"
                        @click="restoreItem"
                    />
                </v-card>
            </v-dialog>
        `
};
