//import createPersistedState from "vuex-persistedstate";
import Vue from "vue";
import modules from "./modules";
import services from "../http/index";

Vue.use(Vuex);
window.Vuex = Vuex;

const store = new Vuex.Store({
    modules,
    plugins: [createPersistedState({
        paths: ["auth"]
    })],
    strict: true
});

services.api.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null;

    if (status === 401 || status === 419) {
        return store.dispatch("auth/ActionDoTokenSanctum").then(response => {
            console.log(response);
            return services.api.request(error.config);
        });
    }

    return Promise.reject(error);
});

export default store;