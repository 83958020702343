import BaseDataTable from "../../../components/organismos/BaseDataTable";
import { DataTableHeaderClass } from "../../../classes/DataTableHeaderClass";
import TableThumb from "../../../components/moleculas/TableThumb.js";
import BaseButton from "../../../components/atomos/BaseButton.js";
import TemplateUtils from "../../../utils/TemplateUtils.js";
import BaseIconButton from "../../../components/atomos/BaseIconButton.js?v=3";
import CanalIconDescription from "../../../components/moleculas/CanalIconDescription.js?v=2";
import ButtonAction from "../../../components/moleculas/ButtonAction.js?v=2";
import DialogEnable from "../../template/components/dialogs/DialogEnable.js";

const template_structure = {
    CD_TEMPLATE: "",
    NM_EMPRESA: "",
    ID_EMPRESA: "",
    ID_EMPRESA_PAI: "",
    CD_PRODUTO_PDES: "",
    DS_TEMPLATE: "",
    TP_TEMPLATE: "",
    ID_TEMPLATE: "",
    NM_USUARIO: "",
    DT_TEMPLATE: "",
    ALT_NM_USUARIO: "",
    DT_ULTIMA_ALTERACAO: "",
    NM_PRODUTO_PAINEL: ""
};

export default {
    components: {
        BaseDataTable,
        TableThumb,
        BaseButton,
        BaseIconButton,
        CanalIconDescription,
        ButtonAction,
        DialogEnable
    },
    filters: {
        format_date: function (date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY HH:mm:ss");
            } else {
                return "-";
            }
        },
        filter_empty: function (value) {
            return value ? value : "-";
        }
    },
    data() {
        return {
            template_utils: new TemplateUtils(),
            type_product: this.$cookie === undefined ? getCookie("type_product") : this.$cookie.get("type_product"),
            id_empresa: this.$cookie === undefined ? getCookie("id_empresa_selected") : this.$cookie.get("id_empresa_selected"),
            dense: false,
            filterable: true,
            pagination: true,
            page_length: 10,
            expandable: false,
            custom_columns: true,
            select_column: false,
            single_select: false,
            loading: false,
            show_page_length: true,
            item_key: "ID_MO_MOES",
            headers: [
                new DataTableHeaderClass({
                    text: "Identificador",
                    value: "ID_TEMPLATE",
                    visibility: "lg",
                    tooltipText:
                        "Identificador gerado automaticamente pelo sistema"
                }),
                new DataTableHeaderClass({
                    text: "Canal",
                    value: "CD_PRODUTO_PDES",
                    cellClass: "text-nowrap",
                    tooltipText: "Canal referente a template"
                }),
                new DataTableHeaderClass({
                    text: "Cliente",
                    value: "NM_EMPRESA",
                    visibility: "md",
                    tooltipText: "Cliente vinculado a template"
                }),
                new DataTableHeaderClass({
                    text: "Nome",
                    value: "CD_TEMPLATE",
                    cellClass: "break-word",
                    tooltipText: "C&oacute;digo da template"
                }),
                new DataTableHeaderClass({
                    text: "Criado por",
                    value: "NM_USUARIO",
                    visibility: "lg",
                    className: "text-nowrap",
                    tooltipText: "Usu&aacute;rio que criou a template"
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities(
                        "Data de cria&ccedil;&atilde;o"
                    ),
                    value: "DT_TEMPLATE",
                    visibility: "md",
                    className: "text-nowrap",
                    tooltipText: "Data que foi criado a template"
                }),
                new DataTableHeaderClass({
                    text: "Atualizado por",
                    value: "ALT_NM_USUARIO",
                    visibility: "lg",
                    className: "text-wrap",
                    cellClass: "break-word",
                    tooltipText:
                        "&Uacute;ltimo usu&aacute;rio que alterou a template"
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities(
                        "&Uacute;ltima altera&ccedil;&atilde;o"
                    ),
                    value: "DT_ULTIMA_ALTERACAO",
                    width: "112px",
                    visibility: "md",
                    className: "text-nowrap",
                    tooltipText:
                        "&Uacute;ltima data de altera&ccedil;&atilde;o da template"
                }),
                new DataTableHeaderClass({
                    text: "Modelo",
                    value: "TP_TEMPLATE",
                    visibility: "lg",
                    tooltipText:
                        "Tipo de template (Texto, m&iacute;dia ou carrousel)"
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("Conte&uacute;do"),
                    value: "DS_TEMPLATE",
                    visibility: "md",
                    sortable: false,
                    width: "130px",
                    tooltipText: "Texto resumido do conte&uacute;do da template"
                }),
                new DataTableHeaderClass({
                    text: window.Vue.htmlEntities("A&ccedil;&otilde;es"),
                    value: "actions",
                    sortable: false,
                    className: "text-nowrap",
                    tooltipText: "Bot�es de a��o"
                })
            ],
            custom_slots: ["CD_PRODUTO_PDES", "NM_EMPRESA", "DS_TEMPLATE", "TP_TEMPLATE", "NM_USUARIO", "DT_TEMPLATE", "ALT_NM_USUARIO", "DT_ULTIMA_ALTERACAO", "actions"],
            items: [],
            alternative_header: false,
            item_name: "templates",
            server_side_config: {
                request_to: "API",
                url: "/template-rcs/list",
                method: "post",
                data: {
                    data_inicio: "",
                    data_fim: "",
                    telefone: "",
                    id_empresa: "",
                    campo_informado: "",
                    origens: "",
                    status: "",
                    codigo_agrupador: ""
                },
                headers: {
                    "Content-Type": "application/json"
                }
            },
            show_dialog_enable: false,
            template_structure: template_structure,
            template_selected: template_structure,
        };
    },
    methods: {
        getTemplates() {
            this.items = [
                {
                    ID_TEMPLATE_APPS_TAES: "1",
                    ID_EMPRESA_TAES: "11051",
                    CD_TEMPLATE_TAES: "teste_ticonta02",
                    DS_TEMPLATE_TAES: "Ola {nome}!",
                    DS_PARAMETRO_TAES: "{\"nome\": \"str\"}",
                    ST_TEMPLATE_TAES: "A",
                    DT_CADASTRO_TAES: "2020-09-16 15:14:36",
                    DT_UPDATE_TAES: "2020-09-16 15:14:39",
                    CD_PRODUTO_PDES: "WTS",
                    TP_TEMPLATE_TAES: "M"
                },
                {
                    ID_TEMPLATE_APPS_TAES: "43",
                    ID_EMPRESA_TAES: "11051",
                    CD_TEMPLATE_TAES: "teste_ti_anexo",
                    DS_TEMPLATE_TAES:
                        "Ol\u00e1 {primeiro_nome}, Devido ao cen\u00e1rio atual e para sua comodidade aqui est\u00e1 sua fatura do seu cart\u00e3o {tipo_cartao} com final {digitos_finais}.",
                    DS_PARAMETRO_TAES:
                        "{\"mes\": \"str\", \"tipo_cartao\": \"str\", \"primeiro_nome\": \"str\", \"digitos_finais\": \"str\", \"headerDocumentUrl\": \"str\", \"headerDocumentCaption\": \"str\"}",
                    ST_TEMPLATE_TAES: "A",
                    DT_CADASTRO_TAES: "",
                    DT_UPDATE_TAES: "",
                    CD_PRODUTO_PDES: "WTS",
                    TP_TEMPLATE_TAES: "M"
                },
                {
                    ID_TEMPLATE_APPS_TAES: "44",
                    ID_EMPRESA_TAES: "11051",
                    CD_TEMPLATE_TAES: "rcs_text",
                    DS_TEMPLATE_TAES:
                        "{\"text\": \"Ol\u00e1 {nome}, esse \u00e9 um teste do time de {time}\"}",
                    DS_PARAMETRO_TAES: "{\"nome\": \"str\", \"time\": \"str\"}",
                    ST_TEMPLATE_TAES: "A",
                    DT_CADASTRO_TAES: "2020-12-15 16:48:21",
                    DT_UPDATE_TAES: "2021-01-25 16:48:21",
                    CD_PRODUTO_PDES: "RCS",
                    TP_TEMPLATE_TAES: "M"
                }
            ];
        },
        showEnable(item) {
            console.log("Item -- ", item);
            this.template_selected = item;
            this.show_dialog_enable = true;
        },
    },
    watch: {
        loading(new_val) {
            if (new_val) {
                this.items = [];
            } else {
                this.getTemplates();
            }
        }
    },
    template:
        /*html*/
        `
            <v-row justify="center" class="bg-background">
                <v-col cols="12" md="10">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="3">
                                        <v-checkbox label="Colunas personalizaveis" v-model="custom_columns"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Filtravel" v-model="filterable"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Habilitar total de linhas" v-model="show_page_length"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Habilitar paginacao" v-model="pagination"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Habilitar checkbox" v-model="select_column"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Checkbox unico" v-model="single_select" :disabled="!select_column"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Loading status" v-model="loading"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Dense style" v-model="dense"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Header alternativo" v-model="alternative_header"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-text-field label="Nome dos itens" v-model="item_name"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card class="pa-5 bg-background" elevation="0" >
                                <base-data-table
                                    :headers="headers"
                                    :list-items="items"
                                    :loading="loading"
                                    :custom-slots="custom_slots"
                                    :server-side-config="server_side_config"
                                    item-key="ID_TEMPLATE"
                                    sort-by="DT_ULTIMA_ALTERACAO"
                                    show-page-length
                                    :step-tutorial-custom-columns="2"
                                >
                                    <template v-slot:CD_PRODUTO_PDES="props">
                                         <canal-icon-description :product-name="props.item.CD_PRODUTO_PDES && props.item.CD_PRODUTO_PDES.length > 0 ? props.item.CD_PRODUTO_PDES : (props.item.TP_TEMPLATE === 'C' ? 'sms-c' : 'sms')"></canal-icon-description>
                                    </template>
                                    
                                    <template v-slot:NM_EMPRESA="props">
                                        <span v-if="props.item.ID_EMPRESA == id_empresa || (props.item.ID_EMPRESA_PAI == id_empresa && (!props.item.ID_EMPRESA || props.item.ID_EMPRESA == ''))" class="body-2">Todos</span>
                                        <span v-else class="body-2">{{ props.item.NM_EMPRESA }}</span>
                                    </template>
                                    
                                    <template v-slot:TP_TEMPLATE="props">
                                        <span class="body-2" v-html="template_utils.getTraducaoTipoTemplateApps(props.item.TP_TEMPLATE)"></span>
                                    </template>
                                    
                                    <template v-slot:NM_USUARIO="props">
                                        <span class="body-2">{{ props.item.NM_USUARIO | filter_empty }}</span>
                                    </template>
                                    
                                    <template v-slot:DT_TEMPLATE="props">
                                        <span class="body-2">{{ props.item.DT_TEMPLATE | format_date }}</span>
                                    </template>
                                    
                                    <template v-slot:ALT_NM_USUARIO="props">
                                        <span class="body-2">{{ props.item.ALT_NM_USUARIO || "-" }}</span>
                                    </template>
                                    
                                    <template v-slot:DT_ULTIMA_ALTERACAO="props">
                                        <span v-if="props.item.DT_ULTIMA_ALTERACAO" class="body-2">{{ props.item.DT_ULTIMA_ALTERACAO | format_date }}</span>
                                        <span v-else class="body-2">-</span>
                                    </template>
                                    
                                    <template v-slot:DS_TEMPLATE="props">
                                        <v-row no-gutters class="py-1">
                                            <v-col cols="12">
                                                <v-card elevation="0" class="rounded-0" max-width="130px">
                                                    <table-thumb
                                                        :ds-template="props.item.DS_TEMPLATE"
                                                        :type-template="props.item.TP_TEMPLATE"
                                                        @click="showDetails(props.item)"
                                                    />
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </template>
                                    
                                    <template v-slot:actions="props">
                                        <v-row no-gutters class="py-2 flex-nowrap">
                                            <v-col cols="auto" class="px-1">
                                                <button-action
                                                    type="edit"
                                                    :disabled="props.item.CD_PRODUTO_PDES === 'WTS' || (props.item.CD_PRODUTO_PDES === 'RCS' && ['M', 'I'].indexOf(props.item.TP_TEMPLATE) === -1)"
                                                    @click="redirectEdit(props.item)"
                                                ></button-action>
                                            </v-col>
                                            <v-col cols="auto" class="px-1">
                                                <button-action
                                                    type="copy"
                                                    :disabled="props.item.CD_PRODUTO_PDES === 'WTS' || (props.item.CD_PRODUTO_PDES === 'RCS' && ['M', 'I'].indexOf(props.item.TP_TEMPLATE) === -1)"
                                                    @click="showCopyTemplate(props.item)"
                                                ></button-action>
                                            </v-col>
                                            <v-col cols="auto" class="px-1">
                                                <button-action
                                                    type="disable"
                                                    @click="showDisable(props.item)"
                                                ></button-action>
                                            </v-col>
                                            <v-col cols="auto" class="px-1">
                                                <button-action
                                                    type="enable"
                                                    @click="showEnable(props.item)"
                                                ></button-action>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </base-data-table>
                            </v-card>
                        </v-col>
                        <dialog-enable  v-model="show_dialog_enable"  :item="template_selected" @disabled="showEnable"></dialog-enable>
                    </v-row>
                </v-col>
            </v-row>
        `
};
