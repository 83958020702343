import { BasePillClass } from "../../../classes/BasePillClass";
import { DraggableItemClass } from "../../../classes/DraggableItemClass";
import ConfigPreview from "../../template-rcs/components/ConfigPreview";

export default {
    components: {
        ConfigPreview
    },
    data() {
        return {
            have_suggestions: false,
            type: "card",
            fastResponses: true,
            buttons: true,
            image: true,
            text: true,
            imageVariable: false,
            list_fast_responses: [
                new DraggableItemClass({ text: "Sim", type: "pill", pill: new BasePillClass({ title: "Sim", prependIcon: "fa-grip-vertical", draggable: true }) }),
                new DraggableItemClass({ text: "Que legal!", type: "pill", pill: new BasePillClass({ title: "Que legal!", prependIcon: "fa-grip-vertical", draggable: true, editable: true, deletable: true }) })
            ],
            list_buttons: [
                new DraggableItemClass({ text: "Ligar", type: "pill", pill: new BasePillClass({ title: "Ligar", titleIcon: "fa-phone-alt", prependIcon: "fa-grip-vertical", draggable: true, editable: true, deletable: true, type: "phone" }), custom: true }),
                new DraggableItemClass({ text: "Local", type: "pill", pill: new BasePillClass({ title: "Local", titleIcon: "fa-crosshairs", prependIcon: "fa-grip-vertical", draggable: true, editable: true, deletable: true, type: "localization" }), custom: true })
            ],
            fillText: false,
            fillSuggestions: false,
            fillImage: false,
            content: null
        };
    },
    computed: {
        value() {
            let data = {};

            if (this.fillText) {
                data["list_title"] = [
                    new DraggableItemClass({ text: "Ola", type: "text", pill: new BasePillClass({ title: "Ola" }) }),
                    new DraggableItemClass({ text: "pessoa", type: "text", pill: new BasePillClass({ title: "pessoa" }) }),
                    new DraggableItemClass({ text: "Titulo", type: "pill", pill: new BasePillClass({ title: "Titulo", prependIcon: "fa-grip-vertical", draggable: true, rounded: true, numberCharacters: 10 }) }),
                    new DraggableItemClass({ text: "subtitulo", type: "pill", pill: new BasePillClass({ title: "subtitulo", prependIcon: "fa-grip-vertical", draggable: true, rounded: true, numberCharacters: 10 }) }),
                ];
                data["list_messages"] = [
                    new DraggableItemClass({ text: "Ola", type: "text", pill: new BasePillClass({ title: "Ola" }) }),
                    new DraggableItemClass({ text: "Nome", type: "pill", pill: new BasePillClass({ title: "Nome", prependIcon: "fa-grip-vertical", draggable: true, rounded: true, numberCharacters: 10 }) }),
                    new DraggableItemClass({ text: "isso", type: "text", pill: new BasePillClass({ title: "isso" }) }),
                    new DraggableItemClass({ text: "eh", type: "text", pill: new BasePillClass({ title: "eh" }) }),
                    new DraggableItemClass({ text: "mensagem", type: "pill", pill: new BasePillClass({ title: "mensagem", prependIcon: "fa-grip-vertical", draggable: true, rounded: true, numberCharacters: 10 }) }),
                    new DraggableItemClass({ text: "um", type: "text", pill: new BasePillClass({ title: "um" }) }),
                    new DraggableItemClass({ text: "teste", type: "text", pill: new BasePillClass({ title: "teste" }) }),
                ];
            }
            if (this.fillSuggestions) {
                data["list_suggestions"] = [];
            }
            if (this.fillImage) {
                data["image"] = this.imageVariable ? "variable" : "https://s4.static.brasilescola.uol.com.br/img/2019/09/panda.jpg";
            }

            return data;
        }
    },
    watch: {
        value() {
            this.mountSuggestions();
            this.content = this.value;
        },
        fastResponses() {
            this.mountSuggestions();
        },
        buttons() {
            this.mountSuggestions();
        },
        content() {
            console.log(this.content);
        }
    },
    mounted() {
        this.mountSuggestions();
    },
    methods: {
        mountSuggestions() {
            if (this.value.list_suggestions !== undefined && this.fillSuggestions) {
                if (this.fastResponses && this.buttons) {
                    this.value.list_suggestions = [...this.list_fast_responses, ...this.list_buttons];
                } else if (this.fastResponses) {
                    this.value.list_suggestions = this.list_fast_responses;
                } else if (this.buttons) {
                    this.value.list_suggestions = this.list_buttons;
                } else {
                    this.value.list_suggestions = [];
                }
            }
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center" class="bg-background">
                <v-col cols="12" md="10">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="4">
                                        <v-checkbox label="Preencher texto" v-model="fillText"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-checkbox label="Preencher suggestions" v-model="fillSuggestions"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-checkbox label="Preencher imagem" v-model="fillImage"></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-divider />
                                <v-row>
                                    <v-col v-if="['text', 'card'].indexOf(type) > -1" cols="4">
                                        <v-checkbox label="Respostas rapidas" v-model="fastResponses"></v-checkbox>
                                    </v-col>
                                    <v-col v-if="['text', 'card'].indexOf(type) > -1" cols="4">
                                        <v-checkbox label="Botoes" v-model="buttons"></v-checkbox>
                                    </v-col>
                                    <v-col v-if="['card'].indexOf(type) > -1" cols="4">
                                        <v-checkbox label="Imagem" v-model="image"></v-checkbox>
                                    </v-col>
                                    <v-col v-if="type === 'image' || (type === 'card' && image)" cols="4">
                                        <v-checkbox label="Imagem variavel" v-model="imageVariable"></v-checkbox>
                                    </v-col>
                                    <v-col v-if="['card'].indexOf(type) > -1" cols="4">
                                        <v-checkbox label="Texto" v-model="text"></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-radio-group v-model="type">
                                            <v-radio label="Mensagem" value="text"></v-radio>
                                            <v-radio label="Imagem" value="image"></v-radio>
                                            <v-radio label="Card" value="card"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="5">
                            <v-card class="pa-5 bg-background" elevation="0">
                                <config-preview
                                    v-model="content"
                                    :type="type"
                                    :fast-responses="fastResponses"
                                    :buttons="buttons"
                                    :image="image"
                                    :image-variable="imageVariable"
                                    :text="text"
                                />
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};