import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "close-circle-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom xmlns="http://www.w3.org/2000/svg" width="29.152" height="29.152" viewBox="0 0 29.152 29.152">
                <g id="Group_2603" data-name="Group 2603" transform="translate(0.75 0.75)">
                    <circle id="Ellipse_4" data-name="Ellipse 4" cx="13.826" cy="13.826" r="13.826" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <path id="Path_541" data-name="Path 541" d="M32.643,44.271,43.387,33.5" transform="translate(-24.347 -25.205)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                    <path id="Path_542" data-name="Path 542" d="M43.387,44.271,32.643,33.5" transform="translate(-24.347 -25.205)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                </g>
            </svg-custom>
        `
    )
};