export default {
    template: (
        /*html*/
        `
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.05407 9.5C6.95472 9.5 5.5 11.425 5.5 13.4708V46.5292C5.5 48.575 6.95472 50.5 9.05407 50.5H50.9459C53.0453 50.5 54.5 48.575 54.5 46.5292V13.4708C54.5 11.425 53.0453 9.5 50.9459 9.5H9.05407ZM9.05407 11.5C8.33227 11.5 7.5 12.2352 7.5 13.4708L7.50596 46.7083C7.58182 47.8355 8.36787 48.5 9.05407 48.5H50.9459C51.6677 48.5 52.5 47.7648 52.5 46.5292V13.4708C52.5 12.2352 51.6677 11.5 50.9459 11.5H9.05407Z" fill="currentColor"/>
                <path d="M24.6961 25.0392L23.6569 24L21.3481 26.3088L19.0393 24.0001L18.0001 25.0393L20.3088 27.348L18 29.6569L19.0392 30.6961L21.3481 28.3873L23.657 30.6962L24.6962 29.6569L22.3873 27.348L24.6961 25.0392Z" fill="currentColor"/>
                <path d="M40.6961 25.0392L39.6569 24L37.3481 26.3088L35.0393 24.0001L34.0001 25.0393L36.3088 27.348L34 29.6569L35.0392 30.6961L37.3481 28.3873L39.657 30.6962L40.6962 29.6569L38.3873 27.348L40.6961 25.0392Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30 34.75C27.9473 34.75 26.1683 35.928 25.3047 37.6482L23.9642 36.9753C25.0724 34.7677 27.3583 33.25 30 33.25C32.6417 33.25 34.9276 34.7677 36.0358 36.9753L34.6953 37.6482C33.8317 35.928 32.0527 34.75 30 34.75Z" fill="currentColor"/>
            </svg>               
        `
    )
};