import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente basico de textarea para utilizacao no painel
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName BaseTextarea
 * @component
 * @vue
 * @category Atomos
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Texto a ser exibido como placeholder
         */
        label: {
            type: String,
            required: true
        },
        /**
         * Indica que o campo eh obrigatório
         */
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Regras de validação
         */
        rules: {
            type: Array,
            required: false,
            default: function () {
                return [];
            }
        },
        /**
         * Valor a ser preenchido
         */
        value: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Utilizar icone de validação no título
         */
        useIcon: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Título do campo
         */
        title: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Hint do campo
         */
        hint: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Conteúdo do popover de dica do campo
         */
        popoverContent: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Habilita o contador de caracteres com limite sendo a quantidade informada
         */
        counter: {
            type: [Number, String],
            required: false,
            default: null
        }
    },
    data() {
        return {
            content: this.value,
            icon: null,
            show: false,
            hide_hint: false,
            field_rules: this.rules
        };
    },
    watch: {
        content() {
            /**
             * Evento de input, disparado sempre que o conteúdo do campo for alterado
             *
             * @property {string} content Conteúdo do campo
             *
             * @event input
             * @vue
             */
            this.$emit("input", this.content);
        },
        value() {
            this.content = this.value;
        },
        counter() {
            this.makeRuleCounter();
        },
        rules() {
            this.field_rules = this.rules;
            this.makeRuleCounter();
        }
    },
    mounted() {
        this.makeRuleCounter();
    },
    methods: {
        /**
         * Monta a regra de validação para o campo em caso de ter um contador de caracteres
         * @vue
         */
        makeRuleCounter() {
            if (this.counter > 0) {
                this.field_rules = [
                    ...this.rules,
                    ...[
                        (v) => !v || v.length <= this.counter || "O texto excedeu o limite de caracteres"
                    ]
                ];
            }
        },
        /**
         * Emite um evento informando que a tecla enter foi pressionada no campo
         * @vue
         */
        enterPressed() {
            /**
             * Evento que informa que a tecla enter foi pressioanda enquanto o campo estava em foco
             *
             * @property {string} content Conteúdo do campo
             *
             * @event enterPressed
             * @vue
             */
            this.$emit("enterPressed", this.content);
        },
        /**
         * Função para setar o icone de validação do input
         * @vue
         */
        setIcon() {
            this.icon = this.$refs.error.validate() ? " text-success fa-check" : " text-danger fa-exclamation";
        },
        /**
         * Função para modificar o estado do popover
         * @vue
         */
        togglePopover() {
            this.show = !this.show;
        },
        /**
         * Função para desabilitar popover
         * @vue
         */
        closePopover() {
            this.show = false;
        },
        /**
         * Verifica se o campo possui um erro e esconde a hint
         * @param {Boolean} has_error Indica se existe erro no campo ou não
         * @vue
         */
        updateError(has_error) {
            this.hide_hint = has_error;
        },
        htmlEntities(value) {
            return window.Vue.htmlEntities(value);
        }
    },

    template: (
        /* html */
        `
            <div class="p-0 m-0">
                <v-row v-if="title" no-gutters>
                    <v-col>
                        <span class="body-2 text-gray-700" v-html="title"></span><i v-if="useIcon" class="fas " :class="icon"></i>
                    </v-col>
                </v-row>
                <v-row class="mt-2" no-gutters>
                    <v-col>
                        <v-textarea
                            color="gray-900"
                            class="mb-n3"
                            ref="error"
                            @input="setIcon"
                            v-model="content"
                            :rules="field_rules"
                            :label="htmlEntities(label)"
                            :required="required"
                            solo 
                            hide-details="auto"
                            :counter="counter"
                            auto-grow
                            @update:error="updateError"
                        >
                            <template v-slot:message="{ message }">
                                <v-row no-gutters justify="start" class="flex-nowrap">
                                    <v-col cols="auto">
                                        <v-icon x-small left color="error-500">mdi-alert</v-icon>
                                    </v-col>
                                    <v-col class="text-error-500 text-start caption">
                                        {{ message }}
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:counter="{ props }">
                                <v-row no-gutters class="mt-2" justify="end">
                                    <v-col cols="auto">
                                        <v-counter v-bind="props" :value="content ? content.length : 0"></v-counter>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row v-if="!hide_hint && (popoverContent || hint)" no-gutters class="text-left" :class="counter ? 'mt-n6' : 'mt-2'">
                    <v-col>
                        <base-tooltip :show="show" :text="popoverContent">
                            <span
                                class="font-size-12"
                                :class="popoverContent && popoverContent.length > 0 ? 'text-decoration-underline primary--text' : 'dark-grey--text'"
                                v-html="hint"
                                :aria-controls="popoverContent && popoverContent.length > 0"
                                v-click-outside="closePopover"
                                @click="togglePopover"
                            ></span>
                        </base-tooltip>
                    </v-col>
                </v-row>
            </div> 
        `
    )
};
