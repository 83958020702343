import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente basico de autocomplete do painel
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName BaseAutocomplete
 * @component
 * @vue
 * @category Atomos
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Array de empresas para listagem no select do componente
         */
        items: {
            type: Array,
            required: true
        },
        /**
         * label do select
         */
        label: {
            type: String,
            required: true
        },
        /**
         * Define o status de loading
         */
        loading: {
            type: [Boolean, String],
            required: false,
            default: false
        },
        /**
         * Define a obrigatoriedade de preenchimento do campo
         * @values true, false
         */
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define se o campo deve estar desabilitado
         */
        disable: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define as regras a serem utilizadas para validacao dos dados inseridos
         */
        rules: {
            type: Array,
            required: false,
            default: function () {
                return [
                    v => !v || (v.length > 0 || (Number.isInteger(v) && v > 0) ) || "Selecione um item"
                ];
            }
        },
        /**
         * Define um valor default para o campo
         */
        value: {
            type: [String, Object, Array, Number],
            required: false,
            default: null
        },
        /**
         * verificar se usa o icone de validacao 
         */
        useIconValidation: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Title do select
         */
        title: {
            type: String,
            required: false,
            default: null
        },
        /**
         * hint do select
         */
        hint: {
            type: String,
            required: false,
            default: null
        },
        /**
         * popoverContent do select
         */
        popoverContent: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Define se o select pode ser multiselect ou nao
         */
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define que o select vai retornar um objeto
         */
        returnObject: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Texto do tooltip do campo
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Cor do tooltip
         */
        colorTooltip: {
            type: String,
            required: false,
            default: "gray-700"
        },
        /**
         * Define a mensagem de erro caso nenhum dado seja encontrado
         */
        noData: {
            type: String,
            required: false,
            default: "Nenhum dado dispon&iacute;vel"
        },
        /**
         * Adiciona name ao input
         */
        name: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Retorna de descri��o do campo
         */
        returnDescription: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Possibilidade de alterar a descri��o
         */
        customDescription: {
            type: String,
            required: false,
            default: "itens"
        }
    },
    data() {
        return {
            content: this.value,
            icon: null,
            show: false,
            label_hover: false
        };
    },
    computed: {
        noData_text() {
            return window.Vue.htmlEntities(this.noData);
        },
        show_tooltip() {
            return this.label_hover && this.tooltipText !== null && this.tooltipText !== "";
        },
        labelHtml() {
            return window.Vue.htmlEntities(this.label);
        },
        titleHtml() {
            return window.Vue.htmlEntities(this.title);
        }
    },
    watch: {
        content() {
            /**
             * Evento de input, disparado sempre que o conteudo do campo for alterado
             * 
             * @property {Object} content Conteudo do campo
             * 
             * @event input
             * @vue
             */
            this.$emit("input", this.content);

            if ((this.rules.length === 0 && this.content.length === 0) || !this.content) {
                this.setIcon(true);
            } else {
                this.setIcon();
            }
        },
        value() {
            this.content = this.value;
        }
    },
    methods: {
        /**
         * Emite um evento informando que a tecla enter foi pressionada no campo
         * @vue
         */
        enterPressed() {
            /**
             * Evento que informa que a tecla enter foi pressioanda enquanto o campo estava em foco
             * 
             * @property {Object} content Conteudo do campo
             * 
             * @category Atomos
             * @event enterPressed
             * @vue
            */
            this.$emit("enterPressed", this.content);
        },
        /**
         * Funcao para setar o icone de validacao do input
         * @vue
         */
        setIcon(clear) {
            if (clear) {
                this.icon = "";
            } else if (this.$refs.error.validate()) {
                this.icon = " text-success fa-check";
            } else {
                this.icon = " text-danger fa-exclamation";
            }
        },
        /**
         * Funcao para modificar o estado do popover
         * @vue
         */
        togglePopover() {
            this.show = !this.show;
        },
        /**
         * Funcao para desabilitar popover
         * @vue
         */
        closePopover() {
            this.show = false;
        },
        /**
         * Selecionar todos os itens
         * @vue
         */
        selectAll() {
            if (this.returnObject) {
                this.content = this.items;
            } else {
                this.content = this.items.map(x => x.value);
            }
        },
        /**
         * Desmarcar todos os itens
         * @vue
         */
        unselectAll() {
            this.content = [];
        },
        /**
         * Retorna descri��o label do input
         * @param {Object} item
         * @returns {String}
         * @vue
         */
        setDescription(item) {
            let description = this.content.length == this.items.length ? "Todos" : (this.content.length > 1 ? (this.content.length + " " + this.customDescription + " selecionados") : item.text);

            // Verifica se precisa emitir a descricao
            if (this.returnDescription) {
                this.$emit("setDescription", description);
            }

            return description;
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters>
                <v-col cols="12">
                    <base-tooltip
                        :color="colorTooltip"
                        :show="show_tooltip"
                        :disabled="!tooltipText"
                        :text="tooltipText"
                    >
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-hover v-model="label_hover" :open-delay="500">
                                    <v-row v-if="title" no-gutters>
                                        <v-col>
                                            <span class="font-size-12">{{ titleHtml }} <i v-if="useIconValidation" :class="'fas ' + icon" /></span>
                                        </v-col>
                                    </v-row>
                                </v-hover>
                                <v-row no-gutters>
                                    <v-col cols="12" class="pt-1">
                                        <v-autocomplete
                                            ref="error"
                                            v-model="content"
                                            :items="items"
                                            :loading="loading"
                                            :disabled="loading || disable ? true : false"
                                            class="mb-n3"
                                            :rules="rules"
                                            :label="labelHtml"
                                            :required="required"
                                            solo
                                            color="dark-primary"
                                            item-color="dark-primary"
                                            hide-details="auto"
                                            :multiple="multiple"
                                            :return-object="returnObject"
                                            :no-data-text="noData_text"
                                            :name="name"
                                            @keydown.enter.prevent="enterPressed"
                                        >
                                            <template #message="{ message }">
                                                <v-row no-gutters justify="start" class="flex-nowrap">
                                                    <v-col cols="auto">
                                                        <v-icon x-small left color="error-500">
                                                            mdi-alert
                                                        </v-icon>
                                                    </v-col>
                                                    <v-col class="text-error-500 text-start caption">
                                                        {{ message }}
                                                    </v-col>
                                                </v-row>
                                            </template>
                                            <template #selection="{ item, index }">
                                                <slot
                                                    v-if="$scopedSlots.content_slot"
                                                    name="content_slot"
                                                    :item="item"
                                                    :index="index"
                                                />
                                                <span v-else-if="index === 0">{{ setDescription(item) }}</span>
                                            </template>
                                            <template v-if="multiple && items.length > 0" #prepend-item>
                                                <v-row no-gutters justify="center" class="mx-2">
                                                    <v-col
                                                        cols="6"
                                                        class="text-center bg-background bg-extra-light-grey-hover rounded-l-lg px-3 py-1 cursor-pointer-hover"
                                                        @click="selectAll"
                                                    >
                                                        <span>Selecionar<br>Todos</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="6"
                                                        class="text-center bg-background bg-extra-light-grey-hover rounded-r-lg px-3 py-1 cursor-pointer-hover"
                                                        @click="unselectAll"
                                                    >
                                                        <span>Desmarcar<br>Todos</span>
                                                    </v-col>
                                                </v-row>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-row v-if="hint && !popoverContent" no-gutters class="text-center">
                                    <v-col>
                                        <span v-html="hint" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </base-tooltip>
                    <v-row v-if="popoverContent && hint" no-gutters class="text-end">
                        <v-col>
                            <base-tooltip :show="show" :text="popoverContent">
                                <a
                                    v-click-outside="closePopover"
                                    class="body-2 text-primary-blue-500"
                                    href="#"
                                    @click="togglePopover"
                                    v-html="hint"
                                />
                            </base-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};