export default {
    name: "clock-icon",
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26.5" height="26.5" viewBox="0 0 26.5 26.5">
                <g id="_19247103631554126437" data-name="19247103631554126437" transform="translate(0.75 0.75)">
                    <path id="Path_587" data-name="Path 587" d="M22.338,4.662a12.5,12.5,0,1,1-17.677,0A12.5,12.5,0,0,1,22.338,4.662Z" transform="translate(-1 -1)" fill="none" stroke="currentColor" stroke-width="1.5"/>
                    <path id="Path_588" data-name="Path 588" d="M32,12v9.613l4.326,4.326" transform="translate(-19.908 -8.313)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"/>
                </g>
            </svg>
        `
    )
};