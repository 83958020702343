import PreviewCarousel from "./../../../components/moleculas/PreviewCarousel";

export default ({
    components: { PreviewCarousel },

    data() {
        return {
        };
    },
    methods: {
        mountCard(template, params) {
            let ds_template;
            if (params) {
                ds_template = JSON.parse(this.template_utils.preencherCamposTemplate(template, JSON.parse(params)));
            } else {
                ds_template = JSON.parse(template);
            }
            let contents = ds_template.contents !== undefined ? ds_template.contents : ds_template.content !== undefined ? ds_template.content : null;
            let list_fast_response = null;
            let list_suggestions = null;

            if (contents.suggestions && contents.suggestions !== undefined && contents.suggestions.length > 0) {
                let { fast_response, suggestions } = this.mountSuggestions(contents.suggestions);
                list_fast_response = fast_response;
                list_suggestions = suggestions;
            }

            let list_messages = this.mountMessageOrTitleList(contents.description ?? "");
            let list_title = this.mountMessageOrTitleList(contents.title ?? "");

            let image = contents.media?.file?.url ?? "";
            image = image === "{url}" ? "variable" : image;
            let card = {};

            card.image = image;
            card.title = list_title ?? "";
            card.description = list_messages ?? "";
            card.suggestionButtons = list_suggestions;
            card.suggestionResponses = list_fast_response;
            card.orientation = ds_template.orientation ?? "";
            card.alignment = ds_template.alignment ?? "";
            card.image_height = contents.media?.height ?? "";

            return card;
        },
    },
    template: (
    /*html*/
        `
      <v-container>
         <v-row>
            <v-col cols="12">
                <preview-carousel 
                    :list-card="{"cardWidth":"MEDIUM","contents":[{"description":"Olá {nome} , temos uma proposta imperdível para você regularizar o seu débito. Acesse agora a oferta que preparamos para você. ","media":{"height":"MEDIUM","file":{"url":"https://www.christuskirche-stadeln.de/sites/christuskirche-stadeln.de/files/styles/max_1300x1300/public/media/img/mutter-kind.jpg?itok=5vIAnWyP"}},"suggestions":[{"type":"url","label":"BOLETO","value":"{value_url_suggestion}"}]},{"description":"Duvidas? Fale com nosso Assistente Virtual e obtenha mais informações.","media":{"height":"MEDIUM","file":{"url":"https://coolwallpapers.me/picsup/3002841-affair_communication_device_girl_lady_mobile_mobile-phone_phone_smartphone_technology_texting_touch_touchscreen_woman.jpg"}},"suggestions":[{"type":"url","label":"CHAT","value":"https://kami.com.ai/chat/"}]},{"description":"Se preferir, fale agora com nosso time de especialistas.","media":{"height":"MEDIUM","file":{"url":"https://media04.meinbezirk.at/article/2021/02/23/0/25441640_XXL.jpg"}},"suggestions":[{"type":"dial_phone","label":"LIGAR","value":"5541996311539"}]}]}"
                    :fields-template="message.params"
                    :select-options="message.params"/>
            </v-col>
        </v-row>
      </v-container>
    `
    )
});
