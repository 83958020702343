import DialogClickDetails from "../../sessoes_rcs/components/DialogClickDetails";
import BaseVisualCard from "../../../components/atomos/BaseVisualCard";

export default {
    components: {
        DialogClickDetails,
        BaseVisualCard
    },
    data() {
        return {
            link: 10,
            phone: 14,
            map: 15,
            request_location: 16,
            show_dialog_session: false
        };
    },
    template: /*html*/ `
        <v-container class="pt-5 fill-height">
            <v-row justify="center">
                <v-col cols="12" md="8" lg="6">
                    <v-card class="d-flex justify-center">
                        <v-btn @click="show_dialog_session = true" color="dark-primary white--text" class="my-10">Abrir modal de detalhes</v-btn>
                    </v-card>
                </v-col>
                <dialog-click-details v-model="show_dialog_session" :link="link" :phone="phone" :map="map" :request-location="request_location"></dialog-click-details>
            </v-row>
        </v-container>
    `
};