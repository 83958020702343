import ProductButtonUsage from "./ProductButtonUsage";
import BaseButtonUsage from "./BaseButtonUsage";
import BaseBoxFormUsage from "./BaseBoxFormUsage";
import AreaActionUsage from "./AreaActionUsage";
import CanalButtonUsage from "./CanalButtonUsage";
import BaseCardShortUsage from "./BaseCardShortUsage";
import BaseCardLargeUsage from "./BaseCardLargeUsage";
import BaseBackgroundIconUsage from "./BaseBackgroundIconUsage";
import ButtonFormStepUsage from "./ButtonFormStepUsage";
import CellPhonePreviewUsage from "./CellPhonePreviewUsage";
import BaseCardSliderUSage from "./BaseCardSliderUSage";
import BaseTextFieldUsage from "./BaseTextFieldUsage";
import BaseSectionFormUsage from "./BaseSectionFormUsage";
import BaseNotifyUsage from "./BaseNotifyUsage";
import BasePillUsage from "./BasePillUsage";
import BaseInformationUsage from "./BaseInformationUsage";
import BaseTextareaUsage from "./BaseTextareaUsage";
import BaseDatePickerUsage from "./BaseDatePickerUsage";
import BaseSpanLimitedUsage from "./BaseSpanLimitedUsage";
import BaseProgressBarUsage from "./BaseProgressBarUsage";
import BaseButtonCheckboxUsage from "./BaseButtonCheckboxUsage";
import BaseCardExtraLargeUsage from "./BaseCardExtraLargeUsage";
import BaseIconButtonUsage from "./BaseIconButtonUsage";
import PreviewImageUsage from "./PreviewImageUsage";
import PreviewCardUsage from "./PreviewCardUsage";
import ActiveFilterUsage from "./ActiveFilterUsage";
import BaseChatUsage from "./BaseChatUsage";
import BaseVisualCardUsage from "./BaseVisualCardUsage";
import ModeloIconDescriptionUsage from "./ModeloIconDescriptionUsage";
import BaseAutocompleteUsage from "./BaseAutocompleteUsage";
import PreviewTextUsage from "./PreviewTextUsage";
import FilterPageSliderUsage from "./FilterPageSliderUsage";

export default [
    {
        path: "product-button-usage",
        name: "Product button usage",
        component: ProductButtonUsage
    },
    {
        path: "base-button-usage",
        name: "Base button usage",
        component: BaseButtonUsage
    },
    {
        path: "base-box-form-usage",
        name: "Base box form usage",
        component: BaseBoxFormUsage
    },
    {
        path: "area-action-usage",
        name: "Area action usage",
        component: AreaActionUsage
    },
    {
        path: "canal-button-usage",
        name: "Canal button usage",
        component: CanalButtonUsage
    },
    {
        path: "base-card-short-usage",
        name: "Base Card Short",
        component: BaseCardShortUsage
    },
    {
        path: "base-card-large-usage",
        name: "Base Card Large",
        component: BaseCardLargeUsage
    },
    {
        path: "base-background-icon-usage",
        name: "Base background icon usage",
        component: BaseBackgroundIconUsage
    },
    {
        path: "button-form-step-usage",
        name: "button form step usage",
        component: ButtonFormStepUsage
    },
    {
        name: "CellPhone Preview",
        path: "cellphone-preview-usage",
        component: CellPhonePreviewUsage
    },
    {
        name: "Componente base para card de slider",
        path: "base-card-slider-usage",
        component: BaseCardSliderUSage
    },
    {
        name: "Componente base de input do painel",
        path: "base-text-field-usage",
        component: BaseTextFieldUsage
    },
    {
        name: "Base Section Form Usage",
        path: "base-section-form-usage",
        component: BaseSectionFormUsage
    },
    {
        name: "Base Notify",
        path: "base-notify-usage",
        component: BaseNotifyUsage
    },
    {
        name: "Base Pill",
        path: "base-pill-usage",
        component: BasePillUsage
    },
    {
        name: "Base Information Usage",
        path: "base-information-usage",
        component: BaseInformationUsage
    },
    {
        name: "Base Textarea",
        path: "base-textarea-usage",
        component: BaseTextareaUsage
    },
    {
        name: "Base Date Picker",
        path: "base-date-picker-usage",
        component: BaseDatePickerUsage
    },
    {
        name: "Botao com checkbox",
        path: "base-button-checkbox-usage",
        component: BaseButtonCheckboxUsage
    },
    {
        name: "Base card extra large",
        path: "base-card-extra-large-usage",
        component: BaseCardExtraLargeUsage
    },
    {
        name: "Base Span Limited",
        path: "base-span-limited-usage",
        component: BaseSpanLimitedUsage
    },
    {
        name: "Base Progress Bar",
        path: "base-progress-bar-usage",
        component: BaseProgressBarUsage
    },
    {
        name: "Base Button Checkbox",
        path: "base-button-checkbox-usage",
        component: BaseButtonCheckboxUsage
    },
    {
        name: "Base Icon Button",
        path: "base-icon-button-usage",
        component: BaseIconButtonUsage
    },
    {
        path: "preview-image-usage",
        name: "Preview Image",
        component: PreviewImageUsage
    },
    {
        path: "preview-card-usage",
        name: "Preview Card",
        component: PreviewCardUsage
    }, {
        path: "active-filter-usage",
        name: "Active filter",
        component: ActiveFilterUsage
    },
    {
        path: "base-chat-usage",
        name: "Base Chat",
        component: BaseChatUsage
    },
    {
        path: "base-visual-card-usage",
        name: "Base Visual Card",
        component: BaseVisualCardUsage
    },
    {
        path: "modelo-icon-description-usage",
        name: "Modelo Icon Description",
        component: ModeloIconDescriptionUsage
    },
    {
        path: "base-autocomplete-usage",
        name: "Base Autocomplete",
        component: BaseAutocompleteUsage
    },
    {
        path: "preview-text-usage",
        name: "Preview Text",
        component: PreviewTextUsage
    },
    {
        path: "filter-page-slider-usage",
        name: "Filter Page Slider",
        component: FilterPageSliderUsage
    }
];
