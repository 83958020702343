import BaseBackgroundIcon from "../../../components/atomos/BaseBackgroundIcon.js";

export default ({
    components: {
        "base-background-icon": BaseBackgroundIcon
    },
    template: (
        /*html*/
        `
            <v-row class="mt-5">
                <v-col>
                    <base-background-icon title="Icone RCS" icon-name="icon-rcs" background-color="rcs"></base-background-icon>
                </v-col>
                <v-col>
                    <base-background-icon title="SMS" sub-title="Isso eh um icone" icon-name="icon-sms" background-color="sms"></base-background-icon>
                </v-col>
                <v-col>
                    <base-background-icon icon-name="fa-list" background-color="super-light-grey" icon-color="dark-green"></base-background-icon>
                </v-col>
                <v-col>
                    <base-background-icon icon-name="fa-list" background-color="dark-cyan" :circle="false"></base-background-icon>
                </v-col>
                <v-col>
                    <base-background-icon icon-name="fa-list" background-color="dark-orange" :size="30"></base-background-icon>
                </v-col>
                <v-col>
                    <base-background-icon icon-name="fa-list" background-color="dark-purple" :size="100"></base-background-icon>
                </v-col>
            </v-row>
        `
    )
});
