import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";
/**
 * Componente de mini formulario para adicionar respostas rapidas no template 
 * @requires {@link BaseTextField}
 * @requires {@link BaseButton}
 * @component
 * @displayName FormRespostasRapidas
 * @category Page / Template RCS
 * @subcategory Forms
 * @vue
 * @author Pedro Augusto <pedro.ribas@pgmais.com.br>
 */
export default {
    components: {
        BaseTextField,
        BaseButton
    },
    props: {
        /**
         * Dados a serem preenchidos no formulario
         */
        dataForm: {
            type: Object,
            required: false,
            default: null
        },
        /**
         * Lista de itens do tipo draggable para valida��o das regras
         */
        listValidation: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            new_resposta: this.dataForm && this.dataForm.new_resposta ? this.dataForm.new_resposta : null,
            new_resposta_rules: [
                v => !!v || window.Vue.htmlEntities("Voc&ecirc; precisa dar um nome para a resposta"),
                v => !v || v.length <= 25 || window.Vue.htmlEntities("A resposta n&atilde;o pode ter mais de 25 caracteres"),
                v => {
                    let valid = (typeof this.listValidation.find(element => element.text === v && element.id !== (this.dataForm ? this.dataForm.id : null)) == "undefined");
                    return !!valid || window.Vue.htmlEntities("J&aacute; existe uma resposta igual a essa") ;
                }
            ],
            disable_button: true
        };
    },
    computed: {
        isEmpty() {
            return !this.dataForm || this.dataForm.id === undefined || this.dataForm.id == "";
        },
        showUnsetButtons() {
            return this.new_resposta && this.new_resposta != "";
        },
        showCancelButton() {
            return this.dataForm && this.dataForm.id !== undefined && this.dataForm.id != "" && !this.disable_button;
        }
    },
    watch: {
        new_resposta() {
            this.disable_button = !this.$refs.form_resposta_add.validate();
        },
        dataForm() {
            if (this.dataForm && this.dataForm.new_resposta) {
                this.new_resposta = this.dataForm.new_resposta;
            }
        }
    },
    methods: {
        /**
         * Emite um evento informando que um botao foi clicado
         * @vue
         */
        formSubmit: function () {
            if (this.$refs.form_resposta_add.validate()) {
                let formData = {
                    id: this.dataForm && this.dataForm.id ? this.dataForm.id : null,
                    new_resposta: this.new_resposta
                };
                this.$refs.form_resposta_add.reset();

                /**
                 * Evento formSubmit do botao
                 * @property {object} formData Dados do campo {new_resposta}
                 * @event formSubmit
                 * @vue 
                 */
                this.$emit("formSubmit", formData);
            }
        },
        /**
         * Realiza a limpeza das informacoes no formulario
         * @vue
         */
        clearContent(delete_field = false) {
            this.$refs.form_resposta_add.reset();

            /**
             * Informa que a edicao foi cancelada
             * @event cancelEdit
             * @vue
             */
            this.$emit("cancelEdit");

            if (delete_field) {
                /**
                 * Indica que um campo personalizado deve ser removido
                 * @property {Object} dataForm Dados do campo a ser removido {id, name_field, number_characters}
                 * @event deleteItem
                 * @vue
                 */
                this.$emit("deleteItem", this.dataForm);
            }
        }
    },
    template: (
        /*html*/
        `
        <v-form ref="form_resposta_add">
            <v-row no-gutters>
                <v-col>
                    <base-text-field
                        v-model="new_resposta"
                        title="Escreva a resposta"
                        label="Resposta"
                        :required="true"
                        :rules="new_resposta_rules"
                        :counter="25"
                        :max-length="25"
                        tooltip-text="Crie respostas r&aacute;pidas espec&iacute;ficas para o objetivo da sua mensagem"
                        @enterPressed="formSubmit"
                        @focus="$emit('focus')"
                        @blur="$emit('blur')"
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col cols="12">
                    <base-button
                        primary
                        block
                        :label="isEmpty ? 'Adicionar resposta' : 'Alterar resposta'"
                        :icon="isEmpty ? 'mdi-plus-thick' : 'mdi-refresh'"
                        :disabled="disable_button"
                        @click="formSubmit"
                    ></base-button>  
                </v-col>
            </v-row>
            <v-row v-if="showUnsetButtons" no-gutters class="mt-6" :justify="showCancelButton ? 'space-around' : 'center'">
                <v-col cols="auto">
                    <base-button
                        tertiary
                        danger
                        :label="isEmpty ? 'Limpar' : 'Excluir resposta'"
                        icon="mdi-trash-can"
                        width="auto"
                        @click="clearContent(showCancelButton)"
                    ></base-button>  
                </v-col>
                <v-col v-if="showCancelButton" cols="auto">
                    <base-button
                        tertiary
                        danger
                        :label="'Cancelar edi&ccedil;&atilde;o' | htmlEntities"
                        icon="mdi-close-thick"
                        width="auto"
                        @click="clearContent"
                    ></base-button>  
                </v-col>
            </v-row>
        </v-form>
        `
    )
};