import BaseBackgroundIcon from "../atomos/BaseBackgroundIcon?v=2";

/**
 * Componente de icone com descricao do canal de envio
 *
 * @displayName CanalIconDescription
 * @component
 * @category Moleculas
 * @requires {@link BaseBackgroundIcon}
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseBackgroundIcon
    },
    props: {
        /**
         * Nome do produto utilizado para envio (rcs, whatsapp, sms-flash, flash, sms-concatenado, sms, hig, sms-smart, smg, sms-interativo)
         */
        productName: {
            type: String,
            required: false,
            default: null,
            validator: function (data) {
                return [
                    null,
                    "rcs",
                    "whatsapp",
                    "sms-flash",
                    "flash",
                    "sms-concatenado",
                    "sms-c",
                    "sms",
                    "hig",
                    "sms-smart",
                    "smg",
                    "sms-interativo",
                    "sms-fallback"
                ].indexOf(data.toLowerCase()) > -1;
            }
        },
        /**
         * Texto a ser exibido no tooltip
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        style() {
            let type = (this.productName || "SMS");
            return window.Vue.getProductTheme(type);
        }
    },
    template:
        /*html*/
        `
            <base-background-icon 
                :title="style.name" 
                :icon-name="style.icon" 
                :background-color="style.background_color"
                :size="30"
                :tooltip="tooltipText"
            ></base-background-icon>
        `
};
