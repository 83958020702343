import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";
/**
 * Componente de mini formulario para adicionar nome e quantidade de caracteres a um campo 
 * @requires {@link BaseTextField}
 * @requires {@link BaseButton}
 * @component
 * @displayName FormAddCustomField
 * @category Page / Template RCS
 * @subcategory Forms
 * @vue
 * @author Pedro Augusto <pedro.ribas@pgmais.com.br>
 * @vue/component
 */
export default {
    name: "form-add-custom-field",
    components: {
        BaseTextField,
        BaseButton
    },
    props: {
        /**
         * lista de dados para validacao do formulario
         */
        listDataValidation: {
            type: Array,
            required: true
        },
        /**
         * Dados a serem preenchidos no formulario
         */
        dataForm: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            name_field: this.dataForm && this.dataForm.name_field ? this.dataForm.name_field : null,
            number_characters: this.dataForm && this.dataForm.number_characters ? this.dataForm.number_characters : null,
            name_field_rules: [
                value => !!value || window.Vue.htmlEntities("Voc&ecirc; precisa dar um nome para o campo"),
                (value) => {
                    let valid = (typeof this.listDataValidation.find(element => element.text === value && element.id !== (this.dataForm ? this.dataForm.id : null)) == "undefined");
                    return !!valid || window.Vue.htmlEntities("J&aacute; existe um campo com esse nome") ;
                },
                value => !(value === " ") || window.Vue.htmlEntities("Voc&ecirc; precisa dar um nome para o campo"),
            ],
            number_characters_rules: [
                value => !!value || window.Vue.htmlEntities("Voc&ecirc; precisa informar o n&uacute;mero m&aacute;ximo de caracteres"),
                value => Number(value) >= 0 || window.Vue.htmlEntities("O campo deve ser maior do que zero!"),
                value => !(Number(value) == 0) || window.Vue.htmlEntities("O campo n&atilde;o pode ser negativo!"),
            ],
            disable_button: true,
            focused: {
                name: false,
                number: false
            }
        };
    },
    computed: {
        isEmpty() {
            return !this.dataForm || this.dataForm.id === undefined || this.dataForm.id == "";
        },
        showUnsetButtons() {
            return this.name_field && this.name_field != "" && this.number_characters && this.number_characters != "";
        },
        showCancelButton() {
            return this.dataForm && this.dataForm.id !== undefined && this.dataForm.id != "" && !this.disable_button;
        }
    },
    watch: {
        name_field() {
            if (this.name_field && this.name_field !== "" && parseInt(this.number_characters)) {
                this.disable_button = false;
            } else {
                this.disable_button = true;
            }
        },
        number_characters() {
            if (this.name_field && this.name_field !== "" && parseInt(this.number_characters)) {
                this.disable_button = false;
            } else {
                this.disable_button = true;
            }
        },
        dataForm() {
            if (this.dataForm && this.dataForm.name_field && this.dataForm.number_characters) {
                this.name_field = this.dataForm.name_field;
                this.number_characters = this.dataForm.number_characters;
            }
        }
    },
    methods: {
        /**
         * Emite um evento informando que um botao foi clicado
         * @vue
         */
        formSubmit() {
            if (this.$refs.form_add_custom_fields.validate()) {
                let formData = {
                    id: this.dataForm && this.dataForm.id ? this.dataForm.id : null,
                    name_field: _.deburr(this.name_field),
                    number_characters: parseInt(this.number_characters)
                };
                this.$refs.form_add_custom_fields.reset();

                /**
                 * Evento formSubmit do botao
                 * @property {Object} formData Dados do campo {name_field, number_characters}
                 * @event formSubmit
                 * @vue 
                 */
                this.$emit("formSubmit", formData);
            }
        },
        /**
         * Realiza a limpeza das informacoes no formulario
         * @vue
         */
        clearContent(delete_field = false) {
            this.$refs.form_add_custom_fields.reset();

            /**
             * Informa que a edicao foi cancelada
             * @event cancelEdit
             * @vue
             */
            this.$emit("cancelEdit");

            if (delete_field) {
                /**
                 * Indica que um campo personalizado deve ser removido
                 * @property {Object} dataForm Dados do campo a ser removido {id, name_field, number_characters}
                 * @event deleteItem
                 * @vue
                 */
                this.$emit("deleteItem", this.dataForm);
            }
        },
        focus(field) {
            this.$emit("focus");

            this.focused[field] = true;
        },
        blur(field) {
            this.focused[field] = false;

            if (!this.focused.name && !this.focused.number) {
                this.$emit("blur");
            }
        }
    },
    template: (
        /*html*/
        `
        <v-form ref="form_add_custom_fields">
            <v-row  no-gutters >
                <v-col cols="12" lg="8">
                    <base-text-field
                        v-model="name_field"
                        title="D&ecirc; um nome para o campo"
                        label="Nome do campo"
                        :required="true"
                        :rules="name_field_rules"
                        tooltip-text="O nome do campo personalizado ser&aacute; utilizado para identificar o dado vari&aacute;vel em seu arquivo de dados no momento de criar a campanha"
                        @enterPressed="formSubmit"
                        @focus="focus('name')"
                        @blur="blur('name')"
                    ></base-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                    <base-text-field
                        v-model="number_characters"
                        type="number"
                        class="ml-lg-3"
                        title="Caracteres"
                        hint="O que &eacute; isso?"
                        tooltip-text="&Eacute; a quantidade m&aacute;xima de caracteres para ser contabilizado na mensagem"
                        popover-content="&Eacute; a quantidade m&aacute;xima de caracteres que voc&ecirc; definir&aacute; para esse campo. Esse valor ser&aacute; utilizado para a contagem de caracteres na mensagem."
                        content-class-tooltip="border-secondary border bg-of-white"
                        :required="true"
                        :rules="number_characters_rules"
                        :label="'n&ordm;' | htmlEntities"
                        @enterPressed="formSubmit"
                        @focus="focus('number')"
                        @blur="blur('number')"
                    ></base-text-field>
                </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
                <v-col cols="12">
                    <base-button
                        primary
                        block
                        :label="isEmpty ? 'Adicionar campo' : 'Alterar campo'"
                        :icon="isEmpty ? 'mdi-plus-thick' : 'mdi-refresh'"
                        :disabled="disable_button"
                        @click="formSubmit"
                    ></base-button>  
                </v-col>
            </v-row>
            <v-row v-if="showUnsetButtons" no-gutters class="mt-6" :justify="showCancelButton ? 'space-around' : 'center'">
                <v-col cols="auto">
                    <base-button
                        tertiary
                        danger
                        :label="isEmpty ? 'Limpar' : 'Excluir campo'"
                        icon="mdi-trash-can"
                        width="auto"
                        @click="clearContent(showCancelButton)"
                    ></base-button>  
                </v-col>
                <v-col v-if="showCancelButton" cols="auto">
                    <base-button
                        tertiary
                        danger
                        :label="'Cancelar edi&ccedil;&atilde;o' | htmlEntities"
                        icon="mdi-close-thick"
                        width="auto"
                        @click="clearContent"
                    ></base-button>  
                </v-col>
            </v-row>
        </v-form>
        `
    )
};