import ProductButton from "../../../components/atomos/ProductButton";

/**Cor do botao */
//  color_button:{type:String,  required:true}
export default {
    name: "ProductButtonUsage",
    components: {"product-button": ProductButton},
    data: function() {

        return {data_button_1: {product_icon: "icon-sms",
            title: "SMS",
            description: "sms eh legal",
            disable_button: false,
            color_button: "dark-primary"},
        data_button_2: {product_icon: "icon-rcs",
            title: "RCS",
            description: "O RCS &eacute; um mensageiro instant&acirc;neo, moderno e articulado especificamente para tornar a experi&ecirc;ncia de di&aacute;logo mais rica e din&acirc;mica",
            disable_button: false,
            color_button: "dark-blue"}
        };
    },
    template: (
        /*html*/
        `
            <div class="container">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col sm="12" lg="6">
                                <product-button 
                                               :product-icon="data_button_1.product_icon"
                                               :title="data_button_1.title" 
                                               :description="data_button_1.description" 
                                               :disable-button="data_button_1.disable_button" 
                                               :color-button="data_button_1.color_button" 
                                               @click="console.log('teste');"
                                               />
                                           
                            </v-col>  
                            <v-col sm="12" lg="6">
                                    <product-button 
                                    :product-icon="data_button_2.product_icon"
                                    :title="data_button_2.title" 
                                    :description="data_button_2.description" 
                                    :disable-button="data_button_2.disable_button" 
                                    :color-button="data_button_2.color_button" 
                                    @click="console.log('teste');"/>
                                           
                            </v-col> 
                                
                        </v-row>      
                    </v-col>
                </v-row>     
            </div>
        `
    )
};