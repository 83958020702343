import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    name: "simples-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom xmlns="http://www.w3.org/2000/svg" width="58" height="52" viewBox="0 0 58 52">
                <g id="simples" transform="translate(-2536 -1772)">
                    <rect id="Ret�ngulo_828" data-name="Ret�ngulo 828" width="58" height="52" transform="translate(2536 1772)"  opacity="0"/>
                    <g id="Icon" transform="translate(2475.372 1346.114)">
                    <g id="Grupo_2876" data-name="Grupo 2876" transform="translate(64.628 429.886)">
                        <path id="Caminho_1228" data-name="Caminho 1228" d="M413.969,175.131v1.73a1.021,1.021,0,1,0,2.042,0v-1.73a1.021,1.021,0,1,0-2.042,0Z" transform="translate(-398.922 -149.855)" />
                        <path id="Caminho_1229" data-name="Caminho 1229" d="M465.871,177.883a1.021,1.021,0,0,0,1.021-1.021v-1.73a1.021,1.021,0,1,0-2.042,0v1.73A1.021,1.021,0,0,0,465.871,177.883Z" transform="translate(-431.378 -149.855)" />
                        <path id="Caminho_1230" data-name="Caminho 1230" d="M378.039,137.785a20.509,20.509,0,0,0,39.121,0,6.19,6.19,0,0,0,.137-12.315c-.045-.005-.094-.006-.14-.01a20.666,20.666,0,0,0-6.938-10,1.021,1.021,0,1,0-1.258,1.609,18.6,18.6,0,0,1,6.093,8.573,6.166,6.166,0,0,0-2.6,1.385,1.021,1.021,0,1,0,1.367,1.517,4.149,4.149,0,0,1,2.61-1.056h.012a4.254,4.254,0,0,1,.632.017,4.15,4.15,0,0,1-.477,8.273c-.018,0-.035,0-.053.005a1.019,1.019,0,0,0-1.136.735,18.469,18.469,0,0,1-35.614.011,1.019,1.019,0,0,0-1.092-.742.9.9,0,0,0-.095-.01,4.15,4.15,0,0,1-.477-8.273,4.305,4.305,0,0,1,.637-.017h0a4.153,4.153,0,0,1,2.618,1.057,1.021,1.021,0,0,0,1.367-1.517,6.165,6.165,0,0,0-2.62-1.389,18.52,18.52,0,0,1,11.5-11.485,5.135,5.135,0,0,0,5.053,4.271,3.783,3.783,0,0,0,4-4,3.656,3.656,0,0,0-3.651-3.651,1.021,1.021,0,1,0,0,2.042,1.611,1.611,0,0,1,1.609,1.609,1.762,1.762,0,0,1-1.953,1.953,3.091,3.091,0,0,1-3.088-3.088c0-.136.008-.27.021-.4h0a4.1,4.1,0,0,1,4.1-3.712,5.406,5.406,0,0,1,5.4,5.4,7.013,7.013,0,0,1-7.005,7.005,1.021,1.021,0,1,0,0,2.042,9.058,9.058,0,0,0,9.047-9.048,7.45,7.45,0,0,0-7.442-7.442,6.127,6.127,0,0,0-6.017,4.862A20.547,20.547,0,0,0,378.05,125.46c-.047,0-.1,0-.143.01a6.19,6.19,0,0,0,.133,12.315Z" transform="translate(-372.416 -107.131)" />
                        <path id="Caminho_1231" data-name="Caminho 1231" d="M435.5,195.586a5.056,5.056,0,0,1-8.363,0,1.021,1.021,0,0,0-1.687,1.151,7.1,7.1,0,0,0,11.737,0,1.021,1.021,0,1,0-1.687-1.15Z" transform="translate(-406.131 -163.27)" />
                    </g>
                    </g>
                </g>
            </svg-custom>
        `
    )
};