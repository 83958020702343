import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";
import FilterPageSlider from "../../../../components/atomos/FilterPageSlider?v=2";
import BaseAutocomplete from "../../../../components/atomos/BaseAutocomplete?v=2";
import BaseDatePicker from "../../../../components/atomos/BaseDatePicker";
import BaseSelect from "../../../../components/atomos/BaseSelect?v=2";

/**
 * Componente de formulario de pesquisa de campanha
 *
 * @requires {@link BaseTextField}
 * @requires {@link BaseButton}
 * @requires {@link FilterPageSlider}
 * @requires {@link BaseAutocomplete}
 * @requires {@link BaseDatePicker}
 *
 * @displayName FormFilterGerenciar
 * @category Page / Gerenciar
 * @subcategory forms
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseButton,
        FilterPageSlider,
        BaseAutocomplete,
        BaseDatePicker,
        BaseSelect
    },
    data() {
        return {
            form_data: {
                status_campanha: null,
                dataInicio: null,
                dataFim: null,
                id_empresa: null,
                id_campanha: null,
                nome_campanha: null
            },
            clients_loading: false,
            client_list: [],
            pills_filter: [],
            situacao: [
                {
                    value: "T",
                    text: "Todas..."
                },
                {
                    value: "F",
                    text: "Finalizada"
                },
                {
                    value: "C",
                    text: "Cancelada"
                }
            ],
            show_filter: false,
            disable_data_input: false
        };
    },
    computed: {
        showReset() {
            let has_filter = false;

            for (let key of Object.keys(this.form_data)) {
                if (this.form_data[key] && this.form_data[key].length > 0) {
                    has_filter = true;
                }
            }

            return has_filter && !this.isDefaultFilter();
        }
    },
    watch: {
        /*
         * Adiciona o valor do campo Situa��o no objeto form_data.status_campanha
         */
        situacao(ev) {
            this.form_data.status_campanha = ev;
        },
        /*
         * Atualiza o valor de disable_data_input com base na presen�a de um ID de campanha.
         * Se um ID de campanha estiver presente, disable_data_input ser� verdadeiro, o que ser�
         * usado para desabilitar e zerar os campos 'in�cio' e 'fim' no formul�rio.
         */
        form_data: {
            handler(form) {
                this.disable_data_input = Boolean(form.id_campanha);
            },
            deep: true
        }
    },
    beforeMount() {
        this.disable_data_input = Boolean(this.form_data.id_campanha);
    },
    mounted() {
        this.getClients();
        this.makePillsFilter();
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetMyClients"]),
        /**
         * Submit dor formulario, atualiza a lista de dados de acordo com o filtro
         * @vue
         */
        formSubmit() {
            if (this.$refs.form.validate()) {
                /**
                 * Emite o conteudo do formulario
                 * @property {Object} form_data Conteudo emitido
                 */
                this.$emit("formSubmit", this.form_data);
                this.makePillsFilter();
            }
        },
        /**
         * Monta os dados default do filtro
         * @vue
         */
        makeDefaultFilter() {
            this.form_data.id_empresa = this.client_list.map((e) => {
                return e.value;
            });
            this.form_data.status_campanha = "T";
            this.form_data.dataInicio = dayjs().format("YYYY-MM-01");
            this.form_data.dataFim = dayjs().format("YYYY-MM-DD");

            this.formSubmit();
        },
        /**
         * Busca na API e monta a lista de clientes (carteiras) que a empresa definida na sessao possui
         * @vue
         */
        getClients() {
            this.clients_loading = true;
            this.ActionDoGetMyClients(true).then((response) => {
                this.client_list = response;
                this.clients_loading = false;

                this.makeDefaultFilter();
            });
        },
        /**
         * Monta a configuracao das pills do filtro
         * @vue
         */
        makePillsFilter() {
            let config = [];

            if (this.form_data.dataInicio !== null && this.form_data.dataInicio.length > 0) {
                config.push({
                    text: "Inicio",
                    subText: dayjs(this.form_data.dataInicio).format("DD/MM/YYYY"),
                    removable: false,
                    click: () => {
                        this.form_data.dataInicio = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.dataFim !== null && this.form_data.dataFim.length > 0) {
                config.push({
                    text: "Fim",
                    subText: dayjs(this.form_data.dataFim).format("DD/MM/YYYY"),
                    removable: false,
                    click: () => {
                        this.form_data.dataFim = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.id_campanha !== null && this.form_data.id_campanha.length > 0) {
                config.push({
                    text: "Identificador (Id)",
                    subText: this.form_data.id_campanha,
                    click: () => {
                        this.form_data.id_campanha = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.status_campanha !== null && this.form_data.status_campanha.length > 0) {
                let status = this.situacao.find((item) => item.value === this.form_data.status_campanha);
                config.push({
                    text: "Status",
                    subText: status.text,
                    click: () => {
                        this.form_data.status_campanha = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.id_empresa !== null && this.form_data.id_empresa.length > 0) {
                config.push({
                    text: "Cliente",
                    subText: this.form_data.id_empresa.length + " selecionados",
                    click: () => {
                        this.form_data.id_empresa = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.nome_campanha !== null && this.form_data.nome_campanha.length > 0) {
                config.push({
                    text: "Campanha",
                    subText: this.form_data.nome_campanha,
                    click: () => {
                        this.form_data.nome_campanha = null;
                        this.formSubmit();
                    }
                });
            }

            this.pills_filter = config;
        },
        /**
         * Funcao usada para limpar os inputs do formulario de busca
         * @vue
         */
        limparFiltro() {
            this.form_data = {
                status_campanha: null,
                dataInicio: null,
                dataFim: null,
                id_empresa: null,
                id_campanha: null,
                nome_campanha: null
            };

            this.makeDefaultFilter();
        },
        /**
         * Verifica se o filtro inserido eh o filtro default da pagina
         * @vue
         */
        isDefaultFilter() {
            let is_default = false;

            let default_clients = this.client_list.map((e) => {
                return e.value;
            });
            let default_start_date = dayjs().format("YYYY-MM-01");
            let default_end_date = dayjs().format("YYYY-MM-DD");

            if (this.form_data.dataInicio === default_start_date
                && this.form_data.dataFim === default_end_date
                && (this.form_data.id_campanha === null || this.form_data.id_campanha === "")
                && this.form_data.status_campanha === "T"
                && (this.form_data.id_empresa && JSON.stringify(Array.from(this.form_data.id_empresa)) === JSON.stringify(default_clients))
                && (this.form_data.nome_campanha === null || this.form_data.nome_campanha === "")
            ) {
                is_default = true;
            }

            return is_default;
        }
    },
    template:
        /*html*/
        `
        <filter-page-slider
            v-model="show_filter"
            :pills-filter="pills_filter"
            :show-reset="showReset"
            :data-v-step="1"
            @reset="limparFiltro();"
        >
            <v-form ref="form">
                <v-row class="mt-0" justify="center" justify-md="end">
                    <v-col cols="6" md="3">
                        <base-date-picker
                            v-model="form_data.dataInicio"
                            tooltip-text="Escolha uma <b>data inicial</b> para filtrar."
                            label="Selecione..."
                            title="In&iacute;cio"
                            use-icon-validation
                            clearable
                            :rules="[]"
                            :inicio="true"
                            :max-date="form_data.dataFim"
                            :disabled="disable_data_input"
                        />
                    </v-col>
                    <v-col cols="6" md="3">
                        <base-date-picker
                            v-model="form_data.dataFim"
                            tooltip-text="Escolha uma <b>data fim</b> para filtrar."
                            label="Selecione..."
                            title="Fim"
                            use-icon-validation
                            clearable
                            name="dataFim"
                            :rules="[]"
                            :fim="true"
                            :min-date="form_data.dataInicio"
                            :disabled="disable_data_input"
                        />
                    </v-col>
                    <v-col cols="6" md="3" class="mt-4 mt-sm-0">
                        <base-text-field
                            v-model="form_data.id_campanha"
                            tooltip-text="Voc&ecirc; pode filtrar pelo n&uacute;mero <b>identificador</b> (id ) da campanha."
                            label="Digite..."
                            title="Identificador (Id)"
                            name="id_campanha"
                            use-icon-validation
                            :type="'number'"
                            :rules="[]"
                        />
                    </v-col>
                    <v-col cols="6" md="3" class="mt-4 mt-sm-0">
                        <base-select
                            v-model="form_data.status_campanha"
                            tooltip-text="Voc&ecirc; pode selecionar uma ou mais <b>situa&ccedil;&otilde;es</b> (status) dos disparos"
                            label="Situa&ccedil;&atilde;o"
                            title="Situa&ccedil;&atilde;o"
                            :items="situacao"
                            use-icon-validation
                        />
                    </v-col>
                </v-row>
                <v-row class="mt-4" justify="center" justify-md="end">
                    <v-col cols="6" md="5">
                        <base-autocomplete
                            v-model="form_data.id_empresa"
                            tooltip-text="Voc&ecirc; pode filtrar as templates por cliente."
                            label="Escolha um cliente"
                            title="Cliente"
                            :items="client_list"
                            :rules="[]"
                            :loading="clients_loading"
                            multiple
                            name="id_empresa"
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="6" md="4">
                        <base-text-field
                            v-model="form_data.nome_campanha"
                            tooltip-text="Voc&ecirc; pode buscar pelo <b>nome</b> de uma campanha espec&iacute;fica"
                            label="Digite..."
                            title="Campanha"
                            name="nome_campanha"
                            use-icon-validation
                            :rules="[]"
                        />
                    </v-col>
                    <v-col class="mt-5 d-flex justify-end" md="3">
                        <base-button
                            block
                            icon="fa fa-filter"
                            label="Aplicar filtros"
                            variant="extra-dark-grey"
                            :disabled="false"
                            @click="formSubmit()"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </filter-page-slider>
    `
};
