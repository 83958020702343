import ResetPassword from "../../auth/ResetPassword";

export default {
    components: { ResetPassword },
    template: (
        /*html*/
        `
            <reset-password/>
        `
    )
};