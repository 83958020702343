import CardRcs from "../moleculas/CardRcs";

/**
 * Componente de Carrossel Card Rcs
 *
 * @displayName CarrosselCardRcs
 * @component
 * @category Organismo
 * @author Lyndon Marques <lyndon.marques@pgmais.com.br>
 * @vue
 */
const CarrosselCardRcs = {
    name: "carrossel-card-rcs",
    props: {
        //Lista de configuraes do card
        cardsConfig: {
            type: Array,
            required: true,
            default: []
        }
    },
    data() {
        return {};
    },
    components: {
        "card-rcs": CardRcs
    },
    template:
        /*html*/
        `
          <template>                          
              <v-carousel
                :continuous="true"
                :interval="0"              
                :cycle="false"               
                :show-arrows="true"
                hide-delimiter-background="true"
                delimiter-icon="mdi-minus"
                height="auto"
              >
                <v-carousel-item
                  v-for="({title,
                          description,
                          height,
                          image,
                          number,
                          suggestions} , index) in cardsConfig" 
                  :key="index"
                >
                  <v-sheet
                    style="background: none;"
                    height="100%"
                    tile
                  >
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                    >
                      <card-rcs :number="cardsConfig.length > 1 ? index + 1: null" 
                        :description="description" 
                        :height="height" 
                        :image="image" 
                        :suggestions="suggestions"
                        :title="title"></card-rcs>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
           
          </template>
        `
};

export default CarrosselCardRcs;
