import BaseSectionForm from "../../../components/atomos/BaseSectionForm";

export default {
    components: { BaseSectionForm },
    template: (
        /*html*/
        `
            <v-container>
                <v-row>
                    <v-col>
                        <base-section-form title="teste">
                            Teste Teste Teste Teste Teste Teste <br> Teste Teste Teste Teste Teste Teste Teste Teste Teste
                        </base-section-form>
                    </v-col>
                </v-row>
            </v-container>
        `
    )
};