import TutorialEnableRcs from "../../template-rcs/components/dialogs/TutorialEnableRcs";

export default {
    components: {
        TutorialEnableRcs
    },
    data() {
        return {
            open: false
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6" class="text-center">
                    <v-card class="pa-5">
                        <v-row justify="center">
                            <v-col cols="auto">
                                <v-btn @click="open = true">Abrir tutorial</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <tutorial-enable-rcs v-model="open"></tutorial-enable-rcs>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        `
    )
};