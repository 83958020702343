import BaseButton from "../../../components/atomos/BaseButton?v=2";
import BaseTextField from "../../../components/atomos/BaseTextField";

/**
 * Componente de formulario de reset de senha
 * 
 * @component
 * @category Page / Auth
 * @subcategory Forms
 * @requires {@link base-button.html|BaseButton}
 * @requires {@link base-text-field.html|BaseTextField}
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 * @vue
 */
export default {
    name: "form-reset-password",
    components: { BaseButton, BaseTextField },
    data: function () {
        return {
            form_valid: false,
            password_first_step: "",
            password_second_step: "",
            password_rules: [
                v => !!v || "Digite uma senha",
                v => v.length > 8 || "O campo senha deve ter pelo menos 8 caracteres.",
                v => this.hasUppercase(v) || "A senha deve conter pelo menos uma letra mai&uacute;scula.",
                v => this.hasLowercase(v) || "A senha deve conter pelo menos uma letra min&uacute;scula.",
                v => this.hasSpecialChar(v) || "A senha deve conter pelo menos um caractere especial.",
                v => this.hasNumber(v) || "A senha deve conter pelo menos um n&uacute;mero."
            ],
            password_secont_step_rules: [
                v => v === this.password_first_step || window.Vue.htmlEntities("As senhas n&atilde;o correspondem!"),
            ]
        };
    },
    props: {
        /**
             * Mensagem de erro a ser exibida no formulario
             */
        error_message: {
            type: String,
            required: false,
            default: ""
        },
        /**
             * Mensagem de sucesso a ser exibida no formulario
             */
        success_message: {
            type: String,
            required: false,
            default: ""
        },
        /**
             * Indica se o botao deve estar desabilitado ou nao
             */
        disable_button: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        /**
         * Faz a validacao dos campos do formulario e emite um evento submitChangePassword com os dados email e senha
         * @vue
         */
        formSubmit() {
            if (this.$refs.form_login.validate()) {
                let formData = { password_first_step: this.password_first_step,
                    password_second_step: this.password_second_step };

                /**
                 * Evento de submit do formulario
                 * 
                 * @property {object} formData Dados do login {password_first_step, password_second_step}
                 * 
                 * @event submitChangePassword
                 * @vue
                 */
                this.$emit("submitChangePassword", formData);
            }
        },
        checkLength(password) {
            return password.length >= 8;
        },
        hasUppercase(password) {
            return /[A-Z]/.test(password);
        },
        hasLowercase(password) {
            return /[a-z]/.test(password);
        },
        hasSpecialChar(password) {
            return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);
        },
        hasNumber(password) {
            return /[0-9]/.test(password);
        },
    },
    computed: {
        passwordsMatch() {
            return this.password_first_step === this.password_second_step;
        }
    },
    template: (
        /*html*/
        `
            <v-form ref="form_login" 
                    lazy-validation 
                    v-model="form_valid">
                <v-row no-gutters>
                    <v-col>
                        <h5 class="font-weight-bold dark-primary--text">Bem vindo ao +Connect</h5>
                        <p class="font-075rem">Informe sua nova senha</p>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="error_message != ''">
                    <v-col>
                        <p>
                            <v-icon x-small color="danger">fa-exclamation-circle</v-icon>
                            <span class="danger--text" v-html="error_message"/>
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="success_message != ''">
                    <v-col>
                        <p>
                            <v-icon x-small color="success">fa-check-circle</v-icon>
                            <span v-html="success_message"/>
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <base-text-field 
                            v-model="password_first_step"
                            :rules="password_rules"
                            label="Digite a sua nova senha"
                            :required="true"
                            type="password" 
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-1">
                    <v-col>
                        <base-text-field
                            v-model="password_second_step"
                            :rules="password_secont_step_rules"
                            label="Repita a sua nova senha"
                            :required="true"
                            type="password" 
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-2">
                    <v-col>
                        <ul id="password_requirements">
                            <li :class="{ 'green--text': checkLength(password_first_step), 'red--text': !checkLength(password_first_step) }" id="length">M&iacute;nimo de 8 caracteres</li>
                            <li :class="{ 'green--text': hasUppercase(password_first_step), 'red--text': !hasUppercase(password_first_step) }" id="upper_lower">Letras mai&uacute;sculas e min&uacute;sculas</li>
                            <li :class="{ 'green--text': hasSpecialChar(password_first_step), 'red--text': !hasSpecialChar(password_first_step) }" id="special_chars">Caracteres especiais</li>
                            <li :class="{ 'green--text': hasNumber(password_first_step), 'red--text': !hasNumber(password_first_step) }" id="numbers">N&uacute;meros</li>
                            <li :class="{ 'green--text': passwordsMatch, 'red--text': !passwordsMatch }" id="match">As senhas devem ser iguais</li>
                        </ul>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-4">
                    <v-col>
                        <base-button
                            primary
                            icon='fa-check'
                            label='Enviar'
                            :disabled="disable_button"
                            @click="formSubmit"
                        /> 
                    </v-col>
                </v-row>
            </v-form>
        `
    )
};