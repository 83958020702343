import BaseButton from "../../../components/atomos/BaseButton";
import BaseTooltip from "../../../components/atomos/BaseTooltip";

import * as types from "../store/mutations-types";

/**
 * Componente para importa��o de arquivos com dados para cadastro de campanhas
 *
 * @author: Lyndon Marques - lyndon.marques@pgmais.com.br
 *
 * @displayName FileImport
 * @requires {@link BaseButton}
 * @requires {@link BaseTooltip}
 * @component
 * @category Page / Criar Campanha
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseButton,
        BaseTooltip
    },
    props: {
        /**
         * Desabilita o upload
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Layout selecionado, se houver
         */
        selectedLayout: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            file_data: [],
            border_grey: true,
            border_blue: false,
            border_error: false,
            file_status: [],
            register_count: 0,
            has_file: false,
            status: {
                upload: "upload",
                configuration: "configuration",
                success: "success",
                error: "error",
                sizeLimit: "sizeLimit"
            },
            file_list_config: [
                {
                    icon: "mdi-check-circle",
                    background: "green-500",
                    border: "gray-300"
                },
                {
                    icon: "mdi-cog",
                    icon_color: "green-500",
                    background: "warning-200",
                    border: "gray-300"
                },
                {
                    icon: "mdi-check-bold",
                    background: "gray-0",
                    border: "success-500"
                },
                {
                    icon: "mdi-exclamation-thick",
                    background: "warning-200",
                    border: "gray-300",
                    message: "Formato do arquivo invalido"
                },
                {
                    icon: "mdi-exclamation-thick",
                    background: "gray-300",
                    border: "gray-300",
                    message: "Limite de tamanho excedido (max 300 MB)"
                }
            ],
            hover: false,
            error: false
        };
    },
    computed: {
        list_files: {
            get() {
                return this.$store.state.campaign.list_files;
            },
            set(new_value) {
                this.$store.commit("campaign/" + types.SET_LIST_FILES, new_value);
            }
        },
        mainBorder() {
            let border = "border-1";

            if (this.error) {
                border += " border-error-500";
            } else if (this.hover) {
                border += " border-primary-blue-500";
            } else {
                border += " border-gray-300";
            }

            return border;
        }
    },
    watch: {
        list_files() {
            //verificar se h� arquivos na lista
            if (this.list_files.length > 0) {
                this.has_file = true;
            } else {
                this.has_file = false;
                this.register_count = 0;
            }

            //verificar quantos arquivos est�o com status de erro
            this.error = false;
            for (let file of this.list_files) {
                if (file.status !== this.status.success) {
                    this.error = true;
                    break;
                }
            }
        },
        register_count() {
            this.$emit("totalImported", this.register_count);
        }
    },
    methods: {
        /**
         * Chamado sempre que um arquivo for submetido
         * @vue
         */
        onChange(e) {
            e.preventDefault();

            // Verifica o tipo de evento disparado contendo o arquivo
            let files = null;
            if (e.target.files) {
                files = e.target.files; // Evento disparado pelo input file
            } else {
                files = e.dataTransfer.files; // Evento disparado pelo drag and drop
            }

            if (files.length > 0) { // Verifica se o arquivo � valido
                this.handleFileChange(files[0]); // Chama o m�todo para tratar o arquivo
            }
        },
        /**
         * Evento disparado quando o usu�rio arrasta o arquivo para a �rea de drag and drop
         * @vue
         */
        dragover() {
            this.hover = true;
        },
        /**
         * Evento disparado quando o arquivo � arrastado para fora da �rea de drag and drop
         * @vue
         */
        dragleave() {
            this.hover = false;
        },
        /**
         * Evento disparado quando o arquivo � solto na �rea de drag and drop
         * @param {Object} event 
         * @vue
         */
        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(event); // Chama o m�todo onChange para tratar o arquivo
            this.hover = false;
        },
        /**
         * Faz a leitura do arquivo e retorna um array de objetos
         * @param {Object} file - O arquivo a ser processado
         * @vue
         */
        handleFileChange(file) {
            const new_file = this.createFileObject(file);

            if (!this.checkFileSize(file)) {
                this.updateFileStatus(new_file, this.file_list_config[4], this.status.sizeLimit);
                return;
            } else if (!this.checkFileType(file)) {
                this.updateFileStatus(new_file, this.file_list_config[3], this.status.error);
                return;
            }

            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async() => {
                const lines = this.getProcessedLines(reader.result);
                let data = [];

                if (this.selectedLayout && this.selectedLayout.DS_COLUNAS_FIXAS_LNES) {
                    data = this.processManualLayout(lines);
                } else {
                    data = await this.processAutoLayout(lines);
                }

                this.finalizeFileProcessing(new_file, data);
            };
        },
        /**
         * Cria um objeto de arquivo com base nos par�metros fornecidos
         * @param {Object} file - O arquivo a ser processado
         * @return {Object} O objeto de arquivo criado
         * @vue
         */
        createFileObject(file) {
            const new_file = {
                id: this.generateUniqueId(),
                file_name: file.name,
                size: file.size,
                type: file.type,
                style: this.file_list_config[0],
                status: this.status.upload,
                total_registers: 0,
                file_content: []
            };

            this.list_files = [...this.list_files, _.cloneDeep(new_file)];
            return new_file;
        },
        /**
         * Atualiza o status do arquivo com o estilo e status fornecidos
         * @param {Object} file - O objeto de arquivo a ser atualizado
         * @param {String} style - O estilo a ser aplicado ao arquivo
         * @param {String} status - O status a ser aplicado ao arquivo
         * @vue
         */
        updateFileStatus(file, style, status) {
            file.style = style;
            file.status = status;
            this.$store.commit("campaign/" + types.CHANGE_LIST_FILES_ITEM, file);
        },
        /**
         * Processa o conte�do do arquivo em linhas e remove caracteres especiais
         * @param {String} file_content - O conte�do do arquivo
         * @return {Array} Um array de linhas processadas
         * @vue
         */
        getProcessedLines(file_content) {
            return file_content.split("\n").map(line => this.removeSpecialCharacters(line));
        },
        /**
         * Processa o layout manual das linhas
         * @param {Array} lines - As linhas a serem processadas
         * @return {Array} Um array de objetos representando os dados processados
         * @vue
         */
        processManualLayout(lines) {
            const layout = this.parseLayout(this.selectedLayout.DS_MANUAL_LNES);
            let row_id = 0;
            let data = [];

            lines.forEach(line => {
                const row_content = {};
                let column_index = 0;
                let state = { ddd: false, phone: false, is_valid: true };

                layout.forEach(field => {
                    let field_value = this.extractFieldValue(line, field);
                    const processed_field_value = this.processFieldLayout(field.fieldName, field_value, state);
                    if (processed_field_value === false) {
                        return;
                    }

                    if (!this.validateFieldLayout(field.fieldName, processed_field_value, state)) {
                        return;
                    }

                    row_content[`coluna${column_index}`] = processed_field_value;
                    column_index++;
                });

                if (state.is_valid) {
                    row_content.row_id = row_id++;
                    data.push(row_content);
                }
            });

            return data;
        },
        /**
         * Processa um campo espec�fico do layout
         * @param {string} fieldName - O nome do campo a ser processado
         * @param {string} field_value - O valor do campo a ser processado
         * @param {Object} state - O estado atual dos campos (ddd, phone, is_valid)
         * @return {string|boolean} O valor processado do campo ou false se o processamento falhar
         * @vue
         */
        processFieldLayout(fieldName, field_value, state) {
            const fieldProcessors = {
                "ddd": (value, state) => {
                    state.ddd = value;
                    if (!state.phone) {
                        return false;
                    }
                    return state.ddd + state.phone;
                },
                "telefone": (value, state) => {
                    state.phone = value;
                    if (!state.ddd) {
                        return false;
                    }
                    return state.ddd + state.phone;
                },
                "nome": (value) => {
                    return this.selectedLayout.ST_PRI_NOME_LNES === "S"
                        ? this.extractFirstName(value)
                        : value;
                },
                "default": (value) => value
            };

            const processor = fieldProcessors[fieldName] || fieldProcessors["default"];
            return processor(field_value, state);
        },
        /**
         * Valida um campo espec�fico do layout
         * @param {string} fieldName - O nome do campo a ser validado
         * @param {string} field_value - O valor do campo a ser validado
         * @param {Object} state - O estado atual dos campos (ddd, phone, is_valid)
         * @return {boolean} True se o campo for v�lido, false caso contr�rio
         * @vue
         */
        validateFieldLayout(fieldName, field_value, state) {
            const isAllZeros = (value) => /^[0]+$/.test(value);
            const isEmptyValidate = (value) => {
                return !value || isAllZeros(value);
            };
            const validateDddPhone = (value, state) => {
                if (isEmptyValidate(state.ddd) || isEmptyValidate(state.phone) ||
                    !this.isValidPhone(value)) {
                    state.is_valid = false;
                    return false;
                }
                state.ddd = state.phone = false;
                return true;
            };

            const fieldValidators = {
                "ddd": validateDddPhone,
                "telefone": validateDddPhone,
                "ddd+telefone": (value, state) => {
                    if (!this.isValidPhone(value)) {
                        state.is_valid = false;
                        return false;
                    }
                    return true;
                },
                "default": () => true
            };

            const validator = fieldValidators[fieldName] || fieldValidators["default"];
            return validator(field_value, state);
        },
        /**
         * Extrai o valor do campo de uma linha
         * @param {String} line - A linha de onde o campo ser� extra�do
         * @param {Object} field - O objeto campo que define o in�cio e o fim
         * @return {String} O valor do campo extra�do
         * @vue
         */
        extractFieldValue(line, field) {
            return line.substring(field.start, field.end).trim();
        },
        /**
         * Extrai o primeiro nome de uma string de nome completo
         * @param {String} full_name - O nome completo
         * @return {String} O primeiro nome extra�do
         * @vue
         */
        extractFirstName(full_name) {
            return full_name.split(" ")[0];
        },
        /**
         * Processa automaticamente o layout das linhas utilizando a lib Papa
         * @param {Array} lines - As linhas a serem processadas
         * @return {Promise} Uma promessa que resolve com os dados processados
         * @vue
         */
        async processAutoLayout(lines) {
            return new Promise((resolve, reject) => {
                Papa.parse(lines.join("\n").trim(), {
                    worker: true,
                    complete: (result_parse) => {
                        const data = result_parse.data.map((row, row_index) => {
                            const row_content = { row_id: row_index };
                            Object.entries(row).forEach(([_, value], column_index) => {
                                let column_value = value.trim().replace(/"/g, "");
                                if (column_value.startsWith("\"") && column_value.endsWith("\"")) {
                                    column_value = column_value.slice(1, -1);
                                }
                                row_content[`coluna${column_index}`] = column_value;
                            });
                            return row_content;
                        });
                        resolve(data);
                    },
                    error: (error) => {
                        reject(error);
                    }
                });
            });
        },
        /**
         * Finaliza o processamento do arquivo atualizando seus dados e estado
         * @param {Object} file - O objeto de arquivo a ser atualizado
         * @param {Array} data - Os dados processados do arquivo
         * @vue
         */
        finalizeFileProcessing(file, data) {
            this.register_count += data.length;
            file.total_registers = data.length;
            file.file_content = data;
            file.style = this.file_list_config[1];
            file.status = this.status.configuration;
            this.$store.commit("campaign/" + types.CHANGE_LIST_FILES_ITEM, file);
        },
        /**
         * Fun��o para remover caracteres especiais de uma string
         * @param {String} value - A string que pode conter caracteres especiais a serem removidos.
         * @return {String} A string modificada ap�s a remo��o dos caracteres especiais.
         * @vue
         */
        removeSpecialCharacters(value) {
            if (value !== undefined && value !== null) {
                const charactersToRemove = /[\\"']/g;
                return value.replace(charactersToRemove, "").replace(/\u201c|\u201d|\u2018|\u2019/g, "");
            }
            return value;
        },
        /**
         * Valida se o n�mero de telefone � v�lido
         * @param {String} phone - O n�mero de telefone a ser validado
         * @return {Boolean} Retorna verdadeiro se o telefone for v�lido, falso caso contr�rio
         * @vue
         */
        isValidPhone(phone) {
            const phoneRegex = /^\d{10,11}$/;
            return phoneRegex.test(phone);
        },
        /**
         * Verifica se o tamanho do arquivo � v�lido
         * @param {Object} file  O arquivo a ser verificado
         * @return {Boolean} Retorna verdadeiro se o tamanho do arquivo for v�lido, falso caso contr�rio
         * @vue
         */
        checkFileSize(file) {
            if (file.size > 300000000) { // 300 Megas
                return false;
            }

            return true;
        },
        /**
         * Parseia uma string de layout e retorna um array de objetos representando os campos
         * @param {String} layoutString - A string que define o layout, ex: "nome(0,10),telefone(11,20)"
         * @return {Array} Um array de objetos onde cada objeto representa um campo com nome, in�cio e fim
         * @vue
         */
        parseLayout(layoutString) {
            const layout = [];
            const pattern = /([a-zA-Z0-9+\s]+)\((\d+),(\d+)\)/g;
            let match;

            while ((match = pattern.exec(layoutString)) !== null) {
                layout.push({
                    fieldName: match[1].toLowerCase().trim(),
                    start: parseInt(match[2], 10),
                    end: parseInt(match[3], 10) + 1,
                });
            }

            return layout;
        },
        /**
         * Verifica se o formato do arquivo � v�lido
         * @param {Object} file 
         * @vue
         */
        checkFileType(file) {
            if (file.type != "text/plain" && file.type != "text/csv") {
                return false;
            }

            return true;
        },
        /**
         * Formata o subtexto da linha do arquivo
         * @param {Object} file 
         * @vue
         */
        formatText(file) {
            if (file.status == "error" || file.status == "sizeLimit") {
                return file.style.message; // Retorna a mensagem de erro
            } else {
                let formatted = file.size.toLocaleString(); // Formata o numero
                formatted = formatted.replace(/,(\d{3})$/, ".$1"); // Troca a virgula por ponto
                return formatted + " KB";
            }
        },
        /**
         * Gera uma chave �nica para o arquivo importado
         * @vue
         */
        generateUniqueId() {
            return (
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15) +
                Date.now()
            );
        },
        /**
         * Remove um arquivo do array
         * @param {String} file_id
         * @vue
         */
        removeFile(file_id) {
            // Obtem a posi��o do objeto ID no array
            let file_index = this.list_files.findIndex(
                (x) => x.id === file_id
            );

            // Remove a quantidade de registros do arquivo do total de registros
            if (this.register_count > 0) {
                this.register_count -= this.list_files[file_index].total_registers;
            }

            // Remove o arquivo do array
            this.$store.commit("campaign/" + types.REMOVE_FILE_FROM_LIST, file_index);
            this.$refs.file.value = null;
        },
        /**
         * Remove todos os arquivos importados
         * @vue
         */
        removeAllFiles() {
            this.list_files = [];
            this.register_count = 0;
            this.$refs.file.value = null;
        },
        /**
         * Formata o total de registros importados
         * @param {Number} ev Total de registros
         * @vue
         */
        formatNumber(ev) {
            let formatted = ev.toLocaleString(); // Formata o numero
            formatted = formatted.replace(/,(\d{3})$/, ".$1"); // Troca a virgula por ponto
            return formatted;
        },
        /**
         * Emite um evento informando que o modal de configuracao deve ser aberto para o arquivo escolhido
         * @param {Object} file 
         * @vue
         */
        openModalConfigurations(file) {
            if (["configuration", "success"].indexOf(file.status) > -1) {
                this.$emit("openModalConfigurations", file.id);
            }
        },
    },
    template:
        /*html*/
        `
            <v-row no-gutters>
                <v-col cols="12">
                    <v-card elevation="0" :class="mainBorder + ' pa-8'" :disabled="disabled">
                        <v-row
                            no-gutters
                            @dragover="dragover"
                            @dragleave="dragleave"
                            @drop="drop"
                            @change="onChange"
                        >
                            <v-col cols="12">
                                <v-row v-if="!has_file" no-gutters>
                                    <v-col cols="12">
                                        <v-row no-gutters justify="center">
                                            <v-col cols="auto">
                                                <v-icon :size="52" color="gray-900">
                                                    $importadosCardsIcon
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters justify="center" class="my-4">
                                            <v-col cols="auto" class="text-center">
                                                <span class="body-2 text-gray-900">
                                                    Arraste e solte seus arquivos aqui<br>
                                                    OU
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters justify="center">
                                    <v-col cols="auto">
                                        <base-button
                                            width="auto"
                                            :disabled="disabled"
                                            label="Buscar arquivos"
                                            icon="mdi-folder-open"
                                            secondary
                                            cols="auto"
                                            tooltip-text="Arraste ou clique para importar seus arquivos"
                                            @click="$refs.file.click()"
                                        />
                                    </v-col>
                                    <input
                                        ref="file"
                                        type="file"
                                        class="opacity-0 overflow-hidden absolute disable d-none"
                                        accept=".txt, .csv"
                                        multiple
                                        id="inputBuscarArquivo"
                                    />
                                </v-row>
                                <v-row v-if="has_file" no-gutters class="mt-4">
                                    <v-col
                                        cols="12"
                                        v-for="(file, index) in list_files"
                                        :key="index"
                                    >
                                        <v-row no-gutters align="center">
                                            <v-col>
                                                <v-list
                                                    rounded
                                                    dense
                                                    max-height="200"
                                                    class="py-1 px-2"
                                                >
                                                    <v-list-item
                                                        :class="'body-2 border-1 border-' + file.style.border + ' bg-' + file.style.background"
                                                        :disabled="['configuration', 'success'].indexOf(file.status) === -1"
                                                        dense
                                                        rounded
                                                        @click="openModalConfigurations(file)"
                                                    >
                                                        <v-list-item-icon>
                                                            <v-icon :color="file.style.border" :size="16">
                                                                {{ file.style.icon }}
                                                            </v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-row no-gutters>
                                                                <v-col>
                                                                    <span class="body-2 text-gray-900">{{ file.file_name }}</span>
                                                                </v-col>
                                                                <v-col cols="auto">
                                                                    <span class="caption text-gray-900">{{ formatText(file) }}</span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-progress-circular
                                                    v-if="file.status == 'upload'"
                                                    :size="20"
                                                    indeterminate
                                                    color="primary-blue-500"
                                                />
                                                <v-icon
                                                    v-else
                                                    :size="20"
                                                    class="pointer"
                                                    color="error-500"
                                                    @click="removeFile(file.id)"
                                                >
                                                    $trashOutlineIcon
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col v-if="has_file" col="12" class="mt-1">
                    <v-row no-gutters>
                        <v-col cols="6" class="text-left">
                            <a class="body-2" @click="removeAllFiles()">Excluir arquivos</a>
                        </v-col>
                        <v-col
                            cols="6"
                            class="text-right text-nowrap body-2"
                        >
                            <base-tooltip text="Esse &eacute; o total de registros importados sem descartar os higienizados.">
                                <span class="text-gray-900">Total de registros importados: <span class="bold" v-text="formatNumber(register_count)" /></span>
                            </base-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
};
