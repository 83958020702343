import BasePill from "./BasePill";
import BaseSelect from "./BaseSelect";

/**
 * Componente para pre visualizacao de suggestions
 * 
 * @requires {@link BasePill}
 * @requires {@link BaseSelect}
 * 
 * @displayName PreviewSuggestions
 * @category Atomos
 * @author David dos Satnos Andrade <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BasePill,
        BaseSelect
    },
    props: {
        /**
         * Lista de chips a serem exibidos
         */
        listChips: {
            type: Array,
            required: true
        },
        /**
         * Indica que sera utilizado para configurar um card
         */
        isCard: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Largura do card
         */
        width: {
            type: [Number, String],
            required: false,
            default: undefined
        },
        /**
         * Lista de opcoes que podem ser utilizadas em um select
         */
        selectOptions: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Campos variaveis do template
         */
        fieldsTemplate: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            fields_template: this.fieldsTemplate
        };
    },
    watch: {
        fieldsTemplate() {
            this.fields_template = this.fieldsTemplate;
        },
        fields_template() {
            this.fieldChanged();
        }
    },
    methods: {
        fieldChanged() {
            this.$emit("changeField", this.fields_template);
        }
    },
    template: (
        /*html*/
        `
            <v-card elevation="0" class="bg-transparent" :width="width">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-row v-if="!isCard" no-gutters :class="(selectOptions && Object.keys(selectOptions).length > 0 ? '' : 'flex-nowrap overflow-y-hidden overflow-x-auto') + ' ml-4 py-2'">
                            <v-col
                                v-for="(item, i) in listChips"
                                :key="i"
                                cols="auto"
                                class="pr-2"
                            >
                                <base-pill
                                    v-if="!selectOptions || Object.keys(selectOptions).length === 0"
                                    rounded
                                    content-color="gray-900"
                                    background-color="transparent"
                                    border-color="gray-500"
                                    :title="item.pill.title"
                                    :title-icon="item.pill.titleIcon"
                                    :prepend-icon="null"
                                />
                                <v-row v-else no-gutters class="bg-gray-30 mt-2 pa-2 rounded-2">
                                    <v-col cols="12">
                                        <base-pill
                                            v-if="item.pill.title[0] !== '{' && item.pill.title[item.pill.title.length - 1] !== '}'"
                                            rounded
                                            content-color="gray-900"
                                            background-color="transparent"
                                            border-color="gray-500"
                                            :title="item.pill.title"
                                            :title-icon="item.pill.titleIcon"
                                            :prepend-icon="null"
                                        />
                                        <base-select
                                            v-else
                                            v-model="fields_template[item.pill.title.slice(1, -1)]"
                                            :items="selectOptions"
                                            :title="item.pill.title.slice(1, -1).replaceAll('_', ' ')"
                                            label="Selecione uma op&ccedil;&atilde;o"
                                            tooltip-text="Selecione uma op&ccedil;&atilde;o para configurar seu arquivo"
                                            clearable
                                            :prepend-icon="item.pill.titleIcon"
                                            @change="fieldChanged"
                                        />
                                    </v-col>
                                    <v-col v-if="item.pill.subTitle && item.pill.subTitle[0] === '{' && item.pill.subTitle[item.pill.subTitle.length - 1] === '}'" cols="12">
                                        <base-select
                                            v-model="fields_template[item.pill.subTitle.slice(1, -1)]"
                                            :items="selectOptions"
                                            :title="item.pill.subTitle.slice(1, -1).replaceAll('_', ' ')"
                                            label="Selecione uma op&ccedil;&atilde;o"
                                            tooltip-text="Selecione uma op&ccedil;&atilde;o para configurar seu arquivo"
                                            clearable
                                            :prepend-icon="item.pill.titleIcon"
                                            @change="fieldChanged"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-else no-gutters class="pa-4">
                            <v-col
                                v-for="(item, index) in listChips"
                                :key="'response-' + index"
                                cols="12"
                                class="text-center"
                            >
                                <v-row no-gutters justify="center">
                                    <v-col cols="auto">
                                        <base-pill
                                            content-color="gray-900"
                                            background-color="transparent"
                                            border-color="transparent"
                                            :title="item.pill.title"
                                            :title-icon="item.pill.titleIcon"
                                            :prepend-icon="null"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row v-if="index < listChips.length - 1" no-gutters justify="center">
                                    <v-col cols="12">
                                        <v-divider class="ma-0 pa-0" />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};