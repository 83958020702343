export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-UserPerm-Enabled" transform="translate(3.75 -59)">
                    <rect id="Retângulo_354" data-name="Retângulo 354" width="28" height="28" transform="translate(-3.75 59)" fill="currentColor" opacity="0"/>
                    <g id="Grupo_1859" data-name="Grupo 1859" transform="translate(0 63)">
                        <path id="Caminho_494" data-name="Caminho 494" d="M20.3,10.5V8.8a3.5,3.5,0,0,0-7,0v1.8l-.1.1a8.69,8.69,0,0,0-2.6-1.6A4.932,4.932,0,0,0,12.7,5a5,5,0,0,0-10,0A4.932,4.932,0,0,0,4.8,9.1a6.54,6.54,0,0,0-2.6,1.7A9.451,9.451,0,0,0,.1,13.9a.778.778,0,0,0,.2.8,11.453,11.453,0,0,0,7.4,2.7,10.3,10.3,0,0,0,4.6-1v.8a2.112,2.112,0,0,0,2.1,2.1h4.9a2.2,2.2,0,0,0,2.2-2.1V12.3A2.09,2.09,0,0,0,20.3,10.5ZM16.8,6.8a2.006,2.006,0,0,1,2,2v1.5h-4V8.8A2.006,2.006,0,0,1,16.8,6.8ZM7.7,1.5A3.543,3.543,0,0,1,11.2,5,3.631,3.631,0,0,1,7.7,8.6,3.5,3.5,0,0,1,4.2,5,3.418,3.418,0,0,1,7.7,1.5Zm-6,12.4a8.008,8.008,0,0,1,1.5-2.1,6.676,6.676,0,0,1,9.2.1v2.9A9.884,9.884,0,0,1,1.7,13.9ZM20,17.3c0,.4-.3.6-.7.6H14.4a.576.576,0,0,1-.6-.6V12.4a.576.576,0,0,1,.6-.6h4.9a.669.669,0,0,1,.7.6Z" transform="translate(-0.061 0)" fill="currentColor"/>
                        <path id="Caminho_495" data-name="Caminho 495" d="M17.6,14.9l.1-.1.1-.1a.1.1,0,0,1,.1-.1l.1-.1v-.3a1.421,1.421,0,0,0-.2-.6,1.05,1.05,0,0,0-.9-.5,1.11,1.11,0,0,0-1.1,1.1v.3h0a.349.349,0,0,0,.1.2h0l.2.2h0c.1.1.1.1.2.1l-.2,1-.3.7H18l-.7-1.6h.1C17.4,15,17.5,15,17.6,14.9Z" transform="translate(-0.061 0)" fill="currentColor"/>
                    </g>
                </g>
            </svg>      
        `
    )
};