import Vue from "vue";
import Vuetify from "vuetify";
import "./vuetify.css";

Vue.use(Vuetify);

import pt from "vuetify/src/locale/pt.ts";
import theme from "./theme";
import custom_icons from "./icons";

const opts = {
    lang: {
        locales: { pt },
        current: "pt"
    },
    theme: {
        themes: { light: theme }
    },
    icons: {
        iconfont: "fa",
        values: custom_icons
    },
    breakpoint: {
        thresholds: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1264,
            xl: 1904
        }
    }
};

export default new Vuetify(opts);