import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor,
    stroke: currentColor
`;

export default {
    name: "botoes-solo-icon",
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
            <svg-custom id="Group_2833" data-name="Group 2833" xmlns="http://www.w3.org/2000/svg" width="17.826" height="17.677" viewBox="0 0 17.826 17.677">
                <g id="Rectangle_767" data-name="Rectangle 767" transform="matrix(0.695, 0.719, -0.719, 0.695, 5.318, 1.05)" fill="none" stroke="currentColor" stroke-width="1">
                    <rect width="7.394" height="7.394" rx="2" stroke="currentColor"/>
                    <rect x="0.5" y="0.5" width="6.394" height="6.394" rx="1.5" fill="none"/>
                </g>
                <g id="Rectangle_768" data-name="Rectangle 768" transform="matrix(0.695, 0.719, -0.719, 0.695, 13.731, 0)" fill="none" stroke="currentColor" stroke-width="1">
                    <rect width="5.895" height="5.895" rx="2" stroke="currentColor"/>
                    <rect x="0.5" y="0.5" width="4.895" height="4.895" rx="1.5" fill="none"/>
                </g>
                <g id="Rectangle_769" data-name="Rectangle 769" transform="translate(17.319 9.392) rotate(90)" fill="none" stroke="currentColor" stroke-width="1">
                    <rect width="8.286" height="8.72" rx="2" stroke="currentColor"/>
                    <rect x="0.5" y="0.5" width="7.286" height="7.72" rx="1.5" fill="none"/>
                </g>          
            </svg-custom>
        `
    )
};