export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Baixar-Enabled" transform="translate(3.929 -41)">
                    <rect id="Retângulo_351" data-name="Retângulo 351" width="28" height="28" transform="translate(-3.929 41)" fill="currentColor" opacity="0"/>
                    <g id="Grupo_1856" data-name="Grupo 1856" transform="translate(0 46)">
                        <path id="Caminho_488" data-name="Caminho 488" d="M18.8,9.7a.789.789,0,0,0-.8.8v3A2.476,2.476,0,0,1,15.5,16H4a2.476,2.476,0,0,1-2.5-2.5v-3a.789.789,0,0,0-.8-.8.632.632,0,0,0-.7.7v3a4.012,4.012,0,0,0,4,4H15.5a4.012,4.012,0,0,0,4-4v-3A.684.684,0,0,0,18.8,9.7Z" fill="currentColor"/>
                        <path id="Caminho_489" data-name="Caminho 489" d="M9,12.8a.908.908,0,0,0,.7.3h0a.908.908,0,0,0,.7-.3L14.3,9a.778.778,0,0,0-1.1-1.1l-2.7,2.7V.8A.789.789,0,0,0,9.7,0C9.2,0,9,.3,9,.8v9.9L6.3,7.9A.778.778,0,0,0,5.2,9Z" fill="currentColor"/>
                    </g>
                </g>
            </svg>
        `
    )
};