export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
                <g>
                    <rect fill="none" height="24" width="24"/>
                    <rect fill="none" height="24" width="24"/>
                    <rect fill="none" height="24" width="24"/>
                </g>
                <g>
                <g/>
                    <path d="M20,4H4C2.9,4,2.01,4.9,2.01,6L2,18c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M4,9h10.5v3.5H4V9z M4,14.5 h10.5V18L4,18V14.5z M20,18l-3.5,0V9H20V18z"/>
                </g>
            </svg>
        `
    )
};