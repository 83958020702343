import BaseCardExtraLarge from "../../../components/atomos/BaseCardExtraLarge";

export default {
    components: {BaseCardExtraLarge},
    data() {
        return {
            title: "Titulo",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ultrices dui sed odio ultrices, ",
            imageUrl: "/images/reports/analytical_report.png",
            active: false,
            disabled: false,
            soon: false
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <v-card class="pa-5">
                        <v-row>
                            <v-col cols="6">
                                <v-text-field label="Titulo" v-model="title"></v-text-field> 
                            </v-col>
                            <v-col cols="6">
                                <v-text-field label="Descricao" v-model="description"></v-text-field> 
                            </v-col>
                            <v-col cols="4">
                                <v-checkbox label="Selecionado" v-model="active"></v-checkbox>
                            </v-col>
                            <v-col cols="4">
                                <v-checkbox label="Desabilitado" v-model="disabled"></v-checkbox>
                            </v-col>
                            <v-col cols="4">
                                <v-checkbox label="Em breve" v-model="soon"></v-checkbox>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12">
                                <v-row>
                                    <base-card-extra-large
                                        :imageUrl="imageUrl"
                                        :title="title"
                                        :description="description"
                                        v-model="active"
                                        :disabled="disabled"
                                        :soon="soon"
                                    />     
                                </v-row>      
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>   
            </v-row>
        `
    )
};