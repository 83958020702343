import BaseSpanLimited from "../../../components/atomos/BaseSpanLimited";

export default {
    components: {
        BaseSpanLimited
    },
    data() {
        return {
            text: "Test",
            limit: 30,
        };
    },
    template: (
        /*html*/
        `
        <v-col cols="12">
            <v-row justify="center">
                <v-col cols="4">
                    <v-row>
                        <v-col>
                            <v-text-field v-model="text" label="Texto"></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="limit"  label="Limite"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row  no-gutters>
                        <v-col>
                            <base-span-limited :text="text" :limit="limit"></base-span-limited>
                        </v-col>
                    </v-row>
                </v-col> 
            </v-row>
        </v-col>
        `
    )
};