import NewWindow from "../../../../components/organismos/NewWindow";

/**
 * Componente de tutorial sobre como habilitar o RCS no celular
 *
 * @displayName TutorialEnableRcs
 * @requires {@link NewWindow}
 * @component
 * @category Page / Template RCS
 * @subcategory dialogs
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 */
export default {
    name: "tutorial-enable-rcs",
    components: {
        NewWindow
    },
    props: {
        /**
         * Indica se o tutorial deve ser exibido ou nao
         */
        open: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    model: {
        prop: "open",
        event: "close"
    },
    watch: {
        open() {
            if (!this.open) {
                this.$emit("close");
            }
        }
    },
    data() {
        return {
            host: window.location.origin
        };
    },
    template: (
        /*html*/
        `
            <new-window v-model="open">
                <v-app class="bg-of-white">
                    <v-container fill-height fluid>
                        <v-row class="text-center">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <h5>Como ativar o RCS?</h5>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-row class="pb-3" no-gutters>
                                        <v-col cols="12">
                                            <div class="font-size-15 font-weight-bold">Passo 1 - Instalar o aplicativo "Mensagens" (se n&atilde;o tiver)</div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <div class="font-size-13 medium-grey--text">Entre na loja PlayStore do Google e procure pelo app <b>"Mensagens"</b>.</div>
                                                </v-col>
                                            </v-row>
                                            <v-row class="pt-1 pb-1">
                                                <v-col>                                                    
                                                    <v-img
                                                     class="mx-auto"
                                                     contain
                                                     :src="host + '/images/tutorial-enable-rcs/tutorial-rcs-passo-1.png'"
                                                     width="400"></v-img>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-0">
                                                <v-col cols="12">
                                                    <div class="font-size-13 medium-grey--text">Ap&oacute;s encontrar, abra a p&aacute;gina do aplicativo e aperte no bot&atilde;o instalar.</div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-row class="pb-3" no-gutters>
                                        <v-col cols="12">
                                            <div class="font-size-15 font-weight-bold">Passo 2 - Abrir o aplicativo e entrar em "Configura&ccedil;&otilde;es"</div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <div class="font-size-13 medium-grey--text">Ao abrir o aplicativo clique no &iacute;cone de <b>mais informa&ccedil;&otilde;es <i class="fas fa-ellipsis-v"></i></b> posicionado no canto superior direito e escolha <b>"Configura&ccedil;&otilde;es"</b></div>
                                                </v-col>
                                            </v-row>
                                            <v-row class="pt-1">
                                                <v-col>
                                                    <v-img
                                                    class="mx-auto"
                                                    contain
                                                    :src="host + '/images/tutorial-enable-rcs/tutorial-rcs-passo-2.png'"
                                                    width="400"></v-img>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-row class="pb-3" no-gutters>
                                        <v-col cols="12">
                                            <div class="font-size-15 font-weight-bold">Passo 3 - Habilitar recursos de CHAT</div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <div class="font-size-13 medium-grey--text">Entre na op&ccedil;&atilde;o <b>"Recursos de chat"</b> e depois ligue a op&ccedil;&atilde;o <b>"Ativar recursos de chat"</b></div>
                                                </v-col>
                                            </v-row>
                                            <v-row class="pt-1">
                                                <v-col>
                                                    <v-img
                                                        class="mx-auto"
                                                        contain
                                                        :src="host + '/images/tutorial-enable-rcs/tutorial-rcs-passo-3.png'"
                                                        width="400"></v-img>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-row class="pb-3" no-gutters>
                                        <v-col cols="12">
                                            <div class="font-size-15 font-weight-bold">Passo 4 - Status</div>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12">
                                            <v-row no-gutters>
                                                <v-col cols="12">
                                                    <div class="font-size-13 medium-grey--text">Aguarde o Status ficar como <span class="dark-success--text">Conectado</span> e pronto, voc&ecirc; j&aacute; pode receber mensagens de RCS</div>
                                                </v-col>
                                            </v-row>
                                            <v-row class="pt-1 pb-1">
                                                <v-col>
                                                    <v-img
                                                        class="mx-auto"
                                                        contain
                                                        :src="host + '/images/tutorial-enable-rcs/tutorial-rcs-passo-4.png'"
                                                        width="400"></v-img>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-0">
                                                <v-col cols="12">
                                                    <div class="font-size-13 medium-grey--text">Se o status n&atilde;o parecer como Conectado, pode ser que sua operadora de telefonia ainda n&atilde;o esteja habilitada para o recebimento de RCS</div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-container>
                </v-app>
            </new-window>
        `
    )
};