import services from "../../../http/index";

/**
 * Desabilita um template de SMS
 * @returns Object
 */
export const ActionDoDisableTemplate = (context, params) => {
    return services.php.request({
        url: "/Template/desativarTemplate",
        method: "post",
        data: params
    }).then(response => {
        let resp = response.data;
        return resp;
    });
};

/**
 * Desabilita um template de RCS ou WHATSAPP
 * @returns Object
 */
export const ActionDoDisableTemplateApps = (context, template_id) => {
    return services.api.request({
        url: `/template-rcs/${template_id}`,
        method: "delete"
    }).then(response => {
        let resp = response.data;
        return resp;
    });
};

/**
 * Reativar Template
 * @returns String
 */
export const ActionDoEnableTemplate = (context, template_id) => {
    return services.api
        .request({
            url: `/template-rcs/${template_id}`,
            method: "post"
        })
        .then((response) => {
            let resp = response.data;
            return resp;
        });
};
