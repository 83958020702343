import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente de stepper do painel
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName ButtonFormStep
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br>
 * @vue
 * @category Atomos
 * @component
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * posicao do botao
         */
        step: {
            type: Number,
            required: true
        },
        /**
         * Titulo do botao
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Se o botao esta eh o proximo da fila
         */
        ready: {
            type: Boolean,
            default: false
        },
        /**
         * se o botao eh ativo
         */
        active: {
            type: Boolean,
            default: false
        },
        /**
         * se o botao eh desabilitado
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * Se o botao tera uma linha na vertical e na horizontal
         */
        line: {
            type: Boolean,
            default: false
        },
        /**
         * Texto do tooltip do titulo
         */
        tooltipTitle: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Texto do tooltip da descricao
         */
        tooltipDescription: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        class_step_number() {
            let classess = "bg-gray-0 border-1 border-primary-blue-500 body-2 text-primary-blue-500";

            if (this.active) {
                classess = "bg-primary-blue-500 border-0 body-2 bold text-gray-0";
            } else if (this.disabled) {
                classess = "bg-transparent border-dashed-1 border-gray-200 body-2 bold text-gray-100";
            } else if (this.ready) {
                classess = "bg-secondary-blue-100 border-1 border-primary-blue-500 body-2 text-primary-blue-500";
            }

            return classess;
        },
        class_title() {
            let class_default = "text-primary-blue-500";

            if (this.active) {
                class_default += " bold";
            } else if (this.disabled) {
                class_default = "text-gray-100";
            }

            return class_default;
        },
        role_button() {
            return this.disabled ? null : "button";
        },
    },
    methods: {
        /**
         * funcao que fica vinculada ao click do botao, apenas se o botao nao estiver desabilitado
         * @vue
         */
        clickButton() {
            if (!this.disabled) {
                /**
                 * Evento de click do botao
                 * @event click
                 * @vue
                */
                this.$emit("click");
            }
        }
    },
    template: (
        /*html*/
        `
            <v-row @click="clickButton" :role="role_button" no-gutters class="flex-wrap flex-md-nowrap">
                <base-tooltip :text="tooltipTitle">
                    <v-col cols="12" md="auto" class="d-flex justify-content-center justify-content-md-start">
                        <v-row no-gutters>
                            <v-col cols="12" class="d-flex justify-content-center justify-content-md-start">
                                <v-avatar size="38" :class="class_step_number">
                                    {{ step }}
                                </v-avatar>
                            </v-col>
                            <v-col cols="12" class="text-center fill-height d-none d-md-block">
                                <v-divider v-if="line" class="dark-primary opacity-unset" vertical></v-divider>
                            </v-col>
                        </v-row>
                    </v-col>
                </base-tooltip>
                <v-col class="px-0" cols="12" md="">
                    <base-tooltip :text="tooltipTitle">
                        <v-row no-gutters class="px-3" justify="center" justify-md="start">
                            <v-col cols="auto" class="py-2 d-flex justify-content-center justify-content-md-start">
                                <span :class="'text-center text-md-start body-2 ' + class_title" v-html="title"></span>
                            </v-col>
                        </v-row>
                    </base-tooltip>
                    <base-tooltip :text="tooltipDescription">
                        <v-row no-gutters class="ml-md-3" justify="center" justify-md="start">
                            <v-col cols="auto" class="ml-1 text-center text-md-start">
                                <slot></slot>
                            </v-col>
                        </v-row>
                    </base-tooltip>
                </v-col>
            </v-row>
        `
    )
};
