import services from "../../../http/index";

/**
 * Busca a lista de campanhas atuais para o autocomplete do filtro da dashboard
 * @returns Object
 */
export const ActionDoGetCampaigns = (context, params) => {
    return services.api.request({
        url: "/campaign/ids-dashboard",
        method: "post",
        data: params
    }).then(response => {
        let resp = response.data;
        return resp;
    });
};

/**
 * Busca os dados das campanhas atuais para exibir na dashboard
 * @returns Object
 */
export const ActionDoGetCampaignsData = (context, params) => {
    return services.api.request({
        url: "/dashboard/list",
        method: "post",
        data: params
    }).then(response => {
        let resp = response.data;
        return resp;
    });
};

/**
 * Busca a contagem dos status das campanhas
 * @returns Object
 */
export const ActionDoGetStatusCount = (context, params) => {
    return services.api.request({
        url: "/dashboard/count-status",
        method: "post",
        data: params
    }).then(response => {
        let resp = response.data;
        return resp;
    });
};

/**
 * Realiza a pausa das campanhas
 * @returns Object
 */
export const ActionDoPauseCampaigns = (context, params) => {
    return services.api.request({
        url: "/campaign/pause",
        method: "post",
        data: params
    }).then(response => {
        let resp = response.data;
        return resp;
    });
};

/**
 * Realiza o reinicio das campanhas
 * @returns Object
 */
export const ActionDoRestartCampaigns = (context, params) => {
    return services.api.request({
        url: "/campaign/restart",
        method: "post",
        data: params
    }).then(response => {
        let resp = response.data;
        return resp;
    });
};

/**
 * Busca as informacoes dos lotes que devem ser cancelados
 * @returns Object
 */
export const ActionDoGetLotesCancel = (context, params) => {
    return services.api.request({
        url: "/campaign/lotes-cancel",
        method: "post",
        data: params
    }).then(response => {
        let resp = response.data;
        return resp;
    });
};

/**
 * Realiza o cancelamento das campanhas
 * @returns Object
 */
export const ActionDoCancelCampaigns = (context, params) => {
    return services.api.request({
        url: "/campaign/cancel",
        method: "post",
        data: params
    }).then(response => {
        let resp = response.data;
        return resp;
    });
};