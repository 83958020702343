import GroupButtonCheckbox from "../../../components/moleculas/GroupButtonCheckbox";
import ButtonCheckboxClass from "../../../classes/ButtonCheckboxClass";

/**
 * Componente de dados adicionais para filtro de relatorio
 * 
 * @requires {@link GroupButtonCheckbox}
 * @requires {@link ButtonCheckboxClass}
 * 
 * @displayName AditionalReportData
 * @category Page / Relatorios
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        GroupButtonCheckbox
    },
    props: {
        /**
         * Array com as opcoes disponiveis, deve seguir a seguinte estrutura:
         * <pre>[
         *  {
         *      name: "string",
         *      checked: boolean,
         *      indeterminate: boolean,
         *      buttons: new {@link ButtonCheckboxClass}()
         *  },
         *  {
         *      ...
         *  }
         * ]</pre>
         */
        value: {
            type: Array,
            required: true,
            validator: function (data) {
                let is_valid = true;

                for (const element of data) {
                    if (element.name === undefined || element.buttons === undefined || element.checked === undefined || element.indeterminate === undefined) {
                        is_valid = false;
                        break;
                    }

                    for (const buttons of element.buttons) {
                        if (!(buttons instanceof ButtonCheckboxClass)) {
                            is_valid = false;
                            break;
                        }
                    }
                }

                return is_valid;
            }
        },
        /**
         * Habilita ou desabilita o botao de selecionar todos
         */
        selectAll: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            options: this.value,
            select_all: false,
            indeterminate: false
        };
    },
    watch: {
        value() {
            this.options = this.value;
        },
        options: {
            handler(opts) {
                let options_enable = [];
                let has_option_indeterminate = false;

                for (let option of opts) {
                    let buttons_enable = [];

                    for (let button of option.buttons) {
                        if (button.checked) {
                            buttons_enable.push(button);
                        }
                    }

                    if (buttons_enable.length === option.buttons.length) {
                        option.indeterminate = false;
                        option.checked = true;
                        options_enable.push(option);
                    } else if (buttons_enable.length > 0) {
                        has_option_indeterminate = true;
                        option.indeterminate = true;
                        option.checked = false;
                    } else {
                        option.indeterminate = false;
                        option.checked = false;
                    }
                }

                if (options_enable.length === this.options.length) {
                    this.indeterminate = false;
                    this.select_all = true;
                } else if (options_enable.length > 0 || has_option_indeterminate) {
                    this.indeterminate = true;
                    this.select_all = false;
                } else {
                    this.indeterminate = false;
                    this.select_all = false;
                }
            },
            deep: true
        }
    },
    methods: {
        /**
         * Marca ou desmarca todas as opcoes de uma sessao
         * @param {ButtonCheckboxClass} option 
         * @vue
         */
        selectAllOption(option, status = undefined) {
            for (let button of option.buttons) {
                if (!button.disabled) {
                    button.checked = status !== undefined ? status : option.checked;
                }
            }
        },
        /**
         * Marca ou desmarca todas as opcoes disponiveis
         * @vue
         */
        toggleSelectAll(value) {
            for (let option of this.options) {
                if (!option.disabled) {
                    this.selectAllOption(option, value);
                }
            }
        }
    },
    template: (
        /*html*/
        `
            <v-card elevation="0" class="bg-super-light-grey border border-medium-light-grey rounded-0">
                <v-row v-if="selectAll" no-gutters>
                    <v-col cols="12" class="py-4 px-10">
                        <v-checkbox
                            v-model="select_all"
                            dense
                            hide-details
                            class="mt-0"
                            color="dark-primary"
                            :indeterminate="indeterminate"
                            @change="toggleSelectAll"
                        >
                            <template #label>
                                <span class="text-black">Selecionar todos os dados adicionais</span>
                            </template>
                        </v-checkbox>
                    </v-col>
                    <v-divider class="mb-0"></v-divider>
                </v-row>
                <v-row v-for="(option, i) in options" :key="i" no-gutters>
                    <v-col cols="12" class="pt-4 px-10">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-checkbox
                                    v-model="option.checked"
                                    dense
                                    hide-details
                                    class="mt-0"
                                    color="dark-primary"
                                    :indeterminate="option.indeterminate"
                                    @change="selectAllOption(option)"
                                >
                                    <template #label>
                                        <span class="text-black" v-html="option.name"></span>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" class="mt-3">
                                <group-button-checkbox v-model="option.buttons"></group-button-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-divider class="mb-0"></v-divider>
                </v-row>
            </v-card>
        `
    )
};