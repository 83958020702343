export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Check-Enabled" transform="translate(1.67 -41)">
                    <rect id="Retângulo_348" data-name="Retângulo 348" width="28" height="28" transform="translate(-1.67 41)" fill="currentColor" opacity="0"/>
                    <g id="check" transform="translate(-8.852 34.325)">
                        <path id="Caminho_643" data-name="Caminho 643" d="M29.717,11.346a2.236,2.236,0,1,1,3.193,3.131l-11.9,14.874a2.236,2.236,0,0,1-3.219.06L9.907,21.523a2.236,2.236,0,1,1,3.16-3.16L19.308,24.6,29.66,11.413a.7.7,0,0,1,.06-.065Z" transform="translate(0)" fill="currentColor"/>
                    </g>
                </g>
            </svg>      
        `
    )
};