export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Abrir-Disabled" transform="translate(4.791 -155)">
                    <rect id="Retângulo_341" data-name="Retângulo 341" width="28" height="28" transform="translate(-4.791 155)" fill="currentColor" opacity="0"/>
                    <g id="Abrir-icon-3_1_" transform="translate(0.01 160)">
                        <g id="Caminho_361-3_1_" transform="translate(0 0.2)">
                            <path id="Caminho_463" data-name="Caminho 463" d="M-188.8-107.5h-13.4a2.006,2.006,0,0,1-2-2v-13.4a2.094,2.094,0,0,1,2-2.1h4.3a.789.789,0,0,1,.8.8.736.736,0,0,1-.8.8h-4.3c-.3,0-.5.2-.5.6v13.4a.472.472,0,0,0,.5.5h13.4a.472.472,0,0,0,.5-.5v-4.3a.789.789,0,0,1,.8-.8.736.736,0,0,1,.8.8v4.3A2.157,2.157,0,0,1-188.8-107.5Z" transform="translate(204.2 125)" fill="currentColor"/>
                        </g>
                        <g id="Linha_29-3_1_" transform="translate(7.975 0.675)">
                            <path id="Caminho_464" data-name="Caminho 464" d="M-195.5-115.5a.764.764,0,0,1-.5-.2.785.785,0,0,1,0-1.1l7.5-7.5a.785.785,0,0,1,1.1,0,.785.785,0,0,1,0,1.1l-7.5,7.5A1.421,1.421,0,0,1-195.5-115.5Z" transform="translate(196.225 124.525)" fill="currentColor"/>
                        </g>
                        <g id="Caminho_362-3_1_" transform="translate(10)">
                            <path id="Caminho_465" data-name="Caminho 465" d="M-187.5-117.7a.789.789,0,0,1-.8-.8v-5.2l-5.1.1a.789.789,0,0,1-.8-.8.736.736,0,0,1,.8-.8h5.2a1.367,1.367,0,0,1,1.4,1.4v5.2C-186.8-118-187.1-117.7-187.5-117.7Z" transform="translate(194.2 125.2)" fill="currentColor"/>
                        </g>
                    </g>
                </g>
            </svg>      
        `
    )
};