export default {
    template: (
        /*html*/
        `
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8125 6C11.8125 9.21016 9.21016 11.8125 6 11.8125C2.78984 11.8125 0.1875 9.21016 0.1875 6C0.1875 2.78984 2.78984 0.1875 6 0.1875C9.21016 0.1875 11.8125 2.78984 11.8125 6ZM5.32767 9.07767L9.64017 4.76517C9.78661 4.61873 9.78661 4.38129 9.64017 4.23485L9.10985 3.70453C8.96341 3.55807 8.72597 3.55807 8.57951 3.70453L5.0625 7.22152L3.42049 5.57951C3.27405 5.43307 3.03661 5.43307 2.89015 5.57951L2.35983 6.10983C2.21339 6.25627 2.21339 6.49371 2.35983 6.64015L4.79733 9.07765C4.94379 9.22411 5.18121 9.22411 5.32767 9.07767Z" fill="currentColor"/>
        </svg>

        `
    )
};