import styled from "../../vue-styled-components.es";

const StyledSvg = styled("svg")`
    fill: currentColor
`;

export default {
    components: { "svg-custom": StyledSvg },
    template: (
        /*html*/
        `
        <svg-custom xmlns="http://www.w3.org/2000/svg" width="18" height="17.75" viewBox="0 0 18 17.75">
            <g id="Grupo_2838" data-name="Grupo 2838" transform="translate(0 0)">
                <path id="União_24" data-name="União 24" d="M2.963,16.715a.362.362,0,0,1-.2-.322V14.779a2.167,2.167,0,0,1-1.3-.714A2.245,2.245,0,0,1,.9,12.575V11A2.211,2.211,0,0,1,3.09,8.767H4.563v.715H3.09A1.5,1.5,0,0,0,1.615,11v1.579A1.5,1.5,0,0,0,3.09,14.089h.028a.358.358,0,0,1,.357.357v1.2l1.853-1.482a.357.357,0,0,1,.223-.079h4.7a1.5,1.5,0,0,0,1.475-1.514v-.094h.712v.094A2.211,2.211,0,0,1,10.254,14.8H5.674L3.341,16.672a.358.358,0,0,1-.378.043Zm11.27-3.477L11.9,11.37H7.319A2.211,2.211,0,0,1,5.132,9.141V7.562A2.211,2.211,0,0,1,7.319,5.333h7.164a2.211,2.211,0,0,1,2.187,2.229V9.141a2.249,2.249,0,0,1-.561,1.49,2.176,2.176,0,0,1-1.3.714v1.613a.358.358,0,0,1-.2.322.347.347,0,0,1-.155.036A.355.355,0,0,1,14.233,13.238ZM5.845,7.562V9.141a1.5,1.5,0,0,0,1.474,1.514h4.705a.359.359,0,0,1,.222.078L14.1,12.215v-1.2a.358.358,0,0,1,.357-.357h.028a1.5,1.5,0,0,0,1.475-1.514V7.562a1.5,1.5,0,0,0-1.475-1.514H7.319A1.5,1.5,0,0,0,5.845,7.562Zm-3.784.386a.36.36,0,0,1-.2-.322V6.012A2.176,2.176,0,0,1,.561,5.3,2.25,2.25,0,0,1,0,3.809V2.229A2.211,2.211,0,0,1,2.187,0H9.351a2.211,2.211,0,0,1,2.188,2.229V3.809a2.25,2.25,0,0,1-.367,1.236l-.052.079-.6-.393.052-.078a1.539,1.539,0,0,0,.25-.844V2.229A1.5,1.5,0,0,0,9.351.716H2.187A1.5,1.5,0,0,0,.713,2.229V3.809A1.5,1.5,0,0,0,2.187,5.322h.028a.357.357,0,0,1,.356.357v1.2L4.424,5.4a.364.364,0,0,1,.223-.078h.935v.715h-.81L2.438,7.906a.361.361,0,0,1-.222.078A.354.354,0,0,1,2.061,7.948Z" transform="translate(0 1)"/>
                <g id="Grupo_2837" data-name="Grupo 2837" transform="translate(10)">
                    <path id="Subtração_14" data-name="Subtração 14" d="M4098,7923.481a4,4,0,1,1,4-4A4.005,4.005,0,0,1,4098,7923.481Zm-1.875-4.47a.314.314,0,0,0-.312.314v.312a.313.313,0,0,0,.312.312h1.406v1.406a.313.313,0,0,0,.313.312h.312a.313.313,0,0,0,.312-.312v-1.406h1.408a.313.313,0,0,0,.312-.312v-.312a.314.314,0,0,0-.312-.314h-1.408V7917.6a.313.313,0,0,0-.312-.312h-.312a.313.313,0,0,0-.312.312v1.406Z" transform="translate(-4094 -7915.48)"/>
                </g>
            </g>
        </svg-custom>
        `
    )
};