import services from "../../http/index";
import * as types from "./mutations-types";

/**
 * Faz a requisi??o para a API ou para o Legado para pegar as informa??es de uma tabela
 *
 * @param {Object} params Parametros necess?rios para realizar a requisi??o
 * <pre>{
 *     request_to: String - PHP ou API - Obrigat?rio,
 *     url: String - Endpoint da URL para onde a requisi??o ser? realizada - Obrigat?rio
 *     method: String - Tipo da requisi??o (post, get, put) - Opcional, default = post
 *     data: Any - Conteudo da requisi??o - Opcional
 *     headers: Object - Headers a serem adicionados a requisi??o - Opcional,
 *     options: Object - Options do datatable
 * }</pre>
 * @returns {Object} Objeto com a lista de relatorios
 */
export const ActionDoGetDataTableServerSide = (context, params) => {
    let service = null;
    let request = {
        url: params.url,
        method: params.method ?? "post"
    };

    switch (params.request_to) {
    case "PHP":
        service = services.php;
        break;
    case "API":
        service = services.api;
        break;
    }

    if (params.data !== undefined) {
        request.data = {
            ...params.data,
            ...makeDataTableParams(params.options)
        };
    }
    if (params.headers !== undefined) {
        request.headers = params.headers;
    } else {
        request.headers = {
            "Content-Type": "application/json"
        };
    }

    return service
        .request(request)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            throw error;
        });
};

/**
 * Monta os parametros de options para utiliza��o em metodos getList do sistema legado
 *
 * @param {Object} options Objeto options do componente DataTable do vuetify
 * @return {Object} Objeto com as configuracoes necessarias do sistema legado
 */
function makeDataTableParams(options) {

    let config = {
        order: [],
        columns: [],
        start: (options.page * options.itemsPerPage) - options.itemsPerPage,
        length: options.itemsPerPage,
        page: options.page
    };

    if (options.sortBy && options.sortBy.length > 0) {
        for (let index in options.sortBy) {
            if (!isNaN(index)) {
                config.order.push({
                    column: index,
                    dir: options.sortDesc[index] ? "DESC" : "ASC"
                });

                config.columns.push({
                    data: options.sortBy[index],
                    name: "",
                    searchable: true,
                    orderable: true,
                    search: {
                        value: "",
                        regex: ""
                    }
                });
            } else {
                config.columns.push({
                    data: "",
                    name: "",
                    searchable: true,
                    orderable: true,
                    search: {
                        value: "",
                        regex: ""
                    }
                });
            }
        }
    }

    return config;
}

/**
 * Busca a lista de clientes da empresa definida na sessao do usuario
 *
 * @param {Object} context
 * @param {Boolean} for_the_select
 * @returns Objeto com a lista de clientes
 */
export const ActionDoGetMyClients = ({ commit }, for_the_select = false) => {
    return services.api.request({
        url: "/empresas/minhas-carteiras",
        method: "get"
    }).then((response) => {
        let resp = response.data;
        let product = getCookie("type_product");
        let clients_keys = {};
        let clients = [];

        for (let client of response.data) {
            if (
                (client.NM_PRODUTO_PAINEL_PPES == product ||
                    (product === "SMS" &&
                        !client.NM_PRODUTO_PAINEL_PPES)) &&
                clients_keys[client.ID_EMPRESA_EPES] === undefined
            ) {
                clients_keys[client.ID_EMPRESA_EPES] = true;
                clients.push({
                    text: client.NM_EMPRESA_EPES,
                    value: client.ID_EMPRESA_EPES
                });
            }
        }

        commit(types.SET_LIST_CLIENTS, resp);
        commit(types.SET_LIST_CLIENTS_FOR_SELECT, clients);
        commit(types.SET_CLIENTS_LOADING, false);

        if (!for_the_select) {
            return resp;
        } else {
            return clients;
        }
    });
};

/**
 * Busca as informa??es do usu?rio na sess?o da API
 *
 * @param {Object} context
 * @param {Boolean} for_the_select
 * @returns Objeto com as informa??es do usuario
 */
export const ActionDoGetUserInfo = () => {
    return services.api
        .request({
            url: "/auth/user",
            method: "post"
        })
        .then((response) => {
            return response.data;
        })
        .catch(error => {
            console.error("Error fetching user info", error);
            throw error;
        });
};

/**
 * Busca um determinado cookie salvo no navegador
 *
 * @param {string} name Nome do cookie a ser buscado
 * @return {string} Conteudo salvo no cookie
 */
function getCookie(name) {
    var cookie_name = name.trim() + "=";
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];

        while (cookie.charAt(0) == " ") {
            cookie = cookie.substring(1);
        }

        if (cookie.indexOf(cookie_name) == 0) {
            return cookie.substring(cookie_name.length, cookie.length);
        }
    }

    return "";
}

/**
 * Salva o status do tour no banco de dados
 *
 * @param {Object} context
 * @param {FormData} form_data
 * @returns {Promise}
 */
export const ActionDoSaveStatusTour = (context, form_data) => {
    return services.php
        .request({
            url: "/Tour/setEvento",
            method: "post",
            data: form_data
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

/**
 * Busca dados de interacao com o tour
 *
 * @param {Object} context
 * @param {FormData} form_data
 * @returns {Promise}
 */
export const ActionDoGetTourInteraction = (context, form_data) => {
    return services.php
        .request({
            url: "/Tour/getPermissao",
            method: "post",
            data: form_data
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            throw error;
        });
};

/**
 * Realiza requisi��o para enviar e-mail de suporte
 *
 * @param {Object} context
 * @param {Object} formData
 * @returns {Promise}
 */
export const ActionDoSendSupportContactMail = (context, formData) => {
    return services.api
        .request({
            url: "/utils/send-support-contact-mail",
            method: "POST",
            data: formData
        })
        .then((result) => {
            return result.data.response;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
};

/**
 * Faz a requisicao para a API Laravel para realizar o envio de um SMS avulso normal 
 * @param {Object} params Parametros para o envio de teste 
 * <pre>{
 *     telefones: Array telefones para o envio 
 *     id_carteira: Number id da empresa 
 *     mensagem: Mensagem da template
 * }</pre>
 * @returns response.data
 */
export const ActionDoSendSmsSimples = (context, params) => {
    return services.api.request({
        url: "/send-message/send-sms",
        method: "post",
        data: params
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

/**
 * Faz uma requisicao para a API Laravel, para obter os dados e detalhes de uma campanha
 * @param {Number} id_campaign 
 * @returns response.data
 */
export const ActionDoGetCampaignDetails = (context, id_campaign) => {
    return services.api.request({
        url: `/campaign/${id_campaign}`,
        method: "get"
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

/**
 * Busca as informacoes de uma empresa juntamente com as regras de horarios para criacao de campanhas
 * @param {Number} client_or_comany_id  ID de uma carteira ou empresa pai
 * @return response.data
 */
export const ActionDoGetClientOrCompanyInfo = (context, client_or_comany_id) => {
    return services.api.request({
        url: `/empresas/${client_or_comany_id}`,
        method: "get"
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

/**
 * Busca a lista de templates da empresa
 * @param {Object} body Dados para filtrar os templates
 * @return response.data
 */
export const ActionDoGetTemplateList = (context, body) => {
    return services.api.request({
        url: `/template-rcs/list`,
        method: "post",
        data: body
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};

/**
 * Busca a lista de layouts da empresa
 * @param {Object} context
 * @param {Object} body Dados para filtrar os layouts
 * @return response.data
 */
export const ActionDoGetLayoutList = (context, body) => {
    return services.api.request({
        url: `/layout/list`,
        method: "post",
        data: body
    }).then(response => {
        return response.data;
    }).catch(error => {
        console.log(error);
        throw error;
    });
};