export class ProdutoTema {
    icon_name;
    color;
    name;
    full_name;
}
/**
 *
 * @type {{whatsapp: ProdutoTema, rcs: ProdutoTema, "sms-concatenado": ProdutoTema, "sms-flash": ProdutoTema, sms: {color: string, name: string, icon_name: string}, "sms-smart": {color: string, name: string, icon_name: string}}}
 */
const temas_produto = {
    "rcs": { "color": "#6561A9", "icon_name": "icon-rcs", "full_name": "RCS", "name": "RCS"},
    "whatsapp": { "color": "#23986E", "icon_name": "icon-whatsapp", "full_name": "Whatsapp", "name": "Whatsapp"},
    "sms-flash": { "color": "#3899D7", "icon_name": "icon-flash", "full_name": "SMS Flash", "name": "Flash"},
    "flash": { "color": "#3899D7", "icon_name": "icon-flash", "full_name": "SMS Flash", "name": "Flash"},
    "sms-concatenado": { "color": "#B9BF14", "icon_name": "icon-sms-concatenado", "full_name": "SMS Concatenado", "name": "Concatenado"},
    "sms": { "color": "#95C230", "icon_name": "icon-sms", "full_name": "SMS Simples", "name": "SMS"},
    "hig": { "color": "#95C230", "icon_name": "icon-sms", "full_name": "SMS Simples", "name": "SMS"},
    "sms-smart": { "color": "#49BED8", "icon_name": "icon-smg", "full_name": "SMG SmartMessage", "name": "SMG"},
    "smg": { "color": "#49BED8", "icon_name": "icon-smg", "full_name": "SMG SmartMessage", "name": "SMG"},
    "sms-interativo": { "color": "#74A505", "icon_name": "icon-sms-interativo", "full_name": "SMS Interativo", "name": "Interativo"}
};

const cd_produto_traducao = {
    "RCS": "rcs",
    "WHATS": "whatsapp",
    "WTS": "whatsapp",
    "FLASH": "sms-flash",
    "SMS-C": "sms-concatenado",
    "SMS": "sms",
    "SMG": "sms-smart",
    "HIG": "sms",
    "SMS-I": "sms-interativo"
};

export class ProdutoUtils {

    /**
     * Busca o nome_da_classe de acordo com o nome do produto
     * @param type {'rcs'|'sms'|'sms-flash'|'sms-concatenado'|'sms-smart'|'whatsapp'} - qual o produto
     * @returns {ProdutoTema}
     */
    getTema(type) {
        return temas_produto[type];
    }
    /**
     * Retorna o nome do produto como est� sendo utilizado no front-end
     * @param produto_nome_banco {string} - Nome do produto como est� na coluna CD_PRODUTO_PDES
     */
    getProdutoTraducao(produto_nome_banco) {
        return cd_produto_traducao[produto_nome_banco.toUpperCase()];
    }
}