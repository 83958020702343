export default {
    name: "user-refresh-circle-icon",
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="30.573" height="26.3" viewBox="0 0 30.573 26.3">
                <g id="Icone_Repetidos" transform="translate(-29.483 -21.844)">
                    <g id="icone_repetidos-2" data-name="icone_repetidos" transform="translate(25.976 16.99)">
                        <path id="Path_607" data-name="Path 607" d="M55.93,45.1a.49.49,0,0,0-.5.48h0A2.46,2.46,0,0,1,53,48.05H50.8L52.28,47a.494.494,0,0,0-.58-.8l-3.26,2.36,3.26,2.37A.43.43,0,0,0,52,51a.54.54,0,0,0,.4-.2.5.5,0,0,0-.11-.69L50.79,49H53a3.45,3.45,0,0,0,3.45-3.45.49.49,0,0,0-.52-.45Z" transform="translate(-22.37 -22.15)" fill="none"/>
                        <path id="Path_608" data-name="Path 608" d="M52,43.62,50.49,44.7a.46.46,0,0,0-.2.4.49.49,0,0,0,.49.49.48.48,0,0,0,.28-.1l3.26-2.37L51,40.76a.5.5,0,0,0-.69.12.49.49,0,0,0,.12.68L52,42.64H49.77a3.44,3.44,0,0,0-3.44,3.44.5.5,0,0,0,1,0,2.46,2.46,0,0,1,2.46-2.46Z" transform="translate(-22.37 -22.15)" fill="none"/>
                        <path id="Path_610" data-name="Path 610" d="M43.5,50.2q.16.3.36.6Q43.66,50.5,43.5,50.2Z" transform="translate(-22.37 -22.15)" fill="currentColor"/>
                        <path id="Path_611" data-name="Path 611" d="M42.42,46.69a7.921,7.921,0,0,1-.05-.85,5.616,5.616,0,0,1,.05-.92c0,.3-.05.61-.05.92A7.92,7.92,0,0,0,42.42,46.69Z" transform="translate(-22.37 -22.15)" fill="currentColor"/>
                        <path id="Path_612" data-name="Path 612" d="M42.44,46.91a3.441,3.441,0,0,0,.1.63C42.5,47.33,42.47,47.12,42.44,46.91Z" transform="translate(-22.37 -22.15)" fill="currentColor"/>
                        <path id="Union_16" data-name="Union 16" d="M322.531-1973.795a12.494,12.494,0,0,1-1.881-14.884,12.512,12.512,0,0,1,13.774-5.967,12.5,12.5,0,0,1,9.585,11.547,5.27,5.27,0,0,0-.782-.133,11.721,11.721,0,0,0-9.052-10.674,11.73,11.73,0,0,0-12.83,5.6,11.714,11.714,0,0,0,.523,12.462l3.584-.85h.072l2.616-1.461a1.485,1.485,0,0,0,.735-1.336v-1.335l-.078-.095a5.973,5.973,0,0,1-1.064-2.21v-.141l-.125-.078a.579.579,0,0,1-.274-.492v-1.227a.577.577,0,0,1,.2-.437l.117-.1v-2.031a2.147,2.147,0,0,1,.492-1.564,3.339,3.339,0,0,1,2.611-.882,3.385,3.385,0,0,1,2.61.875,2.229,2.229,0,0,1,.5,1.564v2.022l.117.1a.585.585,0,0,1,.195.437v1.258a.58.58,0,0,1-.421.555l-.172.055-.055.179a6.967,6.967,0,0,1-.891,1.789c-.086.117-.172.226-.267.335l-.086.1v1.32a1.509,1.509,0,0,0,.829,1.344l2.914,1.453h.095l.042.047h.013l-.009,0c.446.5.892,1,1.346,1.512a13.17,13.17,0,0,1,1.517,1.766c-.329.046-.555.468-.782.679a7.427,7.427,0,0,1-1.11.78l-.137.082q.166.2.347.387a12.485,12.485,0,0,1-5.836,1.447A12.506,12.506,0,0,1,322.531-1973.795Z" transform="translate(-315.502 1999.844)" fill="currentColor"/>
                        <path id="Path_614" data-name="Path 614" d="M42.79,48.56a9.14,9.14,0,0,0,.66,1.55,8.5,8.5,0,0,1-.66-1.55Z" transform="translate(-22.37 -22.15)" fill="currentColor"/>
                        <path id="Path_615" data-name="Path 615" d="M42.58,47.76c.05.22.11.44.17.65C42.69,48.2,42.63,48,42.58,47.76Z" transform="translate(-22.37 -22.15)" fill="currentColor"/>
                        <path id="Path_616" data-name="Path 616" d="M51.368,37.027c-.271-.058-.542-.107-.822-.14a6.851,6.851,0,0,0-.822,0,7.4,7.4,0,0,0-7.354,6.639c0,.246-.041.5-.041.756a6.509,6.509,0,0,0,.041.7,1.234,1.234,0,0,0,0,.181,2.827,2.827,0,0,0,.082.518c.033.173,0,.123,0,.181a5.315,5.315,0,0,0,.14.534v.123a6.983,6.983,0,0,0,.542,1.274.337.337,0,0,0,0,.074q.131.246.3.493l.271.123a7.741,7.741,0,0,0,.69.822,7.395,7.395,0,1,0,6.992-12.275Zm-4.93,7.395a.411.411,0,1,1-.822,0,2.826,2.826,0,0,1,2.826-2.826h1.832l-1.216-.887a.4.4,0,0,1-.1-.559.411.411,0,0,1,.567-.1l2.695,1.939-2.678,1.947a.394.394,0,0,1-.23.082.4.4,0,0,1-.4-.4.378.378,0,0,1,.164-.329l1.2-.871H48.459a2.021,2.021,0,0,0-2.021,2.021ZM51.1,46.837H49.281l1.224.887a.411.411,0,0,1,.09.567.444.444,0,0,1-.329.164.353.353,0,0,1-.238-.082L47.35,46.426l2.678-1.939a.406.406,0,1,1,.477.657l-1.216.912H51.1a2.021,2.021,0,0,0,2.021-2.021h0a.411.411,0,1,1,.822,0A2.835,2.835,0,0,1,51.1,46.837Z" transform="translate(-23.105 -20.487)" fill="currentColor"/>
                    </g>
                </g>
            </svg>      
        `
    )
};