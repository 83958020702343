/**
 * Componente de apresenta��o de mensagem de texto dentro o celular
 * @displayName ChatMessage
 * @component
 * @vue
 * @author Igor Mateus Rodrigues de Oliveira <igor.oliveira@pgmais.com.br>
 * @vue/component
 */
export default {
    name: "chat-message",
    props: {
        /**
         *  Informa se o avatar vai ficar a esquerda do campo de texto
         */
        iconLeft: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    template:
    /*html*/
    ` 
        <v-row no-gutters>
            <v-col v-if="iconLeft" class="pr-2" cols="auto">
                <v-avatar color="super-dark-grey" size="30"></v-avatar> 
            </v-col>
            <v-col>
                <v-card elevation="0" class="rounded-lg bg-of-white text-left px-3 pt-2" min-height="72">
                    <slot></slot> 
                </v-card>
            </v-col>
            <v-col v-if="!iconLeft" class="pl-0" cols="auto">
                <v-avatar color="super-dark-grey" size="30"></v-avatar> 
            </v-col>
        </v-row>
    `,
};