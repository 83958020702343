export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Excluir-Hover" transform="translate(5.667 -98)">
                    <rect id="Retângulo_361" data-name="Retângulo 361" width="28" height="28" transform="translate(-5.667 98)" fill="currentColor" opacity="0"/>
                    <g id="Grupo_1688_1_" transform="translate(0.333 100.7)">
                        <path id="Caminho_522" data-name="Caminho 522" d="M43.1,408.3h0l5.2-.5h.1l3.6-.4h.1l5-.5a.752.752,0,0,0-.1-1.5l-4.3.4-.1-1.1a.55.55,0,0,0-.3-.5,1.689,1.689,0,0,0-.5-.2l-3.6.4a.773.773,0,0,0-.7.8l.1,1.1-4.5.5a.773.773,0,0,0-.7.8A.684.684,0,0,0,43.1,408.3Zm5.9-2.5,2.1-.2v.4l-2.1.2Z" transform="translate(-42.4 -404)" fill="currentColor"/>
                        <path id="Caminho_523" data-name="Caminho 523" d="M57.6,410.7c-.1-.2-.3-.2-.5-.2h-14a.764.764,0,0,0-.5.2,1.421,1.421,0,0,0-.2.6l.9,12.6a2.506,2.506,0,0,0,2.5,2.3h8.9a2.506,2.506,0,0,0,2.5-2.3l.9-12.6C57.8,411,57.8,410.8,57.6,410.7ZM47,424.1h0a.773.773,0,0,1-.8-.7l-.5-10.2a.752.752,0,1,1,1.5-.1l.5,10.2A.713.713,0,0,1,47,424.1Zm3.8-.7a.789.789,0,0,1-.8.8.736.736,0,0,1-.8-.8V413.2a.789.789,0,0,1,.8-.8.736.736,0,0,1,.8.8Zm3.2,0a.751.751,0,0,1-.7.7h0a.773.773,0,0,1-.7-.8l.5-10.2a.752.752,0,1,1,1.5.1Z" transform="translate(-42.4 -404)" fill="currentColor"/>
                    </g>
                </g>
            </svg>
        `
    )
};