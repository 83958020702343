/**
 * Classe que representa um item ButtonCheckbox
 */
export default class ButtonCheckboxClass {
    id
    label
    icon
    checked
    backgroundColor
    backgroundColorHover
    backgroundColorChecked
    contentColor
    contentColorHover
    contentColorChecked
    tooltipText
    disabled

    /**
     * Cria um ButtonCheckbox
     * @param {String} id - id do botao
     * @param {String} label - Label do botao
     * @param {String} icon - Icone do botao
     * @param {Boolean} checked - Indica se o botao esta selecionado
     * @param {String} backgroundColor - Cor de fundo
     * @param {String} backgroundColorHover - Cor de fundo em hover
     * @param {String} backgroundColorChecked - Cor de fundo selecionado
     * @param {String} contentColor - Cor do texto
     * @param {String} contentColorHover - Cor do texto em hover
     * @param {String} contentColorChecked - Cor do texto selecionado
     * @param {String} tooltipText - Texto do tooltip
     * @param {Boolean} disabled - Indica se ficar� desabilitado
     */
    constructor({
        id = null,
        label,
        icon,
        checked,
        backgroundColor = "off-white",
        backgroundColorHover = "extra-light-grey",
        backgroundColorChecked = "dark-primary",
        contentColor = "black",
        contentColorHover = "black",
        contentColorChecked = "off-white",
        tooltipText = null,
        disabled = false
    }) {
        this.id = id ? id : Math.random();
        this.label = label;
        this.icon = icon;
        this.checked = checked;
        this.backgroundColor = backgroundColor;
        this.backgroundColorHover = backgroundColorHover;
        this.backgroundColorChecked = backgroundColorChecked;
        this.contentColor = contentColor;
        this.contentColorHover = contentColorHover;
        this.contentColorChecked = contentColorChecked;
        this.tooltipText = tooltipText;
        this.disabled = disabled;
    }

    get id() {
        return this.id;
    }

    set id(id) {
        this.id = id;
    }

    get label() {
        return this.label;
    }

    set label(label) {
        this.label = label;
    }

    get icon() {
        return this.icon;
    }

    set icon(icon) {
        this.icon = icon;
    }

    get checked() {
        return this.checked;
    }

    set checked(checked) {
        this.checked = checked;
    }

    get backgroundColor() {
        return this.backgroundColor;
    }

    set backgroundColor(backgroundColor) {
        this.backgroundColor = backgroundColor;
    }

    get backgroundColorHover() {
        return this.backgroundColorHover;
    }

    set backgroundColorHover(backgroundColorHover) {
        this.backgroundColorHover = backgroundColorHover;
    }

    get backgroundColorChecked() {
        return this.backgroundColorChecked;
    }

    set backgroundColorChecked(backgroundColorChecked) {
        this.backgroundColorChecked = backgroundColorChecked;
    }

    get contentColor() {
        return this.contentColor;
    }

    set contentColor(contentColor) {
        this.contentColor = contentColor;
    }

    get contentColorHover() {
        return this.contentColorHover;
    }

    set contentColorHover(contentColorHover) {
        this.contentColorHover = contentColorHover;
    }

    get contentColorChecked() {
        return this.contentColorChecked;
    }

    set contentColorChecked(contentColorChecked) {
        this.contentColorChecked = contentColorChecked;
    }

    get tooltipText() {
        return this.tooltipText;
    }

    set tooltipText(tooltipText) {
        this.tooltipText = tooltipText;
    }

    get disabled() {
        return this.disabled;
    }

    set disabled(disabled) {
        this.disabled = disabled;
    }
}