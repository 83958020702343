import ButtonFormStep from "../../components/atomos/ButtonFormStep?v=2";
import PageHeader from "../../components/moleculas/PageHeader?v=2";
import FooterAction from "../../components/moleculas/FooterAction?v=2";
import ReportStepOne from "./components/ReportStepOne?v=2";
import ReportStepTwo from "./components/ReportStepTwo";
import ReportStepThree from "./components/ReportStepThree";
import ReportStepFour from "./components/ReportStepFour";
import ReportStepFive from "./components/ReportStepFive";
import ReportStepSix from "./components/ReportStepSix?v=2";
import DialogNotifyReportGeneration from "./components/dialogs/DialogNotifyReportGeneration";
import BaseNotify from "../../components/atomos/BaseNotify?v=2";
import PgTour from "../../components/organismos/PgTour?v=2";
import { FormStepClass } from "../../classes/FormStepClass";
import { BaseService } from "../../services/BaseService";
import * as types from "./store/mutations-types";

const base_service = new BaseService();

/**
 * Pagina do gerador de relatorios
 *
 * @requires {@link ButtonFormStep}
 * @requires {@link FooterAction}
 * @requires {@link ReportStepOne}
 * @requires {@link ReportStepTwo}
 * @requires {@link ReportStepThree}
 * @requires {@link ReportStepFour}
 * @requires {@link ReportStepFive}
 * @requires {@link ReportStepSix}
 * @requires {@link DialogNotifyReportGeneration}
 * @requires {@link BaseNotify}
 * @requires {@link PgTour}
 * @requires {@link FormStepClass}
 * @requires {@link BaseService}
 *
 * @displayName ReportGeneratorPage
 * @category Page / Relatorios
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        ButtonFormStep,
        PageHeader,
        FooterAction,
        ReportStepOne,
        ReportStepTwo,
        ReportStepThree,
        ReportStepFour,
        ReportStepFive,
        ReportStepSix,
        DialogNotifyReportGeneration,
        BaseNotify,
        PgTour
    },
    data() {
        return {
            steps: [
                new FormStepClass({
                    step: 1,
                    step_display: 1,
                    title: "Modalidade",
                    ready: false,
                    active: true,
                    disabled: false,
                    line: false
                }),
                new FormStepClass({
                    step: 2,
                    step_display: 2,
                    title: "Per&iacute;odo",
                    ready: false,
                    active: false,
                    disabled: true,
                    line: false
                }),
                new FormStepClass({
                    step: 3,
                    step_display: 3,
                    title: "Clientes",
                    ready: false,
                    active: false,
                    disabled: true,
                    line: false
                }),
                new FormStepClass({
                    step: 4,
                    step_display: 4,
                    title: "Campanhas",
                    ready: false,
                    active: false,
                    disabled: true,
                    line: false
                }),
                new FormStepClass({
                    step: 5,
                    step_display: 5,
                    title: "Dados adicionais",
                    ready: false,
                    active: false,
                    disabled: true,
                    line: false
                }),
                new FormStepClass({
                    step: 6,
                    step_display: 6,
                    title: "Revise e gere o relat&oacute;rio",
                    ready: false,
                    active: false,
                    disabled: true,
                    line: false
                })
            ],
            footer_config: {
                1: {
                    right_button: {
                        text: "2 | Escolha o Per&iacute;odo",
                        disabled: true
                    },
                    left_button: { text: null, sub_text: null }
                },
                2: {
                    right_button: {
                        text: "3 | Escolha os clientes",
                        disabled: true
                    },
                    left_button: { text: "Voltar", sub_text: "1. Modalidade" }
                },
                3: {
                    right_button: {
                        text: "4 | Escolha as campanhas",
                        disabled: true
                    },
                    left_button: {
                        text: "Voltar",
                        sub_text: "2. Per&iacute;odo"
                    }
                },
                4: {
                    right_button: {
                        text: "5 | Ver dados adicionais",
                        disabled: true
                    },
                    left_button: { text: "Voltar", sub_text: "3. Clientes" }
                },
                5: {
                    right_button: {
                        text: "6 | Revisar e gerar o relat&oacute;rio",
                        disabled: true
                    },
                    left_button: { text: "Voltar", sub_text: "4. Campanhas" }
                },
                6: {
                    right_button: {
                        text: "Gerar relat&oacute;rio",
                        disabled: false
                    },
                    left_button: {
                        text: "Voltar",
                        sub_text: "5. Dados adicionais"
                    }
                }
            },
            dialog_notify: false,
            notify_data: {},
            show_notify: false,
            tooltips_text: {
                1: {
                    1: {
                        title: window.Vue.htmlEntities(
                            "Escolha o tipo de relat&oacute;rio que deseja extrair"
                        ),
                        description: window.Vue.htmlEntities(
                            "Informa&ccedil;&otilde;es coletadas no primeiro passo"
                        )
                    },
                    2: {
                        title: "Navegue para o segundo passo",
                        description: null
                    },
                    3: {
                        title: window.Vue.htmlEntities(
                            "Esse passo n&atilde;o est&aacute; dispon&iacute;vel no momento"
                        ),
                        description: null
                    }
                },
                2: {
                    1: {
                        title: "Navegue para o primeiro passo",
                        description: null
                    },
                    2: {
                        title: window.Vue.htmlEntities(
                            "Escolha o per&iacute;odo que deseja extrair dados"
                        ),
                        description: window.Vue.htmlEntities(
                            "Informa&ccedil;&otilde;es coletadas no segundo passo"
                        )
                    },
                    3: {
                        title: "Navegue para o terceiro passo",
                        description: null
                    }
                }
            }
        };
    },
    computed: {
        ...window.Vuex.mapState("reports", {
            all_campaigns: (state) => state.all_campaigns,
            generator_step_one: (state) => state.generator_step_one,
            generator_step_two: (state) => state.generator_step_two,
            generator_step_three: (state) => state.generator_step_three,
            generator_step_four: (state) => state.generator_step_four,
            generator_step_five: (state) => state.generator_step_five,
            generator_step_six: (state) => state.generator_step_six
        }),
        step_active() {
            for (let step of this.steps) {
                if (step.active) {
                    return step;
                }
            }

            return null;
        },
        steps_tour() {
            let tour = [];

            switch (this.step_active.step) {
            case 1:
                tour = [
                    {
                        target: "[data-v-step='1']",
                        title: "Passo-a-passo",
                        content: "<p>Utilize o passo-a-passo para navegar entre as diferentes etapas do gerador de relat&oacute;rios e visualizar os dados preenchidos em cada etapa. </p><p>Habilite as pr&oacute;ximas etapas preenchendo informa&ccedil;&otilde;es obrigat&oacute;rias da etapa em que voc&ecirc; se encontra.</p>",
                        date: "2022-10-07 00:00:00"
                    },
                    {
                        target: "[data-v-step='7']",
                        title: "Escolha o tipo de relat&oacute;rio que deseja extrair",
                        content: "Escolha o <b>sint&eacute;tico</b> caso queira um relat&oacute;rio resumido demonstrando uma vis&atilde;o geral. Ou escolha o <b>anal&iacute;tico</b> para informa&ccedil;&otilde;es detalhadas de cada envio."
                    }
                ];
                break;
            case 2:
                tour = [
                    {
                        target: "[data-v-step='2']",
                        title: "Per&iacute;odo",
                        content: "Escolha uma das op&ccedil;&otilde;es de per&iacute;odo para extrair seu relat&oacute;rio.",
                        date: "2022-10-07 00:00:00"
                    },
                    {
                        target: "[data-v-step='3']",
                        title: "Calend&aacute;rio",
                        content: "<p>Utilize o calend&aacute;rio para visualizar o per&iacute;odo  escolhido.</p><p>Caso escolha uma data espec&iacute;fica, utilize o formul&aacute;rio &ldquo;Data selecionada&rdquo; para escolher uma data. </p>",
                        before: () => {
                            if (this.generator_step_one.type_period === undefined) {
                                document.querySelector("#cardLargeChoiceseven-days .v-card").click();
                            }
                        }
                    },
                    {
                        target: "[data-v-step='4']",
                        title: "Repetir o relat&oacute;rio",
                        content: "<p>Selecione essa op&ccedil;&atilde;o para automatizar o gerador de relat&oacute;rio.</p><p>Ao selecionar essa op&ccedil;&atilde;o, voc&ecirc; n&atilde;o poder&aacute; selecionar campanhas espec&iacute;ficas.</p>"
                    },
                    {
                        target: "[data-v-step='5']",
                        title: "Pr&oacute;ximo passo habilitado",
                        content: "<p>Ao preencher todos os campos obrigat&oacute;rios e escolher a configura&ccedil;&atilde;o de desejada, o pr&oacute;ximo passo ser&aacute; habilitado.</p>"
                    },
                    {
                        target: "[data-v-step='footerActionRight']",
                        title: "Pr&oacute;ximo passo habilitado",
                        content: "<p>Utilize o passo-a-passo ou bot&atilde;o para prosseguir.</p>"
                    }
                ];
                break;
            case 3:
                tour = [
                    {
                        target: "[data-v-step='8']",
                        title: "Selecione os clientes",
                        content: "<p>Neste momento voc&ecirc; poder&aacute; selecionar os clientes que deseja extrair os dados.</p><p>Se desejar, selecione todos os clientes clicando na caixa de sele&ccedil;&atilde;o.</p>",
                        date: "2022-10-07 00:00:00"
                    },
                    {
                        target: "[data-v-step='9']",
                        title: "Pesquise um cliente por nome",
                        content: "Voc&ecirc; pode procurar por um cliente espec&iacute;fico que n&atilde;o encontrou na listagem."
                    }
                ];
                break;
            case 4:
                tour = [
                    {
                        target: "[data-v-step='10']",
                        title: "Selecione as campanhas",
                        content: "Neste momento voc&ecirc; poder&aacute; selecionar as campanhas que deseja extrair os dados.",
                        date: "2022-10-07 00:00:00"
                    },
                    {
                        target: "[data-v-step='11']",
                        title: "Pesquise uma campanha por nome",
                        content: "Voc&ecirc; pode procurar por uma campanha espec&iacute;fica que n&atilde;o encontrou na tabela."
                    }
                ];
                break;
            case 5:
                tour = [
                    {
                        target: "[data-v-step='12']",
                        title: "Selecione dados adicionais",
                        content: "Escolha dados adicionais para incluir em seu relat&oacute;rio",
                        date: "2022-10-07 00:00:00"
                    }
                ];

                if (this.generator_step_two.report_type !== "analytical") {
                    tour.push({
                        target: "[data-v-step='13']",
                        title: "Agrupar por dia",
                        content: "Ao selecionar essa op&ccedil;&atilde;o, seu relat&oacute;rio ir&aacute; agrupar os envios por dia."
                    });
                }
                break;
            case 6:
                tour = [
                    {
                        target: "[data-v-step='14']",
                        title: "Resumo do seu relat&oacute;rio",
                        content: "<p>Neste momento voc&ecirc; poder&aacute; conferir toda a configura&ccedil;&atilde;o feita no seu relat&oacute;rio.</p><p>Voc&ecirc; pode voltar para os passos anteriores para editar a configura&ccedil;&atilde;o a qualquer momento.</p>",
                        date: "2022-10-07 00:00:00"
                    },
                    {
                        target: "[data-v-step='buttonDeleteReview']",
                        title: "Comece tudo do zero",
                        content: "<p>Voc&ecirc; pode limpar tudo que configurou e preencheu e come&ccedil;ar do zero novamente.</p><p><b>Aten&ccedil;&atilde;o:</b> Utilizando esse bot&atilde;o, voc&ecirc; perder&aacute; tudo que fez at&eacute; aqui.</p>"
                    },
                    {
                        target: "[data-v-step='16']",
                        title: "Selecione a op&ccedil;&atilde;o que deseja receber o arquivo",
                        content: "<p>Voc&ecirc; pode escolher entre receber por email ou baixar diretamente no painel</p>"
                    }
                ];
                break;
            }

            return tour;
        },
        repeatReportStepOne() {
            return this.generator_step_one.recurrence_report;
        }
    },
    watch: {
        repeatReportStepOne() {
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_SIX}`, {});
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_FIVE}`, {});
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_FOUR}`, {});
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_THREE}`, {});
        },
        generator_step_one() {
            if (this.checkStepOne()) {
                if (!this.checkStepTwo()) {
                    this.toggleStepReady(2, true);
                }
            } else {
                this.toggleStepReady(2, false);
            }

            if (this.generator_step_one.recurrence_report) {
                this.steps[3].hidden = true;
                this.steps[4].step_display = 4;
                this.steps[5].step_display = 5;

                this.footer_config[3].right_button.text =
                    "4 | Ver dados adicionais";
                this.footer_config[5].right_button.text =
                    "5 | Revisar e gerar o relat&oacute;rio";
                this.footer_config[5].left_button.sub_text = "3. Clientes";
                this.footer_config[6].left_button.sub_text =
                    "4. Dados adicionais";
            } else {
                this.steps[3].hidden = false;
                this.steps[4].step_display = 5;
                this.steps[5].step_display = 6;

                this.footer_config[3].right_button.text =
                    "4 | Escolha as campanhas";
                this.footer_config[5].right_button.text =
                    "6 | Revisar e gerar o relat&oacute;rio";
                this.footer_config[5].left_button.sub_text = "4. Campanhas";
                this.footer_config[6].left_button.sub_text =
                    "5. Dados adicionais";
            }
        },
        generator_step_two() {
            if (this.checkStepTwo()) {
                if (!this.checkStepThree()) {
                    this.toggleStepReady(3, true);
                }
            } else {
                this.toggleStepReady(3, false);
            }
        },
        generator_step_three() {
            if (this.checkStepThree()) {
                if (!this.checkStepFour()) {
                    if (this.generator_step_one.recurrence_report) {
                        this.toggleStepReady(5, true);
                    } else {
                        this.toggleStepReady(4, true);
                    }
                } else {
                    this.toggleStepReady(4, true);
                }
            } else {
                if (this.generator_step_one.recurrence_report) {
                    this.toggleStepReady(5, false);
                } else {
                    this.toggleStepReady(4, false);
                    this.toggleStepReady(5, false);
                    this.toggleStepReady(6, false);
                }
            }
        },
        generator_step_four() {
            this.checkStepFive();

            if (this.checkStepFour()) {
                this.toggleStepReady(5, true);
                this.toggleStepReady(6, true);
            } else {
                this.toggleStepReady(6, false);
                this.toggleStepReady(5, false);
            }
        },
        generator_step_five() {
            if (this.checkStepFive()) {
                this.toggleStepReady(6, true);
            } else {
                this.toggleStepReady(6, false);
            }
        },
        generator_step_six() {
            this.checkStepSix();
        }
    },
    methods: {
        ...window.Vuex.mapActions("reports", ["ActionDoGenerateReport"]),
        /**
         * Monta a descricao do passo um e verifica se os dados foram preenchidos corretamente
         * @returns {Boolean}
         * @vue
         */
        checkStepOne() {
            let report_types = {
                analytical: "Analitico",
                synthetic: window.Vue.htmlEntities("Sint&eacute;tico")
            };
            this.steps[0].description = [];

            if (
                this.generator_step_one.report_type !== undefined &&
                Object.keys(report_types).indexOf(
                    this.generator_step_one.report_type
                ) > -1
            ) {
                this.steps[0].description.push(
                    report_types[this.generator_step_one.report_type]
                );
                return true;
            } else {
                return false;
            }
        },
        /**
         * Monta a descricao do passo dois e verifica se os dados foram preenchidos corretamente
         * @returns {Boolean}
         * @vue
         */
        checkStepTwo() {
            if (this.generator_step_two?.date_invalid) {
                return false;
            }

            let types = {
                "seven-days": "7 dias",
                "thirty-days": "30 dias",
                "yesterday": "Ontem",
                "specific-date": "Data especifica"
            };
            this.steps[1].description = [];

            // Verifica se o periodo selecionado eh valido
            if (!Object.keys(types).includes(this.generator_step_two.type_period)) {
                return false;
            }

            this.steps[1].description.push(types[this.generator_step_two.type_period]);

            // Verifica se o tipo de relat�rio � analytical ou synthetic e se o periodo � specific-date
            if ((this.generator_step_one.report_type === "analytical" || this.generator_step_one.report_type === "synthetic") &&
                this.generator_step_two.type_period === "specific-date") {
                if (this.generator_step_two.period_selected && this.generator_step_two.period_selected.length === 2) {
                    let validDates = window.Vue.isValidDateUsa(this.generator_step_two.period_selected[0]) && window.Vue.isValidDateUsa(this.generator_step_two.period_selected[1]);
                    let dateRangeCheck = this.generator_step_one.report_type === "analytical" ?
                        window.Vue.dateIsAfter(this.generator_step_two.period_selected[0], dayjs().subtract(61, "days").format("YYYY-MM-DD")) && window.Vue.dateIsAfter(this.generator_step_two.period_selected[1], dayjs().subtract(61, "days").format("YYYY-MM-DD")) :
                        window.Vue.dateIsAfter(this.generator_step_two.period_selected[0], (dayjs().add(-1, "day")).subtract(366, "days")) && window.Vue.dateIsAfter(this.generator_step_two.period_selected[1], (dayjs().add(-1, "day")).subtract(366, "days").format("YYYY-MM-DD"));
                    let dateFutureCheck = this.generator_step_one.report_type !== "analytical" ? window.Vue.dateIsBefore(this.generator_step_two.period_selected[0], dayjs().format("YYYY-MM-DD")) && window.Vue.dateIsBefore(this.generator_step_two.period_selected[1], dayjs().format("YYYY-MM-DD")) :
                        window.Vue.dateIsBefore(this.generator_step_two.period_selected[0], dayjs().add(1, "day").format("YYYY-MM-DD")) && window.Vue.dateIsBefore(this.generator_step_two.period_selected[1], dayjs().add(1, "day").format("YYYY-MM-DD"));

                    return validDates && dateRangeCheck && dateFutureCheck;
                }
                return false;
            }

            return true;
        },
        /**
         * Monta a descricao do passo tres e verifica se os dados foram preenchidos corretamente
         * @returns {Boolean}
         * @vue
         */
        checkStepThree() {
            this.steps[2].description = [];

            if (
                this.generator_step_three.clients !== undefined &&
                this.generator_step_three.clients.length > 0
            ) {
                this.steps[2].description.push(
                    this.generator_step_three.clients.length + " cliente(s)"
                );
                return true;
            } else {
                return false;
            }
        },
        /**
         * Monta a descricao do passo quatro e verifica se os dados foram preenchidos corretamente
         * @returns {Boolean}
         * @vue
         */
        checkStepFour() {
            this.steps[3].description = [];
            if (
                (this.generator_step_four.campaigns !== undefined &&
                this.generator_step_four.campaigns.length > 0) ||
                this.generator_step_four.only_single_api_messages
            ) {
                if (
                    this.generator_step_four.campaigns !== undefined &&
                    this.generator_step_four.campaigns.length > 0
                ) {
                    this.steps[3].description.push(
                        this.generator_step_four.campaigns.length + " campanha(s)"
                    );
                } else {
                    this.steps[3].description.push(
                        "Apenas mensagens Avulsas/API"
                    );
                }

                return true;
            } else {
                return false;
            }
        },
        /**
         * Monta a descricao do passo cinco e verifica se os dados foram preenchidos corretamente
         * @returns {Boolean}
         * @vue
         */
        checkStepFive() {
            this.steps[4].description = [];

            if (
                this.generator_step_five.aditional_data !== undefined &&
                this.generator_step_five.aditional_data.length > 0
            ) {
                this.steps[4].description.push(
                    this.generator_step_five.aditional_data.length +
                        " dados adicionais"
                );
            }
            if (this.generator_step_five.agroup === true) {
                this.steps[4].description.push("Agrupar por dia");
            }

            if (this.step_active.step === 4 || this.step_active.step === 5) {
                return true;
            } else {
                return false;
            }
        },
        /**
         * Monta a descricao do passo seis e verifica se os dados foram preenchidos corretamente
         * @returns {Boolean}
         * @vue
         */
        checkStepSix() {
            if (this.step_active.step === 6) {
                this.steps[5].description = [];

                if (!this.generator_step_six.form_valid) {
                    this.footer_config[6].right_button.disabled = true;
                } else {
                    this.footer_config[6].right_button.disabled = false;
                }

                if (
                    this.generator_step_six.email_attach !== undefined &&
                    this.generator_step_six.email_attach !== ""
                ) {
                    this.steps[5].description.push("Receber via email");
                }

                return true;
            } else {
                return false;
            }
        },
        /**
         * Leva o usuario para um passo especifico
         * @param {Number} step Numero do passo para o qual o usuario deve ser direcionado
         * @vue
         */
        goToStep(step) {
            if (step === 7) {
                this.dialog_notify = true;
            } else {
                for (let item of this.steps) {
                    if (item.step !== step) {
                        item.active = false;
                    } else {
                        item.active = true;
                    }
                }
            }
        },
        /**
         * Altera o estado de pronto de um passo
         * @param {Number} step Numero do passo
         * @param {Boolean} ready
         * @vue
         */
        toggleStepReady(step, ready) {
            if (this.generator_step_one.recurrence_report && step === 5) {
                this.footer_config[step - 2].right_button.disabled = !ready;
            } else {
                this.footer_config[step - 1].right_button.disabled = !ready;
            }

            let new_steps = [];
            for (let item of this.steps) {
                item.ready = false;

                if (item.step === step) {
                    item.ready = ready;
                    item.disabled = !ready;
                }

                if (item.step === step - 1) {
                    item.line = ready;
                }

                if (
                    this.generator_step_one.recurrence_report &&
                    step === 5 &&
                    item.step === 3
                ) {
                    item.line = ready;
                }

                new_steps.push(item);
            }

            this.steps = new_steps;
        },
        /**
         * Metodo chamado sempre que o usuario limpar todos os dados, limpa as informacoes de ativo ou pronto de todos os passos e direciona para o passo um
         * @vue
         */
        clearForm() {
            for (let step of this.steps) {
                step.description = [];
                step.ready = false;
                step.active = false;
            }

            this.goToStep(1);
        },
        /**
         * Metodo responsavel por montar os dados escolhidos pelo usuario e mandar para a API gerar o relatorio
         * @vue
         */
        generateReport() {
            this.show_notify = true;
            let today = new Date();

            let day = String(today.getDate()).padStart(2, "0");
            let month = String(today.getMonth() + 1).padStart(2, "0");
            let year = today.getFullYear();

            let hour = String(today.getHours()).padStart(2, "0");
            let minute = String(today.getMinutes()).padStart(2, "0");
            let second = String(today.getSeconds()).padStart(2, "0");

            let date = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

            let params = {
                requested_from: "maisconnect",
                notification_to: this.notify_data.notification_to,
                notification_type:
                    this.notify_data.notification_type != "no"
                        ? this.notify_data.notification_type
                        : null,
                data_inicial: this.generator_step_two.period_selected[0],
                data_final:
                    this.generator_step_two.type_period === "yesterday"
                        ? this.generator_step_two.period_selected[0]
                        : this.generator_step_two.period_selected[1],
                data_solicitacao: date,
                clientes: this.generator_step_three.clients.map(
                    (client) => client.ID_EMPRESA_EPES
                )
            };

            if (!this.generator_step_two.recurrence_report) {
                params["campanhas"] = this.generator_step_four.campaigns.map(
                    (campaign) => campaign.ID_CAMPANHA_CAES
                );
                params["todas_campanhas"] = this.all_campaigns;
            } else {
                params["todas_campanhas"] = true;
            }

            if (
                (this.all_campaigns ||
                    this.generator_step_two.recurrence_report) &&
                this.generator_step_two.recurrence_config !== undefined &&
                this.generator_step_two.recurrence_config !== null
            ) {
                params["recurrence_period"] =
                    this.generator_step_two.recurrence_config.recurrence_period;
                params["start_recurrence"] =
                    this.generator_step_two.recurrence_config.start_recurrence;
                params["finish_recurrence"] =
                    this.generator_step_two.recurrence_config.finish_recurrence;
            }

            // Dados adicionais
            params["agrupar"] =
                this.generator_step_five.agroup !== undefined &&
                this.generator_step_five.agroup
                    ? "D"
                    : "C";

            let aditional_data = this.generator_step_five.aditional_data;
            if (aditional_data.indexOf("api") > -1) {
                aditional_data.push("avulsas");
            }

            params["new_filter"] = this.generator_step_five.aditional_data;

            if (
                this.generator_step_six.email_attach !== undefined &&
                this.generator_step_six.email_attach
            ) {
                params["email_attach"] = this.generator_step_six.email_attach;
            }

            this.ActionDoGenerateReport({
                type_report: this.generator_step_one.report_type,
                data: params
            });

            setTimeout(() => {
                window.location.href =
                    base_service.baseDomainPhp() + "/Relatorios";
            }, 2500);
        },
        /**
         * Verifica se o tooltip dos passos devem ser exibidos
         * @param {Number} step Numero do passo
         * @returns {Boolean}
         * @vue
         */
        checkTooltip(step) {
            if (
                this.tooltips_text[this.step_active.step] !== undefined &&
                this.tooltips_text[this.step_active.step][step.step] !==
                    undefined
            ) {
                if (this.step_active.step !== step.step) {
                    if (!step.disabled) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            } else {
                return false;
            }
        }
    },
    template:
        /*html*/
        `
        <v-container class="align-start">
            <v-card elevation="0">
                <v-card-text>
                    <v-row class="px-0 px-md-6" no-gutters>
                        <v-col cols="12">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <page-header
                                        tutorial
                                        title="Gerador de relat&oacute;rio"
                                        description="Gere relat&oacute;rios anal&iacute;ticos e sint&eacute;ticos, automatize relat&oacute;rios, escolha os dados que deseja extrair."
                                        page-return-name="Meus relat&oacute;rios"
                                        page-return-link="/Relatorios"
                                        @tutorial="$refs.tour.start()"
                                    ></page-header>
                                </v-col>
                            </v-row>
                            <v-row class="mt-7 mb-16 pb-16">
                                <v-col cols="12" md="3" v-bind:data-v-step="1">
                                    <v-row no-gutters class="flex-nowrap flex-md-wrap overflow-auto">
                                        <v-col cols="auto" md="12" v-for="step in steps" :key="step.step" :class="step.line ? 'mb-9' : 'mb-15'" v-bind:data-v-step="step.step === 3 ? 5 : null" v-if="!step.hidden">
                                            <button-form-step
                                                :step="step.step_display"
                                                :title="step.title"
                                                :ready="step.ready"
                                                :active="step.active"
                                                :disabled="step.disabled"
                                                :line="step.line"
                                                :tooltip-title="checkTooltip(step) ? tooltips_text[step_active.step][step.step].title : null"
                                                :tooltip-description="checkTooltip(step) ? tooltips_text[step_active.step][step.step].description : null"
                                                @click="goToStep(step.step)"
                                            >
                                                <v-row v-if="step.description.length" no-gutters>
                                                    <v-col cols="12">
                                                        <v-row v-for="description in step.description" no-gutters>
                                                            <v-col cols="auto">
                                                                <span class="font-size-10 font-weight-bold" v-html="description"></span>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </button-form-step>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="9">
                                    <report-step-one v-if="step_active.step === 1"></report-step-one>
                                    <report-step-two v-else-if="step_active.step === 2"></report-step-two>
                                    <report-step-three v-else-if="step_active.step === 3"></report-step-three>
                                    <report-step-four v-else-if="step_active.step === 4"></report-step-four>
                                    <report-step-five v-else-if="step_active.step === 5"></report-step-five>
                                    <report-step-six v-else-if="step_active.step === 6" @clearData="clearForm"></report-step-six>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <footer-action
                                :btn-right-text="footer_config[step_active.step].right_button.text"
                                btn-right-icon="mdi-arrow-right"
                                :btn-right-disabled="footer_config[step_active.step].right_button.disabled"
                                :sub-text-left="footer_config[step_active.step].left_button.sub_text"
                                :btn-left-text="footer_config[step_active.step].left_button.text"
                                btn-left-icon="mdi-undo"
                                :btn-left-disabled="false"
                                btn-right-tooltip="Avan&ccedil;ar para o pr&oacute;ximo passo"
                                btn-left-tooltip="Voltar para o passo anterior"
                                @btnRightClick="goToStep((generator_step_one.recurrence_report && step_active.step === 3) ? step_active.step + 2 : step_active.step + 1)"
                                @btnLeftClick="goToStep((generator_step_one.recurrence_report && step_active.step === 5) ? step_active.step - 2 : step_active.step - 1)"
                            ></footer-action>
                        </v-col>
                    </v-row>
                </v-card-text>
                <dialog-notify-report-generation
                    v-model="notify_data"
                    :dialog="dialog_notify"
                    @close="dialog_notify = false"
                    @actionDialog="generateReport"
                ></dialog-notify-report-generation>
                <base-notify
                    v-model="show_notify"
                    title="Estamos gerando o seu relat&oacute;rio"
                    message="Recebemos todas as informa&ccedil;&otilde;es selecionadas e estamos trabalhando para gerar o seu relat&oacute;rio. "
                    type="success"
                ></base-notify>
                <pg-tour ref="tour" :steps="steps_tour"></pg-tour>
            </v-card>
        </v-container>
        `
};
