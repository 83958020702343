import AtomicDesignUsage from "./AtomicDesignUsage.js";
import ComponentsUsage from "./ComponentsUsage.js";
import Icons from "./Icons.js";

import { routes as atomos } from "./atomos";
import { routes as moleculas } from "./moleculas";
import { routes as organismos } from "./organismos";
import { routes as dialogs } from "./dialogs";
import { routes as template_rcs } from "./template-rcs";
import { routes as auth } from "./auth";
import { routes as reports } from "./reports";
import { routes as dashboard } from "./dashboard";
import { routes as campaign } from "./campaign";
import { routes as sessoes_rcs } from "./sessoes-rcs";

export default [
    {
        path: "documentacao",
        name: "documentacao",
        component: AtomicDesignUsage,
        children: [
            {
                path: "atomos",
                name: "Atomos",
                component: ComponentsUsage,
                children: [
                    ...atomos
                ]
            },
            {
                path: "moleculas",
                name: "Moleculas",
                component: ComponentsUsage,
                children: [
                    ...moleculas
                ]
            },
            {
                path: "organismos",
                name: "Organismos",
                component: ComponentsUsage,
                children: [
                    ...organismos
                ]
            },
            {
                path: "dialogs",
                name: "Dialogs",
                component: ComponentsUsage,
                children: [
                    ...dialogs
                ]
            },
            {
                path: "auth",
                name: "Componentes da tela de login",
                component: ComponentsUsage,
                children: [
                    ...auth
                ]
            },
            {
                path: "template-rcs",
                name: "Template RCS",
                component: ComponentsUsage,
                children: [
                    ...template_rcs
                ]
            },
            {
                path: "reports",
                name: "Relatorios",
                component: ComponentsUsage,
                children: [
                    ...reports
                ]
            },
            {
                path: "dashboard",
                name: "Dashboard",
                component: ComponentsUsage,
                children: [
                    ...dashboard
                ]
            },
            {
                path: "campaign",
                name: "Campanhas",
                component: ComponentsUsage,
                children: [
                    ...campaign
                ]
            },
            {
                path: "icons",
                name: "Icones",
                component: Icons
            },
            {
                path: "sessoes-rcs",
                name: "Sessoes RCS",
                component: ComponentsUsage,
                children: [
                    ...sessoes_rcs
                ]
            }
        ]
    }
];