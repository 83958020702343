export default {
    name: "info-icon",
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26.5" height="26.5" viewBox="0 0 26.5 26.5">
                <g id="_16201049001581068819" data-name="16201049001581068819" transform="translate(0.75 0.75)">
                    <g id="Group_2588" data-name="Group 2588">
                        <path id="Path_589" data-name="Path 589" d="M12.5,0A12.5,12.5,0,1,1,0,12.5,12.5,12.5,0,0,1,12.5,0Z" fill="none" stroke="currentColor" stroke-width="1.5"/>
                        <line id="Line_68" data-name="Line 68" y2="10" transform="translate(12.487 5.991)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2"/>
                        <line id="Line_69" data-name="Line 69" transform="translate(12.487 19.482)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2"/>
                    </g>
                </g>
            </svg>
                
        `
    )
};