import FormAddCustomImage from "../../template-rcs/components/forms/FormAddCustomImage";

export default {
    components: {
        FormAddCustomImage
    },
    template: (
        /*html*/
        `
            <v-card elevation="0" class="bg-gray-50 pa-16">
                <v-row justify="center">
                    <v-col cols="12" md="3">
                        <form-add-custom-image></form-add-custom-image>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};