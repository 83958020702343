/**
 * Ao realizar altera��es nas cores, o arquivo '/vue-app/public/styles/custom_colors/colors.css' tamb�m deve ser alterado
 */

export default {
    primary: {
        super_dark_primary: "#182C69",
        dark_primary: "#3559EB",
        primary: "#00B9F9",
        light_primary: "#20DFF1",
        extra_light_primary: "#C7FAFF",
        blue_900: "#162EDD",
        blue_500: "#3559EB",
        blue_200: "#9AACF5",
        blue_100: "#C2CDF9",
        blue_50: "#E7EBFD"
    },
    secondary: {
        blue_500: "#3DB7F4",
        blue_300: "#77CDF7",
        blue_200: "#9EDBFA",
        blue_100: "#C5E9FC",
        pink_900: "#FD4198",
        pink_500: "#FE70BD"
    },
    tertiary: {
        blue_900: "#0D1853",
        blue_100: "#BDC4DA"
    },
    success: {
        dark_success: "#279A11",
        success: "#4ED136",
        light_success: "#9AF988",
        extra_light_success: "#DFFFD9",
        900: "#1CDD26",
        500: "#40EB4F",
        200: "#A0F5A7",
        50: "#E8FDEA"
    },
    warning: {
        dark_warning: "#C59300",
        warning: "#FFBE00",
        light_warning: "#FFE494",
        extra_light_warning: "#FFF4D2",
        900: "#FF9900",
        500: "#FFBE00",
        200: "#FFDF80",
        50: "#FFF7E0"
    },
    danger: {
        dark_danger: "#BC2300",
        danger: "#F52D00",
        light_danger: "#FFAC9F",
        extra_light_danger: "#FFE6E1"
    },
    grey: {
        off_white: "#F5F7F8",
        background: "#EFF0F2",
        super_light_grey: "#E1E1E4",
        extra_light_grey: "#CBD0D6",
        light_grey: "#B5BCC4",
        medium_light_grey: "#9EA7B1",
        medium_grey: "#8A929B",
        medium_dark_grey: "#7C818A",
        dark_grey: "#6B717C",
        extra_dark_grey: "#555A64",
        super_dark_grey: "#3A4148",
        black: "#1D1F27",
    },
    gray: {
        0: "#FFFFFF",
        10: "#F5F7F8",
        30: "#EFF0F2",
        50: "#E1E1E4",
        100: "#CBD0D6",
        200: "#B5BCC4",
        300: "#9EA7B1",
        400: "#8A929B",
        500: "#7C818A",
        600: "#6B717C",
        700: "#555A64",
        800: "#3A4148",
        900: "#1D1F27"
    },
    produtos: {
        sms: "#00B6F5",
        rcs: "#0064E4",
        whatsapp: "#83C512"
    },
    brand: {
        sms_500: "#00B6F5",
        rcs_500: "#0064E4",
        email_500: "#20DFF1"
    },
    purple: {
        dark_purple: "#7C00E8",
        primary_purple: "#9340FF",
        light_purple: "#CBA2FF",
        extra_light_purple: "#F2E8FF",
        900: "#5104DC",
        500: "#9340FF"
    },
    cyan: {
        dark_cyan: "#08B5AC",
        primary_cyan: "#0BD9CE",
        light_cyan: "#70EEE7",
        extra_light_cyan: "#D5FFFD",
        900: "#04A99B"
    },
    green: {
        dark_green: "#92B500",
        primary_green: "#AEDB00",
        light_green: "#E0FC7A",
        extra_light_green: "#F0FCC3",
        900: "#5D930A",
        500: "#9DD200"
    },
    orange: {
        dark_orange: "#BE5E00",
        primary_orange: "#F47901",
        light_orange: "#FFBD7D",
        extra_light_orange: "#FFE8D1",
        900: "#D25118",
        500: "#F47901"
    },
    coral: {
        900: "#C93D45",
        500: "#EA6B72"
    },
    info: {
        500: "#0045F5"
    },
    error: {
        900: "#EE1100",
        500: "#F52D00",
        200: "#FA9680",
        50: "#FEE6E0"
    }
};