import BaseCardShort from "../../../components/atomos/BaseCardShort";

export default ({
    components: {
        BaseCardShort
    },
    methods: {
        clicado: function () {
            console.log("TESTE");
        }
    },
    data() {
        //**Valores default para icone,label e estado do bot�o */
        return {
            icon: "mdi-check",
            label: "Button modelo",
            disabled: false,
            soon: false,
            active: false,
            vertical: false
        };
    },
    template: (
        /*html*/
        ` 
            <v-row justify="center" class="fill-height bg-background">
                <v-col cols="12" md="10">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="3">
                                        <v-checkbox label="Desabilitado" v-model="disabled"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Em breve" v-model="soon"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Ativo" v-model="active"></v-checkbox>
                                    </v-col>
                                    <v-col cols="3">
                                        <v-checkbox label="Vertical" v-model="vertical"></v-checkbox>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Icone" v-model="icon"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Label" v-model="label"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="3">
                            <base-card-short
                                v-model="active"
                                :icon="icon"
                                :label="label"
                                :disabled="disabled"
                                :soon="soon"
                                :vertical="vertical"
                                @click="clicado"
                            />  
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `)
});