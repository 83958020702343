import DialogPreviewTemplate from "../../../components/organismos/DialogPreviewTemplate";
import BaseButton from "../../../components/atomos/BaseButton";

export default {
    components: {
        DialogPreviewTemplate,
        BaseButton
    },
    data() {
        return {
            show_dialog: false,
            type: "message",
            ds_fallback: JSON.stringify({ "text": "Hey {nome} ! Isso � um teste de template de RCS modelo texto completo, que cont�m bot�es e sugest�es de respostas.", "suggestions": [{ "type": "text", "label": "Quero receber novidades" }, { "type": "text", "label": "Sim" }, { "type": "url", "label": "Site da PGMais", "value": "https://pgmais.com.br/" }, { "type": "dial_phone", "label": "Ligar para a PGMais", "value": "555555555541992292703" }, { "type": "show_location", "label": "Escrit�rio da PGMais", "value": { "latitude": "-25.416071798508998", "longitude": "-49.261896828850894" } }, { "type": "request_location", "label": "Compartilhar minha localiza��o" }] })
        };
    },
    computed: {
        dsTemplate() {
            switch (this.type) {
            case "message":
                return JSON.stringify({ "text": "Hey {nome} ! Isso � um teste de template de RCS modelo texto completo, que cont�m bot�es e sugest�es de respostas.", "suggestions": [{ "type": "text", "label": "Quero receber novidades" }, { "type": "text", "label": "Sim" }, { "type": "url", "label": "Site da PGMais", "value": "https://pgmais.com.br/" }, { "type": "dial_phone", "label": "Ligar para a PGMais", "value": "555555555541992292703" }, { "type": "show_location", "label": "Escrit�rio da PGMais", "value": { "latitude": "-25.416071798508998", "longitude": "-49.261896828850894" } }, { "type": "request_location", "label": "Compartilhar minha localiza��o" }] });
            case "image":
                return JSON.stringify({ "url": "https://s4.static.brasilescola.uol.com.br/img/2019/09/panda.jpg" });
            case "card":
                return JSON.stringify({ "alignment": "LEFT", "orientation": "VERTICAL", "contents": { "description": "Ol� {nome} , temos uma proposta imperd�vel para voc� regularizar o seu d�bito. Quer receber informa��es sobre essa oportunidade agora?", "media": { "height": "TALL", "file": { "url": "https://mamamobil.ru/wp-content/uploads/2020/12/podarok-anons.jpg" } }, "suggestions": [{ "type": "text", "label": "SIM" }, { "type": "text", "label": "N�O" }] } });
            case "carousel":
                return JSON.stringify({ "cardWidth": "MEDIUM", "contents": [{ "description": "Ol� {nome} , temos uma proposta imperd�vel para voc� regularizar o seu d�bito. Acesse agora a oferta que preparamos para voc�. ", "media": { "height": "MEDIUM", "file": { "url": "https://www.christuskirche-stadeln.de/sites/christuskirche-stadeln.de/files/styles/max_1300x1300/public/media/img/mutter-kind.jpg?itok=5vIAnWyP" } }, "suggestions": [{ "type": "url", "label": "BOLETO", "value": "{value_url_suggestion}" }] }, { "description": "Duvidas? Fale com nosso Assistente Virtual e obtenha mais informa��es.", "media": { "height": "MEDIUM", "file": { "url": "https://coolwallpapers.me/picsup/3002841-affair_communication_device_girl_lady_mobile_mobile-phone_phone_smartphone_technology_texting_touch_touchscreen_woman.jpg" } }, "suggestions": [{ "type": "url", "label": "CHAT", "value": "https://kami.com.ai/chat/" }] }, { "description": "Se preferir, fale agora com nosso time de especialistas.", "media": { "height": "MEDIUM", "file": { "url": "https://media04.meinbezirk.at/article/2021/02/23/0/25441640_XXL.jpg" } }, "suggestions": [{ "type": "dial_phone", "label": "LIGAR", "value": "5541996311539" }] }] });
            }

            return "";
        }
    },
    template: (
        /*html*/
        `
        <v-container>
            <v-row justify="center">
                <v-col cols="12" class="d-flex justify-center">
                    <v-radio-group v-model="type">
                        <v-radio label="Mensagem" value="message"></v-radio>
                        <v-radio label="Imagem" value="image"></v-radio>
                        <v-radio label="Card" value="card"></v-radio>
                        <v-radio label="Carrossel" value="carousel"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="3">
                    <base-button
                        primary
                        label="Ope dialog"
                        @click="show_dialog = true"
                    />
                </v-col>
            </v-row>
            <dialog-preview-template
                v-model="show_dialog"
                :ds-template="dsTemplate"
                :ds-template-fallback="ds_fallback"
                highlight="fallback"
            />
        </v-container>
        `
    )
};