import BaseTooltip from "../atomos/BaseTooltip?v=2";
import DialogNews from "../organismos/DialogNews";
import BaseDialog from "./BaseDialog?v=3";
import { BaseService } from "../../services/BaseService";

const base_service = new BaseService();

/**
 * Componente do page header que fica vinculado a tela
 * 
 * @requires {@link BaseTooltip}
 * @requires {@link DialogNews}
 * @requires {@link BaseService}
 * @requires {@link BaseDialog}
 * 
 * @displayName PageHeader
 * @category Atomos
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip,
        DialogNews,
        BaseDialog
    },
    props: {
        /**
         * titulo da tela
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Descricao da tela 
         */
        description: {
            type: String,
            required: false,
            default: " "
        },
        /**
         * Habilita o link de tutorial
         */
        tutorial: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Link para a tela antiga
         */
        oldPage: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Nome da pagina antiga para ser exibido no link
         */
        nameOldPage: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Nome da pagina para onde o usuario pode retornar
         */
        pageReturnName: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Um segundo link para uma outra tela antiga
         */
        oldPageTwo: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Nome da pagina antiga para ser exibido no link
         */
        nameOldPageTwo: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Link da pagina para onde o usuario pode retornar
         */
        pageReturnLink: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Texto a ser exibido no tooltip do link de retorno
         */
        pageReturnTooltip: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Exibe um modal de confirmacao ao clicar no link de retornar
         */
        confirmReturn: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe um botao de 'fechar' no cabecalho
         */
        closeButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            show_news: false,
            show_cofirm_return: false
        };
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoSaveStatusTour"]),
        /**
         * Faz o redirecionamento para o link informado em oldPage
         * @vue
         */
        redirectOldPage() {
            window.location = base_service.baseDomainPhp() + this.oldPage;
        },
        /**
         * Faz o redirecionamento para o link informado em oldPageTwo
         * @vue
         */
        redirectOldPageTwo() {
            window.location = base_service.baseDomainPhp() + this.oldPageTwo;
        },
        /**
         * Faz o redirecionamento para a pagina anterior
         * @vue
         */
        retirectToPrevPage() {
            window.location = base_service.baseDomainPhp() + this.pageReturnLink;
        },
        /**
         * Acao relacionada ao link de retornar
         */
        returnClick() {
            if (this.confirmReturn) {
                this.show_cofirm_return = true;
            } else {
                this.retirectToPrevPage();
            }
        },
        /**
         * Finaliza o novidades
         * @vue
         */
        finishNews() {
            let form_data = new FormData();
            form_data.append("nome_tela", "/Dashboard");
            form_data.append("evento", "sair");
            form_data.append("passo", "1");
            form_data.append("bloqueio", "0");
            form_data.append("tipo_tour", "4");

            this.ActionDoSaveStatusTour(form_data);
        }
    },
    template:
    /*html*/
        `
        <v-card elevation="0">
            <v-row v-if="(pageReturnName && pageReturnLink) || oldPage || oldPageTwo || tutorial || closeButton" no-gutters class="py-4">
                <v-col>
                    <v-row v-if="closeButton" no-gutters>
                        <v-col>
                            <a class="body-2 text-primary-blue-500 text-decoration-none" @click="$emit('closeClick')">
                                <v-icon small left color="primary-blue-500">mdi-close-thick</v-icon> Fechar
                            </a>
                        </v-col>
                    </v-row>
                    <v-row v-if="oldPage" no-gutters>
                        <v-col>
                            <a class="body-2 bold text-decoration-none text-primary-blue-500" @click="redirectOldPage">Clique aqui para ir &agrave; tela <span v-if="nameOldPage">'{{ nameOldPage }}' </span>antiga</a>
                        </v-col>
                    </v-row>
                    <v-row v-if="oldPageTwo" no-gutters>
                        <v-col class="mt-3">
                            <a class="body-2 bold text-decoration-none text-primary-blue-500" @click="redirectOldPageTwo">Clique aqui para ir &agrave; tela <span v-if="nameOldPageTwo">'{{ nameOldPageTwo }}' </span>antiga</a>
                        </v-col>
                    </v-row>
                    <v-row v-if="pageReturnName && pageReturnLink" no-gutters>
                        <v-col :class="oldPage || oldPageTwo ? 'mt-3' : ''">
                            <base-tooltip :text="pageReturnTooltip">
                                <a class="body-2 text-primary-blue-500 text-decoration-none" @click="returnClick">
                                    <v-icon left color="primary-blue-500">mdi-chevron-left</v-icon> Voltar para <span class="bold">{{ pageReturnName | htmlEntities }}</span>
                                </a>
                            </base-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="tutorial" cols="auto" class="d-flex justify-end">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <a
                                v-bind="attrs"
                                v-on="on"
                                class="text-decoration-none text-primary-blue-500 align-center body-2" 
                                aria-control
                            >
                                Precisa de ajuda <v-icon x-small right color="primary-blue-500" small>far fa-question-circle</v-icon>
                            </a>
                        </template>
                        <v-list>
                            <v-list-item @click="$emit('tutorial')">
                                <v-list-item-title class="body-2 text-primary-blue-500">Quero ver o tutorial</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="show_news = true">
                                <v-list-item-title class="body-2 text-primary-blue-500">Me mostre as novidades</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="overflow-wrap">
                    <span class="subtitle-1 text-gray-900" v-html="title"></span>
                </v-col>
            </v-row>
            <v-row no-gutters justify="space-between" class="mt-2 mb-8">
                <v-col cols="12" :md="this.$slots.button ? '' : 6"> 
                    <span class="mb-3 body-2 text-gray-900" v-html="description"></span>
                </v-col>
                <v-col cols="this.$slots.button ? 'auto' : 12" :md="this.$slots.button ? 'auto' : 6" v-if="this.$slots.default"> 
                    <slot></slot>
                </v-col>
                <v-col cols="12" md="5" lg="4" offset-lg="1" class="mt-4" v-if="this.$slots.button">
                    <v-row no-gutters justify="end">
                        <v-col md="6">
                            <slot name="button"></slot>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <dialog-news v-model="show_news"></dialog-news>
            <base-dialog
                v-model="show_cofirm_return"
                title="Tem certeza que deseja sair?"
                :action-button="{ icon: 'mdi-check-bold', label: 'Sim, quero sair', loading: false }"
                use-icon-close
                max-width="410"
                use-sub-action
                @clickDialog="retirectToPrevPage"
            >
                <span class="body-2 text-gray-900">Voc&ecirc; perder&aacute; todas as altera&ccedil;&otilde;es realizadas.</span>
                <template #subAction>
                    <v-row no-gutters class="mt-4">
                        <v-col cols="12" class="text-center">
                            <a class="text-primary-blue-500 text-decoration-none body-2" @click="show_cofirm_return = false">Mudei de id&eacute;ia, quero continuar aqui</a>
                        </v-col>
                    </v-row>
                </template>
            </base-dialog>
        </v-card>
        
        `,
};
