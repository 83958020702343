import DialogRedirect from "../../dashboard/components/dialogs/DialogRedirect";

export default {
    components: {
        DialogRedirect
    },
    data() {
        return {
            show: false,
            campaignName: "Teste de nome de campanha",
            type: "C",
            ids: [7950979]
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="12" md="8" lg="6">
                    <v-card class="pa-5">
                        <v-row justify="center">
                            <v-col>
                                <v-select label="Tipo de modal" v-model="type" :items="[{ text: 'Cancelamento', value: 'C' }, { text: 'Escalonamento', value: 'E' }]"></v-select>
                            </v-col>
                            <v-col>
                                <v-text-field label="Nome da campanha (escalonamento)" v-model="campaignName"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row justify="center" no-gutters>
                            <v-col cols="auto">
                                <v-btn @click="show = true" color="dark-primary white--text" class="my-10">Abrir modal</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <dialog-redirect v-model="show" :campaign-name="campaignName" :type="type" :campaigns-ids="ids"></dialog-redirect>
            </v-row>
        `
    )
};