import PreviewTemplate from "../../../../components/organismos/PreviewTemplate";
import DialogPreviewTemplate from "../../../../components/organismos/DialogPreviewTemplate";
import PageHeader from "../../../../components/moleculas/PageHeader";
import ConfigPreview from "../ConfigPreview";
import AccordionDefault from "../../../../components/moleculas/AccordionDefault";
import AddMessageTemplate from "../AddMessageTemplate";
import FieldsTemplate from "../FieldsTemplate";
import BaseButton from "../../../../components/atomos/BaseButton";
import BaseDialog from "../../../../components/moleculas/BaseDialog";
import BaseNotify from "../../../../components/atomos/BaseNotify";
import PgTour from "../../../../components/organismos/PgTour";
import BaseTooltip from "../../../../components/atomos/BaseTooltip";
import { ItemAccordionClass } from "../../../../classes/ItemAccordionClass";
import { DraggableItemClass } from "../../../../classes/DraggableItemClass";
import { BasePillClass } from "../../../../classes/BasePillClass";

/**
 * Componente para realizar a configuração de um template de fallback
 *
 * @requires {@link PreviewTemplate}
 * @requires {@link DialogPreviewTemplate}
 * @requires {@link PageHeader}
 * @requires {@link ConfigPreview}
 * @requires {@link AccordionDefault}
 * @requires {@link AddMessageTemplate}
 * @requires {@link FieldsTemplate}
 * @requires {@link BaseButton}
 * @requires {@link BaseDialog}
 * @requires {@link BaseNotify}
 * @requires {@link PgTour}
 * @requires {@link BaseTooltip}
 * @requires {@link ItemAccordionClass}
 * @requires {@link DraggableItemClass}
 *
 * @displayName DialogConfigFallback
 * @category Page / Template RCS
 * @subcategory dialogs
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PreviewTemplate,
        DialogPreviewTemplate,
        PageHeader,
        ConfigPreview,
        AccordionDefault,
        AddMessageTemplate,
        FieldsTemplate,
        BaseButton,
        BaseDialog,
        BaseNotify,
        PgTour,
        BaseTooltip
    },
    props: {
        /**
         * Conteúdo a ser exibido
         */
        configData: {
            type: Object,
            required: false,
            default: null
        },
        /**
         * Conteúdo passado no v-model do componente
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            show: this.value,
            accordion_active_item: null,
            // Conteúdo dos accordions
            message_input: null,
            fields_custom: [],
            // Controle do conteúdo dentro do preview
            section_selected: null,
            // Outras infos
            show_confirm_close: false,
            show_notify: false,
            show_title: false,
            notify_title: null,
            notify_message: null,
            removed_pill: null,
            position_removed: null,
            notify_button_label: null,
            config_preview_content: {
                list_messages: []
            },
            show_dialog_preview: false,
            ds_template: null,
            is_fallback: true
        };
    },
    computed: {
        ...window.Vuex.mapState("templatesrcs", {
            step_one_content: (state) => state.step_one_content,
            step_two_content: (state) => state.step_two_content
        }),
        totalCaracateresMessages() {
            let total = 0;

            for (let message of this.config_preview_content.list_messages) {
                if (message.type === "text") {
                    total += message.text.length;
                } else if (message.type === "pill") {
                    total += message.pill.numberCharacters;
                }

                total++;
            }

            if (total > 0) {
                total--;
            }

            return total;
        },
        isUnderLg() {
            if (this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lg) {
                return true;
            }

            return false;
        },
        isUnderMd() {
            if (this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.md) {
                return true;
            }

            return false;
        },
        disableButton() {
            return this.totalCaracateresMessages > 160 || this.totalCaracateresMessages < 1;
        },
        listAccordion() {
            let list = [
                new ItemAccordionClass({
                    title: "Escreva sua mensagem",
                    icon: "$iconeTextoIcon",
                    icon_bg_color: "primary-blue-500",
                    icon_text_color: "gray-0",
                    active: false,
                    disabled: false,
                    complete: false,
                    slot_name: "messageInput",
                    tooltip: "Escreva uma mensagem ou altere o texto j&aacute; criado"
                })
            ];

            if (this.fields_custom.length) {
                list.push(
                    new ItemAccordionClass({
                        title: "Adicione campos personalizados",
                        icon: "$camposPersonalizadosIcon",
                        icon_bg_color: "primary-blue-500",
                        icon_text_color: "gray-0",
                        active: false,
                        disabled: false,
                        complete: false,
                        slot_name: "custom_fields",
                        tooltip: "Enrique&ccedil;a a mensagem adicionando campos personalizados"
                    }));
            }

            return list;
        },
        stepsTour() {
            let tour = [
                {
                    target: "[data-v-step='15']",
                    title: "Preview do seu template principal",
                    content: "<p>Visualize o que foi cadastrado no seu template principal.</p>",
                    date: "2022-10-25 00:00:00"
                },
                {
                    target: "[data-v-step='accordion-messageInput']",
                    title: "Mensagem de SMS convencional",
                    content: "<p>Cadastre sua mensagem de SMS com limita&ccedil;&atilde;o de at&eacute; 160 caracteres.</p>"
                }
            ];

            if (this.fields_custom.length) {
                tour.push({
                    target: "[data-v-step='accordion-custom_fields']",
                    title: "Campos personalizados",
                    content: "<p>Adicione campos personalizados na sua mensagem alternativa de SMS</p>"
                });
            }

            tour.push({
                target: "[data-v-step='18']",
                title: "Salve sua mensagem alternativa",
                content: "<p>Ap&oacute;s preencher os campos obrigat&oacute;rios, salve sua mensagem alternativa cadastrada.</p>"
            });

            return tour;
        }
    },
    watch: {
        value() {
            this.show = this.value;
        },
        configData() {
            this.mountDataOnOpen();
        },
        show() {
            this.message_input = null;
            this.fields_custom = [];
            this.config_preview_content.list_messages = [];
            this.section_selected = null;

            if (!this.show) {
                document.addEventListener("keyup", this.onEsc);
            } else {
                this.mountDataOnOpen();
            }

            this.$emit("input", this.show);
        },
        section_selected() {
            for (let message of this.config_preview_content.list_messages) {
                message.pill.selected = false;
            }
        }
    },
    methods: {
        /**
         * Metodo chamado ao tentar fechar o dialog
         * @vue
         */
        clickClose() {
            if (this.totalCaracateresMessages > 0) {
                this.show_confirm_close = true;
            } else {
                this.closeDialog();
            }
        },
        /**
         * Metodo chamado ao alterar a mensagem de template
         * @vue
         * @param {Object} messages_input
         */
        changeMessageTemplate(messages_input) {
            let keys_content_preview = {
                "message_input": "list_messages"
            };

            Object.entries(keys_content_preview).forEach((key_content_preview) => {
                let [key_content, key_preview] = key_content_preview;
                this[key_content] = messages_input[key_content];
                this.mountPreviewMessage(key_preview, this[key_content]);
            });
        },
        /**
         * Monta o conteúdo da previsualização da mensagem
         * @vue
         */
        mountPreviewMessage(key_preview, content_preview) {
            if (content_preview && content_preview.length > 0) {
                let list_pills = {};

                // Remove espaços em branco extras entre palavras e linhas
                content_preview = content_preview.replace(/\s{2,}/g, " ").trim();

                for (let draggable_position in this.config_preview_content[key_preview]) {
                    if (this.config_preview_content[key_preview][draggable_position].type === "pill") {
                        list_pills[draggable_position] = this.config_preview_content[key_preview][draggable_position];
                    }
                }

                this.config_preview_content[key_preview] = []; // Limpa a lista de mensagens
                let words = content_preview.split(/({[^}]+})/);

                let list_text = [];
                for (let i = 0; i < words.length; i++) {
                    let word = words[i];
                    if (word.match(/{[^}]+}/)) {
                        list_text.push(word);
                    } else if (word.trim() !== "") {
                        // Quebra a frase em uma lista de plavras, removendo espaços em branco que possam surgir
                        let list_words = word.split(" ").filter(element => element);
                        let lastWord = list_words[list_words.length - 1];
                        if (lastWord && /[,.?!]$/.test(lastWord)) {
                            if (word[0] === " ") {
                                list_text.push(" ");
                                word = word.substr(1);
                            }
                        }
                        // Adiciona as palavras no array list_text com um espaço ao final de cada uma.
                        for (let element of list_words) {
                            list_text.push(element);
                        }
                    }
                }

                while (Object.values(list_text).length > 0) {
                    let position_text = Object.keys(list_text)[0]; // Pega a primeira posição da lista de textos

                    if (list_text[position_text] && list_text[position_text].length > 0) {

                        if (list_text[position_text].startsWith("{") && list_text[position_text].endsWith("}")) { // Verifica se o valor de list_text[position_text] está entre chaves {}

                            const pillText = list_text[position_text].slice(1, -1); // Remove as chaves e cria o objeto do tipo "pill"
                            let pill = new BasePillClass({ title: pillText }); // Cria o objeto do tipo "pill"

                            this.config_preview_content[key_preview].push(
                                new DraggableItemClass({
                                    text: pillText,
                                    type: "pill",
                                    custom: true,
                                    pill: pill,
                                    prependIcon: "fa-grip-vertical",
                                    draggable: true,
                                    editable: true,
                                    deletable: true,
                                    rounded: true,
                                })
                            );
                        } else {
                            // Cria o objeto do tipo "text"
                            let text = list_text[position_text].replace(/\s*([,!?\s])\s*/g, "$1 ").replace(/\s+/g, " ");

                            this.config_preview_content[key_preview].push(
                                new DraggableItemClass({
                                    text: text,
                                    type: "text",
                                    custom: false
                                })
                            );
                        }

                        // Remove objetos duplicados em this.config_preview_content[key_preview]
                        const uniqueMessages = [];
                        this.config_preview_content[key_preview].forEach((message) => {

                            if (!uniqueMessages.some(m => m.text === message.text && m.type === "pill" && m.custom === message.custom)) { // Verifica se o objeto já existe na lista
                                uniqueMessages.push(message); // Se não existir adiciona o objeto na lista
                            }
                        });
                        this.config_preview_content[key_preview] = []; // Limpa a lista de mensagens
                        this.config_preview_content[key_preview] = uniqueMessages; // Atualiza a lista de mensagens
                        delete list_text[position_text]; // Remove o texto da lista de textos
                    }
                }
            } else {
                this.config_preview_content[key_preview] = [];
            }
        },
        /**
         * Metodo que realiza o fechamento da modal de configuração
         * @vue
         */
        closeDialog() {
            this.$emit("close");
            this.show = false;
        },
        /**
         * Função par vincular eventos no esq
         * @param {Object} e Evento
         * @vue
         */
        onEsc(e) {
            if (!e) {
                e = window.event;
            }

            let keyCode = e.keyCode || e.which;

            if (keyCode == "27") {
                if (!this.show) {
                    this.clickClose();
                    document.removeEventListener("keyup", this.onEsc);
                }
            }
        },
        /**
         * Metodo chamado ao clicar em algum campo customizado
         * @param {DraggableItemClass} item Dados de um item
         * @vue
         */
        clickCustomField(item) {
            this.config_preview_content.list_messages.push(item);
        },
        /**
         * Edita o conteúdo de um item ja existente
         * @param {DraggableItemClass} edited_item Dados de um item editado
         */
        editCustomField(edited_item) {
            let new_list = [];

            for (let item of this.config_preview_content.list_messages) {
                if (item.id === edited_item.id) {
                    new_list.push(edited_item);
                } else {
                    new_list.push(item);
                }
            }

            this.config_preview_content.list_messages = new_list;
        },
        /**
         * Remove um item da lista de campos personalizados
         * @param {DraggableItemClass} removed_item Dados do item a ser removido
         */
        removeCustomField(removed_item) {
            this.config_preview_content.list_messages = this.config_preview_content.list_messages.filter(item => {
                return item.id !== removed_item.id;
            });
        },
        /**
         * Metodo chamado ao clicar no botão de salvar do componente
         * @vue
         */
        saveConfiguration() {
            /**
             * Emite um evento "saveConfig" informando que o botão de salvar do componente foi acionado
             *
             * @event saveConfig
             * @vue
             */
            this.$emit("saveConfig");

            /**
             * Emite os dados para alterar o valor de v-model
             * @event input
             * @vue
             */
            this.$emit("save", {
                list_messages: this.config_preview_content.list_messages,
            });
        },
        /**
         * Monta os dados dentro do componente assim que o valor de value for alterado
         * @vue
         */
        mountDataOnOpen() {
            this.config_preview_content.list_messages = this.configData?.list_messages ?? [];

            if (this.configData?.list_messages) {
                let schedule_view_content = { "message_input": "list_messages" };
                Object.entries(schedule_view_content).forEach((view_content) => {
                    let [key_input_view, key_config_data] = view_content;

                    if (this.config_preview_content[key_config_data].length < 1) {
                        return;
                    }

                    let new_message = "";
                    let isPreviousPill = false;
                    for (let message of this.config_preview_content[key_config_data]) {
                        if (message.type === "text") {
                            if (isPreviousPill) {
                                if (message.text && !/^[?,!]$/.test(message.text.trim())) { // Verificando se message.text não é uma vírgula, interrogação ou exclamação                                    
                                    new_message += " ";
                                }
                            }
                            new_message += message.text + " ";
                            isPreviousPill = false;

                        } else if (message.type === "pill") {
                            if (message.text) {
                                new_message += `{${message.text}}`;
                                this.fields_custom.push(message);
                                isPreviousPill = true;
                            }
                        }
                    }

                    // Remove espaços em branco extras
                    new_message = new_message.replace(/\s*([,!?\s])\s*/g, "$1 ").replace(/\s+/g, " ");

                    this[key_input_view] = new_message;
                });
            }

            for (let item of this.step_two_content.list_messages) {
                if (item.type === "pill") {
                    let new_item = _.cloneDeep(item);
                    new_item.pill.editable = false;
                    new_item.pill.deletable = false;
                    new_item.pill.removable = false;
                    this.fields_custom.push(new_item);
                }
            }
        },
        /**
         * Exibe uma notificação ao usuário
         * @param {Object} data Dados da notificação
         * @vue
         */
        showNotify(data) {
            this.notify_title = data.notify_title;
            this.notify_message = data.notify_message;
            this.removed_pill = data.removed_pill;
            this.position_removed = data.position_removed;
            this.notify_button_label = data.notify_button_label;
            this.restore_type = data.restore_type;
            this.show_notify = true;
        },
        /**
         * Faz a restauração de um item removido
         * @vue
         */
        restoreItem() {
            let array_start = [];
            let array_end = [];

            if (this.removed_pill.pill.numberCharacters !== null) {
                switch (this.restore_type) {
                case "remove":
                    array_start = this.config_preview_content.list_messages.slice(0, this.position_removed);
                    array_end = this.config_preview_content.list_messages.slice(this.position_removed);

                    this.config_preview_content.list_messages = array_start;
                    this.config_preview_content.list_messages.push(this.removed_pill);
                    this.config_preview_content.list_messages.push(...array_end);

                    this.show_notify = false;
                    break;
                }
            }
        },
        /**
         * Define qual sessão (texto, respostas rápidas, botões) está ativa no momento
         * @param {String} type Tipo de sessão ativa
         * @vue
         */
        setSectionActive(type, item_active = 0) {
            this.section_selected = type;

            if (type) {
                this.accordion_active_item = item_active;
            } else {
                this.accordion_active_item = null;
            }
        },
        /**
         * Metodo executado quando um item do accordion for clicado
         * @param {Object} accordion Dados do accordion selecionado
         * @vue
         */
        accordionClicked(accordion) {
            if (accordion.item !== undefined) {
                switch (accordion.item.slot_name) {
                case "messageInput":
                    this.setSectionActive("text", 0);
                    break;
                case "custom_fields":
                    this.setSectionActive("text", 1);
                    break;
                }
            } else {
                this.section_selected = null;
            }
        },
        /**
         * Exibe o dialog de preview de template
         * @param {String} content_preview JSON no formato string
         */
        showDialogPreview(content_preview) {
            this.ds_template = content_preview;
            this.show_dialog_preview = true;
        },
    },
    template:
        /*html*/
        `
        <v-dialog
            v-model="show"
            fullscreen
            scrollable
            persistent
        >
            <v-card
                elevation="0"
                tabindex="0"
                class="cursor-default"
                :ripple="false"
            >
                <v-card-title>
                    <v-toolbar elevation="0">
                        <v-container class="align-start">
                            <v-row justify="space-between" no-gutters>
                                <v-col cols="auto">
                                    <base-tooltip text="Sair da configura&ccedil;&atilde;o" bottom>
                                        <a class="body-2 text-primary-blue-500 text-decoration-none" @click="clickClose">
                                            <v-icon small left color="primary-blue-500">mdi-close-thick</v-icon> Fechar
                                        </a>
                                    </base-tooltip>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-toolbar>
                </v-card-title>
                <v-card-text>
                    <v-container class="align-start">
                        <v-row class="px-0" no-gutters>
                            <v-col cols="12">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <page-header
                                            title="Mensagem alternativa SMS"
                                            description="Cadastre aqui uma mensagem alternativa para ser enviada por SMS para os contatos que n&atilde;o receberem o RCS por algum motivo"
                                            tutorial
                                            @tutorial="$refs.tour.start()"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        lg="3"
                                        :order="isUnderLg || isUnderMd ? 3 : 0"
                                        class="pt-4 pt-md-8 pt-lg-0"
                                    >
                                        <v-row no-gutters>
                                            <v-col cols="12" :data-v-step="15">
                                                <preview-template
                                                    :type="step_one_content.modelo.value === 'text' ? 'message' : step_one_content.modelo.value"
                                                    :message="step_two_content.list_messages"
                                                    :list-suggestions="step_two_content.list_chips_preview"
                                                    :image="step_two_content.image"
                                                    :card="step_two_content.card"
                                                    :list-card="step_two_content.list_card"
                                                    description="Esta &eacute; a mensagem principal que voc&ecirc; cadastrou"
                                                    :height="541"
                                                    @showDialogPreview="showDialogPreview"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        lg="5"
                                        class="mt-2 mt-lg-0 pt-4 pt-md-0"
                                        :order="isUnderMd ? 2 : 1"
                                    >
                                        <config-preview
                                            v-model="config_preview_content"
                                            type="text"
                                            text
                                            :section-selected="section_selected"
                                            :max-characters="160"
                                            @clickSection="setSectionActive"
                                            @showNotify="showNotify"
                                        />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        md="6"
                                        lg="4"
                                        class="mt-2 mt-lg-0"
                                        :order="isUnderMd ? 1 : 2"
                                    >
                                        <accordion-default :items="listAccordion" :active-item="accordion_active_item" @click_item="accordionClicked">
                                            <template #messageInput="{}">
                                                <add-message-template
                                                    v-model="message_input"
                                                    :counter="160"
                                                    :show-title="show_title"
                                                    :is-fallback="is_fallback"
                                                    @changeMessage="changeMessageTemplate"
                                                />
                                            </template>
                                            <template #custom_fields="{}">
                                                <fields-template
                                                    v-model="fields_custom"
                                                    :show-form="false"
                                                    :show-default-fields="false"
                                                    description-custom-fields="Esses s&atilde;o os campos que voc&ecirc; est&aacute; utilizando em seu template principal"
                                                    @itemClick="clickCustomField"
                                                    @editItem="editCustomField"
                                                    @removeItem="removeCustomField"
                                                    @showNotify="showNotify"
                                                />
                                            </template>
                                        </accordion-default>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="bg-gray-0 pa-4 shadow-top-1">
                    <v-row justify="center" no-gutters>
                        <v-col cols="auto" :data-v-step="18">
                            <base-button
                                primary
                                label="Salvar configura&ccedil;&atilde;o"
                                icon="fa-check"
                                block
                                tooltip-text="Para salvar seu template, preencha algum dos campos"
                                :disabled="disableButton"
                                @click="saveConfiguration"
                            />
                        </v-col>
                    </v-row>
                </v-card-actions>
                <base-dialog
                    v-model="show_confirm_close"
                    max-width="410px"
                    title="Tem certeza que deseja fechar?"
                    :action-button="{ label: 'Sim, quero fechar', icon: 'mdi-check-bold' }"
                    use-sub-action
                    @clickDialog="closeDialog"
                >
                    <v-row no-gutters>
                        <v-col class="text-start">
                            <span class="body-2 text-gray-900">Voc&ecirc; perder&aacute; todas as altera&ccedil;&otilde;es realizadas.</span>
                        </v-col>
                    </v-row>
                    <template #subAction>
                        <v-row no-gutters class="mt-4">
                            <v-col cols="12" class="text-center">
                                <a class="text-primary-blue-500 text-decoration-none body-2" @click="show_confirm_close = false">Mudei de id&eacute;ia, quero continuar aqui</a>
                            </v-col>
                        </v-row>
                    </template>
                </base-dialog>
                <pg-tour ref="tour" :steps="stepsTour" />
                <base-notify
                    v-model="show_notify"
                    :title="notify_title"
                    :message="notify_message"
                    :button-label="notify_button_label"
                    button-icon="fa-undo"
                    type="success"
                    @click="restoreItem"
                />
                <dialog-preview-template
                    v-model="show_dialog_preview"
                    :ds-template="ds_template"
                />
            </v-card>
        </v-dialog>
        `
};
