export default {
    template: (
        /*html*/
        `
            <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2473_23594)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M40.601 10.7726C40.7327 11.0102 40.7253 11.3005 40.5816 11.5311L29.7975 28.8319C29.6924 29.0004 29.5246 29.1203 29.3311 29.1651C29.1376 29.2098 28.9342 29.1758 28.7658 29.0705L19.1164 23.0376L14.3402 29.6343C14.1671 29.8734 13.8697 29.9884 13.5808 29.9279C13.2919 29.8674 13.0656 29.6428 13.0029 29.3544L10.4464 17.5919L1.04668 11.7201C0.763391 11.5431 0.631701 11.1998 0.723955 10.8788C0.81621 10.5578 1.11006 10.3368 1.44408 10.3372L39.9475 10.387C40.2192 10.3874 40.4693 10.535 40.601 10.7726ZM12.0021 17.7036L13.6326 25.2057L14.5491 19.6396C14.5935 19.3695 14.7819 19.1452 15.0403 19.0549L28.99 14.1752L12.0021 17.7036ZM32.7095 11.8742L4.0587 11.8371L11.2523 16.3309L32.7095 11.8742ZM38.1871 12.5435L28.9238 27.4044L19.4328 21.4704C19.4119 21.4514 19.3898 21.4333 19.3663 21.4164C19.3143 21.3787 19.2591 21.3487 19.202 21.3262L17.0055 19.9529L38.1871 12.5435ZM15.8432 20.9911L14.9875 26.1884L17.8445 22.2424L15.8432 20.9911Z" fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_2473_23594">
                        <rect width="40" height="40" fill="white" transform="translate(0.728516 0.0861816) rotate(0.174)"/>
                    </clipPath>
                </defs>
            </svg>
        `
    )
};