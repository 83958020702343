import BaseBackgroundIcon from "../atomos/BaseBackgroundIcon?v=2";

/**
 * Componente de icone com descricao do modelo do produto enviado
 *
 * @displayName ModeloIconDescription
 * @component
 * @category Moleculas
 * @requires {@link BaseBackgroundIcon}
 * @vue
 * @author Bruno Dias Ramos <bruno.dias@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseBackgroundIcon
    },
    props: {
        /**
         * Modelo do produto enviado (
         * CAROUSEL = "R", 
         * MENSAGEM = "M", 
         * TEMPLATE = "T", 
         * IMAGEM = "I", 
         * VIDEO = "V", 
         * AUDIO = "A", 
         * FILE = "F", 
         * CARD = "C"
         * )
         */
        modelName: {
            type: String,
            required: false,
            default: null,
            validator: function (data) {
                return [null, "R", "M", "T", "I", "V", "A", "F", "C"].indexOf(data) > -1;
            }
        },
        subTitle: {
            type: String,
            required: false,
            default: null
        },
    },
    computed: {
        style () {
            let model = (this.modelName);
            return window.Vue.getModelTheme(model);
        },
    },
    template:
        /*html*/
        `   
            <v-row no-gutter>
                <v-col cols="auto" class="pr-2">
                    <v-icon size="20" color="gray-900">
                        {{ style.icon }}
                    </v-icon>
                </v-col>
                <v-col v-if="style.name || subTitle" :class="!subTitle ? 'd-flex align-center' : ''">
                    <v-row no-gutters class="sm-3">
                        <v-col cols="12">
                            <span class="body-2 text-gray-900" v-html="style.name"></span>
                        </v-col>
                        <v-col v-if="subTitle" cols="12">
                            <span class="body-2 text-gray-900">({{ subTitle }} pag.)</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
};
