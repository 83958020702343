import FormRecoveryPassword from "../../auth/components/FormRecoveryPassword";

export default {
    components: { FormRecoveryPassword },
    methods: {
        submit(email) {
            console.log(email);
        },
        link() {
            console.log("Link clicado");
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="2">
                    <form-recovery-password @recoveryPassButton="submit" @backLogin="link"></form-recovery-password>
                </v-col>
            </v-row>
        `
    )
};