export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g id="Btn-Escalonar-Hover" transform="translate(-1155 -2155)">
                    <g id="Ico-Escalonar-Hover" transform="translate(1159 2056)">
                        <g id="Grupo_1842" data-name="Grupo 1842" transform="translate(0 102)">
                            <path id="Caminho_458" data-name="Caminho 458" d="M15.5,35.5H13.7v-.8a.789.789,0,0,0-.8-.8.736.736,0,0,0-.8.8v.8H5.3v-.8a.684.684,0,0,0-.7-.7.789.789,0,0,0-.8.8v.8H2.1A2.112,2.112,0,0,0,0,37.7V51a2.03,2.03,0,0,0,2.1,2H15.5a2.006,2.006,0,0,0,2-2V37.6A2.094,2.094,0,0,0,15.5,35.5Zm-3.3,1.1v-1h1.5v2.3a.789.789,0,0,1-.8.8.736.736,0,0,1-.8-.8V36.6Zm-8.4,0v-1H5.3v2.3a.789.789,0,0,1-.8.8.736.736,0,0,1-.8-.8V36.6Zm12,9.6a.789.789,0,0,1,.8.8.736.736,0,0,1-.8.8H12.2v3.9a.789.789,0,0,1-.8.8.736.736,0,0,1-.8-.8V47.8H6.8v3.9a.789.789,0,0,1-.8.8c-.5,0-.7-.5-.7-.9V47.7H1.8A.713.713,0,0,1,1,47a.789.789,0,0,1,.8-.8H5.4V42.4H1.8a.8.8,0,0,1,0-1.6h14a.789.789,0,0,1,.8.8.736.736,0,0,1-.8.8H12.2v3.8h3.6Z" transform="translate(0 -33.9)" fill="currentColor"/>
                            <rect id="Retângulo_308" data-name="Retângulo 308" width="3.8" height="3.8" transform="translate(6.8 8.5)" fill="currentColor"/>
                        </g>
                    </g>
                    <rect id="Retângulo_475" data-name="Retângulo 475" width="26" height="26" transform="translate(1155 2155)" fill="currentColor" opacity="0"/>
                </g>
            </svg>
        `
    )
};