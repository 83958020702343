import BaseButton from "../../../components/atomos/BaseButton";

/**
 * Componente responsavel por fazer a exportacoes dos avulsos nos formatos texto/CSV
 *
 * @requires {@link BaseButton}
 *
 * @displayName ExportAvulso
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseButton
    },
    props: {
        /**
         * Objeto que indica se deve ou no habilitar os botes de exportar
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Dados do filtro a serem submetidos
         */
        filterData: {
            type: Object,
            required: true,
            default: function () {
                return {};
            }
        },
        /**
         * Dados da tabela
         */
        contentTable: {
            type: Object,
            required: false,
            default: function () {
                return {};
            }
        },
    },
    data() {
        return {
            disabled_text: true,
            disabled_excel: true,
            loading_text: true,
            loading_excel: true
        };
    },
    watch: {
        loading: function (ev) {
            this.exportButtonLoading(ev);
        },
        contentTable: function (ev) {
            this.exportButtonLoading(ev.length > 0);

            // Quando os dados da tabela forem atualizados, desabilita o loading dos botoes de exportar
            this.loading_text = false;
            this.loading_excel = false;
        },
        filterData: function () {
            this.exportButtonLoading(false);
        }
    },
    methods: {
        ...window.Vuex.mapActions("avulsoapi", ["ActionDoDownloadAvulsos"]),
        /**
         * Ativa/inativa o loading e disabled, dentro do botao exportar
         * @param {boolean} is_loading
         */
        exportButtonLoading(is_loading = false) {
            this.loading_text = is_loading;
            this.loading_excel = is_loading;

            if (this.contentTable.length == 0) { // Se no houver dados ap�s o filtro, ativa o disable
                this.disabled_text = true;
                this.disabled_excel = true;
            } else { // Se houver dados aps o filtro, inativa o disable
                this.disabled_text = false;
                this.disabled_excel = false;
            }
        },
        /**
         * Baixa um array buffer no tipo indicado.
         * OBS: O navegador pode acabar bloqueando multiplos downloads  ent�o importando inform�-lo
         * @param arrayBuffer - Arquivo
         * @param full_name {string} -  nome + extenso = file.csv
         * @param type {string} - tipo arquivo
         */
        downloadFileAvulso(arrayBuffer, full_name, type = "text/csv") {
            let blob = new Blob([arrayBuffer], {type});
            let a = document.createElement("a");
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = full_name;
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        },
        /**
         * Funcao que gera o relatorio em formato text/excel
         */
        async exportarTextExcel(file_type) {
            if (file_type == "text") {
                this.loading_text = true;
                this.disabled_text = true;
            } else { //excel
                this.loading_excel = true;
                this.disabled_excel = true;
            }

            await this.ActionDoDownloadAvulsos(this.filterData).then(data => {
                if (file_type == "text") {
                    this.downloadFileAvulso(data, "AvulsoRelatorio.txt", "text/plain");
                } else { //excel
                    this.downloadFileAvulso(data, "AvulsoRelatorio.csv");
                }

                this.exportButtonLoading(false);
            }).catch(() => {
                this.exportButtonLoading(false);
            });
        }
    },
    template:
    /*html*/
        `
        <div>
            <v-row class="justify-content-end mt-3">
                <v-col cols="12" class="col-md-1 text-md-right text-sm-left text-center mt-2">
                    <label> Download:</label>
                </v-col>
                <v-col cols="12" class="col-md-4 pt-0">
                    <v-row class="justify-content-end" :data-v-step="5">
                        <v-col cols="12" class="col-md-6 mb-4">
                            <base-button
                                :disabled="disabled_text"
                                :loading="loading_text"
                                icon="icon-btn-download"
                                label="Texto"
                                :block="false"
                                width="100%"
                                :small="true"
                                @click="exportarTextExcel('text')"
                            >
                                <template #loader>
                                    <span>Carregando...</span>

                                    <v-progress-linear
                                        indeterminate
                                        absolute
                                        bottom
                                        color="dark-primary"
                                        height="4"
                                    />
                                </template>
                            </base-button>
                        </v-col>
                        <v-col cols="12" class="col-md-6 mb-4">
                            <base-button
                                variant="extra-dark-grey"
                                :disabled="disabled_excel"
                                :loading="loading_excel"
                                icon="icon-btn-download"
                                label="Excel"
                                :block="false"
                                width="100%"
                                :small="true"
                                @click="exportarTextExcel('excel')"
                            >
                                <template #loader>
                                    <span>Carregando...</span>

                                    <v-progress-linear
                                        indeterminate
                                        absolute
                                        bottom
                                        color="dark-primary"
                                        height="4"
                                    />
                                </template>
                            </base-button>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    `
};