import BaseCardExtraLarge from "../atomos/BaseCardExtraLarge";
import { BaseCardExtraLargeClass } from "../../classes/BaseCardExtraLargeClass";
/**
 * Componente de GroupCardExtraLarge
 *
 * @displayName GroupCardExtraLarge
 * @component
 * @category Moleculas
 * @requires {@link BaseCardExtraLarge}
 * @requires {@link BaseCardExtraLargeClass}
 * @vue
 * @author Igor Oliveira <igor.oliveira@pgmais.com.br>
 */
export default {
    components: {
        BaseCardExtraLarge,
    },
    props: {
        /**
         * Propriedade com informa��es para gera��o dos cards
         */
        cards: {
            type: Array,
            required: true,
            validator: function (data) {
                let is_valid = true;

                for (const element of data) {
                    if (!(element instanceof BaseCardExtraLargeClass)) {
                        is_valid = false;
                        break;
                    }
                }

                return is_valid;
            }
        },
        /**
         * Propriedade que define se componente aceitar� apenas um card selecionavel ou mais
         */
        singleSelect: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    methods: {
        /**
         * Funcao que ao clicar bot�o valida multi-select e emite bot�o clicado
         * @param {Object} item
         */
        click(item) {
            let cards_selecteds = [];

            if (this.singleSelect) {
                for (let card of this.cards) {
                    if (item.id != card.id) {
                        card.active = false;
                    } else {
                        card.active = true;
                        cards_selecteds = card;
                    }
                }
            } else {
                for (let card of this.cards) {
                    if (card.active) {
                        cards_selecteds.push(card);
                    }
                }
            }

            /**
             * Emite os dados dos cards que estao com o status ativo
             *
             * @param {Array|Object} cards_selecteds Array de objetos do tipo {@link BaseCardExtraLargeClass} contendo os cards selecionados
             * @event selected
             * @vue
             */
            this.$emit("selected", cards_selecteds);
        }
    },
    template: (
        /*html*/
        `
        <v-row>
            <v-col cols="12" md="6" v-for="(item, key) in cards" class="mb-4">
                <base-card-extra-large
                    v-model="item.active"
                    :image-url="item.imageUrl"
                    :title="item.title"
                    :description="item.description"
                    :disabled="item.disabled"
                    :soon="item.soon"
                    @click="click(item)"
                ></base-card-extra-large>
            </v-col> 
        </v-row>
        `
    )
};