import BaseDialog from "../../../components/moleculas/BaseDialog";
import BaseVisualCard from "../../../components/atomos/BaseVisualCard";

/**
 * Componente modal contendo a quantidade total de cliques nos botoes de RCS
 * 
 * @requires {@link BaseDialog}
 * @requires {@link BaseVisualCard}
 * 
 * @displayName DialogClickDetails
 * @author Carlos Eduardo de Moraes Oliveira <carlos.oliveira@pgmais.com.br>
 * @component
 * @vue
 * @vue/component 
 */
export default {
    name: "dialog-click-details",
    components: {
        BaseDialog,
        BaseVisualCard
    },
    props: {
        /**
         * Objeto que indica se o modal deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: true,
            default: false
        },
        /**
         * Objeto que indica o numero total de links clicados
         */
        link: {
            type: Number,
            required: false,
            default: null
        },
        /**
         * Objeto que indica o numero total de telefones clicados
         */
        phone: {
            type: Number,
            required: false,
            default: null
        },
        /**
         * Objeto que indica o numero total de localizacao clicada
         */
        map: {
            type: Number,
            required: false,
            default: null
        },
        /**
         * Objeto que indica o numero total de solicita��o de localizacao
         */
        requestLocation: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            dialog: this.value,
            values: [
                { "title": "LINK", "icon": "fa-globe-americas", "variableCount": "link", "tooltip": "Direciona o destinat&aacute;rio a uma p&aacute;gina de internet." },
                { "title": "TELEFONE", "icon": "fa-phone-alt", "variableCount": "phone", "tooltip": "O telefone informado ser&aacute; enviado ao aplicativo de liga&ccedil;&atilde;o do destinat&aacute;rio." },
                { "title": "LOCALIZA&Ccedil;&Atilde;O", "icon": "fa-map-marker-alt", "variableCount": "map", "tooltip": "A localiza&ccedil;&atilde;o informada ser&aacute; apresentada no aplicativo de mapa do destinat&aacute;rio." },
                { "title": "S. LOCALIZA&Ccedil;&Atilde;O", "icon": "fa-crosshairs", "variableCount": "requestLocation", "tooltip": "Solicitar Localiza&ccedil;&atilde;o: <br><br>Bot&atilde;o para solicitar ao destinat&aacute;rio compartilhar a sua localiza&ccedil;&atilde;o." },
            ]
        };
    },
    computed: {
        /**
         * Calcula o total de cliques
         *
         * @returns {number} - Retorna o total de cliques
         * @vue
         */
        totalClicks() {
            return this.link + this.phone + this.map + this.requestLocation;
        }
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog() {
            /**
             * Emite o status do dialog (false ou true)
             * @property {Boolean} dialog
             *
             * @event input
             * @vue
             */
            this.$emit("input", this.dialog);

            if (!this.dialog) {
                /**
                 * Emite um evento chamado close informando que o modal foi fechado
                 *
                 * @event close
                 * @vue
                 */
                this.$emit("close");
            }
        },
    },
    methods: {
        /**
         * Calcula a porcentagem de cliques para um determinado tipo de clique
         *
         * @param {string} clickType - O tipo de clique(ex. "link")
         * @returns {number} - Retorna a porcentagem de cliques para o tipo
         * @vue
         */
        calculatePercent(clickType) {
            if (this.totalClicks === 0) { // Evita divisao por zero
                return 0;
            }
            const percent = (this[clickType] / this.totalClicks) * 100;
            return Math.round(percent);
        },
        /**
         * Retorna o numero de cliques para um determinado tipo
         *
         * @param {string} type - O tipo de clique(ex. "link")
         * @returns {number} - Retorna o numero de cliques para o tipo especifico
         * @vue
         */
        returnNumber(type) {
            return this[type];
        }
    },
    template: (
        /*html */
        `
            <base-dialog
                v-model="dialog"
                :title="'Clicados ' + totalClicks"
                max-width="874px"
                sub-title="Quantidade total de cliques nos bot&otilde;es de RCS"
                use-icon-close
            >
                <v-card elevation="0">
                    <v-row justify="center" class="mt-3 gap-4" no-gutters>
                        <v-col cols="12" sm="3" v-for="(value) in values">
                            <base-visual-card
                                :icon="value.icon"
                                :title="value.title"
                                :number="returnNumber(value.variableCount)"
                                :percent="calculatePercent(value.variableCount)"
                                :tooltip-text="value.tooltip"
                            />
                        </v-col>
                    </v-row>
                </v-card>
            </base-dialog>
        `
    )
};