/**
 * Componente para reinserir actions removidas na configuracao de template
 * 
 * @component
 * @displayName InsertBlocks
 * @category Page / Template RCS
 * @author David Nunes dos Santos Andrade <david.santos@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    props: {
        /**
         * Indica se o componente deve estar visivel ou nao. Alterado por v-model
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe o componente abaixo da posicao de referencia
         */
        bottom: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe o componente acima da posicao de referencia
         */
        top: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe o componente a esquerda da posicao de referencia
         */
        left: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Exibe o componente a direita da posicao de referencia
         */
        right: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define o icone a ser exibido no componente
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * Posicao de referencia para exibir o componente
         */
        refPosition: {
            type: DOMRect,
            required: true,
            default() {
                return new DOMRect();
            }
        }
    },
    data() {
        return {
            show: this.value,
            hover: false,
            active: false
        };
    },
    computed: {
        classArrow() {
            if (this.bottom) {
                return "arrow-top";
            } else if (this.top) {
                return "arrow-bottom";
            } else if (this.left) {
                return "arrow-right";
            } else if (this.right) {
                return "arrow-left";
            }

            return "";
        },
        positionX() {
            let position = 0;

            if (this.bottom || this.top) {
                position = this.refPosition.x + (this.refPosition.width / 2) - 24;
            } else if (this.left) {
                position = this.refPosition.x - 60;
            } else if (this.right) {
                position = this.refPosition.x + this.refPosition.width;
            }

            return position;
        },
        positionY() {
            let position = 0;

            if (this.bottom) {
                position = this.refPosition.y + this.refPosition.height;
            } else if (this.top) {
                position = this.refPosition.y - 60;
            } else if (this.left) {
                position = this.refPosition.y - 24 + (this.refPosition.height / 2);
            } else if (this.right) {
                position = this.refPosition.y - 24 + (this.refPosition.height / 2);
            }

            return position;
        },
        color() {
            let color = "bg-secondary-blue-100 border-secondary-blue-100";

            if (this.active) {
                color = "bg-gray-900 border-gray-900";
            } else if (this.hover) {
                color = "bg-primary-blue-500 border-primary-blue-500";
            }

            return color;
        },
        textColor() {
            let color = "gray-900";

            if (this.hover || this.active) {
                color = "gray-0";
            }

            return color;
        }
    },
    watch: {
        value() {
            if (this.value) {
                this.active = false;
            }

            this.show = this.value;
        },
        show() {
            this.$emit("input", this.show);
        }
    },
    methods: {
        click() {
            this.active = true;
            this.show = false;
            this.$emit("click");
        }
    },
    template: (
        /*html*/
        `
            <v-menu
                v-model="show"
                :position-x="positionX"
                :position-y="positionY"
                absolute
                offset-y
                :close-on-click="false"
                :content-class="classArrow + ' insert-block elevation-3 ' + color"
                rounded="3"
            >

                <v-hover v-model="hover">
                    <v-card elevation="0" width="48" height="48" class="rounded-3 bg-transparent" @click="click">
                        <v-row no-gutters justify="center" align="center" class="fill-height">
                            <v-col cols="auto">
                                <v-icon size="32" :color="textColor">{{ icon }}</v-icon>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-menu>
        `
    )
};