export default {
    template: (
        /*html*/
        `
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.40097 8.88846C3.40097 8.61232 3.62483 8.38846 3.90097 8.38846H11.2552C11.5313 8.38846 11.7552 8.61232 11.7552 8.88846C11.7552 9.1646 11.5313 9.38846 11.2552 9.38846H3.90097C3.62483 9.38846 3.40097 9.1646 3.40097 8.88846Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.40097 14.5727C3.40097 14.2965 3.62483 14.0727 3.90097 14.0727H10.8972C11.1734 14.0727 11.3972 14.2965 11.3972 14.5727C11.3972 14.8488 11.1734 15.0727 10.8972 15.0727H3.90097C3.62483 15.0727 3.40097 14.8488 3.40097 14.5727Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.40097 17.7084C3.40097 17.4323 3.62483 17.2084 3.90097 17.2084H8.68121C8.95735 17.2084 9.18121 17.4323 9.18121 17.7084C9.18121 17.9846 8.95735 18.2084 8.68121 18.2084H3.90097C3.62483 18.2084 3.40097 17.9846 3.40097 17.7084Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.40097 11.7306C3.40097 11.4544 3.62483 11.2306 3.90097 11.2306H13.4615C13.7376 11.2306 13.9615 11.4544 13.9615 11.7306C13.9615 12.0067 13.7376 12.2306 13.4615 12.2306H3.90097C3.62483 12.2306 3.40097 12.0067 3.40097 11.7306Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.8996 5.40042V20.6005C24.8996 21.1528 24.4519 21.6005 23.8996 21.6005L2.10059 21.6005C1.5483 21.6005 1.10059 21.1528 1.10059 20.6005V5.40039C1.10059 4.8481 1.5483 4.40039 2.10059 4.40039L23.8996 4.40042C24.4519 4.40042 24.8996 4.84814 24.8996 5.40042ZM15.5406 5.40042V20.6005L2.10059 20.6005L2.10059 5.40039L15.5406 5.40042ZM17.8161 13.866C17.8546 13.8931 17.9006 13.9076 17.9477 13.9076L22.4927 13.9074C22.5398 13.9074 22.5857 13.8928 22.6241 13.8659C22.6625 13.8389 22.6915 13.8007 22.7072 13.7566C22.7228 13.7126 22.7244 13.6648 22.7115 13.6198C22.6987 13.5748 22.6722 13.5349 22.6356 13.5055L20.9365 12.1435C20.896 12.111 20.8456 12.0934 20.7936 12.0934C20.7416 12.0934 20.6912 12.111 20.6508 12.1435L19.5145 13.0542L19.0856 12.7141C19.0453 12.6821 18.9952 12.6646 18.9436 12.6646C18.8919 12.6646 18.8418 12.6821 18.8015 12.7141L17.8056 13.5051C17.7687 13.5343 17.742 13.5742 17.729 13.6193C17.716 13.6643 17.7174 13.7122 17.733 13.7564C17.7486 13.8006 17.7777 13.8389 17.8161 13.866Z" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.90097 8.38846C3.62483 8.38846 3.40097 8.61232 3.40097 8.88846C3.40097 9.1646 3.62483 9.38846 3.90097 9.38846H11.2552C11.5313 9.38846 11.7552 9.1646 11.7552 8.88846C11.7552 8.61232 11.5313 8.38846 11.2552 8.38846H3.90097ZM3.90097 14.0727C3.62483 14.0727 3.40097 14.2965 3.40097 14.5727C3.40097 14.8488 3.62483 15.0727 3.90097 15.0727H10.8972C11.1734 15.0727 11.3972 14.8488 11.3972 14.5727C11.3972 14.2965 11.1734 14.0727 10.8972 14.0727H3.90097ZM3.90097 17.2084C3.62483 17.2084 3.40097 17.4323 3.40097 17.7084C3.40097 17.9846 3.62483 18.2084 3.90097 18.2084H8.68121C8.95735 18.2084 9.18121 17.9846 9.18121 17.7084C9.18121 17.4323 8.95735 17.2084 8.68121 17.2084H3.90097ZM3.90097 11.2306C3.62483 11.2306 3.40097 11.4544 3.40097 11.7306C3.40097 12.0067 3.62483 12.2306 3.90097 12.2306H13.4615C13.7376 12.2306 13.9615 12.0067 13.9615 11.7306C13.9615 11.4544 13.7376 11.2306 13.4615 11.2306H3.90097Z" fill="currentColor"/>
        </svg>
        `
    )
};