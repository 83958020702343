import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente basico de botao com checkbox
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName BaseButtonCheckbox
 * @component
 * @vue
 * @category Atomos
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Label do botao
         */
        label: {
            type: String,
            required: true
        },
        /**
         * Iconde do botao
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * Valor informado em v-model
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Cor do background do botao
         */
        backgroundColor: {
            type: String,
            required: false,
            default: "off-white"
        },
        /**
         * Cor do background do botao quando esta em hover
         */
        backgroundColorHover: {
            type: String,
            required: false,
            default: "extra-light-grey"
        },
        /**
         * Cor do background do botao quando esta com o checkbox ativo
         */
        backgroundColorChecked: {
            type: String,
            required: false,
            default: "dark-primary"
        },
        /**
         * Cor do conteudo do botao
         */
        contentColor: {
            type: String,
            required: false,
            default: "black"
        },
        /**
         * Cor do conteudo do botao quando esta em hover
         */
        contentColorHover: {
            type: String,
            required: false,
            default: "black"
        },
        /**
         * Cor do conteudo do botao quando esta com o checkbox ativo
         */
        contentColorChecked: {
            type: String,
            required: false,
            default: "off-white"
        },
        /**
         * Texto a ser exibido no tooltip
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Informa se o campo ficar� desabilitado
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            checked: this.value,
            hover: false
        };
    },
    computed: {
        bgColor() {
            if (this.checked) {
                return `bg-${this.backgroundColorChecked}`;
            } else if (this.hover) {
                return `bg-${this.backgroundColorHover}-hover`;
            } else {
                return `bg-${this.backgroundColor}`;
            }
        },
        textColor() {
            if (this.checked) {
                return `${this.contentColorChecked}--text`;
            } else if (this.hover) {
                return `${this.contentColorHover}--text`;
            } else {
                return `${this.contentColor}--text`;
            }
        },
        iconColor() {
            if (this.checked) {
                return this.contentColorChecked;
            } else if (this.hover) {
                return `${this.contentColorHover}`;
            } else {
                return this.contentColor;
            }
        }
    },
    watch: {
        value() {
            this.checked = this.value;
        },
        checked() {
            /**
             * Evento de input, altera o valor de v-model sempre que botao for clicado
             * 
             * @property {Boolean} checked
             * 
             * @event input
             * @vue
             */
            this.$emit("input", this.checked);
        }
    },
    methods: {
        click() {
            if (!this.disabled) {
                this.checked = !this.checked;
            }
        }
    },
    template: (
        /*html*/
        `
            <v-hover v-model="hover">
                <base-tooltip :text="tooltipText">
                    <v-card
                        elevation="0" 
                        :class="(bgColor + ' rounded-lg text-center ' + (disabled ? 'cursor-not-allowed' : ''))"
                        width="90"
                        height="90"
                        @click="click"
                    >
                        <v-flex>
                            <v-row no-gutters justify="end">
                                <v-col cols="auto">
                                    <v-checkbox
                                        v-model="checked"
                                        readonly
                                        class="ma-0 pt-2 mt-n1 mr-n1"
                                        hide-details
                                        dense
                                        :color="contentColorChecked"
                                        :disabled="disabled"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-row no-gutters justify="center" class="mt-n4 pa-3 pt-0">
                                <v-col cols="12">
                                    <v-icon large :color="iconColor">{{ icon }}</v-icon>
                                </v-col>
                                <v-col cols="12" class="mt-2">
                                    <p :class="textColor + ' font-size-10 user-select-none'" v-html="label"></p>
                                </v-col>
                            </v-row>
                        </v-flex>
                    </v-card>
                </base-tooltip>
            </v-hover>
        `
    )
};