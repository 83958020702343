import AgroupReportData from "../../reports/components/AgroupReportData";

export default {
    components: {
        AgroupReportData
    },
    data: function() {
        return { };
    },
    template: (
        /*html*/
        `
            <v-row justify="center" class="pa-15">
                <v-col cols="12" sm="12" md="6" lg="5" xl="4">
                    <agroup-report-data></agroup-report-data>
                </v-col>
            </v-row>
        `
    )
};