import CardContentClass from "../../classes/CardContentClass";
import BaseTooltip from "../atomos/BaseTooltip?v=2";

/**
 * Componente Progress Card
 * <iframe src="http://localhost:8080/v2/documentacao/moleculas/progress-card-usage" style="width:100%; height: 215px;"></iframe>
 * 
 * @requires {@link CardContentClass}
 * @requires {@link BaseTooltip}
 *
 * @displayName ProgressCard
 * @component
 * @category Moleculas
 * @vue
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Lista de objetos do tipo {@link CardContentClass}
         */
        listContent: {
            type: Array,
            required: true,
            validator(data) {
                let is_valid = true;
                for (const element of data) {
                    if (!(element instanceof CardContentClass)) {
                        is_valid = false;
                        break;
                    }
                }
                return is_valid;
            }
        },
        /**
         * Icone do card
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * Título do card
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Total do card
         */
        total: {
            type: [String, Number],
            required: false,
            default: 0
        },
        /**
         * Cor do conteúdo
         */
        contentColor: {
            type: String,
            required: false,
            default: "gray-0"
        },
        /**
         * Cor do background
         */
        backgroundColor: {
            type: String,
            required: false,
            default: "primary-blue-500"
        },
        /**
         * loading do card
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Texto do tooltip do card
         */
        tooltipText: {
            type: String,
            required: true
        },
        /**
         * Desabilitar o tooltip
         */
        disabledTooltip: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Callback ao clicar no link
         */
        linkCallback: {
            type: Function,
            required: false,
            default() {
                return () => { };
            }
        },
        /**
         * Texto do link
         */
        linkText: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Cor do texto do link
         */
        linkColor: {
            type: String,
            required: false,
            default: "secondary-blue-300",
        },
        /**
         * Texto do tooltip do link
         */
        tooltipLink: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            array_content: []
        };
    },
    methods: {
        /**
         * Realiza o cálculo de porcentagem
         * @param {Number} dividendo 
         * @param {Number} divisor 
         * @vue
         * @returns {Number}
         */
        calculatePercentage(dividendo, divisor) {
            let result = (dividendo / (divisor || 10) * 100).toFixed(2);
            return isNaN(result) ? 0 : result;
        },
        /**
         * Formata os numeros para o padrão ###.###.###
         * @param {number} number 
         * @vue
         */
        formatNumbers(number) {
            return (new Intl.NumberFormat("pt-BR")).format(number);
        }
    },
    template: (
        /*html*/
        `
        <v-card class="pa-6" :color="backgroundColor" elevation="0" width="100%">
            <v-row v-if="loading">
                <v-col cols="12">
                    <v-row no-gutters justify="space-between" align="center">
                        <v-col cols="8" class="pr-2">
                            <v-skeleton-loader width="150" type="chip" />
                        </v-col>
                        <v-col cols="auto">
                            <v-skeleton-loader type="avatar" />
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-6 gap-6">
                        <v-col>
                            <v-row class="mb-2" no-gutters>
                                <v-col class="text-start">
                                    <v-skeleton-loader width="25" type="text" />
                                </v-col>
                            </v-row>
                            <v-row class="mb-2" no-gutters>
                                <v-col class="text-start">
                                    <v-skeleton-loader width="60" type="text" />
                                </v-col>
                            </v-row>
                            <v-row class="d-none d-md-block mb-2" no-gutters>
                                <v-col>
                                    <v-skeleton-loader width="100%" type="text" />
                                </v-col>
                            </v-row>
                            <v-row class="d-none d-md-block" no-gutters>
                                <v-col class="text-start">
                                    <v-skeleton-loader width="60" type="text" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row class="mb-2" no-gutters>
                                <v-col class="text-start">
                                    <v-skeleton-loader width="25" type="text" />
                                </v-col>
                            </v-row>
                            <v-row class="mb-2" no-gutters>
                                <v-col class="text-start">
                                    <v-skeleton-loader width="60" type="text" />
                                </v-col>
                            </v-row>
                            <v-row class="d-none d-md-block mb-2" no-gutters>
                                <v-col>
                                    <v-skeleton-loader width="100%" type="text" />
                                </v-col>
                            </v-row>
                            <v-row class="d-none d-md-block" no-gutters>
                                <v-col class="text-start">
                                    <v-skeleton-loader width="60" type="text" />
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row class="mb-2" no-gutters>
                                <v-col class="text-start">
                                    <v-skeleton-loader width="25" type="text" />
                                </v-col>
                            </v-row>
                            <v-row class="mb-2" no-gutters>
                                <v-col class="text-start">
                                    <v-skeleton-loader width="60" type="text" />
                                </v-col>
                            </v-row>
                            <v-row class="mb-2" no-gutters>
                                <v-col>
                                    <v-skeleton-loader width="100%" type="text" />
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="text-start">
                                    <v-skeleton-loader width="60" type="text" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-else no-gutters>
                <v-col cols="12">
                    <v-row no-gutters justify="space-between" align="center">
                        <v-col cols="auto">
                            <v-row no-gutters>
                                <base-tooltip :disabled="disabledTooltip" :text="tooltipText">
                                    <v-col cols="auto">
                                        <v-row no-gutters>
                                            <v-col cols="auto" class="pr-2">
                                                <span :class="'text-' + contentColor + ' body-1'" v-html="title" />
                                            </v-col>
                                            <v-col cols="auto" class="pr-2">
                                                <span :class="'text-' + contentColor + ' subtitle-3'">{{ formatNumbers(total) }}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </base-tooltip>
                                <base-tooltip :disabled="disabledTooltip || !tooltipLink" :text="tooltipLink">
                                    <v-col v-if="linkCallback && linkText" cols="auto" class="d-none d-md-block">
                                        <span :class="'text-' + linkColor + ' body-2 cursor-pointer-hover'" v-html="linkText" @click="linkCallback" />
                                    </v-col>
                                </base-tooltip>
                            </v-row>
                        </v-col>
                        <v-col cols="auto">
                            <v-icon :color="contentColor" size="40" >
                                {{icon}}
                            </v-icon>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-6 gap-6 flex-nowrap">
                        <v-col v-for="(item, key) in listContent" cols="">
                            <base-tooltip :disabled="item.disable_tooltip" :text="item.tooltip">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <v-row class="mb-2" no-gutters>
                                            <v-col class="text-start">
                                                <span :class="'text-' + contentColor + ' body-2 bold'">{{ formatNumbers(item.dividendo) }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-row :class="'mb-2 ' + (item.item_click ? 'cursor-pointer-hover' : '')" no-gutters @click="$emit('click', item.item_click)">
                                            <v-col class="text-start">
                                                <span  :class="'text-' + contentColor + ' body-2 ' + (item.item_click ? 'text-decoration-underline' : '')" v-html="item.name"></span>
                                            </v-col>
                                            <v-col v-if="item.item_click" cols="auto">
                                                <v-icon :color="contentColor" right small>mdi-chevron-right</v-icon>
                                            </v-col>
                                        </v-row>
                                        <v-row class="d-none d-sm-block mb-2" no-gutters>
                                            <v-col>
                                                <v-progress-linear
                                                    :color="contentColor"
                                                    class="rounded-4"
                                                    :value="calculatePercentage(item.dividendo,item.divisor)"
                                                    height="6"
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="!item.hide_percent" class="d-none d-sm-block" no-gutters>
                                            <v-col class="text-start">
                                                <span  :class="'text-' + contentColor + ' subtitle-3'">{{ calculatePercentage(item.dividendo,item.divisor) }} %</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row> 
                            </base-tooltip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
        `
    )
};