import services from "../../../http/index";

export const ActionDoGetCampanhasByFilter = (context, params) => {
    return services.api.request({
        url: "/campaign/list-campaigns",
        method: "post",
        data: params
    }).then(response => {
        if (params.download) {
            return {
                "data": response.data,
                "name": decodeURIComponent(encodeURI(response.headers["file-name"]))
            };
        } else {
            let resp = response.data;
            return resp;
        }
    });
};