import * as types from "./mutations-types";

export default {
    [types.SET_USER](state, user) {
        state.user = user;
    },
    [types.SET_TYPE_PRODUCT](state, product) {
        state.type_product = product;
    },
    [types.SET_LIST_EMPRESAS](state, lista) {
        state.list_empresas = lista;
    },
    [types.SET_EMPRESA](state, empresa) {
        state.empresa_selected = empresa;
    }
};