import PreviewText from "../../../components/atomos/PreviewText";
import CellPhonePreview from "../../../components/atomos/CellPhonePreview";
import TemplateUtils from "../../../utils/TemplateUtils";

/**
 * Componente para documenta��o do componente PreviewText
 * 
 * @requires {@link CellPhonePreview}
 * @requires {@link TemplateUtils}
 * 
 * @displayName PreviewTextUsage
 * @category Atomos
 * @author Bruno Dias Ramos <bruno.dias@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */

export default {
    name: "PreviewTextUsage",
    components: {
        PreviewText,
        CellPhonePreview,
    },

    data() {
        return {
            message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            selectOptions: [],
            fieldsTemplate: {},
            template_utils: new TemplateUtils(),
        };
    },

    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6" class="text-center">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field label="Texto" v-model="message"></v-text-field>
                                </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-card
                                        elevation="0"
                                        :height="495"
                                        width="100%"
                                    >
                                        <v-row no-gutters justify="center">
                                            <v-col cols="auto">
                                                <v-card
                                                    elevation="0"
                                                    :height="495 - 30"
                                                >
                                                    <v-card
                                                        height="444"
                                                        elevation="0"
                                                        class="bg-transparent"
                                                    >
                                                        <cell-phone-preview
                                                            show-header
                                                            :show-footer="false"
                                                            :show-preview-message="false"
                                                            height="100%"
                                                            :width="300"
                                                            :content-fill="false"
                                                        >
                                                            <v-row no-gutters>
                                                                <v-col cols="12">
                                                                    <preview-text
                                                                        :list-message="template_utils.formatMessageList(message)"
                                                                        :select-options="selectOptions"
                                                                        :fields-template="fieldsTemplate"
                                                                    />
                                                                </v-col>
                                                            </v-row>
                                                        </cell-phone-preview>
                                                    </v-card>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};