export default {
    template: (
        /*html*/
        `
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
            <g id="Btn-FinalizarChat-Hover" transform="translate(-1816 -2155)">
                <g id="Ico-FinalizarChat-Hover" transform="translate(1751.931 2142.895)">
                    <g id="Grupo_2232" data-name="Grupo 2232" transform="translate(-7.495 -21.063)">
                        <path id="Caminho_561" data-name="Caminho 561" d="M93.7,53.24l-1.75-1.75a3.1,3.1,0,0,0,1.082-1.039l0,0c.011-.018.019-.037.029-.055.05-.083.1-.168.137-.256.023-.047.043-.1.064-.144.036-.087.07-.173.1-.263.023-.072.039-.146.056-.22s.037-.142.048-.216a2.95,2.95,0,0,0,.04-.473h0v-6.8a3.107,3.107,0,0,0-3.094-3.094H79.39l-2.545-2.546a.75.75,0,0,0-1.061,1.061L92.637,54.3A.75.75,0,1,0,93.7,53.24Z" fill="currentColor"/>
                        <path id="Caminho_562" data-name="Caminho 562" d="M75.977,42.03v6.8a3.093,3.093,0,0,0,2.346,3.006v2.91a.75.75,0,0,0,.463.693.742.742,0,0,0,.287.057.752.752,0,0,0,.531-.219l3.347-3.348H88.14L76.509,40.295A3.1,3.1,0,0,0,75.977,42.03Z" fill="currentColor"/>
                    </g>
                </g>
                <rect id="Retângulo_486" data-name="Retângulo 486" width="26" height="26" transform="translate(1816 2155)" fill="currentColor" opacity="0"/>
            </g>
        </svg>
        `
    )
};