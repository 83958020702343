import BaseTextarea from "../../../components/atomos/BaseTextarea?v=2";
import SectionCard from "../../../components/moleculas/SectionCard?v=2";
import BaseButton from "../../../components/atomos/BaseButton?v=2";
import BaseTextField from "../../../components/atomos/BaseTextField?v=2";

/**
 * Componente para adição de mensagem no cadastro de template de RCS
 * 
 * @requires {@link BaseTextarea}
 * @requires {@link SectionCard}
 * @requires {@link BaseButton}
 * @requires {@link BaseTextField}
 * @component
 * @displayName AddMessageTemplate
 * @category Page / Template RCS
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTextarea,
        SectionCard,
        BaseButton,
        BaseTextField
    },
    props: {
        /**
         * Value do campo passado por v-model
         */
        value: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Quantidade máxima de caracteres
         */
        counter: {
            type: [Number, String],
            required: false,
            default: 1000
        },
        /**
         * Título da mensagem
         */
        title: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Informa se o modelo exibira o title
         */
        showTitle: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Informa se é para criação de mensagem de fallback
         */
        isFallback: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            value_title: this.title,
            content: this.value,
            disable_button: true,
            msg_rules: {
                value_title: [
                    v => !!v || window.Vue.htmlEntities("Voc&ecirc; precisa inserir um t&iacute;tulo"),
                    v => v !== null && v !== undefined && !!v.trim() || window.Vue.htmlEntities("N&atilde;o &eacute; permitido o t&iacute;tulo vazio"),
                ],
                content: [
                    v => !!v || window.Vue.htmlEntities("Voc&ecirc; precisa inserir uma mensagem"),
                    v => v !== null && v !== undefined && !!v.trim() || window.Vue.htmlEntities("N&atilde;o &eacute; permitido a mensagem vazia"),
                ],
            },
        };
    },
    computed: {
        isEmpty() {
            return (!this.value || this.value == "") && (!this.title || this.title == "");
        },
        showSubmitButton() {
            if (this.isFallback == true || !this.showTitle) { //Se for fallback ou não exibir o title
                return (this.content && this.content.trim() !== "") &&
                    !this.disable_button;
            } else {
                return (this.value_title && this.value_title.trim() !== "") &&
                    (this.content && this.content.trim() !== "") &&
                    !this.disable_button;
            }
        },
        showUnsetButtons() {
            return (this.content && this.content != "") || (this.value_title && this.value_title != "");
        },
        showCancelButton() {
            return (this.value && this.value != "" && !this.disable_button && this.value !== this.content)
                || (this.title && this.title != "" && !this.disable_button && this.title !== this.value_title);
        },
        total() {
            let total_title = this.value_title ? this.value_title.length : 0;
            let total_message = this.content ? this.content.length : 0;

            return total_title + total_message;
        },
        errorCount() {
            return this.total > this.counter;
        },
        rules() {
            return this.errorCount ? [() => false || "O texto excedeu o limite de caracteres"] : [];
        }
    },
    watch: {
        value() {
            this.content = this.value;
        },
        title() {
            this.value_title = this.title;
        },
        content(new_value, old_value) {
            if (new_value !== old_value && new_value !== "" && new_value !== null && this.total <= this.counter) {
                this.disable_button = false;
            } else {
                this.disable_button = true;
            }
        },
        value_title(new_value, old_value) {
            if (new_value !== old_value && new_value !== "" && new_value !== null && this.total <= this.counter) {
                this.disable_button = false;
            } else {
                this.disable_button = true;
            }
        }
    },
    methods: {
        /**
         * Evento de click do botão de adição de texto
         * @vue
         */
        clickButton() {
            this.disable_button = true;
            // Se for mensagem de fallback, não permite quebra de linha no texto
            if (this.isFallback) {
                this.content = this.content.replace(/\s{2,}/g, " "); // Se houver mais de 3 espaços em branco, substitui por 1
            }
            /**
             * Altera o valor de v-model e exibe preview
             * @event changeMessage
             * @vue
             */
            this.$emit("changeMessage", {
                "title_input": this.value_title ? this.value_title.trim() : null,
                "message_input": this.content ? this.isFallback ? this.content.trim() : this.content : null
            });
        },
        /**
         * Realiza a limpeza das informações no formulário
         * @vue
         */
        clearContent() {
            this.content = "";
            this.value_title = "";
            this.clickButton();
        },
        /**
         * Cancela a edição dos campos e reseta para os valores salvos
         */
        cancelEdit() {
            this.content = this.value;
            this.value_title = this.title;
        }
    },
    template: (
        /*html*/
        `
            <section-card
                :title="'Utilize a caixa de texto para escrever a mensagem, voc&ecirc; pode adicionar at&eacute; ' + counter + ' caracteres.'"
                icon="$caixaTextoIcon"
            >
                <template #header>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-row v-if="showTitle" no-gutters>
                                <v-col cols="12">
                                    <base-text-field v-model="value_title" title="T&iacute;tulo" label="Escreva o t&iacute;tulo" :rules="msg_rules.value_title"/>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12 mt-2">
                                    <base-textarea v-model="content" title="Mensagem" label="Escreva o conte&uacute;do da mensagem..." :rules="msg_rules.content"/>
                                </v-col>
                                <v-col cols="12" class="mt-4 text-end">
                                    <span :class="'caption ' + (errorCount ? 'text-error-500' : 'text-gray-500')">{{ total }}/{{ counter }} caracteres</span>
                                </v-col>
                            </v-row>
                            <v-row v-if="showSubmitButton" no-gutters class="mt-6">
                                <v-col cols="12">
                                    <base-button
                                        primary
                                        block
                                        :label="isEmpty ? 'Adicionar mensagem' : 'Alterar mensagem'"
                                        :icon="isEmpty ? 'mdi-plus-thick' : 'mdi-refresh'"
                                        :disabled="disable_button"
                                        @click="clickButton"
                                    ></base-button>
                                </v-col>
                            </v-row>
                            <v-row v-if="showUnsetButtons" no-gutters class="mt-6" :justify="showCancelButton ? 'space-around' : 'center'">
                                <v-col cols="auto">
                                    <base-button
                                        tertiary
                                        danger
                                        :label="isEmpty ? 'Limpar' : 'Excluir mensagem'"
                                        icon="mdi-trash-can"
                                        width="auto"
                                        @click="clearContent"
                                    ></base-button>  
                                </v-col>
                                <v-col v-if="showCancelButton" cols="auto">
                                    <base-button
                                        tertiary
                                        danger
                                        :label="'Cancelar edi&ccedil;&atilde;o' | htmlEntities"
                                        icon="mdi-close-thick"
                                        width="auto"
                                        @click="cancelEdit"
                                    ></base-button>  
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </template>
            </section-card>
        `
    )
};