import BaseButton from "../atomos/BaseButton?v=2";
import BaseIconButton from "../atomos/BaseIconButton?v=3";

/**
 * Componente de botao acoes
 * 
 * @displayName ButtonAction
 * @requires {@link BaseButton}
 * @requires {@link BaseIconButton}
 * @component
 * @category Moleculas
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseButton,
        BaseIconButton
    },
    props: {
        /**
         * Tipo do botao de acao (open)
         */
        type: {
            type: String,
            required: true,
            validator: function (data) {
                return [
                    "open",
                    "edit",
                    "escalonar",
                    "pause",
                    "play",
                    "cancel",
                    "moreInfo",
                    "delete",
                    "disable",
                    "download",
                    "userPermission",
                    "internalPermission",
                    "chat",
                    "chatFinish",
                    "mail",
                    "confirm",
                    "discard",
                    "copy",
                    "enable"
                ].indexOf(data) > -1;
            }
        },
        /**
         * Indica se o botao deve ter o estado de desabilitado
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica se o botao deve ter o estado de loading
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica se o tamanho do botao
         */
        size: {
            type: Number,
            required: false,
            default: 32
        },
        /**
         * Indica se o tamanho do botao ser� do tipo small
         */
        small: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            hover: false
        };
    },
    computed: {
        configButton() {
            switch (this.type) {
            case "open":
                return {
                    icon: "$openOutlineIcon",
                    icon_hover: "$openIcon",
                    color: "primary-blue-500",
                    tooltip: "Detalhes da campanha"
                };
            case "edit":
                return {
                    icon: "$editOutlineIcon",
                    icon_hover: "$editIcon",
                    color: "primary-blue-500",
                    tooltip: "Editar"
                };
            case "escalonar":
                return {
                    icon: "$escalonarOutlineIcon",
                    icon_hover: "$escalonarIcon",
                    color: "primary-blue-500",
                    tooltip: "Escalonar"
                };
            case "pause":
                return {
                    icon: "$pauseOutlineIcon",
                    icon_hover: "$pauseIcon",
                    color: "gray-700",
                    tooltip: "Pausar"
                };
            case "play":
                return {
                    icon: "$playOutlineIcon",
                    icon_hover: "$playIcon",
                    color: "primary-green",
                    tooltip: "Reiniciar"
                };
            case "cancel":
                return {
                    icon: "$cancelOutlineIcon",
                    icon_hover: "$cancelOutlineIcon",
                    color: "error-500",
                    tooltip: "Cancelar"
                };
            case "moreInfo":
                return {
                    icon: "$moreInfoIcon",
                    icon_hover: "$moreInfoIcon",
                    color: "gray-700",
                    tooltip: "Mais informa&ccedil;&otilde;es"
                };
            case "delete":
                return {
                    icon: "$trashOutlineIcon",
                    icon_hover: "$openTrashIcon",
                    color: "error-500",
                    tooltip: "Excluir"
                };
            case "disable":
                return {
                    icon: "$disableOutlineIcon",
                    icon_hover: "$disableIcon",
                    color: "error-500",
                    tooltip: "Desativar"
                };
            case "download":
                return {
                    icon: "$downloadOutlineIcon",
                    icon_hover: "$downloadIcon",
                    color: "primary-blue-500",
                    tooltip: "Download"
                };
            case "userPermission":
                return {
                    icon: "$userLockedOutlineIcon",
                    icon_hover: "$userUnlockedIcon",
                    color: "primary-blue-500",
                    tooltip: "Permiss&otilde;es do usu&aacute;rio"
                };
            case "internalPermission":
                return {
                    icon: "$internalPermissionOutlineIcon",
                    icon_hover: "$internalPermissionIcon",
                    color: "primary-blue-500",
                    tooltip: "Permiss&otilde;es internas"
                };
            case "chat":
                return {
                    icon: "$chatOutlineIcon",
                    icon_hover: "$chatIcon",
                    color: "primary-blue-500",
                    tooltip: "Ver conversa"
                };
            case "chatFinish":
                return {
                    icon: "$chatFinishOutlineIcon",
                    icon_hover: "$chatFinishIcon",
                    color: "error-500",
                    tooltip: "Finalizar chat"
                };
            case "mail":
                return {
                    icon: "$mailOutlineIcon",
                    icon_hover: "$mailOpenOutlineIcon",
                    color: "primary-blue-500",
                    tooltip: "Ver email"
                };
            case "confirm":
                return {
                    icon: "$checkIcon",
                    icon_hover: "$checkIcon",
                    color: "primary-green",
                    tooltip: "Confirmar"
                };
            case "discard":
                return {
                    icon: "$discardIcon",
                    icon_hover: "$discardIcon",
                    color: "error-500",
                    tooltip: "Descartar"
                };
            case "copy":
                return {
                    icon: "mdi-content-copy",
                    icon_hover: "mdi-content-copy",
                    color: "primary-blue-500",
                    tooltip: "Copiar"
                };
            case "enable":
                return {
                    icon: "mdi-check-circle-outline",
                    icon_hover: "mdi-check-circle",
                    color: "success-500",
                    tooltip: "Ativar"
                };
            default:
                return {
                    icon: "",
                    icon_hover: "",
                    color: "",
                    tooltip: ""
                };
            }
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters class="justify-content-center">
                <v-hover v-model="hover">
                    <v-col cols="auto">
                        <base-icon-button
                            :value="false"
                            :icon="configButton.icon"
                            :tooltip="configButton.tooltip"
                            :size="size"
                            :disabled="disabled || loading"
                            :color="hover && !disabled && !loading ? configButton.color : undefined"
                            :text-color="hover && !disabled && !loading ? 'white' : (disabled || loading ? 'gray-50' : configButton.color)"
                            :rounded="false"
                            @click="$emit('click')"
                            :small="small"
                        ></base-icon-button> 
                    </v-col>
                </v-hover>
            </v-row>
        `
    )
};