import { DraggableItemClass } from "../../classes/DraggableItemClass?v=2";
import BasePill from "../atomos/BasePill?v=2";
/**
 * Componente de Group Pills
 *
 * @displayName GroupPills
 * @component
 * @category Moleculas
 * @requires {@link DraggableItemClass}
 * @requires {@link BasePill}
 * @vue
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br>
 */
export default {
    components: {
        BasePill
    },
    props: {
        /**
         * Lista de pill default
         */
        listPillsDefault: {
            type: Array,
            required: false,
            default: null,
            validator(data) {
                let is_valid = true;

                for (const element of data) {
                    if (!(element instanceof DraggableItemClass)) {
                        is_valid = false;
                        break;
                    }
                }

                return is_valid;
            }
        },
        /**
         * Descricao da lista default
         */
        descriptionDefault: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Lista de pills personalizados
         */
        listPillsCustom: {
            type: Array,
            required: false,
            default: null
        },
        /**
         * Descricao da lista custom
         */
        descriptionCustom: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Nome do grupo draggable
         */
        groupName: {
            type: String,
            required: true
        },
        /**
         * Altura do componente
         */
        height: {
            type: String,
            required: false,
            default: "200px"
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters> 
                <v-col cols="12">
                    <v-card elevation="0" :min-height="height" class="overflow-hidden">
                        <v-row v-if="listPillsDefault && listPillsDefault.length > 0" no-gutters>
                            <v-col>
                                <v-row no-gutters>
                                    <v-col>
                                        <span class="body-2 text-gray-700" v-html="descriptionDefault"></span>
                                    </v-col>
                                </v-row>
                                <draggable :list="listPillsDefault" :sort="false" :group="{ name: groupName, put: false, pull: 'clone' }" class="row no-gutters pa-4 mt-0">
                                    <v-col cols="auto" v-for="(item, index) in listPillsDefault" :key="item.pill.id" class="pr-2 pb-2">
                                        <base-pill 
                                            :title="item.pill.title"
                                            :sub-title="item.pill.subTitle"
                                            :title-icon="item.pill.titleIcon"
                                            :prepend-icon="item.pill.prependIcon"
                                            :draggable="item.pill.draggable"
                                            :editable="item.pill.editable"
                                            :deletable="item.pill.deletable"
                                            :removable="item.pill.removable"
                                            :rounded="item.pill.rounded"
                                            :content-color="item.pill.contentColor"
                                            :background-color="item.pill.backgroundColor"
                                            :tooltip-text="item.pill.tooltipText"
                                            :number-characters="item.pill.numberCharacters"
                                            :sub-title-new-line="item.pill.subTitleNewLine"
                                            @click="$emit('click', item)"
                                        ></base-pill>
                                    </v-col>
                                </draggable>
                            </v-col>
                        </v-row>
                        <v-row v-if="listPillsCustom && listPillsCustom.length > 0" no-gutters>
                            <v-col>
                                <v-row no-gutters>
                                    <v-col>
                                        <span class="body-2 text-gray-700" v-html="descriptionCustom"></span>
                                    </v-col>
                                </v-row>
                                <draggable :list="listPillsCustom" :sort="false" :group="{ name: groupName, put: false, pull: 'clone' }" class="row no-gutters pa-4 mt-0">
                                    <v-col cols="auto" v-for="(item, index) in listPillsCustom" :key="item.pill.id" class="pr-2 pb-2">
                                        <base-pill 
                                            :title="item.pill.title"
                                            :sub-title="item.pill.subTitle"
                                            :title-icon="item.pill.titleIcon"
                                            :prepend-icon="item.pill.prependIcon"
                                            :draggable="item.pill.draggable"
                                            :editable="item.pill.editable"
                                            :deletable="item.pill.deletable"
                                            :removable="item.pill.removable"
                                            :rounded="item.pill.rounded"
                                            :content-color="item.pill.contentColor"
                                            :background-color="item.pill.backgroundColor"
                                            :tooltip-text="item.pill.tooltipText"
                                            :number-characters="item.pill.numberCharacters"
                                            :sub-title-new-line="item.pill.subTitleNewLine"
                                            :selected="item.pill.selected"
                                            @click="$emit('click', item)"
                                            @delete="$emit('delete', item)"
                                            @edit="$emit('edit', item)"
                                        ></base-pill>
                                    </v-col>
                                </draggable>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-row justify="center" align="center" class="pa-2 mt-2 bg-gray-10" no-gutters>
                        <v-col cols="auto">
                            <span class="caption text-gray-500">Clique ou arraste para adicionar</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
         
        `
    )
};