import BaseButton from "../atomos/BaseButton?v=2";

/**
 * Componente de de tour para o painel
 * 
 * @requires {@link BaseButton}
 * @displayName PgTour
 * @category Organismos
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseButton
    },
    props: {
        /**
         * Lista de passos do tour
         */
        steps: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            options: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: "Pular tutorial",
                    buttonPrevious: "Anterior",
                    buttonNext: "Proximo",
                    buttonStop: "Finalizar"
                },
            },
            tour_name: "pgTour" + Math.random().toString(),
            show_tutorial: false,
            current_tutorial: null
        };
    },
    computed: {
        ...window.Vuex.mapState("utils", {
            showing_news: state => state.showing_news
        }),
        listSteps() {
            let new_steps = [];
            let count = 0;

            for (let step of this.steps) {
                new_steps.push(step);

                if (new_steps[count].params !== undefined) {
                    new_steps[count].params["placement"] = "auto";
                } else {
                    new_steps[count]["params"] = {
                        placement: "auto"
                    };
                }

                count++;
            }

            return new_steps;
        }
    },
    watch: {
        showing_news(new_val) {
            if (!new_val && this.show_tutorial) {
                this.show_tutorial = false;
                this.$tours[this.tour_name].start();
            }
        },
        steps() {
            if (this.current_tutorial !== window.location.pathname + "-" + this.steps[0].target) {
                this.getStatusTutorial();
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.getStatusTutorial();
        }, 1000);
    },
    methods: {
        ...window.Vuex.mapActions("utils", [
            "ActionDoSaveStatusTour",
            "ActionDoGetTourInteraction"
        ]),
        start(step) {
            this.$tours[this.tour_name].start(step);
        },
        stop() {
            this.$tours[this.tour_name].stop();
            this.saveStatusTutorial();
        },
        finish() {
            this.$tours[this.tour_name].finish();
            this.saveStatusTutorial();
        },
        getTitle(step, title) {
            return (step + 1) + ". " + title;
        },
        changeStep(step) {
            this.$tours[this.tour_name].currentStep = step;
        },
        /**
         * Busca os dados para verificar se o usuario ja visualizou este tutorial
         */
        getStatusTutorial() {
            if (this.steps.length && this.steps[0].target !== undefined) {
                this.current_tutorial = window.location.pathname + "-" + this.steps[0].target;

                let form_data = new FormData();
                form_data.append("nome_tela", this.current_tutorial);

                this.ActionDoGetTourInteraction(form_data).then((response) => {
                    let viewed_tutorial = false;

                    for (let interaction of response) {
                        if (interaction.id_tipo == "2" && new Date(interaction.last_interaction) > (this.steps[0].date ? new Date(this.steps[0].date) : new Date("2022-10-07 00:00:00"))) {
                            viewed_tutorial = true;
                        }
                    }

                    if (!viewed_tutorial) {
                        if (!this.showing_news) {
                            this.$tours[this.tour_name].start();
                        } else {
                            this.show_tutorial = true;
                        }
                    }
                });
            }
        },
        /**
         * Finaliza o novidades
         * @vue
         */
        saveStatusTutorial() {
            let form_data = new FormData();
            form_data.append("nome_tela", this.current_tutorial);
            form_data.append("evento", "sair");
            form_data.append("passo", "1");
            form_data.append("bloqueio", "0");
            form_data.append("tipo_tour", "2");

            this.ActionDoSaveStatusTour(form_data);

            this.$emit("closed");
        },
    },
    template: (
        /*html*/
        `
        <v-tour :name="tour_name" :steps="listSteps" :options="options">
            <template slot-scope="tour">
                <transition name="fade" appear>
                    <v-step
                        v-if="tour.steps[tour.currentStep]"
                        :highlight="true"
                        :key="tour.currentStep"
                        :step="tour.steps[tour.currentStep]"
                        :previous-step="tour.previousStep"
                        :next-step="tour.nextStep"
                        :stop="tour.stop"
                        :skip="tour.skip"
                        :is-first="tour.isFirst"
                        :is-last="tour.isLast"
                        :labels="tour.labels"
                        class="pa-8 elevation-7 rounded-1 bg-gray-0"
                    >
                        <template slot="header">
                            <v-btn small icon color="gray-300" absolute right top @click="stop">
                                <v-icon size="21" color="gray-300">fa-times</v-icon>
                            </v-btn>
                            <v-row no-gutters>
                                <v-col>
                                    <p class="subtitle-3 text-primary-blue-500 mb-6" v-html="getTitle(tour.currentStep, tour.steps[tour.currentStep].title)"></p>
                                </v-col>
                            </v-row>
                        </template>
                        <template slot="content">
                            <v-row no-gutters class="mb-8">
                                <v-col cols="12">
                                    <span class="body-2 text-gray-900" v-html="tour.steps[tour.currentStep].content"></span>
                                </v-col>
                            </v-row>
                        </template>
                        <template slot="actions">
                            <v-row justify="center" no-gutters class="mb-8">
                                <v-col
                                    v-for="(step, i) in tour.steps"
                                    :key="i"
                                    cols="auto"
                                    class="pa-1"
                                >
                                    <v-card
                                        elevation="0"
                                        width="34px"
                                        height="3px"
                                        @click="changeStep(i)"
                                        :class="i == tour.currentStep ? 'bg-secondary-blue-300' : 'bg-tertiary-blue-900'"
                                    ></v-card>
                                </v-col>
                            </v-row>
                            <v-row justify="center" no-gutters class="gap-4">
                                <v-col>
                                    <base-button
                                        secondary
                                        left
                                        width="-webkit-fill-available"
                                        :block="false"
                                        label="Anterior"
                                        icon="mdi-chevron-left"
                                        color="dark-grey"
                                        :disabled="tour.isFirst"
                                        @click="tour.previousStep"
                                    ></base-button>
                                </v-col>
                                <v-col>
                                    <base-button
                                        v-if="!tour.isLast"
                                        primary
                                        width="-webkit-fill-available"
                                        :block="false"
                                        label="Pr&oacute;ximo"
                                        icon="mdi-chevron-right"
                                        @click="tour.nextStep"
                                    ></base-button>
                                    <base-button
                                        v-if="tour.isLast"
                                        primary
                                        width="-webkit-fill-available"
                                        :block="false"
                                        label="Sair"
                                        icon="mdi-check-bold"
                                        @click="finish"
                                    ></base-button>
                                </v-col>
                            </v-row>
                        </template>
                    </v-step>
                </transition>
            </template>
        </v-tour>
    `
    )
};
