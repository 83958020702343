import BasePill from "../atomos/BasePill?v=2";
import BaseTooltip from "../atomos/BaseTooltip?v=2";

/**
 * Componente de componente de text field com valida&ccedil;&atilde;o de n&uacute;meros de telefone
 * 
 * @requires {@link BasePill}
 * @requires {@link BaseTooltip}
 *
 * @displayName TextFieldPhoneValidator
 * @component
 * @category Moleculas
 * @vue
 * @author Igor Mateus Rodrigues de Oliveira <igor.oliveira@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BasePill,
        BaseTooltip
    },
    props: {
        /**
         * Label do text-field-phone-validator
         */
        label: {
            type: String,
            required: false,
            default: ""
        },
        /**
        * Verificar se o text-field-phone-validator fica desabilitado ou nao
        */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
        * Hint do text-field-phone-validator
        */
        hint: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Define as regras a serem utilizadas para validacao dos dados inseridos
         */
        rules: {
            type: Array,
            required: false,
            default() {
                return [];
            }
        },
        /**
         * Define as regras a serem utilizadas para validacao dos dados inseridos
         */
        limit: {
            type: Number,
            required: false,
            default: 100
        },
        /**
         * Title do select
         */
        title: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            content: [],
            contentIndex: 0,
            message_tooltip: null,
            show: false,
            content_color: [],
        };
    },
    computed: {
        titleHtml() {
            return window.Vue.htmlEntities(this.title);
        }
    },
    watch: {
        content() {
            if (this.content.length > this.limit) {
                let new_list = this.content;
                new_list.pop();
                this.content = new_list;
            } else {
                this.valida_phone();
                /**
                * Evento de input, disparado sempre que o conteudo do campo for alterado
                * @event input
                * @vue
                * @property {Array} content  Array com os telefones
                */
                this.$emit("input", this.content);
            }
        }
    },
    methods: {
        /**
        * Metodo para validar telefones digitados 
        * @vue
        **/
        valida_phone() {
            this.esconderPopover();
            for (let index in this.content) {
                if (!(/^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/.test(this.content[index]))) {
                    this.message_tooltip = window.Vue.htmlEntities("Telefone inv&aacute;lido");
                    this.mostrarPopover();
                    this.content_color[index] = "error-900";
                } else {
                    this.esconderPopover();
                    this.content_color[index] = "green-900";
                }
            }
        },
        /**
        * Metodo para mostrar popover
        * @vue
        **/
        mostrarPopover() {
            this.show = true;
        },
        /**
        * Metodo para esconder popover
        * @vue
        **/
        esconderPopover() {
            this.show = false;
        },
        /**
        * Metodo que apaga a pill 
        * @param {Objetc} item que está sendo apagado
        * @param {Objetc} parent valor pai do componente 
        * @vue
        **/
        closeClick(item, parent) {
            this.content = this.content.filter((element) => element != item);
            this.esconderPopover();
            parent.selectItem(item);

        }
    },
    template:
        /*html*/
        `
        <v-row no-gutters>
            <v-col cols="12">
                <base-tooltip :show="show" :text="message_tooltip">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-row v-if="title" no-gutters>
                                <v-col>
                                    <span class="font-size-12">{{ titleHtml }}</span>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="12" class="pt-1">
                                    <v-combobox
                                        v-model="content"
                                        class="pb-n4 mb-n4"
                                        append-icon=""
                                        multiple
                                        :label="label"
                                        solo
                                        :rules="rules"
                                        :disabled="disabled"
                                        hide-details="auto"
                                    >
                                        <template #selection="{ item, index, parent }">
                                            <v-col cols="auto" class="px-0">
                                                <base-pill
                                                    rounded
                                                    removable
                                                    small
                                                    content-color="gray-0"
                                                    :prepend-icon="null"
                                                    :title="item"
                                                    :sub-title="' '"
                                                    :background-color="content_color[index]"
                                                    @remove="closeClick(item, parent)"
                                                />
                                            </v-col>
                                        </template>
                                        <template #message="{ message }">
                                            <v-row no-gutters justify="start" class="flex-nowrap">
                                                <v-col cols="auto">
                                                    <v-icon x-small left color="error-500">
                                                        mdi-alert
                                                    </v-icon>
                                                </v-col>
                                                <v-col class="text-error-500 text-start">
                                                    <span class="text-error-500 text-start caption" v-html="message" />
                                                </v-col>
                                            </v-row>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-row>
                            <v-row v-if="hint" no-gutters class="mt-2">
                                <v-col cols="12">
                                    <span class="caption text-gray-500" v-html="hint" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </base-tooltip>
            </v-col>
        </v-row>
        `
};
