import BaseButton from "../../../../components/atomos/BaseButton?v=2";
import PreviewImage from "../../../../components/atomos/PreviewImage";
import BaseDialog from "../../../../components/moleculas/BaseDialog";
import SectionCard from "../../../../components/moleculas/SectionCard";
import BaseSpanLimited from "../../../../components/atomos/BaseSpanLimited";
import BaseIconButton from "../../../../components/atomos/BaseIconButton";
import PreviewCard from "../../../../components/atomos/PreviewCard";
import BaseTooltip from "../../../../components/atomos/BaseTooltip";
import DialogCustomImageSimulator from "../dialogs/DialogCustomImageSimulator";

/**
 * Componente de formulario para adicionar imagens customizadas no template
 * 
 * @requires {@link BaseButton}
 * @requires {@link PreviewImage}
 * @requires {@link BaseDialog}
 * @requires {@link SectionCard}
 * @requires {@link BaseSpanLimited}
 * @requires {@link BaseIconButton}
 * @requires {@link PreviewCard}
 * @requires {@link BaseTooltip}
 * @requires {@link DialogCustomImageSimulator}
 * 
 * @component
 * @displayName FormAddCustomImage
 * @category Page / Template RCS
 * @subcategory Forms
 * @vue
 * @author David Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseButton,
        PreviewImage,
        BaseDialog,
        SectionCard,
        BaseSpanLimited,
        BaseIconButton,
        PreviewCard,
        BaseTooltip,
        DialogCustomImageSimulator
    },
    props: {
        /**
         * Indica que a utilizacao de imagem personalizada esta ativa
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            active: this.value,
            list_example: [
                {
                    telefone: "4199999991",
                    url: window.location.origin + "/images/cadastro-template/demonstra1.png",
                    action: 0,
                    title: "Imagem A",
                    description: "Exemplo de imagem personalizada para o primeiro contato",
                    tooltip: "Simula&ccedil;&atilde;o da mensagem do primeiro contato"
                },
                {
                    telefone: "4199999992",
                    url: window.location.origin + "/images/cadastro-template/demonstra2.png",
                    action: 1,
                    title: "Imagem B",
                    description: "Exemplo de imagem personalizada para o segundo contato",
                    tooltip: "Simula&ccedil;&atilde;o da mensagem do segundo contato"
                },
            ],
            show_simulator: false,
            image_simulator: null,
            index_simulator: null
        };
    },
    watch: {
        value() {
            this.active = this.value;
        },
        active() {
            if (this.active && this.active !== this.value) {
                this.showNotify({
                    notify_title: "Imagem personalizada selecionada",
                    notify_message: "Voc&ecirc; est&aacute; utilizando a imagem personalizada.",
                    removed_pill: null,
                    position_removed: null,
                    notify_button_label: null,
                    restore_type: null
                });
            }

            this.$emit("input", this.active);
        }
    },
    methods: {
        /**
         * Exibe o modal para simular o preview de card de imagem
         * @param {Object} item Dados de um item
         * @vue
         */
        showSimulator(item) {
            this.index_simulator = item.action;
            this.image_simulator = item.url;
            this.show_simulator = true;
        },
        /**
         * Altera a imagem default do simulador
         * @param {String} new_image URL da nova imagem
         * @vue
         */
        updateImage(new_image) {
            this.list_example[this.index_simulator].url = new_image;
            this.index_simulator = null;
        },
        /**
         * Emite um evento informando que uma notificacao deve ser exibida
         * @param {Object} data 
         * @vue
         */
        showNotify(data) {
            this.$emit("showNotify", data);
        }
    },
    template: (
        /*html*/
        `
        <section-card title="Envie imagens personalizadas para cada contato do arquivo de dados." icon="mdi-cloud-upload-outline" icon-size="40">
            <template #header>
                <v-row no-gutters>
                    <v-col cols="12" v-if="!active">
                        <base-button
                            primary
                            label="Utilizar imagem personalizada"
                            icon="mdi-check-bold"
                            @click="active = true"
                        ></base-button>
                    </v-col>
                    <v-col v-else cols="12">
                        <section-card title="Simule o arquivo de dados (opcional)" icon="mdi-eye-outline" icon-size="40">
                            <template #header>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <span class="body-2 text-gray-700">O arquivo ir&aacute; parecer com isso, essas informa&ccedil;&otilde;es s&atilde;o apenas uma simula&ccedil;&atilde;o da campanha:</span>
                                    </v-col>
                                    <v-col cols="12" class="mt-3">
                                        <base-tooltip text="Exemplo de arquivo de dados">
                                            <v-card elevation="0" class="border-1 border-gray-300 rounded-0">
                                                <v-data-table
                                                    :headers="[{ text: 'Telefone', value: 'telefone', sortable: false, width: '30px' }, { text: 'URL da imagem', value: 'url', sortable: false }, { text: '', value: 'action', sortable: false, width: '10px' }]"
                                                    :items="list_example"
                                                    class="elevation-0"
                                                    hide-default-footer
                                                    dense
                                                >
                                                    <template v-slot:item.url="{ item }">
                                                        <base-span-limited :text="item.url" :limit="30"></base-span-limited>
                                                    </template>
                                                    <template v-slot:item.action="{ item }">
                                                        <base-icon-button icon="mdi-pencil" size="32" @click="showSimulator(item)"></base-icon-button>
                                                    </template>
                                                </v-data-table>
                                            </v-card>
                                        </base-tooltip>
                                    </v-col>
                                    <v-col cols="12" class="mt-4">
                                        <span class="body-2 text-gray-700">As imagens apresentadas abaixo s&atilde;o apenas para <b>simula&ccedil;&atilde;o</b>:</span>
                                    </v-col>
                                    <v-col cols="12" class="mt-3">
                                        <v-row no-gutters>
                                            <v-col v-for="item in list_example" cols="6" class="px-2">
                                                <base-tooltip :text="item.tooltip">
                                                    <preview-card :image="item.url" :title="item.title" :description="item.description"></preview-card>
                                                </base-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </template>
                        </section-card>
                        <dialog-custom-image-simulator v-model="show_simulator" :image-url="image_simulator" @updateImage="updateImage"></dialog-custom-image-simulator>
                    </v-col>
                </v-row>
            </template>
        </section-card>
        `
    )
};