import CanalButton from "../../../components/atomos/CanalButton";

export default ({
    data: function () {
        return {
            button: {
                load: false
            }
        };
    },
    methods: {
        clicado: function () {
            this.button.load = true;
        }
    },
    components: {
        "canal-button": CanalButton
    },
    template: (
        /*html*/
        `<div class="container" id="vue-page">
                    <div class="row">
                        <div class="col">
                            <h5>Bot�es de sele��o de canal</h5>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='rcs'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='whatsapp'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-concatenado'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-flash'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-smart'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-interativo'></canal-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Ativo</h6>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='rcs' :active='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='whatsapp' :active='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-concatenado' :active='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms' :active='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-flash' :active='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-smart' :active='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-interativo' :active='true'></canal-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Desabilitado</h6>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='rcs' :disabled='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='whatsapp' :disabled='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-concatenado' :disabled='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms' :disabled='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-flash' :disabled='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-smart' :disabled='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-interativo' :disabled='true'></canal-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Carregando</h6>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='rcs' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='whatsapp' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-concatenado' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-flash' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-smart' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-interativo' :loading='true'></canal-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Desabilitado e carregando</h6>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='rcs' :disabled='true' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='whatsapp' :disabled='true' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-concatenado' :disabled='true' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms' :disabled='true' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-flash' :disabled='true' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-smart' :disabled='true' :loading='true'></canal-button>
                        </div>
                        <div class="col-12 col-md-4 col-xl mb-2">
                            <canal-button type='sms-interativo' :disabled='true' :loading='true'></canal-button>
                        </div>
                    </div>
                </div>

               `)
});