
const traducao_template = {'texto':'Texto','r':'Carrossel','m':'Texto','t':'Template','i':'Imagem','v':'Video','a':'Audio','f':'Arquivo','c':'Card'};
const traducao_tipo_template = [{'codigo':'R','nome':'Carrossel'},{'codigo':'M','nome':'Texto'},{'codigo':'T','nome':'Template'},{'codigo':'I','nome':'Imagem'},{'codigo':'V','nome':'Video'},{'codigo':'A','nome':'Audio'},{'codigo':'F','nome':'Arquivo'},{'codigo':'C','nome':'Card'}];
const type_icons = {
    'text': 'fa-align-left' ,
    'url': 'fa-globe-americas' ,
    'show_location': 'fa-crosshairs',
    'request_location': 'fa-map-marker-alt',
    'dial_phone': 'fa-phone'
}

const translate_types = {
    'text': 'texto' ,
    'url': 'link' ,
    'show_location': 'envio de localiza&ccedil;&atilde;o',
    'request_location': 'receber localiza&ccedil;&atilde;o',
    'dial_phone': 'discar n&uacute;mero'
}

const type_labels = {
    'url': 'URL' ,
    'dial_phone': 'Telefone'
}

const tipo_template_traducao = {
    'R': 'Carousel',
    'M': 'Texto',
    'T': 'Template',
    'I': 'Imagem',
    'V': 'Video',
    'A': 'Audio',
    'F': 'File',
    'C': 'Card'
}

export class TemplateModel{
    DS_TEMPLATE;
    CD_TEMPLATE;
    NM_EMPRESA;
    ID_EMPRESA;
    CD_PRODUTO;
    DS_TEMPLATE;
    TP_TEMPLATE;
    ID_TEMPLATE;
    NM_USUARIO; 
    DT_TEMPLATE;
    DS_LOGIN;
    DT_ULTIMA_ALTERACAO;

    constructor({DS_TEMPLATE, CD_TEMPLATE, NM_EMPRESA, ID_EMPRESA, CD_PRODUTO,TP_TEMPLATE, ID_TEMPLATE, NM_USUARIO, DT_TEMPLATE, DS_LOGIN, DT_ULTIMA_ALTERACAO}){
       this.DS_TEMPLATE = DS_TEMPLATE;
        this.CD_TEMPLATE = CD_TEMPLATE;
        this.NM_EMPRESA = NM_EMPRESA;
        this.ID_EMPRESA = ID_EMPRESA;
        this.CD_PRODUTO = CD_PRODUTO;
        this.DS_TEMPLATE = DS_TEMPLATE;
        this.TP_TEMPLATE = TP_TEMPLATE;
        this.ID_TEMPLATE = ID_TEMPLATE;
        this.NM_USUARIO = NM_USUARIO; 
        this.DT_TEMPLATE =  DT_TEMPLATE;
        this.DS_LOGIN = DS_LOGIN;
        this.DT_ULTIMA_ALTERACAO = DT_ULTIMA_ALTERACAO;
    }
}

export class TemplateService {

    base_url = '/Template';

    /**
     * Define as informa��es para os campos din�micos que podem ser inclu�dos no template
     *
     * Cada chave do objeto corresponde a um campo din�mico que pode ser adicionado
     * Cada valor do objeto � outro objeto que cont�m informa��es espec�ficas para o campo como:
     *
     *   - `type`: o tipo de dados que o campo deve conter. Por exemplo, 'text', 'number', etc.
     *   - `maxCaracteres`: o n�mero m�ximo de caracteres que o campo deve ter (se aplic�vel).
     *   - `format`: o formato em que os dados do campo devem ser apresentados (se aplic�vel).
     *
     * Essas informa�?es s�o usadas para preencher as op��es do campo "Tipo" na tabela din�mica,
     * e para definir a quantidade de caracteres dos respectivos campos quando um tipo espec�fico � selecionado.
     */
    fields = {
        "texto": { type: 'text', maxLength: 100},
        "senha": { maxLength: 4, type: 'number' },
        "data": { format: 'dd/mm/yyyy', type: 'date', pattern: '[0-9]{2}/[0-9]{2}/[0-9]{4}' },
        "horario": { format: 'hh:mm', type: 'time', pattern: '[0-9]{2}:[0-9]{2}' },
        "cartao": { maxLength: 4, type: 'number' },
        "quantidade_parcelas": { maxLength: 3, type: 'number' },
        "numero": { type: 'number', maxLength: 100 },
        "telefone": { format: '(00) 00000-0000', type: 'phone', pattern: "\\(\\d{2}\\) \\d{4,5}-\\d{4}" },
        "valor": { format: 'R$000.000,00', type: 'money' }
    };

    /**
     * Busca a lista de templates da empresa pai logada sem o interativo
     * @returns Promise<{ProdutoTema}>
     */
    
    meusTemplates() {
        return $.post(this.base_url + '/meusTemplates');
    }

    templatesCarteira(id_carteira) {
        return $.post(this.base_url + '/templatesCarteira', {id_carteira});
    }

    get traducao_tipo_template(){
        return traducao_tipo_template;
    }
    
    desativarTemplate(id_template){
        return $.post(this.base_url + '/desativarTemplate',{id_template});
    }

    desativarTemplateApps(id_template){
        return $.post(this.base_url + '/desativarTemplateApps',{id_template});

    }
    
    getTraducaoTipoTemplateApps(tipo)
    {
        return traducao_template[tipo.toLowerCase()];

    }
    /**
     * Busca a lista de templates de whatsapp da empresa pai logada sem o interativo
     * @returns Promise<{ProdutoTema}>
     */
    meusTemplatesApps() {
        return $.post(this.base_url + '/meusTemplatesApps');
    }

    /**
     * Recebe o texto de um template e substitui os valores de campo por inputs
     * com os atributos name e placeholder = nome do campo
     * @param template {string}
     * @returns {string}
     */
    camposTemplateComoInput(template, params_names, params_length = "", params_type = "") {
        if (params_names) {
            let match;
            let params = [];
            let map_params = {};
            let length_params = {};
            let type_params = {};
            let names = params_names.split(';');
            let lengths = params_length.split(';');
            let types = params_type.split(';');

            const regex = /\[CAMPO(\d+)\]/g;

            while ((match = regex.exec(template)) !== null) {
                params.push(`CAMPO${match[1]}`);
            }

            if (params.length === names.length) {
                for (let i = 0; i < params.length; i++) {
                    map_params[params[i]] = names[i];
                    length_params[params[i]] = lengths[i];
                    type_params[params[i]] = types[i];
                }

                for (let param in map_params) {
                    const regex = new RegExp("\\[" + param + "\\]", "gm");

                    const field_info = this.fields[type_params[param]] || this.fields.texto;
                    const input_type = field_info.type || 'text';
                    const maxLength = length_params[param] || field_info.maxLength || 100;
                    const pattern = field_info.pattern || '';

                    let inputElement = `<input type="${input_type}" name="${param}" class="px-2 mt-1" placeholder="${map_params[param]}" `;

                    if (input_type === 'number') {
                        // Defina um valor m�nimo e m�ximo para campos num�ricos
                        let minValue = '0';
                        let maxValue = '9'.repeat(parseInt(maxLength, 10));
                        inputElement += `min="${minValue}" max="${maxValue}" `;
                    } else {
                        // Use maxlength para outros tipos
                        inputElement += `maxlength="${maxLength}" `;
                    }

                    if (pattern) {
                        inputElement += `pattern="${pattern}" `;
                    }

                    // Adicionar oninput apenas para tipos 'text' e 'number'
                    if (input_type === 'text' || input_type === 'number') {
                        inputElement += `oninput="if(this.value.length > ${maxLength}) this.value = this.value.slice(0, ${maxLength});" `;
                    }

                    inputElement += '/>';

                    template = template.replace(regex, inputElement);
                }                

                return template;
            }
        }

        return template.replace(/\[(.*?)]|{(.*?)}/gm, '<input name="$1$2" class="px-2 mt-1 bg-white" placeholder="$1$2" maxlength="100"/>');
    }

    /**
     * Recebe o texto de um template e substitui os valores de campo por inputs
     * com os atributos name e placeholder = nome do campo
     * @param template {string}
     * @returns {string}
     */
    createCardParamsInputs(params) {
        let inputs = {}

        Object.keys(params).forEach((param) => {
            const maxLength = 100
            const paramType = 'text'
            let inputElement = `<input style="border: 1px solid #dfdfdf;width: 120px;calc(1.5em + 0.5rem + 2px);" type="${paramType}" name="${param}" class="px-2 mt-1" placeholder="${param}" `;


            if (paramType === 'number') {
                let minValue = '0';
                let maxValue = '9'.repeat(parseInt(maxLength, 10));
                inputElement += `min="${minValue}" max="${maxValue}" `;
            } else {
                inputElement += `maxlength="${maxLength}" `;
            }

            if (paramType === 'text' || paramType === 'number') {
                inputElement += `oninput="if(this.value.length > ${maxLength}) this.value = this.value.slice(0, ${maxLength});" `;
            }

            inputElement += '/>';

            inputs[param] = inputElement
        });

        return inputs;
    }

    renderSuggestionsHtml(cardSuggestions){
        let suggestionsHtml = ""
        for (let index = 0; index < cardSuggestions.length; index++) {
            if (cardSuggestions[index].type === "text"){
                suggestionsHtml += `
                <div class="tooltipCard">
                    ${cardSuggestions[index].label}
                </div>
                `
            }else if (cardSuggestions[index].type === "url"){
                suggestionsHtml += `
                    <div class="cardSuggestion">
                        <img style="height:14px;width: auto;padding-right:3px" src="/Views/EnvioRapido/icons/suggestionUrl.svg" />
                        ${cardSuggestions[index].label}
                    </div>
                `
            }else if (cardSuggestions[index].type === "dial_phone"){
                suggestionsHtml += `
                <div class="cardSuggestion">
                    <img style="height:14px;width: auto;padding-right:3px" src="/Views/EnvioRapido/icons/suggestionPhone.svg" />
                    ${cardSuggestions[index].label}
                </div>
            `
            }else if (cardSuggestions[index].type === "show_location"){
                suggestionsHtml += `
                <div class="cardSuggestion">
                    <img style="height:14px;width: auto;padding-right:3px" src="/Views/EnvioRapido/icons/suggestionShowLocation.svg" />
                    ${cardSuggestions[index].label}
                </div>
            `
            }else if (cardSuggestions[index].type === "request_location"){
                suggestionsHtml += `
                <div class="cardSuggestion">
                    <img style="height:14px;width: auto;padding-right:3px" src="/Views/EnvioRapido/icons/suggestionRequestLocation.svg" />
                    ${cardSuggestions[index].label}
                </div>
            ` 
            }

            if (index < cardSuggestions.length){
                suggestionsHtml += `<hr class="divider">`
            }
        }

        return suggestionsHtml
    }

    replaceCardParamsInputs(template, inputs){
        const regex = /\{\s*([a-zA-Z_0-9]\w*)\s*\}/g;

        let match;
        let paramsFound = [];
        while ((match = regex.exec(template)) !== null) {
            paramsFound.push(`${match[1]}`);
        }

        if (!paramsFound) return template;

        for (let index = 0;index < paramsFound.length;index += 1){
            template = template.replace(`{${paramsFound[index]}}`, inputs[paramsFound[index]]);
        }

        return template;
    }

    camposTemplateCardComoInput(template, params_names) {
        if (params_names) {
            let match;
            let params = [];

            const regex = /\{\s*([a-zA-Z_]\w*)\s*\}/g;

            while ((match = regex.exec(template)) !== null) {
                params.push(`${match[1]}`);
            }

            if (params.length > 0) {
                for (let param in params) {

                    const field_info = this.fields.texto;
                    const input_type = field_info.type || 'text';
                    const maxLength = 25;
                    const pattern = field_info.pattern || '';

                    let inputElement = `<input type="${input_type}" id="Card${params[param]}"  name="${params[param]}" class="px-2 mt-1" style="border: 1px solid #dfdfdf;width: 120px;calc(1.5em + 0.5rem + 2px);" placeholder="${params[param]}" `;

                    if (input_type === 'number') {
                        // Defina um valor m�nimo e m�ximo para campos num�ricos
                        let minValue = '0';
                        let maxValue = '9'.repeat(parseInt(maxLength, 10));
                        inputElement += `min="${minValue}" max="${maxValue}" `;
                    } else {
                        // Use maxlength para outros tipos
                        inputElement += `maxlength="${maxLength}" `;
                    }

                    if (pattern) {
                        inputElement += `pattern="${pattern}" `;
                    }

                    // Adicionar oninput apenas para tipos 'text' e 'number'
                    if (input_type === 'text' || input_type === 'number') {
                        inputElement += `oninput="if(this.value.length > ${maxLength}) this.value = this.value.slice(0, ${maxLength});" `;
                    }

                    inputElement += '/>';

                    template = template.replace(`{${params[param]}}`, inputElement);
                }                

                return template;
            }
        }

        return template.replace(/\[(.*?)]|{(.*?)}/gm, '<input name="$1$2" class="px-2 mt-1 bg-white" placeholder="$1$2" maxlength="100"/>');
    }

    /**
     * Recebe o texto de um template e substitui os valores de campo pelos valores informados
     * com os atributos name e placeholder = nome do campo
     * @param template {string}
     * @param valores {Object} - os indices devem ser os nomes dos campos
     * @param campos_negrito {boolean} - se true retorna um html com os campos dentro de tags <b></b>
     * @returns {string|html}
     */
    preencherCamposTemplate(template, valores, campos_negrito = false){
        const bold = campos_negrito ? (str) => `<b>${str}</b>` : (str) => str;

        Object.keys(valores).forEach((key) => {
            let valor = (valores[key] && valores[key].toString().trim());

            if (/\d{4}-\d{2}-\d{2}/.test(valor)) {
                valor = this.converteDataFormato(valor); // Fun��o para converter de yyyy-mm-dd para dd/mm/yyyy
            }

            valor = bold(valor);

            const keyRegex = new RegExp(`\\[(${key})]|\\{(${key})}`, "gm");
            template = template.replace(keyRegex, valor);
        });

        if (campos_negrito) {
            const templateFormatRegex = new RegExp('(\\[\\w+])|(\\{\\w+})','gm');
            template = template.replace(templateFormatRegex,'<b>$1$2</b>');
        }

        return template;
    }

    /**
     * Verifica se o template informado possui parametros
     * @param template {string}
     * @returns {boolean}
     */
    hasParams(template){
        return template.split(/\[.*?]|\{.*?}/gm).length > 1
    }

    /**
     * Retorna o nome do tipo do template
     * @param tipo_template {string} - Nome do template como no banco TP_TEMPLATE_TAES
     */
    getTipoTemplateTraducao(tipo_template){
        return tipo_template_traducao[tipo_template.toUpperCase()];
    }


    /**
     * Converte o formato da data de ISO (YYYY-MM-DD) para um formato personalizado (DD/MM/YYYY)
     * @param {string} dataISO - A data no formato ISO
     * @return {string} - A data no formato personalizado (DD/MM/YYYY)
     */
    converteDataFormato(dataISO) {
        if (!dataISO) return '';
        let [ano, mes, dia] = dataISO.split('-');
        return `${dia}/${mes}/${ano}`;
    }

    /**
     * Retorna o objeto HTML de um card do carrossel
     * 
     * @param {ImagensCarrossel} image Array de imagens do carrossel
     * @param {numeric} number_card N?mero do card na ordem carrosel
     * @param {boolean} limitar_caracteres Indica se a descri��o e o t�tulo devem ter seus caracteres limitados
     * @param {boolean} modal_configuracao Indica se o card � para o modal de configura��o do template, caso seja, a classe col-md-8 n�o deve ser adicionada
     * 
     * @returns {object}
     */
    montarCardCarrossel(image, number_card, limitar_caracteres = false, modal_configuracao = false) {
        if (limitar_caracteres) {
            image.descricao = image.descricao.length >  150 ? image.descricao.slice(0, 150) + '...': image.descricao;
            image.titulo    = image.titulo.length > 25 ? image.titulo.slice(0, 25) + '...': image.titulo;
        }
        let nome_variavel_imagem = (image.link.match(/name=['"](.*?)['"]/) || [])[1];
        
        let card = $('<div />').addClass('carousel-content mx-2 my-3 '+(number_card >0 ? 'card-parent':'')).append(
            $('<div />').addClass('row mx-0 justify-content-center card-child').append(
                $('<div />').addClass('col-12 border border-light-grey rounded pb-3 bg-white ' + (modal_configuracao ? '' : 'col-md-8')).append(
                    $('<div />').addClass('row border-bottom border-light-grey bg-extradark-grey').append(
                       number_card > 0 ? ($('<div />').addClass('bg-white rounded ml-2 mt-2 px-3 py-2').prop('style', 'position: absolute;z-index: 2050;').html(number_card)) : ''
                    ).append(
                        $('<div />').addClass('image-container-carrossel col-12 d-flex align-items-center p-0').append(
                            $('<div />').addClass('image-carousel ' + (this.isUrl(image.link) ? '' : 'd-none')).append(
                                $('<img />').addClass('p-0').attr('data-name', nome_variavel_imagem ).prop('src', modal_configuracao && (image.link.indexOf('<select') > -1 || image.link.indexOf('<input') > -1) ? '': image.link)
                            )
                        ).append(
                            $('<div />').addClass('icon-image col-12 justify-content-center ' + (this.isUrl(image.link) ? 'd-none' : 'd-flex')).append(
                                this.isUrl(image.link) ? '' : $('<i />').addClass('font-7rem far fa-image')
                            )
                        )
                    )
                ).append(
                    this.isUrl(image.link) ? '' :
                    ($('<div />').addClass('row border-bottom border-light-grey pt-2 pb-2 bg-extradark-grey align-items-center').append(
                        $('<div />').addClass('col-1').append(
                            $('<i />').addClass('fas fa-image')
                        )
                    ).append(
                        $('<div />').addClass('col-10').append(
                            image.link
                        )
                    ))
                ).append(
                    $('<div />').addClass('row border-bottom border-light-grey mb-3 pt-2 pb-2 bg-extradark-grey align-items-center').append(
                        $('<div />').addClass('col-1').append(
                            $('<i />').addClass('fas fa-expand')
                        )
                    ).append(
                        $('<div />').addClass('col-10').append(
                            image.tamanho
                        )
                    )
                ).append(
                    $('<div />').addClass('row').append(
                        $('<div />').addClass('col-12 text-left').append(
                            $('<p />').addClass('h6').append(image.titulo)
                        )
                    )
                ).append(
                    $('<div />').addClass('row').append(
                        $('<div />').addClass('col-12 overflow-wrap text-left').append(
                            $('<span />').append(image.descricao)
                        )
                    )
                ).append(this.montarSuggestions(image.suggestions))
            )
        );

        return card;
    }


    montarCampoTexto(mensagem){
        let section_message = $('<div />').addClass('row justify-content-center').append(

            $('<div/>').addClass('col-12 div-balloon balloon-left border').append(
                '<span>'+mensagem.text+'</span>'
            )
        ).append(
            $('<div/>').addClass('col-12').append(
                (mensagem.suggestions ? this.montarSuggestionText(mensagem.suggestions) : '')
            )
        );
        return section_message;
    }

    /**
     * Monta as suggestions do texto caso haja adicionado o �cone de acordo com o tipo
     *
     * @param {array} suggestions Array de suggestions
     * @returns {TemplateService.montarSuggestions.section_suggestions}
     */
    montarSuggestionText(suggestions = []){
        let section_suggestions = $('<div />').addClass('row justify-content-left');

        let current = this;
        $.each(suggestions, function(index, suggestion) {
            let fields = $('<div />').addClass('border rounded bg-dark-primary mt-2 text-white').append(
                $('<div />').addClass('row mx-0 p-2').append(
                    $('<div />').addClass('col p-0').append(
                        $('<i />').addClass('fas ' + type_icons[suggestion.type])
                    )
                ).append(
                    $('<div />').addClass('ml-3 section-fields').css('max-width', 'fit-content').append(
                        current.montarFieldsSuggestions('Etiqueta de ' + translate_types[suggestion.type], suggestion.label)
                    )
                )
            );

            if (typeof suggestion.value !== 'undefined') {
                if (suggestion.type == 'show_location') {
                    fields.find('.section-fields').append(
                        current.montarFieldsSuggestions('Latitude', suggestion.value.latitude)
                    ).append(
                        current.montarFieldsSuggestions('Longitude', suggestion.value.longitude)
                    );
                } else {
                    fields.find('.section-fields').append(current.montarFieldsSuggestions(type_labels[suggestion.type], suggestion.value));
                }
            }

            section_suggestions.append(fields);
        });

        return section_suggestions;
    }


    /**
     * Monta as suggestions do card caso haja adicionado o �cone de acordo com o tipo
     * 
     * @param {array} suggestions Array de suggestions
     * @returns {TemplateService.montarSuggestions.section_suggestions}
     */
    montarSuggestions(suggestions = []) {
        let section_suggestions = $('<div />').addClass('row justify-content-center');
        
        let current = this;
        $.each(suggestions, function(index, suggestion) {
            let fields = $('<div />').addClass('col-11 border rounded bg-dark-primary mt-2 text-white').append(
                $('<div />').addClass('row p-2').append(
                    $('<div />').addClass('col-auto p-0').append(
                        $('<i />').addClass('fas ' + type_icons[suggestion.type])
                    )
                ).append(
                    $('<div />').addClass('col section-fields').append(
                        current.montarFieldsSuggestions('Etiqueta de ' + translate_types[suggestion.type], suggestion.label)
                    )
                )
            );
    
            if (typeof suggestion.value !== 'undefined') {
                if (suggestion.type == 'show_location') {
                    fields.find('.section-fields').append(
                        current.montarFieldsSuggestions('Latitude', suggestion.value.latitude)
                    ).append(
                        current.montarFieldsSuggestions('Longitude', suggestion.value.longitude)
                    );
                } else {
                    fields.find('.section-fields').append(current.montarFieldsSuggestions(type_labels[suggestion.type], suggestion.value));
                }
            }
            
            section_suggestions.append(fields);
        });
        
        return section_suggestions;
    }
    
    /**
     * Monta os campos de siggestions com label e value
     * 
     * @param {string} label Label do campo
     * @param {string} input Select HTML ou texto
     * 
     * @returns {jQuery}
     */
    montarFieldsSuggestions(label, input) {
        let isField = input.toString().indexOf("</select>") >= 0;
        return $('<div />').addClass('row mb-2').append(
            $('<div />').addClass('col-12 mb-1').append(
                $('<span />').append(label)
            )
        ).append(
            $('<div />').addClass('col-12').append(
                isField ?
                $('<span />').append(input) :
                $('<div />').addClass('p-2 border rounded').append(
                    $('<span />').append(input)
                )
            )
        )
    }

    /**
     * Retorna o objeto HTML de um card de imagem
     * 
     * @param {string} link URL da imagem a ser carregada
     * @param {boolean} responsividade_select Ativa a responsividade do card para quando houver um select
     * 
     * @returns {object}
     */
    montarCardImagem(link, responsividade_select = false) {
        let nome_variavel_imagem = (link.match(/name=['"](.*?)['"]/) || [])[1];
        return $('<div />').addClass('carousel-content mx-2 my-3 col-12').append(
            $('<div />').addClass('row mx-0 justify-content-center').append(
                $('<div />').addClass('col-12 border border-light-grey rounded bg-white ' + (responsividade_select ? 'col-md-9 col-lg-6' : '')).append(
                    $('<div />').addClass('row border-bottom bg-extradark-grey').append(
                        $('<div />').addClass('image-container-carrossel col-12 d-flex align-items-center p-0').append(
                            $('<div />').addClass('image-carousel ' + (this.isUrl(link) ? '' : 'd-none')).append(
                                $('<img />').attr('data-name', nome_variavel_imagem ).addClass('p-0').prop('src', link)
                            )
                        ).append(
                            $('<div />').addClass('icon-image col-12 justify-content-center ' + (this.isUrl(link) ? 'd-none' : 'd-flex')).append(
                                this.isUrl(link) ? '' : $('<i />').addClass('font-7rem far fa-image')
                            )
                        )
                    )
                ).append(
                    this.isUrl(link) ? '' :
                    ($('<div />').addClass('row border-bottom pt-2 pb-2 bg-extradark-grey align-items-center').append(
                        $('<div />').addClass('col-1').append(
                            $('<i />').addClass('fas fa-image')
                        )
                    ).append(
                        $('<div />').addClass('col-10').append(
                            link
                        )
                    ))
                )
            )
        );
    }

    /**
     * Verifica se uma string � uma URL v�lida
     * @param {string} s Url a ser verificada
     */
    isUrl(s) {
        var regexp = /(https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
        return regexp.test(s);
    }
     /**
     * Busca o template da campanha informada
     * @returns Promise
     */
    getCodigoTemplateCampanhaSms(id_campanha) {
        return $.post(this.base_url + '/getCodigoTemplateCampanhaSms',{id_campanha});
    }
    
    /**
     * Busca o template da campanha informada
     * @returns Promise
     */
    getCodigoTemplateCampanhaApps(id_campanha) {
        return $.post(this.base_url + '/getCodigoTemplateCampanhaApps',{id_campanha});
    }

    /**
     * Busca um codigo de template de acordo com o c?digo informado
     * @returns Promise
     */
    getCodigoTemplate(codigo_template) {
        return $.post(this.base_url + '/getCodigoTemplate',{codigo_template});
    }

    /**
     * Busca um template de acordo com o c�digo informado
     * @returns Promise
     */
    getTemplateById(id_template) {
        return $.get(this.base_url + '/getTemplateById', { id_template });
    }
    
    /**
     * Realiza a limpeza dos caracteres */
     cleanCharacters(text) {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    validateDate(input) {
        const dateRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
        let dateValue = input.value;

        // Converter a data para o formato dd/mm/yyyy se estiver em outro formato
        if (dateValue.includes('-')) {
            const [year, month, day] = dateValue.split('-');
            dateValue = `${day}/${month}/${year}`;
        }

        // Verifica se a data est� no formato correto e � uma data v�lida
        if (!dateRegex.test(dateValue) || !this.isValidDate(dateValue)) {
            alert("Data inv\u00E1lida. Por favor, insira uma data no formato dd/mm/yyyy.");
            input.value = ''; // Limpa o campo
        }
    }

    isValidDate(dateString) {
        const parts = dateString.split("/");
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // O m�s � baseado em zero
        const year = parseInt(parts[2], 10);

        const date = new Date(year, month, day);
        return date && (date.getMonth() === month) && (date.getDate() === day);
    }

    validatePhone(input) {
        let phone = $(input).val().replace(/\D/g, ''); // Remove os n�o d�gitos
        if (!(phone.length === 10 || phone.length === 11)) {
            $(input).val(''); // Limpa o campo
        }
    }

    /**
     * A fun��o `checkBudget` verifica a exist�ncia de valores nos campos do telefone e do ID Empresa
     * Caso esses valores estejam definidos, a fun��o faz uma requisi��o HTTP POST ass�ncrona para o arquivo `get-orcamento.php` no servidor com os valores dos campos do telefone e do ID Empresa
     * A resposta do servidor � ent�o interpretada como JSON. Se a resposta contiver um caractere 'X' ou 'B' na primeira posi��o, a fun��o resolve a promessa com `isValid` definido como `false`
     * Se a resposta contiver um segundo elemento (info[1]), a fun��o resolve a promessa com `info` definido com os dados da resposta
     * Em caso de falha na solicita��o HTTP POST ou se a resposta n�o puder ser interpretada como JSON, a promessa � rejeitada com o erro capturado
     *
     * @param {string} phones Representa os n�meros de telefone a serem verificados
     * @param {number} idemp Representa o identificador da empresa a ser verificado
     * @returns {Promise<Object>} Retorna uma promessa que resolve um objeto que cont�m o estado de validade (campo `isValid`) e a informa��o da resposta (campo `info`)
     */
    checkBudget(phones, idemp) {
        return new Promise((resolve, reject) => {
            if (phones && idemp) {
                $.ajax({
                    type: "POST",
                    url: "get-orcamento.php",
                    data: {
                        fones: phones,
                        idemp: idemp
                    },
                    async: true
                }).done(function (response) {
                    let info;
                    try {
                        info = JSON.parse(response);
                        let isValid = !(info[0] === "X" || info[0] === "B");
                        resolve({isValid: isValid, info: info});
                    } catch (e) {
                        console.error("Malformed JSON response");
                        reject(e);
                    }
                }).fail(function(error) {
                    console.log("AJAX request failed", error);
                    reject(error);
                });
            } else {
                resolve({idValid: false, info: null});
            }
        });
    }

    /**
     * A fun��o extractValidPhoneNumbers extrai todos os n�meros v�lidos de telefone
     * de uma string de entrada. Um n�mero de telefone v�lido tem 10 ou 11 d�gitos.
     *
     * @param {string} textareaValue - A string de entrada, contendo os n�meros de telefone potenciais.
     * @returns {string} Uma string contendo os n�meros de telefone v�lidos, separados por v�rgulas.
     */
    extractValidPhoneNumbers(textareaValue) {
        // Express�o regular para validar n�meros de telefone com 10 ou 11 d�gitos.
        let regex = /\b(\d{2}\s*\d{8}|\d{2}\s*\d{9})\b/g;

        let phones = [];
        let match;
        while ((match = regex.exec(textareaValue)) !== null) {
            // Removendo espa�os no in�cio ou no final do n�mero de telefone
            let phone = match[0].trim();

            // Removendo espa�os no meio do n�mero
            phone = phone.replace(/\s+/g, '');

            phones.push(phone);
        }
        return phones.join(",");
    }
}
