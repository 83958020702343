import LoginPage from "./LoginPage";
import SelectProductPage from "./SelectProductPage";
import ResetPassword from "./ResetPassword";

export default [
    {
        path: "auth",
        name: "auth",
        component: LoginPage
    },
    {
        path: "auth/select-product",
        name: "selectProduct",
        component: SelectProductPage
    },
    {
        path: "auth/reset-password",
        name: "reset-password",
        component: ResetPassword
    },
    {
        path: "select-empresa",
        name: "selectEmpresa"
    }
];