import FormSuggestionTelefone from "../../template-rcs/components/forms/FormSuggestionTelefone";

export default {
    components: {
        FormSuggestionTelefone
    },
    methods: {
        formSubmit(data) {
            alert(`Dados emitidos: \n Etiqueta: ${data.etiqueta} \n Telefone: ${data.telefone}`);
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="12" md="3">
                    <form-suggestion-telefone @formSubmit="formSubmit"></form-suggestion-telefone>
                </v-col>
            </v-row>
        `
    )
};