import PageHeader from "../moleculas/PageHeader";
import ProgressCard from "../moleculas/ProgressCard";
import BaseButton from "../atomos/BaseButton";
import StatusCampaign from "../moleculas/StatusCampaign";
import CanalIconDescription from "../moleculas/CanalIconDescription";
import PreviewTemplate from "./PreviewTemplate";
import DialogNotifyReportGeneration from "../../views/reports/components/dialogs/DialogNotifyReportGeneration";
import PgTour from "./PgTour";
import BaseDialog from "../moleculas/BaseDialog";
import DialogPreviewTemplate from "./DialogPreviewTemplate";
import BaseNotify from "../atomos/BaseNotify";
import BaseTooltip from "../atomos/BaseTooltip";
import BaseInformation from "../atomos/BaseInformation";
import BaseDataTable from "./BaseDataTable";
import CardContentClass from "../../classes/CardContentClass";
import { DataTableHeaderClass } from "../../classes/DataTableHeaderClass";
import { BaseService } from "../../services/BaseService";
import { CardRcsClass } from "../../classes/CardRcsClass";
import { DraggableItemClass } from "../../classes/DraggableItemClass";
import { BasePillClass } from "../../classes/BasePillClass";
import DialogClickDetails from "../../views/sessoes_rcs/components/DialogClickDetails";
import DialogListSessions from "../../views/sessoes_rcs/components/DialogListSessions";

/**
 * Componente de dialog de detalhes de campanhas
 *
 * @requires {@link PageHeader}
 * @requires {@link ProgressCard}
 * @requires {@link BaseButton}
 * @requires {@link StatusCampaign}
 * @requires {@link CanalIconDescription}
 * @requires {@link PreviewTemplate}
 * @requires {@link DialogNotifyReportGeneration}
 * @requires {@link PgTour}
 * @requires {@link BaseDialog}
 * @requires {@link DialogPreviewTemplate}
 * @requires {@link BaseNotify}
 * @requires {@link BaseTooltip}
 * @requires {@link BaseInformation}
 * @requires {@link BaseDataTable}
 * @requires {@link CardContentClass}
 * @requires {@link DataTableHeaderClass}
 * @requires {@link BaseService}
 * @requires {@link CardRcsClass}
 * @requires {@link DraggableItemClass}
 * @requires {@link BasePillClass}
 * @requires {@link DialogClickDetails}
 * @requires {@link DialogListSessions}
 *
 * @displayName DialogCampaignDetails
 * @category Organismos
 * @component
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        PageHeader,
        ProgressCard,
        BaseButton,
        StatusCampaign,
        CanalIconDescription,
        PreviewTemplate,
        DialogNotifyReportGeneration,
        PgTour,
        BaseDialog,
        DialogPreviewTemplate,
        BaseNotify,
        BaseTooltip,
        BaseInformation,
        BaseDataTable,
        DialogClickDetails,
        DialogListSessions
    },
    filters: {
        formatDate(date) {
            if (date) {
                return window.Vue.htmlEntities(dayjs(date).format("DD/MM/YYYY [&agrave;s] HH:mm"));
            } else {
                return "";
            }
        },
    },
    props: {
        /**
         * Valor de v-model, indica se o componente deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * ID da campanha para buscar as informacoes
         */
        campaignId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show: this.value,
            campaign_details: null,
            show_dialog_preview: false,
            show_report_notify: false,
            report_notify_data: null,
            config_report: {},
            ds_template: null,
            notify_message: "",
            notify_title: "",
            show_notify: false,
            dialog_action_config: {
                show: false,
                title: "",
                description: "",
                link_text: "",
                information: "",
                form_action: "",
                show_lotes: false,
                action: () => {},
                button: {
                    label: "",
                    variant: "",
                    loading: false,
                    icon: ""
                }
            },
            base_service: new BaseService(),
            headers_table: [
                new DataTableHeaderClass({text: "Lotes", value: "lote", sortable: false})
            ],
            selected_lotes: [],
            show_click_details: false,
            show_list_sessions: false,
            cards_loading: false
        };
    },
    computed: {
        pageDescription() {
            if (this.campaign_details) {
                return "Aqui voc&ecirc; tem acesso a mais detalhes sobre a campanha <b>" + this.campaign_details.NM_CAMPANHA_CAES + "</b>";
            } else {
                return null;
            }
        },
        campaignProduct() {
            return this.campaign_details?.product.CD_PRODUTO_PDES;
        },
        showPlayButton() {
            return this.campaign_details?.ST_CAMPANHA_CAES === "P";
        },
        showPauseButton() {
            return ["A", "E"].indexOf(this.campaign_details?.ST_CAMPANHA_CAES) > -1;
        },
        showCancelButton() {
            return (["A", "E", "P"].indexOf(this.campaign_details?.ST_CAMPANHA_CAES) > -1 && ["WHATS", "WTS"].indexOf(this.campaign_details?.product.CD_PRODUTO_PDES) === -1);
        },
        showEscalonarButton() {
            return (["A", "P"].indexOf(this.campaign_details?.ST_CAMPANHA_CAES) > -1 && ["RCS", "WHATS", "WTS"].indexOf(this.campaign_details?.product.CD_PRODUTO_PDES) === -1);
        },
        totalProcessed() {
            if (this.campaign_details) {
                switch (this.campaignProduct) {
                case "RCS":
                    return this.campaign_details.delivered_rcs_count + this.campaign_details.delivered_fallback_count + this.campaign_details.not_delivered_rcs_count + this.campaign_details.not_delivered_fallback_count + this.campaign_details.sent_rcs_count + this.campaign_details.sent_fallback_count + this.campaign_details.waiting_rcs_count + this.campaign_details.canceleds_business_rule_simple_count + this.campaign_details.canceleds_user_rcs_count + this.campaign_details.cenceleds_pre_delivery_simple_count + this.campaign_details.not_sent_rcs_count + this.campaign_details.not_sent_fallback_count;
                case "SMS-C":
                    return this.campaign_details.delivered_concatenated_count + this.campaign_details.not_delivered_concatenated_count + this.campaign_details.sent_concatenated_count + this.campaign_details.waiting_concatenated_count + this.campaign_details.canceleds_business_rule_concatenated_count + this.campaign_details.canceleds_user_concatenated_count + this.campaign_details.cenceleds_pre_delivery_concatenated_count;
                default:
                    return this.campaign_details.delivered_simple_count + this.campaign_details.not_delivered_simple_count + this.campaign_details.sent_simple_count + this.campaign_details.not_sent_simple_count + this.campaign_details.waiting_simple_count + this.campaign_details.canceleds_business_rule_simple_count + this.campaign_details.canceleds_user_simple_count + this.campaign_details.cenceleds_pre_delivery_simple_count;
                }
            } else {
                return 0;
            }
        },
        totalSanitized() {
            if (this.campaign_details) {
                return this.campaignProduct === "SMS-C"
                    ? this.campaign_details.invalids_concatenated_count + this.campaign_details.blacklist_concatenated_count + this.campaign_details.repeated_concatenated_count
                    : this.campaign_details.invalids_simple_count + this.campaign_details.blacklist_simple_count + this.campaign_details.repeated_simple_count;
            } else {
                return 0;
            }
        },
        totalSent() {
            if (this.campaign_details) {
                switch (this.campaignProduct) {
                case "RCS":
                    return this.campaign_details.delivered_rcs_count + this.campaign_details.not_delivered_rcs_count + this.campaign_details.sent_rcs_count;
                case "SMS-C":
                    return this.campaign_details.delivered_concatenated_count + this.campaign_details.not_delivered_concatenated_count + this.campaign_details.sent_concatenated_count;
                default:
                    return this.campaign_details.delivered_simple_count + this.campaign_details.not_delivered_simple_count + this.campaign_details.sent_simple_count;
                }
            } else {
                return 0;
            }
        },
        totalSentFallback() {
            if (this.campaign_details && this.campaign_details.delivered_fallback_count !== undefined) {
                return this.campaign_details.delivered_fallback_count + this.campaign_details.not_delivered_fallback_count + this.campaign_details.sent_fallback_count;
            } else {
                return 0;
            }
        },
        totalNotSent() {
            if (this.campaign_details) {
                switch (this.campaignProduct) {
                case "RCS":
                    return this.campaign_details.not_sent_rcs_count + this.campaign_details.not_sent_fallback_count;
                case "SMS-C":
                    return this.campaign_details.not_sent_concatenated_count;
                default:
                    return this.campaign_details.not_sent_simple_count;
                }
            } else {
                return 0;
            }
        },
        totalResponses() {
            if (this.campaign_details) {
                if (this.campaignProduct === "RCS") {
                    return this.campaign_details.responses_rcs_count;
                } else {
                    return this.campaign_details.responses_sms_count;
                }
            } else {
                return 0;
            }
        },
        importedData() {
            let data = [];

            if (this.campaign_details) {
                data = [
                    new CardContentClass({
                        name: "Higienizados",
                        divisor: this.campaign_details.QT_TOTAL_SMS_CAES,
                        dividendo: this.totalSanitized,
                        tooltip: "Na higieniza&ccedil;&atilde;o, s&atilde;o removidos os registros inv&aacute;lidos, repetidos e bloqueados pela blacklist.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Processados",
                        divisor: this.campaign_details.QT_TOTAL_SMS_CAES,
                        dividendo: this.totalProcessed,
                        tooltip: "Apenas os registros v&aacute;lidos e permitidos s&atilde;o processados para envio.",
                        disable_tooltip: false
                    })
                ];
            }

            return {
                title: "Importados",
                total: this.campaign_details?.QT_TOTAL_SMS_CAES,
                tooltip_text: "Total de registros nos arquivos importados para cria&ccedil;&atilde;o da campanha.",
                disable_tooltip: false,
                data
            };
        },
        sanitizedData() {
            let data = [];

            if (this.campaign_details) {
                data = [
                    new CardContentClass({
                        name: "Inv&aacute;lidos",
                        divisor: this.totalSanitized,
                        dividendo: this.campaignProduct === "SMS-C" ? this.campaign_details.invalids_concatenated_count : this.campaign_details.invalids_simple_count,
                        tooltip: "Registros que foram descartados por n&atilde;o estarem de acordo com os requisitos de um n&uacute;mero de telefone v&aacute;lido.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Blacklist",
                        divisor: this.totalSanitized,
                        dividendo: this.campaignProduct === "SMS-C" ? this.campaign_details.blacklist_concatenated_count : this.campaign_details.blacklist_simple_count,
                        tooltip: "Registros bloqueados pela blacklist.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Repetidos",
                        divisor: this.totalSanitized,
                        dividendo: this.campaignProduct === "SMS-C" ? this.campaign_details.repeated_concatenated_count : this.campaign_details.repeated_simple_count,
                        tooltip: "N&uacute;meros de telefone que est&atilde;o repetidos no arquivo que foi processado.",
                        disable_tooltip: false
                    })
                ];
            }

            return {
                title: "Higienizados",
                total: this.totalSanitized,
                tooltip_text: "Total de registros que foram higienizados (descartados) para a campanha.",
                disable_tooltip: false,
                data
            };
        },
        processedData() {
            let data = [];

            if (this.campaign_details) {
                let canceleds = 0;

                switch (this.campaignProduct) {
                case "RCS":
                    canceleds = this.campaign_details.canceleds_business_rule_simple_count + this.campaign_details.canceleds_user_rcs_count + this.campaign_details.cenceleds_pre_delivery_simple_count;
                    break;
                case "SMS-C":
                    canceleds = this.campaign_details.canceleds_business_rule_concatenated_count + this.campaign_details.canceleds_user_concatenated_count + this.campaign_details.cenceleds_pre_delivery_concatenated_count;
                    break;
                default:
                    canceleds = this.campaign_details.canceleds_business_rule_simple_count + this.campaign_details.canceleds_user_simple_count + this.campaign_details.cenceleds_pre_delivery_simple_count;
                    break;
                }

                data = [
                    new CardContentClass({
                        name: "Enviados",
                        divisor: this.totalProcessed,
                        dividendo: this.totalSent + this.totalSentFallback,
                        tooltip: "Voc&ecirc; tem acesso ao andamento dos envios em tempo real.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "N&atilde;o enviados",
                        divisor: this.totalProcessed,
                        dividendo: this.totalNotSent,
                        tooltip: "Voc&ecirc; tem acesso ao andamento dos envios em tempo real.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Aguardando envio",
                        divisor: this.totalProcessed,
                        dividendo: this.campaignProduct === "SMS-C" ? this.campaign_details.waiting_concatenated_count : (this.campaignProduct === "RCS" ? this.campaign_details.waiting_rcs_count : this.campaign_details.waiting_simple_count),
                        tooltip: "Os registros ir&atilde;o aguardar o envio at&eacute; a data e hora agendada, ou de acordo com regras de hor&aacute;rios que podem ser cadastradas por cliente (carteira).",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Cancelados",
                        divisor: this.totalProcessed,
                        dividendo: canceleds,
                        tooltip: "Alguns registros podem ser cancelados por regras de neg&oacute;cio ou manualmente.",
                        disable_tooltip: false
                    })
                ];
            }

            return {
                title: "Processados",
                total: this.totalProcessed,
                tooltip_text: "Total de registros que foram processados para envio",
                disable_tooltip: false,
                data
            };
        },
        sentData() {
            let data = [];

            if (this.campaign_details) {
                data = [
                    new CardContentClass({
                        name: "Entregues",
                        divisor: this.totalSent + this.totalSentFallback,
                        dividendo: this.campaignProduct === "SMS-C"
                            ? this.campaign_details.delivered_concatenated_count
                            : (this.campaignProduct === "RCS"
                                ? this.campaign_details.delivered_rcs_count
                                : this.campaign_details.delivered_simple_count),
                        tooltip: this.campaignProduct === "RCS" ? "Mensagens com entrega confirmada ao destinat&aacute;rio" : "Mensagens com entrega confirmada ao destinat&aacute;rio",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "N&atilde;o entregues",
                        divisor: this.totalSent + this.totalSentFallback,
                        dividendo: this.campaignProduct === "SMS-C"
                            ? this.campaign_details.not_delivered_concatenated_count
                            : (this.campaignProduct === "RCS"
                                ? this.campaign_details.not_delivered_rcs_count + this.totalSentFallback
                                : this.campaign_details.not_delivered_simple_count),
                        tooltip: "Mensagens que n&atilde;o foram entregues ao destinat&aacute;rio. Isso pode acontecer se o aparelho estiver desligado, fora da &aacute;rea de servi&ccedil;o ou se o n&uacute;mero estiver inativo.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Aguardando retorno",
                        divisor: this.totalSent + this.totalSentFallback,
                        dividendo: this.campaignProduct === "SMS-C" ? this.campaign_details.sent_concatenated_count : (this.campaignProduct === "RCS" ? this.campaign_details.sent_rcs_count : this.campaign_details.sent_simple_count),
                        tooltip: "Mensagens enviadas e sem retorno do status de entregue ou n&atilde;o entregue. Essa informa&ccedil;&atilde;o pode levar at&eacute; 24h para retornar e nesse per&iacute;odo o status fica como aguardando retorno.",
                        disable_tooltip: false
                    })
                ];
            }

            return {
                title: "Enviados " + this.campaignProduct,
                total: this.totalSent + this.totalSentFallback,
                tooltip_text: "Total de registros que foram enviados &agrave;s operadoras / fornecedores",
                disable_tooltip: false,
                data
            };
        },
        deliveredRcs() {
            let data = [];

            if (this.campaign_details) {
                data = [
                    new CardContentClass({
                        name: "Visualizados",
                        divisor: this.campaign_details.delivered_rcs_count,
                        dividendo: this.campaign_details.QT_VISUALIZADOS,
                        tooltip: "Quantidade total de visualiza&ccedil;&otilde;es nas mensagens entregues aos destinat&aacute;rios",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Clicados",
                        item_click: "clicks",
                        divisor: this.campaign_details.delivered_rcs_count,
                        dividendo: this.campaign_details.clicados,
                        tooltip: "Quantidade Total de clicados. Veja em quais bot&otilde;es o destinat&aacute;rio clicou na mensagem recebida, acesse para ver mais detalhes",
                        disable_tooltip: false,
                        hide_percent: false
                    }),
                    new CardContentClass({
                        name: "Sess&otilde;es",
                        item_click: "sessions",
                        divisor: this.campaign_details.delivered_rcs_count,
                        dividendo: this.campaign_details.sessoes_rcs,
                        tooltip: "Quantidade total de sess&otilde;es abertas e expiradas, acesse para ver mais detalhes",
                        disable_tooltip: false,
                        hide_percent: false
                    })
                ];
            }

            return {
                title: "Entregues " + this.campaignProduct,
                total: this.campaign_details.delivered_rcs_count,
                tooltip_text: "Total de registros que foram entregues",
                disable_tooltip: false,
                data
            };
        },
        sentDataFallback() {
            let data = [];

            if (this.campaign_details) {
                data = [
                    new CardContentClass({
                        name: "Entregues",
                        divisor: this.totalSentFallback,
                        dividendo: this.campaign_details.delivered_fallback_count,
                        tooltip: "Mensagens com entrega confirmada ao destinat&aacute;rio",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "N&atilde;o entregues",
                        divisor: this.totalSentFallback,
                        dividendo: this.campaign_details.not_delivered_fallback_count,
                        tooltip: "Mensagens que n&atilde;o foram entregues ao destinat&aacute;rio. Isso pode acontecer se o aparelho estiver desligado, fora da &aacute;rea de servi&ccedil;o ou se o n&uacute;mero estiver inativo.",
                        disable_tooltip: false
                    }),
                    new CardContentClass({
                        name: "Aguardando retorno",
                        divisor: this.totalSentFallback,
                        dividendo: this.campaign_details.sent_fallback_count,
                        tooltip: "Mensagens enviadas e sem retorno do status de entregue ou n&atilde;o entregue. Essa informa&ccedil;&atilde;o pode levar at&eacute; 24h para retornar e nesse per&iacute;odo o status fica como aguardando retorno.",
                        disable_tooltip: false
                    })
                ];
            }

            return {
                title: "SMS Alternativo",
                total: this.totalSentFallback,
                tooltip_text: "",
                disable_tooltip: true,
                data
            };
        },
        responsesData() {
            let data = [];

            if (this.campaign_details) {
                data = [
                    new CardContentClass({
                        name: "Retornos sobre entregues",
                        divisor: this.campaignProduct === "SMS-C"
                            ? this.campaign_details.delivered_concatenated_count
                            : (this.campaignProduct === "RCS"
                                ? this.campaign_details.delivered_rcs_count + this.campaign_details.delivered_fallback_count
                                : this.campaign_details.delivered_simple_count),
                        dividendo: this.totalResponses,
                        tooltip: "Este &eacute; o percentual de retornos recebidos sobre as mensagens que foram entregues ao destinat&aacute;rio",
                        disable_tooltip: false
                    })
                ];
            }

            return data;
        },
        template() {
            let template = {
                type: "",
                message: "",
                list_suggestions: [],
                image: "",
                card: new CardRcsClass({}),
                list_card: []
            };

            if (this.campaign_details) {
                let ds_template = "";

                switch (this.campaignProduct) {
                case "RCS":
                    ds_template = this.campaign_details.template_apps_history?.DS_TEMPLATE_TAHES ?? (this.campaign_details.arq_simple?.DS_SMS_ACES ?? "");
                    break;
                case "SMS-C":
                    ds_template = this.campaign_details.latest_mo_concatenated?.DS_MSG_MCES ?? "";
                    break;
                default:
                    ds_template = this.campaign_details.latest_mo_simple?.DS_SMS_MOES ?? "";
                    break;
                }

                try {
                    ds_template = JSON.parse(ds_template);
                } catch (error) {
                    ds_template = { text: ds_template };
                }

                if (ds_template.url !== undefined && ds_template.url !== "") {
                    template.type = "image";
                    template.image = ds_template.url[0] === "{" && ds_template.url[ds_template.url.length - 1] === "}" ? "variable" : ds_template.url;
                } else if (ds_template.text !== undefined && ds_template.text !== "") {
                    template.type = "message";
                    template.message = ds_template.text;
                    template.list_suggestions = [];

                    if (ds_template.suggestions !== undefined && Array.isArray(ds_template.suggestions)) {
                        for (let suggestion of ds_template.suggestions) {
                            let pill = new BasePillClass({ title: suggestion.label });

                            switch (suggestion.type) {
                            case "url":
                                pill.titleIcon = "fa-globe-americas";
                                break;
                            case "dial_phone":
                                pill.titleIcon = "fa-phone-alt";
                                break;
                            case "show_location":
                                pill.titleIcon = "fa-map-marker-alt";
                                break;
                            case "request_location":
                                pill.titleIcon = "fa-crosshairs";
                                break;
                            }

                            template.list_suggestions.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                        }
                    }
                } else if (ds_template.contents !== undefined && !Array.isArray(ds_template.contents)) {
                    template.type = "card";
                    let buttons = [];
                    let responses = [];

                    if (ds_template.contents.suggestions !== undefined && Array.isArray(ds_template.contents.suggestions)) {
                        for (let index in ds_template.contents.suggestions) {
                            let pill = new BasePillClass({ title: ds_template.contents.suggestions[index].label });

                            switch (ds_template.contents.suggestions[index].type) {
                            case "url":
                                pill.titleIcon = "fa-globe-americas";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "dial_phone":
                                pill.titleIcon = "fa-phone-alt";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "show_location":
                                pill.titleIcon = "fa-map-marker-alt";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "request_location":
                                pill.titleIcon = "fa-crosshairs";
                                buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            case "text":
                                responses.push(new DraggableItemClass({ text: null, type: "pill", pill: pill, order: index }));
                                break;
                            }
                        }
                    }

                    template.card = new CardRcsClass({
                        image: ds_template.contents.media !== undefined ? (ds_template.contents.media.file.url[0] === "{" && ds_template.contents.media.file.url[ds_template.contents.media.file.url.length - 1] === "}" ? "variable" : ds_template.contents.media.file.url) : null,
                        title: ds_template.contents.title,
                        description: ds_template.contents.description,
                        suggestionButtons: buttons,
                        suggestionResponses: responses,
                        orientation: ds_template.orientation,
                        alignment: ds_template.alignment,
                        image_height: ds_template.contents.media?.height ?? "TALL"
                    });
                } else if (ds_template.contents !== undefined && Array.isArray(ds_template.contents)) {
                    template.type = "carousel";
                    template.list_card = [];

                    for (let card of ds_template.contents) {
                        let buttons = [];
                        let responses = [];

                        if (card.suggestions !== undefined && Array.isArray(card.suggestions)) {
                            for (let suggestion of card.suggestions) {
                                let pill = new BasePillClass({ title: suggestion.label });

                                switch (suggestion.type) {
                                case "url":
                                    pill.titleIcon = "fa-globe-americas";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "dial_phone":
                                    pill.titleIcon = "fa-phone-alt";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "show_location":
                                    pill.titleIcon = "fa-map-marker-alt";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "request_location":
                                    pill.titleIcon = "fa-crosshairs";
                                    buttons.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                case "text":
                                    responses.push(new DraggableItemClass({ text: null, type: "pill", pill: pill }));
                                    break;
                                }
                            }
                        }

                        template.list_card.push(new CardRcsClass({
                            image: card.media !== undefined ? (card.media.file.url[0] === "{" && card.media.file.url[card.media.file.url.length - 1] === "}" ? "variable" : card.media.file.url) : null,
                            title: card.title,
                            description: card.description,
                            suggestionButtons: buttons,
                            suggestionResponses: responses
                        }));
                    }
                }
            }

            return template;
        },
        fallback() {
            let template = {
                type: "",
                message: "",
                list_suggestions: [],
                image: "",
                card: new CardRcsClass({}),
                list_card: []
            };

            if (this.campaign_details) {
                let ds_template = "";

                try {
                    ds_template = JSON.parse(this.campaign_details.template_apps_history.fallback.DS_TEMPLATE_TAHES);
                } catch (error) {
                    ds_template = { text: this.campaign_details.template_apps_history.fallback.DS_TEMPLATE_TAHES };
                }

                template.type = "message";
                template.message = ds_template.text;
            }

            return template;
        },
        listTour() {
            let list = [];

            if (this.campaign_details) {
                if (this.showPlayButton || this.showPauseButton || this.showCancelButton || this.showEscalonarButton) {
                    list.push({
                        target: "[data-v-step='campaign-details-1']",
                        title: "Bot&otilde;es de a&ccedil;&atilde;o",
                        content: "Estas s&atilde;o as a&ccedil;&otilde;es dispon&iacute;veis para a sua campanha.",
                        date: "2022-11-03 00:00:00"
                    });

                    list.push({
                        target: "[data-v-step='campaign-details-2']",
                        title: "Informa&ccedil;&otilde;es de envio",
                        content: "Acompanhe as informa&ccedil;&otilde;es dispon&iacute;veis da sua campanha em tempo real."
                    });
                } else {
                    list.push({
                        target: "[data-v-step='campaign-details-2']",
                        title: "Informa&ccedil;&otilde;es de envio",
                        content: "Acompanhe as informa&ccedil;&otilde;es dispon&iacute;veis da sua campanha em tempo real.",
                        date: "2022-11-03 00:00:00"
                    });
                }

                list.push(...[
                    {
                        target: "[data-v-step='campaign-details-3']",
                        title: "Mais alguns detalhes",
                        content: "Aqui voc&ecirc; pode ver mais algumas informa&ccedil;&otilde;es sobre a sua campanha.",
                    },
                    {
                        target: "[data-v-step='campaign-details-4']",
                        title: "Divis&atilde;o de lotes e relat&oacute;rios",
                        content: "Voc&ecirc; pode ver os lotes da sua campanha e extrair relat&oacute;rios.",
                    },
                    {
                        target: "[data-v-step='campaign-details-5']",
                        title: "Visualize o template enviado",
                        content: "Voc&ecirc; pode ver o template que foi utilizado no disparo da sua campanha.",
                    }
                ]);
            }

            return list;
        }
    },
    watch: {
        value(new_value) {
            this.show = new_value;
        },
        show(new_show) {
            this.$emit("input", new_show);
        },
        campaignId(new_id) {
            if (new_id) {
                this.campaign_details = null;
                this.getCampaignDetails();
            }
        }
    },
    mounted() {
        this.campaign_details = null;

        if (this.campaignId) {
            this.getCampaignDetails();
        }
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetCampaignDetails"]),
        ...window.Vuex.mapActions("reports", ["ActionDoGenerateReport"]),
        ...window.Vuex.mapActions("dashboard", ["ActionDoPauseCampaigns", "ActionDoRestartCampaigns", "ActionDoCancelCampaigns"]),
        /**
         * Busca os dados de uma campanha
         * @vue
         */
        getCampaignDetails() {
            this.cards_loading = true;
            if (this.campaignId) {
                this.ActionDoGetCampaignDetails(this.campaignId).then(response => {
                    this.campaign_details = response;
                    this.cards_loading = false;
                }).catch(() => {
                    this.campaign_details = null;
                });
            }
        },
        /**
         * Metodo que realiza o fechamento da modal de confoguracao
         * @vue
         */
        closeDialog() {
            this.$emit("close");
            this.show = false;
        },
        /**
         * Redireciona o usuario para a pagina de retornos
         * @vue
         */
        redirectResponses() {
            if (this.campaignProduct === "RCS") {
                $.doGet("RadarApp", {
                    id_campanha: this.campaign_details.ID_CAMPANHA_CAES
                });
            } else {
                $.doPost("list-radar.php", {
                    idcamp: this.campaign_details.ID_CAMPANHA_CAES,
                    sh_dtini: dayjs(this.campaign_details.DT_INICIO_CAES, "YYYY-MM-DD").format("DD/MM/YYYY"),
                    sh_dtfim: dayjs(this.campaign_details.DT_FIM_CAES, "YYYY-MM-DD").format("DD/MM/YYYY")
                });
            }
        },
        /**
         * Monta a formatacao do periodo de envio do lote
         * @param {Object} lote Dados do lote
         * @returns String
         * @vue
         */
        getPeriodLot(lote) {
            let period = "";

            if (dayjs(lote.DT_INICIO_LTES).isBefore(dayjs(this.campaign_details.DT_CAMPANHA_CAES).add(1, "minute")) && dayjs(lote.DT_INICIO_LTES).isAfter(dayjs(this.campaign_details.DT_CAMPANHA_CAES).subtract(1, "minute"))) {
                period = "envio imediato";
            } else if (lote.DT_FIM_LTES === undefined || lote.DT_FIM_LTES === null || lote.DT_FIM_LTES === "" || lote.DT_FIM_LTES === lote.DT_INICIO_LTES) {
                period = dayjs(lote.DT_INICIO_LTES).format("DD/MM [&agrave;s] HH:mm");
            } else {
                period = "entre " + dayjs(lote.DT_INICIO_LTES).format("DD/MM [&agrave;s] HH:mm") + " at&eacute; " + dayjs(lote.DT_FIM_LTES).format("DD/MM [&agrave;s] HH:mm");
            }

            return window.Vue.htmlEntities(period);
        },
        /**
         * Exibe o dialog de preview de template
         * @param {String} content_preview JSON no formato string
         * @vue
         */
        showDialogPreview(content_preview) {
            this.ds_template = content_preview;
            this.show_dialog_preview = true;
        },
        /**
         * Exibe o modal de notificacao de relatorio
         * @param {String} report_type 
         */
        showReportNotify(report_type) {
            this.config_report = {
                report_type: report_type
            };

            this.show_report_notify = true;
        },
        /**
         * Metodo responsavel por montar os dados escolhidos pelo usuario e mandar para a API gerar o relatorio
         * @vue
         */
        generateReport() {
            this.ActionDoGenerateReport({
                type_report: this.report_notify_data.report_type,
                data: {
                    requested_from: "maisconnect",
                    notification_to: this.report_notify_data.notification_to,
                    notification_type: this.report_notify_data.notification_type != "no" ? this.report_notify_data.notification_type : null,
                    data_inicial: this.campaign_details.DT_INICIO_CAES,
                    data_final: this.campaign_details.DT_FIM_CAES,
                    data_solicitacao: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                    clientes: [this.campaign_details.ID_EMPRESA_CAES],
                    campanhas: [this.campaign_details.ID_CAMPANHA_CAES],
                    only_campaign: true,
                    agrupar: "C",
                    mensagens_avulsas: false,
                    new_filter: ["invalidos", "cancelados", "repetidos", "blacklist"]
                }
            });

            this.notify_title = "Seu relat&oacute;rio est&aacute; sendo gerado";

            if (this.report_notify_data.notification_type === "email") {
                this.notify_message = "Estamos gerando seu relat&oacute;rio em segundo plano, em breve te enviaremos um EMAIL de aviso.";
            } else if (this.report_notify_data.notification_type === "sms") {
                this.notify_message = "Estamos gerando seu relat&oacute;rio em segundo plano, em breve te enviaremos um SMS de aviso.";
            } else {
                this.notify_message = "Estamos gerando seu relat&oacute;rio em segundo plano e em breve estar&aacute; dispon&iacute;vel para download, verifique o andamento na p&aacute;gina <a target='_blank' href='/Relatorios'>Meus relat&oacute;rios</a>";
            }
            this.show_notify = true;
        },
        /**
         * Realiza a pausa da campanha
         * @vue
         */
        pause() {
            this.dialog_action_config.title = "Tem certeza que deseja pausar a campanha?";
            this.dialog_action_config.description = "Voc&ecirc; poder&aacute; reiniciar sua campanha a qualquer momento.";
            this.dialog_action_config.information = null;
            this.dialog_action_config.link_text = "Mudei de id&eacute;ia, n&atilde;o quero pausar";
            this.dialog_action_config.show_lotes = false;
            this.dialog_action_config.form_action = "";
            this.dialog_action_config.button = {
                primary: false,
                label: "Sim, quero pausar minha campanha",
                color: "gray-700",
                loading: false,
                icon: "$pauseIcon",
                disabled: false
            };

            this.dialog_action_config.action = () => {
                this.dialog_action_config.button.loading = true;
                this.ActionDoPauseCampaigns({ campaign_id: [this.campaign_details.ID_CAMPANHA_CAES] }).then(() => {
                    this.dialog_action_config.button.loading = false;
                    this.dialog_action_config.show = false;

                    this.notify_title = "Sua campanha foi pausada";
                    this.notify_message = "Voc&ecirc; poder&aacute; retomar sua campanha a qualquer momento clicando no bot&atilde;o reiniciar";
                    this.show_notify = true;

                    this.campaign_details = null;
                    this.getCampaignDetails();
                }).catch(error => {
                    console.log(error);
                    this.dialog_action_config.button.loading = false;
                    this.dialog_action_config.show = false;
                });
            };

            this.dialog_action_config.show = true;
        },
        /**
         * Realiza o reinicio da campanha
         * @vue
         */
        restart() {
            this.dialog_action_config.title = "Deseja reiniciar sua campanha?";
            this.dialog_action_config.description = "Voc&ecirc; pode pausar sua campanha novamente se necess&aacute;rio.";
            this.dialog_action_config.information = null;
            this.dialog_action_config.link_text = "Mudei de id&eacute;ia, n&atilde;o quero reiniciar";
            this.dialog_action_config.show_lotes = false;
            this.dialog_action_config.form_action = "";
            this.dialog_action_config.button = {
                primary: false,
                label: "Sim, quero reiniciar",
                color: "success-500",
                loading: false,
                icon: "$playIcon",
                disabled: false
            };

            this.dialog_action_config.action = () => {
                this.dialog_action_config.button.loading = true;
                this.ActionDoRestartCampaigns({ campaign_id: [this.campaign_details.ID_CAMPANHA_CAES] }).then(() => {
                    this.dialog_action_config.button.loading = false;
                    this.dialog_action_config.show = false;

                    this.notify_title = "Sua campanha foi reiniciada";
                    this.notify_message = "Voc&ecirc; poder&aacute; pausar sua campanha a qualquer momento";
                    this.show_notify = true;

                    this.campaign_details = null;
                    this.getCampaignDetails();
                }).catch(error => {
                    console.log(error);
                    this.dialog_action_config.button.loading = false;
                    this.dialog_action_config.show = false;
                });
            };

            this.dialog_action_config.show = true;
        },
        /**
         * Realiza o cancelamento da campanha
         * @vue
         */
        cancel() {
            this.dialog_action_config.title = "Tem certeza que deseja cancelar a campanha?";
            this.dialog_action_config.description = "Escolha os lotes que deseja cancelar";
            this.dialog_action_config.information = "";
            this.dialog_action_config.link_text = "Mudei de id&eacute;ia, n&atilde;o quero cancelar";
            this.dialog_action_config.form_action = "";
            this.dialog_action_config.show_lotes = true;
            this.dialog_action_config.form_action = "";
            this.dialog_action_config.button = {
                primary: false,
                label: "Sim, quero cancelar minha campanha",
                loading: false,
                icon: "$cancelOutlineIcon",
                color: "error-500",
            };

            if (this.campaign_details.lotes.length === 1) {
                this.selected_lotes = this.campaign_details.lotes;
            }

            this.dialog_action_config.action = () => {
                this.dialog_action_config.button.loading = true;
                let lotes_ids = [];

                for (let lote of this.selected_lotes) {
                    lotes_ids.push(lote.ID_LOTE_LTES);
                }

                this.ActionDoCancelCampaigns({ campaign_id: [this.campaign_details.ID_CAMPANHA_CAES], lotes: lotes_ids }).then(() => {
                    this.dialog_action_config.button.loading = false;
                    this.dialog_action_config.show = false;
                    this.selected_lotes = [];

                    this.notify_title = "Sua campanha foi cancelada";
                    this.notify_message = "Campanha cancelada com sucesso";
                    this.show_notify = true;

                    this.campaign_details = null;
                    this.getCampaignDetails();
                }).catch(error => {
                    console.log(error);
                    this.dialog_action_config.button.loading = false;
                    this.dialog_action_config.show = false;
                });
            };

            this.dialog_action_config.show = true;
        }, //ActionDoGetLotesCancel,
        /**
         * Realiza o escalonamento da campanha
         * @vue
         */
        escalonar() {
            this.dialog_action_config.title = "Escalonar";
            this.dialog_action_config.description = `Tem certeza que deseja escalonar a campanha <b>${this.campaign_details.NM_CAMPANHA_CAES}</b>?`;
            this.dialog_action_config.information = "Ao clicar em continuar sua campanha ser&aacute; <b>pausada</b> e voc&ecirc; ser&aacute; redirecionado &agrave; tela de escalonar";
            this.dialog_action_config.link_text = "Mudei de id&eacute;ia, n&atilde;o quero escalonar";
            this.dialog_action_config.show_lotes = false;
            this.dialog_action_config.form_action = this.base_service.baseDomainPhp() + "/upCampEscalonar.php";
            this.dialog_action_config.button = {
                primary: true,
                label: "Continuar",
                loading: false,
                icon: "mdi-arrow-right",
                disabled: false
            };

            this.dialog_action_config.action = () => {
                this.dialog_action_config.button.loading = true;
                this.ActionDoPauseCampaigns({ campaign_id: [this.campaign_details.ID_CAMPANHA_CAES] }).then(() => {
                    this.$refs.form.$el.submit();
                }).catch(error => {
                    console.log(error);
                    this.dialog_action_config.button.loading = false;
                    this.dialog_action_config.show = false;
                });
            };

            this.dialog_action_config.show = true;
        },
        cardClicked(type) {
            if (type === "clicks") {
                this.show_click_details = true;
            } else if (type === "sessions") {
                this.show_list_sessions = true;
            }
        }
    },
    template: (
        /*html*/
        `
            <v-dialog
                v-model="show"
                fullscreen
                scrollable
                persistent
            >
                <v-card elevation="0" tabindex="0" class="cursor-default" :ripple="false">
                    <v-card-title>
                        <v-toolbar elevation="2" height="auto">
                            <v-container class="align-start">
                                <page-header
                                    title="Detalhes da campanha"
                                    :description="pageDescription"
                                    tutorial
                                    close-button
                                    @tutorial="$refs.tour.start()"
                                    @closeClick="closeDialog"
                                >
                                    <template>
                                        <v-row justify="start" justify-md="end" class="mt-4 mt-md-0 gap-4" no-gutters v-bind:data-v-step="'campaign-details-1'">
                                            <v-col v-if="showPauseButton" cols="auto">
                                                <base-button
                                                    icon="$pauseIcon"
                                                    label="Pausar"
                                                    color="gray-700"
                                                    tooltip-text="Para pausar sua campanha, o status deve ser <b>a enviar</b> ou <b>enviando</b>."
                                                    @click="pause"
                                                ></base-button>
                                            </v-col>
                                            <v-col v-if="showPlayButton" cols="auto">
                                                <base-button
                                                    icon="$playIcon"
                                                    label="Reiniciar"
                                                    color="success-500"
                                                    tooltip-text="Para reiniciar, sua campanha deve estar <b>pausada</b>."
                                                    @click="restart"
                                                ></base-button>
                                            </v-col>
                                            <v-col v-if="showCancelButton" cols="auto">
                                                <base-button
                                                    icon="$cancelOutlineIcon"
                                                    label="Cancelar"
                                                    color="error-500"
                                                    tooltip-text="Para cancelar sua campanha, o status deve ser <b>a enviar</b>, <b>enviando</b> ou <b>pausado</b>."
                                                    @click="cancel"
                                                ></base-button>
                                            </v-col>
                                            <v-col v-if="showEscalonarButton" cols="auto">
                                                <base-button
                                                    icon="$escalonarIcon"
                                                    label="Escalonar"
                                                    primary
                                                    tooltip-text="Para escalonar sua campanha, o status deve ser <b>a enviar</b> ou <b>pausado</b>."
                                                    @click="escalonar"
                                                ></base-button>
                                            </v-col>
                                        </v-row>
                                    </template>
                                </page-header>
                            </v-container>
                        </v-toolbar>
                    </v-card-title>
                    <v-card-text class="bg-gray-30">
                        <v-container class="py-8">
                            <v-row no-gutters class="gap-4" v-bind:data-v-step="'campaign-details-2'">
                                <v-col cols="12":sm="campaignProduct === 'SMS' ? 6 : 12" :md="campaignProduct === 'SMS' ? 6 : 4" class="d-flex">
                                    <progress-card
                                        icon="$importadosCardsIcon"
                                        :title="importedData.title"
                                        background-color="gray-800"
                                        :tooltip-text="importedData.tooltip_text"
                                        :disabled-tooltip="importedData.disable_tooltip"
                                        :loading="cards_loading"
                                        :total="importedData.total"
                                        :list-content="importedData.data"
                                    />
                                </v-col>
                                <v-col v-if="campaignProduct === 'RCS'" cols="12" md="4" class="d-flex">
                                    <progress-card
                                        icon="$higienizadosCardsIcon"
                                        :title="sanitizedData.title"
                                        background-color="gray-800"
                                        :tooltip-text="sanitizedData.tooltip_text"
                                        :disabled-tooltip="sanitizedData.disable_tooltip"
                                        :loading="cards_loading"
                                        :total="sanitizedData.total"
                                        :list-content="sanitizedData.data"
                                    />
                                </v-col>
                                <v-col cols="12" :md="campaignProduct === 'SMS' ? 6 : 4" class="d-flex">
                                    <progress-card
                                        icon="$processadosCardsIcon"
                                        :title="processedData.title"
                                        background-color="gray-800"
                                        :tooltip-text="processedData.tooltip_text"
                                        :disabled-tooltip="processedData.disabled_tooltip"
                                        :loading="cards_loading"
                                        :total="processedData.total"
                                        :list-content="processedData.data"
                                    />
                                </v-col>
                                <v-col cols="12" :md="campaignProduct === 'RCS' ? 4 : 8" :sm="campaignProduct === 'RCS' ? 12 : 8" class="d-flex">
                                    <progress-card
                                        icon="$enviadosCardsIcon"
                                        :title="sentData.title"
                                        background-color="primary-blue-500"
                                        :tooltip-text="sentData.tooltip_text"
                                        :disabled-tooltip="sentData.disabled_tooltip"
                                        :loading="cards_loading"
                                        :total="sentData.total"
                                        :list-content="sentData.data"
                                    />
                                </v-col>
                                <v-col v-if="campaignProduct === 'RCS'" cols="12" md="4" class="d-flex">
                                    <progress-card
                                        icon="$enviadosCardsIcon"
                                        :title="deliveredRcs.title"
                                        background-color="primary-blue-500"
                                        :tooltip-text="deliveredRcs.tooltip_text"
                                        :disabled-tooltip="deliveredRcs.disabled_tooltip"
                                        :loading="cards_loading"
                                        :total="deliveredRcs.total"
                                        :list-content="deliveredRcs.data"
                                        @click="cardClicked"
                                    />
                                </v-col>
                                <v-col v-if="campaignProduct === 'RCS'" cols="12" md="4" class="d-flex">
                                    <progress-card
                                        icon="$smsAlternativoIcon"
                                        :title="sentDataFallback.title"
                                        background-color="primary-blue-500"
                                        :tooltip-text="sentDataFallback.tooltip_text"
                                        :disabled-tooltip="sentDataFallback.disabled_tooltip"
                                        :loading="cards_loading"
                                        :total="sentDataFallback.total"
                                        :list-content="sentDataFallback.data"
                                    />
                                </v-col>
                                <v-col v-else cols="12" md="4" class="d-flex">
                                    <progress-card
                                        icon="$radarCardsIcon"
                                        :title="campaignProduct === 'RCS' ? 'Sess&otilde;es RCS' : 'Radar'"
                                        background-color="primary-blue-500"
                                        tooltip-text="Total de respostas recebidas sobre as mensagens que foram entregues"
                                        disabled-tooltip="false"
                                        :loading="cards_loading"
                                        :total="totalResponses"
                                        :list-content="responsesData"
                                        :link-text="totalResponses > 0 ? 'Ver retornos' : null"
                                        :link-callback="redirectResponses"
                                        tooltip-link="Clique para visualizar as mensagens"
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4 gap-4">
                                <v-col cols="12" md="7" lg="6" class="d-flex" v-bind:data-v-step="'campaign-details-3'">
                                    <v-card elevation="0" class="border-1 border-gray-300 rounded-1" width="100%">
                                        <v-row no-gutters class="pa-4">
                                            <v-col cols="12">
                                                <span class="subtitle-3 text-gray-900">Sobre</span>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="flex-sm-nowrap pa-6">
                                            <v-col cols="12" sm="">
                                                <v-row no-gutters align="center">
                                                    <v-col cols="4" lg="3">
                                                        <span class="caption text-gray-700">Identificador</span>
                                                    </v-col>
                                                    <v-col>
                                                        <base-tooltip v-if="campaign_details" text="C&oacute;digo de identifica&ccedil;&atilde;o da campanha">
                                                            <span class="body-2 text-gray-900">{{ campaign_details.ID_CAMPANHA_CAES }}</span>
                                                        </base-tooltip>
                                                        <v-skeleton-loader v-else type="text" width="50" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-4" align="center">
                                                    <v-col cols="4" lg="3">
                                                        <span class="caption text-gray-700">Template</span>
                                                    </v-col>
                                                    <v-col>
                                                        <base-tooltip v-if="campaign_details" text="Template utilizado na campanha">
                                                            <span class="body-2 text-gray-900">{{ campaign_details.template_apps_history?.CD_TEMPLATE_TAHES ?? 'Sem template' }}</span>
                                                        </base-tooltip>
                                                        <v-skeleton-loader v-else type="text" width="100" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-4" align="center">
                                                    <v-col cols="4" lg="3">
                                                        <span class="caption text-gray-700">Canal</span>
                                                    </v-col>
                                                    <v-col>
                                                        <canal-icon-description v-if="campaign_details" tooltip-text="Canal utilizado na campanha" :product-name="campaign_details.product.CD_PRODUTO_PDES" />
                                                        <v-skeleton-loader v-else type="avatar" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-4" align="center">
                                                    <v-col cols="4" lg="3">
                                                        <span class="caption text-gray-700">Cliente</span>
                                                    </v-col>
                                                    <v-col>
                                                        <base-tooltip v-if="campaign_details" text="Cliente (carteira)">
                                                            <span class="body-2 text-gray-900">{{ campaign_details.client.NM_EMPRESA_EPES }}</span>
                                                        </base-tooltip>
                                                        <v-skeleton-loader v-else type="text" width="100" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-4" align="center">
                                                    <v-col cols="4" lg="3">
                                                        <span class="caption text-gray-700">Campanha</span>
                                                    </v-col>
                                                    <v-col>
                                                        <base-tooltip v-if="campaign_details" text="Nome da campanha">
                                                            <span class="body-2 text-gray-900">{{ campaign_details.NM_CAMPANHA_CAES }}</span>
                                                        </base-tooltip>
                                                        <v-skeleton-loader v-else type="text" width="100" />
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col cols="auto" class="d-none d-sm-block">
                                                <v-divider vertical class="mx-6 pa-0" />
                                            </v-col>
                                            <v-col cols="12" sm="" class="mt-6 mt-sm-0">
                                                <v-row no-gutters align="center">
                                                    <v-col cols="4" lg="3">
                                                        <span class="caption text-gray-700">Criado em</span>
                                                    </v-col>
                                                    <v-col>
                                                        <base-tooltip v-if="campaign_details" text="Data e hora da cria&ccedil;&atilde;o da campanha">
                                                            <span class="body-2 text-gray-900">{{ campaign_details.DT_CAMPANHA_CAES | formatDate }}</span>
                                                        </base-tooltip>
                                                        <v-skeleton-loader v-else type="text" width="50" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-4" align="center">
                                                    <v-col cols="4" lg="3">
                                                        <span class="caption text-gray-700">Usu&aacute;rio</span>
                                                    </v-col>
                                                    <v-col>
                                                        <base-tooltip v-if="campaign_details" text="Usu&aacute;rio que criou a campanha">
                                                            <span class="body-2 text-gray-900">{{ campaign_details.user?.NM_USUARIO_USES }}</span>
                                                        </base-tooltip>
                                                        <v-skeleton-loader v-else type="text" width="50" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-4" align="center">
                                                    <v-col cols="4" lg="3">
                                                        <span class="caption text-gray-700">In&iacute;cio</span>
                                                    </v-col>
                                                    <v-col>
                                                        <base-tooltip v-if="campaign_details" text="In&iacute;cio do disparo da campanha">
                                                            <span class="body-2 text-gray-900">{{ campaign_details.DT_INICIO_CAES | formatDate }}</span>
                                                        </base-tooltip>
                                                        <v-skeleton-loader v-else type="text" width="50" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-4" align="center">
                                                    <v-col cols="4" lg="3">
                                                        <span class="caption text-gray-700">Fim</span>
                                                    </v-col>
                                                    <v-col>
                                                        <base-tooltip v-if="campaign_details" text="Fim do disparo da campanha">
                                                            <span class="body-2 text-gray-900">{{ campaign_details.DT_FIM_CAES | formatDate }}</span>
                                                        </base-tooltip>
                                                        <v-skeleton-loader v-else type="text" width="50" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="mt-4" align="center">
                                                    <v-col cols="4" lg="3">
                                                        <span class="caption text-gray-700">Status</span>
                                                    </v-col>
                                                    <v-col>
                                                        <status-campaign v-if="campaign_details" :status="campaign_details.ST_CAMPANHA_CAES" :percent-progress="(totalProcessed * 100) / campaign_details.QT_TOTAL_SMS_CAES" />
                                                        <v-skeleton-loader v-else type="chip" width="100" />
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="5" lg="6" v-bind:data-v-step="'campaign-details-4'">
                                    <v-row no-gutters>
                                        <v-col cols="12" sm="6" md="12" class="pr-sm-2 pr-md-0 d-flex">
                                            <v-card elevation="0" class="border-1 border-gray-300 rounded-1" :height="$vuetify.breakpoint.width >= $vuetify.breakpoint.thresholds.sm && $vuetify.breakpoint.width <= $vuetify.breakpoint.thresholds.md ? 'auto' : 140" width="100%">
                                                <v-row no-gutters class="pa-4">
                                                    <v-col cols="12">
                                                        <span v-if="campaign_details" class="subtitle-3 text-gray-900">{{ campaign_details?.lotes.length }} lote{{ campaign_details?.lotes.length > 1 ? 's' : '' }} para envio</span>
                                                        <v-skeleton-loader v-else width="150" type="chip" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters class="pa-6">
                                                    <v-col cols="12">
                                                        <v-card v-if="campaign_details" elevation="0" class="overflow-auto" :max-height="$vuetify.breakpoint.width >= $vuetify.breakpoint.thresholds.sm && $vuetify.breakpoint.width <= $vuetify.breakpoint.thresholds.md ? 81 : 60">
                                                            <v-row no-gutters align="center" v-for="(lote, i) in campaign_details?.lotes" :key="i" :class="i === 0 ? '' : 'mt-2'">
                                                                <v-col cols="12">
                                                                    <span class="caption text-gray-700">Lote {{ i + 1 }}</span>&nbsp
                                                                    <span class="body-2 text-gray-900"><b>{{ lote.QT_SMS_LTES }}</b> registros - {{ getPeriodLot(lote) }}</span>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                        <v-card v-else elevation="0" class="overflow-auto" :max-height="$vuetify.breakpoint.width >= $vuetify.breakpoint.thresholds.sm && $vuetify.breakpoint.width <= $vuetify.breakpoint.thresholds.md ? 81 : 60">
                                                            <v-row no-gutters align="center">
                                                                <v-col cols="12">
                                                                    <v-skeleton-loader width="400" type="text" />
                                                                </v-col>
                                                            </v-row>
                                                            <v-row no-gutters align="center" class="mt-2">
                                                                <v-col cols="12">
                                                                    <v-skeleton-loader width="400" type="text" />
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="12" class="mt-4 mt-sm-0 mt-md-4 pl-sm-2 pl-md-0 d-flex">
                                            <v-card elevation="0" class="border-1 border-gray-300 rounded-1" width="100%">
                                                <v-row no-gutters class="pa-4">
                                                    <v-col cols="12">
                                                        <span class="subtitle-3 text-gray-900">Baixar relat&oacute;rios</span>
                                                    </v-col>
                                                </v-row>
                                                <base-tooltip v-if="campaign_details" text="Seu relat&oacute;rio ser&aacute; gerado em plano de fundo e voc&ecirc; pode optar por ser notificado quando estiver pronto">
                                                    <v-row no-gutters class="pa-6">
                                                        <v-col cols="12"">
                                                            <base-button 
                                                                v-if="campaignProduct === 'RCS'"
                                                                icon="$downloadOutlineIcon"
                                                                label="Anal&iacute;tico"
                                                                secondary
                                                                @click="showReportNotify('analytical')"
                                                            />
                                                            <v-row v-else no-gutters class="flex-nowrap">
                                                                <v-col>
                                                                    <v-row no-gutters class="gap-4">
                                                                        <v-col cols="12">
                                                                            <base-button 
                                                                                icon="$downloadOutlineIcon"
                                                                                label="Anal&iacute;tico"
                                                                                secondary
                                                                                @click="showReportNotify('analytical')"
                                                                            />
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <base-button 
                                                                                icon="$downloadOutlineIcon"
                                                                                label="Sint&eacute;tico"
                                                                                secondary
                                                                                @click="showReportNotify('synthetic')"
                                                                            />
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col cols="auto">
                                                                    <v-divider vertical class="ma-0 pa-0" />
                                                                </v-col>
                                                                <v-col class="pl-4">
                                                                    <v-row no-gutters>
                                                                        <v-col cols="12">
                                                                            <base-button 
                                                                                icon="$downloadOutlineIcon"
                                                                                label="Radar"
                                                                                secondary
                                                                                @click="showReportNotify('radar')"
                                                                            />
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </base-tooltip>
                                                <v-row v-else no-gutters class="pa-6">
                                                    <v-col cols="12"">
                                                        <v-row no-gutters class="flex-nowrap">
                                                            <v-col>
                                                                <v-row no-gutters class="gap-4">
                                                                    <v-col cols="12">
                                                                        <v-skeleton-loader type="image" width="100%" height="30" />
                                                                    </v-col>
                                                                    <v-col cols="12">
                                                                        <v-skeleton-loader type="image" width="100%" height="30" />
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                            <v-col cols="auto">
                                                                <v-divider vertical class="ma-0 pa-0" />
                                                            </v-col>
                                                            <v-col class="pl-4">
                                                                <v-row no-gutters>
                                                                    <v-col cols="12">
                                                                        <v-skeleton-loader type="image" width="100%" height="30" />
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4 pr-4" v-bind:data-v-step="'campaign-details-5'">
                                <v-col cols="12">
                                    <v-card elevation="0" class="border-1 border-gray-300 rounded-1" width="100%">
                                        <v-row no-gutters class="pa-4">
                                            <v-col cols="12">
                                                <span class="subtitle-3 text-gray-900">Mensagem</span>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="campaign_details" no-gutters class="pa-6 gap-6">
                                            <v-col cols="12" :sm="campaignProduct === 'RCS' && campaign_details.template_apps_history?.fallback !== undefined && campaign_details.template_apps_history?.fallback ? 6 : 12">
                                                <v-row no-gutters class="mb-4">
                                                    <v-col cols="12">
                                                        <canal-icon-description :product-name="campaign_details.product.CD_PRODUTO_PDES" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="12">
                                                        <preview-template
                                                            :type="template.type"
                                                            :message="template.message"
                                                            :list-suggestions="template.list_suggestions"
                                                            :image="template.image"
                                                            :card="template.card"
                                                            :list-card="template.list_card"
                                                            @showDialogPreview="showDialogPreview"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                            <v-col v-if="campaignProduct === 'RCS' && campaign_details.template_apps_history?.fallback !== undefined && campaign_details.template_apps_history?.fallback" cols="12" sm="6">
                                                <v-row no-gutters class="mb-4">
                                                    <v-col cols="12">
                                                        <canal-icon-description product-name="sms-fallback" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="12">
                                                        <preview-template
                                                            :type="fallback.type"
                                                            :message="fallback.message"
                                                            :list-suggestions="fallback.list_suggestions"
                                                            :image="fallback.image"
                                                            :card="fallback.card"
                                                            :list-card="fallback.list_card"
                                                            @showDialogPreview="showDialogPreview"
                                                        />
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row v-else no-gutters class="pa-6 gap-6">
                                            <v-col cols="12">
                                                <v-row no-gutters class="mb-4" align="center">
                                                    <v-col cols="auto">
                                                        <v-skeleton-loader type="avatar" />
                                                    </v-col>
                                                    <v-col class="ml-2">
                                                        <v-skeleton-loader type="text" width="100" />
                                                    </v-col>
                                                </v-row>
                                                <v-row no-gutters>
                                                    <v-col cols="12">
                                                        <preview-template loading />
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <pg-tour v-if="campaign_details" ref="tour" :steps="listTour" />
                    <dialog-preview-template v-model="show_dialog_preview" :ds-template="ds_template" />
                    <dialog-notify-report-generation
                        :data-report="config_report"
                        v-model="report_notify_data"
                        :dialog="show_report_notify"
                        @close="show_report_notify = false"
                        @actionDialog="generateReport"
                    />
                    <base-notify
                        v-model="show_notify"
                        :title="notify_title"
                        :message="notify_message"
                        type="success"
                    />
                    <base-dialog
                        v-model="dialog_action_config.show"
                        :title="dialog_action_config.title"
                        :action-button="dialog_action_config.button"
                        use-icon-close
                        max-width="410"
                        use-sub-action
                        :disable-action-button="dialog_action_config.show_lotes && selected_lotes.length === 0"
                        @clickDialog="dialog_action_config.action"
                    >
                        <v-form ref="form" :action="dialog_action_config.form_action" method="POST" name="redirect">
                            <input type="hidden" name="conteudo" :value="[campaign_details?.ID_CAMPANHA_CAES]" />
                            <input type="hidden" name="link" :value="window?.location?.href" />
                            <input type="hidden" name="return_details" :value="campaign_details?.ID_CAMPANHA_CAES" />
                            <input type="hidden" name="return_link" :value="window?.location?.pathname" />
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <span class="body-2 text-gray-900" v-html="dialog_action_config.description" />
                                </v-col>
                                <v-col v-if="dialog_action_config.information" cols="12" class="mt-4">
                                    <base-information :description="dialog_action_config.information" />
                                </v-col>
                                <v-col v-if="dialog_action_config.show_lotes" cols="12" class="mt-4">
                                    <base-data-table
                                        v-model="selected_lotes"
                                        :selected-items="selected_lotes"
                                        :headers="headers_table"
                                        :list-items="campaign_details?.lotes"
                                        :pagination="false"
                                        :show-total="false"
                                        :page-length="-1"
                                        :custom-columns="false"
                                        select-column
                                        item-key="ID_LOTE_LTES"
                                        dense
                                        :custom-slots="['lote']"
                                        :height="campaign_details?.lotes.length > 4 ? 160 : 'auto'"
                                        fixed-header
                                    >
                                        <template v-slot:lote="props">
                                            <span class="body-2 text-gray-900">Lote {{ props.index + 1 }} - {{ props.item.QT_SMS_LTES }} registros - {{ getPeriodLot(props.item) }}</span>
                                        </template>
                                    </base-data-table>
                                </v-col>
                            </v-row>
                        </v-form>
                        <template #subAction>
                            <v-row no-gutters class="mt-4">
                                <v-col cols="12" class="text-center">
                                    <a class="text-primary-blue-500 text-decoration-none body-2" @click="dialog_action_config.show = false" v-html="dialog_action_config.link_text" />
                                </v-col>
                            </v-row>
                        </template>
                    </base-dialog>
                </v-card>
                <dialog-click-details
                    v-model="show_click_details"
                    :link="campaign_details?.list_clicados?.open_url_count"
                    :phone="campaign_details?.list_clicados?.dial_phone_count"
                    :map="campaign_details?.list_clicados?.open_map_count"
                    :requestLocation="campaign_details?.list_clicados?.request_location_count"
                />
                <dialog-list-sessions
                    v-model="show_list_sessions"
                    :campaigns="[campaignId]"
                />
            </v-dialog>
        `
    )
};