import BaseBackgroundIcon from "../atomos/BaseBackgroundIcon?v=2";

/**
 * Componente serve para que o usuario acompanhe todos os andamento da criacao de template
 * @displayName Background Icon Text
 * @component
 * @vue
 */
const BackgroundIconText = {
    name: "background-icon-text",
    components: {"base-background-icon": BaseBackgroundIcon},
    props:
      {
          /**
           * Propriedade onde vao estar os dados do step
           */
          steps: {
              type: Object,
              required: true,
              validator: function(data) {
                  let data_valid = true;
                  for (let type in data) {
                      if (typeof type.icon !== "undefined" &&
                        typeof type.background !== "undefined" &&
                        typeof type.circle !== "undefined" &&
                        typeof type.title !== "undefined" &&
                        typeof type.description !== "undefined"
                      ) {
                          console.error("Esta faltando alguns dos campos obrigatorios do componente");
                          data_valid = false;
                      }
                  }

                  return data_valid;
              }
          }
      },
    template:
    /*html*/
          ` 
            <div class="container-fluid  p-0 m-0 "> 
                <v-col v-for="({icon,background,circle,title,description},key) in steps" :key="key">
                      <v-row class="mt-1">
                        <v-col sm="5" lg="6" >
                            <base-background-icon :icon="icon"
                            :background-color="background" 
                            :circle="circle" />  
                        </v-col>
                        <v-col cols="1" class="d-flex align-content-center flex-wrap p-0 ">
                            <v-row>
                                <v-col class="pl-1">
                                     <span class="font-weight-bold d-block">{{title}} </span>  
                                     <span class="d-block">{{description}} </span>  
                                </v-col>
                            </v-row>
                        </v-col>
                      </v-row>           
                </v-col>
            </div>
          `,
};
export default BackgroundIconText;
