import BaseInformation from "../../../../components/atomos/BaseInformation?v=3";
import BaseDataTable from "../../../../components/organismos/BaseDataTable?v=2";
import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";
import StatusCampaign from "../../../../components/moleculas/StatusCampaign?v=2";
import CanalIconDescription from "../../../../components/moleculas/CanalIconDescription?v=2";
import { DataTableHeaderClass } from "../../../../classes/DataTableHeaderClass";

/**
 * Componente de dialog para acoes da dashboard
 * <iframe src="http://localhost:8080/v2/documentacao/dashboard/dialog-actions-usage" style="width: 100%; height: 315px;"></iframe>
 * 
 * @requires {@link BaseInformation}
 * @requires {@link BaseDialog}
 * @requires {@link BaseDataTable}
 * @requires {@link StatusCampaign}
 * @requires {@link CanalIconDescription}
 * @requires {@link DataTableHeaderClass}
 * 
 * @displayName DialogActions
 * @category Page / Dashboard
 * @subcategory dialogs
 * @author Pedro Augusto Ribas Pinto <pedro.ribas@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseDataTable,
        BaseInformation,
        BaseDialog,
        StatusCampaign,
        CanalIconDescription
    },
    props: {
        /**
         * Array com os dados para a tabela
         */
        listData: {
            type: Array,
            required: true
        },
        /**
         * Tipo de acao (P = Pausa, R = Reinicio)
         */
        type: {
            type: String,
            required: true,
            validator(data) {
                return ["P", "R"].includes(data);
            }
        },
        /**
         * Valor do atributo v-model do componente, indica se o dialog deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            dialog: this.value,
            headers: [
                new DataTableHeaderClass({ text: "Identificador", value: "id" }),
                new DataTableHeaderClass({ text: "Canal", value: "canal", sortable: false }),
                new DataTableHeaderClass({ text: "Cliente", value: "cliente" }),
                new DataTableHeaderClass({ text: "Campanha", value: "campanha" }),
                new DataTableHeaderClass({ text: "Situa&ccedil;&atilde;o", value: "status", sortable: false })
            ],
            custom_slots: ["status", "canal"],
            selected_items: this.listData,
            dialog_loading: false
        };
    },
    computed: {
        title() {
            return this.type === "R" ? "Reiniciar" : "Pausar";
        },
        contentText() {
            let message = "";

            switch (this.type) {
            case "R":
                if (this.listData.length > 1) {
                    message = "H&aacute; " + this.listData.length + " campanhas que est&atilde;o eleg&iacute;veis para serem reiniciadas";
                } else {
                    message = "A seguinte campanha ser&aacute; reiniciada";
                }
                break;
            case "P":
                if (this.listData.length > 1) {
                    message = "H&aacute; " + this.listData.length + " campanhas que est&atilde;o eleg&iacute;veis para serem pausadas";
                } else {
                    message = "A seguinte campanha ser&aacute; pausada";
                }
                break;
            }

            return message;
        },
        informationText() {
            let message = "";

            switch (this.type) {
            case "R":
                message = window.Vue.htmlEntities("&Eacute; poss&iacute;vel pausar as campanhas novamente. Desde que n&atilde;o tenham sido finalizadas ou canceladas.");
                break;
            case "P":
                message = window.Vue.htmlEntities("&Eacute; poss&iacute;vel reiniciar a campanha a qualquer momento");
                break;
            }

            return message;
        },
        button() {
            let button_pause = {
                label: "Pausar",
                variant: "dark-grey",
                loading: false,
                icon: "mdi-pause"
            };
            let button_reiniciar = {
                label: "Reiniciar",
                variant: "primary-green",
                loading: false,
                icon: "fa-solid fa-play"
            };

            return this.type === "R" ? button_reiniciar : button_pause;
        },
        idsSelecteds() {
            let ids = [];

            for (let campaign of this.selected_items) {
                ids.push(campaign.id);
            }

            return ids;
        },
        disableActionButton() {
            return this.idsSelecteds.length === 0;
        }
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog() {
            /**
             * Evento de input, disparado sempre que o dialog for alterado
             * 
             * @property {Boolean} dialog status da modal se ela esta ou nao ativa
             * @event input
             * @vue
             */
            this.$emit("input", this.dialog);
        },
        listData() {
            this.selected_items = this.listData;
        }
    },
    methods: {
        ...window.Vuex.mapActions("dashboard", ["ActionDoPauseCampaigns", "ActionDoRestartCampaigns"]),
        /**
         * Faz o gerenciamento de como a acao deve ser realizada de acordo com tipo de conteudo exibido
         * @vue
         */
        campaignAction() {
            switch (this.type) {
            case "P":
                this.pause();
                break;
            case "R":
                this.restart();
                break;
            }
        },
        /**
         * Realiza a pausa das campanhas selecionadas
         * @vue
         */
        pause() {
            this.dialog_loading = true;
            this.ActionDoPauseCampaigns({ campaign_id: this.idsSelecteds }).then(() => {
                this.dialog_loading = false;
                this.$emit("success", {campaigns: this.selected_items, type: this.type});
                this.dialog = false;
            }).catch(error => {
                this.dialog_loading = false;
                this.$emit("error", error.response.data);
                this.dialog = false;
            });
        },
        /**
         * Realiza o reinicio das campanhas selecionadas
         * @vue
         */
        restart() {
            this.dialog_loading = true;
            this.ActionDoRestartCampaigns({ campaign_id: this.idsSelecteds }).then(() => {
                this.dialog_loading = false;
                this.$emit("success", {campaigns: this.selected_items, type: this.type});
                this.dialog = false;
            }).catch(error => {
                this.dialog_loading = false;
                this.$emit("error", error.response.data);
                this.dialog = false;
            });
        }
    },
    template: (
        /*html*/
        `
        <base-dialog 
            v-model="dialog"
            :title="title"
            :action-button="button"
            :loading="dialog_loading"
            cols-button-action="col-6 col-md-auto"
            cols-button-cancel="col-6 col-md-auto"
            justify-buttons="space-between"
            use-icon-close
            cancel
            max-width="1520px"
            :disable-action-button="disableActionButton"
            @clickDialog="campaignAction"
        >
            <v-card elevation="0">
                <v-row class="mt-n7 p-0">
                    <v-col>
                        <v-row align="center" >
                            <v-col cols="12" md="6" class="pb-0 mb-0">
                                <span class="align-center font-size-12" v-html="contentText"></span>
                            </v-col>
                            <v-col cols="12" md="6"> 
                                <v-row justify="end">
                                    <v-col cols="12" md="9">
                                        <base-information
                                            :description="informationText"
                                            icon="mdi-alert-circle"
                                            border-color="border-primary"
                                            icon-color="primary"
                                        ></base-information>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pt-0 mt-0">
                        <base-data-table
                            v-model="selected_items"
                            :selected-items="selected_items"
                            :headers="headers"
                            :list-items="listData"
                            :pagination="false"
                            :page-length="-1"
                            :custom-columns="false"
                            :custom-slots="custom_slots"
                            :height="listData.length > 4 ? '260px' : null"
                            select-column
                            item-key="id"
                            sort-by="id"
                        >
                            <template v-slot:status="props">
                                <status-campaign :status="props.item.status"></status-campaign>
                            </template>
                            <template v-slot:canal="props">
                                <canal-icon-description :product-name="props.item.canal"></canal-icon-description>
                            </template>
                        </base-data-table>
                    </v-col>
                </v-row>
            </v-card>
        </base-dialog>
        `
    )
};