import BaseTextarea from "../../../components/atomos/BaseTextarea";

// @vue/component
export default {
    components: {
        BaseTextarea
    },
    data() {
        return {
            label: "Placeholder do textarea",
            title: "Titulo do textarea",
            hint: null,
            popoverContent: null,
            counter: 20
        };
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="6">
                    <v-row>
                        <v-col>
                            <v-card class="pa-5">
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field label="Placeholder" v-model="label"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Titulo" v-model="title"></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field label="Dica" v-model="hint"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Conteudo do popover" v-model="popoverContent"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field label="Contador de caracteres" v-model="counter"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <base-textarea
                                            :label="label"
                                            :title="title"
                                            :hint="hint"
                                            :popover-content="popoverContent"
                                            :counter="counter"
                                        ></base-textarea>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};