import ReportStepTwo from "../../reports/components/ReportStepTwo";

export default {
    components: {
        ReportStepTwo
    },
    template: (
        /*html*/
        `
            <v-container>
                <v-row class="mt-10">
                    <v-col cols="12" md="3">
                    </v-col>
                    <v-col cols="12" md="9">
                        <report-step-two></report-step-two>
                    </v-col>
                </v-row>
            </v-container>
        `
    )
};