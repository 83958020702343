export default {
    template: (
        /*html*/
        `
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 4.24H1.862C1.7805 4.23987 1.69977 4.25581 1.62443 4.28691C1.54909 4.31801 1.48063 4.36365 1.42295 4.42124C1.36527 4.47883 1.31951 4.54722 1.28829 4.62251C1.25707 4.69779 1.241 4.7785 1.241 4.86V16.023C1.241 16.1045 1.25707 16.1852 1.28829 16.2605C1.31951 16.3358 1.36527 16.4042 1.42295 16.4618C1.48063 16.5193 1.54909 16.565 1.62443 16.5961C1.69977 16.6272 1.7805 16.6431 1.862 16.643H18C18.0815 16.6431 18.1622 16.6272 18.2376 16.5961C18.3129 16.565 18.3814 16.5193 18.4391 16.4618C18.4967 16.4042 18.5425 16.3358 18.5737 16.2605C18.6049 16.1852 18.621 16.1045 18.621 16.023V4.86C18.621 4.7785 18.6049 4.69779 18.5737 4.62251C18.5425 4.54722 18.4967 4.47883 18.4391 4.42124C18.3814 4.36365 18.3129 4.31801 18.2376 4.28691C18.1622 4.25581 18.0815 4.23987 18 4.24ZM1.862 3C1.61761 2.99987 1.37559 3.04787 1.14975 3.14128C0.923913 3.23468 0.718684 3.37165 0.545781 3.54437C0.372879 3.71708 0.235688 3.92217 0.142043 4.1479C0.0483975 4.37364 0.000131287 4.61561 0 4.86V16.023C0.000131287 16.2674 0.0483975 16.5094 0.142043 16.7351C0.235688 16.9608 0.372879 17.1659 0.545781 17.3386C0.718684 17.5113 0.923913 17.6483 1.14975 17.7417C1.37559 17.8351 1.61761 17.8831 1.862 17.883H18C18.2444 17.8831 18.4864 17.8351 18.7122 17.7417C18.9381 17.6483 19.1433 17.5113 19.3162 17.3386C19.4891 17.1659 19.6263 16.9608 19.72 16.7351C19.8136 16.5094 19.8619 16.2674 19.862 16.023V4.86C19.8619 4.61561 19.8136 4.37364 19.72 4.1479C19.6263 3.92217 19.4891 3.71708 19.3162 3.54437C19.1433 3.37165 18.9381 3.23468 18.7122 3.14128C18.4864 3.04787 18.2444 2.99987 18 3H1.862Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.724 7.341C3.724 7.2595 3.74007 7.17879 3.77129 7.10351C3.80251 7.02822 3.84827 6.95983 3.90595 6.90224C3.96363 6.84465 4.03209 6.79901 4.10743 6.76791C4.18277 6.73681 4.2635 6.72087 4.345 6.721H15.516C15.6804 6.721 15.8381 6.78632 15.9544 6.90259C16.0707 7.01887 16.136 7.17657 16.136 7.341C16.136 7.50543 16.0707 7.66313 15.9544 7.77941C15.8381 7.89568 15.6804 7.961 15.516 7.961H4.345C4.2635 7.96113 4.18277 7.94519 4.10743 7.91409C4.03209 7.88299 3.96363 7.83735 3.90595 7.77976C3.84827 7.72217 3.80251 7.65378 3.77129 7.5785C3.74007 7.50321 3.724 7.4225 3.724 7.341ZM3.724 10.441C3.724 10.3595 3.74007 10.2788 3.77129 10.2035C3.80251 10.1282 3.84827 10.0598 3.90595 10.0022C3.96363 9.94465 4.03209 9.89901 4.10743 9.86791C4.18277 9.83681 4.2635 9.82087 4.345 9.821H15.516C15.6804 9.821 15.8381 9.88632 15.9544 10.0026C16.0707 10.1189 16.136 10.2766 16.136 10.441C16.136 10.6054 16.0707 10.7631 15.9544 10.8794C15.8381 10.9957 15.6804 11.061 15.516 11.061H4.345C4.26358 11.0611 4.18293 11.0452 4.10766 11.0142C4.03239 10.9832 3.96397 10.9376 3.9063 10.8801C3.84864 10.8226 3.80286 10.7544 3.77158 10.6792C3.7403 10.604 3.72413 10.5224 3.724 10.441ZM3.724 13.541C3.724 13.4595 3.74007 13.3788 3.77129 13.3035C3.80251 13.2282 3.84827 13.1598 3.90595 13.1022C3.96363 13.0447 4.03209 12.999 4.10743 12.9679C4.18277 12.9368 4.2635 12.9209 4.345 12.921H11.793C11.9574 12.921 12.1151 12.9863 12.2314 13.1026C12.3477 13.2189 12.413 13.3766 12.413 13.541C12.413 13.7054 12.3477 13.8631 12.2314 13.9794C12.1151 14.0957 11.9574 14.161 11.793 14.161H4.345C4.18074 14.1613 4.02308 14.0963 3.90665 13.9805C3.79022 13.8646 3.72453 13.7073 3.724 13.543V13.541Z" fill="currentColor"/>
            </svg>
        `
    )
};