import colors_theme from "./colors";
import new_colors from "./new_colors?v=2";

export default {
    "super-dark-primary": colors_theme.primary.super_dark_primary,
    "dark-primary": colors_theme.primary.dark_primary,
    "primary": colors_theme.primary.primary,
    "light-primary": colors_theme.primary.light_primary,
    "extra-light-primary": colors_theme.primary.extra_light_primary,

    "dark-success": colors_theme.success.dark_success,
    "success": colors_theme.success.success,
    "light-success": colors_theme.success.light_success,
    "extra-light-success": colors_theme.success.extra_light_success,

    "dark-warning": colors_theme.warning.dark_warning,
    "warning": colors_theme.warning.warning,
    "light-warning": colors_theme.warning.light_warning,
    "extra-light-warning": colors_theme.warning.extra_light_warning,

    "dark-danger": colors_theme.danger.dark_danger,
    "danger": colors_theme.danger.danger,
    "light-danger": colors_theme.danger.light_danger,
    "extra-light-danger": colors_theme.danger.extra_light_danger,

    "off-white": colors_theme.grey.off_white,
    "background": colors_theme.grey.background,
    "super-light-grey": colors_theme.grey.super_light_grey,
    "extra-light-grey": colors_theme.grey.extra_light_grey,
    "light-grey": colors_theme.grey.light_grey,
    "medium-light-grey": colors_theme.grey.medium_light_grey,
    "medium-grey": colors_theme.grey.medium_grey,
    "medium-dark-grey": colors_theme.grey.medium_dark_grey,
    "dark-grey": colors_theme.grey.dark_grey,
    "extra-dark-grey": colors_theme.grey.extra_dark_grey,
    "super-dark-grey": colors_theme.grey.super_dark_grey,
    "black": colors_theme.grey.black,

    "sms": colors_theme.produtos.sms,
    "rcs": colors_theme.produtos.rcs,
    "whatsapp": colors_theme.produtos.whatsapp,

    "dark-purple": colors_theme.purple.dark_purple,
    "primary-purple": colors_theme.purple.primary_purple,
    "light-purple": colors_theme.purple.light_purple,
    "extra-light-purple": colors_theme.purple.extra_light_purple,

    "dark-cyan": colors_theme.cyan.dark_cyan,
    "primary-cyan": colors_theme.cyan.primary_cyan,
    "light-cyan": colors_theme.cyan.light_cyan,
    "extra-light-cyan": colors_theme.cyan.extra_light_cyan,

    "dark-green": colors_theme.green.dark_green,
    "primary-green": colors_theme.green.primary_green,
    "light-green": colors_theme.green.light_green,
    "extra-light-green": colors_theme.green.extra_light_green,

    "dark-orange": colors_theme.orange.dark_orange,
    "primary-orange": colors_theme.orange.primary_orange,
    "light-orange": colors_theme.orange.light_orange,
    "extra-light-orange": colors_theme.orange.extra_light_orange,

    "accent": colors_theme.primary.dark_primary,

    // Novas cores
    // Primary
    "primary-blue-900": new_colors.primary["blue_900"],
    "primary-blue-500": new_colors.primary["blue_500"],
    "primary-blue-200": new_colors.primary["blue_200"],
    "primary-blue-100": new_colors.primary["blue_100"],
    "primary-blue-50": new_colors.primary["blue_50"],

    // Secondary
    "secondary-blue-500": new_colors.secondary["blue_500"],
    "secondary-blue-300": new_colors.secondary["blue_300"],
    "secondary-blue-200": new_colors.secondary["blue_200"],
    "secondary-blue-100": new_colors.secondary["blue_100"],

    // Tertiary
    "tertiary-blue-900": new_colors.tertiary["blue_900"],
    "tertiary-blue-100": new_colors.tertiary["blue_100"],

    // Success
    "success-900": new_colors.success["900"],
    "success-500": new_colors.success["500"],
    "success-200": new_colors.success["200"],
    "success-50": new_colors.success["50"],

    // Warning
    "warning-900": new_colors.warning["900"],
    "warning-500": new_colors.warning["500"],
    "warning-200": new_colors.warning["200"],
    "warning-50": new_colors.warning["50"],

    // Error
    "error-900": new_colors.error["900"],
    "error-500": new_colors.error["500"],
    "error-200": new_colors.error["200"],
    "error-50": new_colors.error["50"],

    // Gray
    "gray-0": new_colors.gray["0"],
    "gray-10": new_colors.gray["10"],
    "gray-30": new_colors.gray["30"],
    "gray-50": new_colors.gray["50"],
    "gray-100": new_colors.gray["100"],
    "gray-200": new_colors.gray["200"],
    "gray-300": new_colors.gray["300"],
    "gray-400": new_colors.gray["400"],
    "gray-500": new_colors.gray["500"],
    "gray-600": new_colors.gray["600"],
    "gray-700": new_colors.gray["700"],
    "gray-800": new_colors.gray["800"],
    "gray-900": new_colors.gray["900"],

    // Brand
    "brand-sms-500": new_colors.brand["sms_500"],
    "brand-rcs-500": new_colors.brand["rcs_500"],
    "brand-email-500": new_colors.brand["email_500"],

    // Green
    "green-900": new_colors.green["900"],

    // Info
    "info-500": new_colors.info["500"],

    // Apoio
    "coral-900": new_colors.coral["900"],
    "cyan-900": new_colors.cyan["900"]
};