export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Reiniciar-Hover" transform="translate(5 -41)">
                    <rect id="Retângulo_336" data-name="Retângulo 336" width="28" height="28" transform="translate(-5 41)" fill="currentColor" opacity="0"/>
                    <path id="play-fill" d="M24.417,18.423,11.5,25.916A1.662,1.662,0,0,1,9,24.5V9.515A1.661,1.661,0,0,1,11.5,8.1L24.417,15.6a1.627,1.627,0,0,1,0,2.827Z" transform="translate(-8 38.125)" fill="currentColor"/>
                </g>
            </svg>
        `
    )
};