export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-UserPerm-Hover" transform="translate(3.75 -116)">
                    <rect id="Retângulo_355" data-name="Retângulo 355" width="28" height="28" transform="translate(-3.75 116)" fill="currentColor" opacity="0"/>
                    <g id="Caminho_379-3_1_" transform="translate(0 120)">
                        <path id="Caminho_499" data-name="Caminho 499" d="M955.6,835H951c0-.1.1-.2.1-.3v-2.2a2,2,0,0,1,4,0,.789.789,0,0,0,.8.8.736.736,0,0,0,.8-.8,3.5,3.5,0,0,0-7,0v2.2a.764.764,0,0,0,.2.5c-.1.1-.2.1-.3.2a8.688,8.688,0,0,0-2.6-1.6,4.932,4.932,0,0,0,2.1-4.1,5,5,0,0,0-10,0,4.932,4.932,0,0,0,2.1,4.1,6.54,6.54,0,0,0-2.6,1.7,9.451,9.451,0,0,0-2.1,3.1.778.778,0,0,0,.2.8,11.453,11.453,0,0,0,7.4,2.7,10.3,10.3,0,0,0,4.6-1v.8a2.112,2.112,0,0,0,2.1,2.1h4.9a2.2,2.2,0,0,0,2.2-2.1V837A2.371,2.371,0,0,0,955.6,835Zm-15.2-5.2a3.5,3.5,0,1,1,3.5,3.5A3.543,3.543,0,0,1,940.4,829.8Zm8.2,7.4v2.4a9.918,9.918,0,0,1-10.7-.9,8.009,8.009,0,0,1,1.5-2.1,6.676,6.676,0,0,1,9.2.1Zm5.7,4.2h-2.1l.7-1.6a1.087,1.087,0,0,1-.7-1,1.1,1.1,0,0,1,2.2,0,1.2,1.2,0,0,1-.7,1Z" transform="translate(-936.461 -824.7)" fill="currentColor"/>
                    </g>
                </g>
            </svg>
        `
    )
};