import CardShortChoice from "../../../components/moleculas/CardShortChoice?v=2";
import BaseSectionForm from "../../../components/atomos/BaseSectionForm?v=2";
import BaseTextField from "../../../components/atomos/BaseTextField?v=2";
import BaseAutocomplete from "../../../components/atomos/BaseAutocomplete";
import CarouselNews from "../../../components/organismos/CarouselNews?v=2";
import BaseInformation from "../../../components/atomos/BaseInformation?v=3";
import PreviewTemplate from "../../../components/organismos/PreviewTemplate";
import DialogPreviewTemplate from "../../../components/organismos/DialogPreviewTemplate";
import { CardSliderClass } from "../../../classes/CardSliderClass?v=2";

import * as types from "../store/mutations-types";

/**
 * Componente que representa o passo um do cadastro de templates para RCS
 * 
 * @displayName CadastroTemplatePassoUm
 * @requires {@link CardShortChoice}
 * @requires {@link BaseSectionForm}
 * @requires {@link BaseTextField}
 * @requires {@link BaseAutocomplete}
 * @requires {@link CarouselNews}
 * @requires {@link BaseInformation}
 * @requires {@link PreviewTemplate}
 * @requires {@link DialogPreviewTemplate}
 * @requires {@link CardSliderClass}
 * @component
 * @category Page / Template RCS
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 * @vue/component
 */
export default {
    components: {
        CardShortChoice,
        BaseSectionForm,
        BaseTextField,
        BaseAutocomplete,
        CarouselNews,
        BaseInformation,
        PreviewTemplate,
        DialogPreviewTemplate
    },
    data() {
        return {
            id_empresa: this.$cookie === undefined ? getCookie("id_empresa_selected") : this.$cookie.get("id_empresa_selected"),
            code_rules: [
                v => !!v || "Informe um c&oacute;digo para o template",
                v => {
                    const normalizedCode = (v || "").trim().toUpperCase().replaceAll(" ", "_");
                    const matchingTemplate = this.list_templates.find(template => {
                        return template.CD_TEMPLATE_TAES.toUpperCase() === normalizedCode &&
                            (template.ID_EMPRESA_TAES == this.id_empresa ||
                                (Array.isArray(this.clients) && this.clients.includes(template.ID_EMPRESA_TAES)));
                    });
                    if (!matchingTemplate) {
                        return true;
                    }
                    if (matchingTemplate.ID_TEMPLATE_APPS_TAES == this.id_template_edit) {
                        return true;
                    }
                    return window.Vue.htmlEntities("J&aacute; existe um template com este nome");
                }
            ],
            name_rules: [
                v => !!v || "Informe um nome para o template"
            ],
            cards_short: [
                {
                    label: "Texto",
                    icon: "$templateTextIcon",
                    icon_small: "$templateTextIcon",
                    disabled: true,
                    active: false,
                    soon: false,
                    value: "text",
                    tooltip: "Crie templates no formato de texto com ou sem dados vari&aacute;veis"
                },
                {
                    label: "Imagem",
                    icon: "$templateImageIcon",
                    icon_small: "$templateImageIcon",
                    disabled: true,
                    active: false,
                    soon: false,
                    value: "image",
                    tooltip: "Crie templates para envio de imagens &uacute;nicas ou personalizadas"
                },
                {
                    label: "Card",
                    icon: "$templateCardIcon",
                    icon_small: "$templateCardIcon",
                    disabled: true,
                    active: false,
                    soon: false,
                    value: "card",
                    tooltip: "Crie cart&otilde;es de mensagem podendo conter imagem, t&iacute;tulo, descri&ccedil;&atilde;o e links."
                },
                {
                    label: "Carrossel",
                    icon: "$templateCarouselIcon",
                    icon_small: "$templateCarouselIcon",
                    disabled: false,
                    active: false,
                    soon: true,
                    value: "carousel",
                    tooltip: "EM BREVE! Crie sequ&ecirc;ncias de cart&otilde;es de mensagem com at&eacute; 10 p&aacute;ginas"
                }
            ],
            template_code: null,
            template_name: null,
            clients: null,
            client_list: [],
            client_description: null,
            list_templates: [],
            on_mount: true,
            show_dialog_preview: false,
            ds_template: null
        };
    },
    computed: {
        ...window.Vuex.mapState("templatesrcs", {
            step_one_content: state => state.step_one_content,
            step_two_content: state => state.step_two_content,
            id_template_edit: state => state.id_template_edit
        }),
        ...window.Vuex.mapState("utils", {
            clients_loading: state => state.clients_loading,
            list_clients_for_select: state => state.list_clients_for_select
        }),
        cardSelected() {
            for (let card of this.cards_short) {
                if (card.active) {
                    return card;
                }
            }

            return null;
        },
        cardVertical() {
            return this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.sm || (this.$vuetify.breakpoint.width >= this.$vuetify.breakpoint.thresholds.md && this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.thresholds.lg);
        },
        list_card_slider() {
            let list = [];

            switch (this.cardSelected?.value) {
            case "text":
                list = [
                    new CardSliderClass({
                        image: "/images/cadastro-template/Txt_Onboarding1.svg",
                        title: "Duas modalidades de RCS de texto",
                        text: "- <strong>Basic</strong>: at&eacute; 160 caracteres;</BR>- <strong>Single</strong>: de 161 at&eacute; 1000 caracteres.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/Txt_Onboarding2.svg",
                        title: "Adicione campos personalizados",
                        text: "Campos personalizados s&atilde;o utilizados para apresentar dados reais de diferentes contatos.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/Txt_Onboarding3.svg",
                        title: "Respostas r&aacute;pidas e bot&otilde;es de a&ccedil;&atilde;o",
                        text: "Ajude os clientes a tomar decis&otilde;es sugerindo respostas r&aacute;pidas ou bot&otilde;es de a&ccedil;&atilde;o.",
                        vertical: this.cardVertical
                    })
                ];
                break;
            case "image":
                list = [
                    new CardSliderClass({
                        image: "/images/cadastro-template/Img_Onboarding1.svg",
                        title: "Mais do que palavras...",
                        text: "Utilize imagens para se comunicar com seus clientes.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/Img_Onboarding2.svg",
                        title: "&Uacute;nicas ou personalizadas",
                        text: "Escolha entre enviar a mesma imagem para todos ou uma imagem diferente para cada contato.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/Img_Onboarding3.svg",
                        title: "Imagens personalizadas",
                        text: "Voc&ecirc; poder&aacute; enviar uma imagem diferente para cada contato em suas campanhas.",
                        vertical: this.cardVertical
                    })
                ];
                break;
            case "card":
                list = [
                    new CardSliderClass({
                        image: "/images/cadastro-template/Card_Onboarding1.svg",
                        title: "Tudo em um s&oacute; lugar",
                        text: "Com o modelo de card, voc&ecirc; poder&aacute; enviar mensagens completas utilizando todas as ferramentas que o RCS oferece.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/Card_Onboarding2.svg",
                        title: "O melhor para a sua campanha",
                        text: "Escolha o tamanho da imagem, escreva uma descri&ccedil;&atilde;o sem limites de caracteres, escolha a orienta&ccedil;&atilde;o vertical ou horizontal, adicione at&eacute; 4 bot&otilde;es.",
                        vertical: this.cardVertical
                    }),
                    new CardSliderClass({
                        image: "/images/cadastro-template/Card_Onboarding3.svg",
                        title: "Aumente o engajamento",
                        text: "O card inova a forma de se comunicar, trazendo experi&ecirc;ncias mais interativas e completas, construindo um relacionamento mais pr&oacute;ximo com seus clientes.",
                        vertical: this.cardVertical
                    })
                ];
                break;
            }

            return list;
        }
    },
    watch: {
        clients() {
            setTimeout(() => {
                this.setStateData();
            }, 200);
        },
        template_code() {
            this.updateTemplate();
        },
        template_name() {
            this.updateTemplate();
        },
        cardSelected() {
            this.setStateData();
        },
        step_one_content() {
            if (this.step_one_content.template_code && this.step_one_content.modelo) {
                for (let modelo of this.cards_short) {
                    if (modelo?.value == this.step_one_content.modelo?.value) {
                        modelo.active = true;
                        modelo.disabled = false;
                    }
                }
                this.template_code = this.step_one_content.template_code;
                this.template_name = this.step_one_content.template_name ?? "";
            }
        },
    },
    mounted() {
        this.checkClients();
        this.getTemplates();

        if (this.step_one_content.template_code && this.step_one_content.modelo) {

            for (let modelo of this.cards_short) {
                if (modelo?.value == this.step_one_content.modelo?.value) {
                    modelo.active = true;
                }

                if (!modelo.soon) {
                    modelo.disabled = false;
                }
            }

            this.template_code = this.step_one_content.template_code;
            this.template_name = this.step_one_content.template_name;
        }

        setTimeout(() => {
            this.on_mount = false;
        }, 500);
    },
    methods: {
        ...window.Vuex.mapActions("templatesrcs", ["ActionDoGetTemplatesRcs"]),
        /**
         * Busca a lista de templates para verificar se ja existem templates com o mesmo c�digo
         */
        getTemplates() {
            this.ActionDoGetTemplatesRcs().then(response => {
                this.list_templates = response;
            });
        },
        /**
         * Salva os dados do passo um no store
         * @vue
         */
        setStateData() {
            if (this.template_code && this.cardSelected && (this.clients_loading || (this.clients && (this.clients.length > 0 || Number.isInteger(this.clients))))) {
                this.template_code = this.template_code.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                this.$store.commit("templatesrcs/" + types.SET_STEP_ONE, {
                    template_code: this.template_code,
                    template_name: this.template_name ?? "",
                    modelo: _.cloneDeep(this.cardSelected),
                    clients: Number.isInteger(this.clients) ? [this.clients] : (this.clients !== null ? this.clients : this.step_one_content.clients),
                    client_description: this.client_description
                });
            } else {
                this.$store.commit("templatesrcs/" + types.SET_STEP_ONE, {});
            }
        },
        /**
         * Exibe o dialog de preview de template
         * @param {String} content_preview JSON no formato string
         */
        showDialogPreview(content_preview) {
            this.ds_template = content_preview;
            this.show_dialog_preview = true;
        },
        /**
         * Define a descri��o do campo clientes
         * @param {String} description
         * @vue
         */
        setDescriptionClient(description) {
            this.client_description = description;
        },
        /**
         * Verifica se existem clientes ja selecionados e monta a lista de clientes para o autocomplete
         * @vue
         */
        checkClients() {
            if (!this.clients_loading) {
                // Verifica se j� h� clientes selecionados e seleciona os mesmos que o usu�rio continua tendo permiss�o
                if (this.step_one_content.clients && this.step_one_content.clients.length > 0) {
                    let clients_remained = this.step_one_content.clients.filter(field_client_id => {
                        if (field_client_id === -1) {
                            return -1;
                        } else {
                            return this.list_clients_for_select.find((client) => {
                                return client.value === field_client_id;
                            });
                        }
                    });

                    this.clients = this.id_template_edit ? clients_remained[0] : clients_remained;
                } else if (this.id_template_edit === null) {
                    this.clients = this.list_clients_for_select.map(x => x.value);
                }

                this.client_list = [];
                if (this.id_template_edit) {
                    this.client_list.push({ text: "Todos", value: -1 });
                }
                this.client_list.push(...this.list_clients_for_select);
            } else {
                setTimeout(() => {
                    this.checkClients();
                }, 500);
            }
        },
        /**
         * Verifica se os campos do formul�rio s�o v�lidos para liberar os cards de sele��o do modelo do template
         * @vue
         */
        updateTemplate() {
            if (!this.on_mount && !this.clients_loading) {
                for (let card of this.cards_short) {
                    if (!card.soon) {
                        card.disabled = !this.$refs.formCodeName.validate();
                        //card.active = !this.$refs.formCodeName.validate() ? false : card.active;
                    }
                }
                this.setStateData();
            }
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters>
                <v-col
                    cols="12"
                    md="5"
                    lg="4"
                    class="pa-lg-8"
                    :data-v-step="2"
                >
                    <base-section-form title="Dados do template">
                        <v-row no-gutters class="mt-8">
                            <v-col>
                                <base-autocomplete
                                    tooltip-text="Voc&ecirc; pode selecionar um ou mais clientes(carteiras)."
                                    v-model="clients"
                                    title="Selecionar um ou mais clientes (carteiras)"
                                    label="Clientes"
                                    :items="client_list"
                                    :loading="clients_loading"
                                    @setDescription="setDescriptionClient"
                                    customDescription="clientes"
                                    :multiple="!id_template_edit"
                                    return-description
                                    :rules="id_template_edit ? [v => !!v || 'Selecione um cliente'] : undefined"
                                ></base-autocomplete>
                            </v-col>
                        </v-row>
                        <v-form ref="formCodeName">
                            <v-row no-gutters class="mt-4">
                                <v-col>
                                    <base-text-field
                                        v-model="template_code"
                                        label="Insira o c&oacute;digo aqui..."
                                        title="C&oacute;digo do template"
                                        tooltip-text="O c&oacute;digo do template ajudar&aacute; na identifica&ccedil;&atilde;o durante a busca na lista de templates."
                                        :rules="code_rules"
                                        :disabled="this.clients_loading"
                                        required
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col>
                                    <base-text-field
                                        v-model="template_name"
                                        label="Digite o nome aqui..."
                                        title="Nome do template"
                                        tooltip-text="O nome do template auxiliar&aacute; na identifica&ccedil;&atilde;o durante a busca na lista de templates."
                                        :rules="name_rules"
                                        :disabled="this.clients_loading"
                                        required
                                    />
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row no-gutters class="mt-4">
                            <v-col>
                                <base-information description="O c&oacute;digo do template ser&aacute; gravado em letras MAI&Uacute;SCULAS, al&eacute;m disso todo espa&ccedil;o ser&aacute; convertido em <i>underline</i> ( _ )" />
                            </v-col>
                        </v-row>
                    </base-section-form>
                    <v-row class="mt-8" no-gutters>
                        <v-col>
                            <v-row no-gutters>
                                <v-col class="body-2">
                                    Escolha o modelo
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4">
                                <v-col>
                                    <card-short-choice v-model="cards_short" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    v-if="id_template_edit && step_two_content && (step_two_content.list_messages?.length || step_two_content.image)"
                    cols="12"
                    md="6"
                    offset-md="1"
                    lg="6"
                    offset-lg="1"
                    class="mt-2 mt-lg-0"
                >
                    <preview-template
                        :type="cardSelected?.value === 'text' ? 'message' : cardSelected?.value"
                        :message="step_two_content.list_messages"
                        :list-suggestions="step_two_content.list_chips_preview"
                        :image="step_two_content.image"
                        :card="step_two_content.card"
                        :list-card="step_two_content.list_card"
                        @showDialogPreview="showDialogPreview"
                    />
                </v-col>
                <v-col
                    v-else-if="!id_template_edit && cardSelected"
                    cols="12"
                    offset-sm="0"
                    sm="12"
                    md="5"
                    offset-md="2"
                    lg="6"
                    offset-lg="1"
                    class="mt-2 mt-lg-0"
                >
                    <carousel-news :list-card-slider="list_card_slider" />
                </v-col>
                <v-col
                    v-else
                    cols="12"
                    md="5"
                    offset-md="2"
                    lg="6"
                    offset-lg="1"
                    class="py-5 mt-2 mt-lg-0"
                >
                    <v-img src="/images/cadastro-template/placeholder_1.svg" max-height="310" contain />
                </v-col>
                <dialog-preview-template
                    v-model="show_dialog_preview"
                    :ds-template="ds_template"
                />
            </v-row>
        `
    )
};