/**
 * Componente base de Chat
 *
 * @displayName BaseChat
 * @component
 * @category Atomos
 * @author Igor M R Oliveira <igor.oliveira@pgmais.com.br>
 * @vue
 */
export default {
    props: {
        /**
         * Texto da mensagem.
         */
        messageText: {
            type: String,
            required: true,
        },
        /**
         * Tipo da mensagem.
         *
         * S => Sender
         * R => Receive
         */
        type: {
            type: String,
            required: false,
            default: "S",
        }
    },
    template: (
        /*html*/
        `
            <v-row>
                <v-col cols="12">
                    <v-card elevation="10"  class="pa-8 rounded-5"  :class="{
                        'rounded-br-0': type === 'S',
                        'rounded-tl-0': type === 'R',
                        'primary-blue-500': type === 'S',
                        'gray-0': type != 'S'                       
                    }" >
                        <v-card-text 
                            :class="[type === 'S' ? 'text-gray-0' : 'text-gray-900']"           
                            text-align="left"
                            v-html="messageText"
                        />
                    </v-card>
                </v-col>
            </v-row>
        `
    )
};