import BackgroundIconText from "../../../components/moleculas/BackgroundIconText";

export default ({
    el: "#vue-page",
    data: function () {
        return {
            steps: {
                "canal": { icon: "icon-rcs", background: "rcs", circle: false, title: "Canal", description: "Teste RCS"},
                "modelo": { icon: "icon-texto", background: "primary", circle: false, title: "Modelo", description: "Teste texto" }
            }
        };
    },
    components: {
        "background-icon-text": BackgroundIconText
    },
    template:
    /*html*/
    `
      <v-container>
         <v-row>
            <v-col cols="2">
              <background-icon-text :steps="steps" />
            </v-col>
         </v-row>
      </v-container>
      `
});

