import NewCampaignPage from "../../campaign/NewCampaignPage";

export default {
    components: {
        NewCampaignPage
    },
    template:
        /*html*/
        `
            <div class="row justify-center mt-16">
                <new-campaign-page />
            </div>
     
        `
};

