import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";
import FilterPageSlider from "../../../../components/atomos/FilterPageSlider?v=2";
import BaseAutocomplete from "../../../../components/atomos/BaseAutocomplete?v=2";
import BaseDatePicker from "../../../../components/atomos/BaseDatePicker";
import BaseSelect from "../../../../components/atomos/BaseSelect";
import TemplateUtils from "../../../../utils/TemplateUtils";

/**
 * Componente de formulario de pesquisa de templates
 * 
 * @requires {@link BaseTextField}
 * @requires {@link BaseButton}
 * @requires {@link FilterPageSlider}
 * @requires {@link BaseAutocomplete}
 * @requires {@link BaseSelect}
 * @requires {@link BaseDatePicker}
 * 
 * @displayName FormFilterTemplate
 * @category Page / Template
 * @subcategory forms
 * @author David Nunes dos Santos <david.santos@pgmais.com>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseButton,
        FilterPageSlider,
        BaseAutocomplete,
        BaseDatePicker,
        BaseSelect
    },
    data() {
        return {
            template_utils: new TemplateUtils(),
            type_product: this.$cookie === undefined ? getCookie("type_product") : this.$cookie.get("type_product"),
            form_data: {
                tipo: null,
                cliente: null,
                codigo: null,
                nome: null,
                resumo: null,
                data_criacao: null,
                data_alteracao: null,
                fallback: null,
                status: null
            },
            clients_loading: false,
            client_list: [],
            pills_filter: [],
            fallback_options: [
                { value: "T", text: "Todos" },
                { value: "S", text: window.Vue.htmlEntities("Cont&eacute;m") },
                { value: "N", text: window.Vue.htmlEntities("N&atilde;o cont&eacute;m") }
            ],
            status_options: [
                { value: "T", text: "Todos" },
                { value: "A", text: "Ativos" },
                { value: "I", text: "Inativos" }
            ],
            show_form: false,
            disable_button: true
        };
    },
    computed: {
        showReset() {
            let has_filter = false;

            for (let key of Object.keys(this.form_data)) {
                if (this.form_data[key] && this.form_data[key].length > 0) {
                    has_filter = true;
                }
            }

            return has_filter && !this.isDefaultFilter();
        },
        formString() {
            return JSON.stringify(this.form_data);
        }
    },
    watch: {
        formString() {
            this.disable_button = false;
        }
    },
    mounted() {
        this.getClients();
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetMyClients"]),
        /**
         * Submit dor formulario, atualiza a lista de dados de acordo com o filtro
         * @vue
         */
        formSubmit() {
            if (this.$refs.form.validate()) {
                /**
                 * Emite o conteudo do formulario
                 * @property {Object} form_data Conteudo emitido
                 */
                this.$emit("formSubmit", this.form_data);
                this.makePillsFilter();

                setTimeout(() => {
                    this.disable_button = true;
                }, 500);
            }
        },
        /**
         * Busca na API e monta a lista de clientes (carteiras) que a empresa definida na sessao possui
         * @vue
         */
        getClients() {
            this.clients_loading = true;
            this.ActionDoGetMyClients(true).then((response) => {
                this.client_list = response;
                this.clients_loading = false;

                this.makeDefaultFilter();
            });
        },
        /**
         * Monta os dados default do filtro
         * @vue
         */
        makeDefaultFilter() {
            this.form_data.cliente = this.client_list.map((e) => {
                return e.value;
            });
            this.form_data.status = "A";

            if (this.type_product === "RCS") {
                this.form_data.tipo = this.template_utils.getTiposTemplateKeys();
                this.form_data.fallback = "S";
            }

            this.formSubmit();
        },
        /**
         * Monta a configuracao das pills do filtro
         * @vue
         */
        makePillsFilter() {
            let config = [];

            if (this.form_data.tipo !== null && this.form_data.tipo.length > 0) {
                config.push({
                    text: "Modelo",
                    subText: this.form_data.tipo.length + " selecionados",
                    click: () => {
                        this.form_data.tipo = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.cliente !== null && this.form_data.cliente.length > 0) {
                config.push({
                    text: "Cliente",
                    subText: this.form_data.cliente.length + " selecionados",
                    click: () => {
                        this.form_data.cliente = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.codigo !== null && this.form_data.codigo.length > 0) {
                config.push({
                    text: "C&oacute;digo",
                    subText: this.form_data.codigo,
                    click: () => {
                        this.form_data.codigo = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.nome !== null && this.form_data.nome.length > 0) {
                config.push({
                    text: "Nome do template",
                    subText: this.form_data.nome,
                    click: () => {
                        this.form_data.nome = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.resumo !== null && this.form_data.resumo.length > 0) {
                config.push({
                    text: window.Vue.htmlEntities("Conte&uacute;do"),
                    subText: this.form_data.resumo,
                    click: () => {
                        this.form_data.resumo = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.data_criacao !== null && this.form_data.data_criacao.length > 0) {
                config.push({
                    text: window.Vue.htmlEntities("Data de cria&ccedil;&atilde;o"),
                    subText: dayjs(this.form_data.data_criacao).format("DD/MM/YYYY"),
                    click: () => {
                        this.form_data.data_criacao = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.data_alteracao !== null && this.form_data.data_alteracao.length > 0) {
                config.push({
                    text: window.Vue.htmlEntities("Data de altera&ccedil;&atilde;o"),
                    subText: dayjs(this.form_data.data_alteracao).format("DD/MM/YYYY"),
                    click: () => {
                        this.form_data.data_alteracao = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.fallback !== null && this.form_data.fallback.length > 0) {
                config.push({
                    text: "SMS alternativo",
                    subText: this.fallback_options.filter((option) => {
                        return option.value === this.form_data.fallback;
                    })[0].text,
                    click: () => {
                        this.form_data.fallback = null;
                        this.formSubmit();
                    }
                });
            }

            if (this.form_data.status !== null && this.form_data.status.length > 0) {
                config.push({
                    text: "Status",
                    subText: this.status_options.filter((option) => {
                        return option.value === this.form_data.status;
                    })[0].text,
                    click: () => {
                        this.form_data.status = null;
                        this.formSubmit();
                    }
                });
            }

            this.pills_filter = config;
        },
        /**
         * Fun��o usada para limpar os inputs do formul�rio de busca
         * @vue
         */
        limparFiltro() {
            this.form_data = {
                tipo: null,
                cliente: null,
                codigo: null,
                nome: null,
                resumo: null,
                data_criacao: null,
                data_alteracao: null,
                fallback: null,
                status: null
            };

            this.makeDefaultFilter();
        },
        /**
         * Verifica se o filtro inserido eh o filtro default da pagina
         * @vue
         */
        isDefaultFilter() {
            let is_default = false;

            let default_types = this.template_utils.getTiposTemplateKeys();
            let default_clients = this.client_list.map((e) => {
                return e.value;
            });
            let default_day = dayjs().format("YYYY-MM-DD");

            if (((this.type_product === "RCS" && this.form_data.tipo && JSON.stringify(Array.from(this.form_data.tipo)) === JSON.stringify(default_types)) || this.type_product !== "RCS")
                && (this.form_data.cliente && JSON.stringify(Array.from(this.form_data.cliente)) === JSON.stringify(default_clients))
                && (!this.form_data.codigo || this.form_data.codigo === "")
                && (!this.form_data.nome || this.form_data.nome === "")
                && ((this.type_product === "RCS" && this.form_data.fallback === "C") || this.type_product !== "RCS")
                && (!this.form_data.resumo || this.form_data.resumo === "")
                && this.form_data.data_criacao === default_day
                && this.form_data.data_alteracao === default_day
                && this.form_data.status === "A"
            ) {
                is_default = true;
            }

            return is_default;
        }
    },
    template: (
        /*html*/
        `
        <filter-page-slider
            v-model="show_form"
            :pills-filter="pills_filter"
            :show-reset="showReset"
            :data-v-step="2"
            @reset="limparFiltro();"
        >
            <v-form ref="form">
                <v-row>
                    <v-col v-if="type_product === 'RCS'" cols="6" sm="">
                        <base-autocomplete
                            v-model="form_data.tipo"
                            tooltip-text="Escolha o t&iacute;po de template que deseja filtrar. (Texto, m&iacute;dia, carroussel)."
                            label="Selecione uma op&ccedil;&atilde;o"
                            title="Modelo"
                            multiple
                            :rules="[]"
                            :items="template_utils.getTiposTemplateToAutocomplete()"
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="6" sm="">
                        <base-autocomplete
                            v-model="form_data.cliente"
                            tooltip-text="Voc&ecirc; pode filtrar as templates por cliente."
                            label="Selecione uma op&ccedil;&atilde;o"
                            title="Cliente"
                            :items="client_list"
                            :rules="[]"
                            :loading="clients_loading"
                            multiple
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="6" sm="" class="mt-4 mt-sm-0">
                        <base-text-field
                            v-model="form_data.codigo"
                            tooltip-text="Voc&ecirc; pode filtrar pelo c&oacute;digo do template."
                            label="Digite aqui..."
                            title="C&oacute;digo"
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="6" sm="" class="mt-4 mt-sm-0">
                        <base-text-field
                            v-model="form_data.nome"
                            tooltip-text="Voc&ecirc; pode filtrar pelo nome do template."
                            label="Digite aqui..."
                            title="Nome do template"
                            use-icon-validation
                        />
                    </v-col>
                    <v-col
                        v-if="type_product === 'RCS'"
                        cols="6"
                        sm=""
                        class="mt-4 mt-sm-0"
                    >
                        <base-select
                            v-model="form_data.fallback"
                            tooltip-text="Filtre templates com ou sem SMS alternativo"
                            label="Selecione uma op&ccedil;&atilde;o"
                            title="SMS alternativo"
                            use-icon-validation
                            :rules="[]"
                            :items="fallback_options"
                        />
                    </v-col>
                    <v-col cols="6" sm="" class="d-sm-none mt-4 mt-sm-0">
                        <base-text-field
                            v-model="form_data.resumo"
                            tooltip-text="Filtro por uma parte do texto da template."
                            label="Digite aqui..."
                            title="Conte&uacute;do"
                            use-icon-validation
                        />
                    </v-col>
                </v-row>
                <v-row class="mt-4">
                    <v-col cols="6" sm="" class="d-none d-sm-block">
                        <base-text-field
                            v-model="form_data.resumo"
                            tooltip-text="Filtro por uma parte do texto da template."
                            label="Digite aqui..."
                            title="Conte&uacute;do"
                            use-icon-validation
                        />
                    </v-col>
                    <v-col cols="6" sm="">
                        <base-date-picker
                            v-model="form_data.data_criacao"
                            tooltip-text="Data de cria&ccedil;&atilde;o da template"
                            label="Digite aqui..."
                            title="Data de cria&ccedil;&atilde;o"
                            use-icon-validation
                            clearable
                        />
                    </v-col>
                    <v-col cols="6" sm="">
                        <base-date-picker
                            v-model="form_data.data_alteracao"
                            tooltip-text="Data da &uacute;ltima altera&ccedil;&atilde;o da template"
                            label="Digite aqui..."
                            title="Data de altera&ccedil;&atilde;o"
                            use-icon-validation
                            clearable
                        />
                    </v-col>
                    <v-col cols="6" sm="" class="mt-4 mt-sm-0">
                        <base-select
                            v-model="form_data.status"
                            tooltip-text="Filtre pelo Status atual do template"
                            label="Selecione uma op&ccedil;&atilde;o"
                            title="Status"
                            use-icon-validation
                            :rules="[]"
                            :items="status_options"
                        />
                    </v-col>
                    <v-col class="d-flex align-end mt-4 mt-sm-0" cols="6" sm="">
                        <base-button
                            primary
                            block
                            icon="fa fa-filter"
                            label="Aplicar filtros"
                            :disabled="disable_button"
                            @click="formSubmit()"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </filter-page-slider>
    `
    )
};
