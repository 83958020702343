import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";
import BaseInformation from "../../../../components/atomos/BaseInformation?v=3";
import TutorialEnableRcs from "./TutorialEnableRcs";
import TextFieldPhoneValidator from "../../../../components/moleculas/TextFieldPhoneValidator?v=2";
import BaseAutocomplete from "../../../../components/atomos/BaseAutocomplete?v=2";
import ModalMensagemTeste from "./ModalMensagemTeste?v=2";

/**
 * Componente de modal com formulario, para envios de teste de template
 * @displayName ModalFormMensagemTeste
 * @requires {@link BaseDialog}
 * @requires {@link BaseInformation}
 * @requires {@link TutorialEnableRcs}
 * @requires {@link TextFieldPhoneValidator}
 * @requires {@link ModalMensagemTeste}
 * @requires {@link BaseAutocomplete}
 * @component
 * @category Page / Template RCS
 * @subcategory dialogs
 * @vue
 * @author Pedro Augusto Ribas <pedro.ribas@pgmais.com.br>
 * @vue/component 
 */
export default {
    name: "modal-form-teste-template",
    components: {
        TextFieldPhoneValidator,
        BaseDialog,
        BaseInformation,
        TutorialEnableRcs,
        ModalMensagemTeste,
        BaseAutocomplete
    },
    props: {
        /**
        * Propriedade para  controlar status da modal se ela esta ou nao ativa
        */
        controlModal: {
            type: Boolean,
            required: true
        },
        /**
         * Dados para o envio de teste de template
         */
        dataToSend: {
            type: Object,
            required: true
        },
        /**
         * ID da carteira a ser utilizada no envio
         */
        clientId: {
            type: Number,
            required: false,
            default: null
        }
    },
    data() {
        return {
            open_modal_tutorial_rcs: false,
            dialog: false,
            content: [],
            content_rules: [
                v => !!v || window.Vue.htmlEntities("Informe ao menos um n&uacute;mero de telefone"),
                v => !v || v.length > 0 || window.Vue.htmlEntities("Informe ao menos um n&uacute;mero de telefone"),
                v => {
                    let is_valid = true;

                    for (let i of v) {
                        if (!(/^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/.test(i))) {
                            is_valid = false;
                        }
                    }

                    return is_valid || Vue.htmlEntities("N&uacute;mero de telefone inv&aacute;lido");
                }
            ],
            disabled: false,
            modal_mensagem: false,
            telefones: "",
            all_clients: [],
            carteiras_permitidas: [],
            carteiras_rules: [
                v => !!v || "Escolha uma carteira",
            ],
            carteira: this.clientId,
            send_fallback: false,
            select_loading: true,
            button_action: {
                label: "Enviar mensagem",
                icon: "fa-paper-plane",
                loading: false
            },
            show_blacklist_message: false,
            enable_fallback: false,
            id_empresa: this.$cookie === undefined ? getCookie("id_empresa_selected") : this.$cookie.get("id_empresa_selected")
        };
    },
    computed: {
        ...window.Vuex.mapState("auth", {
            list_empresas: state => state.list_empresas
        }),
        disableButton() {
            for (let rule of this.content_rules) {
                if (rule(this.content) !== true) {
                    return true;
                }
            }

            if (!this.clientId) {
                for (let rule of this.carteiras_rules) {
                    if (rule(this.carteira) !== true) {
                        return true;
                    }
                }
            }

            return false;
        }
    },
    watch: {
        controlModal() {
            this.dialog = this.controlModal;
        },
        dialog() {
            /**
            *
            * E realizado um emmit de input sempre que o do valor do dialog eh alterado 
            * @event input
            * @vue
            * @property {Boolean} dialog  status da modal se ela esta ou nao ativa
            */
            this.$emit("input", this.dialog);
            this.content = null;
        },
        carteira() {
            if (!this.clientId) {
                let client = this.all_clients.find((c) => {
                    return parseInt(c.ID_EMPRESA_EPES) === parseInt(this.carteira);
                });

                let empresa = null;
                if (this.list_empresas) {
                    empresa = this.list_empresas.find(emp => {
                        return parseInt(emp.ID_EMPRESA_EPES) === parseInt(this.id_empresa);
                    });
                }

                if (this.dataToSend.fallback !== undefined && this.dataToSend.fallback && (client && client.CD_ROTA_API_AVULSO_EPES) || (empresa && empresa.CD_ROTA_API_AVULSO_EPES)) {
                    this.enable_fallback = true;
                } else {
                    this.enable_fallback = false;
                    this.send_fallback = false;
                }
            }
        },
        clientId() {
            this.carteira = this.clientId;
        }
    },
    created() {
        if (!this.clientId) {
            this.getCarteirasPermitidas();
        }
    },
    methods: {
        ...window.Vuex.mapActions("templatesrcs", ["ActionDoSendTemplateRcs", "ActionDoGetCarteirasPermitidas", "ActionDoGetCarteirasPermitidasAtivas"]),
        ...window.Vuex.mapActions("utils", ["ActionDoSendSmsSimples"]),
        /**
         * Funcao para ativar tutorial de como habilitar o rcs
         * @vue
         */
        activeTutorialRcs() {
            this.open_modal_tutorial_rcs = true;
        },
        /**
        * Funcao que faz um restart do formulario e fecha a modal, limpando o array de conteudos e     
        * @vue
        */
        closeModal() {
            this.button_action.loading = false;
            this.dialog = false;
            this.$refs.form_phone_validator.reset();
        },
        /**
        * Funcao que fica vinculada ao BaseDialog  
        * @vue
        */
        baseDialogFunction() {
            this.button_action.loading = true;
            if (this.$refs.form_phone_validator.validate()) {

                this.telefones = this.eliminateDuplicates(this.content.map((element) => {
                    return element;
                }));

                let statusAction = false;
                let body = {
                    id_carteira: this.carteira,
                    tipo: this.dataToSend.tipo,
                    telefones: this.telefones
                };

                if (this.dataToSend.mensagem !== undefined && this.dataToSend.mensagem) {
                    body["mensagem"] = this.dataToSend.mensagem;
                }

                if (this.dataToSend.suggestions !== undefined && this.dataToSend.suggestions) {
                    body["suggestions"] = this.dataToSend.suggestions;
                }

                if (this.dataToSend.url !== undefined && this.dataToSend.url) {
                    body["url"] = this.dataToSend.url;
                }

                if (this.dataToSend.parametros !== undefined && this.dataToSend.parametros) {

                    if (this.dataToSend.parametros.content.media.file.url === "variable") {
                        let send = this.dataToSend.parametros;
                        send.content.media.file.url = "https://gsvc.pgmais.com.br/comercial/RCS/imgURL.png";
                        body["parametros"] = send;
                    } else {
                        body["parametros"] = this.dataToSend.parametros;
                    }
                }

                if (this.dataToSend.id_template !== undefined && this.dataToSend.id_template) {
                    body["id_template"] = this.dataToSend.id_template;
                }

                if (this.dataToSend.campos !== undefined && this.dataToSend.campos) {
                    body["campos"] = this.dataToSend.campos;
                }

                this.ActionDoSendTemplateRcs(body).then(response => {
                    if (this.send_fallback && this.dataToSend.fallback !== undefined && this.dataToSend.fallback) {
                        this.ActionDoSendSmsSimples({
                            id_carteira: this.carteira,
                            mensagem: this.dataToSend.fallback,
                            telefones: this.telefones
                        }).then(response => {
                            if (response) {
                                this.dialog = false;
                                this.modal_mensagem = true;
                                this.telefones = this.telefones.join(",");
                                this.$refs.form_phone_validator.reset();
                            }

                            this.button_action.loading = false;
                        }).catch(error => {
                            let status = error.response.data.status_envio;
                            this.button_action.loading = false;

                            if (status.total_enviados === status.blacklist) {
                                this.show_blacklist_message = true;
                            } else {
                                this.dialog = false;
                            }
                        });
                    } else {
                        statusAction = response;
                        if (statusAction) {
                            this.dialog = false;
                            this.modal_mensagem = true;
                            this.telefones = this.telefones.join(",");
                            this.$refs.form_phone_validator.reset();
                        }

                        this.button_action.loading = false;
                    }
                }).catch(error => {
                    let status = error.response.data.status_envio;
                    this.button_action.loading = false;

                    if (status.total_enviados === status.blacklist) {
                        this.show_blacklist_message = true;
                    }
                });
            } else {
                this.button_action.loading = false;
            }
        },
        /**
         * 
         * Funcao para remover valores repetidos
         * @vue
         * @param {Array} arr 
         * @returns {Array} 
         */
        eliminateDuplicates(arr) {
            let out = [];
            let obj = {};

            for (let i = 0; i < arr.length; i++) {
                obj[arr[i]] = 0;
            }
            for (let i in obj) {
                out.push(i);
            }
            return out;
        },
        /**
        * Funcao que fica vinculada ao BaseDialog  
        * @vue
        */
        getCarteirasPermitidas() {
            this.select_loading = true;

            this.ActionDoGetCarteirasPermitidasAtivas().then(response => {
                this.all_clients = response.filter(empresa => {
                    return empresa.NM_PRODUTO_PAINEL_PPES && empresa.NM_PRODUTO_PAINEL_PPES.toUpperCase() === "RCS";
                });

                this.carteiras_permitidas = this.all_clients.map(rcsCompanies => {
                    return {
                        text: rcsCompanies.NM_EMPRESA_EPES,
                        value: rcsCompanies.ID_EMPRESA_EPES
                    };
                });

                this.select_loading = false;
            });
        }
    },
    template: (
        /*html*/
        `
        <base-dialog
            v-model="dialog"
            use-icon-close
            cancel
            title="Teste seu template"
            max-width="410px"
            cols-button-cancel="auto"
            :cols-button-action="undefined"
            :action-button="button_action"
            :disable-action-button="disableButton"
            @clickDialog="baseDialogFunction"
            @closeDialog="closeModal"
        >
            <v-form ref="form_phone_validator">
                <v-row no-gutters>
                    <v-col cols="12">
                        <span class="body-2 text-gray-900">Para receber uma mensagem de teste do template, informe at&eacute; 3 n&uacute;meros de telefone <b>Android</b> com o <b>RCS habilitado</b></span>
                    </v-col>
                    <v-col cols="12" class="mt-4">
                        <text-field-phone-validator
                            v-model="content"
                            hint="*Para mais de um telefone, separe por virgula(,), ponto-v&iacute;rgula (;) ou enter"
                            :label="'Informe um n&uacute;mero com DDD' | htmlEntities"
                            :limit="3"
                            :rules="content_rules"
                        />
                    </v-col>
                    <v-col v-if="show_blacklist_message" cols="12" class="mt-4">
                        <base-information
                            description="Todos os telefones informados est&atilde;o cadastrados na blacklist e nenhuma mensagem foi enviada"
                            icon="mdi-alert"
                            icon-color="error-500"
                            text-color="error-500"
                        />
                    </v-col>
                    <v-col v-if="!clientId" cols="12" class="mt-4">
                        <base-autocomplete
                            v-model="carteira"
                            :items="carteiras_permitidas"
                            label="Escolha uma carteira"
                            :loading="select_loading"
                            :rules="carteiras_rules"
                            no-data="Nenhuma carteira encontrada"
                        />
                    </v-col>
                    <v-col v-if="enable_fallback && !clientId" cols="12" class="mt-6">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <span class="body-2 text-gray-900">Deseja enviar um teste de mensagem alternativa tamb&eacute;m?</span>
                            </v-col>
                            <v-col cols="12" class="mt-4">
                                <v-checkbox
                                    v-model="send_fallback"
                                    :color="send_fallback ? 'primary-blue-500' : 'gray-300'"
                                    class="ma-0 pa-0"
                                    hide-details
                                    dense
                                >
                                    <template #label>
                                        <span class="body-2 text-gray-900">Sim, quero enviar</span>
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
            <base-information class="mt-6" description="Para que a mensagem chegue at&eacute; o aparelho, o RCS deve estar habilitado e a internet conectada. Cada disparo ter&aacute; o custo de um envio avulso pela carteira selecionada">
                <v-row no-gutters justify="center">
                    <v-col cols="auto" class="pb-2">
                        <a class="body-2 text-primary-blue-500 text-decoration-none" href="#" @click="activeTutorialRcs">Como habilitar o RCS?</a>
                    </v-col>
                </v-row>
            </base-information>
            <modal-mensagem-teste
                v-model="modal_mensagem"
                :control-modal="modal_mensagem"
                :phones-sent="telefones.toString()"
                @clickModal="modal_mensagem=false"
                @closeModal="modal_mensagem=false"
            />
            <tutorial-enable-rcs v-model="open_modal_tutorial_rcs" />
        </base-dialog>
        `
    )
};