export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                <g id="Btn-Cancel" transform="translate(-1335 -2098)">
                    <g id="Ico-Cancelar-Enabled" transform="translate(1339 2056)">
                        <g id="slash-circle" transform="translate(-1 45)">
                            <path id="Caminho_556" data-name="Caminho 556" d="M9.885,18.534A8.649,8.649,0,1,0,1.236,9.885,8.649,8.649,0,0,0,9.885,18.534Zm0,1.236A9.885,9.885,0,1,0,0,9.885,9.885,9.885,0,0,0,9.885,19.77Z" fill="currentColor" stroke="currentColor" stroke-width="0.5" fill-rule="evenodd"/>
                            <path id="Caminho_557" data-name="Caminho 557" d="M19.1,5.18a.618.618,0,0,1,0,.875L6.054,18.548a.619.619,0,0,1-.875-.875L18.222,5.18a.618.618,0,0,1,.875,0Z" transform="translate(-2.253 -2.254)" fill="currentColor" stroke="currentColor" stroke-width="0.5" fill-rule="evenodd"/>
                        </g>
                    </g>
                    <rect id="Retângulo_465" data-name="Retângulo 465" width="26" height="26" transform="translate(1335 2098)" fill="currentColor" opacity="0"/>
                </g>
            </svg>
        `
    )
};