import PreviewTemplate from "../../../../components/organismos/PreviewTemplate";
import CanalIconDescription from "../../../../components/moleculas/CanalIconDescription";
import BaseDialog from "../../../../components/moleculas/BaseDialog";
import DialogPreviewTemplate from "../../../../components/organismos/DialogPreviewTemplate";

/**
 * Componente para confirmacao de criacao de campanha
 * 
 * @requires {@link PreviewTemplate}
 * @requires {@link CanalIconDescription}
 * @requires {@link BaseDialog}
 * @requires {@link DialogPreviewTemplate}
 * 
 * @displayName DialogConfirmation
 * @category Page / Campaign
 * @subcategory dialogs
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        PreviewTemplate,
        CanalIconDescription,
        BaseDialog,
        DialogPreviewTemplate
    },
    props: {
        /**
         * Controla a exibicao do componente
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Tipo do produto ao qual a campanha esta vinculada
         */
        typeProduct: {
            type: String,
            required: false,
            default: "SMS"
        },
        /**
         * Nome da carteira vinculada a campanha
         */
        clientName: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Nome da campanha
         */
        campaignName: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Total de telefones validos
         */
        validPhones: {
            type: Number,
            required: false,
            default: 0
        },
        /**
         * Agendamento (imediato ou agendado)
         */
        scheduling: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Conteudo do template
         */
        templateContent: {
            type: Object,
            required: false,
            default() {
                return {};
            }
        },
        /**
         * Status de carregamento
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Progresso de envios
         */
        progress: {
            type: Number,
            required: false,
            default: 0
        },
        /**
         * Total de telefones
         */
        totalPhones: {
            type: Number,
            required: false,
            default: 0
        },
        /**
         * Telefones inv�lidos
         */
        invalidPhones: {
            type: Number,
            required: false,
            default: 0
        },
        /**
         * Telefones repetidos
         */
        repeatedPhones: {
            type: Number,
            required: false,
            default: 0
        },
        /**
         * Telefones na blacklist
         */
        blacklistPhones: {
            type: Number,
            required: false,
            default: 0
        },
        /**
         * Quantidade de envios Basic
         */
        basicSend: {
            type: Number,
            required: false,
            default: 0
        },
        /**
         * Quantidade de envios Single
         */
        singleSend: {
            type: Number,
            required: false,
            default: 0
        },
    },
    data() {
        return {
            show: this.value,
            action_button: {
                label: "Confirmar o envio",
                icon: "mdi-check-bold",
                primary: true,
                loading: this.loading,
                id: "confirmarEnvio"
            },
            ds_template: null,
            show_dialog_preview: false
        };
    },
    watch: {
        value() {
            this.show = this.value;
        },
        show() {
            this.$emit("input", this.show);
        },
        loading() {
            this.action_button.loading = this.loading;
        }
    },
    methods: {
        /**
         * Exibe o dialog de preview de template
         * @param {String} content_preview JSON no formato string
         * @vue
         */
        showDialogPreview(content_preview) {
            this.ds_template = content_preview;
            this.show_dialog_preview = true;
        },
        /**
         * Retorna em porcentagem
         * @param value
         * @returns {number}
         */
        getProgressPercentage(value) {
            return (value * 100) / this.totalPhones;
        }
    },
    template:
    /*html*/
    `
        <base-dialog
            v-model="show"
            title="Confirma&ccedil;&atilde;o de dados"
            cancel
            :action-button="action_button"
            use-icon-close
            max-width="900px"
            cols-button-action="auto"
            cols-button-cancel="auto"
            @clickDialog="$emit('confirm')"
        >
            <v-row no-gutters>
                <v-col cols="6">
                    <v-row no-gutters class="gap-4">
                        <v-col cols="12">
                            <canal-icon-description :product-name="typeProduct" />
                        </v-col>
                        <v-col cols="12">
                            <span class="body-2 text-gray-900">Cliente (carteira): <b>{{ clientName }}</b></span>
                        </v-col>
                        <v-col cols="12">
                            <span class="body-2 text-gray-900">Campanha: <b>{{ campaignName }}</b></span>
                        </v-col>
                      <v-col cols="12">
                        <span class="body-2 text-gray-900">Total importado: <b>{{ totalPhones }}</b></span>
                      </v-col>
                        <v-col cols="12">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <span class="body-2 text-gray-900">Agendamento</span>
                                </v-col>
                                <v-col cols="12" class="mt-2">
                                    <v-card elevation="0" class="border-1 border-gray-300 rounded-1 pa-4">
                                        <v-row no-gutters justify="center">
                                            <v-col cols="auto">
                                                <span class="body-2 text-gray-900" v-html="scheduling" />
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <span class="body-2 text-gray-900">Telefones v&aacute;lidos: <b>{{ validPhones }}</b></span>
                            <v-progress-linear
                                :value="getProgressPercentage(validPhones)"
                                color="success p-0"
                                background-color="grey lighten-2"
                                height="10"
                                rounded
                                :clickable="false"
                            ></v-progress-linear>
                        </v-col>
                        <v-col cols="12">
                            <span class="body-2 text-gray-900">Telefones inv&aacute;lidos: <b>{{ invalidPhones }}</b></span>
                            <v-progress-linear
                                :value="getProgressPercentage(invalidPhones)"
                                color="error p-0"
                                background-color="grey lighten-2"
                                height="10"
                                rounded
                                :clickable="false"
                            ></v-progress-linear>
                        </v-col>
                        <v-col cols="12">
                            <span class="body-2 text-gray-900">Telefones repetidos: <b>{{ repeatedPhones }}</b></span>
                            <v-progress-linear
                                :value="getProgressPercentage(repeatedPhones)"
                                color="warning"
                                background-color="grey lighten-2"
                                height="10"
                                rounded
                                :clickable="false"
                            ></v-progress-linear>
                        </v-col>
                        <v-col cols="12">
                            <span class="body-2 text-gray-900">Telefones na blacklist: <b>{{ blacklistPhones }}</b></span>
                            <v-progress-linear
                                :value="getProgressPercentage(blacklistPhones)"
                                color="info"
                                background-color="grey lighten-2"
                                height="10"
                                rounded
                                :clickable="false"
                            ></v-progress-linear>
                        </v-col>
                        <v-col cols="12">
                            <span class="body-2 text-gray-900">Envios at&eacute; 160 caracteres: <b>{{ basicSend }}</b></span>
                            <v-progress-linear
                                :value="getProgressPercentage(basicSend)"
                                color="info"
                                background-color="grey lighten-2"
                                height="10"
                                rounded
                                :clickable="false"
                            ></v-progress-linear>
                        </v-col>
                        <v-col cols="12">
                            <span class="body-2 text-gray-900">Envios com +160 caracteres: <b>{{ singleSend }}</b></span>
                            <v-progress-linear
                                :value="getProgressPercentage(singleSend)"
                                color="info"
                                background-color="grey lighten-2"
                                height="10"
                                rounded
                                :clickable="false"
                            ></v-progress-linear>
                            <span class="body-2 text-gray-900">Obs: Envios com + de 160 caracteres ser&atilde;o classificados como Single.</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <v-row no-gutters class="gap-4">
                        <preview-template
                            v-if="templateContent"
                            :type="templateContent.type"
                            :message="templateContent.message"
                            :list-suggestions="templateContent.list_suggestions"
                            :image="templateContent.image"
                            :card="templateContent.card"
                            :list-card="templateContent.list_card"
                            @showDialogPreview="showDialogPreview"
                        />
                    </v-row>
                </v-col>
                <dialog-preview-template v-model="show_dialog_preview" :ds-template="ds_template" />
            </v-row>
            <v-row no-gutters class="pt-2" v-if="progress > 0">
                <v-col cols="12">
                    <v-progress-linear
                        v-model="progress"
                        height="25"
                        color="dark-primary"
                        :clickable="false"
                    >
                        <strong>{{ Math.ceil(progress) }}%</strong>
                    </v-progress-linear>
                </v-col>  
            </v-row>
        </base-dialog>
    `
};