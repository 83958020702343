import NotFound from "../views/NotFound";
import RouterPage from "../views/RouterPage";
import { routes as documentacao } from "../views/documentacao";
import { routes as auth } from "../views/auth";
import { routes as template_rcs } from "../views/template-rcs";
import { routes as reports } from "../views/reports";
import { routes as dashboard } from "../views/dashboard";
import { routes as template } from "../views/template";
import { routes as avulsoapi } from "../views/avulsoapi";
import { routes as blacklist } from "../views/blacklist";
import { routes as gerenciar } from "../views/gerenciar";
import { routes as sessoes_rcs } from "../views/sessoes_rcs";
import { routes as campaign } from "../views/campaign";

export default [
    {
        path: "/v2",
        name: "root",
        component: RouterPage,
        children: [
            ...documentacao,
            ...auth,
            ...template_rcs,
            ...reports,
            ...dashboard,
            ...template,
            ...avulsoapi,
            ...blacklist,
            ...gerenciar,
            ...sessoes_rcs,
            ...campaign
        ]
    },
    {
        path: "*",
        name: "notFound",
        component: NotFound
    }

];