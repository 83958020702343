import FormResetPassword from "../../auth/components/FormResetPassword";

export default {
    components: {FormResetPassword},
    methods: {
        resetPassword(dados) {
            console.log(dados);
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center">
                <v-col cols="2">
                    <form-reset-password
                               @submitChangePassword="resetPassword" />
                </v-col>
            </v-row>
        `
    )
};