import BaseTextField from "../../../../components/atomos/BaseTextField?v=2";
import BaseNotify from "../../../../components/atomos/BaseNotify?v=2";
import BaseTooltip from "../../../../components/atomos/BaseTooltip?v=2";
import { BaseService } from "../../../../services/BaseService";

const base_service = new BaseService();

/**
 * Componente de formulario de finalizacao de relatorio
 * 
 * @requires {@link BaseTextField}
 * @requires {@link BaseNotify}
 * @requires {@link BaseTooltip}
 * @requires {@link BaseService}
 * 
 * @displayName FormReportCompletion
 * @category Page / Relatorios
 * @subcategory forms
 * @author David Nunes dos Santos <igor.santos@pgmais.com>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTextField,
        BaseNotify,
        BaseTooltip
    },
    props: {
        /**
         * Email a ser preenchido no text field de email
         */
        emailAttach: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            email: this.emailAttach,
            email_attach: this.emailAttach ? true : false,
            show_notify: false,
            notify_title: "",
            notify_message: "",
            data_emit: {
                form_valid: true,
                email_attach: null
            }
        };
    },
    computed: {
        emailRules() {
            return [
                v => !!v || "Digite um email",
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || window.Vue.htmlEntities("Digite um email v&aacute;lido")
            ];
        }
    },
    watch: {
        emailAttach() {
            if (this.emailAttach) {
                this.email = this.emailAttach;
                this.email_attach = true;
            } else {
                this.email = null;
                this.email_attach = false;
            }
        },
        email() {
            if (this.$refs.form.validate()) {
                this.data_emit = {
                    form_valid: true,
                    email_attach: this.email
                };
            } else if (this.email_attach) {
                this.data_emit = {
                    form_valid: false,
                    email_attach: null
                };
            } else {
                this.data_emit = {
                    form_valid: true,
                    email_attach: null
                };
            }
        },
        email_attach() {
            if (this.email_attach) {
                this.fillInputMail();
                this.notify_title = window.Vue.htmlEntities("Voc&ecirc; selecionou email");
                this.notify_message = window.Vue.htmlEntities("Ap&oacute;s gerar o relat&oacute;rio, voc&ecirc; vai receber o arquivo CSV por email.");
                this.show_notify = true;
                this.data_emit = {
                    form_valid: false,
                    email_attach: null
                };
            } else {
                this.email = null;
                this.data_emit = {
                    form_valid: true,
                    email_attach: null
                };
            }
        },
        data_emit() {
            /**
             * Emite os dados selecionados pelo usuario <pre>{ form_valid: Boolean, email_attach: String }</pre>
             * @event change
             * @vue
             */
            this.$emit("change", this.data_emit);
        }
    },
    methods: {
        ...window.Vuex.mapActions("utils", ["ActionDoGetUserInfo"]),
        /**
         * Reponsavel por redirecionar o usuario para a tela de listagem de relatorios
         */
        goToListReports() {
            window.location.href = base_service.baseDomainPhp() + "/Relatorios";
        },
        /**
         * Funcao para realizar o preenchimento do input de email do formulario
         * @vue
        */
        fillInputMail() {
            this.ActionDoGetUserInfo().then((response) => {
                this.email = response.DS_LOGIN_USES;
            }).catch((error) => {
                console.log(error);
            });
        }
    },
    template: (
        /*html*/
        `
            <v-form ref="form">
                <v-row>
                    <v-col cols="12">
                        <v-card elevation="0" height="120">
                            <base-tooltip text="Selecione essa op&ccedil;&atilde;o para receber seu arquivo por email">
                                <v-row align="center">
                                    <v-col cols="1">
                                        <v-icon color="dark-primary">$mailOutlineIcon</v-icon>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox v-model="email_attach" hide-details color="dark-primary" class="mt-0">
                                            <template #label>
                                                <span class="font-size-12">Quero receber o arquivo CSV por email</span>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </base-tooltip>
                            <v-row v-if="email_attach" align="center" class="mt-0">
                                <v-col offset="1">
                                    <base-text-field
                                        v-model="email"
                                        label="Email"
                                        type="email"
                                        :required="email_attach"
                                        :rules="emailRules"
                                    ></base-text-field>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card elevation="0" height="100">
                            <v-row align="center">
                                <v-col cols="1">
                                    <v-icon color="dark-primary">$downloadIcon</v-icon>
                                </v-col>
                                <v-col>
                                    <b class="font-size-13">Baixar manualmente</b>
                                </v-col>
                            </v-row>
                            <v-row align="center" class="mt-0">
                                <v-col offset="1">
                                    <span class="font-size-11">O relat&oacute;rio gerado estar&aacute; dispon&iacute;vel para download na p&aacute;gina <a href="#" @click="goToListReports" class="text-dark-primary">Meus relat&oacute;rios</a> em at&eacute; 24 horas ap&oacute;s a solicita&ccedil;&atilde;o.</span>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <base-notify
                    v-model="show_notify"
                    :title="notify_title"
                    :message="notify_message"
                    type="notification"
                ></base-notify>
            </v-form>
        `
    )
};
