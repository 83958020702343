import BaseTooltip from "../../../components/atomos/BaseTooltip";

/**
 * Componente para configura��o de conteudo da template
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName ConfigContent
 * @category Page / Template RCS
 * @author David dos Satnos Andrade <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Nome do icone
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * Texto de instrucao
         */
        text: {
            type: String,
            required: true
        },
        /**
         * Texto a ser exibido quando em estado de ativo
         */
        textOnActive: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Exibe o icone de fechar o componente
         */
        showClose: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Valor do v-model, indica o estado de ativo
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Define a altura
         */
        height: {
            type: [String, Number],
            required: false,
            default: undefined
        },
        /**
         * Define a largura
         */
        width: {
            type: [String, Number],
            required: false,
            default: undefined
        }
    },
    data() {
        return {
            hover: false
        };
    },
    computed: {
        cardColor() {
            let color = "transparent";

            if (this.value) {
                color = "primary-blue-500";
            } else if (this.hover) {
                color = "secondary-blue-100";
            }

            return color;
        },
        borderStyle() {
            let color = "border-gray-500";
            let style = "border-dashed-1";

            if (this.value) {
                color = "border-primary-blue-500";
                style = "border-2";
            } else if (this.hover) {
                color = "border-primary-blue-500";
                style = "border-1";
            }

            return `${style} ${color}`;
        },
        contentColor() {
            let color = "gray-800";

            if (this.value) {
                color = "gray-0";
            } else if (this.hover) {
                color = "primary-blue-500";
            }

            return color;
        },
        contentText() {
            if (this.value && this.textOnActive) {
                return this.textOnActive;
            } else {
                return this.text;
            }
        }
    },
    methods: {
        /**
         * Chamado quando o componente eh clicado
         */
        click() {
            this.$emit("input", !this.value);
            this.$emit("click");
        },
        /**
         * Chamado quando o botao de fechar eh clicado
         */
        closeClick() {
            this.$emit("close");
        }
    },
    template: (
        /*html*/
        `
            <v-hover v-model="hover">
                <v-card elevation="0" :class="'bg-' + cardColor + ' ' + borderStyle + ' rounded-0 fill-height'" @click="click" :height="height" :width="width">
                    <v-row align="center" class="fill-height">
                        <v-col>
                            <base-tooltip text="Remover bloco">
                                <v-btn v-if="showClose" small icon color="gray-300" absolute right top class="mt-n3 mr-n3" @click="closeClick" v-bind:data-v-step="'closeContentBlockConfig'">
                                    <v-icon color="gray-300">mdi-close-circle</v-icon>
                                </v-btn>
                            </base-tooltip>
                            <v-row no-gutters justify="center">
                                <v-col cols="auto">
                                    <v-icon :color="contentColor" large>{{ icon }}</v-icon>
                                </v-col>
                            </v-row>
                            <v-row no-gutters justify="center">
                                <v-col cols="auto" class="text-center">
                                    <span :class="'body-2 text-' + contentColor" v-html="contentText"></span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-hover>
        `
    )
};