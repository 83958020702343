/**
 * Classe utilizada para representar itens do tipo header para um DataTable do vuetify
 */
export class DataTableHeaderClass {
    text;
    value;
    align;
    sortable;
    filterable;
    groupable;
    divider;
    class;
    cellClass;
    width;
    filter;
    sort;
    visibility;
    disabled;
    tooltipText;
    stepTutorial;

    /**
     * @param {string} text
     * @param {string} value
     * @param {string} align
     * @param {boolean} sortable
     * @param {boolean} filterable
     * @param {boolean} groupable
     * @param {boolean} divider
     * @param {string} class
     * @param {string} cellClass
     * @param {string} width
     * @param {string} filter
     * @param {string} sort
     * @param {string} visibility
     * @param {boolean} disabled
     * @param {string} tooltipText
     * @param {string} stepTutorial
     */
    constructor({
        text,
        value,
        align = "start",
        sortable = true,
        filterable = false,
        groupable = false,
        divider = false,
        className = "",
        cellClass = "",
        width = "auto",
        filter = null,
        sort = null,
        visibility = "",
        disabled = false,
        tooltipText = null,
        stepTutorial = null
    }) {
        this.text = text;
        this.value = value;
        this.align = align;
        this.sortable = sortable;
        this.filterable = filterable;
        this.groupable = groupable;
        this.divider = divider;
        this.class = className;
        this.cellClass = cellClass;
        this.width = width;
        this.filter = filter;
        this.sort = sort;
        this.visibility = visibility;
        this.disabled = disabled;
        this.tooltipText = tooltipText;
        this.stepTutorial = stepTutorial;
    }

    /**
     * Pega o valor de text
     * @return {string} text do objeto
     */
    get text() {
        return this.text;
    }

    /**
     * Define um novo valor para text
     * @param {string} text - Novo text do objeto
     */
    set text(text) {
        this.text = text;
    }

    /**
     * Pega o valor de value
     * @return {string} value do objeto
     */
    get value() {
        return this.value;
    }

    /**
     * Define um novo valor para value
     * @param {string} value - Novo value do objeto
     */
    set value(value) {
        this.value = value;
    }

    /**
     * Pega o valor de align
     * @return {string} align do objeto
     */
    get align() {
        return this.align;
    }

    /**
     * Define um novo valor para align
     * @param {string} align - Novo align do objeto
     */
    set align(align) {
        this.align = align;
    }

    /**
     * Pega o valor de sortable
     * @return {boolean} sortable do objeto
     */
    get sortable() {
        return this.sortable;
    }

    /**
     * Define um novo valor para sortable
     * @param {boolean} sortable - Novo sortable do objeto
     */
    set sortable(sortable) {
        this.sortable = sortable;
    }

    /**
     * Pega o valor de filterable
     * @return {boolean} filterable do objeto
     */
    get filterable() {
        return this.filterable;
    }

    /**
     * Define um novo valor para filterable
     * @param {boolean} filterable - Novo filterable do objeto
     */
    set filterable(filterable) {
        this.filterable = filterable;
    }

    /**
     * Pega o valor de groupable
     * @return {boolean} groupable do objeto
     */
    get groupable() {
        return this.groupable;
    }

    /**
     * Define um novo valor para groupable
     * @param {boolean} groupable - Novo groupable do objeto
     */
    set groupable(groupable) {
        this.groupable = groupable;
    }

    /**
     * Pega o valor de divider
     * @return {boolean} divider do objeto
     */
    get divider() {
        return this.divider;
    }

    /**
     * Define um novo valor para divider
     * @param {boolean} divider - Novo divider do objeto
     */
    set divider(divider) {
        this.divider = divider;
    }

    /**
     * Pega o valor de class
     * @return {string} class do objeto
     */
    get className() {
        return this.class;
    }

    /**
     * Define um novo valor para class
     * @param {string} class - Novo class do objeto
     */
    set className(className) {
        this.class = className;
    }

    /**
     * Pega o valor de cellClass
     * @return {string} cellClass do objeto
     */
    get cellClass() {
        return this.cellClass;
    }

    /**
     * Define um novo valor para cellClass
     * @param {string} cellClass - Novo cellClass do objeto
     */
    set cellClass(cellClass) {
        this.cellClass = cellClass;
    }

    /**
     * Pega o valor de width
     * @return {string} width do objeto
     */
    get width() {
        return this.width;
    }

    /**
     * Define um novo valor para width
     * @param {string} width - Novo width do objeto
     */
    set width(width) {
        this.width = width;
    }

    /**
     * Pega o valor de filter
     * @return {string} filter do objeto
     */
    get filter() {
        return this.filter;
    }

    /**
     * Define um novo valor para filter
     * @param {string} filter - Novo filter do objeto
     */
    set filter(filter) {
        this.filter = filter;
    }

    /**
     * Pega o valor de sort
     * @return {string} sort do objeto
     */
    get sort() {
        return this.sort;
    }

    /**
     * Define um novo valor para sort
     * @param {string} sort - Novo sort do objeto
     */
    set sort(sort) {
        this.sort = sort;
    }

    /**
     * Pega o valor de visibility
     * @return {string} visibility do objeto
     */
    get visibility() {
        return this.visibility;
    }

    /**
     * Define um novo valor para visibility
     * @param {string} visibility - Novo visibility do objeto
     */
    set visibility(visibility) {
        this.visibility = visibility;
    }

    /**
     * Pega o valor de disabled
     * @return {boolean} disabled do objeto
     */
    get disabled() {
        return this.disabled;
    }

    /**
     * Define um novo valor para disabled
     * @param {boolean} disabled - Novo disabled do objeto
     */
    set disabled(disabled) {
        this.disabled = disabled;
    }

    /**
     * Pega o valor de tooltipText
     * @return {string} tooltipText do objeto
     */
    get tooltipText() {
        return this.tooltipText;
    }

    /**
     * Define um novo valor para tooltipText
     * @param {string} tooltipText - Novo tooltipText do objeto
     */
    set tooltipText(tooltipText) {
        this.tooltipText = tooltipText;
    }

    /**
     * Pega o valor de stepTutorial
     * @return {string} stepTutorial do objeto
     */
    get stepTutorial() {
        return this.stepTutorial;
    }

    /**
     * Define um novo valor para stepTutorial
     * @param {string} stepTutorial - Novo stepTutorial do objeto
     */
    set stepTutorial(stepTutorial) {
        this.stepTutorial = stepTutorial;
    }

    /**
     * Retorna um objeto com todos os dados
     * @return {object}
     */
    get all() {
        return {
            text: this.text,
            value: this.value,
            align: this.align,
            sortable: this.sortable,
            filterable: this.filterable,
            groupable: this.groupable,
            divider: this.divider,
            class: this.class,
            cellClass: this.cellClass,
            width: this.width,
            filter: this.filter,
            sort: this.sort,
            disabled: this.disabled,
            tooltipText: this.tooltipText,
            stepTutorial: this.stepTutorial
        };
    }

}