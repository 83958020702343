import RouterPage from "../RouterPage";
import CadastroTemplatePage from "./CadastroTemplatePage";

export default [
    {
        path: "template-rcs",
        name: "templateRcs",
        component: RouterPage,
        children: [
            {
                path: "cadastro-template",
                name: "cadastroTemplate",
                component: CadastroTemplatePage
            }
        ]
    }
];