import ButtonFormStep from "../../../components/atomos/ButtonFormStep";

export default ({
    components: {
        "button-form-step": ButtonFormStep
    },
    data() {
        return {
            teste: "<h1>Teste</h1>",
            name_slot: "step-1"
        };
    },
    methods: {
        /**
         * funcao que fica vinculada ao click do botao, apenas se o botao nao estiver desabilitado
         */
        clickButton: function () {
            console.log("teste");
        }
    },
    template: (
        /*html*/
        `<div class="container">  
        <v-row class="mb-3">
            <v-col md="12" class="mb-2 col">
                <button-form-step  :step="1" title="Algum titulo" :active="true" :line="true">
                    <template  v-slot:[name_slot]>
                        Conteudo do slotConteudo do slotConteudo do slotConteudo do slot<br>Conteudo do slotConteudo do slotConteudo do slotConteudo do slot<br>
                        Conteudo do slotConteudo do slotConteudo do slotConteudo do slot<br>Conteudo do slotConteudo do slotConteudo do slotConteudo do slot<br>
                    </template>
                </button-form-step>
            </v-col>
            <v-col md="12" class="mb-2 col">
                <button-form-step @click="clickButton":step="2" title="Algum titulo" :ready="true">
                    <template v-slot:step-2>TESTE</template>
                </button-form-step>
            </v-col>
            <v-col md="12" class="mb-2 col">
                <button-form-step :step="3" title="Algum titulo" :disabled="true">
                    <span>Conteudo do slot</span>
                </button-form-step>
            </v-col>
        </v-row>
        <v-row>
            <div class="col">
                <h5>Button Step</h5>
            </div>
        </v-row>
        <v-row class="mb-3">
            <v-col cols="12" class="mb-2">
                <button-form-step :step="1" title="Algum titulo">
                    <span>Conteudo do slot</span>
                </button-form-step>
            </v-col>
        </v-row>
        <v-row>
            <div class="col">
                <h5>Button Step ready</h5>
            </div>
        </v-row>
        <v-row class="mb-3">
            <v-col class="mb-2">
                <button-form-step :step="1" title="Algum titulo" :ready="true">
                    <span>Conteudo do slot</span>
                </button-form-step>
            </v-col>
        </v-row>
        <v-row>
            <div class="col">
                <h5>Button Step Ativo</h5>
            </div>
        </v-row>
        <v-row class="mb-3">
            <v-col class="mb-2">
                <button-form-step :step="1" title="Algum titulo" :active="true">
                    <span>Conteudo do slot</span>
                </button-form-step>
            </v-col>
        </v-row>
        <v-row>
            <div class="col">
                <h5>Button Step Desabilitado</h5>
            </div>
        </v-row>
        <v-row class="mb-3">
            <v-col class="mb-2">
                <button-form-step :step="1" title="Algum titulo" :disabled="true">
                    <span>Conteudo do slot</span>
                </button-form-step>
            </v-col>
        </v-row>
        </div>`)
});