import DialogRemoveFallback from "../../template-rcs/components/dialogs/DialogRemoveFallback";

export default {
    components: {
        DialogRemoveFallback
    },
    data() {
        return {
            show: false
        };
    },
    methods: {
        clear() {
            console.log("Limpar fallback");
        }
    },
    template: (
        /*html*/
        `
            <v-row justify="center" class="bg-background">
                <v-col cols="12" md="3">
                    <v-row justify="center" class="bg-background">
                        <v-col cols="12" md="3">
                            <v-btn @click="show = true">Exibir dialog</v-btn>
                        </v-col>
                    </v-row>
                    <v-row justify="center" class="bg-background">
                        <v-col cols="12" md="3">
                            <dialog-remove-fallback v-model="show" @clearFallback="clear" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        `
    )
};