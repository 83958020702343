/**
 * @property {string} image
 * @property {string} title
 * @property {string} text
 */
export class CardSliderClass {
    image
    title
    text
    vertical

    constructor({image, title, text, vertical = false}) {
        this.image = image;
        this.title = title;
        this.text = text;
        this.vertical = vertical;
    }
}