import BaseBackgroundIcon from "../atomos/BaseBackgroundIcon?v=2";
import BaseTooltip from "../atomos/BaseTooltip?v=2";

/**
 * Componente de uso basico para um accordion
 *
 * @displayName AccordionDefault
 * @component
 * @category Moleculas
 * @requires {@link BaseBackgroundIcon}
 * @requires {@link BaseTooltip}
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseBackgroundIcon,
        BaseTooltip
    },
    props: {
        /**
         * Array de itens para ser utilizado no accordion, os itens devem ser uma instancia de {@link ItemAccordionClass}
         */
        items: {
            type: Array,
            required: true
        },
        /**
         * Item a ser marcado como ativo
         */
        activeItem: {
            type: [String, Number],
            required: false,
            default: null
        }
    },
    data() {
        return {
            panel_active: this.activeItem
        };
    },
    watch: {
        activeItem() {
            this.panel_active = this.activeItem;
        },
        panel_active() {
            /**
             * Evento click_item disparado quando um item do accordion for clicado
             * 
             * @property {Object} param0 {item, index}
             * @event click_item
             * @vue
            */
            this.$emit("click_item", {
                item: this.items[this.panel_active],
                index: this.panel_active
            });
        }
    },
    mounted() {
        for (let index in this.items) {
            if (this.items[index].active) {
                this.panel_active = parseInt(index);
                break;
            }
        }
    },
    template:
        /*html*/
        `
            <v-expansion-panels flat accordion v-model="panel_active" class="border border-gray-300 elevation-0">
                <v-expansion-panel v-for="(item, i) in items" :key="i" :readonly="item.disabled" class="border border-top-0 border-gray-300">
                    <v-expansion-panel-header class="px-4 py-2 ma-0" :data-v-step="'accordion-' + item.slot_name">
                        <base-tooltip 
                        :text="item.tooltip" 
                        :open-delay="500"
                        >
                            <v-row align="center" no-gutters>
                                <v-col cols="auto" class="pr-3">
                                    <base-background-icon 
                                        :icon-name="item.icon" 
                                        :background-color="item.disabled ? 'gray-700' : (panel_active !== i ? 'transparent' : item.icon_bg_color)" 
                                        :icon-color="panel_active !== i ? 'gray-700' : item.icon_text_color"
                                        :circle="false"
                                        :size="30"
                                    ></base-background-icon>
                                </v-col>
                                <v-col :class="item.disabled || panel_active !== i ? 'text-gray-700' : ''">
                                    <span v-html="item.title"></span>
                                </v-col>
                            </v-row>
                        </base-tooltip>
                        <template v-slot:actions>
                            <base-tooltip :text="panel_active === i ? 'Esconder' : 'Expandir'">
                                <v-icon small :color="item.disabled || !item.complete ? 'gray-700' : 'green-900'">fa-chevron-circle-down</v-icon>
                            </base-tooltip>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="bg-gray-30">
                        <v-card elevation="0" :height="536 - (items.length * 46)" class="bg-transparent pa-6 overflow-y-auto overflow-x-hidden">
                            <slot :name="item.slot_name"></slot>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        `
};
