/**
 * Classe que representa um item CardRcsClass
 */
export class CardRcsClass {
    id
    image
    title
    media
    description
    suggestionButtons
    suggestionResponses
    orientation
    alignment
    image_height

    /**
     * CardRcsClass constructor
     *
     * @param {id} Number
     * @param {image} String
     * @param {title} String
     * @param {media} String
     * @param {description} String
     * @param {suggestionButtons} Array
     * @param {suggestionResponses} Array
     * @param {orientation} String - HORIZONTAL ou VERTICAL
     * @param {alignment} String - RIGHT ou LEFT
     * @param {image_height} String - TALL, MEDIUM ou SHORT
     */
    constructor({
        id = null,
        image = null,
        title = null,
        media = null,
        description = null,
        suggestionButtons = null,
        suggestionResponses = null,
        orientation = "VERTICAL",
        alignment = "LEFT",
        image_height = "TALL"
    }) {
        this.id = id ? id : Math.random();
        this.image = image;
        this.title = title;
        this.media = media;
        this.description = description;
        this.suggestionButtons = suggestionButtons;
        this.suggestionResponses = suggestionResponses;
        this.orientation = orientation;
        this.alignment = alignment;
        this.image_height = image_height;
    }
}