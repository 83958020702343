import BaseTooltip from "./BaseTooltip?v=2";
import { ProgressBarValueClass } from "../../classes/ProgressBarValueClass";

/**
 * Componente basico de barra de progresso
 * <iframe src="http://localhost:8080/v2/documentacao/atomos/base-progress-bar-usage" style="width:100%; height: 215px;"></iframe>
 * 
 * @requires {@link BaseTooltip}
 * @requires {@link ProgressBarValueClass}
 * 
 * @displayName BaseProgressBar
 * @component
 * @vue
 * @category Atomos
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Valor total que representa 100%
         */
        total: {
            type: [Number, String],
            required: true
        },
        /**
         * Texto a ser exibido acima da barra de progresso
         */
        label: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Lista de valores que completam a barra de progresso, cada item deve ser uma instancia de {@link ProgressBarValueClass}
         */
        listValues: {
            type: Array,
            required: true,
            validator(data) {
                let is_valid = true;

                for (const element of data) {
                    if (!(element instanceof ProgressBarValueClass)) {
                        is_valid = false;
                        break;
                    }
                }

                return is_valid;
            }
        },
        /**
         * Exibir tooltip no title
         */
        showTooltipTitle: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        title() {
            let title = "";

            if (this.label) {
                title = window.Vue.htmlEntities(this.label);
            } else {
                let names = [];
                for (let item of this.listValues) {
                    if (item.color == "gray-300") {
                        continue;
                    }

                    let dividerPercent = " <strong>" + this.getPercent(item.total).toFixed(0) + "%</strong>";
                    names.push(item.total.toLocaleString() + window.Vue.htmlEntities(dividerPercent));
                }

                title = names.join(window.Vue.htmlEntities("<span class='gray-300--text caption'> | </span>"));
            }

            return title;
        },
        tooltipTitle() {
            let tooltip = "";
            for (let item of this.listValues) {
                tooltip += `${item.name}: ${item.total.toLocaleString()} | ` + this.getPercent(item.total).toFixed(2) + "%<br>";
            }
            return tooltip;
        }
    },
    methods: {
        /**
         * Retorna o valor da porcentagem ocupada de acordo com o valor informado em relacao ao valor total
         * @example 
         * // Se o valor total for 1000, retorna 50
         * getPercent(500)
         * @param {Number} value Valor ocupado
         * @returns {Number} Valor da porcentagem
         * @vue
         */
        getPercent(value) {
            return value == 0 ? value : (value * 100) / this.total;
        },
        /**
         * Retorna o conteudo a ser apresentado no tooltip
         * @example 
         * // Se o valor total for 1000, retorna: 'Item 1: 500 | 50%'
         * getTooltipContent(new ProgressBarValueClass({ name: "Item 1", total: 500, color: "dark-primary" }))
         * @param {ProgressBarValueClass} item Valor de um item na barra de progresso
         * @returns {String} Texto do tooltip
         * @vue
         */
        getTooltipContent(item) {
            if (item.name) {
                return `${item.name}: ${item.total.toLocaleString()} | ` + this.getPercent(item.total).toFixed(2) + "%";
            } else {
                return null;
            }
        }
    },
    template: (
        /*html*/
        `
            <v-row no-gutters>
                <v-col cols="12">
                    <base-tooltip :disabled="!showTooltipTitle" :text="tooltipTitle">
                        <span class="caption text-gray-800 cursor-default" v-html="title" />
                    </base-tooltip>
                </v-col>
                <v-col cols="12">
                    <v-card
                        class="bg-gray-300 d-flex overflow-hidden rounded-4"
                        elevation="0"
                        height="8px"
                        min-width="80px"
                        width="100%"
                    >
                        <base-tooltip
                            v-for="(item, i) in listValues"
                            :key="i"
                            :disabled="!getTooltipContent(item)"
                            :text="getTooltipContent(item)"
                        >
                            <v-card
                                :class="'rounded-0 bg-' + item.color"
                                :width="getPercent(item.total) + '%'"
                                elevation="0"
                                height="8px"
                            />
                        </base-tooltip>
                    </v-card>
                </v-col>
            </v-row>
        `
    )
};