import AreaAction from "../../../components/atomos/AreaAction";
import ProductButton from "../../../components/atomos/ProductButton";

/**
 * Componente de formulario de login
 * 
 * @component
 * @category Page / Auth
 * @requires {@link area-action.html|AreaAction}
 * @requires {@link product-button.html|ProductButton}
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue
 */
export default {
    name: "select-product-box",
    components: {
        AreaAction,
        ProductButton
    },
    data() {
        return {
            products: {
                "rcs": {
                    id: "rcs",
                    product_icon: "$rcsProductIcon",
                    title: "RCS",
                    description: "O <b>RCS</b> &eacute; um mensageiro instant&acirc;neo, moderno e articulado especificamente para tornar a experi&ecirc;ncia de di&aacute;logo mais rica e din&acirc;mica",
                    disable_button: true,
                    color_button: "light-grey",
                    icon_color: "white",
                    description_color: "light-grey",
                    show_message_block: true
                },
                "sms": {
                    id: "sms",
                    product_icon: "$smsProductIcon",
                    title: "SMS",
                    description: "O <b>SMS</b> &eacute; o canal de comunica&ccedil;&atilde;o de melhor custo-benef&iacute;cio dispon&iacute;vel na plataforma de envio de mensagens da PGMais.",
                    disable_button: true,
                    color_button: "light-grey",
                    icon_color: "black",
                    description_color: "light-grey",
                    show_message_block: true
                }
            },
            buttons: {},
            show_loader: true
        };
    },
    props: {
        /**
         * Define o valor da barra de progresso
         */
        progress_val: {
            type: Number,
            required: false,
            default: 0
        },
        /**
         * Define se a barra de progresso deve ter o status de indeterminado
         */
        indeterminate: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica se a barra de progresso deve ser exibida
         */
        show_progress: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        ...window.Vuex.mapState("auth", {
            user: state => state.user,
            list_empresas: state => state.list_empresas
        }),
        title_area() {
            return "Bem-vindo <b>" + this.user.NM_USUARIO_USES + "</b>, escolha um canal para come&ccedil;ar!";
        },
    },
    methods: {
        ...window.Vuex.mapActions("auth", ["ActionDoGetMinhasEmpresas"]),
        /**
         * Indica que um produto foi selecionado
         * @vue
         */
        selectProduct: function (type_product) {
            /**
             * Evento de produto selecionado
             * 
             * @property {object} product Dados do produto selecionado
             * 
             * @event productSelected
             * @vue
             */
            this.$emit("productSelected", this.products[type_product]);
        },
        /**
         * Indica que o bot�o de retorno para a sele��o de produtos foi clicado
         * @vue
         */
        backSelectProduct() {
            /**
             * Evento de retorno para o select product
             * 
             * @event selectProduct
             * @vue
             */
            this.$emit("selectProduct");
        },
        /**
         * Verifica quais produtos o usu�rio tem acesso
         * @vue
         */
        checkProductsUser() {
            this.list_empresas.forEach(empresa => {
                switch (empresa.NM_PRODUTO_PAINEL_PPES.toLowerCase()) {
                case "sms":
                    this.products["sms"].disable_button = false;
                    this.products["sms"].color_button = "sms";
                    this.products["sms"].description_color = "black";
                    this.products["sms"].show_message_block = false;
                    break;
                case "rcs":
                    this.products["rcs"].disable_button = false;
                    this.products["rcs"].color_button = "rcs";
                    this.products["rcs"].description_color = "black";
                    this.products["rcs"].show_message_block = false;
                    break;
                }
            });

            this.buttons = Object.values(this.products).filter(function (item) {
                return item;
            });

            this.show_loader = false;
        }
    },
    mounted() {
        this.ActionDoGetMinhasEmpresas().then(() => {
            this.checkProductsUser();
        }).catch(error => {
            console.error(error);
            throw error;
        });
    },
    template: (
        /*html*/
        `
            <area-action :title="title_area">
                <v-row class="px-lg-15" justify="space-around">
                    <v-col cols="12" md="auto" class="px-md-8 py-5 d-flex justify-center" v-for="button in buttons" :key="button.product_icon">
                        <product-button
                            :id="button.id"
                            :product-icon="button.product_icon"
                            :title="button.title" 
                            :description="button.description" 
                            :disable-button="button.disable_button" 
                            :color-button="button.color_button" 
                            :center-button="true"
                            :description-color="button.description_color"
                            :show-message-block="button.show_message_block"
                            :icon-color="button.icon_color"
                            @click-button-voltar="backSelectProduct"
                            @click="selectProduct">
                        </product-button>
                    </v-col>
                    <v-col cols="12" md="auto" class="px-10 py-10 py-md-0 d-flex justify-center" v-if="show_loader">
                        <v-skeleton-loader type="image" height="300" width="310" class="rounded-3"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12" md="auto" class="px-10 py-10 py-md-0 d-flex justify-center" v-if="show_loader">
                        <v-skeleton-loader type="image" height="300" width="310" class="rounded-3"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-progress-linear
                    v-model="progress_val"
                    :active="show_progress"
                    :indeterminate="indeterminate"
                    absolute
                    bottom
                    color="dark-primary"
                    height="7"
                ></v-progress-linear>
            </area-action>
        `
    )
};