export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Pausar-Enabled" transform="translate(5 -41)">
                    <rect id="Retângulo_336" data-name="Retângulo 336" width="28" height="28" transform="translate(-5 41)" fill="currentColor" opacity="0"/>
                    <g id="play-fill" transform="translate(-8 38.125)" fill="none">
                        <path d="M24.417,18.423,11.5,25.916A1.662,1.662,0,0,1,9,24.5V9.515A1.661,1.661,0,0,1,11.5,8.1L24.417,15.6a1.627,1.627,0,0,1,0,2.827Z" stroke="none"/>
                        <path d="M 10.66538333892822 24.64305686950684 C 10.6940336227417 24.64305686950684 10.72101402282715 24.63515663146973 10.74951362609863 24.6186466217041 L 23.67382431030273 17.11976623535156 C 23.71344375610352 17.09716606140137 23.73806381225586 17.05478668212891 23.73806381225586 17.0091667175293 C 23.73806381225586 16.96355628967285 23.71344375610352 16.92117691040039 23.67382431030273 16.8985767364502 L 23.66425323486328 16.89306640625 L 10.74959373474121 9.399736404418945 C 10.71991348266602 9.382516860961914 10.69270420074463 9.374496459960938 10.66395378112793 9.374496459960938 C 10.66231346130371 9.374496459960938 10.50000381469727 9.380526542663574 10.50000381469727 9.514886856079102 L 10.50000381469727 24.50164604187012 C 10.50000381469727 24.63697624206543 10.66373348236084 24.64305686950684 10.66538333892822 24.64305686950684 M 10.66538238525391 26.14305877685547 C 9.801135063171387 26.14305877685547 9.000003814697266 25.46783256530762 9.000003814697266 24.50164604187012 L 9.000003814697266 9.514886856079102 C 9.000003814697266 8.236676216125488 10.40450096130371 7.465415477752686 11.50230407714844 8.102266311645508 L 24.41704368591309 15.59564685821533 C 24.92468452453613 15.88521671295166 25.23806381225586 16.42474555969238 25.23806381225586 17.0091667175293 C 25.23806381225586 17.59358596801758 24.92468452453613 18.13312721252441 24.41704368591309 18.42269706726074 L 11.50230407714844 25.91606712341309 C 11.23358821868896 26.07176780700684 10.9461030960083 26.14305877685547 10.66538238525391 26.14305877685547 Z" stroke="none" fill="currentColor"/>
                    </g>
                </g>
            </svg>                    
        `
    )
};