import BaseTooltip from "./BaseTooltip?v=2";

/**
 * Componente de botao basico para o projeto
 * 
 * @requires {@link BaseTooltip}
 * 
 * @displayName BaseButton
 * @component
 * @category Atomos
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseTooltip
    },
    props: {
        /**
         * Nome do icone a ser apresentado no botao
         */
        icon: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Label a ser exibida no bptap
         */
        label: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Cor da label e do icone
         */
        contentColor: {
            type: String,
            required: false,
            default: "gray-0"
        },
        /**
         * Cor do background do botao
         */
        color: {
            type: String,
            required: false,
            default: "primary-blue-500"
        },
        /**
         * Indica se o botao deve ter o estado de desabilitado
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica se o icone deve ser apresentado em left, do contratio fica em right
         */
        left: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica se o botao deve ocupar todo o espaco
         */
        block: {
            type: Boolean,
            required: false,
            default: true
        },
        /**
         * Indica que o botao deve ser do tamanho x-large
         */
        xLarge: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Propriedade para personalizar a borda do componenete
         */
        border: {
            type: String,
            required: false,
            default: ""
        },
        /**
         * Propriedade para largura maxima do botao
         */
        width: {
            type: [String, Number],
            required: false,
            default: undefined
        },
        /**
         * Indica que o botao deve ter o tamanho small
         */
        small: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica se o botao deve ter o estado de loading
         */
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Texto do tooltip do campo
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Orientacao do tooltip
         */
        tooltipOrientation: {
            type: String,
            required: false,
            default: "top"
        },
        /**
         * Indica que somente o icone sera exibido
         */
        onlyIcon: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica o estado de ativo
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica o estado de danger
         */
        danger: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica o estado de primary
         */
        primary: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica o estado de secondary
         */
        secondary: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Indica o estado de tertiary
         */
        tertiary: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Quantidade de colunas que o botao vai ocupar
         */
        cols: {
            type: [String, Number],
            required: false,
            default: undefined
        },
        /**
         * ID do button
         */
        id: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            hover: false,
            active: this.value
        };
    },
    computed: {
        buttonColor() {
            let color = this.color;

            if (this.primary) {
                color = this.danger ? "error-500" : "primary-blue-500";

                if (this.disabled || this.loading) {
                    color = "transparent";
                } else if (this.active) {
                    color = this.danger ? "gray-900" : "tertiary-blue-900";
                } else if (this.hover) {
                    color = this.danger ? "error-900" : "primary-blue-900";
                }

                color += " pg-primary";
            } else if (this.secondary) {
                color = "gray-50";

                if (this.disabled || this.loading) {
                    color = "transparent";
                } else if (this.active) {
                    color = "secondary-blue-200";
                } else if (this.hover) {
                    color = "secondary-blue-100";
                }

                color += " pg-secondary";
            } else if (this.tertiary) {
                color = "transparent pg-tertiary";
            }

            return color;
        },
        textColor() {
            let color = "text-" + this.contentColor;

            if (this.disabled || this.loading) {
                color = "text-gray-100";
            }

            if (this.primary) {
                color = "text-gray-0";

                if (this.disabled || this.loading) {
                    color = "text-gray-100";
                }
            } else if (this.secondary) {
                color = "text-primary-blue-500";

                if (this.disabled || this.loading) {
                    color = "text-gray-100";
                } else if (this.active) {
                    color = "text-tertiary-blue-900";
                } else if (this.hover) {
                    color = "text-primary-blue-900";
                }
            } else if (this.tertiary) {
                color = this.danger ? "text-gray-500" : "text-primary-blue-500";

                if (this.disabled || this.loading) {
                    color = "text-gray-100";
                } else if (this.active) {
                    color = this.danger ? "text-gray-900" : "text-tertiary-blue-900";
                } else if (this.hover && this.danger) {
                    color = "text-error-500";
                }
            }

            return color;
        },
        contentStyle() {
            let style = "";

            if (this.tertiary && (this.hover || this.active)) {
                style = "text-decoration-underline";
            }

            return style;
        },
        borderStyle() {
            let style = this.border;

            if (this.primary) {
                style = "rounded-1 border-0";

                if (this.disabled || this.loading) {
                    style = "rounded-1 border-dashed-1 border-gray-100";
                }
            } else if (this.secondary) {
                style = "rounded-1 border-0";
            } else if (this.tertiary) {
                style = "rounded-1 border-0";
            }

            return style;
        }
    },
    watch: {
        value() {
            this.active = this.value;
        }
    },
    methods: {
        /**
         * Funcao que fica vinculada ao botao  
         */
        buttonFunction() {
            /**
             * Evento de click do botao
             * @event click
             * @vue
             */
            this.$emit("click");
            this.$emit("input", !this.value);
        }
    },
    template: (
        /*html*/
        `
            <base-tooltip :disabled="!tooltipText" :text="tooltipText" :[tooltipOrientation]="true">
                <v-row no-gutters justify="center">
                    <v-col :cols="cols">
                        <v-hover v-model="hover" :disabled="disabled || loading">
                            <v-btn
                                depressed
                                :x-large="xLarge"
                                :small="small"
                                :disabled="disabled || loading"
                                :loading="loading"
                                :width="onlyIcon ? '40px' : (!block ? (width !== undefined ? width : '100%') : undefined)"
                                :block="width !== undefined ? undefined : block"
                                :height="onlyIcon ? '40px' : undefined"
                                :class="buttonColor + ' ' + borderStyle"
                                @click="buttonFunction"
                                :id="id"
                            >
                                <div :class="'d-flex align-center ' + (small ? 'px-2' : 'px-4 py-2') + ' ' + textColor">
                                    <v-icon v-if="left && icon" :class="textColor" :left="!onlyIcon">
                                        {{ icon }}
                                    </v-icon>
                                    <span v-if="!onlyIcon" :class="'body-2 ' + textColor + ' ' + contentStyle" v-html="label" />
                                    <v-icon v-if="!left && icon" :class="textColor" :right="!onlyIcon">
                                        {{ icon }}
                                    </v-icon>
                                </div>
                                <template #loader>
                                    <slot name="loader" />
                                </template>
                            </v-btn>
                        </v-hover>
                    </v-col>
                </v-row>
            </base-tooltip>
        `
    )
};