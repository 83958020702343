import BaseDialog from "../../../../components/moleculas/BaseDialog";
import BaseTextField from "../../../../components/atomos/BaseTextField";
import DialogConfigTemplateMessage from "./DialogConfigTemplateMessage";
import PreviewTemplate from "../../../../components/organismos/PreviewTemplate";
import TemplateUtils from "../../../../utils/TemplateUtils";
import { DraggableItemClass } from "../../../../classes/DraggableItemClass";
import { BasePillClass } from "../../../../classes/BasePillClass";
import { CardRcsClass } from "../../../../classes/CardRcsClass";
import * as types from "../../store/mutations-types";
import * as types_utils from "../../../../utils/store/mutations-types";

/**
 * Componente de modal para realizar uma c?pia de template
 *
 * @requires {@link PreviewTemplate}
 * @requires {@link BaseDialog}
 * @requires {@link BaseTextField}
 * @requires {@link DialogConfigTemplateMessage}
 *
 * @displayName DialogTemplateCopy
 * @component
 * @category Page / Template RCS
 * @subcategory dialogs
 * @vue
 * @vue/component
 */
export default {
    components: {
        PreviewTemplate,
        BaseDialog,
        BaseTextField,
        DialogConfigTemplateMessage
    },
    props: {
        /**
         * Valor do atributo v-model do componente, indica se o dialog deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: false,
            default: null
        },
        /**
         * Dados do item a ser exibido
         */
        item: {
            type: Object,
            required: false,
            default: null
        }
    },
    data() {
        return {
            show_dialog: this.value,
            loading_content: true,
            template_code: null,
            template_name: null,
            checkbox_template_edit: false,
            data_config: null,
            show_config: false,
            buttons_config: [],
            template_utils: new TemplateUtils(),
            icons_buttons: {
                "url": {
                    icon: "fa-globe-americas",
                    type: "link"
                },
                "dial_phone": {
                    icon: "fa-phone-alt",
                    type: "phone"
                },
                "show_location": {
                    icon: "fa-map-marker-alt",
                    type: "map"
                },
                "request_location": {
                    icon: "fa-crosshairs",
                    type: "localization"
                }
            },
            id_empresa: this.$cookie === undefined ? getCookie("id_empresa_selected") : this.$cookie.get("id_empresa_selected"),
            code_rules: [
                v => !!v || "Informe um c&oacute;digo para o template",
                v => {
                    let search = this.list_templates.filter(template => {
                        return template.CD_TEMPLATE_TAES.toUpperCase() === v.trim().toUpperCase().replaceAll(" ", "_") && (template.ID_EMPRESA_TAES === this.id_empresa || this.list_clients_for_select.indexOf(template.ID_EMPRESA_TAES));
                    });

                    return search.length === 0 || window.Vue.htmlEntities("J&aacute; existe um template com este nome");
                }
            ],
            name_rules: [
                v => !!v || "Informe um nome para o template"
            ],
            list_templates: [],
            disable_action_button: true,
        };
    },
    computed: {
        ...window.Vuex.mapState("templatesrcs", {
            step_one_content: state => state.step_one_content,
            step_two_content: state => state.step_two_content,
            step_three_content: state => state.step_three_content,
            id_template_created: state => state.id_template_created
        }),
        ...window.Vuex.mapState("utils", {
            list_clients_for_select: state => state.list_clients_for_select
        }),
    },
    watch: {
        show_dialog() {
            /**
             * Evento de input, disparado sempre que o status de exibicao/no exibicao do dialog for alterado
             *
             * @property {string} show_dialog Status da modal
             *
             * @event input
             * @vue
             */
            this.$emit("input", this.show_dialog);
        },
        value() {
            this.show_dialog = this.value;
        },
        item() {
            this.loading_content = true;
            this.template_code = window.Vue.htmlEntities("COPIA_DE_" + this.item.CD_TEMPLATE);
            this.template_name = window.Vue.htmlEntities("Copia " + this.item.NM_TEMPLATE);
            this.getTemplate(this.item.ID_TEMPLATE);
        },
        template_code() {
            this.disableButtonCopy();
        },
        template_name() {
            this.disableButtonCopy();
        }
    },
    mounted() {
        this.getTemplates();
    },
    methods: {
        ...window.Vuex.mapActions("templatesrcs", ["ActionDoGetTemplateRcsById", "ActionDoCreateTemplateRcs", "ActionDoGetTemplatesRcs"]),
        ...window.Vuex.mapActions("utils", ["ActionDoGetMyClients"]),
        /**
         * Busca os clientes que o usuario tem acesso
         * @vue
         */
        getClients() {
            this.$store.commit("utils/" + types_utils.SET_CLIENTS_LOADING, true);
            this.ActionDoGetMyClients();
        },
        closeDialog() {
            this.show_dialog = !this.show_dialog;
        },
        /**
         * Obtem lista de sugestoes de respostas
         * @vue
         * @param   {Object} suggestions
         * @returns {{list_suggestions: [], list_fast_response: []}}
         */
        getListSuggestionsResponse(suggestions) {
            let list_fast_response = [];
            let list_suggestions = [];

            if ((typeof(suggestions) != "undefined") && suggestions.length > 0) {
                for (let index in suggestions) {
                    if (suggestions[index].type === "text") {
                        list_fast_response.push(new DraggableItemClass({
                            text: suggestions[index].label,
                            type: "pill",
                            custom: true, // TODO: Como saber se eh custom?
                            order: index,
                            pill: new BasePillClass({
                                title: suggestions[index].label,
                                prependIcon: "fa-grip-vertical",
                                draggable: true,
                                editable: true,
                                deletable: true,
                                order: index
                            })
                        }));
                    } else if (["url", "dial_phone", "show_location", "request_location"].indexOf(suggestions[index].type) > -1) {
                        list_suggestions.push(new DraggableItemClass({
                            text: suggestions[index].label,
                            type: "pill",
                            custom: true,
                            order: index,
                            pill: new BasePillClass({
                                title: suggestions[index].label,
                                subTitle: suggestions[index].type === "show_location" ? `${suggestions[index].value.latitude}, ${suggestions[index].value.longitude}` : suggestions[index].value,
                                prependIcon: "fa-grip-vertical",
                                titleIcon: this.icons_buttons[suggestions[index].type].icon,
                                draggable: true,
                                editable: true,
                                deletable: true,
                                subTitleNewLine: true,
                                type: this.icons_buttons[suggestions[index].type].type,
                                order: index
                            })
                        }));
                    }
                }
            }
            return {
                list_fast_response: list_fast_response,
                list_suggestions: list_suggestions
            };
        },
        /**
         * Obtem lista de mensagens
         * @vue
         * @param   {String} text
         * @returns {Array}
         */
        getListMessages(text) {
            let list_messages = [];
            if ((typeof(text) != "undefined")) {
                for (let message of text.split(" ")) {
                    message = message.trim();

                    if (message[0] === "{" && message[message.length - 1] === "}") {
                        let text = message.replaceAll("{", "").replaceAll("}", "");
                        list_messages.push(new DraggableItemClass({
                            text: text,
                            type: "pill",
                            custom: true,
                            pill: new BasePillClass({
                                title: text,
                                subTitle: "",
                                prependIcon: "fa-grip-vertical",
                                draggable: true,
                                editable: true,
                                deletable: true,
                                rounded: true,
                                numberCharacters: 1
                            })
                        }));
                    } else {
                        list_messages.push(new DraggableItemClass({
                            text: message,
                            type: "text",
                            pill: new BasePillClass({})
                        }));
                    }
                }
            }
            return list_messages;
        },
        /**
         * Obtem imagem
         * @vue
         * @param   {String} url
         * @returns {String}
         */
        getImage(url) {
            let image = null;
            if (typeof(url) != "undefined") {
                image = ((url === "{url}") ? "variable" : url);
            }
            return image;
        },
        /**
         * Obtem lista de carrosel/card
         * @vue
         * @param   {Object} ds_template
         * @returns {{list_card: [], card: {}}}
         */
        getCardCarousel(ds_template) {
            let content_template = ds_template.contents ?? ds_template.content;
            let list_card = [];
            let card = {};

            if (typeof(content_template) != "undefined") {
                if (Array.isArray(content_template)) { // Carousel
                    content_template.forEach((content) => {
                        let list = this.getListSuggestionsResponse(content.suggestions);
                        list_card.push(new CardRcsClass({
                            image: content.media.file.url,
                            title: this.getListMessages(content.title ?? ""),
                            description: this.getListMessages(content.description ?? ""),
                            suggestionButtons: list.list_suggestions,
                            suggestionResponses: list.list_fast_response,
                            image_height: content.media.height
                        }));
                    });
                } else { // Card
                    let list = this.getListSuggestionsResponse(content_template.suggestions);
                    card = new CardRcsClass({
                        image: content_template.media.file.url,
                        title: this.getListMessages(content_template.title ?? ""),
                        description: this.getListMessages(content_template.description ?? ""),
                        suggestionButtons: list.list_suggestions,
                        suggestionResponses: list.list_fast_response,
                        orientation: ds_template.orientation,
                        alignment: ds_template.alignment,
                        image_height: content_template.media.height
                    });
                }
            }

            return { list_card: list_card, card: card };
        },
        /**
         * Define os passos e valores do template
         * @vue
         * @param {Object} payload
         */
        setStepsTemplate(payload) {
            // Comita as informacoes do passo um e dois
            this.$store.commit("templatesrcs/" + types.SET_STEP_ONE, {
                template_code: payload.template_code,
                template_name: payload.template_name,
                modelo: payload.template_type,
                clients: parseInt(payload.id_empresa) === parseInt(this.id_empresa) ? [-1] : [payload.id_empresa],
                client_description: parseInt(payload.id_empresa) === parseInt(this.id_empresa) ? "Todos" : ""
            });

            // Estrutura de payload
            let paramsPayload = {
                button_type: {},
                image: null,
                card: {},
                list_fast_response: [],
                list_suggestions: [],
                list_messages: [],
                list_card: [],
            };

            // Preenche estrutura com os valores informados
            Object.keys(paramsPayload).forEach((param) => {
                if (payload[param]) {
                    paramsPayload[param] = payload[param];
                }
            });
            paramsPayload.list_chips_preview = [...paramsPayload.list_fast_response, ...paramsPayload.list_suggestions];

            this.$store.commit("templatesrcs/" + types.SET_STEP_TWO, paramsPayload);

            if (payload.fallback) {
                let ds_fallback = "";

                try {
                    ds_fallback = JSON.parse(payload.fallback.DS_TEMPLATE_TAES).text;
                } catch (error) {
                    ds_fallback = payload.fallback.DS_TEMPLATE_TAES;
                }
                this.$store.commit("templatesrcs/" + types.SET_STEP_THREE, { list_messages: this.getListMessages(ds_fallback) });
            }

            this.getClients();
        },
        /**
         * Obtem template e o monta para exibicao
         * @vue
         * @param {Integer} templateId
         */
        getTemplate(templateId) {
            this.ActionDoGetTemplateRcsById(templateId).then(template => {
                let ds_template = JSON.parse(template.DS_TEMPLATE_TAES);
                let fallback = template.fallback !== undefined && template.fallback.length ? template.fallback[0] : null;

                // Funcoes que obtem e armazena campos necessarios pro template
                const buildTemplate = {
                    M(e) { // Text
                        let listSuggestions = e.getListSuggestionsResponse(ds_template.suggestions);
                        let listMessages = e.getListMessages(ds_template.text);
                        let templateType = { label: "Texto", icon: "$templateTextIcon", icon_small: "$templateTextIcon", value: "text" };

                        let have_buttons = (listSuggestions.list_suggestions.length > 0);
                        let have_fast_response = (listSuggestions.list_fast_response.length > 0);

                        let buttonType = { icon: "$simplesIcon", icon_small: "$simplesSmallIcon", title: "Simples", type: "simples" };
                        if (have_buttons && have_fast_response) {
                            buttonType = { icon: "$completoIcon", icon_small: "$completoSmallIcon", title: "Completo", type: "completo" };
                        } else if (have_buttons) {
                            buttonType = { icon: "$botoesIcon", icon_small: "$botoesSmallIcon", title: "Bot&otilde;es", type: "botoes" };
                        } else if (have_fast_response) {
                            buttonType = { icon: "$respostasRapidasIcon", icon_small: "$respostasRapidasSmallIcon", title: "Respostas r&aacute;pidas", type: "respostas_rapidas" };
                        }

                        e.setStepsTemplate({
                            button_type: buttonType,
                            list_fast_response: listSuggestions.list_fast_response,
                            list_suggestions: listSuggestions.list_suggestions,
                            list_messages: listMessages,
                            template_type: templateType,
                            template_code: template.CD_TEMPLATE_TAES,
                            template_name: template.NM_TEMPLATE_TAES,
                            template_id: template.ID_TEMPLATE_APPS_TAES,
                            id_empresa: template.ID_EMPRESA_TAES,
                            fallback: fallback
                        });
                    },
                    I(e) { // Image
                        let image = e.getImage(ds_template.url);
                        let templateType = { label: "Imagem", icon: "$templateImageIcon", icon_small: "$templateImageIcon", value: "image" };

                        let buttonType = { icon: "$imageVariableIcon", icon_small: "$imageVariableSmallIcon", title: "Personalizada", type: "imagem_personalizada" };
                        if (template.DS_PARAMETRO_TAES === "{}") {
                            buttonType = { icon: "$imageIcon", icon_small: "$imageSmallIcon", title: "&Uacute;nica", type: "imagem_unica" };
                        }

                        e.setStepsTemplate({
                            button_type: buttonType,
                            image: image,
                            template_type: templateType,
                            template_code: template.CD_TEMPLATE_TAES,
                            template_name: template.NM_TEMPLATE_TAES,
                            template_id: template.ID_TEMPLATE_APPS_TAES,
                            id_empresa: template.ID_EMPRESA_TAES,
                            fallback: fallback
                        });
                    },
                    C(e) { // Card
                        let content_template = ds_template.contents ?? ds_template.content;
                        let cardCarousel = e.getCardCarousel(ds_template);

                        let have_buttons = (cardCarousel.card.suggestionButtons.length > 0);
                        let have_fast_response = (cardCarousel.card.suggestionResponses.length > 0);

                        let buttonType = { icon: "$simplesIcon", icon_small: "$simplesSmallIcon", title: "Simples", type: "simples" };
                        if (have_buttons && have_fast_response) {
                            buttonType = { icon: "$completoIcon", icon_small: "$completoSmallIcon", title: "Completo", type: "completo" };
                        } else if (have_buttons) {
                            buttonType = { icon: "$botoesIcon", icon_small: "$botoesSmallIcon", title: "Bot&otilde;es", type: "botoes" };
                        } else if (have_fast_response) {
                            buttonType = { icon: "$respostasRapidasIcon", icon_small: "$respostasRapidasSmallIcon", title: "Respostas r&aacute;pidas", type: "respostas_rapidas" };
                        }

                        let templateType = { label: "Card", icon: "$templateCardIcon", icon_small: "$templateCardIcon", value: "card" };

                        e.setStepsTemplate({
                            button_type: buttonType,
                            image: content_template.media.file.url,
                            card: cardCarousel.card,
                            template_type: templateType,
                            template_code: template.CD_TEMPLATE_TAES,
                            template_name: template.NM_TEMPLATE_TAES,
                            template_id: template.ID_TEMPLATE_APPS_TAES,
                            list_fast_response: cardCarousel.card.suggestionResponses,
                            list_suggestions: cardCarousel.card.suggestionButtons,
                            list_messages: cardCarousel.card.description,
                            id_empresa: template.ID_EMPRESA_TAES,
                            fallback: fallback
                        });
                    },
                    R(e) { // Carousel
                        let cardCarousel = e.getCardCarousel(ds_template);

                        let buttonType = { icon: "$simplesIcon", icon_small: "$simplesSmallIcon", title: "Carrossel", type: "carousel" };
                        let templateType = { label: "Carrossel", icon: "$templateCarouselIcon", icon_small: "$templateCarouselIcon", value: "carousel" };

                        e.setStepsTemplate({
                            button_type: buttonType,
                            list_card: cardCarousel.list_card,
                            template_type: templateType,
                            template_code: template.CD_TEMPLATE_TAES,
                            template_name: template.NM_TEMPLATE_TAES,
                            template_id: template.ID_TEMPLATE_APPS_TAES,
                            id_empresa: template.ID_EMPRESA_TAES,
                            fallback: fallback
                        });
                    }
                };

                // Chama funcao com base no tipo do template (M = Text, I = Image, C = Card, R = Carousel)
                buildTemplate[template.TP_TEMPLATE_TAES](this);

                this.loading_content = false;
            }).catch(() => {
                this.closeDialog();
            });
        },
        /**
         * Realiza a copia do template
         * @vue
         */
        copyTemplate() {
            let data = {
                id_produto: 134, // 134 ID do tipo de produto 'RCS TEMPLATE'
                tipo: this.template_utils.getTypeTemplate(this.step_one_content.modelo.value),
                cd_template: this.template_code.trim().toUpperCase().replaceAll(" ", "_"),
                nm_template: this.template_name.trim(),
                ds_template: this.template_utils.getDsTemplate(this.step_one_content.modelo.value, this.step_two_content),
                ds_parametro: this.template_utils.getDsParametro(this.step_one_content.modelo.value, this.step_two_content),
                clients: this.step_one_content.clients[0] === -1 || this.step_one_content.clients.length === this.list_clients_for_select.length ? [parseInt(this.id_empresa)] : this.step_one_content.clients
            };

            if (this.step_three_content && Object.keys(this.step_three_content).length > 0) {
                let ds_template_fallback = this.template_utils.getDsTemplate("text", this.step_three_content);
                ds_template_fallback = ds_template_fallback.replace(/{"text":"(.*?)"}/, "$1");
                data["fallback_content"] = {
                    cd_template: this.template_code.trim().toUpperCase().replaceAll(" ", "_") + "_FALLBACK",
                    nm_template: this.template_name.trim(),
                    ds_template: ds_template_fallback,
                    ds_parametro: this.template_utils.getDsParametro("text", this.step_three_content)
                };
            }
            this.ActionDoCreateTemplateRcs(data).then((response) => {
                let idTemplateCreated = false;
                if (response.status) {
                    idTemplateCreated = Object.values(response.success)[0].id_template;
                }
                this.$store.commit("templatesrcs/" + types.SET_ID_TEMPLATE_CREATED, idTemplateCreated);
                this.closeDialog();
            }).catch(() => {
                this.$store.commit("templatesrcs/" + types.SET_ID_TEMPLATE_CREATED, false);
                this.closeDialog();
            });
        },
        /**
         * Acao do botao principal de copiar template,
         *  verifica se o usuario habilitou o checkbox para editar template para exibir modal de configuracao
         *  @vue
         */
        setupCopyTemplate() {
            if (this.checkbox_template_edit) {
                this.buttons_config = this.template_utils.getMountButtonsConfig(this.step_one_content.modelo.value);

                // Armazena configuracao do template atual para utilizar na modal de edicao
                this.data_config = {
                    button_type: this.step_two_content.button_type,
                    image: this.step_two_content.image,
                    list_messages: this.step_two_content.list_messages,
                    list_fast_response: this.step_two_content.list_fast_response,
                    list_suggestions: this.step_two_content.list_suggestions,
                    list_chips_preview: this.step_two_content.list_chips_preview,
                    card: this.step_two_content.card
                };

                this.show_config = true;
            } else {
                this.copyTemplate();
            }
        },
        /**
         * Acao de salvar configuracoes da edicao do template
         * @vue
         * @param {Object} data
         */
        saveConfig(data) {
            this.data_config = data;

            this.$store.commit("templatesrcs/" + types.SET_STEP_TWO, this.data_config);
            this.show_config = false;
            this.copyTemplate();
        },
        /**
         * Busca a lista de templates para verificar se ja existem templates com o mesmo nome
         * @vue
         */
        getTemplates() {
            this.ActionDoGetTemplatesRcs().then(response => {
                this.list_templates = response;
            });
        },
        /**
         * Verifica se os campos do formul?rio s?o v?lidos para liberar o bot?o de a??o que realiza a c?pia do template
         * @vue
         */
        disableButtonCopy() {
            if (this.$refs.formCodeName) {
                this.disable_action_button = !this.$refs.formCodeName.validate();
            } else {
                this.disable_action_button = false;
            }
        }
    },
    template: (
        /*html*/
        `
            <base-dialog
                v-model="show_dialog"
                title="C&oacute;pia de template"
                max-width="545"
                use-icon-close
                use-sub-action
                :action-button="{ label: 'Copiar template', icon: 'mdi-content-copy', loading: loading_content }"
                :disable-action-button="disable_action_button"
                content-class="pt-8 pl-8 pr-8 pb-4"
                @clickDialog="setupCopyTemplate"
                @closeDialog="closeDialog"
            >
                <v-row>
                    <v-col v-if="!loading_content">
                        <v-form ref="formCodeName">
                            <v-row no-gutters class="mt-4">
                                <v-col>
                                    <base-text-field
                                        v-model="template_code"
                                        label="Insira o c&oacute;digo aqui..."
                                        title="C&oacute;digo do template"
                                        tooltip-text="Digite o c&oacute;digo do seu novo template"
                                        :rules="code_rules"
                                        required
                                    />
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-4 pb-4">
                                <v-col>
                                    <base-text-field
                                        v-model="template_name"
                                        label="Digite o nome aqui..."
                                        title="Nome do template"
                                        tooltip-text="O nome do template auxiliar&aacute; na identifica&ccedil;&atilde;o durante a busca na lista de templates."
                                        :rules="name_rules"
                                        required
                                    />
                                </v-col>
                            </v-row>
                        </v-form>
                        <preview-template
                            :type="((step_one_content.modelo.value === 'text') ? 'message' : step_one_content.modelo.value)"
                            :message="step_two_content.list_messages"
                            :list-suggestions="step_two_content.list_chips_preview"
                            :image="step_two_content.image"
                            :card="step_two_content.card"
                            :list-card="step_two_content.list_card"
                            show-overflow
                        />
                    </v-col>
                    <v-col v-else>
                        <v-card elevation="0" class="pb-8">
                            <v-row justify="center" class="d-flex flex-flow-column fill-height" no-gutters>
                                <v-col cols="12">
                                    <v-skeleton-loader type="text" width="208" height="16" />
                                    <v-skeleton-loader height="32" type="image" />
                                </v-col>
                                <v-col cols="12" class="pt-4 d-flex justify-center">
                                    <v-skeleton-loader type="text" width="393" height="15" />
                                </v-col>
                                <v-col cols="12">
                                    <v-skeleton-loader height="200" type="image" />
                                </v-col>
                                <v-col cols="12" class="pt-4">
                                    <v-skeleton-loader height="34" type="image" />
                                </v-col>
                                <v-col cols="12" class="pt-4">
                                    <v-skeleton-loader height="16" type="image" />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <template v-if="!loading_content" #subAction>
                    <v-row no-gutters>
                        <v-col v-if="['text', 'image', 'card'].indexOf(step_one_content.modelo.value) > -1">
                            <v-checkbox v-model="checkbox_template_edit" label="Quero editar meu template" />
                        </v-col>
                        <v-col v-else class="cursor-not-allowed">
                            <v-checkbox disabled>
                                <template #label>
                                    <span><s>Quero editar meu template</s>(Dispon&iacute;vel para os modelos texto e imagem)</span>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                </template>
                <dialog-config-template-message
                    v-model="show_config"
                    :config-data="data_config"
                    :list-buttons="buttons_config"
                    @save="saveConfig"
                />
            </base-dialog>
        `
    )
};