import BaseBackgroundIcon from "../atomos/BaseBackgroundIcon?v=2";
import BaseButton from "../atomos/BaseButton?v=2";
import BaseTooltip from "../atomos/BaseTooltip?v=2";

/**
 * Componente para que o usuario acompanhe todos os andamento da criacao de template
 * 
 * @requires {@link BaseBackgroundIcon}
 * @requires {@link BaseButton}
 * @requires {@link BaseTooltip}
 * 
 * @displayName DataSummary
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseButton,
        BaseBackgroundIcon,
        BaseTooltip
    },
    props: {
        /**
         * Objeto de parametros de bg-icon-text
         */
        steps: {
            type: [Object, Array],
            required: true
        },
        /**
         *  Informa se o boto de delete deve ficar visivel
         */
        disabledDelete: {
            type: Boolean,
            required: false,
            default: true
        },
        /** 
         * Informa a quantidade de colunas para a ajuste do componente
         */
        columns: {
            type: [String, Number],
            required: false,
            default: 12
        },
        /**
         * Texto do tooltip a ser exibido
         */
        tooltipText: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Texto do tooltip a ser exibido para o botao de limpar tudo
         */
        tooltipClearButton: {
            type: String,
            required: false,
            default: null
        }
    },
    methods: {
        /**
         * Funcao que esta vinculada ao botao de exclusao. 
         * Ela dispara o evento vinculado ao componente
         * @param instance 
         */
        try_delete() {
            /**
             * Evento responsvel por deletar os parametros do DataSummary
             * 
             * @property {Object} data Dados da div de reviso de contedo
             * 
             * @category Moleculas
             * @event click
             * @vue
             */
            this.$emit("click");
        }
    },
    template: (
        /*html*/
        `
        <base-tooltip :text="tooltipText">
            <v-card elevation="0" class="border-2 border-primary-blue-500 rounded-2 pa-6">
                <v-row no-gutters class="mb-2">
                    <v-col>
                        <slot name="header"></slot>
                    </v-col>
                </v-row>
                <v-row no-gutters class="overflow-x-auto flex-nowrap flex-md-wrap">
                    <v-col :cols="columns" v-for="(step, key) in steps" :key="key" class="pb-2">
                        <base-background-icon
                            :icon-name="step.icon"
                            :background-color="step.background" 
                            :circle="step.circle" 
                            :title="step.title"
                            :sub-title="step.description"
                            :tooltip="step.tooltip"
                            :size="45"
                        ></base-background-icon>  
                    </v-col>
                </v-row>
                <v-row v-if="!disabledDelete" no-gutters justify="end">
                    <v-col cols="auto" v-bind:data-v-step="'buttonDeleteReview'">
                        <base-button
                            tertiary
                            danger
                            left
                            icon="icon-btn-delete"
                            label="Limpar tudo"
                            :block="false"
                            width="auto"
                            :tooltip-text="tooltipClearButton"
                            @click="try_delete"
                        ></base-button>
                    </v-col>
                </v-row>   
            </v-card>
        </base-tooltip>
        `
    )
};
