import { store as auth } from "../views/auth/index";
import { store as templatesrcs } from "../views/template-rcs/index";
import { store as reports } from "../views/reports/index";
import { store as utils } from "../utils/index";
import { store as dashboard } from "../views/dashboard/index";
import { store as templates } from "../views/template/index";
import { store as avulsoapi } from "../views/avulsoapi/index";
import { store as blacklist } from "../views/blacklist/index";
import { store as gerenciar } from "../views/gerenciar/index";
import { store as campaign } from "../views/campaign/index";
import { store as sessoes_rcs } from "../views/sessoes_rcs/index";

export default {
    auth,
    templatesrcs,
    reports,
    utils,
    dashboard,
    templates,
    avulsoapi,
    blacklist,
    gerenciar,
    campaign,
    sessoes_rcs
};