import RouterPage from "../RouterPage";
import DashboardPage from "./DashboardPage";

export default [
    {
        path: "dashboard",
        name: "dashboard",
        component: RouterPage,
        children: [
            {
                path: "list",
                name: "Dashboard List",
                component: DashboardPage
            }
        ]
    }
];