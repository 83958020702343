import ContentEdit from "../../views/template-rcs/components/ContentEdit";
import DialogPreviewTemplate from "../organismos/DialogPreviewTemplate";

/**
 * Componente pre visualizador de template em tabelas
 * 
 * @requires {@link ContentEdit}
 * @requires {@link DialogPreviewTemplate}
 * 
 * @displayName TableThumb
 * @category Moleculas
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        ContentEdit,
        DialogPreviewTemplate
    },
    props: {
        /**
         * Conteudo do template
         */
        dsTemplate: {
            type: String,
            required: true
        },
        /**
         * Dados do template de fallback, JSON em formato de string
         */
        dsTemplateFallback: {
            type: String,
            required: false,
            default: null
        },
        /**
         * Adiciona um destaque a um dos templates
         */
        highlight: {
            type: String,
            required: false,
            default: null,
            validator(data) {
                if (data) {
                    return ["main", "fallback"].indexOf(data) > -1;
                } else {
                    return true;
                }
            }
        },
        /**
         * Tipo do template
         */
        typeTemplate: {
            type: String,
            required: false,
            default: "M"
        },
        /**
         * Exibe o dialog de preview padrao quando clicado
         */
        defaultDialog: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            type_product: this.$cookie === undefined ? getCookie("type_product") : this.$cookie.get("type_product"),
            show_dialog: false
        };
    },
    computed: {
        content() {
            let template = this.highlight === "fallback" ? this.dsTemplateFallback : this.dsTemplate;
            let data = {};
            let image = null;
            let text = null;
            let suggestions = null;

            if (this.type_product === "SMS" || this.highlight === "fallback") {
                try {
                    data = { text: JSON.parse(template).text, image: null };
                } catch (error) {
                    data = { text: template, image: null };
                }
            } else {
                let content = null;

                try {
                    content = JSON.parse(template);
                } catch (error) {
                    return { text: template, image: null };
                }

                switch (this.typeTemplate) {
                case "M":
                case "E":
                case "":
                case "texto":
                case "TEXT":
                case "T":
                case null:
                    data = { text: content.text, image: null, suggestions: content.suggestions };
                    break;
                case "C":
                    image = content.contents ? content.contents.media.file.url
                        : content.content ? content.content.media.file.url
                            : null;
                    text = content.contents ? content.contents.description
                        : content.content ? content.content.description
                            : null;
                    suggestions = content.contents ? content.contents.suggestions
                        : content.content ? content.content.suggestions
                            : null;
                    try {
                        image = new URL(image);
                        image = image.href;
                    } catch (error) {
                        image = "/images/image-placeholder.svg";
                    }

                    data = {
                        text: text,
                        image: image,
                        suggestions: suggestions,
                    };
                    break;
                case "R":
                    try {
                        image = new URL(content.contents[0].media.file.url);
                        image = image.href;
                    } catch (error) {
                        image = "/images/image-placeholder.svg";
                    }

                    data = {
                        text: content.contents[0].description,
                        image: image,
                        suggestions: content.contents[0].suggestions
                    };
                    break;
                case "I":
                    try {
                        image = new URL(content.url);
                        image = image.href;
                    } catch (error) {
                        image = "/images/image-placeholder.svg";
                    }

                    data = {
                        text: null,
                        image: image,
                        suggestions: null
                    };
                    break;
                case "V":
                    try {
                        image = new URL(content.url);
                        image = image.href;
                    } catch (error) {
                        image = "/images/image-placeholder.svg";
                    }

                    data = {
                        text: null,
                        image: image,
                        suggestions: null
                    };
                    break;
                default:
                    data = { text: null, image: null, suggestions: null };
                    break;
                }
            }

            return data;
        },
        icon() {
            if (this.type_product === "RCS" && this.content.suggestions?.length) {
                let icons_buttons = {
                    "url": "fa-globe-americas",
                    "dial_phone": "fa-phone-alt",
                    "show_location": "fa-map-marker-alt",
                    "request_location": "fa-crosshairs"
                };

                for (let suggestion of this.content.suggestions) {
                    if (Object.keys(icons_buttons).indexOf(suggestion.type) > -1) {
                        return icons_buttons[suggestion.type];
                    }
                }

                return "mdi-dots-horizontal";
            }

            return null;
        }
    },
    methods: {
        /**
         * Metodo chamado ao clicar no componente, verifica se deve emitir um click ou abrir o modal de preview
         * @vue
         */
        onClick() {
            if (this.defaultDialog) {
                this.show_dialog = true;
            } else {
                /**
                 * Emite um evento de click
                 * @event click
                 * @vue
                 */
                this.$emit("click");
            }
        }
    },
    template:
    /*html*/
        `
        <content-edit
            :text="null"
            icon="mdi-eye"
            @click="onClick"
        >
            <v-card elevation="0" class="bg-primary-blue-50 rounded-0 pa-1" height="42">
                <v-row
                    no-gutters
                    class="flex-nowrap fill-height"
                    align="center"
                    justify="space-between"
                >
                    <v-col v-if="content.image" :cols="content.text ? 'auto' : icon ? 9 : 12">
                        <v-img :src="content.image" :width="content.text ? 42 : 'auto'" height="42" />
                    </v-col>
                    <v-col v-if="content.text" class="pl-1 text-truncate" :cols="!content.image && icon ? 9 : undefined">
                        <span class="caption text-gray-900" v-html="content.text" />
                    </v-col>
                    <v-col v-if="icon" :cols="content.text ? 'auto' : undefined" class="pa-1 d-flex justify-end">
                        <v-icon color="gray-300" size="16">
                            {{ icon }}
                        </v-icon>
                    </v-col>
                </v-row>
                <dialog-preview-template
                    v-model="show_dialog"
                    :ds-template="dsTemplate"
                    :ds-template-fallback="dsTemplateFallback"
                    :highlight="highlight"
                />
            </v-card>
        </content-edit>
        `,
};
