import BaseSectionForm from "../../../components/atomos/BaseSectionForm?v=2";
import AditionalReportData from "./AditionalReportData";
import AgroupReportData from "./AgroupReportData";
import ButtonCheckboxClass from "../../../classes/ButtonCheckboxClass";

import * as types from "../store/mutations-types";

/**
 * Componente de passo cinco do gerador de relatorios
 * 
 * @requires {@link BaseSectionForm}
 * @requires {@link AditionalReportData}
 * @requires {@link AgroupReportData}
 * @requires {@link ButtonCheckboxClass}
 * 
 * @displayName ReportStepFive
 * @category Page / Relatorios
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseSectionForm,
        AditionalReportData,
        AgroupReportData
    },
    data() {
        return {
            type_product: this.$cookie === undefined ? getCookie("type_product") : this.$cookie.get("type_product"),
            report_options: [
                {
                    id: "mensagens",
                    name: "Selecionar todas as mensagens",
                    checked: false,
                    indeterminate: false,
                    buttons: [
                        new ButtonCheckboxClass({ label: "Mensagens Avulsas", icon: "$chatBubbleIcon", checked: false, id: "avulsas", tooltipText: "Clique para incluir dados de envios avulsos" }),
                        new ButtonCheckboxClass({ label: "Mensagens API", icon: "$apiMessagesIcon", checked: false, id: "api", tooltipText: "Clique para incluir dados de envios realizados por API" }),
                        new ButtonCheckboxClass({ label: "Retornos", icon: "$multiChatBubbleIcon", checked: false, id: "radar", tooltipText: "Clique para incluir as mensagens de retorno (radar)" })
                    ]
                },
                {
                    id: "envios",
                    name: "Selecionar todas as situa&ccedil;&otilde;es de envio",
                    checked: false,
                    indeterminate: false,
                    buttons: [
                        new ButtonCheckboxClass({ label: "Entregues", icon: "$checkCircleIcon", checked: false, id: "entregues", tooltipText: "Clique para incluir o status de Entregues" }),
                        new ButtonCheckboxClass({ label: "N&atilde;o Entregues", icon: "$closeCircleIcon", checked: false, id: "nao_entregues", tooltipText: "Clique para incluir o status de N&atilde;o Entregues" }),
                        new ButtonCheckboxClass({ label: "Aguardando", icon: "$clockIcon", checked: false, id: "aguardando", tooltipText: "Clique para incluir o status de Aguardando retorno da operadora" })
                    ]
                },
                {
                    id: "bloqueios",
                    name: "Selecionar todos os bloqueios",
                    checked: false,
                    indeterminate: false,
                    buttons: [
                        new ButtonCheckboxClass({ label: "Inv&aacute;lidos", icon: "$infoIcon", checked: false, id: "invalidos", tooltipText: "Clique para incluir os telefones inv&aacute;lidos" }),
                        new ButtonCheckboxClass({ label: "Cancelados", icon: "$blockedIcon", checked: false, id: "cancelados", tooltipText: "Clique para incluir os envios cancelados" }),
                        new ButtonCheckboxClass({ label: "Repetidos", icon: "$userRefreshCircleIcon", checked: false, id: "repetidos", tooltipText: "Clique para incluir os telefones repetidos" }),
                        new ButtonCheckboxClass({ label: "Blacklist", icon: "$fileAlertIcon", checked: false, id: "blacklist", tooltipText: "Clique para incluir os telefones na blacklist" })
                    ]
                }
            ],
            agroup_report: false,
            aditional_data: [],
            config: []
        };
    },
    computed: {
        ...window.Vuex.mapState("reports", {
            generator_step_one: state => state.generator_step_one,
            generator_step_two: state => state.generator_step_two,
            generator_step_four: state => state.generator_step_four,
            generator_step_five: state => state.generator_step_five
        })
    },
    watch: {
        agroup_report() {
            this.refreshDataStore();
        },
        aditional_data() {
            this.refreshDataStore();
        },
        report_options: {
            handler(options) {
                let buttons_enabled = [];

                for (let option of options) {
                    for (let button of option.buttons) {
                        if (button.checked) {
                            buttons_enabled.push(button.id);
                        }
                    }
                }

                this.aditional_data = buttons_enabled;
            },
            deep: true
        }
    },
    mounted() {
        // build bot�es de mensagens
        let messages_buttons = [];
        let columns_buttons = [];

        if (this.type_product === "RCS") {
            messages_buttons.push(new ButtonCheckboxClass({ label: "Mensagens Avulsas/API", icon: "$chatBubbleIcon", checked: false, id: "api", tooltipText: "Clique para incluir dados de envios avulsos e de envios realizados por API" }));
            if (this.generator_step_one.report_type == "analytical") {
                columns_buttons.push(new ButtonCheckboxClass({label: "Texto da Mensagem", icon: "$cardVerticalMediumIcon", checked: false, id: "texto_mensagem", tooltipText: "Clique para adicionar a coluna com o texto das mensagens" }));
                columns_buttons.push(new ButtonCheckboxClass({label: "Texto do Fallback", icon: "$templateCardIcon", checked: false, id: "texto_fallback", tooltipText: "Clique para adicionar a coluna com o texto das mensagens de fallback" }));
            }
        } else {
            messages_buttons.push(new ButtonCheckboxClass({ label: "Mensagens Avulsas", icon: "$chatBubbleIcon", checked: false, id: "avulsas", tooltipText: "Clique para incluir dados de envios avulsos" }));
            messages_buttons.push(new ButtonCheckboxClass({ label: "Mensagens API", icon: "$apiMessagesIcon", checked: false, id: "api", tooltipText: "Clique para incluir dados de envios realizados por API" }));
        }

        if (this.generator_step_one.report_type == "analytical") {
            messages_buttons.push(new ButtonCheckboxClass({ label: "Retornos", icon: "$multiChatBubbleIcon", checked: false, id: "radar", tooltipText: "Clique para incluir as mensagens de retorno (radar)" }));
        }

        // verifica se foi selecionado apenas mensagens avulsas/api
        if (Object.keys(this.generator_step_four.campaigns).length < 1) {
            ["avulsas", "api"].forEach(buttonId => {
                let button = messages_buttons.find(option => {
                    return option.id == buttonId;
                });

                if (button) {
                    button.checked = true;
                    button.disabled = true;
                }
            });
        }

        this.config.push(
            {
                id: "mensagens",
                name: "Selecionar todas as mensagens",
                checked: false,
                indeterminate: false,
                buttons: messages_buttons
            }
        );

        if (this.type_product === "RCS" && this.generator_step_one.report_type == "analytical") {
            this.config.push({
                id: "colunas",
                name: "Selecionar todas as colunas",
                checked: false,
                indeterminate: false,
                buttons: columns_buttons
            });
        }

        this.config.push(
            {
                id: "envios",
                name: "Selecionar todas as situa&ccedil;&otilde;es de envio",
                checked: false,
                indeterminate: false,
                buttons: [
                    new ButtonCheckboxClass({ label: "Entregues", icon: "$checkCircleIcon", checked: false, id: "entregues", tooltipText: "Clique para incluir o status de Entregues" }),
                    new ButtonCheckboxClass({ label: "N&atilde;o Entregues", icon: "$closeCircleIcon", checked: false, id: "nao_entregues", tooltipText: "Clique para incluir o status de N&atilde;o Entregues" }),
                    new ButtonCheckboxClass({ label: "Aguardando", icon: "$clockIcon", checked: false, id: "aguardando", tooltipText: "Clique para incluir o status de Aguardando retorno da operadora" })
                ]
            },
            {
                id: "bloqueios",
                name: "Selecionar todos os bloqueios",
                checked: false,
                indeterminate: false,
                buttons: [
                    new ButtonCheckboxClass({ label: "Inv&aacute;lidos", icon: "$infoIcon", checked: false, id: "invalidos", tooltipText: "Clique para incluir os telefones inv&aacute;lidos" }),
                    new ButtonCheckboxClass({ label: "Cancelados", icon: "$blockedIcon", checked: false, id: "cancelados", tooltipText: "Clique para incluir os envios cancelados" }),
                    new ButtonCheckboxClass({ label: "Repetidos", icon: "$userRefreshCircleIcon", checked: false, id: "repetidos", tooltipText: "Clique para incluir os telefones repetidos" }),
                    new ButtonCheckboxClass({ label: "Blacklist", icon: "$fileAlertIcon", checked: false, id: "blacklist", tooltipText: "Clique para incluir os telefones na blacklist" })
                ]
            }
        );
        this.report_options = this.config;

        if (this.generator_step_five.agroup === true) {
            this.agroup_report = true;
        }
        if (this.generator_step_five.aditional_data !== undefined && this.generator_step_five.aditional_data.length > 0) {
            for (let option of this.report_options) {
                for (let button of option.buttons) {
                    if (this.generator_step_five.aditional_data.indexOf(button.id) > -1) {
                        button.checked = true;
                    }
                }
            }
        }
    },
    methods: {
        /**
         * Atualiza os dados no store
         * @vue
         */
        refreshDataStore() {
            this.$store.commit(`reports/${types.SET_GENERATOR_CONFIG_STEP_FIVE}`, {
                agroup: this.agroup_report,
                aditional_data: this.aditional_data
            });
        }
    },
    template: (
        /*html*/
        `
            <v-row>
                <v-col cols="12" md="6" lg="7">
                    <base-section-form title="Dados adicionais" description="Esses dados s&atilde;o opcionais, selecione os dados que deseja popular colunas do relat&oacute;rio.">
                        <v-row>
                            <v-col v-bind:data-v-step="12">
                                <aditional-report-data v-model="report_options" select-all></aditional-report-data>
                            </v-col>
                        </v-row>
                    </base-section-form>
                </v-col>
                <v-col v-if="generator_step_one.report_type !== 'analytical'" cols="12" md="6" lg="">
                    <agroup-report-data v-model="agroup_report" v-bind:data-v-step="13"></agroup-report-data>
                </v-col>
            </v-row>
        `
    )
};