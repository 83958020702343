export default {
    template: (
        /*html*/
        `
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5" clip-path="url(#clip0_368_916)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8908 10.9002C12.7511 10.8914 12.6113 10.9152 12.4794 10.9701C12.3476 11.025 12.2262 11.1099 12.1224 11.2201C12.0186 11.3302 11.9343 11.4634 11.8744 11.6119C11.8167 11.755 11.7827 11.9096 11.7743 12.0676L11.8178 29.1334C11.8397 29.4577 11.9672 29.7599 12.1742 29.9768C12.3857 30.1983 12.6629 30.313 12.9459 30.296C12.9568 30.2953 12.9677 30.2949 12.9786 30.2949L37.2702 30.2071C37.283 30.207 37.2957 30.2074 37.3085 30.2082C37.4482 30.217 37.588 30.1932 37.7199 30.1383C37.8517 30.0835 37.9731 29.9985 38.0769 29.8883C38.1807 29.7782 38.265 29.6451 38.3249 29.4965C38.3826 29.3534 38.4166 29.1988 38.425 29.0408L38.3815 11.975C38.3596 11.6508 38.2321 11.3485 38.0251 11.1316C37.8136 10.9101 37.5364 10.7954 37.2534 10.8125C37.2425 10.8131 37.2316 10.8135 37.2207 10.8135L12.9291 10.9013C12.9163 10.9014 12.9036 10.901 12.8908 10.9002ZM12.9426 9.30746C12.6313 9.29106 12.3202 9.34546 12.0263 9.46779C11.726 9.59283 11.4496 9.78634 11.2131 10.0372C10.9766 10.2881 10.7846 10.5914 10.6482 10.9297C10.5118 11.268 10.4336 11.6346 10.4182 12.0085C10.4176 12.0221 10.4173 12.0358 10.4174 12.0495L10.461 29.165C10.4611 29.1808 10.4615 29.1967 10.4624 29.2125C10.5025 29.9634 10.793 30.6654 11.271 31.1662C11.7445 31.6624 12.3641 31.9214 12.9976 31.8887L37.2567 31.801C37.568 31.8174 37.8791 31.763 38.173 31.6406C38.4733 31.5156 38.7497 31.3221 38.9862 31.0712C39.2228 30.8203 39.4147 30.517 39.5511 30.1787C39.6875 29.8404 39.7657 29.4738 39.7811 29.0999C39.7817 29.0863 39.782 29.0726 39.7819 29.0589L39.7383 11.9435C39.7382 11.9276 39.7378 11.9117 39.7369 11.8959C39.6968 11.145 39.4063 10.4431 38.9283 9.94224C38.4548 9.44603 37.8351 9.18697 37.2016 9.21974L12.9426 9.30746Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23.1017 20.3744C23.3259 20.727 23.2643 21.2263 22.9641 21.4898L12.3016 30.8459C12.0014 31.1093 11.5763 31.037 11.3521 30.6844C11.1278 30.3318 11.1894 29.8324 11.4896 29.569L22.1522 20.2129C22.4524 19.9495 22.8775 20.0218 23.1017 20.3744Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3043 10.3461C11.5297 9.99455 11.955 9.92418 12.2543 10.189L25.1026 21.5561L37.8918 10.0963C38.1897 9.82933 38.6154 9.8966 38.8427 10.2465C39.0699 10.5965 39.0126 11.0965 38.7147 11.3635L25.5166 23.1896C25.2749 23.4063 24.9399 23.4075 24.697 23.1926L11.438 11.4621C11.1387 11.1973 11.0788 10.6977 11.3043 10.3461Z" fill="currentColor"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0958 20.3599C27.3182 20.0057 27.7429 19.9303 28.0444 20.1916L38.7545 29.4704C39.056 29.7316 39.1202 30.2305 38.8978 30.5848C38.6754 30.939 38.2506 31.0144 37.9491 30.7531L27.239 21.4743C26.9375 21.2131 26.8734 20.7142 27.0958 20.3599Z" fill="currentColor"/>
                    <path d="M1.35564 26.8017L7.51593 26.783M3.18814 21.6625L7.50034 21.6494M5.02064 16.5233L7.48475 16.5158" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_368_916">
                        <rect width="40" height="40" fill="currentColor" transform="translate(-0.000244141)"/>
                    </clipPath>
                </defs>
            </svg>        
        `
    )
};