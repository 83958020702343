export default {
    template: (
        /*html*/
        `
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                <g id="Ico-Editar-Hover" transform="translate(4.983 -98)">
                    <rect id="Retângulo_343" data-name="Retângulo 343" width="28" height="28" transform="translate(-4.983 98)" fill="transparent" opacity="0"/>
                    <g id="Grupo_1745_1_" transform="translate(0 103)">
                        <g id="Grupo_1743-3_1_">
                            <path id="Caminho_393-3_1_" d="M2-11.7" transform="translate(0.839 27.775)" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <path id="Caminho_394-3_1_" d="M.5-13.1" transform="translate(0.839 27.775)" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            <g id="Grupo_1846" data-name="Grupo 1846">
                                <path id="Caminho_468" data-name="Caminho 468" d="M.5-16.1-.8-11.2a.618.618,0,0,0,.2.7.764.764,0,0,0,.5.2H.1L5-11.6Z" transform="translate(0.839 27.775)" fill="currentColor"/>
                                <rect id="Retângulo_309" data-name="Retângulo 309" width="11.3" height="7" transform="matrix(0.707, -0.707, 0.707, 0.707, 2.201, 10.404)" fill="currentColor"/>
                                <path id="Caminho_469" data-name="Caminho 469" d="M16-25.6l-1.5-1.5a2.605,2.605,0,0,0-3.5,0h0l-.6.6,5,5,.6-.6a2.342,2.342,0,0,0,.7-1.7A2.567,2.567,0,0,0,16-25.6Z" transform="translate(0.839 27.775)" fill="currentColor"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
      
        `
    )
};