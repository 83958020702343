export default {
    template: (
        /*html*/
        `
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_466_3200)">
                    <path d="M19.19 8.189L16.89 5.889C16.6827 5.68254 16.4021 5.56662 16.1095 5.56662C15.8169 5.56662 15.5363 5.68254 15.329 5.889L5.575 15.645V19.498H9.431L19.191 9.749C19.2938 9.64678 19.3754 9.52524 19.431 9.39138C19.4867 9.25752 19.5154 9.11397 19.5154 8.969C19.5154 8.82403 19.4867 8.68048 19.431 8.54662C19.3754 8.41276 19.2928 8.29122 19.19 8.189ZM8.853 18.105H6.969V16.221L13.548 9.647L15.433 11.53L8.853 18.105ZM16.418 10.546L14.534 8.663L16.114 7.085L17.998 8.968L16.418 10.546Z" fill="currentColor"/>
                    <path d="M6.272 10.445H7.666V5.571H9.757V4.178H4.181V5.571H6.272V10.445Z" fill="currentColor"/>
                    <path d="M4.181 12.534H1.394V1.393H12.544V4.178H13.938V1.393C13.938 1.20998 13.9019 1.02876 13.8319 0.859691C13.7618 0.69062 13.6591 0.537012 13.5296 0.407647C13.4002 0.278282 13.2465 0.175695 13.0774 0.105749C12.9083 0.035803 12.727 -0.000131023 12.544 3.58971e-07H1.394C1.21098 -0.000131023 1.02974 0.035803 0.860615 0.105749C0.691493 0.175695 0.537812 0.278282 0.408354 0.407647C0.278896 0.537012 0.176199 0.69062 0.106131 0.859691C0.036064 1.02876 -4.7158e-08 1.20998 0 1.393V12.534C-4.7158e-08 12.717 0.036064 12.8982 0.106131 13.0673C0.176199 13.2364 0.278896 13.39 0.408354 13.5194C0.537812 13.6487 0.691493 13.7513 0.860615 13.8213C1.02974 13.8912 1.21098 13.9271 1.394 13.927H4.181V12.534Z" fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_466_3200">
                        <rect width="20" height="20" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        `
    )
};