import BaseAutocomplete from "../../../components/atomos/BaseAutocomplete";

/**
 * Componente para documenta��o do componente BaseAutocomplete
 * 
 * 
 * @displayName BaseAutocompleteUsage
 * @category Atomos
 * @author Bruno Dias Ramos <bruno.dias@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */

export default {
    name: "BaseAutocompleteUsage",
    components: { BaseAutocomplete },

    data() {
        return {
            status: "",
            situation_loading: false,
            situation_list: [
                {"value": "WAITING", "text": "Aguardando"},
                {"value": "CANCELED", "text": "Cancelado"},
                {"value": "NOT_SENT", "text": window.Vue.htmlEntities("N&atilde;o enviado")},
                {"value": "SENT", "text": "Enviado"},
                {"value": "NOT_DELIVERED", "text": window.Vue.htmlEntities("N&atilde;o entregue")},
                {"value": "DELIVERED", "text": "Entregue"}
            ],
        };
    },

    template: (
        /*html*/
        `
            <v-row justify="center" class="mt-8">
                <v-col cols="6" class="text-center">
                    <base-autocomplete
                        v-model="status"
                        tooltip-text="Voc&ecirc; pode selecionar uma ou mais situa&ccedil;&otilde;es(status) dos disparos."
                        label="Situa&ccedil;&atilde;o"
                        title="Situa&ccedil;&atilde;o"
                        name="situation"
                        :items="situation_list"
                        :rules="[]"
                        :loading="situation_loading"
                        multiple
                        use-icon-validation
                    />
                </v-col>
            </v-row>
        `
    )
};