import BasePill from "../../../components/atomos/BasePill";

/**
 * Componente para configurar uma mensagem no cadastro de template
 * 
 * @requires {@link BasePill}
 * 
 * @component
 * @displayName ConfigMessageTemplate
 * @category Page / Template RCS
 * @vue
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @vue/component
 */
export default {
    components: {
        BasePill
    },
    model: {
        prop: "listMessage",
        event: "change"
    },
    props: {
        /**
         * Lista de elementos da mensagem a ser exibida. 
         * Os itens do array devem ser do tipo {@link DraggableItemClass}
         */
        listMessage: {
            type: Array,
            required: true
        },
        /**
         * Lista de elementos referente ao título a ser exibido. 
         * Os itens do array devem ser do tipo {@link DraggableItemClass}
         */
        listTitle: {
            type: Array,
            required: false,
            default: null
        },
        /**
         * Indica a quantidade máxima de caracteres do componente
         */
        maxCharacters: {
            type: [Number, String],
            required: false,
            default: 1000
        },
        /**
         * Define o nome do grupo draggable
         */
        groupName: {
            type: String,
            required: false,
            default: "default"
        },
        /**
         * Define a largura do componente
         */
        width: {
            type: [Number, String],
            required: false,
            default: undefined
        }
    },
    data() {
        return {
            list: _.cloneDeep(this.listMessage),
            title: _.cloneDeep(this.listTitle)
        };
    },
    computed: {
        totalCharacters() {
            let total = 0;

            for (let message of this.list) {
                if (message.type === "text") {
                    total += message.text.length;
                } else if (message.type === "pill") {
                    total += message.pill.numberCharacters;
                }
                total++;
            }

            if (total > 0) {
                total--;
            }

            if (this.title && this.title.length) {
                for (let title of this.title) {
                    if (title.type === "text") {
                        total += title.text.length;
                    } else if (title.type === "pill") {
                        total += title.pill.numberCharacters;
                    }
                    total++;
                }

                if (total > 0) {
                    total--;
                }
            }

            return total;
        },
        colorTotalCharacters() {
            return this.totalCharacters > this.maxCharacters ? "text-error-900" : "text-gray-500";
        },
        havePills() {
            for (let item of this.list) {
                if (item.type === "pill") {
                    return true;
                }
            }

            if (this.title) {
                for (let item of this.title) {
                    if (item.type === "pill") {
                        return true;
                    }
                }
            }

            return false;
        }
    },
    watch: {
        listMessage(new_value) {
            this.list = _.cloneDeep(new_value);
        },
        listTitle(new_value) {
            this.title = _.cloneDeep(new_value);
            this.$emit("clickItem", new_value);
        }
    },
    methods: {
        /**
         * Emite um evento informando que uma notificação deve ser exibida
         * @param {Object} data 
         */
        showNotify(data) {
            this.$emit("showNotify", data);
        },
        /**
         * Informa que a lista de items foi alterada
         * @vue
         */
        changeList() {
            /**
             * Evento que emite a lista de items sempre que for alterada, este evento pode ser capturado pelo v-model
             * 
             * @property {Array} list Lista de items
             * 
             * @event change
             * @vue
             */
            this.$emit("change", this.list);
        },
        /**
         * Informa que o título foi alterado
         * @vue
         */
        changeTitle() {
            /**
             * Evento que emite a lista de items do título sempre que for alterada, este evento pode ser capturado pelo v-model
             * 
             * @property {Array} list Lista de items
             * 
             * @event change
             * @vue
             */
            this.$emit("changeTitle", this.title);
        },
        /**
         * Remove um item do tipo pill do array
         * @param {Number} position Posicao do item no array
         * @vue
         */
        removePill(position, title = false) {
            let notify_content = {
                notify_title: "Campo personalizado removido",
                position_removed: position,
                notify_button_label: "Reinserir campo",
                restore_type: "remove"
            };

            if (title) {
                notify_content["notify_message"] = `O campo personalizado <b>${this.title[position].text}</b> foi removido da mensagem`;
                notify_content["removed_pill"] = this.title[position];

                this.title.splice(position, 1);
                this.changeTitle();
            } else {
                notify_content["notify_message"] = `O campo personalizado <b>${this.list[position].text}</b> foi removido da mensagem`;
                notify_content["removed_pill"] = this.list[position];

                this.list.splice(position, 1);
                this.changeList();
            }

            this.showNotify(notify_content);
        }
    },
    template: (
        /*html*/
        `
            <v-card elevation="0" class="bg-transparent fill-height" :width="width">
                <v-row no-gutters align="end" class="fill-height">
                    <v-col v-if="title" cols="12">
                        <draggable
                            tag="v-row"
                            class="no-gutters"
                            :group="{ name: groupName, put: true, pull: true }"
                            :list="title"
                            @change="changeTitle"
                        >
                            <v-col
                                v-for="(item, i) in title"
                                :key="item.id"
                                cols="auto"
                                class="pr-1 pb-1 cursor-move d-flex align-end"
                            >
                                <span v-if="item.type === 'text'">{{ item.text }}</span>
                                <base-pill
                                    v-if="item.type === 'pill'"
                                    rounded
                                    removable
                                    draggable
                                    :prepend-icon="item.pill.selected ? 'mdi-arrow-all' : undefined"
                                    :selected="item.pill.selected"
                                    selected-text="Reposicionando"
                                    :title="item.text"
                                    @remove="removePill(i, true)"
                                    @click="$emit('clickItem', item)"
                                ></base-pill>
                            </v-col>
                        </draggable>
                    </v-col>
                    <v-col cols="12">
                        <draggable
                            tag="v-row"
                            class="no-gutters"
                            :group="{ name: groupName, put: true, pull: true }"
                            :list="list"
                            @change="changeList"
                        >
                            <v-col
                                v-for="(item, i) in list"
                                :key="item.id"
                                :cols="item.type === 'break-line' ? 12 : 'auto'"
                                class="pr-1 cursor-move d-flex align-end"
                            >
                                <span v-if="item.type === 'text' || item.type === 'break-line'">{{ item.text }}</span>
                                <base-pill
                                    v-if="item.type === 'pill'"
                                    rounded
                                    removable
                                    draggable
                                    :prepend-icon="item.pill.selected ? 'mdi-arrow-all' : undefined"
                                    :selected="item.pill.selected"
                                    selected-text="Reposicionando"
                                    :title="item.text"
                                    @remove="removePill(i)"
                                    @click="$emit('clickItem', item)"
                                ></base-pill>
                            </v-col>
                        </draggable>
                    </v-col>
                    <v-col v-if="havePills" cols="12" class="bg-gray-10 mt-4">
                        <v-row no-gutters justify="center" class="mt-2">
                            <v-col cols="auto" class="pr-1">
                                <v-card elevation="0" class="bg-gray-50 rounded-1 text-center align-center align-items-center align-content-center align-self-center align-center" width="20" height="20">
                                    <v-icon small class="mb-1" color="gray-500">mdi-arrow-left</v-icon>
                                </v-card>
                            </v-col>
                            <v-col cols="auto" class="pl-1">
                                <v-card elevation="0" class="bg-gray-50 rounded-1 text-center align-center align-items-center align-content-center align-self-center align-center" width="20" height="20">
                                    <v-icon small class="mb-1" color="gray-500">mdi-arrow-right</v-icon>
                                </v-card>
                            </v-col>
                            <v-col cols="12" class="mt-2 text-center">
                                <span class="caption text-gray-500">Arraste ou utilize o teclado para reposicionar</span>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="text-end">
                        <v-divider :class="'mb-1 ' + (havePills ? 'mt-0' : 'mt-4')"></v-divider>
                        <span :class="'caption ' + colorTotalCharacters">{{ totalCharacters }}/{{ maxCharacters }} caracteres</span>
                    </v-col>
                </v-row>
            </v-card>
        `
    )
};