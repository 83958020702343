import BaseDialog from "../../../../components/moleculas/BaseDialog?v=3";
import BaseButton from "../../../../components/atomos/BaseButton?v=2";
import CanalIconDescription from "../../../../components/moleculas/CanalIconDescription?v=2";
import { ProdutoUtils } from "../../../../utils/ProdutoUtils";
import TemplateUtils from "../../../../utils/TemplateUtils";
import CarrosselCardRcs from "../../../../../../vue-app/src/components/organismos/CarrosselCardRcs";
import CardRcs from "../../../../../../vue-app/src/components/moleculas/CardRcs";
import { TextPreview } from "../../../../../../vue-app/src/components/moleculas/TextPreview";
import { TemplateService } from "../../../../../../js/services/TemplateService";

const template_service = new TemplateService();
/**
 * Componente de dialog para detalhes de um template
 * <iframe src="http://localhost:8080/v2/documentacao/template/dialog-details" style="width: 100%; height: 315px;"></iframe>
 *
 * @requires {@link BaseDialog}
 * @requires {@link BaseButton}
 * @requires {@link CanalIconDescription}
 * @requires {@link ProdutoUtils}
 * @requires {@link TemplateUtils}
 *
 * @displayName DialogDetails
 * @category Page / Template
 * @subcategory dialogs
 * @author David Nunes dos Santos <david.santos@pgmais.com.br>
 * @component
 * @vue
 * @vue/component
 */
export default {
    components: {
        BaseDialog,
        BaseButton,
        CanalIconDescription,
        CarrosselCardRcs,
        CardRcs,
        TextPreview
    },
    filters: {
        format_date: function(date) {
            if (date) {
                return moment(date).format("DD/MM/YYYY HH:mm:ss");
            } else {
                return "-";
            }
        }
    },
    props: {
        /**
         * Valor do atributo v-model do componente, indica se o dialog deve ser exibido ou nao
         */
        value: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * Dados do item a ser exibido
         */
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dialog: this.value,
            produto_utils: new ProdutoUtils(),
            template_utils: new TemplateUtils(),
            id_empresa:
                this.$cookie === undefined
                    ? getCookie("id_empresa_selected")
                    : this.$cookie.get("id_empresa_selected"),
            preview: {}
        };
    },
    watch: {
        value() {
            this.dialog = this.value;
        },
        dialog() {
            /**
             * Emite o status do dialog (false ou true)
             * @property {Boolean} dialog
             *
             * @event input
             * @vue
             */
            this.$emit("input", this.dialog);

            if (!this.dialog) {
                /**
                 * Emite um evento chamado close informando que o modal foi fechado
                 *
                 * @event close
                 * @vue
                 */
                this.$emit("close");
            }
        },
        async item() {
            //Tratativas responsaveis pela renderiza��o do componente de carrossel
            let content_template = this.item.DS_TEMPLATE;
            let type = "";
            let text = "";
            let cards = [];

            try {
                if (JSON.parse(content_template).text) {
                    type = "text";
                    text = JSON.parse(content_template);
                    this.preview = { value: text, type: type };
                }
                if (JSON.parse(content_template).url) {
                    type = "image";
                    this.preview = {
                        url: JSON.parse(content_template).url,
                        type: type
                    };

                    $("#preview_template").html("");
                    let img = await template_service.montarCardImagem(
                        this.preview.url
                    );
                    $("#preview_template").append(img);
                }
                if (JSON.parse(content_template).contents) {
                    content_template = JSON.parse(content_template).contents;
                    if (Array.isArray(content_template)) {
                        type = "carousel";
                        $.each(content_template, function(index, content) {
                            cards.push({
                                number: index + 1,
                                title: content.title,
                                height: content.media.height,
                                description: content.description,
                                image: content.media.file.url,
                                suggestions: content.suggestions
                            });
                        });
                        this.preview = { value: cards, type: type };
                    } else {
                        type = "card";
                        cards.push({
                            number: 1,
                            title: content_template.title,
                            height: content_template.media.height,
                            description: content_template.description,
                            image: content_template.media.file.url,
                            suggestions: content_template.suggestions
                        });

                        this.preview = { value: cards[0], type: type };
                    }
                }
            } catch (e) {
                type = "text";
                text = content_template;
                this.preview = { value: { text: text }, type: type };
            }
        }
    },
    methods: {
        getResumoTemplate(type, resumo) {
            let data = resumo;
            let template_type = this.template_utils.getTraducaoTipoTemplateApps(
                type
            );

            if (
                template_type == "Texto" ||
                type == "E" ||
                type === "" ||
                !type ||
                this.type_product === "SMS"
            ) {
                try {
                    data = JSON.parse(resumo).text;
                } catch (error) {
                    data = resumo;
                }

                return data;
            } else {
                return template_type;
            }
        },
        disable() {
            this.dialog = false;
            this.$emit("disable", this.item);
        },
        edit() {
            this.dialog = false;
            this.$emit("edit", this.item);
        }
    },
    template:
        /*html*/
        `
            <base-dialog 
                v-model="dialog"
                title="Detalhes de templates"
                use-icon-close
                max-width="50%"
                cancel
            >
                <v-card elevation="0">
                    <v-row justify="end">
                        <v-col v-if="!(item.CD_PRODUTO_PDES == 'WTS')" cols="auto">
                            <base-button
                                primary
                                label="Editar"
                                icon="$editOutlineIcon"
                                @click="edit"
                            ></base-button>
                        </v-col>
                        <v-col cols="auto">
                            <base-button
                                primary
                                danger
                                label="Desativar"
                                icon="$disableOutlineIcon"
                                @click="disable"
                            ></base-button>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card elevation="0" class="border border-light-grey pa-5">
                                <p>C&oacute;digo: <b>{{ item.CD_TEMPLATE }}</b></p>
                                <p>Cliente: <b>{{ item.ID_EMPRESA == id_empresa || (item.ID_EMPRESA_PAI == id_empresa && (!item.ID_EMPRESA || item.ID_EMPRESA == '')) ? 'Todos' : item.NM_EMPRESA }}</b></p>
                                <p>Canal: <b>{{ produto_utils.getProdutoTraducao(item.CD_PRODUTO_PDES && item.CD_PRODUTO_PDES.length > 0 ? item.CD_PRODUTO_PDES : (item.TP_TEMPLATE === 'C' ? 'sms-c' : 'sms')) }}</b></p>
                                <p>Identificador: <b>{{ item.ID_TEMPLATE }}</b></p>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card elevation="0" class="border border-light-grey pa-5">
                                <p>Criado por: <b>{{ item.NM_USUARIO || '-' }}</b></p>
                                <p>Data de cria&ccedil;&atilde;o: <b>{{ item.DT_TEMPLATE | format_date }}</b></p>
                                <p>Atualizado por: <b>{{ item.ALT_NM_USUARIO || '-' }}</b></p>
                                <p>Data de atualiza&ccedil;&atilde;o: <b>{{ item.DT_ULTIMA_ALTERACAO | format_date }}</b></p>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <canal-icon-description :product-name="item.CD_PRODUTO_PDES && item.CD_PRODUTO_PDES.length > 0 ? item.CD_PRODUTO_PDES : (item.TP_TEMPLATE === 'C' ? 'sms-c' : 'sms')"></canal-icon-description>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card elevation="0" class="border border-light-grey pa-5">
                                <p v-if="preview.type == '' " v-html="getResumoTemplate(item.TP_TEMPLATE, item.DS_TEMPLATE)"></p>
                                
                                <v-col cols="12" v-if="preview.type==='carousel'">
                                    <carrossel-card-rcs :cards-config="preview.value"></carrossel-card-rcs>
                                </v-col>
                                <v-col cols="12" v-if="preview.type==='card'">
                                    <card-rcs :title="preview.value.title"
                                        :description="preview.value.description"
                                        :height="preview.value.height"
                                        :suggestions="preview.value.suggestions"
                                        :image="preview.value.image"
                                        ></card-rcs>
                                </v-col>
                                <v-col cols="12" v-if="preview.type==='text'">
                                    <text-preview force_direction="left" :text="preview.value.text" :suggestions="preview.value.suggestions"></text-preview>
                                </v-col>
                                <v-col cols="12" v-if="preview.type=='image'">
                                    <div id="preview_template" >
                                        <div></div>
                                    </div>                                
                                </v-col>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </base-dialog>
        `
};
